import { useState, useEffect, useContext } from "react";

import FirebaseUtils from "../../utils/firebase.utils";

import { prepareCartItem } from "../../utils/cart.utils";

import {Cart, DocumentLogs, Requests } from "../../models"

import { message, Form, Select, Input, Button } from 'antd'

import { GlobalContext } from "../../Store";

const { Option } = Select


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { span: 16 },
};

export default function Warranty({ record, callback }) {

    const [options, setOptions] = useState([]);

    const { request } = record;

    const [loading, setLoading] = useState(false);

    const [selectedWarrenty, setSelectedWarrenty] = useState({});

    const { user } = useContext(GlobalContext);


    useEffect(() => {
        getOptions();

        return () => { };
    }, []);

    function getOptions() {
        var queries = [
            {
                field: 'category',
                value: 'Extended Warranty',
            },
        ];

        FirebaseUtils.getAccessories(request.city, queries).then((result) => {
            setOptions(result.accessories);
        });
    }

    /**
     *
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        setLoading(true);

        let selected = options.filter((entry) => entry.partNo === values.selected)[0];

        let data = {
            partName: selected.partName,
            price: selected.price,
            partNo: selected.partNo,
        };

        var params = prepareCartItem({ ...selected, quantity: 1 }, record.booking, request);

        params = {
            ...params,
            deleted_at: null,
            deleted_by: null,
        };

        {/* Extended Warranty edition option for closed settlement */ }
        if (request.actual_delivery.settlementTaken) {
            let param = {
                ...params,
                description: params.name
            }
            FirebaseUtils.updateBillDetails(record.id, param).then(() => {
                var queries = [
                    {
                        field: 'bookingNo',
                        value: request.bookingNo,
                    },
                    {
                        field: 'dealerCode',
                        value: request.dealerCode,
                    },
                    {
                        field: 'type',
                        value: 'Extended Warranty',
                    },
                ];

                // Load the Cart
                Cart.get(queries).then((result) => {
                    let id = result.cart[0].id
                    FirebaseUtils.updateCartItem(id, params).then(() => {
                        let batch = FirebaseUtils.getApp().batch();
                        const requestRef = Requests.getRecordReference(request.id);
                        //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs
                        DocumentLogs.recordLog('request', request, params, user, batch, requestRef).then(async () => {

                            message.success('Updates has been recorded');

                            setLoading(false);

                            callback();
                        })
                    })
                })
            });
        }
        else if (record.id) {
            FirebaseUtils.updateCartItem(record.id, params).then((result) => {
                message.success('Updates has been recorded');

                setLoading(false);

                callback();
            });
        } else {
            FirebaseUtils.addCartItem(params).then((result) => {
                message.success('Updates has been recorded');

                setLoading(false);

                callback();
            });
        }
    };

    /**
     *
     *
     * @param {*} values
     */
    const onRemove = (values) => {
        setLoading(true);

        FirebaseUtils.deleteCartItem(record.id).then(() => {
            message.success('Warranty has been removed');

            setLoading(false);

            callback();
        });
    };

    function handleChange(value) {
        setSelectedWarrenty(value);
    }

    return (
        <>
            <Form
                {...layout}
                layout="vertical"
                name="basic"
                initialValues={{
                    selected: record && record.no ? record.no : null,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name={'selected'}
                    label="Extended Warranty"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter current status',
                        },
                    ]}
                >
                    <Select placeholder="Select the preference" onChange={handleChange}>
                        {options.map((doc, index) => (
                            <Option value={doc.partNo} key={index}>
                                {doc.partName}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {selectedWarrenty
                    ? options.map((doc, index) => {
                        if (doc.partNo == selectedWarrenty) {
                            return <Input style={{ marginBottom: '10px', width: '30%' }} defaultValue={doc.price} disabled />;
                        }
                    })
                    : null}

                <Form.Item {...tailLayout}>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Submit
                    </Button>

                    <Button loading={loading} type="secondary" onClick={onRemove}>
                        Remove
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
