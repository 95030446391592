import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Row, Tag, Form, Input, message, Card, Image } from 'antd';

import { Link } from 'react-router-dom';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import RequestForm from './../../../common/resources/request-form/request-form';

import RecordForm from './../../../common/resources/record-form/record-form';

import BaseForm from './../../../common/resources/base-form/base-form';

import GetUserName from '../../../../components/get-username/get-username';

import './review-request-detail.scss';

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};

export default function RequestDetail({ match, step, history }) {
    const { user } = useContext(GlobalContext);

    const city = user.locations[0];

    const [userRequest, setuserRequest] = useState({ comments: [] });

    const { params } = match;

    const { id } = params;

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        setLoader(true);

        // checking if allocation, since allocation table is at base, db (new structure)
        if (step.endpoint === 'Allocation') {
            FirebaseUtils.getBaseRecord(id, step.model).then((request) => {
                setuserRequest(request);
                setLoader(false);
            });
        } else if (step.endpoint === 'cancellations') {
            FirebaseUtils.getBaseRecord(id, step.model).then((result) => {
                var request = result;
                setuserRequest(request);
                setLoader(false);
            });
        } else {
            FirebaseUtils.getRecord(step.endpoint || 'requests', id).then((request) => {
                console.log(request);

                setuserRequest(request);

                setLoader(false);

                if (step.endpoint !== 'stock_transfers') {
                    FirebaseUtils.getBooking(request.bookingNo).then((result) => {
                        let booking = result.data();

                        request.booking = booking;

                        setuserRequest(request);
                    });
                }
            });
        }
    }

    return (
        <div className="review-request-detail">
            {/* <Header onBack={() => props.history.goBack()} /> */}

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    {step.endpoint === 'stock_transfers' ? (
                        <>
                            {/* Vehicle Card */}
                            <div className="vehicle-card">
                                <div className="vehicle-image-wrapper">{/* <img src={images[file['model']]} alt="vehicle-img" /> */}</div>

                                <div gutter={0} className="detail-wrapper">
                                    {/* 
                                            <Tag color="purple">
                                                {userRequest.status || 'Initial'}
                                            </Tag> */}

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Booking No</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{userRequest['bookingNo']}</h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>From</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{userRequest['from']}</h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>To</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{userRequest['to']}</h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Vin No</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{userRequest['vinNo']}</h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>Created By</Row>
                                        <Row span={12}>
                                            <h3 className=""><GetUserName user_id={userRequest['created_by']}/></h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>Created At</Row>
                                        <Row span={12}>
                                            <h3 className="">{userRequest['created_at']}</h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>Remarks</Row>
                                        <Row span={12}>
                                            <h3 className="">{userRequest['remarks']}</h3>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : // for deallocation details section
                    step.endpoint === 'Allocation' ? (
                        <>
                            <div className="about-us page-content">
                                <h1 className="page-heading">Deallocation</h1>
                            </div>
                            <p>Update any necessary information and see the status of your allocation below</p>
                            <div gutter={0} className="detail-wrapper">
                                <div className="detail-element">
                                    <Row span={12}>
                                        <Row span={12}>
                                            <h3>Booking No </h3>
                                        </Row>
                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title amount">{userRequest.bookingNo}</h2>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Request </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.requestId}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Dealer Code :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{userRequest.dealerCode}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Vin :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{userRequest.vinNo}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Status </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.status}</span>
                                    </Row>
                                </div>
                            </div>
                        </>
                    ) : step.endpoint === 'cancellations' ? (
                        <>
                            <div className="vehicle-card">
                                <div className="about-us page-content">
                                    <h1 className="page-heading">Cancel {userRequest.type}</h1>
                                </div>
                                <p>Update any necessary information and see the status of your allocation below</p>
                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <Row span={12}>
                                                <h3>Customer Name </h3>
                                            </Row>
                                        </Row>
                                        <Row span={12}>
                                            <h2 className="title amount">{userRequest.customerName}</h2>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Email :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.email}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Booking No </h3>
                                        </Row>
                                        <Row span={12}>
                                            <a href={`/bookings/${userRequest.bookingId}`}>{userRequest.bookingId}</a>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Dealer Code :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.dealerCode}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Phone No :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.phone}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Attachments :</h3>
                                        </Row>
                                        <>
                                            {userRequest.cancellation_request &&
                                                userRequest.cancellation_request.map((file) => {
                                                    return (
                                                        <>
                                                            {file.type === 'application/pdf' ? (
                                                                <embed src={file.url} width="500" height="375" type="application/pdf" />
                                                            ) : (
                                                                <Image width={200} src={file.url} />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                        </>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Reason :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.reason}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Remarks :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.remarks}</span>
                                        </Row>
                                    </div>
                                    {userRequest.sm_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from SM :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.sm_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {userRequest.ccm_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from CCM :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.ccm_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {userRequest.mgt_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from Management :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.mgt_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {userRequest.accounts_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from Accounts :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.accounts_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {userRequest.refund_initiated_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from CCM (refund initiated) :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.refund_initiated_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Status </h3>
                                        </Row>
                                        <Row span={12}>
                                            <span>{userRequest.status}</span>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            <BaseForm
                                index={id}
                                formContent={userRequest}
                                modelIndex="id"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <div className="about-us page-content">
                                <h1 className="page-heading">Allocation Request</h1>
                            </div>

                            <p>Update any necessary information and see the status of your request below</p>
                            <div gutter={0} className="detail-wrapper">
                                {/* <Tag color="purple">
                                            {userRequest.status}
                                            </Tag> */}

                                <div className="detail-element">
                                    <Row span={12}>
                                        <Row span={12}>
                                            <h3>Customer </h3>
                                        </Row>
                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title amount">{userRequest.customer.customerName} </h2>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Phone </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.customer.phone}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Remarks </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.customer.remarks}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Variant </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.variant}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Color</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.color}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Consultant :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{userRequest.kec}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Vin :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{userRequest.allocation && userRequest.allocation.vinNo}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>
                                            <h3>Request :</h3>
                                        </span>
                                    </Row>
                                    <Row span={12}>
                                        <Link to={`/requests/${userRequest['id']}`}>View Request</Link>
                                    </Row>
                                </div>
                            </div>
                        </>
                    )}

                    {step.endpoint === 'stock_transfers' ? (
                        <>
                            <RecordForm
                                index={id}
                                formContent={userRequest}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : // for deallocation
                    step.endpoint === 'Allocation' ? (
                        <>
                            <BaseForm
                                index={id}
                                formContent={userRequest}
                                modelIndex="id"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : step.endpoint === 'cancellations' ? null : (
                        <>
                            <RequestForm
                                formContent={userRequest}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    )}
                </Fragment>
            )}
        </div>
    );
}
