
/**
 * 
 * Component to show the total of the receipts
 * 
 */

import React, { useEffect, useState, useContext } from 'react';

import { Table, Typography } from 'antd';

import moment from 'moment';

import Rupee from '../../rupee/rupee';


// 
const { Text } = Typography;

const receiptColumns = [
    {
        title: '#',
        // dataIndex: 'index',
        render: (value, item, index) => index + 1,
    },

    {
        title: 'Date',
        dataIndex: 'txn_date',
        // sorter: (a, b) => moment(a['Receipt Date'], 'DD/MM/YYYY').unix() - moment(b['Receipt Date'], 'DD/MM/YYYY').unix(),
        // sortDirections: ['ascend', 'descend', 'ascend'],
    },

    {
        title: 'Type',
        // dataIndex: 'On Account Of',
        render: (text, record) => {
            return (
                <div style={{ maxHeight: '36px' }}>
                    <div>{record['On Account Of']}</div>
                    <div style={{ fontSize: '10px', color: 'grey' }}>{record['account']}</div>
                </div>
            );
        },
    },

    {
        title: 'Amount',
        dataIndex: 'amount',
    },
];


/**
 *
 * Table to show summary of all the received data
 * DMS Receipts
 *
 * @param {*} param0
 */
export default function CustomerEntriesTable({ receipts = [] }) {

    console.log(receipts);

    return (
        <Table
            pagination={false}
            // scroll={{ x: true }}

            // scroll={{ x: true, y: 750 }}
            rowKey={(record, key) => key}
            columns={receiptColumns}
            dataSource={receipts}
            size="small"
            summary={(pageData) => {
                let total = pageData.reduce((a, b) => a + parseInt(b['amount']), 0);

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>Total</Table.Summary.Cell>

                            {/* <Table.Summary.Cell>
                        </Table.Summary.Cell> */}

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={total}></Rupee>
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        />
    );
}