import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

import CustomerList from './components/customer-list/customer-list';
import TaskForm from './components/rto-form/rto-form';

import TaskRoutes from '../common/resources/task-routes/task-routes';
import ChangePassword from './../common/components/change-password/change-password';

import './service-receptionist-landing.scss';

import { GlobalContext } from '../../Store';

import { receptionistSteps } from './service-receptionist-config';

import GenericRequest from '../common/resources/generic-request/generic-request';

import TaskOverview from '../common/resources/task-overview/task-overview';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';

export default function ReceptionistLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    const queries = [
        {
            field: 'warranty.process',
            value: 'incomplete',
        },
    ];

    const modules = [
        // {
        //   caption: "Customers",
        //   path: `/${user.locations[0]}/customers`,
        // },

        {
            caption: 'Tasks',
            submenus: receptionistSteps.filter((step) => !step.isForm),
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => (
                            <>
                                <NoticeBoard />
                                <TaskOverview steps={receptionistSteps} />
                            </>
                        )}
                    />

                    <Route exact path={'/'} component={() => <TaskOverview steps={receptionistSteps} />}></Route>
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    {/* <Route path="/:city/customers" component={() => <GenericRequest columns={requestColumns} queries={queries} />} />

                    <Route path="/requests/:id" component={({ ...routeParams }) => <DeliveryScheduleForm step={{ fields: [] }} {...routeParams} columns={requestColumns} queries={queries} />} /> */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={receptionistSteps} list={CustomerList} form={TaskForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
