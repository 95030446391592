import React, { useState, useContext } from 'react';

import { Form, Button, Input, message } from 'antd';

import { withRouter, Link } from 'react-router-dom';
import mask from './../../assets/images/forgot.png';

import './forgot-password.scss';

import FirebaseUtils from '../../utils/firebase.utils';

var logo = require('./../../assets/images/kia-logo.png');

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { span: 8 },
};

const ForgotPassword = ({ history }) => {
    const [loading, setLoading] = useState(false);

    /**
     *
     * Sent reset password link
     * **/
    async function onFinish(values) {
        setLoading(true);

        try {
            await FirebaseUtils.resetPassword(values.email);
            message.success('Password reset link sent!');
            setLoading(false);
        } catch {
            setLoading(false);
            message.error('Invalid email address!');
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setLoading(false);
    };

    return (
        <section className="user-authentication-section">
            <div className="auth-form-wrapper">
                <div className="brand-logo">
                    <img className="logo-welcome" src={logo} alt="Logo" />
                </div>

                <div className="form-title">
                    <h3>Reset Your Password</h3>

                    <p>Let's get a new one!</p>
                </div>

                <img className="forgot-image" src={mask} alt="forgot" />

                <Form {...layout} layout="vertical" name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Link to="/Login-phone" style={{ marginTop: '10px' }}>
                    Login
                </Link>
            </div>
        </section>
    );
};

export default withRouter(ForgotPassword);
