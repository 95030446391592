import Base from './base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import FirebaseUtils from '../utils/firebase.utils';

import ApiUtils from '../utils/api.utils';

import { Payments } from '../models';

import { message } from 'antd';

import moment from 'moment';

// import CollectionAdd from './../modules/accounts/components/collection-add/collection-add';

import { Users } from './index';
import { dealerCodeKey } from '../modules/global-config';

class Payment extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get filterDate() {
        return true;
    }

    get getEndpoint() {
        return 'payments';
    }

    get path() {
        return `payments`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `payment`;
    }

    get columns() {
        return [];
    }

    refundLink = async (refundType, values, request, department, user,mode, cancelFlag, isBooking) => {
        var cityCode = dealerCodeKey
        // For Bank Transfer
        // Via Razorpay
        if (refundType === 'instant-imps' || refundType === 'Transfer by Razorpay') {
            let token = await FirebaseUtils.getToken();

            let formBody = {
                name: values.name,
                email: values.email,
                contact: values.contact,
                ifsc: values.ifsc,
                account_number: values.account_number,
                amount: values.amount * 100,

                bookingNo: request.bookingNo,
                id: request.id,
                dealerCode: request.dealerCode,

                reference_id: `${request.dealerCode} - Booking - ${request.bookingNo}`,
            };

            if (request && request.customer) {
                formBody = {
                    ...formBody,
                    customer: request.customer,
                    allocation: request.allocation,
                };
            }

            if (cancelFlag) {
                formBody = {
                    ...formBody,
                    location: cityCode[request.dealerCode],
                    remarks: values.account_remarks,
                };
            } else {
                formBody = {
                    ...formBody,
                    location: request.city,
                    remarks: values.remarks,
                };
            }

            // var current = isBooking ? request.to_account.value : request.accounts.ac_code.value;
            var refName;

            if (!cancelFlag) {
                refName = isBooking
                    ? request.name + ' / ' + request.phone
                    : request.customer.customerName + ' / ' + request.customer.phone + ' / ' + request.allocation.vinNo;
            }

            var params = {
                description: 'Customer Refund via Razorpay',
                mode :mode,
                amount: values.amount,
            };

            if (cancelFlag) {
                params = {
                    ...params,
                    ref: request.name + ' / ' + request.phone,
                };
            } else {
                params = {
                    ...params,
                    ref: refName,
                };
            }

            formBody = {
                ...formBody,
                params: params,
                isBooking: false,
                cancelFlag: cancelFlag,
                department: department,
                user: user,
                token: token,
            };

            // Initiate createRefundRequest

            await Payments.loadFunction('createRefundRequest', { formBody }).then((result) => {
                if (result.status === 'success') {
                    message.success('Refund process complete');
                } else {
                    message.error(result.description);
                }
            });
        } else if (refundType === 'instant-link' || refundType === 'Payment Link by Razorpay') {
            // Payout Link via Razorpay

            let token = await FirebaseUtils.getToken();

            // For Payout
            let formBody = {
                name: values.name,
                email: values.email,
                contact: values.contact,
                amount: values.amount * 100,

                bookingNo: request.bookingNo,
                id: request.id,
                dealerCode: request.dealerCode,
            };

            if (request && request.customer) {
                formBody = {
                    ...formBody,

                    customer: request.customer,
                    allocation: request.allocation,
                };
            }

            if (cancelFlag) {
                formBody = {
                    ...formBody,
                    location: cityCode[request.dealerCode],
                };
            } else {
                formBody = {
                    ...formBody,
                    location: request.city,
                };
            }
            var refName;
            if (!cancelFlag) {
                refName = isBooking
                    ? request.name + ' / ' + request.phone
                    : request.customer.customerName + ' / ' + request.customer.phone + ' / ' + request.allocation.vinNo;
            }

            var params = {
                description: 'Customer Refund via Razorpay',
                amount: values.amount,
                mode: mode
            };

            if (cancelFlag) {
                params = {
                    ...params,
                    ref: request.name + ' / ' + request.phone,
                };
            } else {
                params = {
                    ...params,
                    ref: refName,
                };
            }

            formBody = {
                ...formBody,
                params: params,
                isBooking: false,
                cancelFlag: cancelFlag,
                department: department,
                user: user,
                token: token,
            };

            //   Initiate the payout link

            await Payments.loadFunction('initiatePayoutLink', { formBody }).then((result) => {
                if (result.status === 'success') {
                    message.success('Refund process complete');
                } else {
                    message.error(result.error.description);
                }
            });
        } else if (refundType === 'cash' || refundType === 'Cash') {
            // for cancellation flow
            let formBody = {
                name: values.name,
                email: values.email,
                contact: values.contact,
                amount: values.amount,
                remarks: values.remarks,
            };

            // For Cash Payment
            var refName;

            // var customerDetail;

            if (!cancelFlag) {
                refName = isBooking
                ? request.name + ' / ' + request.phone
                : request.customer.customerName + ' / ' + request.customer.phone + ' / ' + request.allocation.vinNo;
            }

            if (cancelFlag) {
                params = {
                    ...params,
                    ref: values.name + ' / ' + values.contact,
                    // customer: values.to_account.value,
                };
            } else {
                params = {
                    ...params,
                    ref: refName,
                    // customer: customerDetail,
                };
            }

            params = {
                ...params,
                amount: values.amount,
                payment_mode: 'cash',
                channel: 'cash',
                description: 'Refund as Cash',
                mode: mode

            };

            return this.addPaymentEntry(params, formBody, request, isBooking, cancelFlag);

        } else if (refundType === 'bank' || refundType === 'Net Banking') {
            // for cancellation flow
            let formBody = {
                name: values.name,
                email: values.email,
                contact: values.contact,
                ifsc: values.ifsc,
                account_number: values.account_number,
                amount: values.amount * 100,
                remarks: values.remarks,
                reference_id: `${request.dealerCode} - Booking - ${request.bookingNo}`,
            };

            var refName;

            if (!cancelFlag) {
                refName = isBooking
                    ? request.name + ' / ' + request.phone
                    : request.customer.customerName + ' / ' + request.customer.phone + ' / ' + request.allocation.vinNo;

                // customerDetail = isBooking ? request.to_account.value : request.accounts.ac_code.value;
            }
            // Normal Bank Transfer
            // var current = isBooking ? request.to_account.value : request.accounts.ac_code.value;

            if (cancelFlag) {
                params = {
                    ...params,
                    ref: values.name + ' / ' + values.contact,
                    // customer: values.to_account.value,
                };
            } else {
                params = {
                    ...params,
                    ref: refName,
                    // customer: customerDetail,
                };
            }

            params = {
                source: '',
                ...params,
                amount: values.amount,
                payment_mode: 'net banking',
                channel: 'bank',
                description: 'Customer Refund via Bank Transfer',
                mode: mode

            };

            return this.addPaymentEntry(params, formBody, request, isBooking, cancelFlag);
        }
    };

    /**
     * Add a Payment Entry to Firestore
     * and Add a Cart Entry
     */
    addPaymentEntry = (params, formBody, request, isBooking, cancelFlag) => {
        //Prepare item to add to cart
        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
            deleted_at: null,
            deleted_by: null
        };

        params = {
            bookingNo: request.bookingNo,
            requestId: request.id,
            dealerCode: request.dealerCode,
            ...params,
            ...creation,
        };

        if (!cancelFlag) {
            params = {
                ...params,
                customerID: request.customer.customerID,
                vinNo: request.allocation.vinNo,
            };
        }

        // Create a payment record in firestore
        return FirebaseUtils.createPayment(params)
            .then(() => {
                if (isBooking || cancelFlag) {
                    return;
                } else {
                    let cartItem = {
                        bookingNo: request.bookingNo,
                        requestId: request.id,
                        customerID: request.customer.customerID,
                        type: 'additional',
                        vinNo: request.allocation.vinNo,
                        description: params.description,
                        price: params.amount,
                        dealerCode: request['dealerCode'],
                        ...creation,
                    };

                    return FirebaseUtils.addCartItem(cartItem).then(() => {
                        message.success('Refund process complete');
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export default Payment;
