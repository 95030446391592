import React, { useEffect, useState, useContext } from 'react';

import { Tabs, Form, message, Alert, Button, Tag, Row, Table, Typography, Select, Card, Space, Popconfirm, Skeleton } from 'antd';

import { GlobalContext } from './../../Store';

import ApiUtils from '../../utils/api.utils';

import PayableEntries from '../payable-entries/payable-entries';

import FirebaseUtils from '../../utils/firebase.utils';

import Ledger from './../ledger/ledger';

import { CheckCircleOutlined } from '@ant-design/icons';

import AsyncSelect from 'react-select/async';

import './summary.scss';

import { Bills, Cart, BillDetails, Departments, CustomerEntries, Requests } from '../../models';

import CustomerRefunds from '../customer-refunds/customer-refunds';

const { Option } = Select;


const { TabPane } = Tabs;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

/**
 * Summary Component
 *
 * @param {*} param0
 */
export default function Summary({ request, booking, saveBreakup = () => { }, refresh = () => { }, updatePricing = () => { } }) {
    const { user, branches, dispatch, disableDisplayPhoneNumber } = useContext(GlobalContext);

    console.log(branches);

    const [editMode, seteditMode] = useState(false);

    const [form] = Form.useForm();

    const [ledger, setLedger] = useState([]);

    const [cart, setCart] = useState([]);

    const { accounts } = request;

    const [csAccounts, setCsAccounts] = useState(false)

    // const [options, setOptions] = useState(branches);

    const [allDepartments, setAllDepartments] = useState([]);

    const [departments, setDepartments] = useState([]);

    const [selectedDepartment, setSelectedDepartment] = useState();


    // Variable to store the records of the customer receipts 
    const [customerReceipts, setCustomerReceipts] = useState([])

    // useEffect(() => {
    //     setOptions(branches);
    // }, [branches]);

    // Variable to store the total of the customer receipts
    const [customerReceiptTotal, setCustomerReceiptTotal] = useState(0)

    useEffect(async () => {
        await getAllDepartments().then(async (department) => {

            await getCartData(department)

            // Checking if cs accounts data to be shown
            await Requests.disableCsAccounts(request).then(async (result) => {


                if (result.enable) {

                    setCsAccounts(true)

                    // Load accounts ledger
                    await loadAccountsLedger(request.accounts, department);
                } else {

                    setCsAccounts(false)

                    //Load total receipt data
                    await getCustomerRecieptTotal()
                }
            })


        });
    }, []);

    async function selectBranch(branchId, arg) {
        let matching = departments.filter((item) => item.id === branchId).pop();

        setSelectedDepartment(matching);
    }


    // function selectBranch(branch, arg) {
    //     let matching = options.filter((item) => item.code === branch).pop();

    //     // setSelected({ code: branch });

    //     ApiUtils.setBranch(matching, user);
    //     form.setFieldsValue({ branch: branch });

    //     dispatch({ type: 'defaultBranch', payload: matching });
    // }

    /**
     * 
     * @returns Load cart data
     */
    async function getCartData() {
        var queries = [
            {
                field: 'bookingNo',
                value: request.bookingNo,
            },
            {
                field: 'dealerCode',
                value: request.dealerCode,
            },
        ];

        // Load the Cart
        Cart.getVehicleDetailed(queries, request).then((result) => {
            result = result.filter((item) => {
                if (item.type === 'basic') {
                    return item;
                } else if (item.status !== 'removed') {
                    return item;
                }
            });

            let arr = [].concat(result);

            BillDetails.getActiveEntries(queries).then((result) => {
                result = result.filter((item) => item.status !== 'removed' && item.attached_with !== 'independent');
                //
                arr = [].concat(arr, result);

                //handled billed vas
                Bills.get(queries).then((result) => {
                    // Filter the bills without invoice number
                    var bills = result.bills.filter(
                        (bill) => bill.invoice_number && bill.cancellation_status !== true && bill.attached_with !== 'independent'
                    );

                    // All the bills
                    bills.forEach((item) => {
                        let body = {
                            ...item,
                            table: 'bills',
                            price: item.net_amount,
                            description: `${item.invoice_number} - ${item.created_at}`,
                        };

                        if (body.transaction_type === 'sales-return') {
                            body.description = `${item.invoice_number} - ${item.created_at} ${(<p>Return</p>)}`;
                        }

                        if (body.type === 'basic') {
                            body.description = `Exshowroom : ${request.allocation.vinNo}`;

                            if (body.tcs_amount) {
                                body.price = body.price - body.tcs_amount;
                            }

                            body.order = 1;
                        }

                        if (body.type === 'VAS' && body.table === 'bills' && body.cancellation_status !== true) {
                            body.type = 'VAS Bills';

                            if (body.transaction_type === 'sales-return') {
                                body.net_amount = -body.net_amount;
                            }
                        }

                        if (body.type === 'Accessories' && body.table === 'bills' && body.cancellation_status !== true) {
                            body.type = 'Accessories Bills';

                            if (body.transaction_type === 'sales-return') {
                                body.net_amount = -body.net_amount;
                            }
                        }

                        if (body.type === 'After Market' && body.table === 'bills' && body.cancellation_status !== true) {
                            body.type = 'After Market Bills';
                        }

                        arr.push(body);

                        // Once the ex showroom is aded we have to add the tcs
                        if (!body.tcsExempted && item.tcs_amount) {
                            arr.push({
                                description: 'TCS @ 1 %',
                                order: 2,
                                type: 'basic',
                                price: item.tcs_amount,
                            });
                        }
                    });

                    console.log(arr);

                    setCart(arr);
                });
            });
        });

    }

    async function getAllDepartments() {
        return await Departments.get().then((res) => {
            setAllDepartments(res.departments);

            var department = res.departments.filter((dep) => dep.description === 'Department Handling Sales' && user.department_ids.includes(dep.id));

            setDepartments(department);

            var selectedDep = department.filter((dep) => dep.location === request.city);

            setSelectedDepartment(selectedDep[0]);

            return selectedDep[0];
        });
    }


    /**
     * Function to get receipt total
     */
    function getCustomerRecieptTotal() {
        let bookingNo = request.bookingNo.substring(5);
        var totalPaid = 0;

        // Get all approved entries
        let query = [
            {
                field: 'bookingNo',
                value: bookingNo,
            },
            {
                field: 'dealerCode',
                value: request.dealerCode,
            },
            {
                field: 'verified',
                value: 'approved'
            }
        ];
        return CustomerEntries.get(query).then((result) => {
            if (result.customer_entries.length) {

                console.log(result.customer_entries);

                // Setting the value against the entries
                setCustomerReceipts(result.customer_entries);

                return result.customer_entries.forEach((entry, index) => {
                    totalPaid += entry.amount;
                    setCustomerReceiptTotal(totalPaid)
                });
            } else {

                setCustomerReceiptTotal(totalPaid)

            }
        })
    }

    /**
     * Function Loads accounts ledger
     */
    function loadAccountsLedger(accounts, department) {
        if (accounts && accounts.ac_code && accounts.ac_code.value) {
            // Get the CustomerBalance // Not used at the moment
            ApiUtils.getCustomerLedger(request.city, accounts.ac_code.value, null, null, department).then((balance) => {
                console.log(balance);

                // Get the ledger details
                ApiUtils.getLedgerDetails(request.city, accounts.ac_code.value, null, department)
                    .then((result) => {
                        console.log(result);

                        if (result) {
                            // Credits are the Right Side
                            var credits = result.filter((entry) => entry.Trn === -1);

                            // Debits are the left side
                            var debits = result.filter((entry) => entry.Trn === 1);

                            // console.log('Credits', credits);

                            let creditTotal = credits.reduce((a, b) => a + parseInt(b.Amount), 0);

                            //
                            let debitTotal = debits.reduce((a, b) => a + parseInt(b.Amount), 0);

                            if (balance) {
                                result.unshift({
                                    VDate: '01/04/2022',
                                    Amount: balance,
                                    Trn: -1,
                                    Type: 'Opening Balance',
                                    OpActualAcHead: 'Opening Balance',
                                    OpAcPDCStatus: 'R',
                                });
                            }

                            setLedger(result);
                        } else {
                        }

                        // console.log('Debit Total', debitTotal);
                    })
                    .catch((error) => {
                        console.log(error);

                        message.error('Loading ledger failed');
                    });
            });
        }
    }


    async function getAllDepartments() {
        return await Departments.get().then((res) => {
            setAllDepartments(res.departments);

            var department = res.departments.filter((dep) => dep.description === 'Department Handling Sales' && user.department_ids.includes(dep.id));

            setDepartments(department);

            var selectedDep = department.filter((dep) => dep.location === request.city);

            setSelectedDepartment(selectedDep[0]);

            return selectedDep[0];
        });
    }



    /**
     * Load the matching heads
     */
    function loadHeads(query) {
        return ApiUtils.getHeads(query, selectedDepartment).then((result) => {
            return result.map((item) => {
                return {
                    value: item.ac_code,
                    label: item.ac_desc,
                };
            });
        });
    }

    /**
 *
 *
 * @param {*} values
 */
    function onSubmit(values) {
        // console.log(values);

        var params = {
            accounts: {
                ...request.accounts,
                ...values,
            },
        };

        FirebaseUtils.updateRequestGeneric(
            // request.city,
            request.id,
            params,
            'Accounts head has been updated',
            '',
            'accounts'
        ).then(() => {
            message.success('Accounts head has been updated');

            refresh();
        });
    }

    return (


        <>

            {/* Accounts Head */}
            {
                ['accounts', 'admin'].indexOf(user.role) !== -1 && (editMode || !accounts || !accounts.ac_code) ?
                    (
                        <>


                            <div className="vehicle-card card">
                                <Alert
                                    message={`Please select the Accounts Head of the customer to show live comparison with accounts.`}
                                    type="warning"
                                    showIcon
                                    style={{ margin: '10px 0px' }}
                                />

                                <Form
                                    name="new-record"
                                    form={form}
                                    {...layout}
                                    onFinish={onSubmit}
                                    layout="vertical"
                                    // validateMessages={validateMessages}
                                    initialValues={
                                        {
                                            // transaction_date:new Date()
                                        }
                                    }
                                >
                                    <Form.Item
                                        name={'branch'}
                                        label="Select Branch"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select Branch',
                                            },
                                        ]}
                                    >
                                        <Select
                                            onSelect={selectBranch}
                                            placeholder="Branch"
                                            style={{
                                                width: '200px',
                                                margin: '0px 10px',
                                            }}
                                        >
                                            {departments.map((doc, index) => (
                                                <Option value={doc.id} key={doc.code}>
                                                    {doc.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    {selectedDepartment ? (
                                        <Form.Item
                                            name={'ac_code'}
                                            label="Customer Account Head"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select Account Head',
                                                },
                                            ]}
                                        >
                                            <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} />
                                        </Form.Item>
                                    ) : null}


                                    {/* <Form.Item
                                        name={'ac_code'}
                                        label="Customer Account Head"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select Account Head',
                                            },
                                        ]}
                                    >
                                        <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} />
                                    </Form.Item> */}

                                    <Button style={{ marginRight: '10px' }} type="primary" htmlType="submit" className="submit-button">
                                        Submit
                                    </Button>
                                </Form>
                            </div>

                        </>
                    ) : <>


                        {accounts && accounts.ac_code ? (
                            <div className="vehicle-card card">
                                <div gutter={0} className="detail-wrapper">
                                    <Tag icon={<CheckCircleOutlined />} color="success">
                                        Account Head
                                    </Tag>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Head</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{accounts.ac_code.value}</h3>
                                        </Row>
                                    </div>
                                    
                                    {/*disable PhoneNumber for kec and tl */}
                                    {!disableDisplayPhoneNumber ? <>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <span>Name</span>
                                            </Row>
                                            <Row span={12}>
                                                <h3>{accounts.ac_code.label}</h3>
                                            </Row>
                                        </div>
                                    </> : null}

                                    {user.role === 'accounts' ? (
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                seteditMode(true);
                                            }}
                                            type="secondary"
                                            className="submit-button"
                                        >
                                            Edit
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </>

            }


            {/* )} */}
            {/* Accounts Head Ends */}

            {/* Vehicle Allocation */}
            {
                request.allocation.vinNo && cart.length ? (
                    <>
                        <Tabs defaultActiveKey="0">
                            <TabPane tab="Summary" key="0">
                                {/* {cart.length ? ( */}
                                <PayableEntries
                                    // columns={cartColumns}
                                    booking={booking}
                                    request={request}
                                    refresh={refresh}
                                    saveBreakup={saveBreakup}
                                    cart={cart}
                                    cs_accounts={csAccounts}
                                    ledger={ledger}

                                    // Cutomer Entries
                                    customerReceipts={customerReceipts}
                                    customerReceiptTotal={customerReceiptTotal}
                                    // Cutomer Entries Ends


                                    callback={(pricing) => {
                                        updatePricing(pricing);
                                    }}
                                />
                                {/* ) : null} */}
                            </TabPane>

                            {csAccounts ?
                                <TabPane tab="Ledger" key="1">
                                    <Ledger booking={booking} request={request} ledger={ledger} />
                                </TabPane>
                                : null}

                        </Tabs>
                    </>
                ) : null
            }
            {/* Vehicle Allocation Ends */}

            {/* Refunds If any should be listed here with status */}
            <CustomerRefunds request={request} />
            {/* Refunds */}
        </>
    );
}


