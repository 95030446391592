import React, { useState, useContext } from 'react';

import { Button, message, Modal, Typography } from 'antd';

import { GlobalContext } from '../../../../Store';

import VehicleUtils from '../../../../utils/vehicles.utils';

import { Vehicles } from '../../../../models';

import './block-request.scss';

import { CheckCircleOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';

import StockTransferRequest from '../../../../components/vehicle-detail/stock-transfer-request';

import GatePassModal from '../../../../components/vehicle-detail/gate-pass';

const { Title } = Typography;

export default function BlockRequest({ index, caption = 'Block Vehicle', request, vehicles = {}, callback, city }) {
    const [selected, setSelected] = useState(null);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [visible, setVisible] = useState(false);

    // Visble for stock transfer detail modal
    const [stockTransferVisible, setStockTransferVisible] = useState(false);

    const [btnloading, setBtnLoading] = useState(false)

    // Visible for gate pass modal
    const [view, setView] = useState(false);

    const [stockTransferId, setStockTransferId] = useState()

    /**
     *
     * Function executed when
     * Allocate/Block vehicle to a request
     *
     */
    function blockVehicle(values) {

        setLoading(true);
        setBtnLoading(true)

        // During Allocation we have to update the request with the vehicle details
        // and also add the cart with the respective pricing and basic tax elements

        var vehicle = vehicles[selected];

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        // Update the request
        updateRequestStatus(vehicle, values, batch).then((response) => {

            if (response && response.stockTransferId)
                setStockTransferId(response.stockTransferId)
            // And Add Pricing
            addPricing(vehicle, batch).then(async () => {

                await batch.commit();

                message.info('Blocked Successfully');

                setLoading(false);

                setStockTransferVisible(false)

                callback();
            });
        });
    }

    /**
     *
     * @param {*} vehicle
     */
    async function updateRequestStatus(vehicle, values, batch) {

        // Block the vehicle
        return await Vehicles.blockVehicle(request, vehicle, values, batch).then((newBooking) => {
            // If vehicle and request are from different locations, show gate pass

            if (vehicles[selected].dealerCode === request.dealerCode) {
                setVisible(false);

                callback(newBooking, selected);
            } else {

                return newBooking
            }
        });
    }

    /**
     * Add the pricing elements to the cart
     */
    async function addPricing(vehicle, batch) {
        return VehicleUtils.addPricing(request, vehicle, user, batch).then(() => { });
    }

    /**
     *
     */
    function submitRequest() {

        if (selected) {
            // If vehicle and request are from different locations, create stock transfer request
            if (vehicles[selected].dealerCode !== request.dealerCode)
                setStockTransferVisible(true)
            else
                blockVehicle()

            setVisible(false)

            // blockVehicle({ selected });
        } else {
            message.error('You have to select a vehicle');
        }
    }

    /**
     *
     */
    function closeModal() {
        setVisible(false);
    }

    return (
        <div className="block-vehicle">
            <Button
                size="small"
                type="secondary"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setVisible(true);
                }}
            >
                {caption}
            </Button>

            <Modal
                title="Select a vehicle"
                visible={visible}
                // onOk={submitRequest}
                onCancel={closeModal}
                footer={[
                    <Button key="back" onClick={closeModal}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={submitRequest}>
                        Submit
                    </Button>,
                ]}
            >
                <p>Select a vehicle from below to block for this request.</p>

                {Object.keys(vehicles)
                    .filter((index) => !vehicles[index].allocated)
                    .map((index, key) => {
                        let vehicle = vehicles[index];

                        return (
                            <div
                                key={key}
                                className={`list-item ${selected === index ? 'active' : ''}`}
                                onClick={() => {
                                    setSelected(index);
                                }}
                            >
                                <div className="left">
                                    <Title level={5}>
                                        {vehicle['Variant']}
                                        <span> {` [ ${vehicle['Exterior Color Name']} ] `} </span>
                                    </Title>

                                    <div>{/* {`${car['Cust ID'] ? 'Allocated for ' + car["Cust Name"] + ', ' + car["Cust ID"] : ''}`} */}</div>

                                    <div>{`${vehicle['Vin Number']}`}</div>

                                    <small className="status">{vehicle['Stock Location'] || vehicle['Stock Status']} </small>
                                </div>

                                {selected === index ? <CheckCircleOutlined /> : null}
                            </div>
                        );
                    })}
            </Modal>

            {/** Stock Transfer modal begins */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Stock Transfer Request"
                visible={stockTransferVisible}
                okText="Okay"
                onOk={() => {
                    setStockTransferVisible(false);
                }}
                onCancel={() => {
                    setStockTransferVisible(false);
                }}
            >
                <StockTransferRequest
                    btnloading={btnloading}
                    vehicle={vehicles[selected]}
                    from={vehicles[selected] && vehicles[selected].dealerCode ? vehicles[selected].dealerCode : null}
                    to={request.dealerCode}
                    required={true}
                    Callback={(values) => {
                        blockVehicle(values);
                    }}
                />
            </Modal>
            {/** Stock Transfer modal ends */}
            {/**Gate pass modal begins */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Gate pass"
                visible={view}
                width={'60%'}
                okText="Okay"
                onOk={() => {
                    callback()
                    setView(false);
                }}
                onCancel={() => {
                    callback()
                    setView(false);

                }}
            >
                <GatePassModal params={vehicles[selected] && vehicles[selected].id ? vehicles[selected].id : null} vehicle={vehicles[selected]} stockTransferId={stockTransferId} />
            </Modal>
            {/** Gate pass modal ends */}
        </div>
    );
}
