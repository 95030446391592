import Base from './base';

class PackageDetails extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'package_details';
    }

    get path() {
        return `package_details`;
    }

    get = (queries) => {
        return this.getMethod(queries).then((result) => {
            return result;
        });
    };

    /**
     * Update of model
     *
     * @param {*} id
     * @param {*} values
     * @param {*} user
     * @returns
     */
    update = (id, values) => {
        return this.update(id, values).then((result) => {
            return result
        })
    }
}

export default PackageDetails