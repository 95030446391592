import React, { useState, useContext } from "react"

import { withRouter } from 'react-router-dom'

import { GlobalContext } from './../../Store';

import { Typography, Descriptions } from 'antd';


var logo = require('./../../assets/images/kia-logo.png');

const { Title } = Typography;


function ContactComponent({ history }) {

    const { user, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    return (
        <section className="about-component">
            <div className="about-section">

                <div className="brand-logo">
                    <img className="logo-welcome" src={logo} alt="Logo" />
                </div>

                <div className="content-section" style={{ margin: '50px' }}>


                    <Title level={3}>
                        CONTACT US
                    </Title>

                    <div className="detail-section">
                        <div >

                            <Descriptions title="Kozhikode">
                                <Descriptions.Item label="UserName">DKH KIA, CALICUT</Descriptions.Item>
                                <Descriptions.Item label="Telephone">9947445566</Descriptions.Item>
                                <Descriptions.Item label="Email">
                                    it@dkhkia.com
                                </Descriptions.Item>
                                <Descriptions.Item label="Address">
                                    Athanikkal, West Hill, Kannur Road,  Calicut - 673005, Kerala India
                                </Descriptions.Item>
                            </Descriptions>

                        </div>
                        <div >

                            <Descriptions title="Kannur">
                                <Descriptions.Item label="UserName">DKH KIA, KANNUR</Descriptions.Item>
                                <Descriptions.Item label="Telephone">9947445566</Descriptions.Item>
                                <Descriptions.Item label="Email">
                                    it@dkhkia.com
                                </Descriptions.Item>
                                <Descriptions.Item label="Address">
                                    Kairali Nagar, Thottada (P O),
                                    Kannur - 670007, Kerala, India
                                </Descriptions.Item>
                            </Descriptions>

                        </div>
                        <div >

                            <Descriptions layout={'vertical'} title="Malappuram">
                                <Descriptions.Item label="UserName">DKH KIA, MALAPPURAM</Descriptions.Item>
                                <Descriptions.Item label="Telephone">9947445566</Descriptions.Item>
                                <Descriptions.Item label="Email">
                                    it@dkhkia.com
                                </Descriptions.Item>
                                <Descriptions.Item label="Address">
                                    N.H.213, Ramapuram, Panangangara
                                    Malappuram - 679321, Kerala, India
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default withRouter(ContactComponent)