import React, { useState, useContext, useRef } from 'react';

import { Typography, Table, Popover, Button } from 'antd';

import { useReactToPrint } from 'react-to-print';

import moment from 'moment';

import { GlobalContext } from './../../../../Store';

import Rupee from './../../../../components/rupee/rupee';

var companyLogo = require('../../../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

export const Settlement = ({ data, billNumber, bills }) => {
    const { user } = useContext(GlobalContext);
    const print = useRef();

    const columns = [
        {
            title: '#',
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Description',
            // dataIndex: 'description',
            render: (record) => {
                return record.type === 'additional' ? (
                    <div>{record.type}</div>
                ) : (
                    <div>
                        <div>{record.customerName}</div>
                        <div style={{ color: 'grey', fontSize: '10px' }}>{record.phone}</div>
                    </div>
                );
            },
            width: 200,
        },
        {
            title: 'Amount',
            dataIndex: 'actual_payout',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{text}</div>;
            },
            width: 150,
        },
        {
            title: 'sgst (9%)',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{record.sgst_amount}</div>;
            },
            width: 150,
        },
        {
            title: 'cgst (9%)',
            render: (text, record) => {
                return <div style={{ height: '20px' }}> {record.cgst_amount}</div>;
            },
            width: 150,
        },
        {
            title: 'Total Amount',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{parseFloat(record.final_payout)}</div>;
            },
            width: 200,
        },
    ];

    // function calculatedAmount(amt) {
    //     let cgst = amt * 0.09;
    //     let sgst = amt * 0.09;
    //     let total = amt + parseFloat(cgst) + parseFloat(sgst);
    //     return total;
    // }

    const address =
        user.locations[0] == 'kozhikode'
            ? { add1: 'Kannur Rd, Athanikkal, West Hill,', add2: 'Kozhikode, Kerala 673005' }
            : user.locations[0] == 'kannur'
            ? { add1: 'Kairali Nagar, Thottada(PO),', add2: 'Kannur, Kerala 670007' }
            : { add1: 'N.H.213, Ramapuram, Panangangara,', add2: 'Malappuram, Kerala 679321' };

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const getDateAndTime = () => {
        let date = new Date();
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>{moment(date).format('DD MMM YYYY')}</div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div className="page-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div>
                        <img className="logo-welcome" src={companyLogo} alt="Logo" />
                        <Title level={3}>Invoice</Title>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ border: '1px dashed #d9d9d9', padding: '4px 15px', marginRight: '10px' }}>Bill No :- {billNumber}</div>
                        {/* {bills ? (
                            <Button type="dashed" className="print-button" onClick={handlePrint}>
                                Print Invoice
                            </Button>
                        ) : null} */}
                    </div>
                </div>
                <div>
                    <Summary data={data} columns={columns} />
                </div>
            </div>
            {/* <div className="print-block" style={{ overflow: 'hidden', height: 0, width: 0, display: 'none' }}>
                <WrapperClass ref={print}>
                    {bills ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    borderBottom: '1px dashed #2f3e4c',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <div>
                                    <img className="logo-welcome" src={companyLogo} alt="Logo" />
                                    <Title level={3}>Invoice #{bills.invoiceNumber}</Title>
                                </div>
                                <div>
                                    <div className="address-block">
                                        <div style={{ fontSize: '12px' }}>DKH Kia ,</div>
                                        <div style={{ fontSize: '10px' }}>{address['add1']} </div>
                                        <div style={{ fontSize: '10px' }}>{address['add2']}</div>
                                    </div>
                                    <div className="date-and-time" style={{}}>
                                        {getDateAndTime()}
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
                                <div style={{ flexBasis: '50%' }}>
                                    <div style={{ fontSize: '16px' }}>Invoice Number</div>
                                    <div style={{ fontSize: '14px' }}>{bills.invoiceNumber}</div>
                                </div>
                                <div>
                                    <div style={{ fontSize: '16px' }}>Invoice Date</div>
                                    <div style={{ fontSize: '14px' }}>{bills.created_at}</div>
                                </div>
                            </div>
                            <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
                                <div style={{ flexBasis: '50%' }}>
                                    <div style={{ fontSize: '16px' }}>From ,</div>
                                    <div style={{ fontSize: '14px' }}>{bills.from_address} </div>
                                    <div style={{ fontSize: '14px' }}>{address['add2']}</div>
                                </div>
                                <div>
                                    <div style={{ fontSize: '16px' }}>To ,</div>
                                    <div style={{ fontSize: '14px' }}>{bills.to_address} </div>
                                    <div style={{ fontSize: '14px' }}>{address['add2']}</div>
                                </div>
                            </div>
                            <div style={{ marginTop: '32px' }}>
                                <Summary data={data} columns={columns} />
                            </div>
                        </div>
                    ) : null}
                </WrapperClass>
            </div> */}
        </div>
    );
};

function Summary({ data, columns, refresh }) {
    const [amount, setAmount] = useState(0);

    const { user = {} } = useContext(GlobalContext);

    let props = {};

    return (
        <>
            <Table
                pagination={false}
                scroll={{ x: true }}
                rowKey={(record, key) => key}
                columns={columns}
                dataSource={data}
                {...props}
                size="small"
                summary={(pageData) => {
                    //  #todo Below section will have to be optimized in future as there is enormous amount of rerendering happending for this
                    // component . But it works
                    let total = 0,
                        receivedTotal = 0;

                    pageData.forEach((entry) => {
                        let amount = entry.final_payout;
                        total += amount;
                    });

                    total = Math.round(total);

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Total</Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                <Table.Summary.Cell>
                                    <Popover content={`Total`}>
                                        <Text>
                                            <Rupee value={total}></Rupee>
                                        </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}
