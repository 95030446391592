/**
 * Component to edit bill and bill details
 * @author Hasna
 */

import { useState } from 'react';

import { Button, Modal, message, Form, Checkbox, Input, Select } from 'antd';

import { Bills } from '../../../../models';

import moment from 'moment-timezone';

const { Option } = Select;

export default function EditBill({ loadPage, bill, bill_details, user, round }) {
    const [editVisible, setEditVisible] = useState(false);
    /**
     * Edit details modal
     */
    const handleEdit = () => {
        setEditVisible(true);
    };
    return (
        <div>
            <div>
                <Button type="dashed" onClick={handleEdit}>
                    Edit Details
                </Button>
            </div>

            {/** Modal for editing details start*/}
            <Modal
                footer={null}
                title="Edit Bill"
                visible={editVisible}
                okText="Okay"
                onOk={() => {
                    setEditVisible(false);
                }}
                onCancel={() => {
                    setEditVisible(false);
                }}
            >
                <EditModal
                    bill={bill}
                    billDetails={bill_details}
                    user={user}
                    round={round}
                    callBack={() => {
                        setEditVisible(false);
                        loadPage();
                    }}
                />
            </Modal>

            {/** Modal for editing details end*/}
        </div>
    );

    /**
     * Component to edit modal
     * @param {*} param0
     * @returns
     */

    function EditModal({ bill, billDetails, user, callBack, round }) {

        const [editName, setEditName] = useState(false);

        const [editState, setEditState] = useState(false);

        const [loading, setLoading] = useState(false);

        const [disable, setDisable] = useState(false);

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 16 },
        };

        function onEdit(values) {
            // converting to milli seconds
            let date = moment(values.invoice_date_millisecond, 'DD/MM/YYYY').startOf().valueOf();

            setLoading(true);
            var formBody = {
                ...values,
                bill_id: bill.id,
                // converting to millsecond
                invoice_date_millisecond: date,
                // converting to utc format
                invoice_date: new Date(date),

                //Here selectedRecord will be items selected(vehicle in this case)
                selectedRecords: billDetails,
                user: user,
                round: round,
            };
            // console.log("PPP",formBody)

            //Function to edit bill
            Bills.loadFunction('editBill', formBody).then((result) => {

                if (result.status === 'success') {
                    message.success('Edited Successfully');

                    callBack();
                } else {
                    message.error('Failed');
                }

                setLoading(false);
            });
        }
        return (
            <>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onEdit}
                    initialValues={{
                        name: bill.customer_name,
                        address: bill.customer_address,
                        customer_state: bill.customer_state,
                        ship_to_address: bill.ship_to_address,
                        pan_number: bill.pan_number,
                        ship_to_postal_code: bill.ship_to_postal_code,
                        customer_postal_code: bill.customer_postal_code,
                        invoice_date_millisecond: moment(bill.invoice_date_millisecond).format('DD/MM/YYYY'),
                        gst_number: bill.gst_number,
                        edit_name: editName,
                        edit_state: editState,
                    }}
                    onFieldsChange={(fields) => {
                        //checkbox for editname
                        if (fields[0].name[0] === 'edit_name') {
                            setEditName(fields[0].value);
                            setDisable(true);
                        }

                        //checkbox for edit state
                        if (fields[0].name[0] === 'edit_state') {
                            setEditState(fields[0].value);
                            setDisable(true);
                        }
                    }}
                >
                    <Form.Item valuePropName="checked" name="edit_name" rules={[{ required: false, message: 'Edit Bill Name' }]}>
                        <Checkbox>Edit Bill </Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Enter Name"
                        name="name"
                        rules={[
                            { required: true, message: 'Please Enter Customer Name' },
                            {
                                pattern: /^[a-zA-Z\s]*$/g,
                                message: 'Please Enter Valid Name!',
                            },
                        ]}
                    >
                        <Input disabled={!editName} />
                    </Form.Item>

                    <Form.Item
                        label="Enter Bill to  Address"
                        name="address"
                        rules={[
                            { required: true, message: 'Please Enter Customer Address' },
                            // {
                            //     pattern: /^[ A-Za-z0-9_@.,/#()&-]*$/,
                            //     message: 'Enter Valid Address!',
                            // },
                        ]}
                    >
                        <Input disabled={!editName} />
                    </Form.Item>
                    <Form.Item
                        label="Enter Bill to  Postal Code"
                        name="customer_postal_code"
                        rules={[
                            { required: true, message: 'Please Enter Customer Postal Code' },
                            {
                                pattern: /(^\d{6,7}$)|(^\d{5}-\d{4}$)/,
                                message: 'Please enter valid Pin Code ',
                            },
                        ]}
                    >
                        <Input disabled={!editName} />
                    </Form.Item>
                    <Form.Item
                        label="Enter Shipping Address"
                        name="ship_to_address"
                        rules={[
                            { required: true, message: 'Please Enter Customer Shipping Address ' },
                            // {
                            //     pattern: /^[ A-Za-z0-9_@.,/#()&-]*$/,
                            //     message: 'Enter Valid Address!',
                            // },
                        ]}
                    >
                        <Input disabled={!editName} />
                    </Form.Item>
                    <Form.Item
                        label="Enter Shipping Postal Code"
                        name="ship_to_postal_code"
                        rules={[
                            { required: true, message: 'Please Enter Customer Shipping Postal Code' },
                            {
                                pattern: /(^\d{6,7}$)|(^\d{5}-\d{4}$)/,
                                message: 'Please enter valid Pin Code ',
                            },
                        ]}
                    >
                        <Input disabled={!editName} />
                    </Form.Item>
                    <Form.Item
                        label="Enter  Pan Card Number"
                        name="pan_number"
                        rules={[
                            { required: true, message: 'Please Enter Pan Card  Number' },
                            {
                                pattern: /^[ A-Za-z0-9/]*$/,
                                message: 'Enter Valid Pan Card Number!',
                            },
                        ]}
                    >
                        <Input disabled={!editName} />
                    </Form.Item>

                    <Form.Item
                        label="Enter  Invoice Date"
                        name="invoice_date_millisecond"
                        rules={[
                            { required: true, message: 'Please Enter Invoice Date' },
                            {
                                pattern: /^[ 0-9/]*$/,
                                message: 'Invalid Date Format',
                            },
                        ]}
                    >
                        <Input disabled={!editName} />
                    </Form.Item>
                    <Form.Item valuePropName="checked" name="edit_state" rules={[{ required: false, message: 'Edit State' }]}>
                        <Checkbox>Edit State</Checkbox>
                    </Form.Item>

                    <Form.Item name={'customer_state'} label="Customer State" rules={[{ required: true }]}>
                        <Select allowClear style={{ width: '100%' }} placeholder="Please select" disabled={!editState}>
                            {['Kerala', 'Pondicherry', 'Tamil Nadu', 'Karnataka', 'Andhra Pradesh', 'Other'].map((model, key) => {
                                return (
                                    <Option value={model} key={key}>
                                        {model}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                   {/* adding gst number for edit */}

                    { bill.gst_number ? (
                    
                    <Form.Item
                        label="Enter GST Number"
                        name="gst_number"
                        rules={[
                            { required: true, message: 'Please Enter GST Number ' },
                            {
                                pattern: /^[ A-Za-z0-9/]*$/,
                                message: 'Enter Valid GST Number!',
                            },
                        ]}
                    >
                        <Input disabled={!editState} />
                    </Form.Item>
                    ) : '' }

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" disabled={!disable} loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}
