import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Row, Tag, Image, Timeline, Divider, Col } from 'antd';

import PlaceHolder from '../../../../../../components/ui_elements/PlaceHolder';

import { Payouts } from '../../../../../../models';

// import './reconcile-summary.scss';

/**
 *
 */
export default function PayoutInfo({ collection = { attachments: [] } }) {
    const [payouts, setPayouts] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPayout();
    }, []);

    function getPayout() {
        var arr = [];
        return Promise.all(
            collection.attributes.payouts.map((payout) => {
                return Payouts.getRecord(payout).then((result) => {
                    arr.push(result);
                    return arr;
                });
            })
        ).then((arr) => {
            setPayouts(arr[0]);
            setLoading(false);
        });
    }

    console.log(payouts);

    return (
        <div>
            <></>
            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {' '}
                    {payouts.map((p) => (
                        <div gutter={0} className="card collection-card" style={{ margin: '8px 0px 8px 0px' }}>
                            <Col>
                                <div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Customer Name:</h3>
                                        </Row>
                                        <Row span={12}>
                                            <h4>{p.customerName}</h4>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Actual Payout:</h3>
                                        </Row>
                                        <Row span={12}>
                                            <h4 className="title amount">{p.final_payout}</h4>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Variant:</h3>
                                        </Row>
                                        <Row span={12}>
                                            <h4>{p.variant}</h4>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Created At:</h3>
                                        </Row>
                                        <Row span={12}>
                                            <h4>{p.created_at}</h4>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    ))}
                </>
            )}

            <Divider />

            {/* <Timeline>
                <Timeline.Item color={'green'}>
                    Created by {collection.created_by && collection.created_by.name}
                    <div>{collection.created_at}</div>
                </Timeline.Item>
                <Timeline.Item color="red">Verification</Timeline.Item>
                <Timeline.Item color="gray">DMS Receipt</Timeline.Item>
            </Timeline> */}
        </div>
    );
}
