



/**
 * Columns for Request Table
 */
export const requestColumns = [

    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName'
    },
    {
        type: 'input',
        caption: 'Part Name',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'warranty.status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant'
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
]



export const warrantySteps = [

    {
        // caption: 'Before Retail',
        // submenus: [
        //     {
        path: '/data-collection',
        caption: 'Data Collection',
        status: 'pending',
        redirect_to: 'warranty',
        queries: [{
            field: 'warranty.process',
            value: 'incomplete'
        },
        {
            field: 'warranty.status',
            value: 'pending'
        },
        {
            field: 'status',
            operator: '!=',
            value:'removed'
        },]
        //     }
        // ]
    },

    {
        // caption: 'After Retail',
        // submenus: [
        //     {
        path: '/login',
        caption: 'Login',
        status: 'Login',
        redirect_to: 'warranty-login',
        queries: [{
            field: 'warranty.process',
            value: 'incomplete'
        },
        {
            field: 'warranty.status',
            value: 'Login'
        },
        {
            field: 'status',
            operator: '!=',
            value:'removed'
        },]

        //     },
        // ]
    },


    // Data Collection Form
    {
        isForm: true,
        caption: 'Collection Form',
        path: '/warranty/:city/:id',
        post_status: 'Data Collected',
        fields: [

            {
                type: 'radio',
                options: ['Yes', 'No'],
                caption: 'Opting for Extended Warranty',
                field: 'type'
            },

            {
                type: 'input',
                caption: 'No of Years',
                field: 'years',
                condition: (values) => {
                    return values.type === 'Yes'
                }
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks'
            },
        ]
    },



    // Login Form
    {
        isForm: true,
        caption: 'Collection Form',
        path: '/warranty-login/:city/:id',
        post_status: 'Payment Pending',
        fields: [

            {
                type: 'upload',
                field: 'attachment',
                caption: 'Insurance '
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks'
            },
        ]
    },
]



