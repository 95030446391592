/**
 *
 *
 */

import Base from './base';

class Stocks extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'stocks';
    }

    get path() {
        return `stocks`;
    }

    get getName() {
        return `stocks`;
    }

    // getStockPoints = (city) => {
    //     var queries = [];

    //     return this.get(queries);
    // };
}

export default Stocks;
