/**
 * Generic List accepts an array of fields to update any resource
 */

import React, { useState, useRef, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Table, Space, Menu, message, DatePicker, Popover, Dropdown, Tag } from 'antd';

import { withRouter } from 'react-router-dom';

import { ExportReactCSV } from './ExportReactCSV';

import './bill-list.scss';

import FirebaseUtils from '../../../../utils/firebase.utils';
import { GetData } from '../../../../utils/http.utils';

import { ReloadOutlined, DownOutlined } from '@ant-design/icons';

import useDeviceDetect from '../../../../hooks/device-detect';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import Rupee from '../../../../components/rupee/rupee';

import moment from 'moment';

import { BillDetails, Departments, Purchases, } from '../../../../models';

import { billDetailSchema, ItemWiseSchema } from '../../../global-config';

const { RangePicker } = DatePicker;

const { Title, Text } = Typography;

/**
 * Generic List
 *
 * @param {*} param0
 */
function SalesReport({ match }) {
    const [range, setRange] = useState([moment().startOf('month'), moment().endOf('day')]);

    const [records, setRecords] = useState({ bills: [] });

    const [expRecord, setExptRecords] = useState();

    const { isMobile } = useDeviceDetect();

    const { user } = useContext(GlobalContext);

    const { params } = match;

    const { city } = params;

    const { category } = params;

    const { department } = params;

    let catName;

    if (category === 'basic') {
        catName = 'Vehicle';
    } else {
        catName = category;
    }

    // const city = user.locations[0];

    const [loading, setLoading] = useState(false);

    const [location, setLocation] = useState(user.locations[0]);

    const [view, setView] = useState(isMobile);

    const [selected, setSelected] = useState([]);

    const showbills = ['All', 'Not Cancelled', 'Cancelled'];

    const [billtype, setBilltype] = useState(showbills[0]);

    const [departments, setDepartments] = useState([]);

    //let isRequest = model === 'requests';

    let columns;

    let column;

    let queries = [
        {
            field: 'type',
            value: category,
        },

        // {
        //     field: 'cancellation_status',
        //     value: false,
        // },
        {
            field: 'transaction_type',
            operator: '==',
            value: 'sale',
        },
    ];

    const menus = showbills.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function handleMenuClick(e) {
        setBilltype(e.key);

        let update = [...queries];

        update = [
            ...queries,
            {
                field: 'invoice_date_millisecond',
                operator: '>=',
                value: moment(range[0]).startOf('day').valueOf(),
            },
            {
                field: 'invoice_date_millisecond',
                operator: '<=',
                value: moment(range[1]).endOf('day').valueOf(),
            },
            {
                field: 'department_id',
                operator: 'in',
                value: departments,
            },
        ];

        if (e.key === 'Cancelled') {
            update.push({
                field: 'cancellation_status',
                value: true,
            });
        } else if (e.key === 'Not Cancelled') {
            update.push({
                field: 'cancellation_status',
                value: false,
            });
        }

        loadData(update);
    }

    /**
     * columns to be shown for vehicle report
     */
    const billColumns = [
        {
            field: 'invoice_number',
            caption: 'Invoice No',
        },
        {
            caption: 'Invoice Date',
            render(record) {
                return moment(record.invoice_date_millisecond).format('DD/MM/YYYY');
            },
        },
        {
            field: 'bookingNo',
            caption: 'Booking No',
        },
        {
            field: 'customer_name',
            caption: 'Customer',
        },
        {
            field: 'customer_state',
            caption: 'State',
        },

        {
            field: 'gst_number',
            caption: 'GSTIN',
        },
        {
            field: 'attributes.model',
            caption: 'Model',
        },
        {
            field: 'attributes.variant',
            caption: 'Variant',
        },
        {
            field: 'attributes.vinNo',
            caption: 'VIN Number',
        },
        {
            field: 'pan_number',
            caption: 'PAN No.',
        },
        {
            field: 'taxable_amount',
            caption: 'Taxable Value',
        },
        {
            field: 'igst_amount',
            caption: 'IGST',
        },
        {
            field: 'cgst_amount',
            caption: 'CGST',
        },
        {
            field: 'sgst_amount',
            caption: 'SGST',
        },
        {
            field: 'cess_amount',
            caption: 'Cess',
            render(record) {
                return (
                    <div>
                        {record.cess_amount}
                        <br />
                        {record.cess_percent}%
                    </div>
                );
            },

        },

        {
            field: 'tcs_amount',
            caption: 'TCS',
        },

        {
            caption: 'HSN Code',
            render(record) {
                return (
                    <div> {record.hsn_code} </div>
                );
            },
        },

        {
            field: 'item_discount_amount',
            caption: 'Item (Dis)',
        },
        {
            field: 'net_amount',
            caption: 'Invoice Value',
        },
        {
            caption: '',
            render: (record) => {
                return <>{record.cancellation_status === true ? <Tag color="red"> Cancelled</Tag> : null}</>;
            },
        },

        {
            caption: '',
            render: (record) => {
                return (
                    <>
                        <Link to={`/bill/${record.id}`}> View</Link>
                    </>
                );
            },
        },
    ];
    /**
    * columns to be shown for vehicle report
    */
    const billColumn = [
        {
            field: 'invoice_number',
            caption: 'Invoice No',
        },
        {
            caption: 'Invoice Date',
            render(record) {
                return moment(record.invoice_date_millisecond).format('DD/MM/YYYY');
            },
        },
        {
            field: 'bookingNo',
            caption: 'Booking No',
        },
        {
            field: 'customer_name',
            caption: 'Customer',
        },
        {
            field: 'customer_state',
            caption: 'State',
        },

        {
            field: 'gst_number',
            caption: 'GSTIN',
        },
        {
            field: 'attributes.model',
            caption: 'Model',
        },
        {
            field: 'attributes.variant',
            caption: 'Variant',
        },
        {
            field: 'attributes.vinNo',
            caption: 'VIN Number',
        },
        {
            field: 'pan_number',
            caption: 'PAN No.',
        },
        {
            field: 'taxable_amount',
            caption: 'Taxable Value',
        },
        {
            field: 'igst_amount',
            caption: 'IGST',
        },
        {
            field: 'cgst_amount',
            caption: 'CGST',
        },
        {
            field: 'sgst_amount',
            caption: 'SGST',
        },
        {
            field: 'cess_amount',
            caption: 'Cess',

        },
        {
            field: 'cess_percent',
            caption: 'Cess percent',

        },
        {
            field: 'hsn_code',
            caption: 'HSN Code',

        },

        {
            field: 'tcs_amount',
            caption: 'TCS',
        },

        {
            field: 'item_discount_amount',
            caption: 'Item (Dis)',
        },

        {
            field: 'net_amount',
            caption: 'Invoice Value',
        },

    ];

    /**
     * columns to be shown for accessory/vas report
     *  */
    const accessorybillColumns = [
        {
            field: 'invoice_number',
            caption: 'Invoice No',
        },
        {
            caption: 'Invoice Date',
            render(record) {
                return moment(record.invoice_date_millisecond).format('DD/MM/YYYY');
            },
        },

        {
            field: 'customer_name',
            caption: 'Customer',
        },
        {
            field: 'customer_state',
            caption: 'State',
        },

        {
            field: 'taxable_amount',
            caption: 'Taxable Value',
        },
        {
            field: 'igst_amount',
            caption: 'IGST',
        },
        {
            field: 'cgst_amount',
            caption: 'CGST',
        },
        {
            field: 'sgst_amount',
            caption: 'SGST',
        },

        // {
        //     field: 'total_tax',
        //     caption: 'Tax',
        // },
        {
            field: 'item_discount_amount',
            caption: 'Item (Dis)',
        },
        {
            field: 'net_amount',
            caption: 'Invoice Value',
        },
        {
            caption: '',
            render: (record) => {
                return (
                    <>
                        <Link to={`/bill/${record.id}`}> View</Link>
                    </>
                );
            },
        },
    ];
    /**
       * columns to be shown for accessory/vas report
       *  */
    const accessorybillColumn = [
        {
            field: 'invoice_number',
            caption: 'Invoice No',
        },
        {
            caption: 'Invoice Date',
            render(record) {
                return moment(record.invoice_date_millisecond).format('DD/MM/YYYY');
            },
        },

        {
            field: 'customer_name',
            caption: 'Customer',
        },
        {
            field: 'customer_state',
            caption: 'State',
        },

        {
            field: 'taxable_amount',
            caption: 'Taxable Value',
        },
        {
            field: 'igst_amount',
            caption: 'IGST',
        },
        {
            field: 'cgst_amount',
            caption: 'CGST',
        },
        {
            field: 'sgst_amount',
            caption: 'SGST',
        },

        // {
        //     field: 'total_tax',
        //     caption: 'Tax',
        // },
        {
            field: 'item_discount_amount',
            caption: 'Item (Dis)',
        },
        {
            field: 'net_amount',
            caption: 'Invoice Value',
        },

    ];
    if (department === 'sales') {
        accessorybillColumns.splice(
            4,
            0,
            {
                field: 'bookingNo',
                caption: 'Booking No',
            },

            {
                field: 'attributes.model',
                caption: 'Model',
            },
            {
                field: 'attributes.variant',
                caption: 'Variant',
            },
            {
                field: 'attributes.vinNo',
                caption: 'VIN Number',
            }
        );
    }

    if (category === 'basic') {
        columns = billColumns;
    } else {
        columns = accessorybillColumns;
    }


    if (category === 'basic') {
        column = billColumn;
    } else {
        column = accessorybillColumn;
    }

    const cols = [
        ...[
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            },
        ],
        ...columns.map((entry) => {
            if (entry.sort) {
                return {
                    render: (record) => {
                        if (entry.render) {
                            return entry.render(record);
                        } else {
                            return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        }
                    },
                    title: entry.caption,
                    key: entry.field,
                    sorter: (a, b) => entry.sort(a, b),
                    sortDirections: ['ascend', 'descend', 'ascend'],
                };
            } else {
                return {
                    render: (record) => {
                        if (entry.render) {
                            return entry.render(record);
                            // return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        } else {
                            return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        }
                        // return record[entry.field]
                    },
                    title: entry.caption,
                    key: entry.field,
                };
            }
        }),
    ];

    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    useEffect(() => {
        getDepartments().then((departments) => {
            queries.push({
                field: 'department_id',
                operator: 'in',
                value: departments,
            });

            // Update
            let update = [...queries];

            // Update
            update = [
                ...queries,
                {
                    field: 'invoice_date_millisecond',
                    operator: '>=',
                    value: moment(range[0]).startOf('day').valueOf(),
                },
                {
                    field: 'invoice_date_millisecond',
                    operator: '<=',
                    value: moment(range[1]).endOf('day').valueOf(),
                },
            ];

            loadData(update);
        });
    }, []);

    /**
     * 
     * @returns 
     */
    async function getDepartments() {
        var deptIDs = [];

        var query = [
            {
                field: 'id',
                operator: 'in',
                value: user.department_ids,
            },
        ];
        return await Departments.get(query)
            .then((result) => {
                result.departments.map((dep) => {
                    deptIDs.push(dep.id);
                });
                setDepartments(deptIDs);

                return deptIDs;

            })

    }



    function getRecords(queries) {
        FirebaseUtils.getListing(city, queries, 'bills').then((result) => {

            let content = result;

            return Promise.all(
                content.bills.map((bill) => {
                    var query = [
                        {
                            field: 'bill_id',
                            value: bill.id,
                        },
                    ];

                    let cess_percent;
                    let hsn_code;

                    return BillDetails.get(query)
                        .then((res) => {
                            if (res && res['bill-details'] && res['bill-details'][0] && res['bill-details'][0].cess_percent) {
                                cess_percent = res['bill-details'][0].cess_percent;
                            }

                            var query = [
                                {
                                    field: 'VinNo',
                                    value: bill.attributes.vinNo,
                                },
                            ];

                            Purchases.get(query)
                                .then((res) => {

                                    if (res && res['purchase']) {
                                        hsn_code = res['purchase']['HSN Code'];
                                    }

                                })

                        })
                        .then(() => {
                            bill = {

                                ...bill,
                                hsn_code: hsn_code,
                                cess_percent: cess_percent,
                            };

                            return bill;
                        });
                })
            ).then(async (bill) => {

                bill = await Promise.all(
                bill.map(async (bills) => {
                    // Once the tcsExempted is true , we have to tcs_amount is zero
                    if (bills.tcsExempted) {
                      return { ...bills, tcs_amount: 0 }
                    } else {
                       return { ...bills, tcs_amount: bills.tcs_amount }
                    }
                }))

                setRecords({ bills: [...bill] });

                var exporData = getExportData(bill, column);
                setExptRecords(exporData);

                setLoading(false);
            });
        });
    }

    function getExportData(data, columns) {

        return data.map((record, index) => {
            var content = {
                index: index + 1,
            };

            columns.forEach((column) => {
                if (column.field) {
                    content[column.caption] = column.field.split('.').reduce((acc, part) => acc && acc[part], record);

                    // content[column.caption] = record[column.field];
                } else {
                    content[column.caption] = column.render(record);
                }

                // content[column.field] = record[column.field];
            });

            return content;
        });
    }

    const loadData = (queries) => {
        setLoading(true);

        setSelected([]);

        getRecords(queries);
    };

    // function refresh() {
    //     if (billtype === 'Cancelled Bills') {
    //         queries.push({
    //             field: 'cancellation_status',
    //             value: true,
    //         });
    //     } else if (billtype === 'Bills') {
    //         queries.push({
    //             field: 'cancellation_status',
    //             value: false,
    //         });
    //     }
    //     loadData(queries);
    // }

    const onSelectChange = (keys, rows) => {
        console.log(keys, rows);
        setSelected(keys);
    };

    const rowSelection = {
        selected,
        onChange: onSelectChange,
    };

    function updateFilter(range) {
        let update = [
            ...queries,
            {
                field: 'invoice_date_millisecond',
                operator: '>=',
                value: moment(range[0]).startOf('day').valueOf(),
            },
            {
                field: 'invoice_date_millisecond',
                operator: '<=',
                value: moment(range[1]).endOf('day').valueOf(),
            },
            {
                field: 'department_id',
                operator: 'in',
                value: departments,
            },
        ];

        if (billtype === 'Cancelled') {
            update.push({
                field: 'cancellation_status',
                value: true,
            });
        } else if (billtype === 'Not Cancelled') {
            update.push({
                field: 'cancellation_status',
                value: false,
            });
        }

        setRange(range);

        loadData(update);
    }

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>
                        {city} {catName} Bills
                    </Title>
                </div>

                <div className="table-actions">
                    {/* Table Filters */}
                    <div className="table-filters">
                        <Dropdown overlay={menu}>
                            <Button size={'small'}>
                                {billtype} <DownOutlined />
                            </Button>
                        </Dropdown>

                        <Space direction="vertical" size={12}>
                            <>
                                <RangePicker allowClear={false} inputReadOnly format={'DD/MM/YYYY'} value={range} onChange={updateFilter} />
                            </>
                        </Space>
                    </div>
                    {/* Table Filters Ends */}

                    {/* <Button onClick={refresh} type="secondary" size={'small'}>
                        <ReloadOutlined />
                    </Button> */}

                    {expRecord && <ExportReactCSV csvData={expRecord} />}
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <Table
                        className="table-exportable"
                        loading={loading}
                        size="small"
                        // scroll={{ x: true, y: 750 }}

                        // scroll={{ x: true, y: 750 }}
                        rowKey={(record) => record.id}
                        // rowKey={record => record[model + 'Id']}
                        dataSource={records['bills']}
                        columns={cols}
                        // onChange={handleTableChange}
                        pagination={false}
                        summary={(pageData) => {
                            let cgst_total = 0,
                                sgst_total = 0,
                                igst_total = 0,
                                tcs_total = 0;

                            var cess_total = 0;

                            pageData.forEach((entry) => {
                                cgst_total += entry.cgst_amount;

                                sgst_total += entry.sgst_amount;

                                igst_total += entry.igst_amount;

                                cess_total += entry.cess_amount;

                                tcs_total += entry.tcs_amount;
                            });
                            return (
                                <>
                                    {user.role === 'accounts' ? (
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            {category == 'basic' ? (
                                                <>
                                                    <Table.Summary.Cell></Table.Summary.Cell>

                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                </>
                                            ) : null}

                                            <Table.Summary.Cell>
                                                <b>Total</b>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Text>
                                                    <strong>
                                                        <Popover content={`Total IGST`}>
                                                            <Text>
                                                                {' '}
                                                                <Rupee value={igst_total}></Rupee>
                                                            </Text>
                                                        </Popover>
                                                    </strong>
                                                </Text>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Text>
                                                    <strong>
                                                        <Popover content={`Total CGST`}>
                                                            <Text>
                                                                {' '}
                                                                <Rupee value={cgst_total}></Rupee>
                                                            </Text>
                                                        </Popover>
                                                    </strong>
                                                </Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <Text>
                                                    <strong>
                                                        <Popover content={`Total SGST`}>
                                                            <Text>
                                                                {' '}
                                                                <Rupee value={sgst_total}></Rupee>
                                                            </Text>
                                                        </Popover>
                                                    </strong>
                                                </Text>
                                            </Table.Summary.Cell>

                                            {category === 'basic' ? (
                                                <>
                                                    <Table.Summary.Cell>
                                                        <Text>
                                                            <strong>
                                                                <Popover content={`Total CESS`}>
                                                                    <Text>
                                                                        {' '}
                                                                        <Rupee value={cess_total}></Rupee>
                                                                    </Text>
                                                                </Popover>
                                                            </strong>
                                                        </Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell>
                                                        <Text>
                                                            <strong>
                                                                <Popover content={`Total TCS`}>
                                                                    <Text>
                                                                        {' '}
                                                                        <Rupee value={tcs_total}></Rupee>
                                                                    </Text>
                                                                </Popover>
                                                            </strong>
                                                        </Text>
                                                    </Table.Summary.Cell>
                                                </>
                                            ) : null}
                                        </Table.Summary.Row>
                                    ) : null}
                                </>
                            );
                        }}
                    ></Table>
                </>
            )}
        </section>
    );
}

export default withRouter(SalesReport);
