import Base from './base';

class Item extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'items';
    }

    get path() {
        return `items`;
    }

    get getName() {
        return `items`;
    }
}

export default Item;
