import React, { useState, useContext } from "react"

import { withRouter } from 'react-router-dom'

import { GlobalContext } from './../../Store';

import { Typography } from 'antd';

var logo = require('./../../assets/images/kia-logo.png');

const { Title } = Typography;

function AboutComponent({ history }) {

    const { user, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    return (
        <section className="about-component">
            <div className="about-section">

                <div className="brand-logo">
                    <img className="logo-welcome" src={logo} alt="Logo" />
                </div>

                <div className="content-section" style={{ margin: '50px' }}>

                    <Title level={3}>
                        Refunds and Cancellation
                    </Title>

                    <p>
                        This section is not applicable to this application at the moment.
                    </p>

                </div>




            </div>
        </section>
    );
}

export default withRouter(AboutComponent)