
import React, { useState, useEffect, useContext } from "react";

import "./allocation-requests.scss";

import { GlobalContext } from './../../../../Store';

import { Row, Tabs, Button, Tag } from "antd";

import PlaceHolder from './../../../../components/ui_elements/PlaceHolder';


import FirebaseUtils from './../../../../utils/firebase.utils';
import RequestCard from "./components/request-card/request-card";

const { TabPane } = Tabs;



export default function AllocationRequests(props) {

    const { user } = useContext(GlobalContext);

    const [loader, setLoader] = useState(true);


    const [result, setResults] = useState([]);

    useEffect(() => {

        // console.log(user);

        loadData();


    }, [])


    function loadData() {
        getRequests(user.locations[0]);
    }

    function getRequests(location) {

        setLoader(true);

        console.log(user);

        FirebaseUtils.getMyRequests(location, user.dms['Consultant Name']).then((result) => {

            console.log(result);

            setLoader(false);

            let b = Object.keys(result.requests).map((request) => {
                return { ...result.requests[request], requestId: request }
            });

            setResults(b);

        });
    }

    return (
        <div className="allocation-requests">

            <Tabs defaultActiveKey="1" onChange={() => { }}>
                <TabPane tab="Pending Requests" key="1">

                    <div className="list-header">

                        <p className="page-desc">
                            {loader ? 'Loading data' : `Found ${result.length} results`}
                        </p>

                        <div>
                            <Button onClick={loadData} type="secondary" size={'small'}>Refresh</Button>
                        </div>

                    </div>

                    <Row className="report-listing-wrapper">
                        {loader ? (
                            <PlaceHolder type="listing" />
                        ) : (
                                <>
                                    {
                                        result.map((report, index) =>

                                            <RequestCard request={report} index={index} key={index} />
                                        )
                                    }
                                </>
                            )}
                    </Row>

                </TabPane>

            </Tabs>

        </div>
    );
}
