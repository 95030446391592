/**
 *@author Nihala Dilshi
 * 
 */

import React, { useState, useEffect, useContext } from 'react';

import { withRouter } from 'react-router-dom';

import { StatusMasters } from '../../../../models';

import { GlobalContext } from '../../../../Store';

import { Select, Radio, Form } from 'antd';
import './follow_up_status.scss'

const { Option } = Select;

/**
 * Component for selecting follow up status
 *
 * @param {*} param0
 * @returns
 */
function FollowUpStatus({ callback, onChange, content,form }) {

    const [status, setStatus] = useState([]);
    const [selectedOption, setSelectedOption] = useState(content.enquiry_status || '');
    const [description, setDescription] = useState([]);


    const { user } = useContext(GlobalContext);

    useEffect(() => {
        getStatus();
    }, []);
    useEffect(async () => {
        const result = await getDescription()
        setDescription(result)
    }, [selectedOption]);

    /**
     *
     *   Load the follow up status
     */
    async function getDescription() {
        if (selectedOption) {
            let queries = [
                {
                    field: 'category',
                    value: selectedOption,
                },
                {
                    field: 'mode',
                    value: 'follow_up_status',
                },
            ];

            return StatusMasters.get(queries).then((res) => {
                let a = res.status_masters || []
                return (a)


            })
        }
    }
    async function getStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'enquiry_status',
            },
        ];

        const result = await StatusMasters.get(queries);
        setStatus(result.status_masters || []);
    }

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
        form.setFieldsValue({ description: null });
    };

    /**
     * On change of the select box ,
     * append object along with id
     */
    function handleChange(id) {
        onChange(id);
        callback(id, selectedOption);
    }


    return (
        <div className="stock-pointer">
            <Radio.Group onChange={handleRadioChange} value={selectedOption}>
                {status.map((option, key) => (
                    <Radio key={key} value={option.name}>
                        {option.name}
                    </Radio>
                ))}
            </Radio.Group>

            {selectedOption && description ? (
                <>
                    {/* <StatusMaster category={selectedOption} /> */}
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: 'Please select a Follow up status!' }]}
                    >
                        <Select style={{ width: 300 }} onChange={handleChange}>
                            {description.map((option, key) => (
                                <Option key={key} value={option.description}>
                                    {option.description}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </>
            ) : null}
        </div>
    );
};


export default withRouter(FollowUpStatus);
