import React, { useEffect, useContext } from 'react';

import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

// import ApprovalList from './components/approval-list/approval-list';
// import ApprovalForm from './components/approval-form/approval-form';

import Dashboard from '../common/components/dashboard/dashboard';
import StockDashboard from '../common/components/stock-dashboard/stock-dashboard';

import MatchingDashboard from '../common/components/matching-dashboard/matching-dashboard';

import AccountsOverview from './../common/resources/accounts-overview/accounts-overview';
import BookingOverview from './../common/resources/booking-overview/booking-overview';

import DailyOverview from './../common/resources/daily-overview/daily-overview';

import VerifyPayment from './../common/components/verify-payment/verify-payment';
import ChangePassword from './../common/components/change-password/change-password';

import ManageUsers from '../common/components/manage-users/manage-users';

import ManageBookings from '../common/components/manage-bookings/manage-bookings';
import ManageRequests from '../common/components/manage-requests/manage-requests';

// import CreateUser from '../create-user/create-user';

// import Stock from './components/stock/stock';

import NoticeBoard from '../common/components/notice-board/notice-board';

import StockDetail from '../common/components/stock-detail/stock-detail';

import CustomerReceipts from '../common/components/customer-receipts/customer-receipts';
import CustomerReceiptDetail from '../common/components/customer-receipts/customer-receipts-detail';
import CustomerReceiptAdd from '../common/components/customer-receipts/customer-receipts-add';

// import StockDashboard from './components/stock-dashboard/stock-dashboard';
// import GenericListing from '../../components/listing/listing';
import GenericList from './../common/resources/generic/generic-list/generic-list';

import BookingCard from '../../components/booking-card/booking-card';

// import IFStatus from './components/if-status/if-status';
import IFStatus from './../common/components/if-status/if-status';

import ActualStock from '../common/components/actual-stock/actual-stock';

import PreferenceDetail from '../common/components/preference-detail/preference-detail';
import RequestDetail from '../common/components/request-detail/request-detail';

import AllocationList from '../common/components/dms-allocation/dms-allocation';
// import RequestStatus from '../common/components/request-status/request-status';

// Existing Allocations
import ExistingAllocation from '../common/components/existing-allocations/existing-allocations';

import HighPriority from '../common/components/high-priority/high-priority';
import HighPriorityDetail from '../common/components/high-priority-detail/high-priority-detail';

import ManagePayments from './../common/components/manage-payments/manage-payments';
import ReconcileSummary from './../common/components/reconcile-summary/reconcile-summary';

import BookingDetail from '../common/components/booking-detail/booking-detail';

import ModuleRoutes from './../common/resources/modules-routes/module-routes';

import { bookingColumns, collectionColumns, requestColumns, stockSchema, stockColumns, pricingSchema, userSchema } from './super-config';

import { bookingSchema, saleSchema, partsSchema, partsColumns, billingRequestSchema, deliveryRequestSchema } from './../global-config';

import Profile from './../common/resources/profile/profile';

import './super-landing.scss';

import { GlobalContext } from '../../Store';

//import EditUser from "../edit-user/edit-user";

export default function SuperLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    const modules = [
        {
            caption: 'Daily Overview',
            path: '/daily-overview',
        },

        {
            caption: 'Account Overview',
            path: '/accounts-overview',
        },

        {
            caption: 'Booking Overview',
            path: '/booking-overview',
        },

        {
            caption: 'Reports',
            submenus: [
                {
                    caption: 'Retail Requests',
                    submenus: user.locations.map((location) => {
                        return {
                            path: `/${location}/retail-review`,
                            caption: `${location}`,
                        };
                    }),
                },
                {
                    caption: 'Delivery Requests',
                    submenus: user.locations.map((location) => {
                        return {
                            path: `/${location}/delivery-review`,
                            caption: `${location}`,
                        };
                    }),
                },
            ],
        },

        {
            caption: 'Reconcilation',
            submenus: [
                {
                    path: '/pending-summary',
                    caption: 'Pending to Reconcile',
                },

                // {
                //     path: '/verify-payment',
                //     caption: 'Verify Receipts',
                // },

                {
                    path: '/bank-receipts',
                    caption: 'Bank Statement',
                },

                {
                    path: '/customer-receipts',
                    caption: 'Customer Receipts',
                },
            ],
        },

        // {
        //   caption: "Reports",
        //   submenus: user.locations.map((location) => {

        //     return {
        //       path: `/${location}/collections`,
        //       caption: `${location}`
        //     }

        //   })
        // },

        {
            path: '/admin-stock',
            caption: 'Allocations',
        },

        {
            caption: 'Requests',
            submenus: user.locations.map((location) => {
                return {
                    path: `/${location}/requests`,
                    caption: `${location}`,
                };
            }),
        },

        {
            caption: 'Bookings',
            submenus: user.locations.map((location) => {
                return {
                    path: `/bookings`,
                    caption: `${location}`,
                };
            }),
        },

        {
            caption: 'Sales',
            submenus: user.locations.map((location) => {
                return {
                    path: `/${location}/sales`,
                    caption: `${location}`,
                };
            }),
        },

        {
            caption: 'Vehicles',
            submenus: user.locations.map((location) => {
                return {
                    path: `/${location}/vehicles`,
                    caption: `${location}`,
                };
            }),
        },

        {
            caption: 'Accessories',
            submenus: user.locations.map((location) => {
                return {
                    path: `/${location}/accessories`,
                    caption: `${location}`,
                };
            }),
        },

        {
            caption: 'Stock',
            submenus: [
                {
                    path: '/actual-stock',
                    caption: 'DMS Stock',
                },
            ],
        },

        {
            caption: 'Summary',
            submenus: [
                {
                    path: '/matching-summary',
                    caption: 'Matching Summary',
                },

                {
                    path: '/if-status',
                    caption: 'If Status',
                },
            ],
        },

        // {
        //     caption: 'High Priority',
        //     submenus: [
        //         {
        //             path: '/high-priority',
        //             caption: 'High Priority Requests',
        //         },
        //     ],
        // },

        {
            caption: 'Approvals',
            submenus: [
                {
                    path: '/approvals',
                    caption: 'Billing Requests',
                },
                {
                    path: '/allocation-list',
                    caption: 'DMS Allocation Requests',
                },
            ],
        },

        {
            caption: 'Settings',
            submenus: [
                {
                    path: '/common/users',
                    caption: 'Manage Users',
                },
                {
                    path: '/stock',
                    caption: 'Stock',
                },
                // {
                //   path: '/edit-user',
                //   caption: 'Edit User'
                // },
            ],
        },

        {
            caption: 'Manage',
            submenus: [
                {
                    path: '/central/pricing/',
                    caption: 'Manage Pricing',
                },

                {
                    path: '/existing-allocation',
                    caption: 'Migration Support',
                },

                {
                    path: '/manage-stock',
                    caption: 'Manage Stock',
                },
                {
                    path: '/manage-receipts',
                    caption: 'Manage Receipts',
                },
            ],
        },
        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route exact path={'/'} component={Dashboard}></Route>
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    {/* <PrivateRoute exact path="/stock" component={Stock} userRole={'admin'} /> */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    <PrivateRoute exact path="/actual-stock" component={ActualStock} userRole={'admin'} />

                    <PrivateRoute path="/matching-summary" component={MatchingDashboard} userRole={'admin'} />

                    <Route path="/admin-stock" component={StockDashboard} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-requests" component={ManageRequests} userRole={'admin'} />

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/if-status" component={IFStatus} userRole={'admin'} />

                    {/* <PrivateRoute exact path="/edit-user" component={EditUser} userRole={'admin'} /> */}

                    <PrivateRoute exact path="/allocation-list" component={AllocationList} userRole={'admin'} />

                    {/* <PrivateRoute exact path="/:city/allocation-status/:id" component={RequestStatus} userRole={'admin'} /> */}

                    <PrivateRoute exact path="/existing-allocation" component={ExistingAllocation} userRole={'admin'} />

                    {/* High Priority */}
                    <PrivateRoute exact path="/high-priority" component={HighPriority} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/high-priority/:id" component={HighPriorityDetail} userRole={'admin'} />
                    {/* High Priority Ends */}

                    {/* Accounts Route */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/retail-review`}
                        component={(...routeParams) => (
                            <GenericList schema={billingRequestSchema} queries={billingRequestSchema.billingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Delivered Requests */}
                    <Route
                        exact
                        path={`/:city/delivery-review`}
                        component={(...routeParams) => (
                            <GenericList schema={deliveryRequestSchema} queries={deliveryRequestSchema.deliveryRequests} {...routeParams} />
                        )}
                    />
                    {/* Delivered Requests Ends */}

                    <PrivateRoute exact path="/bank-receipts" component={ManagePayments} userRole={'admin'} />

                    <PrivateRoute exact path="/pending-summary" component={ReconcileSummary} userRole={'admin'} />

                    <PrivateRoute exact path="/verify-payment" component={VerifyPayment} userRole={'admin'} />

                    <PrivateRoute exact path="/accounts-overview" component={AccountsOverview} userRole={'admin'} />
                    {/* Accounts Route Ends */}

                    {/* Customer Receipts Module */}
                    <PrivateRoute exact path="/customer-receipts" component={CustomerReceipts} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/customer-receipts/add" component={CustomerReceiptAdd} userRole={'admin'} />
                    {/* Customer Receipts Module Ends */}

                    <Route
                        path="/:city/requests"
                        component={() => (
                            <ModuleRoutes
                                disableUpload={true}
                                disableAdd={true}
                                model="requests"
                                isDirect={true}
                                columns={requestColumns}
                                collection="requests"
                            />
                        )}
                    />

                    <Route
                        path="/bookings"
                        component={() => <ModuleRoutes model="bookings" schema={bookingSchema} columns={bookingColumns} collection="bookings" />}
                    />

                    <Route
                        path="/:city/vehicles"
                        component={() => <ModuleRoutes model="vehicles" schema={stockSchema} columns={stockColumns} collection="vehicles" />}
                    />

                    <Route
                        path="/:city/sales"
                        component={() => <ModuleRoutes model="sales" schema={saleSchema} columns={saleSchema.columns} collection="sales" />}
                    />

                    <Route
                        path="/:city/collections"
                        component={() => (
                            <GenericList
                                isDirect={true}
                                queries={[
                                    {
                                        field: 'amount',
                                        value: 25000,
                                    },
                                ]}
                                model="customer_entries"
                                columns={collectionColumns}
                                collection="collections"
                            />
                        )}
                    />

                    <Route
                        path="/central/pricing"
                        component={() => <ModuleRoutes model="pricing" schema={pricingSchema} columns={pricingSchema.columns} collection="list" />}
                    />

                    <Route
                        path="/common/users"
                        component={() => <ModuleRoutes model="users" schema={userSchema} columns={userSchema.columns} collection="users" />}
                    />

                    {/* <Route path="/users" component={() => <ManageUsers />} />   */}

                    <Route
                        path="/:city/accessories"
                        component={() => <ModuleRoutes model="accessories" schema={partsSchema} columns={partsColumns} collection="parts" />}
                    />

                    <PrivateRoute exact path="/booking-overview" component={BookingOverview} userRole={'admin'} />

                    <PrivateRoute exact path="/daily-overview" component={DailyOverview} userRole={'admin'} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
