/**
 * Component to get complete detail of a vehicle
 */

import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Typography, Button, Row, Tabs, Timeline, Select, Divider, Tag, Skeleton, List, Card, Modal, Form, Input, Col, message } from 'antd';

import { useLocation, useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';

import { ArrowRightOutlined } from '@ant-design/icons';

import './vehicle-detail.scss';

import FirebaseUtils from '../../utils/firebase.utils';

import moment from 'moment';

import { GlobalContext } from '../../Store';

import { Vehicles, Requests, Purchases, Bills, Transfers, Sales, StockTransfers, StockTransactions } from './../../models/';

import StockTransferRecord from '../../modules/common/resources/stock-transfer/stock-transfer';

import StockTransferRequestCreator from './stock-transfer-request-creator';

import GatePassModal from './gate-pass';
import AdjustStock from './adjust-stock';
import StockTransferDetails from '../../modules/common/resources/stock-transfer-details/stock-transfer-details';
import VehicleTracker from '../../modules/common/components/vehicle-tracker/vehicle-tracker';
import { dealerCodeKey } from '../../modules/global-config';

var images = {
    SELTOS: require('./../../assets/images/cars/seltos.png'),
    SONET: require('./../../assets/images/cars/sonet.png'),
    CARNIVAL: require('./../../assets/images/cars/carnival.png'),
    CARENS: require('./../../assets/images/cars/carens.png'),
    EV6:require('./../../assets/images/cars/ev6.png')

};

const { Title } = Typography;

const { Option } = Select;

const { TabPane } = Tabs;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function VehicleDetail() {
    const params = useParams();

    const [transfers, setTransfers] = useState([]);

    const [loading, setLoading] = useState(false);

    const [btnloading, setBtnLoading] = useState(false);

    const [vehicle, setVehicle] = useState({});

    const [request, setRequest] = useState({});

    const [customer, setCustomer] = useState({});

    const [purchase, setPurchase] = useState({});

    const [stockTransfer, setStockTransfer] = useState([]);

    const [result, setResult] = useState([]);

    const [stockTransaction, setStockTransaction] = useState([]);

    const [transferenable, setTransferEnable] = useState(true);

    const [billid, setBillId] = useState({});

    const [visible, setVisible] = useState(false);

    const [sale, setSale] = useState({});

    const [bill, setBill] = useState({});

    const [location, setLocation] = useState([]);

    const [view, setView] = useState(false);

    const [vehicleTracking, setVehicleTracking] = useState(false);

    const [vehicleLocation, setVehicleLocation] = useState();



    const [activeStockTransfer, setActiveStockTransfer] = useState({})

    useEffect(() => {
        setLoading(true);

        loadPageData();
    }, []);

    const openModal = () => {
        setView(true);
    };

    const { user } = useContext(GlobalContext);

    //Location array for selecting the location to where the vehilce is to be transferred

    let locations = ['kozhikode', 'kannur', 'malappuram','kondotty','vadakara','tirur','kasargod'];

    var cityCode = dealerCodeKey

    /**
     * Load page data, get vehicles data using params id,
     * get request data if the vehicle is allocated
     * get stock transfer records
     * get Stock transaction records
     *
     * @returns
     */
    function loadPageData() {
        return Vehicles.getRecord(params.id).then((result) => {
            var res = result;

            if (result && result.allocated && result.allocated.requestId) getRequest(result.allocated.requestId);

            setVehicle(result);
            //Get Stock Transfer details of the vehilce
            getStockTranfer();
            //Get stock Transaction details of the vehicle
            getStockTransaction();
            //Get location of the vehilce so that the location can be filtered from location array
            getLocation(res);

            //The vehicle cannot be up for stock transfer request if the vehilce is billed
            if (result.update === 'remove') {
                setTransferEnable(false);
            }

            //Get Purchase details of the vehilce (these are dms records)
            return getPurchase(result).then(() => {
                return getTransfers().then(() => {
                    var queries = [
                        {
                            field: 'attributes.vinNo',
                            value: params.id,
                        },
                        {
                            field: 'type',
                            value: 'basic',
                        },
                    ];

                    //Get Bill details of the vehilce
                    return Bills.get(queries).then((result) => {
                        if (result && result.bills[0] && result.bills[0].id) setBillId(result.bills[0].id);
                        if (result.bills[0]) {
                            result.bills.map((bill) => {
                                //If Invoice Number is present
                                if (bill.invoice_number && bill.cancellation_status !== true) setBill(bill);
                                setLoading(false);
                                return bill;
                            });
                        } else setLoading(false);
                    });
                });
            });
        });
    }

    /**
     * Get records of surge stock transfers of the vehicle.
     * These stock transfer records are filtered to find any of the stock transfer request is cancelled
     * All the records are sorted descending with respect to the created_time
     * @returns
     */

    function getStockTranfer() {
        //Consider only active stock transfers
        var queries = [
            {
                field: 'vinNo',
                operator: '==',
                value: params.id,
            },

        ];
        // ordering  using transaction_date
        var config = {
            orderBy: 'transaction_date',
            // limit: 1,
            order: 'desc',
        };
        return StockTransfers.get(queries, config).then(async (result) => {
            var stock = [];

            // Transfer record created on delivery not to be included
            stock = result.stock_transfers.filter((transfer) => transfer.mode != "delivery")

            for (var i = 0; i < stock.length; i++) {
                for (var j = 0; j < stock.length; j++) {
                    var temp;

                    if (stock[i].created_time < stock[j].created_time) {
                        temp = stock[i];
                        stock[i] = stock[j];
                        stock[j] = temp;
                    }
                }
            }

            // For each stock transfer we need to get details yard and other details
            return Promise.all(stock.map(async (obj) => {
                var result = await StockTransfers.getRecordStock(obj.id)
                return result;

            })).then((result) => {
                setStockTransfer(result);

                //Get only the active stockTransfers
                var activeTransfer = result.filter((ele) => ele.active === true);


                // Get the last created active record

                for (var i = 0; i < activeTransfer.length; i++) {
                    for (var j = i + 1; j < activeTransfer.length; j++) {
                        var temp;

                        if (activeTransfer[i].created_time < activeTransfer[j].created_time) {
                            temp = activeTransfer[i];
                            activeTransfer[i] = activeTransfer[j];
                            activeTransfer[j] = temp;
                        }
                    }
                }


                console.log(activeTransfer[0])

                setActiveStockTransfer(activeTransfer[0])
            })

        });
    }

    /**
     * Get Stock Transaction records of the vehicle
     * Here the record with transaction code 20 or 70 is taken, as these codes implies that is a record for stock transfer
     * All those records are sorted in descending order with respect to created_at
     * @returns
     */

    function getStockTransaction() {
        var queries = [
            {
                field: 'item_identity_number',
                value: params.id,
            },
        ];

        return StockTransactions.get(queries).then(async (result) => {
            var stock = [];
            stock = result.stock_transactions.map((ele) => {
                if (ele.transaction_code === 5) {
                    ele = {
                        ...ele,
                        description: 'Bill Cancelled',
                    };
                    return ele;
                } else if (ele.transaction_code === 20) {
                    ele = {
                        ...ele,
                        description: 'Inward Stock Transfer',
                    };
                    return ele;
                } else if (ele.transaction_code === 70) {
                    ele = {
                        ...ele,
                        description: 'Outward Stock Transfer',
                    };
                    return ele;
                } else if (ele.transaction_code === 60) {
                    ele = {
                        ...ele,
                        description: 'Billed',
                    };
                    return ele;
                } else {
                    ele = {
                        ...ele,
                        // description:'Bill Cancelled'
                    };
                    return ele;
                }
            });

            for (var i = 0; i < stock.length; i++) {
                for (var j = 0; j < stock.length; j++) {
                    var temp;

                    if (stock[i].transaction_date < stock[j].transaction_date) {
                        temp = stock[i];
                        stock[i] = stock[j];
                        stock[j] = temp;
                    }
                }
            }

            setStockTransaction(stock);
        });
    }

    /**
     * Get all the purchase (dms records)
     *
     * @returns
     */
    function getPurchase() {
        var queries = [
            {
                field: 'VinNo',
                value: params.id,
            },
        ];

        return Purchases.get(queries).then((result) => {
            // console.log(result);

            if (result.purchase.length) {
                setPurchase(result.purchase[0]);
            }
        });
    }

    /**
     * Here the location of the vehilce is filtered from the location array , so vehilce location would be removed from select for stock transfer
     * @param {*} result
     */

    function getLocation(result) {
        var vehicle_location = cityCode[result.dealerCode];

        locations = locations.filter((ele) => ele !== vehicle_location);

        setLocation(locations);
    }

    /**
     * If the vehilce is allocated and request id is present, request details are taken.
     *
     * @param {*} id
     * @returns
     */

    function getRequest(id) {
        return Requests.getRecord(id).then((result) => {

            setRequest(result);
            setCustomer(result.customer);
            getSales(result.allocation.vinNo);
        });
    }

    /**
     * Get sales records of the vehicle(this is dms records)
     * @param {*} id
     */

    function getSales(id) {
        var queries = [
            {
                field: 'Vin No',
                value: id,
            },
        ];

        Sales.get(queries).then((res) => {
            // console.log(res.sales[0]);
            setSale(res.sales[0]);
        });
    }

    /**
     * Get all dms stock transfer records. These are interstock transfer records
     * @returns
     */
    function getTransfers() {
        var q = [
            {
                field: 'VIN',
                value: params.id,
            },
        ];

        return Transfers.get(q).then((result) => {
            // console.log(result);

            setTransfers(result['inter-stock-transfers']);
        });
    }

    //Set the status of the vehicle (reatiled, allocated or free stock)
    var status = null;
    if (vehicle.update === 'remove') {
        status = 'Retailed';
    } else if (vehicle.update === 'new') {
        if (vehicle.allocated) {
            status = 'Allocated';
        } else status = 'Free Stock';
    }
    console.log(stockTransfer);

    return (
        <div className="vehicle-detail">
            <div className="page-header">
                <div>
                    <Title level={4}>{params.id}</Title>

                    <p className="page-desc">{loading ? '. Loading data' : ``}</p>
                </div>

                <div>
                    <Button onClick={loadPageData} type="secondary" size={'small'}>
                        Refresh
                    </Button>
                </div>
            </div>

            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="page-content">
                        {/* Vehicle Card */}
                        <div className="vehicle-thumbnail">
                            <div className="thumbnail">
                                <div className="vehicle-image-wrapper">
                                    <img src={images[vehicle['Model']]} alt="vehicle-img" />
                                </div>

                                <Title level={5}>
                                    {vehicle['Variant']} <i>{vehicle['Exterior Color Name']}</i>
                                    <p style={{ color: 'orange' }}> {status}</p>
                                </Title>
                                
                                {/* vehicle's location tracking by tracking number */}
                                <div style={{marginBlockEnd:'10px'}} >
                                    {vehicle.tracking_number ? (
                                        <VehicleTracker record={vehicle} />
                                    ) : null}
                                </div>
                                <div gutter={0} className="detail-wrapper">
                                    <Tag color="purple">{vehicle['dealerCode']}</Tag>
                                </div>
                                {/** StockTrasferRequest creator componet Start */}
                                {/* {transferenable ? ( */}
                                <div>
                                    {vehicle.id ? (
                                        <StockTransferRequestCreator
                                            Callback={(values) => {
                                                if (values) loadPageData();
                                            }}
                                            user={user}
                                            params={params.id}
                                            vehicle={vehicle}
                                            caption={'Create Stock Transfer Request'}
                                            required={true}
                                            stockTransfer={activeStockTransfer}
                                        ></StockTransferRequestCreator>
                                    ) : null}
                                </div>

                                <div className="adjust-stock">
                                    {/** Allow only if vehicle is not billed and there is no active stock transfers */}

                                    {vehicle.id &&
                                        user.role === 'pdi' &&
                                        (stockTransfer.length === 0 ||
                                            (activeStockTransfer && activeStockTransfer.status === 'done' && activeStockTransfer.sub_mode != 'virtual')) ? ( // not checking cancelled or inactive cases here, since it already filtered
                                        <AdjustStock
                                            vehicle={vehicle}
                                            callback={() => {
                                                loadPageData();
                                            }}
                                        />
                                    ) : null}
                                </div>
                                {/* ) : null}  */}
                                {/** StockTrasferRequest creator componet End */}
                            </div>

                            <Divider />

                            {!bill && !bill.invoice_number ? (
                                vehicle.allocated ? (
                                    <Link className="request-card" to={`/requests/${request.id}`}>
                                        <div className="card" style={{ marginTop: '20px' }}>
                                            <p> Allocated to</p>
                                            <br />
                                            <h2 className="title amount ">{customer.customerName}</h2>
                                            <h4 className="title">{customer.phone}</h4>
                                            <h3 className="title ">
                                                {request.variant} , {request.city}
                                            </h3>
                                            <h4>{request.color}</h4>
                                            Created at {request.created_at}
                                        </div>
                                    </Link>
                                ) : null
                            ) : bill && bill.id ? (
                                <>
                                    <p>Billed to</p>
                                    <div className="card">
                                        <div className="left">
                                            <h3>
                                                <strong>{bill.customer_name}</strong>
                                            </h3>
                                            <h4 className="title">{bill.customer_phone}</h4>
                                            <h4 className="title">Invoice Number: {bill.invoice_number}</h4>
                                            <span>Invoiced Date: {moment(bill.invoice_date_millisecond).format('DD/MM/YYYY')}</span>
                                            <br />

                                            <Link to={`/bill/${bill.id}`}> View Bill </Link>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        {/*  */}
                        <div>
                            <div className="right-content">
                                <div>
                                    <Tabs defaultActiveKey="0" forceRender={true}>
                                        {true ? null : (
                                            <TabPane tab="Over View" key="0">
                                                <Timeline>
                                                    {/* KIN Invoice Date */}
                                                    <Timeline.Item color="blue">
                                                        Purchase <Tag color="orange">DMS</Tag>
                                                        <div>
                                                            <div className="card">
                                                                <div className="left">
                                                                    <h4 className="title">Purchased on : {purchase['KIN Invoice Date']}</h4>
                                                                    <h4> KIN Invoice Number : {purchase['KIN Invoice No']}</h4>
                                                                    <span>
                                                                        {' '}
                                                                        Order Date : <Tag>{purchase['Order Date']}</Tag>
                                                                    </span>
                                                                    <span>
                                                                        Departure Date :<Tag>{purchase['Departure Date']}</Tag>
                                                                    </span>
                                                                    <span>
                                                                        Billing Dealer Code :{' '}
                                                                        <Tag color="green">{purchase['Billing Dealer Code']}</Tag>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Timeline.Item>
                                                    {/* KIN Invoice Date Ends */}

                                                    <Timeline.Item>
                                                        Stock Transfer <Tag color="orange">DMS</Tag>
                                                        {transfers.length > 0 ? (
                                                            transfers.map((transfer) => {
                                                                return (
                                                                    <>
                                                                        {/* From {transfer['Transferred From']} to {transfer['Transferred To']} on {transfer['Transferred Date']} */}
                                                                        <div>
                                                                            <div className="card">
                                                                                <div className="left">
                                                                                    <h4>Stock Transferred On : {transfer['Transferred Date']}</h4>
                                                                                    <Tag color="green"> {transfer['Transferred From']}</Tag>{' '}
                                                                                    <ArrowRightOutlined />
                                                                                    <> </>
                                                                                    <Tag color="orange">{transfer['Transferred To']}</Tag>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        ) : (
                                                            <p> No Transfers Yet</p>
                                                        )}
                                                    </Timeline.Item>

                                                    <Timeline.Item color="blue">
                                                        Stock Transfer <Tag color="green">Surge</Tag>
                                                        <div>
                                                            {stockTransfer.map((stockTransfer) => {
                                                                return (
                                                                    <div className="card">
                                                                        <div className="left">
                                                                            {/* <h4 className="title">Stock Transfer Requested On : {stockTransfer.request_created_at}</h4>
                                                 <h4 className="title">Stock Transfer Requested By : {stockTransfer.created_by}</h4> */}
                                                                            <h4> Created At : {stockTransfer.created_at}</h4>
                                                                            <span>
                                                                                <strong> Type : </strong>
                                                                                {stockTransfer.type}{' '}
                                                                            </span>
                                                                            <span>
                                                                                {' '}
                                                                                <strong>Status :</strong> {stockTransfer.status}
                                                                            </span>
                                                                            <Tag color="green"> {stockTransfer.from}</Tag> <ArrowRightOutlined />
                                                                            <Tag color="orange">{stockTransfer.to}</Tag>
                                                                            {/* {stockTransfer.start?<h4> Stock Transfer started on: {(stockTransfer.start)} </h4> :null}
                                                 {stockTransfer.end?<h4> Stock Transfer completed on: {(stockTransfer.end)} </h4> :null}
  */}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </Timeline.Item>

                                                    <Timeline.Item color="blue">
                                                        Stock Transaction <Tag color="green">Surge</Tag>
                                                        <div>
                                                            {stockTransaction.map((stockTransaction) => {
                                                                var value;
                                                                var date = stockTransaction.transaction_date.toDate();
                                                                var d = date.valueOf();
                                                                value = moment(d).format('DD/MM/YY hh:mm A');

                                                                return (
                                                                    <div className="card">
                                                                        <div className="left">
                                                                            <h4>
                                                                                {' '}
                                                                                {stockTransaction.description
                                                                                    ? stockTransaction.description
                                                                                    : 'Stock In'}
                                                                            </h4>
                                                                            <h4> Created At : {value}</h4>

                                                                            {stockTransaction.transaction_code === 20 ||
                                                                                stockTransaction.transaction_code === 70 ? (
                                                                                <>
                                                                                    <Tag color="green"> {stockTransaction.fromDealerCode}</Tag>{' '}
                                                                                    <ArrowRightOutlined />
                                                                                    <> </>
                                                                                    <Tag color="orange">{stockTransaction.toDealerCode}</Tag>
                                                                                </>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </Timeline.Item>

                                                    {vehicle.update === 'remove' ? (
                                                        <Timeline.Item>
                                                            {!bill && !bill.invoice_number ? (
                                                                <>
                                                                    Billed <Tag color="orange">DMS</Tag>
                                                                    <div className="card">
                                                                        <div className="left">
                                                                            <h3>
                                                                                <strong>{sale['Ship To Name']}</strong>
                                                                            </h3>

                                                                            <h4 className="title">{sale['Contact Num1']}</h4>
                                                                            <h4 className="title">Invoice Number: {sale['Invoice No']}</h4>
                                                                            <span>Invoiced Date: {sale['Invoice Date']}</span>
                                                                            <br />
                                                                            <Link to={`/bill/${billid}`}> View Bill </Link>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Billed to
                                                                    <div className="card">
                                                                        <div className="left">
                                                                            <h3>
                                                                                <strong>{bill.customer_name}</strong>
                                                                            </h3>
                                                                            <h4 className="title">{bill.customer_phone}</h4>
                                                                            <h4 className="title">Invoice Number: {bill.invoice_number}</h4>
                                                                            <span>
                                                                                Invoiced Date:{' '}
                                                                                {moment(bill.invoice_date_millisecond).format('DD/MM/YYYY')}
                                                                            </span>
                                                                            <span>
                                                                                Created at: {moment(bill.created_time).format('DD/MM/YYYY hh:mm a')}{' '}
                                                                            </span>
                                                                            <br />
                                                                            <Link to={`/bill/${bill.id}`}> View Bill </Link>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Timeline.Item>
                                                    ) : (
                                                        <Timeline.Item>Not Billed Yet</Timeline.Item>
                                                    )}
                                                </Timeline>
                                            </TabPane>
                                        )}

                                        <TabPane tab="Stock Transfer" key="1">
                                            <StockTransferDetails  transferRequest={request.transferRequest} stockTransfers={stockTransfer} />
                                        </TabPane>

                                        {/** Stock Movement Tab  start */}
                                        <TabPane tab="Stock Movement" key="2">
                                            <StockTransferRecord resource_number={params.id}></StockTransferRecord>
                                        </TabPane>
                                        {/** Stock Movement Tab  end */}
                                    </Tabs>
                                </div>
                            </div>

                            {/* Purchase */}
                            {/* <Purchases.Card purchase={purchase} /> */}
                            {/* Purchase Ends */}

                            {/* Stock Transfers */}

                            {/* Stock Transfers Ends */}
                            {/* <Title level={5}>Vehicles</Title> */}
                        </div>
                    </div>
                </>
            )}
            {/* Modal for Creating Stock Transfer Request */}
            {/* <Modal
                destroyOnClose={true}
                footer={null}
                title="Stock Transfer Request"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <StockTransferRequest
                    // location={location}
                    btnloading={btnloading}
                    // params={params.id}
                    vehicle={vehicle}
                    Callback={(values) => {
                        onFinish(values);
                    }}
                />
            </Modal>

            {/* Modal for Creating Stock Transfer Request Ends */}

            {/**   Modal used for Gate pass Start  */}

            <Modal
                destroyOnClose={true}
                footer={null}
                title="Gate pass"
                visible={view}
                width={'60%'}
                okText="Okay"
                onOk={() => {
                    setView(false);
                }}
                onCancel={() => {
                    setView(false);
                }}
            >
                <GatePassModal params={params.id} vehicle={vehicle} stockTransferId={result.id} />
            </Modal>

            {/**   Modal used for Gate pass End  */}
        </div>
    );
}
