import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Tag, Tabs, Image, Row, Select, Form, Input, Radio, message, Typography, Upload } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import RequestInfo from './../../../../components/request-info/request-info';
import MatchingReceipts from './../../../../components/matching-receipts/matching-receipts';

import RequestForm from './../../../common/resources/request-form/request-form';

import './insurance-form.scss';

import { CheckCircleOutlined } from '@ant-design/icons';

import CommentBlock from '../../../common/components/comment-block/comment-block';

import { Bills } from '../../../../models';

import VehicleInvoice from '../../../../templates/vehicle-invoice/vehicle-invoice';

import InvoicePreview from '../../../../templates/invoice-preview/invoice-preview';

const { Title } = Typography;

export default function InsuranceForm({ match, step, history }) {
    const [booking, setBooking] = useState({});

    const [editMode, seteditMode] = useState(false);

    const [request, setRequest] = useState({ insurance: {}, customer: {} });

    const { params } = match;

    const { city, id } = params;

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        setLoader(true);

        FirebaseUtils.getRequest(id).then((result) => {
            let data = { ...{ insurance: {} }, ...result };

            console.log('DATA: ', data);
            console.log('STEP:', step);

            setRequest(data);

            getBooking(data.bookingNo);

            if (data.insurance.status !== 'pending') {
                seteditMode(false);
            } else {
                seteditMode(true);
            }

            setLoader(false);
        });
    }

    function getBooking(id) {
        FirebaseUtils.getBooking(id).then((result) => {
            var booking = result.data();

            if (booking) {
                setBooking({ ...booking, comments: [], isValid: true });
            } else {
                setBooking({ empty: true, isValid: false });
            }

            setLoader(false);

            // #todo the booking object have to be removed from
        });
    }

    return (
        <div className="insurance-form">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <h1 className="page-heading">Insurance Details</h1>

                        <div className="split-container">
                            <div className="left">
                                {/* Vehicle Card */}
                                <RequestInfo request={request} />
                            </div>

                            <div className="right">
                                {/* Invoice */}
                                <Invoice booking={booking} userRequest={request} />
                                {/* Invoice  Ends */}

                                {/* Vehicle Card */}
                                <div className="card">
                                    <h4 className="title">Insurance Nominee : {request.actual_billing.insurance_nominee}</h4>
                                    <h4 className="title">Insurance : {request.actual_billing.insurance}</h4>
                                    <h4 className="title">Relation : {request.actual_billing.relation}</h4>
                                </div>
                                <div className="vehicle-card">
                                    <Title level={4}>{step.caption}</Title>

                                    <p>Please check with the customer on the Insurance proceedings and provide the details</p>

                                    <div gutter={0} className="detail-wrapper">
                                        {request.insurance.type ? (
                                            <div className="">
                                                <div className="detail-element">
                                                    <Row span={12}>
                                                        <span>Insurance Type</span>
                                                    </Row>
                                                    <Row span={12}>
                                                        <h2 className="title">{request.insurance.type}</h2>
                                                    </Row>
                                                </div>
                                            </div>
                                        ) : null}

                                        {request.insurance.type && request.insurance.type !== 'direct' ? (
                                            <>
                                                <div className="detail-element">
                                                    <Row span={12}>
                                                        <span>Provider</span>
                                                    </Row>
                                                    <Row span={12}>
                                                        <h2 className="title">{request.insurance.provider}</h2>
                                                    </Row>
                                                </div>

                                                <div className="detail-element">
                                                    <Row span={12}>
                                                        <span>Remarks</span>
                                                    </Row>
                                                    <Row span={12}>
                                                        <h2 className="title">{request.insurance.remarks}</h2>
                                                    </Row>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>

                                    <RequestForm
                                        formContent={request}
                                        booking={booking}
                                        modelIndex="requestId"
                                        model={step.model || 'insurance'}
                                        step={step}
                                        callback={() => {
                                            history.goBack();
                                        }}
                                    />
                                </div>

                                {/* Receipts */}
                                <MatchingReceipts
                                    userRequest={request}
                                    callback={(total) => {
                                        // setReceived(total);
                                    }}
                                />
                                {/* Receipts Ends */}

                                <CommentBlock id={request.id} />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}

function Invoice({ userRequest, booking }) {
    const { billing } = userRequest;

    const [billId, setBillID] = useState();

    const { invoice = [] } = billing;

    let queries = [
        {
            field: 'bookingNo',
            value: userRequest.bookingNo,
        },
        {
            field: 'type',
            value: 'basic',
        },
        {
            field: 'cancellation_status',
            value: false,
        },
    ];

    Bills.get(queries).then((result) => {
        let bill = result.bills[0];
        if (bill) {
            setBillID(bill.id);
        } else {
            setBillID(false);
        }
    });

    return (
        <>
            {billing && billing.invoice && billing.invoice ? (
                <div className="vehicle-card">
                    <Tag icon={<CheckCircleOutlined />} color="success">
                        Invoice Done
                    </Tag>

                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row span={12}>
                                <span>Invoice</span>
                            </Row>
                            <Row span={12}>
                                {billId ? <VehicleInvoice printable={true} booking={booking} request={userRequest} id={billId} /> : null}

                                {/* 
                                <h2 className="title">
                                    {
                                        billing.invoice.type === "application/pdf"
                                            ?
                                            <embed src={billing.invoice.url} width="500" height="375"
                                                type="application/pdf" />
                                            : <Image width={200} src={billing.invoice.url} />
                                    }
                                </h2> */}
                            </Row>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

function Insurance({ request }) {
    return (
        <>
            {request.insurance.attachment ? (
                <div className="vehicle-card">
                    <Tag icon={<CheckCircleOutlined />} color="success">
                        Insurance Done
                    </Tag>

                    <ImageWrapper caption="Insurance" request={request} field="insuranceAttachment" />
                </div>
            ) : null}
        </>
    );
}

function ImageWrapper({ caption, request, field }) {
    return (
        <>
            <div gutter={0} className="detail-wrapper">
                <div className="detail-element">
                    <Row span={12}>
                        <span>{caption}</span>
                    </Row>
                    <Row span={12}>
                        <h2 className="title">
                            {request.insurance[field].type === 'application/pdf' ? (
                                <embed src={request.insurance[field].url} width="500" height="375" type="application/pdf" />
                            ) : (
                                <Image width={200} src={request.insurance[field].url} />
                            )}
                        </h2>
                    </Row>
                </div>
            </div>
        </>
    );
}

function ImageArray({ images, request, field }) {
    return (
        <>
            <div gutter={0} className="detail-wrapper">
                {images.map((image) => {
                    return (
                        <div>
                            {image.type === 'application/pdf' ? (
                                <embed src={image.url} width="500" height="375" type="application/pdf" />
                            ) : (
                                <Image width={200} src={image.url} />
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
}
