/**
 * @description To adjust stock
 */

import { Button, Modal, message } from "antd";

import React, { useState } from "react";

import StockTransferRequest from "./stock-transfer-request";
import { Vehicles } from "../../models";
import { dealerCodeKey } from "../../modules/global-config";

export default function AdjustStock({ vehicle, callback }) {
    const [visible, setVisible] = useState(false);

    const [btnloading, setBtnLoading] = useState(false);

    var cityKey = dealerCodeKey

    /**
     *  To open modal 
     */
    function openModal() {
        setVisible(true)
    }

    /**
     * Adjust Stock Functionality
     */

    function adjustStock(values) {
        setBtnLoading(true)
        
        var formBody = {
            ...values,
            from: cityKey[vehicle.dealerCode],
            vinNo: vehicle["Vin Number"]
        }
        console.log(formBody)
        Vehicles.loadFunction('adjustStock', formBody).then((result) => {
            console.log(result)
            if (result.success == true) {
                message.success(result.message)
                setVisible(false)
                setBtnLoading(false)
                callback();

            }
        })

        //Stock is adjusted by adding in to location with transaction code 40 
        //and removing from current location with transaction code 80
    }

    return (
        <div>
            <Button onClick={openModal}>Adjust Stock</Button>

            <Modal
                destroyOnClose={true}
                footer={null}
                title="Adjust Stock"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >

                {/**Here we use the same modal used for stock transfer request */}
                <StockTransferRequest
                    btnloading={btnloading}
                    vehicle={vehicle}
                    required={null}
                    Callback={(values) => {
                        adjustStock(values)
                    }}
                    from={vehicle.dealerCode}
                    setExternalDealerStockTransfer={null}
                    mode="adjust"
                />
            </Modal>

        </div>


    )
}