import Base from './base';

class LookUpValue extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'lookup_values';
    }

    get path() {
        return `lookup_values`;
    }

    
}

export default LookUpValue;
