import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Form, Input, InputNumber, DatePicker, message, Typography, Skeleton } from 'antd';

import { Select, Alert, Radio } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import { Enquirys, Pricing, Bookings, Designations, Employees, Departments, CustomerEntries, BankCredits } from '../../../../models';

import FirebaseUtils from '../../../../utils/firebase.utils';

import FileUpload from '../../../../components/file-upload/file-upload';

import moment from 'moment';
import { cityKeys } from '../../../global-config';

const { Title } = Typography;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

const { TextArea } = Input;

const { Option } = Select;

var cityKey = cityKeys

export default function KECCollection({ history, match, department, tlFlag, reports }) {
    const { user } = useContext(GlobalContext);

    let isFinance = user.role === 'finance';

    const [form] = Form.useForm();

    const [loader, setLoader] = useState(false);

    const [type, setType] = useState('');

    const [mode, setMode] = useState('');

    const [loading, setLoading] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [enquiry, setEnquiry] = useState([]);

    var [variant, setVariant] = useState([]);

    const [choice, setChoice] = useState(enquiry[0]);

    const [emp, setEmp] = useState({});

    const [kec, setKec] = useState([]);

    const [dept, setDept] = useState();

    var { city } = match.params;

    const [hide, setHide] = useState(true);

    const [noBooking, setNoBooking] = useState(false);

    const [bookingAlert, setBookingAlert] = useState(false);

    //const { city } = match.params;

    const [selectedBooking, setSelectedBooking] = useState();

    const [load, setLoad] = useState(false);

    const [enquirySelected, setEnquirySelected] = useState(false);

    const [booking, setBooking] = useState([]);

    const [bookingNumber, setBookingNumber] = useState([]);

    useEffect(() => {
        getEmployees();
        getDepartment();
    }, []);

    function getEmployees() {
        var queries = [
            {
                field: 'role',
                value: 'kec',
            },
        ];

        // if (['sm', 'tl', 'accounts'].indexOf(user.role) !== -1) {
        //     queries.push({
        //         field: 'locations',
        //         operator: 'array-contains',
        //         value: city,
        //     });
        // }

        var query = [
            {
                field: 'designation',
                value: 'KEC',
            },
        ];

        Designations.get(query).then((result) => {
            console.log(result);

            var queries = [
                {
                    field: 'designation',
                    value: result.designations[0].id,
                },
            ];

            Employees.get(queries).then((res) => {
                console.log(res);
                setKec(res.employees);
            });

            // FirebaseUtils.getEmployees(queries).then((result) => {
            //     setEmp(result);

            //     let b = Object.keys(result).map((booking) => {
            //         return { ...result[booking], ...{ userId: booking } };
            //     });

            //     setKec(b);
            //     console.log(result);
            // });
        });
    }

    function getDepartment() {
        // if (user.role === 'kec') {
        city = user.locations[0];
        // }

        var query = [
            {
                field: 'description',
                value: department[0],
            },
            {
                field: 'dealer_code',
                value: cityKey[city],
            },
        ];

        Departments.get(query).then((res) => {
            setDept(res.departments[0]);
        });
    }

    /**
     *
     * For each change in status we update the log
     *
     * @param {*} values
     */
    const onSubmit = async (values) => {
        let city = user.locations[0];

        let creation = {
            created_by:user.id,
            created_by_name:user.name,
            created_by_role:user.role,
            // created_by: { name: user.name, role: user.role },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),
            transaction_date: moment().startOf('day').valueOf(),
            txn_date: moment().format('DD/MM/YYYY HH:mm'),
        };
      

        let verificationTime;

        //tlFlag is when
        if (tlFlag) {
            verificationTime = {
                verified_at: moment().format('DD/MM/YYYY HH:mm'),
                verified_by: { name: user.name, role: user.role },
                verified_date: moment().startOf('day').valueOf(),
                verified_time: moment().valueOf(), // this can be removed if not needed
            };
        }

        if (values.amount) {
            values.amount = parseFloat(values.amount);
        }

        if (values.type === 'Cheque') {
            values.chequeDate = moment(values.chequeDate).format('DD/MM/YYYY HH:mm');
        }

        if (!values.enquiryId) {
            delete values.enquiryId;
        }

        if (!values.bookingNo) {
            delete values.bookingNo;
        }

        if (!values.utr) {
            delete values.utr;
        }

        let params = {
            ...creation,
            ...values,

            city: city,
            deleted_at:null,
            deleted_by:null,
            pending_at: 'accounts',
            verified: false,
            dealerCode: cityKey[city],
            department_id: dept.id,
            branch_id: dept.branch_id,
            department_name: dept.name,
        };

        if (tlFlag) {
            params = {
                ...params,
                ...verificationTime,
                amount: parseFloat(reports.credit),
                status: 'processing',
                suggestion: {
                    receiptId: reports.receiptId,
                    reconciled_by: reports.receiptId,
                    clearing_city: reports.city,
                    bank: reports.bank,
                },
            };
        } else {
            params = {
                ...params,
                status: 'submitted',
                amount: parseFloat(values.amount),
            };
        }

        if (!values.variant) {
            if (choice && choice.id) {
                params = {
                    ...params,
                    enquiryId: choice.id,

                    model: choice.Model,
                };

                if (!choice.Variant) {
                    params = {
                        ...params,
                        variant: null,
                    };
                } else {
                    params = {
                        ...params,
                        variant: choice.Variant,
                    };
                }

                if (!choice.Color) {
                    params = {
                        ...params,
                        color: null,
                    };
                } else {
                    params = {
                        ...params,
                        color: choice.Color,
                    };
                }
            } else {
                params = { ...params, variant: null };
            }
        }

        if (user.role === 'finance' || tlFlag) {
            params = {
                ...params,

                source: user.role,

                // kec: kec[values.kec].dms['Consultant Name'],
                // teamLeader: emp[values.kec].dms['teamLeader'],
            };

            if (choice && choice.consultant_id) {
                params = {
                    ...params,
                    consultant_id: choice.consultant_id,
                };
            }

            if (choice && choice.teamleader_id) {
                params = {
                    ...params,
                    teamleader_id: choice.teamleader_id,
                };
            }
        } else {
            params = {
                ...params,
                source: 'kec',
                kec: user.dms['Consultant Name'],
                teamLeader: user.dms['teamLeader'],
            };

            kec.map((employee) => {
                if (employee.id === user.employee_id) {
                    // teamLeader = em.dms['teamLeader'];

                    params = {
                        ...params,
                        consultant_id: employee.id,
                        teamleader_id: employee.header_id,
                    };
                }
            });
        }

        // If its cash we have received the amount
        if (values.type === 'Cash' || tlFlag) {
            params.pending_to_reconcile = false;
            //todo recheck this , how can kec accept cash
            // if its cash , how can it be pending_to_be reconciled
        } else {
            params.pending_to_reconcile = true;

            if (values.type === 'Cheque') {
                params = {
                    ...params,
                    ref: values.chequeNo,
                    chequeNo: values.chequeNo,
                    chequeDate: values.chequeDate,
                };
            } else {
                params = {
                    ...params,
                    ref: values.utr,
                };
            }
        }

        setLoader(true);

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        // when the collection is added, a document with the params is added in the customer_entries.
        var docRef = CustomerEntries.getRecordReference();

        await batch.set(docRef, params);

        message.success('Payment Collection Recorded');

        if (tlFlag) {
            let creation = {
                created_by:user.id,
                created_by_name:user.name,
                created_by_role:user.role,
                // created_by: { name: user.name, role: user.role },
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                txn_date: moment().format('DD/MM/YYYY HH:mm'),
                created_time: moment().valueOf(),
            };
            let receipt = {
                sm: creation,
                pending_at: 'accounts',
                reconcile_status: 'pending',
                suggestion_entry: true,
                suggestion: {
                    collectionIds: [docRef.id],
                    reconciled_for: docRef.id,
                    reconciling_city: values.location,
                },
            };

            // todo sever here we need to consider the location of the payment and the one selection
            var receiptRef = BankCredits.getRecordReference(reports.receiptId);

            await batch.update(receiptRef, receipt);

            message.success('Receipt updates has been recorded');

            setLoader(false);

            history.goBack();
        } else {
            setLoader(false);

            history.goBack();
        }

        await batch.commit();
    };

    /**
    Remove duplicate from an array
*/

    function groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    }

    {
        /**
         *Get Data from Bookings Table
         * */
    }
    function getBookingNumber(phone) {
        let query = [
            {
                field: 'Contact Number',
                value: phone,
            },
            {
                field: 'dealerCode',
                value: cityKey[user.locations[0]],
            },
        ];
        return Bookings.get(query).then((result) => {
            if (result.bookings.length) {
                setBookingNumber(result.bookings);

                setLoading(false);

                setHide(false);

                setDisabled(false);

                return result;
            } else {
                setHide(true);

                setDisabled(true);

                setLoading(false);
            }
        });
    }

    {
        /**
         *
         *Get Variant list from pricing table
         *  */
    }

    function getVariant(model) {
        let query = [
            {
                field: 'model',
                value: model,
            },
        ];
        return Pricing.get(query).then((result) => {
            console.log(result);

            var model;

            model = groupBy(result.pricing, 'variant');

            setVariant(Object.keys(model));

            return variant;
        });
    }

    function onUpload(attachments) {
        setDisabled(false);

        form.setFieldsValue({ attachments: attachments });
    }

    var disable = false; //disable model and variant if present in enquiry
    var required = true;
    if (choice && choice.Model && choice.Variant) {
        disable = true;
        required = false;
    }

    /**
     * Set Choice to get model variant and booking number
     * @param {
     * } enquiry
     */
    function selectChoice(enquiry) {
        setEnquirySelected(true);
        setChoice(enquiry);

        if (!enquiry['Booking No']) {
            setNoBooking(true);

            setSelectedBooking(null);
        } else {
            setNoBooking(false);

            setSelectedBooking(enquiry['Booking No']);
        }

        if (enquiry['Booking No']) {
            var bookingNo = enquiry['Booking No'].substr(5);
        }
        // if (enquiry && enquiry['Booking No']) {
        // var bookingNo = enquiry['Booking No'].substr(5);
        var customerName = enquiry['Name of the Customer'];
        var model = enquiry['Model'];
        var variant = enquiry['Variant'];
        var KEC = enquiry['Consultant Name'];
        form.setFieldsValue({ bookingNo: bookingNo, name: customerName, model: model, variant: variant, kec: KEC });
        // }
    }

    function selectBookingChoice(book) {
        setSelectedBooking(book['Booking No']);

        var bookingNo = book['Booking No'].substr(5);
        form.setFieldsValue({ bookingNo: bookingNo });
    }

    function getBooking(bookingno) {
        setNoBooking(false);

        setLoad(true);
        var query = [
            {
                field: 'bookingId',
                value: bookingno,
            },
        ];

        Bookings.get(query).then((res) => {
            if (res.bookings.length) {
                setBooking(res.bookings);

                setBookingAlert(false);

                setLoad(false);
            } else if (!res.bookings.length && bookingno.length >= 10) {
                setBookingAlert(true);

                setLoad(false);
            }
        });
    }

    const onReject = () => {
        var values = form.getFieldsValue();

        if (values.reject_remarks) {
            var params = {
                rejectRemarks: values.reject_remarks,
                smReject: {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_by: user.name,
                },
                pending_at: 'new',
            };

            FirebaseUtils.updateBankCreditRecord(match.params.id, params).then((result) => {
                console.log(result);

                setLoader(false);

                message.success('Receipt updates has been recorded');

                history.goBack();
            });
        } else {
            message.error('Please provide remarks');
        }
    };

    return (
        <div className="allocation-form">
            <Fragment>
                <div className="page-content">
                    <div className="vehicle-card">
                        <>
                            <div className="document-status">
                                <Title level={3}>Add Collection </Title>

                                <p>For any customer payment , fill the details in the below form</p>

                                {/* <Alert
                                    style={{ margin: '10px 0px' }}
                                    message="Note : Please note that receipts could be done only once the dms enquiries are synced with this application ."
                                    type="warning"
                                    showIcon
                                /> */}

                                <Form
                                    {...layout}
                                    form={form}
                                    name="new-record"
                                    onFinish={onSubmit}
                                    onFieldsChange={(fields) => {
                                        let field = fields[0];

                                        if (field && field.value) {
                                            if (field.name[0] === 'type') {
                                                setType(field.value);
                                            }

                                            if (field.name[0] === 'account') {
                                                setMode(field.value);
                                            }
                                            if (field.name[0] === 'phone') {
                                                setLoading(true);
                                                getBookingNumber(field.value);
                                                setChoice(null);
                                                form.setFieldsValue({ bookingNo: null });
                                                setSelectedBooking(null);
                                            }
                                            if (field.name[0] === 'model') {
                                                getVariant(field.value);
                                            }

                                            if (field.name[0] === 'bookingNo' && field.value.length >= 10) {
                                                getBooking(field.value);
                                            } else if (field.name[0] === 'bookingNo' && field.value.length < 10) {
                                                setBookingAlert(false);
                                            }
                                        } else {
                                        }
                                    }}
                                    layout="vertical"
                                    // validateMessages={validateMessages}
                                    initialValues={{}}
                                >
                                    <Form.Item name={'account'} label="On account of" rules={[{ required: true, message: 'On Account Of' }]}>
                                        <Select>
                                            {['BOOKING', 'PAYMENT', 'FINANCE', 'EXCHANGE', 'INSURANCE', 'ACCESSORIES'].map((option) => (
                                                <Option value={option}>{option}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    {tlFlag && !mode ? (
                                        <Form.Item
                                            name={'reject_remarks'}
                                            label="Remarks"
                                            rules={[{ required: true, message: 'Please enter remarks' }]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item>
                                    ) : null}

                                    {mode ? (
                                        <>
                                            <Form.Item
                                                name={'phone'}
                                                label="Phone Number"
                                                // help="Please enter the mobile number for which the enquiry is punched"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter phone number',
                                                    },
                                                ]}
                                            >
                                                <Input prefix={'+91'} />
                                            </Form.Item>
                                        </>
                                    ) : null}
                                    {hide && disabled && !loading ? (
                                        <Alert
                                            style={{ margin: '10px 0px' }}
                                            message="Please enter the mobile number for which the booking is punched.An extra space or +91 added would not load the booking."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null}

                                    {hide ? null : (
                                        <>
                                            {loading ? (
                                                <Skeleton active />
                                            ) : bookingNumber.length > 0 ? (
                                                <>
                                                    <div>
                                                        {bookingNumber.length === 1 ? (
                                                            <div>
                                                                {' '}
                                                                There is 1 booking matching the given information. Please select the booking to have
                                                                model and variant of the Vehicle.
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                There are {bookingNumber.length} number of bookings matching the given information.
                                                                Please select the correct booking to have model and variant of the Vehicle.
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="enquiry-option">
                                                        {bookingNumber.map((booking) => {
                                                            return (
                                                                <div
                                                                    className={`enquiry ${booking === choice ? 'active' : ''}`}
                                                                    onClick={() => selectChoice(booking)}
                                                                >
                                                                    <CheckCircleOutlined />
                                                                    Name: {booking['Name of the Customer']}
                                                                    <br />
                                                                    Variant: {booking.Variant}
                                                                    <br />
                                                                    Colour: {booking.Color}
                                                                    <br />
                                                                    Enquiry Date:{booking['Enquiry Date']}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </>
                                            ) : null}

                                            {enquirySelected ? (
                                                <>
                                                    {/* {mode === 'BOOKING' ? ( */}
                                                    <Form.Item
                                                        name={'bookingNo'}
                                                        label="Booking No"
                                                        rules={[{ required: true, message: 'Please enter Booking Number' }]}
                                                        help="Please enter without dealer code"
                                                    >
                                                        <Input disabled={selectedBooking} />
                                                    </Form.Item>
                                                    {/* ) : null} */}

                                                    {bookingAlert ? (
                                                        <>
                                                            <Alert
                                                                style={{ margin: '10px 0px' }}
                                                                message="Please enter correct booking number"
                                                                type="warning"
                                                                showIcon
                                                            />
                                                        </>
                                                    ) : null}

                                                    {load ? (
                                                        <Skeleton active />
                                                    ) : !noBooking && booking.length > 0 ? (
                                                        <>
                                                            {booking.length === 1 ? (
                                                                <div>
                                                                    {' '}
                                                                    There is 1 booking matching the given booking number. Please select the booking .
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    There are {booking.length} number of bookings matching the given booking number.
                                                                    Please select the correct booking .
                                                                </div>
                                                            )}
                                                            <div className="enquiry-option">
                                                                {booking.map((book) => {
                                                                    return (
                                                                        <div
                                                                            className={`enquiry ${
                                                                                book['Booking No'] === selectedBooking ? 'active' : ''
                                                                            }`}
                                                                            onClick={() => selectBookingChoice(book)}
                                                                        >
                                                                            <CheckCircleOutlined />
                                                                            <br />
                                                                            Booking Date:{book['Booking Date']}
                                                                            <br />
                                                                            Variant: {book['Variant']}
                                                                            <br />
                                                                            Colour: {book['Color']}
                                                                            <br />
                                                                            KEC:{book['Consultant Name']}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </>
                                                    ) : null}

                                                    {noBooking ? (
                                                        <>
                                                            <Alert
                                                                style={{ margin: '10px 0px' }}
                                                                message="Sorry booking for this enquiry is not updated to surge yet.You can enter the booking number or you can continue once the dms booking is uploaded to surge"
                                                                type="warning"
                                                                showIcon
                                                            />
                                                        </>
                                                    ) : null}

                                                    <Form.Item name={'enquiryId'} label="Enquiry ID" rules={[{ message: 'Please enter enquiry id' }]}>
                                                        <Input />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={'name'}
                                                        label="Customer Name"
                                                        rules={[{ required: true, message: 'Please enter customer name' }]}
                                                    >
                                                        <Input disabled={disable} />
                                                    </Form.Item>

                                                    {isFinance || tlFlag ? (
                                                        <>
                                                            <Form.Item
                                                                name={'kec'}
                                                                label="KEC"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select kec',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    allowClear
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Please select"
                                                                    disabled={disable}
                                                                >
                                                                    {kec.map((kec) => {
                                                                        return (
                                                                            <Option value={kec.id} key={kec.dms_name}>
                                                                                {kec.fname} - {kec.branch}
                                                                            </Option>
                                                                            // <Option value={kec.userId} key={kec.email}>
                                                                            //     {kec.name} - {kec.locations[0]}
                                                                            // </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {tlFlag ? null : (
                                                        <Form.Item
                                                            name={'amount'}
                                                            label="Amount"
                                                            rules={[{ required: true, message: 'Please enter Amount' }]}
                                                        >
                                                            <InputNumber />
                                                        </Form.Item>
                                                    )}

                                                    {tlFlag ? (
                                                        <Form.Item
                                                            name={'location'}
                                                            label="Location"
                                                            rules={[{ required: true, message: 'Location' }]}
                                                        >
                                                            <Radio.Group>
                                                                <Radio value="kozhikode">Kozhikode</Radio>
                                                                <Radio value="kannur">Kannur</Radio>
                                                                <Radio value="malappuram">Malappuram</Radio>
                                                                <Radio value="kondotty">Kondotty</Radio>
                                                                <Radio value="tirur">Tirur</Radio>
                                                                <Radio value="vadakara">Vadakara</Radio>
                                                                <Radio value="kasargod">Kasargod</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    ) : null}

                                                    <>
                                                        <Form.Item
                                                            name="model"
                                                            label="Model"
                                                            rules={[
                                                                {
                                                                    required: required,
                                                                    message: 'Please enter Model',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                allowClear
                                                                style={{ width: '100%' }}
                                                                placeholder="Please select Model"
                                                                disabled={disable}
                                                            >
                                                                {['SONET', 'SELTOS', 'CARNIVAL', 'CARENS','EV6'].map((option) => (
                                                                    <Option value={option}>{option}</Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="variant"
                                                            label="Variant"
                                                            rules={[
                                                                {
                                                                    // required: required,
                                                                    message: 'Please enter Variant',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                allowClear
                                                                style={{ width: '100%' }}
                                                                placeholder="Please select Variant"
                                                                disabled={disable}
                                                            >
                                                                {variant.map((variant) => {
                                                                    return <Option value={variant}>{variant}</Option>;
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </>
                                                    {mode === 'BOOKING' ? (
                                                        <Alert
                                                            style={{ margin: '10px 0px' }}
                                                            message="Please note that we dont accept Cash for Booking amount"
                                                            type="info"
                                                            showIcon
                                                        />
                                                    ) : null}

                                                    <Form.Item
                                                        name={'type'}
                                                        label="Payment Type"
                                                        rules={[{ required: true, message: 'Please choose the payment type.' }]}
                                                    >
                                                        <Radio.Group>
                                                            {mode === 'BOOKING' ? null : <Radio value="Cash">Cash</Radio>}
                                                            <Radio value="Net Banking">Net Banking</Radio>
                                                            <Radio value="Credit Card">Credit Card</Radio>
                                                            <Radio value="Debit Card">Debit Card</Radio>
                                                            {/* <Radio value="Cheque">Cheque</Radio> */}
                                                        </Radio.Group>
                                                    </Form.Item>

                                                    <Alert
                                                        style={{ margin: '10px 0px' }}
                                                        message="If Cheque is directly presented at bank, Please choose Net Banking. Else present cheque to Cashier."
                                                        type="warning"
                                                        showIcon
                                                    />

                                                    {type !== 'Cash' && type !== 'Cheque' ? (
                                                        <Form.Item
                                                            name={'utr'}
                                                            label="UTR Number"
                                                            rules={[{ required: true, message: 'Please enter UTR Number' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    ) : null}

                                                    {type === 'Cheque' ? (
                                                        <>
                                                            <Form.Item
                                                                name={'chequeNo'}
                                                                label="Cheque Number"
                                                                rules={[{ required: true, message: 'Please enter Cheque Number' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={'chequeDate'}
                                                                label="Cheque Date"
                                                                rules={[{ required: true, message: 'Please enter Cheque Date' }]}
                                                            >
                                                                <DatePicker inputReadOnly />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}

                                                    {type === 'Credit Card' || type === 'Debit Card' ? (
                                                        <>
                                                            <Form.Item
                                                                name={'cardNo'}
                                                                label="Card Number(Last 4 digits)"
                                                                rules={[{ required: true, message: 'Please enter Card Number' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}

                                                    {type !== 'Cash' && type !== 'Credit Card' && type !== 'Debit Card' ? (
                                                        <>
                                                            <Form.Item
                                                                name="attachments"
                                                                rules={[{ required: true, message: 'Share attachments of the receipt' }]}
                                                                label="Attach Payment Screenshot"
                                                                valuePropName="fileList"
                                                                extra="File size should not exceed 1 mb"
                                                            >
                                                                <FileUpload
                                                                    callback={onUpload}
                                                                    onProgress={() => {
                                                                        setDisabled(true);
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}

                                                    <Form.Item
                                                        name={'remarks'}
                                                        label="Remarks"
                                                        rules={[{ required: true, message: 'Please enter remarks' }]}
                                                    >
                                                        <TextArea rows={4} />
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                        </>
                                    )}

                                    <Button
                                        loading={loader}
                                        disabled={!selectedBooking || disabled}
                                        type="primary"
                                        htmlType="submit"
                                        className="submit-button"
                                    >
                                        SUBMIT
                                    </Button>

                                    {tlFlag && !mode ? (
                                        <Button onClick={onReject} type="secondary" className="submit-button">
                                            REJECT
                                        </Button>
                                    ) : null}
                                </Form>
                            </div>
                        </>
                    </div>
                </div>
            </Fragment>
        </div>
    );
}
