import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from '../../../../Store';

import { message, Popconfirm, Tabs, Button, Tag, Divider, Typography, Select, Alert } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import moment from 'moment';

import BankReceipt from './components/bank-receipt/bank-receipt';

import { Departments, BankCredits } from '../../../../models';

import './reconcile-summary.scss';

const { TabPane } = Tabs;

const { Option } = Select;

const { Title } = Typography;

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;
    }, {});
};

let defaultReceipt = {
    accounts: [],
    sm: [],
    edp: [],
    new: [],
    exact: [],
    match: [],
};

export default function ReconcileSummary({ history, department, match }) {
    return (
        <section className="reconcile-summary">
            <Summary history={history} department={department} match={match} />
        </section>
    );
}

const Summary = ({ url, history, department, match }) => {
    const [receipts, setReceipts] = useState(defaultReceipt);

    const { user, isMobile } = useContext(GlobalContext);

    const [loader, setLoader] = useState(true);

    const [banks, setBanks] = useState([]);

    const [selectedBanks, setselectedBanks] = useState([]);

    const [codes, setCodes] = useState({});

    const [visible, setVisible] = useState(true);

    const [dep, setDep] = useState([]);

    const [allDepartments, setAllDepartments] = useState([]);

    useEffect(async () => {
        // getDepartment();

        await getCustomerCredits();

        await getBanks();
    }, []);

    useEffect(() => {
        if (dep[0] === 'Department Handling Services') {
            setVisible(false);
        }
    }, [dep]);

    console.log(visible);

    //get all the departments
    async function getAllDepartments(query) {
        return Departments.get(query).then((res) => {
            setAllDepartments(res.departments);
            return res.departments;
        });
    }

    function getBanks() {
        FirebaseUtils.getBanks().then((result) => {
            let banks = result;

            setBanks(banks);

            setselectedBanks(banks.map((b) => b.code));

            let codes = {};

            banks.forEach((bank) => {
                codes[bank.code] = bank.location;
            });

            setCodes(codes);
        });
    }

    function getCustomerCredits(location) {
        let dept;

        if (match.params.category === 'sales') {
            setDep(['Department Handling Sales']);

            dept = 'Department Handling Sales';
        } else if (match.params.category === 'service') {
            setDep(['Department Handling Services']);

            dept = 'Department Handling Services';
        } else if (department) {
            setDep(department);
            dept = department[0];
        }

        setLoader(true);

        //Getting all departments according to description(can be either sales or service)
        var query = [
            {
                field: 'description',
                value: dept,
            },
        ];

        getAllDepartments(query).then((departments) => {
            var deptID = [];
            // deptID will give an array of all department ids of sales or service
            departments.forEach((dep) => {
                deptID.push(dep.id);
            });

            var queries = [
                {
                    field: 'reconcile_status',
                    value: 'pending',
                },
                {
                    field: 'isCredit',
                    value: true,
                },

                //to get only sales or service data
                {
                    field: 'department_id',
                    operator: 'in',
                    value: deptID,
                },
            ];

            FirebaseUtils.getBankCredits(queries).then(async (result) => {
                //attaching department object to bank receipt
                var bankCredit = await Promise.all(
                    result.map((entry) => {
                        let receiptDepartment = departments.filter((dep) => dep.id === entry.department_id);
                        entry.department = receiptDepartment[0];

                        return { ...entry };
                    })
                );

                if (bankCredit && bankCredit.length) {
                    let x = bankCredit.filter((item) => !item.debit);
                    getPendingMatchingReceipts(x, departments);
                } else {
                    setReceipts(defaultReceipt);

                    setLoader(false);
                }
            });
        });
    }

    function getPendingMatchingReceipts(newReceipts, departments) {
        var queries = [
            {
                field: 'pending_to_reconcile',
                value: true,
            },
            {
                field: 'verified',
                value: 'approved',
                // operator: 'in',
                // value: ['approved', false]
            },

            {
                field: 'source',
                operator: 'in',
                value: ['kec', 'cashier', 'finance', 'sm', 'tl', 'KIA Website'],
            },
        ];

        FirebaseUtils.getAllCollections(queries).then((result) => {
            console.log(result);

            newReceipts.forEach((receipt) => {
                receipt.matching = [];

                result.all.forEach((entry) => {
                    //To attach department object with receipt
                    let collectionDepartment = departments.filter((department) => department.id === entry.department_id);

                    // DEpart of
                    entry.department = collectionDepartment[0];

                    var condition;

                    if ((entry.utr !== '' || entry.ref !== '') && entry.amount === parseInt(receipt['credit'])) {
                        condition =
                            receipt['description'].indexOf(entry.ref) !== -1 ||
                            receipt['description'].indexOf(entry.utr) !== -1 ||
                            receipt['ref'].indexOf(entry.utr) !== -1 ||
                            receipt['ref'].indexOf(entry.ref) !== -1;

                        if (condition && entry.type !== 'Debit Card' && entry.type !== 'Credit Card') {
                            // Push the entries to matching
                            receipt.matching.push(entry);

                            receipt.pending_at = 'match';
                        }
                    }
                });
            });

            var grouped = groupBy(newReceipts, 'pending_at');

            console.log(grouped);

            setReceipts({
                ...defaultReceipt,

                ...grouped,
            });

            setLoader(false);
        });
    }

    function onSelect(receipt, index) {
        receipts['new'].forEach((entry, index) => {
            if (receipt.receiptId === entry.receiptId) {
                receipts['new'][index].selected = true;
            }
        });

        setReceipts({ ...receipts });
    }

    function onDiscard(receipt, index) {
        receipts['new'].forEach((entry, index) => {
            if (receipt.receiptId === entry.receiptId) {
                receipts['new'][index].selected = false;
            }
        });

        setReceipts({ ...receipts });
    }

    function sendTo(role) {
        var params = {
            initial: {
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                created_by: user.name,
            },
            pending_at: role,
        };

        Promise.all(
            receipts['new']
                .filter((receipt) => receipt.selected)
                .map( async (entry, index) => {
                    var city = codes[entry.bank];

                    var app = FirebaseUtils.getApp();

                    var batch = app.batch();

                    // To update the bank_credits record
                    var docRef = BankCredits.getRecordReference(entry.receiptId);

                    await batch.update(docRef, params);

                    batch.commit();
                })
        ).then(() => {
            message.success('Status has been updated');

            getCustomerCredits();
            // getBankReceipts();
        });
    }

    function updateBank(value, option) {
        setselectedBanks([...value]);
    }

    return (
        <div className="summary-content">
            <div className="list-header">
                <div>
                    <Title level={3}>Pending to Reconcile</Title>

                    <p className="page-desc">{loader ? '. Loading data' : ``}</p>
                </div>

                <div className="list-actions">
                    <Button
                        onClick={() => {
                            getCustomerCredits();
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>
                </div>
            </div>

            <div className="summary-listing">
                {loader ? (
                    <PlaceHolder type="listing" />
                ) : (
                    <>
                        <Tabs defaultActiveKey="1" onChange={() => {}}>
                            {/* Accounts Pending */}
                            <TabPane tab={`Bank Credits (${receipts['new'].length + receipts['match'].length})`} key="1">
                                <div className="top-header">
                                    {banks.length ? (
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '200px' }}
                                            placeholder="Please select"
                                            defaultValue={banks.map((el) => el.code)}
                                            onChange={updateBank}
                                        >
                                            {banks.map((bank, index) => {
                                                return (
                                                    <Option key={index} value={bank.code}>
                                                        {bank.code}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    ) : null}
                                </div>

                                {/* Exact Match */}

                                <>
                                    {receipts['match'].length ? (
                                        <>
                                            <Divider orientation="left" plain>
                                                {
                                                    receipts['match'].filter((receipt, index) => {
                                                        return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;
                                                    }).length
                                                }{' '}
                                                receipts matched,{' '}
                                                {
                                                    receipts['new'].filter((receipt, index) => {
                                                        return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;
                                                    }).length
                                                }{' '}
                                                Unidentified
                                            </Divider>
                                            <>
                                                {receipts['match']
                                                    .filter((receipt, index) => {
                                                        return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;
                                                    })
                                                    .map((receipt, index) => {
                                                        return (
                                                            <BankReceipt
                                                                codes={codes}
                                                                receipt={receipt}
                                                                callback={getCustomerCredits}
                                                                key={index}
                                                                department={dep}
                                                                allDepartments={allDepartments}
                                                            />
                                                        );
                                                    })}
                                            </>
                                        </>
                                    ) : null}
                                </>

                                {/* Exact Match Ends */}

                                {/* Selected Receipts */}
                                {visible && receipts['new'].filter((receipt) => receipt.selected).length ? (
                                    <div className="selected-item-form card">
                                        {/* Selected Tags */}
                                        <div className="selected-items">
                                            {receipts['new']
                                                .filter((receipt) => receipt.selected)
                                                .map((receipt, key) => {
                                                    return (
                                                        <Tag
                                                            key={key}
                                                            visible
                                                            closable
                                                            onClose={() => {
                                                                onDiscard(receipt, key);
                                                            }}
                                                        >
                                                            {receipt['description']} <span className="title amount">{receipt['credit']}</span>
                                                        </Tag>
                                                    );
                                                })}
                                        </div>
                                        {/* Selected Tag Ends */}

                                        <div className="form-actions">
                                            <Popconfirm
                                                title="Are you sure you want to send this to sales team for identification?"
                                                onConfirm={() => sendTo('sm')}
                                                onCancel={() => {}}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type="secondary" size={'small'}>
                                                    Send to Sales Team
                                                </Button>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                ) : null}
                                {/* Selected Receipts Ends */}

                                <Divider orientation="left" plain>
                                    {
                                        receipts['new'].filter((receipt) => {
                                            return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;
                                        }).length
                                    }{' '}
                                    Unidentified receipts
                                </Divider>

                                <>
                                    {receipts['new']
                                        .filter((receipt) => {
                                            return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;
                                        })
                                        .map((receipt, index) => {
                                            return (
                                                <BankReceipt
                                                    callback={getCustomerCredits}
                                                    codes={codes}
                                                    key={index}
                                                    receipt={receipt}
                                                    department={dep}
                                                    onSelect={() => {
                                                        onSelect(receipt, index);
                                                    }}
                                                    allDepartments={allDepartments}
                                                />
                                            );
                                        })}
                                </>
                            </TabPane>
                            {/* Accounts Pending */}

                            {/* SM Pending */}

                            {visible ? (
                                <TabPane tab={`Pending with Sales Team (${receipts['sm'].length})`} key="2">
                                    <>
                                        {receipts['sm'].map((receipt, index) => {
                                            return (
                                                <BankReceipt
                                                    callback={getCustomerCredits}
                                                    codes={codes}
                                                    key={index}
                                                    receipt={receipt}
                                                    department={dep}
                                                    allDepartments={allDepartments}
                                                />
                                            );
                                        })}
                                    </>
                                </TabPane>
                            ) : null}

                            {/* SM Pending */}

                            {/* Waiting for Approval */}
                            {visible ? (
                                <TabPane tab={`Waiting for Approval (${receipts['accounts'].length})`} key="3">
                                    <>
                                        <Alert
                                            message="Below are bank credits with supporting customer details suggested by Sales Team. Appproval of these entries would be forwarded for DMS Entry and will be updated on CS Accounts once you select the customer head."
                                            type="info"
                                            showIcon
                                            closable
                                        />

                                        {receipts['accounts'].map((receipt, index) => {
                                            return (
                                                <BankReceipt
                                                    callback={getCustomerCredits}
                                                    codes={codes}
                                                    key={index}
                                                    receipt={receipt}
                                                    department={dep}
                                                    allDepartments={allDepartments}
                                                />
                                            );
                                        })}
                                    </>
                                </TabPane>
                            ) : null}
                            {/* Waiting for Approval Ends */}
                        </Tabs>
                    </>
                )}
            </div>
        </div>
    );
};
