/**
 * The Pending with Sales Team Record is listed for the sales team to enter any
 * suggestions for collection detail
 */

import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Select, Row, Tag, Form, Input, Radio, message } from 'antd';

import PlaceHolder from './../../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from './../../../../../Store';

import moment from 'moment';

import FirebaseUtils from '../../../../../utils/firebase.utils';

import FileUpload from '../../../../../components/file-upload/file-upload';

import CommentBlock from './../../../components/comment-block/comment-block';

import { Departments } from '../../../../../models';

import './report.scss';
import KECCollection from '../../collections/collections-add';
import { cityKeys } from '../../../../global-config';


const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};

const tailLayout = {
    wrapperCol: { span: 16 },
};

const { Option } = Select;

export default function Report(props) {
    const { user } = useContext(GlobalContext);

    const [form] = Form.useForm();

    const [reports, setReports] = useState([]);

    const { match } = props;

    const [loader, setLoader] = useState(true);

    const [emp, setEmp] = useState({});

    const [kec, setKec] = useState([]);

    const [mode, setMode] = useState('');

    const [dept, setDept] = useState();

    var city = cityKeys
    useEffect(() => {
        getCustomerReceipts();
        getDepartment();
        getEmployees();
    }, []);

    function getEmployees() {
        var queries = [
            {
                field: 'role',
                value: 'kec',
            },
        ];

        if (['sm', 'tl', 'accounts'].indexOf(user.role) !== -1) {
            queries.push({
                field: 'locations',
                operator: 'array-contains',
                value: user.locations[0],
            });
        }

        FirebaseUtils.getEmployees(queries).then((result) => {
            setEmp(result);

            let b = Object.keys(result).map((booking) => {
                return { ...result[booking], ...{ userId: booking } };
            });

            setKec(b);
        });
    }

    function getDepartment() {
        var query = [
            {
                field: 'description',
                value: 'Department Handling Sales',
            },

            {
                field: 'dealer_code',
                value: city[match.params.city],
            },
        ];

        Departments.get(query).then((res) => {
            setDept(res.departments[0]);
        });
    }

    /**
     * Get stock Data
     */
    function getCustomerReceipts(location) {
        setLoader(true);

        FirebaseUtils.getBankCreditRecord(match.params.id).then((result) => {
            setReports(result);

            setLoader(false);
        });
    }

    function onUpload(attachments) {
        form.setFieldsValue({ attachments: attachments });
    }

    return (
        <div className="reports">
            {/* <Header onBack={() => props.history.goBack()} /> */}

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="about-us page-content">
                        <h1 className="page-heading">Payment Detail</h1>

                        <p>Please check with your team to find and enter the requested customer details for the below payment</p>
                    </div>
                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Description :</h3>
                            </Row>
                            <Row span={12}>
                                <span>{reports['description']}</span>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Ref No./Cheque No. :</h3>
                            </Row>
                            <Row span={12}>
                                <span>{reports['ref']}</span>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Amount :</h3>
                            </Row>
                            <Row span={12}>
                                <h2 className="title amount">
                                    {reports['credit'] ? 'Rs' : ''} {reports['credit']}
                                </h2>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Branch Code :</h3>
                            </Row>
                            <Row span={12}>
                                <h2 className="">
                                    <Tag color="magenta">{reports.bank}</Tag>
                                </h2>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Date :</h3>
                            </Row>
                            <Row span={12}>
                                <span className="">{reports['txn_date']}</span>
                            </Row>
                        </div>
                    </div>

                    <KECCollection history={props.history} match={match} department={['Department Handling Sales']} tlFlag={true} reports={reports} />
                </Fragment>
            )}

            {reports.receiptId && <CommentBlock id={reports.receiptId} />}
        </div>
    );
}
