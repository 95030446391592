
import React, { useState, useEffect, useContext } from "react";


import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom'

import { Row, Tabs, Button, Typography, message } from "antd";

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import moment from 'moment';

import "./existing-allocations.scss";
import { cityKeys, dealerCodeKey } from "../../../global-config";


var dealer = dealerCodeKey

const { TabPane } = Tabs;

const { Title } = Typography;

var cityKey = cityKeys


export default function ExistingAllocations(props) {

    const { user } = useContext(GlobalContext);

    useEffect(() => {

    }, [user])

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="finance">

            <Title level={3}>

                Existing Allocation

            </Title>


            <Tabs defaultActiveKey="1" onChange={callback}>

                {
                    user.locations.map((location, index) => {

                        return (
                            <TabPane tab={`${location} Allocation Requests`} key={index}>

                                <AllocationList url={location} />

                            </TabPane>
                        )
                    })
                }

            </Tabs>

        </section>
    );
}


const AllocationList = ({ url }) => {

    const [allocations, setAllocations] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        getData();

    }, [])


    function getData() {

        setLoading(true);

        var queries = [{
            field: 'Stock Status',
            operator: 'in',
            value: ["Allocated"]
        }]


        FirebaseUtils.getVehicles(url, queries).then((result) => {

            console.log(result);

            let vehicles = Object.keys(result.vehicles).map((request) => {
                return { ...result.vehicles[request] }
            });

            var freeBookingsQueries = [{
                field: 'dealerCode',
                value: cityKey[url]
            }]

            FirebaseUtils.getFreeBookings(freeBookingsQueries).then((result) => {

                let customers = {};

                Object.keys(result).filter((booking) => {

                    // return true;
                    return !result[booking].requestId
                }).forEach((booking) => {

                    customers[result[booking]['Customer ID']] = result[booking];
                });

                let customerKeys = Object.keys(customers);

                vehicles.forEach((vehicle) => {

                    if (customerKeys.indexOf(vehicle['Cust ID']) !== -1) {

                        vehicle.matching = customers[vehicle['Cust ID']];
                    }
                })

                console.log(vehicles);

                setAllocations(vehicles.filter((vehicle) => vehicle.matching));

                setLoading(false);

            });
        });
    }

    return (<><div className="list-header">

        <p className="page-desc">
            {loading ? 'Loading data' : `Found ${Object.keys(allocations).length} results`}
        </p>

        <div className="list-actions">
            <Button onClick={() => { getData() }} type="secondary" size={'small'}>Refresh</Button>
        </div>

    </div>

        <Row className="report-listing-wrapper">
            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                    <>
                        {
                            (allocations).filter((vehicle) => vehicle.matching).map((report, index) => {
                                return <VehicleCard vehicle={report} index={index} key={index} callback={getData} />
                            })
                        }
                    </>
                )}
        </Row></>)
}


function VehicleCard({ vehicle, callback }) {

    const { user } = useContext(GlobalContext);


    /**
     * 
     * Allocate already existing booking to 
     * the team leader 
     * Team leader has to verify after which the finance process woudl
     * start
     * 
     * @param {*} param0 
     */
    function createRequest({ booking }) {

        // Find the city of the booking
        let bookingCity = dealer[booking['Dealer Code']]

        // create request
        FirebaseUtils.createRequest(bookingCity, booking, user, vehicle).then((result) => {

            console.log(result);

            // update booking with request
            FirebaseUtils.updateBooking(booking['Booking No'], true, result.id).then(() => {

                callback();

                // Update vehicle with allocation
                let newBooking = {
                    requestId: result.id,
                    ...{
                        created_by: { name: user.name, role: user.role },
                    },
                    ...{
                        // customer: customer, // is customer required here
                        created_at: moment().format('DD/MM/YYYY HH:mm')
                    },
                    ...{
                        
                         deleted_at:null,
                         deleted_by:null,
                    }
                };

                FirebaseUtils.blockVehicle(vehicle['Vin Number'], newBooking).then((result) => {

                    message.info('Blocked Successfully');

                });

            })
        });
    }

    return (<div className="vehicle-card" >
        <div className="card">

            <h2 className="title amount ">
                {vehicle['Variant']} -
                <small>
                    {vehicle['Exterior Color Name']}
                </small>
            </h2>
            <h4 className="title">
                {vehicle['Cust Name']}
            </h4>


            <h3 className="title">
                {vehicle['Booking No']}
            </h3>

            <h4>
                {vehicle['Vin Number']}
            </h4>

            <p className="address">
                {vehicle['matching']['Contact Number']}
                {/* {vehicle.customer.remarks} */}
                {/* <Tag color="magenta">{vehicle.status || 'Initial'}</Tag> */}
            </p>


            <Button onClick={() => createRequest({ booking: vehicle.matching })} type="secondary">
                Create Request
            </Button>


            {/* Created at {vehicle.created_at} */}
        </div>
    </div>)
}
