
import React, { useState, useEffect, useContext } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

import CustomerList from './components/customer-list/customer-list';
import WarrantyForm from './components/warranty-form/warranty-form';
// import StockDashboard from './components/stock-dashboard/stock-dashboard';

import TaskRoutes from './../common/resources/task-routes/task-routes';
import ChangePassword from "./../common/components/change-password/change-password";

import "./warranty-landing.scss";

import { GlobalContext } from '../../Store';

import { warrantySteps, requestColumns } from './warranty-config';

import GenericRequest from './../common/resources/generic-request/generic-request';

import TaskOverview from './../common/resources/task-overview/task-overview';


import Profile from './../common/resources/profile/profile';

export default function InsuranceLanding(props) {

  const { user } = useContext(GlobalContext);

  useEffect(() => {


  }, [])

  const queries = [{
    field: 'warranty.process',
    value: 'incomplete'
  }]

  const modules = [
    {
      caption: "Customers",
      path: `/${user.locations[0]}/customers`,
    },

    {
      caption: "Tasks",
      submenus: warrantySteps.filter((step) => !step.isForm)
    },
    {
      caption: "Change Password",
      path: '/change-password',
    },
  ]

  return (
    <section className="landing">

      <GlobalHeader modules={modules} user={user} history={props.history}>


        <Switch>

          <Route exact path={'/'} component={() => <TaskOverview steps={warrantySteps} />}></Route>

          <Route path="/:city/customers" component={() => <GenericRequest columns={requestColumns} queries={queries} />} />

          <Route path="/requests/:id" component={({ ...routeParams }) => <WarrantyForm step={{ fields: [] }} {...routeParams} columns={requestColumns} queries={queries} />} />
          <Route excat path={'/change-password'} component={ChangePassword}></Route>

          {/* Maintaining listing page for different status */}
          <TaskRoutes steps={warrantySteps} list={CustomerList} form={WarrantyForm} />

        </Switch>

      </GlobalHeader>


    </section>
  );
}
