import React, { useEffect, useState, useContext } from 'react'

import XLSX from 'xlsx';

import { Space, Switch, message, Tabs, Typography, Alert, Button, Upload, Form, Input, Select } from 'antd';

import { GlobalContext } from '../../../../Store';

import { DownOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Collapse } from 'antd';

import { List } from 'antd';

import './compare-sheets.scss';


const { Panel } = Collapse;


const { TabPane } = Tabs;

const { Text, Title } = Typography;



export default function CompareSheets({ history, report, index }) {

    const [records, setRecords] = useState({
        dms: {},
        dpos: {}
    });

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [files, setFiles] = useState([])

    const [form] = Form.useForm();


    const [location, setLocation] = useState(user.locations[0]);

    const [newparts, setNewparts] = useState({});

    useEffect(() => {


    }, [user])



    var groupBy = function (xs, key) {

        return xs.reduce(function (rv, x) {

            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;

        }, {});
    };



    function handleFile(f) {

        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {

            const bstr = e.target.result;

            // let data = csvJSON(bstr);

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            console.log(wb, wb.SheetNames);

            const ws = wb.Sheets[wsname];

            const dms = wb.Sheets[wb.SheetNames[1]];


            const dpos = XLSX.utils.sheet_to_json(ws, {
                // header: 0
                // range:0

                header: 2,
                range: 0
            });


            const dmsData = XLSX.utils.sheet_to_json(dms, {
                // header: 0
                // range:0

                header: 2,
                range: 0
            });


            // console.log(data, dmsData);

            processData(dpos, dmsData);

        };

        if (rABS) reader.readAsBinaryString(f); else reader.readAsArrayBuffer(f);
    }


    function processData(dpos, dms) {

        var dposBills = groupBy(dpos, 'Invoice No RO Bill No');

        var dmsBills = groupBy(dms, 'Invoice No');

        let dposData = {};

        console.log('DPOS BIlls :' + Object.keys(dposBills).length, dposBills, 'DMS Bills:' + Object.keys(dmsBills).length, dmsBills);

        console.log('Differences');

        Object.keys(dposBills).forEach((inv) => {

            let invoice = inv.trim();




            if (invoice && dmsBills[invoice] && dmsBills[invoice].length) {

                console.log(invoice, 'DPOS:' + dposBills[inv].length, 'DMS:' + dmsBills[invoice].length)

                dposBills[inv].matching = dmsBills[invoice];

                dposData[invoice] = {
                    results: dposBills[inv],
                    matching: dmsBills[invoice]
                };

            } else {

                dposData[invoice] = {
                    results: dposBills[inv],
                    matching: []
                };

                console.log(invoice, 'DPOS:' + dposBills[inv].length, 'DMS: Missing')

                dposBills[inv].matching = [];

            }
        })

        console.log(dposData);



        // Object.keys(dmsBills).forEach((inv) => {

        //     let invoice = inv.trim();

        //     if (invoice && dmsBills[invoice] && dmsBills[invoice].length) {

        //         console.log(invoice, 'DPOS:' + dposBills[inv].length, 'DMS:' + dmsBills[invoice].length)

        //         dposBills[inv].matching = dmsBills[index];

        //     } else {

        //         console.log(invoice, 'DPOS:' + dposBills[inv].length, 'DMS: Missing')

        //         dposBills[inv].matching = [];

        //     }

        // })




        setRecords({
            dpos: dposData,
            // dms: dmsBills
        });


    }






    const uploadProps = {
        onRemove: file => {

            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });

        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: file => {

            setFiles([...files, file]);

            return false;
        },
        files,
    };

    const SheetJSFT = [
        "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
    ].map(function (x) { return "." + x; }).join(",");



    return (
        <div className="compare-sheets">

            <div className="page-header">

                <div className="left">
                    <Title level={4}>

                        Compare Sheets

                    </Title>

                </div>

                <div className="table-hint right">

                    <div className='page-actions'>

                        {
                            loading ?
                                <Text type="secondary">
                                    Loading Accessories
                            </Text> :
                                null
                        }


                        <div className="button-container">

                            <>


                                <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                    <Button size={'small'} icon={<UploadOutlined />}>Select File</Button>
                                </Upload>

                            </>


                        </div>

                    </div>

                </div>


            </div>




            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <>

                        <div className="main-content">


                            <div className="vehicle-card card">


                                {
                                    Object.keys(records['dpos']).length
                                        ?
                                        <>

                                            <Title level={3}>

                                                Summary
</Title>


                                            <Collapse defaultActiveKey={['0']}>

                                                {
                                                    Object.keys(records['dpos']).map((index) =>
                                                        <Panel header={Thumbnail({ index, records: records['dpos'][index] })}>

                                                            <Tabs>

                                                                <TabPane tab={`In DPOS (${records['dpos'][index].results.length})`} key="0">

                                                                    <List
                                                                        bordered
                                                                        dataSource={records['dpos'][index].results}
                                                                        renderItem={item => (
                                                                            <List.Item>
                                                                                <Typography.Text>{item['Part Name']}</Typography.Text>

                        - {item['Work Type']}
                                                                            </List.Item>
                                                                        )}
                                                                    />


                                                                </TabPane>

                                                                <TabPane tab={`In DMS (${records['dpos'][index].matching.length})`} key="1">


                                                                    <List
                                                                        bordered
                                                                        dataSource={records['dpos'][index].matching}
                                                                        renderItem={item => (
                                                                            <List.Item>
                                                                                <Typography.Text>{item['Type of Party']} {item['Part desc']||item['Labour desc']}</Typography.Text>

                                    - {item['Work Type']}
                                                                            </List.Item>
                                                                        )}
                                                                    />


                                                                </TabPane>

                                                            </Tabs>

                                                        </Panel>)
                                                }
                                            </Collapse>

                                        </>
                                        :
                                        <>

                                            <Alert message="This page is built to help you identify the differences in DPOS and DMS Reports for Spares & Accessories in favour of GST Filing. This would work only for an input excel file with first Sheet as report from DPOS and the second from DMS. Any other file input would give unexpected results." type="info" showIcon />

                                        </>
                                }



                            </div>



                            {/* <div className="vehicle-card">

                                <Title level={3}>

                                    DMS Report
                                </Title>

                                <Collapse>

                                    {
                                        Object.keys(records['dms']).map((index) =>
                                            <Panel header={Thumbnail({ index, records: records['dms'][index] })}>

                                                <List
                                                    bordered
                                                    dataSource={records['dms'][index]}
                                                    renderItem={item => (
                                                        <List.Item>
                                                            <Typography.Text>{item['Customer Name']}</Typography.Text>

                                                            - {item['Work Type']}
                                                        </List.Item>
                                                    )}
                                                />

                                            </Panel>)
                                    }

                                </Collapse>

                            </div> */}






                        </div>
                    </>
            }
        </div>
    )
}




function Thumbnail({ index, records }) {
    return (<>{index} - <small>({records.results.length} in DPOS) - ({records.matching.length} in DMS)</small></>);
}