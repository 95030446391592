/**
 * Custom Component to update status of booking for kec
 */

import React, { useEffect, useState } from 'react';

import {Form,Select,Button, Input} from 'antd'

import { StatusLogs, StatusMasters,Bookings } from '../../../models';

import { useHistory } from 'react-router-dom';


const { Option } = Select;

const { TextArea } = Input;

export default function UpdateStatusForm({formContent}) {

    //state to disable and enable button
    const [disabled, setDisabled] = useState('true');

    // state for status options
    const [status, setStatus] = useState([]);

      //state to manage button loading
      const [btnloading, setBtnLoading] = useState(false);

      let history = useHistory();

    useEffect(() => {
        getOptions()
    }, [])

    /**
    * Get all the status options
    */
    async function getOptions() {
        let queries = [
            {
                field: 'mode',
                value: 'BOOKING',
            },
        ];

        const result = await StatusMasters.get(queries);
        setStatus(result.status_masters || []);
    }



    async function updateStatus(values) {

        let formBody = {
            "status_id": values.status_id,
            "remarks": values.remarks ? values.remarks : null,
        }

        let matchingStatus = status.filter((record) => record.id === values.status_id)[0];

        setBtnLoading(true);

        // add status long 
        await StatusLogs.add(
            {
               
                ...formBody,
                status_name: matchingStatus.name,
            },
            {
                hideAlert: true,
            }
        );

       

        await Bookings.update(formContent['Booking No'], {
            current: {
                ...formContent.current,
                currentStatus: matchingStatus.name,
                currentStatus_id: matchingStatus.id,
                status: 'Status Updated',
               
            },

        })
       
        setBtnLoading(false);

        history.goBack()
        // callback(matchingStatus);

       
    }
    return (
        <div>
            <Form
                onFieldsChange={(fields) => {
                    // Enable submit button except current status is ALLOCATED 
                    // Enable submit button  while changing the status
                    if ((fields[0].name[0] === 'status_id')){
                        setDisabled(false);
                    }
                }}
                onFinish={updateStatus}
             
                name="status-change-form"
                layout="vertical"
                label="update status"
            >
                <Form.Item
                    name="status_id"
                    label="Status"
                    rules={[
                        {
                            required: true,

                            message: 'Please select status',
                        },
                    ]}
                >
                    <Select disabled={formContent.current.currentStatus == 'ALLOCATED' }>
                        {status.map((data, key) => (
                            <Option value={data.id}>{data.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="remarks"
                    label="Remarks"
                    rules={[
                        {
                            required: false,
                        },

                        {
                            max: 250,

                            message: 'Length cannot exceed 250 characters !',
                        },
                    ]}
                >
                    <TextArea rows={4} />
                </Form.Item>

                <Button disabled={disabled} loading={btnloading} htmlType="submit" type="primary" size="small">
                    Submit
                </Button>
            </Form>
        </div>
    )
}