import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

import { pdiSteps } from './pdi-config';

import TaskOverview from './../common/resources/task-overview/task-overview';

import { partsSchema, requestSchema, partsColumns } from './../global-config';

import TransitVehicles from './components/pdi-list/pdi-list';
import VehicleDetail from './components/pdi-form/pdi-form';

import VehicleDetails from '../../components/vehicle-detail/vehicle-detail';
import ChangePassword from './../common/components/change-password/change-password';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import ModuleRoutes from './../common/resources/modules-routes/module-routes';

import './pdi-landing.scss';

import { GlobalContext } from '../../Store';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';

import ManageStock from '../common/components/manage-stock/manage-stock';

import StockOutwardDetails from './components/stock-outward/stock-outward';

export default function AccessoriesLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    const queries = [
        {
            field: 'accessories.process',
            value: 'incomplete',
        },
    ];

    // Modules / Menus for this User
    const modules = [
        {
            caption: 'Tasks',
            submenus: pdiSteps.filter((step) => !step.isForm),
        },

        {
            caption: 'Manage',
            submenus: [
                {
                    caption: 'Manage Stock',
                    path: '/manage-stock',
                },
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => (
                            <>
                                <NoticeBoard />
                                <TaskOverview steps={pdiSteps} />
                            </>
                        )}
                    />
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>

                    <PrivateRoute exact path="/manage-stock" component={ManageStock} userRole={'admin'} />

                    <PrivateRoute exact path="/vehicle/:id" component={VehicleDetails} userRole={'admin'} />

                    <Route exact path={'/profile'} component={Profile}></Route>

                    <Route excat path="/stock-outward/:id" component={StockOutwardDetails}></Route>


                    <TaskRoutes steps={pdiSteps} list={TransitVehicles} form={VehicleDetail}/>
                </Switch>
            </GlobalHeader>
        </section>
    );
}
