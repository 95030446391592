/**
 * Stock Register
 */

import React, { useState, useEffect, useContext } from "react"

import { Table, Card, Statistic, Row, Col, Typography } from 'antd';

import { withRouter } from 'react-router-dom';

import Rupee from '../../../../components/rupee/rupee'




// import './form-creator.scss'

import FirebaseUtils from './../../../../utils/firebase.utils';
import { StockPoints } from "../../../../models";

import { ExportReactCSV } from "../generic/generic-list/ExportReactCSV";

const options = {
    page: 1,
    current: 1,
    pageSize: 20,
}

const { Title, Text } = Typography;




/**
 * 
 * 
 * @param {*} param0 
 * @returns 
 */
function StockRegister({
    match
}) {

    const [filterChoices, setFilterChoices] = useState([]);

    const [stockPoints, setStockPoints] = useState([])

    const [expRecord, setExptRecords] = useState();

    const [loading, setLoading] = useState(false);

    const { params } = match;

    const { city, id } = params;

    const [content, setContent] = useState({ vehicles: [] });

    const [total, setTotal] = useState(0);

    useEffect(() => {

        setLoading(true);

        FirebaseUtils.getRecord('stock-register', id).then((result) => {

            console.log(result);

            setLoading(false);

            setContent(result);

            var val = 0;

            result.vehicles.forEach((vehicle) => {

                val += parseFloat(vehicle.vehicleValue);


            })

            FiteringValue(result.vehicles);

            setTotal(val);

        })

        getYardDetails(city)

    }, []);


    /**
    * Function to load stock points
    *
    * @param {*} loc
    */
    async function getYardDetails(city) {

        var queries = [{
            field: "location",
            value: city
        }]

        await StockPoints.get(queries).then((res) => {
            setStockPoints(res['stock_points'] || []);
        });
    }


    const FiteringValue = (data) => {

        let variantOptions = []

        let modelOtions = [];

        let arrKEC = [];
        let arrKEC1 = [];

        let arrTL = [];
        let arrTL1 = [];

        let arrVar = [];
        let arrVar1 = [];

        let arrUS = [];
        let arrUS1 = [];


        data.map((ele) => {

            // Variant 
            if (ele['Variant'] && variantOptions.includes(ele['Variant']) === false) {
                variantOptions.push(ele['Variant'])
                variantOptions.push({ text: ele['Variant'], value: ele['Variant'] })
            }


            if (ele['Model'] && modelOtions.includes(ele['Model']) === false) {
                modelOtions.push(ele['Model'])
                modelOtions.push({ text: ele['Model'], value: ele['Model'] })
            }

        })

        let body = [
            variantOptions,
            modelOtions
        ]

        setFilterChoices(body);
    }

    useEffect(() => {
        getExportData(content['vehicles'], columns);
    }, [content['vehicles'], stockPoints]);



    const columns = [

        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            disableExport: true
        },

        {
            type: 'input',
            title: 'Vin Number',
            dataIndex: 'Vin Number'
        },

        {
            dataIndex: 'Model',
            type: 'input',
            title: 'Model',
            filters: filterChoices && filterChoices[1],

            onFilter: (value, record) => record['Model'].indexOf(value) === 0,

        },

        {
            dataIndex: 'Variant',
            type: 'input',
            title: 'Variant',
            filters: filterChoices && filterChoices[0],

            onFilter: (value, record) => record['Variant'].indexOf(value) === 0,

        },
        // {
        //     dataIndex: 'Stock Status',
        //     type: 'input',
        //     title: 'Stock Status'
        // },
        {
            dataIndex: 'Exterior Color Name',
            type: 'input',
            title: 'Color'
        },

        {
            title: 'Color Type',
            dataIndex: 'Color Type',
            type: 'input',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            // type: 'input',
            exportDefinition: (vehicle) => {
                let matchingStockPoint = stockPoints.filter((record) => record.id === vehicle.stock_point_id);

                if (matchingStockPoint && matchingStockPoint.length) {
                    return matchingStockPoint[0].name;
                } else {
                    return '';
                }
            },
            render: (value, vehicle) => {

                var matchingStockPoint
                if (vehicle.stock_point_id) {

                    matchingStockPoint = stockPoints.filter((record) => record.id === vehicle.stock_point_id)
                    return (
                        <p>
                            {

                                matchingStockPoint.length > 0 ?

                                    matchingStockPoint[0].name : null

                            }
                        </p>

                    )
                }


            },
        },

        {
            title: 'Stock Value',
            dataIndex: 'Basic Price',
            type: 'input',
        },

        {
            title: 'Invoice Date',
            dataIndex: 'KIN Invoice Date',
            type: 'input',
        },

        // {
        //     title: 'Stock Value',
        //     dataIndex: 'vehicleValue',
        //     type: 'input',
        // },


        // {
        //     title: 'Stock Value+Cess',
        //     dataIndex: 'taxValue',
        //     type: 'input',
        // },
    ]



    async function getExportData(records, columns) {
        let filteredColumns = columns.filter((col) => !col.disableExport);

        const arr = await Promise.all(
            records.map((row, index) => {
                let entry = {
                    'Sl No': index + 1,
                };

                filteredColumns.forEach((column, index) => {
                    if (column.exportDefinition) {
                        entry[column.title] = column.exportDefinition(row, index, index);
                    } else if (column.render) {
                        entry[column.title] = column.render(row, index, index);
                    } else if (column.dataIndex) {
                        entry[column.title] = row[column.dataIndex];
                    } else if (column.field) {
                        entry[column.title] = row[column.field];
                    } else {
                        entry[column.title] = row[column.field];
                    }
                });

                return entry;
            })
        );

        setExptRecords(arr);
    }



    return (
        <section className="stock-register">


            <Title level={5}>

                Stock Register
            </Title>


            <Row gutter={16}>

                <Col span={6} xs={16} md={6}>

                    <div className="card">
                        <Statistic formatter={(total) => {

                            return <div>{content.txn_date}</div>

                        }} title={'Stock Date'} />
                    </div>
                </Col>


                <Col span={6} xs={16} md={6}>

                    <div className="card">
                        <Statistic formatter={(total) => {

                            return <div>{content.vehicles.length}</div>

                        }} title={'Vehicles'} />
                    </div>
                </Col>


                <Col span={6} xs={16} md={6}>

                    <div className="card">
                        <Statistic formatter={() => {

                            return <Rupee value={total} />

                        }} title={'Vehicle Value'} />
                    </div>
                </Col>

                <Col span={6} xs={16} md={6}>

                    <div className="card">
                        <Statistic formatter={(total) => {

                            return <Rupee value={content.stockValue} />

                        }} title={'Stock Value(Including Cess)'} />
                    </div>
                </Col>

            </Row>


            <Card title={`Stock Register`} size="small">
                <div style={{ float: "right" }}>
                    <div className="page-actions">{expRecord && <ExportReactCSV csvData={expRecord} />}</div>

                </div>


                <Table
                    loading={loading}
                    size="small"
                    rowKey={record => record.id}
                    // rowKey={record => record[model + 'Id']}
                    dataSource={content['vehicles']}
                    columns={columns}
                    // onChange={handleTableChange}
                    pagination={false}
                    summary={(pageData) => {

                        console.log(pageData);

                        var total = 0;

                        var basicTotal = 0;

                        var cessTotal = 0;

                        pageData.forEach((entry) => {

                            total += parseFloat(entry.vehicleValue);

                            basicTotal += parseFloat(entry['Basic Price']);

                            cessTotal += parseFloat(entry.taxValue)


                        })


                        return (<>
                            <Table.Summary.Row>

                                <Table.Summary.Cell>

                                </Table.Summary.Cell>

                                <Table.Summary.Cell>

                                </Table.Summary.Cell>

                                <Table.Summary.Cell>

                                </Table.Summary.Cell>


                                <Table.Summary.Cell>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell>

                                </Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <strong>Total</strong>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell>

                                    <strong>{basicTotal.toFixed(2)}</strong>

                                </Table.Summary.Cell>

                                <Table.Summary.Cell>

                                </Table.Summary.Cell>

                                {/* <Table.Summary.Cell>

                                    <strong>

                                        {total.toFixed(2)}
                                    </strong>

                                </Table.Summary.Cell> */}

                                {/* <Table.Summary.Cell>

                                    <strong>

                                        {cessTotal.toFixed(2)}
                                    </strong>

                                </Table.Summary.Cell> */}

                            </Table.Summary.Row>
                        </>)


                    }}

                >
                </Table>
            </Card>


        </section>
    );
}

export default withRouter(StockRegister);



