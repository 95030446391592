
/**
 * Generic Detail accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from "react"

import { Link } from 'react-router-dom'

import { Typography, Button, Descriptions, Input, Switch, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom'

import './generic-detail.scss'

import FirebaseUtils from './../../../../../utils/firebase.utils';
import { GetData } from './../../../../../utils/http.utils';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import useDeviceDetect from './../../../../../hooks/device-detect';

import PlaceHolder from './../../../../../components/ui_elements/PlaceHolder';



import RecordForm from './../../../../common/resources/record-form/record-form';

import { GlobalContext } from './../../../../../Store';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { Title } = Typography;

const { TextArea } = Input;

/**
 * Generic List 
 * 
 * @param {*} param0 
 */
function GenericDetail({
    match,
    model,
    collection,
    columns: cols,
    list,
    schema = { custom_actions: [] },
    isDirect = schema.isDirect,
    disableEdit = schema.disableEdit,

}) {

    const [record, setRecords] = useState([]);

    const { isMobile } = useDeviceDetect();

    const { user } = useContext(GlobalContext);

    // const city = user.locations[0];

    const { params } = match;

    const { city } = params;

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        loadData();

    }, []);

    const loadData = () => {

        setLoading(true);

        getRecords(schema)





    }

    function getFirebaseData() {
        if (isDirect) {

            FirebaseUtils.getRecord(model, match.params.id).then((result) => {

                console.log(result);

                setRecords(result);

                setLoading(false);

            })

        } else {

            FirebaseUtils.getListingRecord(match.params.id, model).then((result) => {

                console.log(result.data());

                setRecords(result.data());

                setLoading(false);

            })
        }
    }

    function getNodeData(schema) {


        GetData({ url: schema.endPoint + '/' + match.params.id }).then((result) => {


            console.log(result);

            setLoading(false);

            setRecords({ [model]: result });

            // setVouchers(result);

        })

    }


    const getRecords = (schema) => {

        console.log(schema);
        switch (schema.node) {
            case 'nest':

                return getNodeData(schema);
            default:
                return getFirebaseData(schema);
        }
    }


    function refresh() {

        loadData();
    }

    return (
        <section className="generic-list">

            {/* Table Header */}
            <div className="table-header">

                <div className="table-title">
                    <Title level={4}>
                        {model}
                    </Title>
                </div>

                <div className="table-actions">

                    <div className="button-container">

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>

                        {
                            disableEdit
                                ?
                                null
                                :
                                model === 'items' ?
                                    record.category !== 'Accessories'?
                                        <Space size="middle">
                                            <Button type="dashed">
                                                <Link to={`/${city}/${model}/${match.params.id}/items-edit`}>
                                                    Edit
                                                </Link>
                                            </Button>
                                        </Space>
                                        :null
                                    :
                                    <Space size="middle">
                                        <Button type="dashed">
                                            <Link to={`/${city}/${model}/${match.params.id}/edit`}>
                                                Edit
                                            </Link>
                                        </Button>
                                    </Space>
                        }

                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <>

                    <div className="card">

                        <Descriptions title="Detail">

                            {
                                Object.keys(record).map((field) => {

                                    if (record[field]) {


                                        if (typeof record[field] === 'string' || typeof record[field] === 'number') {


                                            return <Descriptions.Item label={field}>{record[field]}</Descriptions.Item>

                                        } else if (typeof record[field] === 'object') {

                                            let nested = record[field];

                                            Object.keys(record[field]).map((entry) => {

                                                return <Descriptions.Item label={entry}>{record[field][entry]}</Descriptions.Item>

                                            })


                                        } else {
                                            console.log(field, record[field]);
                                        }

                                    }


                                })
                            }

                            {/* {cols.map((column) => <Descriptions.Item label={column.caption}>{record[column.field]}</Descriptions.Item>)} */}

                        </Descriptions>

                        {/* 
                            <RecordForm
                                record={record[model]}
                                // index={id}
                                // formContent={{}}
                                // modelIndex='requestId'
                                model={schema.model}
                                step={schema.form}
                                callback={(params, values) => {

                                }}
                            /> */}

                    </div>

                </>
            }

        </section>
    );
}

export default withRouter(GenericDetail);
