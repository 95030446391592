import React from "react";
import { CSVLink } from "react-csv";
import { Button } from "antd";

export const ExportXLS = ({ csvData, fileName }) => {
//  csvdata
  
  return (
    <Button variant="warning" size='small' >
      {csvData && (
        <CSVLink data={csvData} filename={fileName}>
          Download
        </CSVLink>
      )}
    </Button> 
  );
};
