/**************************************************
 * Get Pending Matching Receipts
 *************************************************/

import FirebaseUtils from './firebase.utils';


/**
 * Calculate number of  matching receipts that are to be reconciled
 * @param {*} newReceipts - Bank records that are not debit
 */
export function getPendingMatchingReceipts(newReceipts) {
    var results = {};

    var queries = [
        {
            field: 'pending_to_reconcile',
            value: true
        },
        {
            field: 'verified',
            value: 'approved'
            // operator: 'in',
            // value: ['approved', false]
        },

        {
            field: 'source',
            operator: 'in',
            value: ['kec', 'cashier', 'finance', 'sm', 'tl', 'KIA Website']
        },

    ]


    return FirebaseUtils.getAllCollections(queries).then((result) => {


        newReceipts.forEach((receipt) => {

            receipt.matching = [];

            result.all.forEach((entry) => {

                var condition;

                if (((entry.utr !== '') || (entry.ref !== '')) && (entry.amount === parseInt(receipt['credit']))) {

                    condition = (receipt['description'].indexOf(entry.ref) !== -1) || (receipt['description'].indexOf(entry.utr) !== -1) || (receipt['ref'].indexOf(entry.utr) !== -1) || (receipt['ref'].indexOf(entry.ref) !== -1);

                    if (condition && entry.type !== 'Debit Card' && entry.type !== 'Credit Card') {

                        // Push the entries to matching
                        receipt.matching.push(entry);

                        receipt.pending_at = 'match';

                    }
                }
            })
        });

        var grouped = groupBy(newReceipts, 'pending_at');
        return grouped;
    });
}

var groupBy = function (xs, key) {

    return xs.reduce(function (rv, x) {

        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;

    }, {});
};
