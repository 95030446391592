import Base from './base';

class Branch extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'branches';
    }

    get path() {
        return `branches`;
    }


    /**
    * Get all dealercode
    *
    * @param {*} 
    * @returns
    */
    getAllBranches = () => {
        return this.get()
            .then((result) => {
                let arr = result.branches.map((entry) => entry.dealer_code);
                arr.sort(); // sorting by dealercode
                return arr;
            });
    };
}    


export default Branch;
