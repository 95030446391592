
import React, { useState, useEffect, useContext } from "react";

import { Alert } from "antd";

import "./sync-status.scss";

import { GlobalContext } from './../../../../Store';

import FirebaseUtils from './../../../../utils/firebase.utils';

import moment from 'moment';


export default function SyncStatus(props) {

    const { user } = useContext(GlobalContext)

    const [modules, setModules] = useState({});

    let location = user.locations[0];

    useEffect(() => {
        loadStatus()
    }, []);

    function loadStatus() {

        FirebaseUtils.loadAllStatus().then((result) => {
            console.log(result);

            console.log(Object.keys(result.models))

            setModules({ ...result.models });
        })

    }

    return (
        <section className="landing">

            <Alert
                message="Important Message"
                description="You play a key role in our system by keeping the DMS in sync with this application. Please sync the below modules at frequest intervals."
                type="info"
                showIcon
            />

            {
                Object.keys(modules).map((module) => {
                    return (<div className="card">
                        <h2 className="title">
                            {module}
                        </h2>
                        <h3 className="title">

                            {/* {modules[module].timestamp} */}
                            {/* {report.phone} */}
                        </h3>

                        <p className="address">
                            Last Updated At {modules[module] && modules[module].created_at}

                            {/* {report.account}, {report.location} */}
                        </p>

                        <h2 className="title amount">

                            {
                                modules[module] ? moment(modules[module].created_time).fromNow() : null
                            }

                            {/* {report.credit ? 'Rs' : ''} {report.credit} */}
                        </h2>

                        <p className="">
                            {/* Credited on {report.date} , KEC <strong>{report.kec}</strong> */}
                        </p>

                    </div>)
                })
            }

            {/* 
      <Tabs defaultActiveKey={`${stockLocation[location]}`}>

        <TabPane tab="All" key="1">
          <CurrentStock url="all" />
        </TabPane>


        <TabPane tab="KOZHIKODE" key="2">
          <CurrentStock url="kozhikode" />
        </TabPane>

        <TabPane tab="MALAPPURAM" key="3">
          <CurrentStock url="malappuram" />
        </TabPane>


        <TabPane tab="KANNUR" key="4">
          <CurrentStock url="kannur" />
        </TabPane>

      </Tabs> */}
        </section>
    );
}
