
import React, { useState, useEffect, useContext } from "react";

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';



import moment from 'moment';

import { Link } from 'react-router-dom'

import { Tag, Badge } from 'antd'




/**
 * Columns for Request Table
 */
export const requestColumns = [

    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName'
    },
    {
        type: 'input',
        caption: 'Part Name',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'warranty.status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant'
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
]





function AllocationCard({ vehicle, index, step }) {

    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" to={`/${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">
                    {vehicle.rto && (vehicle.rto.hsrp_no || vehicle.rto.remarks)}
                </h2>

                <h3 className="title">
                    {vehicle.customer.customerName}
                </h3>

                <h2 className="title">
                    {vehicle.customer.phone}

                </h2>


                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}

                </h3>


                <p className="address">
                    KEC on {vehicle.kec}
                </p>

                {
                    waitingTime && waitingTime.toString().length > 0 ?
                        <p className="date-time" style={{ display: "flex" }}>
                            Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (<div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                        </p>
                        :
                        null
                }


                {/* <Tag color="magenta">{vehicle.status}</Tag> */}

                {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                }



            </div>
        </Link>
    )
}




export const receptionistSteps = [
    {
        path: '/hsrp-received',
        caption: 'HSRP Receival Pending',
        redirect_to: 'hsrp-upload',
        queries: [{
            field: 'rto.status',
            value: 'HSRP Receival Pending'
        },
        {
            field: 'status',
            operator: '!=',
            value:'removed'
        }],
        Card: AllocationCard,
        field_time: 'rto.hsrp_dispatched_date'

    },

    // Upload HSRP Photo
    {
        isForm: true,
        caption: 'Upload HSRP',
        path: '/hsrp-upload/:city/:id',
        model: 'rto',
        post_status: 'HSRP Fitment Pending',
        fields: [
            // {
            //     type: 'upload',
            //     caption: 'Upload Photo',
            //     field: 'hsrp-photo'
            // },
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks'
            },
        ],
        extraParams: (request, params) => {

            let creation = {
                hsrp_received_at: moment().format('DD/MM/YYYY HH:mm'),
                hsrp_received_date: moment().startOf('day').valueOf(),
                hsrp_received_time: moment().valueOf()
            }

            return {
                rto: {
                    ...request.rto,
                    ...creation,
                    ...params,
                },
            }
        },
    },
]



