// import React from "react";

import moment from 'moment';

import { capitalizeStrings, getFormattedDateAndTime } from '../../utils/common.utils';

import { Link } from 'react-router-dom';

import React, { useEffect, } from 'react';

import FirebaseUtils from './../../utils/firebase.utils';



function AllocationCard({ vehicle, index, step }) {


    return (
        <Link className="report-item" to={`/${step.redirect_to}/${vehicle.id}`}>
            <div className="card">
                <h2 className="title amount">
                    {vehicle['Booking No']}
                </h2>


                <h2 className="title">
                    {/* {vehicle.customer.phone} */}
                    {vehicle['Vin Number']}
                </h2>

                <h3 className="title">
                    {vehicle['Model']}
                </h3>

                <h3 className="title">
                    {vehicle['Variant']} - {vehicle['Exterior Color Name']}
                </h3>

            </div>
        </Link>
    )
}


/**
 * 
 * @param {*} param0 
 * @returns 
 */
function StockOutward({ vehicle, index, step }) {


    return (
        <Link className="report-item" to={`/${step.redirect_to}/${vehicle.id}`}>
            <div className="card">
                <h2 className="title amount">
                    {vehicle['bookingNo']}
                </h2>


                <h2 className="title">
                    {vehicle['vinNo']}
                </h2>

                <h3 className="Created_at">
                    {vehicle['created_at']}
                </h3>

                {/* <h3 className="title">
                    {vehicle['Variant']} - {vehicle['Exterior Color Name']}
                </h3> */}

            </div>
        </Link>
    )
}

/**
 * Steps for Accessories
 */
export const pdiSteps = [


    // 
    {
        caption: 'Complete PDI',
        path: '/in-transit',
        status: 'In transit',
        model: 'vehicles',
        redirect_to: 'in-transit-form',
        queries: [
            {
                field: 'update',
                value: 'remove',
                operator: '!=',
            },


            // {
            //     field: 'update',
            //     value: ['new', 'status'],
            //     operator: '==',
            // },
            // {
            //     field: 'Stock Status',
            //     value: 'In transit'
            // },
            {
                field: 'pdi.status',
                value: 'pending'
            }
        ],
        Card: AllocationCard,
    },


    // List used for stock outward .
    {
        caption: 'Stock Transfer Request(outward)',
        path: '/stock-transfer-outward',
        status: 'requested',
        model: 'stock_transfers',
        redirect_to: 'stock-outward',

        queries: ({ user }) => {
            return [
                {
                    field: 'from_stock_point_id',
                    operator:'in',
                    value: user.attributes.stock_point_id,
                },
                {
                    field: 'status',
                    value: 'outward-pending',
                },
                {
                    field:'sub_mode',
                    value:'normal'
                }
            ];
        },

        Card: StockOutward,
    },


    {
        caption: 'Stock Transfer Request(inward)',
        path: '/stock-transfer-inward',
        status: 'Approve',
        model: 'stock_transfers',
        redirect_to: 'stock-outward',
        queries: ({ user }) => {
            return [
                {
                    field: 'to_stock_point_id',
                    operator:"in",
                    value: user.attributes.stock_point_id,
                },
                {
                    field: 'status',
                    value: 'outward-done',
                },
                {
                    field:'sub_mode',
                    value:'normal'
                }
            ];
        },
        Card: StockOutward,
    },
    // 

    // Data Collection Form
    {
        isForm: true,
        caption: 'Form for update status',
        endpoint: 'vehicles',
        model: 'pdi',
        path: '/in-transit-form/:id',
        post_status: 'completed',
        fields: [
            {
                type: 'upload',
                field: 'stencil_print',
                caption: 'Stencil Print'
            },

            {
                type: 'upload',
                field: 'form_22',
                caption: 'Form 22'
            }, {
                type: 'stock-pointer',
                field: 'stock_point_id',
                caption: 'Yard',


            }

        ],
        extraParams: (vehicle, params) => {

            let creation = {
                added_at: moment().format('DD/MM/YYYY HH:mm'),
                added_date: moment().startOf('day').valueOf(),
                added_time: moment().valueOf()
            }

            return {
                pdi: {
                    ...creation,
                    ...params,
                }
            }
        },
    },
]