import React, { useContext, useEffect, useState } from 'react';

import { Tag, Badge, message } from 'antd';

import { Link } from 'react-router-dom';

import moment from 'moment';
import FirebaseUtils from '../../utils/firebase.utils';

import { capitalizeStrings, getFormattedDateAndTime } from '../../utils/common.utils';
import { DocumentLogs, Enquirys } from '../../models';

let next_follow_up_date = moment.tz('Asia/Calcutta').startOf('day').valueOf()

function AllocationCard({ vehicle, index, step }) {
    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" to={`/${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">
                    {vehicle.delivery.scheduled_delivery_date ? (
                        <>
                            {/* {vehicle.delivery.scheduled_delivery_date} - {vehicle.delivery.scheduled_delivery_time} */}
                            {moment(vehicle.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A')}
                        </>
                    ) : (
                        'Pending'
                    )}
                </h2>

                <h3 className="title">{vehicle.customer.customerName}</h3>

                {/* <h2 className="title">{vehicle.customer.phone}</h2> */}

                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}
                </h3>

                <p className="address">KEC on {vehicle.kec}</p>

                {waitingTime && waitingTime.toString().length > 0 ? (
                    <p className="date-time" style={{ display: 'flex' }}>
                        Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (
                        <div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                    </p>
                ) : null}

                {vehicle.delivery.schedule == 'pending' ? (
                    <Tag color="gold">{vehicle.delivery.schedule}</Tag>
                ) : (
                    <Tag color="magenta">{vehicle.delivery.schedule}</Tag>
                )}

                {vehicle.finance && vehicle.finance.documentStatus ? <Badge status="processing" text={vehicle.finance.documentStatus} /> : null}
            </div>
        </Link>
    );
}

export const kecSteps = [
    {
        caption: 'Delivery Plan',
        path: '/plan-delivery',
        redirect_to: 'delivery-cases',
        alert: 'Below are upcoming deliveries. Please come back and update here if there is any delay in the scheduled so that everybody is updated.',
        description: 'Update the delivery time below',
        queries: ({ user }) => {
            return [
                {
                    field: 'consultant_id',
                    value: user.employee_id,
                },
                // {
                //     field: 'rto.status',
                //     value: 'Road Tax Issued'
                // },
                {
                    field: 'delivery.schedule',
                    operator: 'in',
                    value: ['pending', 'Delivery Scheduled'],
                },
                {
                    field: 'status',
                    operator: '!=',
                    value: 'removed'
                },
            ];
        },
        field_time: 'rto.tp_issued_time',
        Card: AllocationCard,
    },

    {
        caption: 'Enquiry Follow Up',
        path: '/follow-up',
        redirect_to: 'followup-form',
        model: 'enquiries',
        queries: ({ user }) => {
            return [
                {
                    field: 'consultant_id',
                    value: user.employee_id,
                },
                {
                    field: 'enquiry_status',
                    value: 'LIVE',
                },
                {
                    field: 'next_follow_up_date',
                    value: next_follow_up_date,
                },
            ];
        },
    },
    {
        caption: 'Missed Follow Up',
        path: '/missed-up',
        redirect_to: 'followup-form',
        model: 'enquiries',
        queries: ({ user }) => {
            return [
                {
                    field: 'consultant_id',
                    value: user.employee_id,
                },
                {
                    field: 'enquiry_status',
                    value: 'LIVE',
                },

                {
                    field: 'next_follow_up_date',
                    operator: '<',
                    value: next_follow_up_date,
                },
            ];
        },
    },

    // //Task Routes

    // {
    //     caption: 'Data Collection',
    //     path: '/for-collection',
    //     redirect_to: 'collection-form',
    //     model: 'bookings',
    //     queries: ({ user }) => {

    //         return [{

    //             field: 'Consultant Name',
    //             value: user.dms['Consultant Name']
    //         },
    //         ]
    //     },
    // },

    {
        caption: 'Pending Booking Status Update',
        description: 'You are adviced to update the booking status for the specified bookings',
        path: '/booking-status',
        redirect_to: 'booking-status-form',
        model: 'bookings',
        queries: ({ user }) => {
            return [
                {
                    field: 'consultant_id',
                    value: user.employee_id,
                },
                {
                    field: 'current.currentStatus',
                    value: 'Awaiting Confirmation',
                },
            ];
        },
        Card: BookingStatusCard,
    },

    {
        caption: 'Pending to Upload Documents',
        description: 'You are adviced to upload the documents for below bookings',
        path: '/pending-document-upload',
        redirect_to: 'booking-status-form',
        model: 'bookings',
        search: true,
        queries: ({ user }) => {
            return [
                {
                    field: 'consultant_id',
                    value: user.employee_id,
                },
                {
                    field: 'document_status',
                    value: 'pending',
                },
            ];
        },
        Card: BookingCard,
    },

    // Billing Form
    // {
    //     isForm: true,
    //     caption: 'Data Collection Form',
    //     path: '/collection-form/:city/:id',
    //     model: 'booking',
    //     post_status: 'Invoiced',
    //     extraParams: (userRequest) => {

    //         return {
    //             insurance: {
    //                 ...userRequest.insurance,
    //                 status: 'Ready for Issue'

    //             },
    //             accessories: {
    //                 ...userRequest.accessories,
    //                 status: 'Ready for Fitment'

    //             },
    //             fasttag: {
    //                 ...userRequest.fasttag,
    //                 status: '`pending`',
    //                 process: 'incomplete'
    //             },
    //             delivery: {
    //                 status: 'pending',
    //                 process: 'incomplete'
    //             }
    //         }

    //     },
    //     fields: [
    //         {
    //             type: 'upload',
    //             field: 'invoice',
    //             caption: 'Invoice '
    //         },
    //         {
    //             type: 'textarea',
    //             caption: 'Remarks',
    //             field: 'remarks'
    //         },
    //     ]
    // },

    // Billing Form
    {
        isForm: true,
        caption: 'Booking Status Form',
        description: 'Please update the status',
        path: '/booking-status-form/:city/:id',
        model: 'current',
        endpoint: 'bookings',
        post_status: 'Status Updated',
        fields: [
            {
                type: 'select',
                field: 'currentStatus',
                // options:  ['CRITICAL CASE', 'URGENT', 'ALLOCATED', 'THIS MONTH', 'NEXT MONTH', 'IN 3 MONTHS', 'CANCELLED', 'DELIVERED'],
                caption: 'Current Status',
            },
            // {
            //     type: 'datetime',
            //     field: 'expectedDate',
            //     caption: 'Expected Delivery Date',
            //     condition: (values) => {
            //         return ['Live', 'Urgent', 'Delivered', 'Next Month', 'After 2 Months', 'After 3 Months', 'Plan Postponed'].indexOf(values.currentStatus) !== -1
            //     },
            // },
            // {
            //     type: 'time',
            //     field: 'scheduled_delivery_time',
            //     caption: 'Delivery Time'
            // },
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],

        extraParams: (userRequest, param) => {
            let creation = {
                lastupdated_at: moment().format('DD/MM/YYYY HH:mm'),
                lastupdated_date: moment().startOf('day').valueOf(),
                lastupdated_time: moment().valueOf(),
            };

            if (param.expectedDate) {
                param.expectedDate = moment(param.expectedDate).format('DD/MM/YYYY');
                creation.expectedDate = param.expectedDate;
            }

            return {
                ...creation,
            };
        },
    },

    // Delivery Schedule Form
    {
        isForm: true,
        caption: 'Enquiry Follow Up',
        endpoint: 'enquiries',
        path: '/followup-form/:city/:id',
        model: 'enquiries',
     
        extraParams: (userRequest, params) => {
            
            return {
                    ...params,
                    next_follow_up_date: moment(params.next_follow_up_date).startOf('day').valueOf(),
            };
        },
        fields: [
            {
                type: 'date',
                field: 'next_follow_up_date',
                caption: 'Next follow up Date',
                transform: (value) => {
                    return moment(value);
                },
            },
            {
                type: 'follow_up_status',
                caption: 'Status',
                field: 'follow_up_status',
            }, 
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },

    // Delivery Schedule Form
    {
        isForm: true,
        caption: 'Delivery Plan',
        path: '/delivery-cases/:city/:id',
        model: 'delivery',
        redirect_to: '/plan-delivery',
        // post_status: 'pending',
        notification: (request, user, values) => {
            let cName = capitalizeStrings(request.customer.customerName);

            // let deliveryDate = moment(values.scheduled_delivery_date).format('DD/MM/YYYY');

            // let deliveryTime = moment(values.scheduled_delivery_time).format('HH:mm A');

            let deliveryTime = moment(values.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A');

            return {
                title: cName + '| Delivery Scheduled',
                body: `${deliveryTime}  Delivery has been scheduled for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
        extraParams: (userRequest, params) => {
            let creation = {
                scheduled_at: moment().format('DD/MM/YYYY HH:mm'),
                scheduled_date: moment().startOf('day').valueOf(),
                scheduled_time: moment().valueOf(),
            };

            let prepared_scheduled_delivery_time =
                moment(params.scheduled_delivery_date).format('DD/MM/YYYY') + ', ' + moment(params.scheduled_delivery_time).format('HH:mm A');

            return {
                delivery: {
                    ...params,
                    ...creation,
                    // scheduled_delivery_unix_date: moment(params.scheduled_delivery_date, 'DD/MM/YYYY HH:mm').startOf('day').valueOf(),
                    scheduled_delivery_date: moment(params.scheduled_delivery_date).startOf('day').valueOf(),
                    scheduled_delivery_time: moment(prepared_scheduled_delivery_time, 'DD/MM/YYYY, HH:mm A').valueOf(),
                    schedule: 'Delivery Scheduled',
                },
            };
        },
        fields: [
            {
                type: 'date',
                field: 'scheduled_delivery_date',
                caption: 'Delivery Date',
                transform: (value) => {
                    return moment(value);
                },
            },

            {
                type: 'time',
                field: 'scheduled_delivery_time',
                caption: 'Delivery Time',
                transform: (value) => {
                    return moment(value);
                },
            },
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        
    },
];

export const bookingSchema = {
    queries: [],
    columns: [],
    disableSelect: true,
    disableUpload: true,
    disableAdd: true,

    Card: BookingCard,

    filterResults: (entry) => {
        return !entry.isCancelled;
    },

    pendingBookings: [
        {
            field: 'billing.status',
            value: 'pending',
        },
    ],

    billingQueries: [
        {
            field: 'billing.status',
            value: 'done',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryQueries: [
        {
            field: 'delivery.status',
            value: 'done',
        },
    ],
};

function BookingStatusCard({ city, booking = {}, index, step }) {
    return (
        <Link key={index} className="booking-card" to={`/${step.redirect_to}/${city}/${index}`}>
            <div className="card">
                <h2 className="title amount ">{booking['Name of the Customer']}</h2>
                {/* <h4 className="title">{booking['Contact Number']}</h4> */}
                <p className="">{booking['Variant']}</p>
                <p>
                    <small> {booking['Color']}</small>
                </p>
                {booking.billing && booking.billing.status === 'pending' ? (
                    <>
                        <BookingPosition city={city} booking={booking} />
                    </>
                ) : null}
                <h4>{booking['Booking No']}</h4>
                <p className="address">Booked on {booking['Booking Date']}</p>
                Committed Delivery Date {booking['Committed Delivery Date']}
            </div>
        </Link>
    );
}

function BookingCard({ city, booking = {}, index }) {
    return (
        <Link key={index} className="booking-card" to={`/bookings/${booking['Booking No']}`}>
            <div className="card">
                <h2 className="title amount ">{booking['Name of the Customer']}</h2>
                {/* <h4 className="title">{booking['Contact Number']}</h4> */}
                <p className="">{booking['Variant']}</p>
                <p>
                    <small> {booking['Color']}</small>
                </p>
                {booking.billing && booking.billing.status === 'pending' ? (
                    <>
                        <BookingPosition city={city} booking={booking} />
                    </>
                ) : null}
                <h4>{booking['Booking No']}</h4>
                <p className="address">Booked on {booking['Booking Date']}</p>
                Committed Delivery Date {booking['Committed Delivery Date']}
            </div>
        </Link>
    );
}

/**
 * Booking Position
 */
export function BookingPosition({ booking, city }) {
    const [model, setModel] = useState({ position: 0 });

    useEffect(() => {
        loadMatchingBookings(city, booking).then((result) => {
            console.log(result);
        });

        return () => {};
    }, []);

    function loadMatchingBookings(city, booking) {
        var queries = [
            {
                field: 'billing.status',
                value: 'pending',
            },
            {
                field: 'Color',
                value: booking['Color'],
            },
            {
                field: 'Variant',
                value: booking['Variant'],
            },
        ];

        return FirebaseUtils.getListing(city, queries, 'bookings').then((result) => {
            const { bookings } = result;

            var arr = bookings
                .filter((b) => !b.isCancelled)
                .sort((a, b) => {
                    return a.bookingDate > b.bookingDate ? 1 : -1;
                });

            var position;

            arr.forEach((item, index) => {
                if (item['Booking No'] === booking['Booking No']) {
                    position = index;
                }
            });

            setModel({ position, bookings });

            return { position, bookings };
        });
    }

    return (
        <p style={{ float: 'right' }}>
            <Badge status="processing" text={`Position : ${model.position + 1}`} />
        </p>
    );
}
