/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from "react"

import { Link } from 'react-router-dom'

import { Typography, Button, Descriptions, Input, Switch, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom'

import './items-edit.scss'

import FirebaseUtils from './../../../../utils/firebase.utils';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import useDeviceDetect from './../../../../hooks/device-detect';

import PlaceHolder from './../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from './../../../../Store';

import FormCreator from './../form-creator/form-creator';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { Title } = Typography;

const { TextArea } = Input;

/**
 * Generic List 
 * 
 * @param {*} param0 
 */
function ItemsEdit({ history, match, model, collection, columns: cols }) {

    const [record, setRecord] = useState([]);

    const { isMobile } = useDeviceDetect();

    const [taxes, setTaxes] = useState([]);

    const { user } = useContext(GlobalContext);

    const { params } = match;

    const { city } = params;

    // const city = user.locations[0];

    const [loading, setLoading] = useState(false);

    const formConfig = {
        fields: [{
            type: 'textarea',
            caption: 'Remarks',
            field: 'remarks'
        }]
    }
    


    useEffect(() => {

        loadData();
        loadTaxes();

    }, []);

    const loadTaxes = () => {
            return FirebaseUtils.getRecords('', [], 'taxes').then((result) => {
                setTaxes(result.taxes)
            })
    }

    const step = {
        fields: [
    {
        type: 'input',
        caption: 'Part No',
        field: 'part_number',
    },
    {
        field: 'part_name',
        type: 'input',
        caption: 'Part Name',
    },
    {
        field: 'hsn',
        type: 'number',
        caption: 'HSN',
    },
    
    {
        caption: 'Category',
        field: 'category',
        type: 'radio',
        options: ['VAS', 'After Market', 'Extended Warranty'],
    },
            
    {
        caption: 'Tax',
        field: 'tax_id',
        type: 'selectText',
        options: taxes.map((tax) => {
            return {text: tax.description, value: tax.id}
        })

            },
    {
        field: 'price',
        type: 'number',
        caption: 'Price',
    },
    {
        field: 'average_price',
        type: 'number',
        caption: 'Average Price',
    },
    {
        field: 'purchase_price',
        type: 'number',
        caption: 'Purchase Price',
            },
    {
        field: 'purchase_price_without_tax',
        type: 'number',
        caption: 'Purchase Price Without Tax',
    },

    {
        caption: 'Inclusive of Tax',
        field: 'tax_inclusive',
        type: 'radioText',
        options: [{ value: true, text: 'true' }, {value : false, text : 'false'}],
    },
    
    

        ]
    };

    const loadData = () => {

        setLoading(true);

        FirebaseUtils.getListingRecord(match.params.id, model).then((result) => {

            console.log(result.data());

            setRecord(result.data());

            setLoading(false);

        })
    }

    const onSubmit = (values) => {

        console.log(values);

        FirebaseUtils.updateListing(model, match.params.id, values).then((result) => {

            history.goBack();

        })

    }


    function refresh() {

        loadData();
    }

    return (
        <section className="generic-list">

            {/* Table Header */}
            <div className="table-header">

                <div className="table-title">
                    <Title level={4}>
                        {model}
                    </Title>
                </div>

                <div className="table-actions">

                    <div className="button-container">

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>



                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <>

                        <div className="card">

                            <FormCreator
                                formContent={record}
                                modelIndex='requestId'
                                model={'accessories'}
                                onSubmit={onSubmit}
                                config={step}
                                callback={() => {

                                    history.goBack();

                                }}
                            />

                        </div>

                    </>
            }

        </section>
    );
}

export default withRouter(ItemsEdit);
