import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from './../../../../Store';

import { Row, Tabs, Button, Alert, Typography, Input, Table, Switch } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { ExportReactCSV } from '../generic/generic-list/ExportReactCSV';

import './task-list.scss';
import { EnquiryCard } from '../../components/enquiry-card/enquiry-card';

const { TabPane } = Tabs;

const { Title } = Typography;

/**
 * Task List from Step
 *
 * @param {*} param0
 */
export default function TaskList({ step }) {
    console.log(step);
    const { user } = useContext(GlobalContext);

    useEffect(() => { }, [user]);

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="finance task-list">
            <Title level={3}>{step.caption}</Title>

            <p>{step.description}</p>

            <Tabs defaultActiveKey="1" onChange={callback}>
                {user.locations.map((location, index) => {
                    return (
                        <TabPane tab={`${location} ${step.caption}`} key={index}>
                            <Tasks url={location} step={step} />
                        </TabPane>
                    );
                })}
            </Tabs>
        </section>
    );
}

const Tasks = ({ url, step }) => {
    const [allocations, setAllocations] = useState([]);

    // The state variable to store the array of search results.
    const [searchList, setSearchList] = useState([]);

    // The state variable to toggle search functionality.
    const [enableSearch, setEnableSearch] = useState(false);

    const [loader, setLoader] = useState(true);

    const [view, setView] = useState(true);

    const [expRecord, setExptRecords] = useState();

    const { user } = useContext(GlobalContext);

    useEffect(() => {
        getData();
    }, []);


    useEffect(() => {
        if (step.columns) {
            getExportData(allocations, step.columns);
        }
    }, [allocations]);



    function getData() {
        setLoader(true);

        var queries = [];

        if (typeof step.queries === 'function') {
            queries = step.queries({ user });
        } else {
            queries = step.queries;
        }

        if (step.model === 'bookings') {
            FirebaseUtils.getListing(url, queries, step.model).then((vehicles) => {
                console.log(vehicles);

                setLoader(false);
                setAllocations(vehicles[step.model]);
            });
        } else {
            if (step.model !== 'stock_transfers') {
                FirebaseUtils.getRecords(url, queries, step.model || 'requests').then((vehicles) => {
                    setLoader(false);

                    console.log(vehicles);

                    setAllocations(vehicles[step.model || 'requests']);
                });
            } else {
                FirebaseUtils.getBaseRecords(queries, step.model || 'requests').then((vehicles) => {
                    // If step.filter is present, filter that
                    if (step.filter) vehicles[step.model || 'request'] = step.filter(vehicles[step.model || 'request']);

                    setLoader(false);

                    console.log(vehicles);

                    setAllocations(vehicles[step.model || 'requests']);
                });
            }
        }
    }

    /**
     * Handles input events for search
     * @param {} event
     */
    const onInput = (event) => {
        const value = event.target.value.toLowerCase();

        let arr = [];

        allocations.forEach((item) => {
            // To search with booking number
            if (item['Booking No'] && item['Booking No'].toLowerCase().includes(value)) {
                arr.push(item);
            }
            // To search with contact number
            else if (item['Contact Number'] && item['Contact Number'].toLowerCase().includes(value)) {
                arr.push(item);
            }
            // To search with name
            else if (item['Name of the Customer'] && item['Name of the Customer'].toLowerCase().includes(value)) {
                arr.push(item);
            }
        });

        setSearchList(arr);

        setEnableSearch(true);
    };

    //Change view
    function changeView(result) {
        setView(result);
    }
    /**
     * This function returns the array that can be downloaded.
     * This function is maintained in firebase-core and when migrating to onedesk, it can be moved
     * @param {*} records
     * @param {*} columns
     */
    async function getExportData(records, columns) {
        let filteredColumns = columns.filter((col) => !col.disableExport);

        const arr = await Promise.all(
            records.map((row, index) => {
                let entry = {
                    'Sl No': index + 1,
                };

                filteredColumns.forEach((column, index) => {
                    if (column.exportDefinition) {
                        entry[column.title] = column.exportDefinition(row, index, index);
                    } else if (column.render) {
                        entry[column.title] = column.render(row, index, index);
                    } else if (column.dataIndex) {
                        entry[column.title] = row[column.dataIndex];
                    } else if (column.field) {
                        entry[column.title] = row[column.field];
                    } else {
                        entry[column.title] = row[column.field];
                    }
                });

                return entry;
            })
        );

        setExptRecords(arr);
    }

    return (
        <>
            <div className="list-header">
                
                {/* disable  for enquiry follow up module */}
                {!(step.caption === 'Enquiry Follow Up' || step.caption === 'Missed Follow Up') ?
                    <>

                        <p className="page-desc">
                            {loader ? 'Loading data' : <>{enableSearch ? `Found ${searchList.length} results` : `Found ${allocations.length} results`}</>}
                        </p>

                        <div className="list-actions">
                            <Button
                                onClick={() => {
                                    getData();
                                }}
                                type="secondary"
                                size={'small'}
                            >
                                Refresh
                            </Button>

                            <div>{expRecord && <ExportReactCSV csvData={expRecord} />}</div>

                            <div>{expRecord && <Switch defaultChecked={view} onChange={changeView} checked={view} />}</div>
                        </div>
                    </> 
                : null }
            </div>

            {step.search ? <Input type="text" placeholder="Search here" onInput={onInput} style={{ marginBottom: '10px' }} /> : null}

            <Row className="report-listing-wrapper">
                {step.alert ? <Alert message={step.alert} type="info" showIcon /> : null}

                {loader ? (
                    <PlaceHolder type="listing" />
                ) : view ? (
                    <>
                        {enableSearch
                            ? searchList.map((report, index) => {
                                return (
                                    <step.Card
                                        request={report}
                                        city={url}
                                        step={step}
                                        booking={report}
                                        vehicle={report}
                                        index={report.id}
                                        key={report.id}
                                    />
                                );
                            })
                            :
                            (step.caption === 'Enquiry Follow Up' || step.caption === 'Missed Follow Up') ?
                                (
                                    <EnquiryCard
                                        record={allocations}
                                        user={user}
                                        step={step}
                                    />
                                )
                                : allocations.map((report, index) => {
                                    return (
                                        <step.Card
                                            request={report}
                                            city={url}
                                            step={step}
                                            booking={report}
                                            vehicle={report}
                                            index={report.id}
                                            key={report.id}
                                        />
                                    );
                                })
                        }
                    </>
                ) : (
                    <>
                        {enableSearch ? (
                            <Table columns={step.columns} dataSource={searchList} />
                        ) : (
                            <Table columns={step.columns} dataSource={allocations} />
                        )}
                    </>
                )}
            </Row>
        </>
    );
};
