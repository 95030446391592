

import React, { useState, useContext, useEffect, useRef } from "react";

import { Card, Typography, Badge, Table, Tag, Tabs, Select, Button, Statistic, Row, Col } from "antd";

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import Rupee from '../../../../components/rupee/rupee'

import { DatePicker, Space } from 'antd';

import { Link } from 'react-router-dom'

import moment from 'moment';

import { Location } from './../../../../utils/location.utils';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import "./incentive-overview.scss";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const dateFormat = 'MM/YYYY';

const { Option } = Select;


let target = {
    kozhikode: {
        kec: {
            'MUHAMMED FARSHAN PP': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'RABIN NAJEEB R M': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'SREERAJ V': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'FINU SHAHAD M': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'ANANTHU K': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'SALMAN P K': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'AKSHAY V': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'ARUN JOSE': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'LAJIN KUMAR O P	': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'JINESH P P': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'AFID E V': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'SHIMJITH T P': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'ACHINTYA K': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'SAMAD T M': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'ARJUN K': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'VAISHAKH P': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'VISHNU DAS V S': {
                seltos: 9,
                sonet: 9,
                carnival: 2
            },
            'ANAND KUMAR K S': {
                seltos: 9,
                sonet: 9,
                carnival: 2

            },
            'M S JIJU': {
                seltos: 9,
                sonet: 9,
                carnival: 2

            },
            'JABIR T P': {
                seltos: 9,
                sonet: 9,
                carnival: 2

            },
            'ARJUN P': {
                seltos: 9,
                sonet: 9,
                carnival: 2

            },
            'SOORAJ T': {
                seltos: 9,
                sonet: 9,
                carnival: 2

            },
            'AJESH': {
                seltos: 9,
                sonet: 9,
                carnival: 2

            },
            'ABDULLA FARHAN': {
                seltos: 9,
                sonet: 9,
                carnival: 2

            },
            'HARIKRISHNAN-NEW': {
                seltos: 9,
                sonet: 9,
                carnival: 2

            }

        }
    },
    kannur: {
        kec: {

        }
    },
    malappuram: {
        kec: {

        }
    }
}

let initialModels = {
    'SELTOS': {},
    'SONET': {},
    'CARNIVAL': {}
}

let incentiveTabs = [
    { name: 'Bookings', value: 'bookings' },
    { name: 'Retails', value: 'sales' }
]



export default function IncentiveOverview(props) {

    const { user } = useContext(GlobalContext);

    const [range, setRange] = useState(moment().startOf('day'));

    const [loading, setLoading] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState(user.locations[0]);

    const [selectedTab, setSelectedTab] = useState("sales");

    const [content, setContent] = useState([]);

    const [model, setModel] = useState('');

    const columns = [
        {
            width: 20,
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => (index + 1)
        },
        {
            title: 'Consultant Name',
            dataIndex: 'Consultant Name',
            render: (value, item, index) => item && item.name
        },
        {
            title: 'SONET',
            children: [
                {
                    title: 'Acheived',
                    //   dataIndex: 'building',
                    render: (value, item, index) => item && item.sonet,
                    //   key: 'building',
                    width: 100,
                    sorter: (a, b) => {
                        if (a && a.sonet && b && b.sonet) {
                            return a.sonet - b.sonet
                        } else if (a && a.sonet) {
                            return a.sonet - 0
                        } else if (b && b.sonet) {
                            return 0 - b.sonet
                        } else {
                            return 0 - 0
                        }

                    },
                    sortDirections: ['ascend', 'descend', 'ascend'],
                },
                {
                    title: 'Target',
                    // dataIndex: 'number',
                    render: (value, item, index) => item && item.sonetTarget,
                    // key: 'number',
                    width: 100,
                },
                {
                    title: 'Incentive',
                    // dataIndex: 'number',
                    render: (value, item, index) => item && item.sonetIncentive,
                    // key: 'number',
                    width: 100,
                }
            ],
            // dataIndex: 'partName',
        },
        {
            title: 'SELTOS',
            // dataIndex: 'sellingPrice',
            children: [
                {
                    title: 'Acheived',
                    //   dataIndex: 'building',
                    render: (value, item, index) => item && item.seltos,
                    //   key: 'building',
                    width: 100,
                    sorter: (a, b) => {
                        if (a && a.seltos && b && b.seltos) {
                            return a.seltos - b.seltos
                        } else if (a && a.seltos) {
                            return a.seltos - 0
                        } else if (b && b.seltos) {
                            return 0 - b.seltos
                        } else {
                            return 0 - 0
                        }

                    },
                    sortDirections: ['ascend', 'descend', 'ascend'],
                },
                {
                    title: 'Target',
                    // dataIndex: 'number',
                    render: (value, item, index) => item && item.seltosTarget,
                    // key: 'number',
                    width: 100,
                },
                {
                    title: 'Incentive',
                    // dataIndex: 'number',
                    render: (value, item, index) => item && item.seltosIncentive,
                    // key: 'number',
                    width: 100,
                }
            ],
        },
        {
            title: 'CARNIVAL',
            // dataIndex: 'sellingPrice',
            children: [
                {
                    title: 'Acheived',
                    //   dataIndex: 'building',
                    render: (value, item, index) => item && item.carnival ? item.carnival : 0,
                    //   key: 'building',
                    width: 100,
                    sorter: (a, b) => {
                        if (a && a.carnival && b && b.carnival) {
                            return a.carnival - b.carnival
                        } else if (a && a.carnival) {
                            return a.carnival - 0
                        } else if (b && b.carnival) {
                            return 0 - b.carnival
                        } else {
                            return 0 - 0
                        }
                    },
                    sortDirections: ['ascend', 'descend', 'ascend'],
                },
                {
                    title: 'Target',
                    // dataIndex: 'number',
                    render: (value, item, index) => item && item.carnivalTarget,
                    // key: 'number',
                    width: 100,
                },
                {
                    title: 'Incentive',
                    // dataIndex: 'number',
                    render: (value, item, index) => item && item.carnivalIncentive,
                    // key: 'number',
                    width: 100,
                }
            ],
        }
    ];

    function loadData(range) {

        setRange(range);

    }

    function updateTime(dt) {
        loadData(dt);
        // Location.search({ dt },{method : 'push'});

    }

    function handleCityChange(value) {
        console.log(`selected ${value}`);
        setSelectedLocation(value);
        // Location.search({ type: value },{method : 'push'});
    }

    var groupBy = function (xs, key) {

        return xs.reduce(function (rv, x) {

            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;

        }, {});
    };

    useEffect(() => {
        setLoading(true);

        getModel();

        var queries = [
            {
                field: 'confirm_date',
                value: moment(range).valueOf(),
                operator: '>='
            },
            {
                field: 'confirm_date',
                value: moment(range).endOf('month').valueOf(),
                operator: '<='
            }
        ]

        FirebaseUtils.getListing(selectedLocation, queries, selectedTab).then((result) => {

            // Group kec by consulatant name
            let consultants = groupBy(result[selectedTab], 'Consultant Name');

            var data = [];

            Object.keys(consultants).forEach((name) => {

                let userScore = {
                    name,
                };

                let bookings = consultants[name];

                let models = groupBy(bookings, 'Model');

                // Get the target
                let userTarget = target[selectedLocation]['kec'][name];

                // Recording Target
                Object.keys(userTarget).forEach((model) => {

                    userScore[model + 'Target'] = userTarget[model];

                });


                // Iterate models to pass score
                Object.keys(initialModels).forEach((m) => {

                    let model = m.toLowerCase();

                    // Actual bookings 
                    userScore[model] = models[m] ? models[m].length : 0;

                    // Identify %
                    userScore[model + 'Percent'] = (userScore[model] / userScore[model + 'Target']) * 100;

                    // define increment value
                    let initialPerCarRate;
                    let initialPerCarRate1;
                    if (model == 'seltos') {
                        initialPerCarRate = 1000;
                        initialPerCarRate1 = 1500;
                    } else if (model == 'sonet') {
                        initialPerCarRate = 750;
                        initialPerCarRate1 = 1000;
                    } else if (model == 'carnival') {
                        initialPerCarRate = 7500;
                        initialPerCarRate1 = 7500;
                    } else {
                        initialPerCarRate = 0;
                        initialPerCarRate1 = 0;
                    }

                    // Per car rate
                    let perCarRate = userScore[model + 'Percent'] >= 100 ? initialPerCarRate1 : userScore[model + 'Percent'] > 80 ? initialPerCarRate : null;


                    userScore[model + 'Incentive'] = perCarRate * userScore[model];


                });

                // % Acheived

                data.push(userScore);

            })


            setContent(data);


            setLoading(false);

        })
    }, [range, selectedLocation, selectedTab])

    function getModel() {
        const model = [
            {
                name: 'SONET',
                value: selectedLocation === 'kozhikode' ? 125 : selectedLocation === 'kannur' ? 100 : 100
            },
            {
                name: 'SELTOS',
                value: selectedLocation === 'kozhikode' ? 92 : selectedLocation === 'kannur' ? 88 : 78
            },
            {
                name: 'CARNIVAL',
                value: selectedLocation === 'kozhikode' ? 9 : selectedLocation === 'kannur' ? 4 : 4
            }
        ]
        setModel(model);
    }

    const onTabChange = (key) => {
        setSelectedTab(key);
    }

    return (
        <section className="accounts-overview listing">

            <div className="page-header">

                <Title level={3}>
                    Incentive Calculator
                </Title>

                <div className="page-actions">

                    <Select defaultValue={user.locations[0]} style={{ width: 150 }} onChange={handleCityChange}>
                        {
                            user.locations.map((loc) => {
                                return <Option value={loc}>{loc}</Option>
                            })
                        }
                    </Select>

                    <DatePicker inputReadOnly picker="month" format={dateFormat} value={range} onChange={updateTime} allowClear={false} />

                    <Button size="small" onClick={() => {
                        setRange(null);
                        setTimeout(() => {
                            setRange(moment().startOf('day'));
                        })
                    }}>
                        Refresh
                    </Button>

                </div>
            </div>
            <Tabs defaultActiveKey={selectedTab} onChange={onTabChange}>
                {
                    incentiveTabs.map((singleTab) => {
                        return (
                            <TabPane disabled tab={singleTab.name} key={singleTab.value}>
                                <div style={{ width: '35%', position: 'absolute', right: '64px' }}>
                                    <Card>
                                        <div style={{ padding: '4px 0px', fontWeight: 'bold' }}> Booking Targets</div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {
                                                model && model[0] && model.map((mod) => {
                                                    return (
                                                        <div style={{ display: 'flex', flexDirection: 'row', border: '1px solid #f0f0f0', marginRight: '10px', padding: '5px' }}>
                                                            <div style={{ fontWeight: 'bold' }}>{mod.name} :</div>
                                                            <div style={{ fontWeight: 'bold' }}>{"   "}{mod.value}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Card>
                                </div>

                                <div style={{ marginTop: '11%' }}>
                                    <Table
                                        loading={loading}
                                        size="small"
                                        rowKey={record => record.id}
                                        bordered
                                        dataSource={content}
                                        columns={columns}
                                        pagination={false}
                                    // summary={pageData => {

                                    //     let total = 0;
                                    //     let totalSELTOS = 0;
                                    //     let totalSONET = 0;
                                    //     let totalCARNIVAL = 0;
                                    //     let incentiveCalculatedValue = [];

                                    //     pageData.forEach((entry) => {
                                    //         Object.keys(entry).forEach((location) => {
                                    //             if (location == 'SELTOS') {
                                    //                 totalSELTOS += parseFloat(entry[location].length);
                                    //             } else if (location == 'SONET') {
                                    //                 totalSONET += parseFloat(entry[location].length);
                                    //             } else {
                                    //                 totalCARNIVAL += parseFloat(entry[location].length);
                                    //             }
                                    //         })
                                    //     })

                                    //     if (!loading) {
                                    //         model && model[0] && model.map((val) => {
                                    //             console.log(val, totalSELTOS, totalSONET, totalCARNIVAL);

                                    //             let totalValue = val.name == 'SELTOS' ? totalSELTOS : val.name == 'SONET' ? totalSONET : totalCARNIVAL;

                                    //             if (Math.round(totalValue / val.value * 100) > 80) {
                                    //                 let data = { "name": val.name, "value": true };
                                    //                 return incentiveCalculatedValue.push(data);
                                    //             } else {
                                    //                 let data = { "name": val.name, "value": false };
                                    //                 return incentiveCalculatedValue.push(data);
                                    //             }
                                    //         })

                                    //         afterIncentiveCalculation.current = incentiveCalculatedValue;

                                    //     }



                                    //     return (
                                    //         <>
                                    //             <Table.Summary.Row>
                                    //                 <Table.Summary.Cell></Table.Summary.Cell>
                                    //                 <Table.Summary.Cell>Total</Table.Summary.Cell>
                                    //                 <Table.Summary.Cell>
                                    //                     <Text>
                                    //                         <Rupee value={totalSONET}></Rupee>
                                    //                     </Text>
                                    //                 </Table.Summary.Cell>
                                    //                 <Table.Summary.Cell></Table.Summary.Cell>
                                    //                 <Table.Summary.Cell></Table.Summary.Cell>
                                    //                 <Table.Summary.Cell>
                                    //                     <Text>
                                    //                         <Rupee value={totalSELTOS}></Rupee>
                                    //                     </Text>
                                    //                 </Table.Summary.Cell>
                                    //                 <Table.Summary.Cell></Table.Summary.Cell>
                                    //                 <Table.Summary.Cell></Table.Summary.Cell>
                                    //                 <Table.Summary.Cell>
                                    //                     <Text>
                                    //                         <Rupee value={totalCARNIVAL}></Rupee>
                                    //                     </Text>
                                    //                 </Table.Summary.Cell>
                                    //                 <Table.Summary.Cell></Table.Summary.Cell>
                                    //                 <Table.Summary.Cell></Table.Summary.Cell>
                                    //             </Table.Summary.Row>
                                    //         </>
                                    //     );
                                    // }}
                                    >
                                    </Table>
                                </div>
                            </TabPane>
                        )
                    })
                }
            </Tabs>

        </section>
    );
}