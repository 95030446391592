import Base from './base';

import FirebaseUtils from '../utils/firebase.utils';

import { Link } from 'react-router-dom';

import { Bookings, Comments, Settings, CustomerEntries } from './index';

import moment from 'moment';

class Requests extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'requests';
    }

    get path() {
        return `requests`;
    }

    get getName() {
        return `requests`;
    }

    /**
     * Update request and add a comment
     *
     * @param {*} id
     * @param {*} params
     * @param {*} message
     * @param {*} description
     * @param {*} process
     */
    updateRequest = async (id, params, message, description, process, batch) => {

        var user = this.getUser();

        // To update requests record of id 'id' with params
        var requestRef = this.getRecordReference(id);

        await batch.update(requestRef, params);

        let content = {
            author: user.name,
            content: 'Status Update : ' + message + ' - ' + description || params.remarks,
            datetime: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),
            type: process,
            deleted_at: null,
            deleted_by: null
        };

        // To create a new comments record and added with content
        var commentRef = await Comments.getRecordReference();

        return await batch.set(commentRef, Comments.appendDefaultValues(content));

    };

    /**
     * Unassign the vehicle
     *
     * @param {*} id
     */
    unassign = async (id, batch) => {

        var user = this.getUser();

        var params = {
            allocation: {
                status: 'pending',
            },
            vinNo: this.firebase().firestore.FieldValue.delete(),
            updated_by: { name: user.name, role: user.role },
            updated_at: moment().format('DD/MM/YYYY HH:mm'),
        };

        // To update the requests record of id 'id' with params
        var requestRef = await this.getRecordReference(id);

        await batch.update(requestRef, params);

    };

    /**
     * Function to initiate billing for sm
     *
     * @param {*} id
     */
    initiateBilliing = async (id) => {
        let creation = {
            sm_initiated_at: moment().format('DD/MM/YYYY HH:mm'),
            sm_initiated_date: moment().startOf('day').valueOf(),
            sm_initiated_time: moment().valueOf(),
        };

        var request = await this.getRecord(id);

        var params = {
            ...creation,

            actual_billing: {
                ...request.actual_billing,
                status: 'Finance Verification Pending',
                approvalRequired: false,



            },
        };

        return await this.update(id, params);
    };

    completeBilling = (request) => {
        var creation = this.getCreation();

        var params = {
            actual_billing: {
                ...creation,
                ...request.actual_billing,
                status: 'Invoiced',
                process: 'complete',
            },
            insurance: {
                process: 'incomplete',
                status: 'Ready for Issue',
                ...creation,
            },
            actual_delivery: {
                status: 'pending',
                process: 'incomplete',
            },
            fasttag: {
                ...request.fasttag,
                status: 'pending',
                process: 'incomplete',
            },
            accessories: {
                ...request.accessories,
                // status: request.accessories'Ready for Fitment'
            },
        };

        // If accessories billing has not started , move it to ready for fitment
        if (request.accessories.status !== 'Invoice' && request.accessories.status !== 'Ready for Billing') {
            params.accessories.status = 'Ready for Fitment';
        }

        return this.update(request.id, params);
    };

    updateRequestGeneric = (id, values, statusUpdate, remarks, model) => {
        var user = FirebaseUtils.getUser();
        let content = {
            author: user.name,
            content: 'Status Update : ' + statusUpdate + ' - ' + remarks || values.remarks,
            datetime: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),
            type: model,
            deleted_at: null,
            deleted_by: null
        };
        if (id) {
            return this.addComment(id, content).then((result) => {
                return this.update(id, values);
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve();
            });
        }
    };

    addComment = (id, comment) => {
        return FirebaseUtils.addComment(id, comment);
    };

    /**
     * Returns true if booking date is before date in settings 
     */

    disableCsAccounts = async (request) => {
        return Settings.getValue('disable-csaccounts').then(async (result) => {
            // Get booking date from bookingno in request
            if (request.bookingNo) {
                return Bookings.getRecord(request.bookingNo).then((booking) => {
                    if (booking.bookingDate < result.date) {
                        // enable cs accounts
                        return {
                            enable: true
                        }
                    }
                    else {

                        //disable cs accounts
                        return {
                            enable: false
                        }
                    }
                })
            } else {

                //disable cs accounts
                return {
                    enable: false
                }
            }
        })
    }

    /**
     * Get total amount recieved
     */

    loadReceiptTotal = async (request) => {
        // load totalbalance fron customer entries table
        return new Promise((resolve, reject) => {
            let bookingNo = request.bookingNo.substring(5);
            var totalBalance = 0;
            let query = [
                {
                    field: 'bookingNo',
                    value: bookingNo,
                },
                {
                    field: 'verified',
                    value: 'approved'
                },
                {
                    field: 'dealerCode',
                    value: request.dealerCode
                }
            ];
            return CustomerEntries.get(query).then((result) => {
                if (result.customer_entries.length) {

                    // Total of all customer receipts
                    for (let i = 0; i < result.customer_entries.length; i++) {

                        totalBalance += result.customer_entries[i].amount;

                    }

                    var total = {
                        paidtotal: totalBalance,
                        pendingcleartotal: 0,
                    };
                    resolve(total);

                } else {

                    var total = {
                        paidtotal: 0,
                        pendingcleartotal: 0,
                    };
                    resolve(total);

                }
            });
        })
    }
}

export default Requests;
