import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import {
    Form,
    Radio,
    Table,
    DatePicker,
    Modal,
    Upload,
    Switch,
    Menu,
    message,
    Divider,
    Typography,
    Button,
    Tag,
    Space,
    Badge,
    Drawer,
    Alert,
    Row,
    Image,
    Card,
    Steps,
    Select,
} from 'antd';

import { GlobalContext } from '../../../../Store';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { UploadOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

import { OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Departments } from '../../../../models';

import { PostData } from './../../../../utils/http.utils';

import moment from 'moment-timezone';

import './manage-payments.scss';
import { getDistance } from '@nivo/core';
import {  cityKeys } from '../../../global-config';

const { RangePicker } = DatePicker;

const { Title, Text } = Typography;

const { Step } = Steps;

const { Option } = Select;

const layout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
};

var statusColor = {
    remove: 'red',
    status: 'orange',
    new: 'green',
};

export default function ManagePayments({ history, report, index, department, match }) {
    const { user, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState([]);

    const [range, setRange] = useState([moment.tz('Asia/Calcutta').startOf('day'), moment.tz('Asia/Calcutta').endOf('day')]);

    const [location, setLocation] = useState(user.locations[0]);

    const [view, setView] = useState(isMobile);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const [selectedItem, setSelectedItem] = useState({});

    const [selectedBanks, setselectedBanks] = useState([]);

    const [filterOn, setFilterOn] = useState(false);

    const [filteredData, setFilteredData] = useState([]);

    const [form] = Form.useForm();

    const [banks, setBanks] = useState([]);

    const [codes, setCodes] = useState({});

    const [dep, setDep] = useState();

    const [deptIDs, setDeptIDs] = useState([]);

    console.log(match);

    var cityKey = cityKeys

    useEffect(() => {
        if (user) {
            if (match.params.category === 'sales') {
                setDep('Department Handling Sales');
            } else if (match.params.category === 'service') {
                setDep('Department Handling Services');
            } else {
                setDep(department[0]);
            }

            getDepartment();
        }

        getBanks();
    }, [user, dep]);

    function getBanks() {
        FirebaseUtils.getBanks().then((result) => {
            let banks = result;

            setBanks(banks);

            let codes = {};

            banks.forEach((bank) => {
                codes[bank.code] = bank.location;
            });

            setselectedBanks(banks.map((b) => b.code));

            setCodes(codes);
        });
    }

    function getDepartment() {
        console.log(dep);

        if (dep) {
            var deptArr = [];

            var query = [
                {
                    field: 'description',
                    value: dep,
                },

                // {
                //     field: 'dealer_code',
                //     value: cityKey[location],
                // },
            ];

            Departments.get(query)

                .then((result) => {
                    result.departments.map((dep) => {
                        deptArr.push(dep.id);
                    });
                    setDeptIDs(deptArr);

                    return deptArr;
                })
                .then((deptArr) => getBankReceipts(range, deptArr));
        }
    }

    /**
     * Get stock Data
     */
    function getBankReceipts(range, deptArr) {
        setLoading(true);

        var queries = [];

        if (range && range.length === 2) {
            queries = [
                {
                    field: 'transaction_date',
                    operator: '>=',
                    value: moment(range[0]).startOf('day').valueOf(),
                },

                {
                    field: 'transaction_date',
                    operator: '<=',
                    value: moment(range[1]).endOf('day').valueOf(),
                },

                {
                    field: 'department_id',
                    operator: 'in',
                    value: deptArr,
                },
            ];
        }
        // FirebaseUtils.getBankReceipts('central', queries).then((result) => {

        //     setLoading(false);

        //     setPayments(result);
        // });

        var config = {
            orderBy: 'transaction_date',
            direction: 'desc',
        };

        FirebaseUtils.getBankCredits(queries, config).then((result) => {
            setLoading(false);
            setResults(result);
        });
    }

    function changeView(result) {
        setView(result);
    }

    useEffect(() => {
        filterData();
    }, [selectedBanks]);

    function updateBank(value, option) {
        setFilterOn(true);
        setselectedBanks([...value]);
    }

    const filterData = () => {
        let data = [];
        result.map((res, index) => {
            if (selectedBanks.indexOf(res.bank) !== -1) {
                return data.push(res);
            }
        });
        setFilteredData(data);
    };

    function updateTime(dt) {
        setRange(dt);

        getBankReceipts(dt, deptIDs);
    }

    return (
        <div className="manage-receipts listing">
            <div className="page-header">
                <div className="left">
                    <Title level={3}> Bank Statement </Title>{' '}
                </div>
                <div className="right">
                    <RangePicker
                        allowClear={false}
                        inputReadOnly
                        format={'DD/MM/YYYY'}
                        value={range}
                        onChange={updateTime}
                        ranges={{
                            Today: [moment(), moment()],

                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        }}
                    />

                    <Switch
                        defaultChecked
                        onChange={changeView}
                        checked={view}
                        checkedChildren={<OrderedListOutlined />}
                        unCheckedChildren={<PicCenterOutlined />}
                    />

                    {user.role != 'admin' ? (
                        <Button size={'small'}>
                            <Link to={`/bank-receipts/upload`}>Upload</Link>
                        </Button>
                    ) : null}

                    <div>
                        <Button
                            onClick={() => {
                                setFilterOn(false);
                                getBankReceipts(range, deptIDs);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            {' '}
                            Refresh{' '}
                        </Button>{' '}
                    </div>
                </div>{' '}
            </div>
            {drawerOpen ? (
                <PaymentDetail
                    data={selectedItem}
                    callback={() => {
                        setDrawerOpen(false);
                    }}
                />
            ) : null}
            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <div
                        className="top-header"
                        style={{
                            padding: '15px',
                            height: '90px',
                        }}
                    >
                        {banks.length ? (
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '430px',
                                    position: 'absolute',
                                    right: '50px',
                                }}
                                placeholder="Please select"
                                defaultValue={banks.map((el) => el.code)}
                                onChange={updateBank}
                            >
                                {' '}
                                {banks.map((bank, index) => {
                                    return (
                                        <Option key={index} value={bank.code}>
                                            {' '}
                                            {bank.code}{' '}
                                        </Option>
                                    );
                                })}{' '}
                            </Select>
                        ) : null}
                    </div>
                    {!view ? (
                        <TableView
                            data={filterOn ? filteredData : result}
                            callback={(item) => {
                                setDrawerOpen(true);
                                setSelectedItem(item);
                            }}
                        />
                    ) : (
                        <CardList data={result} />
                    )}{' '}
                </>
            )}
        </div>
    );
}

export const PaymentDetail = ({ data, callback }) => {
    const [collections, setCollections] = useState([]);

    const [loader, setLoader] = useState(true);

    const { user } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    const [other, setOther] = useState('');

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        callback();
    };

    useEffect(() => {
        showDrawer();
        getData();
    }, []);

    function getData() {
        // setLoader(true);
        if (data && data.reconciled_for) {
            FirebaseUtils.getCollectionRecord(
                // data.reconciling_city,
                data.reconciled_for
            ).then((result) => {
                setCollections(result);
                setLoader(false);
                setOther('');

                // if (result.reconciled_by) {
                //     getBankRecord(result);
                // } else {
                //     getMatchingCredit(result);
                // }
            });
        } else if (data.otherCredit) {
            setOther('other');
            setLoader(false);
        } else {
            setOther('pending');
            setLoader(false);
        }
    }

    const { ac_code, accounts } = data;

    return (
        <>
            <Drawer width={'80%'} placement="right" closable={false} onClose={onClose} visible={visible}>
                {/* <Title level={3}>New Title</Title> */}{' '}
                {/* <Alert message="Please make sure all the customer receipts are verified without delays so that it can proceed for DMS" type="warning" showIcon closable /> */}{' '}
                {/* <TableView data={collections} /> */}
                {loader ? (
                    <div> Loading... </div>
                ) : other === 'other' ? (
                    <div>
                        <div className="detail-element">
                            <Row span={12}>
                                {' '}
                                <h3> ReceiptNo : </h3>
                            </Row>
                            <Row span={12}>
                                {' '}
                                <h2 className="title"> {data.accountReceiptNo} </h2>
                            </Row>
                        </div>{' '}
                        <div className="detail-element">
                            <Row span={12}>
                                {' '}
                                <h3> Ac code : </h3>
                            </Row>
                            <Row span={12}>
                                {' '}
                                <h2 className="title">
                                    {' '}
                                    {ac_code.label} - {ac_code.value}{' '}
                                </h2>
                            </Row>
                        </div>{' '}
                        <div className="detail-element">
                            <Row span={12}>
                                {' '}
                                <h3> Accounted by : </h3>
                            </Row>
                            <Row span={12}>
                                {' '}
                                <h2 className="title">
                                    {' '}
                                    {accounts.created_by && accounts.created_by.name} - {accounts.created_at}{' '}
                                </h2>
                            </Row>
                        </div>{' '}
                        <div className="detail-element">
                            <Row span={12}>
                                {' '}
                                <h3> Account Remarks: </h3>
                            </Row>
                            <Row span={12}>
                                {' '}
                                <h2 className="title"> {data.accountRemarks} </h2>
                            </Row>
                        </div>{' '}
                    </div>
                ) : other === 'pending' ? (
                    <Alert message="Pending" type="info" showIcon />
                ) : (
                    <CollectionInfo collection={collections} />
                )}{' '}
            </Drawer>
        </>
    );
};

function TableView({ data, callback }) {
    function showDrawer(item) {
        callback(item);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Txn Date',
            dataIndex: 'txn_date',
            key: 'Txn Date',
        },

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'Description',
            render: (text, record) => {
                let cls, caption;

                if (record.otherCredit) {
                    cls = 'success';
                    caption = 'Other';
                }

                return (
                    <Space size="middle">
                        <Text> {text} </Text> {record.otherCredit ? <Tag color={cls}> {caption} </Tag> : null}
                        {record.matching && record.matching.length ? <Badge status="success" text={`${record.matching.length} Matching`} /> : null}
                    </Space>
                );
            },
        },
        {
            title: 'Ref No./Cheque No.',
            dataIndex: 'ref',
            key: 'Ref No./Cheque No.',
        },
        {
            title: 'Branch Code',
            dataIndex: 'bank',
            key: 'bank',
        },
        {
            title: 'Debit',
            dataIndex: 'debit',
            key: 'Debit',
        },
        {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'Credit',
        },
        {
            title: 'Status',
            dataIndex: 'reconcile_status',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {' '}
                        {text === 'completed' ? (
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                        ) : text === 'pending' ? (
                            <CloseCircleTwoTone twoToneColor="#FFCC00" />
                        ) : null}{' '}
                    </Space>
                );
            },
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <a
                            onClick={() => {
                                console.log(record);

                                showDrawer(record);
                            }}
                        >
                            View{' '}
                        </a>{' '}
                    </Space>
                );
            },
        },
    ];

    return (
        <Table
            width="100%"
            size="small"
            // scroll={{ x: true, y: 750 }}
            rowKey={(record) => record.index}
            dataSource={data}
            columns={columns}
            // onChange={handleTableChange}
            pagination={false}
        ></Table>
    );
}

function CardList({ data, url }) {
    return data.map((report, index) => {
        return <PaymentCard city={url} request={report} index={index} key={index} />;
    });
}

function PaymentCard({ city, request = {} }) {
    return (
        <div className="request-card" to={`${request.city}/collections/${request.collectionId}`}>
            <div className="card">
                <h3 className="title"> {request['description']}</h3>
                <h2 className={`title ${request['debit'] ? 'amount' : 'credit'}`}> {request['debit'] || request['credit']} </h2>
                <p className="title "> {request['ref']} </p>
                {/* <h4 className="title ">
                                                                                                                                                                                                                                                Balance : {request['Balance']}
                                                                                                                                                                                                                                            </h4> */}
                <p className="">
                    <Tag color="magenta"> {request.status || 'Initial'} </Tag>{' '}
                </p>
                Credited at {request['txn_date']}{' '}
            </div>{' '}
        </div>
    );
}

function CollectionInfo({
    collection = {
        attachments: [],
    },
}) {
    return (
        <div>
            <Card
                style={{
                    marginBottom: '25px',
                }}
            >
                <div className="detail-element">
                    <Row span={12}>
                        <h3> Location: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <h2 className="title">
                            <Tag> {collection.city} </Tag>{' '}
                        </h2>{' '}
                    </Row>{' '}
                </div>

                <div className="detail-element">
                    <Row span={12}>
                        <h3> Name: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <h2 className="title"> {collection.name} </h2>{' '}
                    </Row>{' '}
                </div>

                <div className="detail-element">
                    <Row span={12}>
                        <h3> Amount: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <h2 className="title amount"> {collection.amount} </h2>{' '}
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>
                        <h3> KEC: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <h2 className=""> {collection.kec} </h2>{' '}
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>
                        <h3> Team Leader: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <h2 className=""> {collection.teamLeader} </h2>{' '}
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>
                        <h3> UTR: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <span> {collection.utr} </span>{' '}
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>
                        <span> Attachments </span>{' '}
                    </Row>
                    <>
                        {collection.attachments &&
                            collection.attachments.map((file) => {
                                return (
                                    <>
                                        {' '}
                                        {file.type === 'application/pdf' ? (
                                            <embed src={file.url} width="500" height="375" type="application/pdf" />
                                        ) : (
                                            <Image width={200} src={file.url} />
                                        )}
                                    </>
                                );
                            })}
                    </>{' '}
                </div>

                {/* 
                                                                                                                                                                                                                                                                                                                                                                                                    <div className="detail-element">
                                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                                                                                        <Row span={12}>
                                                                                                                                                                                                                                                                                                                                                                                                            <h3>Customer ID :</h3>
                                                                                                                                                                                                                                                                                                                                                                                                        </Row>
                                                                                                                                                                                                                                                                                                                                                                                                        <Row span={12}>
                                                                                                                                                                                                                                                                                                                                                                                                            <span>{collection.customerId}</span>
                                                                                                                                                                                                                                                                                                                                                                                                        </Row>
                                                                                                                                                                                                                                                                                                                                                                                                    </div> */}

                <div className="detail-element">
                    <Row span={12}>
                        <h3> On Account Of: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <span> {collection.account} </span>
                    </Row>{' '}
                </div>

                <div className="detail-element">
                    <Row span={12}>
                        <h3> Remarks: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <span> {collection.remarks} </span>{' '}
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>
                        <h3> Phone: </h3>{' '}
                    </Row>{' '}
                    <Row span={12}>
                        <span> {collection.phone} </span>{' '}
                    </Row>
                </div>
            </Card>

            {/* <div className="detail-element">
                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <Row span={12}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <h3>Created At :</h3>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </Row>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <Row span={12}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <span>{collection.created_at}</span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </Row>
                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div> */}

            {/* <div>
                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        collection.verificationRemarks
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <Text type="danger">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                {collection.verificationRemarks}, By {collection.verified_by && collection.verified_by.name && collection.verified_by.name} at {collection.verified_at}
                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </Text>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            null
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div> */}

            <div>
                <Steps direction="vertical" status="process" size={'small'}>
                    <Step title="Collection" description={`Created at ${collection.created_at}`} status={'finish'} />

                    <Step title="Credited" description={'Credited'} status={'finish'} />

                    <Step
                        title="Verification"
                        description={
                            collection.verified_at
                                ? `${collection.verificationRemarks}, By ${
                                      collection.verified_by && collection.verified_by.name && collection.verified_by.name
                                  } at ${collection.verified_at}`
                                : 'Pending'
                        }
                        status={collection.verified_at ? 'finish' : 'wait'}
                    />

                    <Step
                        title="Invoice"
                        description={collection.invoiced_time ? `Invoiced at ${moment(collection.invoiced_date).format('DD-MM-YYYY')}` : 'Pending'}
                        status={collection.invoiced_time ? 'finish' : 'wait'}
                    />

                    <Step
                        title="Accounted"
                        description={collection.accounted_time ? `Accounted at ${moment(collection.invoiced_date).format('DD-MM-YYYY')}` : 'Pending'}
                        status={collection.accounted_time ? 'finish' : 'wait'}
                    />
                </Steps>
            </div>
        </div>
    );
}
