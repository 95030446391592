import Base from './base';

import { Link } from 'react-router-dom';

import { Comments } from './index';

import moment from 'moment';

import FirebaseUtils from '../utils/firebase.utils';

class Enquiry extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'enquiries';
    }

    get path() {
        return `enquiries`;
    }

    get getName() {
        return `enquiries`;
    }
    

//Get Records with a limit
    getOrderRecord = (location,queries) => {
        return FirebaseUtils.getOrderRecord(location,queries,this)
    }


//Get Records starting from middle
    getListingData = (location,queries,config) => {
        return FirebaseUtils.getListingData(location,queries,this,config)
    }
}

export default Enquiry;
