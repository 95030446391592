
import React, { useEffect, useState, useContext } from 'react';

import { Input, Form, Button } from 'antd';

import { EditFilled } from '@ant-design/icons';

import './editable-cell.scss';

export default function EditableCell({ text, field, onUpdate }) {

    const [value, setValue] = useState(text);

    const [editing, setEditing] = useState(false);

    const toggleEdit = () => {

        setEditing(!editing);

        setValue(text);
    };

    /**
     * 
     * @param {*} formValues 
     */
    const handleSave = (formValues) => {

        onUpdate(formValues);

        setEditing(false);
    };

    return (
        <div className='editable-cell'>
            {editing ? (
                <Form layout='inline' onFinish={handleSave}>
                    <Form.Item name={field} initialValue={value}>
                        <Input onChange={(e) => setValue(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button onClick={toggleEdit}>Cancel</Button>
                    </Form.Item>
                </Form>
            ) : (
                <div className='display-text'  style={{ paddingRight: 24 }}>
                    {value}
                    <Button size="small" onClick={toggleEdit} style={{ marginLeft: 8 }}>
                        <EditFilled />
                    </Button>
                </div>
            )}
        </div>
    );
};