/**
 * Booking Dashboard Component
 *
 * @description Component to show bookings status
 * @author Nihala Dilshi
 */

import React, { useState, useEffect } from 'react';

import { Table, Modal, Card } from 'antd';

import { Bookings, StatusMasters } from '../../../../models';

var groupByNested = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        const keys = key.split('.');
        let value = x;
        for (let k of keys) {
            value = value[k];
            if (value === undefined) break;
        }
        const keyValue = value ? value : 'NoStatus';
        (rv[keyValue] = rv[keyValue] || []).push(x);
        return rv;
    }, {});
};


var groupBy = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


/**
 *
 * Component for  Booking
 *
 * @param {*} param0
 */
export default function BookingDashboard({ }) {
    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ bookings: [] });

    const [statusName, setStatusName] = useState([])

    useEffect(() => {
        getBookingStatus();
        getBooking();
    }, []);

    // Get booking status from status master
    async function getBookingStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'BOOKING',
            }
        ];
        const result = await StatusMasters.get(queries);

        // fetching name field 
        result.status_masters.forEach((element) => {
            if(element.otherdetails.display != 'none'){
                statusName.push(element.name);
            }
        });
    }

    // state for status options

    const columns = [
        {
            title: 'Code',
            dataIndex: 'model',

        },

        {
            title: '305',
            className: 'calicut',

            render: (entry) => entry['KL305'].mtd,
        },

        {
            className: 'kannur',
            title: '306',

            render: (entry) => entry['KL306'].mtd,
        },

        {
            className: 'malappuram',
            title: '307',

            render: (entry) => entry['KL307'].mtd,
        },
        {
            className: 'kondotty',
            title: '505',

            render: (entry) => entry['KL505'].mtd,
        },
        {
            className: 'tirur',
            title: '506',

            render: (entry) => entry['KL506'].mtd,
        },
        {
            className: 'vadakara',
            title: '507',

            render: (entry) => entry['KL507'].mtd,
        },
        {
            className: 'kasargod',
            title: '508',

            render: (entry) => entry['KL508'].mtd,
        },
        {
            className: 'newaddition',
            title: 'New additions',
        },

        {
            className: 'total',
            title: 'Total',

            render: (entry) => entry.all.mtd,
        },
    ];


    /**
     *
     * Component for Booking
     *
     * @param {*} param0
     */

    async function getBooking() {

        var queries = [
            {
                field: 'actual_billing.status',
                value: 'pending',
            },
        ];

        Bookings.getAll(queries, 'bookings').then((result) => {
            const filteredBookings = result.all.filter((booking) => !booking.isCancelled && (booking && booking.current && booking.current.currentStatus));

            setContent({ bookings: filteredBookings });

            generateBookingDashboard(filteredBookings)
                .then((arr) => {
                    setSummary(arr);
                })
                .catch((error) => {
                    console.error('Error generating booking dashboard:', error);
                });

        });
    }

    /**
     *
     * Component for generateBookingDashboard
     *
     * @param {*} param0
     */

    async function generateBookingDashboard(bookings) {
        var arr = [];

        // grouping booking have current status 
        const updatedData = bookings.map(obj => {
            obj.status = obj.current.currentStatus ? obj.current.currentStatus : 'NoStatus';
            return obj;
        });

        const status = groupByNested(updatedData, 'status');



        statusName.forEach((model) => {

            var towns = groupBy(status[model], 'Dealer Code');

            let town = {
                model: model,
                KL305: {
                    mtd: 0,
                },
                KL306: {
                    mtd: 0,
                },
                KL307: {
                    mtd: 0,
                },
                KL505: {
                    mtd: 0,
                },
                KL506: {
                    mtd: 0,
                },
                KL507: {
                    mtd: 0,
                },
                KL508: {
                    mtd: 0,
                },
                all: {
                    mtd: 0,
                },
            };

            ['KL305', 'KL306', 'KL307','KL505', 'KL506', 'KL507','KL508', 'New additions'].forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                    };
                } else {
                    town[code] = { mtd: 0 };
                }
            });

            if (status[model]) {
                town.all = {
                    mtd: status[model].length,
                };
            }

            arr.push(town);
        });

        return arr;
    }

    return (
        <>
            <div>
                <>
                    {content['bookings'].length ? (
                        <Card title={`BOOKINGS`} style={{ width: '100%', textAlign: 'center' }}>
                            <Table
                                size="small"
                                scroll={{ x: true }}
                                rowKey={(record) => record.id}
                                dataSource={summary}
                                columns={columns}
                                pagination={false}
                                summary={(pageData) => {
                                    let summary = {
                                        KL305: {
                                            mtd: 0,
                                        },
                                        KL306: {
                                            mtd: 0,
                                        },
                                        KL307: {
                                            mtd: 0,
                                        },
                                        KL505: {
                                            mtd: 0,
                                        },
                                        KL506: {
                                            mtd: 0,
                                        },
                                        KL507: {
                                            mtd: 0,
                                        },
                                        KL508: {
                                            mtd: 0,
                                        },
                                        newaddition: {
                                            mtd: 0,
                                        },
                                        all: {
                                            mtd: 0,
                                        },
                                    };

                                    pageData.forEach((entry) => {
                                        ['KL305', 'KL306', 'KL307','KL505', 'KL506', 'KL507','KL508', 'newaddition', 'all'].forEach((code) => {
                                            if (entry[code]) {
                                                summary[code].mtd += entry[code].mtd;
                                            }
                                        });
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>Total</strong>
                                                </Table.Summary.Cell>

                                                {['KL305', 'KL306', 'KL307', 'KL505', 'KL506', 'KL507','KL508','newaddition', 'all'].map((code) => {
                                                    return (
                                                        <>
                                                            <Table.Summary.Cell>
                                                                <strong>{summary[code].mtd}</strong>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    );
                                                })}
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            ></Table>

                        </Card>
                    ) : null}
                </>
            </div>
        </>
    );

}



