import React, { useState, useEffect, useContext } from 'react';

import firebase from 'firebase/compat/app';

import 'firebase/compat/firestore';
// import 'firebase/compat/auth';

import 'firebase/compat/database'; // If using Firebase database
import 'firebase/compat/firestore'; // If using Firebase database
import 'firebase/compat/auth'; // If using Firebase database
import 'firebase/compat/storage'; // If using storage
import 'firebase/compat/functions';

// import firebase from 'firebase/app';

// import 'firebase/database'; // If using Firebase database
// import 'firebase/firestore'; // If using Firebase database
// import 'firebase/auth'; // If using Firebase database
// import 'firebase/storage'; // If using storage
// import 'firebase/functions';

// Add the Performance Monitoring library
import 'firebase/compat/performance';

import moment from 'moment';

import { HighlightOutlined, SmileOutlined, SmileFilled, UploadOutlined } from '@ant-design/icons';

import { message, notification, Button } from 'antd';
import { cityKeys } from '../modules/global-config';

// import 'firebase/storage';

// Get a reference to the database service
// var database = firebase.database();

var firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId,
};

var app = {};

var storage = {};

var user = {};

var functions = {};

var start = null;

var database = {};

let collectionUrl = 'customer_entries';

let customerReceiptUrl = 'bank_credits';

let bankStatementUrl = 'bank';

let firstTime = true;

const Timestamp = firebase.firestore.Timestamp;

// Maintaining each flag for each process
let defaultStats = {
    accounts: {
        status: 'pending',
    },
    allocation: {
        status: 'pending',
    },
    finance: {
        status: 'pending',
        process: 'incomplete',
    },
    // insurance: {
    //     status: 'pending',
    //     process: 'incomplete'

    // },

    accessories: {
        status: 'pending',
        process: 'incomplete',
    },
    warranty: {
        status: 'pending',
        process: 'incomplete',
    },
    billing: {
        status: 'pending',
    },
};

var cityKey = cityKeys

class FirebaseUtils {
    static getFirebaseObject = () => {
        return firebase;
    };

    static getApp = () => {
        return app;
    };

    static connect = () => {
        return new Promise((resolve, reject) => {
            firebase.initializeApp(firebaseConfig);

            // for running locally

            // firebase.functions().useFunctionsEmulator('http://localhost:5001');

            storage = firebase.storage();
            database = firebase.database();

            var starCountRef = firebase.database().ref('version');

            starCountRef.on('value', () => {
                const btn = (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            window.location.reload(true);
                        }}
                    >
                        Reload
                    </Button>
                );

                if (!firstTime) {
                    notification.open({
                        message: 'Hello there,',
                        btn,
                        onClose: () => {
                            window.location.reload(true);
                        },
                        description:
                            'We have released an update to the application. Browser would reload the application to fetch the latest updates. Thank you.',
                        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
                    });
                } else {
                    firstTime = false;
                }
            });

            app = firebase.firestore();

            // Initialize Performance Monitoring and get a reference to the service
            const perf = firebase.performance();

            // storage = firebase.storage();

            // firebase.firestore.setLogLevel("debug");
            // if (window.location.hostname === "localhost") {
            // app.useEmulator("localhost", 5005);
            // }

            // Initialize Cloud Functions through Firebase
            // functions = firebase.functions();
            // functions.useEmulator('localhost', 5001);

            //functions.setLogLevel("debug");
            // if (process.env.NODE_ENV === 'development') {
            // firebase.functions().useEmulator('localhost', 5001);
            // }

            resolve(app, database);
        });
    };

    static onVersionUpdate = (callback) => {
        var starCountRef = firebase.database().ref('version');

        starCountRef.on('child_changed', callback);
    };

    static getUserInfo = (uid) => {
        return app
            .collection('users')
            .doc(uid)
            .get()
            .then((result) => {
                user = { ...result.data(), id: result.id };
                return result;
            });
    };

    static updateProfile = (uid, params) => {
        return app.collection('users').doc(uid).update(params);
    };

    static getToken = () => {
        return firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                return idToken;
            })
            .catch(function (error) {
                return '';
            });
    };

    static getAuthStatus = () => {
        return new Promise((resolve, reject) => {
            return firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // console.log(user.getIdToken());

                    user.getIdToken().then(function (idToken) {
                        console.log(idToken);

                        if (user) {
                            return FirebaseUtils.getUserInfo(user.uid).then((result) => {
                                let data = {
                                    ...result.data(),
                                    ...{ id: user.uid },
                                    token: idToken,
                                };

                                // console.log(data);
                                resolve(data);
                            });
                        } else {
                            resolve(true);
                        }
                    });
                } else {
                    if (user) {
                        return FirebaseUtils.getUserInfo(user.uid).then((result) => {
                            let data = {
                                ...result.data(),
                                ...{ id: user.uid },
                                // token: idToken
                            };

                            // console.log(data);
                            resolve(data);
                        });
                    } else {
                        resolve(false);
                    }
                }
            });
        });
    };

    static loginWithPhone = () => {
        return new Promise((resolve, reject) => {
            // firebase.auth().settings.appVerificationDisabledForTesting = true;

            var appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');

            var phoneNumber = '+917899205111';

            // var appVerifier = response;

            firebase
                .auth()
                .signInWithPhoneNumber(phoneNumber, appVerifier)
                .then(function (confirmationResult) {
                    resolve(confirmationResult);
                })
                .catch(function (error) {
                    // console.log(error);
                });

            // var recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            //     'size': 'invisible',
            //     'callback': function (response) {

            //         var appVerifier = response;

            //         firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            //             .then(function (confirmationResult) {

            //                 console.log(confirmationResult);

            //                 resolve(confirmationResult);

            //             }).catch(function (error) {

            //                 console.log(error);
            //             });

            //     }
            // });
        });
    };

    static loginWithEmail = ({ email, password }) => {
        return new Promise((resolve, reject) => {
            return firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;

                    console.log(errorCode, errorMessage);
                })
                .then((result) => {
                    if (result) {
                        result.user.getIdToken().then(function (idToken) {
                            return FirebaseUtils.getUserInfo(result.user.uid).then((response) => {
                                let data = {
                                    // ...result
                                    ...response.data(),
                                    ...{ id: result.user.uid },
                                    token: idToken,
                                };

                                resolve(data);
                            });
                        });
                    } else {
                        reject({});
                    }
                });
        });
    };

    static resetPassword = (email) => {
        return firebase.auth().sendPasswordResetEmail(email);
    };

    static changePassword = (details) => {
        var user = firebase.auth().currentUser;

        var newPassword = details.npassword;

        return user.updatePassword(newPassword);
    };

    static loginWithID = (id) => {
        console.log('id', id);
        var createCustomToken = firebase.functions().httpsCallable('createCustomToken');

        return createCustomToken({ uid: id }).then(function (result) {
            console.log(result);

            return firebase
                .auth()
                .signInWithCustomToken(result.data)
                .then((result) => {
                    console.log('r', result);
                    return FirebaseUtils.getUserInfo(result.user.uid);
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;

                    console.log(errorCode, errorMessage);
                });
        });
    };

    // used component is not using anywhere
    static writeBookings = (bookings, city) => {
        var batch = app.batch();

        Object.keys(bookings).forEach((key) => {
            var docRef = app.collection('root').doc(city).collection('bookings').doc('list').collection('items').doc(key);

            batch.set(docRef, [key], { merge: true });
        });

        var timeRef = app.collection('root').doc(city).collection('bookings').doc('lastUpdated');

        batch.set(timeRef, { timestamp: moment().format('DD/MM/YYYY HH:mm') });

        return batch.commit();
    };

    // Receipt Related

    // Get All the collections
    static getAllReceipts = (queries = []) => {
        let all = [];

        var receipts = {};

        var locations = ['kozhikode', 'malappuram', 'kannur', 'kondotty', 'tirur', 'vadakara', 'kasargod'];

        var locationPromise = (location) => {
            return FirebaseUtils.getReceipts(location, queries).then((result) => {
                all = [...all, ...result.receipts];

                receipts[location] = result;
                // console.log(receipts,location);

                return receipts;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, receipts });
            });
        });
    };

    /**
     *
     * Get all the receipts
     *
     * @param {*} city
     * @param {*} queries
     */
    static getReceipts = (city, queries = []) => {
        return this.getListing(city, queries, 'receipts');
    };

    // this function is not used anywhere
    static uploadReceipts = (stock, city) => {
        var batch = app.batch();

        Object.keys(stock).forEach((key) => {
            var docRef = app.collection('root').doc(city).collection('receipts').doc('entries').collection('items').doc(key);

            batch.set(docRef, stock[key], { merge: true });

            // docRef.set({ id: key, ...{ vehicles: stock[key] } })
        });

        var timeRef = app.collection('root').doc(city).collection('receipts').doc('lastUpdated');

        batch.set(timeRef, {
            timestamp: moment().format('DD/MM/YYYY HH:mm'),
        });

        return batch.commit();
        // return app.collection('root').doc('kannur').collection('vehicles').set(stock);
    };

    /**
     * Upload the bank receipts
     * this function is not used anywhere
     */
    static uploadBankStatement = (entries, bank) => {
        var batch = app.batch();

        entries.forEach((entry, index) => {
            var docRef = app.collection('master').doc(bankStatementUrl).collection(bank).doc('entries').collection('items').doc();

            batch.set(docRef, entry, { merge: true });

            // docRef.set({ id: key, ...{ vehicles: stock[key] } })
        });

        var timeRef = app.collection('master').doc(bankStatementUrl).collection(bank).doc('lastUpdated');

        batch.set(timeRef, {
            timestamp: moment().format('DD/MM/YYYY HH:mm'),
        });

        return batch.commit();
    };

    // Receipt Related Ends

    // Stock Related

    static getAllVehicles = (queries = []) => {
        let all = [];

        var stock = {};

        var locations = ['kozhikode', 'malappuram', 'kannur', 'kondotty', 'tirur', 'vadakara', 'kasargod'];

        var locationPromise = (location) => {
            return FirebaseUtils.getVehicles(location, queries).then((result) => {
                all = [...all, ...result.vehicles];

                stock[location] = result.vehicles;
                // console.log(stock,location);

                return stock;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, stock });
            });
        });

        // return new Promise((resolve) => {
        //     Promise.all(locations.map((location) => locationPromise(location))).then(
        //         (result) => {
        //             resolve({ all, stock });
        //         }
        //     );
        // });
    };

    /**
     *
     * Generic Method to get vehicles
     *
     * @param {*} city
     * @param {*} queries
     */
    static getVehicles = (city, queries = []) => {
        return this.getListing(
            city,
            queries,
            'vehicles'
            // 'vehicles',
            // 'vehicleId'
        );
    };

    /**
     * Function Should return the stock for location that is available for sale
     */
    static getStock = (city, returnAll) => {
        let obj = { vehicles: [], lastUpdated: {} };

        let p = {};

        return new Promise((resolve, reject) => {
            var ref = app
                // .collection('root')
                // .doc(city)
                .collection('vehicles');
            // .doc('list')
            // .collection('items');

            if (city) {
                ref = ref.where('dealerCode', '==', cityKey[city]);
            }

            if (!returnAll) {
                ref.where('Stock Status', 'in', ['Free Stock', 'In transit']);
            }

            // .where('update', '!=', 'remove')
            ref.get().then((querySnapshot) => {
                //
                querySnapshot.forEach(function (doc) {
                    var data = doc.data();

                    // !data.allocated &&
                    if (["remove", "bbnd"].indexOf(data.update) === -1) {
                        p[doc.id] = data;
                    }
                });

                console.log(p);

                //
                Object.keys(p)
                    .sort((c, d) => {
                        var a = p[c];

                        var b = p[d];

                        var aDays = parseInt(a['Stock Age']);

                        var bDays = parseInt(b['Stock Age']);

                        if (aDays < bDays) {
                            return 1;
                        }

                        if (aDays > bDays) {
                            return -1;
                        }
                        return 0;
                    })
                    .forEach((v) => {
                        obj.vehicles.push(p[v]);
                    });

                app.collection('uploads')
                    .where('model', '==', 'vehicles')
                    .orderBy('created_time')
                    .limit(1)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach(function (doc) {
                            obj.lastUpdated = {
                                ...doc.data(),
                                id: doc.id,
                            };
                        });

                        resolve(obj);
                    });
            });
        });
    };

    static getAllStock = (returnAll) => {
        let all = [];

        var stock = {};

        var locations = ['kozhikode', 'malappuram', 'kannur', 'kondotty', 'tirur', 'vadakara', 'kasargod'];

        var locationPromise = (location) => {
            return FirebaseUtils.getStock(location, returnAll).then((result) => {
                all = [...all, ...result.vehicles];

                stock[location] = result.vehicles;
                // console.log(stock,location);

                return stock;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, stock });
            });
        });
    };

    static getPossibleVehicles = (model, queries) => {
        console.log(model);

        let obj = {};

        return new Promise((resolve, reject) => {
            let ref = app.collection('vehicles');
            ref = ref.where('Stock Status', 'in', ['Free Stock', 'In transit']);

            if (model != '') {
                ref = ref.where('Model', '==', model);
            }

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();

                    // Has to filter removed vehicles
                    if (!data.allocated && data.update !== 'remove') {
                        obj[doc.id] = doc.data();
                    }
                });

                resolve(obj);
            });

            // return (
            //     app
            //         .collection('root')
            //         .doc(city)
            //         .collection('vehicles')
            //         .doc('list')
            //         .collection('items')
            //         .where('Stock Status', 'in', ['Free Stock', 'In transit'])
            //         .where('Model', '==', model)
            //         // .where('update', '!=', 'remove')
            //         .get()
            //         .then((querySnapshot) => {
            //             querySnapshot.forEach(function (doc) {
            //                 let data = doc.data();

            //                 // Has to filter removed vehicles
            //                 if (!data.allocated && data.update !== 'remove') {
            //                     obj[doc.id] = doc.data();
            //                 }
            //             });

            //             resolve(obj);
            //         })
            // );
        });
    };

    // this function is not used anywhere
    static deleteVehicle = (city, vin) => {
        return app.collection('root').doc(city).collection('vehicles').doc('list').collection('items').doc(vin).delete();
    };

    // this function is not used anywhere
    static updateVehicle = (city, vin, field, value) => {
        return app
            .collection('root')
            .doc(city)
            .collection('vehicles')
            .doc('list')
            .collection('items')
            .doc(vin)
            .update({
                [field]: value,
            });
    };

    // Stock Related Ends

    // Booking Related Ends

    // Request Generic Function
    static createRequest = (city, booking, user, vehicle, invoice) => {
        // For Existing Allocations Only
        if (vehicle && vehicle['Vin Number']) {
            defaultStats.allocation = {
                vinNo: vehicle['Vin Number'],
                status: 'Allocated',
                remarks: 'Auto Allocated',
            };
        }

        // If invoiced update the status
        if (invoice && invoice['Vin Number']) {
            defaultStats.allocation = {
                vinNo: invoice['Vin Number'],
                status: 'Allocated',
                remarks: 'Auto Allocated',
            };

            defaultStats.billing = {
                status: 'approved',
                remarks: 'Auto Billed',
            };
        }

        let created_by = {
            created_by: user.id,
            created_by_name: user.name,
            created_by_role: user.role,
        };

        let customer = {
            customerName: booking['Name of the Customer'],
            phone: booking['Contact Number'],
            // place: booking["Ship To Add"],
            customerID: booking['Customer ID'],
        };

        let basic = {
            status: 'Request Created',
            model: booking['Model'],
            color: booking['Color'],
            city: city,
            dealerCode: booking['Dealer Code'],
            variant: booking['Variant'],
            kec: booking['Consultant Name'],
            teamLeader: booking['Team Leader'] || 'NA',
            bookingNo: (booking['Booking No'].substring(0, 4) !== 'KL30' && booking['Booking No'].substring(0, 4) !== 'KL50') ? booking['Dealer Code'] + booking['Booking No'] : booking['Booking No'],
            customer: customer,
            bookingId: booking['Booking No'],
            created_at: new Date(),
            deleted_at: null,
            deleted_by: null,
        };

        if (booking['consultant_id']) {
            basic = {
                ...basic,
                consultant_id: booking['consultant_id'],
            };
        }

        if (booking['teamleader_id']) {
            basic = {
                ...basic,
                teamleader_id: booking['teamleader_id'],
            };
        }

        let request = {
            ...defaultStats,
            ...created_by,
            ...basic,
            ...{
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                booking_date: moment(booking['Booking Date'], 'DD/MM/YYYY HH:mm').valueOf(),
            },
        };

        if (booking['Committed Delivery Date']) {
            request.expectedDate = booking['Committed Delivery Date'];
        }

        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .add(request)
        );
    };

    /**
     *
     * High priority Request
     *
     * @param {*} city
     * @param {*} request
     * @param {*} user
     */
    static createHighPriorityRequest = (city, request, booking, user) => {
        let created_by = {
            created_by: user.id,
            created_by_name: user.name,
            created_by_role: user.role,
        };

        if (booking) {
            request = {
                ...request,
                kec: booking['Consultant Name'],
                teamLeader: booking['Team Leader'] || 'NA',
                bookingNo: booking['Booking No'],
            };
        }

        let params = {
            ...defaultStats,
            ...request,
            created_by: created_by,
            // ...basic,
            ...{
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                // booking_date: moment(booking['Booking Date'], 'DD/MM/YYYY HH:mm').valueOf(),
            },
            deleted_at: null,
            deleted_by: null,
        };

        if (booking && booking['Committed Delivery Date']) {
            params.expectedDate = booking['Committed Delivery Date'];
        }

        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .add(params)
        );
    };

    static addRequest = (newBooking) => {
        return app.collection('requests').add(newBooking);
    };

    static blockVehicle = (vin, newBooking) => {
        return app.collection('vehicles').doc(vin).update({
            allocated: newBooking,
        });
    };

    static allocateVehicle = (newVehicle) => {
        return app.collection('allocations').add(newVehicle);
    };

    // this function is not used anywhere
    static blockCar = (city, vin, allocation) => {
        return app.collection('root').doc(city).collection('vehicles').doc('list').collection('items').doc(vin).update({
            requestId: allocation.id,
            allocated: allocation,
        });
    };

    /**
     * Generic Function to add any field with value to model
     */
    static updateRequest = (id, field, value) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .doc(id)
                .update({
                    [field]: value,
                })
        );
    };

    // this function is same as updateRequest
    static updateRequestBody = (id, body) => {
        return app.collection('requests').doc(id).update(body);
    };

    // this function is not used anywhere
    static assignVehicle = (city, id, vehicle, eligibleForDMSAllocation) => {
        return app
            .collection('root')
            .doc(city)
            .collection('requests')
            .doc(id)
            .update({
                vinNo: vehicle['Vin Number'],
                status: 'Vehicle Allocated',
                dmsAllocation: eligibleForDMSAllocation ? 'approved' : 'pending',
            });
    };

    static unassignVehicle = (id) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .doc(id)
                .update({
                    allocation: {
                        status: 'pending',
                    },
                    vinNo: firebase.firestore.FieldValue.delete(),
                    dmsAllocation: firebase.firestore.FieldValue.delete(),
                    allocationRemarks: firebase.firestore.FieldValue.delete(),
                    status: 'Vehicle Unallocated', //todo fix this ,
                })
        );
    };

    static unblockVehicle = (vin) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('vehicles')
                // .doc('list')
                // .collection('items')
                .doc(vin)
                .update({
                    status: 'new',
                    allocated: firebase.firestore.FieldValue.delete(),
                })
        );
    };

    static usersManage = (city, user) => {
        return app.collection('user');
    };

    static updateBooking = (bookingNo, flag, requestId) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('bookings')
                // .doc('list')
                // .collection('items')
                .doc(bookingNo)
                .update({
                    // return app.collection('root').doc(city).collection('bookings').doc(bookingNo).update({
                    // requestCreated: flag,
                    verified: true,
                    requestId: requestId,
                    status: 'Booking Verified',
                })
        );
    };

    static deleteRequest = (requestId) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .doc(requestId)
                .delete()
        );
    };

    static removeRequest = (bookingNo) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('bookings')
                // .doc('list')
                // .collection('items')
                .doc(bookingNo)
                .update({
                    requestId: firebase.firestore.FieldValue.delete(),
                })
        );
    };

    // this function is not used anywhere
    // Requested by Kec to link the booking with request
    static requestLink = (city, bookingNo, requestId) => {
        return app.collection('root').doc(city).collection('requests').doc(requestId).update({
            requestLink: bookingNo,
            status: 'Under Review',
            // status: 'Booking Verified',
            // verified: true,
            // bookingNo: bookingNo
        });
    };

    /**
     * Primary function to get the info of a vehicle
     */
    static getVehicle = (vin) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('vehicles')
                // .doc('list')
                // .collection('items')
                .doc(vin)
                .get()
                .then((ref) => {
                    return ref.data();
                })
        );
    };

    static getBookings = (city, queries = []) => {
        return this.getListing(city, queries, 'bookings');
    };

    // this function is used but the used component is not used anywhere
    static getBookingStats = (city) => {
        let obj = {};

        return new Promise((resolve, reject) => {
            app.collection('root')
                .doc(city)
                .collection('bookings')
                .doc('list')
                .collection('items')
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(function (doc) {
                        obj[doc.id] = doc.data();
                    });

                    resolve(obj);
                });
        });
    };

    static getFreeBookings = (queries) => {
        let obj = {};

        return new Promise((resolve, reject) => {
            var ref = app.collection('bookings');

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    obj[doc.id] = doc.data();
                });

                resolve(obj);
            });
        });
    };

    static searchBookings = (city, options) => {
        let obj = { bookings: {}, lastUpdated: {} };

        let start = null;

        let end = null;

        return new Promise((resolve, reject) => {
            var ref = app
                // .collection('root')
                // .doc(city)
                .collection('bookings')
                // .doc('list')
                // .collection('items')
                .where(options.field, '==', options.value)
                .where('dealerCode', '==', cityKey[city])
                .get()
                .then((querySnapshot) => {
                    var count = 1;

                    start = querySnapshot.docs[0];

                    end = querySnapshot.docs[querySnapshot.docs.length - 1];

                    // console.log(start, end);

                    querySnapshot.forEach(function (doc) {
                        obj.bookings[doc.id] = doc.data();

                        obj.bookings[doc.id].index = count++;
                    });

                    // app.collection('bookings')
                    //     .doc('lastUpdated')
                    //     .get()
                    //     .then((querySnapshot) => {
                    //         obj.lastUpdated = querySnapshot.data() || {};

                    //         obj.start = start;

                    //         obj.end = end;

                    //         resolve(obj);
                    //     });

                    app.collection('uploads')
                        .where('model', '==', 'bookings')
                        .orderBy('created_time')
                        .limit(1)
                        .get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach(function (doc) {
                                obj.lastUpdated = {
                                    ...doc.data(),
                                    id: doc.id,
                                };
                            });

                            obj.start = start;
                            obj.end = end;

                            resolve(obj);
                        });
                });
        });
        // }
    };

    /**
     * Generic Function to be build for Manage Bookings
     */
    static loadBookings = (city, options, prev) => {
        let obj = { bookings: {}, lastUpdated: {} };

        let start = null;

        let end = null;

        return new Promise((resolve, reject) => {
            let ref = app
                // .collection('root')
                // .doc(city)
                .collection('bookings');
            // .doc('list')
            // .collection('items');

            ref = ref.where('dealerCode', '==', cityKey[city]);

            if (options.start) {
                ref = ref.orderBy('Booking No').startAt(options.start);
            }

            ref = ref.limit(options.pageSize);

            ref.get().then((querySnapshot) => {
                var count = 1;

                start = querySnapshot.docs[0];

                end = querySnapshot.docs[querySnapshot.docs.length - 1];

                querySnapshot.forEach(function (doc) {
                    obj.bookings[doc.id] = doc.data();

                    obj.bookings[doc.id].index = count++;
                });

                // app.collection('root')
                //     .doc(city)
                //     .collection('bookings')
                //     .doc('lastUpdated')
                //     .get()
                //     .then((querySnapshot) => {
                //         obj.lastUpdated = querySnapshot.data() || {};

                //         obj.start = start;

                //         obj.end = end;

                //         resolve(obj);
                //     });
                app.collection('uploads')
                    .where('model', '==', 'bookings')
                    .orderBy('created_time')
                    .limit(1)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach(function (doc) {
                            obj.lastUpdated = {
                                ...doc.data(),
                                id: doc.id,
                            };
                        });

                        obj.start = start;
                        obj.end = end;

                        resolve(obj);
                    });
            });
        });
    };

    /**
     * Generic Function to be build for Manage Bookings
     */
    static getPrevBookings = (city, options) => {
        let obj = { bookings: {}, lastUpdated: {} };

        let start = null;

        let end = null;

        return new Promise((resolve, reject) => {
            var ref = app
                // .collection('root')
                // .doc(city)
                .collection('bookings');
            // .doc('list')
            // .collection('items')
            // .orderBy('Booking No')
            // .startAt(options.start)
            // .limit(options.pageSize);

            ref = ref.orderBy('Booking No').startAt(options.start).limit(options.pageSize);
            ref = ref.where('dealerCode', '==', cityKey[city]);
            ref.get().then((querySnapshot) => {
                var count = 1;

                start = querySnapshot.docs[0];

                end = querySnapshot.docs[querySnapshot.docs.length - 1];

                // console.log(start, end);

                querySnapshot.forEach(function (doc) {
                    obj.bookings[doc.id] = doc.data();

                    obj.bookings[doc.id].index = count++;
                });

                // app.collection('root')
                //     .doc(city)
                //     .collection('bookings')
                //     .doc('lastUpdated')
                //     .get()
                //     .then((querySnapshot) => {
                //         obj.lastUpdated = querySnapshot.data() || {};

                //         obj.start = start;

                //         obj.end = end;

                //         resolve(obj);
                //     });
                app.collection('uploads')
                    .where('model', '==', 'bookings')
                    .orderBy('created_time')
                    .limit(1)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach(function (doc) {
                            obj.lastUpdated = {
                                ...doc.data(),
                                id: doc.id,
                            };
                        });

                        obj.start = start;
                        obj.end = end;

                        resolve(obj);
                    });
            });
        });
        // }
    };

    /**
     * Generic Function to be build for Manage Bookings
     */
    static getNextBookings = (city, options) => {
        let obj = { bookings: {}, lastUpdated: {} };

        let start = null;

        let end = null;

        return new Promise((resolve, reject) => {
            let ref = app
                // .collection('root')
                // .doc(city)
                .collection('bookings');
            // .doc('list')
            // .collection('items');

            if (options.end) {
                ref = ref.orderBy('Booking No').startAfter(options.end);
            }

            ref = ref.where('dealerCode', '==', cityKey[city]);

            ref = ref.limit(options.pageSize);

            ref.get().then((querySnapshot) => {
                var count = 1;

                start = querySnapshot.docs[0];

                end = querySnapshot.docs[querySnapshot.docs.length - 1];

                // console.log(start, end);

                querySnapshot.forEach(function (doc) {
                    obj.bookings[doc.id] = doc.data();

                    obj.bookings[doc.id].index = count++;
                });

                // app.collection('root')
                //     .doc(city)
                //     .collection('bookings')
                //     .doc('lastUpdated')
                //     .get()
                //     .then((querySnapshot) => {
                //         obj.lastUpdated = querySnapshot.data() || {};

                //         obj.start = start;

                //         obj.end = end;

                //         resolve(obj);
                //     });
                app.collection('uploads')
                    .where('model', '==', 'bookings')
                    .orderBy('created_time')
                    .limit(1)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach(function (doc) {
                            obj.lastUpdated = {
                                ...doc.data(),
                                id: doc.id,
                            };
                        });

                        obj.start = start;
                        obj.end = end;

                        resolve(obj);
                    });
            });
        });
    };

    // Allocation Request related

    static getAllRequests = (queries = []) => {
        let all = [];

        var requests = {};

        var locations = ['kozhikode', 'malappuram', 'kannur', 'kondotty', 'tirur', 'vadakara', 'kasargod'];

        var locationPromise = (location) => {
            return FirebaseUtils.getRequests(location, queries).then((result) => {
                // all = { ...all, ...result.requests }

                all = [].concat(...all, ...result.requests);

                requests[location] = result.requests;
                // console.log(requests,location);

                return requests;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, requests });
            });
        });
    };

    static getMyRequests = (city, consultant) => {
        let obj = { requests: {}, lastUpdated: {} };

        return new Promise((resolve, reject) => {
            app.collection('requests')
                .where('kec', '==', consultant)
                .where('dealerCode', '==', cityKey[city])
                .get()
                .then((querySnapshot) => {
                    var count = 1;

                    querySnapshot.forEach(function (doc) {
                        obj.requests[doc.id] = doc.data();

                        obj.requests[doc.id].index = count++;
                    });

                    resolve(obj);
                });
        });
    };

    static getMyTeamsRequests = (city, consultant, smRequest) => {
        var ref;

        let obj = { requests: {}, lastUpdated: {} };

        return new Promise((resolve, reject) => {
            ref = app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .where('dealerCode', '==', cityKey[city])
                .where('teamLeader', '==', consultant);

            if (smRequest) {
                ref = ref.where('status', '==', 'Verification Pending');
            }

            ref.get().then((querySnapshot) => {
                var count = 1;

                querySnapshot.forEach(function (doc) {
                    obj.requests[doc.id] = doc.data();

                    obj.requests[doc.id].index = count++;
                });

                resolve(obj);
            });
        });
    };

    /**
     *
     * Generic listing for requests
     *
     * @param {*} city
     * @param {*} queries
     */
    static getRequests = (city, queries = []) => {
        return this.getRecords(city, queries, 'requests');
    };

    /**
     * Upload the file to storage
     */
    static updateERD = (id, erd, request) => {
        let params = {
            finance: {
                ...request.finance,
                expected_retail_date: moment(erd).valueOf(),
            },
        };

        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .doc(id)
                .update(params)
        );
    };

    static getRequest = (requestId) => {
        // Whenever we return the requests we need to find the present state of many selected packages
        // This would include extended warranty value , accessories cost , value added service cost

        return new Promise((resolve, reject) => {
            app.collection('requests')
                .doc(requestId)
                .get()
                .then((result) => {
                    if (result.data()) {
                        let accessories = {
                            choices: [],
                        };

                        let request = {
                            ...accessories,

                            ...result.data(),
                            ...{ id: result.id },
                        };

                        resolve(request);

                        // If the accessory selection is completed we have to do it from the request, else from the request
                        // if (
                        //     request.accessories &&
                        //     request.accessories.status != 'pending' &&
                        //     request.accessories.choices
                        // ) {
                        //     Promise.all(
                        //         request.accessories.choices.map(
                        //             (choice, index) => {
                        //                 var partNo =
                        //                     choice.partNo || choice['PART NO'];

                        //                 if (partNo) {
                        //                     return FirebaseUtils.getListingRecord(
                        //                         city,
                        //                         'accessories',
                        //                         null,
                        //                         partNo
                        //                     ).then((result) => {
                        //                         let part = result.data();

                        //                         request.accessories.choices[
                        //                             index
                        //                         ] = {
                        //                             ...choice,
                        //                             ...part
                        //                         };
                        //                     });
                        //                 } else {
                        //                     return false;
                        //                 }
                        //             }
                        //         )
                        //     ).then(() => {
                        //         resolve(request);
                        //     });

                        // } else {
                        //     Lets suppose the values from booking is calculated

                        //     resolve(request);
                        // }

                        // return request;
                    } else {
                        resolve(null);
                        // return null;
                    }
                });
        });
    };

    static cancelRequest = (id, index) => {
        // vehicle.bookings.splice(index, 1);
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .doc(id)
                .delete()
        );

        // return app.database().ref('stock/' + vehicle['Vin Number'] + '/bookings/').set(vehicle.bookings);
    };

    static createCancelRequest = (values) => {
        return app.collection('cancellations').add(values);
    };

    // Allocation Request related Ends

    // Requests for the finance coordinator

    static uploadFile = (files, customer) => {
        var url = 'documents/' + customer.customerName + '-' + +new Date();

        var ref = storage.ref(url);

        return ref.put(files).then((snapshot) => {
            console.log(snapshot);

            return ref.getMetadata().then((result) => {
                return snapshot.ref.getDownloadURL().then((downloadUrl) => {
                    let meta = { contentType: result.contentType };

                    return { url: downloadUrl, meta: meta };
                });
            });
        });
    };
    /**
     * Document upload
     */
    static handleUpload = (files, customer, index) => {
        var hide;

        // var url = index + customer.customerName + '-' + +new Date();

        var url = index + customer.customerName + files.name + '-' + new Date();

        var ref = storage.ref(url);

        var uploadTask = ref.put(files);

        uploadTask.on('state_changed', (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            console.log('Upload is ' + progress + '% done');

            console.log(snapshot.state);

            if (progress >= 100) {
                hide && hide();

                message.success(`Upload Complete.`);
            } else {
                // hide = message.loading(`Uploading...`, 1);
            }
        });

        return uploadTask.then((snapshot) => {
            return ref.getMetadata().then((result) => {
                return snapshot.ref.getDownloadURL().then((downloadUrl) => {
                    console.log('downloadurl', downloadUrl);
                    let meta = { contentType: result.contentType };

                    return { url: downloadUrl, meta: meta };
                });
            });
        });
    };
    /**
     * Upload the file to storage
     */
    static uploadAttachment = (files, customer, index) => {
        var hide;

        // var url = index + customer.customerName + '-' + +new Date();

        var url = index + customer.customerName + files.name;

        var ref = storage.ref(url);

        var uploadTask = ref.put(files);

        uploadTask.on('state_changed', (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            console.log('Upload is ' + progress + '% done');

            console.log(snapshot.state);

            if (progress >= 100) {
                hide && hide();

                message.success(`Upload Complete.`);
            } else {
                // hide = message.loading(`Uploading...`, 1);
            }
        });

        return uploadTask.then((snapshot) => {
            return ref.getMetadata().then((result) => {
                return snapshot.ref.getDownloadURL().then((downloadUrl) => {
                    let meta = { contentType: result.contentType };

                    return { url: downloadUrl, meta: meta };
                });
            });
        });
    };

    // Requests for the finance coordinator Ends

    static addComment = (id, comment) => {
        // return app.database().ref('stock/' + vin + '/finance/').set(values);
        return app.collection('logs').doc('comments').collection(id).add(comment);
    };

    static getComments = (id) => {
        let obj = [];

        return new Promise((resolve, reject) => {
            app.collection('logs')
                .doc('comments')
                .collection(id)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(function (doc) {
                        // obj[doc.id] = doc.data();

                        obj.push(doc.data());
                    });

                    resolve(obj);
                });
        });
    };

    static getDocuments = (userRequest) => {
        var arr = [];

        return app
            .collection('documents')
            .where('bookingNo', '==', userRequest.bookingNo)

            .get()
            .then((result) => {
                result.forEach((entry) => {
                    arr.push({
                        ...entry.data(),
                        id: entry.id,
                    });
                });
                console.log(arr);
                return arr;
            });
    };

    static getBooking = (bookingNo) => {
        return app.collection('bookings').doc(bookingNo).get();
    };

    // Accounts Module

    // # todo refactor to only update single field
    static addCollection = (values) => {
        // return app.database().ref('stock/' + vin + '/finance/').set(values);
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection(collectionUrl)
                .add(values)
        );
    };

    static updateCollection = (id, values) => {
        // return app.database().ref('stock/' + vin + '/finance/').set(values);
        return app
            .collection(collectionUrl)
            .doc(id)
            .update({
                ...values,
            });
    };

    // Get All the collections
    static getAllCollections = (queries = []) => {
        let all = [];

        var collection = {};

        var locations = ['kozhikode', 'malappuram', 'kannur', 'kondotty', 'tirur', 'vadakara', 'kasargod'];

        var locationPromise = (location) => {
            return FirebaseUtils.getCollections(location, queries).then((result) => {
                all = [...all, ...result];

                collection[location] = result;
                // console.log(collection,location);

                return collection;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, collection });
            });
        });
    };

    /**
     * Function returns the collections that kec has entered
     */
    static getCollections = (city, queries = []) => {
        var result = [];

        // return app.database().ref('stock/' + vin + '/finance/').set(values);
        var ref = app.collection(collectionUrl);

        queries.forEach((query) => {
            ref = ref.where(query.field, query.operator || '==', query.value);
        });

        // if (city) {
        //     ref = ref.where('dealerCode', '==', cityKey[city])
        // }
        if (city) {
            ref = ref.where('city', '==', city);
        }

        return ref.get().then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
                result.push({ ...doc.data(), collectionId: doc.id });
            });

            return result;
        });
    };

    // # todo refactor to only update single field
    static getCollectionRecord = (id) => {
        return new Promise((resolve, reject) => {
            return (
                app
                    // .collection('root')
                    // .doc(city)
                    .collection(collectionUrl)
                    .doc(id)
                    .get()
                    .then((result) => {
                        var data = { ...result.data(), collectionId: result.id };

                        resolve(data);
                    })
            );
        });
    };

    static getBankReceipts = (bank, queries) => {
        let obj = { bank_receipts: {}, lastUpdated: {} };

        return new Promise((resolve, reject) => {
            var ref = app.collection('master').doc(bankStatementUrl).collection(bank).doc('entries').collection('items');

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            ref = ref.orderBy('transaction_date', 'desc');

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    obj.bank_receipts[doc.id] = doc.data();

                    obj.bank_receipts[doc.id]['receiptId'] = doc.id;
                });

                app.collection('master')
                    .doc(bankStatementUrl)
                    .collection(bank)
                    .doc('lastUpdated')
                    .get()
                    .then((querySnapshot) => {
                        obj.lastUpdated = querySnapshot.data() || {};

                        resolve(obj);
                    });
            });
        });
    };

    // Get All the collections
    static getAllBankCredits = (queries = [], config) => {
        let all = [];

        var receipts = {};

        var locations = ['kozhikode', 'malappuram', 'kannur', 'kondotty', 'tirur', 'vadakara', 'kasargod'];

        var locationPromise = (location) => {
            return FirebaseUtils.getBankCredits(location, queries, config).then((result) => {
                all = [...all, ...result];

                receipts[location] = result;
                // console.log(receipts,location);

                return receipts;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, receipts });
            });
        });
    };

    /**
     * Function returns the collections that kec has entered
     */
    static getBankCredits = (queries = [], config = {}) => {
        var result = [];

        var ref = app
            // .collection('root')
            // .doc(city)
            .collection(customerReceiptUrl);

        return new Promise((resolve, reject) => {
            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            // if (city) {
            //     ref = ref.where('dealerCode', '==', cityKey[city])
            // }

            if (config.orderBy) {
                ref = ref.orderBy(config.orderBy, config.direction);
            }
            if (config.limit) {
                ref = ref
                    .where('mode', '==', config.mode)
                    .orderBy(config.orderBy2, config.direction)
                    .orderBy(config.order, config.direction)
                    .limit(config.limit);
            }

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();

                    result.push({
                        ...data,
                        key: doc.id,
                        receiptId: doc.id,
                    });
                });

                resolve(result);
            });
        });
    };

    // # todo refactor to only update single field
    static getBankCreditRecord = (id) => {
        return new Promise((resolve, reject) => {
            app.collection(customerReceiptUrl)
                .doc(id)
                .get()
                .then((result) => {
                    var data = { ...result.data(), receiptId: result.id };

                    resolve(data);
                });
        });
    };

    static updateBankCreditRecord = (id, values) => {
        return app
            .collection(customerReceiptUrl)
            .doc(id)
            .update({
                ...values,
            });
    };

    // Customer Receipts Section
    // this function is not used anywhere
    static addCustomerReceipt = (city, values) => {
        return app.collection('root').doc(city).collection(customerReceiptUrl).add(values);
    };

    // this function is not used anywhere
    static updateCustomerReceipt = (id, values) => {
        return app
            .collection(customerReceiptUrl)
            .doc(id)
            .update({
                ...values,
            });
    };

    /**
     * Upload the bank receipts
     */
    static uploadBankCredits = (credits, banks, batch) => {
        // var batch = app.batch();

        let codes = {};

        banks.forEach((bank) => {
            codes[bank.code] = bank.location;
        });

        credits.forEach((entry, index) => {
            var docRef = app.collection(customerReceiptUrl).doc();

            batch.set(docRef, entry, { merge: true });

            entry.id = docRef.id;
        });

        return credits;
    };

    // Customer Receipts Section Ends

    /**
     * Create a payment entry for cs accounts
     */
    static createPayment = (entry) => {
        return app.collection('payments').add(entry);
    };

    // For Payout

    static logPayout = (entry) => {
        return (
            app
                // .collection('root')
                // .doc(entry.city)
                .collection('payouts')
                // .doc('list')
                // .collection('items')
                .add(entry)
        );
    };

    static getPayout = (entry) => {
        return app
            .collection('payouts')
            .doc(entry)
            .get()
            .then((res) => {
                return {
                    ...res.data(),
                    id: res.id,
                };
            });
    };

    static updatePayout = (id) => {
        return app.collection('payouts').doc(id).update({
            status: 'rejected',
        });
    };

    // For Payout Ends

    // bill

    static generateBill = (formBody) => {
        var generateBill = firebase.functions().httpsCallable('generateBill');

        return generateBill({ formBody }).then(function (result) {
            console.log(result);
            return result;
        });
    };

    // vas billing

    static generateVasBill = (formBody) => {
        var generateVasBill = firebase.functions().httpsCallable('generateVasBill');
        return generateVasBill({ formBody }).then(function (result) {
            return result;
        });
    };

    // bill ends

    static generateSalesReturn = (formBody) => {
        var generateSalesReturn = firebase.functions().httpsCallable('generateSalesReturn');
        return generateSalesReturn({ formBody }).then(function (result) {
            return result;
        });
    };

    static addPrice = (model, variants) => {
        var batch = app.batch();

        Object.keys(variants).forEach((key) => {
            var docRef = app.collection('master').doc('priceMaster').collection(model).doc('latest').collection('variants').doc(key);

            //     .collection(model).doc('latest')
            // var docRef = app.collection('root').doc(city).collection('vehicles').doc('list').collection('items').doc(key);

            batch.set(docRef, variants[key], { merge: true });

            // docRef.set({ id: key, ...{ vehicles: stock[key] } })
        });

        var timeRef = app.collection('master').doc('priceMaster').collection(model).doc('lastUpdated');

        // var timeRef = app.collection('root').doc(city).collection('vehicles').doc('lastUpdated');

        batch.set(timeRef, {
            timestamp: moment().format('DD/MM/YYYY HH:mm'),
        });

        return batch.commit();

        // return app.collection('master').doc('priceMaster')
        //     .collection(model).doc('latest').set({
        //         variants,
        //         lastUpdated: moment().format('DD/MM/YYYY HH:mm')
        //     });
    };

    static getPrice = (model) => {
        let content = {};

        return app
            .collection('master')
            .doc('priceMaster')
            .collection(model)
            .doc('latest')
            .collection('variants')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    content[doc.id] = doc.data();
                });

                return content;
            });
    };

    static getVariantPrice = (model, variantId) => {
        return app.collection('master').doc('priceMaster').collection(model).doc('latest').collection('variants').doc(variantId).get();
    };

    static getMasterData = (model, queries = []) => {
        let content = {};

        return new Promise((resolve, reject) => {
            var ref = app.collection('master').doc('priceMaster').collection(model).doc('latest').collection('variants');

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    content[doc.id] = doc.data();
                });

                resolve(content);
            });
        });
    };

    static getMaster = () => {
        let banks = [];

        return app
            .collection('pricing')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    banks.push(doc.data());
                });

                var master = groupBy(banks, 'model');

                return { master };
            });

        // let all = {};

        // var master = {};

        // var models = ['carnival', 'sonet', 'seltos'];

        // var locationPromise = (location) => {
        //     return FirebaseUtils.getPrice(location).then((result) => {
        //         // all = { ...all, ...result.data() }

        //         master[location] = result;
        //         // console.log(stock,location);

        //         return master;
        //     });
        // };

        // return new Promise((resolve) => {
        //     Promise.all(models.map((location) => locationPromise(location))).then((result) => {
        //         resolve({ master });
        //     });
        // });
    };

    static getBanks = () => {
        let banks = [];

        return app
            .collection('master')
            .doc('banks')
            .collection('entries')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    banks.push(doc.data());
                });

                return banks;
            });
    };

    static getFinanceBanks = () => {
        let banks = [];

        return app
            .collection('finance_banks')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    banks.push(doc.data());
                });

                return banks;
            });
    };

    static pushIFGap = (report) => {
        return app
            .collection('master')
            .doc('odStatus')
            .collection('entries')
            .add({
                report,
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                transaction_date: moment().startOf('day').valueOf(),
            });
    };

    static getStatus = () => {
        let content = {};

        return new Promise((resolve, reject) => {
            app.collection('master')
                .doc('odStatus')
                .collection('entries')
                .orderBy('transaction_date')
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(function (doc) {
                        content[doc.id] = doc.data();
                    });

                    resolve(content);
                });
        });
    };

    static getApprovedLoans = () => {
        return app.collection('master').doc('odApproved').get();
    };

    // Accounts Module Ends
    // For EDP

    static loadAllStatus = () => {
        let all = {};

        var models = {};

        var modules = ['receipts', 'bookings', 'vehicles', 'sales', 'enquiries'];

        var modulePromise = (module) => {
            return FirebaseUtils.loadStatus(module).then((result) => {
                // all = { ...all, ...result.data() };

                models[module] = result;
                // console.log(models,location);

                return models;
            });
        };

        return new Promise((resolve) => {
            Promise.all(modules.map((module) => modulePromise(module))).then((result) => {
                resolve({ models });
            });
        });
    };

    // For Special Approval
    static loadStatus = (module) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            app.collection('uploads')
                .where('model', '==', module)
                .orderBy('created_time', 'desc')
                .limit(1)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((ele) => {
                        arr.push(ele.data());
                    });

                    resolve(arr[0]);
                });
        });
    };

    // used component is not used anywhere
    static getHighPriority = ({ city, id }) => {
        return app.collection('root').doc(city).collection('priority_list').doc(id).get();
    };

    // function is not used anywhere
    static addHighPriority = (city, newBooking) => {
        return app.collection('root').doc(city).collection('priority_list').add(newBooking);
    };

    static verifyHighPriority = (requestId, comment, isReject) => {
        return new Promise((resolve, reject) => {
            app.collection('logs')
                .doc('comments')
                .collection(requestId)
                .add(comment)
                .then(() => {
                    if (isReject) {
                        app.collection('requests')
                            .doc(requestId)
                            .update({
                                highPriorityStatus: 'rejected',
                            })
                            .then(() => {
                                resolve(false);
                            });
                    } else {
                        app.collection('requests')
                            .doc(requestId)
                            .update({
                                highPriorityStatus: 'approved', // todo this status may change to Request Verified
                            })
                            .then(() => {
                                resolve(true);
                            });
                    }
                });
        });
    };

    // used component is not used anywhere
    static updateHighPriority = (city, id, requestId) => {
        return app.collection('root').doc(city).collection('requests').doc(id).update({
            status: 'processed', // todo this status may change to Request Verified
            requestId: requestId,
        });
    };

    static updateHighPriorityBookingNumber = (id, bookingNo) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('requests')
                .doc(id)
                .update({
                    bookingNo: bookingNo,
                })
        );
    };

    // High Priority List Ends

    // For EDP Ends

    // Admin Users
    static getUsers = () => {
        let content = {};

        return new Promise((resolve, reject) => {
            app.collection('users')
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(function (doc) {
                        content[doc.id] = doc.data();
                    });

                    resolve(content);
                });
        });
    };

    static getEmployees = (queries) => {
        let content = {};

        return new Promise((resolve, reject) => {
            var ref = app.collection('users');

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            // if (role) {
            //     ref = ref.where('role', '==', role)
            // }

            // if (location) {

            //     ref = ref.where('locations', 'array-contains', location)
            // }

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    content[doc.id] = doc.data();
                });

                resolve(content);
            });
        });
    };

    static deleteUser = (userId) => {
        return app.collection('users').doc(userId).delete();
    };

    // Admin Users Ends

    // For Stock Transfer

    static createStockTransfer = (params) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('stock_transfers')
                .add(params)
        );
    };

    static getStockTransfer = (id) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('stock_transfers')
                .doc(id)
                .get()
        );
    };

    static updateStockTransfer = (id, field, value) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('stock_transfers')
                .doc(id)
                .update({
                    [field]: value,
                })
        );
    };

    // For Stock Transfer Ends

    // User Authentication

    static getRefundStatus = ({ formBody }) => {
        var payout = firebase.functions().httpsCallable('getPayout');

        // get the payout
        return payout({ formBody }).then(function (result) {
            console.log(result);
            return result;
        });
    };

    static cancelPayoutLink = ({ formBody }) => {
        var payout = firebase.functions().httpsCallable('cancelPayoutLink');

        // get the payout
        return payout({ formBody }).then(function (result) {
            console.log(result);
            return result;
        });
    };

    static createUser = ({ email, password }) => {
        var createUser = firebase.functions().httpsCallable('createUser');

        return createUser({ email, password }).then(function (result) {
            console.log(result);

            return { user: result.data.uid };
        });
    };

    // static createRefundRequest = ({ formBody }) => {
    //     var createRefundRequest = firebase.functions().httpsCallable('createRefundRequest');

    //     return createRefundRequest({ formBody }).then(function (result) {
    //         console.log(result);
    //         return result;
    //     });
    // };

    static getFinancePenetration = (formBody) => {
        // let payload = {
        //     method: 'POST',
        //     // mode: 'no-cors',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     // body: formBody
        //     body: formBody ? JSON.stringify(formBody) : null
        // };
        var getFinancePenetration = firebase.functions().httpsCallable('getFinancePenetration');

        return getFinancePenetration(formBody).then((result) => {
            return result;
        });

        // var link = 'http://localhost:5001/gbusiness-1601789339241/us-central1/getFinancePenetration';

        // // var link = 'https://us-central1-gbusiness-1601789339241.cloudfunctions.net/getFinancePenetration'

        // return fetch(link, payload)
        //     .then((res) => res.json())
    };

    /**
     *
     */
    static getInsurancePenetration = (formBody) => {
        var getInsurancePenetration = firebase.functions().httpsCallable('getInsurancePenetration');

        return getInsurancePenetration(formBody).then((result) => {
            return result;
        });
    };

    static addUserInfo = (id, values) => {
        return app
            .collection('users')
            .doc(id)
            .set({
                ...values,
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                deleted_at: null,
                deleted_by: null,
            });
    };
    /**
     * Function Adds a new record to the model
     *
     * @param {*} city
     * @param {*} model
     * @param {*} values
     */
    static addDocument = (id, model, values) => {
        return app
            .collection(model)
            .doc(id)
            .set({
                ...values,
            });
    };
    static updateUserInfo = (id, values) => {
        return app
            .collection('users')
            .doc(id)
            .update({
                ...values,
                updated_at: moment().format('DD/MM/YYYY HH:mm'),
            });
    };

    // User Authentication Ends

    /**
     * Get the Node Data
     */
    static getNode = (model, queries) => {
        var arr = [];

        var ref = app.collection(model);

        queries.forEach((query) => {
            ref = ref.where(query.field, query.operator || '==', query.value);
        });

        return ref.get().then((snapshot) => {
            snapshot.forEach((content) => {
                arr.push({
                    id: content.id,
                    ...content.data(),
                });
            });

            return arr;
        });
    };

    static getRecords = (city, queries = [], model) => {
        // Base structure
        let content = { [model]: [] };

        return new Promise((resolve, reject) => {
            var ref = app.collection(model);

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            if (city) {
                ref = ref.where('dealerCode', '==', cityKey[city]);
            }

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    content[model].push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                resolve(content);
            }).catch((error) => {

                console.log(error);

                reject(content);
            });
        });
    };

    //Get data with a limit and orderBy
    static getOrderRecord = (city, queries = [], model, config = {}) => {
        let start = null;

        let end = null;

        // Base structure
        let content = { [model.endpoint || model]: [] };

        return new Promise((resolve, reject) => {
            var ref = app.collection(model.endpoint || model);

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            if (config.orderBy && config.direction) {
                ref = ref.orderBy(config.orderBy, config.direction);
            }

            if (config.end) {
                ref = ref.startAfter(config.end);
            }
            // If there is a start at field
            // Append a value
            if (config.startAt) {
                ref = ref.startAt(config.startAt);
            }

            if (config.end) {
                ref = ref.startAfter(config.end);
            }

            // If there is a start at field
            // Append a value
            if (config.startAt) {
                ref = ref.startAt(config.startAt);
            }

            if (config.limit) {
                ref = ref.limit(config.limit);
            }

            ref.get().then((querySnapshot) => {
                // console.log(querySnapshot)

                start = querySnapshot.docs[0];

                end = querySnapshot.docs[querySnapshot.docs.length - 1];

                querySnapshot.forEach(function (doc) {
                    console.log(doc.data());
                    if (!content[model.endpoint || model]) {
                        content[model.endpoint || model] = [];
                    }
                    // Push document data with id to the model's array in content
                    content[model.endpoint || model].push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                content.start = start;
                content.end = end;

                resolve(content);
            });
        });
    };

    static getListingData = (city, queries = [], model, config) => {
        let obj = { bookings: {}, enquiries: {}, lastUpdated: {} };

        let object = { enquiries: {}, lastUpdated: {} };

        let start = null;

        let end = null;
        // Base structure
        // let content = { [model.endpoint]: [] };
        return new Promise((resolve, reject) => {
            let ref = app
                // .collection('root')
                // .doc(city)
                .collection(model.endpoint);
            // .doc('list')
            // .collection('items');

            //  if (config.orderBy ) {
            //     ref = ref.orderBy(config.orderBy, config.direction)
            // }

            // if (config.direction) {
            //     ref = ref.orderBy(config.orderBy, config.direction)
            // }

            if (config.end) {
                ref = ref.orderBy(config.orderBy, config.direction).startAfter(config.end);
            }

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            ref = ref.limit(config.pageSize);

            ref.get().then((querySnapshot) => {
                var count = 1;

                start = querySnapshot.docs[0];

                end = querySnapshot.docs[querySnapshot.docs.length - 1];

                // console.log(start, end);

                querySnapshot.forEach(function (doc) {
                    if (model.endpoint === 'bookings') {
                        console.log(obj.bookings[doc.id]);
                        console.log(doc.data());
                        obj.bookings[doc.id] = doc.data();

                        obj.bookings[doc.id].index = count++;
                    }

                    if (model.endpoint === 'enquiries') {
                        console.log(doc.id);
                        console.log(doc.data());
                        obj.enquiries[doc.id] = doc.data();

                        obj.enquiries[doc.id].index = count++;
                    }
                });

                // app.collection('root')
                //     .doc(city)
                //     .collection('bookings')
                //     .doc('lastUpdated')
                //     .get()
                //     .then((querySnapshot) => {
                //         obj.lastUpdated = querySnapshot.data() || {};

                //         obj.start = start;

                //         obj.end = end;

                //         resolve(obj);
                //     });
                app.collection('uploads')
                    .where('model', '==', model.endpoint)
                    .orderBy('created_time')
                    .limit(1)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach(function (doc) {
                            obj.lastUpdated = {
                                ...doc.data(),
                                id: doc.id,
                            };
                        });

                        obj.start = start;
                        obj.end = end;

                        resolve(obj);
                    });
            });
        });
    };

    static getBaseRecords = (queries = [], model) => {
        // Base structure

        let content = { [model]: [] };

        return new Promise((resolve, reject) => {
            var ref = app.collection(model);

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    content[model].push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                resolve(content);
            });
        });
    };

    // New Model Section

    /**
     * Function Pushs a new record to the model
     * Function is not used anywhere
     * @param {*} city
     * @param {*} model
     * @param {*} values
     */
    static addModelData = (model, values) => {
        return app.collection(model).add(values);
    };

    /**
     * Get the Record Reference
     *
     * @param {*} id
     * @param {*} model
     * @returns
     */
    static getRecordReference = (model, id = null) => {
        if (id) {
            return app.collection(model.name).doc(id);
        } else {
            return app.collection(model.name).doc();
        }
    };

    /**
     * Update model data
     *
     * @param {*} id
     * @param {*} model
     * @param {*} values
     * @returns
     */
    static updateModelData = (model, id, values) => {
        // Base structure
        return app.collection(model).doc(id).update(values);
    };
    /**
     * *
     * Function to update value of any field in model
     */
    static updateFieldValue = (model, id, field, value) => {
        return app
            .collection(model)
            .doc(id)
            .update({
                [field]: value,
            });
    };

    /**
     * Get the model data
     */
    static getModelRecord = (id, model) => {
        return app
            .collection(model.endpoint)
            .doc(id)
            .get()
            .then((data) => {
                return {
                    ...data.data(),
                    id: data.id,
                };
            });
    };


    /**
     * Get the model data
     */
    static getModelRecordData = (id, model) => {
        return app
            .collection(model)
            .doc(id)
            .get()
            .then((data) => {
                return {
                    ...data.data(),
                    id: data.id,
                };
            });
    };
    /**
     * Get model data
     */
    static getModelData = (queries = [], model, config) => {
        // Base structure
        let content = { [model.endpoint]: [] };

        // console.log(city);

        return new Promise((resolve, reject) => {
            var ref = app.collection(model.endpoint);

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            // if (city) {
            //     console.log(city);
            //     ref = ref.where('dealerCode', '==', cityKey[city]);
            // }

            if (config.orderBy) {
                ref = ref.orderBy(config.orderBy, config.order);
            }

            if (config.limit) {
                ref = ref.limit(config.limit);
            }

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    content[model.endpoint].push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                resolve(content);
            });
        });
    };

    /**
     * Generic method to write listing
     */
    static upload = (records, user, model) => {
        var batch = app.batch();

        Object.keys(records).forEach((key) => {
            var docRef = app.collection(model).doc(key);

            batch.set(docRef, records[key], { merge: true });
        });

        return this.addUploadsDetails(user, model).then(() => {
            return batch.commit();
        });
    };

    // New Model Section Ends
    // *************************

    static firebase = () => {
        return firebase;
    };

    static getUser = () => {
        return user || {};
    };

    /**
     * Generic Settlement
     * get details from bills table
     */
    static getBillingDetailsStatement = (request, type) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            if (request.bookingNo && request.dealerCode) {
                return app
                    .collection('bill-details')
                    .where('bookingNo', '==', request.bookingNo)
                    .where('dealerCode', '==', request.dealerCode)
                    .where('type', '==', type)
                    .get()
                    .then((result) => {
                        result.forEach((ele) => {
                            arr.push({
                                ...ele.data(),
                                id: ele.id,
                            });
                        });

                        resolve(arr);
                        return arr;
                    });
            } else {
                resolve([]);

                return [];
            }
        });
    };

    static getBillingDetails = (request) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            if (request.bookingNo && request.dealerCode) {
                return app
                    .collection('bill-details')
                    .where('bookingNo', '==', request.bookingNo)
                    .where('dealerCode', '==', request.dealerCode)

                    .get()
                    .then((result) => {
                        result.forEach((ele) => {
                            arr.push({
                                ...ele.data(),
                                id: ele.id,
                            });
                        });

                        resolve(arr);
                        return arr;
                    });
            } else {
                resolve([]);

                return [];
            }
        });
    };

    static updateBaseRecords = (id, model, values) => {
        // Base structure
        return app.collection(model).doc(id).update(values);
    };

    static getBaseRecord = (id, model) => {
        // Base structure
        return app
            .collection(model)
            .doc(id)
            .get()
            .then((result) => {
                return {
                    ...result.data(),
                    id: result.id,
                };
            });
    };

    /**
     * Function Pushs a new record to the model
     * Function is not used anywhere
     * @param {*} city
     * @param {*} model
     * @param {*} values
     */
    static addRecord = (city, model, values) => {
        return app.collection('root').doc(city).collection(model).add(values);
    };

    static getRecord = (model, id, index) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection(model)
                .doc(id)
                .get()
                .then((result) => {
                    return {
                        ...result.data(),
                        id: result.id,
                    };
                })
        );
    };

    // Get amount rounding value
    static getRoundValue = () => {
        let arr = [];
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('settings')
                .where('name', '==', 'calculation-rounding')
                .get()
                .then((result) => {
                    result.forEach((ele) => {
                        arr.push({
                            ...ele.data(),
                            id: ele.id,
                        });
                    });
                    return arr;
                })
        );
    };

    // Get invoice cancellation days
    static getCancellationDays = () => {
        let arr = [];
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection('settings')
                .where('name ', '==', 'vas-billing-cancellation-days')
                .get()
                .then((result) => {
                    result.forEach((ele) => {
                        arr.push({
                            ...ele.data(),
                            id: ele.id,
                        });
                    });
                    return arr;
                })
        );
    };

    static getListingRecord = (id, model) => {
        return app
            .collection(model)
            .doc(id)
            .get((result) => {
                console.log(result.data());

                return { data: result.data() };
            });
    };

    static getUserRecord = (id) => {
        return app
            .collection('users')
            .doc(id)
            .get((result) => {
                console.log(result.data());

                return { data: result.data() };
            });
    };

    static getAllRecords = (queries, model) => {
        let all = [];

        var content = {};

        var locations = ['kozhikode', 'malappuram', 'kannur', 'kondotty', 'tirur', 'vadakara', 'kasargod'];

        var locationPromise = (city) => {
            return FirebaseUtils.getRecords(city, queries, model).then((result) => {
                all = [].concat(...all, ...result[model]);

                content[city] = result;

                return content;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, content });
            });
        });
    };

    static getAllListing = (queries, model, collection, id, list) => {
        let all = [];

        var content = {};

        var locations = ['kozhikode', 'malappuram', 'kannur', 'kondotty', 'tirur', 'vadakara', 'kasargod'];

        var locationPromise = (city) => {
            return FirebaseUtils.getListing(city, queries, model, collection, id, list).then((result) => {
                all = [].concat(...all, ...result[model]);

                content[city] = result;

                return content;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, content });
            });
        });
    };

    /**
     * Generic Method intended to handle querying model , pagination and all filtering support
     */

    // note we can get rid of collection and list in future ,
    //  we can instead form a standard structure and

    static getListing1 = (city, queries = [], model, collection, id, list) => {
        let content = { [model]: [], lastUpdated: {} };

        return new Promise((resolve, reject) => {
            var ref = app
                .collection('root')
                .doc(city)
                .collection(model)
                .doc(list || 'list')
                .collection('items');

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    let car = doc.data();

                    // if (car.update !== 'remove') {

                    content[model].push({
                        ...doc.data(),
                        // [id]: doc.id,
                        id: doc.id,
                    });
                    // }
                });

                app.collection('root')
                    .doc(city)
                    .collection(model)
                    .doc('lastUpdated')
                    .get()
                    .then((querySnapshot) => {
                        content.lastUpdated = querySnapshot.data() || {};

                        resolve(content);
                    });
            });
        });
    };

    static getListing = (city, queries = [], model, config) => {
        let content = { [model]: [], lastUpdated: {} };

        return new Promise((resolve, reject) => {
            var ref = app.collection(model);

            queries.forEach((query) => {
                ref = ref.where(query.field, query.operator || '==', query.value);
            });

            if (config && config.limit) {
                ref = ref.limit(config.limit);
            }

            if (city && city !== 'central' && model === 'items') {
                ref = ref.where('dealerCode', '==', cityKey[city]);
            } else if (city && city !== 'central') {
                ref = ref.where('dealerCode', '==', cityKey[city]);
            }

            ref.get().then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    let car = doc.data();

                    content[model].push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                app.collection('uploads')
                    .where('model', '==', model)
                    .orderBy('created_time')
                    .limit(1)
                    .get()
                    .then((querySnapshot) => {
                        // console.log('querySnapshot', querySnapshot);
                        // content.lastUpdated = querySnapshot.data() || {};
                        querySnapshot.forEach(function (doc) {
                            content.lastUpdated = {
                                ...doc.data(),
                                id: doc.id,
                            };
                        });

                        resolve(content);
                    });
            });
        });
    };

    static getVehicleOverview = (city, queries = [], model, collection, id, list) => {
        var arr = [];

        return new Promise((resolve) => {
            this.getListing(city, queries, model).then((result) => {
                // Wait for all request
                Promise.all(
                    result.vehicles
                        .filter((v) => v.allocated && v.allocated.requestId)
                        .map((v) => {
                            if (typeof v.allocated.requestId !== 'string') {
                                console.log(v.allocated.requestId, v);
                                return v;
                            }

                            // Get request of each vehicle
                            return this.getRequest(
                                // city,
                                v.allocated.requestId
                            ).then((result) => {
                                v.request = result;

                                return v;
                            });
                        })
                )

                    .then((res) => {
                        res.forEach((item) => {
                            arr.push(item);
                        });

                        var items = result.vehicles.filter((v) => !v.allocated);

                        arr = [].concat(arr, items);

                        return arr;
                    })
                    .then((arr) => {
                        // Get all the billed cases
                        return this.getRequests(city, [
                            {
                                field: 'delivery.status',
                                operator: 'in',
                                value: ['pending', 'requested', 'approved', 'Delivery Note Taken'],
                            },
                        ]).then((response) => {
                            return Promise.all(
                                response.requests
                                    .filter((request) => {
                                        return request.allocation.vinNo;
                                    })
                                    .map((request) => {
                                        return this.getListingRecord(
                                            // city,
                                            request.allocation.vinNo,
                                            'vehicles'
                                        ).then((result) => {
                                            // console.log(result.data);

                                            return {
                                                ...result.data(),
                                                request: request,
                                            };

                                            // return {

                                            //     'Vin Number': request.allocation.vinNo,
                                            //     'Variant': request.variant,
                                            //     'Exterior Color Name': request.color,
                                            //     request: request
                                            // }
                                        });
                                    })
                            );
                        });
                    })
                    .then((result) => {
                        // Filter the cases where bill is cancelled
                        result = result.filter((ele) => {
                            if (ele.request && ele.request.actual_billing && ele.request.actual_billing.status) {
                                if (ele.request.actual_billing.status !== 'cancelled')
                                    return ele
                            } else {
                                return ele
                            }

                        })

                        arr = [].concat(arr, result);

                        resolve(arr);
                    });
            });
        });
    };

    static getAccessoryOverview = (city, queries = [], model, collection, id, list) => {
        var arr = [];

        return new Promise((resolve) => {
            // talking all request
            this.getListing(city, queries, model).then((result) => {
                // Wait for all request
                Promise.all(
                    result.requests
                        //filter it no tcompleted cases
                        .filter(
                            (v) =>
                                v.allocation &&
                                v.allocation.vinNo &&
                                // (!v.actual_delivery ||
                                v.actual_delivery &&
                                ['Delivered', 'Delivery Completed'].indexOf(v.actual_delivery.status) === -1
                        )
                        .map((v) => {
                            let accItems = {};
                            let accTotal = 0;
                            let vasTotal = 0;
                            let aftTotal = 0;
                            let total = 0;

                            // Get cart of each request
                            return this.getCart(v).then((result) => {
                                let filterAccess = groupBy(result, 'type');

                                // find and saving cart details as per the cart items type
                                if (filterAccess['Accessories'] && filterAccess['Accessories'][0]) {
                                    filterAccess['Accessories'].forEach((entry) => {
                                        accTotal += parseFloat(entry.price);
                                    });
                                    accItems['Accessories'] = {
                                        item: filterAccess['Accessories'],
                                        total: accTotal,
                                    };
                                    total = total + accTotal;
                                }
                                if (filterAccess['VAS'] && filterAccess['VAS'][0]) {
                                    filterAccess['VAS'].forEach((entry) => {
                                        vasTotal += parseFloat(entry.price);
                                    });
                                    accItems['VAS'] = {
                                        item: filterAccess['VAS'],
                                        total: vasTotal,
                                    };
                                    total = total + vasTotal;
                                }
                                if (filterAccess['After Market'] && filterAccess['After Market'][0]) {
                                    filterAccess['After Market'].forEach((entry) => {
                                        aftTotal += parseFloat(entry.price);
                                    });
                                    accItems['After Market'] = {
                                        item: filterAccess['After Market'],
                                        total: aftTotal,
                                    };
                                    total = total + aftTotal;
                                }
                                // making a object with the above created details
                                v.accessoriesList = {
                                    list: {
                                        all: filterAccess,
                                        ...accItems,
                                    },
                                    total: total,
                                };
                                return v;
                            });
                        })
                ).then((res) => {
                    res.forEach((item) => {
                        arr.push(item);
                    });
                    // var items = result.requests.filter((v) => v.allocation && !v.allocation.vinNo);
                    // arr = [].concat(arr, items);

                    resolve(arr);
                });
            });
        });
    };

    static addListing = (model, values, list, schema) => {
        if (schema && schema.tableIndex) {
            return (
                app
                    // .collection('root')
                    // .doc(city)
                    .collection(model)
                    // .doc(list || 'list')
                    // .collection('items')
                    .doc(values[schema.tableIndex])
                    .set(values)
            );
        } else {
            return (
                app
                    // .collection('root')
                    // .doc(city)
                    .collection(model)
                    // .doc(list || 'list')
                    // .collection('items')
                    .add(values)
            );
        }
    };

    static updateListing = (model, id, values) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection(model)
                // .doc(list || 'list')
                // .collection('items')
                .doc(id)
                .update(values)
        );
    };

    static deleteListing = (model, id, list) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection(model)
                // .doc(list || 'list')
                // .collection('items')
                .doc(id)
                .delete()
        );
    };

    /**
     * Updating stock list
     */
    static updateItems = (user, records, model) => {
        var batch = app.batch();

        Object.keys(records).forEach((key) => {
            var docRef;
            if (records[key].id) {
                docRef = app.collection(model).doc(records[key].id);
            } else {
                docRef = app.collection(model).doc();
            }

            batch.set(docRef, records[key], { merge: true });
        });

        return this.addUploadsDetails(user, model).then(() => {
            return batch.commit();
        });
    };

    /**
     * Updating stock list
     */
    static updateStocks = (user, records) => {
        var batch = app.batch();

        Object.keys(records).forEach((key) => {
            var docRef;
            if (records[key].id) {
                docRef = app.collection('stocks').doc(records[key].id);
            } else {
                docRef = app.collection('stocks').doc();
            }

            batch.set(docRef, records[key], { merge: true });
        });

        // var timeRef = app
        //     .collection('uploads')
        //     .doc('lastUpdated');

        // batch.set(timeRef, { timestamp: moment().format('DD/MM/YYYY HH:mm') });
        return this.addUploadsDetails(user, 'stocks').then(() => {
            return batch.commit();
        });
    };

    /**
     * Generic method to write listing
     */
    static writeListing = (user, records, model) => {
        var batch = app.batch();

        Object.keys(records).forEach(async (key) => {
            var docRef = app.collection(model).doc(key);

            await batch.set(docRef, records[key], { merge: true });
        });

        // var timeRef = app
        //     .collection('uploads')
        //     .doc('lastUpdated');

        // batch.set(timeRef, { timestamp: moment().format('DD/MM/YYYY HH:mm') });
        return this.addUploadsDetails(user, model).then(() => {
            return batch.commit();
        });
    };

    /**
     *
     */
    static addUploadsDetails = (user, model) => {
        let body = {
            model: model,
            created_by: {
                name: user.name,
                role: user.role,
            },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),
            deleted_at: null,
            deleted_by: null,
        };
        return app.collection('uploads').add(body);
    };

    /**
     *
     */
    static setRecord = (model, values) => {
        let setRef = app.collection(model);
        return setRef.add(values).then((result) => {
            return [setRef.id, result];
        });
    };
    /**
     *
     */
    static updateRecord = (model, id, values) => {
        return app.collection(model).doc(id).update(values);
    };

    static deleteRecord = (model, id) => {
        return (
            app
                // .collection('root')
                // .doc(city)
                .collection(model)
                .doc(id)
                .delete()
        );
    };

    //Get all taxes
    static getTaxRecord = () => {
        let arr = [];
        return app
            .collection('taxes')
            .get()
            .then((result) => {
                result.docs.forEach((tax) => {
                    arr.push({
                        ...tax.data(),
                        id: tax.id,
                    });
                });
                return arr;
            });
    };

    //Get items with category
    static getItems = (city, queries = []) => {
        return this.getListing(city, queries, 'items');
    };

    //get join items and stock
    static getItemJoinStock = (formBody) => {
        var getItemJoinStock = firebase.functions().httpsCallable('getItems');

        return getItemJoinStock(formBody).then((result) => {
            return { items: [...result.data] };
        });
    };
    //get join items By item_id and stock by dealerCode
    static getItemJoinStockById = (formBody) => {
        var getItemJoinStockByID = firebase.functions().httpsCallable('getItemById');

        return getItemJoinStockByID(formBody).then((result) => {
            return result.data[0];
        });
    };

    //Get stocks

    static getStocks = (city, queries = []) => {
        return this.getListing(city, queries, 'stocks');
    };

    //get stock by item_id
    // static getStock = (itemId) => {
    //     return new Promise((resolve, reject) => {
    //         app
    //             .collection('stocks')
    //             .where('item_id', '==', itemId)
    //             .get()
    //             .then((result) => {
    //                 let data = {
    //                     ...result.data(),
    //                     id: result.id,
    //                 };
    //                 resolve(data)
    //             })
    //     })
    // };

    // Accessories Section

    static getAccessories = (city, queries = []) => {
        return this.getListing(city, queries, 'accessories');
    };

    static writeAccessories = (user, data) => {
        return this.writeListing(user, data, 'accessories');
    };

    // this function is not used anywhere
    static updateAccessories = (city, id, values) => {
        return this.updateRecord('requests', id, values);
    };

    static updateRequestGeneric = (
        // city,
        id,
        values,
        statusUpdate,
        remarks,
        model
    ) => {
        let content = {
            author: user.name,
            content: 'Status Update : ' + statusUpdate + ' - ' + remarks || values.remarks,
            datetime: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),
            type: model,
            deleted_at: null,
            deleted_by: null,
        };

        return this.addComment(id, content).then((result) => {
            return this.updateRecord('requests', id, values);
        });
    };

    /**
     *
     *
     * @param {*} city
     * @param {*} id
     * @param {*} values
     * @param {*} statusUpdate
     * @param {*} remarks
     * @param {*} booking
     * @returns
     */
    static updateBookingGeneric = (id, values, statusUpdate, remarks, booking) => {
        if (remarks) {
            let content = {
                author: user.name,
                content: 'Status Update : ' + statusUpdate + ' - ' + remarks || values.remarks,
                datetime: moment().format('DD/MM/YYYY HH:mm'),
                deleted_by: null,
                deleted_at: null,
            };

            return this.addComment(id, content).then((result) => {
                return this.updateListing('bookings', id, values);
            });
        } else {
            return this.updateListing('bookings', id, values);
        }
    };

    // Accessories Section Ends

    //Update accounts head
    static updateHead = (id, values) => {
        let ac_code = {
            value: values.to_account.value,
            label: values.to_account.label,
        };
        let accounts = {
            ac_code: ac_code,
            status: 'completed',
        };
        return app.collection('requests').doc(id).update({
            accounts: accounts,
        });
    };

    //Cart Section
    static addToCart(item) {
        return app.collection('cart').add(item);
    }
    /**
     * Method used to add, update and remove items from cart
     * @param {*} items - Items to be added to the cart
     * @param {*} customerID  - Customer ID, used to identify records
     * @param {*} type - Type of item being added or updated.
     * @param {*} id  - Id of the item. Used to identify accessories.
     * @param {*} operation - Denotes nature of operation. i.e. updation / deletion
     */
    static addOrUpdateCart = (items = [], customerID = '', type = '', id = '', operation = 'update') => {
        let ref = app.collection('cart');

        if (customerID != '') {
            ref = ref.where('customerID', '==', customerID);
        }

        if (type != '') {
            ref = ref.where('type', '==', type);
        }

        if (type == 'Accessories') {
            ref = ref.where('id', '==', id);
        }

        return ref.get().then((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    //Add, Update or Delete
                    if (operation === 'update') {
                        app.collection('cart').doc(doc.id).set(items);
                    } else if (operation === 'delete') {
                        app.collection('cart').doc(doc.id).delete();
                    }
                });
            } else {
                app.collection('cart').add(items);
            }
        });
    };

    /**
     * Functions adds an item to cart
     */
    static addCartItem = (item) => {
        return app.collection('cart').add(item);
    };

    /**
     * Update Cart Item
     */
    static updateCartItem = (id, item) => {
        return app.collection('cart').doc(id).update(item);
    };

    /**
     * Find and Update Cart
     */
    static findAndUpdateCart = (type, param, request) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            return app
                .collection('cart')
                .where('customerID', '==', request.customer.customerID)
                .where('dealerCode', '==', request.dealerCode)
                .where('description', '==', type)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((item) => {
                        arr.push({ id: item.id, ...item.data() });
                    });

                    if (arr.length) {
                        var doc = arr[0];

                        this.updateCartItem(doc.id, param).then(() => {
                            resolve({});
                        });
                    } else {
                    }
                });
        });
    };

    /**
     * Find and Update Cart
     */
    static findAndUpdateCartBasic = (type, param, request, model) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            return app
                .collection(model)
                .where('customerID', '==', request.customer.customerID)
                .where('dealerCode', '==', request.dealerCode)
                .where('type', '==', 'basic')
                .get()
                .then((snapshot) => {
                    snapshot.forEach((item) => {
                        arr.push({ id: item.id, ...item.data() });
                    });

                    if (model === 'bill-details' && arr.length) {
                        arr = arr.filter((item) => item.status !== 'rejected' || !item.status);
                    }
                    var arr1 = [];
                    if (arr.length) {
                        var doc = arr.filter((a) => !a.status || a.status !== 'removed');
                        doc[0].subItems.map((item) => {
                            if (item.description === type) {
                                item.price = param.price;
                                arr1.push(item);
                            } else {
                                arr1.push(item);
                            }
                        });
                        let total = 0;
                        arr1.forEach((entry) => {
                            total += parseFloat(entry.price);
                        });

                        let params = {
                            ...param,
                            price: total,
                            total: total,
                            subItems: arr1,
                        };

                        if (model === 'bill-details') {
                            this.updateBillDetails(doc[0].id, params).then(() => {
                                resolve({});
                            });
                        } else {
                            this.updateCartItem(doc[0].id, params).then(() => {
                                resolve({});
                            });
                        }
                    } else {
                        console.log('here 1.5');
                        resolve({});
                    }
                });
        });
    };

    // static getFinancePenetration = ()=> {
    //     var user = [];
    //     var data = [];

    //     return app.firestore().collection('users')
    //         .where('role', '==', 'kec')
    //         .get()
    //         .then((querySnapShot) => {

    //             querySnapShot.forEach((doc) => {

    //                 let ele = doc.data();
    //                 user.push(ele.dms['Consultant Name']);
    //             });

    //             return true;

    //         }).then(() => {

    //             return app.firestore().collection('root').doc('malappuram').collection('requests')
    //                 .where('billing.status', '==', 'Invoiced')
    //                 .get()
    //                 .then((querySnapShot) => {

    //                     querySnapShot.forEach((doc) => {

    //                         let ele = doc.data();

    //                         data.push(ele);

    //                     });

    //                     return true;
    //                 }).then(() => {

    //                     var finalOutput = {};

    //                      user.forEach((person) => {

    //                         let retails = data.filter((request) => request.kec === person)

    //                         finalOutput[person] = {
    //                             retails: retails.length,
    //                             cash: 0,
    //                             finance: 0,
    //                             direct: 0,
    //                             inhouse: 0,
    //                             ACH: 0,
    //                         };

    //                     retails.forEach((request) => {

    //                             // ['In House', 'Direct', 'Own Fund']
    //                             if (request.finance.type === 'In House') {

    //                                 // finalOutput[person] = {
    //                                 //     ...finalOutput[person],
    //                                 //     inhouse: 0,
    //                                 // };

    //                                 finalOutput[person].inhouse += 1;

    //                             } else if (request.finance.type === 'Direct') {

    //                                 finalOutput[person].direct += 1;

    //                             } else if (request.finance.type === 'Own Fund') {

    //                                 finalOutput[person].cash += 1;

    //                             }
    //                             finalOutput[person].finance = finalOutput[person].retails - finalOutput[person].cash;
    //                             finalOutput[person].ACH = finalOutput[person].inhouse / finalOutput[person].retails;
    //                         })

    //                         finalOutput[person] = {
    //                             ...finalOutput[person],
    //                             // penetration:retail/inhouse
    //                         };

    //                          // setData

    //                     })
    //                     console.log(finalOutput);

    //                     return finalOutput;
    //                 });
    //         });

    // }

    /**
     * Delete the cart item
     */
    static deleteCartItem = (id) => {
        return app.collection('cart').doc(id).update({
            status: 'removed',
        });
    };

    /**
     *
     * Cancel Refund
     */
    static cancelRefund = (id, item) => {
        return app.collection('payments').doc(id).update({
            item,
        });
    };
    /**
     * Generic Settlement
     * adding details to Bill-Details table
     */
    static addItemToBillDetails = (item) => {
        return app.collection('bill-details').add(item);
    };

    /**
     * Update Cart Item
     */
    static updateBillDetails = (id, item) => {
        return app.collection('bill-details').doc(id).update(item);
    };

    /**
     * Generic Settlement
     * adding details to bills table
     */
    static addBillingStatement = (item) => {
        return app.collection('bills').add(item);
    };

    /**
     * Generic Settlement
     * get details from bills table
     */
    static getBillingStatement = (id, type) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            if (id) {
                return app
                    .collection('bills')
                    .where('bookingNo', '==', id)
                    .get()
                    .then((result) => {
                        result.forEach((ele) => {
                            arr.push({
                                ...ele.data(),
                                id: ele.id,
                            });
                        });

                        resolve(arr);

                        return arr;
                    });
            } else {
                resolve(arr);
            }
        });
    };

    /**
     * to get the count og the bills
     * getting last bill created number
     */
    static getBillOrder = (city, category, date) => {
        var arr = [];
        var finArr = [];
        var typArr = [];

        return new Promise((resolve, reject) => {
            app.collection('branches')
                .where('location', '==', city)
                .get()
                .then((result) => {
                    result.forEach((ele) => {
                        arr.push({
                            ...ele.data(),
                            id: ele.id,
                        });
                    });

                    app.collection('financial_years')
                        .get()
                        .then((result) => {
                            result.forEach((ele) => {
                                finArr.push({
                                    ...ele.data(),
                                    id: ele.id,
                                });
                            });

                            let data = finArr.filter((item) => date >= item.start_date && date <= item.end_date);

                            app.collection('invoice_numbers')
                                .where('branch_id', '==', arr[0].dealer_code)
                                .where('financial_year_id', '==', data[0].id)
                                .where('category', '==', category)
                                .get()
                                .then((result) => {
                                    result.forEach((ele) => {
                                        typArr.push({
                                            ...ele.data(),
                                            id: ele.id,
                                        });
                                    });

                                    resolve(typArr[0]);
                                });
                        });
                });
        });
    };

    /**
     * Generic Settlement
     * adding details to bills table
     */
    static updateBillOrder = (item) => {
        return app.collection('invoice-numbers').doc(item.id).update({
            last_number: item.lastNumber,
        });
    };

    /**
     * Remove specific items from Cart
     */
    //   static clearCartBasic = ({ customerID, dealerCode }) => {
    //     return new Promise((resolve, reject) => {
    //       app
    //         .collection("cart")
    //         .where("customerID", "==", customerID)
    //         .where('type', '==', 'basic')
    //         // .where("dealerCode", "==", dealerCode)
    //         .get().then((snapshot) => {

    //           var result = [];

    //           snapshot.forEach((item) => {

    //             result.push(item.id);

    //           });

    //           Promise.all(result.map((id) => app.collection('cart').doc(id).delete())).then(() => {

    //             resolve(result);

    //           });

    //         });
    //     });
    //   };

    static getBranches = () => {
        var arr = [];

        return app
            .collection('branches')
            .get()
            .then((result) => {
                result.forEach((entry) => {
                    arr.push({
                        ...entry.data(),
                        id: entry.id,
                    });
                });

                return arr;
            });
    };

    static getDepartments = () => {
        var arr = [];

        return app
            .collection('departments')
            .get()
            .then((result) => {
                result.forEach((entry) => {
                    arr.push({
                        ...entry.data(),
                        id: entry.id,
                    });
                });

                return arr;
            });
    };

    static getCartData = (item) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            if (item['Booking No']) {
                return app
                    .collection('cart')
                    .where('bookingNo', '==', item['Booking No'])
                    .get()
                    .then((result) => {
                        result.forEach((entry) => {
                            arr.push({
                                ...entry.data(),
                                id: entry.id,
                            });
                        });

                        resolve(arr);
                        return arr;
                    });
            }
        });
    };

    static getReceiptsData = (item) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            if (item['Booking No']) {
                return app
                    .collection('receipts')
                    .where('Appointment No', '==', item['Booking No'])
                    .get()
                    .then((result) => {
                        result.forEach((entry) => {
                            arr.push({
                                ...entry.data(),
                                id: entry.id,
                            });
                        });

                        resolve(arr);
                        return arr;
                    });
            }
        });
    };

    static getCart = (request) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            if (request.bookingNo && request.dealerCode) {
                return app
                    .collection('cart')
                    .where('bookingNo', '==', request.bookingNo)
                    .where('dealerCode', '==', request.dealerCode)
                    .get()
                    .then((result) => {
                        result.forEach((entry) => {
                            arr.push({
                                ...entry.data(),
                                id: entry.id,
                            });
                        });

                        resolve(arr);

                        return arr;
                    })
                    .catch((error) => {
                        throw error;
                    });
            } else {
                resolve([]);
            }
        });
    };

    /**
     * For request ,
     * Adding Basic Pricing
     */

    static addBasicPrice = (request) => {
        return new Promise((resolve, reject) => {
            if (request && request.allocation && request.allocation.vinNo && request.customer['customerID']) {
                var vehicles = [];

                // Get the vehicle Object
                app.collection('vehicles')
                    // .doc('list')
                    // .collection('items')
                    .where('Vin Number', '==', request.allocation.vinNo)
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((ele) => {
                            vehicles.push({
                                ...ele.data(),
                                id: ele.id,
                            });
                        });

                        if (vehicles.length) {
                            let vehicle = vehicles[0];
                            let vehicleQuery = {
                                vC: vehicle && vehicle['Variant Code'] ? 'true' : 'false',
                                cT: vehicle && vehicle['Color Type'] ? 'true' : 'false',
                                rV: request && request.variant ? 'true' : 'false',
                            };

                            if (vehicleQuery['vC'] === 'true' && vehicleQuery['cT'] === 'true' && vehicleQuery['rV'] === 'true') {
                                // Get the cost object
                                app.collection('pricing')
                                    .where('variant', '==', request.variant)
                                    .where('key', '==', vehicle['Variant Code'] + '-' + vehicle['Color Type'])
                                    .get()
                                    .then((snapshot) => {
                                        var pricing = [];
                                        snapshot.forEach((ele) => {
                                            pricing.push({
                                                ...ele.data(),
                                                id: ele.id,
                                            });
                                        });

                                        if (pricing && pricing.length) {
                                            var data = pricing[0];
                                            //Vechicle Details
                                            let vechicleItems = [];

                                            let creation = {
                                                created_at: moment().format('DD/MM/YYYY HH:mm'),
                                                created_date: moment().startOf('day').valueOf(),
                                                created_time: moment().valueOf(),
                                                deleted_at: null,
                                                deleted_by: null,
                                            };

                                            let defaultProps = {
                                                ...creation,
                                                dealerCode: request.dealerCode,
                                                name: request.variant,
                                                description: request.variant,
                                                customerID: request.customer['customerID'],
                                                bookingNo: request['bookingNo'],
                                                vinNo: request.allocation.vinNo,
                                            };

                                            // Add the basic items
                                            let finalexShowroom = data['exShowroom'];

                                            // let finalexShowroom = data['exShowroom'] + Math.round(data['sellingPrice'] / 100);

                                            vechicleItems.push({
                                                type: 'basic',

                                                subItems: [
                                                    {
                                                        description: 'Ex Showroom Price',
                                                        price: finalexShowroom,
                                                        order: 1,
                                                    },
                                                ],
                                                ...defaultProps,
                                            });

                                            // TCS for Above 10L
                                            if (finalexShowroom > 1000000) {
                                                vechicleItems[0].subItems.push({
                                                    order: 2,
                                                    description: 'TCS @ 1%',
                                                    price: Math.round(1 * (finalexShowroom / 100)),
                                                });
                                            }

                                            vechicleItems[0].subItems.push({
                                                order: 4,
                                                description: 'RTO',
                                                price: data['rto'],
                                            });

                                            // Fast Tag
                                            vechicleItems[0].subItems.push({
                                                order: 5,
                                                description: 'Fast Tag',
                                                price: data['fastTag'],
                                            });

                                            let total = 0;

                                            vechicleItems[0].subItems.forEach((entry) => {
                                                total += parseFloat(entry.price);
                                            });

                                            vechicleItems[0].total = total;
                                            vechicleItems[0].price = total;

                                            // vechicleItems.push({
                                            //     order: 6,
                                            //     description: 'Statutory Charges',
                                            //     type: 'basic',
                                            //     price: 1015,
                                            //     ...defaultProps
                                            // });

                                            // Add all the items to cart
                                            Promise.all(vechicleItems.map((item) => FirebaseUtils.addToCart(item)))
                                                .then(() => {
                                                    console.log('Item added');
                                                    resolve({
                                                        request: request.id,
                                                        status: 'success',
                                                    });
                                                    return;
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                            return;
                                        } else {
                                            resolve({
                                                request: request.id,
                                                error: 'No Pricing Found',
                                            });
                                            return;
                                        }
                                    });
                            } else {
                                resolve({
                                    request: request.id,
                                    error: 'Vehicles details missing',
                                });
                                return;
                            }
                            return;
                        } else {
                            resolve({
                                request: request.id,
                                error: 'No matching vehicle found',
                            });
                            return;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                resolve({
                    request: request.id,
                    error: 'Vin Missing',
                });
                return;
            }
        });
    };

    /**
     * For Each new function ,
     * Make sure you document
     * First step
     *
     * function is not used anywhere
     *
     */
    static getDP = (city, bno) => {
        var arr = [];
        return app
            .collection('root')
            .doc(city)
            .collection('receipts')
            .doc('list')
            .collection('items')
            .where('Application No', '==', bno)
            .get()
            .then((snapshot) => {
                snapshot.forEach((entry) => {
                    arr.push({
                        ...entry.data(),
                        id: entry.id,
                    });
                });

                return arr;
            });
    };

    // Accessories Section Ends

    // Accessories Section Ends

    //Cart Section
    static addToCart(item) {
        return app.collection('cart').add(item);
    }
    /**
     * Method used to add, update and remove items from cart
     * @param {*} items - Items to be added to the cart
     * @param {*} customerID  - Customer ID, used to identify records
     * @param {*} type - Type of item being added or updated.
     * @param {*} id  - Id of the item. Used to identify accessories.
     * @param {*} operation - Denotes nature of operation. i.e. updation / deletion
     */
    static addOrUpdateCart = (items = [], customerID = '', type = '', id = '', operation = 'update') => {
        let ref = app.collection('cart');

        if (customerID != '') {
            ref = ref.where('customerID', '==', customerID);
        }

        if (type != '') {
            ref = ref.where('type', '==', type);
        }

        if (type == 'Accessories') {
            ref = ref.where('id', '==', id);
        }

        return ref.get().then((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    //Add, Update or Delete
                    if (operation === 'update') {
                        app.collection('cart').doc(doc.id).set(items);
                    } else if (operation === 'delete') {
                        app.collection('cart').doc(doc.id).delete();
                    }
                });
            } else {
                app.collection('cart').add(items);
            }
        });
    };

    /**
     * Functions adds an item to cart
     */
    static addCartItem = (item) => {
        return app.collection('cart').add(item);
    };

    /**
     * Update Cart Item
     */
    static updateCartItem = (id, item) => {
        return app.collection('cart').doc(id).update(item);
    };

    /**
     * Find and Update Cart
     */
    static findAndUpdateCart = (type, param, request) => {
        var arr = [];

        return new Promise((resolve, reject) => {
            return app
                .collection('cart')
                .where('customerID', '==', request.customer.customerID)
                .where('dealerCode', '==', request.dealerCode)
                .where('description', '==', type)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((item) => {
                        arr.push({ id: item.id, ...item.data() });
                    });

                    if (arr.length) {
                        var doc = arr[0];

                        this.updateCartItem(doc.id, param).then(() => {
                            resolve({});
                        });
                    } else {
                    }
                });
        });
    };

    /**
     * Delete the cart item
     */
    // static deleteCartItem = (id) => {
    //     return app.collection('cart').doc(id).delete();
    // };

    /**
     * Remove specific items from Cart
     */
    static clearCartBasic = ({ customer, dealerCode }) => {
        return new Promise((resolve, reject) => {
            app.collection('cart')
                .where('customerID', '==', customer.customerID)
                .where('type', '==', 'basic')
                .where('dealerCode', '==', dealerCode)
                .get()
                .then((snapshot) => {
                    var result = [];

                    snapshot.forEach((item) => {
                        result.push(item.id);
                    });

                    Promise.all(result.map((id) => app.collection('cart').doc(id).delete())).then(() => {
                        resolve(result);
                    });
                });
        });
    };

    // static getCart = (request) => {
    //     var arr = [];

    //     return app
    //         .collection('cart')
    //         .where('bookingNo', '==', request.bookingNo)
    //         .get()
    //         .then((result) => {
    //             result.forEach((entry) => {
    //                 arr.push({
    //                     ...entry.data(),
    //                     id: entry.id,
    //                 });
    //             });

    //             return arr;
    //         });
    // };

    // Trading Profit

    static getTrading = ({ ...props }) => {
        var getTradingProfit = firebase.functions().httpsCallable('getTradingProfit');

        return getTradingProfit(props).then(function (result) {
            console.log(result);

            return result;
        });
    };

    //Cart Section Ends
    static logout = () => {
        return firebase.auth().signOut();
    };
}

export default FirebaseUtils;

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;
    }, {});
};
