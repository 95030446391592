import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Divider } from 'antd';

import GlobalHeader from '../../components/global-header/global-header';
import { Location } from '../../utils/location.utils';

import './hr-landing.scss';

import { GlobalContext } from '../../Store';

import Profile from '../common/resources/profile/profile';

import { userSchema } from '../admin/admin-config';

import ModuleRoutes from '../common/resources/modules-routes/module-routes';

import ModuleRoutesLatest from '../common/resources/modules-routes-latest/modules-routes-latest';

import ChangePassword from '../common/components/change-password/change-password';

import { Employees } from '../../models';

export default function HRLanding(props) {

    const { user } = useContext(GlobalContext);

    useEffect(() => { }, []);

    const currentURL = Location.search();

    const tagToSearch = Object.keys(currentURL)[0];

    const statusToSearch = currentURL[tagToSearch];

    const modules = [
        {
            caption: 'Settings',
            submenus: [
                {
                    path: '/common/users',
                    caption: 'Manage Users',
                },
            ],
        },
        {
            caption: 'Employees',
            path: '/employees',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>

                    {/* Manage Users */}
                    <Route
                        path="/common/users"
                        component={(routeParams) => (
                            <ModuleRoutes model="users" {...routeParams} schema={userSchema} columns={userSchema.columns} collection="users" />
                        )}
                    />
                    {/* Manage Users Ends */}

                    <Route path={'/employees'} render={(routeParams) => <ModuleRoutesLatest {...routeParams} model={Employees} />} />


                    <Route exact path={'/profile'} component={Profile}></Route>


                </Switch>
            </GlobalHeader>
        </section>
    );
}
