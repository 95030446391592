
import React,{ useContext } from "react";


import { Tag, Row } from 'antd';

import './booking-info.scss';

import EmployeeDisplay from './../employee-display/employee-display';
import { GlobalContext } from "../../Store";


export default function BookingInfo({ booking = {} }) {
    const { disableDisplayPhoneNumber } = useContext(GlobalContext);


    console.log("booking : ", booking);

   return (<div className="booking-info" >

        {/* <Tag color="purple">
            {booking.status || 'Initial'}
        </Tag> */}

        <div className="vehicle-image-wrapper">

            {/* <img src={images[booking['model']]} alt="vehicle-img" /> */}

        </div>

        <div gutter={0} className="detail-wrapper">


            <div className="detail-element">
                <Row span={12}>
                    <span>Customer</span>
                </Row>
                <Row span={12}>
                    <h3>{booking['Name of the Customer']}</h3>
                </Row>
            </div>

            <div className="detail-element">

                {/*disable PhoneNumber for kec and tl */}
                {!disableDisplayPhoneNumber ?
                    <>
                        <Row span={12}>
                            <span>Phone</span>
                        </Row>
                        <Row span={12}>
                            <h3>{booking['Contact Number']}</h3>
                        </Row>
                    </>
                    : null}
            </div>

            <div className="detail-element">

                <Row span={12}>
                    <span>Customer ID</span>

                </Row>
                <Row span={12}>
                    <h3>{booking['Customer ID']}</h3>

                </Row>

            </div>

            <div className="detail-element">

                <Row span={12}>
                    <span>
                        Variant
</span>
                </Row>
                <Row span={12}>
                    <h3>{booking['Variant']}</h3>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    Exterior Color Name
</Row>
                <Row span={12}>

                    <h3 className="">
                        {booking['Color']}
                    </h3>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    KEC
                    </Row>
                <Row span={12}>

                    <h3 className="">
                        {/* {booking['Consultant Name']} */}
                        <EmployeeDisplay employee_id={booking.consultant_id} />
                    </h3>
                </Row>
            </div>


            <div className="detail-element">
                <Row span={12}>
                    Enquiry Date
                    </Row>
                <Row span={12}>

                    <h3 className="">
                        {booking['Enquiry Date']}
                    </h3>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    Booking Date
                    </Row>
                <Row span={12}>

                    <h3 className="">
                        {booking['Booking Date']}
                    </h3>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    Committed Delivery Date
                    </Row>
                <Row span={12}>

                    <h3 className="">
                        {booking['Committed Delivery Date']}
                    </h3>
                </Row>
            </div>

        </div>
    </div>)



}