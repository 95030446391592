/**
 *
 */

import React, { useEffect, useState, useContext, useRef } from 'react';

import { Typography, Popconfirm, Button, Alert, message, Table } from 'antd';

import Rupee from './../../../../components/rupee/rupee';

import { DeleteOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';
const { Title, Text } = Typography;

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;
    }, {});
};

/**
 * This is used in summary
 */
export function BillsSummary({ type, category, items = [], cart, request = {}, callback, booking, user }) {
    
    // 
    const [entries, setEntries] = useState(() => {

        let list = [];

        let categories = groupBy(cart, 'type');

        if (type === 'Accessories') {
            let arr = [];
            categories['Accessories'] &&
                categories['Accessories'].map((item) => {
                    arr.push(item);
                });
            categories['After Market'] &&
                categories['After Market'].map((item) => {
                    arr.push(item);
                });
            return arr;
        } else {
            return categories[type];
        }
    });

    const [deleteCallback, setDeleteCallback] = useState(() => {
        if (type === 'Accessories') {
            let categories = groupBy(items, 'type');
            return categories[type][0];
        } else {
            return [];
        }
    });

    /**
     * Delete the Cart Item
     */
    const deleteEntry = (entry) => {
        FirebaseUtils.deleteCartItem(entry.id).then(() => {
            message.success('Selected cart item has deleted successfully. ');
            deleteCallback.callback();
        });
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },
        // {
        //     title: 'Part No',
        //     dataIndex: 'partNo',
        // },
        {
            // title: 'Part Name',
            title: 'Description',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>{text ? text : record.description}</div>
                        {/* New Iems added */}
                        {['accounts', 'accessories'].indexOf(user.role) !== -1 && record.subType === 'additional' ? (
                            <Popconfirm
                                title="Are you sure you want to delete this item ? "
                                onConfirm={() => deleteEntry(record)}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="dashed" size="small" style={{ marginLeft: '10px' }}>
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        ) : null}
                        {/* New Iems added Ends */}
                    </div>
                );
            },
        },

        {
            title: 'Total',
            dataIndex: 'net_amount'
        },
    ];

    function calculateTaxableValue(parts) {
        parts.forEach((entry, index) => {
            let sgst = parseFloat(entry.sgst) === 0 ? 9 : parseFloat(entry.sgst);

            let cgst = parseFloat(entry.cgst) === 0 ? 9 : parseFloat(entry.cgst);

            // Should we roud sum of sgst and cgst
            let taxableValue = (parseFloat(entry.price) / (101 + parseFloat(sgst) + parseFloat(cgst))) * 100;

            parts[index].taxable_value = Math.round(taxableValue);
        });

        return parts;
    }

    /**
     * Function to find the non taxable value to calculate the scss
     *
     * @param {*} parts
     */
    function findNonTaxableTotal(parts) {
        let total = 0;

        parts
            .filter((entry) => {
                return !entry.subType && entry.type == 'Accessories';
            })
            .forEach((entry) => {
                // Should we roud sum of sgst and cgst
                // let taxableValue = (((entry.price)) / ((100 + parseFloat(entry.sgst || 9) + parseFloat(entry.cgst || 9)))) * 100;

                // total += taxableValue;

                // For Vas and some items we have predefined the taxable amount to avoid calculation due to disparity in tax of
                // individual items

                if (entry.taxable_value) {
                    // Should we roud sum of sgst and cgst
                    let taxableValue = parseFloat(entry.taxable_value);

                    total += taxableValue;
                } else {
                    // Should we roud sum of sgst and cgst
                    let taxableValue =
                        ((parseFloat(entry.price) * (entry.quantity ? entry.quantity : 1)) /
                            (100 + parseFloat(entry.sgst ? entry.sgst : 9) + parseFloat(entry.cgst ? entry.cgst : 9))) *
                        100;

                    total += taxableValue;
                }
            });

        return Math.round(total);
    }

    return (
        <div className="selected-list card">
            {entries.length ? (
                <>
                    <Title level={3}>{category} Invoice</Title>

                    <Alert className="noprint" message="Below are accessory preferences confirmed by coordinator" type="info" showIcon />

                    <Table
                        rowKey={(entry) => entry.partNo}
                        scroll={{ x: true, y: 750 }}
                        pagination={false}
                        // pagination={{ hideOnSinglePage: true }}
                        columns={columns}
                        dataSource={entries}
                        size="small"
                        summary={(pageData) => {
                            let total = 0;
                            let taxableTotal = 0;

                            pageData.forEach((entry) => {
                                total += entry.net_amount
                            })
                          
                            taxableTotal = total

                            return (
                                <>
                                    {type === 'VAS' ? (
                                        <>
                                            {/* <Table.Summary.Row>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    CGST @ 9%
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <Text>
                                                        <Rupee
                                                            value={gst}
                                                        ></Rupee>
                                                    </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>

                                            <Table.Summary.Row>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    SGST @ 9%
                                                </Table.Summary.Cell>

                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <Text>
                                                        <Rupee
                                                            value={cgst}
                                                        ></Rupee>
                                                    </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row> */}
                                        </>
                                    ) : null}

                                    <Table.Summary.Row>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>Total</Table.Summary.Cell>

                                        <Table.Summary.Cell>
                                            <Text>
                                                <Rupee value={total}></Rupee>
                                            </Text>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>


                                            <Table.Summary.Row>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>Total Payable</Table.Summary.Cell>

                                                <Table.Summary.Cell>
                                                    <Text>
                                                        <Rupee value={taxableTotal}></Rupee>
                                                    </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                            );
                        }}
                    />
                </>
            ) : (
                <>
                    <p>Nothing added yet</p>
                </>
            )}
        </div>
    );
}
