/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Descriptions, Input, Switch, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom';

import './generic-add.scss';

import FirebaseUtils from './../../../../../utils/firebase.utils';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import useDeviceDetect from './../../../../../hooks/device-detect';

import PlaceHolder from './../../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from './../../../../../Store';

import FormCreator from './../../form-creator/form-creator';
import { cityKeys } from '../../../../global-config';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { Title } = Typography;

const { TextArea } = Input;

/**
 * Generic List
 *
 * @param {*} param0
 */
function GenericAdd({ match, model, collection, columns: cols, history, schema }) {
    const [record, setRecord] = useState([]);

    const { isMobile } = useDeviceDetect();

    const { user } = useContext(GlobalContext);

    const { params } = match;

    const { city } = params;

    const [loading, setLoading] = useState(false);

    const [location, setLocation] = useState(user.locations[0]);

    const step = {
        fields: cols,
    };

    useEffect(() => {}, []);

    var cityKey = cityKeys

    const onSubmit = (values) => {

        if (model === 'items') {
            values.price_on = 'master'
        }

        if (schema.tableIndex) {

            var params = {
                ...values,
                dealerCode: cityKey[location],
                deleted_at:null,
                deleted_by:null
            };

            return FirebaseUtils.addListing(model, params, null).then(() => {
                history.goBack();
            });
        } else {
            var params = {
                ...values,
                dealerCode: cityKey[location],
            };

            return FirebaseUtils.addListing(model, params).then(() => {
                history.goBack();
            });
        }
    };

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model}</Title>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        {/* <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button> */}
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <div className="card">
                        <FormCreator
                            formContent={{ [model]: {} }}
                            modelIndex="requestId"
                            model={model}
                            onSubmit={onSubmit}
                            config={step}
                            callback={() => {
                                history.goBack();
                            }}
                        />
                    </div>
                </>
            )}
        </section>
    );
}

export default withRouter(GenericAdd);
