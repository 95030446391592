import React from "react";
import { CSVLink } from "react-csv";
import { Button } from "antd";

export const ExportXLS = ({ csvData, fileName }) => {
  return (
    <Button variant="warning">
      {csvData && (
        <CSVLink data={csvData} filename={"Vehicle Overview"+".csv"}>
          Download
        </CSVLink>
      )}
    </Button> 
  );
};
