import React, { useState, useContext, useEffect, useRef } from 'react';

import { Card, Typography, Table, Tabs, Select, Button, message, Checkbox, Modal, Form, Input, Tooltip, Divider, Statistic, Row, Col } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { DatePicker, Space } from 'antd';

import { Link } from 'react-router-dom';

import moment from 'moment';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Location } from '../../../../utils/location.utils';

import { GlobalContext } from '../../../../Store';

import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';

import useWindowSize from '../../../../hooks/use-window-size';

import { Bookings, Employees, Requests, StatusMasters } from './../../../../models/index';

import { ExportXLS } from './ExportXLS';

import './booking-tracker.scss';

import StatusChange from '../../../../components/status-change/status-change';

// import "./booking-overview.scss";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;

const { RangePicker } = DatePicker;

const { TextArea, Search } = Input;

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

export default function BookingTracker(props) {
    const { user } = useContext(GlobalContext);

    const [range, setRange] = useState([moment().startOf('day'), moment().endOf('day')]);

    const [selected, setSelected] = useState([]);

    const [loading, setLoading] = useState(false);

    var location;

    if (['admin', 'manangement', 'gm', 'rm'].indexOf(user.role) != -1) {
        location = ['all', 'kozhikode', 'kannur', 'malappuram', 'kondotty', 'tirur', 'vadakara', 'kasargod'];
    } else {
        location = user.locations;
    }

    const [selectedLocation, setSelectedLocation] = useState(location[0] === 'all' ? null : location[0]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState({ selectedItem: '', selectedRecord: {}, subText: '' });

    const [option, setOption] = useState('transaction_date');

    const [status, setStatus] = useState([])

    // load awaiting confirmation status
    const [awaitingStatus, setAwaitingStatus] = useState([])

    // const [download, setDownload] = useState();

    const [form] = Form.useForm();

    const onSelectChange = (keys, rows) => {
        setSelected(rows);
    };

    const rowSelection = {
        selected,
        onChange: onSelectChange,
    };

    const customRowSelection = (e, keys, record) => {
        if (e.target.checked) {
            setSelected((state) => [record, ...state]);
        } else {
            let data = selected.filter((item) => item.id !== keys);
            setSelected(data);
        }
    };

    function loadData(range) {
        setRange(range);
    }

    function updateTime(dt) {
        setRange(dt);

        loadData(dt);
    }

    useEffect(() => {
        const param = Location.search();
        if (param && !param.city) {
            setRoute();
        }
    }, []);

    useEffect(() => {
        getBookingStatus();
        setSelected([]);
    }, [loading]);

    const setRoute = () => {
        // Location.search({ city: selectedLocation });
    };

    function handleChange(value) {
        setSelectedLocation(value);

        loadData(range);
    }


    // Get booking status from status master
    async function getBookingStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'BOOKING',
            },
        ];

        const result = await StatusMasters.get(queries);
        setStatus(result.status_masters || []);

        result.status_masters.forEach((element) => {
            if (element.name === 'Awaiting Confirmation') {
                setAwaitingStatus(element)
            }
        });


    }

    /**
    * Update the Booking status and description
    *
    * @param {*} status
    */
    async function updateBookingStatus(record, status, ele) {
        await Bookings.update(record['Booking No'], {
            current: {
                ...record.current,
                currentStatus: status.name,
                currentStatus_id: status.id,
                status: 'Status Updated',
                expectedDate: moment(ele['_d']).format('DD-MM-YYYY'),
            },

        })
        setLoading(!loading);
    }

    const handleStatusChange = (ele, record, key) => {
        if (key === 'dropdown') {
            let subText;
            if (['Delivered', 'Cancelled', 'Not Responding', 'Plan Postponed'].indexOf(ele) !== -1) {
                subText = '';
            } else {
                if (['Next Month', 'After 2 Months', 'After 3 Months'].indexOf(ele) !== -1) {
                    subText = record.current && record.current.expectedDate ? record.current.expectedDate : '';
                } else {
                    subText = record.current && record.current.expectedDate ? record.current.expectedDate : '';
                }
            }

            setSelectedStatus({ selectedItem: ele, selectedRecord: record, subText: subText });

            setIsModalVisible(true);
            setTimeout(() => {
                form.resetFields();
            }, 1000);
        } else {
            const hide = message.loading('Expected date updating..', 0);
            // Dismiss manually and asynchronously
            setTimeout(hide, 2500);
            let value = {
                current: {
                    ...record.current,
                    expectedDate: moment(ele['_d']).format('DD-MM-YYYY'),
                    // status: 'Status Updated'
                },
            };

            return FirebaseUtils.updateBookingGeneric(record.id, value).then(() => {
                setLoading(!loading);
                message.success('Expected date updated successfully.');
            });
        }
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                let dataObj = [];
                dataObj.currentStatus = selectedStatus.selectedItem;
                dataObj.expectedDate = selectedStatus.subText;
                let value = {
                    current: {
                        ...selectedStatus.selectedRecord.current,
                        ...dataObj,
                        status: 'Status Updated',
                        remarks: values.remarks,
                    },
                };

                return FirebaseUtils.updateBookingGeneric(
                    selectedStatus.selectedRecord.id,
                    value,
                    'Updated Booking Status',
                    values.remarks,
                    selectedStatus.selectedRecord
                ).then(() => {
                    setIsModalVisible(false);
                    setSelectedStatus({ selectedItem: '', selectedRecord: {}, subText: '' });
                    setLoading(!loading);
                    message.success('Status updated successfully.');
                });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedStatus({ selectedItem: '', selectedRecord: {}, subText: '' });
    };

    return (
        <section className="booking-tracker listing">
            <div className="page-header">
                <Title level={3}>Booking Tracker</Title>

                <div className="page-actions">
                    <Space>
                        <Select defaultValue={selectedLocation} style={{ width: 120 }} onChange={handleChange}>
                            {location.map((location) => {
                                return <Option value={location === 'all' ? null : location}>{location}</Option>;
                            })}
                        </Select>

                        {selected && selected.length ? (
                            <Button
                                size="medium"
                                onClick={() => {
                                    const hide = message.loading('Status updating..', 0);
                                    // Dismiss manually and asynchronously
                                    setTimeout(hide, 2500);

                                    Promise.all(
                                        selected.map((entry) => {
                                            var count = 0;
                                            let value = {
                                                current: {
                                                    ...entry.current,
                                                    currentStatus: awaitingStatus.name,
                                                    currentStatus_id: awaitingStatus.id
                                                },
                                            };

                                            return FirebaseUtils.updateBookingGeneric(entry.id, value).then(() => { });
                                        })
                                    ).then(() => {
                                        setLoading(!loading);
                                        message.success('Status updated successfully.');
                                        // callback();
                                    });
                                }}
                            >
                                Get Update
                            </Button>
                        ) : null}

                        <Button
                            className="refresh"
                            size="small"
                            onClick={() => {
                                let r = range;

                                setRange(null);

                                setTimeout(() => {
                                    loadData(r);
                                });
                            }}
                        >
                            Refresh
                        </Button>
                    </Space>
                </div>
            </div>

            <DailyDashboard
                option={option}
                customRowSelection={customRowSelection}
                rowSelection={rowSelection}
                range={range}
                url={selectedLocation}
                loading={loading}
                selected={selected}
                status={status}
                handleStatusChange={handleStatusChange}
                updateBookingStatus={updateBookingStatus}
            />

            <Modal title="Confirmation" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} destroyOnClose>
                <p>Are you sure you want to change the status.</p>
                {/* {['Cancelled', 'Not Responding', 'Delivered', 'Next Month', 'After 2 Months', 'After 3 Months', 'Plan Postponed'].indexOf(selectedStatus.selectedItem) !== -1 && selectedStatus.subText == '' ? <p style={{ color: 'orangered' }}>Please ensure the expected date. </p> : null} */}
                {/* <p>This action cannot reverted</p> */}

                <Form
                    preserve={false}
                    form={form}
                    {...layout}
                    name="new-record"
                    layout="vertical"
                    initialValues={{
                        remarks:
                            selectedStatus &&
                                selectedStatus.selectedRecord &&
                                selectedStatus.selectedRecord.current &&
                                selectedStatus.selectedRecord.current.remarks
                                ? selectedStatus.selectedRecord.current.remarks
                                : '',
                    }}
                >
                    <Form.Item
                        name={'remarks'}
                        label="Remarks"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter remarks',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </section>
    );
}

function DailyDashboard({ url, range, option, rowSelection, loading, customRowSelection, selected, status, handleStatusChange, updateBookingStatus }) {
    useEffect(() => {
        return () => { };
    }, [range]);

    return (
        <>
            <Card size="small">
                <PendingBookings
                    url={url}
                    option={option}
                    customRowSelection={customRowSelection}
                    date={range}
                    rowSelection={rowSelection}
                    selected={selected}
                    loading={loading}
                    status={status}
                    handleStatusChange={handleStatusChange}
                    updateBookingStatus={updateBookingStatus}

                    callback={(result) => { }}
                />
            </Card>
        </>
    );
}

/**
 *
 * Table of Customer Receipts
 *
 * @param {*} param0
 */
function PendingBookings({ date, url, option, rowSelection, loading, callback, customRowSelection, selected, status, handleStatusChange, updateBookingStatus }) {
    const { user, disableDisplayPhoneNumber } = useContext(GlobalContext);

    const [filterChoices, setFilterChoices] = useState([]);

    const [kec, setKec] = useState([]);



    const dateFormat = 'DD/MM/YYYY';

    const findSelected = (key, selected) => {
        return selected.map((el) => el.id).indexOf(key) !== -1;
    };

    // for getting bookingstatus
    const bookingStatus = status.map(ele => {
        return { text: ele.name, value: ele.name };
    });

    let columns = [
        {
            title: '#',
            // dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (index, record, text) => {
                return (
                    <div className={findSelected(text, selected) ? `main-component-selected` : `main-component`}>
                        <div className="checkbox-component">
                            <Space size="middle">
                                <Checkbox
                                    checked={findSelected(text, selected)}
                                    onChange={(e) => {
                                        customRowSelection(e, text, record);
                                    }}
                                />
                            </Space>
                        </div>
                        <div className="index-component">{index + 1}</div>
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            key: 'Name of the Customer',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record['Name of the Customer']}</div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>{record.contact_number}</div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'KEC',
            dataIndex: 'consultant',
            key: 'Consultant Name',
            width: 150,
            filters: filterChoices && filterChoices[0],
            onFilter: (value, record) => (record['consultant'] ? record['consultant'].indexOf(value) === 0 : null),
            // fixed: 'left',
        },
        {
            title: 'Team Leader',
            dataIndex: 'teamleader',
            key: 'Team Leader',
            filters: filterChoices && filterChoices[1],
            onFilter: (value, record) => (record['teamleader'] ? record['teamleader'].indexOf(value) === 0 : null),
            // defaultFilteredValue: user.role == 'tl' ? [user.name] : [],
            filterDropdownVisible: user.role == 'tl' ? false : null,
            width: 150,
        },
        {
            title: 'Variant',
            dataIndex: 'Variant',
            key: 'Variant',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record['Variant']}</div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>{record['Color']}</div>
                    </div>
                );
            },
            width: 200,
            filters: filterChoices && filterChoices[2],
            onFilter: (value, record) => (record['Variant'] ? record['Variant'].indexOf(value) === 0 : null),
        },
        {
            title: 'Booking Date',
            dataIndex: 'Booking Date',
            key: 'bookingDate',
            width: 150,
        },
        {
            title: 'Booking No',
            dataIndex: 'Booking No',
            key: 'bookingNo',
            width: 150,
        },
        {
            title: 'Vin No',
            dataIndex: 'Vin No',
            key: 'vinNo',
            render: (text, record) => record.vinNo,
            width: 150,
        },
        {
            title: 'Expected Date',
            // dataIndex: 'current.expectedDate',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {/* {record.current && record.current.expectedDate ? <Text>{record.current.expectedDate}</Text> : <Text>Not Updated</Text>} */}
                        <DatePicker
                            defaultValue={record.current && record.current.expectedDate ? moment(record.current.expectedDate, dateFormat) : null}
                            format={dateFormat}
                            onChange={(ele) => {
                                handleStatusChange(ele, record, 'date');
                            }}
                        />
                    </Space>
                );
            },
            width: 150,
        },
        // {
        //     title: 'Update Status',
        //     // dataIndex: 'current.status',
        //     render: (text, record) => {
        //         let status = 'Pending';

        //         let cl = 'warning';

        //         if (record.current && record.current.status) {
        //             status = record.current.status;

        //             if (status === 'Awaiting Confirmation') {
        //                 cl = 'danger';
        //             }

        //             if (status === 'Status Updated') {
        //                 cl = 'green';
        //             }
        //         }

        //         return (
        //             <Space size="middle">
        //                 <Text type={cl}>{status}</Text>
        //             </Space>
        //         );
        //     },
        //     width: 200,
        //     filters: filterChoices && filterChoices[4],
        //     onFilter: (value, record) => (record.current ? (record.current.status ? record.current.status.indexOf(value) === 0 : null) : null),
        // },

        {
            title: 'Booking Status',
            render: (ele, record) => {
                return (
                    <Space size="middle" style={{ minWidth: '180px' }}>
                        <StatusChange
                            formValues={{
                                status_id: record.current && record.current.currentStatus_id || null,
                                reference_number: record.id,
                            }}
                            updateDisabled={record && record.current && record.current.currentStatus == 'ALLOCATED'}
                            referenceNumber={record.id}
                            statusId={record.current && record.current.currentStatus ? record.current.currentStatus_id : null}
                            caption={'Booking Status'}
                            mode="BOOKING"
                            description={record.current && record.current.currentStatus ? record.current.currentStatus : 'Not updated'}
                            callback={(status) => {
                                updateBookingStatus(record, status, ele);
                            }}
                        />
                    </Space>
                );
            },
            width: 200,
            filters: bookingStatus,
            onFilter: (value, record) =>
                record.current ? (record.current.currentStatus ? record.current.currentStatus.indexOf(value) === 0 : null) : null,
        },
        {
            title: 'Cancellation Status',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Text>{record.cancellation && record.cancellation.status}</Text>
                    </Space>
                );
            },
            width: 200,
            filters: filterChoices && filterChoices[5],
            onFilter: (value, record) =>
                record.cancellation ? (record.cancellation.status ? record.cancellation.status.indexOf(value) === 0 : null) : null,
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastupdated_at',
            key: 'lastupdated_at',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record && record.lastupdated_at}</div>
                    </div>
                );
            },
            width: 200,
        },
        {
            title: 'Remarks',
            // dataIndex: 'current.status',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Tooltip title={record.current && record.current.remarks}>
                            <span>{record.current && record.current.remarks && record.current.remarks.substring(0, 10) + '...'}</span>
                        </Tooltip>
                    </Space>
                );
            },
            width: 150,
        },

        {
            title: '',
            key: 'action',
            // fixed: 'right',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`bookings/${record.id}`}>View</Link>
                    </Space>
                );
            },
            width: 150,
        },
    ];

    const [loader, setLoader] = useState(true);

    const [customers, setCustomers] = useState([]);

    const [downloadRecord, setDownloadRecord] = useState([]);

    const [disableSelect, setDisableSelect] = useState(false);

    const [enableSearch, setEnableSearch] = useState(false);

    const [searchedList, setSearchedList] = useState([]);

    const [download, setDownload] = useState();

    const [enableBookingFilter, setEnableBookingFilter] = useState(false)

    const [filteredCustomers, setFilteredCustomers] = useState([])

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };


    function getBookings(url, range, option) {
        setLoader(true);

        var queries = [
            {
                field: 'actual_billing.status',
                value: 'pending',
            },
            {
                field: 'current.currentStatus',
                operator: "not-in",
                value: ['CANCELLED', 'DELIVERED'],
            },
        ];

        if (user.role === 'tl') {

            queries.push({ field: 'teamleader_id', value: user.employee_id });

            // queries.push({ field: 'Team Leader', value: user.name });
        }

        FirebaseUtils.getListing(url, queries, 'bookings').then((result) => {

            let bookings = result.bookings.filter((entry) => !entry.isCancelled);

            // getting the kec and teamleader and attach
            Employees.getKECandTeamleader(bookings).then(async (res) => {

                // Get vinNo allocated with requestId in bookings

                var bookingsWithVinNo = await Promise.all(
                    res.map(async (bookings) => {
                        if (bookings.requestId) {
                            var request = await Requests.getRecord(bookings.requestId)
                            return { ...bookings, vinNo: request.allocation && request.allocation.vinNo || null }
                        } else {
                            return { ...bookings, vinNo: null }
                        }
                    }),

                    res.map(async (bookings) => {
                        {/*disable PhoneNumber for kec and tl */ }
                        if (disableDisplayPhoneNumber) {
                            return { ...bookings, contact_number: bookings['Contact Number'] }
                        }
                        else {
                            return { ...bookings, contact_number: null }
                        }
                    }))

                setCustomers(bookingsWithVinNo);

                FiteringValue(bookingsWithVinNo);

                setDownloadRecord(bookingsWithVinNo);

                setLoader(false);

            })

            // FiteringValue(bookings);

            // setCustomers(bookings);

            // setDownloadRecord(bookings);
        });
    }

    const FiteringValue = (data) => {
        let arrKEC = [];
        let arrKEC1 = [];
        let arrTL = [];
        let arrTL1 = [];
        let arrVar = [];
        let arrVar1 = [];
        let arrUS = [];
        let arrUS1 = [];
        let arrCS = [];
        let arrCS1 = [];
        let arrStatus = [];


        data.map((ele) => {
            if (ele['consultant'] && arrKEC.includes(ele['consultant']) === false) {
                arrKEC.push(ele['consultant']);
                arrKEC1.push({ text: ele['consultant'], value: ele['consultant'] });
            }
            if (ele['teamleader'] && arrTL.includes(ele['teamleader']) === false) {
                arrTL.push(ele['teamleader']);
                arrTL1.push({ text: ele['teamleader'], value: ele['teamleader'] });
            }
            if (ele['Variant'] && arrVar.includes(ele['Variant']) === false) {
                arrVar.push(ele['Variant']);
                arrVar1.push({ text: ele['Variant'], value: ele['Variant'] });
            }
            if (ele.current && ele.current.status && arrUS.includes(ele.current.status) === false) {
                arrUS.push(ele.current.status);
                arrUS1.push({ text: ele.current.status, value: ele.current.status });
            }
            if (ele.cancellation && ele.cancellation.status && arrCS.includes(ele.cancellation.status) === false) {
                arrCS.push(ele.cancellation.status);
                arrCS1.push({ text: ele.cancellation.status, value: ele.cancellation.status });
            }
        });
        let body = [arrKEC1, arrTL1, arrVar1, arrStatus, arrUS1, arrCS1];

        setFilterChoices(body);
    };

    useEffect(() => {

        if (date) {

            if (enableBookingFilter) {
                setEnableBookingFilter(false)
                setFilteredCustomers([])
            }

            getBookings(url, date, option);

            if (enableSearch) {
                setSearchedList([]);
                setEnableSearch(false);
            }
        }
    }, [loading, url]);

    useEffect(() => {
        // Select required fields from customer json array. (for exporting to csv document)
        setDownload(
            downloadRecord &&
            downloadRecord
                .slice(0)
                .reverse()
                .map((item) => {
                    return {
                        Customer_Name: item['Name of the Customer'],
                        Customer_Mobile: item.contact_number,
                        KEC: item['consultant'],
                        Team_Leader: item['teamleader'],
                        Model: item['Model'],
                        Variant: item['Variant'],
                        Color: item['Color'],
                        Booking_Date: item['Booking Date'],
                        Booking_No: item['Booking No'],
                        Vin_No: item.vinNo,
                        Expected_Date: item.current && item.current.expectedDate && item.current.expectedDate,
                        Update_Status: item.current && item.current.status && item.current.status,
                        Booking_Status: item.current && item.current.currentStatus && item.current.currentStatus,
                        Cancellation_Status: item.cancellation && item.cancellation.status && item.cancellation.status,
                        Remarks: item.current && item.current.remarks && item.current.remarks,
                    };
                })
        );
    }, [downloadRecord]);


    const onSearch = (value) => {
        let arr = [];
        customers.map((item) => {
            if (item['Name of the Customer'].toLowerCase().includes(value.toLowerCase())) {
                arr.push(item);
            }
        });
        setSearchedList(arr);
        setEnableSearch(true);
    };

    const getCount = (value, type) => {
        if (type == 'booking_status') {
            const arr = customers.filter((item) => {
                return item.current && item.current.currentStatus == value;
            });
            return arr.length;
        } else {
            const arr = customers.filter((item) => {
                return item.current && item.current.status == value;
            });
            return arr.length;
        }
    };


    // Filter customers based on current status
    function handleBookingStatus(ele) {

        console.log(ele)
        if (ele) {
            setEnableBookingFilter(true)
            setFilteredCustomers(customers.filter((item) => item.current && item.current.currentStatus_id === ele))
        } else {
            setEnableBookingFilter(false)
        }

    }

    return (
        <>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <div className="statistic-block">
                        {/* Download Button */}
                        <div className="download-button">
                            {/*table data export to csc component*/}
                            {download && <ExportXLS csvData={download} />}


                            <Select
                                placeholder="Select Booking Status"
                                allowClear
                                // defaultValue={record.current && record.current.currentStatus ? record.current.currentStatus : 'Not updated'}
                                style={{ width: 180, textAlign: 'left' }}
                                onChange={(ele) => {
                                    handleBookingStatus(ele);
                                }}
                            >
                                {status.map((data, key) => (
                                    <Option value={data.id}>{data.name}</Option>
                                ))}
                            </Select>

                        </div>


                        <Row gutter={16}>
                            <Col span={5} xs={12} md={5}>
                                <div className="card">
                                    <Statistic title={'Total'} value={enableBookingFilter ? filteredCustomers.length : customers.length} />
                                </div>
                            </Col>

                            <Col span={5} xs={12} md={5}>
                                <div className="card">
                                    <Statistic title={'URGENT - NO STOCK'} value={getCount('URGENT - NO STOCK', 'booking_status')} />
                                </div>
                            </Col>



                            <Col span={5} xs={12} md={5}>
                                <div className="card">
                                    <Statistic title={'ALLOCATED'} value={getCount('ALLOCATED', 'booking_status')} />
                                </div>
                            </Col>
                            <Col span={5} xs={12} md={5}>
                                <div className="card">
                                    <Statistic title={'THIS MONTH RETAIL - NO STOCK'} value={getCount('THIS MONTH RETAIL - NO STOCK', 'booking_status')} />
                                </div>
                            </Col>
                            <Col span={5} xs={12} md={5}>
                                <div className="card">
                                    <Statistic title={'NEXT MONTH'} value={getCount('NEXT MONTH', 'booking_status')} />
                                </div>
                            </Col>
                            <Col span={5} xs={12} md={5}>
                                <div className="card">
                                    <Statistic title={'IN 3 MONTHS'} value={getCount('IN 3 MONTHS', 'booking_status')} />
                                </div>
                            </Col>
                            <Col span={5} xs={12} md={5}>
                                <div className="card">
                                    <Statistic title={'Awaiting Confirmation'} value={getCount('Awaiting Confirmation', 'booking_status')} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="search-block">
                        <Search placeholder="input search text" allowClear onSearch={onSearch} style={{}} />
                    </div>
                    <Divider orientation="left" plain>
                        {enableSearch ? searchedList.length + '   Bookings' : enableBookingFilter ? filteredCustomers.length + '   Bookings' : customers.length + '   Bookings'}
                    </Divider>
                    <VirtualTable
                        columns={columns}
                        dataSource={enableSearch ? searchedList : enableBookingFilter ? filteredCustomers : customers}
                        rowSelection={rowSelection}
                        disableSelect={disableSelect}
                        callback={(val) => {
                            setDownloadRecord(val);
                        }}
                        scroll={{
                            y: 500,
                            x: '100vw',
                        }}
                    // scroll={{ x: 1500, y: 300 }}
                    />
                </>
            )}
        </>
    );
}

function VirtualTable(props) {

    const size = useWindowSize();

    const { columns, scroll } = props;
    const tableWidth = size.width - 256;
    // const [tableWidth, setTableWidth] = useState(1184);

    const widthColumnCount = columns.filter(({ width }) => !width).length;

    const mergedColumns = columns.map((column) => {
        if (column.width) {
            return column;
        }

        return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
    });

    const gridRef = useRef();

    const [connectObject] = useState(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => null,
            set: (scrollLeft) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({
                        scrollLeft,
                    });
                }
            },
        });
        return obj;
    });

    const resetVirtualGrid = () => {
        gridRef.current.resetAfterIndices({
            columnIndex: 0,
            shouldForceUpdate: false,
        });
    };

    useEffect(() => resetVirtualGrid, [tableWidth]);

    const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
        ref.current = connectObject;

        const totalHeight = rawData.length * 54;

        return (
            <Grid
                ref={gridRef}
                className="virtual-grid"
                columnCount={mergedColumns.length}
                columnWidth={(index) => {
                    const { width } = mergedColumns[index];
                    return totalHeight > scroll.y && index === mergedColumns.length - 1 ? width - scrollbarSize - 1 : width;
                }}
                height={800}
                rowCount={rawData.length}
                rowHeight={() => 70}
                width={tableWidth}
                onScroll={({ scrollLeft }) => {
                    onScroll({
                        scrollLeft,
                    });
                }}
            >
                {({ columnIndex, rowIndex, style }) =>
                    mergedColumns[columnIndex].render ? (
                        <div
                            className={classNames('virtual-table-cell', {
                                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                            })}
                            style={style}
                        >
                            {mergedColumns[columnIndex].render(rowIndex, rawData[rowIndex], rawData[rowIndex]['id'])}
                        </div>
                    ) : (
                        <div
                            className={classNames('virtual-table-cell', {
                                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                            })}
                            style={style}
                        >
                            {rawData[rowIndex][mergedColumns[columnIndex].dataIndex]}
                        </div>
                    )
                }
            </Grid>
        );
    };

    return (
        // <ResizeObserver
        //     onResize={({ width }) => {
        //         setTableWidth(width);
        //     }}
        // >
        <Table
            // size='small'
            {...props}
            className="virtual-table"
            columns={mergedColumns}
            rowKey={(record) => record.id}
            onChange={(pagination, filters, sorter, extra) => {
                props.callback(extra.currentDataSource);
            }}
            rowSelection={!props.disableSelect ? props.rowSelection : null}
            pagination={false}
            components={{
                body: renderVirtualList,
            }}
        />
        // </ResizeObserver>
    );
}
