import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import XLSX from 'xlsx';

import { Space, Switch, message, Table, Typography, Tag, Button, Upload, Form, Input, Select, Skeleton } from 'antd';
import { Menu, Dropdown, Drawer } from 'antd';

import { GlobalContext } from '../../../../Store';

import { DownOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import './manage-stocks.scss';

import moment from 'moment';

import useDeviceDetect from '../../../../hooks/device-detect';

import { SearchOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { cityKeys } from '../../../global-config';

const { Text, Title } = Typography;

const { Option } = Select;

export default function ManageParts({ history, report, index }) {
    const [records, setRecords] = useState([]);

    const [taxes, setTaxes] = useState([])

    const [itemUpdatedCount, setItemUpdateCount] = useState(0);

    const [newItems, setNewItems] = useState({});

    const [master, setMaster] = useState([]);

    const { isMobile } = useDeviceDetect();

    const { user, defaultBranch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [files, setFiles] = useState([]);

    const [form] = Form.useForm();

    const [uploading, setUploading] = useState(false)

    const [updateItemsTax, setUpdateItemsTax] = useState({})

    const [result, setResults] = useState([]);

    const [location, setLocation] = useState(user.locations[0]);

    const [bookings, setBookings] = useState({});

    const [newStocks, setNewStocks] = useState({});

    const [page, setPage] = React.useState(1);

    const [view, setView] = useState(isMobile);

    const [visible, setVisible] = useState(false);

    var cityKey = cityKeys

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    const [head, setHead] = useState({});

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
        },
        {
            title: 'Part No',
            dataIndex: 'part_number',
            key: 'partNo',
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'partName',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'Desired Stock',
            dataIndex: 'desired_stock',
            key: 'desired_stock',
        },
        
    ];

    useEffect(() => {
        if (user) {
            getItems(location);
            getStocks(location);
            getTaxRecord()
        }
    }, [user]);

    console.log(records.stocks && records.stocks.length);
    console.log(records.stocks);

    //get items master
    function getItems() {
        FirebaseUtils.getListing(null, [], 'items').then((result) => {
            console.log(result);

            setMaster(result);
        });
    }

    //get stock with branch
    function getStocks(location, options) {
        setLoading(true)
        FirebaseUtils.getStocks(location, []).then((result) => {
            console.log(result);
            setRecords(result);
        });
        setLoading(false)
    }

    //get taxes
    function getTaxRecord() {
        FirebaseUtils.getTaxRecord().then((result) => {
            console.log('taxes', result);
            setTaxes(result);
        });
    }

    function refresh() {
        getStocks(location, []);
    }

    function handleFile(f) {
        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {
            const bstr = e.target.result;

            // let data = csvJSON(bstr);

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws, {
                // header: 0
                // range:0

                header: 2,
                range: 0,
            });

            console.log(data);

            processData(data);
        };

        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
    }

    //Process uploaded data
    function processData(data) {
        var modified = {};
        var items = {};
        var updateItems = {}

        var columns = data[0];

        console.log(columns);
        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
        };
        
        
        
        //processing each raw of uploaded data
        data.forEach((element, index) => {

            let purchasePriceWithoutTax = 0;
            
            if (index < 1) return;

            let key = element['PART NO'].trim();

            //filtering master item existing without tax pointer
            let itemsTax = master.items
            
            //filtering the taxes with EXCEL tax column
            let tax = taxes.filter(tax => tax.stock_tax_id === element['SALE TAX'].trim())


            if (tax.length > 0) {

                itemsTax.map(item => {
                    if (item.part_number === key) {
                        updateItems[key] = {
                            ...item,
                            tax_id: tax[0].id,
                        }
                    }
                })

                
            }

            //purchase_price_without_tax calculation
            purchasePriceWithoutTax = (100 * element['PRICE']) / ( 100 + 18 ) //18% tax 


            let itemMaster = master.items.filter((item) => item.part_number === key);

            let itemId = null;

            // item_id pointing to stock else should be create new item
            if (itemMaster.length > 0) {
                itemId = itemMaster[0].id;
            } else {
                itemId = null;
                let creation = {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_date: moment().startOf('day').valueOf(),
                    created_time: moment().valueOf(),
                };
                items[key] = {
                    ...creation,
                    dealerCode: cityKey[location],
                    branch_id: cityKey[location],
                    category: 'Accessories',
                    hsn: element['HSN CODE'].trim(),
                    part_name: element['PART NAME'].trim(),
                    part_number: element['PART NO'].trim(),
                    supplier: element['SUPPLIER'],
                    tax_inclusive: true,
                    deleted_at:null,
                    deleted_by:null
                };
                if (tax.length > 0) {
                    items[key].tax_id = tax[0].id
                }
            }

            
            // Setting the stocks under branch
            modified[key] = {
                ...creation,
                item_id: itemId,
                part_number: element['PART NO'].trim(),
                part_name: element['PART NAME'].trim(),
                stock: element['INVENTORY QUANTITY'],
                on_order: element['__EMPTY_5'],
                purchase_order: element['__EMPTY_3'],
                desired_stock: element['DESIRED STOCK LEVEL'],
                price: element['__EMPTY_11'],
                purchase_price: element['PRICE'],
                purchase_price_without_tax: parseFloat(purchasePriceWithoutTax.toFixed(2)),
                average_price: element['PRICE'],
                list_price: element['__EMPTY_10'],
                pack_quantity: 1,
                branch_id: cityKey[location],
                dealerCode: cityKey[location],
                deleted_at:null,
                deleted_by:null
                // Add rest of fields
            };
        });

        // We have to compare with old data
        const previousKeys = records.stocks.map((item) => item.part_number);

        console.log(previousKeys);

        const latestKeys = Object.keys(modified);

        console.log(latestKeys);

        latestKeys.forEach((key) => {
            if (previousKeys.indexOf(key) !== -1) {
                let i = previousKeys.indexOf(key);
                let partNum = previousKeys[i];
                let prevRecord = records.stocks.filter((item) => item.part_number === partNum);

                modified[key].duplicate = true;
                modified[key].id = prevRecord[0].id;
            }
        });

        setNewStocks(modified);
        setNewItems(items);
        setUpdateItemsTax(updateItems)
        console.log(modified);
    }
    function approveUpload() {

        setUploading(true)
        let itemId = [];
        let b = {};

        let duplicate = 0;

        

        console.log(newItems);
        //setting taxes for new items
        return Promise.all(Object.keys(updateItemsTax).map(no => {
            return FirebaseUtils.updateRecord('items', updateItemsTax[no].id, updateItemsTax[no]).then((result) => {
                    return result
                    });
        })).then(() => {

            //item is in stocks XL but not in Master,  add item
            return Promise.all(
                Object.keys(newItems).map((no) => {

                    return FirebaseUtils.addListing('items', { ...newItems[no] }).then((result) => {

                        itemId.push({ id: result.id, no: no });

                    

                    });

                })
            )
                .then(() => {
                    console.log(itemId);
                    Object.keys(newStocks).map((no) => {
                        if (newStocks[no].duplicate) {
                            duplicate++;
                        }

                        let item = itemId.filter((i) => i.no === no);
                        if (item.length > 0) {
                            console.log(item);
                            b[no] = {
                                ...newStocks[no],
                                item_id: item[0].id,
                            };
                        } else {
                            b[no] = {
                                ...newStocks[no],
                            };
                        }
                    });

                    let total = Object.keys(b).length;

                    let count = total - duplicate;

                    FirebaseUtils.updateStocks(user, b).then((result) => {
                        message.success(`${total} accessories updated. ${count} new accessories`);

                        setNewStocks({});

                        
                        setUploading(false)
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        })

    }

    const uploadProps = {
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            setFiles([...files, file]);

            return false;
        },
        files,
    };

    function changeView(result) {
        setView(result);
    }

    const SheetJSFT = [
        'xlsx',
        'xlsb',
        'xlsm',
        'xls',
        'xml',
        'csv',
        'txt',
        'ods',
        'fods',
        'uos',
        'sylk',
        'dif',
        'dbf',
        'prn',
        'qpw',
        '123',
        'wb*',
        'wq*',
        'html',
        'htm',
    ]
        .map(function (x) {
            return '.' + x;
        })
        .join(',');

    const menu = () => {
        return (
            <Menu>
                {user.locations.map((location, index) => (
                    <Menu.Item key={index}>
                        <span
                            onClick={() => {
                                setLocation(location);

                                options.start = null;
                                options.end = null;

                                // options = {
                                //     ...options,
                                //     ...{
                                //         start: null,
                                //         end: null
                                //     }
                                // }

                                setOptions(options);

                                getStocks(location, []);
                            }}
                        >
                            {location}
                        </span>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    function handleTableChange(pagination, filters, sorter) {
        console.log(pagination, filters, sorter);

        setPage(pagination.current);

        // options.filters = filters;
        options.page = pagination.current;

        setOptions({ ...options });

        getStocks(location, []);
    }

    function onSearch(values) {
        console.log(values);

        let params = {
            field: values.searchBy,
            value: values.search_key,
        };

        setLoading(true);

        setVisible(false);

        FirebaseUtils.searchBookings(location, params).then((result) => {
            console.log(result);

            setBookings(result);

            setLoading(false);

            let b = Object.keys(result.bookings).map((booking) => {
                return result.bookings[booking];
                // return { ...result.bookings[booking], requestId: request }
            });

            setResults(b);
        });
    }

    function resetPage() {
        form.resetFields();

        getStocks(location, []);
    }

    return (

        uploading ? <Skeleton paragraph={{ rows: 4 }} /> :
            
        <div className="manage-bookings listing">
            <div className="page-header">
                <div className="left">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Title level={4}>
                            <span className="location">{location}</span> Stocks <DownOutlined />
                        </Title>
                    </Dropdown>

                    <div>
                        {bookings.lastUpdated && bookings.lastUpdated.timestamp ? (
                            <p>
                                <small>
                                    Last Updated :{' '}
                                    <Text type="danger">{`${moment(bookings.lastUpdated.timestamp, 'DD/MM/YYYY HH:mm').fromNow()}`}</Text>
                                </small>
                            </p>
                        ) : null}
                    </div>
                </div>

                <div className="table-hint right">
                    {/* <Switch
                        defaultChecked
                        onChange={changeView}
                        checked={view}
                        checkedChildren={<OrderedListOutlined />}
                        unCheckedChildren={<PicCenterOutlined />}
                    /> */}

                    <div className="page-actions">
                        {loading ? <Text type="secondary">Loading Accessories</Text> : null}

                        <div className="button-container">
                            <>
                                <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                    <Button size={'small'} icon={<UploadOutlined />}>
                                        Select File
                                    </Button>
                                </Upload>
                            </>

                            {/* <Button onClick={showDrawer} type="secondary" size={'small'}>
                                <SearchOutlined />
                            </Button> */}

                            <Button onClick={refresh} type="secondary" size={'small'}>
                                <ReloadOutlined />
                            </Button>

                            {/* <Button
                                disabled={options.page <= 1}
                                size="small"
                                onClick={() => {
                                    options.page--;

                                    setOptions(options);

                                    getStocks(location, []);

                                    // getBookings(location, options, true);
                                }}
                            >
                                <CaretLeftOutlined />
                            </Button>

                            <Button
                                size="small"
                                onClick={() => {
                                    getStocks(location, []);

                                    options.page++;

                                    setOptions(options);
                                }}
                            >
                                <CaretRightOutlined />
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>

            
            {Object.keys(newStocks).length ? (
                <div className="file-review">
                    <div className="action-header">
                        <h4>{Object.keys(newStocks).length} new stocks</h4>
                        <p>{Object.keys(newStocks).filter((entry) => newStocks[entry]['duplicate']).length} are duplicate.</p>
                        <p style={{marginTop: '-5px'}}>{Object.keys(newItems).length} new items.</p>
                        <Button onClick={approveUpload} key="3">
                            Approve
                        </Button>
                        ,
                        <Button
                            key="2"
                            onClick={() => {
                                setNewStocks({});
                            }}
                        >
                            Decline
                        </Button>
                    </div>
                </div>
            ) : null}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <>
                        {!view ? (
                            <Table
                                loading={loading}
                                size="small"
                                // scroll={{ x: true, y: 750 }}

                                // scroll={{ x: true, y: 750 }}
                                rowKey={(record) => record.index}
                                dataSource={records.stocks}
                                columns={columns}
                                onChange={handleTableChange}
                                pagination={false}
                            ></Table>
                        ) : (
                            <CardList url={location} data={records.stocks} />
                        )}
                    </>

                    <div></div>
                </>
            )}

            <Drawer placement="left" closable={false} onClose={onClose} visible={visible} className="search-drawer">
                <div className="intro">
                    <Title level={4}>Search</Title>
                </div>

                <Form
                    // {...formItemLayout}
                    // layout={isMobile?'':'inline'}
                    form={form}
                    onFinish={onSearch}
                    // initialValues={{ layout: formLayout }}
                    // onValuesChange={onFormLayoutChange}
                >
                    <Form.Item name="searchBy" label="Search By" rules={[{ required: true }]}>
                        <Select
                            placeholder="Select Option"
                            //   onChange={onGenderChange}
                            allowClear
                        >
                            <Option value="Variant">Variant</Option>

                            <Option value="Contact Number">Contact Number</Option>
                            <Option value="Team Leader">Team Leader</Option>
                            <Option value="Consultant Name">Consultant Name</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="search_key">
                        <Input placeholder="Search By" />
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Submit
                        </Button>
                    </Form.Item>

                    <Button
                        onClick={() => {
                            resetPage();
                        }}
                        type="secondary"
                    >
                        Reset
                    </Button>
                </Form>
            </Drawer>
        </div>
    );
}

function CardList({ data, url }) {
    return data.map((report, index) => {
        return <BookingCard city={url} booking={report} index={index} key={index} />;
    });
}

function BookingCard({ city, booking = {} }) {
    return (
        <Link className="booking-card" to={`${city}/booking/${booking['Booking No']}`}>
            <div className="card">
                <h2 className="title amount ">{booking['Name of the Customer']}</h2>
                <h4 className="title">{booking['Contact Number']}</h4>
                <h3 className="title ">{booking['Variant']}</h3>
                <div>
                    <small> {booking['Color']}</small>
                </div>
                <h4>{booking['Booking No']}</h4>
                <p className="address">
                    Booked on {booking['Booking Date']}
                    <Tag color="magenta">{booking.status || 'Initial'}</Tag>
                </p>
                Committed Delivery Date {booking['Committed Delivery Date']}
            </div>
        </Link>
    );
}
