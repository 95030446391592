/**
 *
 *
 */

import Base from './base';

class StockPoints extends Base {

    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'stock_points';
    }

    get path() {
        return `stock_points`;
    }

    get getName() {
        return `stock_points`;
    }
}

export default StockPoints;