
import React, { useState, useEffect, useContext } from "react";

import { Row, Button } from "antd";

import ReportCard from './../report-card/ReportCard';

import { Tabs } from 'antd';


import PlaceHolder from "../../../../../components/ui_elements/PlaceHolder";

import "./collections.scss";

import { GlobalContext } from './../../../../../Store';


import FirebaseUtils from '../../../../../utils/firebase.utils';


const { TabPane } = Tabs;

export default function Collections(props) {

    const { user } = useContext(GlobalContext);

    let city = user.locations[0];

    const [reports, setReports] = useState([]);

    const [branch, setBranch] = useState({});

    const [loader, setLoader] = useState(false);

    useEffect(() => {

        getCustomerReceipts();

    }, [])

    var groupBy = function (xs, key) {

        return xs.reduce(function (rv, x) {

            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;

        }, {});
    };


    /**
     * Get stock Data
     */
    function getCustomerReceipts(location) {

        setLoader(true);

        var queries = [{
            field: 'pending_at',
            value: 'sm'
        },
        {
            field: 'reconcile_status',
            value: 'pending'
        },
        {
            field: 'isCredit',
            value: true
        }
        ]

        FirebaseUtils.getBankCredits(queries).then((result) => {

            setLoader(false);

            setReports(result);

            var r = groupBy(result, 'bank');

            console.log(result);

            setBranch(r);

        });
    }



    function callback(key) {
        console.log(key);
    }



    return (
        <Tabs defaultActiveKey="1" onChange={callback} className="collection">

            <TabPane tab={`All (${reports.length})`} key="0">

                <div className="list-header">

                    <p className="page-desc">
                        {loader ? 'Loading data' : `Found ${reports.length} results`}
                    </p>

                    <div>
                        <Button onClick={getCustomerReceipts} type="secondary" size={'small'}>Refresh</Button>
                    </div>

                </div>

                <Row className="report-listing-wrapper">
                    {
                        loader ? (
                            <PlaceHolder type="listing" />
                        ) : (<>



                            {
                                reports.map((report, index) => {

                                    return <ReportCard report={report} index={index} key={index} />
                                })
                            }
                        </>)
                    }
                </Row>
            </TabPane>

            {
                Object.keys((branch)).map((index) => {

                    return (<TabPane tab={`${index} (${branch[index].length})`} key={index}>

                        <div className="list-header">

                            <p className="page-desc">
                                {loader ? 'Loading data' : `Found ${reports.length} results`}
                            </p>

                            <div>
                                <Button onClick={getCustomerReceipts} type="secondary" size={'small'}>Refresh</Button>
                            </div>

                        </div>

                        <Row className="report-listing-wrapper">
                            {
                                loader ? (
                                    <PlaceHolder type="listing" />
                                ) : (<>


                                    {
                                        branch[index].map((report, index) => {

                                            return <ReportCard report={report} index={report.reportId} key={index} />
                                        })
                                    }
                                </>)
                            }
                        </Row>
                    </TabPane>)

                })

            }

        </Tabs>
    );
}
