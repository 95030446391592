import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Button, Typography, Statistic, Row, Col, Badge, Skeleton } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

// import "./dashboard.scss";

import { GlobalContext } from '../../../../Store';

import { GetData } from '../../../../utils/http.utils';

const { Title, Text } = Typography;

let displayTile = false;

export default function PaymentRequestsTile({ schema }) {
    const { user } = useContext(GlobalContext);

    const [data, setData] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        setLoader(true);

        Promise.all(
            [
                { name: 'Kozhikode', location: 'clt' },
                { name: 'Malappuram', location: 'mpm' },
                { name: 'Kannur', location: 'knr' },
                // { name: 'Kondotty', location: 'kty' },
                // { name: 'Tirur', location: 'trr' },
                // { name: 'Vadakara', location: 'vdk' },
                // { name: 'Kasargod', location: 'ksd' },
            ].map(loadData)
        ).then((result) => {
            // Get the data
            setData(result);

            setLoader(false);
        });
    }

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    /**
     * Get the data
     */
    function loadData(location) {
        setLoader(true);

        return GetData({ url: schema.endPoint.replace(':city', location.location) + schema.queryParams + '&page=1&limit=50' })
            .then((result) => {
                // Group By Voucher No
                var v = groupBy(result, 'ver_vno');

                var arr = [];

                // Iterate to form data
                Object.keys(v).forEach((v_no) => {
                    var vouchers = v[v_no];

                    // Find the bank record
                    var bank_record = vouchers.filter((r) => r.ver_totrow === 'Y').pop();

                    vouchers.forEach((rec) => {
                        if (rec.ver_totrow !== 'Y' && rec.ver_amt > 0) {
                            arr.push({
                                ...rec,
                                bank_record,
                            });
                        }
                    });
                });

                if (result.length) {
                    displayTile = true;
                } else {
                    displayTile = false;
                }

                // setRecords(result);

                return {
                    location: location.name,
                    result: arr,
                    // result: result,
                };
                // return {
                //     location: location.name,
                //     result: result,
                // };
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {loader ? (
                <></>
            ) : (
                <>
                    {displayTile ? (
                        <>
                            <section className="second-content dashboard dashboard-card">
                                <div className="list-header">
                                    <Title level={4}>Payment Requests</Title>

                                    <div>{/* <Button onClick={getData} type="secondary" size={'small'}>Refresh</Button> */}</div>
                                </div>

                                <Row>
                                    {data.map((location) => (
                                        <PendingRequests content={location} />
                                    ))}
                                </Row>
                            </section>
                        </>
                    ) : null}
                </>
            )}
        </>
    );
}

/**
 *
 *
 * @param {*} param0
 * @returns
 */
function PendingRequests({ content }) {
    const [loader, setLoader] = useState(false);

    const [records, setRecords] = useState([]);

    useEffect(() => { }, []);

    return (
        <>
            <Col className="report-listing-wrapper">
                {loader ? (
                    <Skeleton />
                ) : (
                    <>
                        <Col>
                            <div className="score">
                                <Link className="card dashboard-item" to={`/${content.location.toLowerCase()}/payment-requests`}>
                                    <Statistic title={`${content.location}`} value={content.result.length} />
                                    <a>View</a>
                                </Link>
                            </div>
                        </Col>
                    </>
                )}
            </Col>
        </>
    );
}
