import React, { useState, useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Table, Statistic, Button, Switch, List, Badge, Collapse, Typography, Alert } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import { Vehicles, Requests } from './../../../../models/';

import moment from 'moment';

import { OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import './current-stock.scss';
import { arrowFunctionExpression } from '@babel/types';
import {  cityKeys } from '../../../global-config';

const { Title, Text } = Typography;

const { Panel } = Collapse;

var cityKey = cityKeys

var images = {
    SELTOS: require('./../../../../assets/images/cars/seltos.png'),
    SONET: require('./../../../../assets/images/cars/sonet.png'),
    CARNIVAL: require('./../../../../assets/images/cars/carnival.png'),
    CARENS: require('./../../../../assets/images/cars/carens.png'),
    EV6: require('./../../../../assets/images/cars/ev6.png')
};

var badgeColor = {
    Allocated: 'error',
    'In transit': 'warning',
    'Free Stock': 'success',
};

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function CurrentStock({ url }) {
    const { user } = useContext(GlobalContext);

    const [stock, setStock] = useState({ vehicles: {}, lastUpdated: {} });

    const [loading, setLoading] = useState(true);

    const [view, setView] = useState({
        PreAllocation: false,
        Unallocated: false,
    });

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        setLoading(true);

        if (url === 'all') {
            Vehicles.getAllAvailableStock().then((result) => {
                setLoading(false);

                setStock({ vehicles: result.vehicles });
            });
        } else {
            var queries = [
                {
                    field: 'dealerCode',
                    value: cityKey[url],
                },
            ];

            Vehicles.getAvailableStock(queries).then((result) => {
                setLoading(false);

                setStock({ vehicles: result.vehicles, lastUpdated: result.lastUpdated });
            });
        }
    }

    /**
     *
     * Function maintains internal grouping of vehicles according to status
     *
     * @param {*} stock
     * @param {*} key
     */
    function seggregate(stock) {
        var ele = {
            PreAllocation: [],
            // Allocated: [],
            Unallocated: [],
        };

        stock.forEach((v) => {
            if (v.allocated) {
                ele['PreAllocation'].push(v);
            } else {
                ele['Unallocated'].push(v);
            }
        });

        return ele;
    }

    const columns_preallocated = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Vehicle',
            render: (stock) => {
                var variant;
                variant = stock['Variant'];
                return <div>{stock['Variant']}</div>;
            },
            key: 'Variant',
        },
        {
            title: 'Color',
            render: (stock) => {
                return <div>{stock['Exterior Color Name']}</div>;
            },
            key: 'Color',
        },
        {
            title: 'Allocation Age',
            render: (stock) => {
                return (
                    <div>
                        <div style={{ fontSize: '10px' }}>{stock.allocated && moment(stock.allocated['created_time']).format('DD/MM/YYYY')}</div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>
                            {stock.allocated && moment().diff(moment(stock.allocated['created_time']), 'days') + ' day'}
                        </div>
                    </div>
                );
            },
            key: 'Allocation Age',
        },
        {
            title: 'Dealer Code',
            render: (stock) => {
                return <div>{stock['dealerCode']}</div>;
            },
            key: 'Dealer Code',
        },
        {
            title: 'Vin Number',
            render: (stock) => {
                return <div>{stock['Vin Number']}</div>;
            },
            key: 'Number',
        },
        {
            title: 'Customer Name',
            render: (stock) => {
                return (
                    <div size="middle">
                        <Link style={{ fontSize: '12px' }} to={`/requests/${stock.allocated.requestId}`}>
                            {stock.customer ? stock.customer.customerName : 'Missing'}
                        </Link>
                    </div>
                );
            },
            key: 'Customer Name',
        },
    ];

    const columns_unallocated = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Vehicle',
            render: (stock) => {
                var variant;
                variant = stock['Variant'];
                return <div>{stock['Variant']}</div>;
            },
            key: 'Variant',
        },
        {
            title: 'Color',
            render: (stock) => {
                return <div>{stock['Exterior Color Name']}</div>;
            },
            key: 'Color',
        },
        {
            title: 'Dealer Code',
            render: (stock) => {
                return <div>{stock['dealerCode']}</div>;
            },
            key: 'Dealer Code',
        },
        {
            title: 'Vin',
            render: (stock) => {
                return <div>{stock['Vin Number']}</div>;
            },
            key: 'Number',
        },
    ];

    function changeView(value, e, key) {
        view[key] = value;

        setView({ ...view });

        e.stopPropagation();
    }

    const toggleView = (item) => (
        <Switch
            defaultChecked={view}
            onChange={(value, e) => changeView(value, e, item)}
            checked={view[item]}
            checkedChildren={<OrderedListOutlined />}
            unCheckedChildren={<PicCenterOutlined />}
        />
    );

    let messages = {
        Unallocated: 'Free Stock Vehicles Below , Ensure frequent follow up with customers for conversion to available models.',
        PreAllocation: 'Current Allocations. Follow up with customer and team to ensuire quick retail and delivery.',
    };

    let columns = {
        PreAllocation: columns_preallocated,
        Unallocated: columns_unallocated,
    };

    return (
        <div className="current-stock">
            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <div>
                    <div className="page-header">
                        {stock.lastUpdated && stock.lastUpdated.created_by ? (
                            <p>
                                <small>
                                    Last Updated by {stock.lastUpdated.created_by.name} :{' '}
                                    {`${moment(stock.lastUpdated.created_time).format('dddd, MMMM Do YYYY, h:mm:ss a')}`},{' '}
                                    <Text type="danger">{`${moment(stock.lastUpdated.created_time).fromNow()}`}</Text>
                                </small>
                            </p>
                        ) : null}

                        <div className="action">
                            <Button onClick={loadData} type="secondary" size={'small'}>
                                Refresh
                            </Button>
                        </div>
                    </div>

                    <Collapse className="" ghost>
                        {Object.keys(stock.vehicles).map((model, index) => {
                            var ele = stock.vehicles[model];

                            // Seggregation done for allocation status1
                            var list = seggregate(ele);

                            return (
                                <Panel
                                    key={index}
                                    showArrow={false}
                                    className="collapse-parent"
                                    header={VehicleThumbnail({ model, ele, list, user })}
                                >
                                    <Collapse className="table_view">
                                        {Object.keys(list).map((item, index) => {
                                            console.log(item);
                                            var cars = list[item];

                                            var variants = groupBy(cars, 'Variant');

                                            return (
                                                <Panel key={index} header={`${item} (${cars.length})`} extra={toggleView(item)}>
                                                    <Alert message={messages[item]} type="info" showIcon />

                                                    {view[item] ? (
                                                        <Table pagination={false} dataSource={cars} scroll={{ x: true }} columns={columns[item]} />
                                                    ) : (
                                                        <Collapse className="">
                                                            {Object.keys(variants)
                                                                .sort()
                                                                .map((variant, index) => {
                                                                    var colors = groupBy(variants[variant], 'Exterior Color Name');

                                                                    var arr = Object.keys(colors).map((c) => {
                                                                        return {
                                                                            color: c,
                                                                            vehicles: colors[c],
                                                                        };
                                                                    });

                                                                    return (
                                                                        <Panel key={index} header={`${variant} (${variants[variant].length})`}>
                                                                            <List
                                                                                size="small"
                                                                                dataSource={arr}
                                                                                renderItem={(car) => (
                                                                                    <VehicleCard
                                                                                        variant={variant}
                                                                                        city={url}
                                                                                        car={car}
                                                                                        model={model}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Panel>
                                                                    );
                                                                })}
                                                        </Collapse>
                                                    )}
                                                    {/* <Collapse className="">
                                                   
                                                        {Object.keys(variants)
                                                            .sort()
                                                            .map((variant, index) => {
                                                                var colors = groupBy(variants[variant], 'Exterior Color Name');

                                                                var arr = Object.keys(colors).map((c) => {
                                                                    return {
                                                                        color: c,
                                                                        vehicles: colors[c],
                                                                    };
                                                                });
                                  
                                                                return (

                                                              
                                                                    <Panel key={index} header={`${variant} (${variants[variant].length})`}>
                                                                        <List
                                                                            size="small"
                                                                            dataSource={arr}
                                                                            renderItem={(car) => (
                                                                                <VehicleCard variant={variant} city={url} car={car} model={model} />
                                                                            )}
                                                                        />
                                                                        
                                                                    </Panel>
                                                                );
                                                                

                                                            })}
                                                           
                                                    </Collapse> */}
                                                </Panel>
                                            );
                                        })}
                                    </Collapse>
                                </Panel>
                            );
                        })}
                    </Collapse>
                </div>
            )}
        </div>
    );
}

function VehicleThumbnail({ model, ele, list, user }) {
    return (
        <div className="vehicle-header">
    <img src={images[model]} alt="vehicle-img" />

    <div className="score-board">
        {user.role === 'kec' ? null : (
            <div span={4} className="score-card">
                <Statistic title={model} value={ele.length} style={{ marginRight: '20px' }} />
            </div>
        )}

        {Object.keys(list).map((index, key) => {
            return (
                <div key={key} span={4} className="score-card">
                    <Statistic title={index} value={list[index].length} style={{ marginRight: '20px' }} />
                </div>
            );
        })}
    </div>
</div>

    );
}

/**
 * Vehicle Redirection Card
 *
 * @param {*} param0
 * @returns
 */
function VehicleCard({ car, variant, model, city }) {
    return (
        <Link className="stock-item" to={`${city}/preference?model=${model}&variant=${variant}&color=${car['color']}`}>
            <div className="left">
                <Title level={4}>
                    {car['color']}
                    <span>{` [ ${car['vehicles'].length} ] `}</span>
                </Title>

                <small className="status">
                    <Badge status={badgeColor[car['Stock Status']]} />

                    {car['Stock Location'] || car['Stock Status']}
                </small>
            </div>
        </Link>
    );
}
