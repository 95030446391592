import Base from './base';

class Departments extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'departments';
    }

    get path() {
        return `departments`;
    }

    // get = (queries) => {
    //     return this.getMethod(queries).then((result) => {
    //         return result;
    //     });
    // };
}

export default Departments;
