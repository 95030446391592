/**
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState, useContext } from 'react';

import { message } from 'antd';

import { withRouter } from 'react-router-dom';

// import './form-creator.scss'

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from './../../../../utils/firebase.utils';

import FormCreator from './../form-creator/form-creator';

function RequestForm({ booking, step, model, formContent, modelIndex, callback, department }) {
    const [content, setContent] = useState(formContent);

    const { user } = useContext(GlobalContext);

    const onDataCollection = (values) => {
        // If there is a customer validation that needs
        // to be done , we have to handle it here
        //
        if (step.formValidation) {
            if (step.formValidation.validation(formContent)) {
                message.error(step.formValidation.message);

                return new Promise((resolve, reject) => {
                    resolve(true);
                });
            }
        }

        let params = {
            [model]: {
                ...content[model],
                ...values,
            },
        };

        if (step.post_status) {
            params[model].status = step.post_status;
        }

        if (step.extraParams) {
            params = {
                ...params,
                // Note extra Params will override every previous value if the same key is used
                ...step.extraParams(content, params[model]),
            };
        }

        // Building the notification object

        if (step.notification) {
            params.lastEvent = {
                title: step.notification(content, user, values).title,
                body: step.notification(content, user, values).body,
            };
        }

        return FirebaseUtils.updateRequestGeneric(content.id, params, `${model} update :`, values.remarks, model).then(() => {
            message.success(`${model} details updated`);

            if (step.caption === 'Document Verification') {
                message.success('Successfully forwarded for sales manager verification to sm ');
            }
            if (step.caption === 'Billing Approval') {
                message.success('Successfully forwarded for final verification');
            }

            // Custom Actions to be done on submit

            if (step.onSubmit) {
                step.onSubmit &&
                    step.onSubmit(content, params[model], booking).then(() => {
                        callback(params, values);
                    });
            } else {
                callback(params, values);
            }
        });
    };

    return (
        <section className="request-form">
            {step.fields && step.fields.length ? (
                <FormCreator
                    config={step}
                    formContent={formContent[model]}
                    modelIndex={modelIndex}
                    model={model}
                    onSubmit={onDataCollection}
                    department={department}
                />
            ) : null}
        </section>
    );
}

export default withRouter(RequestForm);
