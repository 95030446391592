import React, { useState, useEffect, useContext } from 'react';

import { Row, Button, Tag, Badge, DatePicker, Space, Alert, Table, Switch } from 'antd';

import { Tabs } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import './delivery-list.scss';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import moment from 'moment';
import QRSearch from '../../../pdi/components/pdi-list/qr-scanner';

import { ExportReactCSV } from '../../../common/resources/generic/generic-list/ExportReactCSV';

const { TabPane } = Tabs;

export default function DeliverySheduledCustomers({ step, history }) {
    const { user } = useContext(GlobalContext);

    const [allocations, setAllocations] = useState([]);

    const [loader, setLoader] = useState(true);

    const [expRecord, setExptRecords] = useState();

    const [view, setView] = useState(true);

    useEffect(() => {
        getData();
    }, [user]);

    useEffect(() => {
        getExportData(allocations, step.columns);
    }, [allocations]);

    console.log(allocations);

    function getData() {
        setLoader(true);
        var queries;
        const { locations } = user;
        if (typeof step.queries === 'function') {
            queries = step.queries({ user });
        } else {
            queries = step.queries;
        }
        if (step.model === 'stock_transfers') {
            FirebaseUtils.getRecords(locations[0], queries, step.model).then((vehicles) => {
                setLoader(false);

                console.log(vehicles);

                setAllocations(vehicles[step.model]);
            });
        } else {
            FirebaseUtils.getRequests(locations[0], step.queries).then((vehicles) => {
                setLoader(false);

                console.log(vehicles);

                setAllocations(vehicles.requests);
            });
        }
    }

    function callback(key) {
        console.log(key);
    }

    //Change view
    function changeView(result) {
        setView(result);
    }

    function onChange(value) {
        step.queries.push({
            field: 'delivery.scheduled_delivery_date',
            value: moment(new Date(value._d)).startOf('day').valueOf(),
        });

        getData();
        step.queries.pop();
    }

    /**
     * This function returns the array that can be downloaded.
     * This function is maintained in firebase-core and when migrating to onedesk, it can be moved
     * @param {*} records
     * @param {*} columns
     */
    async function getExportData(records, columns) {
        let filteredColumns = columns.filter((col) => !col.disableExport);

        const arr = await Promise.all(
            records.map((row, index) => {
                let entry = {
                    'Sl No': index + 1,
                };

                filteredColumns.forEach((column, index) => {
                    if (column.exportDefinition) {
                        entry[column.title] = column.exportDefinition(row, index, index);
                    } else if (column.render) {
                        entry[column.title] = column.render(row, index, index);
                    } else if (column.dataIndex) {
                        entry[column.title] = row[column.dataIndex];
                    } else if (column.field) {
                        entry[column.title] = row[column.field];
                    } else {
                        entry[column.title] = row[column.field];
                    }
                });

                return entry;
            })
        );

        setExptRecords(arr);
    }
    
    return (
        <section className="landing">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Pending Customers" key="1">
                    <div className="list-header">
                        <p className="page-desc">{loader ? 'Loading data' : `Found ${Object.keys(allocations).length} results`}</p>

                        <div className="delivery-list">
                            <QRSearch
                                Callback={(values) => {
                                    // Perform navigation logic based on the scanned data
                                    // history.push(`/stock-outward/${locations[0]}/${values}`);
                                }}
                            />
                            <Space direction="vertical" className="datepicker">
                                <DatePicker format="DD/MM/YYYY" onChange={onChange} placeholder="Filter by date" />
                            </Space>

                            <Button onClick={getData} type="secondary" size={'small'}>
                                Refresh
                            </Button>

                            <div>{expRecord && <ExportReactCSV csvData={expRecord} />}</div>

                            <Switch defaultChecked={view} onChange={changeView} checked={view} />


                        </div>
                    </div>

                    <Row className="report-listing-wrapper">
                        {loader ? (
                            <PlaceHolder type="listing" />
                        ) : view ? (
                            <>
                                {allocations.map((report, index) => {
                                    return <step.Card step={step} vehicle={report} index={report.id} key={index} />;
                                })}
                            </>
                        ) : (
                            <>

                                <Table columns={step.columns} dataSource={allocations} />
                            </>
                        )}
                    </Row>
                </TabPane>
            </Tabs>
        </section>
    );
}
