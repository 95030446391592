import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import XLSX from 'xlsx';

import { Space, Switch, message, Table, Typography, Tag, Button, Upload, Form, Input, Select } from 'antd';
import { Menu, Dropdown, Drawer } from 'antd';

import { GlobalContext } from '../../../../Store';

import { DownOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import './manage-bookings.scss';

import { Bookings, Employees, Settings, StatusMasters } from '../../../../models';

import moment from 'moment';

import { SearchOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import { cityKeys, dealerKeys, timezone } from '../../../global-config';

const { Text, Title } = Typography;

const { Option } = Select;

var cityCode = cityKeys

export default function ManageBookings({ history, report, index }) {
    const [bucket, setBucket] = useState([]);

    const { user, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [files, setFiles] = useState([]);

    const [form] = Form.useForm();

    const [btnloading, setBtnLoading] = useState(false);

    const [result, setResults] = useState([]);

    const [location, setLocation] = useState(user.locations[0]);

    const [bookings, setBookings] = useState({});

    const [newbookings, setNewbookings] = useState({});

    const [page, setPage] = React.useState(1);

    const [view, setView] = useState(isMobile);
    
    const [diffrentDealercode, setDiffrentDealercode] = useState(false);

    const [visible, setVisible] = useState(false);

    const [status, setStatus] = useState([])

    const [diffrentDealercodeCount, setdiffrentDealercodeCount] = useState(0);  // State to count wrong location occurrences



    // const [timezone, setTimezone] = useState('');

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    const [head, setHead] = useState({});

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
        },
        {
            title: 'Customer ID',
            dataIndex: 'Customer ID',
            key: 'Customer ID',
        },
        {
            title: 'Booking No',
            dataIndex: 'Booking No',
            key: 'Booking No',
        },
        {
            title: 'Consultant Name',
            dataIndex: 'Consultant Name',
            key: 'Consultant Name',
        },
        {
            title: 'Team Leader',
            dataIndex: 'Team Leader',
            key: 'Team Leader',
        },

        {
            title: 'Customer Name',
            dataIndex: 'Name of the Customer',
            key: 'address',
        },
        {
            title: 'Phone',
            dataIndex: 'Contact Number',
            key: 'Contact Number',
        },
        {
            title: 'Booking Date',
            dataIndex: 'Booking Date',
            key: 'date',
        },
        {
            title: 'Variant',
            dataIndex: 'Variant',
            key: 'variant',
        },
        {
            title: 'Color',
            dataIndex: 'Color',
            key: 'color',
        },

        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/bookings/${text['Booking No']}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

      // Get booking Awaiting Confirmation status from status master
      async function getBookingStatus() {
        let queries = [
            {
                field: 'name',
                value: 'Awaiting Confirmation',
            },
        ];

        const result = await StatusMasters.get(queries);
        setStatus(result.status_masters);
    }

    useEffect(() => {
        getBookingStatus();
        if (user) {
            getBookings(location, options);
        }
    }, [user]);

    /**
     * Get bookings, with pagination. A limit of 20 is set
     * @param {*} location
     * @param {*} options
     */

    function getBookings(location, options = {}) {
        let params = {
            pageSize: options.pageSize,
        };

        if (options.start) {
            params.start = options.start;
        }

        if (options.end) {
            params.end = options.end;
        }

        setLoading(true);

        var queries = [
            {
                field: 'dealerCode',
                value: cityCode[location],
            },
        ];

        //order by using booking date
        var config = {
            orderBy: 'bookingDate',
            direction: 'desc',
        };

        if (options.start) {
            config = {
                ...config,
                startAt: options.start,
            };
        }

        if (options.end) {
            config = {
                ...config,
                end: options.end,
            };
        }

        if (options.pageSize) {
            config = {
                ...config,
                limit: options.pageSize,
            };
        }

        Bookings.getOrderRecord(location, queries, config).then((result) => {
            // FirebaseUtils.loadBookings(location, params).then((result) => {

            setBookings(result);

            setLoading(false);

            let b = Object.keys(result.bookings).map((booking) => {
                let b = result.bookings[booking];

                return { ...b, bookingDate: moment(b['Booking Date'], 'DD/MM/YYYY').valueOf() };
            });

            setResults(b);

            if (result.end) {
                options.end = result.end;

                head.end = result.end;

                setOptions({ ...options });

                setHead({ ...head });
            }

            bucket.push(result.start);

            setBucket(bucket);

            if (result.start) {
                options.start = result.start;

                head.start = result.start;

                setOptions({ ...options });

                setHead({ ...head });
            }
        });
    }

    /**
     * get time zone
     */
    // async function getTimeZone() {
    //     var query = [
    //         {
    //             field: 'name',
    //             value: 'timezone',
    //         },
    //     ];
    //     var tz = await Settings.get(query);

    //     setTimezone(tz.settings[0].value);
    // }

    /**
     * Load next page of data. Pagination for firebase data. 20 is kept as limit
     *
     */

    function getNextBookings(location, options = {}) {
        setLoading(true);

        let params = {
            pageSize: options.pageSize,
        };

        var queries = [
            {
                field: 'dealerCode',
                value: cityCode[location],
            },
        ];

        var config;

        config = {
            limit: options.pageSize,
            end: options.end,
            orderBy: 'bookingDate',
            direction: 'desc',
        };

        params.start = options.end;

        Bookings.getOrderRecord(location, queries, config).then((result) => {
            // FirebaseUtils.getNextBookings(location, options).then((result) => {
            setBookings(result);

            setLoading(false);

            let b = Object.keys(result.bookings).map((booking) => {
                return result.bookings[booking];
                // return { ...result.bookings[booking], requestId: request }
            });

            setResults(b);

            bucket.push(result.start);

            setBucket(bucket);

            if (result.end) {
                options.end = result.end;

                head.end = result.end;

                setOptions({ ...options });

                setHead({ ...head });
            }

            if (result.start) {
                options.start = result.start;

                head.start = result.start;

                setOptions({ ...options });

                setHead({ ...head });
            }
        });
    }

    /***
     *
     * Load previous page datas
     *
     *
     */

    function getPrevBookings(location, options = {}) {
        setLoading(true);

        let params = {
            pageSize: options.pageSize,
        };

        bucket.pop();

        params.start = bucket[bucket.length - 1];

        var queries = [
            {
                field: 'dealerCode',
                value: cityCode[location],
            },
        ];

        var config;

        config = {
            limit: options.pageSize,
            startAt: bucket[bucket.length - 1],
            orderBy: 'bookingDate',
            direction: 'desc',
        };

        Bookings.getOrderRecord(location, queries, config).then((result) => {
            // FirebaseUtils.getPrevBookings(location, params).then((result) => {
            setBookings(result);

            setLoading(false);

            let b = Object.keys(result.bookings).map((booking) => {
                return result.bookings[booking];
                // return { ...result.bookings[booking], requestId: request }
            });

            setResults(b);

            if (result.end) {
                options.end = result.end;

                head.end = result.end;

                setOptions({ ...options });

                setHead({ ...head });
            }

            if (result.start) {
                options.start = result.start;

                head.start = result.start;

                setOptions({ ...options });

                setHead({ ...head });
            }
        });
    }

    /**
     *
     * Refresh function
     */

    function refresh() {
        let params = {
            pageSize: options.pageSize,
        };

        if (head.start) {
            params.start = head.start;
        }

        getBookings(location, params);
    }

    function handleFile(f) {
        // var files = event.target.files, f = files[0];

        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {
            const bstr = e.target.result;

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws, { header: 0 });

            // uploadPastBookings(data);

            processData(data);
        };

        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
    }

    /**
     *
     * Prepare the data according to the configuration
     *
     * @param {*} data
     */
    function processData(data) {
        var modified = [];
        let count = 0;  // Initialize counter for wrong location


        var columns = data[0];

        //validation for Bookings upload(only can upload user exact location's bookings)

        data.forEach((element, index) => {
            if (cityCode[location] === element['Dealer Code']) {

                var key;

                // At some cases we have to derive the index from two columns hence we use function
                key = findIndex(element);

                if (typeof key !== 'undefined') {
                    // Trim to avoid empty spaces
                    key = key.trim();
                }

                // delete element['Booking No']
                modified[key] = {
                    // Whatever is mentioned in the extra Params
                    ...modified[key],

                    // This is the new element
                    ...element,
                };

                modified[key] = {
                    ...modified[key],
                    'Booking No': key,
                };
            }
            else {

            count++;  // Increment wrong location counter
            setDiffrentDealercode(true)

            }

        });

        setdiffrentDealercodeCount(count)
        var b = {};

        //Each booking from imported bookings are taken and checked if a similar document is in database.
        //If there is a similar data the booking is considered as a duplicate

        Promise.all(
            Object.keys(modified).map(async (booking) => {
                if (modified[booking]['Booking No']) {
                    b[modified[booking]['Booking No']] = modified[booking];
                }

                await Bookings.getRecord(booking).then((res) => {
                    if (res && res['Booking No']) {
                        modified[booking].duplicate = true;
                        return modified[booking];
                    }
                });
            })
        ).then(() => {
            var queries = [
                {
                    field: 'dealerCode',
                    value: cityCode[location],
                },
            ];
            console.log(modified);

            return setNewbookings(modified);
        });

        // //Compare data with previous data to check for duplicate
        // Bookings.get(queries).then((res) => {
        //     console.log(res.bookings);

        //     var oldbooking = []
        //     res.bookings.map((ele) => {
        //         oldbooking[ele.id] = ele
        //     })

        //     return checkExistingBookings(oldbooking, modified)

        //     // const previousKeys = Object.keys(res);

        //     // const latestKeys = Object.keys(modified)

        //     // latestKeys.forEach((key) => {
        //     //     if (previousKeys.indexOf(key) !== -1) {
        //     //         console.log(modified)
        //     //         modified[key].duplicate = true;
        //     //     }
        //     // });
        //     // setNewbookings(modified)
        // });
    }

    /**
     *
     * Derive the index of the table from element
     *
     * @param {]} element
     */
    function findIndex(element) {
        var key;

        key = cityCode[location] + element['Booking No'];

        return key;
    }

    //Check for duplicate enquiries
    async function checkExistingBookings(bookings, newBookings) {
        const previousKeys = Object.keys(bookings);

        const latestKeys = Object.keys(newBookings);

        latestKeys.forEach((key) => {
            if (previousKeys.indexOf(key) !== -1) {
                newBookings[key].duplicate = true;
            }
        });

        return setNewbookings(newBookings);
    }

    /**
     * For approval of upload all datas are written to bookings
     * If it is duplicate booking only imported datas are merged.
     * dtas like billing.status and delivery.status are kept as such
     * @param {*} data
     * @returns
     */

    async function uploadPastBookings(data) {
        setBtnLoading(true);

        let b = {};

        let duplicate = 0;

        await Promise.all(
            Object.keys(newbookings).map(async (no) => {
                if (newbookings[no] && newbookings[no].duplicate) {
                    duplicate++;

                    b[no] = {
                        ...newbookings[no],
                        'Contact Number': newbookings[no]['Contact Number'].toString(), 
                        deleted_at:null,
                        deleted_by:null
                    };
                    
                } else {
                    return await getEmployyeMatch(newbookings[no]['Consultant Name'],newbookings[no]['Dealer Code']).then((employee) => {
                        b[no] = {
                            // Here we need to specify any by default fields that we need to add
                            ...newbookings[no],
                            'Contact Number': newbookings[no]['Contact Number'].toString(),
                            'Consultant Name': newbookings[no]['Consultant Name'].trim(),
                            actual_billing: {
                                status:'pending'
                            },
                            billing: {
                                status: 'pending',
                            },
                            delivery: {
                                status: 'pending',
                            },
                            current:{
                                currentStatus:status[0].name,
                                currentStatus_id:status[0].id
                            },
                            bookingDate: moment.tz(newbookings[no]['Booking Date'], 'DD/MM/YYYY', timezone).valueOf(),
                            bookingId: newbookings[no]['Booking No'].substr(5),
                            dealerCode: newbookings[no]['Dealer Code'],
                            booking_status: 'prebooking',
                            isCancelled:false,
                            created_by: user.id,
                            deleted_at:null,
                            deleted_by:null
                        };

                        if (employee.length) {
                            b[no] = {
                                ...b[no],
                                consultant_id: employee[0].id,
                                teamleader_id: employee[0].header_id,
                            };
                        }
                    });
                }

                return b[no];

                // })
            })
        );

        console.log(b);

        let total = Object.keys(b).length;

        let count = total - duplicate;

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        Object.keys(b).forEach(async (key) => {
            var docRef = Bookings.getRecordReference(key);

            await batch.set(docRef, b[key], { merge: true });
        });

        await Bookings.addUploadsDetails(user);

        return await batch.commit().then(() => {
            setBtnLoading(false);
            message.success(`${total} ${'bookings'} updated`);

            // checkng wrong uploads and display count
            if(diffrentDealercode){
                message.error(`${diffrentDealercodeCount} ${'records are'} not uploaded because of different dealercode`);

            }
            setNewbookings({});
        });
    }

    /**
     * Get data of the kec to which booking is asssigned to store consultnat_id and teamleader_id
     * @param {*} employee
     * @returns
     */

    function getEmployyeMatch(employee, dealerCode) {
        var queries = [
            {
                field: 'dms_name',
                value: employee.trim(),
            },
            {
                field: 'branch',
                value: dealerKeys[dealerCode]
            },

        ];
        console.log(queries)

        return Employees.get(queries).then((result) => {
            return result.employees;
        });
    }

    const uploadProps = {
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            setFiles([...files, file]);

            return false;
        },
        files,
    };

    function changeView(result) {
        setView(result);
    }

    const SheetJSFT = [
        'xlsx',
        'xlsb',
        'xlsm',
        'xls',
        'xml',
        'csv',
        'txt',
        'ods',
        'fods',
        'uos',
        'sylk',
        'dif',
        'dbf',
        'prn',
        'qpw',
        '123',
        'wb*',
        'wq*',
        'html',
        'htm',
    ]
        .map(function (x) {
            return '.' + x;
        })
        .join(',');

    const menu = () => {
        return (
            <Menu>
                {user.locations.map((location, index) => (
                    <Menu.Item key={index}>
                        <span
                            onClick={() => {
                                setLocation(location);

                                options.start = null;
                                options.end = null;

                                // options = {
                                //     ...options,
                                //     ...{
                                //         start: null,
                                //         end: null
                                //     }
                                // }

                                setOptions(options);

                                getBookings(location, options);
                            }}
                        >
                            {location}
                        </span>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    function handleTableChange(pagination, filters, sorter) {
        console.log(pagination, filters, sorter);

        setPage(pagination.current);

        // options.filters = filters;
        options.page = pagination.current;

        setOptions({ ...options });

        getBookings(location, options);

        // if (f.length) {

        //     getBookings(location, options);

        // }
    }

    // function onSearch(values) {
    //     console.log(values);

    //     let params = {
    //         field: values.searchBy,
    //         value: values.search_key,
    //     };

    //     setLoading(true);

    //     setVisible(false);

    //     FirebaseUtils.searchBookings(location, params).then((result) => {
    //         console.log(result);

    //         setBookings(result);

    //         setLoading(false);

    //         let b = Object.keys(result.bookings).map((booking) => {
    //             return result.bookings[booking];
    //             // return { ...result.bookings[booking], requestId: request }
    //         });

    //         setResults(b);
    //     });
    // }

    function resetPage() {
        form.resetFields();

        getBookings(location, options);
    }

    let start = (options.page - 1) * options.pageSize + 1;
    let end = options.page * options.pageSize > bookings.size ? bookings.size : options.page * options.pageSize;

    return (
        <div className="manage-bookings listing">
            <div className="page-header">
                <div className="left">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Title level={4}>
                            <span>{location}</span> Bookings <DownOutlined />
                        </Title>
                    </Dropdown>

                    <div>
                        {bookings.lastUpdated && bookings.lastUpdated.timestamp ? (
                            <p>
                                <small>
                                    Last Updated :{' '}
                                    <Text type="danger">{`${moment(bookings.lastUpdated.timestamp, 'DD/MM/YYYY HH:mm').fromNow()}`}</Text>
                                </small>
                            </p>
                        ) : null}
                    </div>
                </div>

                <div className="table-hint right">
                    {/* <Switch
                        defaultChecked
                        onChange={changeView}
                        checked={view}
                        checkedChildren={<OrderedListOutlined />}
                        unCheckedChildren={<PicCenterOutlined />}
                    /> */}

                    <div className="page-actions">
                        {loading ? <Text type="secondary">Loading Bookings</Text> : <Text type="secondary">{/* {`${start}-${end}`} */}</Text>}

                        <div className="button-container">
                            {/* {
                        isMobile
                        ?null: */}
                            <>
                                <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                    <Button size={'small'} icon={<UploadOutlined />}>
                                        Select File
                                    </Button>
                                </Upload>
                            </>
                            {/* } */}
                            {/* 
                            <Button onClick={showDrawer} type="secondary" size={'small'}>
                                <SearchOutlined />
                            </Button> */}

                            <Button onClick={refresh} type="secondary" size={'small'}>
                                <ReloadOutlined />
                            </Button>

                            <Button
                                disabled={options.page <= 1}
                                size="small"
                                onClick={() => {
                                    options.page--;

                                    setOptions(options);

                                    getPrevBookings(location, options);

                                    // getBookings(location, options, true);
                                }}
                            >
                                <CaretLeftOutlined />
                            </Button>

                            <Button
                                size="small"
                                onClick={() => {
                                    getNextBookings(location, options);

                                    options.page++;

                                    setOptions(options);
                                }}
                            >
                                <CaretRightOutlined />
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Search Features */}
                {/* <Collapse ghost>
                    <Panel header="Search" key="1">
                        <p>
                            Helloi there this is for search
                        </p>
                    </Panel>
                </Collapse> */}
                {/* Search Features Ends */}
            </div>

            {Object.keys(newbookings).length ? (
                <div className="file-review">
                    <div className="action-header">
                        <h4>{Object.keys(newbookings).length} Bookings</h4>
                        <p>{Object.keys(newbookings).filter((entry) => newbookings[entry].duplicate).length} are duplicate.</p>
                        <Button loading={btnloading} onClick={uploadPastBookings} key="3">
                            Approve
                        </Button>
                        ,
                        <Button
                            key="2"
                            onClick={() => {
                                setNewbookings({});
                            }}
                        >
                            Decline
                        </Button>
                    </div>
                </div>
            ) : null}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <>
                        {/* {!view ? ( */}
                        <Table
                            loading={loading}
                            size="small"
                            // scroll={{ x: true, y: 750 }}

                            // scroll={{ x: true, y: 750 }}
                            rowKey={(record) => record.index}
                            dataSource={result}
                            columns={columns}
                            onChange={handleTableChange}
                            pagination={false}
                        ></Table>
                        {/* ) : (
                            <CardList url={location} data={result} />
                        )} */}
                    </>

                    <div></div>
                </>
            )}

            {/* <Drawer placement="left" closable={false} onClose={onClose} visible={visible} className="search-drawer">
                <div className="intro">
                    <Title level={4}>Search</Title>
                </div> */}

            {/* <Form
                    // {...formItemLayout}
                    // layout={isMobile?'':'inline'}
                    form={form}
                    onFinish={onSearch}
                // initialValues={{ layout: formLayout }}
                // onValuesChange={onFormLayoutChange}
                > */}
            {/* <Form.Item name="searchBy" label="Search By" rules={[{ required: true }]}>
                        <Select
                            placeholder="Select Option"
                            //   onChange={onGenderChange}
                            allowClear
                        >
                            <Option value="Variant">Variant</Option>

                            <Option value="Contact Number">Contact Number</Option>
                            <Option value="Team Leader">Team Leader</Option>
                            <Option value="Consultant Name">Consultant Name</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="search_key">
                        <Input placeholder="Search By" />
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Submit
                        </Button>
                    </Form.Item>

                    <Button
                        onClick={() => {
                            resetPage();
                        }}
                        type="secondary"
                    >
                        Reset
                    </Button>
                </Form> */}
            {/* </Drawer> */}
        </div>
    );
}

function CardList({ data, url }) {
    return data.map((report, index) => {
        return <BookingCard city={url} booking={report} index={index} key={index} />;
    });
}

function BookingCard({ city, booking = {} }) {
    return (
        <Link className="booking-card" to={`${city}/booking/${booking['Booking No']}`}>
            <div className="card">
                <h2 className="title amount ">{booking['Name of the Customer']}</h2>
                <h4 className="title">{booking['Contact Number']}</h4>
                <h3 className="title ">{booking['Variant']}</h3>
                <div>
                    <small> {booking['Color']}</small>
                </div>
                <h4>{booking['Booking No']}</h4>
                <p className="address">
                    Booked on {booking['Booking Date']}
                    <Tag color="magenta">{booking.status || 'Initial'}</Tag>
                </p>
                Committed Delivery Date {booking['Committed Delivery Date']}
            </div>
        </Link>
    );
}
