/**
 * All the models in the application
 */

import Document from './documents';

import User from './users';

import Bill from './bills';

import BillDetail from './bill_details';

import CartModel from './cart';

import Request from './requests';

import Booking from './bookings';

import Tax from './taxes';

import DocumentLog from './document_logs';

import Accessory from './accessories';

import Item from './items';

import Cancellation from './cancelations';

import Upload from './uploads';

import Designation from './designations';

// import Employee from './employee';

import Employee from './../modules/employees/employees';

import Vehicle from './vehicles';

import Allocation from './allocations';

import PackageDetail from './package-details';

import Comment from './comments';

import StockTransfer from './stock_transfers';

import Enquiry from './enquirys';
import Receipt from './receipts';

import StockTransaction from './stock-transactions';

import Sale from './sales';
import Department from './departments';

import InvoiceNumber from './invoice_numbers';

import FinancialYear from './financial_years';

import Payout from './payouts';

import Branch from './branches';

import FinanceBank from './finance_banks';

import FinanceBranch from './finance_branches';

import Setting from './settings';

import Billtype from './bill_types';

import PricingModel from './pricing';

import Stock from './stocks';

import Payment from './payments';

import Purchase from './purchases';

import Transfer from './dms_transfers';

import CustomerEntry from './customer_entries';

import BankCredit from './bank_credits';

import StockPoint from './stock_points';

import StatusMaster from './status_master';

import StatusLog from './status_logs';

import Staff from './staffs';

import LookUpType from './lookup_types';

import LookUpValue from './lookup_values';



const Documents = new Document();

const Users = new User();

const Bills = new Bill();

const Cart = new CartModel();

const BillDetails = new BillDetail();

const Requests = new Request();

const Bookings = new Booking();

const Taxes = new Tax();

const Accessories = new Accessory();

const Items = new Item();

const Uploads = new Upload();

const Vehicles = new Vehicle();

const DocumentLogs = new DocumentLog();

const Cancellations = new Cancellation();

const Comments = new Comment();

const StockTransfers = new StockTransfer();

const StockTransactions = new StockTransaction();

const Allocations = new Allocation();

const Designations = new Designation();

const Employees = new Employee();

const Enquirys = new Enquiry();

const Receipts = new Receipt();

const Payouts = new Payout();

// const BillDetails = new BillDetail();

const BankCredits = new BankCredit();

const Sales = new Sale();

const Departments = new Department();

const InvoiceNumbers = new InvoiceNumber();

const FinancialYears = new FinancialYear();

const Branches = new Branch();

const Settings = new Setting();

const PackageDetails = new PackageDetail();

const Billtypes = new Billtype();

const Pricing = new PricingModel();

const Stocks = new Stock();

const Payments = new Payment();

const Purchases = new Purchase();

const Transfers = new Transfer();

const FinanceBanks = new FinanceBank();

const FinanceBranches = new FinanceBranch();
const CustomerEntries = new CustomerEntry();


// Status Log Implementation
const StockPoints = new StockPoint();

const Staffs = new Staff();

const StatusLogs = new StatusLog();
// Status Log Implementation Ends

const StatusMasters = new StatusMaster();

const LookUpTypes =new LookUpType()

const LookUpValues = new LookUpValue()


export {
    Taxes,
    Staffs,
    Documents,
    Users,
    Bills,
    BillDetails,
    Cart,
    Requests,
    Bookings,
    Accessories,
    Items,
    Uploads,
    Vehicles,
    Comments,
    StockTransfers,
    StockTransactions,
    DocumentLogs,
    Allocations,
    Cancellations,
    Departments,
    InvoiceNumbers,
    Branches,
    FinancialYears,
    Settings,
    PackageDetails,
    Designations,
    Employees,
    Billtypes,
    Pricing,
    Sales,
    Receipts,
    Enquirys,
    Payments,
    Stocks,
    Purchases,
    Transfers,
    Payouts,
    FinanceBanks,
    FinanceBranches,
    CustomerEntries,
    BankCredits,

    // Status Log Implementation
    StockPoints,
    StatusLogs,
    StatusMasters,
    LookUpTypes,
    LookUpValues
};
