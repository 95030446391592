import Base from './base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import FirebaseUtils from '../utils/firebase.utils';

import moment from 'moment';

// import CollectionAdd from './../modules/accounts/components/collection-add/collection-add';

import { Users } from './index';

class Allocation extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get filterDate() {
        return true;
    }

    get getEndpoint() {
        return 'allocations';
    }

    get path() {
        return `allocations`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `allocation`;
    }

    get columns() {
        return [];
    }
}

export default Allocation;
