/**
 * @author Nihala Dilshi
 */

import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';


export default function VehicleTracker({ record }) {

    const [viewButton, setViewButton] = useState(true);

    const [location, setLocation] = useState([]);

    const [vehicleTracking, setVehicleTracking] = useState(false);
    /**
     *to show modal for vehicle current location
     * @param {*} value
     * @returns
     */
    function VehicleLocation({ location }) {
        return (
            <>
                <h2>Current location:</h2>
                <p> {location}</p>
            </>
        )
    }

    /**
   * Get the current location of vehicle by tracking_number
   */
    async function fetchVehicleLocation() {
        const url = ` https://trakzee.uffizio.com/webservice?token=getLiveData&user=ashique@soxo.in&pass=Soxo@123&format=json`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (data?.root?.VehicleData) {

                // store data into localstoarge to solve  call exceeded the limit of one minute one call
                localStorage.setItem('data', JSON.stringify(data.root.VehicleData))
                return (data?.root?.VehicleData)
            }

            else {
                return false
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }
    /**
    * Get the current location of vehicle by tracking_number
    */
    async function getVehicleLocation(record) {

        const data = await fetchVehicleLocation();

        if (data) {
            setVehicleTracking(true);

            // matching data by tracking_number
            data.map((loc) => {
                if (loc.Vehicle_Name === record.tracking_number) {
                    setLocation(loc.Location)
                }
            })
        } else {

            setVehicleTracking(true);
            let data = JSON.parse(localStorage.getItem('data'));

            // matching data by tracking_number
            data.map((loc) => {
                if (loc.Vehicle_Name === record.tracking_number) {
                    setLocation(loc.Location)

                }
            })

        }

    }
    return (
        <div className='editable-cell'>
            <div>
                {viewButton ? (
                    // button to connect api
                    <Button
                        onClick={() => {
                            getVehicleLocation(record);
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Vehicle Location
                    </Button>
                ) : null}
            </div>
            <div>
                {vehicleTracking ? (<>
                    <Modal
                        destroyOnClose={true}
                        footer={null}
                        title="Vehicle  Location"
                        visible={vehicleTracking}
                        width={'30%'}
                        okText="Okay"
                        onOk={() => {
                            setVehicleTracking(false);
                        }}
                        onCancel={() => {
                            setVehicleTracking(false);
                        }}
                    >
                        <VehicleLocation location={location} />

                    </Modal>

                </>) : null}

            </div>
        </div>
    );
};