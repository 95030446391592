
import React, { useState, useEffect, useContext } from "react";

import { Row, Button, Tag, Badge } from "antd";

import { Link } from 'react-router-dom'


import GlobalHeader from '../../../../components/global-header/global-header';

import { Tabs, Select } from 'antd';
import PlaceHolder from "../../../../components/ui_elements/PlaceHolder";

import "./customer-list.scss";

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

const { Option } = Select;

const { TabPane } = Tabs;


const documentOptions = [
    {
        label: 'Data Collected',
        value: 'dataCollected'
    },
    {
        label: 'Login',
        value: 'login'
    },
    {
        label: 'Payment Pending',
        value: 'pendingPayment'
    },

    {
        label: 'Payment Done',
        value: 'pendingDone'
    },

]


export default function TPCustomerList({ step }) {

    const { user } = useContext(GlobalContext);

    const [filter, setFilter] = useState('all');

    const [allocations, setAllocations] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        getData(filter);

    }, [user])

    function getData(filter) {

        setLoader(true);

        const { locations } = user;

        FirebaseUtils.getRequests(locations[0], step.queries).then((vehicles) => {

            setLoader(false);

            console.log(vehicles);

            setAllocations(vehicles.requests);

        });

    }

    function callback(key) {
        console.log(key);
    }


    const operations = <Select defaultValue="All" style={{ width: 220 }} onChange={(val) => {

        setFilter(val);

        console.log(val);


        getData(val);
    }}>

        <Option value={'all'}>
            All
        </Option>

        {documentOptions.map((doc) => <Option value={doc.value}>
            {doc.label}
        </Option>)}

    </Select>;


    return (
        <section className="customer-list">

            <Tabs defaultActiveKey="1" onChange={callback} tabBarExtraContent={operations}>
                <TabPane tab="Pending Customers" key="1">

                    <div className="list-header">

                        <p className="page-desc">
                            {loader ? 'Loading data' : `Found ${Object.keys(allocations).length} results`}
                        </p>

                        <div>
                            <Button onClick={getData} type="secondary" size={'small'}>Refresh</Button>


                        </div>

                    </div>

                    <Row className="report-listing-wrapper">
                        {
                            loader ? (
                                <PlaceHolder type="listing" />
                            ) : (
                                    <>
                                        {
                                            (allocations).map((request, index) => {
                                                return <step.Card step={step} vehicle={request} index={request.id} key={index} />
                                            })
                                        }
                                    </>
                                )
                        }
                    </Row>
                </TabPane>

            </Tabs>

        </section>
    );
}
