import React, { useEffect, useState, Fragment, useContext } from "react";

import { Button, Row, Tag, Form, Input, message, Card, Steps } from 'antd';

import { Link } from 'react-router-dom'

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder'


import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import RequestForm from './../../../common/resources/request-form/request-form';

import RecordForm from './../../../common/resources/record-form/record-form';

import FormCreator from './../../../common/resources/form-creator/form-creator';
import { StockPoints, StockTransactions } from "../../../../models";

// import './pdi-form.scss';

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }

    },
};

export default function VehicleDetail({ match, step, history }) {



    const { user } = useContext(GlobalContext);

    const city = user.locations[0];

    const [vehicle, setVehicle] = useState([]);

    const { params } = match;

    const { id } = params;

    const [loader, setLoader] = useState(true)

    useEffect(() => {

        loadData();

    }, [])

    


    function loadData() {

        setLoader(true);

        FirebaseUtils.getVehicle(id).then((vehicle) => {


            setVehicle(vehicle);

            setLoader(false);

        });
    }

    const onSubmit = async (values) => {
        // if (step.formValidation) {
        //     if (step.formValidation.validation(formContent)) {
        //         message.error(step.formValidation.message);

        //         return new Promise((resolve, reject) => {
        //             resolve(true);
        //         });
        //     }
        // }

        let params = {
            [step.model]: {
                ...vehicle[step.model],
                ...values
            }
        };

        if (step.post_status) {
            params[step.model].status = step.post_status;
        }

        if (step.extraParams) {
            params = {
                ...params,
                // Note extra Params will override every previous value if the same key is used
                ...step.extraParams(vehicle, params[step.model])
            };
        }

        // return FirebaseUtils.updateListing('vehicles', vehicle['Vin Number'], params).then(() => {
        //     message.success('Successfully updated PDI. ');
        //     history.goBack();
        // })
        await StockTransactions.UpdatePDI(vehicle['Vin Number'], params).then(async() =>{
            message.success('Successfully updated PDI. ');
            history.goBack();
        }) .catch((error) => {
            console.log(error);
        })
       


    }

    return (
        <div className="review-request-detail">

            {/* <Header onBack={() => props.history.goBack()} /> */}

            {
                loader ?
                    <PlaceHolder type="report" />
                    :
                    <Fragment>
                        <>
                            <div className="about-us page-content">
                                <h1 className="page-heading">
                                    Update Vehicle
                                </h1>
                            </div>

                            <p>
                                Update any necessary information and see the status of your request below
                            </p>
                            <div gutter={0} className="detail-wrapper">

                                <div className="detail-element">

                                    <Row span={12}>

                                        <Row span={12}>
                                            <h3>Vin Number</h3>
                                        </Row>

                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title amount">{vehicle['Vin Number']} </h2>
                                    </Row>

                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Variant</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{vehicle['Model']}</span>
                                    </Row>
                                </div>


                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Price </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{vehicle['Basic Price']}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">

                                    <Row span={12}>
                                        <h3>Variant </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{vehicle['Variant']}</span>
                                    </Row>

                                </div>

                                <div className="detail-element">

                                    <Row span={12}>
                                        <h3>Color</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{vehicle['Exterior Color Name']}</span>
                                    </Row>

                                </div>


                                {/* <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Consultant :</h3>
                                    </Row>
                                    <Row span={12}>

                                        <span className="">
                                            {userRequest.kec}
                                        </span>
                                    </Row>
                                </div> */}


                                {/* <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Vin :</h3>
                                    </Row>
                                    <Row span={12}>

                                        <span className="">
                                            {userRequest.allocation && userRequest.allocation.vinNo}
                                        </span>
                                    </Row>
                                </div> */}

                                {/* <div className="detail-element">

                                    <Row span={12}>
                                        <span>
                                            <h3>Request :</h3>
                                        </span>
                                    </Row>
                                    <Row span={12}>
                                        <Link to={`/requests/${userRequest['id']}`}>
                                            View Request
                                                </Link>
                                    </Row>
                                </div> */}

                            </div>


                        </>
                        <>

                            {/* <RequestForm
                                formContent={vehicle}
                                modelIndex='id'
                                model={step.model}
                                step={step}
                                callback={(params, values) => {

                                    history.goBack();

                                }}
                            /> */}

                            <FormCreator
                                formContent={{ [step.model]: {} }}
                                modelIndex='vehicleId'
                                model={step.model}
                                onSubmit={onSubmit}
                                config={step}
                                callback={() => {

                                    history.goBack();

                                }}
                            />

                        </>

                    </Fragment>}
        </div>
    )
}