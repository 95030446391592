

import FirebaseUtils from './../../utils/firebase.utils';

import { message } from 'antd';
import CreateUser from './../create-user/create-user'
import EditUser from './../edit-user/edit-user'

/**
 * Columns for Request Table
 */
export const requestColumns = [

    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName'
    },
    {
        type: 'input',
        caption: 'Phone',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant'
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Created By',
        field: 'created_by.role',
        type: 'input',
    },

    {
        caption: 'Request Id',
        field: 'id',
        type: 'input',
    },

    // {
    //     caption: 'Booking No',
    //     field: 'bookingNo',
    //     type: 'input',
    // },
]



/**
 * Structure for Booking Listing and Add
 */
export const bookingColumns = [

    {
        type: 'input',
        caption: 'Booking No',
        field: 'Booking No'
    },
    {
        field: 'Name of the Customer',
        type: 'input',
        caption: 'Customer Name'
    },
    {
        field: 'Contact Number',
        type: 'input',
        caption: 'Contact Number'
    },
    {
        field: 'Booking Date',
        type: 'input',
        caption: 'Booking Date'
    },

    {
        caption: 'KEC',
        field: 'Consultant Name',
        type: 'input',
    },

    {
        caption: 'Request',
        field: 'requestId',
        type: 'input',
    },
]



/**
 * Structure for Collections
 */
export const collectionColumns = [

    {
        type: 'input',
        caption: 'Customer',
        field: 'name'
    },
    {
        field: 'kec',
        type: 'input',
        caption: 'KEC'
    },
    {
        field: 'created_at',
        type: 'input',
        caption: 'Created At'
    },
    {
        field: 'phone',
        type: 'input',
        caption: 'Phone'
    },

    {
        caption: 'Amount',
        field: 'amount',
        type: 'input',
    },

    {
        caption: 'Account',
        field: 'account',
        type: 'input',
    },

    // {
    //     caption: 'Team Leader',
    //     field: 'teamLeader',
    //     type: 'input',
    // },

    {
        caption: 'Type',
        field: 'type',
        type: 'input',
    },
]



export const stockColumns = [

    {
        type: 'input',
        caption: 'Vin Number',
        field: 'Vin Number'
    },
    {
        field: 'Variant',
        type: 'input',
        caption: 'Variant'
    },
    {
        field: 'Stock Status',
        type: 'input',
        caption: 'Stock Status'
    },
    {
        field: 'Exterior Color Name',
        type: 'input',
        caption: 'Color'
    },

    {
        caption: 'Color Type',
        field: 'Color Type',
        type: 'input',
    },

    {
        caption: 'Variant Code',
        field: 'Variant Code',
        type: 'input',
    },

    {
        caption: 'Invoice Date',
        field: 'KMI Invoice Date',
        type: 'input',
    },

    // {
    //     caption: 'Soft Allocation',
    //     field: 'allocated.requestId',
    //     type: 'input',
    // },

    {
        caption: 'Status',
        field: 'update',
        type: 'input',
    },
]



/**
 * Schema used for Import
 */
export const bookingSchema = {

    tableHeader: 2,
    tableRange: 0,
    importAll: true,

    extraParams: {
        billing: {
            status: 'pending'
        },
        delivery: {
            status: 'pending'
        }
    },

    tableIndex: 'Booking No',

    dateField: 'bookingDate',

    rowIndex: 'Booking No',
    columns: [
        {
            type: 'input',
            caption: 'Booking No',
            field: 'Booking No'
        },
        {
            field: 'Name of the Customer',
            type: 'input',
            caption: 'Customer Name'
        },
        {
            field: 'Contact Number',
            type: 'input',
            caption: 'Contact Number'
        },
        {
            field: 'Booking Date',
            type: 'input',
            caption: 'Booking Date'
        },

        {
            caption: 'KEC',
            field: 'Consultant Name',
            type: 'input',
        },

        {
            caption: 'Request',
            field: 'requestId',
            type: 'input',
        },
    ],

    custom_actions: [{

        onClick: (selected, city, callback) => {

            console.log('jhell', selected);

            Promise.all(selected.map((entry) => {

                var count = 0;

                return FirebaseUtils.removeRequest(entry).then(() => {
                    count++;
                    console.log(count);
                })

            })).then(() => {

                message.success('Action done');

                callback();
            })

        },
        caption: 'Reset',
        multiple: true

    }]
}







/**
 * Schema used for Import
 */
export const stockSchema = {

    includeFirstRow: true,


    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Vin Number',
    importAll: true,

    rowIndex: 'Vin Number',
    columns: [
        {
            field: 'partNo',
            from: 'PART NO'
        },
        {
            field: 'partName',
            from: 'PART NAME'
        },
        {
            field: 'available',
            from: 'INVENTORY QUANTITY'
        },

        {
            field: 'onOrder',
            from: '__EMPTY_5'
        },
        {
            field: 'listPrice',
            from: '__EMPTY_6'
        },
        {
            field: 'price',
            from: '__EMPTY_7'
        },

        {
            field: 'stockValue',
            from: 'AMT'
        },
        {
            field: 'hsn',
            from: 'HSN CD'
        },

        {
            field: 'supplier',
            from: 'SUPPLIER'
        },

        {
            field: 'category',
            default: 'Accessories'
        },

    ],

    custom_actions: [{

        onClick: (selected, city, callback) => {

            console.log('jhell', selected);

            Promise.all(selected.map((entry) => {

                var count = 0;

                return FirebaseUtils.unblockVehicle(entry).then(() => {
                    count++;
                    console.log(count);
                })

            })).then(() => {

                message.success('Action done');

                callback();
            })

        },
        caption: 'Reset',
        multiple: true

    }]


}

/**
 * Schema used for Import users
 */
export const userSchema = {


    tableIndex: (element) => {
        return element['Name'] + '-' + element['Role']
    },
    rowIndex: 'Name',
    importAll: true,
    tableHeader: 2,
    tableRange: 0,
    isDirect: true,
    disableSelect: true,
    Add: CreateUser,
    Edit: EditUser,

    columns: [
        {
            type: 'input',
            caption: 'NAME',
            field: 'name'
        },
        {
            field: 'phone',
            type: 'input',
            caption: 'Phone'
        },
        {
            field: 'role',
            type: 'input',
            caption: 'Role'
        },
        {
            caption: 'Place',
            field: 'place',
            type: 'input',
        },

        {
            caption: 'Email',
            field: 'email',
            type: 'input',
        },
    ]


}


export const pricingSchema = {


    tableIndex: (element) => {
        return element['Variant'] + '-' + element['Ext Color']
    },
    rowIndex: 'key',

    tableHeader: 0,
    tableRange: 0,


    columns: [


        {
            field: 'variant',
            from: '__EMPTY',
            caption: 'Variant'

        },

        {
            field: 'model',
            from: 'Model',
            caption: 'Model'

        },

        {
            field: 'variantCode',
            from: 'Variant',
            caption: 'Variant'

        },

        {
            field: 'basicKit',
            from: 'Basic Kit',
            caption: 'Basic Kit',

        },
        // {
        //     field: 'city',
        //     from: 'City',
        //     caption: 'City'

        // },
        {
            field: 'exShowroom',
            from: 'Ex-Showroom Price',
            caption: 'Ex-Showroom Price'

        },

        {
            field: 'sellingPrice',
            from: 'Selling Price',
            caption: 'Selling Price'

        },


        {
            field: 'rto',
            from: 'RTO',
            caption: 'RTO'

        },

        {
            field: 'sellingPrice',
            from: 'Selling Price',
            caption: 'Selling Price'

        },

        {
            field: 'extColor',
            from: 'Ext Color',
            caption: 'Ext Color'

        },
        {
            field: 'extendedWarranty',
            from: 'Extended Warranty',
            caption: 'Extended Warranty'

        },
        {
            field: 'fastTag',
            from: 'Fast Tag',
            caption: 'Fast Tag'

        },


        {
            field: 'insurance',
            from: 'Insurance',
            caption: 'Insurance'

        },


        // {
        //     field: 'state',
        //     from: 'State',
        //     caption: 'State'

        // },


        {
            field: 'sgst',
            from: '__EMPTY_3',
            caption: 'SGST'

        },
        {
            field: 'cgst',
            from: 'GST',
            caption: 'CGST'

        },

        {
            field: 'igst',
            from: '__EMPTY_4',
            caption: 'IGST'

        },

        {
            field: 'cess',
            from: '__EMPTY_5',
            caption: 'CESS'

        },

    ]

}







/**
 * Structure for Parts Listing and Add
 */
export const partsColumns = [

    {
        type: 'input',
        caption: 'Part No',
        field: 'partNo'
    },
    {
        field: 'partName',
        type: 'input',
        caption: 'Part Name'
    },
    {
        field: 'supplier',
        type: 'input',
        caption: 'Supplier'
    },
    {
        field: 'price',
        type: 'input',
        caption: 'Price'
    },

    {
        caption: 'Model',
        field: 'model',
        type: 'radio',
        options: ['Seltos', 'Sonet', 'Carnival'], // This has to come from db
    },


    {
        caption: 'Category',
        field: 'category',
        type: 'radio',
        options: ['Accessories', 'VAS', 'After Market', 'Extended Warranty'],
    },

    {
        caption: 'Inclusive of Tax',
        field: 'tax_inclusive',
        type: 'radio',
        options: ['Yes', 'No'],
    },

    {
        caption: 'CGST',
        field: 'cgst',
        type: 'input',
    },

    {
        caption: 'SGST',
        field: 'sgst',
        type: 'input',
    },

    {
        caption: 'IGST',
        field: 'igst',
        type: 'input',
    },

    {
        caption: 'Additional Tax',
        field: 'addition_tax',
        type: 'input',
    },
]


/**
 * Schema used for Import
 */
export const partsSchema = {

    tableHeader: 2,
    tableRange: 0,
    uploadIndex: 'PART NO', // Do we required an extra column like this
    tableIndex: 'partNo',
    custom_actions: [],
    rowIndex: 'partNo',
    columns: [
        {
            field: 'PART NO',
            from: 'PART NO'
        },

        {
            field: 'partNo',
            from: 'PART NO'
        },
        {
            field: 'partName',
            from: 'PART NAME'
        },
        {
            field: 'available',
            from: 'INVENTORY QUANTITY'
        },

        {
            field: 'onOrder',
            from: '__EMPTY_5'
        },
        {
            field: 'listPrice',
            from: '__EMPTY_6'
        },
        {
            field: 'price',
            from: '__EMPTY_7'
        },

        {
            field: 'stockValue',
            from: 'AMT'
        },
        {
            field: 'hsn',
            from: 'HSN CD'
        },

        {
            field: 'supplier',
            from: 'SUPPLIER'
        },

        {
            field: 'category',
            default: 'Accessories'
        },

    ]
}
