import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from './../../../../../../Store';

import { message, Checkbox, Popconfirm, Modal, Button, Tag } from 'antd';

import FirebaseUtils from '../../../../../../utils/firebase.utils';

import moment from 'moment';

import ApiUtils from '../../../../../../utils/api.utils';

// import './reconcile-summary.scss';

import CollectionInfo from './../collection-info/collection-info';

import { CustomerEntries, BankCredits } from '../../../../../../models';

/**
 * Matching Collection Card
 * For Reconcilation
 *
 * @param {*} param0
 * @returns
 */
export default function CollectionCard({ receipt = {}, collection = {}, callback, onSelect, allDepartments }) {
    
    const [visible, setVisible] = useState(false);

    const { user } = useContext(GlobalContext);

    /**
     *
     * This is for acknowledging regarding cheque
     *
     * @param {*} role
     */
    async function autoReconcile(role) {
        //if (collection.invoice) {
        var params2 = {
            // pending_to_reconcile: false,
            clearing_entry: true,
            reconcile_status: 'completed',
            pending_at: 'done',
            reconciled_for: collection.collectionId,
            closing: {
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                created_by: user.name,
            },
        };

        // Not required without cs
        // updateVoucherRecord(collection).then( async () => {
            
            var app = FirebaseUtils.getApp();

            var batch = app.batch();

            
            // Update the reconcile status of bank receipt
            var docRef = BankCredits.getRecordReference(receipt.receiptId);
            
            await batch.update(docRef, params2);

            var params1 = {
                status: 'reviewed',
                pending_to_reconcile: false,
                pending_at: 'done',
                reconciled_by: receipt.receiptId,
                closing: {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_by: user.name,
                },
                clearing_city: receipt.city,
                bank: receipt.bank,
                voucher: {
                    ...receipt.voucher,
                },
            };

            // Update the reconcile status of actual receipt
            var customerRef = CustomerEntries.getRecordReference(collection.collectionId);
                
            await batch.update(customerRef, params1);

            await batch.commit();

            message.success('The updates have been captured');

            callback();
                
        // });
        // } else {
        //     message.error('DMS Receipt Pending');
        // }
    }

    /**
     * Not required without cs
     */
    /**
     * For Cheque Clearing
     * Update the CS Accounts Voucher Record
     */
    // function updateVoucherRecord(receipt) {
    //     var params = {
    //         v_rconstatus: 'Y',
    //         v_rcondt: new Date(),
    //     };

    //     return new Promise((resolve, reject) => {
    //         const { voucher = {} } = receipt;

    //         // Get the bank record of this voucher
    //         ApiUtils.getVouchers(voucher.v_vno, receipt.department).then((result) => {
    //             var bank_entry = result.filter((entry) => entry.v_totrow === 'Y').pop();

    //             if (bank_entry) {
    //                 // Update the Voucher Record
    //                 ApiUtils.updateVoucherRecord(bank_entry.v_id, params, receipt.department)
    //                     .then(() => {
    //                         resolve({});
    //                     })
    //                     .catch((error) => {
    //                         reject(error);
    //                     });
    //             } else {
    //                 message.warning('CS Accounts entry has to be manually Updated');

    //                 resolve({});
    //             }
    //         });

    //         // const { v_ids } = voucher;

    //         // if (v_ids && v_ids[0]) {
    //         //     // Update the Voucher Record
    //         //     ApiUtils.updateVoucherRecord(v_ids[0], params)
    //         //         .then(() => {
    //         //             resolve({});
    //         //         })
    //         //         .catch((error) => {
    //         //             reject(error);
    //         //         });
    //         // } else {
    //         //     message.warning('CS Accounts entry has to be manually Updated');

    //         //     resolve({});
    //         // }
    //     });
    // }

    return (
        <>
            <div className="collection-card">
                <div className="left">
                    <h2 className="title">
                        {collection.name} - <small>{collection.remarks}</small>
                    </h2>
                    <h4 className="title ">{collection.phone}</h4>
                    Created at {collection.created_at} by {collection.created_by_name}
                </div>

                <div className="right">
                    <div>
                        <h3 className="title amount">{collection.amount}</h3>
                    </div>

                    <div>
                        <Button
                            onClick={() => {
                                setVisible(true);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            View
                        </Button>

                        {collection.pending_to_reconcile === true ? (
                            <Popconfirm
                                // title="Mark this entry as reconciled on CS Accounts. Please continue after that."
                                onConfirm={() => autoReconcile()}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="secondary" size={'small'}>
                                    Acknowledge Receipt
                                </Button>
                            </Popconfirm>
                        ) : null}

                        {collection.verified === 'approved' ? <Tag color="success">Verified</Tag> : <Tag color="red">Unverified</Tag>}

                        {collection.invoice ? <Tag color="success">Receipt Completed</Tag> : <Tag color="red">Receipt Pending</Tag>}

                        {collection.accounts ? <Tag color="success">Accounts Done</Tag> : <Tag color="red">Accounts Pending</Tag>}

                        {onSelect && (
                            <Checkbox checked={collection.selected} onChange={onSelect}>
                                Select
                            </Checkbox>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                destroyOnClose={true}
                title="Collection Detail"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <CollectionInfo collection={collection} />
            </Modal>
        </>
    );
}
