/**
 *
 *
 */
import Base from './base';

class StatusLog extends Base {

    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'status_logs';
    }

    get path() {
        return `status_logs`;
    }

    get getName() {
        return `status_logs`;
    }
}

export default StatusLog;