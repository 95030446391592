import React, { useState, useEffect, useContext } from 'react';

import { Row, Button } from 'antd';

import FirebaseUtils from '../../../../utils/firebase.utils';

import CustomerCard from '../CustomerCard';

import { Tabs } from 'antd';

import PlaceHolder from './../../../../components/ui_elements/PlaceHolder';

import './customers.scss';

import { Departments } from '../../../../models';

import { GlobalContext } from './../../../../Store';

const { TabPane } = Tabs;

export default function Customers(props) {
    const [reports, setReports] = useState([]);
    const [loader, setLoader] = useState(false);

    const [deptId, setDeptId] = useState();

    const { user } = useContext(GlobalContext);

    useEffect(() => {
        getDepartment();
        // getBankReceipts();
    }, []);

    /**
     *
     * get department
     */

    function getDepartment() {
        var query = [
            {
                field: 'description',
                value: props.department[0],
            },

            {
                field: 'location',
                value: user.locations[0],
            },
        ];
        Departments.get(query)

            .then((result) => {
                setDeptId(result.departments[0].id);

                let x = result.departments[0].id;

                return x;
            })
            .then((x) => getBankReceipts(x));
    }

    /**
     * Get stock Data
     */
    function getBankReceipts(deptId) {
        setLoader(true);

        var queries = [
            {
                field: 'pending_at',
                value: 'edp',
            },
            {
                field: 'verified',
                value: 'approved',
            },
            {
                field: 'department_id',
                value: deptId,
            },
        ];

        FirebaseUtils.getCollections(user.locations[0], queries).then((result) => {
            setLoader(false);

            console.log(result);

            result.forEach((record) => {
                if (result.reconciled_by) {
                    FirebaseUtils.getBankCreditRecord(result.reconciled_by).then((response) => {
                        record = { ...result, ...{ response } };
                    });
                } else {
                    //
                }
            });

            setReports([...result]);
        });
    }

    function callback(key) {
        console.log(key);
    }

    // console.log(user);

    return (
        <section className="landing">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="New Collections" key="1">
                    <div className="list-header">
                        <p className="page-desc">{loader ? 'Loading data' : `Found ${reports.length} results`}</p>
                        <Button onClick={getBankReceipts} type="secondary" size={'small'}>
                            Refresh
                        </Button>
                    </div>

                    <Row className="report-listing-wrapper">
                        {loader ? (
                            <PlaceHolder type="listing" />
                        ) : (
                            <>
                                {reports.map((report, index) => {
                                    return <CustomerCard report={report} index={index} key={index} />;
                                })}
                            </>
                        )}
                    </Row>
                </TabPane>
            </Tabs>
        </section>
    );
}
