/**
 * Function to display the user Name  by using the corresponding user_id
 * @author Sameena
 */

 import { Employees, Users } from '../../models/';

 import React, { useEffect, useState, useContext } from 'react';
 
 
 export default function GetUserName({ user_id }) {
 
     const [user, setUser] = useState({})
 
 
     useEffect(() => {
 
         getUser(user_id)
 
     }, []);
 
 
     /*
     ** To get the user details from user table by user_id
     */
     async function getUser(user_id) {
 
         var result = await Users.getRecord(user_id);
 
         setUser(result);
 
     }
 
 
 
     return (
                
         <div>
             {user && user.name ? user.name:user_id}
          
         </div>
 
     );
 
 }