import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Form, Input, message, Typography, Alert, Badge } from 'antd';

import { Tag, Row, Col, Image } from 'antd';

import { Radio } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import FileUpload from '../../../../components/file-upload/file-upload';

import ImageWrapper from './../../../../components/image-wrapper/image-wrapper';

import moment from 'moment';

const { Title } = Typography;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

const { TextArea, Text } = Input;

export default function CollectionDetail({ history, match }) {
    const { user } = useContext(GlobalContext);

    const [record, setRecord] = useState({ attachments: [] });

    const [edit, setEdit] = useState(false);

    const [form] = Form.useForm();

    const [loader, setLoader] = useState(false);

    const [matching, setMatching] = useState([]);

    const [matchingloader, setMatchingloader] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const { params } = match;

    const { city, id } = params;

    function loadData() {
        FirebaseUtils.getCollectionRecord(id).then((result) => {
            console.log(result);

            setLoader(false);

            setRecord(result);

            if (result.reconciled_by) {
                getBankRecord(result);
            } else {
                getMatchingCredit(result);
            }
        });
    }

    function getBankRecord(record) {
        FirebaseUtils.getBankCreditRecord(record.reconciled_by).then((result) => {
            setMatching([result]);
        });
    }

    function getMatchingCredit(entry) {
        setMatchingloader(true);

        var queries = [
            {
                field: 'source',
                value: 'bank',
            },
            {
                field: 'credit',
                value: entry.amount.toString(),
            },
            {
                field: 'reconcile_status',
                value: 'pending',
            },
        ];

        FirebaseUtils.getBankCredits(queries).then((result) => {
            console.log(result);

            setMatchingloader(false);

            let records = result.filter((record) => {
                if (entry.utr !== '' || entry.ref !== '') {
                    var condition =
                        record['description'].indexOf(entry.ref) !== -1 ||
                        record['description'].indexOf(entry.utr) !== -1 ||
                        record['ref'].indexOf(entry.utr) !== -1 ||
                        record['ref'].indexOf(entry.ref) !== -1;

                    return condition;
                } else {
                    return false;
                }
            });

            setMatching(records);
        });
    }

    /**
     *
     * For each change in status we update the log
     *
     * @param {*} values
     */
    const onSubmit = (values) => {
        let creation = {
            created_by: { name: user.name, role: user.role },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
        };

        if (values.amount) {
            values.amount = parseFloat(values.amount);
        }

        let params = {
            kec: user.dms['Consultant Name'],
            teamLeader: user.dms['teamLeader'],
            city: user.locations[0],
            deleted_at:null,
            deleted_by:null,
            ...creation,
            ...values,
            status: 'submitted',
        };

        FirebaseUtils.updateCollection(record.id, params).then(() => {
            message.success('Payment Collection Recorded');

            history.goBack();
        });
    };

    function onUpload(attachments) {
        form.setFieldsValue({ attachments: attachments });
    }

    return (
        <div className="allocation-form">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="vehicle-card">
                            <>
                                {edit ? (
                                    <div className="document-status">
                                        <Title level={3}>Add Collection </Title>

                                        <p>For any customer payment , fill the details in the below form</p>

                                        <Form
                                            {...layout}
                                            form={form}
                                            name="new-record"
                                            onFinish={onSubmit}
                                            layout="vertical"
                                            // validateMessages={validateMessages}
                                            initialValues={{
                                                ...record,
                                            }}
                                        >
                                            <Form.Item name={'amount'} label="Amount" rules={[{ required: true, message: 'Please enter Amount' }]}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name={'name'}
                                                label="Customer Name"
                                                rules={[{ required: true, message: 'Please enter customer name' }]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name={'phone'}
                                                label="Phone Number"
                                                rules={[{ required: true, message: 'Please enter phone' }]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name={'utr'}
                                                label="UTR Number"
                                                rules={[{ required: true, message: 'Please enter UTR Number' }]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name={'customerId'}
                                                label="Customer ID"
                                                rules={[{ required: true, message: 'Please enter customer id' }]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item name={'account'} label="On account of" rules={[{ required: true, message: 'On Account Of' }]}>
                                                <Radio.Group>
                                                    <Radio value="BOOKING">Booking</Radio>
                                                    <Radio value="PAYMENT">Part Payment</Radio>
                                                    <Radio value="FINANCE">Finance Payment</Radio>
                                                    <Radio value="EXCHANGE">Exchange Value</Radio>
                                                    <Radio value="INSURANCE">Insurance</Radio>
                                                    <Radio value="ACCESSORIES">Accessories</Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                            {record.type === 'Cash' ? null : (
                                                <Form.Item
                                                    name="attachments"
                                                    rules={[{ required: true, message: 'Share attachments of the receipt' }]}
                                                    label="Attach Payment Screenshot"
                                                    valuePropName="fileList"
                                                    extra="File size should not exceed 1 mb"
                                                >
                                                    <FileUpload callback={onUpload} />
                                                </Form.Item>
                                            )}

                                            <Form.Item name={'remarks'} label="Remarks" rules={[{ required: true, message: 'Please enter remarks' }]}>
                                                <TextArea rows={4} />
                                            </Form.Item>

                                            <Button type="primary" htmlType="submit" className="submit-button">
                                                Submit
                                            </Button>

                                            <Button
                                                type="secondary"
                                                onClick={() => {
                                                    setEdit(false);
                                                }}
                                                className="submit-button"
                                            >
                                                Cancel
                                            </Button>
                                        </Form>
                                    </div>
                                ) : (
                                    <div className="vehicle-card card">
                                        <CollectionInfo collection={record} />

                                        {matchingloader ? (
                                            <PlaceHolder type="report" />
                                        ) : (
                                            <>
                                                {matching.length ? (
                                                    <Alert
                                                        message={`${matching.length} matching record has reflected in bank`}
                                                        type="success"
                                                        showIcon
                                                    />
                                                ) : null}

                                                {/* Matching Records */}
                                                {matching.map((entry) => {
                                                    return <BankCredit receipt={entry} />;
                                                })}

                                                {/* Matching Records Ends */}
                                            </>
                                        )}

                                        {record.invoice ? <ReceiptInfo record={record} /> : null}

                                        {/* Edit Mode Toggle */}
                                        {record.status === 'submitted' ? (
                                            <Button
                                                size="small"
                                                onClick={() => {
                                                    setEdit(true);
                                                }}
                                                type="secondary"
                                                className="submit-button"
                                            >
                                                Edit
                                            </Button>
                                        ) : null}
                                        {/* Edit Mode Toggle */}
                                    </div>
                                )}
                            </>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}

function CollectionInfo({ collection = { attachments: [] } }) {
    return (
        <div>
            <div className="detail-element">
                <Row span={12}>
                    <h3>Name :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title">{collection.name}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Amount :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title amount">{collection.amount}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>UTR :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.utr}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <span>Attachments</span>
                </Row>

                <>
                    {collection.attachments &&
                        collection.attachments.map((file) => {
                            return (
                                <>
                                    {file.type === 'application/pdf' ? (
                                        <embed src={file.url} width="500" height="375" type="application/pdf" />
                                    ) : (
                                        <Image width={200} src={file.url} />
                                    )}
                                </>
                            );
                        })}
                </>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Remarks :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.remarks}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Phone :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.phone}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>On Account Of :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.account}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Created At :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.created_at}</span>
                </Row>
            </div>
        </div>
    );
}

function ReceiptInfo({ city, record }) {
    // const [record, setRecord] = useState({ invoice: [] });

    // useEffect(() => {

    //     FirebaseUtils.getBankCreditRecord(city, receiptId).then((result) => {

    //         console.log(result);

    //         let data = result;

    //         if (data) {

    //             setRecord(data);
    //         }
    //     })

    // }, [])

    return (
        <div>
            <div className="detail-element">
                <Row span={12}>
                    <span>Invoice</span>
                </Row>

                <Row>
                    <ImageWrapper type={Array.isArray(record.invoice) ? 'array' : 'object'} content={record.invoice} />
                </Row>

                {/* <>
                {
                    record.invoice && record.invoice.map((file) => {

                        return <>{file.type === 'application/pdf' ?
                            <>
                                <a href={file.url} download={`newfilename${file.url}`}>Download</a>
                                <embed
                                    src={file.url}
                                    width="500"
                                    height="375"
                                    type="application/pdf"
                                />
                            </>
                            : <Image
                                width={200}
                                src={file.url}
                            />}</>
                    })
                }

            </> */}
            </div>
        </div>
    );
}

function BankCredit({ receipt = {} }) {
    var created_by;
    var created_at;

    if (receipt.rejectRemarks) {
        if (receipt.smReject) {
            created_by = receipt.smReject.created_by;

            created_at = receipt.smReject.created_at;
        } else if (receipt.edpReject) {
            created_by = receipt.edpReject.created_by;

            created_at = receipt.edpReject.created_at;
        }
    }

    return (
        <div className="card">
            <h3 className="title">{receipt['description']}</h3>
            <h2 className={`title ${receipt['debit'] ? 'amount' : 'credit'}`}>{receipt['debit'] || receipt['credit']}</h2>
            <p className="title ">{receipt['ref']}</p>
            <p className="">
                <Tag color="magenta">{receipt.location || receipt.bank}</Tag>

                {receipt.location || receipt.city ? <Badge color="#f50" text={receipt.city || receipt.location} /> : null}
            </p>
            Credited at {receipt['txn_date']}
            <div>
                {receipt.rejectRemarks ? (
                    <Text type="danger">
                        {receipt.rejectRemarks}, By {created_by} at {created_at}
                    </Text>
                ) : null}
            </div>
            {receipt.edp && receipt.edp.created_at ? (
                <>
                    Created at {receipt.edp.created_at}, By {receipt.edp.created_by}
                </>
            ) : null}
        </div>
    );
}
