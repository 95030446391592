import React, { useEffect, useContext, useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {
    PhoneLogin,

    // Roles
    SuperLanding, // Ashique, Marvan
    AdminLanding, // Management
    ManagementLanding,
    RMLanding, // Regional Manager
    SMLanding, // Sales Manager
    TLLanding, // Team 4
    KECLanding, // Sales Staff
    EDPLanding, // DMS Entry Person
    CCMLanding,
    FinanceLanding, // Finance Coordinator
    InsuranceLanding, // Insurance Coorinator
    RTOLanding, // RTO Coordinator
    WarrantyLanding,
    AccessoriesLanding, // Accessories Coordinator
    DeliveryLanding, // Delivery Coordinatior
    AccountsLanding, // Accounts Module
    ReceptionistLanding, // Service Receptionist
    PDILanding, // Added new module
    ServiceAccountsLanding, //Service Accounts
    ServiceGMLanding , //Service GM
    HRLanding,  //HR 
    // Roles Ends

    // privacy section
    AboutComponent,
    PrivacyComponent,
    ContactComponent,
    RefundComponent,
    TermsAndConditionComponent,
} from './components';

import ForgotPassword from './modules/auth/forgot-password';

import Profile from './modules/common/resources/profile/profile';

import GlobalHeader from './components/global-header/global-header';

import { GlobalContext } from './Store';

import { GlobalProvider } from './Store';

import { Location } from './utils/location.utils';

import './App.scss';

import 'antd/dist/antd.css';

function App(props) {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <GlobalProvider>
            <Router>
                <Route path="/" component={Routes} />
            </Router>
        </GlobalProvider>
    );
}

var landing;

function Routes(props) {
    const [loader, setLoader] = useState(true);

    const { user } = useContext(GlobalContext);

    var pages = {
        super: SuperLanding,
        admin: AdminLanding,
        management: ManagementLanding,
        sm: SMLanding,
        kec: KECLanding,
        edp: EDPLanding,
        ccm: CCMLanding,
        accounts: AccountsLanding,
        finance: FinanceLanding,
        tl: TLLanding,
        insurance: InsuranceLanding,
        rto: RTOLanding,
        rm: RMLanding,
        accessories: AccessoriesLanding,
        warranty: WarrantyLanding,
        delivery: DeliveryLanding,
        receptionist: ReceptionistLanding,
        pdi: PDILanding,
        hr:HRLanding,
        service_accounts: ServiceAccountsLanding,
        service_GM:ServiceGMLanding,
    };

    useEffect(() => {
        // Location.getHistoryMethod(getRouterProps);
        // document.getElementById('loading-div').style.display = "none";
    }, []);

    useEffect(() => {
        // console.log(user);

        if (user && user.loggedCheckDone) {
            setLoader(false);

            if (user.email && user.active === 'true') {
                // props.history.push('/');

                landing = pages[user.role];
                // props.history.push(user.url);
            } else {
                props.history.push('/login-phone');
            }
        }
    }, [user]);

    const getRouterProps = () => ({ history: props.history });

    // console.log(landing);

    return (
        <div className="app">
            <div className="content">
                {/* Onboarding welcome card */}
                {!loader ? (
                    <div>
                        <Switch>
                            <Route path="/forgot-password" component={ForgotPassword} />

                            <Route exact path="/login-phone" component={PhoneLogin} />
                            <Route exact path="/about" component={AboutComponent} />
                            <Route exact path="/privacy" component={PrivacyComponent} />
                            <Route exact path="/contact-us" component={ContactComponent} />
                            <Route exact path="/refunds-cancellations" component={RefundComponent} />
                            <Route exact path="/terms-and-conditions" component={TermsAndConditionComponent} />

                            {/* The landing component is decided by the role of the person who logs in  */}
                            {/* The successive routes according to role is decided by the landing component */}
                            <Route path="/" component={landing}></Route>
                        </Switch>
                    </div>
                ) : (
                    <div>
                        <GlobalHeader />
                        {/* <PlaceHolder type="listing" /> */}
                    </div>
                )}
            </div>
        </div>
    );
}

export default App;
