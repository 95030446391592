import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './../../hocs/PrivateRoute';

import GlobalHeader from './../../components/global-header/global-header';

import StockDashboard from './../common/components/stock-dashboard/stock-dashboard';

import PreferenceDetail from './../common/components/preference-detail/preference-detail';

import AllocationRequests from './components/allocation-requests/allocation-requests';

import Collections from './../common/components/collections/collections';
import CollectionDetail from './../common/components/collections/collections-detail';
import AddCollection from './../common/components/collections/collections-add';

// import RequestStatus from './../common/components/request-status/request-status';
import RequestDetail from './../common/components/request-detail/request-detail';

import GenericList from '../common/resources/generic/generic-list/generic-list';

//import RequestDashboard from '../common/resources/request-overview/request-overview';

// My Booking Section Ends

import ManageBookings from './../common/components/manage-bookings/manage-bookings';
import BookingDetail from './../common/components/booking-detail/booking-detail';

import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import TaskList from './../common/resources/task-list/task-list';
import TaskForm from './../common/resources/task-form/task-form';
import BookingListing from './components/booking-listing/booking-listing';
import BookingForm from './components/booking-form/booking-form';
import PendingRequests from './../common/components/pending-requests/pending-requests';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import TaskOverview from './../common/resources/task-overview/task-overview';

import { kecSteps } from './kec-config';

import { requestSchema, bookingSchema } from './../global-config';

import { Location } from '../../utils/location.utils';

import './kec-landing.scss';

import { GlobalContext } from './../../Store';
import ChangePassword from './../common/components/change-password/change-password';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';
import ManageEnquiries from '../common/components/manage-enquiries/manage-enquiries';
import EnquiryDetail from '../common/components/enquiry-detail/enquiry-detail';

export default function KECLanding(props) {
    const { user } = useContext(GlobalContext);

    const currentURL = Location.search();

    const tagToSearch = Object.keys(currentURL)[0];

    const statusToSearch = currentURL[tagToSearch];

    let userQueries = [
        {
            field: 'consultant_id',
            value: user.employee_id,
        },
    ];

    let kecQueries = [
        {
            field: 'consultant_id',
            value: user.employee_id,
        },
    ];

    console.log(user);

    const modules = [
        {
            caption: 'Tasks',
            submenus: [
                // {
                //     caption: "Delivery Plan",
                //     path: "plan-delivery"

                // },
                ...kecSteps.filter((step) => !step.isForm),
            ],
        },

        {
            caption: 'Collections',
            path: '/collections',
        },

        {
            caption: 'Allocations',
            path: '/allocations',
        },
        {
            caption: 'Manage',
            submenus: [
                {
                    path: `/${user.locations[0]}/enquiries`,
                    caption: 'Manage Enquiries',
                },
            ],
        },

        {
            caption: 'Requests',
            submenus: [
                {
                    path: `/${user.locations[0]}/pending-requests`,
                    caption: 'Pending Requests',
                },

                {
                    path: `/${user.locations[0]}/billing-requests`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivered-requests`,
                    caption: 'Delivered Requests',
                },
            ],
            // submenus: [{
            //   path: '/requests',
            //   caption: 'My Requests'
            // }]
        },
        // {
        //   caption: "Bookings",
        //   path: `/${user.locations[0]}/bookings`
        // },

        {
            caption: 'Bookings',
            submenus: [
                // {
                //   path: '/manage-bookings',
                //   caption: 'Bookings'
                // },

                {
                    path: `/${user.locations[0]}/pending-bookings`,
                    caption: 'Pending Bookings',
                },

                {
                    path: `/${user.locations[0]}/billing-done`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivery-done`,
                    caption: 'Delivered Bookings',
                },

                // {
                //   path: '/pending-bookings',
                //   caption: 'Pending Bookings'
                // },
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => {
                            return (
                                <>
                                    <NoticeBoard />

                                    <TaskOverview steps={kecSteps} />

                                    <PendingRequests userRole="consultant_id" />

                                    {/* <RequestDashboard /> */}
                                </>
                            );
                        }}
                    ></Route>
                    {/* 
                        </>) */}

                    {/* <Route exact path={'/'} component={Alloca}></Route> */}

                    {/* }} ></Route> */}

                    {/* <Route exact path={'/'} render={()=> <PendingRequests userRole="kec" />} ></Route> */}
                    <Route exact path={'/allocations'} component={StockDashboard}></Route>
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'admin'} />

                    <Route exact path={'/requests'} component={AllocationRequests}></Route>

                    <Route
                        exact
                        path="/:city/pending-request"
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                queries={[
                                    {
                                        field: user.role,
                                        value: user.name,
                                    },
                                    {
                                        field: tagToSearch,
                                        value: statusToSearch.replace(/-/g, ' '),
                                    },
                                ]}
                                {...routeParams}
                            />
                        )}
                    />

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/enquiries" component={ManageEnquiries} userRole={'admin'} />

                    <PrivateRoute exact path="/collections" component={Collections} userRole={'admin'} />
                    <Route
                        exact
                        path="/collections/add"
                        render={(routeParams) => {
                            return <AddCollection department={['Department Handling Sales']} {...routeParams} />;
                        }}
                        userRole={'admin'}
                    />
                    <PrivateRoute exact path="/:city/collections/:id" component={CollectionDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/enquiries/:id" component={EnquiryDetail} userRole={'admin'} />

                    {/* <PrivateRoute exact path="/:city/bookings" component={() => {

            return <GenericList
              schema={bookingSchema}
             model="bookings"
              queries={
                [{
                  field: 'Consultant Name',
                  value: user.dms['Consultant Name']
                },
                ]}

              columns={bookingSchema.columns} />


          }} userRole={'admin'} /> */}

                    {/* Requests according to Queries */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/pending-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={[...requestSchema.pendingRequests, ...kecQueries]} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/billing-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={[...requestSchema.billingRequests, ...kecQueries]} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/allocated-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={[...requestSchema.allocatedRequests, ...kecQueries]} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}


                    {/* Delivered Requests */}
                    <Route
                        exact
                        path={`/:city/delivered-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={[...requestSchema.deliveryRequests, ...kecQueries]} {...routeParams} />
                        )}
                    />
                    {/* Delivered Requests Ends */}

                    {/* Requests according to Queries Done */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={bookingSchema}
                                queries={[...bookingSchema.pendingBookings, ...userQueries]}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingSchema.columns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => (
                            <GenericList
                                queries={[...bookingSchema.billingQueries, ...userQueries]}
                                schema={bookingSchema}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingSchema.columns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList
                                queries={[...bookingSchema.deliveryQueries, ...userQueries]}
                                schema={bookingSchema}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingSchema.columns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Delivery Bookings Ends */}

                    {/* Cancellations Module */}
                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />
                    {/* Cancellations Module ends */}

                    {/* <PrivateRoute exact path="/my-bookings" component={MyBookings} userRole={'admin'} /> */}
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    {/* <PrivateRoute exact path="/booking/:id" component={MyBookings} userRole={'admin'} /> */}

                    <PrivateRoute exact path="/manage-bookings" component={ManageBookings} userRole={'admin'} />
                    {/* <PrivateRoute exact path="/:city/booking/:id" component={BookingDetail} userRole={'admin'} /> */}

                    {/* <Route path="/sm-stock" component={StockDashboard} userRole={'sm'} /> */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={kecSteps} list={TaskList} form={TaskForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
