/**
 * 
 */

import React from "react";

import DateUtils from "../../utils/date.utils";

// import './date-display.scss';

export default function DateDisplay({ value }) {

    return (<span className="date-display">
        {DateUtils.formatDate(value)}
    </span>)



}