
import React, { useState, useEffect, useContext } from "react";

import "./booking-listing.scss";

import { GlobalContext } from '../../../../Store';

import { Row, Tabs, Button, Tag } from "antd";

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';


import FirebaseUtils from '../../../../utils/firebase.utils';
import RequestCard from "./components/request-card/request-card";


import { Link } from 'react-router-dom'


const { TabPane } = Tabs;



export default function BookingListing({ step }) {

    console.log("step : ", step);

    const { user } = useContext(GlobalContext);

    const city = user.locations[0];

    const [loader, setLoader] = useState(true);

    const [data, setData] = useState({
        [step.model]: []
    });

    useEffect(() => {

        loadData();

    }, [])


    function loadData() {
        getRequests(user.locations[0]);
    }

    function getRequests(location) {

        setLoader(true);

        console.log(user);

        var queries = step.queries({ user });

        FirebaseUtils.getListing(city, queries, 'bookings', 'bookings').then((result) => {
            console.log(result);

            setLoader(false);

            setData(result);

        })

    }

    return (
        <div className="allocation-requests">

            <Tabs defaultActiveKey="1" onChange={() => { }}>
                <TabPane tab="Pending Requests" key="1">

                    <div className="list-header">

                        <p className="page-desc">
                            {loader ? 'Loading data' : `Found ${data.bookings.length} results`}
                        </p>

                        <div>
                            <Button onClick={loadData} type="secondary" size={'small'}>Refresh</Button>
                        </div>

                    </div>

                    <Row className="report-listing-wrapper">
                        {loader ? (
                            <PlaceHolder type="listing" />
                        ) : (
                                <>
                                    {
                                        data.bookings.map((report, index) =>

                                            <BookingCard city={city} step={step} request={report} index={index} key={index} />
                                        )
                                    }
                                </>
                            )}
                    </Row>

                </TabPane>

            </Tabs>

        </div>
    );
}



function BookingCard({ request, city, step }) {

    return (
        <Link className="request-card" to={`${step.redirect_to}/${city}/${request['Booking No']}`}>
            <div className="card">

                <h2 className="title amount ">
                    {request['Name of the Customer']}

                </h2>
                <h4 className="title">
                    {request['Contact Number']}
                </h4>


                <h3 className="title ">
                    {request['Variant']}
                </h3>

                <div>
                    <small> {request['Color']}</small>
                </div>

                <h4>
                    {request['Booking No']}
                </h4>

                <p className="address">
                    Booked on {request['Booking Date']}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}

                </p>


                Committed Delivery Date  {request['Committed Delivery Date']}


            </div>
        </Link>
    )
}