import FirebaseUtils from './firebase.utils';

import moment from 'moment';

import { Uploads, Cart, Pricing} from './../models/';
import { cityKeys } from '../modules/global-config';

function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;
    }, {});
}

var cityKey = cityKeys

/**
 *
 */
class VehicleUtils {
    /**
     * Function Returns all the stock and filters what is required for vips
     */
    static getAllAvailableStock = (q = []) => {
        
        return new Promise((resolve, reject) => {
            var queries = [
                {
                    field: 'isHighPriority',
                    value: true,
                },
                {
                    field: 'highPriorityStatus',
                    value: 'approved',
                },
                {
                    field: 'billing.status',
                    value: 'pending',
                },
            ];

            FirebaseUtils.getAllRequests(queries).then((vip) => {
                var queries = [
                    {
                        field: 'update',
                        operator: '!=',
                        value: 'remove',
                        // value: ['Free Stock', 'In transit', 'Allocated'],
                    },
                ];

                q = [].concat(...q, ...queries);

                FirebaseUtils.getAllVehicles(q).then((result) => {
                    // Remove the vehicles that are billed or not in stock
                    let vehicles = result.all.filter((vehicle) => vehicle.update !== 'remove');

                    vehicles = filterMatching(
                        vip.all.filter((req) => !req.allocation.vinNo),
                        vehicles
                    );

                    var models = groupBy(vehicles, 'Model');

                    var queries = [
                        {
                            field: 'model',
                            value: 'vehiclces',
                        },
                    ];

                    var config = {
                        orderBy: 'created_time',
                        limit: 1,
                    };

                    Uploads.get(queries, config).then((result) => {
                        console.log(result);

                        resolve({
                            vehicles: models,
                            lastUpdated: result.lastUpdated,
                            all: vehicles,
                        });
                    });
                });
            });
        });
    };

    /**
     * Get the available stock in city after
     * high priority
     */
    static getAvailableStock = (city, q = []) => {
        return new Promise((resolve, reject) => {
            var queries = [
                {
                    field: 'isHighPriority',
                    value: true,
                },
                {
                    field: 'highPriorityStatus',
                    value: 'approved',
                },
                {
                    field: 'billing.status',
                    value: 'pending',
                },
            ];

            // Get all high prioriy requests
            FirebaseUtils.getRequests(city, queries).then((vips) => {
                var queries = [
                    {
                        field: 'update',
                        operator: '!=',
                        value: 'remove',
                        // field: 'Stock Status',
                        // operator: 'in',
                        // value: ['Free Stock', 'In transit', 'Allocated'],
                    },
                ];

                var queries = [].concat(...q, ...queries);

                FirebaseUtils.getVehicles(city, queries).then((result) => {
                    // Remove the vehicles that are billed or not in stock
                    let vehicles = result.vehicles.filter((vehicle) => vehicle.update !== 'remove');

                    vehicles = filterMatching(vips.requests, vehicles);

                    var models = groupBy(vehicles, 'Model');

                    var queries = [
                        {
                            field: 'model',
                            value: 'vehiclces',
                        },
                    ];

                    var config = {
                        orderBy: 'created_time',
                        limit: 1,
                    };

                    Uploads.get(queries, config).then((result) => {
                        console.log(result);

                        resolve({
                            vehicles: models,
                            lastUpdated: result.lastUpdated,
                            all: vehicles,
                        });
                    });

                    // resolve({
                    //     vehicles: models,
                    //     lastUpdated: result.lastUpdated,
                    // });
                });
            });
        });
    };

    /**
     * Function returns the available vehicles after reducing the count that would be otherwise required for
     * high priority list
     */
    static getAvailable = (color, variant, city) => {
        let v = {};

        return new Promise((resolve, reject) => {
            // FirebaseUtils.findMatchingHighPriorityList({ color, variant, city }).then((vips) => {

            var queries = [
                {
                    field: 'Stock Status',
                    operator: 'in',
                    value: ['Free Stock', 'In transit'],
                },
                {
                    field: 'Variant',
                    value: variant,
                },
                {
                    field: 'Exterior Color Name',

                    value: color,
                },
            ];

            FirebaseUtils.getVehicles(city, queries).then((result) => {
                let vehicles = result.vehicles;

                // vehicles = filterMatching(vips, vehicles);

                resolve(vehicles);
                // resolve(vips);
            });

            // })
        });
    };

    static blockVehicle = () => {};

    static createStockTransferRequest = (request, user) => {
        let params = {
            dealerCode: cityKey[request.destination_city],
            from: request.destination_city,
            to: request.city,
            requestId: request.id,
            created_by: user.name,
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            bookingNo: request.bookingNo,
            status: 'requested',
            vinNo: request.vinNo,
            deleted_at:null,
            deleted_by:null
        };

        return FirebaseUtils.createStockTransfer(
            // request.destination_city,
            params
        );
    };

    /**
     * Fucntion adds the pricing to cart for the request
     */
    static addPricing = (request, vehicle, user, batch) => {

        let creation = {
            created_by: { name: user.name, role: user.role },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
        };

        const general = {
            customerID: request.customer.customerID,
            bookingNo: request.bookingNo,
            vinNo: vehicle['Vin Number'],
            requestID: request.id,
            dealerCode: request.dealerCode,
            ...creation,
        };

        return new Promise( async (resolve, reject) => {
            //Vechicle Details
            let vechicleItems = [];

            var vehicleQueries = [
                {
                    field: 'variant',
                    value: request.variant,
                },
                {
                    field: 'key',
                    value: vehicle['Variant Code'] + '-' + vehicle['Color Type'],
                },
            ];

            
            await Pricing.get(vehicleQueries).then( async (result) => {
                if (result.pricing.length) {
                    let data = result.pricing[0];

                    let exShowroom = data['exShowroom'];

                    let finalexShowroom = exShowroom;

                    let isMahe = false;

                    // For Mahe Cases we neednt add KFC
                    if (request.customer.place) {
                        if (
                            request.customer.place &&
                            request.customer.place.toLowerCase().indexOf('mahe') === -1 &&
                            request.customer.place.toLowerCase().indexOf('pondicherry') === -1 &&
                            request.customer.place.toLowerCase().indexOf('puducherry') === -1
                        ) {
                            // For all Non Mahe Cases we add KFC
                            // finalexShowroom = data['exShowroom'] + Math.round(data['sellingPrice'] / 100);
                        } else {
                            isMahe = true;
                        }
                    } else {
                        // finalexShowroom = data['exShowroom'] + Math.round(data['sellingPrice'] / 100);
                    }

                    // The exshowroom price was missing kfc hence we add it manually here
                    // let finalexShowroom =
                    //     data['exShowroom'] +
                    //     Math.round(data['sellingPrice'] / 100);

                    //Add to vechicle details array
                    //1. Ex-showroom
                    vechicleItems.push({
                        type: 'basic',

                        name: request.variant,
                        description: request.variant,

                        subItems: [
                            {
                                description: 'Ex Showroom Price',
                                price: finalexShowroom,
                                order: 1,
                            },
                        ],

                        ...general,
                        deleted_at:null,
                        deleted_by:null

                    });

                    // TCS for Above 10L
                    if (finalexShowroom > 1000000) {
                        vechicleItems[0].subItems.push({
                            order: 2,
                            description: 'TCS @ 1%',
                            price: Math.round(1 * (finalexShowroom / 100)),
                        });
                    }

                    // Statutory Charges
                    // vechicleItems.push({

                    //     order: 3,

                    //     description: 'Statutory Charges',

                    //     type: 'basic',

                    //     price: 1015,

                    //     ...general

                    // });

                    //32. RTO
                    vechicleItems[0].subItems.push({
                        order: 4,
                        description: 'RTO',
                        price: isMahe ? 0 : data['rto'],
                    });

                    // Fast Tag
                    vechicleItems[0].subItems.push({
                        order: 5,
                        description: 'Fast Tag',
                        price: data['fastTag'],
                    });

                    let total = 0;

                    vechicleItems[0].subItems.forEach((entry) => {
                        total += parseFloat(entry.price);
                    });

                    vechicleItems[0].total = total;
                    vechicleItems[0].price = total;
                }

                // Add all the items to cart
                await Promise.all(vechicleItems.map( async (item) =>{
                    
                    var cartRef = await Cart.getRecordReference();
            
                    await batch.set(cartRef, Cart.appendDefaultValues(item));
                   
                }));    

                    resolve(vechicleItems);
            });
        });
    };
}

function filterMatching(vips, vehicles) {
    // Filter any that is required for vips
    Object.keys(vips).forEach((id) => {
        var preference = vips[id];

        let match = vehicles.find((vehicle, index) => {
            // Todo here we have to filter all matches
            if (vehicle['Variant'] === preference['variant'] && vehicle['Exterior Color Name'] === preference['color']) {
                vehicles.splice(index, 1);

                return true;
            }

            return false;
        });
    });

    return vehicles;
}

export default VehicleUtils;
