import Base from './base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import FirebaseUtils from '../utils/firebase.utils';

import moment from 'moment';

// import CollectionAdd from './../modules/accounts/components/collection-add/collection-add';

import { Users } from './index';

class Designation extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get filterDate() {
        return true;
    }

    get getEndpoint() {
        return 'designations';
    }

    get path() {
        return `designations`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `designation`;
    }

    get columns() {
        return [];
    }
}

export default Designation;
