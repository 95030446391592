import React, { useState, useContext } from 'react';

import { Form, Button, Input, message } from 'antd';

import { Link } from 'react-router-dom';

import Notify from './../../components/ui_elements/Notify';

import { withRouter } from 'react-router-dom';

import mask from './../../assets/images/kia.png';

import './login.scss';

import { Departments } from '../../models';

import FirebaseUtils from './../../utils/firebase.utils';
import ApiUtils from './../../utils/api.utils';

import { GlobalContext } from './../../Store';

var logo = require('./../../assets/images/kia-logo.png');

let handle = null;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { span: 8 },
};

function LoginPhone({ history }) {
    const { user, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        FirebaseUtils.loginWithEmail(values)
            .then((result) => {
                setLoading(false);

                if (result.id) {
                    if (result.active === 'true') {
                        let userInfo = {
                            dms: {},
                            locations: [],
                            ...result,
                            ...{ loggedCheckDone: true },
                        };

                        localStorage.setItem('userInfo', JSON.stringify(userInfo));

                        dispatch({ type: 'user', payload: userInfo });

                        history.push('/');

                        // Get the branches and set as default according to user role
                        Departments.get().then((result) => {
                            let match = result.departments.find(
                                (ele) => ele.location === userInfo.locations[0] && userInfo.department_ids.includes(ele.id)
                            );

                            userInfo.branch = match;

                            let defaultBranch;

                            if (match) {
                                defaultBranch = match;
                            } else {
                                defaultBranch = result.departments[0];
                            }

                            // ApiUtils.setBranch(defaultBranch, userInfo);

                            // ApiUtils.setBranches(result.departments);

                            dispatch({ type: 'defaultBranch', payload: defaultBranch });

                            dispatch({ type: 'branches', payload: result.departments });
                        });
                    } else {
                        message.error('Access Denied.');
                    }
                } else {
                    message.error('No accounts found.');
                }
            })
            .catch((result) => {
                console.log(result);

                setLoading(false);

                message.error('Login Failed');
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <section className="user-authentication-section">
            <div className="auth-form-wrapper">
                <div className="brand-logo">
                    <img className="logo-welcome" src={logo} alt="Logo" />
                </div>

                <div className="form-title">
                    <h3>Login</h3>

                    <p>Your work assistant for the day !</p>

                    <small>{process.env.REACT_APP_package_version}</small>
                </div>

                <img className="vehicle-image" src={mask} alt="Logo" />

                <Form
                    {...layout}
                    layout="vertical"
                    name="basic"
                    // initialValues={{ email: 'afil.ahammed@gmail.com', password: '123456' }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                        <Input.Password />
                    </Form.Item>

                    {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}

                    <Form.Item {...tailLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>

                    <Link style={{ flexBasis: '25%', color: 'grey' }} className="booking-card" to={`forgot-password`}>
                        Forgot Password ?
                    </Link>
                </Form>
                <p style={{ marginTop: '20px' }}>
                    {/* 👋 Excellence is not a skill. It’s an attitude. */}
                    {/* 👋 Strive to be your best version , Everyday !  */}
                    💪 Team work makes the dream work !
                </p>
                <div className="footer">
                    <Link style={{ flexBasis: '25%', color: 'grey' }} className="booking-card" to={`about`}>
                        About Us
                    </Link>
                    <Link style={{ flexBasis: '40%', color: 'grey' }} className="booking-card" to={`terms-and-conditions`}>
                        Terms and Conditions
                    </Link>
                    <Link style={{ flexBasis: '25%', color: 'grey' }} className="booking-card" to={`privacy`}>
                        Privacy
                    </Link>
                    <Link style={{ flexBasis: '40%', color: 'grey' }} className="booking-card" to={`refunds-cancellations`}>
                        Refunds/Cancellations
                    </Link>
                    <Link style={{ flexBasis: '25%', color: 'grey' }} className="booking-card" to={`contact-us`}>
                        Contact Us
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default withRouter(LoginPhone);
