import React, { useState, useContext, useEffect } from 'react';

import { Typography, List, Button, Row, Col, Dropdown, Menu } from 'antd';

import { DatePicker } from 'antd';

import moment from 'moment';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import { Spin } from 'antd';

import { LoadingOutlined, DownOutlined } from '@ant-design/icons';

import './daily-dashboard.scss';

const { Title, Text } = Typography;

const dateFormat = 'DD/MM/YYYY';

export default function DailyDashboard(props) {
    const { user } = useContext(GlobalContext);

    const [range, setRange] = useState(
        moment.tz('Asia/Calcutta').startOf('day')
    );

    const [option, setOption] = useState('transaction_date');

    const [currentLocation, setCurrentLocation] = useState(user.locations[0]);

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function updateTime(dt) {
        setRange(dt);
    }

    function handleMenuClick(e) {
        setCurrentLocation(e.key);
    }

    return (
        <section className="request-dashboard listing">
            <div className="">
                {/* <Title level={4}>Today's Status</Title> */}

                <Dropdown overlay={menu}>
                    <Button>
                        {currentLocation} <DownOutlined />
                    </Button>
                </Dropdown>
                <DatePicker
                    inputReadOnly
                    format={dateFormat}
                    value={range}
                    onChange={updateTime}
                    allowClear={false}
                />

                <div className="page-actions"></div>
            </div>
            <DailyDone option={option} range={range} url={currentLocation} />
        </section>
    );
}

const departments = [
    {
        name: 'Finance',
        steps: [
            {
                dept: 'finance',
                field: 'finance.data_collected_date',
                caption: 'Data Collected'
            },

            {
                dept: 'finance',
                field: 'finance.documents_collected_date',
                caption: 'Documents Collected'
            },

            {
                dept: 'finance',
                field: 'finance.logged_in_date',
                caption: 'Logged In'
            },

            {
                dept: 'finance',
                field: 'finance.finance_status_date',
                caption: 'Finance Status Updated'
            },

            {
                dept: 'finance',
                field: 'finance.do_date',
                caption: 'Delivery Order Issued'
            },

            {
                dept: 'finance',
                field: 'finance.disbursed_date',
                caption: 'Disbursed today'
            }
        ]
    },
    {
        name: 'Insurance',
        steps: [
            {
                dept: 'insurance',
                field: 'insurance.issued_date',
                caption: 'Issued today'
            },
            {
                dept: 'insurance',
                field: 'insurance.acknowledged_date',
                caption: 'Acknowledged today'
            },
            {
                dept: 'insurance',
                field: 'insurance.paid_date',
                caption: 'Payment Done'
            }
        ]
    },
    {
        name: 'Accessories',
        steps: [
            {
                dept: 'accessories',
                field: 'accessories.data_collected_date',
                caption: 'Data Collected'
            },

            {
                dept: 'accessories',
                field: 'accessories.fitted_date',
                caption: 'Fitment Done'
            },

            {
                dept: 'accessories',
                field: 'accessories.invoiced_date',
                caption: 'Invoiced'
            }
        ]
    },

    {
        name: 'RTO',
        steps: [
            {
                dept: 'rto',
                field: 'rto.issued_date',
                caption: 'Disclaimer Given'
            },

            {
                dept: 'rto',
                field: 'rto.paid_date',
                caption: 'E Challan Received'
            },

            { dept: 'rto', field: 'rto.tp_issued_date', caption: 'TP Issued' }
        ]
    },

    {
        name: 'Accounts',
        steps: [
            {
                dept: 'accounts',
                field: 'accounts.entered_date',
                caption: 'Entered'
            },
            {
                dept: 'accounts',
                field: 'accounts.settled_date',
                caption: 'Settled'
            }
        ]
    },
    {
        name: 'EDP',
        steps: [
            {
                dept: 'edp',
                field: 'allocation.allocated_date',
                caption: 'Allocated'
            },
            {
                dept: 'edp',
                field: 'billing.invoiced_date',
                caption: 'Invoiced'
            },
            {
                dept: 'edp',
                field: 'delivery.delivered_note_date',
                caption: 'Delivery Note'
            }
        ]
    },
    {
        name: 'Delivery Coordinator',
        steps: [
            {
                dept: 'delivary',
                field: 'delivery.delivered_note_date',
                caption: 'Delivery Note'
            },
            {
                dept: 'delivary',
                field: 'delivery.actual_delivery_date',
                caption: 'Delivery'
            }
        ]
    }

    // {
    //     name:'Fasttag',
    //     steps:[{dept:'fasttag',field:'fasttag.',caption:''},]
    // }
];

function DailyDone({ url, option, range }) {
    return (
        <div className="status-container">
            {departments.map((dept) => {
                return (
                    <div className="card status-card capitalize">
                        <Title level={5}>{dept.name}</Title>
                        {/* <Card title={dept.name} bordered={false}> */}

                        <div className="">
                            {
                                // ['finance', 'insurance', 'accessories', 'rto', 'fasttag']

                                dept.steps.map((dept) => (
                                    <DoneTasks
                                        dept={dept}
                                        url={url}
                                        range={range}
                                    />
                                ))
                            }
                            {/* </Card> */}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

function DoneTasks({ url, dept, range }) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        if (url === 'all') {
            getAllDone();
        } else {
            getDone();
        }

        return () => {};
    }, [url, range]);

    /**
     * Get Done Tasks
     */
    function getDone() {
        setLoading(true);
        var queries = [
            {
                field: dept.field,
                operator: '==',
                value: moment(range).valueOf()
            }
        ];
        FirebaseUtils.getRecords(url, queries, 'requests').then((result) => {
            // console.log('result', result);

            setList(result.requests);
            setLoading(false);
        });
    }

    /**
     * Get All
     */
    function getAllDone() {
        setLoading(true);
        var queries = [
            {
                field: dept.field,
                operator: '==',
                value: moment(range).valueOf()
            }
        ];

        FirebaseUtils.getAllRecords(queries, 'requests').then((result) => {
            setList(result.requests);
            setLoading(false);
        });
    }

    return (
        <div className="">
            {/* <Row gutter={16}>
                <Col span={8} xs={16} md={5}> */}
            <List>
                <List.Item>
                    <List.Item.Meta title={dept.caption} />
                    <div>
                        {loading ? <Spin indicator={antIcon} /> : list.length}
                    </div>
                </List.Item>
            </List>
            {/* </Col>
            </Row> */}
        </div>
    );
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
