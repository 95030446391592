/**
 * Refunds with Razorpay ,
 *
 * A value addition to the product
 *
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from './../../../../Store';

import { Button, Form, Select, message, Input, Typography, Radio, Alert } from 'antd';

import moment from 'moment';

import FirebaseUtils from './../../../../utils/firebase.utils';

import ApiUtils from './../../../../utils/api.utils';
import { Departments, Payments } from '../../../../models';

const { Title, Text } = Typography;

const { TextArea } = Input;

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

/**
 * Razorpay Refunds
 *
 * @author Marvan
 *
 */
export default function RefundPortal({ refresh, request, isBooking = false, cs_accounts }) {
    const { defaultBranch, user } = useContext(GlobalContext);

    // const [modes, setModes] = useState([]);

    const [form] = Form.useForm();

    const { customer = {} } = request;

    const [refundType, setRefundType] = useState('cash');

    const [loading, setLoading] = useState();

    const [disabled, setDisabled] = useState(false);

    const [department, setDepartment] = useState({});

    useEffect(() => {
        getDepartment()

            .then((department) => {
                // disable submit button
                if (cs_accounts && !(request.accounts && request.accounts.ac_code) && !(request.to_account && request.to_account.value)) {
                    setDisabled(true)
                }

            });

        return () => { };
    }, []);

    function getDepartment() {
        var query = [
            {
                field: 'dealer_code',
                value: request.dealerCode,
            },

            {
                field: 'description',
                value: 'Department Handling Sales',
            },
        ];

        return Departments.get(query).then((res) => {
            setDepartment(res.departments[0]);
            return res.departments[0];
        });
    }

    /**
     * On Submission of Refund Request
     *
     * @param {*} values
     */
    async function onSubmit(values) {
        setLoading(true);

        var cancelFlag = false;
        var mode = refundType;

        await Payments.refundLink(refundType, values, request, department, user, mode, cancelFlag, isBooking).then(() => {
            setLoading(false);

            refresh();
        });

        //     // For Bank Transfer
        //     // Via Razorpay
        //     if (refundType === 'instant-imps') {
        //         let token = await FirebaseUtils.getToken();
        //         let formBody = {
        //             name: values.name,
        //             email: values.email,
        //             contact: values.contact,
        //             ifsc: values.ifsc,
        //             account_number: values.account_number,
        //             amount: values.amount * 100,
        //             ...request,
        //             location: request.city,

        //             remarks: values.remarks,
        //             reference_id: `${request.dealerCode} - Booking - ${request.bookingNo}`,
        //         };

        //         var current = isBooking ? request.to_account.value : request.accounts.ac_code.value;

        //         var refName = isBooking
        //             ? request.name + ' / ' + request.phone
        //             : request.customer.customerName + ' / ' + request.customer.phone + ' / ' + request.allocation.vinNo;

        //         var customerDetail = isBooking ? request.to_account.value : request.accounts.ac_code.value;

        //         var params = {
        //             description: 'Customer Refund via Razorpay',
        //             ref: refName,
        //             amount: values.amount,
        //             customer: customerDetail,
        //         };

        //         params.mode = department.razorpay_account_head;
        //         formBody = {
        //             ...formBody,
        //             params: params,
        //             isBooking: false,
        //             department: department,
        //             user: user,
        //             token: token,
        //         };

        //         // Initiate createRefundRequest

        //         Payments.loadFunction('createRefundRequest', { formBody }).then((result) => {
        //             if (result.status === 'success') {
        //                 message.success('Refund process complete');

        //                 setLoading(false);

        //                 refresh();
        //             } else {
        //                 message.error(result.description);

        //                 setLoading(false);

        //                 refresh();
        //             }
        //         });
        //     } else if (refundType === 'instant-link') {
        //         // Payout Link via Razorpay

        //         let token = await FirebaseUtils.getToken();

        //         // For Payout
        //         let formBody = {
        //             name: values.name,
        //             email: values.email,
        //             contact: values.contact,
        //             amount: values.amount * 100,
        //             location: request.city,
        //             ...request,
        //         };

        //         // For Payment and CS Accounts Entry
        //         var current = isBooking ? request.to_account.value : request.accounts.ac_code.value;

        //         var refName = isBooking
        //             ? request.name + ' / ' + request.phone
        //             : request.customer.customerName + ' / ' + request.customer.phone + ' / ' + request.allocation.vinNo;

        //         var customerDetail = isBooking ? request.to_account.value : request.accounts.ac_code.value;

        //         var params = {
        //             description: 'Customer Refund via Razorpay',
        //             ref: refName,
        //             amount: values.amount,
        //             customer: customerDetail,
        //         };

        //         params.mode = department.razorpay_account_head;
        //         formBody = {
        //             ...formBody,
        //             params: params,
        //             isBooking: false,
        //             department: department,
        //             user: user,
        //             token: token,
        //         };

        //         //   Initiate the payout link

        //         Payments.loadFunction('initiatePayoutLink', { formBody }).then((result) => {
        //             if (result.status === 'success') {
        //                 message.success('Refund process complete');

        //                 setLoading(false);

        //                 refresh();
        //             } else {
        //                 message.error(result.error.description);

        //                 setLoading(false);

        //                 refresh();
        //             }
        //         });
        //     } else if (refundType === 'cash') {
        //         // for cancellation flow
        //         let formBody = {
        //             name: values.name,
        //             email: values.email,
        //             contact: values.contact,
        //             amount: values.amount,
        //             remarks: values.remarks,
        //         };

        //         // For Cash Payment
        //         var refName = isBooking
        //             ? request.name + ' / ' + request.phone
        //             : request.customer.customerName + ' / ' + request.customer.phone + ' / ' + request.allocation.vinNo;

        //         var customerDetail = isBooking ? request.to_account.value : request.accounts.ac_code.value;

        //         // Customer AC_ptr
        //         var params = {
        //             customer: customerDetail,
        //             description: 'Refund as Cash',
        //             ref: refName,
        //         };

        //         params = {
        //             ...params,
        //             amount: values.amount,
        //             payment_mode: 'cash',
        //             channel: 'cash',
        //         };

        //         // Get the mode of cash
        //         params.mode = form.getFieldValue().mode;

        //         // Initiate Payment Record in CS Acccounts
        //         return ApiUtils.createPayment(params, department)
        //             .then(() => {
        //                 return addPaymentEntry(params, formBody);
        //             })
        //             .catch(() => {
        //                 setLoading(false);
        //             });
        //     } else if (refundType === 'bank') {
        //         // for cancellation flow
        //         let formBody = {
        //             name: values.name,
        //             email: values.email,
        //             contact: values.contact,
        //             ifsc: values.ifsc,
        //             account_number: values.account_number,
        //             amount: values.amount * 100,
        //             remarks: values.remarks,
        //             reference_id: `${request.dealerCode} - Booking - ${request.bookingNo}`,
        //         };

        //         var refName = isBooking
        //             ? request.name + ' / ' + request.phone
        //             : request.customer.customerName + ' / ' + request.customer.phone + ' / ' + request.allocation.vinNo;

        //         var customerDetail = isBooking ? request.to_account.value : request.accounts.ac_code.value;

        //         // Normal Bank Transfer
        //         var current = isBooking ? request.to_account.value : request.accounts.ac_code.value;

        //         var params = {
        //             customer: customerDetail,
        //             description: 'Customer Refund via Bank Transfer',
        //             ref: refName,
        //         };

        //         params = {
        //             source: '',
        //             ...params,
        //             amount: values.amount,
        //             payment_mode: 'net banking',
        //             channel: 'bank',
        //         };

        //         // Get the mode of cash
        //         params.mode = form.getFieldValue().mode;
        //         // params.mode = request.from_account.value;

        //         // Initiate Payment Record in CS Acccounts
        //         return ApiUtils.createPayment(params, department)
        //             .then(() => {
        //                 return addPaymentEntry(params, formBody);
        //             })
        //             .catch((error) => {
        //                 console.log(error);

        //                 setLoading(false);
        //             });
        //     }
        // }

        // /**
        //  * Add a Payment Entry to Firestore
        //  * and Add a Cart Entry
        //  */
        // function addPaymentEntry(params, formBody) {
        //     //Prepare item to add to cart
        //     let creation = {
        //         created_at: moment().format('DD/MM/YYYY HH:mm'),
        //         created_date: moment().startOf('day').valueOf(),
        //         created_time: moment().valueOf(),
        //     };

        //     params = {
        //         bookingNo: request.bookingNo,
        //         requestId: request.id,
        //         dealerCode: request.dealerCode,
        //         ...params,
        //         ...creation,
        //     };

        //     if (!isBooking) {
        //         params = {
        //             ...params,
        //             customerID: request.customer.customerID,
        //             vinNo: request.allocation.vinNo,
        //         };
        //     } else {
        //         // params = {
        //         //     ...params,
        //         //     bookingNo: request.customer.customerID,
        //         //     vinNo: request.allocation.vinNo,
        //         // }
        //     }

        //     // Create a payment record in firestore
        //     return FirebaseUtils.createPayment(params)
        //         .then(() => {
        //             if (isBooking) {
        //                 setLoading(false);
        //                 refresh(params, formBody);
        //             } else {
        //                 let cartItem = {
        //                     bookingNo: request.bookingNo,
        //                     requestId: request.id,
        //                     customerID: request.customer.customerID,
        //                     type: 'additional',
        //                     vinNo: request.allocation.vinNo,
        //                     description: params.description,
        //                     price: params.amount,
        //                     dealerCode: request['dealerCode'],
        //                     ...creation,
        //                 };

        //                 return FirebaseUtils.addCartItem(cartItem).then(() => {
        //                     message.success('Refund process complete');

        //                     setLoading(false);

        //                     refresh();
        //                 });
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
    }

    const onRefundTypeChange = (e) => {
        setRefundType(e.target.value);

        // if(cs_accounts){
        //     loadModes(e.target.value);
        // }
    };

    /**
     * Load the Modes
     */
    // function loadModes(query, dept) {
    //     if (query === 'cash') {
    //         form.setFieldsValue({
    //             mode: dept.acCodes['Cash'],
    //         });
    //     } else {
    //         return ApiUtils.getModes('Net Banking', department).then((result) => {
    //             setModes(result);
    //         });
    //     }
    // }

    return (
        <>
            <Title level={4}>Initiate Refund</Title>

            <p>Fill the below form to create a request for refund.</p>

            <div style={{ marginBottom: '16px' }}>
                <Radio.Group onChange={onRefundTypeChange} value={refundType}>
                    <Radio value="cash">Cash</Radio>

                    <Radio value="bank">Net Banking</Radio>

                    <Radio value="instant-link">Payment Link by Razorpay</Radio>

                    <Radio value="instant-imps">Transfer by Razorpay</Radio>
                </Radio.Group>
            </div>

            {['cash', 'bank'].indexOf(refundType) !== -1 && cs_accounts ? (
                <>
                    <Alert
                        style={{ margin: '10px 0px' }}
                        message="Info"
                        description="This would create an entry on CS Accounts. For Cash/Bank transactions initiate a refund once the fund transfer is made."
                        type="info"
                        showIcon
                    />
                </>
            ) : null}

            <Form
                onFinish={onSubmit}
                form={form}
                {...layout}
                name="new-record"
                layout="vertical"
                initialValues={{
                    name: customer.customerName,
                    contact: customer.phone,
                    // amount: (ledgerReceiptTotal - ledgerCartTotal)
                }}
            >
                <Form.Item
                    name={'name'}
                    label="Customer Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Customer Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={'email'}
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={'contact'}
                    label="Contact"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Contact Phone number',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                {refundType === 'instant-imps' || refundType === 'bank' ? (
                    <>
                        <Form.Item
                            name={'account_number'}
                            label="Account Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter account number',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name={'ifsc'}
                            label="IFSC"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter IFSC code',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </>
                ) : null}

                {/* {refundType && refundType === 'bank' ? (
                    <>
                        <Form.Item
                            name={'mode'}
                            label="Mode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Mode',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                            >
                                {modes.map((item) => {
                                    return (
                                        <Option value={item.ac_code} key={item.ac_code}>
                                            {item.ac_desc} - {item.ac_mode}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </>
                ) : (
                    <></>
                )} */}

                <Form.Item
                    name={'amount'}
                    label="Amount"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter amount',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={'remarks'}
                    label="Remarks"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter remarks',
                        },
                    ]}
                >
                    <TextArea />
                </Form.Item>

                {isBooking && cs_accounts ? (
                    !(request.to_account && request.to_account.value) ? (
                        <>
                            <Alert
                                style={{ margin: '10px 0px' }}
                                message="Account Head Missing"
                                description="You have to update the account head of this customer to proceed with easy refunds"
                                type="error"
                                showIcon
                            />
                        </>
                    ) : null
                ) : !(request.accounts && request.accounts.ac_code) && cs_accounts ? (
                    <>
                        <Alert
                            style={{ margin: '10px 0px' }}
                            message="Account Head Missing"
                            description="You have to update the account head of this customer to proceed with easy refunds"
                            type="error"
                            showIcon
                        />
                    </>
                ) : null}

                <Button
                    disabled={disabled}
                    loading={loading}
                    htmlType="submit"
                    key="submit"
                    type="primary"
                >
                    Submit
                </Button>
            </Form>
        </>
    );
}
