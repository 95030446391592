import React, { useState, useEffect, useContext } from 'react';

import { Alert, Skeleton } from 'antd';

import FirebaseUtils from '../../../../../../utils/firebase.utils';

// import './reconcile-summary.scss';

import PendingReceipt from './../pending-receipt/pending-receipt';

/**
 *
 * Component would list unverified collections of same amount
 *
 * @param {*} param0
 * @returns
 */
export default function ReceiptSelection({ receipt, callback, allDepartments }) {
    const [receipts, setReceipts] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getPendingReceipts();
    }, []);

    function getPendingReceipts(receipts) {
        console.log(receipts);

        var queries = [
            {
                field: 'verified',
                value: false,
            },
            {
                field: 'amount',
                value: parseFloat(receipt.credit),
            },
        ];

        setLoader(true);

        FirebaseUtils.getAllCollections(queries).then((result) => {
            setLoader(false);

            let filtered = result.all.filter((r) => r.receiptId !== receipt.receiptId);

            var receipts = filtered.map((entry) => {
                let receiptDepartment = allDepartments.filter((dep) => dep.id === entry.department_id);

                entry.department = receiptDepartment[0];

                return { ...entry };
            });

            setReceipts(receipts);
        });
    }

    return (
        <div>
            {loader ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    {receipts.length ? (
                        <Alert
                            message="Below is a customer collection that matches the same amount credited in bank. If you confirm that the credited amount is of the below customer with the proofs attached , Submit it with remarks so that it can proceed to EDP for DMS entry."
                            type="info"
                            showIcon
                            closable
                        />
                    ) : (
                        <Alert
                            message="There are no collections submitted by KEC's or the Finance team matching the same amount. If you are sure this is a customer credit, send this to sales team for identification."
                            type="info"
                            showIcon
                            closable
                        />
                    )}

                    {receipts
                        .filter((col) => !col.selected)
                        .map((entry, index) => {
                            return (
                                <PendingReceipt
                                    callback={callback}
                                    key={index}
                                    actualReceipt={receipt}
                                    collection={entry}
                                    allDepartments={allDepartments}
                                />
                            );
                        })}
                </>
            )}
        </div>
    );
}
