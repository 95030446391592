import React, { useEffect, useState, Fragment, useContext } from "react";

import { Button, Tag, Tabs, Image, Row, Select, Form, Input, Radio, message, Typography, Upload } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder'

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import UpdateStatusForm from "../update-status-form";

import RequestInfo from '../../../../components/request-info/request-info';
import MatchingReceipts from '../../../../components/matching-receipts/matching-receipts';

import RequestForm from '../../../common/resources/request-form/request-form';


import './booking-form.scss';

import { CheckCircleOutlined } from '@ant-design/icons';

import CommentBlock from '../../../common/components/comment-block/comment-block';


import { HighlightOutlined, SmileOutlined, SmileFilled, UploadOutlined } from '@ant-design/icons';
import { Switch } from "react-router-dom";

import { BookingPosition } from './../../kec-config';

import BookingInfo from "../../../../components/booking-info/booking-info";

import FormCreator from './../../../common/resources/form-creator/form-creator';


const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }

    },
};

const { Option } = Select;

const { Paragraph, Title } = Typography;


export default function BookingForm({ match, step, history }) {

    const { user } = useContext(GlobalContext);

    const [content, setContent] = useState({});

    const [request, setRequest] = useState({ insurance: {}, customer: {} });

    const { params } = match;

    const { city, id } = params;

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        loadData();

    }, [])

    function loadData() {

        setLoader(true);

        FirebaseUtils.getListingRecord(id, 'bookings').then((result) => {


            // let data = { ...{ insurance: {} }, ...result };

            console.log('RR', result.data());

            setRequest(result.data());

            setLoader(false);

        });

    }


    return (
        <div className="insurance-form">

            {
                loader ?
                    <PlaceHolder type="report" />
                    :
                    <Fragment>

                        <div className='page-content'>

                            <h1 className="page-heading">
                                {step.caption}
                            </h1>


                            <div className="split-container">

                                <div className="left">

                                    {/* Vehicle Card */}
                                    <BookingInfo booking={request} />

                                </div>

                                <div className="right">


                                    {/* Vehicle Card */}

                                    <div className="vehicle-card">

                                        <Title level={4}>
                                            {step.caption}
                                        </Title>


                                        <p>
                                            Please check with the customer and update the below status
                                        </p>

                                        {request.billing && request.billing.status === 'pending' ? (
                                            <>
                                                <BookingPosition city={city} booking={request} />
                                            </>
                                        ) : null}


                                        {step.caption === 'Booking Status Form' ?
                                            <UpdateStatusForm formContent={request}/>
                                            
                                           : <BookForm
                                                city={city}
                                                formContent={request}
                                                modelIndex='Booking No'
                                                model={step.model}
                                                step={step}
                                                callback={() => {

                                                    history.goBack();

                                                }}
                                            />}

                                    </div>


                                    {/* <CommentBlock id={request.id} /> */}

                                </div>
                            </div>


                        </div>


                    </Fragment>}
        </div>
    )
}



function BookForm({ history, city, step, model, formContent, modelIndex, callback }) {

    const [content, setContent] = useState(formContent);

    const onDataCollection = (values) => {

        let params = {
            [model]: {
                ...content[model],
                ...values,
                status: step.post_status
            },
        }

        if (step.extraParams) {

            params = {
                ...params,
                // Note extra Params will override every previous value if the same key is used 
                ...step.extraParams(content, params[model])
            }
        }

        return FirebaseUtils.updateBookingGeneric(content[modelIndex], params, `${model} update :`, values.remarks, 'booking').then(() => {

            message.success(`${model} details updated`);

            callback(params, values);

        });

    }

    return (
        <section className="request-form">

            {
                step.fields && step.fields.length
                    ?
                    <FormCreator
                        config={step}
                        formContent={formContent}
                        modelIndex={modelIndex}
                        model={model}
                        onSubmit={onDataCollection}

                    />
                    :
                    null
            }

        </section>
    );
}