import React,{useState, useContext, useEffect} from 'react';

import {Space, Popconfirm, Tag, Button, Card, Table, Typography, message, Skeleton} from 'antd'

import { GlobalContext } from '../../Store';

import FirebaseUtils from '../../utils/firebase.utils';

import moment from 'moment';

const { Title } = Typography;


/**
 * Customer Refunds
 *
 * @returns
 */
export default function CustomerRefunds({ request }) {
    const [loading, setLoading] = useState(false);

    const [entries, setEntries] = useState([]);

    const { user = {} } = useContext(GlobalContext);

    const columns = [
        {
            title: 'Sl No',
            render: (value, item, index) => {
                return <div>{index + 1}</div>;
            },
        },

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (text, record) => {
                return <div>{text}</div>;
            },
        },

        {
            title: 'Status',
            // dataIndex: 'price',
            render: (text, record) => {
                var color = 'orange';

                var status = 'processing';

                if (record.payment_mode === 'cash') {
                    color = 'green';

                    status = 'Done';
                } else {
                    if (record.payment_mode === 'payout') {
                        // Record status
                        status = record.status.status;

                        if (record.status.status === 'processed') {
                            color = 'green';
                        } else {
                        }
                    }
                }

                return <Tag color={color}>{status}</Tag>;
            },
        },

        {
            title: 'Transaction Date',
            dataIndex: 'created_date',
            render: (text, record) => {
                return <div>{moment(text).format('DD/MM/YYYY')}</div>;
            },
        },
    ];

    if (['accounts', 'admin'].indexOf(user.role) !== -1) {
        columns.push({
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure you want to cancel refund ? "
                        onConfirm={() => cancelRefund(record)}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary">Cancel</Button>
                    </Popconfirm>
                </Space>
            ),
        });
    }

    /**
     * Cancel Refund
     */
    function cancelRefund(record) {
        var item = {
            ...record.status,
            status: 'cancelled',
        };
        FirebaseUtils.cancelRefund(record.id, item).then(() => {
            message.success('Refund has been cancelled successfully!');
            getRefunds();
        });
    }

    useEffect(() => {
        getRefunds();
    }, []);

    function getRefunds() {
        setLoading(true);

        var queries = [
            {
                field: 'bookingNo',
                value: request.bookingNo,
            },
            {
                field: 'dealerCode',
                value: request.dealerCode,
            },
        ];

        FirebaseUtils.getBaseRecords(queries, 'payments').then((result) => {
            // setEntries(result.payments);

            Promise.all(result.payments.map(getRefundStatus)).then((final_result) => {
                let item = [];

                final_result.map((result) => {
                    if (!result.item) {
                        item.push(result);
                    } else if (result.item.status != 'cancelled') {
                        item.push(result);
                    }
                });

                setEntries(item);

                setLoading(false);
            });
        });
    }

    /**
     * Get the current refund status of the customer
     *
     * @param {*} entry
     * @returns
     */
    function getRefundStatus(entry) {
        return new Promise((resolve, reject) => {
            if (entry.transaction_id) {
                let formBody = {
                    id: entry.transaction_id,
                    mode: 'IMPS',
                    location: request.city,
                    payment_mode: entry.payment_mode,
                };

                FirebaseUtils.getRefundStatus({ formBody: formBody }).then((result) => {
                    entry.status = result.data;

                    resolve(entry);
                });
            } else {
                resolve(entry);
            }
        });
    }

    return (
        <>
            {loading ? (
                <>
                    <Skeleton />
                </>
            ) : (
                <>
                    {entries.length ? (
                        <Card>
                            <Title level={3}>Refunds</Title>

                            <Table dataSource={entries} columns={columns} pagination={false} />
                        </Card>
                    ) : null}
                </>
            )}
        </>
    );
}