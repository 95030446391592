import React, { useState, useEffect, useContext } from 'react';

import { CheckCircleTwoTone } from '@ant-design/icons';

import { Typography, message, Progress, Alert } from 'antd';
import { Form, Input, Button, Tag } from 'antd';
import { Collapse } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import Rupee from './../../../../components/rupee/rupee';

import FirebaseUtils from './../../../../utils/firebase.utils';

import XLSX from 'xlsx';

import './fund-gap.scss';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 }
};
const tailLayout = {
    wrapperCol: { span: 16 }
};

const { Panel } = Collapse;

const { Title } = Typography;

var utilised = {};

export default function FundGap(props) {
    const [loading, setLoading] = useState(true);

    let [active, setActive] = useState('0');

    const [master, setMaster] = useState({
        carnival: { lastUpdated: null },
        seltos: { lastUpdated: null },
        sonet: { lastUpdated: null },
        carens:{ lastUpdated:null},
        ev6:{lastUpdated:null}
    });

    const [stock, setStock] = useState({
        kozhikode: { values: {} },
        kannur: { values: {} },
        malappuram: { values: {} },
        kondotty:{values:{}},
        vadakara:{values:{}},
        tirur:{values:{}},
        kasargod:{values:{}}
    });

    const [custom, setCustom] = useState([]);

    useEffect(() => {
        getMaster();
    }, []);

    /**
     *
     * Get the price master
     *
     * @param {*} model
     */
    function getMaster() {
        FirebaseUtils.getListing(
            null,
            [],
            'pricing'
        ).then((result) => {
            console.log(result);

            setMaster(result);

            getStock(result.pricing);
        });

        // FirebaseUtils.getMaster().then((result) => {

        //   console.log(result);

        //   setMaster({ ...result.master });

        //   getStock(result.master);

        // })
    }

    /**
     * Get all the Stock Data
     */
    function getStock(master) {
        var stockValue = { kozhikode: 0, kannur: 0, malappuram: 0 };

        FirebaseUtils.getAllStock(true).then((result) => {
            console.log(result);

            // For each city , find the total price of all cars
            Object.keys(result.stock).forEach((city) => {
                var total = 0;

                var totalPrice = 0;

                // Scan all vehicles of each city
                var vehicles = Object.keys(result.stock[city]);

                var arr = [];

                vehicles
                    .filter((entry) => {
                        return ["remove", "bbnd"].indexOf(result.stock[city][entry].update) === -1;
                    })
                    .forEach((vehicle) => {
                        // This is the vehicle now
                        var entry = result.stock[city][vehicle];

                        console.log(entry.update);

                        var basicPrice = entry['Basic Price'];

                        // Get the Model
                        var model = entry['Model'].toLowerCase();

                        // From the price master find the matching variant
                        var pricing = master.filter(
                            (item) =>
                                item.key ===
                                entry['Variant Code'] +
                                '-' +
                                entry['Color Type']
                        )[0];

                        if (pricing) {
                            var igst = pricing['igst'];

                            var cess = pricing['cess'];

                            //var tcs = 1 / 100;

                            // Find the price of vehicle with tax
                            // totalPrice += basicPrice + ((basicPrice * igst) / 100) + ((basicPrice * cess) / 100) + (basicPrice * tcs);

                            var amount =
                                basicPrice +
                                (basicPrice * igst) / 100 +
                                (basicPrice * cess) / 100;

                            totalPrice = totalPrice + amount

                            // totalPrice = totalPrice + amount + amount * tcs;

                            // stockValue[city] += parseFloat(totalPrice);

                            // total += parseInt(entry['Basic Price']);

                            // stockValue[city] += parseFloat(totalPrice);
                        } else {
                            arr.push(entry);
                        }
                    });

                setCustom([...arr]);

                stock[city] = {
                    values: {},
                    total: totalPrice,
                    vehicles: result.stock[city]
                };

                // stock[city] = { total: total, vehicles: result.stock[city] };
            });

            setStock({ ...stock });

            getApprovedValues();

            getBankCredits()
        });
    }

    /**
     *
     */
    function getApprovedValues() {
        FirebaseUtils.getApprovedLoans().then((result) => {
            let data = result.data();

            console.log(data, stock);

            Object.keys(data).forEach((city) => {
                stock[city].values['approved'] = data[city];
            });

            setStock({ ...stock });


        });
    }


    {
        /**
         * Get Bank Credits
         */
    }

    function getBankCredits() {

        //Get bank credits of Kozhikode

        var configclt = {
            order: 'transaction_date',
            direction: 'desc',
            limit: 1,
            orderBy2: 'created_at',
            mode: '1060012'
        };
        FirebaseUtils.getBankCredits([], configclt).then((result) => {

            console.log(result)
            if (result[0] && result[0].balance)
                utilised['kozhikode'] = -result[0].balance

        });

        // Get bank credits of kannur
        var configknr = {
            order: 'transaction_date',
            direction: 'desc',
            limit: 1,
            orderBy2: 'created_at',
            mode: '1030006'
        }
        FirebaseUtils.getBankCredits([], configknr).then((result) => {

            console.log(result)

            if (result[0] && result[0].balance)
                utilised['kannur'] = -result[0].balance

        });

        //Get bank credits of Malappuram
        var configmlp = {
            order: 'transaction_date',
            direction: 'desc',
            limit: 1,
            orderBy2: 'created_at',
            mode: '1030007'
        }
        FirebaseUtils.getBankCredits([], configmlp).then((result) => {

            console.log(result)

            if (result[0] && result[0].balance)
                utilised.malappuram = -result[0].balance

            Object.keys(utilised).forEach((city) => {
                stock[city].values['utilised'] = utilised[city];
            });

            setLoading(false);

        });

    }

    function handleFile(event, model) {
        var files = event.target.files,
            f = files[0];

        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {
            const bstr = e.target.result;

            // var data = new Uint8Array(e.target.result);

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            // var workbook = XLSX.read(data, { type: 'binary' });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws, { header: 2 });

            // console.log(data);

            prepareData(data, model);
        };

        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
        // reader.readAsBinaryString(f);
    }

    function prepareData(data, model) {
        var special = [
            '__EMPTY',
            '__EMPTY_1',
            '__EMPTY_3',
            '__EMPTY_4',
            '__EMPTY_5',
            '__EMPTY_6'
        ];

        const header = data[0];

        data.splice(0, 1);

        var formatted = {};

        data.forEach((entry) => {
            // Update the keys which has an error
            special.forEach((key) => {
                entry[header[key]] = entry[key];
            });

            // return entry;

            formatted[entry['Variant']] = entry;
            formatted[entry['Variant']] = {
                ...formatted[entry['Variant']],
                deleted_at: null,
                deleted_by: null

            }

        });

        console.log(formatted);

        master[model] = formatted;

        setMaster({ ...master });

        console.log(master);

        FirebaseUtils.addPrice(model, formatted).then((result) => {
            console.log(result);
        });
    }

    /**
     * Apply the values to current days values
     */
    function applyValues(values, city) {
        stock[city].verified = true;
        stock[city].values = values;

        setStock({ ...stock });

        console.log(stock);

        setActive(++active);
    }

    function expand(city, index) {
        stock[city].verified = false;

        setActive(index.toString());
    }

    function submitReport() {
        Object.keys(stock).forEach((city) => {
            stock[city].vehicleCount = Object.keys(stock[city].vehicles).length;

            delete stock[city].vehicles;
        });

        console.log(stock);

        FirebaseUtils.pushIFGap(stock).then(() => {
            message.success('Your report has been submitted');
        });
    }

    const SheetJSFT = [
        'xlsx',
        'xlsb',
        'xlsm',
        'xls',
        'xml',
        'csv',
        'txt',
        'ods',
        'fods',
        'uos',
        'sylk',
        'dif',
        'dbf',
        'prn',
        'qpw',
        '123',
        'wb*',
        'wq*',
        'html',
        'htm'
    ]
        .map(function (x) {
            return '.' + x;
        })
        .join(',');

    return (
        <div className="fund-gap">
            <Title level={3}>Analyze Fund Gap</Title>

            <Alert
                message="Please make sure that the IF Status is submitted everyday using the below form."
                type="warning"
                showIcon
                closable
            />

            {/* 
      <div className="upload-section">

        {
          ['carnival', 'sonet', 'seltos'].map((model, key) => {

            return (<div key={key} className="upload-card card">

              <Title level={4}>{model}</Title>


              <form encType="multipart/form-data">
                <input id="f02" placeholder="Select Carnival" accept={SheetJSFT} type="file" name="files[]" onChange={(event) => { handleFile(event, model) }} />
              </form>

              {master[model] ? <CheckCircleTwoTone className="icon" style={{ fontSize: '25px', color: '#08c' }} twoToneColor="#52c41a" /> : null}

      
            </div>)

          })
        }

      </div> */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <div className="split-container">
                    <div className="left">

                        {/*  */}
                        {custom.length ? (
                            <>
                                <Alert
                                    message={`We could not find pricing for ${custom.length} models. Please calculate seperately`}
                                    type="error"
                                    showIcon
                                    closable
                                />

                                {custom.map((vehicle) => <Tag color="red">{vehicle['Vin Number']}-{vehicle['Variant']}-{vehicle['Variant Code']}</Tag>)}

                            </>


                        ) : null}
                        {/*  */}

                        <Collapse
                            className="form-container"
                            activeKey={active}
                            accordion={false}
                            defaultActiveKey={['0']}
                            onChange={() => { }}
                        >
                            {Object.keys(stock).map((city, index) => (
                                <Panel
                                    key={index}
                                    showArrow={false}
                                    header={
                                        <PanelHeader
                                            current={stock}
                                            city={city}
                                            expand={() => {
                                                expand(city, index);
                                            }}
                                        />
                                    }
                                >
                                    <IFForm
                                        current={stock[city]}
                                        applyValues={(values) => {
                                            applyValues(values, city);
                                        }}
                                    />
                                </Panel>
                            ))}
                        </Collapse>
                    </div>

                    <div className="right">
                        {Object.keys(stock).filter((key) => stock[key].verified)
                            .length ? (
                            <div className="summary card ">
                                <h1>Summary</h1>

                                {Object.keys(stock)
                                    .filter((key) => stock[key].verified)
                                    .map((id) => {
                                        let city = stock[id];

                                        console.log(city, id);

                                        let gap =
                                            parseFloat(city.total) +
                                            parseFloat(city.values.advance) -
                                            parseFloat(city.values.utilised);

                                        console.log(
                                            city.total,
                                            city.values.advance,
                                            city.values.utilised,
                                            gap
                                        );

                                        return (
                                            <div className="box">
                                                <div className="left">
                                                    <h1 className="city">
                                                        {id}
                                                    </h1>

                                                    <p>
                                                        {city.vehicleCount}{' '}
                                                        Vehicles
                                                    </p>
                                                </div>

                                                <div className="right">
                                                    <div className>
                                                        <div>
                                                            <h3>Fund Gap</h3>

                                                            <h2>
                                                                <Rupee
                                                                    value={gap}
                                                                ></Rupee>
                                                            </h2>
                                                        </div>

                                                        <div className="usage">
                                                            <Progress
                                                                percent={Math.round(
                                                                    (city.values
                                                                        .utilised /
                                                                        city
                                                                            .values
                                                                            .approved) *
                                                                    100
                                                                )}
                                                            />

                                                            <p>
                                                                <Rupee
                                                                    value={
                                                                        city
                                                                            .values
                                                                            .utilised
                                                                    }
                                                                ></Rupee>{' '}
                                                                Utilised /{' '}
                                                                <Rupee
                                                                    value={
                                                                        city
                                                                            .values
                                                                            .approved
                                                                    }
                                                                ></Rupee>{' '}
                                                                Approved
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="stats">
                                                        <div>
                                                            <h3>Available</h3>

                                                            <h2>
                                                                <Rupee
                                                                    value={
                                                                        city
                                                                            .values
                                                                            .approved -
                                                                        city
                                                                            .values
                                                                            .utilised
                                                                    }
                                                                ></Rupee>
                                                            </h2>
                                                        </div>

                                                        <div>
                                                            <h3>Advance</h3>

                                                            <h2>
                                                                <Rupee
                                                                    value={
                                                                        city
                                                                            .values
                                                                            .advance
                                                                    }
                                                                ></Rupee>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : null}
                    </div>
                </div>
            )}

            <Button onClick={submitReport} type="primary" htmlType="submit">
                Submit Report
            </Button>
        </div>
    );
}

function PanelHeader({ current, city, expand }) {
    return (
        <div className="panel-header" onClick={expand}>
            <div className="left-title">
                <div className="check-icon">
                    <CheckCircleTwoTone
                        className="icon"
                        style={{ fontSize: '25px' }}
                        twoToneColor={`${current[city].verified
                            ? '#52c41a'
                            : 'rgb(202 202 202)'
                            }`}
                    />
                </div>
                <div className="panel-title">
                    <h1>{city}</h1>
                    <p>
                        <small>
                            {
                                Object.keys(
                                    current[city].vehicles.filter(
                                        (ele) => ele.update !== 'remove'
                                    )
                                ).length
                            }{' '}
                            Vehicles
                        </small>
                    </p>
                </div>
            </div>

            <div className="price-total">
                <h1>
                    <Rupee value={current[city].total}></Rupee>
                    {/* {Math.round()} */}
                </h1>
                <p>
                    <small>Stock Value</small>
                </p>
            </div>
        </div>
    );
}

function IFForm({ current, applyValues }) {
    console.log(current);

    const onFinish = (values) => {
        console.log('Success:', values);
        applyValues(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            layout="vertical"
            {...layout}
            name="basic"
            initialValues={{
                approved: current.values.approved,
                utilised: current.values.utilised
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                label="OD Approved"
                name="approved"
                rules={[
                    { required: true, message: 'Please input your username!' }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="OD Utilised"
                name="utilised"
                rules={[
                    { required: true, message: 'Please input your username!' }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Advance Taken"
                name="advance"
                rules={[
                    { required: true, message: 'Please input your password!' }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}
