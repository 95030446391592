import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Button, Row, Tag, Form, Input, Select, Upload, Image, Popconfirm, message } from 'antd';

import moment from 'moment';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FileUpload from './../../../../components/file-upload/file-upload';

import ApiUtils from '../../../../utils/api.utils';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

// import './billing-form.scss';

import RequestForm from './../../../common/resources/request-form/request-form';

import BaseForm from './../../../common/resources/base-form/base-form';

import { UploadOutlined } from '@ant-design/icons';

import { Departments, StockTransfers } from '../../../../models';
import RecordForm from '../../../common/resources/record-form/record-form';
import StockTransfer from '../../../../models/stock_transfers';
import { dealerCodeKey } from '../../../global-config';

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};

const { Option } = Select;

export default function CustomerPayment({ step, match, field, history }) {
    const [form] = Form.useForm();

    const { user, dispatch } = useContext(GlobalContext);

    const [selected, setSelected] = useState(null);

    //const [options, setOptions] = useState(branches);

    const [userRequest, setuserRequest] = useState({});

    const { params } = match;

    const { id, city } = params;

    const [loader, setLoader] = useState(true);

    const [departments, setDepartments] = useState([]);

    const [selectedDepartment, setSelectedDepartment] = useState({});

    var dealerKey = dealerCodeKey
    // useEffect(() => {
    //     setOptions(branches);

    //     setSelected(selectedDepartment.code);
    // }, [branches]);

    useEffect(async () => {
        await getDepartment();
        // await loadData();
    }, []);

    function loadData(departments) {
        setLoader(true);
        if (step.endpoint === 'cancellations') {
            FirebaseUtils.getBaseRecord(id, step.model).then(async (result) => {
                var request = result;

                console.log(result);

                await setuserRequest({ ...request });

                var selectedDep = await departments.filter((dep) => dep.location === dealerKey[request.dealerCode]);

                await setSelectedDepartment(selectedDep[0]);

                await setLoader(false);
            });
        } else if (step.endpoint === 'stock_transfers') {
            FirebaseUtils.getRecord(step.endpoint, id).then(async (result) => {
                var request = result;
                // get stock transfer details using stock transfer id . 
                let stocktransfer = await StockTransfers.getRecordStock(result.id);

                await setuserRequest({ ...stocktransfer });

                var selectedDep = await departments.filter((dep) => dep.location === dealerKey[request.dealerCode]);

                await setSelectedDepartment(selectedDep[0]);

                await setLoader(false);
            });
        } else if (step.endpoint === 'customer_entries') {
            FirebaseUtils.getRecord(step.endpoint, id).then(async (result) => {
                var entry = result;

                await setuserRequest({ ...entry });

                var selectedDep = await departments.filter((dep) => dep.location === dealerKey[entry.dealerCode]);

                await setSelectedDepartment(selectedDep[0]);

                await setLoader(false);
            });
        } else {
            FirebaseUtils.getRequest(id).then(async (result) => {
                var request = result;

                console.log(request);

                // request.invoice = {};

                await setuserRequest({ ...request });

                var selectedDep = await departments.filter((dep) => dep.location === request.city);

                await setSelectedDepartment(selectedDep[0]);

                await setLoader(false);

                // There are below cases

                // Booking is identified, Get booking
            });
        }
    }
    async function getDepartment() {
        var query = [
            {
                field: 'description',
                value: 'Department Handling Sales',
            },

            {
                field: 'id',
                operator: 'in',
                value: user.department_ids,
            },
        ];
        return await Departments.get(query)
            .then((res) => {
                setDepartments(res.departments);

                return res.departments;
            })
            .then((depts) => {
                loadData(depts);
            });
    }

    async function selectBranch(branchId, arg) {
        let matching = departments.filter((item) => item.id === branchId).pop();

        setSelectedDepartment(matching);

        setSelected(branchId);
    }

    function alreadyCredited() {
        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            txn_date: moment().format('DD/MM/YYYY HH:mm'),
            transaction_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(), // this can be removed if not needed
        };

        var params = {
            refund_done: true,
            refund_confirmation: {
                name: user.name,
                ...creation,
            },
            status: 'Refund Confirmation Pending',
        };

        FirebaseUtils.updateRecord('cancellations', userRequest.id, params).then(() => {
            let bookingParams = {
                cancellation: {
                    id: userRequest.id,
                    status: 'Refund Confirmation Pending',
                },
            };

            return FirebaseUtils.updateBookingGeneric(userRequest.bookingId, bookingParams).then(() => {
                message.success('Marked as refund done');
                history.goBack();
            });
        });
    }

    return (
        <div className="review-request-detail">
            {/* <Header onBack={() => props.history.goBack()} /> */}

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    {step.endpoint === 'cancellations' ? (
                        <>
                            <div className="vehicle-card">
                                <div className="about-us page-content">
                                    <h1 className="page-heading">Cancel {userRequest.type}</h1>
                                </div>
                                <p>Update any necessary information and see the status of your allocation below</p>
                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Customer Name </h3>
                                        </Row>

                                        <Row span={12}>
                                            <h2 className="title amount">{userRequest.customerName}</h2>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Email :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.email}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Booking No </h3>
                                        </Row>
                                        <Row span={12}>
                                            <a href={`/${city}/bookings/${userRequest.bookingId}`}>{userRequest.bookingId}</a>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Dealer Code :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.dealerCode}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Phone No :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.phone}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Attachments :</h3>
                                        </Row>
                                        <>
                                            {userRequest.cancellation_request &&
                                                userRequest.cancellation_request.map((file) => {
                                                    return (
                                                        <>
                                                            {file.type === 'application/pdf' ? (
                                                                <embed src={file.url} width="500" height="375" type="application/pdf" />
                                                            ) : (
                                                                <Image width={200} src={file.url} />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                        </>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Reason :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.reason}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Remarks :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{userRequest.remarks}</span>
                                        </Row>
                                    </div>
                                    {userRequest.sm_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from SM :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.sm_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {userRequest.ccm_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from CCM :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.ccm_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {userRequest.mgt_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from Management :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.mgt_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Status </h3>
                                        </Row>
                                        <Row span={12}>
                                            <span>{userRequest.status}</span>
                                        </Row>
                                    </div>
                                    <Popconfirm
                                        title="Are you sure you want to mark this refund as completed ? "
                                        onConfirm={() => {
                                            alreadyCredited();
                                        }}
                                        onCancel={() => { }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="secondary">Already Credited ?</Button>{' '}
                                    </Popconfirm>
                                </div>
                            </div>

                            <h3>Branch </h3>

                            <Select
                                onSelect={selectBranch}
                                value={selected}
                                placeholder="Branch"
                                style={{
                                    width: '200px',
                                    margin: '0px 10px',
                                }}
                            >
                                {departments.map((doc, index) => (
                                    <Option value={doc.id} key={doc.code}>
                                        {doc.name}
                                    </Option>
                                ))}
                            </Select>

                            <BaseForm
                                index={id}
                                formContent={userRequest}
                                modelIndex="id"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                                department={selectedDepartment}
                            />
                        </>
                        //  Stock transfer setion start 
                    ) : step.endpoint === 'stock_transfers' ? (
                        <div className="vehicle-card">
                            <div className="vehicle-image-wrapper">{/* <img src={images[file['model']]} alt="vehicle-img" /> */}</div>
                            <div gutter={0} className="detail-wrapper">
                                <h1 className="title amount ">StockTransfer Request</h1>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Vin Number</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{userRequest['vinNo']}</h3>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>From</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{userRequest['from']}</h3>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>To</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{userRequest['to']}</h3>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>From Yard</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{userRequest['from_yard_name']}</h3>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>To Yard</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{userRequest['to_yard_name']}</h3>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>Created At</Row>
                                    <Row span={12}>
                                        <h3 className="">{userRequest['created_at']}</h3>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        //  Stock transfer setion end
                    ) : step.endpoint === 'customer_entries' ? (
                        <>

                            <div className="about-us page-content">
                                <h1 className="page-heading">
                                    {step.caption}

                                </h1>
                            </div>

                            <div gutter={0} className="detail-wrapper">
                                {/* <Tag color="purple">
                                            {userRequest.status}
                                        </Tag> */}
                                <div className="detail-element">
                                    <Row span={12}>
                                        <Row span={12}>
                                            <h3>Customer ID </h3>
                                        </Row>
                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title amount">{userRequest.customerID} </h2>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <Row span={12}>
                                            <h3>Amount </h3>
                                        </Row>
                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title amount">{userRequest.amount} </h2>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>On account of</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.account}</span>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Phone </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.phone}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Remarks </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.remarks}</span>
                                    </Row>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="about-us page-content">
                                <h1 className="page-heading">
                                    {step.caption}
                                    {/* 
                                                {
                                                    step.field === 'insurance.status' ?
                                                        <>
                                                            Insurance Payment
                                                        </>
                                                        :
                                                        <>
                                                            Tax Payment
                                                        </>
                                                } */}
                                </h1>
                            </div>
                            <div gutter={0} className="detail-wrapper">
                                {/* <Tag color="purple">
                                            {userRequest.status}
                                        </Tag> */}
                                <div className="detail-element">
                                    <Row span={12}>
                                        <Row span={12}>
                                            <h3>Customer </h3>
                                        </Row>
                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title amount">{userRequest.customer.customerName} </h2>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Phone </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.customer.phone}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Remarks </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.customer.remarks}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Variant </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.variant}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Color</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{userRequest.color}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Consultant :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{userRequest.kec}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Team Leader :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{userRequest.teamLeader}</span>
                                    </Row>
                                </div>
                                <Link to={`/requests/${userRequest.id}`}>View Request</Link>
                            </div>

                            <RequestForm
                                formContent={userRequest}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                                department={selectedDepartment}
                            />
                        </>
                    )}

                    {step.endpoint === 'stock_transfers' ||step.endpoint==='customer_entries' ? (
                        <>
                            <RecordForm
                                index={id}
                                formContent={userRequest}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : null}
                </Fragment>
            )}
        </div>
    );
}
