
import React, { useState, useEffect, useContext } from "react";

import { GlobalContext } from '../../../../Store';

import { message, Checkbox, Popconfirm, Row, Tabs, Modal, Button, Tag, Divider, Typography, Image, Select, Badge, Alert } from "antd";

import { Form, Input } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import moment from 'moment';

import './verify-payment.scss';

const { TabPane } = Tabs;

const { Option } = Select;

const { Title, Text } = Typography;

export default function ReconcileSummary({ history }) {

    return (
        <section className="reconcile-summary">
            <Summary history={history} />
        </section>

    );
}


let verifyingAccounts = [
    'malappuram.accounts@dkhkia.com',

    // 'malappuram.cashier@dkhkia.com',
    // 'kozhikode.cashier@dkhkia.com',
    // 'kannur.cashier@dkhkia.com',
]



var groupBy = function (xs, key) {

    return xs.reduce(function (rv, x) {

        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;

    }, {});
};


let defaultReceipt = {
    'accounts': [],
    'sm': [],
    'edp': [],
    'new': [],
    'exact': [],
    'match': [],

};

const Summary = ({ url, history }) => {

    const { user, isMobile } = useContext(GlobalContext);

    const [view, setView] = useState(isMobile);

    const [receipts, setReceipts] = useState(defaultReceipt);

    const [collections, setCollections] = useState([]);

    const [codes, setCodes] = useState({})

    // let canVerify = verifyingAccounts.indexOf(user.email) !== -1

    let canVerify = true;

    const [loader, setLoader] = useState(true);

    const [banks, setBanks] = useState([]);

    const [selectedBanks, setselectedBanks] = useState([]);

    useEffect(() => {

        // getBankReceipts();

        getCustomerCredits();

        getBanks();

    }, [])


    function getBanks() {
        FirebaseUtils.getBanks().then((result) => {

            let banks = result;

            setBanks(banks);

            setselectedBanks(banks.map((b) => b.code))

            let codes = {}

            banks.forEach((bank) => {
                codes[bank.code] = bank.location;
            })

            setCodes(codes)

        })
    }


    function getCustomerCredits(location) {

        setLoader(true);

        var queries = [
            {
                field: 'reconcile_status',
                value: 'pending'
            },


            // {
            //     field: 'pending_to_reconcile',
            //     value: true
            // },
            // {
            //     field: 'pending_at',
            //     value: 'new'
            // },
        ]


        // For malappuram load only malappuram data
        if (([
            'malappuram.cashier@dkhkia.com',
            'malappuram.accounts@dkhkia.com'
        ].indexOf(user.email) !== -1)) {

            queries.push({
                field: 'city',
                value: 'malappuram'
            })
        }

        FirebaseUtils.getBankCredits(queries).then((result) => {


            // let b = (result.all).map((booking) => {
            //     return result.bank_receipts[booking]
            // });

            // getCollections(result.all);

            console.log(result.all);

            if (result.all && result.all.length) {
                getPendingMatchingReceipts(result.all.filter((item) => !item.debit));
            } else {

                setLoader(false);

            }


        });
    }

    function getPendingMatchingReceipts(newReceipts) {

        var queries = [
            {
                field: 'pending_to_reconcile',
                value: true
            },
            // {
            //     field: 'verified',
            //     value: 'approved'
            // },

            {
                field: 'source',
                operator: 'in',
                value: ['kec', 'cashier', 'finance', 'sm', 'tl', 'KIA Website']
            },
        ]


        FirebaseUtils.getAllCollections(queries).then((result) => {

            console.log(result);

            newReceipts.forEach((receipt) => {

                receipt.matching = [];

                result.all.forEach((entry) => {

                    var condition;

                    if (((entry.utr !== '') || (entry.ref !== '')) && (entry.credit === parseInt(receipt['credit']))) {

                        condition = (receipt['description'].indexOf(entry.ref) !== -1) || (receipt['description'].indexOf(entry.utr) !== -1) || (receipt['ref'].indexOf(entry.utr) !== -1) || (receipt['ref'].indexOf(entry.ref) !== -1);

                        if (condition) {

                            // Push the entries to matching
                            receipt.matching.push(entry);

                            receipt.pending_at = 'match';

                        }
                    }
                })
            });


            var grouped = groupBy(newReceipts, 'pending_at');

            setReceipts({
                ...defaultReceipt,

                ...grouped,

            });

            setLoader(false);

        });

    }

    function onSelect(receipt, index) {

        receipts['new'].forEach((entry, index) => {
            if (receipt.receiptId === entry.receiptId) {
                receipts['new'][index].selected = true;
            }
        })

        setReceipts({ ...receipts });
    }

    function onDiscard(receipt, index) {

        receipts['new'].forEach((entry, index) => {
            if (receipt.receiptId === entry.receiptId) {
                receipts['new'][index].selected = false;
            }
        })

        setReceipts({ ...receipts });
    }


    function sendTo(role) {

        var params = {
            initial: {
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                created_by: user.name
            },
            pending_at: role
        }

        Promise.all(receipts['new'].filter((receipt) => receipt.selected).map((entry, index) => {

            // var city = banks[entry.bank];

            var city = codes[entry.bank];

            return FirebaseUtils.updateCollection(entry.receiptId, params)
        })).then(() => {

            message.success('Status has been updated');

            getCustomerCredits()
            // getBankReceipts();

        })
    }

    function updateBank(value, option) {


        setselectedBanks([...value]);
    }


    return (<div className="summary-content">
        <div className="list-header">


            <div>
                <Title level={3}>
                    Verify Receipts
                </Title>

                <p className="page-desc">
                    {loader ? '. Loading data' : ``}
                </p>

            </div>

            <div className="list-actions">
                <Button onClick={() => {
                    getCustomerCredits()
                }} type="secondary" size={'small'}>Refresh</Button>

                {/* <Switch
                    defaultChecked
                    onChange={changeView}
                    checked={view}
                    checkedChildren={<OrderedListOutlined />}
                    unCheckedChildren={<PicCenterOutlined />}
                /> */}
            </div>

        </div>

        <div className="summary-listing">
            {
                loader
                    ? <PlaceHolder type="listing" />
                    :
                    <>

                        <Tabs defaultActiveKey="1" onChange={() => { }}>

                            {/* Accounts Pending */}
                            <TabPane tab={`New Customer Receipts (${receipts['new'].length + receipts['match'].length})`} key="1">

                                <div className="top-header">

                                    {
                                        banks.length
                                            ? <Select
                                                mode="multiple"
                                                allowClear
                                                style={{ width: '200px' }}
                                                placeholder="Please select"
                                                defaultValue={banks.map((el) => el.code)}
                                                onChange={updateBank}
                                            >
                                                {
                                                    banks.map((bank, index) => {
                                                        return <Option key={index} value={bank.code}>
                                                            {bank.code}
                                                        </Option>
                                                    })
                                                }
                                            </Select> : null}

                                </div>

                                {/* Exact Match */}

                                {!canVerify ? <>

                                    {
                                        receipts['match'].length ?
                                            <>


                                                <Divider orientation="left" plain>
                                                    {receipts['match'].filter((receipt, index) => {

                                                        return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;

                                                    }).length} receipts matched, {receipts['new'].filter((receipt, index) => {

                                                        return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;

                                                    }).length} Unidentified
            </Divider>

                                                <>
                                                    {
                                                        receipts['match'].filter((receipt, index) => {

                                                            return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;

                                                        }).map((receipt, index) => {
                                                            return <BankReceipt codes={codes} receipt={receipt} callback={getCustomerCredits} key={index} />
                                                        })
                                                    }

                                                </>

                                            </>
                                            :
                                            null
                                    }

                                </>
                                    :
                                    null
                                }

                                {/* Exact Match Ends */}

                                {/* Selected Receipts */}
                                {
                                    receipts['new'].filter((receipt) => receipt.selected).length ?
                                        <div className="selected-item-form card">

                                            {/* Selected Tags */}
                                            <div className="selected-items">
                                                {
                                                    receipts['new'].filter((receipt) => receipt.selected).map((receipt, key) => {

                                                        return (<Tag key={key} visible closable onClose={() => { onDiscard(receipt, key) }}>
                                                            {receipt['description']} <span className="title amount">{receipt['credit']}</span>
                                                        </Tag>)
                                                    })
                                                }
                                            </div>
                                            {/* Selected Tag Ends */}

                                            <div className="form-actions">

                                                {/* <Button onClick={() => {
                                                    getBankReceipts();
                                                }} type="secondary" size={'small'}>Cancel</Button> */}

                                                <Button onClick={() => { sendTo('sm') }} type="secondary" size={'small'}>Send to Sales Team</Button>

                                                {/* <Button onClick={() => { sendTo('edp') }} type="secondary" size={'small'}>Send to EDP</Button> */}

                                            </div>

                                        </div>
                                        :
                                        null
                                }
                                {/* Selected Receipts Ends */}


                                <Divider orientation="left" plain>
                                    {receipts['new'].filter((receipt) => {

                                        return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;

                                    }).length} Unidentified receipts
                                </Divider>

                                <>

                                    {
                                        receipts['new'].filter((receipt) => {

                                            return !receipt.selected && selectedBanks.indexOf(receipt.bank) !== -1;

                                        }).map((receipt, index) => {
                                            return <BankReceipt codes={codes} callback={getCustomerCredits} key={index} receipt={receipt} onSelect={() => { onSelect(receipt, index) }} />
                                        })
                                    }

                                </>

                            </TabPane>
                            {/* Accounts Pending */}

                        </Tabs>
                    </>
            }
        </div>
    </div>)
}


const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};



/**
 * 
 * This is particularly for 
 * 
 * @param {*} param0 
 */
function VerificationSelection({ bankEntry, callback, codes }) {

    const [collections, setCollections] = useState([])

    const { user } = useContext(GlobalContext);

    const [loader, setLoader] = useState(true);

    const [form] = Form.useForm();

    useEffect(() => {

        getCollections();

    }, [])


    function getCollections(receipts) {

        console.log(receipts);

        let queries = [
            {
                field: 'verified',
                value: false
            },
            {
                field: 'pending_to_reconcile',
                value: true
            },

        ]

        // For malappuram load only malappuram data
        queries.push({
            field: 'city',
            value: user.locations[0]
        })
        // }


        FirebaseUtils.getAllCollections(queries).then((result) => {

            setCollections(result.all);

            console.log(result);

            setLoader(false);

        });
    }

    function onSelect(collection) {

        collections.forEach((entry, index) => {
            if (entry.collectionId === collection.collectionId) {
                collections[index].selected = true;
            }
        })

        setCollections([...collections]);
    }


    function onDiscard(collection, index) {

        collections.forEach((entry, index) => {
            if (collection.collectionId === entry.collectionId) {
                collections[index].selected = false;
            }
        })

        setCollections([...collections]);
    }


    /**
     * 
     * Once the user selects the customer receipts that
     * can be linked to the bank entry
     * 
     * @param {*} values 
     */
    const onFinish = values => {

        console.log(values);

        let filtered = collections.filter((col) => col.selected);

        let verificationTime = {
            verified_at: moment().format('DD/MM/YYYY HH:mm'),
            verified_by: { name: user.name, role: user.role },
            verified_date: moment().startOf('day').valueOf(),
            verified_time: moment().valueOf()// this can be removed if not needed

        }

        let params = {
            // ...verificationTime,
            ...values,
            isBulkDeposit: filtered.length > 1 ? true : false,

            reconciling_city: codes[bankEntry.bank],
            verified_receipts: filtered.map((entry) => entry.collectionId),
            collectionIds: filtered.map((entry) => entry.collectionId),
            reconcile_status: 'completed',
            pending_at: 'done',

            // status: 'done'
        }

        // Update the receipt with the selected collections 
        FirebaseUtils.updateBankCreditRecord(bankEntry.receiptId, params).then(() => {

            Promise.all(filtered.map((entry, index) => {

                let params = {
                    verificationRemarks: values.verificationRemarks,
                    wasBulkEntry: true,
                    verified: 'approved',
                    verification_entry: bankEntry.receiptId,
                    receiptId: bankEntry.receiptId,
                    reconciled_by: bankEntry.receiptId,
                    clearing_city: bankEntry.city,
                    bank: bankEntry.bank,
                    // status: 'reconciled',
                    pending_at: 'edp',
                    ...verificationTime
                    // pending_to_reconcile: false
                }
                return FirebaseUtils.updateCollection(entry.collectionId, params)

            })).then(() => {

                message.success('The collections has been updated');

                callback();

            })

        })
        // Update the collections receiptid as the one 
    };

    let selectedTotal = collections.filter((receipt) => receipt.selected).reduce((a, b) => a + parseInt(b.amount), 0);

    let pending = bankEntry.credit - selectedTotal;

    let enableSubmit = (selectedTotal === parseInt(bankEntry.credit));

    // || ((selectedTotal >= 95 / 100 * (bankEntry.credit)) && (selectedTotal <= (bankEntry.credit)))


    return (<div>


        {/* Selected Receipts */}
        {
            collections.filter((receipt) => receipt.selected).length ?
                <div className="selected-item-form card">

                    {/* Selected Tags */}
                    <div className="selected-items">
                        {
                            collections.filter((receipt) => receipt.selected).map((collection, key) => {

                                return (<Tag key={key} visible closable onClose={() => { onDiscard(collection, key) }}>
                                    {collection['name']} <span className="title amount">{collection['amount']}</span>
                                </Tag>)
                            })
                        }
                    </div>
                    {/* Selected Tag Ends */}

                    <div className="form-actions">

                        <h3>

                            Collection Total - {selectedTotal}

                        </h3>

                        <h3>

                            Pending Amount - {pending}

                        </h3>

                    </div>


                    <Form
                        form={form}
                        {...layout}
                        name="new-record"
                        layout="vertical"
                        onFinish={onFinish}
                        // validateMessages={validateMessages} 
                        initialValues={{
                        }}>

                        {/* #todo May be we need more fields here */}

                        <Form.Item name={'verificationRemarks'} label="Remarks" rules={[{ required: true, message: 'Please enter Remarks' },]}>
                            <Input />
                        </Form.Item>

                        <Button disabled={!enableSubmit} type="primary" htmlType="submit" className="submit-button">
                            SUBMIT
                        </Button>


                        {/* <Button onClick={onReject} type="secondary" className="submit-button">
                                REJECT
                                </Button> */}
                    </Form>

                </div>
                :
                null
        }
        {/* Selected Receipts Ends */}

        {
            loader
                ? <PlaceHolder type="listing" />
                :
                <>


                    {
                        collections.filter((col) => !col.selected).map((entry, index) => {

                            return (<PendingCollection key={index} collection={entry} onSelect={() => { onSelect(entry, index) }} />)

                        })
                    }

                </>
        }


    </div>)
}


function ReceiptInfo({ receipt = {} }) {


    var created_by;
    var created_at;

    if (receipt.rejectRemarks) {

        if (receipt.smReject) {

            created_by = receipt.smReject.created_by;

            created_at = receipt.smReject.created_at;


        } else if (receipt.edpReject) {

            created_by = receipt.edpReject.created_by;

            created_at = receipt.edpReject.created_at;

        }
    }

    return (<>

        <h3 className="title">
            {receipt['description']}
        </h3>


        <h2 className={`title credit`}>
            {receipt['credit']}
        </h2>

        <p className="title ">
            {receipt['ref']}
        </p>

        <p className="">
            <Tag color="magenta">
                {receipt.location || receipt.bank}
            </Tag>

            {
                (receipt.location || receipt.city)
                    ?
                    <Badge color="#f50" text={receipt.city || receipt.location} />
                    :
                    null
            }

        </p>

        Credited at {receipt['txn_date']}

        <div>

            {
                receipt.rejectRemarks
                    ?
                    <Text type="danger">
                        {receipt.rejectRemarks}, By {created_by} at {created_at}

                    </Text>
                    :
                    null
            }

        </div>

        {
            receipt.edp && receipt.edp.created_at ? <>

                Created at {receipt.edp.created_at}, By {receipt.edp.created_by}

            </>
                :
                null
        }
    </>)


}



function CollectionInfo({ collection = { attachments: [] } }) {

    return (<div>

        <>
        </>


        {
            collection.verified === 'approved'
                ?
                <Tag color="success">Verified</Tag>
                :
                <Tag color="red">Unverified</Tag>
        }

        {
            collection.invoice
                ?
                <Tag color="success">Receipt Completed</Tag>
                :
                <Tag color="red">Receipt Pending</Tag>
        }


        {
            collection.accounts
                ?
                <Tag color="success">Accounts Done</Tag>
                :
                <Tag color="red">Accounts Pending</Tag>
        }


        <div className="detail-element">


            <Row span={12}>

                <h3>Source :</h3>
            </Row>
            <Row span={12}>
                <span>{collection.source}</span>

            </Row>

        </div>


        <div className="detail-element">


            <Row span={12}>

                <h3>Name :</h3>
            </Row>
            <Row span={12}>
                <h2 className="title">
                    {collection.name}
                </h2>
            </Row>

        </div>


        <div className="detail-element">

            <Row span={12}>
                <h3>Amount :</h3>
            </Row>
            <Row span={12}>
                <h2 className="title amount">{collection.amount}</h2>
            </Row>

        </div>

        <div className="detail-element">

            <Row span={12}>
                <h3>UTR :</h3>
            </Row>
            <Row span={12}>
                <span>{collection.utr}</span>
            </Row>

        </div>


        <div className="detail-element">

            <Row span={12}>
                <span>Attachments</span>
            </Row>


            <>
                {
                    collection.attachments && collection.attachments.map((file) => {

                        return <>{file.type === 'application/pdf' ?
                            <embed
                                src={file.url}
                                width="500"
                                height="375"
                                type="application/pdf" />
                            :
                            <Image
                                width={200}
                                src={file.url}
                            />}</>
                    })
                }


            </>
        </div>

        <div className="detail-element">
            <Row span={12}>
                <h3>UTR :</h3>
            </Row>
            <Row span={12}>
                <span>{collection.utr}</span>
            </Row>
        </div>


        <div className="detail-element">

            <Row span={12}>
                <h3>Customer ID :</h3>
            </Row>
            <Row span={12}>
                <span>{collection.customerId}</span>
            </Row>
        </div>


        <div className="detail-element">
            <Row span={12}>
                <h3>On Account Of :</h3>
            </Row>
            <Row span={12}>

                <span>{collection.account}</span>

            </Row>
        </div>


        <div className="detail-element">

            <Row span={12}>
                <h3>Remarks :</h3>
            </Row>
            <Row span={12}>
                <span>{collection.remarks}</span>
            </Row>

        </div>


        <div className="detail-element">

            <Row span={12}>
                <h3>Phone :</h3>
            </Row>
            <Row span={12}>
                <span>{collection.phone}</span>
            </Row>

        </div>

        <div className="detail-element">

            <Row span={12}>
                <span>
                    Invoice
              </span>
            </Row>




            <>
                {
                    collection.invoice && collection.invoice.map((file) => {

                        return <>{file.type === 'application/pdf' ?
                            <embed
                                src={file.url}
                                width="500"
                                height="375"
                                type="application/pdf" />
                            : <Image
                                width={200}
                                src={file.url}
                            />}</>
                    })
                }

            </>

        </div>

    </div>)

}



/**
 * 
 * Card for showing pending receipt
 * 
 * @param {*} param0 
 */
function PendingReceipt({ actualReceipt = {}, collection = {}, callback }) {

    const [form] = Form.useForm();

    const { user } = useContext(GlobalContext);

    const onFinish = values => {

        // reconciled for

        let params = {
            verificationRemarks: values.verificationRemarks,
            verified: 'approved',
            verified_at: moment().format('DD/MM/YYYY HH:mm'),
            verified_by: { name: user.name, role: user.role },
            verified_date: moment().startOf('day').valueOf(),
            verified_time: moment().valueOf()// this can be removed if not needed
        }


        let params1 = {

            ...params,

            pending_at: 'edp',
            status: 'reviewed',

            pending_to_reconcile: false,
            reconciled_by: actualReceipt.receiptId,
            clearing_city: actualReceipt.city,
            bank: actualReceipt.bank
            // Should i think of comments
        }

        // Make Receipt as reconciled true
        FirebaseUtils.updateCollection(collection.collectionId, params1).then((result) => {


            let params2 = {
                clearing_entry: true,
                reconcile_status: 'completed',

                reconciled_for: collection.collectionId,
                reconciling_city: collection.city,
                // duplicate: true,
                pending_at: 'done',// this field may not be required #todo
                closing: {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_by: user.name
                },
            }

            // reconciled by 
            FirebaseUtils.updateBankCreditRecord(actualReceipt.receiptId, params2).then((result) => {

                console.log(result);

                callback();

                form.resetFields();
            });

        });
    };


    return (<div className="receipt-card">
        <div className="card">

            <CollectionInfo collection={collection} />

            <Form
                form={form}
                {...layout}
                name="new-record"
                layout="vertical"
                onFinish={onFinish}
                // validateMessages={validateMessages} 
                initialValues={{
                }}>


                <Form.Item name={'verificationRemarks'} label="Remarks" rules={[{ required: true, message: 'Please enter Remarks' },]}>
                    <Input />
                </Form.Item>

                <Button type="primary" htmlType="submit" className="submit-button">
                    SUBMIT
                </Button>

            </Form>
        </div>
    </div>)
}




function PendingCollection({ receipt = {}, collection = {}, callback, onSelect }) {

    const [visible, setVisible] = useState(false)

    const [reconcileModal, setreconcileModal] = useState(false)

    const [form] = Form.useForm();

    const { user } = useContext(GlobalContext);


    return (<div><div className="card collection-card" to={`/collections/${collection.collectionId}`}>


        <div className="left">

            <h2 className="title">
                {collection.name} - <small>
                    {collection.remarks}
                </small>
            </h2>
            <h4 className="title ">

                {collection.phone}

            </h4>
            Created at {collection.created_at} by {collection.created_by_name}
        </div>

        <div className="right">


            <div>

                <h3 className="title amount">
                    {collection.amount}
                </h3>



                {
                    collection.verified === 'approved'
                        ?
                        <Tag color="success">Verified</Tag>
                        :
                        <Tag color="red">Unverified</Tag>
                }

                {
                    collection.invoice
                        ?
                        <Tag color="success">Receipt Completed</Tag>
                        :
                        <Tag color="red">Receipt Pending</Tag>
                }


                {
                    collection.accounts
                        ?
                        <Tag color="success">Accounts Completed</Tag>
                        :
                        <Tag color="red">Accounts Pending</Tag>
                }


            </div>

            <div>


                <Button onClick={() => { setVisible(true); }} type="secondary" size={'small'}>View</Button>

                {/* {
                    receipt.pending_at === 'new' ? <Button onClick={() => { autoReconcile() }} type="secondary" size={'small'}>Send to EDP</Button> : null
                } */}


                {onSelect && <Checkbox checked={collection.selected} onChange={onSelect}>Select</Checkbox>}

            </div>


        </div>
    </div>


        <Modal
            destroyOnClose={true}
            title="Collection Detail"
            visible={visible}
            okText='Okay'
            footer={null}
            onOk={() => {
                setVisible(false);
            }}
            onCancel={() => {
                setVisible(false);
            }}>

            <CollectionInfo collection={collection} />

        </Modal>

    </div>)
}





/**
 * 
 * Bank Receipt Card
 * 
 */


function BankReceipt({ codes, onSelect, receipt = { matching: [] }, callback }) {

    const [form] = Form.useForm();

    const { user } = useContext(GlobalContext);

    // let canVerify = verifyingAccounts.indexOf(user.email) !== -1

    let canVerify = true;

    const [visible, setVisible] = useState(false);

    const [verificationmodal, setVerificationmodal] = useState(false);

    return (<div className="receipt-card">
        <div className="card">

            {!canVerify && onSelect && <Checkbox checked={receipt.selected} onChange={onSelect}>Select</Checkbox>}

            <ReceiptInfo receipt={receipt} />


            {/* Reconcile Action */}
            {
                canVerify && receipt.pending_at === 'new' && receipt.reconcile_status === 'pending' ?
                    <Button onClick={() => {

                        setVerificationmodal(true);

                    }} type="secondary" size={'small'}>
                        Verify Receipts
                    </Button>
                    :
                    null
            }
            {/* Reconcile Action */}



            {/* Verification Modal */}
            <Modal
                destroyOnClose={true}
                className="reconcile-modal"
                footer={null}
                width={1000}
                title="Verify Receipt"
                visible={verificationmodal}
                okText='Okay'
                onOk={() => {
                    setVerificationmodal(false);
                }}
                onCancel={() => {
                    setVerificationmodal(false);
                }}>

                <ReceiptInfo receipt={receipt} />

                <Tabs onChange={() => { }}>


                    <TabPane tab={`Matching Receipts`} key="1">

                        <Alert message="Below are receipts matching the same amount. You can find the matching collection entry to verify the bank entry. The collection will proceed to edp for dms entry." type="info" showIcon closable />

                        <ReceiptSelection
                            receipt={receipt}
                            callback={() => {
                                setVisible(false);

                                callback();

                            }} />

                    </TabPane>


                    <TabPane tab={`Verify Collections`} key="2">

                        <Alert message="Below are collections that are yet to be verified. You can either select matching entry or select multiple for cases like a bank deposit done against multiple bookings." type="info" showIcon closable />

                        <VerificationSelection
                            codes={codes}
                            bankEntry={receipt}
                            callback={() => {
                                setVerificationmodal(false);

                                callback();

                            }} />

                    </TabPane>

                </Tabs>
            </Modal>
            {/* Verification Modal Ends */}

        </div>
    </div>)
}





function ReceiptSelection({ receipt, callback }) {

    const [receipts, setReceipts] = useState([]);

    const [loader, setLoader] = useState(true);

    const [form] = Form.useForm();

    useEffect(() => {

        getPendingReceipts();

    }, [])


    function getPendingReceipts(receipts) {

        console.log(receipts);

        var queries = [
            {
                field: 'verified',
                value: false
            },
            {
                field: 'amount',
                value: parseInt(receipt.credit)
            },
        ]

        setLoader(true);

        FirebaseUtils.getAllCollections(queries).then((result) => {

            setLoader(false);

            setReceipts(result.all.filter((r) => r.collectionId !== receipt.collectionId));
        });
    }

    return (<div>

        {
            loader
                ? <PlaceHolder type="listing" />
                :
                <>


                    {
                        receipts.filter((col) => !col.selected).map((entry, index) => {

                            return (<PendingReceipt callback={callback} key={index} actualReceipt={receipt} collection={entry} />)

                        })
                    }

                </>
        }


    </div>)
}


