import React, { useState, useContext } from 'react';

import { Button, DatePicker, message, Modal, Form, Input, Radio } from 'antd';

import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// import { GlobalContext } from './../../../../Store';

import FirebaseUtils from './../../utils/firebase.utils';

import moment from 'moment';

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
    },
};

const { TextArea } = Input;

export default function FileUpload({ multiple, accept, caption, listType = 'picture-card', callback, maxSize = 3, initial = [] }) {
  
    const [files, setFiles] = useState(initial);

    const handleChange = (target) => {
        // console.log(target);
    };

    const uploadButton = (
        <div>
            {caption ? (
                <>
                    <div style={{ marginTop: 8 }}>{caption}</div>
                </>
            ) : (
                <>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                </>
            )}
        </div>
    );

    const uploadProps = {
        accept,
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles(newFileList);
        },
        onChange(info) {
            // console.log(info);

            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            var random = {
                customerName: 'CustomerDocument' + Math.round(Math.random() * 1000000),
            };

            FirebaseUtils.uploadAttachment(file, random, '/collections').then((result) => {
                let file = {
                    uid: random.customerName,
                    name: random.customerName,
                    status: 'done',
                    url: result.url,
                    type: result.meta.contentType,
                };

                files.push(file);

                if (maxSize > 1) {
                    callback(files);
                } else {
                    callback(files[0]);
                }

                setFiles([...files]);
            });

            return false;
        },
        files,
    };

    return (
        <div>
            <>
                <Upload

                    // accept
                    // multiple={multiple}
                    {...uploadProps}
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType={listType}
                    fileList={files}
                    // onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {files.length >= maxSize ? null : uploadButton}
                </Upload>
                {/* <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={this.handleCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal> */}
            </>
        </div>
    );
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
