import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Row, message, Image, Popconfirm } from 'antd';
import { Form, Input, Alert, Tag, Badge, Typography } from 'antd';

import { useLocation } from 'react-router-dom';

import moment from 'moment';
import PlaceHolder from './../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from './../../../../Store';

import './receipt-form.scss';

let defaultCollection = {
    attachments: [],
};

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};

const { Title, Text } = Typography;

export default function ReceiptForm(props) {
    const [reports, setReports] = useState([]);

    const { user } = useContext(GlobalContext);

    const { match } = props;

    var otherCredit = useLocation().search.indexOf('othercredit') !== -1;

    const [loader, setLoader] = useState(true);

    const [matchingloader, setMatchingloader] = useState(false);

    const [matching, setMatching] = useState(null);

    useEffect(() => {
        if (!otherCredit) {
            getCollection();
        } else {
            getBankCredit();
        }
    }, []);

    function getBankCredit() {
        FirebaseUtils.getBankCreditRecord(match.params.id).then((result) => {
            console.log(result);

            setLoader(false);

            setReports(result);
        });
    }

    /**
     * Get stock Data
     */
    function getCollection(location) {
        setLoader(true);

        FirebaseUtils.getCollectionRecord(match.params.id).then((result) => {
            setReports(result);

            setLoader(false);

            if (result.reconciled_by) {
                getMatchingCredit(result);
            }
        });
    }

    function getMatchingCredit(record) {
        setMatchingloader(true);

        FirebaseUtils.getBankCreditRecord(record.reconciled_by).then((result) => {
            console.log(result);

            setMatchingloader(false);

            setMatching(result);
        });
    }

    const onFinish = (values) => {
        if (otherCredit) {
            var params = {
                ...values,
                otherCredit: true,

                accounts: {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_by: user.name,
                },
                reconcile_status: 'completed',
                accounted_date: moment().startOf('day').valueOf(),
                accounted_time: moment().valueOf(),

                pending_at: 'done',
            };

            FirebaseUtils.updateBankCreditRecord(match.params.id, params).then((result) => {
                console.log(result);

                setLoader(false);

                props.history.goBack();

                message.success('Receipt updates has been recorded');
            });
        } else {
            var params2 = {
                ...values,
                accounts: {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_by: user.name,
                },

                accounted_date: moment().startOf('day').valueOf(),
                accounted_time: moment().valueOf(),

                pending_at: 'done',
            };

            FirebaseUtils.updateCollection(match.params.id, params2).then((result) => {
                console.log(result);

                setLoader(false);

                props.history.goBack();

                message.success('Receipt updates has been recorded');
            });
        }
    };

    function sendTo(role) {
        var params = {
            pending_at: role,
        };

        return FirebaseUtils.updateBankCreditRecord(reports.receiptId, params).then(() => {
            props.history.goBack();

            message.success('The record would reflect on pending summary');
        });
    }

    return (
        <div className="reports">
            {/* <Header onBack={() => props.history.goBack()} /> */}

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="about-us page-content">
                        <h1 className="page-heading">Payment Detail</h1>

                        <p>
                            {otherCredit
                                ? 'Enter to CS Accounts'
                                : 'This payment has been entered to dms . Provide the receipt details after entry to CsAccounts'}
                        </p>
                    </div>

                    {!otherCredit ? <ReceiptDetail reports={reports} /> : <ReceiptInfo receipt={reports} />}

                    {matchingloader ? (
                        <PlaceHolder type="report" />
                    ) : (
                        <>
                            {matching && matching.credit ? <Alert message={`Please find the matching bank record`} type="success" showIcon /> : null}

                            {/* Matching Records */}
                            {matching && matching.credit ? <ReceiptInfo receipt={matching} /> : null}

                            {/* Matching Records Ends */}
                        </>
                    )}

                    <Form
                        {...layout}
                        name="new-record"
                        layout="vertical"
                        onFinish={onFinish}
                        // validateMessages={validateMessages}
                        initialValues={{}}
                    >
                        <Form.Item name={'head'} label="Account Head" rules={[{ required: true, message: 'Please enter Accound Head' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name={'accountReceiptNo'} label="Receipt No" rules={[{ required: true, message: 'Please enter Receipt No' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name={'accountRemarks'} label="Remarks" rules={[{ required: true, message: 'Please enter Receipt No' }]}>
                            <Input />
                        </Form.Item>

                        <Button type="primary" htmlType="submit" className="submit-button">
                            SUBMIT
                        </Button>

                        {otherCredit ? (
                            <Popconfirm
                                title="Are you sure you want to mark this as Customer Payment  ? "
                                onConfirm={() => sendTo('new')}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="secondary" className="submit-button">
                                    Mark as Customer Payment
                                </Button>
                            </Popconfirm>
                        ) : null}
                    </Form>
                </Fragment>
            )}
        </div>
    );
}

function ReceiptDetail({ reports }) {
    return (
        <div gutter={0} className="detail-wrapper">
            <div className="detail-element">
                <Row span={12}>
                    <h3>Name :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title">{reports.name}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Phone :</h3>
                </Row>
                <Row span={12}>
                    <span>{reports.phone}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Description :</h3>
                </Row>
                <Row span={12}>
                    <span>{reports['description']}</span>
                </Row>
            </div>

            <Tag color="#108ee9">{reports.bank}</Tag>

            <Badge color="#f50" text={reports.city} />

            <div>
                <Tag color="#108ee9">{reports.type}</Tag>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Ref :</h3>
                </Row>
                <Row span={12}>
                    <span>{reports['ref']}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Amount :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title amount">{reports['credit']}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>On Account Of :</h3>
                </Row>
                <Row span={12}>
                    <span>{reports.account}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <span>Invoice</span>
                </Row>

                <>
                    {reports.invoice &&
                        reports.invoice.map((file) => {
                            return (
                                <>
                                    {file.type === 'application/pdf' ? (
                                        <embed src={file.url} width="500" height="375" type="application/pdf" />
                                    ) : (
                                        <Image width={200} src={file.url} />
                                    )}
                                </>
                            );
                        })}
                </>
            </div>

            <CollectionInfo collection={reports} />
        </div>
    );
}

function CollectionInfo({ collection = { attachments: [] } }) {
    return (
        <div className="collection-info">
            <Alert
                message="Matching Payment Details"
                description={`The below details were collected by KEC from the customer. Please reject if these does not match.`}
                type="info"
            />

            <div className="detail-element">
                <Row span={12}>
                    <h3>Name :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title">{collection.name}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Amount :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title amount">{collection.amount}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <span>Attachments</span>
                </Row>

                <>
                    {collection.attachments &&
                        collection.attachments.map((file) => {
                            return (
                                <>
                                    {file.type === 'application/pdf' ? (
                                        <embed src={file.url} width="500" height="375" type="application/pdf" />
                                    ) : (
                                        <Image width={200} src={file.url} />
                                    )}
                                </>
                            );
                        })}
                </>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>UTR :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.utr}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Customer ID :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.customerId}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>On Account Of :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.account}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Remarks :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.remarks}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Phone :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.phone}</span>
                </Row>
            </div>
        </div>
    );
}

function ReceiptInfo({ receipt = {} }) {
    var created_by;
    var created_at;

    if (receipt.rejectRemarks) {
        if (receipt.smReject) {
            created_by = receipt.smReject.created_by;

            created_at = receipt.smReject.created_at;
        } else if (receipt.edpReject) {
            created_by = receipt.edpReject.created_by;

            created_at = receipt.edpReject.created_at;
        }
    }

    return (
        <div className="card">
            <h3 className="title">{receipt['description']}</h3>
            <h2 className={`title credit`}>{receipt['credit']}</h2>
            <p className="title ">{receipt['ref']}</p>
            <p className="">
                <Tag color="magenta">{receipt.bank}</Tag>

                {receipt.location || receipt.city ? <Badge color="#f50" text={receipt.city || receipt.location} /> : null}
            </p>
            Credited at {receipt['txn_date']}
            <div>
                {receipt.rejectRemarks ? (
                    <Text type="danger">
                        {receipt.rejectRemarks}, By {created_by} at {created_at}
                    </Text>
                ) : null}
            </div>
            {receipt.edp && receipt.edp.created_at ? (
                <>
                    Created at {receipt.edp.created_at}, By {receipt.edp.created_by}
                </>
            ) : null}
        </div>
    );
}
