import React, { useState, useEffect } from 'react';

import { Form, Button, Input, message, Typography, Select } from 'antd';

import FirebaseUtils from './../../utils/firebase.utils';

import './edit-user.scss';

import PlaceHolder from './../../components/ui_elements/PlaceHolder';

const { Title } = Typography;

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { span: 8 },
};

function EditUser(props) {
    const [loading, setLoading] = useState(false);

    const { match } = props;

    const [record, setRecord] = useState([]);

    // const [values, setValues] = useState({});

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);

        FirebaseUtils.getUserRecord(match.params.id).then((result) => {
            console.log(result.data());

            setRecord({ ...result.data() });

            setLoading(false);
        });
    };

    const onFinish = (values) => {
        setLoading(true);

        console.log(values);

        // Create Profile With The key
        FirebaseUtils.updateUserInfo(match.params.id, values)
            .then(() => {
                message.success('User profile updated!');

                props.history.goBack();
            })
            .catch((info) => {
                message.error('User Edit failed');

                console.log('Validate Failed:', info);

                setLoading(false);
            });

        //});
    };
    var name = { name: 'navni' };
    return (
        <section className="edit-user">
            <div className="auth-form-wrapper">
                <div className="form-title">
                    <Title level={4}>Create User Form</Title>
                </div>
                {loading ? (
                    <PlaceHolder type="listing" />
                ) : (
                    <>
                        <Form
                            {...layout}
                            layout="vertical"
                            name="basic"
                            onFinish={onFinish}
                            initialValues={{
                                ...record,
                            }}
                        >
                            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Enter user name' }]}>
                                <Input name="name" id="name" placeholder="Name" />
                            </Form.Item>

                            <Form.Item name="email" label="email" rules={[{ required: true, message: 'Please Input the email ID' }]}>
                                <Input name="email" id="email" placeholde="email" disabled />
                            </Form.Item>

                            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please Input Phone Number' }]}>
                                <Input name="phone" id="phone" placeholde="phone" disabled />
                            </Form.Item>

                            <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please Input Role' }]}>
                                <Input name="role" id="role" placeholde="role" />
                            </Form.Item>

                            <Form.Item name="place" label="Place" rules={[{ required: true, message: 'Please Input Place' }]}>
                                <Input name="place" id="place" placeholde="place" />
                            </Form.Item>

                            <Form.Item name="active" label="Active" rules={[{ required: true, message: 'Please Input Active flag' }]}>
                                <Select defaultValue="false" style={{ width: 120 }}>
                                    <Option value="true">True</Option>
                                    <Option value="false">False</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button loading={loading} type="primary" htmlType="submit">
                                    SUBMIT
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </div>
        </section>
    );
}

export default EditUser;
