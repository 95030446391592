import React, { useState, useEffect, useContext } from 'react';

import { Row, Button, Typography, Tag } from 'antd';

import { Link } from 'react-router-dom';

import { Tabs, Select } from 'antd';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import './customer-files.scss';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { StockTransfers } from '../../../../models';

const { Option } = Select;

const { TabPane } = Tabs;

const { Title } = Typography;

const documentOptions = [
    {
        label: 'Data Collected',
        value: 'dataCollected',
    },
    {
        label: 'Documents Collected',
        value: 'documentsCollected',
    },
    {
        label: 'Login',
        value: 'login',
    },
    {
        label: 'Finance Approved',
        value: 'financeApproved',
    },
    {
        label: 'Finance Rejected',
        value: 'financeRejected',
    },
    {
        label: 'Delivery Order',
        value: 'deliveryOrder',
    },
    {
        label: 'Disbursement',
        value: 'disbursement',
    },
];

export default function CustomerFiles({ step, field, value, redirect }) {
    const { user } = useContext(GlobalContext);

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="customer-files">
            <Title level={3}>Customer Files ({step.caption})</Title>

            <p>{step.description}</p>

            <Tabs defaultActiveKey="0" onChange={callback}>
                {user.locations.map((location, index) => {
                    return (
                        <TabPane tab={`${location} Files`} key={index}>
                            <RequestList step={step} field={field} value={value} location={location} redirect={redirect} />
                        </TabPane>
                    );
                })}
            </Tabs>
        </section>
    );
}

function RequestList({ step, field, value, location, redirect }) {
    const [reports, setReports] = useState([]);

    const { user } = useContext(GlobalContext);

    const [loader, setLoader] = useState(true);

    function getData(filter) {
        setLoader(true);

        const { locations } = user;

        var queries = [];

        if (typeof step.queries === 'function') {
            queries = step.queries({ user, location });
        } else {
            queries = step.queries;
        }

        if (step.model == 'cancellations') {
            FirebaseUtils.getRecords(location, queries, step.model || 'requests').then((vehicles) => {
                setLoader(false);

                console.log(vehicles);

                setReports(vehicles[step.model || 'requests']);
            });
        } else if (step.model == 'stock_transfers') {
            FirebaseUtils.getBaseRecords(queries, step.model).then(async (vehicles) => {
                // get stocktransdfer record details
                let arr = [];
                // get stock  trnsfer record 
                let filter = await Promise.all(
                    vehicles.stock_transfers.map(async (ele) => {

                        let stocktransfer = await StockTransfers.getRecordStock(ele.id);

                        arr.push(stocktransfer);
                    })
                );

                setReports(arr);
                setLoader(false);

            });
        } else if (step.model == 'customer_entries') {
            
            if(step.caption === 'Accounts Entry Pending'){
                queries.push({
                    field: 'city',
                    value: location,
                });
            }
           
            FirebaseUtils.getBaseRecords(queries, step.model).then(async (entries) => {
            
                setReports(entries.customer_entries);
                setLoader(false);

            });
        }
        else {
            FirebaseUtils.getRequests(location, queries).then((vehicles) => {
                setLoader(false);

                console.log(vehicles);

                setReports(vehicles.requests);
            });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div className="list-header">
                <p className="page-desc">{loader ? 'Loading data' : `Found ${reports.length} results`}</p>
                <Button onClick={getData} type="secondary" size={'small'}>
                    Refresh
                </Button>
            </div>

            <Row className="report-listing-wrapper">
                {loader ? (
                    <PlaceHolder type="listing" />
                ) : (
                    <>

                        {reports.map((report, index) => (
                            <step.Card step={step} redirect={redirect} request={report} index={index} key={index} />
                        ))}
                    </>
                )}
            </Row>
        </>
    );
}
