/**
 *
 * @description its used to create stocktransfer
 *
 */

import { Button, Modal, Skeleton, message, Alert, Popconfirm } from 'antd';

import GatePassModal from './gate-pass';

import StockTransferRequest from './stock-transfer-request';

import React, { useEffect, useState } from 'react';

import FirebaseUtils from '../../utils/firebase.utils';

import { StatusLogs, StockTransfers, StockTransactions, Vehicles } from '../../models';

import './stock-transfer-request-creator.scss'


export default function StockTransferRequestCreator({ Callback, vehicle, params, user, caption, mode, required, request, stockTransfer }) {

    // set state visible for stock trasfer request modal
    const [visible, setVisible] = useState(false);
    // set visible for gate pass modal
    const [view, setView] = useState(false);
    // button loading
    const [btnloading, setBtnLoading] = useState(false);
    //
    const [stockId, setStockId] = useState([]);

    const [transferenable, setTransferEnable] = useState(true);

    // State to manage cancel request button
    const [cancelEnable, setCancelEnable] = useState(false);


    const [loading, setLoading] = useState(true)

    const [externalDealerStockTransfer, setExternalDealerStockTransfer] = useState(false)

    const [content, setContent] = useState("")

    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {

        getStockTranfer();

    }, []);

    /**
     * Get stocktrasfer Record
     * @returns
     */
    async function getStockTranfer() {
        // To get the latest status of stock transfer records, get the latest record of the specific vehicle from status-logs
        // After getting the record, stock transfer is only enabled if the status in done
        const query = [
            {
                field: 'reference_number',
                value: vehicle.id,
            },
        ];
        var config = {
            orderBy: 'created_time',
            order: 'desc',
            limit: 1,
        };
        // If mode is present, it indicates the request is for gate pass generation
        if (mode) {
            setTransferEnable(true);
            setCancelEnable(false)
        } else {
            // To get the latest status of stock transfer records, get the latest record of the specific vehicle from status-logs
            // After getting the record, stock transfer is only enabled if the status in done or cancelled
            var stockTransferRecord = await StatusLogs.get(query, config);
            var status;


            if (stockTransferRecord.status_logs.length > 0) {
                status =
                    stockTransferRecord.status_logs[0] && stockTransferRecord.status_logs[0].status_name
                        ? stockTransferRecord.status_logs[0].status_name
                        : null;

                if (status === 'done' || status === 'cancelled' || status === 'New Vehicle') {

                    // To be able to cancel virtual stock transfer even if it is complete
                    if (status === "done" && (stockTransfer && stockTransfer.sub_mode === "virtual")) {
                        setCancelEnable(true)
                    }
                } else {
                    setTransferEnable(false);
                    setContent("Stock transfer can be created only if current stock transfer status is done or cancelled")
                    setShowAlert(true)

                    if (stockTransfer && stockTransfer.sub_mode === "virtual") {
                        setCancelEnable(true)
                    }
                }
            }


            // If vehicle is billed or is in transit, disable transfer request being created
            if (vehicle.update === 'bbnd' || vehicle.stock_status === 'in-transit') {


                if (vehicle.update === "bbnd") {
                    setCancelEnable(false)

                    // If vehicle is billed and virtual stock transfer is complete, then should not allow another virtual transfer, but should be able to convert virtual to actual
                    if (stockTransfer && stockTransfer.sub_mode === "virtual" && status === "done") {
                        setTransferEnable(true);

                    } else {
                        setTransferEnable(false)
                        setContent("Cannot initiate stock tranfer since the vehicle is billed!")
                        setShowAlert(true)

                    }

                } else {
                    setContent("Cannot initiate stock transfer since the vehicle is in-transit")
                    setTransferEnable(false);
                    setShowAlert(true)
                    if (stockTransfer.sub_mode === "virtual") {
                        setCancelEnable(true)
                    }
                }


            }

            // If pdi and yard_id for the vehicle s not updated, vehicle cannot be transferred
            if (!vehicle.stock_point_id || vehicle.stock_point_id === null) {
                setTransferEnable(false);
                if (vehicle.update != 'bbnd') {
                    if (vehicle.stock_status != 'in-transit') {
                        setContent("Update the yard location of the vehicle to initiate stock transfer!")
                        setCancelEnable(false)
                        setShowAlert(true)
                    }
                }

            }
        }

        setLoading(false);
    }

    /**
     * Setting visible for modal used for creating stock transfer request
     */
    function CreateStockRequest() {
        setVisible(true);
    }

    /**
     * Function create stock transfer request
     * A new record is created in stock transfers implying the stock transfer is requested
     * @param {
     * } values
     * @returns
     */
    function onFinish(values) {
        setBtnLoading(true);
        // If request is Present, request is needed for delivery cases
        if (!request)
            request = {
                ...values,
                city: values.to,
                //  destination_city: cityCode[values.vehicle.dealerCode],
                vinNo: params,
            };
        else
            request = {
                ...request,
                city: values.to,
                vinNo: params,
            };
        // The mode wil pass when the gate pass create for delivery
        if (mode) {
            request = {
                ...request,
                mode: mode,
            };
        }

        if (externalDealerStockTransfer) {
            request = {
                ...request,
                mode: 'external-dealer-stock-transfer'
            }
        }
        // I f same yard are selcted for to and from, send an error message
        if (values.from_yard_id === values.to_yard_id) {
            message.error('Please select different locations for stock transfer');
            setBtnLoading(false);
            return false;
        } else {
            var app = FirebaseUtils.getApp();

            var batch = app.batch();
            // create Stock transfer record

            return StockTransfers.createRequest(request, vehicle, user, values, batch).then((res) => {
                batch.commit().then(() => {
                    message.success('Stock Transfer Request Created');
                    Callback(res.id);
                    setStockId(res.id);
                    setBtnLoading(false);
                    //  setTransferEnable(false);
                    setVisible(false);
                    if (user.role === 'sm' || mode) {
                        setView(true);
                    }
                    else {
                        setTransferEnable(false);

                        if (vehicle.update != 'bbnd' && values.sub_mode==="virtual") {
                            setCancelEnable(true)
                        }


                    }
                });
            });
        }
    }

    /**
     * To cancel stock transfer request
     */
    async function cancelRequest() {
        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        // Cancellation is allowed only if billing is not complete for the vehicle
        if (vehicle.update != "bbnd") {

            return StockTransfers.cancelStockTransferRequest(stockTransfer, batch).then((res) => {
                batch.commit()
            }).then(() => {
                message.success("Stock transfer request cancelled")

                Callback(stockTransfer.id)

                setTransferEnable(true)

                setCancelEnable(false)
            });



        }
        else {
            message.error("Cannot cancel stock tranfer since the vehicle is billed!")
        }


    }

    return (
        <div>
            {loading ? (
                <Skeleton />
            ) : (
                <div className="transfer-buttons">
                    {transferenable && ['sm', 'accounts', 'admin'].indexOf(user.role) !== -1 ? (
                        <Button
                            onClick={() => {
                                CreateStockRequest();
                            }}
                            type="secondary"
                            color="green"
                        >
                            {caption}
                        </Button>
                    ) : showAlert && ['sm', 'accounts', 'admin'].indexOf(user.role) !== -1 ?
                        <div >
                            <Alert message={content} type="info" />
                        </div>

                        : null}


                    {cancelEnable && ['sm', 'admin'].indexOf(user.role) !== -1 ? <Popconfirm
                        title="Are you sure you want to cancel the stock transfer ?"
                        onConfirm={cancelRequest}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="secondary"
                            color="green"
                        >
                            Cancel Stock Transfer Request
                        </Button>
                    </Popconfirm> : null}


                    <Modal
                        destroyOnClose={true}
                        footer={null}
                        title="Stock Transfer Request"
                        visible={visible}
                        okText="Okay"
                        onOk={() => {
                            setVisible(false);
                        }}
                        onCancel={() => {
                            setVisible(false);
                        }}
                    >
                        <StockTransferRequest
                            btnloading={btnloading}
                            vehicle={vehicle}
                            required={required}
                            Callback={(values) => {
                                onFinish(values);
                            }}
                            from={vehicle.dealerCode}
                            setExternalDealerStockTransfer={() => {
                                setExternalDealerStockTransfer(true)
                            }}
                            stockTransfer={stockTransfer}
                            mode={mode}
                        />
                    </Modal>

                    {/* Modal for Creating Stock Transfer Request Ends */}

                    {/**   Modal used for Gate pass Start  */}

                    <Modal
                        destroyOnClose={true}
                        footer={null}
                        title="Gate pass"
                        visible={view}
                        width={'60%'}
                        okText="Okay"
                        onOk={() => {
                            setView(false);
                        }}
                        onCancel={() => {
                            setView(false);
                            setTransferEnable(false);
                        }}
                    >
                        <GatePassModal params={params.id} vehicle={vehicle} stockTransferId={stockId} />
                    </Modal>
                    {/**   Modal used for Gate pass end  */}
                </div>
            )
            }
        </div >
    );
}
