

import React, { useEffect, useState, Fragment, useContext } from "react";




import {
    Tabs, List,
} from 'antd';

import FirebaseUtils from '../../utils/firebase.utils';

import { Typography } from 'antd';
import { cityKeys } from "../../modules/global-config";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

var cityKey = cityKeys

export default function MatchingReceipts({ userRequest, callback }) {

    const [receipts, setReceipts] = useState({});

    // const [received, setReceived] = useState(0);

    useEffect(() => {

        if (userRequest.bookingNo) {
            findMatchingReceipts(userRequest);
        }

    }, [])

    /**
     * 
     */
    function findMatchingReceipts(preference) {

        var queries = [
            {
                field: 'Appointment No',
                value: preference.bookingNo
            }
        ]

        FirebaseUtils.getReceipts(preference.city, queries).then((result) => {

            setReceipts(result.receipts);

            let total = (result.receipts).reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0)

            callback(total);

            // setReceived(total);

            // todo We will have to consider refund also in future

        });

    }



    return (<>



        <Tabs onChange={() => { }}>

            {
                Object.keys(receipts).length ?
                    <TabPane tab="Receipts" key="0">

                        <List
                            size="small"
                            dataSource={receipts}
                            renderItem={car => <ReceiptCard
                                vehicle={car}

                            />}
                        />

                    </TabPane>
                    :
                    null
            }
        </Tabs>

    </>)
}




function ReceiptCard({ vehicle, model, callback, index }) {

    // console.log(vehicle);

    return (<div className="stock-item" to={`sm-car-detail/${vehicle['Vin Number']}`}>

        <div className="left">

            <Title level={4}>

                {vehicle['Name of Customer']}

                <span>
                    {` [ ${vehicle['Customer ID']} ] `}
                </span>
            </Title>


            <Text level={5}>
                Receipt No : {vehicle['Receipt No.']}
            </Text>

            <div>
                {`KEC : ${vehicle['KEC']}`}
            </div>

            <small className="status">

                {/* <Badge status={badgeColor[vehicle['On Account Of']]} /> */}

                Payment Type : {vehicle['Type of Payment']}
            </small>

            <h3 className="customer-requests">

                {vehicle['Receipt Amount']}
                {/* {vehicle.bookings && vehicle.bookings.length > 0 ? <Text type="warning">{vehicle.bookings.length} Customers waiting</Text> : null} */}
            </h3>

        </div>
        <div className="right">

            {/* <Tag color={statusColor[vehicle['update']]}>
                {vehicle['On Account Of']}
            </Tag> */}

        </div>
    </div>)
}

