import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

import { ccmSteps } from './ccm-config';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import { Tabs, Typography } from 'antd';

import RequestOverview from './../common/resources/request-overview/request-overview';

import {
    requestSchema,
    bookingSchema,
    billingRequestSchema,
    deliveryRequestSchema,
    requestRouteConfig,
    billSchema,
    billActions,
} from './../global-config';

import DailyDashboard from './../common/resources/daily-dashboard/daily-dashboard';


import VehicleOverview from './../common/resources/vehicle-overview/vehicle-overview';

import TaskOverview from './../common/resources/task-overview/task-overview';

import BookingTracker from './../common/resources/booking-tracker/booking-tracker';

import Cancellations from '../common/components/cancellations/cancellations';
import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import TransitVehicles from './components/ccm-approval-list/ccm-approval-list';
import VehicleDetail from './components/ccm-approval-form/ccm-approval-form';
import ChangePassword from './../common/components/change-password/change-password';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import RequestDetail from '../common/components/request-detail/request-detail';

import './ccm-landing.scss';

import BookingDetail from './../common/components/booking-detail/booking-detail';


import { GlobalContext } from '../../Store';

import Profile from './../common/resources/profile/profile';

import NoticeBoard from './../common/components/notice-board/notice-board';

import DeliveryCalendar from './../common/components/delivery-calendar/delivery-calendar'

const { Title } = Typography;

const { TabPane } = Tabs;

export default function AccessoriesLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => { }, []);

    const queries = [
        {
            field: 'accessories.process',
            value: 'incomplete',
        },
    ];

    // Modules / Menus for this User
    const modules = [
        {
            caption: 'Vehicle Overview',
            path: '/vehicle-overview',
        },

        {
            caption: 'Cancellations',
            path: '/cancellations',
        },
        {
            caption: 'Booking Tracker',
            path: '/booking-tracker',
        },



        {
            caption: 'Requests',
            // path: `/${user.locations[0]}/requests`,
            submenus: [
                {
                    path: `/${user.locations[0]}/request-overview`,
                    caption: 'Request Overview',
                },

                ...requestRouteConfig.map((config) => {
                    return {
                        path: '/' + user.locations[0] + '/' + config.path,
                        caption: config.caption,
                    };
                }),
            ],
        },


        {
            caption: 'Bookings',
            submenus: [
                {
                    path: `/${user.locations[0]}/all-bookings`,
                    caption: 'All Bookings',
                },

                {
                    path: `/${user.locations[0]}/pending-bookings`,
                    caption: 'Pending Bookings',
                },

                {
                    path: `/${user.locations[0]}/billing-done`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivery-done`,
                    caption: 'Delivered Bookings',
                },
            ],
        },
        {
            caption: 'Tasks',
            submenus: ccmSteps.filter((step) => !step.isForm),
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => (
                            <>
                                <Tabs>
                                    <TabPane tab="Overview" key="0">
                                        <NoticeBoard />
                                        <TaskOverview steps={ccmSteps} />
                                    </TabPane>
                                    <TabPane tab="Calendar" key="1">
                                        <div className="right">
                                            <div className="card vehicle-card calendar-card">
                                                <Title level={4}>Delivery Plan</Title>

                                                <p>Below is the scheduled delivery plan by KEC</p>
                                                <Tabs defaultActiveKey="0" type="card" size={'small'}>
                                                    {user.locations.map((location, index) => (
                                                        <TabPane tab={location} key={index}>
                                                            <div className="dashboard-item" key={index}>
                                                                <DeliveryCalendar url={location} />
                                                            </div>
                                                        </TabPane>
                                                    ))}
                                                </Tabs>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </>
                        )}
                    />
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>

                    <Route exact path={`/booking-tracker`} component={BookingTracker} />

                    {/* Cancellations Module */}
                    <PrivateRoute exact path="/cancellations" component={Cancellations} userRole={'admin'} />
                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />
                    {/* Cancellations Module ends */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/all-bookings`}
                        component={(...routeParams) => <GenericList dateField={'bookingDate'} schema={bookingSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList schema={bookingSchema} queries={bookingSchema.pendingBookings} {...routeParams} />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => <GenericList queries={bookingSchema.billingQueries} schema={bookingSchema} {...routeParams} />}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList queries={bookingSchema.deliveryRequests} schema={bookingSchema} {...routeParams} />
                        )}
                    />
                    {/* Delivery Bookings Ends */}

                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    {/* vehicle overview */}
                    <Route exact path={`/vehicle-overview`} component={(...routeParams) => <VehicleOverview {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Common Routes for Requests */}
                    {requestRouteConfig.map((config) => {
                        return (
                            <Route
                                exact
                                path={`/:city/${config.path}`}
                                component={(...routeParams) => <GenericList schema={requestSchema} queries={config.queries} {...routeParams} />}
                            />
                        );
                    })}
                    {/* Common Routes for Requests Ends */}

                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />


                    <TaskRoutes steps={ccmSteps} list={TransitVehicles} form={VehicleDetail} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
