import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { GlobalContext } from './../../Store';

import { Menu, message, Button, Select as ReactSelect, Space, Dropdown, Modal, Form } from 'antd';

import GenericHeader from '../header/generic-header';

import ModalSearch from '../modal-search/modal-search';

// import Header from './'

import { Row, Col, Drawer } from 'antd';
import { Switch, Typography } from 'antd';

import { useLocation } from 'react-router-dom';

import { Tag } from 'antd';

import { MenuOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';

import Select from 'react-select';

import FirebaseUtils from './../../utils/firebase.utils';
import ApiUtils from './../../utils/api.utils';

import './global-header.scss';
import { Avatar } from 'antd';

// import GLOBAL from './../global';

const { SubMenu } = Menu;

const { Title } = Typography;

const { Option } = ReactSelect;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

export default function GlobalHeader({ children, history, modules = [], sidemenu = [], ...props }) {
    let location = useLocation();

    const { dispatch, isMobile, branches,disableDisplayPhoneNumber, defaultBranch, user = { locations: [] } } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [visible, setVisible] = useState(false);

    const [options, setOptions] = useState(branches);

    const [selected, setSelected] = useState({});

    const [form] = Form.useForm();

    const [result, setResults] = useState([]);

    const [userVisible, setUserVisible] = useState(false);

    const [userLoading, setUserLoading] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (user.role) {
            setLoading(false);

        }
    }, [user]);

   function toggle() {
        setVisible(true);
    }

    function handleMenuClick(index) {
        // if (index.key === '1') {
        setUserVisible(true);
        loadUser(user.locations[0]);
        // }
    }

    function loadUser(loc) {
        FirebaseUtils.getUsers(loc).then((result) => {
            let b = Object.keys(result)
            .filter((request) => {
                return result[request].active != "false";
            })
            .map((request) => {
                return { label: result[request].name, value: request };
            });
            setResults(b);
        });
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1" icon={<UserOutlined />}>
                Switch Login
            </Menu.Item>
        </Menu>
    );

    /**
     *
     */
    function submitRequest() {
        setUserLoading(true);

        form.validateFields()
            .then((values) => {
                console.log(values);

                let userId = values.user.value;

                FirebaseUtils.loginWithID(userId)
                    .then((result) => {
                        setUserLoading(false);
                        if (result.exists) {
                            let d = result.data();

                            let userInfo = {
                                id: result.id,
                                ...d,
                                ...{
                                    loggedCheckDone: true,
                                },
                            };

                            dispatch({ type: 'user', payload: userInfo });

                            localStorage.setItem('userInfo', JSON.stringify(userInfo));

                            history.push('/');

                            // Get the branches and set as default according to user role
                            FirebaseUtils.getBranches().then((result) => {
                                let match = result.find((ele) => ele.location === userInfo.locations[0]);

                                let defaultBranch;

                                if (match) {
                                    defaultBranch = match;
                                } else {
                                    defaultBranch = result[0];
                                }

                                // ApiUtils.setBranch(defaultBranch, userInfo);

                                dispatch({ type: 'defaultBranch', payload: defaultBranch });
                            });
                        } else {
                            setUserLoading(false);
                            setUserVisible(false);
                            message.error('No accounts found.');
                        }
                    })
                    .catch((result) => {
                        console.log(result);

                        setUserLoading(false);
                        setUserVisible(false);

                        message.error('Login Failed');
                    });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
                setUserLoading(false);
            });
    }

    /**
     *  Function to close modal
     */
    function closeModal() {
        setUserVisible(false);
    }

    return (
        <div className="global-header">
            <div className="layout-content">
                <div className="left-bar">
                    {!isMobile ? (
                        <div className="sidebar-container">
                            <SideMenu callback={onClose} modules={modules} history={history} />
                        </div>
                    ) : (
                        <Drawer placement="left" closable={false} onClose={onClose} visible={visible} className="drawer-content">
                            <SideMenu callback={onClose} modules={modules} history={history} />
                        </Drawer>
                    )}
                </div>

                <div className="right-section">
                    <Row className="page-wrapper">
                        <Col>
                            {location.pathname === '/' ? (
                                <span onClick={toggle} className="toggle-box toggle-menu">
                                    <MenuOutlined />
                                    <Title level={4}>{/* {menu.caption} */}</Title>
                                </span>
                            ) : (
                                <>
                                    <span
                                        className="toggle-box"
                                        onClick={() => {
                                            history && history.goBack();
                                        }}
                                    >
                                        <GenericHeader />
                                        {/* <Title level={4}>
                                            </Title> */}
                                    </span>
                                </>
                            )}
                        </Col>

                        {user.role ? (
                            <Col className="page-menu">


                                {process.env.NODE_ENV === "development" ? <Tag color="red">DEMO</Tag> : null}


                                <ModalSearch />

                                {/* Profile Avatart */}

                                <ProfileAvatar />

                                {/* Profile Avatart */}

                                {/* {['accounts', 'admin'].indexOf(user.role) !== -1 ? (
                                    <div className="branch-selection">
                                        <ReactSelect
                                            value={selected.code}
                                            onSelect={selectBranch}
                                            placeholder="Branch"
                                            style={{
                                                width: '200px',
                                                margin: '0px 10px',
                                            }}
                                        >
                                            {options.map((doc, index) => (
                                                <Option value={doc.code} key={doc.code}>
                                                    {doc.name}
                                                </Option>
                                            ))}
                                        </ReactSelect>
                                    </div>
                                ) : (
                                    <></>
                                )} */}

                                {/* Only Admin */}
                                {['admin', 'management', 'rm'].indexOf(user.role) !== -1 ? (
                                    <Button size="small" onClick={handleMenuClick}>
                                        <UserSwitchOutlined />
                                    </Button>
                                ) : null}
                                {/* Only Admin Ends */}
                            </Col>
                        ) : null}
                    </Row>

                    {children}
                </div>
            </div>
            <Modal confirmLoading={userLoading} title="Impersonate a User" visible={userVisible} onOk={submitRequest} onCancel={closeModal}>
                <p>You can now impersonate any user in the organization . Type the user name below name to access his account .</p>

                <Form {...layout} form={form} name="new-record" layout="vertical">
                    <Form.Item
                        name={'user'}
                        label="User"
                        rules={[
                            {
                                required: true,
                                message: 'Select User',
                            },
                        ]}
                    >
                        <Select options={result} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

function ProfileAvatar() {
    const { user = { locations: [] } } = useContext(GlobalContext);

    useEffect(() => { }, []);

    return (
        <Link className="profile-avatar" to="/profile">
            {user.photograph ? (
                <>
                    <img className="profile-picture" src={user.photograph} alt={'user photograph'} />
                </>
            ) : (
                <Avatar shape="square" size="small" icon={<UserOutlined />} />
            )}

            {user.name}
        </Link>
    );
}

function SideMenu({ history, modules = [], callback }) {
    const [selected, setSelected] = useState([1]);

    const [menu, setMenu] = useState({});

    const { user = { locations: [] } } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user.role) {
            setLoading(false);
        }
    }, [user]);

    function handleLogout() {
        localStorage.clear();

        FirebaseUtils.logout().then(() => {
            history.push('/login-phone');

            message.success('You have logged out successfully.');
        });
    }

    const onMenuClick = (menu, index) => {
        setSelected([index]);

        history.push(menu.path);

        setMenu(menu);

        callback();
    };

    return (
        <>
            <div className="intro">
                <div>
                    <img
                        onClick={() => {
                            history.push('/');
                        }}
                        className="logo-welcome"
                        src={require('./../../assets/images/kia-logo.png')}
                        alt="Logo"
                    />

                    <small>{process.env.REACT_APP_package_version}</small>
                </div>

                {!loading ? (
                    <>
                        {/* <Title level={4}>{user.name}</Title> */}

                        {/* <Tag color="processing">{user.role}</Tag> */}

                        {/* <div className="locations">
                            {user.locations.map((loc, key) => (
                                <Tag key={key}>{loc}</Tag>
                            ))}
                        </div> */}
                    </>
                ) : null}
            </div>

            {/* <Divider></Divider> */}

            {loading ? null : (
                <Menu
                    // selectedKeys={[selected]}
                    style={{ width: 256 }}
                    // defaultSelectedKeys={selected}
                    // defaultOpenKeys={['']}
                    mode="inline"
                    theme={''}
                >
                    <Menu.Item
                        onClick={() => {
                            setSelected([1]);
                            history.push('/');

                            setMenu({ caption: 'Home' });

                            // setVisible(false);

                            callback();
                        }}
                        key="home-menu"
                    >
                        Home
                    </Menu.Item>

                    {modules.map((menu, index) => {
                        if (menu.submenus && menu.submenus.length) {
                            return (
                                <SubMenu
                                    key={'first-level-' + index}
                                    title={
                                        <span>
                                            <span>{menu.caption}</span>
                                        </span>
                                    }
                                >
                                    {menu.submenus.map((submenu, innerIndex) => {
                                        if (submenu.submenus && submenu.submenus.length) {
                                            return (
                                                <SubMenu
                                                    key={'second-level-' + innerIndex}
                                                    title={
                                                        <span>
                                                            <span>{submenu.caption}</span>
                                                        </span>
                                                    }
                                                >
                                                    {submenu.submenus.map((menu) => {
                                                        return (
                                                            <Menu.Item
                                                                onClick={() => {
                                                                    onMenuClick(menu, index);
                                                                }}
                                                                key={`${menu.path}-${index}`}
                                                            >
                                                                {menu.caption}
                                                            </Menu.Item>
                                                        );
                                                    })}
                                                </SubMenu>
                                            );
                                        } else {
                                            return (
                                                <Menu.Item
                                                    onClick={() => {
                                                        onMenuClick(submenu, index);
                                                    }}
                                                    key={`${submenu.path}-${index}`}
                                                >
                                                    {submenu.caption}
                                                </Menu.Item>
                                            );
                                        }
                                    })}
                                </SubMenu>
                            );
                        } else {
                            return (
                                <Menu.Item
                                    onClick={() => {
                                        onMenuClick(menu, index);
                                    }}
                                    key={`${menu.path}-${index}`}
                                >
                                    {menu.caption}
                                    {/* {[].indexOf(menu.caption) !== -1 ? (
                                        <Tag color="success" style={{ marginLeft: '10px' }}>
                                            New
                                        </Tag>
                                    ) : null} */}
                                </Menu.Item>
                            );
                        }
                    })}

                    <Menu.Item onClick={handleLogout} key="logout-button">
                        <span>Logout</span>
                    </Menu.Item>
                </Menu>
            )}
        </>
    );
}
