
import Base from './base';

class Accessory extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'accessories';
    }

    get path() {
        return `accessories`;
    }

}

export default Accessory;
