import React, { useEffect, useState, Fragment, useContext, useRef } from 'react';

import { Typography, Button, Table, Popover, Skeleton, message, Row, Badge, Col, Modal, Form, Input } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import './bill-details.scss';

import { useReactToPrint } from 'react-to-print';

import Rupee from './../../../../components/rupee/rupee';

import { Bills, BillDetails } from './../../../../models/';

// import VehicleBill from './../../../../templates/vehicle-bill/vehicle-bill';

import VehicleServiceInvoice from '../../../../templates/vehicle-service-invoice/vehicle-service-invoice';

var companyLogo = require('../../../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

const { TextArea } = Input;

export default function VehicleServiceBill({ history, match }) {
    const [record, setRecord] = useState({ created_by: {}, attachments: [] });

    const { user } = useContext(GlobalContext);

    const [loader, setLoader] = useState(false);

    const [matching, setMatching] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const { params } = match;

    const { city, id } = params;

    function loadData() {
        setLoader(true);

        // Load data
        return Bills.getRecord(id).then((result) => {
            setRecord(result);

            var queries = [
                {
                    field: 'bill_id',
                    value: result.id,
                },
            ];

            return BillDetails.get(queries).then((result) => {
                setMatching(result['bill-details']);

                setLoader(false);
            });
        });
    }

    return (
        <div className="allocation-form bill-details">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="">
                            <Title level={3}>Bill</Title>

                            <p className="page-desc">{loader ? '. Loading data' : ``}</p>

                            {loader ? (
                                <Skeleton />
                            ) : (
                                <>
                                    <div className="">
                                        <VehicleServiceInvoice printable={true} id={id} matching={matching} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}
