import React, { useState, useEffect, useMemo } from 'react';

import FirebaseUtils from './../../utils/firebase.utils';

import { Link } from 'react-router-dom';

import { message } from 'antd';

import { Tag, Badge } from 'antd';

import moment from 'moment';
import { Requests } from '../../models';
import { cityKeys } from '../global-config';

var cityCode = cityKeys

/**
 * Structure for Booking Listing and Add
 */
export const bookingColumns = [
    {
        type: 'input',
        caption: 'Booking No',
        field: 'Booking No',
    },
    {
        field: 'Name of the Customer',
        type: 'input',
        caption: 'Customer Name',
    },
    {
        field: 'Contact Number',
        type: 'input',
        caption: 'Contact Number',
    },
    {
        field: 'Booking Date',
        type: 'input',
        caption: 'Booking Date',
    },

    {
        caption: 'KEC',
        field: 'Consultant Name',
        type: 'input',
    },

    {
        caption: 'Request',
        field: 'requestId',
        type: 'input',
    },
];

/**
 * Schema for the table
 */
export const bookingSchema = {
    dateField: 'bookingDate',

    pendingBookings: [
        {
            field: 'billing.status',
            value: 'pending',
            // operator: '!='
        },
    ],

    billingQueries: [
        {
            field: 'billing.status',
            value: 'done',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryQueries: [
        {
            field: 'delivery.status',
            value: 'done',
        },
    ],
};

/**
 * Columns for Request Table
 */
export const requestColumns = [
    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName',
    },
    {
        type: 'input',
        caption: 'Phone',
        field: 'customer.phone',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant',
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
];

export const requestSchema = {
    queries: [],
};

export const managementSteps = [
    {
        caption: 'Approve for Cancellation',
        path: '/cancellation-approval',
        redirect_to: 'cancellation-approval-form',
        model: 'cancellations',
        queries: [
            {
                field: 'status',
                value: 'Refund Approval Pending',
            },
        ],
        Card: CancelCard,
    },
    {
        caption: 'Waiting for Approval',
        path: '/waiting-approval',
        redirect_to: 'waiting-billing-form',
        model: 'requests',
        queries: [
            {
                field: 'actual_billing.status',
                value: 'Waiting for Approval',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        Card: RequestCard,
    },
    // Cancel Form

    {
        isForm: true,
        caption: 'Cancellation Approval Form',
        path: '/cancellation-approval-form/:city/:id',
        model: 'cancellations',
        endpoint: 'cancellations',
        // post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'mgt_remarks',
            },
        ],
        extraParams: (userRequest, params, user) => {
            let creation = {
                refund_approved_at: moment().format('DD/MM/YYYY HH:mm'),
                refund_approved_date: moment().startOf('day').valueOf(),
                refund_approved_time: moment().valueOf(),
                status: 'Refund Payment Pending',
                refund_approved_by: user.name,
            };

            return {
                ...params,
                ...creation,
            };
        },
        onSubmit: (request, param) => {
            let params = {
                cancellation: {
                    id: request.id,
                    status: 'Refund Payment Pending',
                },
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingId, params).then(() => {
                message.success('Booking Cancel Request Updated');
            });
        },
    },
    {
        isForm: true,
        caption: 'Waiting Billing',
        path: '/waiting-billing-form/:city/:id',
        model: 'requests',
        endpoint: 'requests',
        // post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'mgt_remarks',
            },
        ],
        extraParams: (userRequest, params, user) => {
            let creation = {
                management_initiated_at: moment().format('DD/MM/YYYY HH:mm'),
                management_initiated_date: moment().startOf('day').valueOf(),
                management_initiated_time: moment().valueOf(),
            };
            return {
                ...params,
                ...creation,
            };
        },
        onSubmit: (request, param) => {
            let params = {
                actual_billing: {
                    ...request.actual_billing,
                    status: 'requested',
                },
            };

            return Requests.update(request.id, params).then(() => {
                message.success('Successfully forwarded for billing to accounts ');
            });
        },
    },
];

export const stockColumns = [
    {
        type: 'input',
        caption: 'Vin Number',
        field: 'Vin Number',
    },
    {
        field: 'Variant',
        type: 'input',
        caption: 'Variant',
    },
    {
        field: 'Stock Status',
        type: 'input',
        caption: 'Stock Status',
    },
    {
        field: 'Exterior Color Name',
        type: 'input',
        caption: 'Color',
    },

    {
        caption: 'Color Type',
        field: 'Color Type',
        type: 'input',
    },

    {
        caption: 'Variant Code',
        field: 'Variant Code',
        type: 'input',
    },

    {
        caption: 'Invoice Date',
        field: 'KMI Invoice Date',
        type: 'input',
    },

    {
        caption: 'Status',
        field: 'update',
        type: 'input',
    },
];

/**
 * Schema used for Import
 */
export const stockSchema = {
    includeFirstRow: true,

    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Vin Number',
    importAll: true,

    rowIndex: 'Vin Number',
    columns: [
        {
            field: 'partNo',
            from: 'PART NO',
        },
        {
            field: 'partName',
            from: 'PART NAME',
        },
        {
            field: 'available',
            from: 'INVENTORY QUANTITY',
        },

        {
            field: 'onOrder',
            from: '__EMPTY_5',
        },
        {
            field: 'listPrice',
            from: '__EMPTY_6',
        },
        {
            field: 'price',
            from: '__EMPTY_7',
        },

        {
            field: 'stockValue',
            from: 'AMT',
        },
        {
            field: 'hsn',
            from: 'HSN CD',
        },

        {
            field: 'supplier',
            from: 'SUPPLIER',
        },

        {
            field: 'category',
            default: 'Accessories',
        },
    ],

    custom_actions: [
        {
            onClick: (selected, city, callback) => {
                console.log('jhell', selected);

                Promise.all(
                    selected.map((entry) => {
                        var count = 0;

                        return FirebaseUtils.unblockVehicle(entry).then(() => {
                            count++;
                            console.log(count);
                        });
                    })
                ).then(() => {
                    message.success('Action done');

                    callback();
                });
            },
            caption: 'Reset',
            multiple: true,
        },
    ],
};

/**
 * Structure for Parts Listing and Add
 */
export const partsColumns = [
    {
        type: 'input',
        caption: 'Part No',
        field: 'partNo',
    },
    {
        field: 'partName',
        type: 'input',
        caption: 'Part Name',
    },
    {
        field: 'supplier',
        type: 'input',
        caption: 'Supplier',
    },
    {
        field: 'price',
        type: 'input',
        caption: 'Price',
    },

    {
        caption: 'Model',
        field: 'model',
        type: 'radio',
        options: ['Seltos', 'Sonet', 'Carnival'], // This has to come from db
    },

    {
        caption: 'Category',
        field: 'category',
        type: 'radio',
        options: ['Accessories', 'VAS', 'After Market', 'Extended Warranty'],
    },

    {
        caption: 'Inclusive of Tax',
        field: 'tax_inclusive',
        type: 'radio',
        options: ['Yes', 'No'],
    },

    {
        caption: 'CGST',
        field: 'cgst',
        type: 'input',
    },

    {
        caption: 'SGST',
        field: 'sgst',
        type: 'input',
    },

    {
        caption: 'IGST',
        field: 'igst',
        type: 'input',
    },

    {
        caption: 'Additional Tax',
        field: 'addition_tax',
        type: 'input',
    },
];

/**
 * Schema used for Import
 */
export const partsSchema = {
    tableHeader: 2,
    tableRange: 0,
    uploadIndex: 'PART NO', // Do we required an extra column like this
    tableIndex: 'partNo',
    custom_actions: [],
    rowIndex: 'partNo',
    columns: [
        {
            field: 'PART NO',
            from: 'PART NO',
        },

        {
            field: 'partNo',
            from: 'PART NO',
        },
        {
            field: 'partName',
            from: 'PART NAME',
        },
        {
            field: 'available',
            from: 'INVENTORY QUANTITY',
        },

        {
            field: 'onOrder',
            from: '__EMPTY_5',
        },
        {
            field: 'listPrice',
            from: '__EMPTY_6',
        },
        {
            field: 'price',
            from: '__EMPTY_7',
        },

        {
            field: 'stockValue',
            from: 'AMT',
        },
        {
            field: 'hsn',
            from: 'HSN CD',
        },

        {
            field: 'supplier',
            from: 'SUPPLIER',
        },

        {
            field: 'category',
            default: 'Accessories',
        },
    ],
};

function RequestCard({ request, step }) {
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}/${request.city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                <h4 className="title">{request.customer.phone}</h4>
                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>
                <h4>{request.color}</h4>
                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>
                Created at {request.created_at}
            </div>
        </Link>
    );
}
function CancelCard({ request = {}, index, step }) {
    let city = request.dealerCode == 'KL305' ? 'kozhikode' : request.dealerCode == 'KL306' ? 'kannur' :  request.dealerCode == 'KL307'?'malappuram': request.dealerCode == 'KL508'?'kasargod': request.dealerCode == 'KL505'?'kondotty':request.dealerCode=='KL507'?'vadakara':'tirur';

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customerName}</h2>
                <h4 className="title">Phone No : {request.phone}</h4>
                <h3 className="title ">Type : {request.type}</h3>
                <a href={`/bookings/${request.bookingId}`} style={{ zIndex: 1 }}>
                    {request.bookingId}
                </a>
                <h4>{request.remarks}</h4>
                <Tag color="purple">{request.status || 'Initial'}</Tag>
                Created at {request.approve_at}
            </div>
        </Link>
    );
}
