import Base from './base';

class BillTypes extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'bill_types';
    }

    get path() {
        return `bill_types`;
    }
}

export default BillTypes;
