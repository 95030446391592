import React, { useState, useEffect, useMemo } from 'react';

import FirebaseUtils from './../../utils/firebase.utils';

import ApiUtils from './../../utils/api.utils';

import { capitalizeStrings } from './../../utils/common.utils';

import { Link } from 'react-router-dom';

import { message } from 'antd';

import { Tag } from 'antd';

import moment from 'moment';

import { Requests, Payments, StockTransfers, CustomerEntries } from './../../models';
import StockTransfer from '../../models/stock_transfers';

function RequestCard({ request, step }) {
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                <h4 className="title">{request.customer.phone}</h4>
                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>
                <h4>{request.color}</h4>
                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>
                Created at {request.created_at}
            </div>
        </Link>
    );
}

/**
 * stock transfer record card
 * @param {*} param0
 * @returns
 */
function StockTransferCard({ request, step }) {
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.vinNo}</h2>
                <h3>Yard</h3>
                <p className="address">
                    {request.from_yard_name} to {request.to_yard_name}
                </p>
                <h3>Location</h3>
                <p className="address">
                    {request.from} to {request.to}
                </p>
                <Tag color="purple">{request.type || 'Initial'}</Tag>

                <h3>Created at {request.created_at}</h3>
            </div>
        </Link>
    );
}

function BillingCard({ request, step }) {
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}?requestId=${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                <h4 className="title">{request.customer.phone}</h4>
                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>
                <h4>{request.color}</h4>
                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>
                Created at {request.created_at}
            </div>
        </Link>
    );
}

function CancelCard({ request = {}, index, step }) {
    let city = request.dealerCode == 'KL305' ? 'kozhikode' : request.dealerCode == 'KL306' ? 'kannur' : request.dealerCode == 'KL307'?'malappuram':request.dealerCode == 'KL508'?'kasargod':request.dealerCode == 'KL505'?'kondotty':request.dealerCode=='KL507'?'vadakara':'tirur';

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customerName}</h2>
                <h4 className="title">Phone No : {request.phone}</h4>
                <h3 className="title ">Type : {request.type}</h3>
                <a href={`/bookings/${request.bookingId}`} style={{ zIndex: 1 }}>
                    {request.bookingId}
                </a>
                <h4>{request.remarks}</h4>
                <Tag color="purple">{request.status || 'Initial'}</Tag>
                Created at {request.approve_at}
            </div>
        </Link>
    );
}

function ReceiptCard({ request = {}, index, step }) {
    
    return (
        <Link className="request-card" to={`/${step.redirect_to}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.amount}</h2>
                <h4 className="title">Phone No : {request.phone}</h4> 
                <h3 className="title ">Customer ID : {request.customerID}</h3>
                <h4>Remarks: {request.remarks}</h4>
                Created at {request.created_at}
            </div>
        </Link>
    );
}

const stepsArray = [
    {
        'Pending For Entry': {
            route: {
                caption: 'Pending For Entry',
                path: '/receipts',
                model: 'customer_entries',
                queries: [
                    {
                        field: 'pending_at',
                        value: 'accounts',
                    },
                    {
                        field: 'verified',
                        value: 'approved',
                    },
                ],
                Card: RequestCard,
            },

            form: {
                // not using this form
                isForm: true,
                caption: 'Enter Accounts Head',
                model: 'requests',
                path: '/accounts-head/:id',
                post_status: 'Payment Done',
                fields: [
                    {
                        type: 'input',
                        caption: 'Accounts Head',
                        field: 'ac_code',
                    },
                ],
                extraParams: (request, params) => {
                    let creation = {
                        entered_at: moment().format('DD/MM/YYYY HH:mm'),
                        entered_date: moment().startOf('day').valueOf(),
                        entered_time: moment().valueOf(),
                    };

                    return {
                        accounts: {
                            ...creation,
                            ...params,
                        },
                    };
                },
            },
        },
        'Other Credits': {
            route: {
                caption: 'Other Credits',
                path: '/other-credits',
                model: 'bank_credits',
                queries: [
                    {
                        field: 'pending_at',
                        value: 'other-credits',
                    },
                ],
                Card: RequestCard,
            },
            form: {},
        },

        'Accounts Entry Pending': {
            route: {
                caption: 'Accounts Entry Pending',
                path: '/pending-manual-entry',
                model: 'customer_entries',
                redirect_to:'pending-receipts',

                queries: (values) => {

                    return [
                        {
                            field: 'accounts_status',
                            value: 'pending',
                        },
                        {
                            field: 'verified',
                            value: 'approved',
                        },
                    ]
                },
                Card: ReceiptCard,
            },
            form: {
                isForm: true,
                caption: 'Manual CS Entry',
                model: 'customer_entries',
                path: '/pending-receipts/:id',
                endpoint:'customer_entries',
                fields: [
                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks',
                    },
                ],

                onSubmit: (request, values) => {
                    var app = FirebaseUtils.getApp();

                    var batch = app.batch();
                    // accoun status updating
                    let accountStatus = {
                        accounts_status: 'completed',
                    };
                    
                    CustomerEntries.update(request.id,accountStatus)
                   batch.commit()
                },
            },
        },

        'Vehicle Billing Requests': {
            route: {
                caption: 'Vehicle Billing Requests',
                path: '/vehicle-billing-requests',
                redirect_to: 'bill-preview',
                queries: [
                    {
                        field: 'actual_billing.status',
                        value: 'requested',
                    },
                    {
                        field: 'status',
                        operator: '!=',
                        value:'removed'
                    },
                ],
                Card: BillingCard,
            },
            form: {
                isForm: true,
                caption: 'Vehicle Billing Request',
                model: 'actual_billing',
                path: '/vehicle-billing/:id',
                post_status: 'completed',
                fields: [
                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks',
                    },
                ],
                notification: (request, user) => {
                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | Vehicle Invoice Completed',
                        body: `Vehicle Invoice has been completed for ${cName} - ${request.allocation.vinNo} ${request.variant}.`,
                    };
                },
                extraParams: (request, params) => {
                    let creation = {
                        invoiced_at: moment().format('DD/MM/YYYY HH:mm'),
                        paid_date: moment().startOf('day').valueOf(),
                        paid_time: moment().valueOf(),
                    };

                    return {
                        actual_billing: {
                            ...request.actual_billing,
                            ...params,
                            ...creation,
                        },

                        insurance: {
                            process: 'incomplete',
                            status: 'Ready for Issue',
                            // ...userRequest.insurance,
                            // status: userRequest.insurance.type === 'inhouse' ? 'Ready for Issue' : 'Done'
                        },

                        actual_delivery: {
                            status: 'pending',
                        },
                    };
                },
            },
        },

        // stock transfer task
        'Stock Transfer': {
            route: {
                caption: 'Stock Transfer',
                path: '/stock-transfer',
                status: 'requested',
                model: 'stock_transfers',
                redirect_to: 'stock-transfers',


                queries: (values) => {

                    return [
                        {
                            field: 'accounts_status',
                            value: 'pending',
                        },
                        {
                            field: 'dealerCode',
                            value: values.user.dealerCode,
                        },

                    ];
                },
                Card: StockTransferCard,
            },
            form: {
                isForm: true,
                caption: 'Stock Transfer Form',
                path: '/stock-transfers/:id',
                model: 'stock_transfers',
                endpoint: 'stock_transfers',
                fields: [
                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks',
                    },
                ],

                onSubmit: (request, values) => {
                    var app = FirebaseUtils.getApp();

                    var batch = app.batch();
                    // accoun status updating
                    let accountStatus = {
                        accounts_status: 'completed',
                    };
                    StockTransfers.update(request.id, accountStatus);
                    // creating records in status logs when Accounts Dispatch Confirmation
                    if (request.status === 'outward-done') {
                        return StockTransfers.createStatusLog('ACCOUNTSDISPATCHCONFIRMATION', request, batch, values).then((res) => {
                            batch.commit().then(() => { });
                        });
                    } else {
                        // creating records in status logs when  Accounts Accept Confirmation

                        return StockTransfers.createStatusLog('ACCOUNTSACCEPTCONFIRMATION', request, batch, values).then((res) => {
                            batch.commit().then(() => { });
                        });
                    }
                },
            },
        },
        'Insurance Payments': {
            route: {
                caption: 'Insurance Payments',
                path: '/insurance-payments',
                redirect_to: 'insurance-payment',
                queries: [
                    {
                        field: 'insurance.status',
                        value: 'Payment Pending',
                    },
                    {
                        field: 'status',
                        operator: '!=',
                        value:'removed'
                    },
                ],
                Card: RequestCard,
            },
            form: {
                isForm: true,
                caption: 'Insurance Payment',
                model: 'insurance',
                path: '/insurance-payment/:id',
                post_status: 'Payment Done',
                fields: [
                    {
                        type: 'radio',
                        options: ['inhouse', 'direct'],
                        caption: 'Insurance Type',
                        field: 'type',
                    },

                    {
                        condition: (values) => {
                            return values.type === 'inhouse';
                        },
                        type: 'upload',
                        field: 'invoice',
                        caption: 'Invoice ',
                    },
                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks',
                    },
                ],
                notification: (request, user) => {
                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | Insurance Payment Done',
                        body: `Insurance Payment has been completed for ${cName} - ${request.allocation.vinNo} ${request.variant}.`,
                    };
                },
                extraParams: (request, params) => {
                    let creation = {
                        paid_at: moment().format('DD/MM/YYYY HH:mm'),
                        paid_date: moment().startOf('day').valueOf(),
                        paid_time: moment().valueOf(),
                    };

                    return {
                        insurance: {
                            ...creation,
                            ...params,
                        },
                    };
                },
            },
        },
        'Road Tax': {
            route: {
                caption: 'Road Tax',
                path: '/pay-tax-list',
                redirect_to: 'pay-tax',
                queries: [
                    {
                        field: 'rto.status',
                        value: 'Pending Payment',
                    },
                    {
                        field: 'status',
                        operator: '!=',
                        value:'removed'
                    },
                ],
                Card: RequestCard,
            },
            form: {
                isForm: true,
                caption: 'RTO Payment Form',
                path: '/pay-tax/:id',
                model: 'rto',
                post_status: 'HSRP Pending',
                fields: [
                    {
                        type: 'number',
                        field: 'tax_amount',
                        caption: 'Tax Amount',
                    },

                    {
                        type: 'upload',
                        field: 'e_receipt',
                        caption: 'E Receipt ',
                    },

                    {
                        type: 'upload',
                        field: 'tax_license',
                        caption: 'Tax License',
                    },

                    {
                        type: 'upload',
                        field: 'invoice',
                        caption: 'Road Tax Details ',
                    },

                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks',
                    },
                ],
                notification: (request, user) => {
                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | RTO Payment Done',
                        body: `RTO Payment has been completed for ${cName} - ${request.allocation.vinNo} ${request.variant}.`,
                    };
                },
                extraParams: (request, params) => {
                    let creation = {
                        paid_at: moment().format('DD/MM/YYYY HH:mm'),
                        paid_date: moment().startOf('day').valueOf(),
                        paid_time: moment().valueOf(),
                    };

                    return {
                        rto: {
                            ...creation,
                            ...params,
                        },
                        // Update accessories status as ready for billing
                        accessories:{
                            ...request.accessories,
                            status:'Ready for Billing'
                        }
                    };
                },
                onSubmit: (request, values) => {
                    let creation = {
                        updated_at: moment().format('DD/MM/YYYY HH:mm'),
                        updated_date: moment().startOf('day').valueOf(),
                        updated_time: moment().valueOf(),
                    };

                    let item = {
                        ...creation,
                        price: values.tax_amount,
                    };

                    return FirebaseUtils.findAndUpdateCartBasic('RTO', item, request, 'cart').then(() => {
                        return FirebaseUtils.findAndUpdateCartBasic('RTO', item, request, 'bill-details').then(() => { });
                    });
                },
            },
        },
        'Ready for Settlement': {
            route: {
                caption: 'Ready for Settlement',
                path: '/settlement-list',
                redirect_to: 'settlement-file',
                queries: [
                    {
                        field: 'accessories.status',
                        value: 'Invoice',
                    },
                    {
                        field: 'actual_delivery.status',
                        value: 'Settlement Pending',
                    },
                    {
                        field: 'status',
                        operator: '!=',
                        value:'removed'
                    },
                ],
                Card: RequestCard,
            },
            form: {
                isForm: true,
                caption: 'Ready for Settlement Form',
                path: '/settlement-file/:id',
                model: 'delivery',
                // post_status: 'Waiting for TP',
                fields: [
                    // {
                    //     type: 'upload',
                    //     field: 'settlement',
                    //     caption: 'Settlement File '
                    // },
                    // {
                    //     type: 'textarea',
                    //     caption: 'Remarks',
                    //     field: 'remarks',
                    //     extra: 'Mention any differences in settlement and the authorized amount'
                    // },
                ],

                notification: (request, user) => {
                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | Settlement Complete',
                        body: `Accounts Settlement Completed for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
                    };
                },

                extraParams: (request, params) => {
                    let creation = {
                        settled_at: moment().format('DD/MM/YYYY HH:mm'),
                        settled_date: moment().startOf('day').valueOf(),
                        settled_time: moment().valueOf(),
                    };

                    return {
                        delivery: {
                            ...creation,
                            ...request.delivery,
                            ...params,
                            status: 'Settlement Ready',
                            settlementTaken: true,
                        },
                    };
                },
            },
        },

        'Generate Gate Pass': {
            route: {
                caption: 'Generate Gate Pass',
                path: '/generate-gatepass-list',
                redirect_to: 'generate-gatepass-file',
                queries: [

                    {
                        field: 'actual_delivery.settlementTaken',
                        value: true,
                    },
                    {
                        field: 'actual_delivery.status',
                        operator: "not-in",
                        value: ["Gatepass Taken", "Delivery Completed", "Delivered"]
                    },


                ],
                Card: RequestCard,
            },
            form: {
                isForm: true,
                caption: 'Generate GatePass Form',
                path: '/generate-gatepass-file/:id',
                model: 'actual_delivery',
                // post_status: 'Waiting for TP',
                fields: [
                    // {
                    //     type: 'upload',
                    //     field: 'settlement',
                    //     caption: 'Settlement File '
                    // },
                    // {
                    //     type: 'textarea',
                    //     caption: 'Remarks',
                    //     field: 'remarks',
                    //     extra: 'Mention any differences in settlement and the authorized amount'
                    // },
                ],

                notification: (request, user) => {
                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | Gatepass Generated',
                        body: `Gatepass taken for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
                    };
                },

                extraParams: (request, params) => {


                    return {
                        actual_delivery: {

                            ...request.actual_delivery,

                            status: 'Gatepass Taken',

                        },
                    };
                },
            },
        },
        'Update Accounts Head': {
            route: {
                caption: 'Update Accounts Head',
                path: '/update-head-list',
                redirect_to: 'update-head-form',
                model: 'requests',
                queries: [
                    {
                        field: 'allocation.status',
                        operator: 'in',
                        value: ['approved', 'Allocated'],
                    },
                    {
                        field: 'accounts.status',
                        value: 'pending',
                    },
                    {
                        field: 'status',
                        operator: '!=',
                        value:'removed'
                    },
                ],
                Card: RequestCard,
            },
            form: {
                isForm: true,
                caption: 'Update Accounts Head',
                path: '/update-head-form/:id',
                model: 'requests',
                endpoint: 'requests',
                fields: [
                    {
                        type: 'head-selector',
                        field: 'to_account',
                        caption: 'To Account (Account Head of Customer)',
                        // condition: (values) => {
                        //     return ['Net Banking'].indexOf(values.payment_mode) !== -1;
                        // },
                    },
                ],
                onSubmit: (request, values, defaultBranch) => {
                    return FirebaseUtils.updateHead(request.id, values);
                },
            },
        },

        // 'Verify Pricing': {
        //     route: {
        //         caption: 'verify pricing',
        //         path: '/verify-pricing-list',
        //         redirect_to: 'verify-pricing-form',
        //         model: 'requests',
        //         description: 'Verify that the Ex showroom of the vehicle and other items of settlment are accurate to avoid any miscommunication.',
        //         queries: [
        //             {
        //                 field: 'pricing.status',
        //                 value: 'pending',
        //             },
        //         ],

        //         Card: RequestCard,
        //     },
        //     form: {
        //         isForm: true,
        //         caption: 'Verify Pricing',
        //         path: '/verify-pricing-form/:id',
        //         model: 'pricing',
        //         endpoint: 'requests',
        //         post_status: 'completed',
        //         fields: [
        //             {
        //                 type: 'textarea',
        //                 caption: 'Remarks',
        //                 field: 'remarks',
        //             },
        //         ],
        //     },
        // },

        Cancellations: {
            route: {
                caption: 'Cancellations',
                path: '/cancellations-list',
                redirect_to: 'cancellations-list-form',
                model: 'cancellations',
                queries: [
                    {
                        field: 'status',
                        value: 'Refund Payment Pending',
                    },
                ],
                Card: CancelCard,
            },
            form: {
                isForm: true,
                caption: 'Cancellations',
                path: '/cancellations-list-form/:id',
                model: 'cancellations',
                endpoint: 'cancellations',
                post_status: 'DMS Cancellation Pending',
                fields: [
                    {
                        type: 'radio',
                        field: 'payment_mode',
                        caption: 'Mode of Refund',
                        options: ['Cash', 'Net Banking', 'Payment Link by Razorpay', 'Transfer by Razorpay'],
                    },

                    {
                        type: 'mode-selector',
                        caption: 'From Account',
                        field: 'from_account',
                        // condition: (values) => {
                        //     return ['Net Banking'].indexOf(values.payment_mode) !== -1;
                        // },

                        // condition: (values) => {
                        //     return ['Transfer by Razorpay', 'Net Banking','Payment Link by Razorpay'].indexOf(values.payment_mode) !== -1;
                        // },
                    },

                    {
                        type: 'head-selector',
                        field: 'to_account',
                        caption: 'To Account (Account Head of Customer)',
                        // condition: (values) => {
                        //     return ['Net Banking'].indexOf(values.payment_mode) !== -1;
                        // },
                    },

                    {
                        type: 'input',
                        field: 'name',
                        caption: 'Customer Name',
                    },
                    {
                        type: 'input',
                        field: 'email',
                        caption: 'Email',
                    },
                    {
                        type: 'input',
                        field: 'contact',
                        caption: 'Contact',
                    },
                    {
                        type: 'input',
                        caption: 'Account Number',
                        field: 'account_number',
                        condition: (values) => {
                            return ['Transfer by Razorpay', 'Net Banking'].indexOf(values.payment_mode) !== -1;
                        },
                    },
                    {
                        type: 'input',
                        caption: 'IFSC',
                        field: 'ifsc',
                        condition: (values) => {
                            return ['Transfer by Razorpay', 'Net Banking'].indexOf(values.payment_mode) !== -1;
                        },
                        // condition: (values) => {
                        //     return values.payment_mode === 'Bank Transfer';
                        // },
                    },

                    {
                        type: 'input',
                        field: 'amount',
                        caption: 'Amount',
                    },
                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'accounts_remarks',
                    },
                ],
                extraParams: (request, params, user) => {
                    let creation = {
                        accounted_at: moment().format('DD/MM/YYYY HH:mm'),
                        accounted_date: moment().startOf('day').valueOf(),
                        accounted_time: moment().valueOf(),
                        status: 'Refund Confirmation Pending',
                        accounted_by: user.name,
                    };

                    return {
                        ...params,
                        ...creation,
                    };
                },
                onSubmit: (request, values, department, user) => {
                    // For Bank Transfer
                    // Via Razorpay

                    let bookingParams = {
                        cancellation: {
                            id: request.id,
                            status: 'Refund Confirmation Pending',
                        },
                    };

                    return FirebaseUtils.updateBookingGeneric(request.bookingId, bookingParams).then(async () => {
                        var cancelFlag = true;

                        var isBooking = false;

                        var mode = values.from_account.value;

                        await Payments.refundLink(values.payment_mode, values, request, department, user, mode, cancelFlag, isBooking);
                        //                     if (values.payment_mode === 'Transfer by Razorpay') {
                        //                         let formBody = {
                        //                             name: values.name,
                        //                             email: values.email,
                        //                             contact: values.contact,
                        //                             ifsc: values.ifsc,
                        //                             account_number: values.account_number,
                        //                             amount: values.amount * 100,

                        //                             location: cityCode[request.dealerCode], // :Note , Should we keep city

                        //                             remarks: values.accounts_remarks,
                        //                             reference_id: `${request.dealerCode} - Booking - ${request.bookingId}`,
                        //                         };

                        //                         // var current = request.accounts.ac_code.value;
                        //                         var current = values.to_account.value;

                        //                         var params = {
                        //                             description: 'Customer Refund via Razorpay',
                        //                             ref: request.name + ' / ' + request.phone,
                        //                             amount: values.amount,
                        //                             customer: values.to_account.value,
                        //                         };

                        //                         // params.mode = defaultBranch.razorpay_account_head;

                        //                         // Get the From Account
                        //                         params.mode = values.from_account.value;

                        //                         // Initiate
                        //                         return FirebaseUtils.createRefundRequest({ formBody })
                        //                             .then((result) => {
                        //                                 if (result.data && result.data.id) {
                        //                                     params = {
                        //                                         ...params,
                        //                                         // Razorpay transaction id
                        //                                         transaction_id: result.data.id,
                        //                                         payment_mode: 'payout',
                        //                                         channel: 'razorpay',
                        //                                     };

                        //                                     // Assign Razorpay Ac_ptr as mode

                        //                                     // Initiate Payment Record in CS Acccounts
                        //                                     return ApiUtils.createPayment(params, department).then((result) => {
                        //                                         if (result.v_vno) {
                        //                                             params = {
                        //                                                 ...params,
                        //                                                 voucher_no: result.v_vno,
                        //                                                 v_ids: result.v_ids,
                        //                                                 // payment_id: result.id
                        //                                             };
                        //                                         }

                        //                                         return addPaymentEntry(request, params);
                        //                                     });
                        //                                 } else {
                        //                                     // setLoading(false);

                        //                                     message.error(result.data.description);
                        //                                 }
                        //                             })
                        //                             .catch((error) => {
                        //                                 console.log(error);

                        //                                 // setLoading(false);
                        //                             });
                        //                     } else if (values.payment_mode === 'Payment Link by Razorpay') {
                        //                         // Payout Link via Razorpay

                        //                         // For Payout
                        //                         let formBody = {
                        //                             name: values.name,
                        //                             email: values.email,
                        //                             contact: values.contact,
                        //                             amount: values.amount * 100,

                        //                             location: cityCode[request.dealerCode],
                        //                         };

                        //                         // For Payment and CS Accounts Entry
                        //                         var current = values.to_account.value;

                        //                         var params = {
                        //                             description: 'Customer Refund via Razorpay',
                        //                             ref: request.name + ' / ' + request.phone,
                        //                             // + ' / ' + request.allocation.vinNo,
                        //                             amount: values.amount,
                        //                             customer: values.to_account.value,
                        //                         };

                        //                         // params.mode = defaultBranch.razorpay_account_head;
                        //                         // Get the From Account
                        //                         params.mode = values.from_account.value;

                        //                         // Initiate the payout link
                        //                         return FirebaseUtils.initiatePayoutLink({ formBody })
                        //                             .then((result) => {
                        //                                 if (result.data && result.data.id) {
                        //                                     params = {
                        //                                         ...params,
                        //                                         // Razorpay transaction id
                        //                                         transaction_id: result.data.id,
                        //                                         payment_mode: 'payout-link',
                        //                                         channel: 'razorpay',
                        //                                     };

                        //                                     // Note : Verify this once instant link is activated

                        //                                     // Initiate Payment Record in CS Acccounts
                        //                                     return ApiUtils.createPayment(params, department).then((response) => {
                        //                                         params = {
                        //                                             ...params,
                        //                                             voucher_no: response.v_vno,
                        //                                             v_ids: response.v_ids,
                        //                                             // payment_id: result.id
                        //                                         };

                        //                                         return addPaymentEntry(request, params);
                        //                                     });
                        //                                 } else {
                        //                                     message.error(result.data.description);
                        //                                 }
                        //                             })
                        //                             .catch(() => {
                        //                                 // setLoading(false);
                        //                             });
                        //                     } else if (values.payment_mode === 'Net Banking') {
                        //                         // Normal Bank Transfer
                        //                         var current = values.to_account.value;

                        //                         var params = {
                        //                             customer: values.to_account.value,
                        //                             description: 'Customer Refund via Bank Transfer',
                        //                             ref: values.name + ' / ' + values.contact,
                        //                         };

                        //                         params = {
                        //                             source: '',
                        //                             ...params,
                        //                             amount: values.amount,
                        //                             payment_mode: 'net banking',
                        //                             channel: 'bank',
                        //                         };

                        //                         // Get the From Account
                        //                         params.mode = values.from_account.value;

                        //                         // Initiate Payment Record in CS Acccounts
                        //                         return ApiUtils.createPayment(params, department)
                        //                             .then((response) => {
                        //                                 params = {
                        //                                     ...params,
                        //                                     voucher_no: response.v_vno,
                        //                                     v_ids: response.v_ids,
                        //                                     // payment_id: result.id
                        //                                 };
                        //                                 return addPaymentEntry(request, params);
                        //                             })
                        //                             .catch((error) => {
                        //                                 console.log(error);
                        //                             });
                        //                     } else if (values.payment_mode === 'Cash') {
                        //                         // For Cash Payment
                        //                         var current = values.to_account.value;

                        //                         // Customer AC_ptr
                        //                         var params = {
                        //                             // customer: values.name,
                        //                             customer: values.to_account.value,
                        //                             description: 'Refund as Cash',
                        //                             ref: values.name + ' / ' + values.contact,
                        //                         };

                        //                         params = {
                        //                             ...params,
                        //                             amount: values.amount,
                        //                             payment_mode: 'cash',
                        //                             channel: 'cash',
                        //                         };

                        //                         // Get the mode of cash
                        //                         params.mode = department.acCodes['Cash'];

                        //                         // Initiate Payment Record in CS Acccounts
                        //                         return ApiUtils.createPayment(params, department)
                        //                             .then((response) => {
                        //                                 params = {
                        //                                     ...params,
                        //                                     voucher_no: response.v_vno,
                        //                                     v_ids: response.v_ids,
                        //                                     // payment_id: result.id
                        //                                 };
                        //                                 return addPaymentEntry(request, params);
                        //                             })
                        //                             .catch((result) => {
                        //                                 console.log(result);
                        //                             });
                        //                     }
                    });
                },
            },
        },
    },
];

/**
 * Add a Payment Entry to Firestore
 * and Add a Cart Entry
 */
function addPaymentEntry(request, params) {
    //Prepare item to add to cart
    let creation = {
        created_at: moment().format('DD/MM/YYYY HH:mm'),
        created_date: moment().startOf('day').valueOf(),
        created_time: moment().valueOf(),
    };

    params = {
        bookingNo: request.bookingId,
        requestId: request.id,
        dealerCode: request.dealerCode,
        name: request.name,

        ...params,
        ...creation,
    };

    // Create a payment record in firestore
    return FirebaseUtils.createPayment(params)
        .then(() => {
            // let cartItem = {
            //     bookingNo: request.bookingId,
            //     requestId: request.id,
            //     type: 'additional',
            //     description: params.description,
            //     price: params.amount,
            //     dealerCode: request['dealerCode'],
            //     ...creation,
            // };

            // return FirebaseUtils.addCartItem(cartItem).then(() => {
            message.success('Refund process complete');

            // setLoading(false);

            // refresh();
            // });
        })
        .catch((error) => {
            console.log(error);
        });
}

export function accountSteps(city) {
    if (city == 'kozhikode') {
        return [
            stepsArray[0]['Accounts Entry Pending']['route'],
            stepsArray[0]['Accounts Entry Pending']['form'],

            stepsArray[0]['Vehicle Billing Requests']['route'],
            stepsArray[0]['Vehicle Billing Requests']['form'],

            stepsArray[0]['Stock Transfer']['route'],
            stepsArray[0]['Stock Transfer']['form'],


            stepsArray[0]['Generate Gate Pass']['route'],
            stepsArray[0]['Generate Gate Pass']['form'],
            stepsArray[0]['Road Tax']['route'],
            stepsArray[0]['Ready for Settlement']['route'],

            // stepsArray[0]['Pending For Entry']['form'],
            stepsArray[0]['Insurance Payments']['route'],
            stepsArray[0]['Cancellations']['route'],
            stepsArray[0]['Update Accounts Head']['route'],
            // stepsArray[0]['Verify Pricing']['route'],
            stepsArray[0]['Insurance Payments']['form'],
            stepsArray[0]['Road Tax']['form'],
            stepsArray[0]['Ready for Settlement']['form'],
            stepsArray[0]['Cancellations']['form'],
            stepsArray[0]['Update Accounts Head']['form'],
            // stepsArray[0]['Verify Pricing']['form'],
        ];
    } else {
        return [
            stepsArray[0]['Accounts Entry Pending']['route'],
            stepsArray[0]['Accounts Entry Pending']['form'],

            stepsArray[0]['Vehicle Billing Requests']['route'],
            stepsArray[0]['Vehicle Billing Requests']['form'],

            stepsArray[0]['Stock Transfer']['route'],
            stepsArray[0]['Stock Transfer']['form'],

            stepsArray[0]['Generate Gate Pass']['route'],
            stepsArray[0]['Generate Gate Pass']['form'],

            // stepsArray[0]['Pending For Entry']['route'],
            // stepsArray[0]['Other Credits']['route'],
            stepsArray[0]['Insurance Payments']['route'],
            stepsArray[0]['Road Tax']['route'],
            stepsArray[0]['Ready for Settlement']['route'],
            stepsArray[0]['Cancellations']['route'],
            stepsArray[0]['Update Accounts Head']['route'],
            // stepsArray[0]['Verify Pricing']['route'],
            // stepsArray[0]['Pending For Entry']['form'],
            stepsArray[0]['Insurance Payments']['form'],
            stepsArray[0]['Road Tax']['form'],
            stepsArray[0]['Ready for Settlement']['form'],
            stepsArray[0]['Cancellations']['form'],
            stepsArray[0]['Update Accounts Head']['form'],
            // stepsArray[0]['Verify Pricing']['form'],
        ];
    }
}

export const pricingSchema = {
    tableIndex: (element) => {
        return element['Variant'] + '-' + element['Ext Color'];
    },
    rowIndex: 'key',

    tableHeader: 0,
    tableRange: 0,

    columns: [
        {
            field: 'variant',
            from: '__EMPTY',
            caption: 'Variant',
        },

        {
            field: 'model',
            from: 'Model',
            caption: 'Model',
        },

        {
            field: 'variantCode',
            from: 'Variant',
            caption: 'Variant',
        },

        {
            field: 'basicKit',
            from: 'Basic Kit',
            caption: 'Basic Kit',
        },
        // {
        //     field: 'city',
        //     from: 'City',
        //     caption: 'City'

        // },
        {
            field: 'exShowroom',
            from: 'Ex-Showroom Price',
            caption: 'Ex-Showroom Price',
        },

        {
            field: 'sellingPrice',
            from: 'Selling Price',
            caption: 'Selling Price',
        },

        {
            field: 'rto',
            from: 'RTO',
            caption: 'RTO',
        },

        {
            field: 'sellingPrice',
            from: 'Selling Price',
            caption: 'Selling Price',
        },

        {
            field: 'extColor',
            from: 'Ext Color',
            caption: 'Ext Color',
        },
        {
            field: 'extendedWarranty',
            from: 'Extended Warranty',
            caption: 'Extended Warranty',
        },
        {
            field: 'fastTag',
            from: 'Fast Tag',
            caption: 'Fast Tag',
        },

        {
            field: 'insurance',
            from: 'Insurance',
            caption: 'Insurance',
        },

        // {
        //     field: 'state',
        //     from: 'State',
        //     caption: 'State'

        // },

        {
            field: 'sgst',
            from: '__EMPTY_3',
            caption: 'SGST',
        },
        {
            field: 'cgst',
            from: 'GST',
            caption: 'CGST',
        },

        {
            field: 'igst',
            from: '__EMPTY_4',
            caption: 'IGST',
        },

        {
            field: 'cess',
            from: '__EMPTY_5',
            caption: 'CESS',
        },
    ],
};
