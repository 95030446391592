import FirebaseUtils from './../utils/firebase.utils';

import { message } from 'antd';

import GenericAdd from './../modules/common/resources/generic/generic-add/generic-add';

import GenericList from './../modules/common/resources/generic/generic-list/generic-list';

import GenericEdit from './../modules/common/resources/generic/generic-edit/generic-edit';

import GenericDetail from './../modules/common/resources/generic/generic-detail/generic-detail';

import GenericUpload from './../modules/common/resources/generic/generic-upload/generic-upload';

import { Uploads } from './index';

import DateUtils from './../utils/date.utils';
import { cityKeys, dealerCodeKey } from '../modules/global-config';

const moment = require('moment-timezone');

let cityKey = cityKeys

class Base {
    name;
    endpoint;
    pluralName;
    schema;
    fields;
    listFields;
    filterFields;

    // columns = [];

    constructor(fields = []) {
        this.endpoint = this.getEndpoint;

        this.name = this.getName;

        this.fields = fields;

        // this.columns = [];
    }

    // get columns() {
    //     return [];
    // }

    filterDate() {
        return false;
    }

    firebase() {
        return FirebaseUtils.getFirebaseObject();
    }

    getUser() {
        return FirebaseUtils.getUser();
    }


    disableAddModal() {
        return false;
    }

    disableDelete() {
        return false;
    }

    disableEdit() {
        return false;
    }

    getCreation(field = 'created') {
        var user = FirebaseUtils.getUser();

        var creation = DateUtils.getCreation(field);

        return {
            ...creation,
            deleted_at: null,
            deleted_by: null,
            [field + '_by_name']: user.name,
            [field + '_by']: user.id,
        };
    }

    dealerCode(city) {
        var keys = cityKey

        return keys[city];
    }

    getCityName(dealerCode) {
        var keys = dealerCodeKey

        return keys[dealerCode];
    }

    /**
     * Get the data from the table
     */
    getData(path, queries = [], config = {}) {
        // Get the records from firebase
        return FirebaseUtils.getModelData(queries, this, config);
    }

    /**
     * Get the data from the table
     */
    get(queries = [], config = {}) {
        // Get the records from firebase
        //console.log(location);

        return FirebaseUtils.getModelData(queries, this, config);
    }

    /**
     *
     * @param {*} queries
     * @returns
     */
    // getAll()

    getAll(queries = [], model) {
        let all = [];

        var content = {};

        var locations = ['kozhikode', 'kannur', 'malappuram','kondotty','vadakara','tirur','kasargod'];

        var locationPromise = (city) => {


            return this.getCity(queries, city).then((result) => {
                //get records from getCity
                all = [].concat(...all, ...result[model]);
                content[city] = result;
                return content;
            });
        };

        return new Promise((resolve) => {
            Promise.all(locations.map((location) => locationPromise(location))).then((result) => {
                resolve({ all, content });
            });
        });
    }

    //To delete a record
    delete = (id, config = { hideAlert: false }) => {

        // Get the records from firebase
        return FirebaseUtils.deleteRecord(this.name, id).then((result) => {

            if (!config.hideAlert) {

                message.success('Record deleted');
            }

            return result;
        });
    };
    /*
    *
    *@param {*} queries
    * @returns
    */
    // getCity()

    getCity(queries, city) {

        var q = [].concat(queries)

        var baseQueries = [
            {
                field: 'dealerCode',
                value: cityKey[city],
            },
        ];

        if (queries.length) {
            q.push(baseQueries[0]);
        }


        return this.get(q, city)
    }

    /**
     * Get the data from the table
     */


    getMethod(queries = [], config = {}) {
        // Get the records from firebase
        return FirebaseUtils.getModelData(queries, this, config);
    }

    /**
     *
     * @param {*} values
     * @returns
     */
    getRecord = (id) => {
        return FirebaseUtils.getModelRecord(id, this);
    };


    /**
     * Function used to get stock transfer record 
     * fetching data using id used corresponding model
     * @param {*} id 
     * @returns 
     */
    getRecordStock = async (id) => {
        let record
        let fromYard
        let toYard
        let driver
        let locationFrom
        let locationTo


        record = await FirebaseUtils.getModelRecord(id, this);

        if (record && record.driver_id)
            driver = await FirebaseUtils.getModelRecordData(record.driver_id, 'staff');


        fromYard = await FirebaseUtils.getModelRecordData(record.from_stock_point_id, 'stock_points');

        if (record && record.to_stock_point_id)
            toYard = await FirebaseUtils.getModelRecordData(record.to_stock_point_id, 'stock_points');

        // locationFrom =  await FirebaseUtils.getModelRecordData(record.location_from, 'branches');

        // locationTo =  await FirebaseUtils.getModelRecordData(record.location_to, 'branches');


        record = {
            ...record,
            from_yard_name: fromYard.name,
            to_yard_name: toYard ? toYard.name : null,
            staff_name: driver ? driver.name : null,
            location_from_name: record.from,
            location_to_name: record.to,
        }
        return record

    };

    add = (values, config = {}) => {
        var user = this.getUser();

        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
            created_by: user.id,
            created_by_name: user.name,
            deleted_at: null,
            deleted_by: null
        };

        let params = {
            ...values,
            ...creation,
        };

        // Get the records from firebase
        return FirebaseUtils.addModelData(this.name, params).then((result) => {
            console.log("WWWWWWWWWWWWW", result.id)
            // hide the alert
            if (!config.hideAlert) {
                message.success('Record added');
            }

            return result;
        });
    };

    /**
   *
   * @param {*} values
   * @param {*} user
   * @returns
   */
    addDocument = (id, values) => {

        let params = {
            ...this.appendDefaultValues(values),
            // ...this.extraParams()
        };

        this.cleanParams(params);

        // Get the records from firebase
        return FirebaseUtils.addDocument(id, this.name, params).then((result) => {

            message.success(`New ${this.name} created`);

            return result;
        });
    };


    /**
     * Clean the params 
     * 
     * @param {*} values 
     * @returns 
     */
    cleanParams = (values) => {

        // 
        Object.keys(values).forEach((key) => {

            if (values[key] === undefined) {

                values[key] = null
            }
        })

        return values;
    }

    /**
        * Append the default values that would be added for each record
        * 
        * @param {*} values 
        * @returns 
        */
    appendDefaultValues = (values) => {

        var user = FirebaseUtils.getUser();

        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
            created_by: user.id,
            // These columns will help 
            deleted_at: null,
            deleted_by: null,
        };

        this.cleanParams(values);

        return {
            ...values,
            ...creation
        }

    }


    /**
        * Append the default values that would be added for updation of each record
        * 
        * @param {*} values 
        * @returns 
        */
    appendUpdateDefaultValues = (values) => {

        var user = FirebaseUtils.getUser();

        let creation = {

            updated_at: moment().format('DD/MM/YYYY HH:mm'),
            updated_date: moment().startOf('day').valueOf(),
            updated_time: moment().valueOf(),
            updated_by: user.id,

            // These columns will help 
            deleted_at: null,
            deleted_by: null,
        };

        this.cleanParams(values);

        return {
            ...values,
            ...creation
        }

    }

    /**
     * Update of model
     *
     * @param {*} id
     * @param {*} values
     * @param {*} user
     * @returns
     */
    update = (id, values, config = {}) => {
        var user = this.getUser();

        let creation = {
            updated_at: moment().format('DD/MM/YYYY HH:mm'),
            updated_date: moment().startOf('day').valueOf(),
            updated_time: moment().valueOf(),
            updated_by: user.id,
        };

        let params = {
            ...values,
            ...creation,
        };

        // Get the records from firebase
        return FirebaseUtils.updateRecord(this.name, id, params).then((result) => {
            // hide the alert
            if (!config.hideAlert) {
                message.success('Record updated');
            }

            return result;
        });
    };

    /**
     *
     *
     * @param {*} id
     * @param {*} field
     * @param {*} value
     * @returns
     */
    updateField = (id, field, value) => {
        return FirebaseUtils.updateFieldValue(this.name, id, field, value);
    };

    /**
     * 
     * @param {*} id 
     * @returns 
     */
    getRecordReference = (id = null) => {

        return FirebaseUtils.getRecordReference(this, id);
    }

    /**
    * Append the default values that would be added for each record
    * 
    * @param {*} values 
    * @returns 
    */
    appendDefaultValues = (values) => {

        var user = FirebaseUtils.getUser();

        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
            created_by: user.id,
            created_by_name: user.name,

            // These columns will help 
            deleted_at: null,
            deleted_by: null,
        };

        this.cleanParams(values);

        return {
            ...values,
            ...creation
        }

    }


    /**
 * Clean the params 
 * 
 * @param {*} values 
 * @returns 
 */
    cleanParams = (values) => {

        // 
        Object.keys(values).forEach((key) => {

            if (values[key] === undefined) {

                values[key] = null
            }
        })

        return values;
    }




    /**
     * Base function for Generic upload
     *
     * @param {*} records
     * @param {*} user
     * @returns
     */
    write = (records, location) => {
        return this.upload(records, location);
    };

    /**
     *
     * @param {*} records
     * @returns
     */
    upload = (records, location) => {
        var user = this.getUser();

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        Object.keys(records).forEach((key) => {
            var docRef = app.collection(this.endpoint).doc(key);

            batch.set(docRef, records[key], { merge: true });
        });

        let body = {
            model: this.endpoint,
            created_by: {
                name: user.name,
                role: user.role,
            },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),
            dealerCode: this.dealerCode(location),
            city: location,
        };

        return Uploads.add(body, { hideAlert: true }).then(() => {
            return batch.commit();
        });
    };


    /**
     * Get data per dealer
     *
     * @param {*} location
     * @param {*} queries
     * @returns
     */
    getLocationData = (location, queries) => {
        queries.push({
            field: 'dealerCode',
            value: cityKey[location],
        });

        return this.get(queries);
    };

    //Add document of uploaded time
    addUploadsDetails = (user) => {

        return FirebaseUtils.addUploadsDetails(user, this.name);
    };

    //  * Base Function to access any custom firebase function
    //  *
    //  * @param {*} endpoint
    //  * @param {*} formBody
    //  * @returns
    //  */
    loadFunction = (endpoint, formBody) => {
        var firebase = FirebaseUtils.getFirebaseObject();

        var callableFunction = firebase.functions().httpsCallable(endpoint);

        return callableFunction(formBody)
            .then((result) => {
                return result.data;
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    };

    /**
     * Function written on base model so that it can be used anywhere
     *
     * @param {*} value
     * @param {*} round
     * @returns
     */
    getRoundedAmount = (value, round) => {
        value = parseFloat(value.toFixed(round));

        return value;
    };


    AddComponent = GenericAdd;

    ListComponent = GenericList;

    EditComponent = GenericEdit;

    DetailComponent = GenericDetail;

    UploadComponent = GenericUpload;

    ModalAddComponent = GenericAdd;
}

export default Base;
