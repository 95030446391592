
import React, { useContext } from "react";

import { Tabs, Typography } from "antd";

// import "./stock-dashboard.scss";

import CurrentStock from './stock-status/stock-status';

import { GlobalContext } from './../../../../Store';

const { TabPane } = Tabs;

const { Title } = Typography;

export default function ActualStock(props) {

    const { user } = useContext(GlobalContext)

    let location = user.locations[0];

    const stockLocation = {
        'kozhikode': 2,
        'kannur': 4,
        'malappuram': 3
    }

    return (
        <section className="landing actual-stock">

            <Title level={4}>DMS Stock</Title>

            <Tabs defaultActiveKey={`${stockLocation[location]}`}>

                <TabPane tab="All" key="1">
                    <CurrentStock url="all" />
                </TabPane>


                <TabPane tab="KOZHIKODE" key="2">
                    <CurrentStock url="kozhikode" />
                </TabPane>

                <TabPane tab="MALAPPURAM" key="3">
                    <CurrentStock url="malappuram" />
                </TabPane>


                <TabPane tab="KANNUR" key="4">
                    <CurrentStock url="kannur" />
                </TabPane>

                <TabPane tab="KONDOTTY" key="5">
                    <CurrentStock url="kondotty" />
                </TabPane>

                <TabPane tab="TIRUR" key="6">
                    <CurrentStock url="tirur" />
                </TabPane>


                <TabPane tab="VADAKARA" key="7">
                    <CurrentStock url="vadakara" />
                </TabPane>

                <TabPane tab="KASARGOD" key="8">
                    <CurrentStock url="kasargod" />
                </TabPane>

            </Tabs>
        </section>
    );
}
