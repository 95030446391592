import Base from './base';

class LookUpType extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'lookup-types';
    }

    get path() {
        return `lookup-types`;
    }

    
}

export default LookUpType;
