
import React, { useState, useEffect, useContext } from "react";


import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom'

import { Row, Tabs, Button, Tag, Alert } from "antd";

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import "./dms-allocation.scss";

const { TabPane } = Tabs;


export default function DmsAllocation(props) {

    const { user } = useContext(GlobalContext);

    useEffect(() => {

    }, [user])

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="finance">



            <Alert
                message="Allocation Requests"
                description="Below are allocation requests from SM's that is waiting for dms entry."
                type="info"
                showIcon
                closable
            />


            <Tabs defaultActiveKey="1" onChange={callback}>

                {
                    user.locations.map((location, index) => {

                        return (
                            <TabPane tab={`${location} Allocation Requests`} key={index}>

                                <FinanceList url={location} />

                            </TabPane>
                        )
                    })
                }

            </Tabs>

        </section>
    );
}


const FinanceList = ({ url }) => {

    const [allocations, setAllocations] = useState([]);

    const [loader, setLoader] = useState(true);


    useEffect(() => {

        getData();

    }, [])


    function getData() {

        setLoader(true);

        var queries = [{
            field: 'dmsAllocation',
            value: 'requested'
        }]

        FirebaseUtils.getRequests(url, queries).then((result) => {

            setLoader(false);

            setAllocations(result.requests);

        });

    }


    return (<><div className="list-header">

        <p className="page-desc">
            {loader ? 'Loading data' : `Found ${Object.keys(allocations).length} results`}
        </p>

        <div className="list-actions">
            <Button onClick={() => { getData() }} type="secondary" size={'small'}>Refresh</Button>
        </div>

    </div>

        <Row className="report-listing-wrapper">
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                    <>
                        {
                            (allocations).map((report, index) => {
                                return <AllocationCard request={report} index={index} key={index} />
                            })
                        }
                    </>
                )}
        </Row></>)
}


function AllocationCard({ request }) {

    return (<Link className="request-card" to={`${request.city}/allocation-status/${request.id}`}>
        <div className="card">

            <h2 className="title amount ">
                {request.customer.customerName}

            </h2>
            <h4 className="title">
                {request.customer.phone}
            </h4>


            <h3 className="title ">
                {request.variant} , {request.city}
            </h3>

            <h4>
                {request.color}
            </h4>

            <p className="address">
                {request.customer.remarks}
                {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
            </p>


            Created at {request.created_at}
        </div>
    </Link>)
}
