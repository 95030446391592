import React, { useEffect, useState, useContext } from 'react'

import { Link } from 'react-router-dom'

import { Switch, Card, Space, Table, Typography, Tag, Button } from 'antd';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';


import { GlobalContext } from '../../../../Store';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import './manage-requests.scss';

import RequestCard from './../../../common/components/request-card/request-card';




const { Text, Title } = Typography;


var statusColor = {
    'Initial': 'magenta',
    'Booked': 'volcano',
    'Allocated': 'orange'
}


export default function ManageRequests({ history, report, index }) {

    const { user, isMobile } = useContext(GlobalContext);

    const [result, setResults] = useState([]);

    const [loading, setLoading] = useState(true);

    const [view, setView] = useState(isMobile);

    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    })

    const [page, setPage] = React.useState(1);

    const [location, setLocation] = useState('all');

    const columns = [{
        title: 'Index',
        dataIndex: 'index',
        render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
    },
    {
        title: 'Name',
        render: (text, row, index) => {

            return (<><>{text.customer.customerName}</> <> {text.isHighPriority && text.highPriorityStatus === 'approved' ? <Tag color="red">High Priority</Tag> : null}</></>);
        },
        key: 'name'
    },
    {
        title: 'Phone',
        dataIndex: 'customer',
        render: (text, row, index) => {
            return text.phone;
        },
        key: 'phone',
    }, 
    // {
    //     title: 'Status',
    //     // dataIndex: 'customer',
    //     render: (text, row, index) => {

    //         let status = 'Initial';

    //         if (text.bookingNo) {

    //             status = 'Booked';

    //             if (text.vinNo) {
    //                 status = 'Allocated';
    //             }
    //         }


    //         return (<Tag color={statusColor[status]}>
    //             {text.status}
    //         </Tag>)


    //     },
    //     key: 'status',
    // },
    {
        title: 'KEC',
        dataIndex: 'kec',
        key: 'kec',
    },
    // {
    //     title: 'Team Leader',
    //     dataIndex: 'teamLeader',
    //     key: 'teamLeader',
    // },

    {
        title: 'Variant',
        dataIndex: 'variant',
        key: 'variant',
    }, {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
    }, {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
    },
    // {
    //     title: 'Created By',
    //     dataIndex: 'created_by',
    //     key: 'created_by',
    //     render: (text, row, index) => {
    //         return text.name;
    //     },
    // }, 
    {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
    }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => {

            return (
                <Space size="middle">
                    <Link to={`/requests/${text.id}`}>
                        View
                        </Link>
                </Space>
            )
        },
    }
    ];

    useEffect(() => {
        if (user) {

            if (location === 'all') {

                getAllRequests();

            } else {

                getBookings(location);

            }

        }

    }, [user])

    const loadData = () => {

        getBookings(location);

        // let tab = user.locations[index];

        // getBookings(tab);
        // setLocation(tab);

    }

    function getBookings(location) {

        setLoading(true);

        FirebaseUtils.getRequests(location).then((result) => {

            console.log(result);

            // let b = Object.keys(result.requests).map((request) => {
            //     return { ...result.requests[request], requestId: request }
            // });

            setResults(result.requests);

            setLoading(false);


        });
    }

    function getAllRequests() {

        setLoading(true);

        FirebaseUtils.getAllRequests().then((result) => {

            console.log(result);

            setResults(result.all);

            setLoading(false);

        });
    }


    function changeView(result) {
        console.log(result);
        setView(result)
    }



    const menu = () => {
        return <Menu>

            <Menu.Item key={index}>
                <span onClick={() => {


                    setLocation('all');

                    getAllRequests();

                }}>All Locations</span>
            </Menu.Item>


            {user.locations.map((location, index) => <Menu.Item key={index}>
                <span onClick={() => {


                    setLocation(location);

                    getBookings(location);

                }}>{location}</span>
            </Menu.Item>)}</Menu>
    }


    return (
        <div className="manage-requests">


            <div className="page-header">



                <div className="left">

                    <Dropdown overlay={menu} trigger={['click']}>
                        <Title level={4}>
                            {location} requests <DownOutlined />
                        </Title>
                    </Dropdown>


                    <Text type="secondary">{`${result.length} records`}</Text>

                </div>




                <div className="right">

                    <Button onClick={loadData} type="secondary" size={'small'}>Refresh</Button>




                    <div className="table-hint right">

                        <Switch
                            defaultChecked
                            onChange={changeView}
                            checked={view}
                            checkedChildren={<OrderedListOutlined />}
                            unCheckedChildren={<PicCenterOutlined />}
                        />

                    </div>
                </div>



            </div>



            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <>

                        <>
                            {
                                !view ?
                                    <Card size="small">
                                        <Table
                                            rowKey={record => record.id}
                                            scroll={{ x: true }}
                                            dataSource={result}
                                            columns={columns}>

                                            <Table.Column
                                                title="Index"
                                                key="index"
                                                render={(value, item, index) => (page - 1) * 10 + index}
                                            />

                                        </Table>
                                    </Card>
                                    :
                                    <CardList url={location} data={result} />
                            }
                        </>




                        <div>

                        </div>

                    </>
            }

        </div>
    )
}




function CardList({ data, url }) {

    console.log(data);

    return (
        data.map((report, index) => {
            return <RequestCard city={url} request={report} index={index} key={index} />
        })
    )
}


