/**
 * Display stock transfer details
 */

import React, { useEffect, useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Row, Tag, Skeleton } from 'antd';

import './stock-transfer-details.scss';
import { StatusMasters, StockTransfers } from '../../../../models';

export default function StockTransferDetails({ transferRequest, stockTransfers }) {

    const [loading, setLoading] = useState(false);

    const [transferData, setTransferData] = useState([])

    const [transferStatus, setTransferStatus] = useState({});


    useEffect(() => {

        setLoading(true);

        getStockTransfersStatus(transferRequest)

        getTransferData()

    }, [stockTransfers])

    /**
     *Showing current status of stocktransfer process
     *@param {*} request.transferRequest
     */
    function getStockTransfersStatus(id) {
        StockTransfers.getRecord(id).then(async (result) => {
            let queries = [
                {
                    field: 'mode',
                    value: 'stock_transfers',
                },
                {
                    field: 'name',
                    value: result.status,
                },
            ];

            StatusMasters.get(queries).then((res) => {
                if (res) {
                    let status = res.status_masters[0].attribute
                    setTransferStatus(status)
                }
            })
        })
    }


    async function getTransferData() {

        // Object to store grouped stocktransfer
        const groupedRecords = {};

        // Grouping records by batch ID
        stockTransfers.forEach(record => {
            if (record.type === 'outward') {
                // For outward records, use the batch ID as the key
                const batchId = record.batch_id || null;

                // If the batch ID is null,then there is no inward record, create an empty array
                if (batchId === null) {
                    groupedRecords[batchId] = [];
                    groupedRecords[batchId].push(record);
                }

            } else if (record.type === 'inward') {
                // For inward records, find the corresponding outward record using the id
                const correspondingOutwardRecord = stockTransfers.find(outwardRecord => outwardRecord.type === 'outward' && outwardRecord.batch_id === record.id);

                if (correspondingOutwardRecord) {
                    // If the outward record is found, push both inward and outward records to the same group
                    const batchId = correspondingOutwardRecord.batch_id;

                    if (!groupedRecords[batchId]) {
                        groupedRecords[batchId] = [];
                    }

                    groupedRecords[batchId].push(correspondingOutwardRecord, record);
                }
            }
        });


        // Convert the object back to an array
        var groupedArray = Object.entries(groupedRecords).map(([batchId, transfers]) => ({
            batchId,
            transfers,
        }));


        return Promise.all(
            groupedArray.map((record) => {
                // Find the objects with type "in" and "out"
                const outwardData = record.transfers.find(obj => obj.type === "outward");
                const inwardData = record.transfers.find(obj => obj.type === "inward");

                // Create a new object based on conditions
                const transferData = {
                    from: outwardData.from,
                    from_yard_name: outwardData.from_yard_name,
                    to: outwardData.to,
                    to_yard_name: outwardData.to_yard_name,
                    status: inwardData ? inwardData.status : outwardData.status,
                    sub_mode: outwardData.sub_mode,
                    from_created_at: outwardData.created_at,
                    to_created_at: inwardData ? inwardData.created_at : null,
                    from_staff_name: outwardData.staff_name,
                    to_staff_name: inwardData ? inwardData.staff_name : null,
                    from_remarks: outwardData.remarks,
                    to_remarks: inwardData ? inwardData.remarks : null

                };

                console.log(transferData)
                return transferData

            })).then((result) => {
                setTransferData(result)
                setLoading(false)
            })


    }


    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <div className="details">
                    {/* The above code is rendering a list of stock transfers. For each transfer, it creates a 
               detail card with information about the transfer. It displays the details of the "From" and "To"
               location */}
               {/* Showing current status of stocktransfer process */}
                    <div className="details" >
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            {!transferStatus.done && (
                                <Row span={12}>
                                    <span> Next Step :</span>
                                </Row>
                            )}
                            <Row span={12}>
                                <h3 style={{ color: transferStatus.done ? '#52c41a' : '#ff4d4f' }}>
                                    {transferStatus.description}
                                </h3>
                            </Row>
                        </div>
                    </div>


                    {transferData.map((transfer) => (
                        <div className="detail-card">


                            <div className="guest-details">
                                <div style={{ margin: '8px' }}>
                                    <h3>From</h3>
                                </div>

                                <div className="guest-details-info">
                                    <div gutter={0} className="detail-wrapper" style={{ margin: '8px' }}>
                                        <div className="detail-element ">
                                            {' '}
                                            <Row span={8}>
                                                <span className="details">Location</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong>{transfer.from}</strong>
                                                </span>
                                            </Row>
                                        </div>

                                        <div className="detail-element ">
                                            <Row span={8}>
                                                <span className="details">Yard</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong> {transfer.from_yard_name}</strong>
                                                </span>
                                            </Row>
                                        </div>

                                        <div className="detail-element ">
                                            <Row span={8}>
                                                <span className="details">Date&Time</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong>{transfer.from_created_at}</strong>
                                                </span>
                                            </Row>
                                        </div>

                                        {transfer.sub_mode === 'virtual' ? null : (
                                            <div className="detail-element ">
                                                <Row span={8}>
                                                    <span className="details">Driver</span>
                                                </Row>
                                                <Row>
                                                    <span>
                                                        <strong>{transfer.from_staff_name}</strong>
                                                    </span>
                                                </Row>
                                            </div>
                                        )}
                                        
                                        <div className="detail-element ">
                                            <Row span={8}>
                                                <span className="details">Remarks</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong>{transfer.from_remarks}</strong>
                                                </span>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* status */}

                            <div style={{ padding: '50px', fontSize: '30px' }}>
                                <Row>
                                    <Tag color={transfer.status === 'cancelled' ? 'orange' : 'green'}>
                                        {transfer.status}
                                    </Tag>
                                </Row>
                                <ArrowRightOutlined style={{ color: '#626F86' }} />
                                <Row>
                                    <Tag color={transfer.sub_mode === 'virtual' ? 'orange' : 'green'}>
                                        {transfer.sub_mode}
                                    </Tag>
                                </Row>
                            </div>

                            {/* TO */}
                            <div className="guest-details">
                                <div style={{ margin: '8px' }}>
                                    <h3>To</h3>
                                </div>
                                <div className="guest-details-info">
                                    <div gutter={0} className="detail-wrapper">
                                        <div className="detail-element ">
                                            {' '}
                                            <Row span={8}>
                                                <span className="details">Location</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong>{transfer.to}</strong>
                                                </span>
                                            </Row>
                                        </div>

                                        <div className="detail-element ">
                                            <Row span={8}>
                                                <span className="details">Yard</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong> {transfer.to_yard_name}</strong>
                                                </span>
                                            </Row>
                                        </div>

                                        <div className="detail-element ">
                                            <Row span={8}>
                                                <span className="details">Date&Time</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong>{transfer.to_created_at}</strong>
                                                </span>
                                            </Row>
                                        </div>
                                        {transfer.sub_mode === 'virtual' ? null : (
                                            <div className="detail-element ">
                                                <Row span={8}>
                                                    <span className="details">Driver</span>
                                                </Row>
                                                <Row>
                                                    <span>
                                                        <strong>{transfer.to_staff_name}</strong>
                                                    </span>
                                                </Row>
                                            </div>
                                        )}
                                        <div className="detail-element ">
                                            <Row span={8}>
                                                <span className="details">Remarks</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong>{transfer.to_remarks}</strong>
                                                </span>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* </Card> */}
                        </div>
                    ))}
                </div>

            )
            }
        </>

    );
}
