

import React, { useState, useEffect, useContext } from 'react'

import { List, Form, Comment, Button, Avatar, Input, Typography } from 'antd';

import { GlobalContext } from './../../../../Store';

import FirebaseUtils from './../../../../utils/firebase.utils';

import './comment-block.scss';

import moment from 'moment';

const { Title } = Typography;

const { TextArea } = Input;

export default function CommentBlock({ id, index ,  loadComments}) {

    const { user } = useContext(GlobalContext);

    const [comments, setComments] = useState([]);

    const [comment, setComment] = useState('');

    useEffect(() => {

        FirebaseUtils.getComments(id).then((result) => {

            setComments([...result]);

        })

        return () => {
        }
    }, [])

    const pushComment = (comment) => {

        let content = {
            author: user.name,
            // avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            content: comment,
            datetime: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),
            type: 'comment',
            deleted_at:null,
            deleted_by:null
        }

        setComment('');

        FirebaseUtils.addComment(id, content).then((result) => {

            comments.push(content);

            setComments([...comments]);

            setComment('');

            loadComments(id);

        })
      
    }

    return (
        <div className="comment-block">

            <Title level={4}>
                Comments
            </Title>

            {/* Comments */}

            {comments.length > 0 && <CommentList comments={comments} />}

            <Comment
                // avatar={
                //     <Avatar
                //         src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                //         alt="Han Solo"
                //     />
                // }
                content={
                    <Editor
                        onChange={(e) => { setComment(e.target.value) }}
                        onSubmit={() => {
                            pushComment(comment);
                        }}
                        submitting={() => { }}
                        value={comment}
                    />
                }
            />

            {/* Comment Ends */}
        </div>
    )
}



const CommentList = ({ comments }) => (

    <List
        dataSource={comments.sort((a, b) => {
            if (a.created_time < b.created_time) {
                return -1
            }
            if (a.created_time > b.created_time) {
                return 1
            }
            return 0;

        })}
        header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
        itemLayout="horizontal"
        renderItem={props => <Comment {...props} />}
    />
);


const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={false} onClick={onSubmit} type="primary">
                Add Comment
        </Button>
        </Form.Item>
    </>
);