
/**
 * Component to display vehicle information
 */

import React, { useEffect, useState, useContext } from 'react';

import { Vehicles, StockPoints } from '../../../../models';

import moment from 'moment';

import { Typography, Badge } from 'antd';

const { Title, Text } = Typography;

var badgeColor = {
    Allocated: 'error',
    'In transit': 'warning',
    'Free Stock': 'success',
};




export function VehicleDisplayCard({ vinNo }) {

    const [vehicle, setVehicle] = useState({})

    const [stockLocation, setStockLocation] = useState()

    const [stockAge, setStockAge] = useState()

    useEffect(() => {
        // Get Vehicle data with vin number
        getVehicleData()

       
    }, [])

    function getVehicleData() {
        Vehicles.getRecord(vinNo).then((result) => {

            setVehicle(result)

            getStockLocation(result)

            getStockAge(result)
        })

    }

    // Get stock location from stock id of vehicle

    function getStockLocation(vehicle) {
        if (vehicle.stock_point_id) {
            StockPoints.getRecord(vehicle.stock_point_id).then((result) => {
                setStockLocation(result.name)
            })
        }

    }

    // Get stock age of vehicle

    function getStockAge(vehicle) {
        let curdate = moment().valueOf();

        let difference = curdate - moment(vehicle['KIN Invoice Date'], 'DD/MM/YYYY').valueOf();

        const diffInDays = difference / 86400000;

        setStockAge(diffInDays.toFixed(0))
    }


    return (
        <div>
            <Title level={5}>{`${vehicle['Vin Number']}`}</Title>

            {vehicle.labels ?
                <p>{vehicle.labels[0]}</p>
                : null}
            <small className="status">
                <Badge status={badgeColor[vehicle['Stock Status']]} />
                {stockLocation} <Text type="danger">{stockAge} days</Text>
                {/* {vehicle['Stock Location'] || vehicle['Stock Status']} - <Text type="danger">{vehicle['Stock Age']} days</Text> */}

            </small>
        </div>
    )

}