/**
 *
 * Component for listing all vehicles for the PDI to do NVI process
 *
 * @description
 * @author Sharfudheen
 *
 *
 */

// Native Imports

import React, { useState, useEffect, useContext } from 'react';

// Library Imports
import { Typography, Input, Tabs } from 'antd';

// Component and Util Imports
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import QRSearch from './qr-scanner';

import './pdi-list.scss';


const { TabPane } = Tabs;

const { Title } = Typography;

/**
 * Renders a section for review requests with a title, tabs for each location, and a list of PDIs for the selected location.
 *
 * @param {Object} step - The step object containing the caption for the title.
 * @returns {JSX.Element} The JSX element representing the review requests section.
 */
export default function TransitVehicles({ step, history }) {
    // The user object containing user data , also a list of locations.
    const { user } = useContext(GlobalContext);

    /**
     * The function to be called when the active tab changes.
     *
     * @param {*} key
     */
    function callback(key) {
        console.log(key);
    }

    return (
        <section className="review-requests">
            <Title level={3}>{step.caption}</Title>

            <Tabs defaultActiveKey="1" onChange={callback}>
                {user.locations.map((location, index) => {
                    return (
                        <TabPane tab={`${location}`} key={index}>
                            <PDIList url={location} step={step} user={user} history={history} />
                        </TabPane>
                    );
                })}
            </Tabs>
        </section>
    );
}

/**
 * A functional component that renders a list of PDI items.
 *
 * @param {string} url - The URL to fetch the list of PDI items from.
 * @param {number} step - The number of items to display per page.
 * @returns {JSX.Element} A React element representing the PDI list.
 *
 */
const PDIList = ({ url, step, user, history }) => {
    //  The state variable to store the array of allocations.
    const [allocations, setAllocations] = useState([]);

    // The state variable to store the array of search results.
    const [searchList, setSearchList] = useState([]);

    // The state variable to toggle search functionality.
    const [enableSearch, setEnableSearch] = useState(false);

    // The state variable to show/hide loading indicator.
    const [loader, setLoader] = useState(true);

    /**
     * Calls the getData function when the component mounts.
     * @function
     * @name useEffect
     * @param {Function} getData - A function that retrieves data.
     * @param {Array} [] - An empty array, indicating that this effect should only run once, on mount.
     */

    useEffect(() => {
        getData();
    }, []);

    /**
     * Retrieves data from FirebaseUtils based on the provided URL, queries, and model.
     *
     * @function
     * @name getData
     * @async
     *
     * @returns {Promise<void>} - A promise that resolves once the data has been retrieved and set in state.
     * @throws {Error} - If there is an error retrieving or setting the data.
     *
     */
    function getData() {
        setLoader(true);

        // * @param {string} url - The URL of the FirebaseUtils listing to retrieve data from.
        // * @param {object} queries - An object containing the query parameters to filter the data by.
        // * @param {string} [model='vehicles'] - The model of data to retrieve (default: 'vehicles').

        if (step.model === 'stock_transfers') {
            var queries = step.queries({ user });
            FirebaseUtils.getBaseRecords(queries, step.model).then((vehicles) => {
                setLoader(false);
                setAllocations(vehicles[step.model]);
            });
        } else {

            FirebaseUtils.getListing(url, step.queries, step.model || 'vehicles').then((vehicles) => {
                setLoader(false);
                setAllocations(vehicles[step.model || 'vehicles']);
            });
        }
    }

    /**
     * Handles input events for searching allocations by VIN number
     *
     * @param {Event} event - The input event object
     * @return {void}
     */
    const onInput = (event) => {
        const value = event.target.value.toLowerCase();

        let arr = [];

        allocations.forEach((item) => {
            if (item['Vin Number'] && item['Vin Number'].toLowerCase().includes(value)) {
                arr.push(item);
            }
        });

        setSearchList(arr);

        setEnableSearch(true);
    };

    /**
     * Returns the count of search results.
     * @type {number}
     */

    const searchResultsCount = enableSearch ? searchList.length : allocations.length;

    /**
     * @returns {JSX.Element} The JSX element representing the list header section.
     * Shows the count of total results.
     * Calls the getData function when the "Refresh" button is clicked.
     * @param {function} getData - The function to call when the button is clicked.
     * Antd Input box for searching the VIN number.
     * Renders a list of reports/vehicles in a wrapper.
     * @param {Object} props - The props object containing the following:
     * @param {boolean} props.loader - A flag indicating whether the data is still loading.
     * @param {boolean} props.enableSearch - A flag indicating whether to enable search functionality.
     * @param {Array} props.searchList - An array of reports/vehicles to be displayed when search is enabled.
     * @param {Array} props.allocations - An array of reports/vehicles to be displayed when search is disabled.
     */

    return (
        <>
            <div className="list-header">
                <p className="page-desc">{loader ? 'Loading data' : `Found ${searchResultsCount} results`}</p>
                <div className="list-actions">


                    <button
                        onClick={() => {
                            getData();
                        }}
                        type="button"
                        className="secondary"
                        size={'small'}
                    >
                        Refresh
                    </button>
                    {/** QR scan Component . Scanning QR and Redirect  */}
                    <QRSearch
                        Callback={(values) => {

                            // Perform navigation logic based on the scanned data
                            history.push(`/${step.redirect_to}/${values}`);
                        }}
                    />
                </div>
            </div>
            <Input type="text" placeholder="Search VIN number here" onInput={onInput} style={{ marginBottom: '10px' }} />

            <div className="report-listing-wrapper">
                {loader ? (
                    <PlaceHolder type="listing" />
                ) : (
                    <>
                        {enableSearch
                            ? searchList.map((report, index) => {
                                return <step.Card step={step} vehicle={report} index={report.id} key={report.id} />;
                            })
                            : allocations.map((report, index) => {
                                return <step.Card step={step} vehicle={report} index={report.id} key={report.id} />;
                            })}
                    </>
                )}
            </div>
        </>
    );
};
