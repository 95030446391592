import React, { useEffect, useState, useContext } from 'react';

import { Form, Space, Checkbox, Modal, Upload, Popconfirm, message, Divider, Typography, Button, Tag, Select, Alert } from 'antd';

import { GlobalContext } from '../../../../Store';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { UploadOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';

import ApiUtils from './../../../../utils/api.utils';

import moment from 'moment';

import { Departments, BankCredits } from '../../../../models';

import './bank-upload.scss';
import {  cityKeys } from '../../../global-config';

const { Title } = Typography;

const { Option } = Select;

// The option of upload
const upload_options = ['Customer Receipts', 'Bulk Posting', 'Cheque Clearing', 'Other Credits'];

const layout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 12,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 12,
        },
    },
};

/**
 *
 * Bank Upload Function
 *
 * @param {*} param0
 * @returns
 */
export default function BankUpload({ history, report, index, department }) {
    const [visible, setVisible] = useState(true);

    const [data, setData] = useState([]);

    // Bank
    const [current, setCurrent] = useState({});

    // For the filter
    const [selectedbank, setSelectedbank] = useState('');

    const { user, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [modes, setModes] = useState([]);

    // const [options, setOptions] = useState(branches);

    const [location, setLocation] = useState(user.locations[0]);

    const [entries, setEntries] = useState([]);

    const [files, setFiles] = useState([]);

    const [selectedBanks, setselectedBanks] = useState([]);

    const [form] = Form.useForm();

    const [banks, setBanks] = useState([]);

    const [codes, setCodes] = useState({});

    const [bankFromUpload, setBankFromUpload] = useState({});

    const [deptID, setDeptID] = useState();

    const [selected, setSelected] = useState();

    const [dept, setDept] = useState([]);

    const [defaultBranch, setDefaultBranch] = useState();

    const [selectedBranch, setSelectedBranch] = useState();

    var cityKey = cityKeys

    useEffect(() => {
        getDep();
        getDefaultBranch();
    }, []);

    useEffect(() => {
        if (user) {
            getBankReceipts(location);
        }

        if (selectedBranch) {
            loadModes('Net Banking', selectedBranch);
        }

        getBanks();
    }, [user]);

    useEffect(() => {
        if (defaultBranch && defaultBranch.location) {
            loadModes('Net Banking', defaultBranch);
        }
    }, [defaultBranch]);

    function getBanks() {
        // Get all the banks
        FirebaseUtils.getBanks().then((result) => {
            let banks = result;

            setBanks(banks);

            let codes = {};

            banks.forEach((bank) => {
                codes[bank.code] = bank.location;
            });

            setselectedBanks(banks.map((b) => b.code));

            setCodes(codes);
        });
    }

    async function getDefaultBranch() {
        var query = [
            {
                field: 'description',
                value: department[0],
            },
            {
                field: 'location',
                value: user.locations[0],
            },
            {
                field: 'id',
                operator: 'in',
                value: user.department_ids,
            },
        ];

        await Departments.get(query).then((res) => {
            setDefaultBranch(res.departments[0]);
        });
    }

    async function getDep() {
        var query = [
            {
                field: 'description',
                value: department[0],
            },

            {
                field: 'id',
                operator: 'in',
                value: user.department_ids,
            },
        ];
        await Departments.get(query).then((res) => {
            setDept(res.departments);
        });
    }

    /**
     * Load the Modes
     */
    function loadModes(query, branch) {
        return ApiUtils.getModes(query, branch).then((result) => {
            setModes(result);
        });
    }

    /**
     * Get stock Data
     */
    function getBankReceipts(location) {
        setLoading(false);
    }

    function getDepartment(city) {
        var query = [
            {
                field: 'description',
                value: department[0],
            },

            {
                field: 'dealer_code',
                value: cityKey[city],
            },
        ];

        return Departments.get(query).then((result) => {
            setDeptID(result.departments[0].id);
            return result.departments[0].id;
        });
    }

    function handleFile(f) {
        if (f.status !== 'removed') {
            var reader = new FileReader();

            const rABS = !!reader.readAsBinaryString;

            reader.onload = function (e) {
                const bstr = e.target.result;

                var index = bstr.indexOf('Txn Date');

                var accIndex = bstr.indexOf('Description');

                let accountNumber = bstr.substring(0, accIndex);
                const acc = accountNumber.split(':');
                acc[0] = acc[0].trim();
                acc[1] = acc[1].replace(/,/g, '');
                acc[1] = acc[1].substring(0, acc[1].length - 2);

                let bank = banks.filter((item) => item['bank_account'].includes(acc[1].substr(acc[1].length - 3)));

                setBankFromUpload(bank);

                setCurrent(bank[0].account_head);

                let data = csvJSON(bstr.substring(index));

                setData({ data });
            };

            if (rABS) reader.readAsBinaryString(f);
            else reader.readAsArrayBuffer(f);
        } else {
            setFiles([]);
        }
    }

    function csvJSON(csv) {
        const lines = csv.split('\n');
        const result = [];
        const headers = lines[0].split(',');

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i]) continue;
            const obj = {};
            const currentline = lines[i].split(',');

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }

        return result;
    }

    function compareStatus(data) {
        var hasNumber = /\d/;

        var actualData = [];

        var keys = Object.keys(data[0]);

        var balanceIndex = keys[7];

        return Promise.all(
            data.map(async (entry) => {
                let content = {
                    txn_date: convertDate(entry['Txn Date']),
                    value_date: convertDate(entry['Value Date']),
                    description: entry['Description'],
                    ref: entry['Ref No./Cheque No.'],
                    branch_code: entry['Branch Code'],
                    balance: entry[balanceIndex],
                    deleted_at: null,
                    deleted_by: null,
                    department_id: deptID,
                };

                if (hasNumber.test(entry['        Debit'])) {
                    content.debit = entry['        Debit'];
                }

                if (hasNumber.test(entry['Credit'])) {
                    content.credit = entry['Credit'];
                }

                // Add the default params to be added on initial upload

                content.created_at = moment().valueOf();

                content.transaction_date = moment(content['txn_date'], 'DD/MM/YYYY HH:mm').valueOf();

                // content.bank = bank;
                content.bank = bankFromUpload[0].code;

                // content.bank = selectedbank;

                // content.bank = form.getFieldValue('bank');

                content.pending_at = 'new';

                content.status = 'new';
                // todo decide on the status

                // content.pending_to_reconcile = true;
                // note note required as this is evidently pending

                content.city = codes[content.bank];

                getDepartment(content.city).then((res) => {
                    content.department_id = res;
                });

                content.source = 'bank';

                if (content.credit) {
                    content.reconcile_status = 'pending';
                }

                if (content.credit || content.debit) {
                    actualData.push(content);
                }

                content.mode = bankFromUpload[0].account_head;
            })
        ).then(async () => {
            await getUpdate(actualData);
        });
    }

    function convertDate(serial) {
        if (serial.indexOf('-') !== -1) {
            return moment(serial, 'DD-MM-YYYY').format('DD-MM-YYYY HH:mm');
        } else if (serial.indexOf('/') !== -1) {
            return moment(serial, 'DD/MM/YYYY').format('DD-MM-YYYY HH:mm');
        } else {
            return moment(serial, 'DD/MM/YYYY').format('DD-MM-YYYY HH:mm');
        }
    }

    /**
     *
     * @param {*} latest
     */
    async function getUpdate(latest) {
        return Promise.all(
            latest.map(async (entry) => {
                var query = [
                    {
                        field: 'balance',
                        value: entry['balance'],
                    },
                    {
                        field: 'txn_date',
                        value: entry['txn_date'],
                    },
                    {
                        field: 'description',
                        value: entry['description'],
                    },
                ];

                var config = {
                    orderBy: 'transaction_date',
                    direction: 'desc',
                };

                await BankCredits.get(query, config).then(async (res) => {
                    if (res && res['bank_credits'] && res['bank_credits'][0]) {
                        entry.duplicate = true;
                    }

                    setVisible(false);
                });
            })
        ).then(async () => {
            await setEntries([...latest]);
        });
    }

    const SheetJSFT = [
        'xlsx',
        'xlsb',
        'xlsm',
        'xls',
        'xml',
        'csv',
        'txt',
        'ods',
        'fods',
        'uos',
        'sylk',
        'dif',
        'dbf',
        'prn',
        'qpw',
        '123',
        'wb*',
        'wq*',
        'html',
        'htm',
    ]
        .map(function (x) {
            return '.' + x;
        })
        .join(',');

    const uploadProps = {
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles([...newFileList]);

            form.setFieldsValue({ bankStatement: null });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                if (info.fileList.length) {
                    handleFile(info.file);

                    form.setFieldsValue({ bankStatement: info.file });
                }
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            setFiles([...files, file]);

            return false;
        },
        files,
    };

    function onSubmit(values) {
        if (defaultBranch.location === codes[bankFromUpload[0].code]) {
            compareStatus(data.data);
        } else {
            message.error(`Looks like you have a selected an incorrect bank statment for the selected branch.`);
        }
    }

    function reset() {
        form.resetFields();

        setFiles([]);

        setEntries([]);

        setVisible(true);

        getBankReceipts();
    }

    async function getSelectedBranch(branchId) {
        return await Departments.getRecord(branchId).then((result) => {
            setSelectedBranch(result);
            setDefaultBranch(result);
            return result;
        });
    }

    async function selectBranch(branchId, arg) {
        setSelected(branchId);

        await getSelectedBranch(branchId);
    }

    return (
        <div className="bank-upload listing">
            <div className="page-header">
                <div className="left">
                    <Title level={3}> Upload the Bank Statement </Title>{' '}
                </div>
                <div className="right">
                    <div>
                        <Button
                            onClick={() => {
                                getBankReceipts();
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            {' '}
                            Refresh{' '}
                        </Button>{' '}
                    </div>
                </div>{' '}
            </div>
            {/* Loading */}
            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {/* Breif The Status */}
                    {entries.length ? (
                        <CardList data={entries} banks={banks} current={current} selectedBranch={selectedBranch} department={department[0]} />
                    ) : null}
                    {/* For New Entries Ends */}
                    {files.length && entries.length ? (
                        <div>
                            <Button onClick={reset} type="secondary">
                                Reset
                            </Button>
                        </div>
                    ) : null}
                </>
            )}
            {/* Loading Ends */}

            {/* Modal */}

            {visible && !loading ? (
                <>
                    <div className="vehicle-card card" title="Sync your Bank">
                        <div className="alert-message">
                            <Alert
                                message="Please make sure you select the correct Branch , Bank for which the file is uploaded, and the Bank Head of CS Accounts to make sure this is recorded correctly on CS Accounts."
                                type="error"
                                showIcon
                            />
                        </div>

                        <Form
                            form={form}
                            {...layout}
                            name="new-record"
                            layout="vertical"
                            onFinish={onSubmit}
                            // validateMessages={validateMessages}
                            initialValues={{}}
                        >
                            <Form.Item
                                name={'branch'}
                                label="Select Branch"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Branch',
                                    },
                                ]}
                            >
                                <Select
                                    onSelect={selectBranch}
                                    placeholder="Branch"
                                    style={{
                                        width: '200px',
                                        margin: '0px 10px',
                                    }}
                                >
                                    {dept.map((doc, index) => (
                                        <Option value={doc.id} key={doc.code}>
                                            {doc.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Mode of Payment',
                                    },
                                ]}
                                name={'bankStatement'}
                                label="Receipt Statement"
                            >
                                <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                    <Button size={'small'} icon={<UploadOutlined />}>
                                        {' '}
                                        Select File{' '}
                                    </Button>{' '}
                                </Upload>{' '}
                            </Form.Item>

                            <Button loading={loading} type="primary" htmlType="submit" className="submit-button">
                                SUBMIT
                            </Button>

                            <Button onClick={reset} type="secondary">
                                Reset
                            </Button>

                            {entries.length ? (
                                <div className="file-review">
                                    <Divider orientation="left"> Review for Upload </Divider>
                                    <div className="action-header">
                                        <h4>
                                            {' '}
                                            {entries.length}
                                            Receipts found.{' '}
                                        </h4>

                                        <p>
                                            {' '}
                                            {entries.filter((entry) => entry['duplicate']).length}
                                            are duplicate.{' '}
                                        </p>
                                    </div>
                                </div>
                            ) : null}
                        </Form>
                    </div>
                    {/* Bank Upload form */}
                </>
            ) : null}
        </div>
    );
}

/**
 * New Record Card List
 *
 * @param {*} param0
 * @returns
 */
function CardList({ data, url, banks, current, selectedBranch, department }) {
    // Loading variable
    const [loading, setLoading] = useState(false);

    let [entries, setEntries] = useState(data.filter((entry) => !entry.duplicate) || []);

    const [modes, setModes] = useState([]);

    const [category, setCategory] = useState('');

    // const [current, setCurrent] = useState('')

    /**
     * Function triggered on select
     */
    function onSelect(item) {
        entries.forEach((entry, index) => {
            if (item.description === entry.description && item.ref === entry.ref && item.balance === entry.balance) {
                entries[index].selected = true;
            }
        });
        getUpdate(entries);

    }

    function onDiscard(receipt, index) {
        // entries[index].selected = false;

        entries.forEach((entry, index) => {
            if (receipt.description === entry.description && receipt.ref === entry.ref) {
                entries[index].selected = false;
            }
        });

        setEntries([...entries]);
    }

    var app = FirebaseUtils.getApp();

    var batch = app.batch();

    async function onConfirm() {
        // setEntries([...entries]);
        // Take all the selected entries ,
        // Create a voucher for cs accounts
        // and add a firebase record
        setLoading(true);

        let pending = entries.filter((item) => !item.selected && !item.debit && !item.duplicate);

        // To make sure selected is not added in the list

        let selectedEntries = entries
            .filter((item) => item.selected && !item.debit && !item.duplicate)
            .map((item) => {
                delete item.selected;

                // To filter only credit records
                item.isCredit = true;
                item.duplicate = true;

                return item;
            });

        const hide = message.loading('Uploading bank records, Please wait', 0);

        // For all the vouchers ,
        // Upload to firebase

        let result = await FirebaseUtils.uploadBankCredits(selectedEntries, banks, batch);
        // If all the entries are added to cs accounts

        if (result.length) {
            Promise.all(
                result.map((item) => {
                    return new Promise(async (resolve, reject) => {
                        // For only customer receipts , we are creating vouchers
                        // if (category === 'Customer Receipts' && department === 'Department Handling Sales') {
                        //     b(item, current, selectedBranch)
                        //         .then(async (record) => {
                        //             // Attach the CS Accounts Entry to voucher

                        //             let params = {
                        //                 voucher: {
                        //                     v_vno: record.v_vno,
                        //                     v_ids: record.v_ids,
                        //                 },
                        //             };

                        //             item.voucher = params.voucher;

                        //             // To update bank_credits record
                        //             var docRef = BankCredits.getRecordReference(item.id);

                        //             await batch.update(docRef, params);

                        //             resolve(item);
                        //         })
                        //         .catch(() => {
                        //             hide();

                        //             resolve(item);
                        //         });
                        // } else {
                            resolve(item);
                        // }
                    });
                })
            ).then(async (result) => {
                await batch.commit();

                hide();

                message.success(`${result.length} credit entries uploaded successfully`);

                entries = entries.filter((item) => !item.selected && !item.debit && !item.duplicate);

                setLoading(false);

                setEntries([...pending]);
            });
        } else {
            hide();

            message.error(`You can't upload this credits,because it is already uploaded`);

            setLoading(false);

            setEntries([...pending]);
        }
    }
    /**
     *
     * @param {*} latest
     */
    async function getUpdate(latest) {
        return Promise.all(
            latest.map(async (entry) => {
                var query = [
                    {
                        field: 'balance',
                        value: entry['balance'],
                    },
                    {
                        field: 'txn_date',
                        value: entry['txn_date'],
                    },
                    {
                        field: 'description',
                        value: entry['description'],
                    },
                ];

                var config = {
                    orderBy: 'transaction_date',
                    direction: 'desc',
                };

                await BankCredits.get(query, config).then(async (res) => {
                    if (res && res['bank_credits'] && res['bank_credits'][0]) {
                        entry.duplicate = true;
                    }

                });
            })
        ).then(async () => {
            await setEntries([...latest]);
        });
    }

    /**
     * Function is used to submit any debit entries , and proceed with any default
     * submission for upload
     */
    async function onProceed() {
        var debits = entries.filter((item) => !item.duplicate && item.debit);

        FirebaseUtils.uploadBankCredits(debits, banks, batch).then(async () => {
            message.success(`${debits.length} debit entries uploaded successfully`);

            entries = entries.filter((item) => !item.debit);

            setLoading(false);

            await setEntries([...entries]);
        });
    }

    return (
        <div className="vehicle-card card">
            <Alert
                message="You will lose all the changes if you refresh or leave this page. Upload process would be complete only once you upload all the records found in the csv uploaded."
                type="warning"
            />

            <p>{data.length} records found</p>

            <p>{data.filter((entries) => entries.duplicate).length} duplicates were ignored.</p>

            {/* Selected Receipts */}
            {entries.filter((receipt) => receipt.selected).length ? (
                <div className="selected-item-form card">
                    {/* Selected Tags */}
                    <div className="selected-items">
                        {entries
                            .filter((receipt) => receipt.selected)
                            .map((receipt, key) => {
                                return (
                                    <Tag
                                        key={key}
                                        visible
                                        closable
                                        onClose={() => {
                                            onDiscard(receipt, key);
                                        }}
                                    >
                                        {receipt['description']} <span className="title amount">{receipt['credit']}</span>
                                    </Tag>
                                );
                            })}
                    </div>
                    {/* Selected Tag Ends */}

                    <Alert
                        message={`Note`}
                        showIcon
                        description={`Select the type of records that you have selected to upload . For Customer Receipts a voucher would be created in CS Accounts.`}
                        type="info"
                    />

                    <Select placeholder="Type of Credit" onChange={setCategory}>
                        {upload_options.map((doc, index) => (
                            <Option value={doc} key={index}>
                                {doc}
                            </Option>
                        ))}
                    </Select>

                    {/* <Select
                        onChange={setCurrent}
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                    >
                        {modes.map((item) => {
                            return (
                                <Option
                                    value={
                                        item.ac_code
                                    }
                                    key={item.ac_code}
                                >
                                    {item.ac_desc} -{' '}
                                    {
                                        item.ac_mode
                                    }
                                </Option>
                            );
                        })}
                    </Select> */}

                    <div className="form-actions">
                        <Popconfirm
                            title="Are you sure your selection is correct"
                            onConfirm={onConfirm}
                            // onConfirm={() => sendTo('sm')}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button loading={loading} type="primary">
                                Submit
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
            ) : null}
            {/* Selected Receipts Ends */}
            {entries
                .filter((item) => !item.selected && !item.debit && !item.duplicate)
                .map((report, index) => {
                    return <PaymentCard city={url} request={report} index={index} key={index} onSelect={onSelect} />;
                })}

            {/* Debit Upload Section */}
            {entries.filter((item) => item.debit && !item.duplicate).length > 0 ? (
                <>
                    <Space direction="vertical">
                        <Alert
                            message={`${entries.filter((item) => item.debit).length} Debits`}
                            showIcon
                            description={`Though we dont do reconcilation of Payments on Surge yet , We will be adding that soon. Submit the below button to upload the credit entries in the uploaded file.`}
                            type="info"
                        />

                        <Button onClick={onProceed} loading={loading} type="primary">
                            Upload
                        </Button>
                    </Space>
                </>
            ) : null}
            {/* Debit Upload Section Ends */}
        </div>
    );
}

function PaymentCard({ city, request = {}, onSelect }) {
    return (
        <div className="request-card">
            <div className="card">
                <h3 className="title"> {request['description']}</h3>
                <h2 className={`title ${request['debit'] ? 'amount' : 'credit'}`}> {request['debit'] || request['credit']} </h2>
                <p className="title "> {request['ref']} </p>
                Credited at {request['txn_date']}{' '}
                {onSelect && (
                    <Checkbox
                        checked={request.selected}
                        onChange={() => {
                            onSelect(request);
                        }}
                    >
                        Select
                    </Checkbox>
                )}
            </div>
        </div>
    );
}
