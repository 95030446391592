import React, { useEffect, useContext } from 'react';

import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './../../hocs/PrivateRoute';

import GlobalHeader from './../../components/global-header/global-header';

import Receipts from './components/receipts/receipts';
import ReceiptForm from './components/receipt-form/receipt-form';

import Dashboard from './../common/components/dashboard/dashboard';

import IFStatus from './../common/components/if-status/if-status';

import VehicleOverview from './../common/resources/vehicle-overview/vehicle-overview';

import MonthWiseRetail from './../common/resources/monthwise-retail/monthwise-retail';

import BookingReport from '../common/resources/booking-report/booking-report';

// Accounts Overview
import AccountsOverview from './../common/resources/accounts-overview/accounts-overview';

import ManageStock from './../common/components/manage-stock/manage-stock';

import FinanceTracker from './../common/resources/finance-tracker/finance-tracker';

import VerifyPayment from './../common/components/verify-payment/verify-payment';

import ManagePayments from './../common/components/manage-payments/manage-payments';
import BankUpload from './../common/components/manage-payments/bank-upload';

import ReconcileSummary from './../common/components/reconcile-summary/reconcile-summary';
import ChangePassword from './../common/components/change-password/change-password';

import CustomerReceipts from '../common/components/customer-receipts/customer-receipts';
import CustomerReceiptDetail from '../common/components/customer-receipts/customer-receipts-detail';
import CustomerReceiptAdd from '../common/components/customer-receipts/customer-receipts-add';

import Cancellations from '../common/components/cancellations/cancellations';
import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import RequestOverview from './../common/resources/request-overview/request-overview';

import CustomerFiles from './components/customer-files/customer-files';
import CustomerPayment from './components/customer-payment/customer-payment';

import RequestDetail from './../common/components/request-detail/request-detail';

import FundGap from './components/fund-gap/fund-gap';

import CompareSheets from './components/compare-sheets/compare-sheets';

import CompareGst from './components/compare-gst/compare-gst';

// import AccountsDashboard from './../common/resources/accounts-dashboard/accounts-dashboard';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import BookingDetail from './../common/components/booking-detail/booking-detail';

import Profile from './../common/resources/profile/profile';

import BillDetails from './../common/resources/finance-payout-tracker/bill-details';

// Tasks

import { accountSteps, pricingSchema } from './accounts-config';

import TaskOverview from './../common/resources/task-overview/task-overview';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import ModuleRoutes from './../common/resources/modules-routes/module-routes';

import {
    billColumns,
    billActions,
    payoutSchema,
    requestSchema,
    billSchema,
    stockTransferSchema,
    stockRegisterSchema,
    stockTransactionSchema,
} from './../global-config';

import StockRegister from './../common/resources/stock-register/stock-register';

import StockTransactionRecord from './../common/resources/stock-transaction/stock-transactions';

import ReconcileOverview from '../common/resources/reconcilation-overview/reconcilation-overview';

import NoticeBoard from './../common/components/notice-board/notice-board';

import { BillPreview } from './../../components/bill-preview/bill-preview';

import './accounts-landing.scss';

import { GlobalContext } from './../../Store';

import { ServiceBill } from './../../components/service-bill/service-bill';

import { ItemWiseSchema } from './../global-config';

import { itemActions } from './../global-config';

import BillList from '../common/resources/bill-list/bill-list';

import VehicleServiceBill from '../common/resources/finance-payout-tracker/vehicle-service-bill';

import VehicleDetail from '../../components/vehicle-detail/vehicle-detail';

import CompareRO from '../common/resources/compare-ro-report/compare-ro';

import SalesReport from '../common/resources/bill-list/sales-report';
import StockTransferRecord from '../common/resources/stock-transfer/stock-transfer';

import BookingTracker from '../common/resources/booking-tracker/booking-tracker';

export default function AccountsLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => { }, []);

    const updatedAccountSteps = accountSteps(user.locations[0]);

    // ...(['kannur.accounts@dkhkia.com', 'accounts@dkhkia.com'].indexOf(user.email)) ? {
    //   path: '/pending-summary',
    //   caption: 'Pending to Reconcile'
    // } : null,
    const modules = [
        {
            caption: 'Reconcilation',
            submenus: [
                {
                    path: '/accounts-overview',
                    caption: 'Accounts Overview',
                },

                {
                    path: '/pending-summary',
                    caption: 'Pending to Reconcile',
                },

                // {
                //   path: '/verify-payment',
                //   caption: 'Verify Receipts'
                // },

                {
                    path: '/bank-receipts',
                    caption: 'Bank Statement',
                },

                {
                    path: '/customer-receipts',
                    caption: 'Customer Receipts',
                },

                // {
                //   path: '/collections',
                //   caption: 'Collections'
                // },
            ],
        },

        {
            caption: 'Reports',
            submenus: [
                {
                    caption: 'Monthwise Retail',
                    path: '/monthwise-retails',
                },

                // {
                //     caption: 'Booking Report',
                //     path: '/booking-report',
                // },
            ],
        },

        {
            caption: 'Sales Report',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Vehicle Bills',
                                path: `/sales/${location}/basic/bills`,
                            },
                            {
                                caption: 'Accessories Bills',
                                path: `/sales/${location}/Accessories/bills`,
                            },
                            {
                                caption: 'VAS Bills',
                                path: `/sales/${location}/VAS/bills`,
                            },
                            {
                                caption: 'After Market Bills',
                                path: `/sales/${location}/After Market/bills`,
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Item-wise GST Report',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Accessories ',
                                path: `/${location}/Accessories/items`,
                            },
                            {
                                caption: 'VAS ',
                                path: `/${location}/VAS/items`,
                            },
                            {
                                caption: 'After Market ',
                                path: `/${location}/After Market/items`,
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Vehicle Overview',
            path: '/vehicle-overview',
        },

        {
            caption: 'Finance Tracker',
            path: '/finance-tracker',
        },
        {
            caption: 'Booking Tracker',
            path: '/booking-tracker',
        },

        {
            caption: 'Cancellations',
            path: '/cancellations',
        },
        {
            caption: 'Tools',
            submenus: [
                {
                    caption: 'Compare RO Report',
                    path: '/compare-ro',
                },
            ],
        },

        {
            caption: 'Requests',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/request-overview`,
                                caption: 'Request Overview',
                            },

                            {
                                path: `/${location}/all-requests`,
                                caption: 'All Requests',
                            },

                            {
                                path: `/${location}/pending-requests`,
                                caption: 'Pending Requests',
                            },

                            {
                                path: `/${location}/billing-requests`,
                                caption: 'Pending for Delivery',
                            },

                            {
                                path: `/${location}/delivered-requests`,
                                caption: 'Delivered Requests',
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Stock Register',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        path: `/${location}/stock-register`,
                    };
                }),
            ],
        },

        {
            caption: 'Stock Transactions',
            path: `/stock-transaction`,

            // submenus: [
            //     ...user.locations.map((location) => {
            //         return {
            //             caption: location,
            //             path: `/${location}/stock-transaction`,
            //         };
            //     }),
            // ],
        },

        {
            caption: 'Stock Transfers',
            path: `/stock-transfers`,


            // submenus: [
            //     ...user.locations.map((location) => {
            //         return {
            //             caption: location,
            //             path: `/${location}/stock-transfers`,
            //         };
            //     }),
            // ],
        },

        {
            caption: 'Payouts',
            submenus: [
                {
                    caption: 'From Finance',
                    submenus: [
                        ...user.locations.map((location) => {
                            return {
                                caption: location,
                                path: `/${location}/finance-payout`,
                            };
                        }),
                    ],
                },
            ],
        },

        // {
        //   caption: "Requests",
        //   submenus: [{
        //     path: '/manage-requests',
        //     caption: 'All Requests'
        //   }]
        // },
        {
            caption: 'Tasks',
            submenus: [
                ...updatedAccountSteps.filter((step) => !step.isForm),

                {
                    path: '/compare-sheets',
                    caption: 'Compare Sheets',
                },

                {
                    path: '/compare-gst',
                    caption: 'Compare GST Report',
                },

                {
                    path: '/fund-gap',
                    caption: 'Fund Gap',
                },

                {
                    path: '/if-status',
                    caption: 'If Status',
                },
            ],
        },
        {
            caption: 'Manage',
            submenus: [
                {
                    path: '/manage-bookings',
                    caption: 'Manage Bookings',
                },
                {
                    path: '/manage-receipts',
                    caption: 'Manage Receipts',
                },

                {
                    caption: 'Manage Stock',
                    path: '/manage-stock',
                },

                {
                    path: '/central/pricing/',
                    caption: 'Manage Pricing',
                },
            ],
        },
        {
            caption: 'Sales Billing',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Vehicle Billing',
                                path: `/${location}/service/bill/Sales/Vehicle`,
                            },
                            {
                                caption: 'Accessory Billing',
                                path: `/${location}/service/bill/Sales/Accessories`,
                            },
                            {
                                caption: 'VAS Billing',
                                path: `/${location}/service/bill/Sales/VAS`,
                            },

                            {
                                caption: 'After Market Billing',
                                path: `/${location}/service/bill/Sales/After Market`,
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => {
                            return (
                                <>
                                    <NoticeBoard />

                                    <Dashboard steps={updatedAccountSteps} />

                                    {/* <TaskOverview steps={updatedAccountSteps} /> */}
                                </>
                            );
                        }}
                    ></Route>

                    <Route exact path={'/booking-report'} component={BookingReport}></Route>

                    {/* finance tracker */}
                    <Route exact path={`/finance-tracker`} component={(...routeParams) => <FinanceTracker {...routeParams} />} />
                    {/* finance tracker Ends */}

                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    {/* <PrivateRoute exact path="/manage-requests" component={ManageRequests} userRole={'admin'} /> */}

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/vehicle/:id" component={VehicleDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/compare-sheets" component={CompareSheets} userRole={'admin'} />

                    <PrivateRoute exact path="/compare-gst" component={CompareGst} userRole={'admin'} />

                    <PrivateRoute exact path="/compare-ro" component={CompareRO} userRole={'admin'} />

                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />

                    <Route exact path={`/booking-tracker`} component={BookingTracker} />

                    <Route
                        exact
                        path={`/:city/all-requests`}
                        component={(...routeParams) => <GenericList schema={requestSchema} queries={[]} {...routeParams} />}
                    />

                    {/* vehicle overview */}
                    <Route exact path={`/vehicle-overview`} component={(...routeParams) => <VehicleOverview {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    <Route
                        exact
                        path="/bank-receipts"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <ManagePayments department={['Department Handling Sales']} {...routeParams} />;
                        }}
                    />

                    <Route
                        exact
                        path="/bank-receipts/upload"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <BankUpload department={['Department Handling Sales']} {...routeParams} />;
                        }}
                    />

                    <Route
                        exact
                        path="/pending-summary"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <ReconcileSummary department={['Department Handling Sales']} {...routeParams} />;
                        }}
                    />

                    {/* <PrivateRoute exact path="/verify-payment" component={VerifyPayment} userRole={'admin'} /> */}

                    <PrivateRoute exact path="/fund-gap" component={FundGap} userRole={'admin'} />

                    {/* Receipts for CS Accounts */}
                    <PrivateRoute exact path="/receipts" component={Receipts} userRole={'admin'} />
                    <PrivateRoute exact path="/receipt/:city/:id" component={ReceiptForm} userRole={'accounts'} />
                    {/* Receipts for CS Accounts Ends */}

                    <Route
                        exact
                        path={`/:city/bills`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={billSchema}
                                actions={billActions}
                                columns={billColumns}
                                {...routeParams}
                                dateField={'created_date'}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/other-credits"
                        render={() => {
                            return <Receipts otherEntry={true} />;
                        }}
                        userRole={'admin'}
                    />

                    <Route exact path={`/:department/:city/:category/bills`} component={(...routeParams) => <SalesReport {...routeParams} />} />

                    <Route
                        exact
                        path={`/:city/:category/items`}
                        component={(...routeParams) => (
                            <BillList schema={ItemWiseSchema} actions={itemActions} {...routeParams} dateField={'created_date'} />
                        )}
                    />

                    <Route exact path="/bill/:id" component={BillDetails} userRole={'admin'} />

                    <Route exact path="/bill/vehicle-service/:id" component={VehicleServiceBill} userRole={'admin'} />

                    <Route
                        exact
                        path="/:city/service/bill/:department/:category"
                        component={({ ...routeParams }) => <ServiceBill key={routeParams} {...routeParams} />}
                        userRole={'admin'}
                    />

                    {/* Bill details Ends */}

                    <Route exact path={'/bill-preview'} component={BillPreview}></Route>

                    <PrivateRoute exact path="/manage-stock" component={ManageStock} userRole={'admin'} />

                    {/* Collections Module */}
                    {/* <PrivateRoute exact path="/collections" component={Collections} userRole={'admin'} /> */}
                    {/* <PrivateRoute exact path="/:city/collections/:id" component={CollectionDetail} userRole={'admin'} /> */}
                    {/* <PrivateRoute exact path="/collections/add" component={CollectionsAdd} userRole={'admin'} /> */}
                    {/* Collections Module Ends */}

                    <Route
                        exact
                        path="/accounts-overview"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <AccountsOverview department={['Department Handling Sales']} {...routeParams} />;
                        }}
                    />

                    {/* Finance Payout */}
                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/finance-payout`}
                        component={(...routeParams) => <GenericList schema={payoutSchema} queries={[]} {...routeParams} />}
                    />

                    {/* Finance Payout Ends */}

                    {/* Requests according to Queries */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/pending-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.pendingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    <Route exact path={'/monthwise-retails'} component={MonthWiseRetail}></Route>

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/billing-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.billingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Delivered Requests */}
                    <Route
                        exact
                        path={`/:city/delivered-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.deliveryRequests} {...routeParams} />
                        )}
                    />
                    {/* Delivered Requests Ends */}

                    {/* Stock Register */}
                    <Route
                        exact
                        path={`/:city/stock-register`}
                        component={(...routeParams) => <GenericList schema={stockRegisterSchema} dateField={'created_time'} queries={[]} {...routeParams} />}
                    />

                    <Route exact path={`/:city/stock-detail/:id`} component={(...routeParams) => <StockRegister {...routeParams} />} />

                    {/* Stock Register Ends */}

                    {/* Stock Transactions */}

                    <PrivateRoute exact path="/stock-transaction" component={StockTransactionRecord} userRole={'admin'} />

                    <PrivateRoute exact path="/stock-transfers" component={StockTransferRecord} />


                    {/* Stock Transactions Ends */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Stock Transfers */}
                    {/* <Route
                        exact
                        path={`/:city/stock-transfers`}
                        component={(...routeParams) => <GenericList schema={stockTransferSchema} queries={[]} {...routeParams} />}
                    /> */}
                    {/* Stock Transfers Ends */}

                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    {/* Customer Receipts Module */}
                    <Route
                        exact
                        path="/customer-receipts"
                        render={(routeParams) => {
                            return <CustomerReceipts department={['Department Handling Sales']} {...routeParams} />;
                        }}
                        userRole={'admin'}
                    />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'admin'} />
                    <Route
                        exact
                        path="/customer-receipts/add"
                        render={(routeParams) => {
                            return <CustomerReceiptAdd department={['Department Handling Sales']} {...routeParams} />;
                        }}
                        userRole={'admin'}
                    />
                    {/* Customer Receipts Module Ends */}

                    {/* Cancellations Module */}
                    <PrivateRoute exact path="/cancellations" component={Cancellations} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />
                    {/* Cancellations Module ends */}

                    <PrivateRoute exact path="/if-status" component={IFStatus} userRole={'admin'} />

                    <Route
                        path="/central/pricing"
                        component={() => <ModuleRoutes model="pricing" schema={pricingSchema} columns={pricingSchema.columns} collection="list" />}
                    />

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={updatedAccountSteps} list={CustomerFiles} form={CustomerPayment} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
