import React, { useEffect, useState, useContext } from 'react';

import {
    Typography,
    Tabs,
    Space,
    Select,
    Card,
    Alert,
    Table,
    Modal,
} from 'antd';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Link } from 'react-router-dom'

import { Empty } from 'antd';

import moment from 'moment'

const { Title } = Typography;

const { TabPane } = Tabs;



export default function AccessoryChoices({
    request,
    booking,
    disableEdit = false,
    isBooking,
    callback
}) {
    return (
        <Card className="accessory-choices card">

            <Title level={3}>Bills</Title>

            <BillList
                disableEdit={disableEdit}
                request={request}
                booking={booking}
                isBooking={isBooking}
                callback={callback}
            />
        </Card>
    );
}

var city = '';

/**
 * Component will load the accesories in cart for this booking
 */
function BillList({
    request = { delivery: {} },
    booking,
    disableEdit,
    isBooking,
    callback
}) {
    const { user } = useContext(GlobalContext);

    const [selected, setSelected] = useState();

    useEffect(() => {
        // Load the Cart Items
        loadCart();
    }, []);

    function loadCart() {
        

        FirebaseUtils.getBillingStatement(request.bookingNo, 'VAS').then((res) => {

            res = res.filter(bill => bill.cancellation_status !== true && bill.type !== 'basic')
            
            setSelected(res);
        })
    }

    booking = {
        ...{
            accessories: {
                choices: []
            }
        },
        ...booking
    };

    return (
        <div className="accessory-list">

            <div className="no-print-block">
                {selected && selected.length ? (
                    <SelectedList
                        callback={callback}
                        isBooking={isBooking}
                        disableEdit={disableEdit}
                        booking={booking}
                        request={request}
                        items={selected}
                    />
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </div>

        </div>
    );
}

/**
 * Selected List
 */
function SelectedList({ items = [], request = {}, callback, booking, updateTab, printTab }) {
    const [entries, setEntries] = useState(items);

    const [categories, setCategories] = useState([]);

    const [selectedTab, setSelectedTab] = useState('All');

    const columns = [



        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1
        },
        {
            title: 'Customer ID',
            dataIndex: 'customerID',
            width: 200
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            width: 200
        },
        {
            title: 'Invoice Date',
            render: (text, record) => {
                return moment(record.invoice_date_millisecond).format("DD/MM/YYYY")
            },
            width: 200
        },
        {
            title: 'Tax',
            dataIndex: 'total_tax',
            width: 200
        },
        {
            title: 'Net Amount',
            dataIndex: 'net_amount'
        },
        {
            title: 'Actions',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/bill/${record.id}`}>
                            View
                        </Link>
                    </Space>
                );
            }
        }
    ];

    useEffect(() => {
        console.log(items);

        let arr = ['All'];

        entries.map((ele) => {
            if (arr.includes(ele.updatedType) === false) {
                arr.push(ele.updatedType);
            }
        });

        setCategories(arr);
    }, [items]);

    const onTabChange = (key) => {
        updateTab(categories[key])
        setSelectedTab(categories[key]);
    };

    const filterEntries = (element, cat) => {
        return element.filter((item) => {
            return item.updatedType === cat;
        });
    };

    return (
        <div className="selected-list">
            {entries.length ? (
                <>
                    {/* <Alert
                        className="noprint"
                        message="Below are the bills of billed VAS"
                        type="info"
                        showIcon
                    /> */}

                    <Tabs tabBarStyle={{ display: 'none' }} defaultActiveKey="0" onChange={onTabChange}>
                        {categories.map((cat, index) => {
                            return (
                                <TabPane tab={cat} key={index}>
                                    <Table
                                        // className="card"
                                        rowKey={(entry) => entry.partNo}
                                        scroll={{ x: true, y: 750 }}
                                        pagination={false}
                                        columns={columns}
                                        dataSource={
                                            cat !== 'All'
                                                ? filterEntries(entries, cat)
                                                : entries
                                        }
                                        size="small"
                                    />
                                </TabPane>
                            );
                        })}
                    </Tabs>
                </>
            ) : (
                <>
                    <p>Nothing added yet</p>
                </>
            )}

        </div>
    );
}
