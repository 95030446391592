import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from './../../../../../../Store';

import { message, Checkbox, Popconfirm, Modal, Button, Tag, Alert } from 'antd';

import { Form, Input } from 'antd';

import FirebaseUtils from '../../../../../../utils/firebase.utils';

import moment from 'moment';

import AsyncSelect from 'react-select/async';

import ApiUtils from '../../../../../../utils/api.utils';

// import './reconcile-summary.scss';

import ReceiptInfo from './../receipt-info/receipt-info';

import CollectionInfo from './../collection-info/collection-info';

import { BankCredits, CustomerEntries } from '../../../../../../models';

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

/**
 * Matching Collection Card
 * For Reconcilation
 *
 * @param {*} param0
 * @returns
 */
export default function CollectionSuggestion({ city, id, receipt = {}, callback, onSelect, allDepartments }) {
    const [form] = Form.useForm();

    const [loader, setLoader] = useState(false);

    const [visible, setVisible] = useState(false);

    const [approvalModal, setApprovalModal] = useState(false);

    const [collection, setCollection] = useState({
        created_by: {},
    });

    const { user, defaultBranch } = useContext(GlobalContext);

    console.log(user);

    useEffect(() => {
        // load the collection record
        FirebaseUtils.getCollectionRecord(id).then((result) => {
            // console.log(result);

            setCollection(result);

            setLoader(false);
        });
    }, []);

    /**
     *
     * On Submit of Form ,
     * Matching Receipt
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        // If its booking ,
        // we have to create account head

        // This same process has to be done for verifying on
        // Customer receipts detail page ,
        // Here ,
        // For Booking , we create account head first ,
        //
        setLoader(true);

        let collectionDepartment = allDepartments.filter((dep) => dep.id === collection.department_id);

        collection.department = collectionDepartment[0];

        //othref has to be passed
        BankCredits.reconcileBankCredit(values, collection, receipt, user, callback, 'CS:S').then(() => {
            setLoader(false);

            setApprovalModal(false);
        });
    };

    /**
     * Update the Collection
     */
    function updateCollection(values) {
        let verificationTime = {
            verified_at: moment().format('DD/MM/YYYY HH:mm'),
            verified_by: { name: user.name, role: user.role },
            verified_date: moment().startOf('day').valueOf(),
            verified_time: moment().valueOf(), // this can be removed if not needed
        };

        let params = {
            pending_to_reconcile: false,
            bank: receipt.bank,
            reconciled_by: receipt.receiptId,
            clearing_city: receipt.city,
            accountRemarks: values.accountRemarks,
            verified: 'approved',
            pending_at: 'edp',
            status: 'reviewed',
            ...verificationTime,
            voucher: {
                ...receipt.voucher,
            },
        };

        return FirebaseUtils.updateCollection(
            // collection.city,
            collection.collectionId,
            params
        );
    }

    /**
     * Load the matching heads
     */
    // function loadHeads(query) {
    //     return ApiUtils.getHeads(query, receipt.department).then((result) => {
    //         return result.map((item) => {
    //             return {
    //                 value: item.ac_code,
    //                 label: item.ac_desc,
    //             };
    //         });
    //     });
    // }

    async function onReject() {
        
        setLoader(true);

        let verificationTime = {
            verified_at: moment().format('DD/MM/YYYY HH:mm'),
            verified_by: { name: user.name, role: user.role },
            verified_date: moment().startOf('day').valueOf(),
            verified_time: moment().valueOf(), // this can be removed if not needed
        };

        let params = {
            verified: 'rejected',
            pending_at: 'done',
            status: 'rejected',
            ...verificationTime,
        };

        var app = FirebaseUtils.getApp();

        var batch = app.batch();
        
        // To update customer_entries record
        var customerRef = CustomerEntries.getRecordReference(collection.collectionId);

        await batch.update(customerRef, params);
        
        var params2 = {
            reconcile_status: 'pending',
            pending_at: 'new',
            suggestion: null,
            };

        // Update the reconcile status of bank receipt
        var docRef = BankCredits.getRecordReference(receipt.receiptId);

        await batch.update(docRef, params2);

        await batch.commit();

        message.success('Suggestion rejected');

        setLoader(true);

        setApprovalModal(false);

        return callback();
            
    }

    return (
        <>
            <div className="collection-card">
                <div className="left">
                    <h2 className="title">
                        {collection.name} - <small>{collection.remarks}</small>
                    </h2>
                    <h4 className="title ">{collection.phone}</h4>
                    Created at {collection.created_at} by {collection.created_by_name}
                </div>

                <div className="right">
                    <div>
                        <h3 className="title amount">{collection.amount}</h3>
                    </div>

                    <div>
                        <Button
                            onClick={() => {
                                setVisible(true);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            View
                        </Button>

                        <Button
                            onClick={() => {
                                setApprovalModal(true);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            Approve / Reject
                        </Button>

                        {collection.verified === 'approved' ? <Tag color="success">Verified</Tag> : <Tag color="red">Unverified</Tag>}

                        {collection.invoice ? <Tag color="success">Receipt Completed</Tag> : <Tag color="red">Receipt Pending</Tag>}

                        {collection.accounts ? <Tag color="success">Accounts Done</Tag> : <Tag color="red">Accounts Pending</Tag>}

                        {onSelect && (
                            <Checkbox checked={collection.selected} onChange={onSelect}>
                                Select
                            </Checkbox>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                destroyOnClose={true}
                title="Collection Detail"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <CollectionInfo collection={collection} />
            </Modal>

            {/* Approval Modal */}
            <Modal
                destroyOnClose={true}
                className="reconcile-modal"
                width={1000}
                title="Approve/ Reject Entry"
                visible={approvalModal}
                footer={null}
                onCancel={() => {
                    setApprovalModal(false);
                }}
            >
                <ReceiptInfo receipt={receipt} />
                <CollectionInfo collection={collection} />
                {collection.city !== receipt.city ? (
                    <>
                        <Alert
                            message={`Please note that this is a credit for ${receipt.city} while you are trying to verify a ${collection.city} record. Your current branch should be same as ${collection.city} to verify a Sales team collection of the city.`}
                            type="error"
                            showIcon
                            closable
                        />
                    </>
                ) : null}
                <Form
                    form={form}
                    {...layout}
                    name="new-record"
                    layout="vertical"
                    onFinish={onFinish}
                    // validateMessages={validateMessages}
                    initialValues={{}}
                >
                    {/* {collection.account !== 'BOOKING' ? (
                        <>
                            <Form.Item
                                name={'ac_code'}
                                label="Account Head"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Account Head',
                                    },
                                ]}
                            >
                                <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} />
                            </Form.Item>
                        </>
                    ) : null} */}

                    <Form.Item
                        name={'verificationRemarks'}
                        label="Remarks"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Remarks',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Button
                        //doubt disabled={collection.city !== department.location}
                        loading={loader}
                        type="primary"
                        htmlType="submit"
                        className="submit-button"
                    >
                        SUBMIT
                    </Button>

                    <Popconfirm
                        title="Are you sure you want to reject this suggestion ?"
                        onConfirm={() => onReject('sm')}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button loading={loader} type="secondary" className="submit-button">
                            REJECT
                        </Button>
                    </Popconfirm>
                </Form>{' '}
                {/* Form for Approving and Forwarding to cs accounts */}
            </Modal>
            {/* Reconcile Modal Ends */}
        </>
    );
}
