/**
 *
 *
 */

import Base from './base';

class Taxes extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'taxes';
    }

    get path() {
        return `taxes`;
    }

    get getName() {
        return `taxes`;
    }
}

export default Taxes;
