


import Base from './base';

import { Link } from 'react-router-dom'

class Uploads extends Base {

    constructor() {

        super()

        this.fields = []

        this.columns = [];
    }

    get getEndpoint() {
        return 'uploads'
    }

    get path() {
        return `uploads`
    }

    get getName() {
        return `uploads`
    }

}

export default Uploads;