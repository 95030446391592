import Base from './base';

import { Link } from 'react-router-dom';

import { Comments } from './index';

import moment from 'moment';

class FinanceBranch extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'finance_branches';
    }

    get path() {
        return `finance_branches`;
    }

    get getName() {
        return `finance_branches`;
    }
}

export default FinanceBranch;
