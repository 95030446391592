/**
 *
 * Wrapper Functions for Updating Node
 *
 *
 */

import { PostData, GetData, PutData } from './http.utils';

import { message } from 'antd';

import moment from 'moment';

/**
 * Susponse account heads are stored
 */
let SuspenseAccounts = {
    mpm: '1090076',
    knr: '10900110',
    clt: '11200001',
};

export default class ApiUtils {
    currentUser = {};

    user = {};

    branch = {
        name: 'ashique',
    };

    // Empty Branch Initially
    branches = [];

    static getUser = () => {
        return this.user;
    };

    static getBranch = () => {
        return this.branch;
    };

    static getBranches = () => {
        return this.branches;
    };

    static getBranchRecord = () => {
        return new Promise((resolve) => {
            resolve(this.branch);
        });
    };

    static setBranch = (branch, user) => {
        // console.log(this.branch);

        this.branch = branch;

        this.currentUser = user;
    };

    /**
     * Set the branches
     */
    static setBranches = (branches) => {
        this.branches = branches;
    };

    /**
     * Get default Props according
     * to the logged in user
     * and branch selected
     *
     * @returns
     */
    static getDefaultProps = (branch) => {
        // const active = this.getBranch();

        // Get the branch from active branch
        const { v_brptr, firm_ptr, fy_id } = branch;

        const currentUser = this.currentUser;

        // Default Props to be added for all vouchers
        return {
            v_firmptr: firm_ptr,
            v_brptr: v_brptr,
            v_finyearid: fy_id,
            v_vtype: 'R',
            v_pcenterptr: 'GEN',
            v_user: currentUser.name,
            v_userid: currentUser.role === 'admin' ? 1 : 0,
            // v_user
            // v_user: "1",
            // v_userid: 1,
            v_canedit: 'Y',
        };
    };

    /**
     *
     * Function is triggered during bank import
     *
     * @param {*} params
     * @returns
     */
    static createBankEntry = (params, current, branch) => {
        //const active = this.getBranch();

        var credit_amount = parseFloat(params.credit);

        var suspense_ptr = SuspenseAccounts[branch.city];

        var formBody = {
            ...this.getDefaultProps(branch),
            // ...defaultProps,
            v_canedit: 'Y',
            v_remarks: params.description + '/' + params.ref,
            v_dt: moment(params.transaction_date).format('MM/DD/YY'),
            rows: [
                // Debit the Bank Account
                {
                    // v_acptr: ac_ptr,
                    v_acptr: current,
                    v_othref: `BI:${params.id}`,
                    v_qty: 0,
                    v_amt: credit_amount,
                    v_totrow: 'Y',
                    v_trn: 1,
                    v_slno: -1,

                    v_rconstatus: 'Y', // Reconcile Status of Bank Entry
                    v_rcondt: moment(params.transaction_date).format('MM/DD/YY'), // Reconcile Status
                    // reconcil_status:'Y',
                    // reconcil_date:new Date(),

                    // v_refdet: params.description,
                    // v_rowremarks: params.ref,
                    v_refdet: ' ',
                },

                // Credit the Suspense Account
                {
                    v_acptr: suspense_ptr,
                    v_qty: 0,
                    v_amt: credit_amount,
                    v_trn: -1,
                    v_othref: `BI:${params.id}`,

                    // v_refdet: params.description,
                    // v_rowremarks: params.ref
                    // v_remarks: params.description + '/' + params.ref,
                    v_refdet: ' ',
                    // v_remarks: params.ref
                },
            ],
        };

        return PostData({
            url: '/voucher/' + branch.city,
            formBody,
        });

        // return new Promise((resolve, reject) => {
        //     message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

        //     reject({});
        // });
    };

    /**
     * Create a voucher
     * For Collection Entry by Cashier
     *
     *
     * @param {*} params
     * @returns
     */
    static createVoucher = (params, mode, branch) => {
        /**
         * Firm
         * Branch
         * Financial Year
         * Profit Center
         * Cost Center
         */

        //  We will need to handle different types of receipts done

        // const active = this.getBranch();

        if (branch.location === params.city) {
            // Cash
            // var ac_ptr = acCodes[params.type];

            var ac_ptr = params.mode;

            let firstEntry = {
                v_acptr: ac_ptr,
                v_qty: 0,
                v_amt: params.amount,
                v_trn: 1,
                v_totrow: 'Y',
                v_slno: -1,
                v_refdet: ' ',
                v_rowremarks: '',
                v_othref: params.v_othref,
            };

            // For Cheque
            if (params.type === 'Cheque') {
                firstEntry.v_refdet = params.chequeNo;

                // firstEntry.v_remarks = params.chequeDate;

                // For PostDated Check we have to update the v_pdstatus and vpddt
                // if (moment(params.chequeDate, 'DD/MM/YYYY').isAfter(moment())) {
                if (mode['ac_ispde'] === 'Y') {
                    firstEntry = {
                        ...firstEntry,
                        v_pdstatus: 'E',
                        v_pddt: moment(params.chequeDate, 'DD/MM/YYYY').format('MM/DD/YYYY'),
                    };
                }
            }

            if (['Credit Card', 'Debit Card'].indexOf(params.type) !== -1) {
                firstEntry.v_remarks = params.cardNo;

                // For Cart Swipes also we have to mark pd status
                if (mode['ac_ispde'] === 'Y') {
                    firstEntry = {
                        ...firstEntry,
                        v_pdstatus: 'E',
                        v_pddt: moment().format('MM/DD/YYYY'),
                    };
                }
            }

            if (['Net Banking'].indexOf(params.type) !== -1) {
                firstEntry.v_remarks = params.ref;
            }

            // Form Body
            var formBody = {
                ...this.getDefaultProps(branch),
                v_dt: new Date(),
                v_narration: params.remarks,
                rows: [firstEntry],
            };

            // Add the remarks coloumn

            if (['Credit Card', 'Debit Card'].indexOf(params.type) !== -1) {
                formBody.v_remarks = params.cardNo;
            }

            if (['Net Banking'].indexOf(params.type) !== -1) {
                formBody.v_remarks = params.ref;
            }

            // For Cheque
            if (params.type === 'Cheque') {
                formBody.v_remarks = params.chequeDate;
            }

            //
            if (params.account === 'BOOKING') {
                // For booking we have to create a new acmas record and assign
                return ApiUtils.createHead(params, branch).then((result) => {
                    let secondEntry = {
                        v_acptr: result.ac_code,
                        v_qty: 0,
                        v_amt: params.amount,
                        v_trn: -1,
                        v_slno: 1,
                        v_canedit: 'Y',
                        v_refdet: ' ',
                        v_rowremarks: '',
                        v_othref: params.v_othref,
                    };

                    // For Cheque
                    if (params.type === 'Cheque') {
                        secondEntry.v_refdet = params.chequeNo;
                        // secondEntry.v_remarks = params.chequeDate;
                    }

                    formBody.rows.push(secondEntry);

                    return PostData({
                        url: '/voucher/' + branch.city,
                        formBody,
                    }).then((voucher) => {
                        return {
                            account_head: result,
                            voucher,
                        };
                    });

                    // return new Promise((resolve, reject) => {
                    //     message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

                    //     reject({});
                    // });
                });
            } else {
                let secondEntry = {
                    v_acptr: params.ac_code.value, // Customer Account
                    v_qty: 0,
                    v_amt: params.amount,
                    v_trn: -1,
                    v_slno: 1,
                    v_canedit: 'Y',
                    v_refdet: ' ',
                    v_rowremarks: '',
                    v_othref: params.v_othref,
                };

                // For Cheque
                if (params.type === 'Cheque') {
                    secondEntry.v_refdet = params.chequeNo;
                    // secondEntry.v_remarks = params.chequeDate;
                }

                // if (['Credit Card', 'Debit Card'].indexOf(params.type) !== -1) {
                //     secondEntry.v_remarks = params.cardNo;
                // }

                // if (['Net Banking'].indexOf(params.type) !== -1) {
                //     secondEntry.v_remarks = params.ref;
                // }

                formBody.rows.push(secondEntry);

                return PostData({ url: '/voucher/' + branch.city, formBody }).then((voucher) => {
                    return {
                        //  account_head: result,
                        voucher,
                    };
                });

                // return new Promise((resolve, reject) => {
                //     message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

                //     reject({});
                // });
            }
        } else {
            message.error('You are not allowed post to a difference branch. Please select the correct branch.');

            return Promise.reject(new Error('Failed'));
        }
    };

    /**
     *
     * Create Other Credit
     *
     * @param {*} params
     * @returns
     */
    static createOtherCredit = (params, values) => {
        // const active = this.getBranch();

        var credit_amount = parseFloat(params.credit);

        return new Promise((resolve, reject) => {
            if (params.mode) {
                var formBody = {
                    ...this.getDefaultProps(params.department),
                    //  ...extraParams,
                    v_dt: moment(params.transaction_date).format('MM/DD/YY'),
                    v_remarks: params.description + '/' + params.ref,
                    rows: [
                        // Debit the Bank Account
                        {
                            v_acptr: params.mode,
                            v_qty: 0,
                            v_amt: credit_amount,
                            v_trn: 1,
                            v_totrow: 'Y',
                            v_refdet: ' ',
                            v_othref: params.v_othref,
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            v_narration: values.remarks,
                        },

                        // Credit the Other head selected
                        {
                            v_acptr: values.ac_code.value,
                            v_qty: 0,
                            v_amt: credit_amount,
                            v_trn: -1,
                            v_refdet: ' ',
                            v_othref: values.v_othref,
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            // v_remarks: params.ref,
                            // v_remarks: params.description + '/' + params.ref,
                            v_narration: values.remarks,
                        },
                    ],
                };

                // return new Promise((resolve, reject) => {

                // message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

                // resolve({});
                // })

                PostData({
                    url: `/voucher/${params.department.city}`,
                    formBody,
                }).then((result) => {
                    resolve(result);
                });
            } else {
                resolve({});
            }
        });
    };

    /**
     *
     * Create Kotak Entry iF Same location
     *
     * @param {*} params
     * @returns
     */
    static createManualEntry = (params, values, collection, branch) => {
        // const active = this.getBranch();

        var credit_amount = parseFloat(collection.amount);

        return new Promise((resolve, reject) => {
            if (params.mode) {
                // #noteenable
                var formBody = {
                    ...this.getDefaultProps(branch),
                    v_dt: moment(values.transaction_date).format('MM/DD/YY'),
                    // v_remarks: params.description + '/' + params.ref,
                    rows: [
                        // Debit the Bank Account
                        {
                            v_acptr: params.mode,
                            v_qty: 0,
                            v_amt: credit_amount,
                            v_trn: 1,
                            v_totrow: 'Y',
                            v_refdet: ' ',
                            v_othref: `RD:MNL${collection.collectionId}`,
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            v_narration: values.remarks,
                        },

                        // Credit the Suspense Account
                        {
                            v_acptr: values.ac_code.value,
                            v_qty: 0,
                            v_amt: credit_amount,
                            v_trn: -1,
                            v_refdet: ' ',
                            v_othref: `RD:MNL${collection.collectionId}`,
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            // v_remarks: params.ref,
                            // v_remarks: params.description + '/' + params.ref,
                            v_narration: values.remarks,
                        },
                    ],
                };

                // return new Promise((resolve, reject) => {
                //     message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

                //     reject({});
                // });

                PostData({
                    url: `/voucher/${branch.city}`,
                    formBody,
                }).then((result) => {
                    resolve(result);
                });
            } else {
                resolve({});
            }
        });
    };

    /**
     *
     * Function is triggered after razorpay success
     *
     * @param {*} params
     * @returns
     */
    static createPayment = (params, branch) => {
        //const active = this.getBranch();

        var credit_amount = parseFloat(params.amount);

        var formBody = {
            ...this.getDefaultProps(branch),
            v_vtype: 'P',
            v_canedit: 'Y',
            v_remarks: params.description + '/' + params.ref,
            v_dt: moment().format('MM/DD/YY'),
            rows: [
                // Debit the Customer
                {
                    v_acptr: params.customer,
                    v_qty: 0,
                    v_amt: credit_amount,
                    v_trn: 1,
                    v_slno: 1,

                    // reconcil_status:'Y',
                    // reconcil_date:new Date(),

                    // v_refdet: params.description,
                    // v_rowremarks: params.ref,
                    v_refdet: ' ',
                },

                // Credit the Razorpay Location Balance
                {
                    v_acptr: params.mode,
                    v_qty: 0,
                    v_amt: credit_amount,
                    v_trn: -1,
                    v_totrow: 'Y',
                    v_slno: -1,

                    v_rconstatus: 'Y', // Reconcile Status of Bank Entry
                    v_rcondt: moment().format('MM/DD/YY'), // Reconcile Status

                    // v_refdet: params.description,
                    // v_rowremarks: params.ref
                    // v_remarks: params.description + '/' + params.ref,
                    v_refdet: ' ',
                    // v_remarks: params.ref
                },
            ],
        };

        return PostData({
            url: '/voucher/' + branch.city,
            formBody,
        });

        // return new Promise((resolve, reject) => {
        //     message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

        //     resolve({});
        // });
    };

    /**
     *
     * Create Kotak Entry iF Same location
     *
     * @param {*} params
     * @returns
     */
    static debitKotak = (params, values, collection, branch) => {
        // const active = this.getBranch();

        var credit_amount = parseFloat(collection.amount);

        return new Promise((resolve, reject) => {
            if (params.mode) {
                // #noteenable
                var formBody = {
                    ...this.getDefaultProps(branch),
                    v_dt: moment(values.transaction_date).format('MM/DD/YY'),
                    // v_remarks: params.description + '/' + params.ref,
                    rows: [
                        // Debit the Bank Account
                        {
                            v_acptr: params.mode,
                            v_qty: 0,
                            v_amt: credit_amount,
                            v_trn: 1,
                            v_totrow: 'Y',
                            v_refdet: ' ',
                            v_othref: `RD:KTKO${collection.collectionId}`,
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            v_narration: values.remarks,
                        },

                        // Credit the Suspense Account
                        {
                            v_acptr: values.ac_code,
                            v_qty: 0,
                            v_amt: credit_amount,
                            v_trn: -1,
                            v_refdet: ' ',
                            v_othref: `RD:KTKO${collection.collectionId}`,
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            // v_remarks: params.ref,
                            // v_remarks: params.description + '/' + params.ref,
                            v_narration: values.remarks,
                        },
                    ],
                };

                PostData({
                    url: `/voucher/mpm`,
                    formBody,
                }).then((result) => {
                    resolve(result);
                });

                // return new Promise((resolve, reject) => {
                // message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

                // resolve({});
                // })
            } else {
                resolve({});
            }
        });
    };

    static createKotakEntry = (params, values, collection, department, allDepartments) => {
        // Active Branch
        //const active = this.getBranch();

        let branch;

        // Get all the branches
        //const branches = this.getBranches();

        // Find the matching branch of the credited head
        if (allDepartments) {
            // Since Kotak is in Malappuram
            var matching = allDepartments.filter((dep) => dep.location === 'malappuram' && dep.description === 'Department Handling Sales');

            if (matching && matching[0]) {
                branch = matching[0];
            }
        }

        // Should override Malappuram kotak Bank head here
        params.mode = '1030007';

        // For Malappuram Branch , find the kozhikode branch code
        const branchParams = {
            ac_code: branch['other'][collection.city],
        };

        return new Promise((resolve, reject) => {
            if (params.mode) {
                // On the Other branch update suspense to credit branch
                return this.debitKotak(params, branchParams, collection, department)
                    .then(() => {
                        // Create a voucher to debit branch and credit

                        params.mode = department.other['malappuram'];

                        params = {
                            ...params,
                            ...collection,
                            credit: collection.amount,
                            v_othref: `RD:KTKJ${collection.collectionId}`,
                        };

                        let receiptDepartment = allDepartments.filter((dep) => dep.id === params.department_id);

                        params.department = receiptDepartment[0];

                        // Mentioning the collection for customer record
                        values.v_othref = `RD:KTKJ${collection.collectionId}`;

                        return this.createJournal(params, values, {
                            v_vtype: 'J',
                        }).then((result) => {
                            resolve(result);
                        });
                        // this.createOtherCredit().then(() => {});
                    })
                    .catch(() => {
                        reject({});
                    });
            } else {
                resolve({});
            }
        });
    };

    /**
     *
     * Create Journal
     *
     * @param {*} params
     * @returns
     */
    static createJournal = (params, values, extraParams = {}) => {
        //const active = this.getBranch();

        var credit_amount = parseFloat(params.credit);

        return new Promise((resolve, reject) => {
            if (params.mode) {
                // #noteenable
                var formBody = {
                    ...this.getDefaultProps(params.department),
                    ...extraParams,
                    v_dt: moment(params.transaction_date).format('MM/DD/YY'),
                    v_remarks: params.description + '/' + params.ref,
                    rows: [
                        // Debit the Branch
                        {
                            v_acptr: params.mode,
                            v_qty: 0,
                            v_amt: credit_amount,
                            v_trn: 1,
                            v_slno: 1,
                            v_totrow: ' ',
                            v_refdet: ' ',
                            v_othref: params.v_othref,
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            v_narration: values.remarks,
                        },

                        // Credit the Customer
                        {
                            v_acptr: values.ac_code.value,
                            v_qty: 0,
                            v_slno: 2,

                            v_othref: values.v_othref,
                            v_amt: credit_amount,
                            v_trn: -1,
                            v_refdet: ' ',
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            // v_remarks: params.ref,
                            // v_remarks: params.description + '/' + params.ref,
                            v_narration: values.remarks,
                        },
                    ],
                };

                //   return new Promise((resolve, reject) => {
                // message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

                // resolve({});

                //  });

                PostData({
                    url: `/voucher/${params.department.city}`,
                    formBody,
                }).then((result) => {
                    resolve(result);
                });
            } else {
                resolve({});
            }
        });
    };

    /**
     * Occassionally other branch credits are used to verify
     * So we need to make necessary vouchers
     *
     * index is used to identify from where the function is triggered
     */
    static verifyOtherBranch = (params, values, collection, index) => {
        var cityIndex = {
            kozhikode: 'c',
            kannur: 'k',
            malappuram: 'm',
        };

        // On that branch
        const branchParams = {
            ac_code: params.department['other'][collection.city],
            v_othref: `R${index}:I${cityIndex[collection.city]}:${collection.collectionId}`,
        };

        return new Promise((resolve, reject) => {
            // if (params.mode) {

            // On the Other branch update suspense to credit branch
            return this.updateVoucherEntry(params, branchParams)
                .then(() => {
                    // Create a voucher to debit branch and credit

                    params.mode = params.department.other[params.city];

                    values.v_othref = `R${index}:P:J${cityIndex[collection.city]}:${collection.collectionId}`;

                    params.v_othref = `R${index}:J:${cityIndex[collection.city]}:${collection.collectionId}`;

                    return this.createJournal(params, values, {
                        v_vtype: 'J',
                    }).then((result) => {
                        resolve(result);
                    });
                    // this.createOtherCredit().then(() => {});
                })
                .catch(() => {
                    reject({});
                });
            // } else {
            //     resolve({});
            // }
        });
    };

    /**
     *
     * Create Custom Record
     *
     * @param {*} params
     * @returns
     */
    static createCustomRecord = (params, values, target) => {
        // const active = this.getBranch();

        var credit_amount = parseFloat(params.credit);

        return new Promise((resolve, reject) => {
            if (params.mode) {
                // #noteenable
                var formBody = {
                    ...this.getDefaultProps(params.department),
                    v_vtype: values.type === 'credit' ? 'P' : 'R',
                    v_dt: moment(params.transaction_date).format('MM/DD/YY'),
                    v_remarks: params.description + '/' + params.ref,
                    rows: [
                        // Debit the Bank Account
                        {
                            v_acptr: params.mode,
                            v_qty: 0,
                            v_amt: credit_amount,
                            v_trn: values.type === 'debit' ? 1 : -1,
                            v_totrow: 'Y',
                            v_refdet: ' ',
                            v_othref: `CSTM:${target}${params.receiptId}`,
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            v_narration: values.remarks,
                        },

                        // Credit the Suspense Account
                        {
                            v_acptr: values.ac_code.value,
                            v_qty: 0,
                            v_amt: credit_amount,
                            // v_trn: -1,
                            v_trn: values.type === 'debit' ? -1 : 1,
                            v_othref: `CSTM:${target}`,
                            v_refdet: ' ',
                            // v_refdet: params.description,
                            // v_rowremarks: params.ref,
                            // v_remarks: params.ref,
                            // v_remarks: params.description + '/' + params.ref,
                            v_narration: values.remarks,
                        },
                    ],
                };

                // return new Promise((resolve, reject) => {
                //     message.warning('Auto posting is disabled right now, Please do them manually.');

                //     resolve({});
                // });
                PostData({
                    url: `/voucher/${params.department.city}`,
                    formBody,
                }).then((result) => {
                    resolve(result);
                });
            } else {
                resolve({});
            }
        });
    };

    /**
     * Update the record with id in city
     *
     * @param {*} city
     * @param {*} id
     * @param {*} formBody
     */
    static updateVoucher = (department, id, formBody) => {
        //var active = this.getVoucherActiveBranch(city, deptId, allDepartments);

        return PutData({
            url: `/voucher/${department.city}/${id}`,
            formBody,
        });

        // return new Promise((resolve, reject) => {
        //     message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

        //     resolve({});
        // });
    };

    /**
     * Update the existing Voucher
     *
     * @param {*} params
     * @returns
     */
    static updateVoucherRecord = (id, formBody, department) => {
        return PutData({
            url: `/voucher/${department.city}/${id}`,
            formBody,
        });
    };

    /**
     * Update the CS Accounts Voucher Record
     */
    static updateVoucherEntry(receipt, values) {
        // Updated params
        var params = {
            v_acptr: values.ac_code,
            v_othref: values.v_othref,
            // v_narration: values.remarks
            // v_refdet: record.remarks,
            // v_rowremarks: values.remarks
        };

        var bankParams = {
            v_bigopacptr: values.ac_code,
            v_opacptr: values.ac_code,
            v_narration: values.verificationRemarks,
        };

        return new Promise((resolve, reject) => {
            const { voucher = {} } = receipt;

            // Get the Voucher Records for updating
            this.getVouchers(voucher.v_vno, receipt.department).then((result) => {
                // Get the bank entry
                var bank_entry = result.filter((entry) => entry.v_totrow === 'Y').pop();

                // Get the suspense entry
                var suspence_entry = result.filter((entry) => entry.v_totrow === null).pop();

                if (bank_entry && suspence_entry) {
                    Promise.all([
                        this.updateVoucher(receipt.department, bank_entry.v_id, bankParams),
                        this.updateVoucher(receipt.department, suspence_entry.v_id, params),
                    ]).then((result) => {
                        // message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

                        resolve(result);
                    });
                } else {
                    message.warning('CS Accounts entry has to be manually Updated');

                    resolve({});
                }
            });
        });
    }

    static updateValues(id, params, baseCity) {
        return ApiUtils.updateVoucherRecord(id, params, baseCity);
    }

    /**
     * Update the single voucher
     *
     * @param {*} id
     * @param {*} formBody
     * @returns
     */
    static updateVoucherSingle(id, formBody) {
        // Active
        const active = this.getBranch();

        return PutData({
            url: `/voucher/${active.city}/${id}`,
            formBody,
        });

        // return new Promise((resolve, reject) => {
        //     message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

        //     reject({});
        // });
    }

    /**
     *
     * For Debit/Credit Card/ Bulk Posting , We update reconcile status
     *
     * @param {*} id
     * @param {*} params
     * @returns
     */
    static updateReconcileStatus = (id, params) => {
        const active = this.getBranch();

        var formBody = {
            v_rconstatus: 'Y',
            v_rcondt: new Date(),
        };

        return PutData({
            url: `/voucher/${active.city}/${id}`,
            formBody,
        });

        // return new Promise((resolve, reject) => {
        //     message.warning('Autoposting for accounts entry is disabled right now, Please do them manually.');

        //     reject({});
        // });
    };

    static createHead = (params, branch) => {
        //  const active = this.getBranch();

        // Get the branch from active branch
        const { v_brptr, firm_ptr, fy_id } = branch;

        var formBody = {
            // ac_code: Math.round(Math.random() * 1000000),
            ac_desc: params.name + ' - ' + params.phone + ' - ' + params.bookingNo,
            ac_groupptr: '109',
            ac_costcentreptr: 'GEN',
            ac_defamt: 0,
            ac_type: '3',
            // ac_addptr: 783,
            // ac_firmptr: firmCodes['sales'],
            ac_firmptr: firm_ptr,
            ac_ispde: 'N',
            ac_linkacptr: 'N',
            v_rconstatus: 'N',
            ac_active: 'Y',
            ac_slno: 0,
            ac_issync: 'N',

            // "cngd_dt": {}
            add_mobile1: params.phone,
            add_firmptr: firm_ptr,
            add_regno3: params.bookingNo,
        };

        // return new Promise((resolve, reject) => {
        //     message.warning('Account creation for accounts is disabled right now, Please do them manually.');

        //     resolve({});
        // });
        return PostData({ url: '/ac-mas/' + branch.city + '/', formBody });
    };

    /**
     * Get the matching heads
     */
    static getVoucherRecord = (id, department) => {
        // const active = this.getBranch();

        return GetData({
            url: `/voucher/${department.city}/${id}`,
        });
    };

    /**
     * Get the matching heads
     */
    static getVoucherRecords = (id, department) => {
        //const active = this.getBranch();

        const { v_brptr, fy_id, firm_ptr } = department;

        return GetData({
            url: `/voucher/${department.city}?v_vno=${id}&v_vtype=R&v_firmptr=${firm_ptr}&v_finyearid=${fy_id}`,
        });
    };

    /**
     * Function would return the branch object for the city passed
     *
     * @param {*} location
     * @returns
     */
    static getActiveBranch = (location, allDepartments) => {
        let active = null;

        // Get all the branches
        // const branches = this.getBranches();

        // Find the matching branch of the credited head
        if (allDepartments) {
            // Since Kotak is in Malappuram

            var matching = allDepartments.filter((dep) => dep.location === location && dep.description === 'Department Handling Sales');

            if (matching && matching[0]) {
                active = matching[0];
            }
        }

        return active;
    };

    /**
     * Get vouchers from the particular branch
     */
    static getVouchers = (id, branch) => {
        // var active = this.getVoucherActiveBranch(location, deptId);

        const { city, v_brptr, fy_id, firm_ptr } = branch;

        return GetData({
            url: `/voucher/${city}?v_vno=${id}&v_vtype=R&v_firmptr=${firm_ptr}&v_finyearid=${fy_id}`,
        });
    };

    static getVoucherActiveBranch = (location, deptId, allDepartments) => {
        let active = null;

        // Get all the branches
        //const branches = this.getBranches();

        // Find the matching branch of the credited head
        if (allDepartments) {
            // Since Kotak is in Malappuram

            var matching = allDepartments.filter((dep) => dep.location === location && dep.id === deptId);

            if (matching && matching[0]) {
                active = matching[0];
            }
        }

        return active;
    };

    /**
     * Get the ledger for the customer
     *
     * Support passing custom firm
     *
     * @param {*} location
     * @param {*} ac_code
     * @param {*} firm_id
     * @returns
     */
    static getCustomerLedger(location, ac_code, firm_id, ver_finyearid, department) {
        // var active = this.getActiveBranch(location, allDepartments);

        let { v_brptr, fy_id, firm_ptr } = department;

        if (firm_id) {
            firm_ptr = firm_id;
        }

        if (ver_finyearid) {
            fy_id = ver_finyearid;
        }

        return GetData({
            url: `/ledger/getLedgerBalance/${department.city}?brptr=${v_brptr}&ac_code=${ac_code}&op_cl_type=O&date=04/01/2021&firmptr=${firm_ptr}&finyearid=${fy_id}`,
        });
    }

    /**
     * Get all the ledger details for the ac_code
     *
     * To override default firm pass firm_id : #Note
     */npm 

    static getLedgerDetails(location, ac_code, firm_id, department) {
        //  var active = this.getActiveBranch(location, allDepartments);

        let { v_brptr, fy_id, firm_ptr } = department;

        if (firm_id) {
            firm_ptr = firm_id;
        }

        return GetData({
            url: `/ledger/getEntireLedgerDetails/${department.city}?fromDate=03/31/2021&toDate=${moment().format(
                'MM/DD/YY'
            )}&acptr=${ac_code}&consolidate=N&firmptr=${firm_ptr}&finyearid=${fy_id}`,
        });
    }

    static getBulkLedgerDetails(department, ac_code, firm_id) {
        // var active = this.getActiveBranch(location);

        let { v_brptr, fy_id, firm_ptr } = department;

        var formBody = {
            fromDate: '03/31/2021',
            toDate: moment().format('MM/DD/YY'),
            acptr: ac_code,
            firmptr: firm_ptr,
            finyearid: fy_id,
            consolidate: 'N',
            op_cl_type: 'O',
            brptr: v_brptr,
        };

        if (firm_id) {
            firm_ptr = firm_id;
        }

        return PostData({
            url: `/ledger/getBulkLedgerDetails/${department.city}`,
            formBody,
        });
    }

    /**
     * Get the matching heads
     */
    static getHeads = (query, branch) => {
        // const active = this.getBranch();

        let firm_ptr = branch.firm_ptr;

        return GetData({
            url: `/ac-mas/${branch.city}?q=${query}&ac_firmptr=${firm_ptr}&limit=50`,
        });
    };

    /**
     * Load Heads for the city passed
     */
    static loadHeads = (department, query) => {
        // var active = this.getActiveBranch(city, allDepartments);

        // const active = this.getBranch();

        let firm_ptr = department.firm_ptr;

        return GetData({
            url: `/ac-mas/${department.city}?q=${query}&ac_firmptr=${firm_ptr}&limit=50`,
        });
    };

    /**
     * Load the heads for group
     */
    static getModes = (group, branch) => {
        // const active = this.getBranch();

        let group_ptr = 103;

        let firm_ptr = branch.firm_ptr;

        if (branch && branch.acCodes) {
            group_ptr = branch.acCodes[group];
        }

        return GetData({
            url: `/ac-mas/${branch.city}?ac_groupptr=${group_ptr}&ac_firmptr=${firm_ptr}&limit=30`,
        });
    };

    /**
     *
     *
     * @param {*} city
     * @param {*} id
     * @returns
     */
    static getPaymentRequestRecord = (city, id, department) => {
        //var active = this.getActiveBranch(city, allDepartments);

        return GetData({
            url: `/voucher-entry-rqst/${department.city}/${id}`,
        });
    };

    static respondPaymentRequest = (city, id, formBody, department) => {
        // var active = this.getActiveBranch(city, allDepartments);

        return PostData({
            url: `/voucher-entry-rqst/approve/${department.city}/${id}`,
            formBody,
        });

        // return new Promise((resolve, reject) => {
        //     message.warning('Auto posting is disabled right now, Please do them manually.');

        //     reject({});
        // });
    };

    static getAccountDetail = (city, id, department) => {
        //var active = this.getActiveBranch(city, allDepartments);

        return GetData({
            url: `/ac-mas/${department.city}/${id}`,
        });
    };
}
