/**
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState, useContext } from "react"

import { Button, Form, Input, Modal, Radio, message } from 'antd';


import { withRouter } from 'react-router-dom'

// import './form-creator.scss'

import FirebaseUtils from './../../../../utils/firebase.utils';
import { PutData, PostData } from './../../../../utils/http.utils';


import FormCreator from './../form-creator/form-creator';
import GatePassModal from "../../../../components/vehicle-detail/gate-pass";
import { BookingStatusModal } from "../../../accounts/components/booking-status-modal/booking-status-modal";


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { TextArea } = Input;


function RecordForm({ index, step, model, formContent, modelIndex, callback, record }) {
    const [content, setContent] = useState(formContent);

    const [visible, setVisible] = useState(false)

    const [bookingStatusModal, setBookingStatusModal] = useState(false);

    const [bookingNo, setBookingNo] = useState();



    const onDataCollection = (values) => {


        return updateRecords(step, values);


    }

    /**
     * Function Pings to specified endpoint with values updated
     * 
     * @param {*} config 
     * @param {*} values 
     */
    function updateNodeData(config, values) {


        let params = {
            // ...values
        }

        if (step.extraParams) {

            params = {
                ...params,
                // Note extra Params will override every previous value if the same key is used 
                ...step.extraParams(values, content)
            }
        }


        return PostData({ url: config.url.replace(':id', record.ver_vno), formBody: params }).then((result) => {

            console.log(result);

            if (result.ok) {

                message.success(`Operation Successfull`);

            } else {


                if (result.statusCode === 400) {

                    message.error(`${result.message}`);

                } else {

                    message.success(`Some issue`);

                    console.log(result);

                }
            }



            console.log(result);

            // setLoading(false);

            // setRecords({ [model]: result });

            // setVouchers(result);

        })

    }

    function updateFirebaseData(config, values) {


        let params = {
            ...values
        }

        if (step.extraParams) {

            params = {
                ...params,
                // Note extra Params will override every previous value if the same key is used 
                ...step.extraParams(content, params)
            }
        }

        return FirebaseUtils.updateRecord(step.endpoint, index, params).then(() => {

            message.success(`${model} details updated`);

            // Custom Actions to be done on submit
            step.onSubmit && step.onSubmit(content, values, setVisible,setBookingStatusModal,setBookingNo, callback);
            if (!step.showGatePass)
                callback(params, values);

        });
    }

    const updateRecords = (config, values) => {

        console.log(config);
        switch (config.node) {
            case 'nest':

                return updateNodeData(config, values);
            default:
                return updateFirebaseData(config, values);
        }
    }

    return (
        <section className="request-form">

            {
                step.fields && step.fields.length
                    ?
                    <FormCreator
                        config={step}
                        formContent={formContent}
                        modelIndex={modelIndex}
                        model={model}
                        onSubmit={onDataCollection}
                    />
                    :
                    null
            }
            {/**   Modal used for Gate pass Start  */}

            <Modal
                destroyOnClose={true}
                footer={null}
                title="Gate pass"
                visible={visible}
                width={'60%'}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                    callback()
                }}
                onCancel={() => {
                    setVisible(false);
                    callback()
                    // setTransferEnable(false);
                }}
            >
                <GatePassModal params={formContent['vinNo']} stockTransferId={index} />
            </Modal>
            {/**   Modal used for Gate pass end  */}

            {/* update booking current status value when unblocking the vehicle  */}
            <div>
                <Modal
                    width={'50%'}
                    destroyOnClose={false}
                    footer={null}
                    title="Booking Status"
                    visible={bookingStatusModal}
                    okText="Okay"
                    onOk={() => {
                        // setBookingStatusModal(false);
                        // callback()
                    }}
                    onCancel={() => {
                        // setBookingStatusModal(false);
                        // callback()
                    }} >

                    <BookingStatusModal id={bookingNo} callback={callback} />
                </Modal>
            </div>
            {/*Ends */}
        </section>

    );
}

export default withRouter(RecordForm);



