import React, { useContext } from 'react';

import { Modal, Button } from 'antd';

import { GlobalContext } from '../../../../Store';

import DateUtils from '../../../../utils/date.utils';

import { Location } from './../../../../utils/location.utils';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import moment from 'moment-timezone';

import './payment-card.scss';

export default function PaymentCard({ location, record, index, callback, history, ...props }) {
    // User document
    const { user } = useContext(GlobalContext);

    console.log(history);

    var city = location.name.toLowerCase();

    // let waitinghours = moment().diff(moment(record.ver_tm));

    const onResponse = (status) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to proceed ?',
            okText: 'Yes',
            cancelText: 'No',
            onCancel: () => {
                console.log('Cancelled');
            },
            onOk: () => {
                return callback(record, status);
            },
        });
    };

    return (
        <div className="payment-card">
            {/* // <Link className="report-item" to={`report/${index}`}> */}
            <div className="card">
                <h2 className="title amount">{record.ver_amt}</h2>

                <h3 className="title">
                    {/* {vehicle.customer.customerName} */}
                    {record.ver_narration}
                </h3>

                <h2 className="title">{record.ac_desc}</h2>

                <p className="address">
                    {record.bank_record ? (
                        <>
                            From {record.bank_record.ac_desc} , Recorded by {record.ver_user} for{' '}
                            {moment.tz(record.ver_dt, 'Asia/Calcutta').format('DD/MM/YYYY')}
                            {/* {record.ver_dt} */}
                        </>
                    ) : (
                        <></>
                    )}
                </p>

                <p>Requested at {DateUtils.surgeTime(record.ver_tm)}</p>

                <p className="address">
                    {record.ver_rowremarks} {record.ver_narration}
                </p>

                <div className="button-group">
                    {['niaz@alsalama.in', 'admin@dkhkia.com', 'drkasim@alsalama.in', 'anoopts@gmail.com'].indexOf(user.email) !== -1 ? (
                        <div>
                            <Button
                                onClick={() => {
                                    onResponse('approved');
                                }}
                                type="primary"
                            >
                                Approve
                            </Button>

                            <Button
                                onClick={() => {
                                    onResponse('declined');
                                }}
                                type="secondary"
                            >
                                Decline
                            </Button>
                        </div>
                    ) : null}

                    <Button
                        onClick={() => {
                            history.push(`/${city}/payment-request-detail/${record.ver_id}`);
                        }}
                        type="secondary"
                    >
                        View Detail
                    </Button>
                </div>
            </div>
        </div>
        // {/* // </Link> */}
    );
}
