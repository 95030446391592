import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';

import { Typography, Table, Popover, Modal, message, Button, Radio, InputNumber, Skeleton, Tag } from 'antd';

import { Form, Input, Checkbox, Select } from 'antd';

import { useReactToPrint } from 'react-to-print';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { GlobalContext } from '../../../../Store';

import Rupee from '../../../../components/rupee/rupee';

import { ExclamationCircleOutlined, DeleteOutlined, FileTextOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Departments, Settings, Branches } from '../../../../models';

import { BillDetails, Requests } from '../../../../models';

import { Taxes } from './../../../../models';
import Branch from './../../../../models/branches';
import { cityKeys } from '../../../global-config';

const { TextArea } = Input;

const { confirm } = Modal;

var companyLogo = require('../../../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

const { Option } = Select;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export const GenerateSettlement = ({ data, booking, billNumber, callback, city }) => {
    const [loading, setLoading] = useState(false);

    // Users
    const { user } = useContext(GlobalContext);

    const { defaultBranch } = useContext(GlobalContext);

    let history = useHistory();

    const [isgst, setIsgst] = useState(false);

    const [packageItems, setPackageItems] = useState();

    const [department, setDepartment] = useState();

    const [state, setState] = useState('Kerala');

    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const [editCount, setEditCount] = useState(0);

    const [billDiscount, setBillDiscount] = useState(0);

    const print = useRef();

    const [lastNumber, setLastNumber] = useState({});

    const [form] = Form.useForm();

    const [branch, setBranch] = useState();

    const [request, setRequest] = useState();

    var cityKey = cityKeys

    useEffect(() => {
        loadBranch();
        loadPackages();
        loadRequest();
    }, []);

    //For brach details to add invoice
    const loadBranch = () => {
        var queries = [
            {
                field: 'dealer_code',
                value: cityKey[city],
            },
        ];
        Branches.get(queries).then((result) => {
            setBranch(result.branches[0]);

            let queries = [
                {
                    field: 'branch_id',
                    value: result.branches[0].id,
                },
                {
                    field: 'description',
                    value: `Department Handling Sales`,
                },
            ];

            //get department details
            return Departments.get(queries).then((result) => {
                setDepartment(result.departments);
            });
        });
    };

    const loadRequest = () => {
        var query = [
            {
                field: 'bookingNo',
                value: booking['Booking No'],
            },
        ];
        Requests.get(query).then((result) => {
            let req = result.requests[0];
            setRequest(req);

            getInvoiceNumber(req);
        });
    };

    /**
     * Close the settlement Method
     */
    const handleGenericSettlement = (values) => {
        confirm({
            title: 'Raise Invoice',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to bill these items, This action is one time',
            onOk() {
                console.log('OK');

                setLoading(true);

                closeFile(values);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    //if item is a package
    const loadPackages = () => {
        setInvoiceLoading(true);

        let pi = data.filter((i) => i.package_status === true);

        if (pi.length > 0) {
            return Promise.all(
                pi.map((pkgItem) => {
                    let query = [
                        {
                            field: 'item_id',
                            value: pkgItem.item_id,
                        },
                    ];

                    return FirebaseUtils.getListing('central', query, 'package_details').then((result) => {
                        result.package_details = result.package_details.filter((i) => i.status !== 'removed' && i.active !== false);

                        return Promise.all(
                            result.package_details.map((pkg) => {
                                return FirebaseUtils.getItemJoinStockById({ id: pkg.sub_item_id, dealerCode: data[0].dealerCode }).then((item) => {
                                    return Taxes.getRecord(item.tax_id).then((tax) => {
                                        item = {
                                            ...item,
                                            item_id: item.id,
                                            cgst: tax.cgst,
                                            sgst: tax.sgst,
                                            igst: tax.igst,
                                            customerID: pkgItem.customerID,
                                            bookingNo: pkgItem.bookingNo,
                                            dealerCode: pkgItem.dealerCode,
                                            quntity: item.quantity || 1,
                                            name: item.part_name,
                                            type: item.category,
                                            id: pkgItem.id,
                                            request: pkgItem.request,
                                        };

                                        if (pkg.type === 'quantity') {
                                            item.quantity = pkg.value;
                                        }

                                        return BillDetails.getDetailedPricing(item, data[0].round);
                                    });
                                });
                            })
                        );
                    });
                })
            ).then((result) => {
                let array = result.flat(1);
                setPackageItems(array);
                setInvoiceLoading(false);
            });
        }
    };

    console.log(packageItems);

    /**
     * Editable rate
     * @value
     */
    data.map((item, key) => {
        item.callback = (value) => {
            if (value === '') {
            } else if (isNaN(value) || value.includes('e') || value.includes('E')) {
                message.warning('Rate should be a number');
            } else {
                item.rate = parseFloat(value);

                let totalTax = item.cgst + item.sgst;

                item.price = parseFloat(item.rate + parseFloat((totalTax / 100) * item.rate));

                let i = { ...BillDetails.getDetailedPricing(item, item.round) };

                item.sgst_amount = i.sgst_amount;
                item.cgst_amount = i.cgst_amount;
                item.igst_amount = i.igst_amount;
                item.net_amount = i.net_amount;
                item.taxable_value = i.taxable_value;
                item.total = i.total;

                if (i.discount_amount) {
                    item.discount_amount = i.discount_amount;
                    item.discount_percent = i.discount_percent;
                }
            }

            setEditCount((prev) => {
                return prev + 1;
            });
        };
    });
    console.log(editCount);
    /**
     * Close the file
     */
    function closeFile(values) {
        const hide = message.loading('Creating Bill...', 0);
        let discount;
        if (billDiscount !== 0) {
            discount = {
                billDiscountType: billDiscount.billDiscountType,
                billDiscountValue: billDiscount.billDiscountValue,
            };
        } else {
            discount = null;
        }

        let supplier_id = null;
        if (data[0].category === 'Accessory') {
            supplier_id = branch.accessory_supplier_id;
        } else if (data[0].category === 'VAS') {
            supplier_id = branch.vas_supplier_id;
        } else if (data[0].category === 'vehicle') {
            supplier_id = branch.vehicle_supplier_id;
        }

        //filtering to avoid Package head

        let d = data.filter((dt) => dt.package_status !== true);

        let selectedRecords = [
            ...d,
            // ...packageItems
        ];

        if (packageItems && packageItems.length) {
            selectedRecords = [].concat(selectedRecords, packageItems);
        }

        //Setting up body
        let formBody = {
            ...discount,
            selectedRecords: selectedRecords,
            selectedIds: null,
            isPayout: false,
            lastNumber: lastNumber,
            user: user,
            customer_name: booking['Bill Name'],
            customer_address: booking['Bill To Add'],
            customer_phone: booking['Contact Number'],
            customer_email: null,
            customer_postal_code: null,
            ship_to_name: booking['Ship Name'] || null,
            ship_to_address: booking['Ship To Add'] || null,
            ship_to_phone: null,
            ship_to_email: null,
            ship_to_postal_code: null,
            department: department[0],
            supplier_id: supplier_id,
            payment_status: null,
            attached_with: 'request',
            request: request,
            city: city,
            dealerCode: cityKey[city],
            branch_id: branch.id,
            ...values,
        };

        FirebaseUtils.generateVasBill(formBody).then((result) => {
            callback();

            setLoading(false);

            history.push(`/bill/${result.data}`);

            hide();
        });
    }

    function getInvoiceNumber(request) {
        setInvoiceLoading(true);

        FirebaseUtils.getBillOrder(request.city, data[0].category, moment().valueOf())
            .then((res) => {
                let id = res.prefix + (res.last_number + 1) + res.post_fix;

                setLastNumber({ id: res.id, lastNumber: id, numberOnly: res.last_number + 1 });

                setInvoiceLoading(false);
            })
            .catch(() => {
                message.error('Getting Bill Number breaks, Try again! .');
            });
    }

    const igst_columns = [
        {
            title: '#',
            width: 20,
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'name',
            width: 220,
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div>{text ? text : record['name']}</div>
                        {record.package_status === true ? (
                            <Tag style={{ marginLeft: '10px' }} color="magenta">
                                Package
                            </Tag>
                        ) : null}
                        {/* {
                            record.updatedType == 'BILLED (VAS)' && !printTab ?
                                <div style={{ marginLeft: '10px' }}><FileTextOutlined /></div>
                                :
                                null
                        } */}
                    </div>
                );
            },
        },
        {
            title: 'Rate',
            render: (text, record) => {
                return record.editable === true ? (
                    <Text editable style={{ color: 'red' }}>
                        {record.rate}
                    </Text>
                ) : (
                    <Text>{record.rate}</Text>
                );
            },
        },
        {
            title: 'Quatity',
            dataIndex: 'quantity',
        },

        {
            title: 'Total',
            dataIndex: 'total',
        },

        {
            title: 'Discount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <Popover content={`Item Discount`}>
                            {record.discount_type && record.discount_type === 'percent'
                                ? '%' + record.discount_value
                                : record.discount_type && record.discount_type === 'amount'
                                ? '₹' + record.discount_value
                                : 0}
                        </Popover>
                    </div>
                );
            },
            width: 150,
        },

        {
            title: 'Taxable Value',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>
                            {((parseFloat(record.quantity) ? parseFloat(record.quantity) : 1) * parseFloat(record.taxable_value)).toFixed(2)}
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'IGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.igst_amount ? record.igst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.igst ? record.igst : 0}%</div>
                    </div>
                );
            },
            width: 150,
        },

        {
            title: 'Net Amount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <div style={{ height: '20px' }}>{text.net_amount}</div>
                    </div>
                );
            },
            width: 200,
        },
    ];

    const columns = [
        {
            title: '#',
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div style={{ display: 'flex' }}>
                            <div>{text ? text : record['name']}</div>
                            {record.package_status === true ? (
                                <Tag style={{ marginLeft: '10px' }} color="magenta">
                                    Package
                                </Tag>
                            ) : null}
                            {/* {
                            record.updatedType == 'BILLED (VAS)' && !printTab ?
                                <div style={{ marginLeft: '10px' }}><FileTextOutlined /></div>
                                :
                                null
                        } */}
                        </div>
                        <small>{record.part_number}</small>
                    </Fragment>
                );
            },
        },
        {
            title: 'Rate',
            render: (text, record) => {
                return record.editable === true ? (
                    <Text
                        editable={{
                            onChange: record.callback,
                            tooltip: 'click to edit rate',
                        }}
                    >
                        {record.rate}
                    </Text>
                ) : (
                    <Text>{record.rate}</Text>
                );
            },
        },
        {
            title: 'Quatity',
            dataIndex: 'quantity',
        },

        {
            title: 'Total',
            dataIndex: 'total',
        },

        {
            title: 'Discount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <Popover content={`Item Discount`}>
                            {record.discount_type && record.discount_type === 'percent'
                                ? '%' + record.discount_value
                                : record.discount_type && record.discount_type === 'amount'
                                ? '₹' + record.discount_value
                                : 0}
                        </Popover>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'Taxable Value',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.taxable_value}</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'SGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.sgst_amount ? record.sgst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.sgst ? record.sgst : 0}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'CGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cgst_amount ? record.cgst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cgst ? record.cgst : 0}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'Net Amount',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{text.net_amount}</div>;
            },
            width: 150,
        },
    ];

    function calculatedAmount(amt, sgst, cgst) {
        let cgst1 = 0;
        let sgst1 = 0;
        if (cgst) {
            cgst1 = amt * (cgst / 100);
        }
        if (sgst) {
            sgst1 = amt * (sgst / 100);
        }
        let total = amt + parseFloat(cgst1) + parseFloat(sgst1);
        return total;
    }

    const address =
        user.locations[0] == 'kozhikode'
            ? { add1: 'Kannur Rd, Athanikkal, West Hill,', add2: 'Kozhikode, Kerala 673005' }
            : user.locations[0] == 'kannur'
            ? { add1: 'Kairali Nagar, Thottada(PO),', add2: 'Kannur, Kerala 670007' }
            : { add1: 'N.H.213, Ramapuram, Panangangara,', add2: 'Malappuram, Kerala 679321' };

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const getDateAndTime = () => {
        let date = new Date();
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>{moment(date).format('DD MMM YYYY')}</div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    /**
     *
     */
    function onSubmit(values) {
        handleGenericSettlement(values);
    }

    const discountSubmit = (values) => {
        setBillDiscount({ ...values });
    };

    return (
        <div className="generate-settlement">
            <div className="invoice-container">
                <div className="page-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div>
                        <img className="logo-welcome" src={companyLogo} alt="Logo" />
                        <Title level={3}>Invoice # {lastNumber.lastNumber}</Title>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {/* <div style={{ border: '1px dashed #d9d9d9', padding: '4px 15px', marginRight: '10px' }}>Bill No :- {billNumber}</div> */}
                        {/* <Button type="dashed" className="print-button" onClick={handlePrint}>
                            Print Invoice
                        </Button> */}
                    </div>
                </div>
                <div>
                    {/*  */}
                    {loading ? (
                        <Skeleton loading={loading} active />
                    ) : (
                        <Summary
                            data={data}
                            columns={state !== 'Kerala' ? igst_columns : columns}
                            isInter={state !== 'Kerala' ? true : false}
                            callback={discountSubmit}
                            editCount={editCount}
                        />
                    )}
                </div>
            </div>

            <div className="modal-footer card">
                <Title level={3}>Create Bill</Title>

                <p>Enter the below information to continue creating the bill .</p>

                <Form
                    {...layout}
                    form={form}
                    name="new-record"
                    onFinish={onSubmit}
                    layout="vertical"
                    onFieldsChange={(fields) => {
                        let field = fields[0];

                        if (field) {
                            if (field.name[0] === 'is_gst_customer') {
                                setIsgst(field.value);
                            }

                            if (field.name[0] === 'customer_state') {
                                setState(field.value);
                            }
                        } else {
                        }
                    }}
                    // validateMessages={validateMessages}
                    initialValues={{}}
                >
                    <Form.Item name="is_gst_customer" valuePropName="checked" wrapperCol={{ span: 16 }}>
                        <Checkbox>GST Customer ?</Checkbox>
                    </Form.Item>

                    {isgst ? (
                        <Form.Item
                            name={'gst_number'}
                            label="GST Number"
                            rules={[
                                {
                                    message: 'Please enter GSTIN',
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    ) : null}

                    <Form.Item
                        name={'customer_state'}
                        label="Customer State"
                        rules={[
                            {
                                message: 'Please select state',
                                required: true,
                            },
                        ]}
                    >
                        <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                            {['Kerala', 'Pondicherry', 'Tamil Nadu', 'Karnataka', 'Andhra Pradesh', 'Other'].map((model, key) => {
                                return (
                                    <Option value={model} key={key}>
                                        {model}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={'pan_number'}
                        label="Pan Number"
                        // rules={[
                        //     {
                        //         message: 'Please enter pan number.',
                        //         required: true,
                        //     },
                        // ]}
                    >
                        <Input style={{ width: '100%' }} placeholder="Enter pan number" />
                    </Form.Item>

                    <Form.Item
                        name={'payment_method'}
                        label="PaymentMethod"
                        rules={[
                            {
                                message: 'Please select payment method',
                                required: true,
                            },
                        ]}
                    >
                        <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                            {['Cash', 'Cheque', 'Debit Card', 'Credit Card', 'Net Banking', 'Other'].map((model, key) => {
                                return (
                                    <Option value={model} key={key}>
                                        {model}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Remarks"
                        name="remarks"
                        rules={[
                            {
                                message: 'Please enter remarks',
                                required: true,
                            },
                        ]}
                    >
                        <TextArea style={{ width: '100%' }} placeholder="Remarks" />
                    </Form.Item>
                    <Form.Item label="Consumer Note" name="consumer_note">
                        <TextArea style={{ width: '100%' }} placeholder="Consumer Note" />
                    </Form.Item>

                    {invoiceLoading ? (
                        <Button type="primary" loading>
                            Loading
                        </Button>
                    ) : (
                        <Button loading={loading} htmlType="submit" type={'primary'}>
                            Create Bill
                        </Button>
                    )}
                </Form>
            </div>
        </div>
    );
};

function Summary({ data, columns, refresh, isInter, callback, editCount }) {
    const [billDiscount, setBillDiscount] = useState(0);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [totals, setTotals] = useState({});

    const [form] = Form.useForm();

    let props = {};

    useEffect(() => {
        calculateTotal(data);
    }, [billDiscount, editCount]);

    let queries = [
        {
            field: 'name',
            value: 'net-amount-rounding',
        },
        {
            field: 'category',
            value: data && data[0].type,
        },
    ];

    function calculateTotal(data) {
        Settings.get(queries).then((result) => {
            let roundValue = result && result.settings.length && result.settings[0].round_value;

            let total = 0;
            let netAmount = 0;
            let cgstAmount = 0;
            let sgstAmount = 0;
            let igstAmount = 0;
            if (!isInter) {
                data.map((item) => {
                    let cgstValue = (item.cgst / 100) * item.taxable_value;
                    let sgstValue = (item.sgst / 100) * item.taxable_value;
                    let igstValue = (item.igst / 100) * item.taxable_value;

                    cgstAmount += cgstValue;
                    sgstAmount += sgstValue;
                    igstAmount += igstValue;

                    total += item.net_amount;
                    netAmount += item.net_amount;
                });
            } else {
                data.map((item) => {
                    let igstValue = (item.igst / 100) * item.taxable_value;

                    igstAmount += igstValue;

                    netAmount += item.net_amount;
                });
            }
            let totalNetAmount = 0;

            if (billDiscount !== 0) {
                data.map((item) => {
                    let netAmount = item.net_amount;
                    totalNetAmount += netAmount;
                });

                if (billDiscount.billDiscountType === 'percent') {
                    let discountAmount = (billDiscount.billDiscountValue / 100) * totalNetAmount;

                    netAmount -= discountAmount;
                } else if (billDiscount.billDiscountType === 'amount') {
                    netAmount -= billDiscount.billDiscountValue;
                }
            }

            //rounding net amount

            let adjArr = coinAdjustment(netAmount, roundValue);

            netAmount = adjArr[0];

            let roundAmount = adjArr[1];

            // function for net amount rounding
            function coinAdjustment(amount, roundValue) {
                let decArr = [];

                decArr[0] = amount;
                decArr[1] = 0;

                if (roundValue > 0 && (amount * 100) % roundValue !== 0) {
                    decArr[0] = amount * 100;
                    decArr[0] = Math.round(decArr[0] / roundValue) * (roundValue / 100);
                    decArr[1] = parseFloat((decArr[0] - amount).toFixed(2));
                }

                return decArr;
            }

            setTotals({
                total,
                roundAmount,
                netAmount,
                cgstAmount,
                sgstAmount,
                igstAmount,
            });
        });
    }

    const showDiscountModal = () => {
        setIsModalVisible(true);
    };

    const billDiscountHandler = (values) => {
        setBillDiscount(values);
        callback(values);
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    console.log(billDiscount);

    return (
        <>
            <Table
                pagination={false}
                scroll={{ x: true }}
                rowKey={(record, key) => key}
                columns={columns}
                dataSource={data}
                {...props}
                size="small"
                summary={(pageData) => {
                    //  #todo Below section will have to be optimized in future as there is enormous amount of rerendering happending for this
                    // component . But it works
                    let total = 0,
                        cgst_total = 0,
                        sgst_total = 0,
                        igst_total = 0;

                    pageData.forEach((entry) => {
                        // Amount
                        let amount = entry.net_amount;

                        // Total
                        total += amount;

                        cgst_total += entry.cgst_amount;
                        sgst_total += entry.sgst_amount;
                        igst_total += entry.igst_amount;
                    });

                    total = Math.round(total);

                    return (
                        <>
                            <Modal title="Bill Discount" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                                <Form {...layout} form={form} name="new-record" onFinish={billDiscountHandler} layout="vertical">
                                    <Form.Item name="billDiscountType" label="Discount" rules={[{ required: true, message: 'Please select a type' }]}>
                                        <Radio.Group>
                                            <Radio value="percent">In %</Radio>
                                            <Radio value="amount">In Rs</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label="Value"
                                        name="billDiscountValue"
                                        dependencies={['billDiscountType']}
                                        rules={[
                                            { required: true, message: 'Please input your amount' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('billDiscountType') === 'amount') {
                                                        if (value > totals.total) {
                                                            return Promise.reject(new Error('Discount should not exceed net amount.'));
                                                        } else return Promise.resolve();
                                                    } else if (!value || getFieldValue('billDiscountType') === 'percent') {
                                                        if (value > 100) {
                                                            return Promise.reject(new Error('Discount should not exceed 100%.'));
                                                        } else return Promise.resolve();
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                },
                                            }),
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                            {/* //Total row */}

                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Total</Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>

                                {isInter ? (
                                    <Table.Summary.Cell>
                                        <Popover content={`Total IGST`}>
                                            <Text>
                                                <Rupee value={totals.igstAmount}></Rupee>
                                            </Text>
                                        </Popover>
                                    </Table.Summary.Cell>
                                ) : (
                                    <Fragment>
                                        <Table.Summary.Cell>
                                            <Popover content={`Total SGST`}>
                                                <Text>
                                                    <Rupee value={totals.sgstAmount}></Rupee>
                                                </Text>
                                            </Popover>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Popover content={`Total CGST`}>
                                                <Text>
                                                    <Rupee value={totals.cgstAmount}></Rupee>
                                                </Text>
                                            </Popover>
                                        </Table.Summary.Cell>
                                    </Fragment>
                                )}

                                {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                <Table.Summary.Cell>
                                    <Popover content={`Total`}>
                                        <Text>
                                            <Rupee value={totals.total}></Rupee>
                                        </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            {/* Discount Row */}
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Discount</Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                {isInter ? null : <Table.Summary.Cell></Table.Summary.Cell>}
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Button type="dashed" onClick={showDiscountModal}>
                                        Add discount
                                    </Button>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <Popover content={`Bill Discount`}>
                                        <Text>
                                            {billDiscount === 0 ? (
                                                <Text>{billDiscount}</Text>
                                            ) : billDiscount.billDiscountType === 'percent' ? (
                                                <Text>{billDiscount.billDiscountValue}%</Text>
                                            ) : (
                                                <Rupee value={billDiscount.billDiscountValue}></Rupee>
                                            )}
                                        </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            {/* //Round Off row */}

                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Round Off</Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>

                                {isInter ? (
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                ) : (
                                    <Fragment>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                    </Fragment>
                                )}

                                {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                <Table.Summary.Cell>
                                    <Popover content={`Round off`}>
                                        <Text>
                                            <Rupee value={totals.roundAmount}></Rupee>
                                        </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            {/* //Total row */}

                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <Text style={{ fontSize: '16px', fontWeight: 600 }}>Grand Total</Text>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>

                                {isInter ? (
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                ) : (
                                    <Fragment>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                    </Fragment>
                                )}

                                {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                <Table.Summary.Cell>
                                    <Popover content={`Total`}>
                                        <Text style={{ fontSize: '20px', fontWeight: '600', color: '#FF0000' }}>
                                            <Rupee value={totals.netAmount}></Rupee>
                                        </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}
