import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './../../hocs/PrivateRoute';

import GlobalHeader from './../../components/global-header/global-header';

import StockDashboard from './../common/components/stock-dashboard/stock-dashboard';

import PreferenceDetail from './../common/components/preference-detail/preference-detail';

import AllocationRequests from './components/allocation-requests/allocation-requests';
// import RequestDetail from './components/request-detail/request-detail';

import RequestDetail from './../common/components/request-detail/request-detail';

// import StockDashboard from './components/stock-dashboard/stock-dashboard';

// My Booking Section Ends

import ManageBookings from './../common/components/manage-bookings/manage-bookings';
import BookingDetail from './../common/components/booking-detail/booking-detail';
import ChangePassword from './../common/components/change-password/change-password';

import Collections from './components/collections/collections';
import CollectionDetail from './components/collections/collections-detail';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import BookingTracker from './../common/resources/booking-tracker/booking-tracker';

import FinanceTracker from './../common/resources/finance-tracker/finance-tracker';

// Pending List
import Collection from './../common/components/sales-collections/collections/collections';
import Report from './../common/components/sales-collections/report/Report';

import CancellationsDetail from './../common/components/cancellations/cancellations-detail';
// Pending List Ends

import './tl-landing.scss';

import { GlobalContext } from './../../Store';

import NoticeBoard from './../common/components/notice-board/notice-board';

import { requestSchema, bookingSchema } from './../global-config';

import PendingRequests from './../common/components/pending-requests/pending-requests';

import Profile from './../common/resources/profile/profile';

import TaskList from './../common/resources/task-list/task-list';
import TaskForm from './../common/resources/task-form/task-form';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import TaskOverview from './../common/resources/task-overview/task-overview';

import { tlSteps } from './tl-config';
import EnquiryDetail from '../common/components/enquiry-detail/enquiry-detail';
import ManageEnquiries from '../common/components/manage-enquiries/manage-enquiries';

export default function TeamLanding(props) {
    const { user } = useContext(GlobalContext);

    let userQueries = [
        {
            field: 'teamleader_id',
            value: user.employee_id,
        },
    ];

    let tlQueries = [
        {
            field: 'teamleader_id',
            value: user.employee_id,
        },
    ];

    useEffect(() => { }, []);

    const modules = [
        {
            caption: 'Booking Tracker',
            path: '/booking-tracker',
        },

        {
            caption: 'Priority',
            submenus: [
                {
                    path: '/sm-requests',
                    caption: 'Priority List',
                },
            ],
        },

        {
            caption: 'Tasks',
            submenus: [
                ...tlSteps.filter((step) => !step.isForm),
                {
                    path: '/pending-list',
                    caption: 'Unidentified Receipts',
                },
            ],
        },

        {
            path: '/allocations',
            caption: 'Allocations',
        },

        {
            caption: 'Finance Tracker',
            path: '/finance-tracker',
        },
        {
            caption: 'Manage',
            submenus: [
                {
                    path: `/${user.locations[0]}/enquiries`,
                    caption: 'Manage Enquiries',
                },
            ],
        },

        {
            caption: 'Today',
            submenus: [
                {
                    path: '/collections',
                    caption: 'Teams Collections',
                },
            ],
        },

        {
            caption: 'Requests',
            submenus: [
                {
                    path: `/${user.locations[0]}/pending-requests`,
                    caption: 'Pending Requests',
                },

                {
                    path: `/${user.locations[0]}/allocated-requests`,
                    caption: 'Allocated Requests',
                },


                {
                    path: `/${user.locations[0]}/billing-requests`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivered-requests`,
                    caption: 'Delivered Requests',
                },
            ],
            // submenus: [{
            //   path: '/requests',
            //   caption: 'My Team'
            // },
            // ]
        },

        {
            caption: 'Bookings',
            submenus: [
                // {
                //   path: '/manage-bookings',
                //   caption: 'Bookings'
                // },

                {
                    path: `/${user.locations[0]}/all-bookings`,
                    caption: 'All Bookings',
                },
                {
                    path: `/${user.locations[0]}/pending-bookings`,
                    caption: 'Pending Bookings',
                },

                {
                    path: `/${user.locations[0]}/billing-done`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivery-done`,
                    caption: 'Delivered Bookings',
                },

                // {
                //   path: '/pending-bookings',
                //   caption: 'Pending Bookings'
                // },
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },

        // {
        //   caption: "Bookings",
        //   path: `/${user.locations[0]}/bookings`,
        // },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        render={() => (
                            <>
                                <NoticeBoard />

                                <TaskOverview steps={tlSteps} />

                                <PendingRequests userRole={'teamleader_id'} />
                            </>
                        )}
                    ></Route>

                    <Route exact path={'/allocations'} component={StockDashboard}></Route>

                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'admin'} />

                    <Route exact path={'/requests'} component={AllocationRequests}></Route>

                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />

                    {/* finance tracker */}
                    <Route exact path={`/finance-tracker`} component={(...routeParams) => <FinanceTracker {...routeParams} />} />
                    {/* finance tracker Ends */}

                    {/* <Route exact path={'/request/:id'} component={RequestDetail}></Route> */}

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />

                    {/* SM Requests */}
                    <Route
                        exact
                        path={'/sm-requests'}
                        render={() => {
                            return <AllocationRequests requested={true} />;
                        }}
                    ></Route>

                    {/* BookingTracker */}
                    <Route exact path={`/booking-tracker`} component={BookingTracker} />
                    {/* BookingTracker ends */}

                    {/* <PrivateRoute exact path="/:city/bookings" component={() => {

            return <GenericList
              schema={bookingSchema}
              model="bookings"
              queries={
                [{
                  field: 'Team Leader',
                  value: user.dms['Consultant Name']
                },
                ]}

              columns={bookingSchema.columns} />


          }} userRole={'admin'} /> */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Requests according to Queries */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/pending-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={[...requestSchema.pendingRequests, ...tlQueries]} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}



                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/allocated-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={[...requestSchema.allocatedRequests, ...tlQueries]} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/billing-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={[...requestSchema.billingRequests, ...tlQueries]} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Delivered Requests */}
                    <Route
                        exact
                        path={`/:city/delivered-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={[...requestSchema.deliveryRequests, ...tlQueries]} {...routeParams} />
                        )}
                    />
                    {/* Delivered Requests Ends */}

                    {/* Requests according to Queries Done */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/all-bookings`}
                        component={(...routeParams) => <GenericList dateField={'bookingDate'} schema={bookingSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={bookingSchema}
                                queries={[...bookingSchema.pendingBookings, ...userQueries]}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingSchema.columns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => (
                            <GenericList
                                queries={[...bookingSchema.billingQueries, ...userQueries]}
                                schema={bookingSchema}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingSchema.columns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList
                                queries={[...bookingSchema.deliveryQueries, ...userQueries]}
                                schema={bookingSchema}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingSchema.columns}
                                collection={'list'}
                            />
                        )}
                    />
                    <PrivateRoute exact path="/:city/enquiries" component={ManageEnquiries} userRole={'admin'} />

                    {/* Delivery Bookings Ends */}

                    {/*Delivery Plan*/}
                    {/* <Route exact path={'/plan-delivery'} component={}></Route> */}

                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/enquiries/:id" component={EnquiryDetail} userRole={'admin'} />

                    {/* <PrivateRoute exact path="/booking/:id" component={MyBookings} userRole={'admin'} /> */}

                    <PrivateRoute exact path="/manage-bookings" component={ManageBookings} userRole={'admin'} />

                    <PrivateRoute exact path="/collections" component={Collections} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/collections/:id" component={CollectionDetail} userRole={'admin'} />

                    <Route exact path={'/pending-list'} component={Collection}></Route>
                    <PrivateRoute exact path="/:city/report/:id" component={Report} userRole={'sm'} />

                    <TaskRoutes steps={tlSteps} list={TaskList} form={TaskForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
