
import React, { useState, useEffect, useContext } from 'react';

import {
    Checkbox,
    Modal,
    Button,
    Tag,
} from 'antd';

// import './reconcile-summary.scss';

import VoucherInfo from './../voucher-info/voucher-info';

import CollectionInfo from './../collection-info/collection-info';


/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function PendingCollection({ receipt = {}, collection = {}, callback, onSelect }) {
    const [visible, setVisible] = useState(false);

    return (
        <div>
            <div className="card collection-card">
                <div className="left">
                    <h2 className="title">
                        {collection.name} - <small>{collection.remarks}</small>
                    </h2>
                    <h4 className="title ">{collection.phone}</h4>
                    <Tag color="orange">{collection.type}</Tag>
                    <p>Card Number : {collection.cardNo}</p>
                    Created at {collection.created_at} by {collection.created_by_name}
                </div>

                <div className="right">
                    <div>
                        <h3 className="title amount">{collection.amount}</h3>

                        <VoucherInfo receipt={collection} />

                        {collection.verified === 'approved' ? <Tag color="success">Verified</Tag> : <Tag color="red">Unverified</Tag>}

                        {collection.invoice ? <Tag color="success">Receipt Completed</Tag> : <Tag color="red">Receipt Pending</Tag>}

                        {collection.accounts ? <Tag color="success">Accounts Completed</Tag> : <Tag color="red">Accounts Pending</Tag>}
                    </div>

                    <div>
                        <Button
                            onClick={() => {
                                setVisible(true);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            View
                        </Button>

                        {onSelect && (
                            <Checkbox checked={collection.selected} onChange={onSelect}>
                                Select
                            </Checkbox>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                destroyOnClose={true}
                title="Collection Detail"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <CollectionInfo collection={collection} />
            </Modal>
        </div>
    );
}