
import React, { useState, useEffect, useContext } from "react";

import { Row, Button, Tag, Badge } from "antd";

import { Link } from 'react-router-dom'


import { Tabs, Select } from 'antd';
import PlaceHolder from "../../../../components/ui_elements/PlaceHolder";

import "./customer-list.scss";

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

const { Option } = Select;

const { TabPane } = Tabs;


const documentOptions = [
    {
        label: 'Data Collected',
        value: 'Data Collected'
    },

    {
        label: 'Payment Pending',
        value: 'Payment Pending'
    },


    {
        label: 'Policy Received',
        value: 'Policy Received'
    }
]


export default function InsuranceCustomers({ step }) {

    const { user } = useContext(GlobalContext);

    const [filter, setFilter] = useState('all');

    const [allocations, setAllocations] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        getData(filter);

    }, [user])

    function getData(filter) {

        setLoader(true);

        const { locations } = user;

        var queries = [
            {
                field: 'insurance.process',
                value: 'incomplete'
            },
            {
                field: 'insurance.status',
                value: step.status
            }
        ]

        FirebaseUtils.getRequests(locations[0], step.queries).then((vehicles) => {

            setLoader(false);

            console.log(vehicles);

            setAllocations(vehicles.requests);

        });

    }

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="customer-list">

            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Pending Customers" key="1">

                    <div className="list-header">

                        <p className="page-desc">
                            {loader ? 'Loading data' : `Found ${Object.keys(allocations).length} results`}
                        </p>

                        <div>
                            <Button onClick={getData} type="secondary" size={'small'}>Refresh</Button>
                        </div>

                    </div>

                    <Row className="report-listing-wrapper">
                        {
                            loader
                                ?
                                (
                                    <PlaceHolder type="listing" />
                                )
                                :
                                (
                                    <>
                                        {
                                            (allocations).map((request) => {
                                                return <step.Card step={step} vehicle={request} index={request.id} key={request.id} />
                                            })
                                        }
                                    </>
                                )}
                    </Row>
                </TabPane>

            </Tabs>

        </section>
    );
}
