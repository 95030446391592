import React, { useEffect, useState, Fragment, useContext } from "react";

import { Button, Tag, Tabs, Image, Row, Select, Form, Input, Radio, message, Typography, Upload } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder'

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import RequestInfo from './../../../../components/request-info/request-info';
import MatchingReceipts from './../../../../components/matching-receipts/matching-receipts';

import { Bills } from "../../../../models"; 

import RequestForm from './../../../common/resources/request-form/request-form';

import './rto-form.scss';

import {
    CheckCircleOutlined,
} from '@ant-design/icons';


import VehicleInvoice from '../../../../templates/vehicle-invoice/vehicle-invoice';

import InvoicePreview from '../../../../templates/invoice-preview/invoice-preview';


import CommentBlock from '../../../common/components/comment-block/comment-block';


import { HighlightOutlined, SmileOutlined, SmileFilled, UploadOutlined } from '@ant-design/icons';

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }

    },
};

const documentOptions = [
    {
        label: 'Data Collected',
        value: 'dataCollected'
    },
    {
        label: 'Pending Payment',
        value: 'pendingPayment'
    },
    {
        label: 'RTO Received',
        value: 'rtoReceived'
    }
]

const { Option } = Select;

const { Paragraph, Title } = Typography;

const { TabPane } = Tabs;

const { TextArea } = Input;


export default function RTOForm({ step, ...props }) {

    const { user } = useContext(GlobalContext);

    const [booking, setBooking] = useState({ proof: {} });

    const [request, setRequest] = useState({ rto: {}, customer: {} });

    const { match } = props;

    const { params } = match;

    const { city, id } = params;

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        loadData();

    }, [])


    function loadData() {

        FirebaseUtils.getRequest(id).then((result) => {

            let data = { ...{ rto: {} }, ...result };

            console.log(data);

            setRequest(data);

            if (data.bookingNo) {

                getBooking(data.bookingNo)


            } else {

            }

            setLoader(false);

        });
    }

    function getBooking(id) {

        FirebaseUtils.getBooking(id).then((result) => {

            var booking = result.data();

            console.log(booking);

            if (booking) {

                setBooking({ proof: {}, ...booking, comments: [], isValid: true });

            } else {

                setBooking({ empty: true, isValid: false })

            }


            // #todo the booking object have to be removed from 

        });
    }

    return (
        <div className="rto-form allocation-form">

            {
                loader ?
                    <PlaceHolder type="report" />
                    :
                    <Fragment>

                        <div className='page-content'>

                            <h1 className="page-heading">
                                RTO Details
                            </h1>


                            <div className="split-container">

                                <div className="left">

                                    {/* Vehicle Card */}
                                    <RequestInfo request={request} />

                                </div>


                                <div className="right">



                                    {/* RTO Files */}


                                    <div className="vehicle-card">

                                        <Title level={3}>{step.caption}</Title>

                                        <p>
                                            After receiving the HSRP , Please update the below form.
                                        </p>


                                        <RequestForm
                                            formContent={request}
                                            modelIndex='requestId'
                                            model={step.model}
                                            step={step}
                                            callback={() => {

                                                props.history.goBack();

                                            }}
                                        />


                                    </div>

                                    {/* RTO Files Ends */}

                                    {/* Invoice */}
                                    <Invoice booking={booking} userRequest={request} />
                                    {/* Invoice  Ends */}

                                    {/* Insurance */}
                                    <Insurance request={request} />
                                    {/* Insurance  Ends */}


                                    {
                                        booking['Booking No'] ?
                                            <CustomerProof booking={booking} />
                                            :
                                            null
                                    }


                                    {/* Receipts */}
                                    <MatchingReceipts
                                        userRequest={request}
                                        callback={(total) => {

                                            // setReceived(total);

                                        }} />
                                    {/* Receipts Ends */}


                                    <CommentBlock id={request.id} />


                                </div>
                            </div>


                        </div>


                    </Fragment>}
        </div>
    )
}






function Invoice({ userRequest, booking }) {

    const { billing } = userRequest;

    const [billId, setBillID] = useState()

    const { invoice = [] } = billing;

    let queries = [
        
        {
            field: 'bookingNo',
            value: userRequest.bookingNo
        },
        {
            field: 'type',
            value: 'basic'
        },
        {
            field: 'cancellation_status',
            value: false
        }
    ]

    Bills.get(queries).then((result) => {
        let bill = result.bills[0]
        if (bill) {
            setBillID(bill.id)
        } else {
            setBillID(false)
        }

    })

    return (<>

        {
            billing && billing.invoice && billing.invoice ?
                <div className="vehicle-card">

                    <Tag icon={<CheckCircleOutlined />} color="success">
                        Invoice Done
                    </Tag>

                    <div gutter={0} className="detail-wrapper">

                        <div className="detail-element">
                            <Row span={12}>
                                <span>
                                    Invoice
                                </span>
                            </Row>
                            <Row span={12}>

                                {billId ?

                                    <VehicleInvoice id={billId} printable={true} booking={booking} request={userRequest} />
                                    
                                    : null }

                                {/* 
                                <h2 className="title">
                                    {
                                        billing.invoice.type === "application/pdf"
                                            ?
                                            <embed src={billing.invoice.url} width="500" height="375"
                                                type="application/pdf" />
                                            : <Image width={200} src={billing.invoice.url} />
                                    }
                                </h2> */}
                            </Row>
                        </div>
                    </div>
                </div>

                : null
        }
    </>)

}





function Insurance({ request }) {

    return (<>

        {
            request.insurance && request.insurance.attachment ?
                <div className="vehicle-card">

                    <Tag icon={<CheckCircleOutlined />} color="success">
                        Insurance Done
                    </Tag>

                    <ImageWrapper caption='Insurance' request={request} field='attachment' />


                </div>

                : null
        }
    </>)

}




function ImageWrapper({ caption, request, field }) {

    return (<>

        <div gutter={0} className="detail-wrapper">

            <div className="detail-element">
                <Row span={12}>
                    <span>
                        {caption}
                    </span>
                </Row>
                <Row span={12}>

                    <h2 className="title">
                        {
                            request.insurance[field].type === "application/pdf"
                                ?
                                <embed src={request.insurance[field].url} width="500" height="375"
                                    type="application/pdf" />
                                :
                                <Image width={200} src={request.insurance[field].url} />
                        }
                    </h2>
                </Row>
            </div>
        </div>


    </>)

}





function CustomerProof({ caption, booking = { proof: {} } }) {

    let proofs = Object.keys(booking.proof);

    return (<div className="vehicle-card card">

        <>

            <Title level={3}>{caption}</Title>

            {
                proofs && proofs.length
                    ?
                    <ImageContent content={booking.proof} images={proofs} type="array" />
                    :
                    <p>No proofs attached</p>
            }

        </>
    </div>)
}




function ImageContent({ type, images, content }) {


    return (<div className="vehicle-card card">

        <div gutter={0} className="detail-wrapper">

            <div className="detail-element">
                <div span={12}>

                    {
                        type === 'array' ?
                            <>

                                {
                                    images.map((file) => {


                                        return (<>

                                            <Title level={4}>
                                                {file}
                                            </Title>

                                            {content[file].type === "application/pdf"
                                                ?
                                                <embed src={content[file].url} width="500" height="375"
                                                    type="application/pdf" />
                                                :
                                                <Image width={200} src={content[file].url} />
                                            }
                                        </>)
                                    })
                                }

                            </>
                            :
                            <>

                                {
                                    images.type === "application/pdf"
                                        ?
                                        <embed src={images.url} width="500" height="375"
                                            type="application/pdf" />
                                        :
                                        <Image width={200} src={images.url} />
                                }

                            </>
                    }

                </div>
            </div>
        </div>
    </div>)
}
