


import React, { useState, useEffect, useContext } from 'react';


import {
    Tag,
} from 'antd';

// import './reconcile-summary.scss';



/**
 * Voucher
 */
export default function VoucherInfo({ receipt = { voucher: {} } }) {
    return (
        <>
            <Tag color="orange">Voucher :{receipt && receipt.voucher ? receipt.voucher.v_vno : '---'}</Tag>

            {/* <Tag color="blue">
                Voucher ID :
                {receipt && receipt.voucher
                    ? JSON.stringify(receipt.voucher.v_ids)
                    : '---'}
            </Tag> */}
        </>
    );
}
