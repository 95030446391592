import React, { useEffect, useState, Fragment, useContext, useRef } from 'react';

import {
    Typography,
    Button,
    Table,
    Popover,
    Popconfirm,
    message,
    Divider,
    Select,
    Space,
    Card,
    Statistic,
    InputNumber,
    Col,
    Row,
    Badge,
    Modal,
    Form,
    Input,
    Radio,
} from 'antd';

import { Link } from 'react-router-dom';

import { RollbackOutlined, CloseCircleOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../../Store';

import FirebaseUtils from '../../../../../utils/firebase.utils';

import useDeviceDetect from './../../../../../hooks/device-detect';

import { useHistory } from 'react-router';

import moment from 'moment';

import './generic-sales-return.scss';

import { useReactToPrint } from 'react-to-print';

import Rupee from './../../../../../components/rupee/rupee';

import { Bills, BillDetails, Settings } from './../../../../../models';

import Item from './../../../../../models/items';
import Summary from './../../../../../components/summary/summary';
import BillDetail from './../../../../../models/bill_details';
import { useForm } from 'antd/lib/form/Form';
import { Tooltip } from 'antd';

const { Title, Text } = Typography;

const { Option } = Select;

const { TextArea } = Input;

/**
 * Generic List
 *
 * @param {*} param0
 */
export default function GenericSalesReturn({
    match,
    schema = { custom_actions: [] },
    model = schema.model,
    columns = schema.columns || [],
    isDirect = schema.isDirect,
    disableUpload = schema.disableUpload,
    disableAdd = schema.disableAdd,
    disableSelect = schema.disableSelect,
    queries = schema.queries || [],
    actions = schema.actions || [],
}) {
    const [record, setRecord] = useState({ created_by: {}, attachments: [] });

    let category = match.params.category;

    let history = useHistory();

    const [loading, setLoading] = useState(true);

    const { isMobile } = useDeviceDetect();

    const { user } = useContext(GlobalContext);

    const [round, setRound] = useState();

    const [lastNumber, setLastNumber] = useState();

    const [returnBills, setReturnBills] = useState();

    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const [editCount, setEditCount] = useState(0);

    var tableRef = useRef(null);

    const [matching, setMatching] = useState([]);

    const [view, setView] = useState(isMobile || !!schema.Card);

    const { params } = match;

    const { id } = params;

    useEffect(() => {
        getRounding();
        loadData();
        loadLastNumber();
    }, []);

    /**
     * Get decimal rounding
     */

    const getRounding = () => {
        let query = [
            {
                field: 'name',
                value: 'calculation-rounding',
            },
        ];
        Settings.get(query).then((res) => {
            setRound(res.settings[0]);
        });
    };

    function loadLastNumber() {
        setInvoiceLoading(true);

        FirebaseUtils.getBillOrder(user.locations[0], `${category}-return`, moment().valueOf())
            .then((res) => {
                let id = res.prefix + res.post_fix + (res.last_number + 1);

                setLastNumber({ id: res.id, lastNumber: id, numberOnly: res.last_number + 1 });

                setInvoiceLoading(false);
            })
            .catch(() => {
                message.error('Getting Bill Number breaks, Try again! .');
            });
    }

    const cols = [
        ...[
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => index + 1,
                width: 50,
            },
            // {
            //     title: 'Part No',
            //     dataIndex: 'no',
            //     render: (text, record) => {
            //         return <div>{text ? record['no'] : record['Part No']}</div>;
            //     },
            // },
            {
                title: 'Part Number',
                dataIndex: 'product_identity_number',
                render: (text, record) => {
                    return (
                        <Fragment>
                            <div>
                                <div>{record['product_identity_number']}</div>
                            </div>
                        </Fragment>
                    );
                },
                width: 100,
            },

            {
                title: 'Part Name',
                dataIndex: 'item_name',
                render: (text, record) => {
                    return (
                        <Fragment>
                            <div>
                                <div>{text ? text : record['name']}</div>
                            </div>
                        </Fragment>
                    );
                },
                width: 200,
            },

            {
                title: 'Rate',
                dataIndex: 'rate',
                width: 100,
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                width: 100,
            },
            {
                title: 'Taxable Amount',
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ height: '20px' }}>{record.taxable_amount}</div>
                        </div>
                    );
                },
                width: 100,
            },
            {
                title: 'SGST',
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ height: '20px' }}>{record.sgst_amount}</div>
                            <div style={{ height: '20px', color: 'grey' }}>@{record.sgst_percent}%</div>
                        </div>
                    );
                },
                width: 100,
            },
            {
                title: 'CGST',
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ height: '20px' }}>{record.cgst_amount}</div>
                            <div style={{ height: '20px', color: 'grey' }}>@{record.cgst_percent}%</div>
                        </div>
                    );
                },
                width: 100,
            },
            {
                title: 'IGST',
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ height: '20px' }}>{record.igst_amount}</div>
                            <div style={{ height: '20px', color: 'grey' }}>@{record.igst_percent}%</div>
                        </div>
                    );
                },
                width: 100,
            },
            {
                title: 'Item Discount',
                render: (text, record) => {
                    return <div style={{ height: '20px' }}>{record.item_discount_amount}</div>;
                },
                width: 100,
            },
            {
                title: 'Net Amount',
                render: (text, record) => {
                    return <div style={{ height: '20px' }}>{record.net_amount}</div>;
                },
                width: 100,
            },
        ],
        ...[
            {
                title: 'Return',
                key: 'action',
                render: (text, record) => {
                    let qty = record.quantity;
                    let i = 1;
                    let quantities = [];
                    while (i <= qty) {
                        quantities.push(i);
                        i++;
                    }

                    return (
                        <Space size="middle">
                            <Select
                                defaultValue={record.return_quantity}
                                value={record.return_quantity}
                                style={{ width: 120 }}
                                onChange={(e) => returnHandler(e, record.id)}
                            >
                                {quantities.map((item, index) => (
                                    <Option value={item} key={index}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                    );
                },
            },
        ],
    ];

    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    function loadData() {
        setLoading(true);

        let bill;
        let billDetails;
        // Load data
        return Bills.getRecord(id)
            .then((result) => {
                bill = result;

                //Set actual bill if return bill
                if (result.transaction_type === 'sales-return') {
                    history.push(`/bill/${result.bill_id}`);
                    history.go(0);
                }

                setRecord(result);

                return result;
            })
            .then((bill) => {
                var queries = [
                    {
                        field: 'bill_id',
                        value: bill.id,
                    },
                ];

                if (bill.type === 'basic') {
                    return;
                } else {
                    return BillDetails.get(queries).then((result) => {
                        console.log(result);

                        billDetails = result['bill-details'];

                        billDetails = billDetails.map((item) => {
                            item.return_quantity = 0;
                            return item;
                        });

                        setMatching(billDetails);
                    });
                }
            })
            .then(() => {
                let query = [
                    {
                        field: 'bill_id',
                        value: bill.id,
                    },
                ];
                return Bills.get(query).then((result) => {
                    if (result.bills.length) {
                        let arr = [];
                        return Promise.all(
                            result.bills.map((b) => {
                                let qry = [
                                    {
                                        field: 'bill_id',
                                        value: b.id,
                                    },
                                ];
                                return BillDetails.get(qry).then((result) => {
                                    arr.push({ bill: b, details: result['bill-details'] });
                                });
                            })
                        ).then(() => {
                            //Avoid return items
                            if (arr.length) {
                                arr.map((item) => {
                                    billDetails = billDetails.map((a) => {
                                        let f = item.details.filter((bd) => bd.bill_detail_id === a.id);

                                        if (f.length) {
                                            a = {
                                                ...a,
                                                quantity: a.quantity - f[0].quantity,
                                            };

                                            return a;
                                        } else {
                                            return a;
                                        }
                                    });
                                });

                                setMatching(billDetails);
                            }
                            // setReturnBills(arr)
                            setLoading(false);
                        });
                    } else {
                        setLoading(false);
                        return;
                    }
                });
            });

        // Get the bill with id
        // .FirebaseUtils.getRecord('bills', id)
        // .then((result) => {});
    }

    console.log('list', matching);

    const returnHandler = (e, id) => {
        let item = matching.filter((item) => item.id === id);
        let index = matching.indexOf(...item);

        matching[index].return_quantity = e;

        setMatching([...matching]);
    };

    const removeCartHandler = (id) => {
        let item = matching.filter((item) => item.id === id);
        let index = matching.indexOf(...item);

        matching[index].return_quantity = 0;

        setMatching([...matching]);
    };

    //Submitting sales return
    function onReturnSubmit(values) {
        setLoading(true);

        let returnRecords = matching.filter((rc) => rc.return_quantity > 0);

        let formBody = {
            bill: record,
            selectedRecords: returnRecords,
            lastNumber: lastNumber,
            user: user,
            round: round.round,
            payment_method: values.payment_method,
            remarks: values.remarks,
            consumer_note: values.consumer_note,
        };

        console.log(formBody);

        FirebaseUtils.generateSalesReturn(formBody).then((result) => {
            history.push(`/bill/${result.data}`);
        });
    }

    //Update selected items
    const updateMatching = (values) => {
        setMatching([...values]);
        setEditCount((prev) => prev + 1);
        return;
    };

    console.log(matching);
    return (
        <Fragment>
            <div className="allocation-form bill-details">
                {loading ? (
                    <PlaceHolder type="report" />
                ) : (
                    <Fragment>
                        <Title level={2}>
                            <RollbackOutlined twoToneColor="#eb2f96" /> Sales Return
                        </Title>
                        <div className="page-content">
                            <div className="vehicle-card">
                                {/* <Badge color="#f50" text={record.status} /> */}
                                <>
                                    <div className="vehicle-card card">
                                        <CollectionInfo collection={record} matching={matching} />

                                        {loading ? (
                                            <PlaceHolder type="listing" />
                                        ) : (
                                            <>
                                                <>
                                                    {
                                                        // !view
                                                        //     ?
                                                        <Table
                                                            ref={(ref) => {
                                                                tableRef = ref;
                                                            }}
                                                            className="table-exportable"
                                                            loading={loading}
                                                            size="small"
                                                            // rowSelection={!disableSelect ? rowSelection : null}
                                                            // scroll={{ x: true, y: 750 }}

                                                            // scroll={{ x: true, y: 750 }}
                                                            rowKey={(record) => record.id}
                                                            // rowKey={record => record[model + 'Id']}
                                                            dataSource={matching.filter((b) => b.quantity > 0)}
                                                            columns={cols}
                                                            // onChange={handleTableChange}
                                                            pagination={false}
                                                        ></Table>
                                                        // :
                                                        // <CardList schema={schema} url={location} data={records[model]} />
                                                    }
                                                </>
                                            </>
                                        )}
                                    </div>
                                </>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
            {loading ? (
                <PlaceHolder type="report" />
            ) : (
                <ReturnList
                    matching={matching}
                    removeCartHandler={removeCartHandler}
                    bill={record}
                    onReturnSubmit={onReturnSubmit}
                    lastNumber={lastNumber}
                    round={round}
                    updateMatching={updateMatching}
                    editCount={editCount}
                />
            )}
        </Fragment>
    );
}

function CollectionInfo({ collection = { created_by: {}, attachments: [] } }) {
    const { user, defaultBranch } = useContext(GlobalContext);
    const print = useRef();

    const address =
        user.locations[0] == 'kozhikode'
            ? { add1: 'Kannur Rd, Athanikkal, West Hill,', add2: 'Kozhikode, Kerala 673005' }
            : user.locations[0] == 'kannur'
            ? { add1: 'Kairali Nagar, Thottada(PO),', add2: 'Kannur, Kerala 670007' }
            : { add1: 'N.H.213, Ramapuram, Panangangara,', add2: 'Malappuram, Kerala 679321' };

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const getDateAndTime = () => {
        let date = new Date();
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>{moment(date).format('DD MMM YYYY')}</div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    return (
        <div>
            {/* <div style={{ position: 'absolute', right: '3%' }}>
                <Button type="dashed" className="print-button" onClick={handlePrint}>
                    Print Invoice
                </Button>
            </div>
            <div style={{ position: 'absolute', right: '10%' }}>
                <Popconfirm
                    title="Are you sure you want to cancel this invoice?"
                    onConfirm={cancelInvoice}
                    okText="Yes"
                    cancelText="No"
                >
                <Button type="dashed" className="print-button">
                    Cancel Invoice
                </Button>
                </Popconfirm>
            </div> */}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: '15px' }}>
                <Col className="gutter-row" span={6}>
                    <div className="detail-element">
                        <Row span={12}>
                            <h3>Customer Name :</h3>
                        </Row>
                        <Row span={12}>
                            <h2 className="title">{collection.customer_name}</h2>
                        </Row>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="detail-element">
                        <Row span={12}>
                            <h3>Booking No :</h3>
                        </Row>
                        <Row span={12}>
                            <span>{collection.bookingNo}</span>
                        </Row>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="detail-element">
                        <Row span={12}>
                            <h3>Customer Id :</h3>
                        </Row>
                        <Row span={12}>
                            <span>{collection.customerID}</span>
                        </Row>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="detail-element">
                        <Row span={12}>
                            <h3>Amount :</h3>
                        </Row>
                        <Row span={12}>
                            <h2 className="title amount">{collection.net_amount}</h2>
                        </Row>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="detail-element">
                        <Row span={12}>
                            <h3>Invoice Number :</h3>
                        </Row>
                        <Row span={12}>
                            <span>{collection.invoice_number}</span>
                        </Row>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="detail-element">
                        <Row span={12}>
                            <h3>Created By :</h3>
                        </Row>
                        <Row span={12}>
                            <span>
                                {collection.authorised_by} At: {collection.created_at}
                            </span>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Divider orientation="left">
                <Title level={4}>Bill Details</Title>
            </Divider>
            <div>{/* <TableView data={matching} /> */}</div>
            <div className="print-block" style={{}}>
                <WrapperClass ref={print}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                borderBottom: '1px dashed #2f3e4c',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <div>
                                {/* <img className="logo-welcome" src={companyLogo} alt="Logo" /> */}
                                <Title level={3}>Invoice #{collection.invoice_number}</Title>
                            </div>
                            <div>
                                <div className="address-block">
                                    <div style={{ fontSize: '12px' }}>DKH Kia ,</div>
                                    <div style={{ fontSize: '10px' }}>{address['add1']} </div>
                                    <div style={{ fontSize: '10px' }}>{address['add2']}</div>
                                </div>
                                <div className="date-and-time" style={{}}>
                                    {getDateAndTime()}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '16px' }}>
                            <div style={{ flexBasis: '50%' }}>
                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Invoice Number</div>
                                <div>{collection.invoice_number}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Invoice Date</div>
                                <div style={{ fontSize: '12px' }}>{collection.created_at}</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '16px' }}>
                            <div style={{ flexBasis: '50%' }}>
                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Bill From</div>
                                <div>DKH MOTORS LLP</div>
                                <div>{collection.from_address}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Bill To</div>
                                <div style={{ fontSize: '12px' }}>{collection.customer_name ? collection.customer_name : collection.to_name}</div>
                                <div style={{ fontSize: '12px' }}>{collection.to_address}</div>
                            </div>
                        </div>
                        <div style={{ marginTop: '16px' }}>{/* <TableView data={matching} /> */}</div>
                    </div>
                </WrapperClass>
            </div>
        </div>
    );
}

function ReturnList({ matching, removeCartHandler, bill, onReturnSubmit, lastNumber, round, updateMatching, editCount }) {
    let total = 0;
    let qtyCheck = matching.filter((item) => item.return_quantity > 0);

    useEffect(() => {}, [matching], editCount);

    const form = useForm();

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const tailLayout = {
        wrapperCol: { span: 16 },
    };

    const [visible, setVisible] = useState(false);

    const [visibleDiscount, setVisibleDiscount] = useState(false);

    const [modalDiscountValue, setModalDiscountValue] = useState(false);

    let queries = [
        {
            field: 'name',
            value: 'net-amount-rounding',
        },
        {
            field: 'category',
            value: bill && bill.type,
        },
    ];
    let adjArr;
    // function for net amount rounding
    function coinAdjustment(amount, roundValue) {
        Settings.get(queries).then((result) => {
            //rounding net amount
            let roundValue = result && result.settings.length && result.settings[0].round_value;
            let decArr = [];

            decArr[0] = amount;
            decArr[1] = 0;

            if (roundValue > 0 && (amount * 100) % roundValue !== 0) {
                decArr[0] = amount * 100;
                decArr[0] = Math.round(decArr[0] / roundValue) * (roundValue / 100);
                decArr[1] = parseFloat((decArr[0] - amount).toFixed(2));
            }

            return decArr;
        });
    }
    //form modal handler
    const handleModal = () => {
        setVisible(true);
    };

    const onFinish = (values) => {
        onReturnSubmit(values);
        setVisible(false);
    };

    const handleDiscountModal = (id) => {
        let item = matching.filter((i) => i.id === id);
        console.log(item);
        setModalDiscountValue({ id: id, value: item[0].return_bill_discount });

        setVisibleDiscount(true);
    };

    const onEditDiscount = (value) => {
        let patchMatch = matching.map((m) => {
            if (m.id === modalDiscountValue.id) {
                m.edit_discount = value.return_bill_discount;
            }
            return m;
        });
        console.log(patchMatch);

        updateMatching(patchMatch);

        setVisibleDiscount(false);
    };

    return qtyCheck.length > 0 ? (
        <Fragment>
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Sales Return"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Form {...layout} name="basic" onFinish={onFinish}>
                    <Form.Item
                        name={'payment_method'}
                        label="PaymentMethod"
                        rules={[
                            {
                                message: 'Please select payment method',
                                required: true,
                            },
                        ]}
                    >
                        <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                            {['Cash', 'Cheque', 'Debit Card', 'Credit Card', 'Net Banking', 'Other'].map((model, key) => {
                                return (
                                    <Option value={model} key={key}>
                                        {model}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Remarks"
                        name="remarks"
                        rules={[
                            {
                                message: 'Please enter remarks',
                                required: true,
                            },
                        ]}
                    >
                        <TextArea style={{ width: '100%' }} placeholder="Remarks" />
                    </Form.Item>
                    <Form.Item label="Consumer Note" name="consumer_note">
                        <TextArea style={{ width: '100%' }} placeholder="Consumer Note" />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Modal For add/discount */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Edit Discount"
                visible={visibleDiscount}
                okText="Okay"
                onOk={() => {
                    setVisibleDiscount(false);
                }}
                onCancel={() => {
                    setVisibleDiscount(false);
                }}
            >
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onEditDiscount}
                    initialValues={{
                        return_bill_discount: modalDiscountValue.value,
                    }}
                >
                    <Form.Item
                        name={'return_bill_discount'}
                        label="Bill Discount"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter an discount value',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            {/* Modal END add/discount */}
            <div className="allocation-form bill-details">
                <div className="page-content">
                    <div className="vehicle-card card">
                        <Row span={24}>
                            <Col span={6}>
                                <Title level={4}>Return Summary</Title>
                            </Col>

                            <Col span={18} style={{ textAlign: 'right' }}>
                                <Title level={5}>Invoice # {lastNumber.lastNumber}</Title>
                            </Col>
                        </Row>
                        {matching.map((item) => {
                            let detailed = BillDetails.salesReturnDetailedPricing(item, bill, round.round);

                            total += detailed.return_net_amount;
                            return item.return_quantity > 0 ? (
                                <Card
                                    type="inner"
                                    style={{ marginBottom: 16 }}
                                    title={detailed.item_name}
                                    extra={
                                        <Fragment>
                                            <Button onClick={() => handleDiscountModal(detailed.id)} type="dashed">
                                                Edit Discount
                                            </Button>
                                            <Button
                                                onClick={() => removeCartHandler(detailed.id)}
                                                icon={<CloseCircleOutlined style={{ color: '#FF0000', marginTop: '4px' }} />}
                                                type="dashed"
                                            >
                                                Remove
                                            </Button>
                                        </Fragment>
                                    }
                                >
                                    <Row gutter={24}>
                                        <Col span={4}>
                                            <Statistic title="Rate" value={detailed.rate} valueStyle={{ fontSize: 20, fontWeight: 600 }} />
                                        </Col>
                                        <Col span={4}>
                                            <Statistic
                                                title="Quantity"
                                                value={detailed.return_quantity}
                                                valueStyle={{ fontSize: 20, fontWeight: 600 }}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Statistic
                                                title="Taxable"
                                                value={detailed.return_taxable_amount}
                                                valueStyle={{ fontSize: 20, fontWeight: 600 }}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Statistic
                                                title="Item Discount"
                                                value={detailed.return_item_discount.toFixed(2)}
                                                valueStyle={{ fontSize: 20, fontWeight: 600 }}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Statistic
                                                title="Bill Discount Applied"
                                                editable
                                                value={detailed.return_bill_discount.toFixed(2)}
                                                valueStyle={{ fontSize: 20, fontWeight: 600 }}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Statistic
                                                title="Total"
                                                value={detailed.return_net_amount.toFixed(2)}
                                                valueStyle={{ fontSize: 20, fontWeight: 600 }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            ) : null;
                        })}
                        <Row gutter={24}>
                            <Col span={16}></Col>
                            <Col span={4}>
                                <Statistic title="Total Amount" value={total} />
                            </Col>
                            <Col span={4}>
                                <Button type="primary" onClick={handleModal}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Fragment>
    ) : null;
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}
