import React, { useEffect, useState, useContext, useRef } from 'react';

import { Typography, Button, Skeleton, Radio, Row, Col, Space, Alert } from 'antd';

//import './vehicle-invoice.scss';

import { Cart, InvoiceNumbers, Bills, BillDetails, Vehicles, Branches, Purchases } from '../../models';

import { DateDisplay } from '../../components';

import { GlobalContext } from '../../Store';

import { useReactToPrint } from 'react-to-print';

import moment from 'moment';
import { cityKeys } from '../../modules/global-config';

const { Title } = Typography;

var companyLogo = require('../../assets/images/kia-logo.png');

export default function PayoutInvoice({ id, printable, matching }) {
    const [loading, setLoading] = useState(false);

    const { user } = useContext(GlobalContext);

    const [bill, setBill] = useState({});

    const [branch, setBranch] = useState();

    const [data, setData] = useState({ items: [] });

    const [vehicle, setVehicle] = useState({});

    const [lastNumber, setLastNumber] = useState({});

    const [hsncode, setHsncode] = useState();

    const print = useRef();

    var cityKey = cityKeys

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    useEffect(() => {
        if (matching[0]) {
            loadBranch();

            loadBillData();

            setLoading(true);
        } else {
            setLoading(false);
        }

        // prepareBill();

        return () => {};
    }, []);

    // For brach details to add invoice
    const loadBranch = () => {
        var queries = [
            {
                field: 'dealer_code',
                value: cityKey[matching[0].city],
            },
        ];

        Branches.get(queries).then((result) => {
            setBranch(result.branches);
        });
    };

    function loadBillData() {
        Bills.getRecord(matching[0].bill_id).then((result) => {
            var payout_bill = result;

            setBill(payout_bill || {});

            if (payout_bill && payout_bill.id) {
                var bill_id = payout_bill.id;

                BillDetails.getPayoutItems({ bill_id }).then((result) => {
                    setData(result);

                    // getVehicle().then(() => {
                    setLoading(false);
                    // });
                });
            } else {
                setLoading(false);
            }
        });
    }

    console.log(bill);

    return (
        <>
            {printable ? (
                <Button type="dashed" className="print-button" onClick={handlePrint}>
                    Print Invoice
                </Button>
            ) : null}
            <div className={`vehicle-invoice ${printable ? 'scale' : ''}`} ref={print}>
                {loading ? (
                    <Skeleton />
                ) : (
                    <>
                        {bill.invoice_number ? (
                            <>
                                <>
                                    <div className="bill-header">
                                        <img className="logo-welcome" src={companyLogo} alt="Logo" />
                                        <div>
                                            <p>{branch && branch[0].address_line_1}</p>
                                            <p>{branch && branch[0].address_line_2}</p>
                                            <p>{branch && branch[0].state}</p>
                                            <p>{branch && branch[0].postal_code}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <Title className="headline">Payout Invoice</Title>
                                    <div className="bill-details">
                                        <div className="left">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="detail-element">Dealer GST No </td>
                                                        <td>: {branch && branch[0].gst_number}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="detail-element">Bill To Name</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.customer_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Bill To Address</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.customer_address}</td>
                                                    </tr> */}
                                                    {/* <tr>
                                                        <td className="detail-element">Ship To Name</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.ship_to_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Ship To Address</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.ship_to_address}</td>
                                                    </tr> */}
                                                    {/* {bill.ship_to_postal_code ? (
                                                        <tr>
                                                            <td className="detail-element">Ship To Postal Code</td>
                                                            <td>: {bill.ship_to_postal_code}</td>
                                                        </tr>
                                                    ) : null} */}
                                                    {/* <tr>
                                                        <td className="detail-element">Financed by/Hypo. by</td>
                                                        <td>: {request.finance && request.finance.bank}</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="right">
                                            <table>
                                                <tbody>
                                                    {/* <tr>
                                                        <td className="detail-element">Customer GST No </td>
                                                        <td>: {bill.gst_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Customer Id</td>
                                                        <td>: {bill.customerID}</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td className="detail-element">Invoice No</td>
                                                        <td>: {bill.invoice_number}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="detail-element">Invoice Date </td>
                                                        <td>
                                                            : <DateDisplay value={bill.invoice_date_millisecond} />
                                                        </td>
                                                    </tr> */}
                                                    {/*  <tr>
                                                        <td className="detail-element">Phone No</td>
                                                        <td>: {bill.customer_phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">PAN No</td>
                                                        <td>: {bill.pan_number} </td>
                                                    </tr> */}
                                                    <tr>
                                                        <td className="detail-element">Place of Supply</td>
                                                        <td>: {bill.customer_state}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <table className="bill-content">
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                <th className="title text-left">PARTICULARS</th>
                                                <th className="title amount">Actual Payout(Rs)</th>
                                                <th className="title amount">CGST(%)</th>
                                                <th className="title amount">SGST(%)</th>
                                                <th className="title amount">Final Payout</th>
                                            </tr>

                                            {/* Basic Vehicle Price */}

                                            {data.res.map((record, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}.</td>

                                                        {record.variant ? (
                                                            <td>
                                                                {record.variant} for {record.customerName}- {record.customerID}
                                                            </td>
                                                        ) : (
                                                            <td>{record.attributes.remarks}</td>
                                                        )}

                                                        {/* <td>{record.payout}</td> */}

                                                        <td>{record.actual_payout}</td>

                                                        <td>{record.cgst}</td>

                                                        <td>{record.sgst}</td>

                                                        <td>{record.final_payout}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    <table className="bill-summary">
                                        <tbody>
                                            <tr>
                                                <td className="title text-left">TOTAL</td>
                                                <td className="title text-right">{data.total}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* <div className="vehicle-particulars">
                                        <p>Vehicle Particulars</p>
                                        <table>
                                            <tbody>
                                                <td>
                                                    <div className="details-box">
                                                        <span>Vin No.</span>
                                                        <span>mm</span>
                                                    </div>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div> */}
                                    <div className="footer">
                                        <p>"Terms & condition apply"</p>
                                        <h3>Applied Promotion:</h3>
                                        <div className="signature">
                                            <div className="">
                                                <div className="sign-box"></div>
                                                <h4>Signature of Customer</h4>
                                            </div>
                                            <div className="">
                                                <p>For DKH Motors LLP</p>
                                                <div className="sign-box"></div>
                                                <h4>Authorised Signatory</h4>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </>
                        ) : (
                            <p>Invoice unavailable.</p>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
