import React, { useEffect, useState, useContext } from 'react';

import { Tabs, Typography, Tag, Badge, Button, message, Alert, Select } from 'antd';

import './matching-bookings.scss';

import { GlobalContext } from './../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import moment from 'moment';

import { CheckCircleOutlined } from '@ant-design/icons';
import { cityKeys, dealerCodeKey } from '../../../global-config';

var dealer = dealerCodeKey

var badgeColor = {
    Allocated: 'error',
    'In transit': 'warning',
    'Free Stock': 'success',
};

const { Option } = Select;

const { TabPane } = Tabs;

const { Title, Text } = Typography;

var cityKey =cityKeys

export default function MatchingBookings({ url }) {
    const [filter, setFilter] = useState(['Live', 'Urgent']);

    let arrStatus = [ ];

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [stock, setStock] = useState({ vehicles: {}, lastUpdated: {} });

    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        loadData();

        return () => {};
    }, []);

    function loadData() {
        setLoading(true);

        if (url === 'all') {
            var q = [
                {
                    field: 'Stock Status',
                    operator: 'in',
                    value: ['Free Stock', 'In Transit'],
                },
            ];

            FirebaseUtils.getAllVehicles(q).then((result) => {
                setLoading(false);

                var vehicles = result.all.filter((vehicle) => !vehicle.allocated && vehicle.update !== 'remove');

                console.log(vehicles);
            });
        } else {
            var queries = [
                {
                    field: 'Stock Status',
                    operator: 'in',
                    value: ['Free Stock', 'In Transit'],
                },
            ];

            FirebaseUtils.getAllVehicles(queries).then((result) => {
                // var { vehicles } = result;
                const vehicles = result.all.filter((vehicle) => !vehicle.allocated && vehicle.update !== 'remove');

                setStock({ vehicles: vehicles });

                var queries = [
                    {
                        field: 'billing.status',
                        value: 'pending',
                    },
                ];

                FirebaseUtils.getBookings(url, queries).then((result) => {
                    console.log(result);

                    setLoading(false);

                    var b = result.bookings
                        .filter((booking) => {
                            return !booking.isCancelled && !booking.requestId;
                        })
                        .map((entry) => {
                            let booking = entry;

                            let matching = vehicles.filter((vehicle) => {
                                return vehicle['Exterior Color Name'] === booking['Color'] && vehicle['Variant'] === booking['Variant'];
                            });

                            booking.matching = matching;

                            return booking;
                            // return result[entry];
                        })
                        .sort((a, b) => {
                            if (a.matching < b.matching) {
                                return 1;
                            }
                            if (a.matching > b.matching) {
                                return -1;
                            }
                            return 0;
                        })
                        .sort((a, b) => {
                            var aDays = moment().diff(moment(a['Booking Date'], 'DD/MM/YYYY'), 'days');

                            var bDays = moment().diff(moment(b['Booking Date'], 'DD/MM/YYYY'), 'days');

                            if (aDays < bDays) {
                                return 1;
                            }

                            if (aDays > bDays) {
                                return -1;
                            }
                            return 0;
                        })
                        .filter((booking) => booking.matching.length);

                    console.log(b);

                    setBookings(b);
                });
            });
        }
    }

    /**
     *
     * Allocate already existing booking to
     * the team leader
     * Team leader has to verify after which the finance process woudl
     * start
     *
     * @param {*} param0
     */
    function createRequest({ vehicle, booking, index }) {
        let city = user.locations[0];

        // Find the city of the booking
        let bookingCity = dealer[booking['Dealer Code']];

        let customer = {
            customerName: booking['Name of the Customer'],
            phone: booking['Contact Number'],
            // place: booking['Ship To Add'],
            customerID: booking['Customer ID'],
        };

        let newBooking = {
            status: 'Verification Pending',
            kec: booking['Consultant Name'],
            teamLeader: booking['Team Leader'],
            dealerCode: cityKey[city],

            // ...preference, // preference is considered here , not the booking choice .
            city: bookingCity, // override the city in the preference with the one in the booking
            ...{
                bookingNo: booking['Booking No'],
                created_by: user,
            },
            ...{
                customer: customer,
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                // expectedDate: booking['Committed Delivery Date']
            },
            deleted_at:null,
            deleted_by:null
        };

        // If its for a different location , we have to add a field to identify that
        // if (city !== preference.city) {

        //     newBooking.destination_city = preference.city

        // }

        if (booking['Committed Delivery Date']) {
            newBooking.expectedDate = booking['Committed Delivery Date'];
        }

        FirebaseUtils.addRequest(newBooking).then((result) => {
            FirebaseUtils.updateBooking(booking['Booking No'], true, result.id).then(() => {
                bookings[index].requestId = result.id;

                setBookings(bookings);

                loadData();

                message.info('Request created');
            });
        });
    }

    let finalBookings = bookings.filter((booking) => {
        if (booking.current && booking.current.currentStatus) {
            return filter.indexOf(booking.current.currentStatus) !== -1;
        } else {
            return true;
        }
    });

    return (
        <section className="matching-bookings">
            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <div className="page-header">
                        <p>
                            <small>{finalBookings.length} Possible Allocations</small>
                        </p>

                        <div className="action">
                            <Button onClick={loadData} type="secondary" size={'small'}>
                                Refresh
                            </Button>

                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '200px' }}
                                placeholder="Please select"
                                defaultValue={['Live', 'Urgent']}
                                onChange={(item) => {
                                    console.log(item);
                                    setFilter(item);
                                }}
                                // onChange={handleChange}
                            >
                                {arrStatus.map((item, index) => (
                                    <Option key={item.value}>{item.value}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <Alert
                        message="Mission Zero"
                        description="Below are all the existing bookings and matching vehicles in stock accross group."
                        type="success"
                        showIcon
                        closable
                    />

                    {finalBookings.map((booking, index) => {
                        return (
                            <BookingCard
                                index={index}
                                vehicles={stock.vehicles}
                                booking={booking}
                                createRequest={({ vehicle, booking }) => {
                                    createRequest({ vehicle, booking, index });
                                }}
                            />
                        );
                    })}
                </>
            )}
        </section>
    );
}

function BookingCard({ vehicles, booking, createRequest, index }) {
    return (
        <div className="card booking-card" key={index}>
            <div className="left">
                <Title level={4}>{booking['Name of the Customer']}</Title>

                <h4 className="title">{booking['Contact Number']}</h4>

                <div>
                    <h3 className="title variant">{booking['Variant']}</h3> | <small> {booking['Color']}</small>
                </div>

                <h4>
                    {booking['Booking No']} - <Tag color="#2db7f5">{dealer[booking['Dealer Code']]}</Tag>
                </h4>

                <h4>
                    KEC : {booking['Consultant Name']} | Team Leader : {booking['Team Leader']}
                </h4>

                <p className="">
                    Booked on {booking['Booking Date']},{' '}
                    <Text type="danger">{`${moment().diff(moment(booking['Booking Date'], 'DD/MM/YYYY'), 'days')} days ago.`}</Text>
                </p>

                {booking.current && booking.current.currentStatus ? (
                    <>
                        <Tag color="magenta">{booking.current.currentStatus}</Tag>

                        <p className="recent-remarks">Recent Remarks : {booking.current.remarks}</p>
                    </>
                ) : (
                    <>
                        <p className="recent-remarks">{booking.current ? booking.current.currentStatus : 'No recent updates'}</p>
                    </>
                )}

                {booking['Committed Delivery Date'] ? <p>Committed Delivery Date {booking['Committed Delivery Date']}</p> : null}
            </div>

            <Title level={5}>Matching Vehicles</Title>

            <div className="vehicle-list">
                {booking.matching.map((vehicle, key) => {
                    {
                        /* let vehicle = vehicles[vin]; */
                    }

                    return (
                        <VehicleCard
                            key={key}
                            vehicle={vehicle}
                            block={() => {
                                createRequest({ vehicle, booking });
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}

function VehicleCard({ vehicle, unblock, block }) {
    const { user } = useContext(GlobalContext);

    return (
        <div className="vehicle-card allocation-request">
            <div className="left">
                <h4 className="title ">
                    {vehicle['Variant']}
                    <span>{` [ ${vehicle['Exterior Color Name']} ] `}</span>
                </h4>

                <div>{`${vehicle['Vin Number']}`}</div>

                <small className="status">
                    <Badge status={badgeColor[vehicle['Stock Status']]} />

                    {vehicle['Stock Location'] || vehicle['Stock Status']}
                </small>
            </div>
            <div className="right card-buttons">
                {vehicle.allocated && vehicle.allocated.customer ? (
                    <div>
                        {/* {['admin', 'sm'].indexOf(user.role) !== -1 ? <Button onClick={() => { unblock(vehicle) }} type="secondary">Unblock</Button> : null} */}

                        <Tag icon={<CheckCircleOutlined />} color="success">
                            Blocked
                        </Tag>
                    </div>
                ) : (
                    <div>
                        {/* 
                        {['admin', 'sm', 'rm'].indexOf(user.role) !== -1 ?
                         <Button onClick={() => { block(vehicle) }} type="secondary">

                            Create Request
                        </Button> : null} */}
                    </div>
                )}
            </div>
        </div>
    );
}
