import React, { useState, useContext } from 'react';

import { GlobalContext } from './../../../../../../Store';

import { Button, Alert } from 'antd';

import { Form, Input } from 'antd';

import AsyncSelect from 'react-select/async';

import ApiUtils from '../../../../../../utils/api.utils';

import { BankCredits } from '../../../../../../models';

import CollectionInfo from './../collection-info/collection-info';

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

/**
 *
 * Card for showing Pending Customer Collections ,
 * Same has to be reconciled against any bank entry.
 * Matching Amount Entry
 *
 * @param {*} param0
 */
export default function PendingReceipt({ actualReceipt = {}, collection = {}, callback, allDepartments }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const { user, dispatch } = useContext(GlobalContext);

    /**
     *
     * On Submit of Form ,
     * Matching Receipt
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        // If its booking ,
        // we have to create account head

        // This same process has to be done for verifying on
        // Customer receipts detail page ,
        // Here ,
        // For Booking , we create account head first ,
        //

        // const hide = message.loading('Updating accounts entry, Please wait', 0);
        setLoading(true);

        BankCredits.reconcileBankCredit(values, collection, actualReceipt, user, callback, 'RP:S').then(() => {
            setLoading(false);
        });
    };

    /**
     * Load the matching heads
     */
    // function loadHeads(query) {
    //     return ApiUtils.loadHeads(collection.department, query).then((result) => {
    //         return result.map((item) => {
    //             return {
    //                 value: item.ac_code,
    //                 label: item.ac_desc,
    //             };
    //         });
    //     });
    // }

    return (
        <div className="receipt-card">
            <div className="card">
                <CollectionInfo collection={collection} />

                {/* Show Alert If its other branch */}

                {collection.city !== actualReceipt.city ? (
                    <>
                        <Alert
                            message={`Please note that this is a credit for ${actualReceipt.city} while you are trying to verify a ${collection.city} record. Your current branch should be same as ${collection.city} to verify a Sales team collection of the city.`}
                            type="error"
                            showIcon
                            closable
                        />
                    </>
                ) : null}

                <Form
                    form={form}
                    {...layout}
                    name="new-record"
                    layout="vertical"
                    onFinish={onFinish}
                    // validateMessages={validateMessages}
                    initialValues={{}}
                >

                    {/**Not required without cs */}
                    {/* {collection.account !== 'BOOKING' ? (
                        <>
                            <Form.Item
                                name={'ac_code'}
                                label="Account Head"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Account Head',
                                    },
                                ]}
                            >
                                <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} />
                            </Form.Item>
                        </>
                    ) : null} */}

                    <Form.Item name={'verificationRemarks'} label="Remarks" rules={[{ required: true, message: 'Please enter Remarks' }]}>
                        <Input />
                    </Form.Item>

                    <Button
                        // disabled={selectedDepartment.location !== collection.city}
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="submit-button"
                    >
                        SUBMIT
                    </Button>
                </Form>
            </div>
        </div>
    );
}
