import React, { useEffect, useContext } from 'react';

import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

import Receipts from '../accounts/components/receipts/receipts';
import ReceiptForm from '../accounts/components/receipt-form/receipt-form';

import Dashboard from '../common/components/dashboard/dashboard';

import IFStatus from '../common/components/if-status/if-status';

import VehicleOverview from '../common/resources/vehicle-overview/vehicle-overview';

import MonthWiseRetail from '../common/resources/monthwise-retail/monthwise-retail';

// Accounts Overview
import AccountsOverview from '../common/resources/accounts-overview/accounts-overview';

import ManageStock from '../common/components/manage-stock/manage-stock';

import FinanceTracker from '../common/resources/finance-tracker/finance-tracker';

import VerifyPayment from '../common/components/verify-payment/verify-payment';

import ManagePayments from '../common/components/manage-payments/manage-payments';
import BankUpload from '../common/components/manage-payments/bank-upload';

import ReconcileSummary from '../common/components/reconcile-summary/reconcile-summary';
import ChangePassword from '../common/components/change-password/change-password';

import CustomerReceipts from '../common/components/customer-receipts/customer-receipts';
import CustomerReceiptDetail from '../common/components/customer-receipts/customer-receipts-detail';
import CustomerReceiptAdd from '../common/components/customer-receipts/customer-receipts-add';

import Cancellations from '../common/components/cancellations/cancellations';
import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import RequestOverview from '../common/resources/request-overview/request-overview';

import CustomerFiles from '../accounts/components/customer-files/customer-files';
import CustomerPayment from '../accounts/components/customer-payment/customer-payment';

import RequestDetail from '../common/components/request-detail/request-detail';

import FundGap from '../accounts/components/fund-gap/fund-gap';

import CompareSheets from '../accounts/components/compare-sheets/compare-sheets';

import CompareGst from '../accounts/components/compare-gst/compare-gst';

// import AccountsDashboard from './../common/resources/accounts-dashboard/accounts-dashboard';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import BookingDetail from '../common/components/booking-detail/booking-detail';

import Profile from '../common/resources/profile/profile';

import BillDetails from '../common/resources/finance-payout-tracker/bill-details';

// Tasks

import { accountSteps, pricingSchema } from '../accounts/accounts-config';

import TaskOverview from '../common/resources/task-overview/task-overview';

import TaskRoutes from '../common/resources/task-routes/task-routes';

import ModuleRoutes from '../common/resources/modules-routes/module-routes';

import { billColumns, billActions, payoutSchema, requestSchema, billSchema, stockTransferSchema, stockRegisterSchema } from '../global-config';

import StockRegister from '../common/resources/stock-register/stock-register';

import ReconcileOverview from '../common/resources/reconcilation-overview/reconcilation-overview';

import NoticeBoard from '../common/components/notice-board/notice-board';

import { BillPreview } from '../../components/bill-preview/bill-preview';

import '../accounts/accounts-landing';

import { GlobalContext } from '../../Store';

import { ServiceBill } from '../../components/service-bill/service-bill';

import { ItemWiseSchema } from '../global-config';

import { itemActions } from '../global-config';

import BillList from '../common/resources/bill-list/bill-list';
import VehicleServiceBill from '../common/resources/finance-payout-tracker/vehicle-service-bill';

import SalesReport from '../common/resources/bill-list/sales-report';

export default function ServiceAccountsLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    const modules = [
        {
            caption: 'Reconcilation',
            submenus: [
                {
                    path: '/accounts-overview',
                    caption: 'Accounts Overview',
                },

                {
                    path: '/pending-summary',
                    caption: 'Pending to Reconcile',
                },

                {
                    path: '/bank-receipts',
                    caption: 'Bank Statement',
                },

                {
                    path: '/customer-receipts',
                    caption: 'Customer Receipts',
                },
            ],
        },

        {
            caption: 'Sales Report',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Accessories Bills',
                                path: `/service/${location}/Accessories/bills`,
                            },
                            {
                                caption: 'VAS Bills',
                                path: `/service/${location}/VAS/bills`,
                            },
                            {
                                caption: 'After Market Bills',
                                path: `/service/${location}/After Market/bills`,
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Service Billing',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Accessory Billing',
                                path: `/${location}/service/bill/Service/Accessories`,
                            },
                            {
                                caption: 'VAS Billing',
                                path: `/${location}/service/bill/Service/VAS`,
                            },
                            {
                                caption: 'After Market Billing',
                                path: `/${location}/service/bill/Service/After Market`,
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => {
                            return (
                                <>
                                    <NoticeBoard />

                                    {/* <Dashboard steps={updatedAccountSteps} /> */}

                                    {/* <TaskOverview steps={updatedAccountSteps} /> */}
                                </>
                            );
                        }}
                    ></Route>

                    {/* finance tracker */}
                    <Route exact path={`/finance-tracker`} component={(...routeParams) => <FinanceTracker {...routeParams} />} />
                    {/* finance tracker Ends */}

                    <Route exact path={`/:department/:city/:category/bills`} component={(...routeParams) => <SalesReport {...routeParams} />} />

                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    {/* <PrivateRoute exact path="/manage-requests" component={ManageRequests} userRole={'admin'} /> */}

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/compare-sheets" component={CompareSheets} userRole={'admin'} />

                    <PrivateRoute exact path="/compare-gst" component={CompareGst} userRole={'admin'} />

                    <Route exact path="/bill/:id" component={BillDetails} userRole={'admin'} />

                    <Route
                        exact
                        path="/bank-receipts"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <ManagePayments department={['Department Handling Services']} {...routeParams} />;
                        }}
                    />

                    <Route
                        exact
                        path="/bank-receipts/upload"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <BankUpload department={['Department Handling Services']} {...routeParams} />;
                        }}
                    />

                    <Route
                        exact
                        path="/pending-summary"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <ReconcileSummary department={['Department Handling Services']} {...routeParams} />;
                        }}
                    />

                    <Route
                        exact
                        path="/:city/service/bill/:department/:category"
                        component={({ ...routeParams }) => <ServiceBill key={routeParams} {...routeParams} />}
                        userRole={'admin'}
                    />

                    {/* <PrivateRoute exact path="/verify-payment" component={VerifyPayment} userRole={'admin'} /> */}
                    {/* Receipts for CS Accounts */}
                    <PrivateRoute exact path="/receipts" component={Receipts} userRole={'admin'} />
                    <PrivateRoute exact path="/receipt/:city/:id" component={ReceiptForm} userRole={'accounts'} />
                    {/* Receipts for CS Accounts Ends */}

                    <Route
                        exact
                        path={`/:city/bills`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={billSchema}
                                actions={billActions}
                                columns={billColumns}
                                {...routeParams}
                                dateField={'created_date'}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/other-credits"
                        render={() => {
                            return <Receipts otherEntry={true} />;
                        }}
                        userRole={'admin'}
                    />

                    <Route
                        exact
                        path={`/:city/:category/bills`}
                        component={(...routeParams) => (
                            <BillList schema={billSchema} actions={billActions} {...routeParams} dateField={'created_date'} />
                        )}
                    />

                    <Route
                        exact
                        path={`/:city/:category/items`}
                        component={(...routeParams) => (
                            <BillList schema={ItemWiseSchema} actions={itemActions} {...routeParams} dateField={'created_date'} />
                        )}
                    />

                    <Route
                        exact
                        path="/service/bill/:department/:category"
                        component={({ ...routeParams }) => <ServiceBill key={routeParams} {...routeParams} />}
                        userRole={'admin'}
                    />

                    {/* Bill details Ends */}
                    {/* Collections Module */}
                    {/* <PrivateRoute exact path="/collections" component={Collections} userRole={'admin'} /> */}
                    {/* <PrivateRoute exact path="/:city/collections/:id" component={CollectionDetail} userRole={'admin'} /> */}
                    {/* <PrivateRoute exact path="/collections/add" component={CollectionsAdd} userRole={'admin'} /> */}
                    {/* Collections Module Ends */}

                    <Route
                        exact
                        path="/accounts-overview"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <AccountsOverview department={['Department Handling Services']} {...routeParams} />;
                        }}
                    />

                    {/* Stock Register Ends */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    {/* Customer Receipts Module */}
                    <Route
                        exact
                        path="/customer-receipts"
                        render={(routeParams) => {
                            return <CustomerReceipts department={['Department Handling Services']} {...routeParams} />;
                        }}
                        userRole={'admin'}
                    />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'admin'} />

                    <Route
                        exact
                        path="/customer-receipts/add/"
                        render={(routeParams) => {
                            return <CustomerReceiptAdd department={['Department Handling Services']} {...routeParams} />;
                        }}
                        userRole={'admin'}
                    />
                    {/* Customer Receipts Module Ends */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Maintaining listing page for different status */}
                    {/* <TaskRoutes steps={updatedAccountSteps} list={CustomerFiles} form={CustomerPayment} /> */}
                </Switch>
            </GlobalHeader>
        </section>
    );
}
