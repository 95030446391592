import Base from './base';

class DMSTransfer̦ extends Base {

    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'inter-stock-transfers';
    }

    get path() {
        return `inter-stock-transfers`;
    }

    get getName() {
        return `inter-stock-transfers`;
    }

}

export default DMSTransfer̦;
