import React from 'react'
import { Link } from 'react-router-dom'

import { Tag } from 'antd'

import './request-card.scss';

export default function RequestCard({ request, index }) {

    return (
        <Link className="request-card" to={`/requests/${request.id}`}>
            <div className="card">

                <h2 className="title amount ">
                    {request.customer.customerName}

                </h2>
                <h4 className="title">
                    {request.customer.phone}
                </h4>


                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>

                <h4>
                    {request.color}
                </h4>

                <p className="address">
                    {request.customer.remarks}
                    <Tag color="magenta">{request.status || 'Initial'}</Tag>
                </p>


                Created at {request.created_at}


            </div>
        </Link>
    )
}