import React, { useState, useContext, useEffect } from 'react';

import { Card, Typography, Badge, Table, Tag, Tabs, Select, Button, Statistic, Row, Col, Dropdown, Menu } from 'antd';

import { DownOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import Rupee from '../../../../components/rupee/rupee';

import { DatePicker, Space } from 'antd';

import { Link } from 'react-router-dom';

import moment from 'moment-timezone';

import { Location } from './../../../../utils/location.utils';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import './accounts-overview.scss';

import { Departments } from '../../../../models';

import RangePicker from '../rangepicker/rangepicker';
import { cityKeys } from '../../../global-config';

// const { RangePicker } = DatePicker;

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;

export default function AccountsOverview(props) {
    const { user } = useContext(GlobalContext);

    const [range, setRange] = useState([moment.tz('Asia/Calcutta').startOf('day'), moment.tz('Asia/Calcutta').endOf('day')]);

    const [option, setOption] = useState('transaction_date');

    const [department, setDepartment] = useState();

    const [selectedTabKey, setSelectedTabKey] = useState('0');

    const [selectedDate, setSelectedDate] = useState();

    console.log(props);

    useEffect(() => {
        if (props.department) {
            setDepartment(props.department[0]);
        } else if (props.match.params.category && props.match.params.category === 'service') {
            setDepartment('Department Handling Services');
        } else if (props.match.params.category && props.match.params.category === 'sales') {
            setDepartment('Department Handling Sales');
        }
    }, []);

    function loadData(range) {
        setRange(range);
    }

    function updateTime(range) {
        setRange(range);

        loadData(range);
        // Location.search({ dt },{method : 'push'});
    }

    function handleChange(value) {
        console.log(`selected ${value}`);
        setOption(value);
        // Location.search({ type: value },{method : 'push'});
    }

    useEffect(() => {
        const searchParams = Location.search();
        if (props.history.location.search && props.history.location.search.length) {
            setSelectedTabKey(props.history.location.search.substring(6));
            // Location.search({ city: props.history.location.search.substring(6) });
        } else {
            setSelectedTabKey('0');
            // Location.search();
        }
    }, [props.history.location.search]);

    function callback(key) {
        setSelectedTabKey(key);
        Location.search({ city: key }, { method: 'push' });
    }

    // const menus = props.department.map((item) => {
    //     return <Menu.Item key={item}>{item}</Menu.Item>;
    // });

    // const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    // function handleMenuClick(e) {
    //     setDep(e.key);
    // }

    return (
        <section className="accounts-overview listing">
            <div className="page-header">
                <Title level={3}>Accounts Overview</Title>

                <div className="page-actions">
                    {/* {['management', 'admin'].indexOf(user.role) !== -1 ? ( */}
                    {/* <>
                        <Dropdown overlay={menu}>
                            <Button size={'small'}>
                                {dep} <DownOutlined />
                            </Button>
                        </Dropdown>
                    </> */}
                    {/* ) : null} */}

                    <Select defaultValue="Collection Date" style={{ width: 150 }} onChange={handleChange}>
                        <Option value="transaction_date">Collection Date</Option>
                        <Option value="verified_date">Verified Date</Option>
                        <Option value="invoiced_date">DMS Entry Date</Option>
                        <Option value="accounted_date">Accounted Date</Option>
                    </Select>


                    <RangePicker
                        inputReadOnly
                        format={'DD/MM/YYYY'}
                        value={range}
                        onChange={updateTime}
                        ranges={{
                            Today: [moment(), moment()],

                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        }}
                    />
                    {/* <DatePicker inputReadOnly format={dateFormat} value={range} onChange={updateTime} allowClear={false} /> */}

                    <Button
                        size="small"
                        onClick={() => {
                            setRange(null);
                            setTimeout(() => {
                                setRange(moment().startOf('day'));
                            });
                        }}
                    >
                        Refresh
                    </Button>
                </div>
            </div>

            <Tabs onChange={callback}>
                {user.locations.length > 0 ? (
                    <TabPane tab="All" key="0">
                        <DailyDashboard option={option} range={range} department={department} url="all" />
                    </TabPane>
                ) : null}

                {user.locations.map((location, index) => {
                    return (
                        <TabPane tab={location} key={location}>
                            <DailyDashboard option={option} range={range} url={location} department={department} />
                        </TabPane>
                    );
                })}

                {/* 
                <TabPane tab="All" key="1">
                    <DailyDashboard option={option} range={range} url="all" />
                </TabPane>

                <TabPane tab="KOZHIKODE" key="2">
                    <DailyDashboard option={option} range={range} url="kozhikode" />
                </TabPane>

                <TabPane tab="MALAPPURAM" key="3">
                    <DailyDashboard option={option} range={range} url="malappuram" />
                </TabPane>

                <TabPane tab="KANNUR" key="4">
                    <DailyDashboard option={option} range={range} url="kannur" />
                </TabPane> */}
            </Tabs>
        </section>
    );
}

function DailyDashboard({ url, range, option, department }) {
    console.log(department);

    return (
        <>
            {/* <BankOverview url={url} option={option} date={range} /> */}

            <Card title={`Todays Collections`} size="small">
                <CustomerReceiptTable url={url} option={option} date={range} department={department} />
            </Card>

            {department === 'Department Handling Services' ? null : (
                <Card title={`DMS Receipts`} size="small">
                    <DMSReceiptTable url={url} option={option} date={range} />
                </Card>
            )}
        </>
    );
}

function BankOverview({ url, date, option }) {
    const [loader, setLoader] = useState(true);

    const [stats, setStats] = useState({});

    const [credits, setCredits] = useState({});

    useEffect(() => {
        if (url === 'all') {
            getAllCustomerCredits(date, option);
        } else {
            getCustomerCredits(url, date, option);
        }
    }, [date]);

    function getCustomerCredits(url, range, option) {
        setLoader(true);

        var queries = [
            {
                field: 'transaction_date',
                operator: '>=',
                value: moment(range[0]).valueOf(),
            },

            {
                field: 'transaction_date',
                operator: '<=',
                value: moment(range[1]).valueOf(),
            },


            {
                field: 'source',
                operator: '==',
                value: 'bank',
            },
        ];

        FirebaseUtils.getBankCredits(url, queries).then((result) => {
            console.log(result);

            setCredits(result);

            let banks = groupBy(result, 'bank');

            setStats(banks);

            setLoader(false);
        });
    }

    function getAllCustomerCredits(range) {
        setLoader(true);

        var queries = [
            {
                field: 'transaction_date',
                operator: '==',
                value: moment(range).valueOf(),
            },

            {
                field: 'source',
                operator: '==',
                value: 'bank',
            },
        ];

        FirebaseUtils.getBankCredits(queries).then((result) => {
            console.log(result);

            setStats(result);

            setLoader(false);
        });
    }

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    return (
        <div>
            <div style={{ marginBottom: '10px' }}>
                <Title level={4}>Bank Overview</Title>

                <Text type="secondary">Total Credits for the day</Text>
            </div>

            {loader ? (
                <PlaceHolder />
            ) : (
                <>
                    {url === 'all' ? (
                        <div>
                            <Row gutter={16}>
                                <Col span={5} xs={16} md={5}>
                                    <div className="card">
                                        <Statistic
                                            formatter={(total) => {
                                                return <Rupee value={total} />;
                                            }}
                                            title={'Total'}
                                            value={stats.all.reduce((a, b) => a + parseInt(b.credit), 0)}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                {Object.keys(stats.receipts).map((city) => {
                                    let deposits = stats.receipts[city];

                                    let banks = groupBy(deposits, 'bank');

                                    return (
                                        <>
                                            {Object.keys(banks).map((bank) => {
                                                let sum = banks[bank].reduce((a, b) => a + parseInt(b.credit), 0);

                                                return (
                                                    <Col span={5} xs={16} md={5}>
                                                        <div className="card">
                                                            <Statistic
                                                                formatter={(total) => {
                                                                    return <Rupee value={total} />;
                                                                }}
                                                                title={bank}
                                                                value={sum}
                                                            />
                                                        </div>
                                                    </Col>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                            </Row>
                        </div>
                    ) : (
                        <Row gutter={16}>
                            <Col span={5} xs={16} md={5}>
                                <div className="card">
                                    <Statistic
                                        formatter={(total) => {
                                            return <Rupee value={total} />;
                                        }}
                                        title={'Total'}
                                        value={credits.reduce((a, b) => a + parseInt(b.credit), 0)}
                                    />
                                </div>
                            </Col>

                            {Object.keys(stats).map((bank) => {
                                let sum = stats[bank].reduce((a, b) => a + parseInt(b.credit), 0);

                                return (
                                    <>
                                        <Col span={5} xs={16} md={5}>
                                            <div className="card">
                                                <Statistic
                                                    formatter={(total) => {
                                                        return <Rupee value={total} />;
                                                    }}
                                                    title={bank}
                                                    value={sum}
                                                />
                                            </div>
                                        </Col>
                                    </>
                                );
                            })}
                        </Row>
                    )}
                </>
            )}
        </div>
    );
}

function DMSReceiptTable({ url, date }) {
    const [receipts, setReceipts] = useState([]);

    const [loader, setLoader] = useState(true);

    const [updated, setUpdated] = useState('');

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Customer Name',
            dataIndex: 'Name of Customer',
            key: 'Name of Customer',
        },
        {
            title: 'On Account Of',
            dataIndex: 'On Account Of',
            key: 'On Account Of',
        },
        {
            title: 'Type',
            dataIndex: 'Type of Payment',
            key: 'Type of Payment',
        },
        {
            title: 'Drawn On',
            dataIndex: 'Drawn On',
            key: 'Drawn On',
        },
        {
            title: 'Amount',
            dataIndex: 'Receipt Amount',
            key: 'Receipt Amount',
        },
    ];

    function getAllDMSReceipts(range) {
        var queries = [
            {
                field: 'Receipt Date',
                operator: '>=',
                value: moment(range[0]).format('DD/MM/YYYY'),
            },

            {
                field: 'Receipt Date',
                operator: '<=',
                value: moment(range[1]).format('DD/MM/YYYY'),
            },

        ];

        FirebaseUtils.getAllReceipts(queries).then((result) => {
            setReceipts(result.all);
            setLoader(false);
        });
    }

    function getDMSReceipts(url, range) {
        var queries = [
            {
                field: 'Receipt Date',
                operator: '>=',
                value: moment(range[0]).format('DD/MM/YYYY'),
            },

            {
                field: 'Receipt Date',
                operator: '<=',
                value: moment(range[1]).format('DD/MM/YYYY'),
            },

        ];

        FirebaseUtils.getReceipts(url, queries).then((result) => {
            setReceipts(result.receipts);
            setUpdated(result.lastUpdated);
            setLoader(false);
        });
    }

    useEffect(() => {
        if (url === 'all') {
            getAllDMSReceipts(date);
        } else {
            getDMSReceipts(url, date);
        }
    }, [date]);

    return (
        <>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {updated ? <p style={{ float: 'right' }}>Last Updated {updated.timestamp}</p> : null}

                    {receipts.length ? (
                        <Table
                            // loading={}
                            size="small"
                            scroll={{ x: true, y: 750 }}
                            // scroll={{ x: true, y: 750 }}
                            rowKey={(record) => record.key}
                            dataSource={receipts}
                            columns={columns}
                            pagination={false}
                            summary={(pageData) => {
                                let total = 0;

                                pageData.forEach((entry) => {
                                    total += parseFloat(entry['Receipt Amount'].split(',').join(''));
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>
                                                <Title level={5}>Receipt Total</Title>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Title level={5}>
                                                    <Rupee value={total}></Rupee>
                                                </Title>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    ) : null}
                </>
            )}
        </>
    );
}

/**
 *
 * Table of Customer Receipts
 *
 * @param {*} param0
 */
function CustomerReceiptTable({ date, url, option, department }) {
    let salesColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Customer Name',
            dataIndex: 'name',
            key: 'name',
            // fixed: 'left',
        },

        {
            title: 'KEC',
            dataIndex: 'kec',
            key: 'kec',
        },

        // {
        //     title: 'Status',
        //     dataIndex: 'pending_at',
        //     key: 'pending_at',
        // },

        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },

        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            onFilter: (value, record) => record.type.indexOf(value) !== -1,
            filters: [
                {
                    text: 'Cash',
                    value: 'Cash',
                },
                {
                    text: 'Net Banking',
                    value: 'Net Banking',
                },
                {
                    text: 'Cheque',
                    value: 'Cheque',
                },
                {
                    text: 'Debit Card',
                    value: 'Debit Card',
                },
                {
                    text: 'Credit Card',
                    value: 'Credit Card',
                },
            ],
        },

        // {
        //     title: 'Collected',
        //     dataIndex: 'amount',
        //     key: 'amount',
        // },

        {
            title: 'Collection',
            dataIndex: 'amount',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.verified === 'rejected' ? (
                            <Text type="danger" delete>
                                {text}
                            </Text>
                        ) : (
                            text
                        )}
                        {/* <Badge status={text ? "success" : "error"} text={text ? record.amount : 0} /> */}
                    </Space>
                );
            },
        },

        {
            title: 'On Account',
            dataIndex: 'account',
            key: 'account',
            onFilter: (value, record) => record.account.indexOf(value) !== -1,
            filters: [
                { text: 'BOOKING', value: 'BOOKING' },
                { text: 'PAYMENT', value: 'PAYMENT' },
                { text: 'FINANCE', value: 'FINANCE' },
                { text: 'EXCHANGE', value: 'EXCHANGE' },
                { text: 'INSURANCE', value: 'INSURANCE' },
                { text: 'ACCESSORIES', value: 'ACCESSORIES' },
            ],
        },

        // {
        //     title: 'Created At',
        //     dataIndex: 'created_at',
        //     key: 'created_at',
        // },

        {
            title: 'Verified',
            dataIndex: 'verified',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Badge status={text === 'approved' ? 'success' : 'error'} text={text === 'approved' ? record.amount : 0} />
                    </Space>
                );
            },
        },

        // {
        //     title: 'Verified At',
        //     dataIndex: 'verified_at',
        //     key: 'verified_at',
        // },

        {
            title: 'DMS Entry',
            dataIndex: 'invoice',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Badge status={text ? 'success' : 'error'} text={text ? record.amount : 0} />
                    </Space>
                );
            },
        },

        // {
        //     title: 'Invoiced At',
        //     dataIndex: 'invoiced_at',
        //     key: 'invoiced_at',
        // },

        {
            title: 'Accounts Entry',
            // dataIndex: 'accounts',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Badge
                            status={record.voucher && record.voucher.v_vno ? 'success' : 'error'}
                            text={record && record.amount ? record.amount : 0}
                        />
                    </Space>
                );
            },
        },

        // {
        //     title: 'Accounted At',
        //     dataIndex: 'accounted_at',
        //     key: 'accounted_at',
        // },

        // {
        //     title: 'Status',
        //     dataIndex: 'pending_to_reconcile',
        //     render: (text, record) => {

        //         return (
        //             <Space size="middle">
        //                 <Tag color={!text ? 'success' : 'red'}>{!text ? 'Credited' : 'Pending'}</Tag>
        //             </Space>
        //         )
        //     },
        // },

        // {
        //     title: 'Amount',
        //     dataIndex: 'amount',
        //     key: 'amount',
        // },

        {
            title: 'Source',
            dataIndex: 'source',
            // fixed: 'right',

            key: 'source',
            onFilter: (value, record) => record.source.indexOf(value) !== -1,
            filters: [
                {
                    text: 'Cashier',
                    value: 'cashier',
                },
                {
                    text: 'KEC',
                    value: 'kec',
                },
                {
                    text: 'Finance',
                    value: 'finance',
                },
                {
                    text: 'SM',
                    value: 'sm',
                },
                {
                    text: 'TL',
                    value: 'tl',
                },
            ],
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Tag color={text === 'kec' ? 'purple' : 'blue'}>{text}</Tag>
                    </Space>
                );
            },
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/${text.city}/customer-receipts/${text.collectionId}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    let serviceColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Customer Name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },

        {
            title: 'Collection',
            dataIndex: 'amount',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.verified === 'rejected' ? (
                            <Text type="danger" delete>
                                {text}
                            </Text>
                        ) : (
                            text
                        )}
                        {/* <Badge status={text ? "success" : "error"} text={text ? record.amount : 0} /> */}
                    </Space>
                );
            },
        },

        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/${text.city}/customer-receipts/${text.collectionId}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    // if (url !== 'all') {
    //     columns.splice(3, 1);
    // }

    const [loader, setLoader] = useState(true);

    const [customers, setCustomers] = useState([]);

    var cityKey = cityKeys

    useEffect(() => {
        if (url === 'all') {
            getAllCustomerCredits(date, option, department);
        } else {
            getCustomerCredits(url, date, option, department);
        }
    }, [date, option, department]);

    function getAllCustomerCredits(range, option, department) {
        if (department) {
            setLoader(true);

            var query = [
                {
                    field: 'description',
                    value: department,
                },
            ];

            Departments.get(query)
                .then((res) => {
                    let dept = res.departments;
                    var deptID = [];
                    dept.forEach((dep) => {
                        deptID.push(dep.id);
                    });
                    return deptID;
                })

                .then((deptID) => {
                    console.log(deptID);
                    var queries = [
                        {
                            field: 'transaction_date',
                            operator: '>=',
                            value: moment(range[0]).valueOf(),
                        },

                        {
                            field: 'transaction_date',
                            operator: '<=',
                            value: moment(range[1]).valueOf(),
                        },

                        {
                            field: 'source',
                            operator: 'in',
                            value: ['cashier', 'kec', 'finance', 'tl', 'sm'],
                        },
                    ];

                    FirebaseUtils.getAllCollections(queries).then((result) => {
                        setLoader(false);

                        var filtered = result.all.filter((res) => deptID.includes(res.department_id));

                        setCustomers(filtered);
                    });
                });
        }
    }

    function getCustomerCredits(url, range, option, department) {
        setLoader(true);

        var query = [
            {
                field: 'description',
                value: department,
            },

            {
                field: 'dealer_code',
                value: cityKey[url],
            },
        ];

        Departments.get(query)
            .then((res) => {
                let deptID = res.departments[0].id;

                return deptID;
            })
            .then((deptID) => {
                var queries = [
                    {
                        field: option,
                        operator: '>=',
                        value: moment(range[0]).valueOf(),
                    },

                    {
                        field: option,
                        operator: '<=',
                        value: moment(range[1]).valueOf(),
                    },


                    {
                        field: 'source',
                        operator: 'in',
                        value: ['cashier', 'kec', 'finance', 'tl', 'sm'],
                    },

                    {
                        field: 'department_id',
                        value: deptID,
                    },
                ];

                FirebaseUtils.getCollections(url, queries).then((result) => {
                    setLoader(false);

                    setCustomers(result);
                });
            });
    }

    let columns;

    if (department === 'Department Handling Services') {
        columns = serviceColumns;
    } else {
        columns = salesColumns;
    }

    const expandedRowRender = (record) => {
        console.log(record);

        return (
            <div>
                <p>
                    Created at {record.created_at} - Verified at {record.source === 'cashier' ? record.created_at : record.verified_at || 'Pending'} -
                    Invoiced at {record.edp && record.edp.created_at ? record.edp.created_at : 'Pending'} - Accounted at{' '}
                    {record.accounts && record.accounts.created_at ? record.accounts.created_at : 'Pending'}
                </p>
            </div>
        );
    };

    return (
        <>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {customers.length ? (
                        <Table
                            // loading={}
                            size="small"
                            scroll={{
                                x: 1200,
                                // y: 300
                            }}
                            // scroll={{ x: true, y: 750 }}

                            // scroll={{ x: true, y: 750 }}
                            rowKey={(record) => record.collectionId}
                            dataSource={customers}
                            columns={columns}
                            pagination={false}
                            expandable={{
                                expandedRowRender: expandedRowRender,
                            }}
                            summary={(pageData) => {
                                let total = 0;

                                let verifiedTotal = 0;

                                let dmsTotal = 0;

                                let cashTotal = 0;

                                let cashVerified = 0;

                                let chequeTotal = 0;

                                let chequeVerified = 0;

                                let accountsTotal = 0;

                                pageData.forEach((entry) => {
                                    if (entry.verified !== 'rejected') {
                                        total += parseFloat(entry['amount']);
                                    }

                                    if (entry.verified === 'approved') {
                                        verifiedTotal += parseFloat(entry['amount']);
                                    }

                                    if (entry.invoice) {
                                        dmsTotal += parseFloat(entry['amount']);
                                    }

                                    if (entry.account) {
                                        accountsTotal += parseFloat(entry['amount']);
                                    }

                                    if (entry.type === 'Cash' || entry.type === 'CPO') {
                                        cashTotal += parseFloat(entry['amount']);

                                        if (entry.verified === 'approved') {
                                            cashVerified += parseFloat(entry['amount']);
                                        }
                                    }

                                    if (entry.type === 'Cheque') {
                                        chequeTotal += parseFloat(entry['amount']);

                                        if (entry.verified === 'approved') {
                                            chequeVerified += parseFloat(entry['amount']);
                                        }
                                    }
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Title level={5}>Total Collection</Title>
                                            </Table.Summary.Cell>

                                            {department === 'Department Handling Sales' ? (
                                                <>
                                                    <Table.Summary.Cell></Table.Summary.Cell>

                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                </>
                                            ) : null}

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Title level={5}>
                                                    <Rupee value={total}></Rupee>
                                                </Title>
                                            </Table.Summary.Cell>

                                            {department === 'Department Handling Sales' ? (
                                                <>
                                                    <Table.Summary.Cell>
                                                        <Title level={5}>
                                                            <Rupee value={verifiedTotal}></Rupee>
                                                        </Title>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell>
                                                        <Title level={5}>
                                                            <Rupee value={dmsTotal}></Rupee>
                                                        </Title>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell>
                                                        <Title level={5}>
                                                            <Rupee value={accountsTotal}></Rupee>
                                                        </Title>
                                                    </Table.Summary.Cell>
                                                </>
                                            ) : null}
                                        </Table.Summary.Row>

                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Text level={5}>Cash Collection</Text>
                                            </Table.Summary.Cell>

                                            {department === 'Department Handling Sales' ? (
                                                <>
                                                    <Table.Summary.Cell></Table.Summary.Cell>

                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                </>
                                            ) : null}

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Title level={5}>
                                                    <Rupee value={cashTotal}></Rupee>
                                                </Title>
                                            </Table.Summary.Cell>

                                            {department === 'Department Handling Sales' ? (
                                                <>
                                                    <Table.Summary.Cell>
                                                        <Title level={5}>
                                                            <Text type="success">
                                                                <Rupee value={cashVerified}></Rupee>
                                                            </Text>
                                                        </Title>
                                                    </Table.Summary.Cell>
                                                </>
                                            ) : null}
                                        </Table.Summary.Row>

                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Text>Cheque Total</Text>
                                            </Table.Summary.Cell>

                                            {department === 'Department Handling Sales' ? (
                                                <>
                                                    <Table.Summary.Cell></Table.Summary.Cell>

                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                </>
                                            ) : null}

                                            <Table.Summary.Cell>
                                                {/* <Title level={5}>
                                                <Rupee value={dmsTotal}></Rupee>
                                            </Title> */}
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Title level={5}>
                                                    <Rupee value={chequeTotal}></Rupee>
                                                </Title>
                                            </Table.Summary.Cell>

                                            {department === 'Department Handling Sales' ? (
                                                <>
                                                    <Table.Summary.Cell>
                                                        <Title level={5}>
                                                            <Text type="warning">
                                                                <Rupee value={chequeVerified}></Rupee>
                                                            </Text>
                                                        </Title>
                                                    </Table.Summary.Cell>
                                                </>
                                            ) : null}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    ) : null}
                </>
            )}
        </>
    );
}
