
import React, { useState, useEffect, useContext } from "react";

import { Row, Button } from "antd";

import ReceiptCard from "./components/ReceiptCard";

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Tabs, Typography } from 'antd';

import { Tag, Badge } from 'antd'


import PlaceHolder from "./../../../../components/ui_elements/PlaceHolder";

import { Link } from 'react-router-dom'

import "./receipts.scss";

import { GlobalContext } from './../../../../Store';


const { TabPane } = Tabs;

const { Title } = Typography;

export default function Receipts({ otherEntry }) {


    const { user } = useContext(GlobalContext);

    function callback(key) {
        // console.log(key);
    }


    return (
        <section className="landing">


            <Title level={3}>

                {otherEntry ? 'Other Credits' : 'Pending for Entry'}

            </Title>

            <Tabs defaultActiveKey="0" onChange={callback}>


                {
                    user.locations.map((location, index) => {

                        return (
                            <TabPane tab={`${location} Credits`} key={index}>

                                <ReceiptList url={location} otherEntry={otherEntry} />

                            </TabPane>
                        )
                    })
                }

            </Tabs>

        </section>
    );
}



function ReceiptList({ url, otherEntry }) {

    const [reports, setReports] = useState([]);


    const [loader, setLoader] = useState(true);


    /**
  * Get stock Data
  */
    function getBankReceipts(location) {

        setLoader(true);

        var queries = [];

        var pending_for_entry = [
            {
                field: 'pending_at',
                value: 'accounts'
            },
            {
                field: 'verified',
                value: 'approved'
            }]

        var other_credits = [
            {
                field: 'pending_at',
                value: 'other-credits'
            }]

        if (otherEntry) {

            queries = [].concat(queries, other_credits)

            // queries.push([...other_credits])

        } else {

            queries = [].concat(queries, pending_for_entry)

            // queries.push([...pending_for_entry])

        }

        if (otherEntry) {


            FirebaseUtils.getBankCredits(url, queries).then((result) => {

                setLoader(false);

                setReports(result);

            });


        } else {


            FirebaseUtils.getCollections(url, queries).then((result) => {

                setLoader(false);

                setReports(result);

            });


        }


    }



    useEffect(() => {

        getBankReceipts();

    }, [])


    return (<><div className="list-header">

        <p className="page-desc">
            {loader ? 'Loading data' : `Found ${reports.length} results`}
        </p>
        <Button onClick={getBankReceipts} type="secondary" size={'small'}>Refresh</Button>

    </div>

        <Row className="report-listing-wrapper">
            {
                loader ? (
                    <PlaceHolder type="listing" />
                )
                    :
                    (
                        <>
                            {
                                reports.map((report, index) => {

                                    return <>

                                        {otherEntry ? <OtherCredit report={report} index={index} key={index} /> : <ReceiptCard report={report} index={index} key={index} />}

                                    </>

                                })
                            }
                        </>
                    )}
        </Row></>)

}




function OtherCredit({ report, index }) {

    console.log(report);

    return (
        <Link className="report-item" to={`receipt/${report.city}/${report.receiptId}?othercredit=true`}>
            <div className="card">
                <h2 className="title">
                    {report.description}
                </h2>
                <h3 className="title">
                    {report.ref}
                </h3>

                <Tag color="blue">
                    {report.bank}
                </Tag>


                <p className="address">
                    {report.account}, {report.location}
                </p>

                <h2 className="title amount">
                    {report['credit'] ? 'Rs' : ''} {report['credit']}
                </h2>

                {/* <Tag color="green">{report.bank}</Tag> */}
                <p className="">
                    Credited on {report['txn_date']}
                </p>

            </div>
        </Link>
    )
}