import Base from './base';

import { Link } from 'react-router-dom';

import { Comments } from './index';

import moment from 'moment';

class CustomerEntry extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'customer_entries';
    }

    get path() {
        return `customer_entries`;
    }

    get getName() {
        return `customer_entries`;
    }
}

export default CustomerEntry;
