/**
 *
 * Insurance Penetration
 *
 * @author Navneeth
 * @description Team report of Insurance Penetration
 */

import React, { useEffect, useState, useContext } from 'react';

// Load the Dependent Libs
import { Link } from 'react-router-dom';

// Load theme Components
import { Table, Dropdown, Menu, Typography, Button, Space, Tabs, Tag, Progress, Statistic, Row, Col, DatePicker, message, Select } from 'antd';

import { GlobalContext } from '../../../../Store';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { OrderedListOutlined, DownOutlined, CheckCircleOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';

import moment, { months } from 'moment-timezone';

import './insurance-tracker.scss';

import { ExportXLS } from './../finance-tracker/ExportXLS';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const dateFormat = 'DD/MM/YYYY';

export default function InsuranceTracker() {
    const teamReportColumns = [
        {
            title: 'S.No:',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, record, index) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span>
                                <strong> {index + 1}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{index + 1}</div>
                            </div>
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'KEC',
            dataIndex: 'kec',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.name}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.name}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 200,
        },

        {
            title: 'RETAILS',
            dataIndex: 'retails',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.retails}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.retails}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 150,
        },

        {
            title: 'DIRECT',
            dataIndex: 'Direct',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.direct}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.direct}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 150,
        },

        {
            title: 'PENDING',
            dataIndex: 'Pending',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.pending}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.pending}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 150,
        },
        {
            title: 'IN HOUSE',
            dataIndex: 'inhouse',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.inhouse}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.inhouse}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },
        {
            title: '% OF ACH',
            dataIndex: 'ach',
            render: (text, record) => {
                var color = 'red';

                if (record.ACH == 100) {
                    color = '#009900';
                } else if (record.ACH >= 90) {
                    color = '#b8db00';
                } else if (record.ACH >= 80) {
                    color = '#cc9900';
                } else if (record.ACH >= 50) {
                    color = '#ff6600';
                } else if (record.ACH >= 0) {
                    color = 'red';
                } else {
                }

                return (
                    <Space size="middle">
                        <span style={{ color: color, fontSize: '20px' }}>{record.ACH}</span>
                    </Space>
                );
            },
            width: 100,
        },
    ];

    return (
        <>
            <div>
                <div className="finance-tracker listing">
                    <div className="page-header">
                        <div className="left">
                            <Title level={3}>Insurance Tracker</Title>
                        </div>
                    </div>
                </div>

                <Tabs defaultActiveKey="0" type="card" size={'small'}>
                    <TabPane tab="Team Report" key="2">
                        <TeamView columns={teamReportColumns} />
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
}

function TeamView({ columns }) {
    const [range, setRange] = useState(moment.tz('Asia/Calcutta').endOf('day'));

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [finResult, setFinResult] = useState();

    const [location, setLocation] = useState(user.locations[0]);

    function updateTime(dt) {
        setRange(dt);
        getRequests(location, moment(dt));
    }

    useEffect(() => {
        getRequests(location, range);
    }, []);

    function getRequests(city) {
        setLoading(true);

        var end_time = new Date(range);
        var start_time = new Date(end_time.getFullYear(), end_time.getMonth(), 1);

        var params = {
            city: city,
            start_time: start_time.valueOf(),
            end_time: end_time.valueOf(),
        };

        FirebaseUtils.getInsurancePenetration(params).then((result) => {
            setLoading(false);

            console.log(result);

            setFinResult(result.data);
        });
    }

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function handleMenuClick(e) {
        setLocation(e.key);

        getRequests(e.key);
    }

    return (
        <div className="insurance-tracker listing">
            <div className="header">
                <Button
                    onClick={() => {
                        setRange(moment.tz('Asia/Calcutta').endOf('day'));
                        getRequests(location);
                    }}
                    type="secondary"
                    size={'small'}
                >
                    Refresh
                </Button>

                <Dropdown overlay={menu}>
                    <Button size={'small'}>
                        {location} <DownOutlined />
                    </Button>
                </Dropdown>

                <DatePicker inputReadOnly format={dateFormat} value={range} onChange={updateTime} allowClear={false} />

                <div>
                    {/*table data export to csc component*/}
                    {finResult && <ExportXLS csvData={finResult} />}
                </div>
            </div>

            <div>
                {loading ? (
                    <>
                        <PlaceHolder type="listing" />
                    </>
                ) : (
                    <Table size="small" rowKey={(record) => record.index} dataSource={finResult} columns={columns} pagination={false} />
                )}
            </div>
        </div>
    );
}
