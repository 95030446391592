import Base from './base';

class Pricing extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'pricing';
    }

    get path() {
        return `pricing`;
    }

    
}

export default Pricing;
