import Base from './base';

class Bill extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'bills';
    }

    get path() {
        return `bills`;
    }

    get getName() {
        return `bills`;
    }
}

export default Bill;
