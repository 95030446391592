/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Descriptions, Input, Switch, Space, Tag, message } from 'antd';

import { withRouter } from 'react-router-dom';

import './items-add.scss';

import FirebaseUtils from './../../../../utils/firebase.utils';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import useDeviceDetect from './../../../../hooks/device-detect';

import PlaceHolder from './../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from './../../../../Store';

import FormCreator from './../form-creator/form-creator';
import { cityKeys } from '../../../global-config';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { Title } = Typography;

const { TextArea } = Input;

/**
 * Generic List
 *
 * @param {*} param0
 */
function ItemsAdd({ match, model, collection, columns: cols, history, schema }) {
    const [record, setRecord] = useState([]);

    const [taxes, setTaxes] = useState([]);

    const [parties, setParties] = useState([]);

    const { isMobile } = useDeviceDetect();

    const { user } = useContext(GlobalContext);

    const { params } = match;

    const { city } = params;

    const [loading, setLoading] = useState(false);

    const [location, setLocation] = useState(user.locations[0]);

    useEffect(() => {
        loadTaxes();
        // loadParties()
    }, []);

    var cityKey = cityKeys

    const loadTaxes = () => {
        return FirebaseUtils.getRecords('', [], 'taxes').then((result) => {
            setTaxes(result.taxes);
        });
    };
    // const loadParties = () => {
    //         return FirebaseUtils.getRecords('', [], 'parties').then((result) => {
    //             setParties(result.parties)
    //         })
    // }

    const step = {
        fields: [
            {
                type: 'input',
                caption: 'Part No',
                field: 'part_number',
            },
            {
                field: 'part_name',
                type: 'input',
                caption: 'Part Name',
            },
            {
                field: 'hsn',
                type: 'number',
                caption: 'HSN',
            },

            {
                caption: 'Category',
                field: 'category',
                type: 'radio',
                options: ['Accessories', 'VAS', 'After Market', 'Extended Warranty'],
            },

            {
                caption: 'Tax',
                field: 'tax_id',
                type: 'selectText',
                options: taxes.map((tax) => {
                    return { text: tax.description, value: tax.id };
                }),
            },
            {
                field: 'price',
                type: 'number',
                caption: 'Price',
            },
            {
                field: 'average_price',
                type: 'number',
                caption: 'Average Price',
            },
            {
                field: 'purchase_price',
                type: 'number',
                caption: 'Purchase Price',
            },
            {
                field: 'purchase_price_without_tax',
                type: 'number',
                caption: 'Purchase Price Without Tax',
            },

            {
                caption: 'Inclusive of Tax',
                field: 'tax_inclusive',
                type: 'radioText',
                options: [
                    { value: true, text: 'true' },
                    { value: false, text: 'false' },
                ],
            },

            {
                caption: 'Package',
                field: 'package_status',
                type: 'selectText',
                options: [
                    { value: true, text: 'true' },
                    { value: false, text: 'false' },
                ],
            },
        ],
    };

    const onSubmit = (values) => {
        console.log(values);

        if (model === 'items') {
            values.price_on = 'master';
        }
        if (values.package_status) {
            values.price = 0;
            values.average_price = 0;
            values.purchase_price = 0;
            values.purchase_price_without_tax = 0;

            let item = taxes.filter((tax) => tax.description === 'GST 0%');
            values.tax_id = item[0].id;
        }

        if (schema.tableIndex) {
            var params = {
                ...values,
                dealerCode: cityKey[location],
                deleted_at:null,
                deleted_by:null
            };

            return FirebaseUtils.addListing(model, params, null).then(() => {
                if (values.package_status) {
                    message.info('You added a package, please add subitems to this package.');
                }
                history.goBack();
            });
        } else {
            var params = {
                ...values,
                dealerCode: cityKey[location],
                deleted_at:null,
                deleted_by:null
            };
            var key = 'party_id';
            delete params[key];

            return FirebaseUtils.addListing(model, params).then(() => {
                if (values.package_status) {
                    message.info('You added a package, please add subitems to this package.');
                }
                history.goBack();
            });
        }
    };

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model}</Title>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        {/* <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button> */}
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <div className="card">
                        <FormCreator
                            formContent={{ [model]: {} }}
                            modelIndex="requestId"
                            model={model}
                            onSubmit={onSubmit}
                            config={step}
                            callback={() => {
                                history.goBack();
                            }}
                        />
                    </div>
                </>
            )}
        </section>
    );
}

export default withRouter(ItemsAdd);
