import React, { useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import './modal-search.scss';

import FirebaseUtils from './../../utils/firebase.utils';

import { Modal, Input, Card } from 'antd';

import { GlobalContext } from '../../Store';

import { SearchOutlined, BulbOutlined, UserOutlined, RightOutlined } from '@ant-design/icons';

export default function ModalSearch() {
    const { user } = useContext(GlobalContext);
    //Hooks
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [isResultsVisible, setResultsVisible] = useState(false);

    const [results, setResults] = useState([]);

    const [location, setLocation] = useState(user.locations[0]);

    const [inputCount, setinputCount] = useState(10);

    //Modal methods
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setResultsVisible(false);
    };
    const getResults = (query) => {
        var queries = [
            {
                field: 'Contact Number',
                value: query,
            },
        ];

        FirebaseUtils.getAllListing(queries, 'bookings', 'bookings', 'bookingId').then((result) => {
            setResults(
                result.all.filter((booking) => {
                    return !booking.isCancelled;
                })
            );
        });
    };

    //Handle search query
    const handleOnInputChange = (e) => {
        setResultsVisible(true);
        //Trigger getResults() only when 10 characters are given
        setinputCount(e.target.value.length);
        if (e.target.value.length == 10) {
            getResults(e.target.value);
        } else {
            setResultsVisible(false);
        }
    };

    //Results to render
    let itemsToRender;
    if (results) {
        itemsToRender = results.map((item) => {
            return (
                <ul className="results-list">
                    <li key={item['Booking No']}>
                        <span>
                            <UserOutlined style={{ paddingRight: '10px' }} />
                            {item['Name of the Customer']}
                        </span>
                        <span>
                            <Link to={`/bookings/${item['Booking No']}`} onClick={handleOk}>
                                View Booking <RightOutlined />
                            </Link>
                        </span>
                    </li>
                </ul>
            );
        });
    }

    return (
        <>
            <span className="show-search">
                <SearchOutlined style={{ marginRight: '1em' }} onClick={showModal} />
            </span>
            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={[]} className="spotlight-container spotlight-search">
                <Input
                    size="large"
                    placeholder=""
                    autoFocus="on"
                    maxLength="10"
                    prefix={<SearchOutlined />}
                    onChange={handleOnInputChange}
                    placeholder={'Type to search'}
                    className={`search-box ${isResultsVisible ? 'bottom-border-no-radius' : ''}`}
                />
                <Card className={`${isResultsVisible ? 'visible-item' : 'hidden-item'}`}>
                    {itemsToRender.length != 0 ? itemsToRender : <span className="light-text">No Results</span>}
                </Card>
                <span className={`spotlight-search-tip ${isResultsVisible ? 'hidden-item' : 'visible-item'}`}>
                    {' '}
                    <BulbOutlined style={{ marginRight: '10px' }} />
                    {inputCount != 10 ? `Enter ${10 - inputCount} more numbers` : 'Search by phone number'}
                </span>
            </Modal>
        </>
    );
}
