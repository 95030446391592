
import React, { useState, useEffect, useContext } from "react";

import { Link } from 'react-router-dom'

import { GlobalContext } from '../../../../Store';

import { Row, Tabs, Button, Tag, Typography } from "antd";

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';


import FirebaseUtils from '../../../../utils/firebase.utils';


import "./ccm-approval-list.scss";

const { TabPane } = Tabs;

const { Title } = Typography;


export default function ApprovalList({ step, redirect }) {

    const { user } = useContext(GlobalContext);

    useEffect(() => {


    }, [])

    return (
        <div className="approval-list">


            <Title level={3}>

                {step.caption}

            </Title>


            <Tabs defaultActiveKey="0">

                {
                    user.locations.map((location, index) => {

                        return (
                            <TabPane tab={`${location} Files`} key={index}>

                                <RequestList step={step} location={location} redirect={redirect} />

                            </TabPane>
                        )
                    })
                }

            </Tabs>

        </div>
    );
}





function RequestList({ step, location, redirect }) {

    const { user } = useContext(GlobalContext);

    const [reports, setReports] = useState([]);

    const [loader, setLoader] = useState(true);

    function getData(filter) {

        setLoader(true);

        var queries = [];

        if (typeof step.queries === 'function') {
            queries = step.queries({ user, location });
        } else {
            queries = step.queries;
        }

        if (step.model == 'cancellations') {
            FirebaseUtils.getRecords(location, queries, step.model || 'requests').then((vehicles) => {

                setLoader(false);

                setReports(vehicles[step.model || 'requests']);

            });
        } else {
            FirebaseUtils.getRequests(location, queries).then((vehicles) => {

                setLoader(false);

                setReports(vehicles.requests);

            });
        }

    }


    useEffect(() => {

        getData();

    }, [])


    return (<><div className="list-header">

        <p className="page-desc">
            {loader ? 'Loading data' : `Found ${reports.length} results`}
        </p>
        <Button onClick={getData} type="secondary" size={'small'}>Refresh</Button>

    </div>

        <Row className="report-listing-wrapper">
            {
                loader ? (
                    <PlaceHolder type="listing" />
                )
                    :
                    (
                        <>
                            {
                                reports.map((report, index) => <step.Card step={step} redirect={redirect} request={report} index={index} key={index} />)
                            }
                        </>
                    )}
        </Row></>)

}

