import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

import DeliverySheduledCustomers from './components/delivery-list/delivery-list';
import DeliveryScheduleForm from './components/delivery-form/delivery-form';
// import StockDashboard from './components/stock-dashboard/stock-dashboard';

import TaskRoutes from '../common/resources/task-routes/task-routes';
import ChangePassword from './../common/components/change-password/change-password';

import RequestDetail from './../common/components/request-detail/request-detail';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import './delivery-landing.scss';

import { GlobalContext } from '../../Store';

import { deliverySteps, requestColumns } from './delivery-config';

import { requestSchema } from './../../modules/global-config';

import GenericRequest from '../common/resources/generic-request/generic-request';

import TaskOverview from '../common/resources/task-overview/task-overview';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';

export default function DeliveryLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    const queries = [
        {
            field: 'warranty.process',
            value: 'incomplete',
        },
    ];

    const modules = [
        // {
        //   caption: "Customers",
        //   path: `/${user.locations[0]}/customers`,
        // },

        {
            caption: 'Tasks',
            submenus: deliverySteps.filter((step) => !step.isForm),
        },

        {
            caption: 'Requests',
            submenus: [
                {
                    path: `/${user.locations[0]}/all-requests`,
                    caption: 'All Requests',
                },
                {
                    path: `/${user.locations[0]}/pending-requests`,
                    caption: 'Pending Requests',
                },

                {
                    path: `/${user.locations[0]}/billing-requests`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivered-requests`,
                    caption: 'Delivered Requests',
                },
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => (
                            <>
                                <NoticeBoard />
                                <TaskOverview steps={deliverySteps} />
                            </>
                        )}
                    ></Route>

                    <Route
                        exact
                        path={`/:city/all-requests`}
                        component={(...routeParams) => <GenericList schema={requestSchema} queries={[]} {...routeParams} />}
                    />

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/pending-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.pendingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/billing-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.billingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Delivered Requests */}
                    <Route
                        exact
                        path={`/:city/delivered-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.deliveryRequests} {...routeParams} />
                        )}
                    />
                    {/* Delivered Requests Ends */}

                    <Route path="/:city/customers" component={() => <GenericRequest columns={requestColumns} queries={queries} />} />

                    <Route
                        path="/:city/delivery-schedule/:id"
                        component={({ ...routeParams }) => (
                            <DeliveryScheduleForm step={{ fields: [] }} {...routeParams} columns={requestColumns} queries={queries} />
                        )}
                    />
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={deliverySteps} list={DeliverySheduledCustomers} form={DeliveryScheduleForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
