import Base from './base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import FirebaseUtils from '../utils/firebase.utils';

import moment from 'moment';

// import CollectionAdd from './../modules/accounts/components/collection-add/collection-add';

import { Users } from './index';

class Document extends Base {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];
    }

    get filterDate() {
        return true;
    }

    get getEndpoint() {
        return 'documents';
    }

    get path() {
        return `documents`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `document`;
    }

    get columns() {
        return [
            {
                caption: 'Document Type',
                field: 'document_type',
                key: 'document_type',
            },
            {
                caption: 'Remarks',
                field: 'remarks',
                key: 'remarks',
            },
            {
                caption: 'Date',
                field: 'created_by',
                key: 'created_by',
            },
        ];
    }

    /**
     * Get the data from the table
     */
    get = (queries) => {
        return this.getMethod(queries).then((result) => {
            return Promise.all(result.documents.map(this.loadStaff)).then((result) => {
                return { documents: result };
            });
        });

        // return this.getMethod(queries);
    };

    // Attach the related staff with the object
    loadStaff = (item) => {

        var id = item.created_by && item.created_by.id ? item.created_by.id : item.created_by

        return new Promise((resolve, reject) => {

            if (typeof id === 'string') {

                Users.getRecord(id).then((result) => {
                    resolve({
                        ...item,
                        created_by: result,
                    });
                });


            } else {

                resolve({
                    ...item,
                    created_by: {}
                })

            }
        })


    };

    /**
     * Add documents
     *
     * @param {*} documentType
     * @param {*} remarks
     * @param {*} userRequest
     * @param {*} files
     * @param {*} user
     * @returns
     */
    addDocument = (documentType, remarks, userRequest, files, user) => {
        console.log(userRequest);

        let customerID = {
            customerID: userRequest.customer.customerID,
        };

        let bookingNo = {
            bookingNo: userRequest.bookingNo,
        };

        let document = {
            ...customerID,
            ...bookingNo,
            documentType: documentType,
            remarks: remarks,
            file: files,
            deleted_at:null,
            deleted_by:null
        };

        return this.add(document, user);
    };

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    // Card = (record) => {
    //     return (
    //         <Link className="booking-card" to={`/collections/${record['id']}`}>
    //             <div className="card">
    //                 <h2 className="">{record.staff ? record.staff.name : null}</h2>

    //                 <h3 className="title">{record.weight}</h3>

    //                 <p className="address">
    //                     Collected on {record.created_at}
    //                     <Tag color="magenta">{record['Consultant Name']}</Tag>
    //                 </p>
    //             </div>
    //         </Link>
    //     );
    // };

    // AddComponent = () => <CollectionAdd model={this} staff={Staffs} />;
}

export default Document;
