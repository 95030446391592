import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';

export const ExportXLS = ({ csvData, fileName }) => {
    return (
        <Button variant="warning" size={'small'}>
            {csvData && (
                <CSVLink data={csvData} filename={fileName + '.csv'}>
                    Download
                </CSVLink>
            )}
        </Button>
    );
};
