import React, { useEffect, useContext } from 'react';

import { Tabs, Divider } from 'antd';

import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './../../hocs/PrivateRoute';

import GlobalHeader from './../../components/global-header/global-header';

import ApprovalList from './../admin/components/approval-list/approval-list';
import ApprovalForm from './../admin/components/approval-form/approval-form';

import Dashboard from './../common/components/dashboard/dashboard';
import StockDashboard from './../common/components/stock-dashboard/stock-dashboard';

import enquiryReport from '../common/resources/enquiry-report/enquiry-report';

// Accounts Overview
import AccountsOverview from './../common/resources/accounts-overview/accounts-overview';

import IncentiveOverview from './../common/resources/incentive-overview/incentive-overview';

import BookingOverview from './../common/resources/booking-overview/booking-overview';

import BookingReport from '../common/resources/booking-report/booking-report';

import DailyOverview from './../common/resources/daily-overview/daily-overview';

import TradingProfit from './../common/resources/trading-profit/trading-profit';

import VerifyPayment from './../common/components/verify-payment/verify-payment';

import MatchingDashboard from './../common/components/matching-dashboard/matching-dashboard';

import ReconcileSummary from './../common/components/reconcile-summary/reconcile-summary';
import ManagePayments from './../common/components/manage-payments/manage-payments';

import ManageBookings from './../common/components/manage-bookings/manage-bookings';
import ManageRequests from './../common/components/manage-requests/manage-requests';

import ManageUsers from './../common/components/manage-users/manage-users';

import StockDetail from './../common/components/stock-detail/stock-detail';

import PaymentRequests from './../common/components/payment-requests/payment-requests';

import PaymentRequestDetail from './../common/components/payment-request-detail/payment-request-detail';

import BillDetails from '../common/resources/finance-payout-tracker/bill-details';

import IFStatus from './../common/components/if-status/if-status';

import ActualStock from './../common/components/actual-stock/actual-stock';

import PreferenceDetail from './../common/components/preference-detail/preference-detail';
import RequestDetail from './../common/components/request-detail/request-detail';

import PaymentRequestsTile from './../common/components/payment-requests-tile/payment-requests-tile';

// Existing Allocations
import ExistingAllocation from './../common/components/existing-allocations/existing-allocations';

import HighPriority from './../common/components/high-priority/high-priority';
import HighPriorityDetail from './../common/components/high-priority-detail/high-priority-detail';

import CustomerReceipts from '../common/components/customer-receipts/customer-receipts';
import CustomerReceiptDetail from '../common/components/customer-receipts/customer-receipts-detail';
import CustomerReceiptAdd from '../common/components/customer-receipts/customer-receipts-add';

import Cancellations from '../common/components/cancellations/cancellations';
import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import AccessoryOverview from './../common/resources/accessory-overview/accessory-overview';

import ChangePassword from './../common/components/change-password/change-password';

import BookingDetail from './../common/components/booking-detail/booking-detail';

import MonthWiseRetail from './../common/resources/monthwise-retail/monthwise-retail';

import './management-landing.scss';

import { GlobalContext } from './../../Store';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import { stockSchema, stockColumns, managementSteps } from './management-config';

import {
    paymentRequestSchema,
    partsSchema,
    partsColumns,
    receiptsSchema,
    requestSchema,
    bookingSchema,
    payoutSchema,
    billingRequestSchema,
    requestRouteConfig,
} from './../global-config';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import RequestOverview from './../common/resources/request-overview/request-overview';

import ModuleRoutes from './../common/resources/modules-routes/module-routes';

import BookingTracker from './../common/resources/booking-tracker/booking-tracker';
import { Location } from '../../utils/location.utils';

import PendingWarranty from './../common/resources/pending-warranty/pending-warranty';

import VehicleOverview from './../common/resources/vehicle-overview/vehicle-overview';

import RetailTracker from './../common/resources/retail-tracker/retail-tracker';

import DeliveryTracker from './../common/resources/delivery-tracker/delivery-tracker';

import FinanceTracker from './../common/resources/finance-tracker/finance-tracker';

import InsuranceTracker from './../common/resources/insurance-tracker/insurance-tracker';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';

import ManageStock from '../common/components/manage-stock/manage-stock';

import VehicleDetail from '../../components/vehicle-detail/vehicle-detail';
import ManageEnquiries from '../common/components/manage-enquiries/manage-enquiries';
import EnquiryDetail from '../common/components/enquiry-detail/enquiry-detail';

const { TabPane } = Tabs;

export default function ManagementLanding(props) {
    const { user } = useContext(GlobalContext);

    const currentURL = Location.search();

    const tagToSearch = Object.keys(currentURL)[0];

    const statusToSearch = currentURL[tagToSearch];

    useEffect(() => {
        const tagToSearch = Object.keys(currentURL)[0];
    }, []);

    const modules = [
        {
            caption: 'Daily Overview',
            path: '/daily-overview',
        },

        {
            caption: 'Reconcilation',
            submenus: [
                {
                    caption: 'Sales Accounts',
                    submenus: [
                        {
                            caption: 'Account Overview',
                            path: '/sales/accounts-overview',
                        },
                        {
                            path: '/sales/pending-summary',
                            caption: 'Pending to Reconcile',
                        },

                        // {
                        //     path: '/verify-payment',
                        //     caption: 'Verify Receipts',
                        // },
                        {
                            path: '/sales/bank-receipts',
                            caption: 'Bank Receipts',
                        },

                        {
                            path: '/sales/customer-receipts',
                            caption: 'Customer Receipts',
                        },
                    ],
                },

                {
                    caption: 'Service Accounts',
                    submenus: [
                        {
                            caption: 'Account Overview',
                            path: '/service/accounts-overview',
                        },
                        {
                            path: '/service/pending-summary',
                            caption: 'Pending to Reconcile',
                        },

                        {
                            path: '/service/bank-receipts',
                            caption: 'Bank Receipts',
                        },

                        {
                            path: '/service/customer-receipts',
                            caption: 'Customer Receipts',
                        },
                    ],
                },
            ],
        },

        {
            caption: 'Reports',
            submenus: [
                {
                    caption: 'Monthwise Retail',
                    path: '/monthwise-retail',
                },

                // {
                //     caption: 'Enquiry Report',
                //     path: '/enquiry-report',
                // },

                // {
                //     caption: 'Booking Report',
                //     path: '/booking-report',
                // },
            ],
        },

        {
            caption: 'Reports',
            submenus: [
                {
                    caption: 'Monthwise Retail',
                    path: '/monthwise-retail',
                },

                // {
                //     caption: 'Enquiry Report',
                //     path: '/enquiry-report',
                // },

                // {
                //     caption: 'Booking Report',
                //     path: '/booking-report',
                // },
            ],
        },

        // {
        //     caption: 'Payments',
        //     submenus: [
        //         {
        //             caption: 'Requests for Approval',
        //             submenus: [
        //                 ...user.locations.map((location) => {
        //                     return {
        //                         caption: `${location} Requests`,
        //                         path: `/${location.toLowerCase()}/payment-requests`,
        //                     };
        //                 }),
        //             ],
        //         },
        //         // {
        //         //     caption: 'Approved Requests',
        //         //     submenus: [
        //         //         ...user.locations.map((location) => {
        //         //             return {
        //         //                 caption: `${location} Requests`,
        //         //                 path: `/${location.toLowerCase()}/approved-requests`,
        //         //             };
        //         //         }),
        //         //     ],
        //         // },
        //     ],
        // },

        {
            caption: 'Booking Tracker',
            path: '/booking-tracker',
        },

        // {
        //     caption: 'Booking Overview',
        //     path: '/booking-overview',
        // },

        {
            caption: 'Finance Tracker',
            path: '/finance-tracker',
        },
        // {
        //     caption: 'Insurance Tracker',
        //     path: '/insurance-tracker',
        // },
        {
            caption: 'Vehicle Overview',
            path: '/vehicle-overview',
        },
        // {
        //     caption: 'Accessory Overview',
        //     path: '/accessory-overview',
        // },
        {
            caption: 'Cancellations',
            path: '/cancellations',
        },

        {
            caption: 'Requests',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/request-overview`,
                                caption: 'Request Overview',
                            },

                            ...requestRouteConfig.map((config) => {
                                return {
                                    path: `/${location}/${config.path}`,
                                    caption: config.caption,
                                };
                            }),
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Bookings',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/all-bookings`,
                                caption: 'All Bookings',
                            },

                            {
                                path: `/${location}/pending-bookings`,
                                caption: 'Pending Bookings',
                            },

                            {
                                path: `/${location}/billing-done`,
                                caption: 'Pending for Delivery',
                            },

                            {
                                path: `/${location}/delivery-done`,
                                caption: 'Delivered Bookings',
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Allocations',
            path: '/admin-stock',
        },

        {
            caption: 'Matching Summary',
            path: '/matching-summary',
        },

        {
            caption: 'DMS Stock',
            path: '/actual-stock',
        },

        // {
        //     caption: 'Vehicles',
        //     submenus: user.locations.map((location) => {
        //         return {
        //             path: `/${location}/vehicles`,
        //             caption: `${location}`,
        //         };
        //     }),
        // },

        // {
        //     caption: 'Payouts',
        //     submenus: [
        //         {
        //             caption: 'From Finance',
        //             submenus: [
        //                 ...user.locations.map((location) => {
        //                     return {
        //                         caption: location,
        //                         path: `/${location}/finance-payout`,
        //                     };
        //                 }),
        //             ],
        //         },
        //     ],
        // },

        {
            caption: 'Accessories',
            submenus: user.locations.map((location) => {
                return {
                    path: `/${location}/accessories`,
                    caption: `${location}`,
                };
            }),
        },

        {
            caption: 'Summary',
            submenus: [
                {
                    path: '/if-status',
                    caption: 'If Status',
                },
            ],
        },

        // {
        //     caption: 'High Priority',
        //     submenus: [
        //         {
        //             path: '/high-priority',
        //             caption: 'High Priority Requests',
        //         },
        //     ],
        // },

        // {
        //     caption: 'Approvals',
        //     submenus: [...managementSteps.filter((step) => !step.isForm)],
        // },

        {
            caption: 'Manage',
            submenus: [
                // {
                //     caption: 'Receipts',
                //     submenus: user.locations.map((location) => {
                //         return {
                //             path: `/${location}/receipts`,
                //             caption: `${location}`,
                //         };
                //     }),
                // },
                {
                    caption: 'Manage Stock',
                    path: '/manage-stock',
                },
                {
                    path: `/${user.locations[0]}/enquiries`,
                    caption: 'Manage Enquiries',
                },
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => {
                            return (
                                <>
                                    <NoticeBoard />

                                    {/* <PaymentRequestsTile schema={paymentRequestSchema} /> */}
                                    <Dashboard steps={managementSteps} />

                                    <Divider />
                                </>
                            );
                        }}
                    ></Route>

                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    <Route exact path={`/booking-tracker`} component={BookingTracker} />

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/retail-review`}
                        component={(...routeParams) => <GenericList schema={billingRequestSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Requests Ends */}

                    {/* Finance Payout */}
                    <Route
                        exact
                        path={`/:city/finance-payout`}
                        component={(...routeParams) => <GenericList schema={payoutSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Finance Payout Ends */}

                    {/* Common Routes for Requests */}
                    {requestRouteConfig.map((config) => {
                        return (
                            <Route
                                exact
                                path={`/:city/${config.path}`}
                                component={(...routeParams) => <GenericList schema={requestSchema} queries={config.queries} {...routeParams} />}
                            />
                        );
                    })}
                    {/* Common Routes for Requests Ends */}

                    <Route
                        exact
                        path="/:city/request"
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                queries={[
                                    {
                                        field: tagToSearch,
                                        value: statusToSearch.replace(/-/g, ' '),
                                    },
                                ]}
                                {...routeParams}
                            />
                        )}
                    />

                    {/* Status Requests Ends */}

                    <PrivateRoute exact path="/actual-stock" component={ActualStock} userRole={'admin'} />

                    <PrivateRoute path="/matching-summary" component={MatchingDashboard} userRole={'admin'} />

                    <Route path="/admin-stock" component={StockDashboard} userRole={'admin'} />

                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/:city/stock/:id" component={StockDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/verify-payment" component={VerifyPayment} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-bookings" component={ManageBookings} userRole={'admin'} />
                    <PrivateRoute exact path="/manage-requests" component={ManageRequests} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/enquiries" component={ManageEnquiries} userRole={'admin'} />
                    <PrivateRoute exact path="/enquiries/:id" component={EnquiryDetail} userRole={'admin'} />


                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/if-status" component={IFStatus} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-users" component={ManageUsers} userRole={'admin'} />

                    <PrivateRoute exact path="/existing-allocation" component={ExistingAllocation} userRole={'admin'} />

                    {/* High Priority */}
                    <PrivateRoute exact path="/high-priority" component={HighPriority} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/high-priority/:id" component={HighPriorityDetail} userRole={'admin'} />
                    {/* High Priority Ends */}

                    {/* Accounts Dashboard */}

                    <Route
                        exact
                        path="/:category/accounts-overview"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <AccountsOverview {...routeParams} />;
                        }}
                    />

                    <PrivateRoute exact path="/incentive-overview" component={IncentiveOverview} userRole={'admin'} />

                    <PrivateRoute exact path="/booking-overview" component={BookingOverview} userRole={'admin'} />

                    <Route
                        exact
                        path="/:category/bank-receipts"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <ManagePayments {...routeParams} />;
                        }}
                    />

                    <Route
                        exact
                        path="/:category/pending-summary"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <ReconcileSummary {...routeParams} />;
                        }}
                    />

                    {/* Customer Receipts Module */}
                    <Route
                        exact
                        path="/:category/customer-receipts"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <CustomerReceipts {...routeParams} />;
                        }}
                    />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/customer-receipts/add" component={CustomerReceiptAdd} userRole={'admin'} />
                    {/* Customer Receipts Module Ends */}

                    {/* Cancellations Module */}
                    <PrivateRoute exact path="/cancellations" component={Cancellations} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />
                    {/* Cancellations Module ends */}

                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/all-bookings`}
                        component={(...routeParams) => <GenericList dateField={'bookingDate'} schema={bookingSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList schema={bookingSchema} queries={bookingSchema.pendingBookings} {...routeParams} />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => <GenericList queries={bookingSchema.billingQueries} schema={bookingSchema} {...routeParams} />}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList queries={bookingSchema.deliveryQueries} schema={bookingSchema} {...routeParams} />
                        )}
                    />
                    {/* Delivery Bookings Ends */}

                    <Route exact path={'/enquiry-report'} component={enquiryReport}></Route>

                    <Route exact path={'/booking-report'} component={BookingReport}></Route>

                    {/**View Bill */}
                    <Route exact path="/bill/:id" component={BillDetails} userRole={'admin'} />

                    {/* Pending Extended Warranty */}
                    <Route exact path={`/pending-extended-warranty`} component={(...routeParams) => <PendingWarranty {...routeParams} />} />
                    {/* Pending Extended Warranty Ends */}

                    {/* vehicle overview */}
                    <Route exact path={`/vehicle-overview`} component={(...routeParams) => <VehicleOverview {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    <Route exact path={'/monthwise-retail'} component={MonthWiseRetail}></Route>
                    {/* Trading profit */}
                    <Route exact path={`/trading-profit`} component={(...routeParams) => <TradingProfit {...routeParams} />} />
                    {/* Trading profit Ends */}

                    {/* vehicle overview */}
                    <Route exact path={`/retail-tracker`} component={(...routeParams) => <RetailTracker {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    {/* vehicle overview */}
                    <Route exact path={`/delivery-tracker`} component={(...routeParams) => <DeliveryTracker {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    {/* accessory overview */}
                    <Route exact path={`/accessory-overview`} component={(...routeParams) => <AccessoryOverview {...routeParams} />} />
                    {/* accessory overview Ends */}

                    {/* finance tracker */}
                    <Route exact path={`/finance-tracker`} component={(...routeParams) => <FinanceTracker {...routeParams} />} />
                    {/* finance tracker Ends */}

                    {/* insurance tracker */}
                    <Route exact path={`/insurance-tracker`} component={(...routeParams) => <InsuranceTracker {...routeParams} />} />
                    {/* insurance tracker Ends */}

                    <PrivateRoute exact path="/daily-overview" component={DailyOverview} userRole={'admin'} />

                    {/* <Route
                        path="/:city/payment-requests"
                        component={(routeParams) => <PaymentRequests {...routeParams} schema={paymentRequestSchema} />}
                    /> */}

                    {/* <Route
                        path="/:city/payment-request-detail/:id"
                        component={(routeParams) => <PaymentRequestDetail {...routeParams} schema={paymentRequestSchema} />}
                    /> */}

                    {/* Receipts */}
                    <Route
                        path="/:city/receipts"
                        component={() => <ModuleRoutes model="receipts" schema={receiptsSchema} columns={receiptsSchema.columns} />}
                    />
                    {/* Receipts Ends */}

                    {/* Manage Stock */}
                    <PrivateRoute exact path="/manage-stock" component={ManageStock} userRole={'admin'} />
                    {/* Manage Stock Ends */}

                    <PrivateRoute exact path="/vehicle/:id" component={VehicleDetail} userRole={'admin'} />


                    <Route
                        path="/:city/vehicles"
                        component={() => <ModuleRoutes model="vehicles" schema={stockSchema} columns={stockColumns} collection="vehicles" />}
                    />

                    <Route
                        path="/:city/accessories"
                        component={() => <ModuleRoutes model="accessories" schema={partsSchema} columns={partsColumns} collection="parts" />}
                    />

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={managementSteps} list={ApprovalList} form={ApprovalForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
