import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import GenericDetail from './../generic/generic-detail/generic-detail';
import GenericList from './../generic/generic-list/generic-list';

import GenericAdd from './../generic/generic-add/generic-add';
import GenericEdit from './../generic/generic-edit/generic-edit';

import GenericUpload from './../generic/generic-upload/generic-upload';

import ItemsUpload from './../items-upload/items-upload';

import ItemsAdd from '../items-add/items-add';
import ItemsEdit from '../items-edit/items-edit';

/**
 * Module Routes is for resources
 *
 * @param {*} param0
 */
export default function ModuleRoutes({ match, model, schema, collection, columns, list, ...props }) {
    return (
        // <Router>

        <Switch>
            <Route
                exact
                path={`/:city/${model}`}
                component={(...routeParams) => (
                    <GenericList schema={schema} {...routeParams} model={model} columns={columns} collection={collection} list={list} />
                )}
            />

            <Route
                exact
                path={`/:city/${model}/create/`}
                component={(routeParams) =>
                    schema.Add ? (
                        <schema.Add schema={schema.Add} {...routeParams} model={model} columns={columns} collection={collection} />
                    ) : (
                        <GenericAdd schema={schema} {...routeParams} model={model} columns={columns} collection={collection} />
                    )
                }
            />

            <Route
                exact
                path={`/:city/${model}/upload/file`}
                component={(...routeParams) => (
                    <GenericUpload schema={schema} {...routeParams} model={model} columns={columns} collection={collection} />
                )}
            />

            <Route
                exact
                path={`/:city/${model}/:id`}
                component={(...routeParams) => (
                    <GenericDetail schema={schema} {...routeParams} model={model} columns={columns} collection={collection} list={list} />
                )}
            />

            <Route
                exact
                path={`/:city/${model}/:id/edit/`}
                component={({ ...routeParams }) =>
                    schema.Edit ? (
                        <schema.Edit {...routeParams} schema={schema} model={model} columns={columns} collection={collection} />
                    ) : (
                        <GenericEdit schema={schema} {...routeParams} model={model} columns={columns} collection={collection} />
                    )
                }
            />

            {/* only for Items */}

            <Route
                exact
                path={`/:city/${model}/custom/upload/file`}
                component={(...routeParams) => (
                    <ItemsUpload schema={schema} {...routeParams} model={model} columns={columns} collection={collection} />
                )}
            />

            <Route
                exact
                path={`/:city/${model}/:id/items-edit/`}
                component={({ ...routeParams }) =>
                    schema.Edit ? (
                        <schema.Edit {...routeParams} schema={schema} model={model} columns={columns} collection={collection} />
                    ) : (
                        <ItemsEdit schema={schema} {...routeParams} model={model} columns={columns} collection={collection} />
                    )
                }
            />

            <Route
                exact
                path={`/:city/${model}/create/custom`}
                component={(...routeParams) =>
                    schema.Add ? (
                        <schema.Add schema={schema.Add} {...routeParams} model={model} columns={columns} collection={collection} />
                    ) : (
                        <ItemsAdd schema={schema} {...routeParams} model={model} columns={columns} collection={collection} />
                    )
                }
            />

            {/* only for Items */}
        </Switch>
        // </Router>
    );
}
