import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Row, Tag, Image, Timeline, Divider } from 'antd';

// import './reconcile-summary.scss';

/**
 *
 */
export default function CollectionInfo({ collection = { attachments: [] } }) {
    return (
        <div>
            <></>

            {/* {collection.verified === 'approved' ? <Tag color="success">Verified</Tag> : <Tag color="red">Unverified</Tag>}

            {collection.invoice ? <Tag color="success">Receipt Completed</Tag> : <Tag color="red">Receipt Pending</Tag>}

            {collection.accounts ? <Tag color="success">Accounts Done</Tag> : <Tag color="red">Accounts Pending</Tag>} */}

            <div className="detail-element">
                <Row span={12}>
                    <h3>City :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.city}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Source :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.source}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Name :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title">{collection.name}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Amount :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title amount">{collection.amount}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <span>Attachments</span>
                </Row>

                <>
                    {collection.attachments &&
                        collection.attachments.map((file) => {
                            return (
                                <>
                                    {file.type === 'application/pdf' ? (
                                        <embed src={file.url} width="500" height="375" type="application/pdf" />
                                    ) : (
                                        <Image width={200} src={file.url} />
                                    )}
                                </>
                            );
                        })}
                </>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>UTR :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.utr}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Cheque No :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.chequeNo}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Cheque Date :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.chequeDate}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Customer ID :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.customerId}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>On Account Of :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.account}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Remarks :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.remarks}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Phone :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.phone}</span>
                </Row>
            </div>

            <Divider />

            {/* <div className="detail-element">
                <Row span={12}>
                    <h3>Created At :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.created_at}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Created By :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.created_by && collection.created_by.name}</span>
                </Row>
            </div> */}

            <Timeline>
                <Timeline.Item color={'green'}>
                    Created by {collection.created_by && collection.created_by_name}
                    <div>{collection.created_at}</div>
                </Timeline.Item>
                <Timeline.Item color="red">Verification</Timeline.Item>
                <Timeline.Item color="gray">DMS Receipt</Timeline.Item>
            </Timeline>

            {/* <div className="detail-element">
                <Row span={12}>
                    <span>Invoice</span>
                </Row>

                <>
                    {collection.invoice &&
                        collection.invoice.map((file) => {
                            return (
                                <>
                                    {file.type === 'application/pdf' ? (
                                        <embed
                                            src={file.url}
                                            width="500"
                                            height="375"
                                            type="application/pdf"
                                        />
                                    ) : (
                                        <Image width={200} src={file.url} />
                                    )}
                                </>
                            );
                        })}
                </>
            </div> */}
        </div>
    );
}
