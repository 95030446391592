import Base from './base';

import { BillDetails, Taxes, Vehicles, Accessories, Items } from './';

import FirebaseUtils from './../utils/firebase.utils';

/**
 * Cart
 */
import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import moment from 'moment';

import { itemsMandatory } from './../modules/global-config';

// import CollectionAdd from './../modules/accounts/components/collection-add/collection-add';

import { Users, Settings } from './index';

// import Vehicles from './vehicles';

class Cart extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get getName() {
        return 'cart';
    }

    get filterDate() {
        return true;
    }

    get getEndpoint() {
        return 'cart';
    }

    get path() {
        return `cart`;
    }

    getActiveEntries = (queries) => {
        var arr = [];

        return this.get(queries).then((result) => {
            result.cart.forEach((item) => {
                if (item.description !== 'Statutory Charges' && (!item.status || (item.status !== 'rejected' && item.status !== 'removed'))) {
                    arr.push(item);
                }
            });

            return arr;
        });
    };

    addAccessoryItem = ({ item, booking, request, user }) => {
        // Default arguments to identify the cart item
        let defaultProps = {
            dealerCode: booking['Dealer Code'],
            customerID: booking['Customer ID'],
            bookingNo: booking['Booking No'],
        };

        // Load the tax of the item
        return Taxes.getRecord(item.tax_id).then((tax) => {
            // Get the pricing according to tax

            // Find the breakup of the item
            var pricing = this.getPricing(item, 2);

            var params = {
                ...defaultProps,
                name: item.part_name || item.name || item.partName, // part_name
                part_number: item.part_number, // part_number
                price: item.price,
                quantity: item.quantity,
                type: item.category,
                category: item.category,
                net_amount: pricing.net_amount,
            };

            // Setting up item_id
            if (item.price_on === 'master') {
                params.item_id = item.id;
            } else params.item_id = item.item_id;

            if (item.editable === true) {
                params.editable = item.editable
            }
            user.hideAlert = true;
            return this.add(params, user);
        });
    };

    /*
     * Get detailed cart items
     * Tax calculation
     * Discount calculation
     */
    getDetailed = (queries, dealerCode) => {
        return this.get(queries).then((result) => {
            let filter_choices = ['Accessories', 'After Market', 'VAS'];

            // let filter_choices = ['Accessories', 'After Market', 'VAS', 'basic'];

            /*  Filter cart items not removed
             *   type = vas / accessories / afer market
             */
            var items = result.cart.filter((record) => !record.subType && filter_choices.indexOf(record.type) !== -1);

            // Filter removed and rejected cases
            items = items.filter((record) => {
                if (record.status) {
                    return ['removed', 'rejected'].indexOf(record.status) === -1;
                } else {
                    return true;
                }
            });

            /*
             * Get the amount rounding setting
             *
             */

            return Settings.getValue('calculation-rounding').then((settings) => {
                return Promise.all(
                    items.map(async (item) => {
                        item.round = settings.round;

                        /*
                         * Call for calculating amounts [net_amount, discount, tax, ..etc]
                         *
                         */
                        return { ...(await this.getDetailedPricing(item, dealerCode)), ...item };
                    })
                ).then((result) => {
                    result.map((item, key) => {
                        let arr = [];

                        //check item includes mandatory fields
                        itemsMandatory.map((field) => {
                            if (!item.hasOwnProperty(field)) {
                                arr.push(field);
                            }
                        });

                        if (arr.length > 0) {
                            result[key].missing_fields = arr;
                        }
                    });

                    return [...result];
                });
            });
        });
    };

    /**
     * Function prepares the invoice content
     *
     * @param {*} request
     * @returns
     */
    getVehicleInvoiceData = (request) => {
        var queries = [
            {
                field: 'customerID',
                value: request.customer.customerID,
            },
            {
                field: 'dealerCode',
                value: request.dealerCode,
            },
        ];

        return this.getVehicleDetailed(queries, request).then((result) => {
            /*
             * Get the amount rounding setting
             *
             */
            return Settings.getValue('calculation-rounding').then((settings) => {
                var data = result[0];

                var items = [];

                var net_amount = 0;

                var tcs_amount = 0;

                let grand_total = 0;

                // Add Basic Price
                items.push({
                    description: `Price of ${data.name} : ${data.vinNo}`,
                    value: data.rate,
                });

                // Add Basic Price
                items.push({
                    description: `Discount`,
                    value: 0,
                });

                // Net Selling Price
                items.push({
                    description: `Net Selling Price`,
                    value: data.taxable_value,
                });

                net_amount += data.taxable_value;

                // GST Below
                items.push({
                    description: `CGST @ ${data.cgst} %`,
                    value: data.cgst_amount,
                });

                net_amount += data.cgst_amount;

                items.push({
                    description: `SGST @ ${data.sgst} %`,
                    value: data.sgst_amount,
                });

                net_amount += data.sgst_amount;

                items.push({
                    description: `Compensation Cess @ ${data.cess}  %`,
                    value: data.cess_amount,
                });

                net_amount += data.cess_amount;

                net_amount = this.getRoundedAmount(net_amount, settings.round);

                if (net_amount > 1000000) {
                    tcs_amount = net_amount * 0.01;
                }

                tcs_amount = this.getRoundedAmount(tcs_amount, settings.round);

                grand_total += net_amount + tcs_amount;

                grand_total = this.getRoundedAmount(grand_total, settings.round);

                return { items: items, net_amount, tcs_amount, grand_total };
            });
        });
    };

    /*
     * Get detailed cart items
     * Tax calculation
     * Discount calculation
     */
    getVehicleDetailed = (queries, request) => {
        var dealerCode = request.dealerCode;

        return this.get(queries).then((result) => {
            console.log(result);

            /*  Filter cart items not removed
             *   type = vas / accessories / afer market
             */
            // && ['basic'].indexOf(record.type) !== -1
            var items = result.cart.filter((record) => ['rejected', 'removed'].indexOf(record.status) === -1);

            /*
             * Get the amount rounding setting
             *
             */
            return Settings.getValue('calculation-rounding').then((settings) => {
                return Promise.all(
                    items.map(async (item) => {
                        item.round = settings.round;

                        item.vinNo = request.allocation.vinNo;

                        /*
                         * Call for calculating amounts [net_amount, discount, tax, ..etc]
                         *
                         */

                        if (item.type === 'basic') {
                            return { ...(await this.getVehicleDetailedPricing(item, dealerCode)), ...item };
                        } else if (['Extended Warranty', 'insurance', 'additional'].indexOf(item.type) !== -1) {
                            return { ...item };
                        } else {
                            return { ...(await this.getDetailedPricing(item, dealerCode)), ...item };
                        }
                    })
                ).then((result) => {
                    console.log(result);

                    // result.map((item, key) => {
                    //     let arr = [];

                    //     //check item includes mandatory fields
                    //     itemsMandatory.map((field) => {
                    //         if (!item.hasOwnProperty(field)) {
                    //             arr.push(field);
                    //         }
                    //     });

                    //     if (arr.length > 0) {
                    //         result[key].missing_fields = arr;
                    //     }

                    //     result[key].request = request;
                    // });

                    return [...result];
                });
            });
        });
    };

    /**
     * Get Item Pricing Based on the master
     *
     */
    getPricing = (item, round) => {
        if (item.package_status === true) {
            item.total = 0;
            item.net_amount = item.price;
            return item;
        } else {
            item.total = item.quantity * item.price;
            item.net_amount = item.quantity * item.price;
        }
        //calculating net amount from price and deducting discount
        if (item.discount_type && item.discount_type === 'percent') {
            let discountAmount = (item.discount_value / 100) * item.total;
            item.net_amount -= discountAmount;
        } else if (item.discount_type && item.discount_type === 'amount') {
            let discountAmount = item.discount_value;

            item.net_amount -= discountAmount;
        }

        item.net_amount = this.getRoundedAmount(item.net_amount, round);

        return item;
    };

    get modelName() {
        return `cart`;
    }

    get columns() {
        return [];
    }

    /**
     *
     *
     * @param {*} record
     * @returns
     */
    getVehicleDetailedPricing = (record, dealerCode) => {
        // For each record we have to check if the price is including tax or notification

        // If its including tax , we have reverse calculate to identify the basic price , tax and its values

        // Load the item master

        //rounding value
        let round = record.round;

        //deleting cart fields for re-calculating those
        delete record['net_amount'];
        delete record['total'];
        delete record['taxable_value'];
        delete record['igst'];
        delete record['cgst'];
        delete record['sgst'];
        delete record['price'];
        delete record['rate'];

        //get item master and stock
        // return this.loadFunction.getVehicleItem({ vinNo: record.vinNo }).then((item) => {

        return this.loadFunction('getVehicleItem', { id: record.vinNo, dealerCode: dealerCode }).then((item) => {
            // In case of price | tax_id is not in record because of there is no stock of Accessory
            // if (!item.price || !item.tax_id) {
            //     return record
            // }

            let tax = {
                cgst: item.pricing.cgst,
                sgst: item.pricing.sgst,
                igst: item.pricing.igst,
                cess: item.pricing.cess,
            };

            // Load the tax breakup from the table
            // return Taxes.getRecord(item.tax_id).then((tax) => {
            record = {
                quantity: 1,
                ...record,
                ...item,
                cgst: tax.cgst,
                sgst: tax.sgst,
                igst: tax.igst,
                cess: tax.cess,
            };

            return this.getVehicleTaxCalculation(record, item, tax, round);
            // });
        });
    };

    /**
     *
     *
     * @param {*} record
     * @returns
     */
    getDetailedPricing = (record, dealerCode) => {
        // For each record we have to check if the price is including tax or notification

        // If its including tax , we have reverse calculate to identify the basic price , tax and its values

        // Load the item master

        //rounding value
        let round = record.round;

        //checking item_id is in cart
        if (!record.item_id) {
            record.item_id = null;
            return record;
        }
        //deleting cart fields for re-calculating those

        delete record['net_amount'];
        delete record['total'];
        delete record['taxable_value'];
        delete record['igst'];
        delete record['cgst'];
        delete record['sgst'];
        if (!record.editable) {
            delete record['price'];
            delete record['rate'];
        }

        //get item master and stock
        return FirebaseUtils.getItemJoinStockById({ id: record.item_id, dealerCode: dealerCode }).then((item) => {
            /*
             *   if item is a package
             *
             */
            if (item.package_status === true) {
                item.net_amount = item.price;
                item.sgst = 0;
                item.cgst = 0;
                item.igst = 0;
                item.sgst_amount = 0;
                item.cgst_amount = 0;
                item.igst_amount = 0;
                item.taxable_value = 0;
                item.total = 0;

                return item;
            }

            // In case of price | tax_id is not in record because of there is no stock of Accessory
            if (!item.price || !item.tax_id) {
                return record;
            }

            // Load the tax breakup from the table
            return Taxes.getRecord(item.tax_id).then((tax) => {
                record = {
                    quantity: 1,
                    ...item,
                    ...record,
                    cgst: tax.cgst,
                    sgst: tax.sgst,
                    igst: tax.igst,
                };

                return this.getTaxCalculation(record, item, tax, round);
            });
        });
    };

    //Function for parseFloat and rounding decimal
    getRoundedAmount = (value, round) => {
        value = parseFloat(value.toFixed(round));

        return value;
    };

    /**
     * Function identified the net amount of the item
     * according to if the item is tax inclusive
     *
     * @param {*} record
     * @param {*} item
     * @param {*} tax
     * @returns
     */
    getTaxCalculation = (record, item, tax, round) => {
        if (item.tax_inclusive === true) {
            // Find the non taxable value

            item.price = parseFloat(record.price);

            let total_tax = tax.cgst + tax.sgst;

            let rate = (100 * item.price) / (100 + total_tax);

            record.rate = this.getRoundedAmount(rate, round);

            let total = record.rate * record.quantity;

            record.total = this.getRoundedAmount(total, round);

            //Finding discount amount and taxable

            if (record.discount_type && record.discount_type === 'percent') {
                let discountAmount = (record.discount_value / 100) * record.total;

                record.discount_amount = this.getRoundedAmount(discountAmount, round);

                record.discount_percent = record.discount_value;

                record.taxable_value = record.total - record.discount_amount;
            } else if (record.discount_type && record.discount_type === 'amount') {
                record.discount_amount = record.discount_value;

                record.discount_percent = (record.discount_amount / record.total) * 100;

                record.discount_percent = this.getRoundedAmount(record.discount_percent);

                record.taxable_value = record.total - record.discount_amount;
            } else {
                record.taxable_value = record.total;
            }

            record.taxable_value = this.getRoundedAmount(record.taxable_value, round);

            //finding tax amount
            let cgstAmount = (record.taxable_value * record.cgst) / 100;
            record.cgst_amount = this.getRoundedAmount(cgstAmount, round);

            let sgstAmount = (record.taxable_value * record.sgst) / 100;
            record.sgst_amount = this.getRoundedAmount(sgstAmount, round);

            let igstAmount = (record.taxable_value * record.igst) / 100;
            record.igst_amount = this.getRoundedAmount(igstAmount, round);

            // Finding net-amount
            let netAmount = record.taxable_value + cgstAmount + sgstAmount;

            record.net_amount = this.getRoundedAmount(netAmount, round);
        } else {
            // Find the non taxable value

            record.price = parseFloat(record.price);

            let rate = item.price;

            record.rate = this.getRoundedAmount(rate, round);

            let total = record.rate * record.quantity;

            record.total = this.getRoundedAmount(total, round);

            //Finding discount amount and taxable

            if (record.discount_type && record.discount_type === 'percent') {
                let discountAmount = (record.discount_value / 100) * record.total;

                record.discount_amount = this.getRoundedAmount(discountAmount, round);

                record.discount_percent = record.discount_value;

                record.taxable_value = record.total - record.discount_amount;
            } else if (record.discount_type && record.discount_type === 'amount') {
                record.discount_amount = record.discount_value;

                record.discount_percent = (record.discount_amount / record.total) * 100;

                record.discount_percent = this.getRoundedAmount(record.discount_percent);

                record.taxable_value = record.total - record.discount_amount;
            } else {
                record.taxable_value = record.total;
            }

            record.taxable_value = this.getRoundedAmount(record.taxable_value, round);

            //finding tax amount
            let cgstAmount = (record.taxable_value * record.cgst) / 100;
            record.cgst_amount = this.getRoundedAmount(cgstAmount, round);

            let sgstAmount = (record.taxable_value * record.sgst) / 100;
            record.sgst_amount = this.getRoundedAmount(sgstAmount, round);

            let igstAmount = (record.taxable_value * record.igst) / 100;
            record.igst_amount = this.getRoundedAmount(igstAmount, round);

            // Finding net-amount
            let netAmount = record.taxable_value + cgstAmount + sgstAmount;

            record.net_amount = this.getRoundedAmount(netAmount, round);
        }

        return record;
    };

    /**
     * Function identified the net amount of the item
     * according to if the item is tax inclusive
     *
     * @param {*} record
     * @param {*} item
     * @param {*} tax
     * @returns
     */
    getVehicleTaxCalculation = (record, item, tax, round) => {
        // Find the non taxable value

        record.price = parseFloat(record.pricing.sellingPrice);

        let rate = record.price;

        record.rate = this.getRoundedAmount(rate, round);

        let total = record.rate * 1;

        record.total = this.getRoundedAmount(total, round);

        //Finding discount amount and taxable

        if (record.discount_type && record.discount_type === 'percent') {
            let discountAmount = (record.discount_value / 100) * record.total;

            record.discount_amount = this.getRoundedAmount(discountAmount, round);

            record.discount_percent = record.discount_value;

            record.taxable_value = record.total - record.discount_amount;
        } else if (record.discount_type && record.discount_type === 'amount') {
            record.discount_amount = record.discount_value;

            record.discount_percent = (record.discount_amount / record.total) * 100;

            record.discount_percent = this.getRoundedAmount(record.discount_percent);

            record.taxable_value = record.total - record.discount_amount;
        } else {
            record.taxable_value = record.total;
        }

        record.taxable_value = this.getRoundedAmount(record.taxable_value, round);

        //finding tax amount
        let cgstAmount = (record.taxable_value * record.cgst) / 100;
        record.cgst_amount = this.getRoundedAmount(cgstAmount, round);

        let sgstAmount = (record.taxable_value * record.sgst) / 100;
        record.sgst_amount = this.getRoundedAmount(sgstAmount, round);

        let igstAmount = (record.taxable_value * record.igst) / 100;
        record.igst_amount = this.getRoundedAmount(igstAmount, round);

        let cessAmount = (record.taxable_value * record.cess) / 100;
        record.cess_amount = this.getRoundedAmount(cessAmount, round);

        // Finding net-amount
        let netAmount = record.taxable_value + cgstAmount + sgstAmount + cessAmount;

        record.net_amount = this.getRoundedAmount(netAmount, round);

        record.ex_showroom = record.pricing.exShowroom

        return record;
    };

    /**
     * @param {*} param0
     * @returns
     */
    clearBasic = ({ customer, dealerCode }, batch) => {

        var user = this.getUser();

        return new Promise(async (resolve, reject) => {

            var queries = [
                {
                    field: 'customerID',
                    value: customer.customerID,
                },
                {
                    field: 'type',
                    value: 'basic',
                },
                {
                    field: 'dealerCode',
                    value: dealerCode,
                },
            ];

            await this.get(queries).then(async (result) => {

                await Promise.all(result.cart.map(async (record) => {

                    let params = {

                        status: 'removed',
                        updated_by: { name: user.name, role: user.role },
                        updated_at: moment().format('DD/MM/YYYY HH:mm'),
                    }

                    // To update the cart record of id 'record.id' with params
                    var cartRef = await this.getRecordReference(record.id);

                    await batch.update(cartRef, params);

                    resolve(result);

                }));
            });
        });
    };
}

export default Cart;
