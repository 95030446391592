/**
 *
 *Component used create  stock trasfer Request ,
 Creating new records in stock_transfer  table . 
 */

import React, { useState, useEffect, useContext } from 'react';

import { Typography, Button, Row, Timeline, Select, Form, Input, Col, Radio, Alert, DatePicker } from 'antd';

import { Branches, Staffs, StockPoints } from '../../models';

import { GlobalContext } from '../../Store';

import moment from 'moment';
import { cityKeys, dealerCodeKey } from '../../modules/global-config';


const { Title } = Typography;

const { Option } = Select;

const { TextArea } = Input;

const formItemLayout = {
    layout: 'vertical',
};

var cityCode = dealerCodeKey

var dealerCode = cityKeys

export default function StockTransferRequest({ btnloading, Callback: callBack, vehicle, from, to, required, setExternalDealerStockTransfer, stockTransfer, mode }) {

    const [form] = Form.useForm();
    // state used to store  list  values of  yard

    const [stockPoints, setStockpoints] = useState([]);

    // state used to store values of drivers

    const [staff, setStaff] = useState([]);

    // state used to store values of drivers

    const [branches, setBranches] = useState([]);

    // Using the form reference supported by antd for further use

    const { user } = useContext(GlobalContext);
    // state used to get location

    const [locations, setLocations] = useState(user.locations[0]);

    const [type, setType] = useState("normal")

    // state used to disable yard fields
    const [result, setResult] = useState([]);

    const [disable, setDisable] = useState(false)

    // State to handle conversion of virtual stock transfer to normal stock transfer
    const [convert, setConvert] = useState(false)

    var fromLocation

    const [range, setRange] = useState(moment.tz('Asia/Calcutta').startOf('day'));

    var dateFormat = "DD/MM/YYYY"

    // const [disabled, setDisable] = useState(true);

    useEffect(() => {

        // When converting virtual stock transfer to actual from location will be that of virtual stock transfer
        if (stockTransfer && stockTransfer.sub_mode === "virtual") {
            fromLocation = dealerCode[stockTransfer.from]
            setConvert(true)
        } else {
            fromLocation = from
        }

        // listing staff
        getStaffDetails(locations);
        // load branhes
        loadBranch();

        if (mode === "delivery") {
            setDisable(true)
        }

    }, []);


    /**
     * Get all stock points data based on location
     * @param { } locations
     */

    function getStaffDetails(locations) {



        if (fromLocation)
            locations = cityCode[fromLocation]
        var queries = [
            {
                field: 'location',
                value: locations,
            },
        ];

        Staffs.get(queries).then((res) => {
            setStaff(res.staff);
        });
    }

    /**
     * Get all stock points data based on location
     * @param { } locations
     */

    function getYardDetails(branches) {
        var queries = [
            {
                field: 'branch_id',
                value: branches,
            },
            {
                field: "type",
                value: type
            }
        ];

        StockPoints.get(queries).then((res) => {
            setStockpoints(res.stock_points);

        });

    }



    /**
     * Onchange function used when changing To field
     * @param {*} values
     */
    function handleChange(values) {

        form.resetFields(["to_yard_id"]);

        const selectedBranch = branches.filter((ele) => ele.id === values)
        // For Externall dealer cases
        if (selectedBranch[0].name === 'External Dealer')
            setExternalDealerStockTransfer()


        getYardDetails(values);
    }

    /**
     * onchange function for selecting From loaction 
     * @param {} values 
     */
    function onChange(values) {

        var queries = [
            {
                field: 'branch_id',
                value: values,
            },


        ];

        StockPoints.get(queries).then((res) => {
            setResult(res.stock_points);

        });


    }

    /**
     * onchange function when selecting transfer type
     * @param {} values 
     */
    function onTypeChange(e) {

        setType(e.target.value)

        // To location is reset when type is changed
        form.resetFields(["location_to", "to_yard_id"]);


    }

    /**
     *
     * To load list of branches
     */

    function loadBranch() {
        Branches.get().then((result) => {
            // If from and to are already known, we prepopulate those values and load stock_point accordingly
            var fromBranch, toBranch
            if (fromLocation) {


                fromBranch = result.branches.filter((ele) => ele.dealer_code === fromLocation)
                onChange(fromBranch[0].id)
                form.setFieldsValue({ location_from: fromBranch[0].id })
            }

            if (to || stockTransfer && stockTransfer.sub_mode === "virtual") {
                var toLocation
                if (to) {
                    toLocation = to

                } else {

                    // When converting virtual stock transfer to actual stock transfer to location will to dealer code in virtual stock transfer.
                    toLocation = stockTransfer.toDealerCode
                }

                toBranch = result.branches.filter((ele) => ele.dealer_code === toLocation)
                getYardDetails(toBranch[0].id)
                form.setFieldsValue({ location_to: toBranch[0].id })
            }

            setBranches(result.branches);
        });
    }


    /**
     * 
     * @param {*} values
     */
    async function onFinish(values) {

        var date = moment(values.date).format('DD/MM/YYYY')

        let result;
        result = await Branches.getRecord(values.location_to);

        result = {
            driver_id: values.driver_id,
            from: values.from,
            from_yard_id: values.from_yard_id,
            location_from: values.location_from,
            location_to: values.location_to,
            remarks: values.remarks,
            to_yard_id: values.to_yard_id,
            to: result.location,
            sub_mode:values.sub_mode,
            transaction_date: date,

        };

        callBack(result);
    }

    return (
        <div>
            <div>

                {convert ? <div> <Alert message={'Convert Virtual Stock Transfer To Actual Stock Transfer'} type='info' /></div> : null}

                <Form name="basic" form={form} onFinish={onFinish} {...formItemLayout}

                // initialValues={{ location_from: cityCode[from], location_to: cityCode[to] }}
                >
                    <Row gutter={20}>
                        <Col xs={24} md={12} xl={12} >
                            <Form.Item
                                name="sub_mode"
                                label="Transfer type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a type',
                                    },
                                ]}
                                initialValue={"normal"}
                            >
                                {/** Transfer type and from is disabled when converting virtual stock transfer to actual */}
                                <Radio.Group onChange={onTypeChange} disabled={convert || mode == "adjust"} >
                                    <Radio value="normal">Normal</Radio>
                                    <Radio value="virtual">Virtual</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item name="location_from" label="FROM"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Location '
                                    },
                                    {
                                        validator(_, value) {

                                            if (value) {

                                                // Check if the vehicle is from the same location selected
                                                const branch = branches.filter((ele) => ele.id === value)

                                                // In case of virtual transfer vehicle dealercode will not be same as the branch
                                                if ((branch[0].location === cityCode[vehicle.dealerCode]) || (stockTransfer && stockTransfer.sub_mode === "virtual"))
                                                    return Promise.resolve();
                                                else
                                                    return Promise.reject('Please select the correct location of the vehicle');

                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    onChange={onChange}
                                    // value={location}
                                    // defaultValue={location}
                                    disabled={disable || convert}
                                >
                                    {branches.map((location, index) => (
                                        <Option value={location.id} key={index}>
                                            {location.location}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="location_to"
                                label="TO"
                                rules={[
                                    {
                                        required: required,
                                        message: 'Select Location '
                                    },

                                    {
                                        validator(_, value) {

                                            // In case of virtual transfer, yard to yard transfer is not done
                                            console.log(type, value)
                                            if (value && type === "virtual") {

                                                // Get the branch location
                                                const branch = branches.filter((ele) => ele.id === value)

                                                // In case of virtual transfer vehicle dealercode will not be same as the branch
                                                if ((branch[0].location != cityCode[vehicle.dealerCode]))
                                                    return Promise.resolve();
                                                else
                                                    return Promise.reject('Virtual stock transfer to same location cannot be done');

                                            } else {
                                                return Promise.resolve();
                                            }


                                        },
                                    },

                                ]}
                                disabled={disable}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                    //   defaultValue={location}
                                    disabled={disable || convert}
                                >
                                    {branches.map((location, index) => (
                                        <Option value={location.id} key={index}>
                                            {location.location}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* {!disabled ? null : ( */}
                    <Row gutter={20}>
                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="from_yard_id"
                                label="From Yard"

                                // In case of converting from virtual to actual stock trasfer from_yard_id in virtual stock transfer is to be prepopulated
                                initialValue={stockTransfer && stockTransfer.sub_mode === "virtual" ? stockTransfer.from_stock_point_id : vehicle.stock_point_id}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select yard'
                                    },
                                    {
                                        validator(_, value) {
                                            // Check if the vehicle is from the same Yard selected
                                            if (value) {
                                                if (vehicle.stock_point_id)
                                                    if (value === vehicle.stock_point_id || (stockTransfer && stockTransfer.sub_mode === "virtual"))
                                                        return Promise.resolve();
                                                    else
                                                        return Promise.reject('Please select the correct location of the vehicle');
                                                else
                                                    return Promise.resolve();

                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    },
                                ]}
                            >


                                <Select allowClear style={{ width: '100%' }} disabled={disable || convert}>

                                    {/** From location will be actual stock point id in any type of stock transfer */}
                                    {result.filter((record) => record.type === "normal").map((option, index) => (
                                        <Option value={option.id} key={index}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="to_yard_id"
                                label="TO Yard"
                                rules={[
                                    {
                                        required: required,
                                        message: 'Please Select yard'

                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }} disabled={disable}>
                                    {stockPoints.map((option, index) => (
                                        <Option value={option.id} key={index}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* )} */}
                    {/* {!disabled ? null : ( */}

                    {/** Driver not required in case of virtual stock transfer */}

                    {type === "virtual" || mode === "adjust" ? null : <Row gutter={20}>
                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="driver_id"
                                label="DRIVER"
                                rules={[
                                    {
                                        required: required,
                                        message: 'Please Select Driver'

                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }}>
                                    {staff.map((value, index) => (
                                        <Option value={value.id} key={index}>
                                            {value.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>}
                    {mode === "adjust" ?
                        <Row>
                            <Col xs={24} md={12} xl={12}>
                                <Form.Item
                                    name="date"
                                    label="Date"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please Select Date',
                                //     },
                                // ]}
                                >
                                    <DatePicker
                                        // onChange={(time) => {
                                        //     updateTime(time);
                                        // }}
                                        // inputReadOnly
                                        format={dateFormat}
                                        value={range}
                                        defaultValue={range}
                                    // value={range}
                                    // allowClear={false}
                                    />
                                </Form.Item></Col>
                        </Row> : null}
                    {/* )} */}

                    {/* <Row> */}
                    {/* <Col xs={24} md={12} xl={6}> */}

                    <Form.Item name="remarks" label="REMARKS">
                        <TextArea rows={4} />
                    </Form.Item>
                    {/* </Col> */}
                    {/* </Row> */}

                    <Form.Item>
                        <Button loading={btnloading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
