import React from "react";


import moment from 'moment';

import FirebaseUtils from './../../utils/firebase.utils';

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';

import { prepareCartItem, prepareCartEntry } from './../../utils/cart.utils';

import { Link } from 'react-router-dom';

import { Tag, Badge } from 'antd';


function InsuranceCard({ vehicle, index, step }) {

    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" key={index} to={`${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">
                    {vehicle.bookingNo}
                </h2>

                {/* {vehicle.finance && vehicle.finance.documentStatus ? <Tag color="orange">{vehicle.finance && vehicle.finance.documentStatus}</Tag> : null} */}


                {/* {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                } */}


                <h3 className="title">
                    {vehicle.customer.customerName}
                </h3>

                <h2 className="title">
                    {vehicle.customer.phone}

                </h2>


                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}

                </h3>


                <p className="address">
                    KEC on {vehicle.kec}
                </p>

                {
                    waitingTime && waitingTime.toString().length > 0 ?
                        <p className="date-time" style={{ display: "flex" }}>
                            Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (<div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                        </p>
                        :
                        null
                }

                {/* <Tag color="magenta">{vehicle.status}</Tag> */}

                {vehicle.insurance && vehicle.insurance.documentStatus ?
                    <Badge status="processing" text={vehicle.insurance.documentStatus} />
                    :
                    null
                }



            </div>
        </Link>
    )
}

const stepsArray = [
    {
        'fast-tag': {
            'route': {
                caption: 'Fast Tag',
                path: '/fast-tag',
                status: 'pending',
                queries: [{
                    field: 'fasttag.process',
                    value: 'incomplete'
                },
                {
                    field: 'fasttag.status',
                    value: 'pending'
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                }],
                redirect_to: 'fasttag',
                field_time: "billing.invoiced_time",
                Card: InsuranceCard,
            },
            'form': {
                isForm: true,
                caption: 'Fast Tag Form',
                path: '/fasttag/:city/:id',
                post_status: 'Fast Tag Issue',
                model: 'fasttag',
                fields: [

                    {
                        type: 'upload',
                        field: 'attachment',
                        caption: 'Fast Tag '
                    },

                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks'
                    },
                ],
                notification: (request, user) => {

                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | Fast Tag Issued',
                        body: `Fast Tag has been issued for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `
                    }
                },
                extraParams: (request, params) => {

                    let creation = {
                        issued_at: moment().format('DD/MM/YYYY HH:mm'),
                        issued_date: moment().startOf('day').valueOf(),
                        issued_time: moment().valueOf()
                    }

                    return {
                        fasttag: {
                            ...creation,
                            ...params,
                        },
                    }
                },
            }
        },
        'login': {
            'route': {
                path: '/login',
                caption: 'Ready for Issue',
                status: 'Ready for Issue',
                redirect_to: 'insurance-login',
                queries: [
                    {
                        field: 'insurance.process',
                        value: 'incomplete'
                    },
                    {
                        field: 'insurance.status',
                        value: 'Ready for Issue'
                    },
                    {
                        field: 'status',
                        operator: '!=',
                        value:'removed'
                    }
                ],
                field_time: "billing.invoiced_time",
                Card: InsuranceCard,
            },
            'form': {
                isForm: true,
                caption: 'Issue Insurance',
                path: '/insurance-login/:city/:id',
                model: 'insurance',
                post_status: 'Payment Pending',
                notification: (request, user) => {

                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | Insurance Issued',
                        body: `Insurance has been issued for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `
                    }
                },

                extraParams: (request, insuranceParams) => {

                    let creation = {
                        issued_at: moment().format('DD/MM/YYYY HH:mm'),
                        issued_date: moment().startOf('day').valueOf(),
                        issued_time: moment().valueOf(),
                        deleted_at:null,
                        deleted_by:null
                    }

                    return {
                        insurance: {
                            ...insuranceParams,
                            status: insuranceParams.type === 'inhouse' ? 'Payment Pending' : 'Insurance Issued',
                            process: insuranceParams.type === 'inhouse' ? 'incomplete' : 'complete',
                            ...creation
                        },
                        rto: {
                            status: 'pending',
                            process: 'incomplete'
                        },
                    }
                },
                fields: [

                    {
                        type: 'radio',
                        options: ['inhouse', 'direct'],
                        caption: 'Insurance Type',
                        field: 'type'
                    },

                    {
                        type: 'input',
                        caption: 'Insurance Provider',
                        field: 'provider',
                        condition: (values) => {
                            return values.type === 'inhouse'
                        }
                    },

                    {
                        type: 'number',
                        caption: 'Premium Actual Amount',
                        field: 'actualAmount',
                        // condition: (values) => {
                        //     return values.type === 'inhouse'
                        // }
                    },

                    {
                        type: 'upload',
                        field: 'attachment',
                        caption: 'Upload Insurance'
                    },


                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks'
                    },
                ],
                onSubmit: (request, content, booking) => {

                    let i = {
                        category: 'insurance',
                        type: 'insurance',
                        price: content.type === 'inhouse' ? content.actualAmount : 0,
                        partName: `Insurance (${content['type']})`,
                        description: content.provider,
                        quantity: 1
                    }

                    let item = prepareCartItem(i, booking, request)

                    return FirebaseUtils.addCartItem(item).then(() => {


                    })

                }
            }
        },
        'payment-pending': {
            'route': {
                caption: 'Insurance Payments',
                path: '/insurance-payments',
                status: 'Payment Pending',
                redirect_to: 'insurance-payment',
                queries: [
                    {
                        field: 'insurance.status',
                        value: 'Payment Pending'
                    },
                    {
                        field: 'status',
                        operator: '!=',
                        value:'removed'
                    }
                ],
                field_time: "billing.invoiced_time",
                Card: InsuranceCard,
            },
            'form': {
                isForm: true,
                caption: 'Insurance Payment',
                model: 'insurance',
                path: '/insurance-payment/:city/:id',
                post_status: 'Payment Done',
                fields: [

                    {
                        type: 'radio',
                        options: ['inhouse', 'direct'],
                        caption: 'Insurance Type',
                        field: 'type'
                    },

                    {
                        condition: (values) => {
                            return values.type === 'inhouse'
                        },
                        type: 'upload',
                        field: 'invoice',
                        caption: 'Invoice '
                    },
                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks'
                    },
                ],
                notification: (request, user) => {

                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | Insurance Payment Done',
                        body: `Insurance Payment has been completed for ${cName} - ${request.allocation.vinNo} ${request.variant}.`
                    }
                },
                extraParams: (request, params) => {

                    let creation = {
                        paid_at: moment().format('DD/MM/YYYY HH:mm'),
                        paid_date: moment().startOf('day').valueOf(),
                        paid_time: moment().valueOf()
                    }

                    return {
                        insurance: {
                            ...creation,
                            ...params,
                        },
                    }
                },
            }
        },
        'payment-done': {
            'route': {
                caption: 'Payment Done',
                path: '/paymentDone',
                status: 'Payment Done',
                redirect_to: '/issue-insurance',
                queries: [
                    {
                        field: 'insurance.process',
                        value: 'incomplete'
                    },
                    {
                        field: 'insurance.status',
                        value: 'Payment Done'
                    },
                    {
                        field: 'status',
                        operator: '!=',
                        value:'removed'
                    }
                ],
                field_time: "insurance.paid_time",
                Card: InsuranceCard,
            },
            'form': {
                isForm: true,
                caption: 'Acknowledge Insurance',
                post_status: 'Insurance Issued',
                model: 'insurance',
                path: '/issue-insurance/:city/:id',
                notification: (request, user) => {

                    let cName = capitalizeStrings(request.customer.customerName);

                    return {
                        title: cName + ' | Insurance Payment Acknowledged',
                        body: `Insurance payment has been acknowledged by Insurance Coordinator for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `
                    }
                },
                extraParams: (request, insuranceParams) => {

                    let creation = {
                        acknowledged_at: moment().format('DD/MM/YYYY HH:mm'),
                        acknowledged_date: moment().startOf('day').valueOf(),
                        acknowledged_time: moment().valueOf()
                    }
                    return {
                        insurance: {
                            ...insuranceParams,
                            process: 'complete',
                            ...creation
                        }
                    }
                },
                fields: [
                    // {
                    //     type: 'upload',
                    //     field: 'attachment',
                    //     caption: 'Insurance '
                    // },
                    {
                        type: 'textarea',
                        caption: 'Remarks',
                        field: 'remarks'
                    },
                ]
            }
        }
    }
]

export function insuranceSteps(city) {

    
    if (city == 'kozhikode') {
        return [
            // {
            //     caption: 'After Retail',
            //     submenus: [
            stepsArray[0]['fast-tag']['route'],
            stepsArray[0]['login']['route'],
            // stepsArray[0]['payment-pending']['route'],
            stepsArray[0]['payment-done']['route'],
            //     ]
            // },
            stepsArray[0]['fast-tag']['form'],
            stepsArray[0]['login']['form'],
            // stepsArray[0]['payment-pending']['form'],
            stepsArray[0]['payment-done']['form']
        ]
    } else {
        return [
            // {
            //     caption: 'After Retail',
            //     submenus: [
            stepsArray[0]['fast-tag']['route'],
            stepsArray[0]['login']['route'],
            stepsArray[0]['payment-done']['route'],
            //     ]
            // },
            stepsArray[0]['fast-tag']['form'],
            stepsArray[0]['login']['form'],
            stepsArray[0]['payment-done']['form']
        ]
    }
}


/**
 * Columns for Request Table
 */
export const requestColumns = [

    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName'
    },
    {
        type: 'input',
        caption: 'Part Name',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'insurance.status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant'
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
]

export const insuranceSteps1 = [

    // {
    //     caption: 'Before Retail',
    //     submenus: [
    //         {
    //             path: '/data-collection',
    //             caption: 'Data Collection',
    //             status: 'pending',
    //             redirect_to: 'insurance',
    //             queries: [
    //                 {
    //                     field: 'insurance.process',
    //                     value: 'incomplete'
    //                 },
    //                 {
    //                     field: 'insurance.status',
    //                     value: 'pending'
    //                 }]
    //         }
    //     ]
    // },


    // Data Collection Form
    // {
    //     isForm: true,
    //     caption: 'Data Collection',
    //     path: '/insurance/:city/:id',
    //     model: 'insurance',
    //     post_status: 'Data Collected',
    //     fields: [

    //         {
    //             type: 'radio',
    //             options: ['inhouse', 'direct'],
    //             caption: 'Insurance Type',
    //             field: 'type'
    //         },

    //         {
    //             type: 'input',
    //             caption: 'Insurance Provider',
    //             field: 'provider',
    //             condition: (values) => {
    //                 return values.type === 'inhouse'
    //             }
    //         },
    //         {
    //             type: 'input',
    //             caption: 'Premium Amount',
    //             field: 'premiumAmount',
    //             condition: (values) => {
    //                 return values.type === 'inhouse'
    //             }
    //         },
    //         {
    //             type: 'textarea',
    //             caption: 'Remarks',
    //             field: 'remarks'
    //         },
    //     ]
    // },

]
