/**
 *
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect } from 'react';

import { withRouter } from 'react-router-dom';

import ApiUtils from './../../../../utils/api.utils';
import FirebaseUtils from './../../../../utils/firebase.utils';

import { Select, Skeleton } from 'antd';

const { Option } = Select;

/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function ReferenceSeletor({ onChange, value, callback = () => {}, table, queries = [] }) {
    let [options, setOptions] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadOptions(table);
    }, []);

    useEffect(() => {
        loadOptions(table);
    }, [queries]);

    /**
     * Load the matching heads
     */
    function loadOptions(table) {
        FirebaseUtils.getListing(null, queries, table).then((result) => {
            setOptions(result[table]);

            setLoading(false);
        });
    }

    /**
     * On change of the select box ,
     * append object along with id
     */
    function handleChange(id) {
        let record = options.filter((record) => record.id === id)[0];

        onChange(id, record);

        callback(id, record);
    }

    return (
        <div className="head-selector">
            {loading ? (
                <Skeleton.Button />
            ) : (
                <Select showSearch optionFilterProp="children" value={value} style={{ width: 260 }} onChange={handleChange}>
                    {options.map((option, key) => (
                        <Option key={key} value={option.id}>
                            {option.name}
                            {option.ifsc ? '-' + option.ifsc : null}
                        </Option>
                    ))}
                </Select>
            )}
        </div>
    );
}

export default withRouter(ReferenceSeletor);
