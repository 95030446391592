import React, { useEffect, useState } from 'react';

import { Tag, Badge, message } from 'antd';

import { Link } from 'react-router-dom';

import moment from 'moment';

import FirebaseUtils from '../../utils/firebase.utils';

import { capitalizeStrings, getFormattedDateAndTime } from '../../utils/common.utils';
import { DocumentLogs, Enquirys } from '../../models';

let next_follow_up_date =  moment.tz('Asia/Calcutta').startOf('day').valueOf()
export const tlSteps = [
    {
        caption: 'Delivery Plan',
        path: '/plan-delivery',
        redirect_to: 'delivery-cases',
        alert: 'Below are upcoming deliveries. Please come back and update here if there is any delay in the scheduled so that everybody is updated.',
        description: 'Update the delivery time below',
        queries: ({ user }) => {
            return [
                {
                    field: 'teamleader_id',
                    value: user.employee_id,
                },
                // {
                //     field: 'rto.status',
                //     value: 'Road Tax Issued'
                // },
                {
                    field: 'delivery.schedule',
                    operator: 'in',
                    value: ['pending', 'Delivery Scheduled'],
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        field_time: 'rto.tp_issued_time',
        Card: AllocationCard,
    },
    {
        caption: 'Cancellation Requests',
        path: '/cancel-request',
        redirect_to: 'cancel-request-form',
        model: 'cancellations',
        queries: ({ user }) => {
            return [
                // {
                //     field: 'dealerCode',
                //     value: user.locations[0]
                // },
                {
                    field: 'status',
                    value: 'Teamleader Approval Pending',
                },
            ];
        },
        Card: CancelCard,
    },
    // cancel request Form
    {
        isForm: true,
        caption: 'Approve/Reject Cancel Request',
        path: '/cancel-request-form/:city/:id',
        endpoint: 'cancellations',
        model: 'cancellations',
        // post_status: 'Allocated',
        extraParams: (userRequest, params, user) => {
            let creation = {
                status: params.status == 'Yes' ? 'Cancellation Approval Pending' : 'Declined',
            };
            if (params.status == 'Yes') {
                creation = {
                    ...creation,
                    approve_at: moment().format('DD/MM/YYYY HH:mm'),
                    approve_date: moment().startOf('day').valueOf(),
                    approve_time: moment().valueOf(),
                    approved_by: user.name,
                };
            } else {
                creation = {
                    ...creation,
                    reject_at: moment().format('DD/MM/YYYY HH:mm'),
                    reject_date: moment().startOf('day').valueOf(),
                    reject_time: moment().valueOf(),
                    rejected_by: user.name,
                };
            }

            return {
                ...params,
                ...creation,
            };
        },
        fields: [
            {
                type: 'radio',
                options: ['Yes', 'No'],
                caption: 'Approve/Reject',
                field: 'status',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'tl_remarks',
            },
        ],
        onSubmit: (request, param) => {
            let params = {
                cancellation: {
                    id: request.id,
                    status: param.status == 'Yes' ? 'Cancellation Approval Pending' : 'Declined',
                },
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingId, params).then(() => {
                message.success('Booking Cancel Request Updated');
            });
        },
    },

    {
        caption: 'Document Verification',
        path: '/document-verification',
        redirect_to: 'document-verification-form',
        queries: ({ user }) => {
            return [
                // {
                //     field: 'teamLeader',
                //     value: user.dms['Consultant Name'],
                // },

                {
                    field: 'teamleader_id',
                    value: user.employee_id,
                },


                {
                    field: 'actual_billing.status',
                    value: 'Document Verification Pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },            ];
        },
        field_time: 'finance_verification_at',
        Card: RequestCard,
    },

    //Document verification
    {
        isForm: true,
        caption: 'Document Verification',
        path: '/document-verification-form/:city/:id',
        model: 'actual_billing',
        post_status: 'Sales Manager Approval Pending',
        endpoint: 'requests',

        fields: [
            {
                type: 'input',
                caption: 'Insurance Nominee',
                field: 'insurance_nominee',
            },

            {
                type: 'select',
                caption: 'Insurance',
                field: 'insurance',
                options: ['Inhouse', 'Direct'],
            },

            {
                type: 'select',
                caption: 'Relation',
                field: 'relation',
                options: ['Mother', 'Father', 'Sister', 'Brother', 'Husband', 'Wife', 'Son', 'Daughter'],
            },

            {
                type: 'number',
                caption: 'Accessories',
                field: 'accessories_amount',
            },

            {
                type: 'reference-selector',
                caption: 'RTO',
                field: 'rto_id',
                table: 'rto_master',

                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        rto: selected.name,
                    };
                },
            },

            {
                type: 'reference-selector',
                caption: 'Bank',
                field: 'bank_id',
                table: 'finance_banks',
                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        bank: selected.name,
                    };
                },
            },

            {
                type: 'reference-selector',
                caption: 'Branch',
                field: 'branch_id',
                queries: (values) => {
                    return [
                        {
                            field: 'bank_id',
                            value: values.bank_id,
                        },
                    ];
                },
                table: 'finance_branches',
                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        branch: selected.name,
                    };
                },
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'tl_remarks',
            },
        ],

        extraParams: (request, values) => {
            let creation = {
                document_verification_at: moment().format('DD/MM/YYYY HH:mm'),
                document_verification_date: moment().startOf('day').valueOf(),
                document_verification_time: moment().valueOf(),
            };

            let params;

            if (request.finance.type !== 'Own Fund') {
                params = {
                    ...request.finance,
                    ...creation,
                    bank: values.bank || '',
                    bank_id: values.bank_id || '',
                    branch: values.branch || '',
                    branch_id: values.branch_id || '',
                };
            } else {
                params = {
                    ...creation,
                    ...request.finance,
                };
            }

            return {
                finance: {
                    ...params,
                },
            };
        },
    },
    {
        caption: 'Pending to Upload Documents',
        description: 'You are adviced to upload the documents for below bookings',
        path: '/pending-document-upload',
        redirect_to: 'booking-status-form',
        model: 'bookings',
        search:true,
        queries: ({ user }) => {
            return [
                // {
                //     field: 'teamleader_id',
                //     value: user.employee_id,
                // },
                {
                    field: 'document_status',
                    value: 'pending',
                },
            ];
        },
        Card: BookingCard,
    },

    {
        caption: 'Enquiry Follow Up',
        path: '/follow-up',
        redirect_to: 'followup-form',
        model: 'enquiries',
        queries: ({ user }) => {
            return [
                {
                    field: 'teamleader_id',
                    value: user.employee_id,
                },
                {
                    field: 'enquiry_status',
                    value: 'LIVE',
                },
                {
                    field: 'next_follow_up_date',
                    value: next_follow_up_date,
                },
            ];
        },
    },
    {
        caption: 'Missed Follow Up',
        path: '/missed-up',
        redirect_to: 'followup-form',
        model: 'enquiries',
        queries: ({ user }) => {
            return [
                {
                    field: 'teamleader_id',
                    value: user.employee_id,
                },
                {
                    field: 'enquiry_status',
                    value: 'LIVE',
                },

                {
                    field: 'next_follow_up_date',
                    operator: '<',
                    value: next_follow_up_date,
                },
            ];
        },
    },
    // Delivery Schedule Form
    {
        isForm: true,
        caption: 'Enquiry Follow Up',
        endpoint: 'enquiries',
        path: '/followup-form/:city/:id',
        model: 'enquiries',

        extraParams: (userRequest, params) => {

            return {
                ...params,
                next_follow_up_date: moment(params.next_follow_up_date).startOf('day').valueOf(),
            };
        },
        fields: [
            {
                type: 'date',
                field: 'next_follow_up_date',
                caption: 'Next follow up Date',
                transform: (value) => {
                    return moment(value);
                },
            },
            {
                type: 'follow_up_status',
                caption: 'Status',
                field: 'follow_up_status',
            }, 
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },
    
    {
        isForm: true,
        caption: 'Delivery Plan',
        path: '/delivery-cases/:city/:id',
        model: 'delivery',
        redirect_to: '/plan-delivery',
        // post_status: 'pending',
        notification: (request, user, values) => {
            let cName = capitalizeStrings(request.customer.customerName);

            // let deliveryDate = moment(values.scheduled_delivery_date).format('DD/MM/YYYY');

            // let deliveryTime = moment(values.scheduled_delivery_time).format('HH:mm A');

            let deliveryTime = moment(values.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A');

            return {
                title: cName + '| Delivery Scheduled',
                body: `${deliveryTime}  Delivery has been scheduled for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
        extraParams: (userRequest, params) => {
            let creation = {
                scheduled_at: moment().format('DD/MM/YYYY HH:mm'),
                scheduled_date: moment().startOf('day').valueOf(),
                scheduled_time: moment().valueOf(),
            };

            let prepared_scheduled_delivery_time =
                moment(params.scheduled_delivery_date).format('DD/MM/YYYY') + ', ' + moment(params.scheduled_delivery_time).format('HH:mm A');

            return {
                delivery: {
                    ...params,
                    ...creation,
                    // scheduled_delivery_unix_date: moment(params.scheduled_delivery_date, 'DD/MM/YYYY HH:mm').startOf('day').valueOf(),
                    scheduled_delivery_date: moment(params.scheduled_delivery_date).startOf('day').valueOf(),
                    scheduled_delivery_time: moment(prepared_scheduled_delivery_time, 'DD/MM/YYYY, HH:mm A').valueOf(),
                    schedule: 'Delivery Scheduled',
                },
            };
        },
        fields: [
            {
                type: 'date',
                field: 'scheduled_delivery_date',
                caption: 'Delivery Date',
                transform: (value) => {
                    return moment(value);
                },
            },

            {
                type: 'time',
                field: 'scheduled_delivery_time',
                caption: 'Delivery Time',
                transform: (value) => {
                    return moment(value);
                },
            },
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },
];

export const bookingSchema = {
    queries: [],
    columns: [],
    disableSelect: true,
    disableUpload: true,
    disableAdd: true,

    Card: BookingCard,

    filterResults: (entry) => {
        return !entry.isCancelled;
    },

    pendingBookings: [
        {
            field: 'billing.status',
            value: 'pending',
        },
    ],

    billingQueries: [
        {
            field: 'billing.status',
            value: 'done',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryQueries: [
        {
            field: 'delivery.status',
            value: 'done',
        },
    ],
};
function CancelCard({ city, vehicle = {}, index, step }) {
    console.log(vehicle);
    // let city = vehicle.dealerCode == 'KL305' ? 'kozhikode' : vehicle.dealerCode == 'KL306' ? 'kannur' : 'malappuram';

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${city}/${index}`}>
            <div className="card">
                <h2 className="title amount ">{vehicle.customerName}</h2>
                <h4 className="title">Phone No : {vehicle.phone}</h4>
                <h3 className="title ">Type : {vehicle.type}</h3>
                <a href={`/bookings/${vehicle.bookingId}`} style={{ zIndex: 1 }}>
                    {vehicle.bookingId}
                </a>
                <h4>{vehicle.remarks}</h4>
                <Tag color="purple">{vehicle.status || 'Initial'}</Tag>
                Created at {vehicle.created_at} by {vehicle.created_by_name}
            </div>
        </Link>
    );
}

function AllocationCard({ vehicle, index, step }) {
    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" to={`/${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">
                    {vehicle.delivery.scheduled_delivery_date ? (
                        <>
                            {/* {vehicle.delivery.scheduled_delivery_date} - {vehicle.delivery.scheduled_delivery_time} */}
                            {moment(vehicle.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A')}
                        </>
                    ) : (
                        'Pending'
                    )}
                </h2>

                <h3 className="title">{vehicle.customer.customerName}</h3>

                {/* <h2 className="title">{vehicle.customer.phone}</h2> */}

                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}
                </h3>

                <p className="address">KEC on {vehicle.kec}</p>

                {waitingTime && waitingTime.toString().length > 0 ? (
                    <p className="date-time" style={{ display: 'flex' }}>
                        Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (
                        <div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                    </p>
                ) : null}

                {vehicle.delivery.schedule == 'pending' ? (
                    <Tag color="gold">{vehicle.delivery.schedule}</Tag>
                ) : (
                    <Tag color="magenta">{vehicle.delivery.schedule}</Tag>
                )}

                {vehicle.finance && vehicle.finance.documentStatus ? <Badge status="processing" text={vehicle.finance.documentStatus} /> : null}
            </div>
        </Link>
    );
}
function RequestCard({ request, step }) {
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}/${request.city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                {/* <h4 className="title">{request.customer.phone}</h4> */}
                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>
                <h4>{request.color}</h4>
                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>
                Created at {request.created_at}
            </div>
        </Link>
    );
}

function BookingCard({ city, booking = {}, index }) {
    return (
        <Link key={index} className="booking-card" to={`/bookings/${booking['Booking No']}`}>
            <div className="card">
                <h2 className="title amount ">{booking['Name of the Customer']}</h2>
                {/* <h4 className="title">{booking['Contact Number']}</h4> */}
                <p className="">{booking['Variant']}</p>
                <p>
                    <small> {booking['Color']}</small>
                </p>
                {booking.billing.status === 'pending' ? (
                    <>
                        <BookingPosition city={city} booking={booking} />
                    </>
                ) : null}
                <h4>{booking['Booking No']}</h4>
                <p className="address">Booked on {booking['Booking Date']}</p>
                Committed Delivery Date {booking['Committed Delivery Date']}
            </div>
        </Link>
    );
}

/**
 * Booking Position
 */
function BookingPosition({ booking, city }) {
    const [model, setModel] = useState({ position: 0 });

    useEffect(() => {
        loadMatchingBookings(city, booking).then((result) => {
            console.log(result);
        });

        return () => { };
    }, []);

    function loadMatchingBookings(city, booking) {
        var queries = [
            {
                field: 'billing.status',
                value: 'pending',
            },
            {
                field: 'Color',
                value: booking['Color'],
            },
            {
                field: 'Variant',
                value: booking['Variant'],
            },
        ];

        return FirebaseUtils.getListing(city, queries, 'bookings').then((result) => {
            const { bookings } = result;

            var arr = bookings
                .filter((b) => !b.isCancelled)
                .sort((a, b) => {
                    return a.bookingDate > b.bookingDate ? 1 : -1;
                });

            var position;

            arr.forEach((item, index) => {
                if (item['Booking No'] === booking['Booking No']) {
                    position = index;
                }
            });

            setModel({ position, bookings });

            return { position, bookings };
        });
    }

    return (
        <p style={{ float: 'right' }}>
            <Badge status="processing" text={`Position : ${model.position + 1}`} />
        </p>
    );
}
