import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './../../hocs/PrivateRoute';

import { Divider } from 'antd';

import GlobalHeader from './../../components/global-header/global-header';

// Pending List
import Collection from './../common/components/sales-collections/collections/collections';

// Pending List Ends

import Report from './../common/components/sales-collections/report/Report';

import MonthWiseRetail from './../common/resources/monthwise-retail/monthwise-retail';

// Accounts Overview
import AccountsOverview from './../common/resources/accounts-overview/accounts-overview';
import BookingOverview from './../common/resources/booking-overview/booking-overview';
// Accounts Overview

import BookingTracker from './../common/resources/booking-tracker/booking-tracker';

import StockDashboard from './../common/components/stock-dashboard/stock-dashboard';

import ManageBookings from './../common/components/manage-bookings/manage-bookings';
import BookingDetail from './../common/components/booking-detail/booking-detail';

import RequestOverview from './../common/resources/request-overview/request-overview';

import FinanceTracker from './../common/resources/finance-tracker/finance-tracker';

import PreferenceDetail from './../common/components/preference-detail/preference-detail';
import RequestDetail from './../common/components/request-detail/request-detail';

import HighPriority from './../common/components/high-priority/high-priority';
import HighPriorityDetail from './../common/components/high-priority-detail/high-priority-detail';

import ActualStock from './../common/components/actual-stock/actual-stock';

import Dashboard from './../common/components/dashboard/dashboard';

import MatchingDashboard from './../common/components/matching-dashboard/matching-dashboard';

import DailyOverview from './../common/resources/daily-overview/daily-overview';

import ReconcileSummary from './../common/components/reconcile-summary/reconcile-summary';

import CustomerReceipts from '../common/components/customer-receipts/customer-receipts';
import CustomerReceiptDetail from '../common/components/customer-receipts/customer-receipts-detail';
// import CustomerReceiptAdd from '../common/components/customer-receipts/customer-receipts-add';

import Cancellations from '../common/components/cancellations/cancellations';
import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import AccessoryOverview from './../common/resources/accessory-overview/accessory-overview';

import GenericList from '../common/resources/generic/generic-list/generic-list';
import ChangePassword from './../common/components/change-password/change-password';
import TaskList from './../common/resources/task-list/task-list';
import TaskForm from './../common/resources/task-form/task-form';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import ManageStock from './../common/components/manage-stock/manage-stock';

import PendingRequestsSM from './components/pending-requests-sm/pending-requests-sm';

import NoticeBoard from './../common/components/notice-board/notice-board';

import SalesReport from '../common/resources/bill-list/sales-report';

import { smSteps } from './sm-config';

import {
    requestSchema,
    bookingSchema,
    billingRequestSchema,
    deliveryRequestSchema,
    requestRouteConfig,
    billSchema,
    billActions,
} from './../global-config';

import { Location } from '../../utils/location.utils';

import './sm-landing.scss';

import { GlobalContext } from './../../Store';

import VehicleOverview from './../common/resources/vehicle-overview/vehicle-overview';

import Profile from './../common/resources/profile/profile';

import BookingReport from '../common/resources/booking-report/booking-report';

import BillList from '../common/resources/bill-list/bill-list';
import VehicleDetail from '../../components/vehicle-detail/vehicle-detail';
import StockTransferRecord from '../common/resources/stock-transfer/stock-transfer';
import StockTransactionRecord from '../common/resources/stock-transaction/stock-transactions';
import ManageEnquiries from '../common/components/manage-enquiries/manage-enquiries';
import EnquiryDetail from '../common/components/enquiry-detail/enquiry-detail';

// import ManageStock from '../common/components/manage-stock/manage-stock';

export default function Landing(props) {
    
    const { user } = useContext(GlobalContext);

    useEffect(() => { }, []);

    const currentURL = Location.search();

    const tagToSearch = Object.keys(currentURL)[0];

    const statusToSearch = currentURL[tagToSearch];

    const modules = [
        {
            caption: 'Daily Overview',
            path: '/daily-overview',
        },

        {
            caption: 'Accounts Overview',
            path: '/accounts-overview',
        },

        {
            caption: 'Reports',
            submenus: [
                {
                    caption: 'Monthwise Retail',
                    path: '/monthwise-retails',
                },

                // {
                //     caption: 'Booking Report',
                //     path: '/booking-report',
                // },
            ],
        },

        {
            caption: 'Allocations',
            path: '/allocation',
        },

        // {
        //     caption: 'Vehicle Stock',
        //     submenus: [
        //         {
        //             caption: 'Stock Movement',
        //             path: '/stock-transaction',
        //         },

        //         {
        //             caption: 'Stock Transfer',
        //             path: '/stock-transfers',
        //         },
        
        //     ],
        // },

        {
            caption: 'Booking Tracker',
            path: '/booking-tracker',
        },

        // {
        //     caption: 'Booking Overview',
        //     path: '/booking-overview',
        // },

        {
            caption: 'Vehicle Overview',
            path: '/vehicle-overview',
        },

        // {
        //     caption: 'Accessory Overview',
        //     path: '/accessory-overview',
        // },

        {
            caption: 'Finance Tracker',
            path: '/finance-tracker',
        },
        {
            caption: 'Cancellations',
            path: '/cancellations',
        },
        // {
        //   caption: 'Request Dashboard',
        //   path: '/request-dashboard',
        // },
        {
            caption: 'Requests',
            submenus: [
                {
                    path: `/${user.locations[0]}/request-overview`,
                    caption: 'Request Overview',
                },

                ...requestRouteConfig.map((config) => {
                    return {
                        path: `/${user.locations[0]}/${config.path}`,
                        caption: config.caption,
                    };
                }),

                // {
                //     path: `/${user.locations[0]}/all-requests`,
                //     caption: 'All Requests'
                // },
                // {
                //     path: `/${user.locations[0]}/pending-requests`,
                //     caption: 'Pending Requests'
                // },

                // {
                //     path: `/${user.locations[0]}/billing-requests`,
                //     caption: 'Pending for Delivery'
                // },

                // {
                //     path: `/${user.locations[0]}/delivered-requests`,
                //     caption: 'Delivered Requests'
                // }
            ],
        },
        {
            caption: 'Bookings',
            submenus: [
                {
                    path: `/${user.locations[0]}/all-bookings`,
                    caption: 'All Bookings',
                },

                {
                    path: `/${user.locations[0]}/pending-bookings`,
                    caption: 'Pending Bookings',
                },

                {
                    path: `/${user.locations[0]}/billing-done`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivery-done`,
                    caption: 'Delivered Bookings',
                },
            ],
        },

        {
            caption: 'Matching Summary',
            path: '/matching-summary',
        },

        {
            caption: 'Reconcilation',
            submenus: [
                {
                    path: '/pending-summary',
                    caption: 'Pending to Reconcile',
                },

                {
                    path: '/customer-receipts',
                    caption: 'Customer Receipts',
                },
            ],
        },
        {
            caption: 'Bills',
            submenus: [
                {
                    caption: 'Vehicle Bills',
                    path: `/${user.locations[0]}/basic/bills`,
                },
                {
                    caption: 'Accessories Bills',
                    path: `/${user.locations[0]}/Accessories/bills`,
                },
                {
                    caption: 'VAS Bills',
                    path: `/${user.locations[0]}/VAS/bills`,
                },
                {
                    caption: 'After Market Bills',
                    path: `/${user.locations[0]}/After Market/bills`,
                },
            ],
        },

        {
            caption: 'Tasks',
            submenus: [
                ...smSteps.filter((step) => !step.isForm),

                // {
                //   path: '/stock-transfers',
                //   caption: 'Review Stock Transfers'
                // },
                {
                    path: '/collections',
                    caption: 'Unidentified Receipts',
                },

                // {
                //   path: '/allocation-list',
                //   caption: 'Review DMS Allocation Requests'
                // },
            ],
        },
        // {
        //     caption: 'Manage',
        //     submenus: [
        //         {
        //             caption: 'Manage Stock',
        //             path: '/manage-stock',
        //         },
        //     ],
        // },

        // {
        //   caption: "Bookings",
        //   submenus: [{
        //     path: '/manage-bookings',
        //     caption: 'Manage Bookings'
        //   }]
        // },
        {
            caption: 'Manage',
            submenus: [
                {
                    caption: 'Manage Stock',
                    path: '/manage-stock',
                },
                {
                    path: `/${user.locations[0]}/enquiries`,
                    caption: 'Manage Enquiries',
                },
            ],
        },

        // {
        //     caption: 'DMS Stock',
        //     path: '/actual-stock',
        // },

        {
            caption: 'Change Password',
            path: '/change-password',
        },

        // {
        //   caption: "High Priority",
        //   path: '/high-priority',
        // },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <PrivateRoute exact path="/daily-overview" component={DailyOverview} userRole={'admin'} />

                    <Route exact path={`/booking-tracker`} component={BookingTracker} />

                    <PrivateRoute path="/actual-stock" component={ActualStock} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-stock" component={ManageStock} userRole={'admin'} />

                    <PrivateRoute exact path="/:city/enquiries" component={ManageEnquiries} userRole={'admin'} />

                    <Route path="/allocation" component={StockDashboard} userRole={'admin'} />

                    <Route
                        exact
                        path={'/'}
                        component={() => {
                            return (
                                <>
                                    {/* <ReconcileOverview /> */}

                                    <NoticeBoard />

                                    {/* <PendingRequestsSM /> */}

                                    {/* <Tabs
                                        defaultActiveKey="0"
                                        type="card"
                                        size={'small'}
                                    >
                                        <TabPane tab="Todays Review" key="0">
                                            <DailyDashboard />
                                        </TabPane>
                                        <TabPane tab="Pending Cases" key="1">
                                            <RequestDashboard />
                                        </TabPane>
                                    </Tabs> */}

                                    <Divider />

                                    {/* <RequestDashboard /> */}

                                    <Dashboard steps={smSteps} />

                                    {/* <TaskOverview steps={smSteps} /> */}
                                </>
                            );
                        }}
                    ></Route>
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    {/* finance tracker */}
                    <Route exact path={`/finance-tracker`} component={(...routeParams) => <FinanceTracker {...routeParams} />} />
                    {/* finance tracker Ends */}

                    {/* vehicle overview */}
                    <Route exact path={`/vehicle-overview`} component={(...routeParams) => <VehicleOverview {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    {/* accessory overview */}
                    <Route exact path={`/accessory-overview`} component={(...routeParams) => <AccessoryOverview {...routeParams} />} />
                    {/* accessory overview Ends */}

                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />

                    <PrivateRoute path="/matching-summary" component={MatchingDashboard} userRole={'admin'} />

                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/high-priority" component={HighPriority} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/high-priority/:id" component={HighPriorityDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-bookings" component={ManageBookings} userRole={'admin'} />
                    <PrivateRoute exact path="/vehicle/:id" component={VehicleDetail} userRole={'admin'} />

                    {/* <PrivateRoute exact path="/manage-stock" component={ManageStock} userRole={'admin'} /> */}
                    {/* <PrivateRoute exact path="/manage-requests" component={ManageRequests} userRole={'admin'} /> */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/all-bookings`}
                        component={(...routeParams) => <GenericList dateField={'bookingDate'} schema={bookingSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Common Routes for Requests */}
                    {requestRouteConfig.map((config) => {
                        return (
                            <Route
                                exact
                                path={`/:city/${config.path}`}
                                component={(...routeParams) => <GenericList schema={requestSchema} queries={config.queries} {...routeParams} />}
                            />
                        );
                    })}
                    {/* Common Routes for Requests Ends */}

                    <Route exact path={'/monthwise-retails'} component={MonthWiseRetail}></Route>

                    <Route exact path={'/booking-report'} component={BookingReport}></Route>
                    {/* Pending Requests */}
                    {/* <Route
                        exact
                        path={`/:city/pending-requests`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                queries={requestSchema.pendingRequests}
                                {...routeParams}
                            />
                        )}
                    /> */}
                    {/* Pending Requests Ends */}

                    {/* Pending Requests */}
                    {/* <Route
                        exact
                        path={`/:city/billing-requests`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                queries={requestSchema.billingRequests}
                                {...routeParams}
                            />
                        )}
                    /> */}
                    {/* Pending Requests Ends */}

                    {/* Delivered Requests */}
                    {/* <Route
                        exact
                        path={`/:city/delivered-requests`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                queries={requestSchema.deliveryRequests}
                                {...routeParams}
                            />
                        )}
                    /> */}
                    {/* Delivered Requests Ends */}

                    {/* Requests according to Queries Done */}

                    {/* Status Requests */}

                    {/* Bill listing route  */}
                    <Route exact path={`/:city/:category/bills`} component={(...routeParams) => <SalesReport {...routeParams} />} />

                    <Route
                        exact
                        path="/:city/request"
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                queries={[
                                    {
                                        field: tagToSearch,
                                        value: statusToSearch.replace(/-/g, ' '),
                                    },
                                ]}
                                {...routeParams}
                            />
                        )}
                    />

                    {/* Status Requests Ends */}

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/enquiries/:id" component={EnquiryDetail} userRole={'admin'} />

                    <Route exact path={'/collections'} component={Collection}></Route>
                    <PrivateRoute exact path="/:city/report/:id" component={Report} userRole={'sm'} />

                    {/* <PrivateRoute exact path="/:city/collections/:id" component={AccountCollectionDetail} userRole={'sm'} /> */}

                    {/* Customer Receipts Module */}
                    <Route
                        exact
                        path="/customer-receipts"
                        render={(routeParams) => {
                            return <CustomerReceipts department={['Department Handling Sales']} {...routeParams} />;
                        }}
                        userRole={'admin'}
                    />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'admin'} />
                    {/* <PrivateRoute exact path="/customer-receipts/add" component={CustomerReceiptAdd} userRole={'admin'} /> */}
                    {/* Customer Receipts Module Ends */}

                    {/* Cancellations Module */}
                    <PrivateRoute exact path="/cancellations" component={Cancellations} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />
                    {/* Cancellations Module ends */}

                    <Route
                        exact
                        path="/accounts-overview"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <AccountsOverview department={['Department Handling Sales']} {...routeParams} />;
                        }}
                    />

                    <PrivateRoute exact path="/booking-overview" component={BookingOverview} userRole={'admin'} />

                    <Route
                        exact
                        path="/pending-summary"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <ReconcileSummary department={['Department Handling Sales']} {...routeParams} />;
                        }}
                    />

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList schema={bookingSchema} queries={bookingSchema.pendingBookings} {...routeParams} />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => <GenericList queries={bookingSchema.billingQueries} schema={bookingSchema} {...routeParams} />}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList queries={bookingSchema.deliveryRequests} schema={bookingSchema} {...routeParams} />
                        )}
                    />
                    {/* Delivery Bookings Ends */}

                    <PrivateRoute exact path="/stock-transaction" component={StockTransactionRecord} userRole={'admin'} />

                    <PrivateRoute exact path="/stock-transfers" component={StockTransferRecord} />
                    {/* Billing Requests */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    <Route exact path={'/report'} component={Report}></Route>

                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={smSteps} list={TaskList} form={TaskForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
