import { Requests, StatusLogs, StatusMasters, Branches, StockTransactions, Vehicles } from '.';
import { cityKeys } from '../modules/global-config';
import FirebaseUtils from '../utils/firebase.utils';
import Base from './base';
import StatusLog from './status_logs';
import StatusMaster from './status_master';

var cityKey = cityKeys

class StockTransfer extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'stock_transfers';
    }

    get path() {
        return `stock_transfers`;
    }

    get getName() {
        return `stock_transfers`;
    }


    /**
     * Create stock transfer Record 
     * @param {*} request 
     * @param {*} vehicle 
     * @param {*} user 
     * @param {*} batch 
     * @returns 
     */


    createRequest = async (request, vehicle, user, values, batch) => {

        var status, statusForLog

        if (user.role === 'sm' && user.locations[0] === this.getCityName(vehicle.dealerCode)) {
            status = 'approved'
            statusForLog = 'STCKAPPROVED'
        }

        // When converting virtual to actual stock transfer from location should be that mentioned in virtual transfer
        // In such cases considering vehicle dealercode is not possible,So we get fromDealercode with from_branch_id
        var result = await Branches.getRecord(values.location_from)
        let dealerCode
        dealerCode = result.dealer_code
        status = "requested"
        statusForLog = 'STCKREQUESTED'



        let params = {
            // ...request,
            remarks: values.remarks,
            dealerCode: dealerCode,
            toDealerCode: this.dealerCode(request.city),
            from: this.getCityName(dealerCode),
            to_stock_point_id: values && values.to_yard_id ? values.to_yard_id : null,
            from_stock_point_id: values && values.from_yard_id ? values.from_yard_id : null,
            transaction_date: new Date(),
            driver_id: values && values.driver_id ? values.driver_id : null,
            to: request.city,
            status: status,
            vinNo: request.vinNo,
            type: 'outward',
            created_by_name: user.name,
            deleted_at: null,
            deleted_by: null,
            accounts_status: null,
            sub_mode: values.sub_mode,
            active: true
        };

        if (request.id) {
            request = {
                ...request,
                requestId: request.id,
                bookingNo: request.bookingNo,
            }
        }
        // create mode in stocktransfer when generate gate pass for delivery 
        if (request.mode) {
            params = {
                ...params,
                mode: request.mode
            }

        }


        // To create a stockRef record and added with params
        var stockRef = await this.getRecordReference();

        await batch.set(stockRef, this.appendDefaultValues(params));
        // If mode(indicates stock transfer is create dfor delivery)
        // actual_delivery in request is updated with stock transfer record id
        if (request.mode && request.mode === 'delivery') {
            var requestRef = await Requests.getRecordReference(request.id)

            await batch.update(requestRef, { actual_delivery: { ...request.actual_delivery, stock_transfer_id: stockRef.id, status: 'Gatepass Taken' }, delivery: { ...request.delivery, status: 'Gatepass Taken' } })
        }
        // Create status log record
        var result = {
            id: stockRef.id,
            vinNo: request.vinNo
        }

        await this.createStatusLog(statusForLog, result, batch, null).then((res) => {
            // batch.commit().then(() => {
            //     // message.success('Request For Stock Transfer Created');
            // });
        });
        return stockRef;

    };

    createInwardRequest = (request) => {
        let params = {
            dealerCode: request.toDealerCode,
            fromDealerCode: request.dealerCode,
            from: request.from,
            to: request.to,
            requestId: request.requestId,
            bookingNo: request.bookingNo,
            status: 'done',
            vinNo: request.vinNo,
            type: 'inward'
        };

        return params;
    };

    /**
     * Create record in status log 
     * @param {*} identifier 
     * @param {*} content 
     * @param {*} batch 
     * @param {*} value 
     */

    createStatusLog = async (identifier, content, batch, value) => {
        let values
        // query using  identifier,mode
        let queries = [
            {
                field: 'identifier',
                value: identifier,
            },
            {
                field: 'mode',
                value: 'STOCKTRANSFER',
            },
        ];
        // get status master 
        let result = await StatusMasters.get(queries);

        var statusRef = await StatusLogs.getRecordReference();

        values = {
            status_id: result.status_masters[0].id,
            referrence_id: content.id,
            status_name: result.status_masters[0].name,
            reference_number: content.vinNo,
            remarks: value ? value.remarks : null,
        }
        // create record in status log 
        await batch.set(statusRef, StatusLogs.appendDefaultValues(values));

    }


    /**
     * Cancel stock transfer Request
     * 
     * 1. Virtal  Trans Out started - cancel stock transfer outward record
     * 2. Virtual Tran Out Completed - cancel both outward stock transfer and stock transaction record
     * 3. Virtual Trans In Started 
     * 4. Virtual Tran In Completed - In cases 3 and 4 both outward and inward sotck transfers , corresponding stock transactions and vehicle location is updated
     *    
     */
    cancelStockTransferRequest = async (stockTransfer, batch) => {
        let params = {
            status: "cancelled",
            active: false
        };


        //If stock transfer type is normal then cancellation is done only when status is requested 
        if (stockTransfer.sub_mode === "normal") {

            if (stockTransfer.status === "requested") {

                await this.update(stockTransfer.id, params)

                /**
                 * Update status in status logs 
                */
                let identifier

                identifier = 'STCKCANCELLED'

                let values = null

                return this.createStatusLog(identifier, stockTransfer, batch, values)
            }
        } else {
            // Update stock transfers, both inward and outward type
            // and also stock transactions

            await this.update(stockTransfer.id, params)

            // Update stock transaction status
            if (stockTransfer.stock_transaction_id) {
                await StockTransactions.update(stockTransfer.stock_transaction_id, params)
            }

            // If stock transfer is done(in case of virtual), then both inward and outward stock transfer and corresponding stock transaction has to be inactive
            if (stockTransfer.status === "done") {

                // Get and update the stock transfer of type inward, with batch_id
                await this.get([{ field: "batch_id", value: stockTransfer.id }]).then(async (result) => {
                    if (result) {
                        var inwardStockTransfer = result['stock_transfers'][0]
                        console.log(inwardStockTransfer)

                        // Update the status
                        this.update(inwardStockTransfer.id, params)

                        // Update status of stock transaction
                        StockTransactions.update(inwardStockTransfer.stock_transaction_id, params)

                        // If cancellation is after virtual stock transfer is complete then even vehicle dealercode and stock point id is to be reverted
                        Vehicles.update(stockTransfer.vinNo, { stock_point_id: stockTransfer.from_stock_point_id, dealerCode: cityKey[stockTransfer.from] })

                    }
                })
            }

            /**
           * Update status in status logs 
           */
            let identifier

            identifier = 'STCKCANCELLED'

            let values = null

            return this.createStatusLog(identifier, stockTransfer, batch, values)

        }
    }
}

export default StockTransfer;
