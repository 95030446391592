import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/**
 * Module Routes is for resources
 *
 * @param {*} param
 *
 */
export default function ModuleRoutes({ match, model, schema, columns, ...props }) {
    const { path } = model;

    return (

        <Switch>
            <Route exact path={`/${path}`} component={(...routeParams) => <model.ListComponent {...routeParams} {...props} model={model} />} />

            <Route exact path={`/${path}/create`} render={(routeParams) => <model.AddComponent {...routeParams} {...props} model={model} />} />

            <Route exact path={`/${path}/:id`} component={(routeParams) => <model.DetailComponent {...routeParams} {...props} model={model} />} />

            <Route path={`/${path}/:id/e/`} render={(routeParams) => <model.EditComponent {...routeParams} {...props} model={model} />} />

            <Route exact path={`/upload/file`} render={(...routeParams) => <model.UploadComponent {...routeParams} {...props} model={model} />} />
        </Switch>
    );
}
