import React, { useState, useEffect, useContext } from 'react';

import { Checkbox, Modal, Button, Tag } from 'antd';

// import './reconcile-summary.scss';

import VoucherInfo from './../voucher-info/voucher-info';

import PayoutInfo from './../payouts/payout-info';

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function PayoutCollectionCard({ receipt = {}, collection = {}, callback, onSelect }) {
    console.log('collection', collection);
    const [visible, setVisible] = useState(false);

    return (
        <div>
            <div className="card collection-card">
                <div className="left">
                    <h2 className="title">
                        {collection.customer_name} - {collection.customer_address}
                    </h2>
                    {/* <h4 className="title ">{collection.actual_total_payout}</h4> */}
                    <small>{collection.remarks}</small>
                    <Tag color="orange">{collection.type}</Tag>
                    <br />
                    Created at {collection.created_at} by {collection.created_by_name}
                </div>

                <div className="right">
                    <div>
                        <h3 className="title amount">{collection.net_amount}</h3>

                        {/* <VoucherInfo receipt={collection} /> */}

                        {/* {collection.verified === 'approved' ? <Tag color="success">Verified</Tag> : <Tag color="red">Unverified</Tag>} */}

                        {/* {collection.invoice ? <Tag color="success">Receipt Completed</Tag> : <Tag color="red">Receipt Pending</Tag>} */}

                        {/* {collection.accounts ? <Tag color="success">Accounts Completed</Tag> : <Tag color="red">Accounts Pending</Tag>} */}
                    </div>

                    <div>
                        <Button
                            onClick={() => {
                                setVisible(true);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            View
                        </Button>

                        {onSelect && (
                            <Checkbox checked={collection.selected} onChange={onSelect}>
                                Select
                            </Checkbox>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                destroyOnClose={true}
                title="Collection Detail"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <PayoutInfo collection={collection} />
            </Modal>
        </div>
    );
}
