import Base from './base';

class Setting extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'settings';
    }

    get path() {
        return `settings`;
    }

    getValue = (value) => {

        var queries = [{
            field: 'name',
            value: value
        }]

        return this.get(queries).then((result) => {

            return result.settings[0];

        })
    }
    

    /**
     * The role should not be able to view or download the phone number.
     * @returns 
     */
    getDisableDisplayPhoneNumber = () => {

        var queries = [{
            field: 'name',
            value: 'disable_display_phone_number',
        }]

        return this.get(queries).then((result) => {
            var role = result.settings[0].value;
            return role;

        })
    }

}

export default Setting;
