/**
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState, useContext } from 'react';

import { Button, Form, Input, Radio, message } from 'antd';

import moment from 'moment';

import { withRouter } from 'react-router-dom';

import { GlobalContext } from '../../../../Store';

// import './form-creator.scss'

import FirebaseUtils from './../../../../utils/firebase.utils';
import { PutData, PostData } from './../../../../utils/http.utils';

import FormCreator from './../form-creator/form-creator';
import { DocumentLogs, Enquirys, StatusLogs, StatusMasters } from '../../../../models';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { TextArea } = Input;

function BaseForm({ index, step, model, formContent, modelIndex, callback, record, department }) {
    const [content, setContent] = useState(formContent);

    const { user, defaultBranch } = useContext(GlobalContext);

    const onDataCollection = (values) => {
        return updateFirebaseData(step, values);
    };

    function updateFirebaseData(config, values) {
        // let removed_dates = {
        //     removed_at: moment().format('DD/MM/YYYY HH:mm'),
        //     removed_date: moment().startOf('day').valueOf(),
        //     removed_time: moment().valueOf()
        // }

        let params = {
            ...values,
            // ...removed_dates,
        };

        // if (step.post_status) {
        //     console.log('here');
        //     params[model].status = step.post_status;
        // }

        if (step.extraParams) {
            params = {
                ...params,
                // Note extra Params will override every previous value if the same key is used
                ...step.extraParams(content, params, user),
            };
        }
        // Follow Up updating in DocumentLogs
        if (step.caption === ('Enquiry Follow Up' || 'Missed Follow Up')) {
            let queries = [
                {
                    field: 'mode',
                    value: 'follow_up_status',
                },
                {
                    field: 'description',
                    value: params.follow_up_status,
                },
            ];

            StatusMasters.get(queries).then(async (result) => {
                let data = result.status_masters[0]
                if (data) {
                    params = {
                        ...params,
                        enquiry_status: data.category,
                        Type: data.priority,
                        status_id: data.id,
                        reference_number: content.id
                    }
                    // create record in status log 

                    await Enquirys.update(content['Enquiry No'], { enquiry_status: data.category, Type: data.priority }).then(async () => {
                        StatusLogs.add({ ...params }, { hideAlert: true }).then(async () => {
                            callback();
                        })
                    })
                }
            })
        }

        // return FirebaseUtils.updateBaseRecords(content.id, config.model, params).then(() => {
        //     message.success(`${model} details updated`);

        // Custom Actions to be done on submit

        if (step.onSubmit) {
            return step.onSubmit(content, values, department, user).then(() => {
                FirebaseUtils.updateBaseRecords(content.id, config.model, params).then(() => {
                    message.success(`${model} details updated`);

                    callback(params, values, department, user);
                });
            });
        } else {
            return FirebaseUtils.updateBaseRecords(content.id, config.model, params).then(() => {
                message.success(`${model} details updated`);

                callback(params, values);
            });
        }
        // });
    }

    return (
        <section className="request-form">
            {step.fields && step.fields.length ? (
                <FormCreator
                    config={step}
                    formContent={formContent}
                    modelIndex={modelIndex}
                    model={model}
                    onSubmit={onDataCollection}
                    department={department}
                />
            ) : null}
        </section>
    );
}

export default withRouter(BaseForm);
