import React from 'react'

import { Skeleton } from 'antd'

export default function PlaceHolder(props){
    return(
        <div className="skeleton-wrapper">
            {
                props.type == "listing" && [0,1,2,3,4].map((item, index) => <div className="card" key={index}>
                <Skeleton  paragraph={{ rows: 3 }}/>
            </div>)
            }
            {
                props.type == "report" &&<div className="detail-skeleton-wrapper">
                    <div className="title">
                        <Skeleton  paragraph={{ rows: 1 }}/>
                    </div>
                    <div className="card">
                        <Skeleton  paragraph={{ rows: 1 }}/>
                    </div>
                </div>
            }
            {/* {
                props.listFlag ? null : <Skeleton avatar paragraph={{ rows: 2}} />
            }
            <Skeleton avatar={props.listFlag} paragraph={{ rows: 2 }} />
            <Skeleton avatar={props.listFlag} paragraph={{ rows: 2 }} />
            <Skeleton avatar={props.listFlag} paragraph={{ rows: 2 }} />
            {
                props.listFlag ? <><Skeleton avatar={props.listFlag} paragraph={{ rows: 2 }} /><Skeleton avatar={props.listFlag} paragraph={{ rows: 1 }} /> </>: null
            } */}
      </div>
    )
}