import React, { useState, useEffect, useContext } from 'react';

import { Drawer, Button } from 'antd';

import FirebaseUtils from './../../../../utils/firebase.utils';

import CommentBlock from './../comment-block/comment-block';

import { MessageOutlined } from '@ant-design/icons';

import './comment-drawer.scss';

/**
 * Comment Drawer Component to show the comment count and enter comments
 *
 * @param {*} param0
 * @returns
 */
export default function CommentDrawer({ id, index }) {
    const [visible, setVisible] = useState(false);

    const [comments, setComments] = useState([]);

    useEffect(() => {
        loadComments(id);

    }, []);

    function loadComments(id) {
        FirebaseUtils.getComments(id).then((result) => {
            setComments(result);
            console.log(result)
        });
    }

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div className="comment-drawer">
            <Button
                onClick={() => {
                    setVisible(true);
                }}
                type="secondary"
                size={'small'}
            >
                Comment
            </Button>

            <Drawer width={'80%'} placement="right" closable={false} onClose={onClose} visible={visible}>
                {id && <CommentBlock id={id} loadComments={loadComments}/>}
            </Drawer>
            <MessageOutlined /> {comments.length}

           
        </div>
    );
}
