import React from 'react';

import { Typography, Row } from 'antd';

import ImageWrapper from './../../../../components/image-wrapper/image-wrapper';

const { Title } = Typography;

export default function DeliveryNote({ userRequest }) {
    const { delivery } = userRequest;

    return (
        <>
            {delivery && delivery.note ? (
                <div className="vehicle-card">
                    <Title level={3}>Delivery Note</Title>
                    <div gutter={0} className="detail-wrapper">
                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Delivery Note</span>
                                </Row>
                                <Row span={12}>
                                    <ImageWrapper type={Array.isArray(delivery.note) ? 'array' : 'object'} content={delivery.note} />
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
