/**
 *
 * Component to get details of all stock available
 */

import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Table, Space, Typography, DatePicker, Select, Form, Row, Col, Button, Tag } from 'antd';

import { GlobalContext } from '../../../../Store';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { Branches, StockPoints, StockTransactions } from '../../../../models';

import Rupee from '../../../../components/rupee/rupee';

import DateUtils from '../../../../utils/date.utils';

import moment from 'moment';

import './stock-transactions.scss';
import { cityKeys } from '../../../global-config';

const { Option } = Select;

const { Text, Title } = Typography;

var cityCode = cityKeys

const dateFormat = 'DD/MM/YYYY';
const formItemLayout = {
    layout: 'vertical',
};
export default function StockTransactionRecord() {
    const [form] = Form.useForm();

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [result, setResults] = useState([]);
    // set state of branches
    const [branches, setBranches] = useState([]);

    const [stockPoints, setStockpoints] = useState([]);

    const [location, setLocation] = useState(user.locations[0]);

    const [range, setRange] = useState(moment.tz('Asia/Calcutta').startOf('day'));

    const transferTypes = [
        { id: "normal", name: "Actual" },
        { id: "virtual", name: "Temporary" }
    ]

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Vin Number',
            dataIndex: 'item_identity_number',
            key: 'Vin Number',
        },

        {
            title: 'Yard ',
            // dataIndex: 'yard_name',
            render: (record) => {
                return (
                    <>
                        <Tag color={'green'}> {record.yard_name}</Tag>
                    </>
                );
            },
            key: 'yard_name',
        },
        {
            title: 'Basic Price',
            dataIndex: 'basic_price',
            key: 'basic_price',
        },

        {
            title: 'Stock Value',
            dataIndex: 'stock_value',
            key: 'stock_value',
        },
        {
            title: 'Purchase date',
            key: 'purchase_date',
            render: (record) => {
                const purchaseDate = record.purchase_date.toDate()
                return moment(purchaseDate).format('DD/MM/YYYY')

            }
        },

        {
            title: 'Age',
            // dataIndex: 'Stock Age',
            render: (record) => {
                const purchaseDate = record.purchase_date.toDate()
                let curdate = moment().valueOf();
                // let specifieddate = record['KIN Invoice Date'] && moment(record['KIN Invoice Date'], "DD/MM/YYYY").format("DD MM YYYY");

                let difference = curdate - moment(purchaseDate).valueOf();

                const diffInDays = difference / 86400000;
                // console.log('hhh', record['KIN Invoice Date']);
                // console.log(moment(record['KIN Invoice Date'], "DD/MM/YYYY").format("DD MM YYYY"));
                // console.log(curdate - moment(record['KIN Invoice Date'], "DD/MM/YYYY").valueOf());
                // console.log('diffInDays', diffInDays);
                return diffInDays.toFixed(0);
            },
            key: 'date',
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },


        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/vehicle/${text.item_identity_number}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        if (user) {
            // getStock(range);
            loadBranch();
        }
    }, [user]);

    /**
     *
     * To load list of branches
     */

    function loadBranch() {
        Branches.get().then((result) => {
            const filteredBranches = result.branches.filter(branch => branch.location); // Filter branches that have a location
            setBranches(filteredBranches);
        });
    }
    /**
     *
     * To load list of branches
     */

    function loadStockPoints(dealerCode) {
        var queries = [
            {
                field: 'dealerCode',
                value: dealerCode,
            },
        ];
        StockPoints.get(queries).then((result) => {
            setStockpoints(result.stock_points);
        });
    }

    /**
     * Get  stock data
     */
    function getStock(value) {
        var arr = [];
        var stock = [];

        var startOf = range.endOf('day');
        // converting date to timeStamp
        var timeStamp = DateUtils.getFirebaseTimestamp(startOf);

        // query values
        var query = [
            // the reason for  taking dealercode   the branch_id not present in stocktransaction
            {
                field: 'dealerCode',
                value: value.dealer_code,
            },

            {
                field: 'transaction_date',
                operator: '<=',
                value: timeStamp.toDate(),
            },
            {
                field: 'active',
                value: true
            }
        ];

        const config = {
            orderBy: 'transaction_date',
            order: 'desc'
        }

        // When select yard then get data also using yard id
        if (value.yard_id) {
            query.push({
                field: 'stock_point_id',
                value: value.yard_id,
            });
        }

        if (value && value.sub_mode) {
            query.push({
                field: "sub_mode",
                value: value.sub_mode
            })
        }

        setLoading(true);

        StockTransactions.get(query, config).then(async (result) => {
            // save result
            var res = result.stock_transactions

            var quantity;

            var finalstock = [];
            let yardDetails;

            // grouping using item_identity_number
            stock = groupBy(res, 'item_identity_number');
            //take all transaction record and grouping that records byitem_identity_number and foreach that records

            arr = Object.values(stock);
            arr.forEach(async (element) => {
                quantity = 0;
                element.forEach(async (req) => {
                    // adding quantity of each records

                    quantity = quantity + req.quantity;
                });

                // if quantity is greater than 0 its means the vehicle still exist in that location
                if (quantity > 0) {
                    finalstock.push(element[0]);
                }
            });
            //get stockpoints based on stocktransaction record
            finalstock = await Promise.all(
                finalstock.map(async (ele) => {
                    if (ele && ele.stock_point_id) {
                        // get stockpoints
                        yardDetails = await StockPoints.getRecord(ele.stock_point_id);
                        ele = {
                            ...ele,
                            yard_name: yardDetails.name,
                        };
                        return ele;
                    } else {
                        return ele;
                    }
                })
            );

            setResults(finalstock);

            setLoading(false);
        });
    }

    // //Get stock details of selected date
    function updateTime(dt) {
        setRange(dt);
    }

    //Find duplicate elements in an array

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    return (
        <div className="generate-listing">
            <div className="stock-register">
                <Title level={5}>Stock Register</Title>
            </div>
            {/** Stock filter Section start */}
            <div className="stock-filter">
                <Form onFinish={getStock} form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        {/** Select Branch section  */}
                        <Col xs={6} md={4} xl={4}>
                            <Form.Item
                                name="dealer_code"
                                label="Branch"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Branch',
                                    },
                                ]}
                            >
                                <Select allowClear onChange={loadStockPoints}>
                                    {branches.map((location, index) => (
                                        <Option value={location.dealer_code} key={index}>
                                            {location.location}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {/** Select Branch section  */}
                        </Col>
                        {/** Selet yard Start */}
                        <Col xs={6} md={4} xl={4}>
                            <Form.Item name="yard_id" label="Yard ">
                                <Select allowClear>
                                    {stockPoints.map((option, index) => (
                                        <Option value={option.id} key={index}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/** Selet yard Ends */}

                        <Col xs={6} md={4} xl={4}>
                            <Form.Item name="sub_mode" label="Transfer Type">
                                <Select allowClear>
                                    {transferTypes.map((type, index) => (
                                        <Option value={type.id} key={index}>
                                            {type.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        {/** Date picer Section start  */}
                        <Col xs={6} md={6} xl={6}>
                            <Form.Item
                                name="date"
                                label="Date"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please Select Date',
                            //     },
                            // ]}
                            >
                                <DatePicker
                                    onChange={(time) => {
                                        updateTime(time);
                                    }}
                                    inputReadOnly
                                    format={dateFormat}
                                    defaultValue={range}
                                    value={range}
                                    allowClear={false}
                                />
                            </Form.Item>
                        </Col>
                        {/**Date picker section ends  */}

                        <Button className="button-submit" type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Row>
                </Form>
            </div>
            {/** Stock filter Section ends */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <p> {result.length + ''} records</p>
                    <div className="table-container">
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.index}
                            dataSource={result}
                            columns={columns}
                            pagination={{
                                pageSize: 200,
                            }}
                            summary={(pageData) => {
                                let total = 0;

                                pageData.forEach((entry) => {

                                    if (entry.sub_mode != "virtual") {
                                        total += parseFloat(entry.stock_value);
                                    }
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Title level={5}>Total</Title>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            {/* <Table.Summary.Cell>
                                         </Table.Summary.Cell> */}

                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            {/* 
                                         <Table.Summary.Cell>
                                         </Table.Summary.Cell> */}

                                            {user.role === 'accounts' ? (
                                                <>
                                                    <Table.Summary.Cell>
                                                        <Title level={5}>
                                                            <Rupee value={total}></Rupee>
                                                        </Title>
                                                    </Table.Summary.Cell>
                                                </>
                                            ) : null}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
