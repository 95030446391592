import React, { useEffect, useState, Fragment, useContext, useRef } from 'react';

import { Typography, Button, Table, Popover, Skeleton, message, Row, Badge, Col, Modal, Form, Input, Popconfirm } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import moment from 'moment';

import './bill-details.scss';

import { useReactToPrint } from 'react-to-print';

import Rupee from './../../../../components/rupee/rupee';

import { Bills, BillDetails, Bookings, Requests, Cart, Settings, Cancellations, Branches } from './../../../../models/';

// import VehicleBill from './../../../../templates/vehicle-bill/vehicle-bill';

import VehicleInvoice from './../../../../templates/vehicle-invoice/vehicle-invoice';

import InvoicePreview from './../../../../templates/invoice-preview/invoice-preview';

import VehicleServiceInvoice from '../../../../templates/vehicle-service-invoice/vehicle-service-invoice';

import VehicleServiceBill from './vehicle-service-bill';

import { Link } from 'react-router-dom';

import { RollbackOutlined } from '@ant-design/icons';
import PayoutInvoice from '../../../../templates/payout-invoice/payout-invoice';

var companyLogo = require('../../../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

const { TextArea } = Input;

export default function BillDetail({ history, match }) {
    const [record, setRecord] = useState({ created_by: {}, attachments: [] });

    const { user } = useContext(GlobalContext);

    const [loader, setLoader] = useState(false);

    const [btnloading, setBtnLoading] = useState(false);

    const [returnBills, setReturnBills] = useState();

    const [matching, setMatching] = useState([]);

    const [cancelDay, setCancelDay] = useState();

    const [request, setRequest] = useState({});

    const [booking, setBooking] = useState({});

    useEffect(() => {
        loadData();
        loadCancelDay();
    }, []);

    const { params } = match;

    const { city, id } = params;

    //get value of days for cancellation
    function loadCancelDay() {
        let query = [
            {
                field: 'name',
                value: 'vas-billing-cancellation-days',
            },
        ];
        Settings.get(query).then((result) => {
            setCancelDay(result.settings[0].value);
        });
    }

    function loadData() {
        setLoader(true);

        let bill;
        // Load data
        return Bills.getRecord(id)
            .then((result) => {
                bill = result;

                //Set actual bill if return bill
                if (result.transaction_type === 'sales-return') {
                    history.push(`/bill/${result.bill_id}`);
                    history.go(0);
                }

                setRecord(result);

                return result;
            })
            .then((bill) => {
                var queries = [
                    {
                        field: 'bill_id',
                        value: bill.id,
                    },
                ];

                if (bill.type === 'basic' && bill.attached_with !== 'independent') {
                    return getBasicDetails(bill);
                } else {
                    if (bill.attached_with !== 'independent') {
                        getBasicDetails(bill);
                    }
                    return BillDetails.get(queries).then((result) => {
                        console.log(result);

                        // let billDetails = result.bill_details.map((item) => BillDetails.getDetailedPricing(item))

                        setMatching(result['bill-details']);
                    });
                }
            })
            .then(() => {
                let query = [
                    {
                        field: 'bill_id',
                        value: bill.id,
                    },
                ];
                return Bills.get(query).then((result) => {
                    if (result.bills.length) {
                        let arr = [];
                        return Promise.all(
                            result.bills.map((b) => {
                                let qry = [
                                    {
                                        field: 'bill_id',
                                        value: b.id,
                                    },
                                ];
                                return BillDetails.get(qry).then((result) => {
                                    arr.push({ bill: b, details: result['bill-details'] });
                                });
                            })
                        ).then(() => {
                            setReturnBills(arr);
                            setLoader(false);
                        });
                    } else {
                        setLoader(false);
                        return;
                    }
                });
            });

        // Get the bill with id
        // .FirebaseUtils.getRecord('bills', id)
        // .then((result) => {});
    }

    async function getBasicDetails(bill) {
        await Bookings.getRecord(bill.bookingNo).then((booking) => {
            setBooking(booking);

            Requests.getRecord(booking.requestId).then((result) => {
                setRequest(result);

                setLoader(false);
            });
        });
    }

    //cancellation invoice
    const cancelInvoice = (value) => {
        setBtnLoading(true);
        let invoice = record.invoice_number;

        let cancelValues = {
            ...value,
            user: user.name,
            branch_id: user.department_ids[0],
            bill_id: record.id,
        };

        let values = {
            ...value,
            ...record,
            cancellation_status: true,
        };

        return (
            Cancellations.cancelInvoice(cancelValues, values, record, matching)

                // return (
                //     FirebaseUtils.setRecord('cancellations', cancelValues)

                //         .then((result) => {

                //             values.cancellation_id = result;
                //             return FirebaseUtils.updateRecord('bills', record.id, values);
                //         })
                //         .then((res) => {
                //             let bill_id = values.id;

                //             var arr = [];

                //             var query = [
                //                 {
                //                     field: 'bill_id',
                //                     value: bill_id,
                //                 },
                //             ];

                //             BillDetails.get(query)
                //                 .then((detail) => {
                //                     console.log(detail);
                //                     let det = detail['bill-details'];

                //                     det.map((it) => {
                //                         arr.push(it);
                //                     });

                //                     return arr;
                //                 })

                //                 .then((arr) => {
                //                     console.log(arr);
                //                     return Promise.all(
                //                         arr.map((item) => {
                //                             let params = {
                //                                 ...item,
                //                                 cancellation_status: true,
                //                             };

                //                             return FirebaseUtils.updateRecord('bill-details', item.id, params);
                //                         })
                //                     );
                //                 });
                //         })
                //         .then((result) => {
                //             let config = {
                //                 hideAlert: true,
                //             };
                //             return Promise.all(
                //                 matching.map((item) => {
                //                     Cart.update(item.cart_id, { status: 'active' }, config);
                //                 })
                //             );
                //         })
                //         // .then(() => {
                //         //     let accessories = {
                //         //         ...request.accessories,
                //         //         status: 'Ready for Billing',
                //         //         // process: 'incomplete',
                //         //     };
                //         //     Requests.update(request.id, { accessories: accessories }, { hideAlert: true });
                //         // })
                .then(() => {
                    message.success(`Invoice ${invoice} has cancelled successfully. `);
                    setBtnLoading(false);
                    loadData();
                    history.goBack();
                })
        );
        // );
    };

    console.log(matching);

    return (
        <div className="allocation-form bill-details">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="">
                            <Title level={3}>Bill</Title>

                            <p className="page-desc">{loader ? '. Loading data' : ``}</p>

                            {loader ? (
                                <Skeleton />
                            ) : (
                                <>
                                    <div className="">
                                        {record.type === 'basic' && record.attached_with === 'independent' ? (
                                            <VehicleServiceInvoice printable={true} id={id} matching={matching} />
                                        ) : record.type === 'basic' ? (
                                            <VehicleInvoice id={id} printable={true} request={request} booking={booking} />
                                        ) : (
                                            // : record.type === 'finance-payout' ? (
                                            //     <PayoutInvoice printable={true} matching={matching} />
                                            // )
                                            <CollectionInfo
                                                collection={record}
                                                matching={matching}
                                                cancelInvoice={cancelInvoice}
                                                id={id}
                                                cancelDay={cancelDay}
                                                returnBills={returnBills}
                                                loader={loader}
                                                request={request}
                                                history={history}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}

function CollectionInfo({
    collection = { created_by: {}, attachments: [] },
    matching,
    returnBills,
    cancelInvoice,
    id,
    cancelDay,
    loader,
    request,
    history,
}) {
    const { user, defaultBranch } = useContext(GlobalContext);

    const [daydiff, setDayDiff] = useState();

    const [visible, setVisible] = useState(false);

    const [branch, setBranch] = useState();

    const print = useRef();

    useEffect(() => {
        findingDayDifference();
        loadBranch();
    });

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const tailLayout = {
        wrapperCol: { span: 16 },
    };

    console.log(collection);

    /*
     *
     * Calculating days difference of invoice created
     */
    const findingDayDifference = () => {
        var td = moment().format('DD/MM/YYYY');
        var id = moment(collection.created_date).format('DD/MM/YYYY');
        var invoice = moment(id, 'DD/MM/YYYY');
        var today = moment(td, 'DD/MM/YYYY');

        var result = today.diff(invoice, 'days');

        setDayDiff(result);
    };

    const address =
        user.locations[0] == 'kozhikode'
            ? { add1: 'Kannur Rd, Athanikkal, West Hill,', add2: 'Kozhikode, Kerala 673005' }
            : user.locations[0] == 'kannur'
                ? { add1: 'Kairali Nagar, Thottada(PO),', add2: 'Kannur, Kerala 670007' }
                : { add1: 'N.H.213, Ramapuram, Panangangara,', add2: 'Malappuram, Kerala 679321' };

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const getDateAndTime = (date) => {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>{moment(date).format('DD MMM YYYY')}</div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    //For brach details to add invoice
    const loadBranch = () => {
        var queries = [
            {
                field: 'dealer_code',
                value: collection.dealerCode,
            },
        ];
        Branches.get(queries).then((result) => {
            setBranch(result.branches);
        });
    };

    const handleModal = () => {
        setVisible(true);
    };

    const onFinish = (values) => {
        cancelInvoice(values);
        setVisible(false);
    };

    function confirm(e) {
        console.log(e);
        history.push(`/bill/return/${id}/${collection.type || collection.category}`);
    }

    function cancel(e) {
        console.log(e);
    }
    // const returnHandler = () => {
    // }

    return (
        <div>
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Cancel Invoice"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Form {...layout} name="basic" onFinish={onFinish}>
                    <Form.Item
                        name="remarks"
                        PlaceHolder="Enter remarks"
                        label="Remarks"
                        rules={[{ required: true, message: 'Please enter remarks!' }]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {(request && request.delivery && request.delivery.settlementTaken) || collection.type === 'finance-payout' ? null : (
                    <div style={{ padding: '1%' }}>
                        <Popconfirm title="Go to sales return page" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
                            <Button type="dashed" className="return-button">
                                Sales Return
                            </Button>
                        </Popconfirm>
                    </div>
                )}
                <div>
                    {collection.cancellation_status !== true ? (
                        <Button type="dashed" className="print-button" onClick={handlePrint}>
                            Print Invoice
                        </Button>
                    ) : null}
                </div>

                {daydiff <= cancelDay && (user.role === 'admin' || user.role === 'management' || user.role === "accounts") && collection.cancellation_status !== true ? (
                    <div>
                        <Button type="dashed" className="print-button" onClick={handleModal}>
                            Cancel Invoice
                        </Button>
                    </div>
                ) : collection.cancellation_status === true ? (
                    <Text type="danger">Cancelled Invoice</Text>
                ) : null}
            </div>

            <hr />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '2%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '30%', alignItems: 'start', justifyContent: 'flex-start' }}>
                    {collection.bookingNo ? (
                        <div className="detail-element">
                            <h3>Booking No :</h3>
                            <span>{collection.bookingNo}</span>
                        </div>
                    ) : null}
                    <div className="detail-element">
                        {collection.type === 'finance-payout' ? <h3>Bank Name:</h3> : <h3>Customer Name :</h3>}
                        <h2 className="title">
                            <Link to={`/requests/${request.id}`}>{collection.customer_name}</Link>
                        </h2>
                    </div>
                    {collection.customerID ? (
                        <div className="detail-element">
                            <h3>Customer Id :</h3>
                            <span>{collection.customerID}</span>
                        </div>
                    ) : null}
                    <div className="detail-element">
                        {collection.type === 'finance-payout' ? <h3>Branch:</h3> : <h3>Customer Address :</h3>}
                        <span style={{ textTransform: 'capitalize' }}>{collection.customer_address}</span>
                    </div>
                    {collection.is_gst_customer ? (
                        <div className="detail-element">
                            <h3>GSTN No :</h3>
                            <span>{collection.gst_number}</span>
                        </div>
                    ) : null}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '30%', alignItems: 'start', justifyContent: 'flex-center' }}>
                    {collection.customer_phone ? (
                        <div className="detail-element">
                            <h3>Customer Phone :</h3>
                            <span>{collection.customer_phone}</span>
                        </div>
                    ) : null}
                    {collection.customer_email ? (
                        <div className="detail-element">
                            <h3>Customer Email :</h3>
                            <span>{collection.customer_email}</span>
                        </div>
                    ) : null}
                    {collection.customer_state ? (
                        <div className="detail-element">
                            <h3>Customer State :</h3>
                            <span>{collection.customer_state}</span>
                        </div>
                    ) : null}
                    {collection.customer_postal_code ? (
                        <div className="detail-element">
                            <h3>Customer Pin :</h3>
                            <span>{collection.customer_postal_code}</span>
                        </div>
                    ) : null}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '30%', alignItems: 'start', justifyContent: 'flex-end' }}>
                    <div className="detail-element">
                        <h3>Type :</h3>
                        <span>{collection.type}</span>
                    </div>

                    <div className="detail-element">
                        <h3>Dealer Code :</h3>
                        <span>{collection.dealerCode}</span>
                    </div>

                    <div className="detail-element">
                        <h3>Dealer GST No</h3>
                        <span>{branch && branch[0].gst_number}</span>
                    </div>

                    <div className="detail-element">
                        <h3>Invoice Number :</h3>
                        <span>{collection.invoice_number}</span>
                    </div>
                    <div className="detail-element">
                        <h3>Created By :</h3>
                        <span>
                            {collection.created_by_name}
                            <br />
                            At: {collection.created_at}
                        </span>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: '16px' }}>
                <TableView data={matching} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '2%' }}>
                    <table>
                        <tr>
                            <td>
                                <h3>Bill Discount :</h3>
                            </td>
                            <td>
                                <h2 className="amount">{collection.bill_discount_amount}</h2>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>Total Discount :</h4>
                            </td>
                            <td>
                                <h3 className="amount">
                                    {parseFloat((collection.item_discount_amount + collection.bill_discount_amount).toFixed(2))}
                                </h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3>Total Amount :</h3>
                            </td>
                            <td>
                                <h1 className="amount">{collection.net_amount}</h1>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            {returnBills && returnBills.length ? (
                <Fragment>
                    <Title level={4}>
                        <RollbackOutlined twoToneColor="#eb2f96" /> Return Bills
                    </Title>
                    {returnBills.map((r) => {
                        console.log(returnBills);
                        return (
                            <Fragment>
                                <div style={{ marginTop: '16px' }}>
                                    <Title level={5}>{r.bill.invoice_number}</Title>

                                    <TableView data={r.details} key={returnBills.length} />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '2%' }}>
                                        <table>
                                            <tr>
                                                <td>
                                                    <h3>Bill Discount :</h3>
                                                </td>
                                                <td>
                                                    <h2 className="amount">{r.bill.bill_discount_amount}</h2>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h4>Total Discount :</h4>
                                                </td>
                                                <td>
                                                    <h3 className="amount">
                                                        {parseFloat((r.bill.item_discount_amount + collection.bill_discount_amount).toFixed(2))}
                                                    </h3>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h3>Total Amount :</h3>
                                                </td>
                                                <td>
                                                    <h1 className="amount">{r.bill.net_amount}</h1>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    })}
                </Fragment>
            ) : null}

            <div className="print-block" style={{}}>
                <WrapperClass ref={print}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                borderBottom: '1px dashed #2f3e4c',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <div>
                                <img className="logo-welcome" src={companyLogo} alt="Logo" />
                                <Title level={3}>Invoice #{collection.invoice_number}</Title>
                            </div>
                            <div>
                                <div className="address-block">
                                    <div style={{ fontSize: '12px' }}>DKH Kia ,</div>
                                    <div style={{ fontSize: '10px' }}>{address['add1']} </div>
                                    <div style={{ fontSize: '10px' }}>{address['add2']}</div>
                                </div>
                                <div className="date-and-time" style={{}}>
                                    {collection.created_at}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '16px' }}>
                            <div style={{ flexBasis: '50%' }}>
                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Invoice Number</div>
                                <div>{collection.invoice_number}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Invoice Date</div>
                                <div style={{ fontSize: '12px' }}>{moment(collection.invoice_date_millisecond).format('DD/MM/YYYY')}</div>

                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Dealer GST No</div>
                                <div style={{ fontSize: '12px' }}>{branch && branch[0].gst_number}</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '16px' }}>
                            <div style={{ flexBasis: '50%' }}>
                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Bill To</div>
                                <div style={{ fontSize: '13px', fontWeight: '600' }}>
                                    {collection.customer_name ? collection.customer_name : collection.to_name}
                                </div>
                                <div style={{ fontSize: '12px', width: '250px' }}>{collection.customer_address}</div>
                                <div style={{ fontSize: '12px' }}>{collection.customer_state}</div>
                                <div style={{ fontSize: '12px' }}>{collection.customer_phone}</div>
                                <div style={{ fontSize: '12px' }}>{collection.customer_email}</div>
                            </div>

                            {collection.is_gst_customer ? (
                                <div style={{ flexBasis: '50%' }}>
                                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>GSTN No</div>
                                    <div>{collection.gst_number}</div>
                                </div>
                            ) : null}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <TableView data={matching} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <div style={{ display: 'flex', width: '25%', flexDirection: 'column', alignItems: 'start', paddingTop: '2%' }}>
                            <table>
                                <tr>
                                    <td>
                                        <h4>Bill Discount :</h4>
                                    </td>
                                    <td>
                                        <h3 className="amount">{collection.bill_discount_amount}</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Total Discount :</h4>
                                    </td>
                                    <td>
                                        <h3 className="amount">
                                            {parseFloat((collection.item_discount_amount + collection.bill_discount_amount).toFixed(2))}
                                        </h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Total Amount :</h4>
                                    </td>
                                    <td>
                                        <h3 className="amount">{collection.net_amount}</h3>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </WrapperClass>
            </div>
        </div>
    );
}

function TableView({ data }) {
    console.log(data);

    const AccessoryColumns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
            width: 50,
        },
        // {
        //     title: 'Part No',
        //     dataIndex: 'no',
        //     render: (text, record) => {
        //         return <div>{text ? record['no'] : record['Part No']}</div>;
        //     },
        // },
        {
            title: 'Part Number',
            dataIndex: 'item_part_number',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div>
                            <div>{record['item_part_number']}</div>
                        </div>
                    </Fragment>
                );
            },
            width: 100,
        },

        {
            title: 'Part Name',
            dataIndex: 'item_name',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div>
                            <div>{text ? text : record['name']}</div>
                        </div>
                    </Fragment>
                );
            },
            width: 200,
        },

        {
            title: 'Rate',
            dataIndex: 'rate',
            width: 100,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 100,
        },
        {
            title: 'Taxable Amount',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.taxable_amount}</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'SGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.sgst_amount}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.sgst_percent}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'CGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cgst_amount}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cgst_percent}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'IGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.igst_amount}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.igst_percent}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'Discount',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{record.item_discount_amount}</div>;
            },
            width: 100,
        },
        {
            title: 'Net Amount',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{record.net_amount}</div>;
            },
            width: 100,
        },
    ];

    const PayoutColumns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
            width: 50,
        },

        {
            title: 'Item Name',
            dataIndex: 'item_name',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div>
                            <div>{text ? text : record['name']}</div>
                        </div>
                    </Fragment>
                );
            },
            width: 200,
        },

        {
            title: 'Actual Payout',
            dataIndex: 'rate',
            width: 100,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 100,
        },
        {
            title: 'Taxable Amount',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.taxable_amount}</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'SGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.sgst_amount}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.sgst_percent}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'CGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cgst_amount}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cgst_percent}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'IGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.igst_amount}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.igst_percent}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'Discount',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{record.item_discount_amount}</div>;
            },
            width: 100,
        },
        {
            title: 'Final Payout',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{record.net_amount}</div>;
            },
            width: 100,
        },
    ];

    let columns;

    if (data.length && data[0].type === 'finance-payout') {
        columns = PayoutColumns;
    } else {
        columns = AccessoryColumns;
    }

    let props = {};

    return (
        <Table
            rowKey={(entry) => entry.partNo}
            // scroll={{ x: true, y: 750 }}
            pagination={false}
            columns={columns}
            dataSource={data}
            {...props}
            size="small"
            summary={(pageData) => {
                let total = 0,
                    cgst_total = 0,
                    sgst_total = 0,
                    igst_total = 0;

                pageData.forEach((entry) => {
                    total += entry.net_amount;
                    cgst_total += parseFloat(entry.cgst_amount ? entry.cgst_amount : 0);
                    sgst_total += parseFloat(entry.sgst_amount ? entry.sgst_amount : 0);
                    igst_total += parseFloat(entry.igst_amount ? entry.igst_amount : 0);
                });

                total = Math.round(total);

                return (
                    <Fragment>
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Popover content={`Total`}>
                                    <Text>
                                        <Rupee value={sgst_total}></Rupee>
                                    </Text>
                                </Popover>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Popover content={`Total`}>
                                    <Text>
                                        <Rupee value={cgst_total}></Rupee>
                                    </Text>
                                </Popover>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Popover content={`Total`}>
                                    <Text>
                                        <Rupee value={igst_total}></Rupee>
                                    </Text>
                                </Popover>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Popover content={`Total`}>
                                    <Text>
                                        <Rupee value={total}></Rupee>
                                    </Text>
                                </Popover>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Fragment>
                );
            }}
        />
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}
