import React, { useEffect, useState, useContext } from 'react';

import { Tabs, Typography, Tag, Badge, Button, message, Alert, Select, Form, Modal, Input } from 'antd';

import './matching-booking1.scss';

import { GlobalContext } from './../../../../Store';

import { Location } from './../../../../utils/location.utils';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import moment from 'moment';

import { Bookings, Requests, StatusMasters, Vehicles } from '../../../../models';
import { cityKeys, dealerCodeKey } from '../../../global-config';


var dealer =dealerCodeKey

var badgeColor = {
    Allocated: 'error',
    'In transit': 'warning',
    'Free Stock': 'success',
};

const { Option } = Select;

const { TabPane } = Tabs;

const { TextArea } = Input;

const { Title, Text } = Typography;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }

    },
};

var cityKey = cityKeys

var filterContent = [
    { text: 'Date', value: 'Date' },
    { text: 'Location', value: 'Location' },
];

export default function MatchingBookings({ url }) {
    const [filter, setFilter] = useState(['CRITICAL CASE', 'URGENT']);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [stock, setStock] = useState({ vehicles: {}, lastUpdated: {} });

    const [bookings, setBookings] = useState([]);

    const [sortered, setSortered] = useState('Date');

    // for model
    const [form] = Form.useForm();

    const [modalLoading, setModalLoading] = useState(false);

    const [visible, setVisible] = useState(false);

    const [selected, setSelected] = useState({ item: '', booking: {} });

    const [status, setStatus] = useState([])


    useEffect(() => {
        loadData();
        getBookingStatus()
        return () => { };

    }, []);


    // Get booking status from status master
    async function getBookingStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'BOOKING',
            },
        ];

        const result = await StatusMasters.get(queries);
        setStatus(result.status_masters || []);
    }

    function loadData() {

        setLoading(true);

        Vehicles.getVehicles(url, null).then((result) => {

            var vehicles = result['vehicles'].filter((vehicle) => !vehicle.allocated);

            setStock({ vehicles: vehicles });

            var queries = [
                {
                    field: 'billing.status',
                    value: 'pending',
                },
            ];

            Bookings.get(queries).then((result) => {

                setLoading(false);

                var b = vehicles.map((entry) => {

                    let booking = entry;

                    let matching = result.bookings.filter((vehicle) => {

                        return (booking['Exterior Color Name'] === vehicle['Color']) && (booking['Variant'] === vehicle['Variant']) && (!vehicle.isCancelled && !vehicle.requestId && vehicle.current && vehicle.current.currentStatus);

                    });


                    booking.matching = matching;

                    // booking.matching.sort((a, b) => {
                    //     var aDays = moment().diff(moment(a['Booking Date'], 'DD/MM/YYYY'), 'days');

                    //     var bDays = moment().diff(moment(b['Booking Date'], 'DD/MM/YYYY'), 'days');

                    //     if (aDays < bDays) {
                    //         return 1;
                    //     }

                    //     if (aDays > bDays) {
                    //         return -1;
                    //     }
                    //     return 0;
                    // })

                    return booking;
                    // return result[entry];
                }).sort((a, b) => {

                    if (a.matching < b.matching) {

                        return 1;

                    }
                    if (a.matching > b.matching) {

                        return -1;

                    }

                    return 0;

                }).filter((booking) => booking.matching.length);

                setBookings(b);

            });
        });

    }

    /**
     *
     * Allocate already existing booking to
     * the team leader
     * Team leader has to verify after which the finance process woudl
     * start
     *
     * @param {*} param0
     */
    function createRequest({ vehicle, booking, index }) {
        // let city = user.locations[0];
        let { city } = Location.search();
        console.log(city)

        // Find the city of the booking
        let bookingCity = dealer[booking['Dealer Code']];

        let customer = {
            customerName: booking['Name of the Customer'],
            phone: booking['Contact Number'],
            customerID: booking['Customer ID'],
        };

        let newBooking = {
            status: 'Verification Pending',
            kec: booking['Consultant Name'],
            teamLeader: booking['Team Leader'],
            dealerCode: cityKey[city],
            deleted_at: null,
            deleted_by: null,

            // ...preference, // preference is considered here , not the booking choice .
            city: bookingCity, // override the city in the preference with the one in the booking
            ...{
                bookingNo: booking['Booking No'],
                created_by: user,
            },
            ...{
                customer: customer,
                created_at: moment().format('DD/MM/YYYY HH:mm'),
            },
        };

        if (booking['Committed Delivery Date']) {
            newBooking.expectedDate = booking['Committed Delivery Date'];
        }

        Requests.add(newBooking).then((result) => {
            Bookings.update(result.id, booking['Booking No'], true,).then(() => {
                bookings[index].requestId = result.id;

                setBookings(bookings);

                loadData();

                message.info('Request created');
            });
        });
    }

    function updateBookingStatus(item, Selectedbooking) {

        let status_name = status.filter((record) => record.id === item)[0];

        setSelected({ item: status_name, booking: Selectedbooking })
        setVisible(true);
    }

    /**
     * On edit model close
     */
    function closeModal() {
        setVisible(false);
    }

    /**
     * On edit model submit
     */
    function submitRequest() {
        setModalLoading(true);
        form.validateFields()
            .then((values) => {
                form.resetFields();

                let value = {
                    current: {
                        ...selected.booking.current,
                        currentStatus: selected.item.name,
                        currentStatus_id: selected.item.id,
                        remarks: values.remarks
                    }
                }
                return Bookings.updateBookingGeneric(selected.booking.id, value).then(() => {
                    message.success('Updated status successfully.');
                    setVisible(false);
                    setModalLoading(false);
                    setBookings([]);
                    loadData();
                })
            })
            .catch((info) => {
                setVisible(false);
                setModalLoading(false);
            });
    }

    function sortContent(item) {
        setSortered(item);
        setBookings([]);
        loadData();
    }

    // when allowclear take all status from statusmaster
    if (filter.length == 0) {
        status.forEach((element) => {
            filter.push(element.name);
        });

    }
    var finalBookings = bookings
        .map((entry) => {
            let b = entry;
            let matching = b.matching.filter((booking) => {
                if (booking.current && booking.current.currentStatus) {
                    return filter.indexOf(booking.current.currentStatus) !== -1;
                } else {
                    return true;
                }
            })
            b.matching = matching;
            return b;
        })

    if (sortered == 'Date') {
        finalBookings = bookings
            .map((entry) => {
                let b = entry;

                let matching = b.matching.filter((booking) => {
                    if (booking.current && booking.current.currentStatus) {
                        return filter.indexOf(booking.current.currentStatus) !== -1;
                    } else {
                        return true;
                    }
                })
                b.matching = matching;
                b.matching.sort((a, b) => {
                    var aDays = moment().diff(moment(a['Booking Date'], 'DD/MM/YYYY'), 'days');

                    var bDays = moment().diff(moment(b['Booking Date'], 'DD/MM/YYYY'), 'days');

                    if (aDays < bDays) {
                        return 1;
                    }

                    if (aDays > bDays) {
                        return -1;
                    }
                    return 0;
                })
                return b;
            })
    }

    return (
        <section className="matching-bookings">
            {
                loading ?
                    (
                        <PlaceHolder type="listing" />
                    )
                    :
                    (
                        <>
                            <div className="page-header">
                                <p>
                                    <small>{bookings.length} Vehicles</small>
                                </p>

                                <div className="action">
                                    <Button onClick={loadData} type="secondary" size={'small'}>
                                        Refresh
                                    </Button>

                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '200px' }}
                                        placeholder="Please select"
                                        defaultValue={filter}
                                        onChange={(item) => {
                                            setFilter(item);
                                            setBookings([]);
                                            loadData();
                                        }}
                                    // onChange={handleChange}
                                    >
                                        {
                                            status.map((data, key) => (
                                                <Option value={data.name}>{data.name}</Option>
                                            ))}
                                    </Select>
                                </div>
                            </div>

                            <Alert
                                message="Mission Zero"
                                description="Below are all the existing bookings and matching vehicles in stock accross group."
                                type="success"
                                showIcon
                                closable
                            />

                            {
                                finalBookings.map((booking, index) => {
                                    return (
                                        <VehicleCard
                                            index={index}
                                            vehicles={stock.vehicles}
                                            booking={booking}
                                            sortered={sortered}
                                            createRequest={({ vehicle, booking }) => {
                                                createRequest({ vehicle, booking, index });
                                            }}
                                            updateBookingStatus={(item, Selectedbooking) => {
                                                updateBookingStatus(item, Selectedbooking)
                                            }}
                                            sortContent={(item) => {
                                                sortContent(item)
                                            }}
                                            status={status}
                                        />
                                    );
                                })
                            }

                            <Modal
                                confirmLoading={modalLoading}
                                title="Update Status"
                                visible={visible}
                                onOk={submitRequest}
                                onCancel={closeModal}
                            >
                                <Form
                                    form={form}
                                    {...layout}
                                    name="new-record"
                                    layout="vertical"
                                    onFieldsChange={(fields) => {
                                    }}
                                    initialValues={{
                                    }}
                                >
                                    <Form.Item
                                        name={'remarks'}
                                        label="Remarks"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter remarks'
                                            }
                                        ]}>
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </>
                    )
            }
        </section>
    );
}

function VehicleCard({ vehicles, booking, createRequest, index, updateBookingStatus, sortContent, sortered, status }) {
    return (
        <div className="card booking-card" key={index}>
            <div className="left">
                <h4 className="title ">
                    <Title level={4}>{booking['Variant']}</Title>
                    <span>{` [ ${booking['Exterior Color Name']} ] `}</span>
                </h4>
                <div>{`${booking['Vin Number']}`}</div>
                <small className="status">
                    <Badge status={badgeColor[booking['Stock Status']]} />
                    {booking['Stock Location'] || booking['Stock Status']}
                </small>
            </div>
            <div className="booking-title">
                <div>
                    <h4 level={5}>Matching Bookings</h4>
                    <p>
                        <small>{booking.matching.length} Possible Allocations</small>
                    </p>
                </div>
                <div style={{}}>
                    Sort By :
                    <Select
                        style={{ width: '200px' }}
                        placeholder="Please select"
                        defaultValue={sortered}
                        onChange={(item) => {
                            sortContent(item)
                        }}
                    >
                        {
                            filterContent.map((item, index) => (
                                <Option key={item.value}>{item.value}</Option>
                            ))
                        }
                    </Select>
                </div>
            </div>
            <div className="vehicle-list">
                {
                    booking.matching.map((vehicle, key) => {
                        return (
                            <BookingCard
                                key={key}
                                booking={vehicle}
                                block={() => {
                                    createRequest({ vehicle, booking });
                                }}
                                updateBookingStatus={(item, Selectedbooking) => {
                                    updateBookingStatus(item, Selectedbooking)
                                }}
                                status={status}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
}

function BookingCard({ booking, unblock, block, updateBookingStatus, status }) {
    const { user } = useContext(GlobalContext);

    return (
        <div className="vehicle-card allocation-request">
            <div className="left">
                <div>
                    <h3 className="title">{booking['Name of the Customer']}</h3> - <small className="title">{booking['Contact Number']}</small>
                </div>
                {/* <div>
                    <h4 className="variant">{booking['Variant']}</h4> | <small> {booking['Color']}</small>
                </div> */}
                <h4>
                    {booking['Booking No']} - <Tag color="#2db7f5">{dealer[booking['Dealer Code']]}</Tag>
                </h4>
                <h5>
                    KEC : {booking['Consultant Name']} | Team Leader : {booking['Team Leader']}
                </h5>
                <p className="">
                    Booked on {booking['Booking Date']},{' '}
                    <Text type="danger">{`${moment().diff(moment(booking['Booking Date'], 'DD/MM/YYYY'), 'days')} days ago.`}</Text>
                </p>

                {
                    booking.current && booking.current.currentStatus ?
                        (
                            <>
                                <Tag color="magenta">{booking.current.currentStatus}</Tag>
                                <p className="recent-remarks">Recent Remarks : {booking.current.remarks}</p>
                                <div className="update-status">
                                    <Select
                                        style={{ width: '200px' }}
                                        placeholder="Please select"
                                        defaultValue={booking.current.currentStatus}
                                        onChange={(item) => {
                                            console.log(item);
                                            updateBookingStatus(item, booking);
                                        }}
                                        disabled={booking.current.currentStatus == 'ALLOCATED'}  >

                                        {status.map((data, key) => (
                                            <Option value={data.id}>{data.name}</Option>
                                        ))}

                                    </Select>
                                </div>
                            </>
                        )
                        :
                        (
                            <>
                                <p className="recent-remarks">{booking.current ? booking.current.currentStatus : 'No recent updates'}</p>
                            </>
                        )
                }
                {booking['Committed Delivery Date'] ? <p>Committed Delivery Date {booking['Committed Delivery Date']}</p> : null}
            </div>
        </div>
    );
}

