import React, { useEffect, useState, useRef, useContext } from 'react';

import {
    Table,
    Row,
    Col,
} from 'antd';

import Rupee from '../rupee/rupee';

import { Typography } from 'antd';

import { GlobalContext } from '../../Store';

import moment from 'moment';

import './ledger.scss';

var companyLogo = require('../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

const bankDetailsRows = [
    {
        key: '1',
        name: 'ACCOUNT NO'
    },
    {
        key: '2',
        name: 'BENEFICIARY NAME'
    },
    {
        key: '3',
        name: 'BANK'
    },
    {
        key: '4',
        name: 'IFSC'
    },
    {
        key: '5',
        name: 'BRANCH'
    },
    {
        key: '6',
        name: 'MICR'
    }
];

const bankDetailColumns = [
    {
        title: 'Bank Details for Fund Transfer',
        dataIndex: 'name',
        key: 'name'
    }
];

const cartColumns = [
    {
        title: '#',
        // dataIndex: 'index',
        // render: (value, item, index) => index + 1
        render: (value, item, index) => {
            return <div style={{ height: '20px' }}>{index + 1}</div>;
        }
    },
    {
        title: 'Description',
        // dataIndex: 'OpAcHead',
        render: (text, record) => {
            return (
                <div style={{ height: '20px' }}>
                    {record.Narration || record.OpAcHead}
                </div>
            );
            // return (
            //     <SummaryDescription
            //         record={record}
            //         callback={record.callback}
            //     />
            // );
        }
    },
    {
        title: 'Amount',
        dataIndex: 'Amount',
        render: (text, record) => {
            return <div style={{ height: '20px' }}>{text}</div>;
        }
    }
];

const receiptColumns = [
    {
        title: '#',
        // dataIndex: 'index',
        render: (value, item, index) => index + 1
    },

    {
        title: 'Date',
        // dataIndex: 'VDate',
        render: (record) => moment(record.VDate).format('DD MMM YYYY')
    },

    {
        title: 'Type',
        // dataIndex: 'On Account Of',
        render: (text, record) => {
            return (
                <div style={{ maxHeight: '36px' }}>
                    <div>{record['OpActualAcHead']}</div>
                    <div style={{ fontSize: '10px', color: 'grey' }}>
                        {record['Type of Payment']}
                    </div>
                </div>
            );
        }
    },

    {
        title: 'Amount',
        dataIndex: 'Amount',
        render: (text, record) => {
            return (
                <div style={{ maxHeight: '36px' }}>
                    <div>{-record['Amount']}</div>
                </div>
            );
        }
    }
];

/**
 * Cart Table
 */
export default function CartTable({
    request,
    ledger,
    columns
}) {
    const [breakup, setBreakup] = useState(
        ledger.filter((entry) => entry.Trn === 1)
    );

    const [receipts, setReceipts] = useState(
        ledger.filter((entry) => entry.Trn === -1)
    );

    console.log(receipts);

    const [received, setReceived] = useState(() => receipts.reduce((a, b) => a + parseInt(b['Amount']), 0));

    console.log(received);

    const print = useRef();

    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);


    const getDateAndTime = () => {
        let date = new Date();
        // console.log(date);
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>
                    {moment(date).format('DD MMM YYYY')}
                </div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    return (
        <div className="summary">
            <div className="vehicle-card document-status">
                {/* Summary for Display */}
                <div className="display-block">
                    <>
                        <div className="page-header">
                            <div>
                                <img
                                    className="logo-welcome"
                                    src={companyLogo}
                                    alt="Logo"
                                />

                                <Title level={3}>Accounts Ledger</Title>
                            </div>
                           
                        </div>

                        {/* In/Out Summary */}

                        <div className="breakup">
                            <div
                                className="table-card"
                                title="Fund to be Collected">
                                <div className="breakup-wrapper">
                                    <div>
                                        <p level={3}>
                                            <strong>
                                                Fund to be Collected
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="button-container">
                                        {/* {(user.role === 'accounts' ||
                                            user.role === 'accessories' ||
                                            user.role === 'sm') &&
                                        !isDelivered ? (
                                            <Button
                                                type="dashed"
                                                onClick={showModal}
                                            >
                                                Add/Discount
                                            </Button>
                                        ) : (
                                            ''
                                        )} */}
                                    </div>
                                </div>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <PendingSummary
                                            // cart={cart}
                                            columns={columns}
                                            received={received}
                                            breakup={breakup}
                                            request={request}
                                            user={user}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div title="Fund Received" className="table-card">
                                <p level={3}>
                                    <strong>Fund Received</strong>
                                </p>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <ReceivedSummary receipts={receipts} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* In/Out Summary Ends */}
                    </>
                </div>
                {/* Summary for Display Ends */}

                {/* Below is only for printing */}
                <div className="print-block">
                    <WrapperClass ref={print}>
                        <div
                            className="page-header"
                            style={{ borderBottom: '1px dashed #2f3e4c' }}
                        >
                            <div>
                                <img
                                    className="logo-welcome"
                                    src={companyLogo}
                                    alt="Logo"
                                />

                                <Title level={3}>Proforma Invoice</Title>
                            </div>

                            <div>
                                <div className="address-block">
                                    <div style={{ fontSize: '12px' }}>
                                        DKH Kia ,
                                    </div>
                                    <div style={{ fontSize: '10px' }}>
                                        Kannur Rd, Athanikkal, West Hill,{' '}
                                    </div>
                                    <div style={{ fontSize: '10px' }}>
                                        Kozhikode, Kerala 673005
                                    </div>
                                </div>
                                <div className="date-and-time" style={{}}>
                                    {getDateAndTime()}
                                </div>
                            </div>
                        </div>

                        {/* In/Out Summary */}

                        <div
                            gutter={0}
                            className="detail-wrapper"
                            style={{ margin: '25px 0px 50px 0px' }}
                        >
                            <Row>
                                <Col span={12}>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>
                                                Customer
                                            </span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {request.customer.customerName.toUpperCase()}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>
                                                Place
                                            </span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px',
                                                    width: '50%'
                                                }}
                                            >
                                                {request.customer.place}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>
                                                KEC
                                            </span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {request.kec.toUpperCase()}
                                            </h3>
                                        </Row>
                                    </div>
                                </Col>

                                <Col>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>
                                                Phone
                                            </span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {request.customer &&
                                                    request.customer.phone}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>
                                                Variant
                                            </span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {request.variant}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>
                                                Vin Number
                                            </span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {request.allocation.vinNo}
                                            </h3>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col span={12}>
                                <div title="Fund to be Collected">
                                    <div gutter={0} className="detail-wrapper">
                                        <div className="detail-element">
                                            <PendingSummary
                                                received={received}
                                                breakup={breakup}
                                                disableExpand={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div title="Fund Received">
                                    <div gutter={0} className="detail-wrapper">
                                        <div className="detail-element">
                                            <ReceivedSummary
                                                received={received}
                                                receipts={receipts}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div
                            className="bank-details-section"
                            style={{
                                position: 'absolute',
                                bottom: '100px',
                                right: 0,
                                left: 0
                            }}
                        >
                            <Table
                                dataSource={bankDetailsRows}
                                columns={bankDetailColumns}
                                pagination={false}
                            />
                        </div>

                        <div
                            className="contact-block"
                            style={{
                                position: 'absolute',
                                bottom: '285px',
                                right: 0,
                                left: 0
                            }}
                        >
                            <div style={{ color: 'black' }}>
                                For queries please contact : +91 9947445566
                            </div>
                        </div>

                        <div
                            className="signature-block"
                            style={{
                                border: '1px solid #f0f0f0',
                                display: 'flex',
                                justifyContent: 'space-between',
                                textAlign: 'center',
                                height: '90px',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0
                            }}
                        >
                            <div
                                className="signature-item"
                                style={{
                                    flexBasis: '33%',
                                    borderRight: '1px solid #f0f0f0'
                                }}
                            >
                                <div
                                    style={{
                                        height: '25px',
                                        borderBottom: '1px solid #f0f0f0',
                                        padding: '1px 0px'
                                    }}
                                >
                                    Accounts Department
                                </div>
                                <div
                                    style={{
                                        color: 'grey',
                                        fontSize: '10px',
                                        position: 'absolute',
                                        bottom: '2px',
                                        left: '8px',
                                        textAlign: 'left'
                                    }}
                                >
                                    Authorised by{' '}
                                    <div
                                        style={{
                                            color: 'black',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {user && user.name}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="signature-item"
                                style={{
                                    flexBasis: '34%',
                                    borderRight: '1px solid #f0f0f0'
                                }}
                            >
                                <div
                                    style={{
                                        height: '25px',
                                        borderBottom: '1px solid #f0f0f0',
                                        padding: '1px 0px'
                                    }}
                                >
                                    Sales Department
                                </div>
                            </div>
                            <div
                                className="signature-item"
                                style={{ flexBasis: '33%' }}
                            >
                                <div
                                    style={{
                                        height: '25px',
                                        borderBottom: '1px solid #f0f0f0',
                                        padding: '1px 0px'
                                    }}
                                >
                                    Customer
                                </div>
                            </div>
                        </div>

                        {/* In/Out Summary Ends */}
                    </WrapperClass>
                </div>
                {/* Print Block Ends */}

               
            </div>
        </div>
    );
}

function PendingSummary({
    breakup,
    received,
}) {
    let props = {};

    return (
        <>
            <Table
                pagination={false}
                scroll={{ x: true }}
                rowKey={(record, key) => key}
                columns={cartColumns}
                dataSource={breakup}
                {...props}
                size="small"
                summary={(pageData) => {
                    let total = 0;

                    pageData.forEach((entry) => {
                        total += parseFloat(entry.Amount);
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                {/* {disableExpand ? null : (
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                )} */}

                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Total</Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <Text>
                                        <Rupee value={total}></Rupee>
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row>
                                {/* {disableExpand ? null : (
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                )} */}

                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    Fund Received
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>
                                        <Rupee value={-received}></Rupee>
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row>

                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    Balance to Pay
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>
                                        <strong>
                                            <Rupee
                                                value={total - (-received)}
                                            ></Rupee>
                                        </strong>
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </>
    );
}

/**
 *
 * Table to show summary of all the received data
 *
 * @param {*} param0
 */
function ReceivedSummary({ receipts }) {
    return (
        <Table
            pagination={false}
            // scroll={{ x: true }}

            // scroll={{ x: true, y: 750 }}
            rowKey={(record, key) => key}
            columns={receiptColumns}
            dataSource={receipts}
            size="small"
            summary={(pageData) => {
                let total = pageData.reduce(
                    (a, b) => a + parseInt(b['Amount']),
                    0
                );

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>Total</Table.Summary.Cell>

                            {/* <Table.Summary.Cell>
                            </Table.Summary.Cell> */}

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={-total}></Rupee>
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        />
    );
}


class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}
