/**
 *
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';

import AsyncSelect from 'react-select/async';

import ApiUtils from './../../../../utils/api.utils';

/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function ModeSeletor({ callback, department }) {
    /**
     * Load the matching heads
     */
    function loadModes(query) {
        // return ApiUtils.getHeads(query).then((result) => {
        //     return result.map((item) => {
        //         return {
        //             value: item.ac_code,
        //             label: item.ac_desc,
        //         };
        //     });
        // });
        return ApiUtils.getModes('Net Banking', department).then((result) => {
            return result.map((item) => {
                return {
                    value: item.ac_code,
                    label: item.ac_desc,
                };
            });
        });
    }

    return (
        <div className="head-selector">
            <AsyncSelect onChange={callback} cacheOptions defaultOptions loadOptions={loadModes} />
        </div>
    );
}

export default withRouter(ModeSeletor);
