import moment from 'moment';

import FirebaseUtils from '../../utils/firebase.utils';

import { message,Tag } from 'antd';

import { Link } from 'react-router-dom';
import { Requests } from '../../models';

/**
 * Schema for the table
 */
export const bookingSchema = {


    dateField: 'bookingDate',


    pendingBookings: [{
        field: 'billing.status',
        value: 'pending',
        // operator: '!='

    }],

    billingQueries: [{
        field: 'billing.status',
        value: 'done'
    },
    {
        field: 'delivery.status',
        value: 'pending'
    }],

    deliveryQueries: [{
        field: 'delivery.status',
        value: 'done'
    }],


}





/**
 * Structure for Booking Listing and Add
 */
export const bookingColumns = [

    {
        type: 'input',
        caption: 'Booking No',
        field: 'Booking No'
    },
    {
        field: 'Name of the Customer',
        type: 'input',
        caption: 'Customer Name'
    },
    {
        field: 'Contact Number',
        type: 'input',
        caption: 'Contact Number'
    },
    {
        field: 'Booking Date',
        type: 'input',
        caption: 'Booking Date'
    },

    {
        caption: 'KEC',
        field: 'Consultant Name',
        type: 'input',
    },

    {
        caption: 'Request',
        field: 'requestId',
        type: 'input',
    },
]
export const rmSteps = [
    // Task Routes

    {
        caption: 'Approve for Billing',
        path: '/billing-approval',
        redirect_to: 'billing-approval-form',
        queries: [
            {
                field: 'billing.status',
                value: 'requested',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        Card: RequestCard,
    },

    {
        caption: 'Approve for Delivery',
        path: '/delivery-approval',
        redirect_to: 'delivery-approval-form',
        queries: [
            {
                field: 'delivery.status',
                value: 'requested',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        Card: RequestCard,
    },

    // {
    //     caption: 'Approve for Cancellation',
    //     path: '/cancellation-approval',
    //     redirect_to: 'cancellation-approval-form',
    //     model: 'cancellations',
    //     queries: [
    //         {
    //             field: 'status',
    //             value: 'Refund Approval Pending'
    //         }
    //     ],
    //     Card: CancelCard
    // },

    // Billing Form
    {
        isForm: true,
        caption: 'Billing Approval Form',
        path: '/billing-approval-form/:city/:id',
        model: 'billing',
        post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (userRequest, params) => {
            let creation = {
                approved_at: moment().format('DD/MM/YYYY HH:mm'),
                approved_date: moment().startOf('day').valueOf(),
                approved_time: moment().valueOf(),
            };

            return {
                billing: {
                    ...userRequest.billing,
                    ...params,
                    ...creation,
                },
            };
        },
    },

    // Delivery Form
    {
        isForm: true,
        caption: 'Delivery Approval Form',
        path: '/delivery-approval-form/:city/:id',
        model: 'delivery',
        post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (userRequest, params) => {
            let creation = {
                approved_at: moment().format('DD/MM/YYYY HH:mm'),
                approved_date: moment().startOf('day').valueOf(),
                approved_time: moment().valueOf(),
            };

            return {
                delivery: {
                    ...userRequest.delivery,
                    ...params,
                    ...creation,
                },
            };
        },
    },

    // Cancel Form
    // {
    //     isForm: true,
    //     caption: 'Cancellation Approval Form',
    //     path: '/cancellation-approval-form/:city/:id',
    //     model: 'cancellations',
    //     endpoint: 'cancellations',
    //     // post_status: 'approved',
    //     fields: [
    //         {
    //             type: 'textarea',
    //             caption: 'Remarks',
    //             field: 'remarks',
    //         },
    //     ],
    //     extraParams: (userRequest, params) => {
    //         let creation = {
    //             refund_approved_at: moment().format('DD/MM/YYYY HH:mm'),
    //             refund_approved_date: moment().startOf('day').valueOf(),
    //             refund_approved_time: moment().valueOf(),
    //             status: 'Refund Payment Pending',
    //         };

    //         return {
    //             ...params,
    //             ...creation,
    //         };
    //     },
    //     onSubmit: (request, param) => {
    //         let params = {
    //             cancellation: {
    //                 id: request.id,
    //                 status: 'Refund Payment Pending',
    //             },
    //         };

    //         return FirebaseUtils.updateBookingGeneric(request.bookingId, params).then(() => {
    //             message.success('Booking Cancel Request Updated');
    //         });
    //     },
    // },

    // Active Allocations
    {
        caption: 'Active Allocations',
        path: '/active-allocations',
        redirect_to: 'active-allocation-form',
        model: 'requests',

        queries: ({ user }) => {
            return [
                {
                    field: 'allocation.status',
                    operator: 'in',
                    value: ['requested', 'approved', 'Allocated'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    // Allocation Form
    {
        isForm: true,
        caption: 'Follow up for a quick retail',
        path: '/active-allocation-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // DMS Billing Allocations
    {
        caption: 'DMS Billed Cases',
        path: '/dms-billed-cases',
        redirect_to: 'actual-billing-form',
        model: 'requests',
        queries: ({ user }) => {
            return [
                {
                    field: 'billing.status',
                    operator: 'in',
                    value: ['requested', 'approved', 'Invoiced'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    // Allocation Form
    {
        isForm: true,
        caption: 'Follow up do the actual billing',
        path: '/actual-billing-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // Disabled temporarily as the new changes for unlinking delivery note is done
    // Delivery Note Taken Cases
    // {
    //     caption: 'Delivery Note Taken',
    //     path: '/delivery-note-taken',
    //     redirect_to: 'delivery-note-taken-form',
    //     model: 'requests',
    //     queries: ({ user }) => {
    //         return [
    //             {
    //                 field: 'delivery.status',
    //                 operator: 'in',
    //                 value: ['requested', 'approved', 'Delivery Note Taken'],
    //             },
    //             {
    //                 field: 'actual_delivery.status',
    //                 value: 'pending',
    //             },
    //             {
    //                 field: 'status',
    //                 operator: '!=',
    //                 value:'removed'
    //             },
    //         ];
    //     },
    //     Card: RequestCard,
    // },

    // Delivery Note Taken Form
    {
        isForm: true,
        caption: 'Follow up do the actual billing',
        path: '/delivery-note-taken-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // Delivery Pending Cases
    {
        caption: 'Delivery Pending',
        path: '/delivery-pending-cases',
        redirect_to: 'delivery-pending-form',
        model: 'requests',
        queries: ({ user }) => {
            return [
                {
                    field: 'actual_delivery.status',
                    operator: 'in',
                    value: ['pending', 'Settlement Pending', 'Settlement Ready', 'Delivery Pending'],
                },
                {
                    field: 'actual_billing.status',                   
                    value: 'Invoiced'
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    // Delivery Pending Form
    {
        isForm: true,
        caption: 'Follow up for a quick delivery',
        path: '/delivery-pending-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    {
        caption: 'Approve for Cancellation',
        path: '/cancellation-approval',
        redirect_to: 'cancellation-approval-form',
        model: 'cancellations',
        queries: [
            {
                field: 'status',
                value: 'Refund Approval Pending',
            },
        ],
        Card: CancelCard,
    },
    {
        caption: 'Waiting for Approval',
        path: '/waiting-approval',
        redirect_to: 'waiting-billing-form',
        model: 'requests',
        queries: [
            {
                field: 'actual_billing.status',
                value: 'Waiting for Approval',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        Card: RequestCard,
    },
    // Cancel Form

    {
        isForm: true,
        caption: 'Cancellation Approval Form',
        path: '/cancellation-approval-form/:city/:id',
        model: 'cancellations',
        endpoint: 'cancellations',
        // post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'mgt_remarks',
            },
        ],
        extraParams: (userRequest, params, user) => {
            let creation = {
                refund_approved_at: moment().format('DD/MM/YYYY HH:mm'),
                refund_approved_date: moment().startOf('day').valueOf(),
                refund_approved_time: moment().valueOf(),
                status: 'Refund Payment Pending',
                refund_approved_by: user.name,
            };

            return {
                ...params,
                ...creation,
            };
        },
        onSubmit: (request, param) => {
            let params = {
                cancellation: {
                    id: request.id,
                    status: 'Refund Payment Pending',
                },
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingId, params).then(() => {
                message.success('Booking Cancel Request Updated');
            });
        },
    },
    {
        isForm: true,
        caption: 'Waiting Billing',
        path: '/waiting-billing-form/:city/:id',
        model: 'requests',
        endpoint: 'requests',
        // post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'mgt_remarks',
            },
        ],
        extraParams: (userRequest, params, user) => {
            let creation = {
                management_initiated_at: moment().format('DD/MM/YYYY HH:mm'),
                management_initiated_date: moment().startOf('day').valueOf(),
                management_initiated_time: moment().valueOf(),
            };
            return {
                ...params,
                ...creation,
            };
        },
        onSubmit: (request, param) => {
            let params = {
                actual_billing: {
                    ...request.actual_billing,
                    status: 'requested',
                },
            };

            return Requests.update(request.id, params).then(() => {
                message.success('Successfully forwarded for billing to accounts ');
            });
        },
    },
]

function RequestCard({ request, step }) {
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}/${request.city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                <h4 className="title">{request.customer.phone}</h4>
                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>
                <h4>{request.color}</h4>
                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>
                Created at {request.created_at}
            </div>
        </Link>
    );
}


function CancelCard({ request = {}, index, step }) {
    let city = request.dealerCode == 'KL305' ? 'kozhikode' : request.dealerCode == 'KL306' ? 'kannur' :  request.dealerCode == 'KL307'?'malappuram':request.dealerCode == 'KL508'?'kasargod':request.dealerCode == 'KL505'?'kondotty':request.dealerCode=='KL507'?'vadakara':'tirur';;;

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customerName}</h2>
                <h4 className="title">Phone No : {request.phone}</h4>
                <h3 className="title ">Type : {request.type}</h3>
                <a href={`/bookings/${request.bookingId}`} style={{ zIndex: 1 }}>
                    {request.bookingId}
                </a>
                <h4>{request.remarks}</h4>
                <Tag color="purple">{request.status || 'Initial'}</Tag>
                Created at {request.approve_at}
            </div>
        </Link>
    );
}

