/**
 * Function to change the KEC from one to another for bulk selection
 * @author Anupama
 */

import React, { useEffect, useState, useContext } from 'react';

import { Select, Typography, Button, Form, Table, Space, Popconfirm, message } from 'antd';

import { Designations, Employees, Bookings, Requests } from '../../../../models';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import SELECT from 'react-select';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import './bulk-kec-change.scss';

const { Option } = Select;

const { Title } = Typography;

const layout = {
    labelCol: {
        xs: { span: 3 },
        sm: { span: 3 },
        lg: { span: 3 },
    },
    wrapperCol: {
        xs: { span: 5 },
        sm: { span: 5 },
        lg: { span: 5 },
    },
};

export default function BulkKecChange() {

    const [booking, setBooking] = useState([]);

    const [loading, setLoading] = useState(false);

    const [kec, setKec] = useState([]);

    const [toKec, setToKec] = useState();

    const [fromKec, setFromKec] = useState();

    const { user } = useContext(GlobalContext);

    const [form] = Form.useForm();

    const [btnloading, setBtnLoading] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [selectedRows, setSelectedRow] = useState([]);

    const onSelectChange = (keys, record) => {

        setSelectedRowKeys(keys);

        setSelectedRow(record);
    };

    useEffect(() => {

        getEmployee();

    }, []);

    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    /*
     ** To get the employees with designation as KEC
     */
     async function getEmployee() {

        return await Employees.get().then((result) => {

            var query = [
                {
                    field: 'designation',
                    value: 'KEC',
                },
            ];

            return Designations.get(query).then((res) => {
                var kecList = result.employees.filter((ele) => ele.designation === res.designations[0].id);


                //Preparing data for react-select
                let b = Object.keys(kecList).map((employee) => {
                    return { label: kecList[employee].fname + ' ' + kecList[employee].lname, value: kecList[employee].id };
                });

                setKec(b);

                return result.employees;
            });
        });
    }
    
    /*
     ** To change the current kec to new kec
     */
    async function changeKec() {

        // if (fromKec != toKec) {

            setBtnLoading(true);

            let selectedkec = await Employees.getRecord(toKec);

            var app = FirebaseUtils.getApp();

            var batch = app.batch();

            await Promise.all(
                selectedRows.map(async (item) => {

                    let query = [
                        {
                            field: 'bookingNo',
                            value: item.id,
                        },
                        {
                            field: 'delivery.status',
                            value: 'pending',
                        },
                    ];

                    let userRequest = await Requests.get(query);

                    await Employees.changeKec(selectedkec, item, user, userRequest, batch);
                })
            );

            await batch.commit();

            await message.success('KEC and Teamleader of these selected bookings are updated successfully');

            setBtnLoading(false);

            return window.location.reload();

        // } 
        // else {

        //     message.warning('You are selected same KEC. Please select another kec.');
        // }
    }

    /*
     * To list the bookings of the selected KEC
     */
    async function listing(kecid) {

        setFromKec(kecid.value);

        setLoading(true);

        let queries = [
            {
                field: 'consultant_id',
                value: kecid.value,
            },
            {
                field: 'delivery.status',
                value: 'pending',
            },
        ];

        // To get bookings corresponding to the queries
        let bookingResult = await Bookings.get(queries);

        let bookingRec = bookingResult.bookings;

        // Getting the consultant name and teamleader name and displaying in the table
        let result = await Promise.all(bookingRec.map(async (ele) => {

                if (ele.consultant_id) {

                    // To get the consultant name of the corresponding booking using its consultant_id
                    let kecRec = await Employees.getRecord(ele.consultant_id);

                    let kecName = kecRec.fname + ' ' + kecRec.lname;

                    let teamLeaderId;

                    if (kecRec.header_id.value) {

                        teamLeaderId = kecRec.header_id.value;

                    } else {

                        teamLeaderId = kecRec.header_id;
                    }

                    // To get the teamleader name of the corresponding booking using the consultant's header_id
                    let teamLeaderRec = await Employees.getRecord(teamLeaderId);

                    let teamLeaderName = teamLeaderRec.fname + ' ' + teamLeaderRec.lname;

                    ele = {
                        ...ele,
                        consultant: kecName,
                        teamleader: teamLeaderName,
                    };

                    return ele;
                } else {
                    return ele;
                }
            })
        );

        // To set result as booking for displaying it in the table
        setBooking(result);

        setLoading(false);
    }

    /*
     ** To get the kec to which the selected booking is assigned
     */
    async function getToKec(kecid) {

        setToKec(kecid.value);

    }


    /*
     ** To get the table in selection mode and using this selection to change the kec from one to another in bulk form
     */
    function TableView({ data, selectedRowKeys, onSelectChange }) {

        const rowSelection = {

            selectedRowKeys,

            onChange: onSelectChange,
        };

        var columns = [
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            },

            {
                title: 'Booking No',
                dataIndex: 'Booking No',
                key: 'Booking No',
            },
            {
                title: 'Dealer Code',
                dataIndex: 'Dealer Code',
                key: 'Dealer Code',
            },
            {
                title: 'Customer Name',
                dataIndex: 'Name of the Customer',
                key: 'name',
            },
            {
                title: 'Phone',
                dataIndex: 'Contact Number',
                key: 'Contact Number',
            },

            {
                title: 'Booking Date',
                dataIndex: 'Booking Date',
                key: 'date',
            },
            // {
            //     title: 'Consultant Name',
            //     dataIndex: 'Consultant Name',
            //     key: 'Consultant Name',
            // },
            {
                title: 'Consultant Name',
                dataIndex: 'consultant',
                key: 'Consultant Name',
            },
            {
                title: 'Team Leader',
                dataIndex: 'teamleader',
                key: 'Team Leader',
            },
        ];

        return (
            <Table
                loading={loading}
                width="100%"
                size="small"
                scroll={{ x: true }}
                // scroll={{ x: true, y: 750 }}
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                dataSource={data}
                columns={columns}
                pagination={false}
            ></Table>
        );
    }

    return (
        <section className="bulk-kec-change listing">
            <div className="page-header">
                <Title level={3}>Bulk KEC Change</Title>
                <div className="page-actions">
                    <div>
                        <Form
                            {...layout}
                            form={form}
                            name="new-record"
                            layout="horizontal"
                            // onFinish={changeKec}
                            initialValues={{}}
                        >
                            <Form.Item
                                name={'from_kec'}
                                label="From KEC"
                                rules={[
                                    {
                                        message: 'Please select from kec.',
                                        required: true,
                                    },
                                ]}
                            >
                                <SELECT style={{ width: '100%' }} options={kec} onChange={(e) => listing(e)} isClearable={true} />
                            </Form.Item>

                            <Form.Item
                                name={'to_kec'}
                                label="To KEC"
                                rules={[
                                    {
                                        message: 'Please select to kec.',
                                        required: true,
                                    },
                                ]}
                            >
                                <SELECT style={{ width: '100%' }} options={kec} onChange={(e) => getToKec(e)} isClearable={true} />
                            </Form.Item>

                            <Space size="middle">
                                <Popconfirm 
                                    title="Are you sure you want to change the kec of these selected booking and request records? "
                                    onConfirm={() => changeKec()}
                                    onCancel={() => {}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button disabled={selectedRowKeys.length === 0 || !toKec} loading={btnloading} type="secondary" >
                                        Assign Booking to Another KEC
                                    </Button>
                                </Popconfirm>
                            </Space>
                            <div style={{ marginTop: 16 }}>
                                <Space>
                                    <span>{selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ''}</span>
                                </Space>
                            </div>
                        </Form>
                    </div>

                    <div>
                        {booking.length ? (
                            <>
                                <TableView
                                    data={booking}
                                    selectedRowKeys={selectedRowKeys}
                                    onSelectChange={(keys, record) => {
                                        onSelectChange(keys, record);
                                    }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </section>
    );
}
