import React, { useEffect, useState, useRef, useContext } from 'react'

import XLSX from 'xlsx';

import { Space, Table, message, Tabs, Typography, Alert, Button, Upload, Form, Input, Select } from 'antd';

import { GlobalContext } from '../../../../Store';

import { DownOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Collapse } from 'antd';

import { List } from 'antd';

import './compare-gst.scss';

// import { saveAs } from 'file-saver';

var FileSaver = require('file-saver');


const { Panel } = Collapse;


const { TabPane } = Tabs;

const { Text, Title } = Typography;



export default function CompareGST({ history, report, index }) {


    const [gstReview, setgstReview] = useState({});

    const [roReview, setroReview] = useState({});


    const [records, setRecords] = useState([]);

    const [columns, setColumns] = useState([]);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [files, setFiles] = useState([])

    useEffect(() => {


        // gstRef.current.appendChild('hello')


    }, [user])


    var groupBy = function (xs, key) {

        return xs.reduce(function (rv, x) {

            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;

        }, {});
    };



    function handleFile(f) {

        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {

            const bstr = e.target.result;

            // let data = csvJSON(bstr);

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            console.log(wb, wb.SheetNames);

            const ws = wb.Sheets[wsname];

            const dms = wb.Sheets[wb.SheetNames[1]];


            const gstReport = XLSX.utils.sheet_to_json(ws, {
                // header: 0
                // range:0

                header: 2,
                range: 0
            });


            const roReport = XLSX.utils.sheet_to_json(dms, {
                // header: 0
                // range:0

                header: 2,
                range: 0
            });


            // console.log(gstReport, roReport);

            processData(gstReport, roReport);

        };

        if (rABS) reader.readAsBinaryString(f); else reader.readAsArrayBuffer(f);
    }


    function processData(gstReport, roReport) {

        // Find the keys of GST Report
        var gstReportData = groupBy(gstReport, 'Invoice No');

        // Find the keys of RO Report
        var roReportData = groupBy(roReport, 'Bill No');

        let gstReportKeys = Object.keys(gstReportData);

        // gstRef.appendChild('hello')

        let roReportKeys = Object.keys(roReportData);

        console.log('Finding Missing entries of gst report in ro report');

        var missingGST = [];

        gstReportKeys.forEach((entry) => {

            if (entry && roReportKeys.indexOf(entry) === -1) {

                console.log(entry, 'is Missing');

                missingGST.push(entry);

            }
        });

        setgstReview({

            entries: gstReportKeys,
            missing: missingGST
        })


        console.log('Finding Missing entries of ro report in gst report');

        var missingRO = [];


        roReportKeys.forEach((entry) => {

            if (entry && gstReportKeys.indexOf(entry) === -1) {

                console.log(entry, 'is Missing');

                missingRO.push(entry);

            }
        });

        setroReview({

            entries: roReportKeys,
            missing: missingRO
        })


        console.log(roReportKeys, gstReportKeys);


        console.log(roReportData);

        // Add columns from ro report to gst report

        var latestReport = gstReport.map((entry) => {

            var invoice = entry['Invoice No'];

            var matching = {};

            if (roReportData[invoice] && roReportData[invoice][0]) {

                matching = roReportData[invoice][0];
            }


            if (roReportKeys.indexOf(invoice) === -1) {

                console.log('Missing', invoice);

            }

            return {
                ...entry,
                insurance: matching['Ins. Comp Name']
            }

        })

        console.log(latestReport);

        var columns = Object.keys(latestReport[0]).map((key) => {

            return {
                dataIndex: key,
                title: key.toUpperCase()
            }

        })


        setColumns([...columns]);

        setRecords([...latestReport]);

    }

    const uploadProps = {
        onRemove: file => {

            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });

        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: file => {

            setFiles([...files, file]);

            return false;
        },
        files,
    };

    const SheetJSFT = [
        "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
    ].map(function (x) { return "." + x; }).join(",");



    return (
        <div className="compare-gst">

            <div className="page-header">

                <div className="left">
                    <Title level={4}>

                        Compare GST RO Billing and RO Billing Report

                    </Title>

                </div>

                <div className="table-hint right">

                    <div className='page-actions'>

                        {
                            loading ?
                                <Text type="secondary">
                                    Loading ..
                            </Text> :
                                null
                        }


                        <div className="button-container">

                            <>


                                <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                    <Button size={'small'} icon={<UploadOutlined />}>Select File</Button>
                                </Upload>

                            </>

                            {/* 
                            <Button onClick={() => {


                                var wb = XLSX.utils.table_to_book(document.getElementById('vehicle-card'), { sheet: "Sheet JS" });


                                function s2ab(s) {
                                    var buf = new ArrayBuffer(s.length);
                                    var view = new Uint8Array(buf);
                                    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                                    return buf;
                                }


                                FileSaver.saveAs(new Blob([s2ab(wb)], { type: "application/octet-stream" }), 'test.xlsx');


                            }} size={'small'}>Download</Button> */}


                        </div>

                    </div>

                </div>


            </div>




            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <>

                        <div className="main-content">


                            <div className="vehicle-card card" id="vehicle-card">


                                {
                                    records.length
                                        ?
                                        <>


                                            <div className="vehicle-card card">

                                                <strong>Review of GST Report</strong>


                                                <p>
                                                    {gstReview.entries.length} invoices found.
                                                </p>

                                                <p>
                                                    {gstReview.missing.length} are missing in RO Report.
                                                </p>


                                                <div className="missing">
                                                    {gstReview.missing.map((item) => <span className="space">{item}</span>)}
                                                </div>



                                            </div>

                                            <div className="vehicle-card card">

                                                <strong>Review of RO Report</strong>




                                                <p>
                                                    {roReview.entries.length} entries found.
                                                </p>

                                                <p>
                                                    {roReview.missing.length} are missing in GST Report.
                                                </p>

                                                <div className="missing">
                                                    {roReview.missing.map((item) => <span className="space">{item}</span>)}
                                                </div>

                                            </div>




                                            <Table
                                                id={'comparison-table'}
                                                loading={loading}
                                                size="small"
                                                scroll={{ x: true, y: 750 }}

                                                // scroll={{ x: true, y: 750 }}
                                                rowKey={record => record.id}
                                                // rowKey={record => record[model + 'Id']}
                                                dataSource={records}
                                                columns={columns}
                                                // onChange={handleTableChange}
                                                pagination={false}>
                                            </Table>






                                        </>
                                        :
                                        <>

                                            <Alert message="This page is built to help you identify the differences in DPOS and DMS Reports for Spares & Accessories in favour of GST Filing. This would work only for an input excel file with first Sheet as export from DMS GST RO Billing Report New and the second from RO Billing report. Any other file input would give unexpected results." type="info" showIcon />

                                        </>
                                }



                            </div>


                        </div>
                    </>
            }
        </div>
    )
}




function Thumbnail({ index, records }) {
    return (<>{index} - <small>({records.results.length} in DPOS) - ({records.matching.length} in DMS)</small></>);
}