import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Typography, Badge } from 'antd';


import Base from './base';

import { Link } from 'react-router-dom';

import { Comments } from './index';

import moment from 'moment';

const { Title } = Typography;

class Purchases extends Base {

    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'purchase';
    }

    get path() {
        return `purchase`;
    }

    get getName() {
        return `purchase`;
    }

    Card = ({ purchase }) => {

        return (<div className="vehicle-card card">
            <div className="left">
                <Title level={5}>{`Purchase on ${purchase['KIN Invoice Date']}`}</Title>


                <small className="status">
                    <Badge status={purchase.VinNo} />


                </small>
            </div>
            <div className="right card-buttons">


            </div>
        </div>)
    }

}

export default Purchases;

