import React, { useEffect, useState, useContext } from 'react'

import { Link } from 'react-router-dom'

import { Table, Dropdown, Menu, Typography, Button, Space } from 'antd';

import { GlobalContext } from '../../../../Store';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { OrderedListOutlined, DownOutlined } from '@ant-design/icons';


import FirebaseUtils from '../../../../utils/firebase.utils';


import moment from 'moment';

// import './manage-payments.scss';

const { Title, Text } = Typography;



export default function PendingWarranty({ history, report, index }) {

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState({ requests: [] });

    const [location, setLocation] = useState(user.locations[0]);

    useEffect(() => {

        if (user) {

            getRequests(location);

        }

    }, [user]);


    /**
     * Get stock Data
     */
    function getRequests(location) {

        setLoading(true);

        var queries = [{
            field: 'delivery.status',
            value: 'Delivered'
        }]


        FirebaseUtils.getRequests(location, queries).then((result) => {

            setLoading(false);

            let res = {
                requests: result.requests.filter((entry) => {
                    return !entry.warranty.note
                })
            }

            console.log(result);

            setResults(res);
        })
    }

    const menus = user.locations.map((item) => {
        return (
            <Menu.Item key={item}>
                {item}
            </Menu.Item>
        )
    });

    const menu = (
        <Menu onClick={handleMenuClick} >
            {menus}
        </Menu>
    );


    function handleMenuClick(e) {
        setLocation(e.key);

        getRequests(e.key);

    }

    return (
        <div className="manage-receipts listing">

            <div className="page-header">

                <div className="left">

                    <Title level={3}>Delivered/EW Status</Title>
                </div>

                <div className="right">

                    <div className="page-actions">
                        <Dropdown overlay={menu}>
                            <Button>
                                {location} <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>


                    <div>
                        <Button onClick={() => { getRequests(); }} type="secondary" size={'small'}>Refresh</Button>
                    </div>

                </div>
            </div>


            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) :
                    <>

                        <TableView data={result.requests} />

                    </>
            }

        </div>
    )
}


function TableView({ data, callback }) {

    function showDrawer(item) {
        callback(item)
    }


    const columns = [{
        title: '#',
        dataIndex: 'index',
        // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
        render: (value, item, index) => index + 1
    },
    {
        title: 'Customer',
        render: (text, record) => {

            return (<>{record.customer.customerName}</>)
        },
        key: 'name',
    },

    // {
    //     title: 'Booking Date',
    //     render: (text, record) => {

    //         return (<>{JSON.stringify(new Date(record.booking_date))}</>)
    //     },
    //     key: 'name',
    // },


    {
        title: 'Allocation/Request Date',
        render: (text, record) => {

            return (<>{record.created_at}</>)
        },
        key: 'name',
    },

    {
        title: 'EW',
        render: (text, record) => {

            return (<>{record.warranty.isPresent}</>)
        },
        key: 'warranty',
    },

    {
        title: 'Warranty Status',
        render: (text, record) => {

            return (<>{record.warranty.status}</>)

        },
        key: 'warranty',
    },



    {
        title: 'Warranty Punched',
        render: (text, record) => {

            return (<>{record.warranty.note ? 'Yes' : 'No'}</>)

        },
        key: 'warranty',
    },


    {
        title: '',
        key: 'action',
        render: (text, record) => {

            return (
                <Space size="middle">
                    <Link to={`/requests/${record.id}`}>
                        View
                    </Link>
                </Space>
            )
        },
    }
    ];

    return (<Table
        width='100%'
        size="small"
        // scroll={{ x: true, y: 750 }}
        rowKey={record => record.index}
        dataSource={data}
        columns={columns}
        // onChange={handleTableChange}
        pagination={false}
    >
    </Table>)

}

