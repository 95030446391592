import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';

import { Typography, Tabs, Button, Input, Alert, Tag } from 'antd';

const { TabPane } = Tabs;

const { Title, Text } = Typography;

/**
 * Accessory Items
 */
export default function ItemSelection({ options = [], choices = [], callback, isBooking }) {
    const [search, setSearch] = useState('');

    const [btnLoading, setBtnLoading] = useState(false);

    const [categories, setCategories] = useState([]);

    const [selectedTab, setSelectedTab] = useState('All');

    const [selected, setSelected] = useState(choices);

    useEffect(() => {
        let arr = ['All'];
        options.map((ele) => {
            if (arr.includes(ele.category) === false) {
                arr.push(ele.category);
            }
        });
        setCategories(arr);
    }, []);

    console.log(selected);

    const selectItem = (item, index) => {
        // selected.push({ ...item, ...{ quantity: 1, id: null, name: item.part_name, partNo: item['PART NO'], description: item.part_name } });
        selected.push({
            ...item,
            ...{ quantity: 1, name: item.part_name },
        });

        setSelected([...selected]);

        // callback(selected);
    };

    const onTabChange = (key) => {
        setSelectedTab(categories[key]);
    };

    const findArrayLength = (opt) => {
        let arrValue = [];
        options
            .filter((item) => {
                return selected.map((ele) => ele.part_name).indexOf(item.part_name) == -1;
            })
            .filter((item) => {
                return item.part_name.toLowerCase().includes(search.toLowerCase());
            })
            .map((val, key) => {
                if (val.category == selectedTab) {
                    arrValue.push(val);
                }
            });
        return arrValue.length;
    };

    async function addAccessories(newCartItems){

        setBtnLoading(true)

        await callback(newCartItems)

        setBtnLoading(false)
        
    }

    return (
        <div className="accessory-selection">
            <div className="split-list">
                <div
                    style={{
                        width: '100%',
                        border: '1px solid #f0f0f0',
                        padding: '16px',
                        height: 'fit-content',
                    }}
                >
                    <div
                        style={{
                            borderBottom: '1px solid #f0f0f0',
                            paddingBottom: '10px',
                        }}
                    >
                        <div style={{ fontWeight: 'bold', fontSize: '17px' }}>CART</div>
                    </div>
                    {selected &&
                        selected.map((ele) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        paddingTop: '5px',
                                    }}
                                >
                                    <div>{ele.name}</div>
                                    <div>{ele.quantity}</div>
                                </div>
                            );
                        })}
                    <div
                        style={{
                            borderTop: '1px solid #f0f0f0',
                            paddingTop: '10px',
                            marginTop: '10px',
                        }}
                    >
                        <Button
                            type="dashed"
                            style={{ alignSelf: 'flex-end' }}
                            loading={btnLoading}
                            onClick={() => {
                                //filtering the selected items that already in cart, By using 'customerID' field
                                let newCartItems = selected.filter((item) => !item.hasOwnProperty('customerID'));

                                addAccessories(newCartItems);
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
                <div className="available">
                    <Tabs defaultActiveKey="0" onChange={onTabChange}>
                        {categories.map((cat, index) => {
                            return (
                                <TabPane tab={cat} key={index}>
                                    <div style={{ width: '100%' }}>
                                        <p level={5}>{selectedTab !== 'All' ? findArrayLength(options) : options.length} available in stock</p>

                                        <Input
                                            placeholder="Search"
                                            value={search}
                                            onChange={(event) => {
                                                setSearch(event.target.value);
                                            }}
                                        />

                                        <div className="list" style={{ paddingTop: '20px' }}>
                                            {options
                                                .filter((item) => {
                                                    return selected.map((ele) => ele.name).indexOf(item.part_name) == -1;
                                                })
                                                .filter((item) => {
                                                    return item.part_name.toLowerCase().includes(search.toLowerCase());
                                                })
                                                .map((option, key) => {
                                                    if (selectedTab !== 'All') {
                                                        if (option.category == selectedTab) {
                                                            return (
                                                                <div
                                                                    className="item-card"
                                                                    key={key}
                                                                    style={{
                                                                        borderBottom: '1px solid #f0f0f0',
                                                                        padding: '16px',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <div className="item-content">
                                                                        <Title level={5}>{option.part_name}</Title>

                                                                        <p>
                                                                            <strong>{option.part_number}</strong>
                                                                        </p>

                                                                        {option.package_status === true ? <Tag color="magenta">Package</Tag> : null}

                                                                        {option.stockNotExist ? (
                                                                            <Fragment>
                                                                                <p>
                                                                                    {option.partNo} - {option.available} unavailable
                                                                                </p>
                                                                                <Alert
                                                                                    width={250}
                                                                                    message="This item is not in stock"
                                                                                    type="warning"
                                                                                    showIcon
                                                                                />
                                                                            </Fragment>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <p>
                                                                                    {option.partNo} - {option.available} available
                                                                                </p>
                                                                                <Text type="danger">
                                                                                    <Title level={5}>{option.price}</Title>
                                                                                </Text>
                                                                            </Fragment>
                                                                        )}
                                                                    </div>
                                                                    {option.stockNotExist ? null : (
                                                                        <Button
                                                                            type="dashed"
                                                                            style={{
                                                                                alignSelf: 'flex-end',
                                                                            }}
                                                                            onClick={() => {
                                                                                selectItem(option, key);
                                                                            }}
                                                                        >
                                                                            Add
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <div
                                                                className="item-card"
                                                                key={key}
                                                                style={{
                                                                    borderBottom: '1px solid #f0f0f0',
                                                                    padding: '16px',
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <div className="item-content">
                                                                    <Title level={5}>{option.part_name}</Title>

                                                                    <p>
                                                                        <strong>{option.part_number}</strong>
                                                                    </p>

                                                                    {option.package_status === true ? <Tag color="magenta">Package</Tag> : null}
                                                                    {option.stockNotExist ? (
                                                                        <Fragment>
                                                                            <p>
                                                                                {option.partNo} - {option.available} unavailable
                                                                            </p>
                                                                            <Alert
                                                                                width={250}
                                                                                message="This item is not in stock"
                                                                                type="warning"
                                                                                showIcon
                                                                            />
                                                                        </Fragment>
                                                                    ) : (
                                                                        <Fragment>
                                                                            <p>
                                                                                {option.partNo} - {option.available} available
                                                                            </p>
                                                                            <Text type="danger">
                                                                                <Title level={5}>{option.price}</Title>
                                                                            </Text>
                                                                        </Fragment>
                                                                    )}
                                                                </div>
                                                                {option.stockNotExist ? null : (
                                                                    <Button
                                                                        type="dashed"
                                                                        style={{
                                                                            alignSelf: 'flex-end',
                                                                        }}
                                                                        onClick={() => {
                                                                            selectItem(option, key);
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </TabPane>
                            );
                        })}
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
