import React, { useEffect, useState, Fragment, useContext } from 'react';

/**
 *
 * This is one of the primary component of this application
 * that is being used a lot as it records the cancellation .
 *
 * We have a form creator component that is a generic form builder
 *
 * #todo Lets try to recreate below using form creator
 *
 *
 */

import { Form, Input, message, Typography, Select, Space, Timeline, Modal, Button, Popconfirm } from 'antd';

import { Tag, Row, Badge, Image } from 'antd';

import AsyncSelect from 'react-select/async';

import { Radio } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import ApiUtils from './../../../../utils/api.utils';

import FileUpload from '../../../../components/file-upload/file-upload';

import ImageWrapper from './../../../../components/image-wrapper/image-wrapper';

import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import RefundPortal from './../../../../modules/common/resources/refund-portal/refund-portal';

import moment from 'moment';
import { Link } from 'react-router-dom';
import { Users, Departments } from '../../../../models';
import { dealerCodeKey } from '../../../global-config';

const { Title, Text } = Typography;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

const { TextArea } = Input;

const { Option } = Select;

const otherMode = [
    {
        id: 0,
        name: 'Kodak Mahindra Bank',
    },
    {
        id: 1,
        name: 'Other',
    },
];

let onlineOptions = ['Net Banking'];

var cityCode = dealerCodeKey

export default function CancellationsDetail({ history, match }) {
    const { user } = useContext(GlobalContext);

    const [record, setRecord] = useState({ created_by: {}, attachments: [] });

    const [form] = Form.useForm();

    const [loader, setLoader] = useState(false);

    const [matchingloader, setMatchingloader] = useState(false);

    const [matching, setMatching] = useState([]);

    const [vouchers, setVouchers] = useState({});

    const [request, setRequest] = useState({});

    const [visible, setVisible] = useState(false);

    const [retry, setRetry] = useState(false);

    const [department, setDepartment] = useState({});

    useEffect(() => {
        loadData();
    }, []);

    const { params } = match;

    const { city, id } = params;

    function onUpload(attachments) {
        // form.setFieldsValue({ attachments: attachments });

        var params;
        if(record && record.cancellation_request){
            params = {
                cancellation_request: [
                    ...record.cancellation_request,
                    attachments[0]
                ]
            };
        } else {
            params = {
                cancellation_request: [
                    attachments[0]
                ]
            };
        }

        FirebaseUtils.updateRecord('cancellations', record.id, params).then(() => {
            loadData();
        });

    }

    function loadData() {
        FirebaseUtils.getRecord('cancellations', id).then(async (result) => {
            var queries = [
                {
                    field: 'dealer_code',
                    value: result.dealerCode,
                },
                {
                    field: 'description',
                    value: 'Department Handling Sales',
                },
            ];

            await Departments.get(queries).then((result) => {
                setDepartment(result.departments[0]);
            });

            var query = [
                {
                    field: 'dms.Consultant Name',
                    value: result.created_by_name,
                },
            ];

            var arr = [];

            Users.get(query)
                .then((res) => {
                    let ele = {
                        ...result,
                    };

                    if (res.users.length) {
                        ele = {
                            ...ele,
                            teamleader: res.users[0].dms.teamLeader,
                        };
                    }

                    arr.push(ele);
                })
                .then(() => {
                    console.log(arr[0]);
                    setRecord(arr[0]);

                    setLoader(false);
                    // if (['Payment Link by Razorpay', 'Transfer by Razorpay'].indexOf(result.payment_mode) !== -1) {
                    getPayoutStatus(arr[0]);
                    // }
                    if (arr[0].voucher) {
                        setVouchers(arr[0].voucher);
                    }
                });
        });
    }

    /**
     * get Payout Status
     */
    function getPayoutStatus(record) {
        let queries = [
            {
                field: 'bookingNo',
                value: record.bookingId,
            },
            {
                field: 'payment_mode',
                operator: 'in',
                value: ['payout', 'payout-link'],
            },
        ];
        FirebaseUtils.getRecords(city, queries, 'payments').then((result) => {
            // For accounts
            if (user.role === 'accounts') {
                if (result['payments'] && result['payments'][0]) {
                    setRetry(true);
                } else {
                    if (!record.refund_done) {
                        if (['DMS Cancellation Pending', 'Cancellation Completed', 'Refund Confirmation Pending', ''].indexOf(record.status) !== -1) {
                            setRetry(true);
                        }
                    }
                }
            }

            setMatching(result['payments']);
        });
    }

    /**
     * Load the matching heads
     */
    function loadModes() {
        ApiUtils.getModes('Net Banking').then((result) => {
            return result.map((item) => {
                return {
                    value: item.ac_code,
                    label: item.ac_desc,
                };
            });
        });
    }

    /**
     * Function to Initiate Refund
     */
    function initiateRefund(record) {
        setVisible(true);

        let body = {
            ...record,
            customer: {
                customerName: record.name,
                phone: record.phone,
            },
            city: cityCode[record.dealerCode],
        };
        setRequest(body);
    }

    function alreadyCredited() {
        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            txn_date: moment().format('DD/MM/YYYY HH:mm'),
            transaction_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(), // this can be removed if not needed
        };

        var params = {
            refund_done: true,
            refund_confirmation: {
                name: user.name,
                ...creation,
            },
            status: 'Refund Confirmation Pending',
        };

        FirebaseUtils.updateRecord('cancellations', record.id, params).then(() => {
            let bookingParams = {
                cancellation: {
                    id: record.id,
                    status: 'Refund Confirmation Pending',
                },
            };

            return FirebaseUtils.updateBookingGeneric(record.bookingId, bookingParams).then(() => {
                message.success('Marked as refund done');
                setLoader(true);
                loadData();
            });
        });
    }

    /**
     * Callback Function to update allocation
     */
    function refundCallback(param, request) {
        let body = {
            name: request.name,
            contact: request.contact,
            email: request.email,
            reference_id: param.ref,
            amount: param.amount,
        };
        if (param.payment_mode === 'payout') {
            body = {
                ...body,
                account_number: request.account_number,
                ifsc: request.ifsc,
                remarks: request.remarks,
                payment_mode: 'Transfer by Razorpay',
            };
        } else if (param.payment_mode === 'payout-link') {
            body = {
                ...body,
                payment_mode: 'Payment Link by Razorpay',
            };
        } else if (param.payment_mode === 'net banking') {
            body = {
                ...body,
                account_number: request.account_number,
                ifsc: request.ifsc,
                remarks: request.remarks,
                from_account: {
                    value: param.mode,
                },
                payment_mode: 'Net Banking',
            };
        } else {
            body = {
                ...body,
                remarks: request.remarks,
                payment_mode: 'cash',
            };
        }
        FirebaseUtils.updateBaseRecords(id, 'cancellations', body).then(() => {
            message.success(`Cancellation details updated`);
            setVisible(false);
            loadData();
        });
    }

    return (
        <div className="allocation-form">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="vehicle-card">
                            <Badge color="#f50" text={record.status} />
                            <>
                                <div className="vehicle-card card">
                                    <CollectionInfo collection={record} city={city} onUpload={onUpload} />
                                </div>
                            </>

                            <Timeline>
                                <Timeline.Item
                                    dot={
                                        record.created_at ? (
                                            <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                        ) : (
                                            <ClockCircleOutlined style={{ fontSize: '16px' }} />
                                        )
                                    }
                                    color={record.created_at ? 'green' : 'gray'}
                                >
                                    {/* Cancellation Approval Pending */}
                                    {record.created_by_name ? `Created By ${record.created_by_name} At` : 'Created At'}
                                    <p>{record.created_at}</p>
                                </Timeline.Item>
                                <Timeline.Item
                                    color={record.approve_at || record.reject_at ? 'green' : 'wait'}
                                    dot={
                                        record.approve_at || record.reject_at ? (
                                            <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                        ) : (
                                            <ExclamationCircleOutlined />
                                        )
                                    }
                                >
                                    {record.reject_at
                                        ? `Rejected By ${record.rejected_by} At`
                                        : record.approved_by
                                            ? `Verified By ${record.approved_by} At`
                                            : 'Verified At'}
                                    <p>{record.approve_at ? record.approve_at : record.reject_at ? record.reject_at : null}</p>
                                </Timeline.Item>
                                <Timeline.Item
                                    dot={
                                        record.ccm_followed_up_at ? (
                                            <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                        ) : (
                                            <ClockCircleOutlined style={{ fontSize: '16px' }} />
                                        )
                                    }
                                    color={record.ccm_followed_up_at ? 'green' : 'gray'}
                                >
                                    {record.ccm_followed_up_by ? `CCM Followed Up By ${record.ccm_followed_up_by}` : 'CCM Followed Up'}
                                    <p>{record.ccm_followed_up_at}</p>
                                </Timeline.Item>
                                <Timeline.Item
                                    dot={
                                        record.refund_approved_at ? (
                                            <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                        ) : (
                                            <ClockCircleOutlined style={{ fontSize: '16px' }} />
                                        )
                                    }
                                    color={record.refund_approved_at ? 'green' : 'gray'}
                                >
                                    {record.refund_approved_by ? `Approved By ${record.refund_approved_by}` : 'Approved By'}
                                    <p>{record.refund_approved_at}</p>
                                </Timeline.Item>
                                <Timeline.Item
                                    dot={
                                        record.accounted_at ? (
                                            <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                        ) : (
                                            <ClockCircleOutlined style={{ fontSize: '16px' }} />
                                        )
                                    }
                                    color={record.accounted_at ? 'green' : 'gray'}
                                >
                                    {record.accounted_by ? `Refund Initiated By ${record.accounted_by}` : 'Refund Initiated'}
                                    <p>{record.accounted_at}</p>
                                </Timeline.Item>
                                <Timeline.Item
                                    dot={
                                        record.ccm_confirmed_at ? (
                                            <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                        ) : (
                                            <ClockCircleOutlined style={{ fontSize: '16px' }} />
                                        )
                                    }
                                    color={record.ccm_confirmed_at ? 'green' : 'gray'}
                                >
                                    {record.ccm_confirmed_by ? `Refund Confirmed By ${record.ccm_confirmed_by}` : 'Refund Confirmed At'}
                                    <p>{record.ccm_confirmed_at}</p>
                                </Timeline.Item>
                                <Timeline.Item
                                    dot={
                                        record.dms_cancellation_at ? (
                                            <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                        ) : (
                                            <ClockCircleOutlined style={{ fontSize: '16px' }} />
                                        )
                                    }
                                    color={record.dms_cancellation_at ? 'green' : 'gray'}
                                >
                                    {record.dms_cancellation_by ? `DMS Cancellation By ${record.dms_cancellation_by}` : 'DMS Cancellation At'}
                                    <p>{record.dms_cancellation_at}</p>
                                </Timeline.Item>
                            </Timeline>

                            <div>
                                {retry ? (
                                    <Space>
                                        <Button
                                            type={'primary'}
                                            onClick={() => {
                                                initiateRefund(record);
                                            }}
                                        >
                                            Retry Payment
                                        </Button>
                                        <Popconfirm
                                            title="Are you sure you want to mark this refund as completed ? "
                                            onConfirm={() => {
                                                alreadyCredited();
                                            }}
                                            onCancel={() => { }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="secondary">Already Credited ?</Button>{' '}
                                        </Popconfirm>
                                    </Space>
                                ) : null}
                            </div>
                            {matchingloader ? (
                                <PlaceHolder type="report" />
                            ) : (
                                <>
                                    {matching.map((entry) => {
                                        return (
                                            <PayoutDetails
                                                data={entry}
                                                callback={() => {
                                                    setRetry(false);
                                                }}
                                            />
                                        );
                                    })}
                                </>
                            )}

                            {/* Vouchers */}
                            {record.voucher ? <Vouchers record={record.voucher} department={department} /> : null}
                            {/* Vouchers Ends */}
                        </div>
                    </div>

                    {/* Refund Modal */}
                    <Modal
                        width={'70%'}
                        destroyOnClose={true}
                        header={null}
                        footer={null}
                        visible={visible}
                        okText="Okay"
                        onOk={() => {
                            setVisible(false);
                        }}
                        onCancel={() => {
                            setVisible(false);
                        }}
                    >
                        <RefundPortal
                            amount={record.amount}
                            refresh={(param, request) => refundCallback(param, request)}
                            request={request}
                            isBooking={true}
                        />
                    </Modal>
                    {/* Refund Modal Ends */}
                </Fragment>
            )}
        </div>
    );
}

function PayoutDetails({ data = {}, callback }) {
    const [content, setContent] = useState([]);

    useEffect(() => {
        let formBody = {
            id: data.transaction_id,
            mode: 'IMPS',
            location: cityCode[data.dealerCode],
            payment_mode: data.payment_mode,
        };

        FirebaseUtils.getRefundStatus({ formBody: formBody }).then((result) => {
            setContent(result.data);
            if ((result.data.status === 'cancelled')||(result.data.status === "reversed")) {
                console.log(result.data.status)
               
            }
            else{
                callback();
            }
        });
    }, []);

    return (
        <div className="card">
            <h3 className="title">Purpose : {content.purpose}</h3>
            <h3 className="title">Type: {content.entity}</h3>
            <p className="title ">Amount : {data.amount}</p>
            <p className="title ">Ref : {content.reference_id}</p>
            <p className="">
                <Tag color="magenta">{content.status}</Tag>
            </p>
            {(content.status === 'issued' && data.payment_mode === 'payout-link') ||
                (content.status === 'queued' && data.payment_mode === 'payout') ? (
                <Popconfirm
                    title="Are you sure you want to mark this refund as completed ? "
                    onConfirm={() => {
                        let formBody = {
                            id: content.id,
                            location: cityCode[data.dealerCode],
                            payment_mode: data.payment_mode,
                        };
                        FirebaseUtils.cancelPayoutLink({ formBody: formBody }).then((result) => {
                            setContent(result.data);
                        });
                    }}
                    onCancel={() => { }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button>Cancel</Button>
                </Popconfirm>
            ) : null}
        </div>
    );
}

function CollectionInfo({ collection = { created_by: {}, attachments: [] }, city, onUpload }) {

    const { disableDisplayPhoneNumber } = useContext(GlobalContext);

    console.log(city);
    return (
        <div>
            <div className="detail-element">
                <Row span={12}>
                    <h3>Name :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title">{collection.customerName}</h2>
                </Row>
            </div>
            <div className="detail-element">
                <Row span={12}>
                    <h3>Amount :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title amount">{collection.amount}</h2>
                </Row>
            </div>

            {/* <div className="detail-element">
                <Row span={12}>
                    <h3>KEC :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="">{collection.created_by}</h2>
                </Row>
            </div> */}
            <div className="detail-element">
                <Row span={12}>
                    <h3>Payment Mode :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.payment_mode}</span>
                </Row>
            </div>
            {['Transfer by Razorpay', 'Net Banking'].indexOf(collection.payment_mode) !== -1 ? (
                <>
                    <div className="detail-element">
                        <Row span={12}>
                            <h3>Account Number :</h3>
                        </Row>
                        <Row span={12}>
                            <span>{collection.account_number}</span>
                        </Row>
                    </div>
                    <div className="detail-element">
                        <Row span={12}>
                            <h3>IFSC :</h3>
                        </Row>
                        <Row span={12}>
                            <span>{collection.ifsc}</span>
                        </Row>
                    </div>
                </>
            ) : null}

            {['Net Banking'].indexOf(collection.payment_mode) !== -1 ? (
                <div className="detail-element">
                    <Row span={12}>
                        <h3>From Account :</h3>
                    </Row>
                    <Row span={12}>
                        <span>{collection.from_account.label}</span>
                    </Row>
                </div>
            ) : null}

            <div className="detail-element">
                <Row span={12}>
                    <span>Attachments</span>
                </Row>
                <>
                    {collection.cancellation_request &&
                        collection.cancellation_request.map((file) => {
                            return (
                                <>
                                    {file.type === 'application/pdf' ? (
                                        <embed src={file.url} width="500" height="375" type="application/pdf" />
                                    ) : (
                                        <Image width={200} src={file.url} />
                                    )}
                                </>
                            );
                        })
                    }
                    <FileUpload
                        // initial={collection.cancellation_request}
                        callback={onUpload}
                    />
                </>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Team Leader :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.teamleader}</span>
                </Row>
            </div>
            <div className="detail-element">
                <Row span={12}>
                    <h3>On Account Of :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.type}</span>
                </Row>
            </div>
            <div className="detail-element">
                <Row span={12}>
                    <h3>Remarks :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.remarks}</span>
                </Row>
            </div>
            {collection.sm_remarks ? (
                <div className="detail-element">
                    <Row span={12}>
                        <h3>Remarks from SM :</h3>
                    </Row>
                    <Row span={12}>
                        <span>{collection.sm_remarks}</span>
                    </Row>
                </div>
            ) : null}
            {collection.ccm_remarks ? (
                <div className="detail-element">
                    <Row span={12}>
                        <h3>Remarks from CCM :</h3>
                    </Row>
                    <Row span={12}>
                        <span>{collection.ccm_remarks}</span>
                    </Row>
                </div>
            ) : null}
            {collection.mgt_remarks ? (
                <div className="detail-element">
                    <Row span={12}>
                        <h3>Remarks from Management :</h3>
                    </Row>
                    <Row span={12}>
                        <span>{collection.mgt_remarks}</span>
                    </Row>
                </div>
            ) : null}
            {collection.accounts_remarks ? (
                <div className="detail-element">
                    <Row span={12}>
                        <h3>Remarks from Accounts :</h3>
                    </Row>
                    <Row span={12}>
                        <span>{collection.accounts_remarks}</span>
                    </Row>
                </div>
            ) : null}
            {collection.refund_initiated_remarks ? (
                <div className="detail-element">
                    <Row span={12}>
                        <h3>Remarks from CCM(refund initiated) :</h3>
                    </Row>
                    <Row span={12}>
                        <span>{collection.refund_initiated_remarks}</span>
                    </Row>
                </div>
            ) : null}
            {collection.edp_remarks ? (
                <div className="detail-element">
                    <Row span={12}>
                        <h3>Remarks from EDP :</h3>
                    </Row>
                    <Row span={12}>
                        <span>{collection.edp_remarks}</span>
                    </Row>
                </div>
            ) : null}

            {/*disable PhoneNumber for kec and tl */}
            {!disableDisplayPhoneNumber ?  
             <div className="detail-element">
                <Row span={12}>
                    <h3>Phone :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.phone}</span>
                </Row>
             </div> : null }
           
            <div className="detail-element">
                <Row span={12}>
                    <h3>Email :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.email}</span>
                </Row>
            </div>
            <div className="detail-element">
                <Row span={12}>
                    <h3>Created At :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.created_at}</span>
                </Row>
            </div>
            <div className="detail-element">
                <Row span={12}>
                    <h3>Booking No :</h3>
                </Row>
                <Row span={12}>
                    <Link to={`/bookings/${collection.bookingNo}`}>
                        <span>{collection.bookingNo}</span>
                    </Link>
                </Row>
            </div>
            {/* <div className="detail-element">
                <Row span={12}>
                    <h3>Created By :</h3>
                </Row>
                <Row span={12}>
                    <span>
                        {collection.created_by} -{' '}
                        KEC{' '}
                    </span>
                </Row>
            </div> */}
        </div>
    );
}

/**
 *
 * Vouchers
 */
function Vouchers({ record = { v_ids: [] }, department }) {
    const [records, setRecords] = useState([]);

    useEffect(() => {
        getRecord(record.v_vno);
    }, []);

    function getRecord(voucher) {
        ApiUtils.getVoucherRecords(voucher, department).then((result) => {
            setRecords(result);
            console.log(result);
        });
    }

    return (
        <>
            {records.map((v_id) => (
                <VoucherRecord record={v_id} />
            ))}
        </>
    );
}

/**
 * Voucher Record
 */
function VoucherRecord({ record = {} }) {
    return (
        <div className="card">
            <h3 className="title">
                Voucher {record.v_vno} - {record['v_id']}
            </h3>
            <h2 className={`title ${record['v_trn'] === -1 ? 'amount' : 'credit'}`}>{record['v_amt']}</h2>
            <p className="title ">{record['v_acptr']}</p>
            Credited at {record['v_dt']}
        </div>
    );
}
