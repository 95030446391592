import React, { useState, useEffect, useContext } from 'react'

import { Link } from 'react-router-dom'

import { GlobalContext } from './../../../Store';

import moment from 'moment';

import { Tag } from 'antd'

import FirebaseUtils from './../../../utils/firebase.utils';

import { capitalizeStrings, getFormattedDateAndTime } from './../../../utils/common.utils';

export default function CustomerCard({ report, index }) {

    const { user } = useContext(GlobalContext);

    let waitinghours = moment().diff(moment(report['txn_date'], 'DD/MM/YYYY hh:mm'));


    return (
        <Link className="report-item" to={`customer/${report.collectionId}`}>
            <div className="card">
                <h2 className="title">
                    {report.name}
                </h2>
                <h3 className="title">
                    {report.phone}
                </h3>

                <p className="address">
                    {report.account}, {report.location}
                </p>

                <Tag color="blue" style={{ margin: '10px 0px' }}>
                    {report.type}
                </Tag>

                <h2 className="title amount">
                    Rs {report.source === 'bank' ? report.credit : report['amount']}
                </h2>

                <p className="address" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    KEC <strong style={{ marginLeft: '16px' }}>{report['kec']}</strong>
                </p>

                {
                    report['txn_date'] ?
                        <p className="date-time" style={{ display: "flex" }}>
                            Waiting since {moment(report['txn_date'], 'DD/MM/YYYY hh:mm').format('hh a dddd - DD/MM/YYYY')} (<div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                        </p>
                        :
                        null
                }


            </div>
        </Link>
    )
}