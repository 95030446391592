

import React, { useState, useContext, useEffect } from "react";

import { Card, Typography, Badge, Table, Tag, Tabs, Select, Button, Statistic, Row, Col } from "antd";

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import Rupee from '../../../../components/rupee/rupee'

import { DatePicker, Space } from 'antd';

import { Link } from 'react-router-dom'

import moment from 'moment';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';



import "./booking-overview.scss";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;

const {RangePicker} = DatePicker;

export default function BookingOverview(props) {

    const { user } = useContext(GlobalContext);

    const [range, setRange] = useState([moment().startOf('day'),moment().endOf('day')]);

    const [option, setOption] = useState('transaction_date');

    function loadData(range) {

        setRange(range);

    }

    function updateTime(dt) {

        setRange(dt);

        loadData(dt);

    }


    return (
        <section className="accounts-overview listing">

            <div className="page-header">

                <Title level={3}>
                    Booking Overview
                </Title>

                <div className="page-actions">

                    <RangePicker 
                        inputReadOnly 
                        format={'DD/MM/YYYY'} 
                        value={range} 
                        onChange={updateTime}
                        ranges={{
                            Today: [moment(), moment()],

                            'Yesterday': [moment().subtract(1,'days').startOf('day'), moment().subtract(1,'days').endOf('day')],

                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                            'Last Week': [moment().subtract(1,'week').startOf('week'), moment().subtract(1,'week').endOf('week')],

                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                            'Last Month': [moment().subtract(1,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
                        }}
                     />

                    <Button size="small" onClick={() => {

                         let r = range;
                        
                        setRange(null);

                        setTimeout(() => {
                            
                            loadData(r);
                        })
                    }}>
                        Refresh
                    </Button>

                </div>
            </div>

            <Tabs>
                
                {
                    user.locations.length===3?  
                     <TabPane tab="All" key="0">
                        <DailyDashboard option={option} range={range} url="all" />
                    </TabPane>
                    :
                    null
                }
             
                {
                                user.locations.map((location, index) => {

                                    return (
                                      
                                    <TabPane tab={location} key={index+1}>
                                        <DailyDashboard option={option} range={range} url={location} />
                                    </TabPane>
                                    )
                                })
                            }


                            {/* <TabPane tab="KOZHIKODE" key="2">
                    <DailyDashboard option={option} range={range} url="kozhikode" />
                </TabPane>

                <TabPane tab="MALAPPURAM" key="3">
                    <DailyDashboard option={option} range={range} url="malappuram" />
                </TabPane>

                <TabPane tab="KANNUR" key="4">
                    <DailyDashboard option={option} range={range} url="kannur" />
                </TabPane> */}

            </Tabs>

        </section>
    );
}



function DailyDashboard({ url, range, option }) {

    const [loadingBooking,setLoadingBooking] = useState(true);

    const [loadingCollections,setLoadingCollections] = useState(true);

    const [bookings,setBookings] = useState([]);

    const [collections,setCollections] = useState(0);

    useEffect(() => {

        setLoadingBooking(true);

        setLoadingCollections(true);

        return () => {

        }
    }, [range])


    return (<>

                <Row gutter={16}>
                    <Col span={5} xs={16} md={5}>

                    <div className="card">
                        <Statistic title={'Bookings (Collections)'} value={loadingCollections?'Loading':collections} />
                    </div>
                </Col>

                <Col span={5} xs={16} md={5}>

                    <div className="card">
                        <Statistic title={'Bookings (DMS)'} value={loadingBooking?'Loading':bookings.length} />
                    </div>
                </Col>


    </Row>

                        



        <Card title={`Bookings as per Collections`} size="small">
            <CustomerReceiptTable url={url} option={option} date={range} callback={(result)=>{
                    console.log(result);

                    setCollections(result);
                    
                    setLoadingCollections(false);

            }}  />
        </Card>

        <Card title={`Bookings as per DMS`} size="small">
            <DMSBookings url={url} option={option} date={range} callback={(result)=>{
                    console.log(result);

                    setLoadingBooking(false);

                    setBookings(result);
            }} />
        </Card>

    </>)

}

    
function DMSBookings({url, date,callback}) {

        const [bookings, setBookings] = useState({bookings:[]});
    
        const [loader, setLoader] = useState(true);
    
        const [updated, setUpdated] = useState('');
    
        const columns = [
            {
                title:'#',
                render:(row,entry,index)=>{
                        return index+1
                }
            },

            {
                type: 'input',
                title: 'Booking No',
                dataIndex: 'Booking No'
            },
            {
                dataIndex: 'Name of the Customer',
                type: 'input',
                title: 'Customer Name'
            },
            {
                dataIndex: 'Contact Number',
                type: 'input',
                title: 'Contact Number'
            },
            {
                dataIndex: 'Booking Date',
                type: 'input',
                title: 'Booking Date'
            },
        
            {
                title: 'KEC',
                dataIndex: 'Consultant Name',
                type: 'input',
            },

            {
                title: 'Team Leader',
                dataIndex: 'Team Leader',
                type: 'input',
            },
        
            {
                title: 'Remarks',
                dataIndex: 'Last follow-up Remarks',
                type: 'input',
            },
        ]
        

    function getAllDMSBookings(range) {

        var queries = [
            {
            field: 'bookingDate',
        operator: '>=',
        value: moment(range[0]).valueOf()
    },

            {
            field: 'bookingDate',
        operator: '<=',
        value: moment(range[1]).valueOf()
    },]

        FirebaseUtils.getAllListing(queries, 'bookings', 'bookings', 'bookingsId').then((result) => {

            setBookings({bookings:result.all});

        setLoader(false);

        callback(result.all);

    });

}


    function getDMSBookings(url, range) {

        var queries = [{
            field: 'bookingDate',
            operator: '>=',
            value: moment(range[0]).format('DD/MM/YYYY')
    },
    {
        field: 'bookingDate',
    operator: '<=',
    value: moment(range[1]).format('DD/MM/YYYY')
}]

        FirebaseUtils.getListing(url, queries, 'bookings', 'bookings', 'bookingsId').then((result) => {

            setBookings(result);

        setLoader(false);

        callback(result.bookings);


    });

}

    useEffect(() => {

        if(date){


        if (url === 'all') {

            getAllDMSBookings(date);

        } else {

            getDMSBookings(url, date);

        }}


}, [date])

    return (<>

            {
                loader ? (
                    <PlaceHolder type="listing" />
                ) :
                    <>

                        {
                            updated
                                ?
                                <p style={{ float: 'right' }}>
                                    Last Updated {bookings.timestamp}
                                </p>
                                :
                                null
                        }



                        {
                            bookings.length ?
                            <Table
                                // loading={}
                                size="small"
                                scroll={{ x: true, y: 750 }}
                                // scroll={{ x: true, y: 750 }}
                                rowKey={record => record.key}
                                dataSource={bookings.bookings}
                                columns={columns}
                                pagination={false}>
                            </Table>
                            :
                            null
                        }
                    </>
            }

        </>)
    
    }
    
    
    
    
    
    /**
     *
     * Table of Customer Receipts
     *
 * @param {*} param0
        */
function CustomerReceiptTable({date, url, option,callback}) {

            let columns = [
    
        {
            title: '#',
        dataIndex: 'index',
        key: 'index',
        render: (value, item, index) => index + 1,
    },


        {
            title: 'Customer Name',
        dataIndex: 'name',
        key: 'name',
        // fixed: 'left',
    },

        {
            title: 'KEC',
        dataIndex: 'kec',
        key: 'kec',
    },

            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
            },

            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                onFilter: (value, record) => record.type.indexOf(value) !== -1,
                filters: [
                    {
                        text: 'Cash',
                        value: 'Cash',
                    },
                    {
                        text: 'Net Banking',
                        value: 'Net Banking',
                    },
                    {
                        text: 'Cheque',
                        value: 'Cheque',
                    },
                    {
                        text: 'Debit Card',
                        value: 'Debit Card',
                    },
                    {
                        text: 'Credit Card',
                        value: 'Credit Card',
                    },
                ],
            },
            {
                title: 'Collection',
                dataIndex: 'amount',
                render: (text, record) => {

                    return (
                        <Space size="middle">
                            {record.verified==='rejected'?<Text type="danger" delete>{text}</Text>:text}
                        </Space>
                    )
                },
            },



            {
                title: 'Verified',
                dataIndex: 'verified',
                render: (text, record) => {

                    return (
                        <Space size="middle">
                            <Badge status={text === 'approved' ? "success" : "error"} text={text === 'approved' ? record.amount : 0} />
                        </Space>
                    )
                },
            },

            {
                title: 'DMS Entry',
                dataIndex: 'invoice',
                render: (text, record) => {

                    return (
                        <Space size="middle">
                            <Badge status={text ? "success" : "error"} text={text ? record.amount : 0} />
                        </Space>
                    )
                },
            },

            {
                title: 'Accounts Entry',
                dataIndex: 'accounts',
                render: (text, record) => {

                    return (
                        <Space size="middle">
                            <Badge status={text ? "success" : "error"} text={text ? record.amount : 0} />
                        </Space>
                    )
                },
            },

            {
                title: 'Source',
                dataIndex: 'source',
                // fixed: 'right',

                key: 'source',
                onFilter: (value, record) => record.source.indexOf(value) !== -1,
                filters: [
                    {
                        text: 'Cashier',
                        value: 'cashier',
                    },
                    {
                        text: 'KEC',
                        value: 'kec',
                    },
                    {
                        text: 'Finance',
                        value: 'finance',
                    },
                    {
                        text: 'SM',
                        value: 'sm',
                    },
                    {
                        text: 'TL',
                        value: 'tl',
                    },



                ],
                render: (text, record) => {

                    return (
                        <Space size="middle">
                            <Tag color={text === 'kec' ? 'purple' : 'blue'}>{text}</Tag>
                        </Space>
                    )
                },
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => {
        
                    return (
                        <Space size="middle">
                            <Link to={`/${text.city}/customer-receipts/${text.collectionId}`}>
                                View
                           </Link>
                        </Space>
                    )
                },
            }

    ];

    const [loader, setLoader] = useState(true);
    
        const [customers, setCustomers] = useState([]);
    


    var groupBy = function (xs, key) {

        return xs.reduce(function (rv, x) {

            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;

        }, {});
    };

    
    function getAllCustomerCredits(range) {

            setLoader(true);

        var queries = [
        //     {
        //     field: 'amount',
        //     operator: '==',
        //     value: 25000
        // },

        {
            field: 'account',
            operator: '==',
            value: 'BOOKING'
        },
        
        {
            field:'transaction_date',
            operator:'>=',
            value:moment(range[0]).valueOf()
        },
        {
            field:'transaction_date',
            operator:'<=',
            value:moment(range[1]).valueOf()
        }]

        FirebaseUtils.getAllCollections(queries).then((result) => {

            setLoader(false);

            setCustomers(result.all);


            var filtered_bookings = result.all.filter((entry)=>entry.verified==='approved');

            var bookings = groupBy(filtered_bookings,'phone');

            var count = 0;

            Object.keys(bookings).forEach((phone)=>{

                var sum = bookings[phone].reduce((a, b) => a + parseInt(b.amount), 0)

                if([25000,100000].indexOf(sum)!==-1){

                    count++;
                }
            });

            callback(count);

           console.log(bookings);
    });
}

    function getCustomerCredits(url, range, option) {

        setLoader(true);

        var queries = [
            //     {
            //     field: 'amount',
            //     operator: '==',
            //     value: 25000
            // },
            {
                field: 'account',
                operator: '==',
                value: 'BOOKING'
            },
    
            {
                field:'transaction_date',
                operator:'>=',
                value:moment(range[0]).valueOf()
            },
            {
                field:'transaction_date',
                operator:'<=',
                value:moment(range[1]).valueOf()
            }
            //     {
            //         field: 'source',
            //     operator: 'in',
            //     value: ['cashier', 'kec', 'finance','tl','sm']
            // }
        ]

        FirebaseUtils.getCollections(url,queries).then((result) => {

            setLoader(false);

            setCustomers(result);

            var filtered_bookings = result.filter((entry)=>entry.verified==='approved');

            var bookings = groupBy(filtered_bookings,'phone');

            var count = 0;

            Object.keys(bookings).forEach((phone)=>{

                var sum = bookings[phone].reduce((a, b) => a + parseInt(b.amount), 0)

                if([25000,100000].indexOf(sum)!==-1){

                    count++;
                }
            });

            callback(count);

            // callback(result);

        });
    }

    useEffect(() => {

        if(date){

            if (url === 'all') {

                getAllCustomerCredits(date, option);
    
            } else {
    
                getCustomerCredits(url, date, option);
    
            }
        }

    }, [date, option])


    const expandedRowRender = (record) => {

        console.log(record);

        return (
            <div>
                <p>
                    Created at {record.created_at} - Verified at {record.source === 'cashier' ? record.created_at : (record.verified_at || 'Pending')} - Invoiced at {record.edp&&record.edp.created_at?record.edp.created_at: 'Pending'} - Accounted at {record.accounts&&record.accounts.created_at?record.accounts.created_at : 'Pending'}
                </p>
            </div>
        );
    }



    return (<>{
            loader ? (
                <PlaceHolder type="listing" />
            ) : <>

                    {
                        customers.length ? <Table
                            // loading={}
                            size="small"
                            scroll={{
                                x: 1200,
                                // y: 300
                            }}
                            // scroll={{ x: true, y: 750 }}

                            // scroll={{ x: true, y: 750 }}
                            rowKey={record => record.collectionId}
                            dataSource={customers}
                            columns={columns}
                            pagination={false}

                            expandable={{
                                expandedRowRender: expandedRowRender,
                            }}
                            summary={pageData => {

                                let total = 0;

                                let verifiedTotal = 0;

                                let dmsTotal = 0;

                                let cashTotal = 0;

                                let cashVerified = 0;

                                let chequeTotal = 0;

                                let chequeVerified = 0;


                                let accountsTotal = 0;

                                pageData.forEach((entry) => {


                                    if (entry.verified!=='rejected') {

                                        total += parseFloat(entry['amount']);

                                    }


                                    if (entry.verified==='approved') {

                                        verifiedTotal += parseFloat(entry['amount']);

                                    }

                                    if (entry.invoice) {

                                        dmsTotal += parseFloat(entry['amount']);

                                    }

                                    if (entry.accounts) {

                                        accountsTotal += parseFloat(entry['amount']);

                                    }

                                    if (entry.type === 'Cash') {

                                        cashTotal += parseFloat(entry['amount']);

                                        if (entry.verified==='approved') {

                                            cashVerified += parseFloat(entry['amount']);

                                        }

                                    }

                                    if (entry.type === 'Cheque') {

                                        chequeTotal += parseFloat(entry['amount']);

                                        if (entry.verified ==='approved') {

                                            chequeVerified += parseFloat(entry['amount']);

                                        }
                                    }
                                })


                                return (
                                    <>
                                        <Table.Summary.Row>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>


                                            <Table.Summary.Cell>


                                                <Title level={5}>

                                                    Total Collection
                                            </Title>


                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>




                                            <Table.Summary.Cell>

                                                <Title level={5}>

                                                    <Rupee value={total}></Rupee>
                                                </Title>


                                            </Table.Summary.Cell>



                                            <Table.Summary.Cell>


                                                <Title level={5}>
                                                    <Rupee value={verifiedTotal}></Rupee>
                                                </Title>
                                            </Table.Summary.Cell>





                                            <Table.Summary.Cell>


                                                <Title level={5}>
                                                    <Rupee value={dmsTotal}></Rupee>
                                                </Title>
                                            </Table.Summary.Cell>




                                            <Table.Summary.Cell>


                                                <Title level={5}>

                                                    <Rupee value={accountsTotal}></Rupee>
                                                </Title>

                                            </Table.Summary.Cell>

                                        </Table.Summary.Row>
                                        <Table.Summary.Row>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>


                                            <Table.Summary.Cell>


                                                <Text level={5}>

                                                    Cash Collection
                                            </Text>


                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>




                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>



                                            <Table.Summary.Cell>

                                                <Title level={5}>

                                                    <Rupee value={cashTotal}></Rupee>
                                                </Title>


                                            </Table.Summary.Cell>


                                            <Table.Summary.Cell>


                                                <Title level={5}>

                                                    <Text type="success">
                                                        <Rupee value={cashVerified}></Rupee>
                                                    </Text>

                                                </Title>
                                            </Table.Summary.Cell>

                                        </Table.Summary.Row>

                                        <Table.Summary.Row>


                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>





                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>


                                                <Text>

                                                    Cheque Total
    
                                            </Text>



                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>


                                            <Table.Summary.Cell>


                                                {/* <Title level={5}>
                                                <Rupee value={dmsTotal}></Rupee>
                                            </Title> */}
                                            </Table.Summary.Cell>



                                            <Table.Summary.Cell>

                                                <Title level={5}>

                                                    <Rupee value={chequeTotal}></Rupee>
                                                </Title>


                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>

                                                <Title level={5}>

                                                    <Text type="warning">
                                                        <Rupee value={chequeVerified}></Rupee>
                                                    </Text>


                                                </Title>


                                            </Table.Summary.Cell>


                                        </Table.Summary.Row>


                                    </>
                                );
                            }}

                        /> : null
                    }
                </>

        }

        </>
        )
    }
    
