/**
 *
 *
 */

import { Vehicles } from '.';

import FirebaseUtils from '../utils/firebase.utils';

import Base from './base';

class StockTransactions extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'stock_transactions';
    }

    get path() {
        return `stock_transactions`;
    }

    get getName() {
        return `stock_transactions`;
    }

    /**
     *  Add new record in  stock transaction and updating yard_id in vehicle table
     * @param {*} arr
     * @returns
     *
     */

    UpdatePDI = async (vinNo, values) => {

        var app = FirebaseUtils.getApp();

        var batch = app.batch();
        // query values 
        var query = [
            {
                field: 'item_identity_number',
                value: vinNo,
            },

        ];

        // var config = {
        //     orderBy: 'transaction_code',
        // };
        // get stocktransaction record 
        return this.get(query, { orderBy: 'created_at', limit: 1 }).then(async (result) => {
            let res = result.stock_transactions[0];


            //Update Status in Vehicles
            const vehicleReference = Vehicles.getRecordReference(vinNo);
            let stockPointId = values.pdi.stock_point_id
            delete values.pdi.stock_point_id

            await batch.update(vehicleReference, {
                pdi: values.pdi,
                stock_point_id: stockPointId
                // dealerCode: res.dealerCode,
                // yard_id: values.pdi.yard_id,

            });

            var stockTransactionReference = this.getRecordReference(res.id);

            await batch.update(stockTransactionReference, {
                stock_point_id: stockPointId
            })

            // delete res.id

            // create a new record in stock transaction
            // let formBody = {
            //     ...res,
            //     quantity: +1,
            //     transaction_code: 15,
            //     transaction_date: new Date(),
            //     deleted_at: null,
            //     deleted_by: null,
            //     stock_point_id: values.pdi.stock_point_id,
            // };

            // await batch.set(stockTransactionReference, formBody);


        }).then(async () => {
            return batch.commit();

        })
    };
}

export default StockTransactions;
