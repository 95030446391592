
import React, { useState, useEffect, useContext } from "react";


import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom'

import { Row, Tabs, Button, Tag, Select } from "antd";

import { Modal, Form, Input, message, Badge } from 'antd';


import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import moment from 'moment';

import "./high-priority.scss";

const { TabPane } = Tabs;

const { Option } = Select;

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};


const colorMaster = {
    'sonet': ['CLEAR WHITE', 'GLACIER WHITE PEARL', 'STEEL SILVER', 'GRAVITY GREY', 'AURORA BLACK PEARL', 'INTENSE RED', 'INTELLIGENCY BLUE', 'BEIGE GOLD'],
    'seltos': ['CLEAR WHITE', 'GLACIER WHITE PEARL', 'STEEL SILVER', 'GRAVITY GREY', 'AURORA BLACK PEARL', 'INTENSE RED', 'INTELLIGENCY BLUE', 'PUNCHY ORANGE'],
    'carnival': ['GLACIER WHITE PEARL', 'STEEL SILVER', 'AURORA BLACK PEARL']
}



export default function HighPriority(props) {

    const { user } = useContext(GlobalContext);

    useEffect(() => {

    }, [user])

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="high-priority">


            <Tabs defaultActiveKey="1" onChange={callback}>

                {
                    user.locations.map((location, index) => {

                        return (
                            <TabPane tab={`${location} High Priority`} key={index}>

                                <HighPriorityList url={location} />

                            </TabPane>
                        )
                    })
                }

            </Tabs>

        </section>
    );
}


const HighPriorityList = ({ url }) => {

    const { user } = useContext(GlobalContext);

    const [data, setData] = useState([]);

    const [loader, setLoader] = useState(true);

    const [visible, setVisible] = useState(false);

    const [form] = Form.useForm();

    const [variants, setVariants] = useState([]);

    const [colors, setColors] = useState([]);


    const [master, setMaster] = useState({
        carnival: { lastUpdated: null },
        seltos: { lastUpdated: null },
        sonet: { lastUpdated: null },
    });

    useEffect(() => {

        getData();

        getMaster();

    }, [])


    function getData() {

        setLoader(true);

        var queries = [{
            field: 'isHighPriority',
            value: true
        }, {
            field: 'delivery.status',
            operator: '!=',
            value: 'Delivery Completed'
        },
        {
            field: 'status',
            operator: '!=',
            value:'removed'
        },]

        FirebaseUtils.getRequests(url, queries).then((result) => {

            console.log(result);

            setLoader(false);

            // let customers = Object.keys(result.requests).map((request) => {
            //     return { ...result.requests[request], requestId: request }
            // });

            getStock(url, result.requests);

        });
    }


    /**
     * 
     * Function gets the stock and updates the availablity
     * 
     * @param {*} location 
     * @param {*} list 
     */
    function getStock(location, list) {

        setLoader(true);

        var queries = [{
            field: 'Stock Status',
            operator: 'in',
            value: ["Free Stock", "In transit"]
        }]

        FirebaseUtils.getVehicles(location, queries).then((result) => {

            console.log(result);

            setLoader(false);

            // let stock = Object.keys(result.vehicles).map((booking) => {
            //     return result.vehicles[booking]
            // });

            // Attach matching count on item
            list = list.map((customer, index) => {

                var available = result.vehicles.filter((vehicle) => {
                    return (vehicle.update !== 'remove') && (vehicle['Variant'] === customer.variant) && (vehicle['Exterior Color Name'] === customer.color)
                });

                customer.matching = available.length;

                return customer;
            })

            setData(list);
        });
    }


    function add() {

        form
            .validateFields()
            .then(values => {

                let customer = {
                    customerName: values.name,
                    phone: values.phone,
                    place: values.place
                }

                let request = {
                    city: url,
                    highPriorityStatus: user.role === 'admin' ? 'approved' : 'requested',
                    isHighPriority: true,
                    model: values.model,
                    variant: values.variant,
                    remarks: values.remarks,
                    color: values.color,
                    // kec: user.dms['Consultant Name'],
                    // teamLeader: user.dms['teamLeader'],
                    status: 'Request Created',
                    // ...preference,
                    // city: kecCity,
                    customer,
                    created_by: user,
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    expectedDate: moment(customer.expectedDate).format('DD/MM/YYYY HH:mm')
                }

                if (values.bookingNo) {

                    request.bookingNo = values.bookingNo;

                    FirebaseUtils.getBooking(values.bookingNo).then((response) => {

                        var booking = response.data();

                        createRequest(url, request, booking, user);

                    })

                } else {

                    createRequest(url, request, null, user);

                }

            })
    }

    function createRequest(url, request, booking, user) {

        FirebaseUtils.createHighPriorityRequest(url, request, booking, user).then((result) => {

            form.resetFields();

            setVisible(false);

            message.success('An entry has been added');

            getData();

        })
    }

    /**
 * 
 * Get the price master
 * 
 * @param {*} model 
 */
    function getMaster() {
        FirebaseUtils.getMaster().then((result) => {

            let data = {};

            Object.keys(result.master).forEach((model) => {

                let vehicles = result.master[model];

                data[model] = Object.keys(vehicles).map((v) => {

                    return vehicles[v];
                })


            })

            setMaster({ ...data });

        })
    }



    /**
     * 
     */
    function closeModal() {

        // setSelected({});

        setVisible(false);
    }

    return (<div className="page-content"><div className="list-header">

        <p className="page-desc">
            {loader ? 'Loading data' : `Found ${Object.keys(data).length} results`}
        </p>

        <div className="list-actions">
            <Button onClick={() => { getData() }} type="secondary" size={'small'}>Refresh</Button>

            <Button onClick={() => {

                setVisible(true);


            }} type="primary" size={'small'}>Add</Button>
        </div>

    </div>

        <Row className="report-listing-wrapper">
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {
                        (data).map((report, index) => {
                            return <PriorityCard city={url} request={report} index={index} key={index} />
                        })
                    }
                </>
            )}
        </Row>



        <Modal
            title="User Details"
            visible={visible}
            onOk={add}
            onCancel={closeModal}>

            <Form
                form={form}
                {...layout}
                name="new-record"
                layout="vertical"

                onFieldsChange={(fields) => {

                    let field = fields[0];

                    if (field && field.value) {

                        if (field.name[0] === 'model') {

                            let model = field.value.toLowerCase();

                            setVariants(master[model]);

                            setColors(colorMaster[model])
                        }


                    } else {

                    }

                }}

                // onFinish={onSubmit}
                // validateMessages={validateMessages} 
                initialValues={{
                }}>

                <Form.Item name={'name'} label="Name" rules={[{ required: true, message: 'Please enter Name' },]}>
                    <Input />
                </Form.Item>

                <Form.Item name={'place'} label="Place" rules={[{ required: true, message: 'Please enter Place' },]}>
                    <Input />
                </Form.Item>

                <Form.Item name={'phone'} label="Phone Number" rules={[{ required: true, message: 'Please enter Customer Phone Number' },]}>
                    <Input />
                </Form.Item>

                <Form.Item name={'bookingNo'} label="Booking Number" rules={[{ message: 'Please enter Booking Number' },]}>
                    <Input />
                </Form.Item>

                <Form.Item name={'model'} label="Model" rules={[{ required: true, message: 'Please select model' },]}>
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                    >

                        {['SELTOS', 'SONET', 'CARNIVAL'].map((model, key) => {

                            return <Option value={model} key={key}>{model}</Option>
                        })}

                    </Select>
                </Form.Item>

                <Form.Item name={'variant'} label="Variant" rules={[{ required: true, message: 'Please enter Locations' },]}>
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        // defaultValue={['a10', 'c12']}
                        onChange={() => { }}>

                        {
                            variants.map((variant, key) => {

                                return <Option value={`${variant['Name']}`} key={key}>
                                    {variant['Name']} - {variant['Ext Color']}
                                </Option>
                            })
                        }

                    </Select>
                </Form.Item>

                <Form.Item name={'color'} label="Color" rules={[{ required: true, message: 'Please select Color' },]}>
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        // defaultValue={['a10', 'c12']}
                        onChange={() => { }}>

                        {
                            colors.map((color, key) => {

                                return <Option value={`${color}`} key={key}>
                                    {color}
                                </Option>
                            })
                        }

                    </Select>
                </Form.Item>

                <Form.Item name={'email'} label="Email" rules={[{ message: 'Please enter Email Address' },]}>
                    <Input />
                </Form.Item>

                <Form.Item name={'remarks'} label="Remarks" rules={[{ required: true, message: 'Please enter remarks' },]}>
                    <Input />
                </Form.Item>

            </Form>
        </Modal>

    </div>)
}


function PriorityCard({ city, request = {} }) {

    return (<Link className="request-card" to={`/requests/${request.id}`}>
        <div className="card">

            <h2 className="title amount ">
                {request.customer.customerName}

            </h2>
            <h4 className="title">
                {request.customer.phone}
            </h4>


            <h3 className="title ">

                {request.variant}, {request.color}
            </h3>

            <h4>
                {request.remarks}
            </h4>

            <p className="address">
                <Tag color="magenta">{request.highPriorityStatus || 'Initial'}</Tag>

                <Badge status="processing" text={`${request.matching} Vehicles Matching`} />
            </p>


            Created at {request.created_at} by {request.created_by.name}
        </div>
    </Link>)
}
