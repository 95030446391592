/**
 * Function to display the employee name by using the corresponding employee_id
 * @author Anupama
 */

import { Employees } from '../../models/';

import React, { useEffect, useState, useContext } from 'react';


export default function EmployeeDisplay({ employee_id }) {

    const [employee, setEmployee] = useState({})


    useEffect(() => {

        getEmployee(employee_id)

    }, []);


    /*
    ** To get the employee by employee_id
    */
    async function getEmployee(employee_id) {

        var result = await Employees.getRecord(employee_id);

        setEmployee(result);

    }



    return (
               
        <div>
            {employee&&employee.fname?
            employee.fname + " " + employee.lname:''}


        </div>

    );

}