import React, { useState, useContext } from 'react';
// import './confirm-modal.scss';
import { Card, Input, Button, Modal, Form, Row, message, Popconfirm, Skeleton, Typography, Checkbox, Timeline, Tag } from 'antd';
// import { GlobalContext } from '../../Store';


const { Title, Text } = Typography;
const { TextArea } = Input;


export default function ConfirmModal({ caption, children, callback }) {
    // const { user } = useContext(GlobalContext);
    //Hooks
    const [visible, setVisible] = useState(false);
    /**
     * On submit of form
     *
     * @param {*} event
     */
    function onSubmit(values) {
        callback({ values });
    }
    return (
        <>
            <span className="show-search">
                <Button type="secondary" onClick={() => setVisible(true)}>
                    {caption}
                </Button>
            </span>
            <Modal visible={visible} footer={null} className=""
                onCancel={() => {
                    setVisible(false)
                }}
            >
                <Form layout="vertical" onFinish={onSubmit}>
                    <p>Are you sure you want to proceed?</p>
                    <Form.Item name={'remarks'} label="Remarks" tooltip={{ title: 'Tooltip with customize icon' }}>
                        <TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}