
import React, { useState, useEffect, useContext } from "react";

import { Row, Button, Tag, Badge, Typography, Tabs } from "antd";

import PlaceHolder from "../../../../components/ui_elements/PlaceHolder";

import { Link } from 'react-router-dom'

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import './accessory-customers.scss';
import { cityKeys } from "../../../global-config";

const { TabPane } = Tabs;

const { Title } = Typography;

var cityCode = cityKeys


export default function AccessoryCustomers({ step }) {

    const { user } = useContext(GlobalContext);

    const [filter, setFilter] = useState('all');

    const [allocations, setAllocations] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        getData();

    }, [user])

    function getData() {

        setLoader(true);

        const { locations } = user;

        FirebaseUtils.getRequests(user.locations[0], step.queries).then((vehicles) => {

            setLoader(false);

            console.log(vehicles);

            setAllocations(vehicles.requests);

        });

    }

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="accessory-customers">

            <Title level={3}>

                {step.caption}

            </Title>


            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Pending Customers" key="1">

                    <div className="list-header">

                        <p className="page-desc">
                            {loader ? 'Loading data' : `Found ${Object.keys(allocations).length} results`}
                        </p>

                        <div>
                            <Button onClick={getData} type="secondary" size={'small'}>Refresh</Button>


                        </div>

                    </div>

                    <Row className="report-listing-wrapper">
                        {loader ? (
                            <PlaceHolder type="listing" />
                        ) : (
                                <>
                                    {
                                        (allocations).map((report, index) => {
                                            return <step.Card step={step} vehicle={report} index={report.id} key={index} />
                                        })
                                    }
                                </>
                            )}
                    </Row>
                </TabPane>

            </Tabs>

        </section>
    );
}