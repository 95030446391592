import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from './../../../../../../Store';

import { message, Checkbox, Popconfirm, Tabs, Modal, Button, Typography, Select, Alert } from 'antd';

import { Form, Input, DatePicker } from 'antd';

import FirebaseUtils from '../../../../../../utils/firebase.utils';

import moment from 'moment';

import AsyncSelect from 'react-select/async';

import ApiUtils from '../../../../../../utils/api.utils';

// import './reconcile-summary.scss';

import ReceiptInfo from './../receipt-info/receipt-info';

import CollectionCard from './../collection-card/collection-card';

import CollectionSuggestion from './../collection-suggestion/collection-suggestion';

import CollectionSelection from './../collection-selection/collection-selection';

import PayoutCollection from '../payouts/payout-collection';

import ReceiptSelection from './../receipt-selection/receipt-selection';

import CommentDrawer from './../../../comment-drawer/comment-drawer';

import { Departments, BankCredits } from '../../../../../../models';

const { TabPane } = Tabs;

const { Option } = Select;

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

/**
 *
 * Bank Receipt Card
 *
 */

export default function BankReceipt({ onSelect, receipt = { matching: [] }, callback, codes, department, allDepartments }) {
    const [loading, setLoading] = useState(false);

    const [loader, setLoader] = useState(false);

    //const branch = ApiUtils.getBranch();

    const [form] = Form.useForm();

    const { user, defaultBranch, branches, dispatch } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    const [view, setView] = useState(true);

    // for dropdown
    const [options, setOptions] = useState(branches);

    const [selectedDepartment, setSelectedDepartment] = useState();

    const [departments, setDepartments] = useState([]);

    useEffect(async () => {
        await getDepartments();

        if (department && department[0] === 'Department Handling Services') {
            setView(false);
        }
    }, []);

    useEffect(() => {
        setOptions(branches);
    }, [branches]);

    var app = FirebaseUtils.getApp();

    var batch = app.batch();

    async function getDepartments() {
        var query = [
            {
                field: 'description',
                value: department[0],
            },

            {
                field: 'id',
                operator: 'in',
                value: user.department_ids,
            },
        ];
        return await Departments.get(query).then((res) => {
            setDepartments(res.departments);

            setSelectedDepartment(res.departments[0]);

            setLoader(true);
        });
    }

    /**
     * On Submit function for Othercredit
     *
     * @param {*} values
     */
    const onFinish = async(values) => {

        let creation = {
            created_by: { name: user.name, role: user.role },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),

            accounted_date: moment().startOf('day').valueOf(),
            accounted_time: moment().valueOf(),
        };

        var params = {
            ...values,
            otherCredit: true,

            accountRemarks: values.remarks,

            accounts: {
                ...creation,
            },

            pending_at: 'done',
            reconcile_status: 'completed',
        };

        setLoading(true);


        /***Not required without cs */
        // if (values.ac_code.value === receipt.mode) {
        //     message.error('Both heads cannot be similar');
        // } else {
            // // Add the reference columns
            // receipt.v_othref = 'RP:O:' + values.ac_code.value;
            // // #noteenable

            // // Add the reference column for bank entry
            // receipt.v_othref = 'RP:O:BANK';
            // if (loader) {
                // ApiUtils.createOtherCredit(receipt, values)
                    // .then( async () => {

                        // To update the bank_credits record 
                        var docRef = BankCredits.getRecordReference(receipt.receiptId);

                        await batch.update(docRef, params);

                        await batch.commit();

                        setLoading(false);

                        callback();

                        form.resetFields();

                        setVisible(false);

                        message.success('The  record has been updated');
                        
                    // })
                    // .catch(() => {

                    //     setLoading(false);
                    // });
            // }
        // }
        /**** */
    };

    async function sendTo(role) {
        var params = {
            initial: {
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                created_by: user.name,
            },
            pending_at: role,
        };

        var city = codes[receipt.bank];

        // To update bank_credits record
        var docRef = BankCredits.getRecordReference(receipt.receiptId);

        await batch.update(docRef, params);

        await batch.commit();

        message.success('Entry send to Sales team');

        callback();
    
    }

    /**
     * Load the matching heads
     */
    // function loadHeads(query) {
    //     // let branch = selectedDepartment;
    //     return ApiUtils.getHeads(query, receipt.department).then((result) => {
    //         return result.map((item) => {
    //             return {
    //                 value: item.ac_code,
    //                 label: item.ac_desc,
    //             };
    //         });
    //     });
    // }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: '99%',
            marginLeft: '1px',
        }),
    };

    // async function selectBranch(branchId, arg) {
    //     let matching = departments.filter((item) => item.id === branchId).pop();

    //     setSelectedDepartment(matching);

    //     //setSelected(branchId);
    // }
    // console.log(receipt);
    return (
        <div className="receipt-card">
            <div className="card">
                {view && onSelect && (
                    <Checkbox checked={receipt.selected} onChange={onSelect}>
                        Select
                    </Checkbox>
                )}

                <ReceiptInfo receipt={receipt} />

                {/* Reconcile Action */}
                {!receipt.suggestion && receipt.reconcile_status === 'pending' ? (
                    <Button
                        onClick={() => {
                            setVisible(true);
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Reconcile
                    </Button>
                ) : null}
                {/* Reconcile Action */}

                {/* Comment Drawer */}
                <CommentDrawer id={receipt.receiptId} />
                {/* Comment Drawer Ends */}

                {/* Matching Collections */}
                {receipt.matching && receipt.matching.length && loader ? (
                    <div className="matching">
                        {receipt.matching.map((entry, index) => {
                            return (
                                <CollectionCard
                                    key={index}
                                    receipt={receipt}
                                    callback={callback}
                                    collection={entry}
                                    allDepartments={allDepartments}
                                />
                            );
                        })}
                    </div>
                ) : null}
                {/* Matching Collections Ends */}

                {/* Matching Collections */}
                {receipt.suggestion && loader ? (
                    <div className="matching">
                        <CollectionSuggestion
                            receipt={receipt}
                            callback={callback}
                            city={receipt.suggestion.reconciling_city}
                            id={receipt.suggestion.reconciled_for}
                            allDepartments={allDepartments}
                        />
                    </div>
                ) : null}
                {/* Matching Collections Ends */}

                {/* Reconcile Modal */}
                <Modal
                    destroyOnClose={true}
                    className="reconcile-modal"
                    width={1200}
                    title="Reconcile Entry"
                    visible={visible}
                    footer={null}
                    // okText='Okay'
                    // onOk={() => {
                    //     setVisible(false);
                    // }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                >
                    <ReceiptInfo receipt={receipt} />

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {view && receipt.pending_at !== 'sm' ? (
                            <Popconfirm
                                title="Are you sure you want to send this to sales team for identification?"
                                onConfirm={() => sendTo('sm')}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="secondary" size={'small'}>
                                    Send to Sales Team
                                </Button>
                            </Popconfirm>
                        ) : null}

                        {/* <Popconfirm
                            title="Are you sure you want to send this to sales team for identification?"
                            onConfirm={() => sendTo('other-credits')}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" size={'small'}>
                                Send to Other Credits
                            </Button>
                        </Popconfirm> */}
                    </div>

                    <Tabs onChange={() => {}}>
                        {view ? (
                            <TabPane tab={`Matching Customer Collections`} key="0">
                                <ReceiptSelection
                                    receipt={receipt}
                                    callback={() => {
                                        setVisible(false);

                                        callback();
                                    }}
                                    allDepartments={allDepartments}
                                />
                            </TabPane>
                        ) : null}

                        <TabPane tab={`Other Credits`} key="1">
                            {/* <Alert
                                style={{ margin: '10px 0px' }}
                                message="Please update the receipt in CSAccounts and enter the details in the below form"
                                type="info"
                                showIcon
                                closable
                            /> */}

                            <Form
                                form={form}
                                {...layout}
                                name="new-record"
                                layout="vertical"
                                onFinish={onFinish}
                                // validateMessages={validateMessages}
                                initialValues={{}}
                            >
                                {/* {loader ? (
                                    <Form.Item
                                        name={'branch'}
                                        label="Select Branch"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select Branch',
                                            },
                                        ]}
                                    >
                                        <Select
                                            onSelect={selectBranch}
                                            placeholder="Branch"
                                            style={{
                                                width: '200px',
                                                margin: '0px 10px',
                                            }}
                                        >
                                            {departments.map((doc, index) => (
                                                <Option value={doc.id} key={doc.code}>
                                                    {doc.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : null} */}

 {/**Not required without cs */}
                                {/* <Form.Item
                                    name={'ac_code'}
                                    label="Account Head"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select Account Head',
                                        },
                                    ]}
                                >
                                    <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} maxMenuHeight={250} styles={customStyles} />
                                </Form.Item> */}
                                {/**** */}

                                <Form.Item
                                    name={'remarks'}
                                    label="Remarks"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Receipt No',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                {loader ? (
                                    <Button
                                        // disabled={selectedDepartment.location !== receipt.city}
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                        className="submit-button"
                                    >
                                        SUBMIT
                                    </Button>
                                ) : null}

                                {/* <Button onClick={onReject} type="secondary" className="submit-button">
                                REJECT
                                </Button> */}
                            </Form>

                            {/* {loader ? (
                                <Alert
                                    style={{ margin: '10px 0px' }}
                                    message={`Please note that you are posting to ${selectedDepartment.location} branch on CS Accounts. If you have selected a wrong branch , It might be usually because you have access to multiple branches. `}
                                    type="warning"
                                    showIcon
                                    closable
                                />
                            ) : null} */}

                            {/* {loader && selectedDepartment.location !== receipt.city ? (
                                <Alert style={{ margin: '10px 0px' }} message="Please select the correct branch to post " type="error" showIcon />
                            ) : null} */}
                        </TabPane>

                        {loader ? (
                            <TabPane tab={`Pending Collections`} key="2">
                                <CollectionSelection
                                    target={'BLK'}
                                    verified={'approved'}
                                    codes={codes}
                                    bankEntry={receipt}
                                    callback={() => {
                                        setVisible(false);

                                        callback();
                                    }}
                                    allDepartments={allDepartments}
                                />
                            </TabPane>
                        ) : null}

                        {view && loader ? (
                            <TabPane tab={`Online Bookings`} key="3">
                                <CollectionSelection
                                    target={'ONL'}
                                    verified={false}
                                    codes={codes}
                                    bankEntry={receipt}
                                    callback={() => {
                                        setVisible(false);

                                        callback();
                                    }}
                                    allDepartments={allDepartments}
                                />
                            </TabPane>
                        ) : null}

                        <TabPane tab={`Payouts`} key="4">
                            <PayoutCollection
                                target={'BLK'}
                                verified={'approved'}
                                codes={codes}
                                bankEntry={receipt}
                                callback={() => {
                                    setVisible(false);

                                    callback();
                                }}
                            />
                        </TabPane>
                    </Tabs>
                </Modal>
                {/* Reconcile Modal Ends */}
            </div>
        </div>
    );
}
