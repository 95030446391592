import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';

import {
    Typography,
    Tabs,
    Space,
    Popconfirm,
    Tag,
    Button,
    Select,
    Card,
    Alert,
    message,
    Input,
    Table,
    Drawer,
    Modal,
    Dropdown,
    Form,
    Menu,
    Radio,
    InputNumber,
    Row,
    Col,
    Skeleton,
} from 'antd';

import Rupee from './../../../../components/rupee/rupee';

import { GlobalContext } from '../../../../Store';

import { MoreOutlined, DeleteOutlined } from '@ant-design/icons';

import { useReactToPrint } from 'react-to-print';

import moment from 'moment';

import './accessory-choices.scss';

import FirebaseUtils from '../../../../utils/firebase.utils';

import ItemSelection from './../item-selection/item-selection';

import { BillDetails, Cart, Branches } from './../../../../models/';

import { GenerateSettlement } from './generate-settlement';
import { cityKeys, dealerCodeKey } from '../../../global-config';

var companyLogo = require('../../../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

const { Option } = Select;

const { TabPane } = Tabs;

const { confirm } = Modal;

var cityCode = dealerCodeKey

var cityKey = cityKeys

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { span: 16 },
};

export default function AccessoryChoices({ request, booking, disableEdit = false, isBooking, callback, city }) {
    // setSelected

    return (
        <Card className="accessory-choices card">
            <Title level={5}>Accessory Choices</Title>

            <AccessoryList disableEdit={disableEdit} request={request} booking={booking} isBooking={isBooking} callback={callback} city={city} />
        </Card>
    );
}

/**
 * Component will load the accesories in cart for this booking
 */
function AccessoryList({ request = { delivery: {} }, booking, disableEdit, isBooking, callback, editCount, city }) {
    const [loading, setLoading] = useState(false);

    const [partsloaded, setPartsloaded] = useState(false);

    const [branch, setBranch] = useState();

    useEffect(() => {
        // loadBranch();
    }, [editCount]);

    //For brach details to add invoice
    // const loadBranch = () => {
    //     var queries = [{
    //         field: 'dealer_code',
    //         value: request.dealerCode
    //     }]
    //     Branches.get(queries).then((result) => {
    //         setBranch(result.branches)
    //     })
    // }

    const { user = { branch: {} } } = useContext(GlobalContext);

    let cityName = user.locations[0];

    let dealerCode = request.dealerCode || booking['Dealer Code'];

    const [visible, setVisible] = useState(false);

    const [form] = Form.useForm();

    const [openModel, setOpenModel] = useState(false);

    const [invoicemodal, setInvoicemodal] = useState(false);

    const [missAlert, setMissAlert] = useState([]);

    const [options, setOptions] = useState([]);

    const [printTab, setPrintTab] = useState('All');

    const [generateBillVisibility, setGenerateBillVisibility] = useState(false);

    const address =
        user.locations[0] == 'kozhikode'
            ? { add1: 'Kannur Rd, Athanikkal, West Hill,', add2: 'Kozhikode, Kerala 673005' }
            : user.locations[0] == 'kannur'
            ? { add1: 'Kairali Nagar, Thottada(PO),', add2: 'Kannur, Kerala 670007' }
            : { add1: 'N.H.213, Ramapuram, Panangangara,', add2: 'Malappuram, Kerala 679321' };

    const print = useRef();

    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        // Load the Cart Items
        loadCart();
    }, [editCount]);

    function loadCart() {
        setLoading(true);

        var queries = [
            {
                field: 'customerID',
                value: booking['Customer ID'],
            },
            {
                field: 'dealerCode',
                value: booking['Dealer Code'],
            },
        ];

        // Load the cart for the bookings
        Cart.getDetailed(queries, dealerCode).then((result) => {
            // Only Accessories and Vas Items

            console.log(result);

            var entries = result
                .filter((record) => record.status !== 'removed')
                .filter((item) => ['Accessories', 'VAS', 'After Market', 'additional'].indexOf(item.type) !== -1);

            entries = entries
                .filter((record) => record.status !== 'removed')
                .sort((a, b) => (a.order ? (a.order > b.order ? 1 : -1) : 1))
                .map((item) => {
                    item.request = request;
                    if (!item.name) {
                        item.name = item.description;
                    }
                    if (!item.quantity) {
                        item.quantity = 1;
                    }

                    if (item.type) {
                        item.updatedType = item.type;
                    }

                    return item;
                });

            let vasArray = entries.filter((item) => {
                return item.type === 'VAS';
            });
            let accessoriesArray = entries.filter((item) => {
                return item.type === 'Accessories';
            });

            let amArray = entries.filter((item) => {
                return item.type === 'After Market';
            });

            if ((vasArray && vasArray[0]) || (accessoriesArray && accessoriesArray[0]) || (amArray && amArray[0])) {
                setGenerateBillVisibility(true);
            }

            let arr = entries;

            //find mandatory column missing items
            let missing = [];
            arr.map((item) => {
                if (item.category === 'VAS' || item.type === 'VAS') {
                    let missField = [];
                    if (item.missing_fields) {
                        item.missing_fields.map((mf) => {
                            missField.push(mf);
                        });
                    }
                    if (missField.length > 0) {
                        missing.push({ name: item.part_name, category: item.category, id: item.id, field: missField });
                    }
                }
            });

            if (missing.length > 0) {
                setMissAlert(missing);
            }

            // If price is missing on any of the cart item

            let notInStock = arr.filter((item) => !item.price);
            console.log(notInStock);

            if (notInStock.length > 0) {
                notInStock.map((ns) => {
                    message.warning(`${ns.name} not in stock!`);
                });

                //set field not_in_stock
                arr.map((item) => {
                    if (!item.price) {
                        item.not_in_stock = true;
                    }
                });
            }

            //setting cart
            setSelected(arr);

            setLoading(false);

            console.log(arr);
        });
    }

    booking = {
        ...{
            accessories: {
                choices: [],
            },
        },
        ...booking,
    };

    const [selected, setSelected] = useState();

    useEffect(() => {
        getParts();
    }, [editCount]);

    /**
     * Get the master data to show options for the user
     */
    function getParts() {
        setPartsloaded(true);

        FirebaseUtils.getItemJoinStock({ dealerCode: cityKey[user.locations[0]] }).then((result) => {
            //filtering for stock exist
            result &&
                result.items.map((item) => {
                    if (!item.price) {
                        item.stockNotExist = true;
                    }
                });

            setOptions(result);

            setPartsloaded(false);
        });
    }

    const showModal = () => {
        setOpenModel(true);
        setSelectedTab('add');
    };

    const getDateAndTime = () => {
        let date = new Date();
        // console.log(date);
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>{moment(date).format('DD MMM YYYY')}</div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const handleItemSubmit = () => {
        if (selectedTab === 'add') {
            form.validateFields()
                .then((values) => {
                    form.resetFields();

                    //Prepare item to add to cart
                    let creation = {
                        created_at: moment().format('DD/MM/YYYY HH:mm'),
                        created_date: moment().startOf('day').valueOf(),
                        created_time: moment().valueOf(),
                        deleted_at: null,
                        deleted_by: null,
                    };

                    let rate = 0;

                    if (values.itemType === 'discount') {
                        rate = -1 * values.itemValue;
                    } else {
                        rate = values.itemValue;
                    }

                    let itemToAdd = {
                        bookingNo: request.bookingNo,
                        requestId: request.id,
                        customerID: request.customer.customerID,
                        type: 'Accessories',
                        subType: 'additional',
                        vinNo: request.allocation.vinNo,
                        description: values.itemName,
                        price: rate,
                        dealerCode: booking['Dealer Code'],
                        ...creation,
                    };

                    FirebaseUtils.addCartItem(itemToAdd).then((res) => {
                        console.log('Added to cart');
                    });

                    setOpenModel(false);
                    callback();
                    message.success('Cart Updated');
                })
                .catch((info) => {
                    console.log('Validate Failed:', info);
                });
        } else {
        }
    };

    const handleCancel = () => {
        setOpenModel(false);
        setSelectedTab('');
    };

    let isDelivered = false;

    if (request && request.delivery) {
        if (['Delivered', 'Delivery Completed'].indexOf(request.delivery.status) !== -1) {
            isDelivered = true;
        }

        // If its allocated , kec should not be able to update
        if (user.role === 'kec') {
            if (request.allocation && request.allocation.status === 'Allocated') {
                isDelivered = true;
            }
        }
    }

    return (
        <div className="accessory-list">
            {!isDelivered && !partsloaded ? (
                <Button
                    className="add-button"
                    disabled={disableEdit}
                    type="dashed"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setVisible(true);
                    }}
                >
                    Add Accessory
                </Button>
            ) : null}
            {/* 
            {['accessories'].indexOf(user.role) !== -1 && !isDelivered ? (
                <Button type="dashed" className="add-discount-button" onClick={showModal}>
                    Add/Discount
                </Button>
            ) : (
                ''
            )} */}

            {['accessories'].indexOf(user.role) !== -1 ? (
                <Button type="dashed" className="print-button" onClick={handlePrint}>
                    Print {printTab}
                </Button>
            ) : null}

            {['accessories'].indexOf(user.role) !== -1 &&
            generateBillVisibility &&
            (printTab === 'VAS' || printTab === 'Accessories' || printTab === 'After Market') ? (
                <Button
                    type="dashed"
                    className="generate-bill-button"
                    onClick={() => {
                        setInvoicemodal(true);
                    }}
                >
                    Raise Invoice
                </Button>
            ) : null}

            <div className="no-print-block">
                {!loading ? (
                    <SelectedList
                        callback={callback}
                        isBooking={isBooking}
                        disableEdit={disableEdit}
                        booking={booking}
                        request={request}
                        items={selected}
                        missAlert={missAlert}
                        updateTab={(val) => {
                            setPrintTab(val);
                        }}
                        cityName={cityName}
                    />
                ) : (
                    <Skeleton />
                )}
            </div>

            {/* Below is only for printing */}
            <div className="print-block">
                <WrapperClass ref={print}>
                    <div className="page-header" style={{ borderBottom: '1px dashed #2f3e4c' }}>
                        <div>
                            <img className="logo-welcome" src={companyLogo} alt="Logo" />

                            <Title level={3}>Proforma Invoice</Title>
                        </div>

                        <div>
                            <div className="address-block">
                                <div style={{ fontSize: '12px' }}>DKH Kia ,</div>
                                <div style={{ fontSize: '10px' }}>{address['add1']} </div>
                                <div style={{ fontSize: '10px' }}>{address['add2']}</div>
                            </div>
                            <div className="date-and-time" style={{}}>
                                {getDateAndTime()}
                            </div>
                        </div>
                    </div>

                    <div gutter={0} className="detail-wrapper" style={{ margin: '25px 0px 25px 0px' }}>
                        <Row>
                            <Col span={12}>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span style={{ fontSize: '10px' }}>Customer</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3
                                            style={{
                                                margin: '2px 0px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {request.customer && request.customer.customerName.toUpperCase()}
                                        </h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span style={{ fontSize: '10px' }}>Place</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3
                                            style={{
                                                margin: '2px 0px',
                                                fontSize: '12px',
                                                width: '50%',
                                            }}
                                        >
                                            {request.customer && request.customer.place}
                                        </h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span style={{ fontSize: '10px' }}>KEC</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3
                                            style={{
                                                margin: '2px 0px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {request.kec && request.kec.toUpperCase()}
                                        </h3>
                                    </Row>
                                </div>
                            </Col>

                            <Col>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span style={{ fontSize: '10px' }}>Phone</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3
                                            style={{
                                                margin: '2px 0px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {request.customer && request.customer.phone}
                                        </h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span style={{ fontSize: '10px' }}>Variant</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3
                                            style={{
                                                margin: '2px 0px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {request.variant}
                                        </h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span style={{ fontSize: '10px' }}>Vin Number</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3
                                            style={{
                                                margin: '2px 0px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {request.allocation && request.allocation.vinNo}
                                        </h3>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col span={24}>
                            <div title="Fund to be Collected">
                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        {selected && selected.length ? (
                                            <SelectedList
                                                printable={true}
                                                callback={callback}
                                                isBooking={isBooking}
                                                disableEdit={disableEdit}
                                                booking={booking}
                                                request={request}
                                                items={selected}
                                                printTab={printTab}
                                                missAlert={missAlert}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <div
                        className="contact-block"
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            left: 0,
                        }}
                    >
                        <div style={{ color: 'black' }}>For queries please contact : +91 9947445566</div>
                    </div>

                    <div
                        className="signature-block"
                        style={{
                            border: '1px solid #f0f0f0',
                            display: 'flex',
                            justifyContent: 'space-between',
                            textAlign: 'center',
                            height: '90px',
                            position: 'absolute',
                            bottom: '25px',
                            left: 0,
                            right: 0,
                        }}
                    >
                        <div
                            className="signature-item"
                            style={{
                                flexBasis: '25%',
                                borderRight: '1px solid #f0f0f0',
                            }}
                        >
                            <div
                                style={{
                                    height: '25px',
                                    borderBottom: '1px solid #f0f0f0',
                                    padding: '1px 0px',
                                }}
                            >
                                Accessories Department
                            </div>
                        </div>
                        <div
                            className="signature-item"
                            style={{
                                flexBasis: '25%',
                                borderRight: '1px solid #f0f0f0',
                            }}
                        >
                            <div
                                style={{
                                    height: '25px',
                                    borderBottom: '1px solid #f0f0f0',
                                    padding: '1px 0px',
                                }}
                            >
                                Accounts Department
                            </div>
                            <div
                                style={{
                                    color: 'grey',
                                    fontSize: '10px',
                                    position: 'absolute',
                                    bottom: '2px',
                                    left: '26%',
                                    textAlign: 'left',
                                }}
                            >
                                Authorised by{' '}
                                <div
                                    style={{
                                        color: 'black',
                                        fontSize: '12px',
                                    }}
                                >
                                    {user && user.name}
                                </div>
                            </div>
                        </div>
                        <div
                            className="signature-item"
                            style={{
                                flexBasis: '25%',
                                borderRight: '1px solid #f0f0f0',
                            }}
                        >
                            <div
                                style={{
                                    height: '25px',
                                    borderBottom: '1px solid #f0f0f0',
                                    padding: '1px 0px',
                                }}
                            >
                                Sales Department
                            </div>
                        </div>
                        <div className="signature-item" style={{ flexBasis: '25%' }}>
                            <div
                                style={{
                                    height: '25px',
                                    borderBottom: '1px solid #f0f0f0',
                                    padding: '1px 0px',
                                }}
                            >
                                Customer
                            </div>
                        </div>
                    </div> */}
                </WrapperClass>
            </div>

            {/* // In/Out Summary Ends */}
            {/* Print Block Ends */}

            <Drawer
                className="accessory-drawer"
                width="100%"
                destroyOnClose={true}
                title="Choose customer choices"
                visible={visible}
                onClose={() => {
                    setVisible(false);
                }}
            >
                {console.log(options)}
                <ItemSelection
                    // selected={selected}
                    choices={selected}
                    options={options.items}
                    callback={(items) => {
                        setLoading(true);
                        setSelected(items);

                        setVisible(false);

                        // On Submit we update the cart with each item
                        // And Update status of request if required
                        return Promise.all(items.map((item) => Cart.addAccessoryItem({ item, booking, request, user }))).then(() => {
                            message.success('Cart Updated');

                            // We have to revert the status of request , if its already billed
                            if (request.accessories && request.accessories.status === 'Invoice') {
                                var params = {
                                    accessories: {
                                        ...request.accessories,
                                        status: 'Ready for Billing',
                                        process: 'incomplete',
                                    },
                                };

                                return FirebaseUtils.updateRequestGeneric(
                                    // request.city,
                                    request.id,
                                    params,
                                    'Accessories Item Added',
                                    'Update on accessories',
                                    'accessories'
                                ).then(() => {
                                    message.success('Please upload the accessories bill for the additional item');

                                    callback();
                                });
                            } else {
                                callback();
                            }
                        });
                    }}
                />
            </Drawer>

            {/* Add/Discount Modal */}
            <Modal
                visible={openModel}
                onOk={handleItemSubmit}
                onCancel={handleCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={handleItemSubmit}>
                        Submit
                    </Button>,
                ]}
                width={selectedTab === 'add' ? 400 : 910}
            >
                <Form form={form} {...layout} name="new-record" layout="vertical" initialValues={{}}>
                    <Form.Item
                        name="itemType"
                        label="Invoice Stats"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a type',
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio value="addition">Addition</Radio>
                            <Radio value="discount">Discount</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name={'itemName'}
                        label="Item Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter an item name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={'itemValue'}
                        label="Item Value"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter an item value',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                </Form>
            </Modal>
            {/* Add/Discount Modal Ends */}

            {/* Invoicing Modal */}
            <Modal onCancel={() => setInvoicemodal(false)} visible={invoicemodal} width={'90%'} footer={null}>
                {selected && (
                    <GenerateSettlement
                        booking={booking}
                        data={selected
                            .filter((item) => {
                                return item.updatedType === printTab;
                            })
                            .map((item) => BillDetails.getDetailedPricing(item, item.round))}
                        callback={() => {
                            setInvoicemodal(false);

                            callback();
                        }}
                        city={city}
                        // billNumber={lastNumber.lastNumber}
                    />
                )}
            </Modal>
            {/* Invoicing Modal Ends */}
        </div>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}

/**
 * Selected List
 */
function SelectedList({ prinatable, items = [], request = {}, callback, booking, updateTab, printTab, missAlert, cityName }) {
    // Entries
    // const [entries, setEntries] = useState(items);

    const { user } = useContext(GlobalContext);

    const [categories, setCategories] = useState([]);

    const [selectedTab, setSelectedTab] = useState('All');

    const count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const [editCount, setEditCount] = useState(0);

    const [selected, setSelected] = useState([]);

    items = items.map((record) => {
        // Callback method
        record.callback = (values) => {
            record.rate = values;
            if (!values.discount_type && !values.discount_value) {
                record.rate = values;
                if (values === '') {
                } else if (isNaN(values) || values.includes('e') || values.includes('E')) {
                    message.warning('Rate should be a number');
                } else {
                    record.rate = parseFloat(values);

                    let totalTax = record.cgst + record.sgst;

                    record.price = parseFloat(record.rate + parseFloat((totalTax / 100) * record.rate));

                    let i = { ...BillDetails.getDetailedPricing(record, record.round) };

                    record.sgst_amount = i.sgst_amount;
                    record.cgst_amount = i.cgst_amount;
                    record.igst_amount = i.igst_amount;
                    record.net_amount = i.net_amount;
                    record.taxable_value = i.taxable_value;
                    record.total = i.total;

                    if (i.discount_amount) {
                        record.discount_amount = i.discount_amount;
                        record.discount_percent = i.discount_percent;
                    }
                }
                record = {
                    ...record,
                    ...values,
                };
                let pricing = Cart.getPricing(record, record.round);
                values = parseFloat(values);
                let price = values + 0.18 * values;
                price = parseFloat(price.toFixed(2));

                values = {
                    price: price,
                    rate: values,
                    ...values,
                    net_amount: pricing.net_amount,
                };

                Cart.update(record.id, values, user).then(() => {
                    console.log(record);
                    // Records captured
                    message.success('Records updated');
                    callback();
                });
                setEditCount((prev) => {
                    return prev + 1;
                });
            } else {
                record = {
                    ...record,
                    ...values,
                };

                let pricing = Cart.getPricing(record, record.round);
                // values = parseFloat(values)
                // let price = values + (0.18 * values)
                // price = parseFloat(price.toFixed(2))

                values = {
                    // price: price,
                    // discount_type:discount_type,
                    // discount_value:discount_value,
                    ...values,
                    net_amount: pricing.net_amount,
                };

                Cart.update(record.id, values, user).then(() => {
                    console.log(record);
                    // Records captured
                    message.success('Records updated');
                    callback();
                });
            }
        };

        return record;
    });

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 30,
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Part Name',
            dataIndex: 'name',
            width: 150,
            render: (text, record) => {
                return (
                    <div>
                        <p>{text ? text : record['name']}</p>

                        {record.package_status === true ? <Tag color="magenta">Package</Tag> : null}

                        {record.not_in_stock === true ? <Tag color="#cd201f">Item is not in stock, Please add!</Tag> : null}

                        <small>{text ? record['part_number'] : null}</small>
                    </div>
                );
            },
        },
        // {
        //     title: 'Rate',
        //     dataIndex: 'rate',
        //     width: 100,
        // },
        {
            title: 'Rate',
            render: (text, record) => {
                return record.editable === true ? (
                    <Text
                        editable={{
                            onChange: record.callback,
                            tooltip: 'click to edit rate',
                        }}
                    >
                        {record.rate}
                    </Text>
                ) : (
                    <Text>{record.rate}</Text>
                );
            },
        },

        printTab
            ? {}
            : {
                  title: 'Quantity',
                  width: 80,
                  // dataIndex: 'quantity',
                  render: (value, key, index) => {
                      return (
                          <Select
                              defaultValue={value.quantity}
                              style={{ width: 50 }}
                              onChange={(v) => {
                                  console.log(v);

                                  onUpdate(index, v, value.id, value);
                              }}
                          >
                              {count.map((ele, key) => (
                                  <Option key={key} value={ele}>
                                      {ele}
                                  </Option>
                              ))}
                          </Select>
                      );
                  },
              },

        {
            title: 'Total',
            dataIndex: 'total',
            width: 100,
        },
        {
            title: 'Discount',
            render: (text, record) => {
                let type = '₹';

                let discount = 0;

                if (record.discount_type) {
                    if (record.discount_type === 'percent') {
                        return (
                            <div>
                                <div style={{ height: '20px' }}>{`${record.discount_value} %`}</div>
                            </div>
                        );
                    } else if ((record.discount_type = 'amount')) {
                        return (
                            <div>
                                <div style={{ height: '20px' }}>{`${record.discount_value} ₹`}</div>
                            </div>
                        );
                    }
                } else
                    return (
                        <div>
                            <div style={{ height: '20px' }}>{discount}</div>
                        </div>
                    );
            },
            width: 90,
        },
        {
            title: 'Taxable',
            dataIndex: 'taxable_value',
            width: 100,
        },

        {
            title: 'CGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cgst_amount ? record.cgst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cgst ? record.cgst : 0}%</div>
                    </div>
                );
            },
            width: 90,
        },

        {
            title: 'SGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.sgst_amount ? record.sgst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.sgst ? record.sgst : 0}%</div>
                    </div>
                );
            },
            width: 90,
        },

        {
            title: 'Net Amount',
            render: (text, record) => record.net_amount,
        },

        printTab
            ? {}
            : {
                  title: 'Actions',
                  render: (entry, index, ele) => {
                      return (
                          <Space>
                              <Popconfirm
                                  title="Are you sure you want to delete this ? "
                                  onConfirm={() => deleteEntry(entry, ele)}
                                  onCancel={() => {}}
                                  okText="Yes"
                                  cancelText="No"
                              >
                                  <Button type="secondary" className="submit-button" disabled={entry.updatedType == 'BILLED (VAS)' ? true : false}>
                                      <DeleteOutlined />
                                  </Button>
                              </Popconfirm>

                              <MoreAction data={entry} callback={entry.callback} />
                          </Space>
                      );
                  },
              },
    ];

    useEffect(() => {
        console.log(items);

        let arr = ['All'];

        items.map((ele) => {
            if (arr.includes(ele.updatedType) === false) {
                arr.push(ele.updatedType);
            }
        });

        setCategories(arr);
    }, [editCount]);

    /**
     *
     */
    const onUpdate = (index, value, id, item) => {
        item.quantity = value;
        let pricing = Cart.getPricing(item, item.round);
        if (request && request.delivery && request.delivery.status) {
            if (['Delivery Completed', 'Delivered'].indexOf(request.delivery.status) === -1) {
                let param = {
                    quantity: value,
                    net_amount: pricing.net_amount,
                };

                FirebaseUtils.updateCartItem(id, param).then(() => {
                    callback();

                    message.success('Cart Updated');
                });
            } else {
                message.error('Updating is not possible for delivered items');
            }
        } else {
            let param = {
                quantity: value,
            };

            FirebaseUtils.updateCartItem(id, param).then(() => {
                callback();

                message.success('Cart Updated');
            });
        }
    };

    /**
     * Delete the Cart Item
     */
    const deleteEntry = (entry, index) => {
        if (request && request.delivery && request.delivery.status) {
            if (['Delivery Completed', 'Delivered'].indexOf(request.delivery.status) === -1) {
                FirebaseUtils.deleteCartItem(entry.id).then(() => {
                    callback();
                });
            } else {
                message.error('Updating is not possible for delivered items');
            }
        } else {
            FirebaseUtils.deleteCartItem(entry.id).then(() => {
                callback();
            });
        }
    };

    /**
     *
     *
     * @param {*} values
     */
    const onSubmit = (values) => {
        var params = {
            accessories: {
                ...request.accessories,
                choices: selected,
                status: 'pending',
                process: 'incomplete',
            },
        };

        var statusUpdate = 'Accessory data collected';

        FirebaseUtils.updateRequestGeneric(
            // request.city,
            request.id,
            params,
            statusUpdate,
            'Update on accessories',
            'accessories'
        ).then(() => {
            message.success('Accessory Details Collected');

            callback();

            setSelected([]);
        });
    };

    /**
     * Function to find the non taxable value to calculate the scss
     *
     * @param {*} parts
     */
    function findNonTaxableTotal(parts) {
        let total = 0;

        parts
            .filter((entry) => {
                return !entry.subType;
            })
            .forEach((entry) => {
                if (entry.type !== 'VAS' && entry.type !== 'After Market') {
                    // For Vas and some items we have predefined the taxable amount to avoid calculation due to disparity in tax of
                    // individual items

                    if (entry.taxable_value) {
                        // Should we roud sum of sgst and cgst
                        if (entry.taxable_value) {
                            // Should we roud sum of sgst and cgst
                            let taxableValue = parseFloat(entry.taxable_value);

                            total += taxableValue;
                        } else {
                            // Should we roud sum of sgst and cgst
                            let taxableValue =
                                ((parseFloat(entry.price) * (entry.quantity ? entry.quantity : 1)) /
                                    (100 + parseFloat(entry.sgst ? entry.sgst : 9) + parseFloat(entry.cgst ? entry.cgst : 9))) *
                                100;

                            total += taxableValue;
                        }
                    } else {
                        // Should we roud sum of sgst and cgst
                        let taxableValue =
                            ((parseFloat(entry.price) * (entry.quantity ? entry.quantity : 1)) /
                                (100 + parseFloat(entry.sgst ? entry.sgst : 9) + parseFloat(entry.cgst ? entry.cgst : 9))) *
                            100;

                        total += taxableValue;
                    }
                }
            });

        return Math.round(total);
    }

    const onTabChange = (key) => {
        updateTab(categories[key]);
        setSelectedTab(categories[key]);
    };

    const filterEntries = (element, cat) => {
        return element.filter((item) => {
            return item.updatedType === cat;
        });
    };

    return (
        <div className="selected-list">
            {items.length ? (
                <>
                    <Row span={24}>
                        <Col span={5}>
                            <Title style={{ display: 'none' }} level={3}>
                                Proforma Invoice
                            </Title>
                        </Col>
                        <Col>
                            {!prinatable && missAlert.length > 0 ? (
                                <Fragment>
                                    <Alert
                                        message={missAlert[0].field.map((item) => item) + ` are missing in item ${missAlert[0].name}`}
                                        type="warning"
                                        showIcon
                                        closable
                                        style={{ margin: 0 }}
                                    />
                                </Fragment>
                            ) : null}
                        </Col>
                    </Row>

                    {/* <Alert className="noprint" message="Below are accessory preferences confirmed by coordinator" type="info" showIcon /> */}

                    <Tabs tabBarStyle={{ display: 'none' }} defaultActiveKey="0" onChange={onTabChange}>
                        {categories.map((cat, index) => {
                            return (
                                <TabPane tab={cat} key={index}>
                                    <Table
                                        className="cart-list-table"
                                        rowKey={(entry) => entry.partNo}
                                        scroll={{ x: true }}
                                        // scroll={{ x: true, y: 750 }}
                                        pagination={false}
                                        columns={columns}
                                        editCount={editCount}
                                        dataSource={
                                            printTab
                                                ? printTab !== 'All'
                                                    ? filterEntries(items, printTab)
                                                    : items
                                                : cat !== 'All'
                                                ? filterEntries(items, cat)
                                                : items
                                        }
                                        size="small"
                                        summary={(pageData) => {
                                            let total = 0;

                                            let request = {};

                                            if (pageData.length) {
                                                request = pageData[0].request;
                                            }

                                            let isMahe = false;

                                            // For Mahe Cases we neednt add KFC
                                            // if (
                                            //     request.customer &&
                                            //     request.customer.place
                                            // ) {
                                            //     if (
                                            //         request.customer.place &&
                                            //         request.customer.place
                                            //             .toLowerCase()
                                            //             .indexOf('mahe') ===
                                            //         -1 &&
                                            //         request.customer.place
                                            //             .toLowerCase()
                                            //             .indexOf(
                                            //                 'pondicherry'
                                            //             ) === -1 &&
                                            //         request.customer.place
                                            //             .toLowerCase()
                                            //             .indexOf(
                                            //                 'puducherry'
                                            //             ) === -1
                                            //     ) {
                                            //     } else {
                                            //         isMahe = true;
                                            //     }
                                            // }

                                            let isKFCPresent = false;

                                            let notTaxableTotal = findNonTaxableTotal(pageData);

                                            pageData.forEach((entry) => {
                                                total = total + entry.net_amount;
                                            });

                                            let cess = (1 / 100) * notTaxableTotal;

                                            let taxableTotal;

                                            if (cat === 'Accessories' || cat === 'All') {
                                                taxableTotal = total;
                                            } else {
                                                taxableTotal = total;
                                            }

                                            if (request && request.accessories && request.accessories.invoiced_date <= 1627669800000) {
                                                isKFCPresent = true;

                                                taxableTotal = taxableTotal + cess;
                                            }

                                            // let taxableTotal = total + parseFloat(cess);

                                            return (
                                                <>
                                                    <Table.Summary.Row>
                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                        <Table.Summary.Cell>Total</Table.Summary.Cell>

                                                        <Table.Summary.Cell></Table.Summary.Cell>

                                                        <Table.Summary.Cell></Table.Summary.Cell>

                                                        <Table.Summary.Cell></Table.Summary.Cell>

                                                        <Table.Summary.Cell></Table.Summary.Cell>

                                                        <Table.Summary.Cell></Table.Summary.Cell>

                                                        <Table.Summary.Cell></Table.Summary.Cell>

                                                        <Table.Summary.Cell>
                                                            <Text>
                                                                <Rupee value={total}></Rupee>
                                                            </Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                    {/* 
                                                    {isMahe ? null : (
                                                        <>
                                                            {cat === 'Accessories' && isKFCPresent ? (
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell></Table.Summary.Cell>

                                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                                    <Table.Summary.Cell>Kerala Flood Cess @ 1%</Table.Summary.Cell>

                                                                    <Table.Summary.Cell></Table.Summary.Cell>

                                                                    <Table.Summary.Cell></Table.Summary.Cell>

                                                                    <Table.Summary.Cell>
                                                                        <Text>
                                                                            <Rupee value={cess}></Rupee>
                                                                        </Text>
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            ) : null}

                                                            <Table.Summary.Row>
                                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                                <Table.Summary.Cell>Total Payable</Table.Summary.Cell>

                                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                                <Table.Summary.Cell></Table.Summary.Cell>


                                                                <Table.Summary.Cell>
                                                                    <Text>
                                                                        <Rupee value={taxableTotal}></Rupee>
                                                                    </Text>
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                        </>
                                                    )} */}
                                                </>
                                            );
                                        }}
                                    />
                                </TabPane>
                            );
                        })}
                    </Tabs>
                </>
            ) : (
                <>
                    <p>Nothing added yet</p>
                </>
            )}

            {selected.length ? (
                <div className="card">
                    <div className="selected-items">
                        {selected.map((entry, key) => {
                            return (
                                <Tag key={key} visible>
                                    {entry['partName']} <span className="title amount">{entry['sellingPrice']}</span>
                                </Tag>
                            );
                        })}
                    </div>

                    <Button
                        style={{ margin: '10px 0px' }}
                        size="small"
                        onClick={() => {
                            onSubmit(true);
                        }}
                        type="primary"
                        className="submit-button"
                    >
                        Confirm
                    </Button>
                </div>
            ) : null}
        </div>
    );
}

/**
 * More Action for Row
 *
 * @returns
 */
function MoreAction({ callback, data }) {
    const [visible, setVisible] = useState(false);

    const handleClick = (params) => {
        console.log(params);
        setVisible(true);
    };

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item>Add Discount</Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={menu} placement="bottomLeft">
                <Button size="small">
                    <MoreOutlined />
                </Button>
            </Dropdown>

            {/* Modal for Adding Discount */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Add Discount"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <DiscountModal
                    data={data}
                    callback={(values) => {
                        setVisible(false);

                        callback(values);
                    }}
                />
            </Modal>
            {/* Modal for Adding Discount Ends */}
        </>
    );
}

/**
 *
 *
 * @returns ]
 */
function DiscountModal({ callback, data }) {
    const onFinish = (values) => {
        callback(values);
        console.log(values);
    };

    console.log(data);

    return (
        <>
            <p>Mention the discount</p>

            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                    discount_type: data.discount_type,
                    discount_value: data.discount_value,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="discount_type"
                    label="Discount"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a type',
                        },
                    ]}
                >
                    <Radio.Group defaultValue={data.discount_type}>
                        <Radio value="percent">In %</Radio>
                        <Radio value="amount">In Rs</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Value"
                    name="discount_value"
                    dependencies={['discount_type']}
                    rules={[
                        { required: true, message: 'Please input your amount' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('discount_type') === 'amount') {
                                    if (value > data.net_amount) {
                                        return Promise.reject(new Error('Discount should not exceed net amount.'));
                                    } else return Promise.resolve();
                                } else if (!value || getFieldValue('discount_type') === 'percent') {
                                    if (value > 100) {
                                        return Promise.reject(new Error('Discount should not exceed 100%.'));
                                    } else return Promise.resolve();
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        }),
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
