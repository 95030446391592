/**
 * Create New users.
 * Super Admin create new users.
 */

import React, { useState, useEffect } from 'react';

import { Form, Button, Input, message, Typography, Select } from 'antd';

import FirebaseUtils from './../../utils/firebase.utils';

import { useHistory } from 'react-router-dom';

import './create-user.scss';

import { Location } from '../../utils/location.utils';
import { Departments } from '../../models';

const { Title } = Typography;

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { span: 8 },
};

function CreateUser({ history }) {
    const [loading, setLoading] = useState(false);

    const [departmentNames, setDepartmentNames] = useState([]);
    let hist = useHistory();

    useEffect(() => {
        let dep_name = [];

        Departments.get().then((result) => {
            result.departments.map((dep) => {
                dep_name.push(dep.name);
            });

            setDepartmentNames(dep_name);
        });
    }, []);

    const onFinish = (values) => {
        setLoading(true);

        console.log(values);

        let userContent = {
            name: values.name,
            email: values.email,
            place: values.place,
            role: values.role,
            phone: values.phone,
            locations: values.locations,
            department_names: values.department_names,
            active: values.active,
        };

        if (['tl', 'kec'].indexOf(values.role) !== -1) {
            userContent.dms = {
                'Consultant Name': values.consultantName,
                teamLeader: values.teamLeader,
            };
        }

        let depId = [];

        return Promise.all(
            values.department_names.map(async (depname) => {
                var q = [
                    {
                        field: 'name',

                        value: depname,
                    },
                ];

                await Departments.get(q).then((res) => {
                    depId.push(res.departments[0].id);
                });
            })
        )
            .then(() => {
                userContent.department_ids = depId;
            })
            .then(() => {
                // Create User Account
                FirebaseUtils.createUser({ email: values.email, password: '123456' })
                    .then((result) => {
                        console.log(result);

                        setLoading(false);

                        if (result.user) {
                            // Create Profile With The key
                            FirebaseUtils.addUserInfo(result.user, userContent).then(() => {
                                message.success('User profile created!');

                                hist.goBack();
                            });
                        } else {
                            message.error('Error');
                        }
                    })
                    .catch((info) => {
                        message.error('User creation failed');

                        hist.goBack();
                    });
            });

        // });
    };

    return (
        <section className="create-user">
            <div className="auth-form-wrapper">
                <div className="form-title">
                    <Title level={4}>Create User Form</Title>
                </div>
                <Form {...layout} layout="vertical" name="basic" onFinish={onFinish}>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Enter user name' }]}>
                        <Input name="name" id="name" placeholder="name" />
                    </Form.Item>

                    <Form.Item name="email" label="email" rules={[{ required: true, message: 'Please Input the email ID' }]}>
                        <Input name="email" id="email" placeholde="email" />
                    </Form.Item>

                    <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please Input Phone Number' }]}>
                        <Input name="phone" id="phone" placeholde="phone" />
                    </Form.Item>

                    <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please Input Role' }]}>
                        <Input name="role" id="role" placeholde="role" />
                    </Form.Item>

                    <Form.Item name={'locations'} label="Locations" rules={[{ required: true, message: 'Please enter Locations' }]}>
                        <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Please select">
                            {['kozhikode', 'kannur', 'malappuram', 'kondotty','tirur','vadakara','kasargod'].map((location, key) => {
                                return (
                                    <Option value={location} key={key}>
                                        {location}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item name={'department_names'} label="Departments" rules={[{ required: true, message: 'Please enter Departments' }]}>
                        <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Please select">
                            {departmentNames.map((department_name, key) => {
                                return (
                                    <Option value={department_name} key={key}>
                                        {department_name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item name="place" label="Place" rules={[{ required: true, message: 'Please Input Place' }]}>
                        <Input name="place" id="place" placeholde="place" />
                    </Form.Item>

                    <Form.Item name="consultantName" label="Consultant Name" rules={[{ required: false, message: 'Please Input Consultant Name' }]}>
                        <Input name="consultantName" id="consultantName" placeholde="consultantName" />
                    </Form.Item>

                    <Form.Item name="teamLeader" label="Team Leader" rules={[{ required: false, message: 'Please Input Place' }]}>
                        <Input name="place" id="place" placeholde="place" />
                    </Form.Item>

                    <Form.Item name="active" label="Active" rules={[{ required: true, message: 'Please Input Active flag' }]}>
                        <Select defaultValue="false" style={{ width: 120 }}>
                            <Option value="true">True</Option>
                            <Option value="false">False</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            SUBMIT
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </section>
    );
}

export default CreateUser;
