import Base from './base';

import { Branches, FinancialYears } from './index';

class InvoiceNumbers extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'invoice_numbers';
    }

    get path() {
        return `invoice_numbers`;
    }

    /**
     * Get the next invoice number for the location and category
     * 
     * @param {*} param0 
     * @returns 
     */
    getNextNumber = ({ location, date, category }) => {

        var branch_queries = [{
            field: 'location',
            value: location
        }]

        return Branches.get(branch_queries).then((result) => {

            var branches = result.branches;

            return FinancialYears.get().then((result) => {

                let data = result.financial_years.filter((item) => date >= item.start_date && date <= item.end_date);

                var queries = [{
                    field: 'branch_id',
                    value: branches[0].dealer_code
                }, {
                    field: 'financial_year_id',
                    value: data[0].id
                }, {
                    field: 'category',
                    value: category
                }]

                return this.get(queries).then((result) => {

                    return result.invoice_numbers[0];

                })

            })

        })


    }
}

export default InvoiceNumbers;
