


import Base from './base';

import { Link } from 'react-router-dom'

class Comments extends Base {

    constructor() {

        super()

        this.fields = []

        this.columns = [];
    }

    get getEndpoint() {
        return 'comments'
    }

    get path() {
        return `comments`
    }

    get getName() {
        return `comment`
    }

    updateRequest = ()=>{


    }

}

export default Comments;