/**
 * 
 * Component will show the Cart Total . 
 * 
 * It will also consider the receipt total to enable the refund portal  
 * 
 * 
 */


import React, { useState, useContext } from 'react';

import { GlobalContext } from '../../Store';

import { Table, Button, Typography, Modal,Popover } from 'antd'

import { CheckCircleTwoTone, WarningOutlined } from '@ant-design/icons'

import Rupee from '../rupee/rupee';

import { BillsSummary } from '../../modules/common/components/bills-summary/bills-summary'

import { AccessorySummary } from '../../modules/common/components/accessory-summary/accessory-summary';

import RefundPortal from '../../modules/common/resources/refund-portal/refund-portal';

import SummaryDescription from '../summary-description/summary-description';

const { Title, Text } = Typography;



export default function PendingSummary({
    cs_accounts,
    breakup,

    ledger = [],
    request = {},
    disableExpand,
    received,
    cart,
    customerReceiptTotal,
    // user,
    isHeadSelected,
    enableBankDetails,
    refresh,
}) {
    const [amount, setAmount] = useState(0);

    const { user = {} } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    const cartColumns = [
        {
            title: '#',
            // dataIndex: 'index',
            // render: (value, item, index) => index + 1
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Description',
            // dataIndex: 'description',
            render: (record) => {
                return <SummaryDescription record={record} callback={record.callback} />;
            },
        },

        {
            title: 'Amount',
            dataIndex: 'price',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{text}</div>;
            },
        },

        {
            title: '',

            render: (record) => {
                // console.log(record);

                return record.description === 'Ex Showroom Price' ? <>{/* <MoreAction callback={record.callback} data={record} /> */}</> : null;
            },
        },
    ];


    if (cs_accounts) {
        // Receipts
        var ledgerReceipts = ledger.filter((entry) => entry.Trn === -1);

        var clearedRecipt = ledgerReceipts.filter((entry) => entry.OpAcPDCStatus === 'R' || entry.OpAcPDCStatus === 'N');

        var clearedTotal = clearedRecipt.reduce((a, b) => a + parseInt(b['Amount']), 0);

        var cleared = -clearedTotal;

        // Cart
        var ledgerCart = ledger.filter((entry) => entry.Trn === 1);

        // Receipts
        var ledgerReceiptTotal = ledgerReceipts.reduce((a, b) => a + parseInt(b['Amount']), 0);

        // Cart Total
        var ledgerCartTotal = ledgerCart.reduce((a, b) => a + parseInt(b['Amount']), 0);
    }

    let props = {};

    if (!disableExpand) {
        props.expandable = {
            expandedRowRender: (record, value) => {
                if (record.type === 'VAS Bills' || record.type === 'Accessories Bills') {
                    return (
                        <BillsSummary
                            type={record.type}
                            cart={cart}
                            items={breakup}
                            category={record.description}
                            disableEdit={true}
                            booking={record.booking}
                            request={request}
                            user={user}
                        />
                    );
                } else
                    return (
                        <AccessorySummary
                            type={record.type}
                            cart={cart}
                            items={breakup}
                            category={record.description}
                            disableEdit={true}
                            booking={record.booking}
                            request={request}
                            user={user}
                        />
                    );
            },
            rowExpandable: (record) => ['Accessories', 'Accessories Bills', 'VAS', 'VAS Bills', 'After Market'].indexOf(record.type) !== -1,
        };
    }

    /**
     * Function to Initiate Refund
     */
    function initiateRefund(amount) {
        setVisible(true);

        setAmount(amount);
    }

    return (
        <>
            <Table
                pagination={false}
                scroll={{ x: true }}
                rowKey={(record, key) => key}
                columns={cartColumns}
                dataSource={breakup}
                {...props}
                size="small"
                summary={(pageData) => {
                    //  #todo Below section will have to be optimized in future as there is enormous amount of rerendering happending for this
                    // component . But it works
                    let total = 0,
                        receivedTotal = 0;

                    pageData.forEach((entry) => {
                        total += parseFloat(entry.price);
                    });

                    total = Math.round(total);

                    let totalDescription,
                    receivedDescription = '';


                    if (cs_accounts) {

                       
                        // Below are description prepared for
                        if (isHeadSelected) {
                            // Prepare description  for Cart total compared with ledger cart total
                            if (ledgerCartTotal === total) {
                                totalDescription = 'Fund to be Collected as per accounts is similar to Surge.';
                            } else {
                                totalDescription = 'There is a difference of ' + (ledgerCartTotal - total).toFixed(2) + ' Rs.';
                            }

                            receivedTotal = -ledgerReceiptTotal;

                            // Prepare description for fund received
                            if (-ledgerReceiptTotal === received) {
                                receivedDescription = 'Fund received as per accounts is similar to DMS receipts.';
                            } else {
                                receivedDescription = 'There is a difference of ' + (-ledgerReceiptTotal - received) + ' Rs.';
                            }
                        } else {


                            // If head is not selected , we consider dms receipts
                            receivedTotal = received;

                            // Total/Received
                            totalDescription = 'Customer Head has to be selected to show the difference.';

                            receivedDescription = 'Customer Head has to be selected to show the difference.';
                        }

                    }
                    else {
                        // When loading data from customer entries 
                        receivedTotal = customerReceiptTotal
                    }

                    if (enableBankDetails) {
                        enableBankDetails.current = Math.round(total - receivedTotal);
                    }

                    return (
                        <>
                            <Table.Summary.Row></Table.Summary.Row>
                            <Table.Summary.Row>
                                {disableExpand ? null : <Table.Summary.Cell></Table.Summary.Cell>}

                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Total</Table.Summary.Cell>

                                {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                <Table.Summary.Cell>
                                    <Popover content={`${totalDescription}`}>
                                    <Text>
                                        <Rupee value={total}></Rupee>



                                        {cs_accounts && !disableExpand && isHeadSelected ? (
                                            <>
                                                <span className="check-icon">
                                                    {ledgerCartTotal === total ? (
                                                        <>
                                                            <CheckCircleTwoTone
                                                                style={{
                                                                    fontSize: '16px',
                                                                }}
                                                                twoToneColor="#52c41a"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <WarningOutlined twoToneColor={'#f7d52a'} />
                                                        </>
                                                    )}
                                                </span>
                                            </>
                                        ) : null}
                                    </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row>
                                {disableExpand ? null : <Table.Summary.Cell></Table.Summary.Cell>}

                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Fund Received</Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    {cs_accounts ? <Popover content={receivedDescription}>
                                        <Text>
                                            <Rupee value={receivedTotal}></Rupee>



                                            {cs_accounts && !disableExpand && isHeadSelected ? (
                                                <>
                                                    <span className="check-icon">
                                                        {-ledgerReceiptTotal === received ? (
                                                            <>
                                                                <CheckCircleTwoTone
                                                                    style={{
                                                                        fontSize: '16px',
                                                                    }}
                                                                    twoToneColor="#52c41a"
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <WarningOutlined twoToneColor={'#f7d52a'} />
                                                            </>
                                                        )}
                                                    </span>
                                                </>
                                            ) : null}
                                        </Text>
                                    </Popover> : <Rupee value={customerReceiptTotal} />}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row>
                                {disableExpand ? null : <Table.Summary.Cell></Table.Summary.Cell>}

                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Balance to Pay</Table.Summary.Cell>
                                <Table.Summary.Cell>
                                   {cs_accounts? <Text>
                                        <strong>
                                            <Rupee value={Math.round(total - receivedTotal)}></Rupee>
                                        </strong>
                                    </Text>:<Text>
                                        <strong>
                                            <Rupee value={Math.round(total - customerReceiptTotal)}></Rupee>
                                        </strong>
                                    </Text>} 
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row>
                                {disableExpand ? null : <Table.Summary.Cell></Table.Summary.Cell>}

                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Pending for clearing</Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>
                                        <strong>
                                            {cs_accounts ? <Rupee value={Math.round(receivedTotal - cleared)}></Rupee> : null}

                                        </strong>
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            {total - receivedTotal < 0 ? (
                                <>
                                    <Table.Summary.Row>
                                        {disableExpand ? null : <Table.Summary.Cell></Table.Summary.Cell>}

                                        <Table.Summary.Cell></Table.Summary.Cell>

                                        <Table.Summary.Cell>{/* Balance to Pay */}</Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            {['accounts', 'admin'].indexOf(user.role) !== -1 ? (
                                                <>
                                                    <Button
                                                        size="small"
                                                        loading={false}
                                                        onClick={() => {
                                                            initiateRefund(total - receivedTotal);
                                                        }}
                                                        type="primary"
                                                    >
                                                        Initiate Refund
                                                    </Button>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                            ) : null}
                        </>
                    );
                }}
            />

            {/* Refund Modal */}

            <Modal
                width={'70%'}
                // size={''}
                destroyOnClose={true}
                header={null}
                footer={null}
                // title={null}
                // title="Refund Customer Payments"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <RefundPortal amount={amount} refresh={refresh} request={request} cs_accounts={cs_accounts} />
            </Modal>
            {/* Refund Modal Ends */}
        </>
    );
}