import React, { useEffect, useContext } from 'react';

import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './../../hocs/PrivateRoute';

import GlobalHeader from './../../components/global-header/global-header';

import Customers from './components/customers/customers';
import CustomerForm from './components/customer-form/customer-form';
// import DashboardOverviewCard from './components/dashboard-overview-card/dashboard-overview-card'

import RequestOverview from './../common/resources/request-overview/request-overview';

import SyncStatus from './../common/components/sync-status/sync-status';

import PreferenceDetail from './../common/components/preference-detail/preference-detail';

import ReviewRequests from './components/review-requests/review-requests';
import ReviewRequestDetail from './components/review-request-detail/review-request-detail';

// import BillingList from './components/billing-list/billing-list';
// import BillingForm from './components/billing-form/billing-form';

import ModuleRoutes from './../common/resources/modules-routes/module-routes';

import DailyOverview from './../common/resources/daily-overview/daily-overview';
import ChangePassword from './../common/components/change-password/change-password';
import ManageRequests from './../common/components/manage-requests/manage-requests';
import ManageStock from './../common/components/manage-stock/manage-stock';

import Cancellations from '../common/components/cancellations/cancellations';
import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import enquiryReport from '../common/resources/enquiry-report/enquiry-report';

import RequestDetail from './../common/components/request-detail/request-detail';

import ManageBookings from './../common/components/manage-bookings/manage-bookings';

import ManageEnquiries from './../common/components/manage-enquiries/manage-enquiries'
import BookingDetail from './../common/components/booking-detail/booking-detail';

import StockDashboard from './../common/components/stock-dashboard/stock-dashboard';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import './edp-landing.scss';

import NoticeBoard from './../common/components/notice-board/notice-board';

import { GlobalContext } from './../../Store';
// import AllocationRequests from "../kec/components/allocation-requests/allocation-requests";

import { edpSteps, stockSchema, stockColumns } from './edp-config';

import {
    receiptsSchema,
    bookingSchema,
    saleSchema,
    purchaseSchema,
    InterStockSchema,
    enquirySchema,
    requestSchema,
    partsSchema,
    partsColumns,
    requestRouteConfig,
} from './../global-config';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import TaskOverview from './../common/resources/task-overview/task-overview';

import VehicleOverview from './../common/resources/vehicle-overview/vehicle-overview';

// import TradingProfit from './../common/resources/trading-profit/trading-profit';

import Profile from './../common/resources/profile/profile';

import MonthWiseRetail from '../common/resources/monthwise-retail/monthwise-retail';

import BookingReport from '../common/resources/booking-report/booking-report';

import StockOutwardDetails from '../pdi/components/stock-outward/stock-outward';
import EnquiryDetail from '../common/components/enquiry-detail/enquiry-detail';

export default React.memo((props) => {
    const { user } = useContext(GlobalContext);

    console.log(user);

    useEffect(() => { }, []);

    const modules = [
        {
            caption: 'Daily Overview',
            path: '/daily-overview',
        },

        {
            caption: 'Vehicle Overview',
            path: '/vehicle-overview',
        },

        {
            caption: 'Reports',
            submenus: [
                {
                    caption: 'Monthwise Retail',
                    path: '/monthwise-retails',
                },

                // {
                //     caption: 'Booking Report',
                //     path: '/booking-report',
                // },
                // {
                //     caption: 'Enquiry Report',
                //     path: '/enquiry-report',
                // },
            ],
        },
        {
            caption: 'Cancellations',
            path: '/cancellations',
        },

        // {
        //     caption: 'Trading Profit',
        //     path: '/trading-profit'
        // },

        {
            caption: 'Requests',
            // path: `/${user.locations[0]}/requests`,
            submenus: [
                {
                    path: `/${user.locations[0]}/request-overview`,
                    caption: 'Request Overview',
                },

                ...requestRouteConfig.map((config) => {
                    return {
                        path: '/' + user.locations[0] + '/' + config.path,
                        caption: config.caption,
                    };
                }),
            ],
        },

        {
            caption: 'Bookings',
            submenus: [
                {
                    path: `/${user.locations[0]}/all-bookings`,
                    caption: 'All Bookings',
                },

                {
                    path: `/${user.locations[0]}/pending-bookings`,
                    caption: 'Pending Bookings',
                },

                {
                    path: `/${user.locations[0]}/billing-done`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivery-done`,
                    caption: 'Delivered Bookings',
                },
            ],
        },

        {
            caption: 'Tasks',
            submenus: [
                // {
                //     path: '/receipts',
                //     caption: 'Customer Receipts'
                // },

                ...edpSteps.filter((step) => !step.isForm),

                // {
                //   path: '/billing-list',
                //   caption: 'For Billing'
                // },
                // {
                //   path: '/for-delivery',
                //   caption: 'For Delivery'
                // },
            ],
        },

        // {
        //   path: '/admin-stock',
        //   caption: 'Allocations',

        //   submenus: [{
        //     path: '/admin-stock',
        //     caption: 'Current Allocations'
        //   }]
        // },

        // {
        //   caption: "Stock",
        //   submenus: [{
        //     path: '/actual-stock',
        //     caption: 'DMS Stock'
        //   }]
        // },

        // {
        //   caption: "Vehicles",
        //   submenus: user.locations.map((location) => {

        //     return {
        //       path: `/${location}/vehicles`,
        //       caption: `${location}`
        //     }

        //   })
        // },

        {
            caption: 'Manage',
            submenus: [
                {
                    path: `/${user.locations[0]}/accessories`,
                    caption: 'Manage Parts',
                },

                {
                    caption: 'Manage Stock',
                    path: '/manage-stock',
                },

                {
                    path: `/${user.locations[0]}/enquiries`,
                    caption: 'Manage Enquiries',
                },

                {
                    caption: 'Manage Bookings',
                    // path: '/manage-bookings',
                    path: `/${user.locations[0]}/bookings`,
                },

                {
                    path: `/${user.locations[0]}/receipts`,
                    caption: 'Manage Receipts',
                },

                {
                    path: `/${user.locations[0]}/sales`,
                    caption: 'Manage Sales',
                },

                {
                    path: `/${user.locations[0]}/purchase`,
                    caption: 'Manage Purchases',
                },

                {
                    path: `/${user.locations[0]}/inter-stock-transfers`,
                    caption: 'Manage Inter Stock',
                },
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => {
                            return (
                                <>
                                    <NoticeBoard />

                                    <TaskOverview steps={edpSteps} />

                                    <SyncStatus />
                                </>
                            );
                        }}
                    />
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    <Route path="/view-stock" component={StockDashboard} userRole={'sm'} />

                    <Route exact path={'/monthwise-retails'} component={MonthWiseRetail}></Route>

                    <Route exact path={'/booking-report'} component={BookingReport}></Route>

                    <Route exact path={'/enquiry-report'} component={enquiryReport}></Route>

                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'sm'} />

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/all-bookings`}
                        component={(...routeParams) => <GenericList dateField={'bookingDate'} schema={bookingSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Bookings Ends */}

                    {/* <PrivateRoute exact path="/review-requests" component={ReviewRequests} userRole={'admin'} />
          <Route exact path={'/review-request/:id'} component={ReviewRequestDetail}></Route> */}

                    {/* <PrivateRoute exact path="/billing-list" component={BillingList} userRole={'admin'} />
          <PrivateRoute exact path={'/billing-list/:id'} component={BillingForm} /> */}

                    {/* <PrivateRoute exact path="/manage-bookings" component={ManageBookings} userRole={'admin'} /> */}
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-requests" component={ManageRequests} userRole={'admin'} />
                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/daily-overview" component={DailyOverview} userRole={'admin'} />

                    <Route
                        exact
                        path="/receipts"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <Customers department={['Department Handling Sales']} {...routeParams} />;
                        }}
                    />

                    <PrivateRoute exact path="/manage-stock" component={ManageStock} userRole={'admin'} />

                    {/* Cancellations Module */}
                    <PrivateRoute exact path="/cancellations" component={Cancellations} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />
                    {/* Cancellations Module ends */}

                    <Route
                        path="/:city/sales"
                        component={() => <ModuleRoutes model="sales" schema={saleSchema} columns={saleSchema.columns} collection="sales" />}
                    />

                    <Route
                        path="/:city/purchase"
                        component={() => (
                            <ModuleRoutes model="purchase" schema={purchaseSchema} columns={purchaseSchema.columns} collection="purchase" />
                        )}
                    />

                    <Route
                        path="/:city/inter-stock-transfers"
                        component={() => (
                            <ModuleRoutes
                                model="inter-stock-transfers"
                                schema={InterStockSchema}
                                columns={InterStockSchema.columns}
                                collection="inter-stock-transfers"
                            />
                        )}
                    />

                    {/* <Route
                        path="/:city/enquiries"
                        component={() => <ModuleRoutes model="enquiries" schema={enquirySchema} columns={enquirySchema.columns} collection="sales" />}
                    /> */}

                    <PrivateRoute exact path="/:city/enquiries" component={ManageEnquiries} userRole={'admin'} />

                    <PrivateRoute exact path="/customer/:id" component={CustomerForm} userRole={'edp'} />

                    <Route
                        path="/:city/receipts"
                        component={() => <ModuleRoutes model="receipts" schema={receiptsSchema} columns={receiptsSchema.columns} />}
                    />

                    <Route
                        path="/:city/vehicles"
                        component={() => <ModuleRoutes model="vehicles" schema={stockSchema} columns={stockColumns} collection="vehicles" />}
                    />

                    <Route
                        path="/:city/accessories"
                        component={() => <ModuleRoutes model="accessories" schema={partsSchema} columns={partsColumns} collection="parts" />}
                    />



                    {/* <Route
                        path="/:city/bookings"
                        component={() => (
                            <ModuleRoutes
                                model="bookings"
                                schema={bookingSchema}
                                disableUpload={false}
                                columns={bookingSchema.columns}
                                collection="bookings"
                            />
                        )}
                    /> */}


                    <PrivateRoute exact path="/:city/bookings" component={ManageBookings} userRole={'admin'} />

                    {/* vehicle overview */}
                    <Route exact path={`/vehicle-overview`} component={(...routeParams) => <VehicleOverview {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    {/* Trading profit */}
                    {/* <Route
                        exact
                        path={`/trading-profit`}
                        component={(...routeParams) => (
                            <TradingProfit {...routeParams} />
                        )}
                    /> */}
                    {/* Trading profit Ends */}

                    {/* Common Routes for Requests */}
                    {requestRouteConfig.map((config) => {
                        return (
                            <Route
                                exact
                                path={`/:city/${config.path}`}
                                component={(...routeParams) => <GenericList schema={requestSchema} queries={config.queries} {...routeParams} />}
                            />
                        );
                    })}
                    {/* Common Routes for Requests Ends */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList schema={bookingSchema} queries={bookingSchema.pendingBookings} {...routeParams} />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => <GenericList queries={bookingSchema.billingQueries} schema={bookingSchema} {...routeParams} />}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList queries={bookingSchema.deliveryQueries} schema={bookingSchema} {...routeParams} />
                        )}
                    />
                    {/* Delivery Bookings Ends */}

                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />

                    <Route exact path={'/profile'} component={Profile}></Route>

                    <Route exact path="/stock-outward/:id" component={StockOutwardDetails}></Route>
                    <PrivateRoute exact path="/enquiries/:id" component={EnquiryDetail} userRole={'admin'} />


                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={edpSteps} list={ReviewRequests} form={ReviewRequestDetail} />
                </Switch>
            </GlobalHeader>
        </section>
    );
});
