


import Base from './base';

import { Link } from 'react-router-dom'

class Users extends Base {

    constructor() {

        super()

        this.fields = [{
            field: 'name',
            caption: 'Name'
        }, {
            field: 'place',
            caption: 'Place'
        }, {
            field: 'phone',
            caption: 'Phone'
        }]

        this.columns = [];
    }

    get getEndpoint() {
        return 'users'
    }

    get path() {
        return `users`
    }

    get getName() {

    }
    /*
    *  Permission to allow edition
    */
    hasPermission = (user, value) => {
        var allowed 
        if (user.permissions && user.permissions.includes(value)) {
            allowed = true
        }
        return allowed
    }

}

export default Users;