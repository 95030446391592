import React, { useState, useEffect, useContext } from 'react';

import { Row, Button, Typography } from 'antd';

import { Link } from 'react-router-dom';

import { Tabs } from 'antd';

import { Tag, Badge } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import './review-requests.scss';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';
import { cityKeys, dealerCodeKey } from '../../../global-config';

const { TabPane } = Tabs;

const { Title } = Typography;

var cityCode = cityKeys

export default function ReviewRequests({ step }) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, [user]);

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="review-requests">
            <Title level={3}>{step.caption}</Title>

            <Tabs defaultActiveKey="1" onChange={callback}>
                {user.locations.map((location, index) => {
                    return (
                        <TabPane tab={`${location} Customers`} key={index}>
                            <RequestList url={location} step={step} />
                        </TabPane>
                    );
                })}
            </Tabs>
        </section>
    );
}

const RequestList = ({ url, step }) => {
    const { user } = useContext(GlobalContext);

    const [allocations, setAllocations] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        setLoader(true);

        // if table is in base (new db structure)
        if (step.otherTable) {
            let code = cityCode[url];
            var queries = step.queries({ code });

            FirebaseUtils.getBaseRecords(queries, step.model).then(
                (vehicles) => {
                    Promise.all(
                        vehicles[step.model].map((vehicle) =>
                            getRequest(vehicle)
                        )
                    ).then((result) => {
                        setAllocations(result);

                        setLoader(false);
                    });
                }
            );
        } else {
            if (step.model === 'stock_transfers') {
                var queries = step.queries({ user });

                console.log(queries,"queries")

                FirebaseUtils.getBaseRecords(
                    queries,
                    step.model || 'requests'
                ).then((vehicles) => {
                    setLoader(false);

                    console.log(vehicles);

                    setAllocations(vehicles[step.model || 'requests']);
                });
            } else {
                FirebaseUtils.getRecords(
                    url,
                    step.queries,
                    step.model || 'requests'
                ).then((vehicles) => {
                    setLoader(false);

                    console.log(vehicles);

                    setAllocations(vehicles[step.model || 'requests']);
                });
            }
        }
    }

    let locations = dealerCodeKey

    function getRequest(vehicle) {
        return FirebaseUtils.getRequest(vehicle.requestId).then((result) => {
            let params = {};

            if (result && result.customer) {
                params = {
                    ...result.customer
                };
            }

            return {
                ...vehicle,
                ...params
            };
        });
    }

    return (
        <>
            <div className="list-header">
                <p className="page-desc">
                    {loader
                        ? 'Loading data'
                        : `Found ${allocations.length} results`}
                </p>

                <div className="list-actions">
                    <Button
                        onClick={() => {
                            getData();
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>
                </div>
            </div>

            <Row className="report-listing-wrapper">
                {loader ? (
                    <PlaceHolder type="listing" />
                ) : (
                    <>
                        {allocations.map((report, index) => {
                            return (
                                <step.Card
                                    step={step}
                                    vehicle={report}
                                    index={report.id}
                                    key={report.id}
                                />
                            );
                        })}
                    </>
                )}
            </Row>
        </>
    );
};
