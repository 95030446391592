/**
 *
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';

import AsyncSelect from 'react-select/async';

import ApiUtils from './../../../../utils/api.utils';

/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function HeadSeletor({ callback, department }) {
    /**
     * Load the matching heads
     */
    function loadHeads(query) {
        // return new Promise((resolve, reject) => {
        //     resolve([{ value: 'place', label: 'Place' }]);
        // });

        return ApiUtils.getHeads(query, department).then((result) => {
            return result.map((item) => {
                return {
                    value: item.ac_code,
                    label: item.ac_desc + '- (' + item.ac_code + ')',
                };
            });
        });
    }

    return (
        <div className="head-selector">
            <AsyncSelect onChange={callback} cacheOptions defaultOptions loadOptions={loadHeads} />
        </div>
    );
}

export default withRouter(HeadSeletor);
