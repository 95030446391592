import React from 'react';

import moment from 'moment';

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';

import { Link } from 'react-router-dom';

import { Tag, Badge } from 'antd';

function TPCard({ vehicle, index, step }) {
    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" to={`${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">{vehicle.bookingNo}</h2>

                {/* {vehicle.finance && vehicle.finance.documentStatus ? <Tag color="orange">{vehicle.finance && vehicle.finance.documentStatus}</Tag> : null} */}

                {/* {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                } */}

                <h3 className="title">{vehicle.customer.customerName}</h3>

                <h2 className="title">{vehicle.customer.phone}</h2>

                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}
                </h3>

                <p className="address">KEC on {vehicle.kec}</p>

                {waitingTime && waitingTime.toString().length > 0 ? (
                    <p className="date-time" style={{ display: 'flex' }}>
                        Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (
                        <div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                    </p>
                ) : null}

                {/* <Tag color="magenta">{vehicle.status}</Tag> */}

                {vehicle.finance && vehicle.finance.documentStatus ? <Badge status="processing" text={vehicle.finance.documentStatus} /> : null}
            </div>
        </Link>
    );
}

/**
 * Columns for Request Table
 */
export const requestColumns = [
    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName',
    },
    {
        type: 'input',
        caption: 'Part Name',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'rto.status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant',
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
];

export const rtoSteps = [
    {
        caption: 'Fast Tag Pending',
        path: '/fast-tag',
        status: 'pending',
        queries: [
            {
                field: 'fasttag.process',
                value: 'incomplete',
            },
            {
                field: 'fasttag.status',
                value: 'pending',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        redirect_to: 'fasttag',
        field_time: 'billing.invoiced_time',
        Card: TPCard,
    },
    // For Login
    {
        caption: 'For Login',
        path: '/for-login',
        status: 'pending',
        redirect_to: 'login-roadtax',
        // model: 'rto.status',
        // process: 'rto.process',
        queries: [
            {
                field: 'rto.process',
                value: 'incomplete',
            },
            {
                field: 'rto.status',
                value: 'pending',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'insurance.issued_time',
        Card: TPCard,
    },
    {
        caption: 'HSRP Pending',
        path: '/hsrp-pending',
        queries: [
            {
                field: 'rto.status',
                value: 'HSRP Pending',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        redirect_to: '/order-hsrp',
        field_time: 'rto.paid_time',
        Card: TPCard,
    },

    {
        caption: 'HSRP Pending (Other)',
        description: 'Other State',
        path: '/hsrp-pending-other',
        queries: [
            {
                field: 'rto.status',
                value: 'HSRP Pending(Other)',
            },
            {
                field: 'rto.type',
                value: 'Other State',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        redirect_to: '/order-hsrp-other',
        field_time: 'rto.paid_time',
        Card: TPCard,
    },

    // Issue Fast Tag
    {
        isForm: true,
        caption: 'Fast Tag Form',
        path: '/fasttag/:city/:id',
        post_status: 'Fast Tag Issue',
        model: 'fasttag',
        fields: [
            {
                type: 'upload',
                field: 'attachment',
                caption: 'Fast Tag ',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                issued_at: moment().format('DD/MM/YYYY HH:mm'),
                issued_date: moment().startOf('day').valueOf(),
                issued_time: moment().valueOf(),
            };

            return {
                fasttag: {
                    ...creation,
                    ...params,
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Fast Tag Issued',
                body: `Fast Tag has been issued for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
    },

    {
        isForm: true,
        caption: 'Issue RTO',
        model: 'rto',
        post_status: 'Pending Payment',
        path: '/login-roadtax/:city/:id',
        fields: [
            {
                field: 'applicationNo',
                type: 'input',
                caption: 'Application No',
            },

            {
                type: 'upload',
                field: 'form20',
                caption: 'Form 20 ',
            },

            {
                type: 'upload',
                field: 'form21',
                caption: 'Form 21 ',
            },

            {
                type: 'upload',
                field: 'form22',
                caption: 'Form 22 ',
            },

            {
                type: 'upload',
                field: 'disclaimer',
                caption: 'Disclaimer ',
            },
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                issued_at: moment().format('DD/MM/YYYY HH:mm'),
                issued_date: moment().startOf('day').valueOf(),
                issued_time: moment().valueOf(),
            };

            return {
                rto: {
                    ...request.rto,
                    ...creation,
                    ...params,
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | RTO Login Complete',
                body: `RTO Forms has been updated on Parviahan Site for ${cName} - ${request.allocation.vinNo} ${request.variant}.`,
            };
        },
    },

    // Login Form
    // {
    //     isForm: true,
    //     caption: 'Road Tax Form',
    //     path: '/tp-issue/:city/:id',
    //     model: 'rto',
    //     post_status: 'Road Tax Issued',
    //     fields: [
    //         {
    //             type: 'checkbox',
    //             field: 'regn_no',
    //             options: ['Yes', 'No'],
    //             caption: 'Reservation ',
    //         },

    //         {
    //             type: 'input',
    //             field: 'hsrp_no',
    //             caption: 'HSRP No',
    //         },

    //         // {
    //         //     type: 'input',
    //         //     field: 'reg_No',
    //         //     caption: 'Temporary Registration'
    //         // },

    //         {
    //             type: 'textarea',
    //             caption: 'Remarks',
    //             field: 'remarks',
    //         },
    //     ],
    //     notification: (request, user) => {
    //         let cName = capitalizeStrings(request.customer.customerName);

    //         return {
    //             title: cName + ' | TP Ready',
    //             body: `Temporary Registration is ready for ${cName} - ${request.allocation.vinNo} ${request.variant}.`,
    //         };
    //     },
    //     extraParams: (request, params) => {
    //         let creation = {
    //             tp_issued_at: moment().format('DD/MM/YYYY HH:mm'),
    //             tp_issued_date: moment().startOf('day').valueOf(),
    //             tp_issued_time: moment().valueOf(),
    //         };

    //         return {
    //             rto: {
    //                 ...request.rto,
    //                 ...creation,
    //                 ...params,
    //             },
    //             delivery: {
    //                 ...request.delivery,
    //                 schedule: 'pending',
    //             },
    //         };
    //     },
    // },

    // Order HSRP
    {
        isForm: true,
        caption: 'Order HSRP',
        path: '/order-hsrp/:city/:id',
        model: 'rto',
        post_status: 'HSRP Receival Pending',
        fields: [
            {
                type: 'radio',
                options: ['Normal', 'Reservation', 'Other State'],
                caption: 'Type',
                field: 'type',
            },

            {
                type: 'input',
                field: 'hsrp_no',
                caption: 'HSRP No',
                condition: (values) => {
                    return values.type !== 'Other State';
                },
            },

            {
                type: 'upload',
                field: 'tp',
                caption: 'Temporary Registration',
                condition: (values) => {
                    return values.type === 'Other State';
                },
            },

            {
                type: 'textarea',
                field: 'remarks',
                caption: 'Remarks',
            },
        ],
        extraParams: (request, params) => {
            if (params.type === 'Other State') {
                let creation = {
                    tp_issued_at: moment().format('DD/MM/YYYY HH:mm'),
                    tp_issued_date: moment().startOf('day').valueOf(),
                    tp_issued_time: moment().valueOf(),
                };

                return {
                    rto: {
                        ...creation,
                        ...params,
                        status: 'HSRP Pending(Other)',
                    },
                    delivery: {
                        ...request.delivery,
                        schedule: 'pending',
                    },
                };
            } else {
                let creation = {
                    hsrp_ordered_at: moment().format('DD/MM/YYYY HH:mm'),
                    hsrp_ordered_date: moment().startOf('day').valueOf(),
                    hsrp_ordered_time: moment().valueOf(),
                };

                return {
                    rto: {
                        ...creation,
                        ...params,
                    },
                    delivery: {
                        ...request.delivery,
                        schedule: 'pending',
                    },
                };
            }
        },
    },

    // Order HSRP For Other State
    {
        isForm: true,
        caption: 'HSRP Pending(Other State)',
        path: '/order-hsrp-other/:city/:id',
        model: 'rto',
        post_status: 'HSRP Receival Pending',
        fields: [
            {
                type: 'input',
                field: 'hsrp_no',
                caption: 'HSRP No',
                condition: (values) => {
                    return values.type !== 'Other State';
                },
            },

            {
                type: 'textarea',
                field: 'remarks',
                caption: 'Remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                hsrp_ordered_at: moment().format('DD/MM/YYYY HH:mm'),
                hsrp_ordered_date: moment().startOf('day').valueOf(),
                hsrp_ordered_time: moment().valueOf(),
            };

            return {
                rto: {
                    ...creation,
                    ...params,
                },
                // delivery: {
                //     ...request.delivery,
                //     // schedule: 'pending'
                // }
            };
        },
    },
];

/**
 * Global Request Schema
 */
export const requestSchema = {
    model: 'requests',
    queries: [],

    pendingRequests: [
        {
            field: 'billing.status',
            value: 'pending',
            // operator: '!='
        },
    ],

    billingRequests: [
        {
            field: 'billing.status',
            value: 'Invoiced',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryRequests: [
        {
            field: 'delivery.status',
            value: 'Delivered',
        },
    ],

    deliveryNoteTakenRequests: [
        {
            field: 'rto.status',
            value: 'Road Tax Issued',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: true,
    columns: [
        {
            type: 'input',
            caption: 'Customer',
            field: 'customer.customerName',
        },

        {
            type: 'input',
            caption: 'Application No',
            field: 'rto.applicationNo',
        },

        {
            field: 'variant',
            type: 'input',
            caption: 'Variant',
        },

        {
            type: 'input',
            caption: 'Phone',
            field: 'customer.phone',
        },

        {
            type: 'input',
            caption: 'Allocation',
            field: 'allocation.status',
        },

        {
            type: 'tag',
            caption: 'Finance',
            field: 'finance.status',
        },

        {
            type: 'input',
            caption: 'Insurance',
            field: 'insurance.status',
        },

        {
            type: 'input',
            caption: 'KEC',
            field: 'kec',
        },

        {
            caption: 'Created At',
            field: 'created_at',
            type: 'input',
        },
    ],
};
