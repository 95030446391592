/**
 *
 *
 */
import Base from './base';

class StatusMaster extends Base {

    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'status_masters';
    }

    get path() {
        return `status_masters`;
    }

    get getName() {
        return `status_masters`;
    }
}

export default StatusMaster;