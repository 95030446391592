/**
 * Change Password by the user.
 * user can change there password as there wish.
 */

import React, { useState, useContext } from 'react';

import { Form, Button, Input, message, Typography } from 'antd';

import { GlobalContext } from './../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import './change-password.scss'

import moment from 'moment';

const { Title, Text } = Typography;


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { span: 8 },
}

function ChangePassword({ history }) {

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {

        setLoading(true);

        if (values.npassword === values.cpassword) { //check the new password and coonform password is same

            FirebaseUtils.changePassword(values).then(function () {

                var params = {
                    passwordChanged: true,
                    password_changed_on: moment().valueOf()
                }

                FirebaseUtils.updateProfile(user.id, params).then(() => {

                    setLoading(false);

                    history.goBack();

                    // Update successful.
                    message.success('Your password has been updated!')

                })

            }).catch(function (error) {

                message.error(error.message)

                setLoading(false);

                // An error happened.
            })
            // sent details to the firebase.uttle for update password
        } else {

            // if the passwords is not same
            setLoading(false)

            message.error("Password does not match")
        }
    }

    return (

        <section className="change-password">
            <div className="auth-form-wrapper">
                <div className="form-title">
                    <Title level={4}>
                        Change Password
                    </Title>

                    <p>To ensure that nobody else has access to your account , Please update your password.</p>
                </div>
                <Form
                    {...layout}
                    layout='vertical'
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name='npassword'
                        label="New Password"
                        rules={[{ required: true, message: 'Please confirm your Password!' }]}>
                        <Input.Password type="password" name="npassword" id="npassword" placeholder="New Password" />
                    </Form.Item>

                    <Form.Item
                        name='cpassword'
                        label="Confirm Password"
                        rules={[{ required: true, message: 'Please confirm your Password!' }]}>
                        <Input.Password type="password" name="cpassword" id="cpassword" placeholder="Confirm Password" />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            SUBMIT
						</Button>
                    </Form.Item>

                </Form>
            </div>
        </section>
    )
}

export default ChangePassword;
