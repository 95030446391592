/**
 * Practice documenting the file as you write
 */

// Load the Native Libraries

import React, { useEffect, useState, useContext } from 'react';

// Load the Dependent Libs
import { Link } from 'react-router-dom';

// Load theme Components
import {
    Table,
    Dropdown,
    Menu,
    Typography,
    Button,
    Space,
    Tabs,
    Tag,
    Progress,
    Statistic,
    Row,
    Col,
    DatePicker,
    message,
    Select,
    Input,
    Modal,
    Form,
    Drawer,
    Checkbox,
    Popconfirm,
} from 'antd';

import Rupee from '../../../../components/rupee/rupee';

import { GlobalContext } from '../../../../Store';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';
import FileUpload from './../../../../components/file-upload/file-upload';
import RequestDetail from './../../../common/components/request-detail/request-detail';

import ConfirmModal from './../../../../components/confirm-modal/confirm-modal';

import { Settlement } from './finance-payout-settlement';

import { OrderedListOutlined, DownOutlined, CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Payouts, FinanceBanks, FinanceBranches, Branches, Departments, Items, InvoiceNumbers, Settings } from '../../../../models';

import moment, { months } from 'moment-timezone';

import './finance-payout-tracker.scss';
import { cityKeys } from '../../../global-config';

// import { ExportXLS } from './ExportXLS';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea, Search } = Input;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};

let arr = [];

let branchArr = [];

let filters = {
    statusTypes: [
        { text: 'pending', value: 'pending' },
        // { text: 'Variance Submission Pending', value: 'Variance Submission Pending' },
        { text: 'Invoice Pending', value: 'Invoice Pending' },
        { text: 'Consolidation Pending', value: 'Consolidation Pending' },
        { text: 'Disbursement Pending', value: 'Disbursement Pending' },
        { text: 'Disbursed', value: 'Disbursed' },
    ],
    financeTypes: [
        { text: 'In House', value: 'In House' },
        { text: 'Own Fund', value: 'Own Fund' },
        { text: 'Direct', value: 'Direct' },
    ],
    accessories: [
        { text: 'pending', value: 'pending' },
        { text: 'Data Collected', value: 'Data Collected' },
        { text: 'Ready for Fitment', value: 'Ready for Fitment' },
        { text: 'Accessories Fitted', value: 'Accessories Fitted' },
        { text: 'Ready for Billing', value: 'Ready for Billing' },
    ],
    finance: [
        { text: 'pending', value: 'pending' },
        { text: 'Data Collected', value: 'Data Collected' },
        { text: 'Documents Collected', value: 'Documents Collected' },
        { text: 'Finance Approval Pending', value: 'Finance Approval Pending' },
        { text: 'Delivery Order Pending', value: 'Delivery Order Pending' },
        { text: 'Disbursement Pending', value: 'Disbursement Pending' },
    ],
    retail: [
        { text: 'pending', value: 'pending' },
        { text: 'requested', value: 'requested' },
        { text: 'approved', value: 'approved' },
        { text: 'Invoiced', value: 'Invoiced' },
    ],
    insurance: [
        { text: 'Ready for Issue', value: 'Ready for Issue' },
        { text: 'Payment Pending', value: 'Payment Pending' },
        { text: 'Payment Done', value: 'Payment Done' },
        { text: 'Insurance Issued', value: 'Insurance Issued' },
    ],
    rto: [
        { text: 'pending', value: 'pending' },
        { text: 'Payment Pending', value: 'Payment Pending' },
        { text: 'Waiting for TP', value: 'Waiting for TP' },
        { text: 'Road Tax Issued', value: 'Road Tax Issued' },
    ],
    delivery: [
        { text: 'requested', value: 'requested' },
        { text: 'Approved', value: 'Approved' },
        { text: 'Delivery Note Taken', value: 'Delivery Note Taken' },
        { text: 'Delivery Completed', value: 'Delivery Completed' },
    ],
    bank: arr,
    branch: branchArr,
};

function getBanks() {
    FinanceBanks.get().then((result) => {
        result.finance_banks.map((bank) => {
            arr.push({ text: bank.name, value: bank.name });
        });
        console.log(arr);
        return arr;
    });
}

function getBranches() {
    FinanceBranches.get().then((result) => {
        result.finance_branches.map((branch) => {
            branchArr.push({ text: branch.name, value: branch.name });
        });
        console.log(branchArr);
        return branchArr;
    });
}

/**
 * Finance Tracker is Meant to give a glimpse of the finance status at any moment
 *
 */

export default function PenetrationTracker({ history }) {
    // model
    const [form] = Form.useForm();

    const [edit, setEdit] = useState(false);

    const [loading, setLoading] = useState(false);

    const [selected, setSelected] = useState([]);

    const [round, setRound] = useState();

    // Drawer
    const [visible, setVisible] = useState(false);

    const [selectedRequest, setSelectedRequest] = useState([]);

    const [departments, setDepartments] = useState([]);

    const { user } = useContext(GlobalContext);

    useEffect(() => {
        getRounding();

        getBanks();

        getBranches();

        getDepartments();
    }, []);

    function getDepartments() {
        var query = [
            {
                field: 'id',
                operator: 'in',
                value: user.department_ids,
            },
            {
                field: 'description',
                value: 'Department Handling Finance',
            },
        ];

        Departments.get(query).then((result) => {
            console.log(result);

            setDepartments(result.departments);
        });
    }

    /**
     * Get NET_AMOUNT decimal rounding
     */

    const getRounding = () => {
        let query = [
            {
                field: 'name',
                value: 'calculation-rounding',
            },
        ];
        Settings.get(query).then((res) => {
            setRound(res.settings[0]);
        });
    };

    const payoutsColumns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Customer',
            dataIndex: 'customerName',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{record['customerName']}</div>
                            <div>{record['phone']}</div>
                        </div>
                    </Space>
                );
            },
            width: 150,
        },
        {
            title: 'Variant',
            dataIndex: 'variant',
            width: 150,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 150,
        },
        {
            title: 'Bank',
            dataIndex: 'bank',
            render: (text, record) => {
                return (
                    <div
                        style={{
                            fontSize: '12px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {record.bank}
                    </div>
                );
            },
            width: 150,
            filters: filters['bank'],
            onFilter: (value, record) => {
                console.log(record);
                if (record && record.bank === value) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            render: (text, record) => {
                return (
                    <div
                        style={{
                            fontSize: '12px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {record.branch}
                    </div>
                );
            },
            width: 150,
            filters: filters['branch'],
            onFilter: (value, record) => {
                console.log(record);
                if (record && record.branch === value) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        {
            title: 'Sanctioned Amount',
            dataIndex: 'sanctioned_amount',
            width: 150,
        },

        {
            title: 'Payout',
            dataIndex: 'payout',
            width: 150,
        },

        {
            title: 'Actual Payout',
            dataIndex: 'actual_payout',
            width: 150,
        },

        {
            title: 'Current Status',
            // dataIndex: 'current.status',
            render: (text, record) => {
                // return (
                //     <Space size="middle">
                //         <Select defaultValue={record.status ? record.status : 'Not updated'} style={{ width: 120 }}
                //             onChange={(ele) => { handleStatusChange(ele, record) }}
                //         >
                //             {
                //                 filters['statusTypes'].map((ele) => {
                //                     return <Option value={ele.value}>{ele.value}</Option>
                //                 })
                //             }
                //         </Select>
                //     </Space>
                // )
                let tagColor = 'gold';
                // if (record.status) {
                //     if (record.status == 'Invoice Pending') {
                //         tagColor = 'warning';
                //     } else if (record.status == 'Consolidation Pending') {
                //         tagColor = 'warning';
                //     } else if (record.status == 'Disbursement Pending') {
                //         tagColor = 'warning';
                //     } else if (record.status == 'Disbursed') {
                //         tagColor = 'success';
                //     } else {
                //         tagColor = 'gold';
                //     }
                // }
                return record.status ? <Tag color={tagColor}>{record.status}</Tag> : null;
            },
            width: 150,
            filters: filters['statusTypes'],
            // onFilter: (value, record) => record.current ? record.current.currentStatus ? record.current.currentStatus.indexOf(value) === 0 : null : null,
        },

        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return record.type === 'additional' && record.status === 'Invoice Pending' ? (
                    <Space size="middle">
                        <Popconfirm
                            title="Are you sure you want to delete this item ? "
                            onConfirm={() => deleteEntry(record)}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" size={'small'}>
                                Delete
                            </Button>
                        </Popconfirm>
                    </Space>
                ) : (
                    <Space size="middle">
                        {record.status === 'Invoice Pending' ? (
                            <Button
                                onClick={() => {
                                    setEdit(true);
                                    setSelected({ record: record, type: 'edit' });
                                }}
                                type="secondary"
                                size={'small'}
                            >
                                Edit
                            </Button>
                        ) : null}

                        {/* {
                            record.status === 'Invoice Pending' ?
                                <Button
                                    onClick={() => {
                                        setEdit(true);
                                        setSelected({ record: record, type: 'upload' });
                                    }}
                                    type="secondary"
                                    size={'small'}>
                                    Upload
                                </Button>
                                :
                                null
                        } */}
                        <Button
                            onClick={() => {
                                setVisible(true);
                                setSelectedRequest(record);
                            }}
                            type="link"
                            size={'small'}
                        >
                            View
                        </Button>
                    </Space>
                );
            },
        },
    ];

    // var payoutsQueries = (range) => {
    //     return [];
    // };

    /**
     * On edit model close
     */
    function closeModal() {
        setEdit(false);
    }

    /**
     * On edit model close
     */
    function deleteEntry(item) {
        const { city, id, refresh } = item;
        Payouts.update(id, { status: 'rejected' }).then(() => {
            message.info('Payout deleted successfully.');
            refresh();
        });
    }

    const getRoundedAmount = (value, round) => {
        value = parseFloat(value.toFixed(round));

        return value;
    };

    function calculatedAmount(amt) {
        let cgst_amount = amt * 0.09;
        let sgst_amount = amt * 0.09;
        let total = amt + parseFloat(cgst_amount) + parseFloat(sgst_amount);
        return { total, cgst_amount, sgst_amount };
    }

    function calculateTaxableAmount(amt) {
        let taxableAmount;

        let cgst = 9;

        let sgst = 9;

        let total_tax = cgst + sgst;

        let rate = (100 * amt) / (100 + total_tax);

        taxableAmount = rate;

        let cgstAmount = (taxableAmount * cgst) / 100;

        let sgstAmount = (taxableAmount * sgst) / 100;

        return { taxableAmount, cgstAmount, sgstAmount };
    }

    /**
     * On edit model submit
     */
    function submitRequest() {
        setLoading(true);
        form.validateFields()
            .then((values) => {
                const { record, type } = selected;
                const { city, id, refresh } = record;

                let params = {
                    ...values,
                };

                if (values.tax_inclusive === false) {
                    let amounts = calculatedAmount(parseFloat(values.actual_payout));
                    params = {
                        ...params,
                        cgst: 9,
                        sgst: 9,
                        actual_payout: parseFloat(values.actual_payout),
                        cgst_amount: getRoundedAmount(amounts.cgst_amount, round.round),
                        sgst_amount: getRoundedAmount(amounts.sgst_amount, round.round),
                        final_payout: getRoundedAmount(amounts.total, round.round),
                    };
                } else if (values.tax_inclusive === true) {
                    let tax_inclusive_amounts = calculateTaxableAmount(parseFloat(values.actual_payout));

                    params = {
                        ...params,
                        cgst: 9,
                        sgst: 9,
                        actual_payout: getRoundedAmount(tax_inclusive_amounts.taxableAmount, round.round),
                        cgst_amount: getRoundedAmount(tax_inclusive_amounts.cgstAmount, round.round),
                        sgst_amount: getRoundedAmount(tax_inclusive_amounts.sgstAmount, round.round),
                        final_payout: getRoundedAmount(parseFloat(values.actual_payout), round.round),
                    };
                }

                Payouts.update(id, params).then(() => {
                    message.info('Details edited successfully');
                    setEdit(false);
                    setLoading(false);
                    refresh();
                });
            })
            .catch((info) => {
                console.log('info', info);
                setEdit(false);
                setLoading(false);
            });
    }

    /**
     * Function handles trigger of onupload
     *
     * @param {*} element
     * @param {*} attachments
     */
    function onUpload(field, attachments) {
        form.setFieldsValue({ [field]: attachments });
    }

    function onClose() {
        setVisible(false);
    }

    console.log('selected', selected);

    console.log(user);

    return (
        <>
            <div>
                <div className="finance-tracker listing">
                    <div className="page-header">
                        <div className="left">
                            <Title level={3}>Finance Payout</Title>
                        </div>
                    </div>
                </div>

                <Tabs defaultActiveKey="0" type="card" size={'small'}>
                    <TabPane tab="Payouts" key="0">
                        <PayoutComponent columns={payoutsColumns} departments={departments} round={round} getRoundedAmount={getRoundedAmount} />
                    </TabPane>
                    <TabPane tab="Bills" key="1">
                        <BillsComponent history={history} departments={departments} />
                    </TabPane>
                </Tabs>

                <Drawer visible={visible} width={'80%'} closable onClose={onClose}>
                    {selectedRequest && selectedRequest.id ? (
                        <RequestDetail hideInfo={true} city={selectedRequest.city} id={selectedRequest.requestId} />
                    ) : null}
                </Drawer>

                <Modal destroyOnClose={true} confirmLoading={loading} title="Update Status" visible={edit} onOk={submitRequest} onCancel={closeModal}>
                    <Form form={form} {...layout} name="new-record" layout="vertical">
                        {selected && selected.record ? (
                            <>
                                {selected.type === 'edit' ? (
                                    <>
                                        <Form.Item
                                            name={'actual_payout'}
                                            label="Actual Payout"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Enter Actual Payout',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="tax_inclusive"
                                            initialValue={false}
                                            valuePropName="checked"
                                            wrapperCol={{ offset: 0, span: 16 }}
                                        >
                                            <Checkbox>Inclusive Of Tax</Checkbox>
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <Form.Item
                                            name={'invoice'}
                                            label="Invoice"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please upload Invoice',
                                                },
                                            ]}
                                        >
                                            <FileUpload
                                                initial={selected.record['invoice']}
                                                maxSize={3}
                                                callback={(attachment) => onUpload('invoice', attachment)}
                                                onProgress={() => {}}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                                {/* <Form.Item name={'status'} label="Current Status" rules={[{ message: 'Please change status' }]}>
                                    <Select allowClear style={{ width: '200%' }} placeholder="Please select" onChange={() => {}}>
                                        {filters['statusTypes'].map((variant, key) => {
                                            return (
                                                <Option value={`${variant.value}`} key={key}>
                                                    {variant.value}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item> */}
                            </>
                        ) : null}
                    </Form>
                </Modal>
            </div>
        </>
    );
}

function PayoutComponent({ columns, departments, round, getRoundedAmount }) {
    const [range, setRange] = useState([moment.tz('Asia/Calcutta').startOf('month'), moment.tz('Asia/Calcutta').endOf('month')]);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [result, setResults] = useState({ requests: [] });

    const [finResult, setFinResult] = useState();

    const [option, setOption] = useState();

    // model for bulk edit
    const [form] = Form.useForm();

    const [bulkEdit, setBulkEdit] = useState(false);

    const [modelLoading, setModelLoading] = useState(false);

    // search
    const [enableSearch, setEnableSearch] = useState(false);

    const [searchedList, setSearchedList] = useState([]);

    // row selection
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    var [selectedRow, setSelectedRow] = useState([]);

    const [lastNumber, setLastNumber] = useState([]);

    const [selectedTab, setSelectedTab] = useState('');

    const [banks, setBanks] = useState([]);

    const [branches, setBranches] = useState([]);

    const [department, setDepartment] = useState();

    const [branch, setBranch] = useState();

    const [additionalItem, setAdditionalItem] = useState();

    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const [loader, setLoader] = useState(false);

    const [headDepartment, setHeadDepartment] = useState();

    const [headId, setHeadId] = useState();

    const [bank, setBank] = useState();

    var cityKey = cityKeys

    const onSelectChange = (keys, record) => {
        setSelectedRowKeys(keys);
        setSelectedRow(record);
    };

    function updateTime(dt) {
        setRange(dt);
        getRequests(selectedDepartments, dt);
    }

    useEffect(() => {
        getHeadId();

        loadBranch();

        getBanks();

        //getBranches();

        getAdditionalItem();
    }, []);

    useEffect(() => {
        getBranches(bank);
    }, [bank]);

    function getBanks() {
        // Get all the Fanks
        FinanceBanks.get().then((result) => {
            let banks = [];

            result.finance_banks.map((bank) => {
                banks.push({
                    ...bank,
                });
            });

            setBanks(banks);
        });
    }

    function getBranches(bank) {
        //get all branhces
        var qry = [
            {
                field: 'bank',
                value: bank,
            },
        ];
        FinanceBranches.get(qry).then((result) => {
            let branches = [];

            result.finance_branches.map((branch) => {
                branches.push({
                    ...branch,
                });
            });

            setBranches(branches);
        });
    }

    function getAdditionalItem() {
        let qry = [
            {
                field: 'category',
                value: 'Finance Additional',
            },
        ];

        return Items.get(qry).then((result) => {
            setAdditionalItem(result.items[0].id);
        });
    }

    function refresh() {
        setRange(range);
        getRequests(selectedDepartments, range);
    }

    function getHeadId() {
        var q = [
            {
                field: 'name',
                value: 'Finance Head Office',
            },
        ];

        Departments.get(q).then((res) => {
            setHeadDepartment(res.departments[0]);
            setHeadId(res.departments[0].id);
        });
    }

    // For brach details to add invoice
    const loadBranch = () => {
        var queries = [
            {
                field: 'dealer_code',
                value: cityKey[user.locations[0]],
            },
        ];
        Branches.get(queries).then((result) => {
            setBranch(result.branches[0].id);

            let query = [
                {
                    field: 'branch_id',
                    value: result.branches[0].id,
                },
                {
                    field: 'description',
                    value: `Department Handling Finance`,
                },
            ];

            //get department details
            return Departments.get(query).then((result) => {
                setDepartment(result.departments[0]);
            });
        });
    };

    function onClick() {
        if (selectedDepartments.length && range) {
            setLoader(true);
            getRequests(selectedDepartments, range);
        } else {
            message.error('Please Select Department');
        }
    }

    /**
     * Get stock Data
     */
    function getRequests(selectedDepartments, range) {
        setLoading(true);

        let query = [
            {
                field: 'created_time',
                operator: '>=',
                value: moment(range[0]).tz('Asia/Kolkata').startOf('day').valueOf(),
            },

            {
                field: 'created_time',
                operator: '<=',
                value: moment(range[1]).tz('Asia/Kolkata').endOf('day').valueOf(),
            },
        ];

        if (!selectedDepartments.includes(headId)) {
            query.push({
                field: 'department_id',
                operator: 'in',
                value: selectedDepartments,
            });
        }

        Payouts.get(query).then((result) => {
            return Promise.all(
                result.payouts
                    .filter((item) => item.status !== 'rejected' || !item.status)
                    .map((requestData) => {
                        let arr = {
                            ...requestData,
                            refresh: refresh,
                        };

                        if (!requestData.requestId) {
                            return arr;
                        }

                        return FirebaseUtils.getRequest(requestData.requestId).then((res) => {
                            arr.request = res;
                            return arr;
                        });
                    })
            ).then((res) => {
                setResults(res);
                setLoading(false);
            });
        });
    }

    function calculatedAmount(amt) {
        let cgst_amount = amt * 0.09;
        let sgst_amount = amt * 0.09;
        let total = amt + parseFloat(cgst_amount) + parseFloat(sgst_amount);
        return { total, cgst_amount, sgst_amount };
    }

    function calculateTaxableAmount(amt) {
        let taxableAmount;

        let cgst = 9;

        let sgst = 9;

        let total_tax = cgst + sgst;

        let rate = (100 * amt) / (100 + total_tax);

        taxableAmount = rate;

        let cgstAmount = (taxableAmount * cgst) / 100;

        let sgstAmount = (taxableAmount * sgst) / 100;

        return { taxableAmount, cgstAmount, sgstAmount };
    }

    const onBulkPayment = () => {
        setModelLoading(true);
        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
            deleted_at:null,
            deleted_by:null
        };

        if (selectedTab === 'add') {
            form.validateFields()
                .then((values) => {
                    console.log(values);
                    // updating status of payout
                    let bank_id;
                    FinanceBanks.get([{ field: 'name', value: values.bank }])
                        .then(async (res) => {
                            var qry = [
                                {
                                    field: 'bank_id',
                                    value: res.finance_banks[0].id,
                                },
                            ];
                            var financeBranch = await FinanceBranches.get(qry);

                            var financeBranchId = financeBranch.finance_branches[0].id;

                            return { bank_id: res.finance_banks[0].id, financeBranchId: financeBranchId };
                        })
                        .then(async (result) => {
                            let body = {
                                ...creation,
                                type: 'additional',

                                branch: values.branch,
                                bank: values.bank,
                                amount: values.amount,
                                payout: 0,
                                actual_payout: values.amount,
                                remarks: values.remarks,
                                tax_inclusive: values.tax_inclusive,
                                status: 'Invoice Pending',
                                item_id: additionalItem,
                                department_id: values.department,
                                bank_id: result.bank_id,
                                finance_branch_id: result.financeBranchId,
                            };

                            if (values.tax_inclusive === false) {
                                let amounts = calculatedAmount(parseFloat(values.amount));
                                body = {
                                    ...body,
                                    cgst: 9,
                                    sgst: 9,
                                    actual_payout: parseFloat(values.amount),
                                    cgst_amount: getRoundedAmount(amounts.cgst_amount, round.round),
                                    sgst_amount: getRoundedAmount(amounts.sgst_amount, round.round),
                                    final_payout: getRoundedAmount(amounts.total, round.round),
                                };
                            } else if (values.tax_inclusive === true) {
                                let tax_inclusive_amounts = calculateTaxableAmount(parseFloat(values.amount));

                                body = {
                                    ...body,
                                    cgst: 9,
                                    sgst: 9,
                                    actual_payout: getRoundedAmount(tax_inclusive_amounts.taxableAmount, round.round),
                                    cgst_amount: getRoundedAmount(tax_inclusive_amounts.cgstAmount, round.round),
                                    sgst_amount: getRoundedAmount(tax_inclusive_amounts.sgstAmount, round.round),
                                    final_payout: getRoundedAmount(parseFloat(values.amount), round.round),
                                };
                            }

                            if (values.city) {
                                body = {
                                    ...body,
                                    city: values.city,
                                };
                            }
                            await Payouts.add(body).then(() => {
                                setBulkEdit(false);
                                setSelectedTab('');
                                setModelLoading(false);
                                refresh();
                            });
                        });
                })
                .catch((info) => {
                    setBulkEdit(false);
                    setSelectedTab('');
                });
        } else {
            selectedRow = selectedRow.map((item) => {
                item.round = round.round;
                item.category = 'finance-payout';
                item.quantity = 1;
                item.purchase_price_without_tax = 0;
                item.rate = item.actual_payout;
                item.item_id = item.id;

                if (item.type === 'additional') {
                    item.name = 'Additional';
                } else {
                    item.name = item.customerName + ' - ' + item.variant;
                }

                item.type = 'finance-payout';
                item.price = item.actual_payout;
                item.tax_id = null;
                item.bookingNo = null;
                if (item.request && item.request.customer) {
                    item.request.customer.customerName = null;
                }

                return item;
            });

            let formBody = {
                selectedIds: selectedRowKeys,
                selectedRecords: selectedRow,
                lastNumber: lastNumber,
                department: department,
                branch_id: branch,
                user: user,
                city: user.locations[0],
                dealerCode: cityKey[user.locations[0]],
                customer_state: 'Kerala',
                isPayout: true,
                notFromCart: true,
                attached_with: null,
                supplier_id: null,
                pan_number: null,
                request: null,

                is_gst_customer: null,
                gst_number: null,
                payment_method: null,
                payment_status: null,
                remarks: null,
                consumer_note: null,
                billDiscountType: null,
                billDiscountValue: null,

                customer_name: selectedRow[0].bank,
                customer_address: selectedRow[0].branch,
                customer_phone: null,
                customer_email: null,
                customer_postal_code: null,
                ship_to_name: null,
                ship_to_address: null,
                ship_to_phone: null,
                ship_to_email: null,
                ship_to_postal_code: null,

                customerID: null,
            };

            console.log(formBody);

            FirebaseUtils.generateVasBill(formBody)
                .then((res) => {
                    console.log(res);
                    setBulkEdit(false);
                    setModelLoading(false);
                    setSelectedRowKeys([]);
                    setSelectedRow([]);
                    message.success('Payout bill created successfully!');
                    refresh();
                })
                .catch((e) => {
                    console.log(e);
                    // setModelLoading(false);
                });
        }
    };

    function getSelectedDepartments(values) {
        setSelectedDepartments(values);
    }

    function selectBank(bank) {
        setBank(bank);
    }

    return (
        <div className="finance-tracker listing">
            <div style={{ display: 'flex' }}>
                <Select mode="multiple" allowClear style={{ width: '30%' }} placeholder="Please select department" onChange={getSelectedDepartments}>
                    {departments.map((dep, key) => {
                        return (
                            <Option value={dep.id} key={key}>
                                {dep.name}
                            </Option>
                        );
                    })}
                </Select>

                <div style={{ margin: '10px' }}>
                    <SearchOutlined style={{ fontSize: '20px' }} onClick={onClick} />
                </div>
            </div>

            <div className="header">
                <div className="search-block">
                    <div style={{ display: 'flex' }}>
                        <Button
                            onClick={() => {
                                setSelectedTab('add');
                                setBulkEdit(true);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            Record Additional Payout
                        </Button>
                        {/* <Search placeholder="input search text" allowClear onSearch={onSearch} style={{}} /> */}
                    </div>
                </div>
                <div className="header-actions">
                    <Button
                        onClick={() => {
                            setSelectedRowKeys([]);
                            setSelectedRow([]);
                            getRequests(selectedDepartments, range);
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>

                    {selectedRowKeys && selectedRowKeys.length > 0 ? (
                        <Button
                            onClick={() => {
                                let selectedData = selectedRow.filter((item) => item.status !== 'Invoice Pending' || !item.actual_payout);
                                if (selectedData && selectedData[0]) {
                                    message.error('Please make sure all selected payout status is Invoice Pending and Actual Payout is edited.');
                                } else {
                                    if (selectedDepartments.includes(headId)) {
                                        //if HO raise the invoice bill number will according to HO invoice number
                                        setDepartment(headDepartment);
                                        var query = [
                                            {
                                                field: 'category',
                                                value: 'finance-payout',
                                            },

                                            {
                                                field: 'branch_id',
                                                value: 'HO',
                                            },
                                        ];
                                        InvoiceNumbers.get(query)
                                            .then((res) => {
                                                let result = res.invoice_numbers[0];

                                                let id = result.prefix + (result.last_number + 1) + result.post_fix;

                                                setLastNumber({ id: result.id, lastNumber: id, numberOnly: result.last_number + 1 });

                                                setBulkEdit(true);
                                            })
                                            .catch(() => {
                                                message.error('Getting Bill Number breaks, Try again! .');
                                            });
                                    } else {
                                        FirebaseUtils.getBillOrder(selectedRow[0].city, 'finance-payout', moment().valueOf())
                                            .then((res) => {
                                                let id = res.prefix + (res.last_number + 1) + res.post_fix;

                                                setLastNumber({ id: res.id, lastNumber: id, numberOnly: res.last_number + 1 });
                                                setBulkEdit(true);
                                            })
                                            .catch(() => {
                                                message.error('Getting Bill Number breaks, Try again! .');
                                            });
                                    }
                                }
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            Raise Invoice
                        </Button>
                    ) : null}

                    <div className="page-actions">
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={updateTime}
                            ranges={{
                                Today: [moment(), moment()],
                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* <Row gutter={16} style={{ width: '75%' }}>
                <Col span={5} xs={16} md={5}>
                    <div className="card">
                        <Statistic
                            title={'Total'}
                             value={loading ? 'Loading' : count.Total}
                        />
                    </div>
                </Col>

                <Col span={5} xs={16} md={5}>
                    <div className="card">
                        <Statistic
                            title={'In House'}
                             value={loading ? 'Loading' : count.InHouse}
                        />
                        <p style={{ color: 'red' }}>{count.InHouseP}%</p>
                    </div>
                </Col>

                <Col span={5} xs={16} md={5}>
                    <div className="card">
                        <Statistic
                            title={'Direct'}
                            value={loading ? 'Loading' : count.Direct}
                        />
                        <p style={{ color: 'red' }}>{count.DirectP}%</p>
                    </div>
                </Col>

                <Col span={5} xs={16} md={5}>
                    <div className="card">
                        <Statistic
                            title={'Own Fund'}
                            value={loading ? 'Loading' : count.OwnFund}
                        />
                        <p style={{ color: 'red' }}>{count.OwnFundP}%</p>
                    </div>
                </Col>
            </Row> */}

            {loader && loading ? (
                <PlaceHolder type="listing" />
            ) : loader && !loading ? (
                <>
                    {result && (
                        <TableView
                            data={enableSearch ? searchedList : result}
                            columns={columns}
                            selectedRowKeys={selectedRowKeys}
                            onSelectChange={(keys, record) => {
                                onSelectChange(keys, record);
                            }}
                        />
                    )}
                </>
            ) : null}

            <Modal
                confirmLoading={modelLoading}
                destroyOnClose={true}
                title="Add Payout"
                visible={bulkEdit}
                onClose={() => setModelLoading(false)}
                onOk={onBulkPayment}
                onCancel={() => {
                    setBulkEdit(false);
                    setModelLoading(false);
                    setSelectedTab('');
                    setSelectedRowKeys([]);
                    setSelectedRow([]);
                }}
                width={920}
            >
                <>
                    {selectedTab === 'add' ? (
                        <Form form={form} {...layout} name="new-record" layout="vertical">
                            <Form.Item
                                name={'department'}
                                label="Department"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Department',
                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                                    {departments.map((item) => {
                                        return (
                                            <Option value={item.id} key={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name={'bank'}
                                label="Bank"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bank',
                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }} placeholder="Please select" onSelect={selectBank}>
                                    {banks.map((item) => {
                                        return (
                                            <Option value={item.name} key={item.name}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={'branch'}
                                label="Branch"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Branch',
                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                                    {branches.map((item) => {
                                        return (
                                            <Option value={item.name} key={item.name}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name={'city'} label="Locations">
                                <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Please select">
                                    {['kozhikode', 'kannur', 'malappuram'].map((location, key) => {
                                        return (
                                            <Option value={location} key={key}>
                                                {location}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={'amount'}
                                label="Amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter amount',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={'remarks'}
                                label="Remarks"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter remarks',
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>

                            <Form.Item name="tax_inclusive" initialValue={false} valuePropName="checked" wrapperCol={{ offset: 0, span: 16 }}>
                                <Checkbox>Inclusive Of Tax</Checkbox>
                            </Form.Item>
                        </Form>
                    ) : (
                        selectedRow && <Settlement data={selectedRow} billNumber={lastNumber.lastNumber} />
                    )}
                </>
            </Modal>
        </div>
    );
}

function TableView({ data, columns, selectedRowKeys, onSelectChange }) {
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Table
            // width="100%"
            size="small"
            scroll={{ x: true }}
            // scroll={{ x: true, y: 750 }}
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            dataSource={data}
            columns={columns}
            // onChange={handleTableChange}
            pagination={false}
            summary={(pageData) => {
                let totalVC = 0;
                let totalDP = 0;
                let totalBalance = 0;

                // pageData.forEach((entry) => {
                //     totalVC += parseFloat(entry['cartTotal']);
                //     totalDP += parseFloat(entry['receiptTotal']);
                //     totalBalance += parseFloat(entry['balance']);
                // });

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            {/* <Table.Summary.Cell>
                                <Title level={5}>Total</Title>
                            </Table.Summary.Cell> */}

                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>
                                {/* <Text>
                                    <Rupee value={totalVC}></Rupee>
                                </Text> */}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                                {/* <Text>
                                    <Rupee value={totalDP}></Rupee>
                                </Text> */}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                                {/* <Text>
                                    <Rupee value={totalBalance}></Rupee>
                                </Text> */}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        ></Table>
    );
}

function BillsComponent({ history, departments }) {
    const [range, setRange] = useState([moment.tz('Asia/Calcutta').startOf('month'), moment.tz('Asia/Calcutta').endOf('month')]);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState({ requests: [] });

    const [finResult, setFinResult] = useState();

    //const [location, setLocation] = useState(user.locations[0]);

    const [viewBill, setViewBill] = useState(false);

    const [selectedData, setSelectedData] = useState({ record: {}, lastNumber: '' });

    const [headId, setHeadId] = useState();

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            width: 150,
        },
        // {
        //     title: 'Description',
        //     // dataIndex: 'description',
        //     width: 150,
        //     render: (value, item) => {
        //         return (
        //             <div>
        //                 Finance payout from {item.customer_name} - {item.customer_address}
        //             </div>
        //         );
        //     },
        // },
        {
            title: 'To',
            // dataIndex: 'to_address',
            width: 150,
            render: (value, item) => {
                return (
                    <div>
                        {item.customer_name} - {item.customer_address}
                    </div>
                );
            },
        },
        // {
        //     title: 'City',
        //     dataIndex: 'city',
        //     width: 150
        // },

        {
            title: 'Invoiced Date',
            dataIndex: 'created_at',

            width: 150,
        },

        {
            title: 'Created By',
            dataIndex: 'created_by_name',
            width: 150,
        },

        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     width: 150,
        // },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Button
                            onClick={() => {
                                history.push(`/bill/${record.id}`);
                            }}
                            type="link"
                            size={'small'}
                        >
                            View
                        </Button>

                        {/* <Button
                            onClick={() => {
                                let arr = [];
                                let payouts = record.payouts;
                                Promise.all(
                                    payouts.map((item) =>
                                        FirebaseUtils.getPayout(item).then((res) => {
                                            arr.push(res);
                                        })
                                    )
                                ).then(() => {
                                    setSelectedData({ payouts: arr, record: record, lastNumber: record.invoiceNumber });
                                    setViewBill(true);
                                });
                            }}
                            type="link"
                            size={'small'}
                        >
                            Print Bill
                        </Button> */}

                        {/* {record.status === 'removed' ? null : (
                            <ConfirmModal
                                caption="Cancel Bill"
                                callback={(val) => {
                                    let payouts = record.payouts;

                                    Promise.all(
                                        payouts.map((item) => FirebaseUtils.updateListing('payouts', item, { status: 'Invoice Pending' }))
                                    ).then(() => {
                                        FirebaseUtils.updateListing('bills', record.id, { status: 'removed', cancel_remarks: val }).then(() => {
                                            refresh();
                                        });
                                    });
                                }}
                            />
                        )} */}
                    </Space>
                );
            },
        },
    ];

    function updateTime(dt) {
        setRange(dt);
        getRequests(dt);
    }

    useEffect(() => {
        getHeadId();

        if (range) {
            getRequests(range);
        }
    }, []);

    function refresh() {
        setRange(range);
        getRequests(range);
    }

    function getHeadId() {
        var q = [
            {
                field: 'name',
                value: 'Finance Head Office',
            },
        ];

        Departments.get(q).then((res) => {
            setHeadId(res.departments[0].id);
        });
    }

    /**
     * Get stock Data
     */
    function getRequests(range) {
        setLoading(true);

        let queries = [
            {
                field: 'created_time',
                operator: '>=',
                value: moment(range[0]).tz('Asia/Kolkata').startOf('day').valueOf(),
            },

            {
                field: 'created_time',
                operator: '<=',
                value: moment(range[1]).tz('Asia/Kolkata').endOf('day').valueOf(),
            },
            {
                field: 'type',
                value: 'finance-payout',
            },

            // {
            //     field: 'department_id',

            //     value: departments,
            // },
        ];

        // if (!headId in user.department_ids) {
        //     queries.push({
        //         field: 'department_id',
        //         operator: 'in',
        //         value: departments,
        //     });
        // }
        FirebaseUtils.getListing(null, queries, 'bills').then((result) => {
            return Promise.all(
                result.bills
                    .filter((item) => item.status !== 'rejected' || !item.status)
                    .map((requestData) => {
                        console.log('requestData', requestData);
                        return requestData;
                    })
            ).then((res) => {
                setResults(res);
                setLoading(false);
            });
        });
    }

    // const menus = user.locations.map((item) => {
    //     return <Menu.Item key={item}>{item}</Menu.Item>;
    // });

    // const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    // function handleMenuClick(e) {
    //     setLocation(e.key);
    //     getRequests(e.key);
    // }

    return (
        <div className="finance-tracker listing">
            <div className="header">
                <div className="header-actions">
                    <Button
                        onClick={() => {
                            getRequests(range);
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>

                    <div className="page-actions">
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={updateTime}
                            ranges={{
                                Today: [moment(), moment()],
                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />
                    </div>
                </div>
            </div>

            {loading ? <PlaceHolder type="listing" /> : <>{result && <TableView data={result} columns={columns} />}</>}
            <Modal
                title="Bill Details"
                visible={viewBill}
                // onOk={onBulkPayment}
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={() => {
                    setViewBill(false);
                    setSelectedData({ payouts: [], record: {}, lastNumber: '' });
                }}
                width={920}
            >
                <>
                    <Settlement data={selectedData.payouts} billNumber={selectedData.lastNumber} bills={selectedData.record} />
                </>
            </Modal>
        </div>
    );
}
