
import React, { useState, useEffect, useContext } from "react";

import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import { Statistic, Tag, Typography, Row, Col } from "antd";

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import './pending-requests-rm.scss';

const { Title, Text } = Typography;


export default function PendingRequestsRM(props) {

    const { user } = useContext(GlobalContext);

    const [results, setResults] = useState({
        kozhikode: {
            requests: []
        },
        kannur: {
            requests: []
        },
        malappuram: {
            requests: []
        },
        konodtty: {
            requests: []
        },
        tirur: {
            requests: []
        },
        vadakara: {
            requests: []
        },
        kasargod: {
            requests: []
        }
    });

    useEffect(() => {
        getPending()
    }, [])

    function getPending() {
        FirebaseUtils.getAllRecords([], 'requests').then((result) => {
            return result.content;
        }).then((data) => {
            setResults(data)
        })
    }

    /**
     * Calculates and return the length of an object.
     * @param {Object} obj - Object containing arrray of requests.
     */
    function getObjectLength(obj) {
        return Object.keys(obj).length;
    }

    { console.log("AHA!", results) }
    return (<div className="summary-content">
        <div className="list-header">
            <div>
                <Title level={4}>
                    Requests
            </Title>
                <div className="rm-pending-requests-container">
                    <div className="card dashboard-item">
                        <Statistic title={`Kozhikkode`} value={getObjectLength(results.kozhikode.requests)} className="dashboard-card" />
                        {/* {{ pathname: `${currentLocation}/pending-request`, search: `?allocation.status=pending` }} */}
                        <Link to={`/${user.locations[0]}/requests`}>
                            View
                    </Link>
                    </div>

                    <div className="card dashboard-item">
                        <Statistic title={`Kannur`} value={getObjectLength(results.kannur.requests)} className="dashboard-card" />
                        <Link to={`/${user.locations[1]}/requests`}>
                            View
                    </Link>
                    </div>

                    <div className="card dashboard-item">
                        <Statistic title={`Malappuram`} value={getObjectLength(results.malappuram.requests)} className="dashboard-card" />
                        <Link to={`/${user.locations[2]}/requests`}>
                            View
                    </Link>
                    </div>

                    <div className="card dashboard-item">
                        <Statistic title={`Kondotty`} value={getObjectLength(results.kondotty.requests)} className="dashboard-card" />
                        {/* {{ pathname: `${currentLocation}/pending-request`, search: `?allocation.status=pending` }} */}
                        <Link to={`/${user.locations[0]}/requests`}>
                            View
                    </Link>
                    </div>

                    <div className="card dashboard-item">
                        <Statistic title={`Tirur`} value={getObjectLength(results.tirur.requests)} className="dashboard-card" />
                        <Link to={`/${user.locations[1]}/requests`}>
                            View
                    </Link>
                    </div>

                    <div className="card dashboard-item">
                        <Statistic title={`Vadakara`} value={getObjectLength(results.vadakara.requests)} className="dashboard-card" />
                        <Link to={`/${user.locations[2]}/requests`}>
                            View
                    </Link>
                    </div>

                    <div className="card dashboard-item">
                        <Statistic title={`Kasargod`} value={getObjectLength(results.kasargod.requests)} className="dashboard-card" />
                        <Link to={`/${user.locations[2]}/requests`}>
                            View
                    </Link>
                    </div>


                    <div className="card dashboard-item">
                        <Statistic title="View Allocations" className="dashboard-card" />
                        <Link to={`/allocation`}>
                            View
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}