/**
 *  This Componet Used to Scan the QR and Redirect to navigate to a specific route
 *
 */
import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { ScanOutlined } from '@ant-design/icons';
import { QrReader } from 'react-qr-reader';

export default function QRSearch({ Callback, facingMode = "environment" }) {

    const [scanVisible, setScanVisible] = useState(false);

    const [result, setResult] = useState('no Result');

    const handleScan = (data) => {
        if (data) {
            // Handle the scanned QR code data
            setResult(data);
            // For example, you can use react-router-dom to navigate to a specific route

            setScanVisible(false); // Hide the scanner after scanning
        }
    };

    /**
     * handle error 
     * @param {*} error 
     */
    const handleError = (error) => {
        console.error(error);
        // Handle error, e.g., display an error message to the user
    };
    /**
     * set visible true when open modal
     */

    const showScanModal = () => {
        setScanVisible(true);
    };
    /**
     * Set visible false 
     */

    const hideScanModal = () => {
        setScanVisible(false);
    };

    /**
     * Scan QR code 
     * @param {*} value 
     */

    function onResult(value) {

        if (value) {
            // set QR code id to the state 
            setResult(value.text);
            // pass the QR id 
            Callback(value.text);
            // Modal visible false after Reading Qr 
            setScanVisible(false);
        }
    }

    return (
        <div>
            <Button type="primary" icon={<ScanOutlined />} onClick={showScanModal}>
                Scan QR Code
            </Button>

            <Modal visible={scanVisible} onCancel={hideScanModal} footer={null}>
                <QrReader onResult={onResult} delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} constraints={{
                    facingMode:'environment'
                }} />
            </Modal>
        </div>
    );
}
