import React, { useState, useEffect, useContext } from 'react'

import { Link } from 'react-router-dom'

import { Tag, Badge } from 'antd'

import FirebaseUtils from '../../../../../utils/firebase.utils';



export default function CustomerCard({ report, index }) {

    return (
        <Link className="report-item" to={`receipt/${report.city}/${report.collectionId}`}>
            <div className="card">
                <h2 className="title">
                    {report.name}
                </h2>
                <h3 className="title">
                    {report.phone}
                </h3>

                <p className="address">
                    {report.account}, {report.location}
                </p>

                <h2 className="title amount">
                    {report['amount'] ? 'Rs' : ''} {report['amount']}
                </h2>

                {report.source === 'bank' ? <Tag color="#108ee9">Online Transfer</Tag> : <Tag color="#108ee9">{report.type}</Tag>}


                {report.source === 'bank' ? <Tag color="#108ee9">{report.bank}</Tag> : null}


                <Badge color="#f50" text={report.city} />

                {/* <Tag color="green">{report.bank}</Tag> */}

                <div>

                    {report.reconciled_by ? <BankEntry report={report} /> : null}

                </div>

                <p className="">
                    Credited on {report['txn_date']} , KEC <strong>{report.kec}</strong>
                </p>

            </div>
        </Link>
    )
}


function BankEntry({ report }) {


    const [record, setRecord] = useState({});

    useEffect(() => {

        if (report.reconciled_by) {
            getBankRecord(report);
        }


    }, [])


    function getBankRecord(report) {

        FirebaseUtils.getBankCreditRecord(report.reconciled_by).then((result) => {

            setRecord(result);
        });

    }


    return (<>

        <Tag style={{ margin: '10px 0px' }} color="magenta">{record.bank}</Tag>


    </>)

}