/**
 * Cancellation
 *
 * @author Nihala Dilshi
 */

import Base from './base';

import { Bills, BillDetails, Cart, StockTransactions } from './';

import FirebaseUtils from '../utils/firebase.utils';

/*
 * Model for Cancellation
 */
class Cancellations extends Base {
    constructor() {
        super();
        this.fields = [];
        this.columns = [];
    }

    /**
     *
     * get()
     *
     */
    get getEndpoint() {
        return 'cancellations';
    }

    /**
     *
     * get()
     *
     */
    get path() {
        return `cancellations`;
    }

    /**
     *
     */
    get getName() {
        return `cancellations`;
    }

    //Cancel Invoice for invoice with type not equal to basic
    cancelInvoice = async (cancelValues, values, record, matching) => {
        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        //Create New record in cancellations

        let setRef = this.getRecordReference();

        await batch.set(setRef, this.appendDefaultValues(cancelValues));

        values.cancellation_id = setRef.id;

        //Update Bills with cancellation_status and id

        let billRef = Bills.getRecordReference(record.id);

        await batch.update(billRef, values);

        let bill_id = values.id;

        var arr = [];

        var query = [
            {
                field: 'bill_id',
                value: bill_id,
            },
        ];

        //  Update Bill details
        return BillDetails.get(query)
            .then((detail) => {
                let det = detail['bill-details'];

                det.map((it) => {
                    arr.push(it);
                });

                return arr;
            })
            .then((arr) => {
                return Promise.all(
                    arr.map(async (item) => {
                        let params = {
                            ...item,
                            cancellation_status: true,
                        };

                        let billDetailsRef = BillDetails.getRecordReference(item.id);

                        await batch.update(billDetailsRef, params);
                    })
                );
            })
            .then((result) => {
                let config = {
                    hideAlert: true,
                };

                if (values.attached_with != 'independent') {
                    Promise.all(
                        matching.map(async (item) => {
                            //Update cart
                            let cartRef = Cart.getRecordReference(item.cart_id);

                            var user = this.getUser();

                            var status = {
                                status: 'active',
                            };

                            await batch.update(cartRef, Cart.appendUpdateDefaultValues(status));
                        })
                    );
                }

                return batch.commit();
            });
    };
}
export default Cancellations;
