import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import {
    Table,
    Dropdown,
    Menu,
    Typography,
    Button,
    Space,
    Progress,
    Tag,
    Popover
} from 'antd';

import { GlobalContext } from '../../../../Store';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { DownOutlined, CheckCircleOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';
import { ExportXLS } from './ExportXLS';

import moment from 'moment';

import './accessory-overview.scss';
import { dealerCodeKey } from '../../../global-config';

const { Title, Text } = Typography;

let filters = {
    accessories: [
        { text: 'pending', value: 'pending' },
        { text: 'Data Collected', value: 'Data Collected' },
        { text: 'Ready for Fitment', value: 'Ready for Fitment' },
        { text: 'Accessories Fitted', value: 'Accessories Fitted' },
        { text: 'Ready for Billing', value: 'Ready for Billing' }
    ],
    model: [
        { text: 'SONET', value: 'SONET' },
        { text: 'SELTOS', value: 'SELTOS' },
        { text: 'CARNIVAL', value: 'CARNIVAL' }
    ],
    retail: [
        { text: 'Completed', value: 'Completed' },
        { text: 'Processing', value: 'Processing' },
        { text: 'Pending', value: 'Pending' }
    ],
};

export default function VehicleOverview({ history, report, index }) {
    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState([]);
    const [vehResult, setVehResult] = useState();

    const [location, setLocation] = useState(user.locations[0]);

    useEffect(() => {
        if (user) {
            getRequests(location);
        }
    }, [user]);

    useEffect(() => {
        // Select required fields from result json array. (for exporting to csv document)
        setVehResult(
            result &&
            result
                .slice(0)
                .map((item) => {
                    return {
                        Vehicle: item.allocation && item.allocation.vinNo ? item.allocation.vinNo : "",
                        Variant: item.variant,
                        color: item.color,
                        Customer: item.customer && item.customer.customerName ? item.customer.customerName : "",
                        Phone: item.customer && item.customer.phone ? item.customer.phone : "",
                        KEC: item.kec,
                        Model: item.model,
                        TeamLeader: item.teamLeader,
                        Allocated: item.allocation && item.allocation['created_time'] ? moment(item.allocation['created_time']).format('DD/MM/YYYY') : "",
                        Retailed: item.billing && item.billing.invoiced_time ? moment(item.billing['invoiced_time']).format('DD/MM/YYYY') : "",
                        // Accessories: item.accessories && item.accessories.status,
                        Accessories: item.accessoriesList &&
                            item.accessoriesList.list &&
                            item.accessoriesList.list['Accessories'] ?
                            item.accessoriesList.list['Accessories'].total
                            :
                            0,
                        VAS:
                            item.accessoriesList &&
                                item.accessoriesList.list &&
                                item.accessoriesList.list['VAS'] ?
                                item.accessoriesList.list['VAS'].total
                                :
                                0,
                        AfterMarket:
                            item.accessoriesList &&
                                item.accessoriesList.list &&
                                item.accessoriesList.list['After Market'] ?
                                item.accessoriesList.list['After Market'].total
                                :
                                0,
                        DataCollected: item.accessories && item.accessories.data_collected_at ? moment(item.accessories.data_collected_at).format('DD/MM/YYYY') : "",
                        Fittment: item.accessories && item.accessories.fitted_at ? moment(item.accessories.fitted_at).format('DD/MM/YYYY') : "",
                        Invoiced: item.accessories && item.accessories.invoiced_at ? moment(item.accessories.invoiced_at).format('DD/MM/YYYY') : "",
                    };
                })
        );
    }, [result]);

    /**
     * Get Data
     */
    function getRequests(location) {
        setLoading(true);

        var queries = [
            {
                field: 'allocation.status',
                operator: '==',
                value: 'Allocated'
            },
            {
                field: 'actual_delivery.status',
                operator: '!=',
                value: 'Delivery Completed'
            }
        ];

        FirebaseUtils.getAccessoryOverview(location, queries, 'requests').then(
            (result) => {
                result=result.filter((data) => data.status !== 'removed');
                console.log('here overview', result);
                setLoading(false);

                setResults(result);
            }
        );
    }

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function handleMenuClick(e) {
        setLocation(e.key);

        getRequests(e.key);
    }

    return (
        <div className="vehicle-overview listing">
            <div className="page-header">
                <div className="left">
                    <Title level={3}>Accessory Overview</Title>
                </div>

                <div className="right">
                    <div className="page-actions">
                        <Dropdown overlay={menu}>
                            <Button size="small">
                                {location} <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>

                    <div>
                        <Button
                            onClick={() => {
                                getRequests(location);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            Refresh
                        </Button>
                    </div>
                    <div>
                        {vehResult && <ExportXLS csvData={vehResult} />}
                    </div>
                </div>
            </div>

            {
                loading ?
                    (
                        <PlaceHolder type="listing" />
                    )
                    :
                    (
                        <>
                            <TableView data={result} />
                        </>
                    )
            }
        </div>
    );
}

function TableView({ data, callback }) {
    const customDot = (dot, { status, index }) => (
        <Popover
            content={
                <span>
                    step {index} status: {status}
                </span>
            }
        >
            {dot}
        </Popover>
    );

    var dealerCode = dealerCodeKey
    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1
        },
        {
            title: 'Customer',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {
                            record.customer && record.customer.customerName ?
                                (
                                    <div>
                                        <Link
                                            style={{ fontSize: '12px' }}
                                            to={`/requests/${record.id}`}
                                        >
                                            {record.customer.customerName}
                                        </Link>
                                        <div style={{ fontSize: '10px', color: 'grey' }}>{record.customer.phone}</div>
                                    </div>
                                )
                                :
                                (
                                    <div style={{ fontSize: '12px' }}>undefined</div>
                                )
                        }
                    </Space>
                );
            },
            sorter: (a, b) => {
                if (
                    a && a.customer &&
                    a.customer.customerName &&
                    b && b.customer &&
                    b.customer.customerName
                ) {
                    return a.customer.customerName.localeCompare(b.customer.customerName);
                } else if (a && a.customer && a.customer.customerName) {
                    return a.customer.customerName.localeCompare('zz');
                } else if (b && b.customer && b.customer.customerName) {
                    return 'zz'.localeCompare(b.customer.customerName);
                } else {
                    return 'zz'.localeCompare('zz');
                }
            },
            defaultSortOrder: 'descend',
            width: 200
        },

        {
            title: 'Vehicle',
            render: (text, record) => {
                var model, variant, color;

                model = record.model;
                variant = record.variant;
                color = record.color;
                var city = dealerCode[record.dealerCode];

                return (
                    <div>
                        <div style={{ fontSize: '12px' }}>
                            <Link
                                style={{ fontSize: '12px' }}
                                to={`/${city}/preference?model=${model}&variant=${variant}&color=${color}`}
                            >
                                {record.allocation.vinNo}
                            </Link>
                        </div>
                        <div style={{ fontSize: '10px' }}>
                            {record.variant}
                        </div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>
                            {record.color}
                        </div>
                    </div>
                );
            },
            sorter: (a, b) => {
                if (a && a.request && a.request.allocation && b && b.request && b.request.allocation) {
                    return (
                        a.request.allocation.status.length -
                        b.request.allocation.status.length
                    );
                } else if (a && a.request && a.request.allocation) {
                    return a.request.allocation.status.length - 0;
                } else if (b && b.request && b.request.allocation) {
                    return 0 - b.request.allocation.status.length;
                } else {
                    return 0 - 0;
                }
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend'],
            width: 200
        },
        {
            title: 'KEC',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <div>{record && record['kec']}</div>
                    </Space>
                );
            },
            width: 200
        },
        {
            title: 'Model',
            dataIndex: 'model',
            filters: filters.model,
            onFilter: (value, record) => record['model'].indexOf(value) === 0,
            width: 150
        },
        {
            title: 'Retail',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {
                            record.billing && record.billing.status === 'Invoiced' ?
                                <Tag color="success">Completed</Tag>
                                :
                                record.billing && (record.billing.status === 'approved' || record.billing.status === 'requested') ?
                                    <Tag color="blue">Processing</Tag>
                                    :
                                    <Tag color="magenta">Pending</Tag>
                        }
                    </Space>
                );
            },
            // filters: filters.retail,
            // onFilter: (value, record) => record['model'].indexOf(value) === 0,
            width: 200
        },
        {
            title: 'Accessories',
            render: (text, record) => {
                let progressPercent;
                if (record.accessories) {
                    if (record.accessories.status == 'pending') {
                        progressPercent = 0;
                    } else if (record.accessories.status == 'Data Collected') {
                        progressPercent = 20;
                    } else if (record.accessories.status == 'Ready for Fitment') {
                        progressPercent = 40;
                    } else if (record.accessories.status == 'Accessories Fitted') {
                        progressPercent = 60;
                    } else if (record.accessories.status == 'Ready for Billing') {
                        progressPercent = 80;
                    } else {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize'
                            }}
                        >
                            {record.accessories && record.accessories.status}
                        </div>
                        <Progress
                            percent={progressPercent}
                            steps={5}
                            showInfo={progressPercent == 0 ? false : true}
                        />
                    </div>
                );
            },
            filters: filters.accessories,
            onFilter: (value, record) => record.accessories && record.accessories.status.indexOf(value) === 0,
            width: 200
        },
        {
            title: 'Accessories',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <div>{record.accessoriesList && record.accessoriesList.list && record.accessoriesList.list['Accessories'] ? record.accessoriesList.list['Accessories'].total : 0}</div>
                    </Space>
                );
            },
            width: 200
        },
        {
            title: 'VAS',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <div>{record.accessoriesList && record.accessoriesList.list && record.accessoriesList.list['VAS'] ? record.accessoriesList.list['VAS'].total : 0}</div>
                    </Space>
                );
            },
            width: 200
        },
        {
            title: 'After Market',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <div>{record.accessoriesList && record.accessoriesList.list && record.accessoriesList.list['After Market'] ? record.accessoriesList.list['After Market'].total : 0}</div>
                    </Space>
                );
            },
            width: 200
        },
        {
            title: 'Total',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <div>{record.accessoriesList && record.accessoriesList.total}</div>
                    </Space>
                );
            },
            width: 200
        },
        {
            title: 'Data Collection',
            width: 200,
            render: (text, record) => {
                return (
                    record.accessories && record.accessories.data_collected_at ?
                        <div style={{ display: 'flex' }}>
                            <div>
                                <div style={{ fontSize: '12px' }}>{record.accessories && record.accessories.remarks}</div>
                                <div style={{ fontSize: '10px' }}>
                                    {record.accessories && record.accessories.data_collected_at}
                                </div>
                                <div style={{ fontSize: '10px', color: 'grey' }}>
                                    {record.accessories.data_collected_at ? moment(record.accessories.data_collected_at, 'DD/MM/YYYY').fromNow() : null}
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                                <CheckCircleOutlined style={{ color: "green" }} />
                            </div>

                        </div>
                        :
                        <Tag color="magenta">pending</Tag>
                )
            }
        },
        {
            title: 'Accessories Fittment',
            width: 200,
            render: (text, record) => {
                return (
                    record.accessories && record.accessories.fitted_at ?
                        <div style={{ display: 'flex' }}>
                            <div>
                                <div style={{ fontSize: '12px' }}>{record.accessories && record.accessories.fitted_remarks}</div>
                                <div style={{ fontSize: '10px' }}>
                                    {record.accessories && record.accessories.fitted_at}
                                </div>
                                <div style={{ fontSize: '10px', color: 'grey' }}>
                                    {
                                        record.accessories.data_collected_at && record.accessories.fitted_at ?
                                            record.accessories && moment(record.accessories.fitted_at, 'DD/MM/YYYY').diff(moment(record.accessories.data_collected_at, 'DD/MM/YYYY'), 'days') + ' days later'
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                                <CheckCircleOutlined style={{ color: "green" }} />
                            </div>
                        </div>
                        :
                        <Tag color="magenta">pending</Tag>
                )
            }
        },
        {
            title: 'Invoice',
            width: 200,
            render: (text, record) => {
                return (
                    record.accessories && record.accessories.invoiced_at ?
                        <div style={{ display: 'flex' }}>
                            <div>
                                <div style={{ fontSize: '12px' }}>{record.accessories && record.accessories.invoiced_remarks}</div>
                                <div style={{ fontSize: '10px' }}>
                                    {record.accessories && record.accessories.invoiced_at}
                                </div>
                                <div style={{ fontSize: '10px', color: 'grey' }}>
                                    {
                                        record.accessories.fitted_at && record.accessories.invoiced_at ?
                                            record.accessories && moment(record.accessories.invoiced_at, 'DD/MM/YYYY').diff(moment(record.accessories.fitted_at, 'DD/MM/YYYY'), 'days') + ' days later'
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                                <CheckCircleOutlined style={{ color: "green" }} />
                            </div>
                        </div>
                        :
                        <Tag color="magenta">pending</Tag>
                )
            }
        },
    ];

    return (
        <Table
            width="100%"
            size="small"
            scroll={{ x: true }}
            rowKey={(record) => record.index}
            dataSource={data}
            columns={columns}
            pagination={false}
        ></Table>
    );
}
