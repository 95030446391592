/**
 * @author Hasna
 */

import React, { useEffect, useState, useRef, useContext } from 'react';
import FirebaseUtils from '../../../../utils/firebase.utils';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, Table, Input, Select, Space, Typography, Alert, message } from 'antd';
import ImageWrapper from './../../../../components/image-wrapper/image-wrapper';
import { GlobalContext } from '../../../../Store';

import { Documents } from './../../../../models/';

const { Title } = Typography;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

/**
 *
 * To display added document
 */
export default function DocumentsComponent({ userRequest }) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [documentType, setDocumentType] = useState('');

    const [remarks, setRemarks] = useState('');

    const [files, setFiles] = useState([]);

    const [documents, setDocuments] = useState([]);

    const { user } = useContext(GlobalContext);

    useEffect(() => {
        loadDocuments();

        return () => { };
    }, []);

    /**
     * Load data for the component
     */
    function loadDocuments() {
        var queries = [
            {
                field: 'bookingNo',
                value: userRequest.bookingNo,
            },
        ];

        Documents.get(queries).then((result) => {
            setDocuments([...result.documents]);
        });
    }

    /**
     * to Upload document
     */
    const uploadProps = {
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles(newFileList);
        },
        onChange(info) {
            // console.log(info);

            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            var random = {
                customerName: 'customerCollections' + Math.round(Math.random() * 1000000),
            };

            FirebaseUtils.handleUpload(file, random, '/collections').then((result) => {
                console.log(result);
                let file = {
                    uid: random.customerName,
                    name: random.customerName,
                    status: 'done',
                    url: result.url,
                    type: result.meta.contentType,
                };
                console.log(file);
                files.push(file);

                setFiles([...files]);
                console.log('file.url', file.url);
                var url = file.url;
            });

            return false;
        },
        files,
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    //
    /**
     * On add modal submit
     */
    const handleOk = () => {
        setIsModalVisible(false);

        Documents.addDocument(documentType, remarks, userRequest, files, user).then(() => {
            loadDocuments();
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    /**
     * Change in document type
     */

    const handleChange = (value) => {
        setDocumentType(value);
    };
    //Remark
    const inputRemark = (e) => {
        setRemarks(e.target.value);
    };

    const handleUpload = (e) => {
        console.log(e);
    };

    return (
        <>
            <div className="vehicle-card">
                <div className="page-header">
                    <div>
                        <Title level={3}>Documents</Title>

                        <Alert message="Have any additional document that you want to record for the customer, Add it here" type="info" />
                    </div>
                    <div className="action">
                        <Button size="small" onClick={showModal}>
                            <PlusOutlined />
                            Add Document
                        </Button>
                    </div>
                </div>

                <div className="page-content">
                    <Modal title="Add Document" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <Space direction="vertical">
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select Document type"
                                optionFilterProp="children"
                                onChange={handleChange}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Option value="insurance">Insurance</Option>
                                <Option value="rto">RTO</Option>
                                <Option value="invoice">Invoice</Option>
                                <Option value="accessories">Accessories</Option>

                                <Option value="delivery">Delivery</Option>

                                <Option value="recipts">Receipts</Option>

                                <Option value="other">Other</Option>
                            </Select>
                            {/* {...uploadProps} fileList={files} */}
                            <Upload {...uploadProps} fileList={files}>
                                <Button icon={<UploadOutlined />} onClick={handleUpload}>
                                    Click to Upload File
                                </Button>
                            </Upload>
                            Remarks:
                            <TextArea rows={4} onChange={inputRemark} />
                        </Space>
                    </Modal>
                    <Table
                        scroll={{ x: true }}
                        pagination={false}
                        dataSource={documents}
                        columns={[
                            {
                                title: '#',
                                dataIndex: 'index',
                                render: (value, item, index) => index + 1,
                            },

                            {
                                title: 'DocumentType',
                                dataIndex: 'documentType',
                                key: 'documentType',
                            },

                            {
                                title: 'Remarks',
                                dataIndex: 'remarks',
                                key: 'remarks',
                            },
                            {
                                title: 'Created By',
                                // dataIndex: 'created_by.name',
                                key: 'created_by',
                                render: (record) => {
                                    return record.created_by && record.created_by.name;
                                },
                            },
                            {
                                title: 'Created At',
                                dataIndex: 'created_at',
                                key: 'created_at',
                            },
                            {
                                title: 'Document',
                                key: 'action',
                                render: (record) => {
                                    return (
                                        <Space size="middle">
                                            <ViewModal record={record} />
                                        </Space>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </>
    );
}

/**
 *
 */
function ViewModal({ record }) {
    const [IsViewVisible, setIsViewVisible] = useState(false);

    const viewOk = () => {
        setIsViewVisible(false);
    };
    const viewCancel = () => {
        setIsViewVisible(false);
    };

    const viewModal = () => {
        setIsViewVisible(true);
    };

    return (
        <>
            <a onClick={viewModal}>View</a>

            <Modal title="Document" visible={IsViewVisible} onOk={viewOk} onCancel={viewCancel}>
                <Space>
                    <ImageWrapper type={Array.isArray(record.file) ? 'array' : 'object'} content={record.file} />
                </Space>
            </Modal>
        </>
    );
}
