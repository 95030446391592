/**
 *
 * Component to get details of all stock available
 */

import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Table, Space, Typography, DatePicker, Select, Form, Row, Col, Button, Tag } from 'antd';

import { GlobalContext } from '../../../../Store';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { Branches, StockPoints, StockTransactions, StockTransfers, Stocks } from '../../../../models';

import Rupee from '../../../../components/rupee/rupee';

import DateUtils from '../../../../utils/date.utils';

import moment from 'moment';

import './stock-transfer.scss';
import { cityKeys } from '../../../global-config';

const { Option } = Select;

const { Text, Title } = Typography;

var cityCode = cityKeys

const dateFormat = 'DD/MM/YYYY';
const formItemLayout = {
    layout: 'vertical',
};

const { RangePicker } = DatePicker;

export default function StockTransferRecord({ resource_number }) {
    const [form] = Form.useForm();

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [result, setResults] = useState([]);
    // set state of branches
    const [branches, setBranches] = useState([]);

    const [stockPoints, setStockpoints] = useState([]);

    const [yardDetails, setYardDetails] = useState([]);

    const [location, setLocation] = useState(user.locations[0]);

    // const [range, setRange] = useState(moment.tz('Asia/Calcutta').startOf('day'));
    const [range, setRange] = useState([moment.tz('Asia/Calcutta').startOf('day'), moment.tz('Asia/Calcutta').endOf('day')]);

    var starttime = moment.tz(process.env.REACT_APP_TIMEZONE).startOf('day');

    //Setting starttime and endtime within a week from today
    var endtime = moment.tz(process.env.REACT_APP_TIMEZONE).endOf('day');

    const transferTypes = [
        { id: "normal", name: "Actual" },
        { id: "virtual", name: "Temporary" }
    ]

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Vin Number',
            dataIndex: 'item_identity_number',
            key: 'Vin Number',
        },
        {
            title: 'To Location',
            dataIndex: 'to',
            key: 'to',
        },

        {
            title: 'Yard To',
            dataIndex: 'to_yard_name',
            key: 'to_yard_name',
        },
        {
            title: 'Yard From',
            dataIndex: 'from_yard_name',
            key: 'from_yard_name',
        },

        {
            title: 'Basic Price',
            dataIndex: 'basic_price',
            key: 'basic_price',
        },

        {
            title: 'Stock Value',
            dataIndex: 'stock_value',
            key: 'stock_value',
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },

        {
            title: "Transfer Type",
            // dataIndex:"sub_mode",
            key: "sub_mode",
            render: (text, record) => {
                if (record.sub_mode === "virtual") {
                    return <div>Temporary Transfer</div>
                } else {
                    return <div>Actual Transfer</div>
                }
            },
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/vehicle/${text.item_identity_number}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        if (user) {
            getStock();
            loadBranch();
        }
    }, [user]);

    /**
     *
     * To load list of branches
     */

    function loadBranch() {
        Branches.get().then((result) => {
            const filteredBranches = result.branches.filter(branch => branch.location); // Filter branches that have a location
            setBranches(filteredBranches);
        });
    }

    /**
     *
     * To load list of branches
     */

    function loadStockPoints(value) {
        var queries = [
            {
                field: 'location',
                value: value,
            },
        ];
        StockPoints.get(queries).then((result) => {
            setStockpoints(result.stock_points);
        });
    }

    function loadYardDeatails(value) {
        var queries = [
            {
                field: 'location',
                value: value,
            },
        ];
        StockPoints.get(queries).then((result) => {
            setYardDetails(result.stock_points);
        });
    }

    /**
     * Get  stock data
     */
    function getStock(value) {
        var arr = [];
        var stock = [];

        var startOf = range[0].startOf('day');
        // converting date to timeStamp
        var timeStampstartOf = DateUtils.getFirebaseTimestamp(startOf);

        var endOf = range[1].endOf('day');
        // converting date to timeStamp
        var timeStampEndof = DateUtils.getFirebaseTimestamp(endOf);

        // query values
        var query = [
            // the reason for  taking dealercode   the branch_id not present in stocktransaction

            {
                field: 'quantity',
                value: -1,
            },
            {
                field: 'transaction_date',
                operator: '>',
                value: timeStampstartOf.toDate(),
            },
            {
                field: 'transaction_date',
                operator: '<',
                value: timeStampEndof.toDate(),
            },
            {
                field: 'active',
                value: true
            }
        ];

        // When select yard then get data also using yard id
        if (value && value.to) {
            query.push({
                field: 'to',
                value: value.to,
            });
        }

        if (value && value.to_yard_id) {
            query.push({
                field: 'to_stock_point_id',
                value: value.to_yard_id,
            });
        }
        if (value && value.from_yard_id) {
            query.push({
                field: 'from_stock_point_id',
                value: value.from_yard_id,
            });
        }
        if (value && value.from) {
            query.push({
                field: 'from',
                value: value.from,
            });
        }

        if (value && value.sub_mode) {
            query.push({
                field: "sub_mode",
                value: value.sub_mode
            })
        }

        if (resource_number) {
            query.push({
                field: 'item_identity_number',
                value: resource_number,
            });
        }

        var res;
        setLoading(true);

        StockTransactions.get(query).then(async (result) => {
            // save result
            res = result.stock_transactions

            var quantity;

            var finalstock = [];

            let yardDetails;
            // grouping using item_identity_number
            stock = groupBy(res, 'item_identity_number');
            // get values
            arr = Object.values(stock);
            arr.forEach(async (element) => {
                element.forEach(async (req) => {
                    quantity = 0;

                    quantity = quantity + req.quantity;
                });

                // if (quantity  > 0) {
                finalstock.push(element[0]);
                // }
            });
            // get yard details 
            finalstock = await Promise.all(finalstock.map(async (ele) => {
                yardDetails = await StockTransactions.getRecordStock(ele.id);
                ele = {
                    ...ele,
                    from_yard_name: yardDetails.from_yard_name,
                    to_yard_name: yardDetails.to_yard_name,
                };
                return ele
            })
            );

            console.log(finalstock)

            // Not listing stock transaction records that are created on adjustment of stock or on delivery
            setResults(finalstock.filter((stock) => ![60, 40, 80].includes(stock.transaction_code)))
            

            setLoading(false);
        });
    }

    // //Get stock details of selected date
    // function updateTime(dt) {
    //     console.log(dt);

    //     setRange(dt);
    //     getStock(dt);
    // }

    /**
     * Find duplicate elements in an array
     * @param {*} xs
     * @param {*} key
     * @returns
     */

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    /**
     * upadate Range when changing date
     * @param {*} range
     */
    function updateTime(range) {
        setRange(range);
    }

    return (
        <div className="generate-listing">
            <div className="stock-register">
                <Title level={5}>Stock Transaction Movement </Title>
            </div>
            {/** Stock filter Section start */}
            <div className="stock-filter">
                {!resource_number ? (
                    <Form onFinish={getStock} form={form} {...formItemLayout}>
                        <Row gutter={30}>
                            {/** Select Branch section  */}
                            <Col xs={6} md={4} xl={4}>
                                <Form.Item
                                    name="from"
                                    label="FROM"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please Select Branch',
                                //     },
                                // ]}
                                >
                                    <Select allowClear onChange={loadStockPoints}>
                                        {branches.map((location, index) => (
                                            <Option value={location.location} key={index}>
                                                {location.location}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {/** Select Branch section  */}
                            </Col>
                            <Col xs={6} md={4} xl={4}>
                                <Form.Item name="from_yard_id" label="Yard From">
                                    <Select allowClear>
                                        {stockPoints.map((option, index) => (
                                            <Option value={option.id} key={index}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={6} md={4} xl={4}>
                                <Form.Item
                                    name="to"
                                    label="To"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please Select Branch',
                                //     },
                                // ]}
                                >
                                    <Select allowClear onChange={loadYardDeatails}>
                                        {branches.map((location, index) => (
                                            <Option value={location.location} key={index}>
                                                {location.location}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {/** Select Branch section  */}
                            </Col>
                            {/** Selet yard Start */}

                            <Col xs={4} md={4} xl={4}>
                                <Form.Item name="to_yard_id" label="Yard to">
                                    <Select allowClear>
                                        {yardDetails.map((option, index) => (
                                            <Option value={option.id} key={index}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/** Selet yard Ends */}
                            {/** Date picer Section start  */}
                            <Col xs={6} md={6} xl={6}>
                                <Form.Item name="date" label="Date">
                                    {/* <DatePicker inputReadOnly format={dateFormat} defaultValue={range} value={range} allowClear={false} /> */}

                                    <RangePicker
                                        allowClear={false}
                                        inputReadOnly
                                        defaultValue={range}
                                        format={'DD/MM/YYYY'}
                                        value={range}
                                        onChange={(time) => {
                                            updateTime(time);
                                        }}
                                        ranges={{
                                            Today: [moment(), moment()],

                                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month'),
                                                moment().subtract(1, 'month').endOf('month'),
                                            ],
                                        }}
                                    />
                                </Form.Item>


                            </Col>

                            <Col xs={4} md={4} xl={4}>
                                <Form.Item name="sub_mode" label="Transfer Type">
                                    <Select allowClear>
                                        {transferTypes.map((type, index) => (
                                            <Option value={type.id} key={index}>
                                                {type.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/**Date picker section ends  */}

                            <Col xs={2} md={2} xl={2}>
                                <Form.Item>
                                    <Button style={{ marginTop: '32px' }} type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : null}
            </div>
            {/** Stock filter Section ends */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <p> {result.length + ''} records</p>
                    <div className="table-container">
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.index}
                            dataSource={result}
                            columns={columns}
                            pagination={{
                                pageSize: 200,
                            }}
                            summary={(pageData) => {
                                let total = 0;

                                pageData.forEach((entry) => {
                                    total += parseFloat(entry.stock_value);
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Title level={5}>Total</Title>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            {/* <Table.Summary.Cell>
                                         </Table.Summary.Cell> */}

                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            {/* 
                                         <Table.Summary.Cell>
                                         </Table.Summary.Cell> */}

                                            {user.role === 'accounts' ? (
                                                <>
                                                    <Table.Summary.Cell>
                                                        <Title level={5}>
                                                            <Rupee value={total}></Rupee>
                                                        </Title>
                                                    </Table.Summary.Cell>
                                                </>
                                            ) : null}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
