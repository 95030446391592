import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from '../../../../../../Store';

import { Link } from 'react-router-dom';

import { message, Skeleton, Modal, Button, Tag, Select, Alert, Radio } from 'antd';

import { Form, Input, InputNumber, DatePicker } from 'antd';

import FirebaseUtils from '../../../../../../utils/firebase.utils';

import moment from 'moment';

import AsyncSelect from 'react-select/async';

import ApiUtils from '../../../../../../utils/api.utils';

import PayoutCollectionCard from './payout-collections-card';

import { BankCredits, Bills, Payouts, Departments } from './../../../../../../models';
import { getAngle } from '@nivo/core';

const { RangePicker } = DatePicker;

const { Option } = Select;

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

/**
 *
 * This is particularly for RazorPay and Settling Card Swipes
 *
 * @param {*} param0
 */
export default function PayoutCollection({ bankEntry, callback, codes, verified, target }) {
    const [collections, setCollections] = useState([]);

    const { user } = useContext(GlobalContext);

    const [items, setItems] = useState([]);

    const [loader, setLoader] = useState(true);

    const [form] = Form.useForm();

    const [customForm] = Form.useForm();

    const [mode, setMode] = useState('');

    const [visible, setVisible] = useState(false);

    const [allDepartments, setAllDepartments] = useState([]);

    const [range, setRange] = useState([moment.tz('Asia/Calcutta').startOf('day'), moment.tz('Asia/Calcutta').endOf('day')]);

    useEffect(() => {
        getAllDepartments();
        getCollections(range);
    }, []);

    //getting All Departments here as allDepartment when passing from bank receipt will not contain finance department
    function getAllDepartments() {
        Departments.get().then((result) => setAllDepartments(result.departments));
    }

    function getCollections(range) {
        let queries = [
            {
                field: 'created_date',
                operator: '>=',
                // value: 1580236200000,
                value: range[0].startOf('day').valueOf(),
            },

            {
                field: 'created_date',
                operator: '<=',
                value: range[1].endOf('day').valueOf(),
                // value: 1590777000000,
            },

            {
                field: 'status',
                value: 'active',
            },
            {
                field: 'type',
                value: 'finance-payout',
            },
        ];

        Bills.get(queries).then((result) => {
            console.log(result.bills);

            var filteredBills = result.bills.filter((b) => b.paid_status != 'yes');

            setCollections(filteredBills);
            setLoader(false);
        });
    }

    function onSelect(collection) {
        collections.forEach((entry, index) => {
            if (entry.id === collection.id) {
                collections[index].selected = true;
            }
        });

        setCollections([...collections]);
    }

    function onDiscard(collection, index) {
        collections.forEach((entry, index) => {
            if (entry.id === collection.id) {
                collections[index].selected = false;
            }
        });

        setCollections([...collections]);
    }

    const onSubmitCustom = (values) => {
        collections.push({
            isCustom: true,
            ...values,
            name: values.ac_code.label,
            selected: true,
            created_by: {},
        });

        setVisible(false);

        setCollections([...collections]);

        customForm.resetFields();

        setItems([]);
    };

    /**
     * Load the matching heads
     */
    function loadHeads(query) {
        return ApiUtils.getHeads(query, bankEntry.department).then((result) => {
            return result.map((item) => {
                return {
                    value: item.ac_code,
                    label: item.ac_desc,
                };
            });
        });
    }

    /**
     * Create a custom record
     */
    function createCustomAccountsRecord(bankEntry, record) {
        // Build the params
        var params = {
            ...bankEntry,
            credit: record.amount,
        };

        return ApiUtils.createCustomRecord(params, record, target);
    }

    /**
     *
     * Once the user selects the customer receipts that
     * can be linked to the bank entry
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        // At this stage we have two types of collections
        // One for CS Accounts
        // And ON for Surge
        // console.log(values);

        let filtered = collections.filter((col) => col.selected && !col.isCustom);
        let customRecords = collections.filter((col) => col.selected && col.isCustom);

        let params = {
            ...values,
            collectionIds: filtered.map((entry) => entry.id),
            bulkEntry: true,
            clearing_entry: true,
            reconcile_status: 'completed',
        };

        // Update the receipt with the selected collections

        Promise.all(
            filtered.map((entry, index) => {
                let params = {
                    receiptId: bankEntry.receiptId,
                    status: 'reviewed',
                    pending_to_reconcile: false,
                    reconciled_by: bankEntry.receiptId,
                    bank: bankEntry.bank,
                    clearing_city: codes[bankEntry.bank],
                    voucher: {
                        ...bankEntry.voucher,
                    },
                };

                if (!verified) {
                    // params.verified = 'approved'

                    let verificationTime = {
                        verified_at: moment().format('DD/MM/YYYY HH:mm'),
                        verified_by: { name: user.name, role: user.role },
                        verified_date: moment().startOf('day').valueOf(),
                        verified_time: moment().valueOf(), // this can be removed if not needed
                    };

                    params = {
                        ...params,
                        ...verificationTime,
                        verified: 'approved',
                        pending_at: 'edp',
                    };
                }

                // Not required without cs
                // Update the paid status for all the selected bills
                // return updateReconcileStatus(entry)
                    // .then(() => {
                        return Bills.update(entry.id, { paid_status: 'yes', paid_amount: parseInt(bankEntry.credit) })
                    // })
                    .then(() => {
                        Bills.getRecord(entry.id).then((res) => {
                            res.payouts.map((id) => {
                                return Payouts.update(id, { status: 'Disbursed' });
                            });
                        });
                    });
            }),


            // Not required without cs
            // // These records has to be posted to cs accounts
            // customRecords.map((record) => {
            //     // Update the reconcile status for all the selected
            //     return createCustomAccountsRecord(bankEntry, record);
            // })
        ).then(() => {
            BankCredits.update(bankEntry.receiptId, params).then(() => {
                message.success('The collections has been updated');

                callback();
            });
        });
    };

    /**
     * Not required without cs
     * 
     * Function would update the reconcile status
     * of the entry on cs accounts
     *
     * @param {*} collection
     * @returns
     */
    // function updateReconcileStatus(collection) {
    //     var params = {
    //         v_rconstatus: 'Y',
    //         v_rcondt: new Date(),
    //         v_othref: `RB:${target}${bankEntry.receiptId}`,
    //     };

    //     return new Promise((resolve, reject) => {
    //         const { voucher = {} } = collection;

    //         var collectionDepartment = allDepartments.filter((dep) => dep.id === collection.department_id);
    //         collection.department = collectionDepartment[0];

    //         // Get the bank record of this voucher
    //         ApiUtils.getVoucherRecords(voucher.v_vno, collection.department).then((result) => {
    //             var bank_entry = result.filter((entry) => entry.v_totrow === 'Y').pop();

    //             if (bank_entry) {
    //                 // Update the Voucher Record
    //                 ApiUtils.updateVoucherRecord(bank_entry.v_id, params, collection.department)
    //                     .then((result) => {
    //                         resolve({});
    //                     })
    //                     .catch((error) => {
    //                         reject(error);
    //                     });
    //             } else {
    //                 message.warning('CS Accounts entry has to be manually Updated');

    //                 resolve({});
    //             }
    //         });
    //     });
    // }

    let selectedTotal = collections
        .filter((receipt) => receipt.selected)
        .reduce((a, b) => {
            return a + parseInt(b.net_amount || b.amount);
        }, 0);

    let pending = bankEntry.credit - selectedTotal;

    let enableSubmit =
        selectedTotal === bankEntry.credit || (selectedTotal >= (95 / 100) * bankEntry.credit && selectedTotal <= (105 / 100) * bankEntry.credit);

    // let enableSubmit = selectedTotal === parseInt(bankEntry.credit);

    // if()

    const options = ['Cash', 'Net Banking', 'Credit Card', 'Debit Card', 'Cheque', 'Online'];

    function filterList(option) {
        setMode(option);
    }

    function updateTime(dt) {
        setRange(dt);
        setLoader(true);

        getCollections(dt);
    }

    function addCustomEntry() {
        setVisible(true);
    }

    return (
        <div className="pending-collections">
            <div className="list-options">
                <div>Listing All Payout Bills</div>

                <div>
                    <RangePicker
                        allowClear={false}
                        inputReadOnly
                        format={'DD/MM/YYYY'}
                        value={range}
                        onChange={updateTime}
                        ranges={{
                            Today: [moment(), moment()],

                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        }}
                    />
                    <Button onClick={addCustomEntry} style={{ marginLeft: '10px' }} type="secondary" size={'small'}>
                        Add Custom
                    </Button>
                </div>
            </div>

            <Modal
                destroyOnClose={true}
                title="Collection Detail"
                visible={visible}
                footer={null}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <div className="vehicle-card card">
                    <Form
                        form={customForm}
                        {...layout}
                        name="new-customer-record"
                        layout="vertical"
                        onFinish={onSubmitCustom}
                        // validateMessages={validateMessages}
                        initialValues={{}}
                    >
                        {/* #todo May be we need more fields here */}

                        <Form.Item
                            name="type"
                            label="Type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a type',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="credit">Credit</Radio>
                                <Radio value="debit">Debit</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {/**Not required without cs */}
                        {/* <Form.Item
                            name={'ac_code'}
                            label="Account Head"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Account Head',
                                },
                            ]}
                        >
                            <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} />
                        </Form.Item> */}

                        <Form.Item
                            name={'amount'}
                            label="Amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Amount',
                                },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            name={'remarks'}
                            label="Remarks"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Remarks',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Button
                            // disabled={!enableSubmit}
                            type="primary"
                            htmlType="submit"
                            className="submit-button"
                        >
                            SUBMIT
                        </Button>
                    </Form>
                </div>
            </Modal>

            {/* Selected Receipts */}
            {collections.filter((payout) => payout.selected).length ? (
                <div className="selected-item-form card">
                    {/* Selected Tags */}
                    <div className="selected-items">
                        {collections
                            .filter((receipt) => receipt.selected)
                            .map((collection, key) => {
                                return (
                                    <Tag
                                        key={key}
                                        visible
                                        closable
                                        onClose={() => {
                                            onDiscard(collection, key);
                                        }}
                                    >
                                        {collection['type']} <span className={`title amount`}>{collection.net_amount || collection['amount']}</span>
                                    </Tag>
                                );
                            })}
                    </div>
                    {/* Selected Tag Ends */}

                    <div className="form-actions">
                        <h3>Collection Total - {selectedTotal}</h3>

                        <h3>Pending Amount - {pending}</h3>
                    </div>

                    <Form
                        form={form}
                        {...layout}
                        name="new-record"
                        layout="vertical"
                        onFinish={onFinish}
                        // validateMessages={validateMessages}
                        initialValues={{}}
                    >
                        {/* #todo May be we need more fields here */}

                        <Form.Item
                            name={'reconcilationRemarks'}
                            label="Remarks"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Remarks',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Button disabled={!enableSubmit} type="primary" htmlType="submit" className="submit-button">
                            SUBMIT
                        </Button>

                        {/* <Button onClick={onReject} type="secondary" className="submit-button">
                                REJECT
                            </Button> 
                        */}
                    </Form>
                </div>
            ) : null}
            {/* Selected Receipts Ends */}

            {loader ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    {collections
                        .filter((col) => {
                            if (mode && mode !== '') {
                                return !col.selected && mode.indexOf(col.type) !== -1;
                            } else {
                                return !col.selected;
                            }
                        })
                        .map((entry, index) => {
                            return (
                                <PayoutCollectionCard
                                    key={index}
                                    collection={entry}
                                    onSelect={() => {
                                        onSelect(entry, index);
                                    }}
                                />
                            );
                        })}
                </>
            )}
        </div>
    );
}
