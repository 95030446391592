/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Typography, Button, message, Input, Upload, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom';

// import './generic-add.scss'

import XLSX from 'xlsx';

import FirebaseUtils from './../../../../utils/firebase.utils';

import { UploadOutlined } from '@ant-design/icons';

import useDeviceDetect from './../../../../hooks/device-detect';

import PlaceHolder from './../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from './../../../../Store';

import moment from 'moment';
import { cityKeys } from '../../../global-config';

const { Title } = Typography;

const { TextArea } = Input;

var cityCode = cityKeys

/**
 * Generic List
 *
 * @param {*} param0
 */
function ItemsUpload({ history, model, schema, collection, columns: cols }) {
    const [content, setContent] = useState({});

    const [records, setRecords] = useState([]);

    const [newparts, setNewparts] = useState({});

    const { isMobile } = useDeviceDetect();

    const [files, setFiles] = useState([]);

    const { user } = useContext(GlobalContext);

    // const city = user.locations[0];

    const [loading, setLoading] = useState(false);

    const step = {
        fields: cols,
    };

    useEffect(() => {
        setLoading(true);

        FirebaseUtils.getListing(null, [], model).then((result) => {
            console.log('res', result);

            setRecords(result);

            var r = {};

            result[model].forEach((item) => {
                var key = findIndex(item);

                r[key] = item;
                // r[item[key]] = item;
            });

            setContent(r);

            setLoading(false);
        });
    }, []);

    function handleFile(f) {
        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {
            const bstr = e.target.result;

            // let csvData = csvJSON(bstr);

            // console.log(csvData);

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws, {
                header: schema.tableHeader,
                range: schema.tableRange,
            });

            console.log(data);

            processData(data);
        };

        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
    }

    /**
     *
     * Derive the index of the table from element
     *
     * @param {]} element
     */
    function findIndex(element) {
        var key;

        if (typeof schema.tableIndex === 'function') {
            key = schema.tableIndex(element);
        } else {
            // index = schema.tableIndex;

            key = schema.uploadIndex
                ? cityCode[user.locations[0]] + element[schema.uploadIndex]
                : cityCode[user.locations[0]] + element[schema.tableIndex];
        }

        return key;
    }

    function csvJSON(csv) {
        const lines = csv.split('\n');
        const result = [];
        const headers = lines[0].split(',');

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i]) continue;
            const obj = {};
            const currentline = lines[i].split(',');

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }

        return result;
    }

    /**
     *
     * Prepare the data according to the configuration
     *
     * @param {*} data
     */

    function processData(data) {
        var modified = {};
        var item = {};

        var columns = data[0];

        console.log(columns);
        let creation = {
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
        };

        data.forEach((element, index) => {
            if (index < 1) return;
            if (!element['PART NO'] || !element['PART NAME']) {
                return
            }
            let key = element['PART NO'].trim();

            let supplier;
            if (element['SUPPLIER']) {
                supplier = element['SUPPLIER'];
            } else {
                supplier = null;
            }

            modified[key] = {
                ...creation,
                dealerCode: cityCode[user.locations[0]],
                branch_id: cityCode[user.locations[0]],
                part_number: element['PART NO'].trim(),
                part_name: element['PART NAME'].trim(),
                category: 'Accessories',
                hsn: element['HSN CD'].trim(),
                supplier: supplier,
                tax_inclusive: true,
                price_on: 'stock',

                // Add rest of fields
            };
        });

        // We have to compare with old data
        const previousKeys = records[model].map((entry) => entry.part_number);

        const latestKeys = Object.keys(modified);

        latestKeys.forEach((key) => {
            if (previousKeys.indexOf(key) !== -1) {
                let i = previousKeys.indexOf(key);
                let partNum = previousKeys[i];
                let prevRecord = records[model].filter((item) => item.part_number === partNum);

                modified[key].duplicate = true;
                modified[key].id = prevRecord[0].id;
            }
        });

        setNewparts(modified);

        console.log(modified);
    }

    function approveUpload() {

        setLoading(true)
        let b = {};

        let duplicate = 0;

        Object.keys(newparts).forEach((no) => {
            if (newparts[no].duplicate) {
                duplicate++;
            }

            b[no] = {
                // Here we need to specify any by default fields that we need to add
                ...newparts[no],
                deleted_at:null,
                deleted_by:null

                // ...records[model][no]
            };
        });

        let total = Object.keys(b).length;

        let count = total - duplicate;

        FirebaseUtils.updateItems(user, b, model).then((result) => {
            message.success(`${total} ${model} updated. ${count} new ${model}`);

            setNewparts({});

            history.goBack();

            setLoading(false)
        });
    }

    const uploadProps = {
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            setFiles([...files, file]);

            return false;
        },
        files,
    };

    const SheetJSFT = [
        'xlsx',
        'xlsb',
        'xlsm',
        'xls',
        'xml',
        'csv',
        'txt',
        'ods',
        'fods',
        'uos',
        'sylk',
        'dif',
        'dbf',
        'prn',
        'qpw',
        '123',
        'wb*',
        'wq*',
        'html',
        'htm',
    ]
        .map(function (x) {
            return '.' + x;
        })
        .join(',');

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model} Upload</Title>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        {/* <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button> */}
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <div className="card">
                        <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                            <Button size={'small'} icon={<UploadOutlined />}>
                                Select File
                            </Button>
                        </Upload>

                        {Object.keys(newparts).length ? (
                            <div className="file-review">
                                <div className="action-header">
                                    <h4>
                                        {Object.keys(newparts).length} new {model}
                                    </h4>
                                    <p>{Object.keys(newparts).filter((entry) => newparts[entry]['duplicate']).length} are duplicate.</p>
                                    <Button onClick={approveUpload} key="3">
                                        Approve
                                    </Button>
                                    ,
                                    <Button
                                        key="2"
                                        onClick={() => {
                                            setNewparts({});

                                            setFiles([]);
                                        }}
                                    >
                                        Decline
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            )}
        </section>
    );
}

export default withRouter(ItemsUpload);
