
import React, { useContext } from "react";

import { Tabs, Typography } from "antd";

import "./matching-dashboard.scss";

import MatchingBookings from './../matching-bookings/matching-bookings';

import MatchingBookings1 from './../matching-bookings/matching-bookings1';

import { GlobalContext } from './../../../../Store';

const { TabPane } = Tabs;

const { Title } = Typography;


export default function MatchingDashboard(props) {

    const { user } = useContext(GlobalContext)

    return (
        <section className="matching-dashboard">

            <Title level={4}>Matching Summary</Title>

            <Tabs >

                {
                    user.locations.map((location, index) =>

                        <TabPane tab={location} key={index}>
                            {/* <Tabs >
                                <TabPane tab={'old'} key={'old'}>
                                    <MatchingBookings url={location} />
                                </TabPane>
                                <TabPane tab={'new'} key={'new'}> */}
                            <MatchingBookings1 url={location} />
                            {/* </TabPane>
                            </Tabs>
                            <MatchingBookings url={location} /> */}
                        </TabPane>
                    )
                }

            </Tabs>
        </section>
    );
}
