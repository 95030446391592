import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import { DatePicker, Space, Table, Typography } from 'antd';

import { GlobalContext } from '../../../../Store';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';


import moment from 'moment';
import FirebaseUtils from '../../../../utils/firebase.utils';

import "./trading-profit.scss";

const { RangePicker } = DatePicker;

const { Title, Text } = Typography;

export default function TradingProfit({ history, report, index }) {

    const { user } = useContext(GlobalContext);

    const [range, setRange] = useState([
        moment.tz('Asia/Calcutta').subtract(1, 'month').startOf('month'),
        moment.tz('Asia/Calcutta').subtract(1, 'month').endOf('month')
    ]);

    const [loading, setLoading] = useState(true);

    const [location, setLocation] = useState(user.locations[0]);

    const [content, setContent] = useState([]);

    useEffect(() => {

        getData(range);

    }, []);


    function updateTime(dt) {
        setRange(dt);

        getData(dt);
    }

    const getData = async (range) => {

        setLoading(true);

        let formBody = {
            location: location,
            start_date: moment(range[0]).startOf('day').valueOf(),
            end_date: moment(range[1]).endOf('day').valueOf()
        }

        // FirebaseUtils.getTrading(formBody).then((result) => {

        //     setLoading(false);

        //     setContent(result);

        // })

        let payload = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: formBody ? JSON.stringify(formBody) : null
        };

        return fetch('https://us-central1-gbusiness-1601789339241.cloudfunctions.net/getTradingProfit', payload)
            .then((res) => res.json())
            .then((res) => {
                console.log(res);

                setLoading(false);


                setContent(res);

            })
            .catch((error) => {
                console.log('error api response', error);
                return;
            });
    }

    return (
        <div className="order-tracking listing">

            <div className="page-header">
                <div className="left">
                    <Title level={3}>Trading Overview</Title>
                </div>
                <div className="right">
                    <RangePicker
                        allowClear={false}
                        inputReadOnly
                        format={'DD/MM/YYYY'}
                        value={range}
                        onChange={updateTime}
                        ranges={{
                            Today: [moment(), moment()],

                            Yesterday: [
                                moment().subtract(1, 'days').startOf('day'),
                                moment().subtract(1, 'days').endOf('day')
                            ],

                            'This Week': [
                                moment().startOf('week'),
                                moment().endOf('week')
                            ],

                            'Last Week': [
                                moment().subtract(1, 'week').startOf('week'),
                                moment().subtract(1, 'week').endOf('week')
                            ],

                            'This Month': [
                                moment().startOf('month'),
                                moment().endOf('month')
                            ],

                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month'),
                                moment().subtract(1, 'month').endOf('month')
                            ]
                        }}
                    />
                </div>
            </div>

            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <div className="tracking-component">
                        <TableView data={content} />
                    </div>
            }

        </div>
    )
}

function TableView({ data = {}, callback }) {

    var content = [];

    var columns = [{
        title: 'Particulars',
        dataIndex: 'particulars',
        render: (text, record) => {
            if (text.includes('Total') || text.includes('Closing Stock')) {
                return (
                    <div style={{ fontWeight: 'bold' }}>
                        {text}
                    </div>
                );
            } else {
                return (
                    <div>
                        {text}
                    </div>
                );
            }
        },
        key: 'name',
    }]

    var locations = ['Kozhikode', 'Kannur', 'Malappuram']

    var models = ['Seltos', 'Sonet', 'Carnival', 'Total'];

    locations.forEach((location) => {

        var base = {
            title: location,
            children: []
        }

        models.forEach((vehicle, index) => {
            if (vehicle == 'Total') {
                base.children.push({
                    title: vehicle,
                    dataIndex: location.toLowerCase() + vehicle.toUpperCase(),
                    render: (text, record) => {
                        return (
                            <div style={{ fontWeight: 'bold', fontSize: '14px', textAlign: 'right' }}>
                                {text}
                            </div>
                        );
                    },
                })
            } else {
                base.children.push({
                    title: vehicle,
                    dataIndex: location.toLowerCase() + vehicle.toUpperCase(),
                    render: (text, record) => {
                        if (record.particulars.includes('Total') || record.particulars.includes('Closing Stock')) {
                            return (
                                <div style={{ fontWeight: 'bold', fontSize: '14px', textAlign: 'right' }}>
                                    {text}
                                </div>
                            );
                        } else {
                            return (
                                <div style={{ fontSize: '12px', textAlign: 'right' }}>
                                    {text}
                                </div>
                            );
                        }
                    },
                })
            }
        });

        columns.push(base)
    });




    // content = prepareData(data);

    console.log(content);

    useEffect(() => {

    }, [])


    /**
     * Prepare the data
     */
    function prepareData(data) {

    }
    return (<>

        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            size="middle"
        // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
        />

    </>
    );
}