import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Row, message, Image, Alert, Tag } from 'antd';
import { Form, Input, Typography, Badge } from 'antd';

import PlaceHolder from './../../../../components/ui_elements/PlaceHolder';
import FirebaseUtils from '../../../../utils/firebase.utils';

import moment from 'moment';

import { GlobalContext } from './../../../../Store';

import FileUpload from './../../../../components/file-upload/file-upload';

// import Collections from "../../../rm/components/collections/collections";

import './customer-form.scss';

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};

let defaultCollection = {
    attachments: [],
};

const { Text } = Typography;

export default function CustomerForm(props) {
    const [form] = Form.useForm();

    const [reports, setReports] = useState([]);

    const [collection, setCollection] = useState({});

    const { user } = useContext(GlobalContext);

    const { match } = props;

    const [loader, setLoader] = useState(true);

    const [disabled, setDisabled] = useState(false);

    const [matching, setMatching] = useState([]);

    const [matchingloader, setMatchingloader] = useState(false);

    useEffect(() => {
        getBankReceipt();
    }, []);

    /**
     * Get stock Data
     */
    function getBankReceipt(location) {
        setLoader(true);

        FirebaseUtils.getCollectionRecord(match.params.id).then((result) => {
            let data = result;

            console.log(data);

            setReports(data);

            setLoader(false);

            if (result.reconciled_by) {
                getBankRecord(result);
            }
        });
    }

    function getBankRecord(record) {
        setMatchingloader(true);

        FirebaseUtils.getBankCreditRecord(record.reconciled_by).then((result) => {
            setMatching([result]);

            console.log(result);

            setMatchingloader(false);
        });
    }

    const onFinish = (values) => {
        var params = {
            ...values,
            edp: {
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                created_by: user.name,
            },
            invoiced_date: moment().startOf('day').valueOf(),
            invoiced_time: moment().valueOf(),
            pending_at: 'accounts',
            status: 'invoiced',
        };

        FirebaseUtils.updateCollection(match.params.id, params).then((result) => {
            console.log(result);

            setLoader(false);

            props.history.goBack();
        });
    };

    const onReject = () => {
        var values = form.getFieldsValue();

        if (values.edpRemarks) {
            var params = {
                rejectRemarks: values.edpRemarks,
                edpReject: {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_by: user.name,
                },
                pending_at: 'sm',
            };

            FirebaseUtils.updateCollection(match.params.id, params).then((result) => {
                console.log(result);

                setLoader(false);

                props.history.goBack();
            });
        } else {
            message.error('Please provide remarks');
        }
    };

    function onUpload(invoice) {
        setDisabled(false);

        form.setFieldsValue({ invoice: invoice });
    }

    return (
        <div className="customer-form">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div gutter={0} className="detail-wrapper">
                        <p>Please provide the customer id and receipt details after entering into dms</p>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Name :</h3>
                            </Row>
                            <Row span={12}>
                                <h2 className="title">{reports.name}</h2>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Phone :</h3>
                            </Row>
                            <Row span={12}>
                                <span>{reports.phone}</span>
                            </Row>
                        </div>

                        <Tag color="blue" style={{ margin: '10px 0px' }}>
                            {reports.type}
                        </Tag>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Description :</h3>
                            </Row>
                            <Row span={12}>
                                <span>{reports['description']}</span>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Ref :</h3>
                            </Row>
                            <Row span={12}>
                                <span>{reports['ref']}</span>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Amount :</h3>
                            </Row>
                            <Row span={12}>
                                <h2 className="title amount">{reports['amount']}</h2>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>On Account Of :</h3>
                            </Row>
                            <Row span={12}>
                                <span>{reports.account}</span>
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <h3>Date :</h3>
                            </Row>
                            <Row span={12}>
                                <span className="">{reports['txn_date']}</span>
                            </Row>
                        </div>

                        <CollectionInfo collection={reports} />
                    </div>

                    {matchingloader ? (
                        <PlaceHolder type="report" />
                    ) : (
                        <>
                            {matching.length ? (
                                <Alert message={`${matching.length} matching record has reflected in bank`} type="success" showIcon />
                            ) : null}

                            {/* Matching Records */}
                            {matching.map((entry) => {
                                return <ReceiptInfo receipt={entry} />;
                            })}

                            {/* Matching Records Ends */}
                        </>
                    )}

                    <Form
                        form={form}
                        {...layout}
                        name="new-record"
                        layout="vertical"
                        onFinish={onFinish}
                        // validateMessages={validateMessages}
                        initialValues={{}}
                    >
                        <Form.Item
                            name="invoice"
                            rules={[
                                {
                                    required: true,
                                    message: 'Share attachments of the invoice',
                                },
                            ]}
                            label="Attach Invoice"
                            valuePropName="fileList"
                            extra="File size should not exceed 1 mb"
                        >
                            <FileUpload
                                callback={onUpload}
                                onProgress={() => {
                                    setDisabled(true);
                                }}
                            />
                        </Form.Item>

                        <Form.Item name={'customerID'} label="Customer ID" rules={[{ required: true, message: 'Please enter Customer ID' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name={'receiptNo'} label="Receipt No" rules={[{ required: true, message: 'Please enter Receipt No' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name={'edpRemarks'} label="Remarks" rules={[{ message: 'Please enter Receipt No' }]}>
                            <Input />
                        </Form.Item>

                        <Button disabled={disabled} type="primary" htmlType="submit" className="submit-button">
                            SUBMIT
                        </Button>

                        <Button onClick={onReject} type="secondary" className="submit-button">
                            REJECT
                        </Button>
                    </Form>
                </Fragment>
            )}
        </div>
    );
}

function CollectionInfo({ collection = { attachments: [] } }) {
    return (
        <div className="collection-info">
            <Alert
                message="Matching Payment Details"
                description={`The below details were collected by KEC from the customer. Please reject if these does not match.`}
                type="info"
            />

            {/* 
        <div className="detail-element">


            <Row span={12}>

                <h3>Name :</h3>
            </Row>
            <Row span={12}>
                <h2 className="title">
                    {collection.name}
                </h2>
            </Row>

        </div>


        <div className="detail-element">

            <Row span={12}>
                <h3>Amount :</h3>
            </Row>
            <Row span={12}>
                <h2 className="title amount">{collection.amount}</h2>
            </Row>

        </div> */}

            <div className="detail-element">
                <Row span={12}>
                    <h3>KEC :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.kec}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Team Leader :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.teamLeader}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Type :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.type}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>UTR :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.utr}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <span>Attachments</span>
                </Row>

                <>
                    {collection.attachments &&
                        collection.attachments.map((file) => {
                            return (
                                <>
                                    {file.type === 'application/pdf' ? (
                                        <embed src={file.url} width="500" height="375" type="application/pdf" />
                                    ) : (
                                        <Image width={200} src={file.url} />
                                    )}
                                </>
                            );
                        })}
                </>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Customer ID :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.customerId}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Remarks :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.remarks}</span>
                </Row>
            </div>
        </div>
    );
}

function ReceiptInfo({ receipt = {} }) {
    var created_by;
    var created_at;

    if (receipt.rejectRemarks) {
        if (receipt.smReject) {
            created_by = receipt.smReject.created_by;

            created_at = receipt.smReject.created_at;
        } else if (receipt.edpReject) {
            created_by = receipt.edpReject.created_by;

            created_at = receipt.edpReject.created_at;
        }
    }

    return (
        <div className="card">
            <h3 className="title">{receipt['description']}</h3>
            <h2 className={`title ${receipt['debit'] ? 'amount' : 'credit'}`}>{receipt['debit'] || receipt['credit']}</h2>
            <p className="title ">{receipt['ref']}</p>
            <p className="">
                <Tag color="magenta">{receipt.location || receipt.bank}</Tag>

                {receipt.location || receipt.city ? <Badge color="#f50" text={receipt.city || receipt.location} /> : null}
            </p>
            Credited at {receipt['txn_date']}
            <div>
                {receipt.rejectRemarks ? (
                    <Text type="danger">
                        {receipt.rejectRemarks}, By {created_by} at {created_at}
                    </Text>
                ) : null}
            </div>
            {receipt.edp && receipt.edp.created_at ? (
                <>
                    Created at {receipt.edp.created_at}, By {receipt.edp.created_by}
                </>
            ) : null}
        </div>
    );
}
