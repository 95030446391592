import React, { useEffect, useState, Fragment, useContext } from "react";


import { Link } from 'react-router-dom'


import { Tag, Tabs, Badge, Button, Row, List, Steps, Card, Alert, message, Form } from 'antd';

import { Comment, Avatar, Input } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder'

import Header from '../../../../components/page-header/page-header';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import CommentBlock from './../../../common/components/comment-block/comment-block';

import moment from 'moment';

import {
    CheckCircleOutlined,
} from '@ant-design/icons';

import './stock-detail.scss';

import { Typography } from 'antd';

const { Title, Text } = Typography;

const { TextArea } = Input;

var images = {
    SELTOS: require('./../../../../assets/images/cars/seltos.png'),
    SONET: require('./../../../../assets/images/cars/sonet.png'),
    CARNIVAL: require('./../../../../assets/images/cars/carnival.png'),
    CARENS: require('./../../../../assets/images/cars/carens.png')
}

var badgeColor = {
    'Allocated': 'error',
    'In transit': 'warning',
    'Free Stock': 'success'
}

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }

    },
};

const { TabPane } = Tabs;

const { Step } = Steps;




export default function HighPriority(props) {

    const [form] = Form.useForm();

    const [vehicle, setVehicle] = useState({});

    const [bookingForm] = Form.useForm();

    const { user } = useContext(GlobalContext);

    const [file, setFile] = useState({});

    const [booking, setBooking] = useState({});

    const [request, setRequest] = useState({ created_by: {} });

    const [matching, setMatching] = useState([]);

    const { match } = props;

    const { params } = match;

    const { id, city } = params;

    // const city = user.locations[0];

    const [loader, setLoader] = useState(true)

    useEffect(() => {

        loadData();

    }, [])


    function loadData() {

        setLoader(true);

        console.log(city, id);

        FirebaseUtils.getVehicle(id).then((result) => {

            setVehicle(result);

            setLoader(false);
            console.log(result);


        });
    }

    function reset() {

        FirebaseUtils.unblockVehicle(vehicle['Vin Number']).then(() => {

            message.success('Unblocked successfully');

        })

    }


    return (
        <div className="high-priority-detail">

            <div className="page-header">
                <Title level={3}>
                    Vehicle Detail
                </Title>

                <div>
                    <Button onClick={loadData} type="secondary" size={'small'}>Refresh</Button>
                </div>
            </div>

            {
                loader ?
                    <PlaceHolder type="report" />
                    :
                    <Fragment>

                        <div className="page-content">

                            <div className="split-container">

                                <div className="left">

                                    {/* Vehicle Card */}
                                    <div className="vehicle-card">

                                        <Tag color="purple">
                                            {file.status || 'Initial'}
                                        </Tag>

                                        <div className="vehicle-image-wrapper">

                                            {/* <img src={images[file['model']]} alt="vehicle-img" /> */}

                                        </div>

                                        <div gutter={0} className="detail-wrapper">


                                            <div className="detail-element">
                                                <Row span={12}>
                                                    <span>Variant</span>
                                                </Row>
                                                <Row span={12}>
                                                    <h3>{vehicle['Variant']}</h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">
                                                <Row span={12}>
                                                    <span>Color</span>
                                                </Row>
                                                <Row span={12}>
                                                    <h3>{vehicle['Exterior Color Name']}</h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">

                                                <Row span={12}>
                                                    <span>Stock Location</span>

                                                </Row>
                                                <Row span={12}>
                                                    <h3>{vehicle['Stock Location']}</h3>

                                                </Row>

                                            </div>

                                            <div className="detail-element">

                                                <Row span={12}>
                                                    <span>
                                                        Variant
                                                    </span>
                                                </Row>
                                                <Row span={12}>
                                                    <h3>{vehicle['variant']}</h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">
                                                <Row span={12}>
                                                    Exterior Color Name
            </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {vehicle['color']}
                                                    </h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">
                                                <Row span={12}>
                                                    Customer ID
                                                </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {vehicle['Cust ID']}
                                                    </h3>
                                                </Row>
                                            </div>


                                            <div className="detail-element">
                                                <Row span={12}>
                                                    Booking Number
                                                </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {vehicle['Booking No']}
                                                    </h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">
                                                <Row span={12}>
                                                    Stock Status
                                                </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {vehicle['Stock Status']}
                                                    </h3>
                                                </Row>
                                            </div>

                                            {/* <div className="detail-element">
                                                <Row span={12}>
                                                    Committed Delivery Date
                                                </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {booking['Committed Delivery Date']}
                                                    </h3>
                                                </Row>
                                            </div> */}

                                        </div>
                                    </div>

                                </div>
                                <div className="right">

                                    <Button onClick={() => { reset() }} type="secondary" size={'small'}>Reset</Button>


                                    <Card>
                                        {JSON.stringify(vehicle.allocated)}
                                    </Card>

                                    {/* 
                                    <Title level={4}>
                                        Comments
                                    </Title> */}

                                    {/* <CommentBlock id={file.id} /> */}

                                </div>
                            </div>

                        </div>
                    </Fragment>}
        </div>
    )
}



function VehicleCard({ vehicle, unblock, booking, blockVehicle }) {

    const { user } = useContext(GlobalContext);

    return (<div className="vehicle-item" >
        <div className="left">

            <Text level={1}>
                {vehicle['Variant']}
                <span>
                    {` [ ${vehicle['Exterior Color Name']} ] `}
                </span>
            </Text>

            <div>
                {`${vehicle['Vin Number']}`}
            </div>

            <small className="status">

                <Badge status={badgeColor[vehicle['Stock Status']]} />


                {vehicle['Stock Location'] || vehicle['Stock Status']}
            </small>

        </div>
        <div className="right">

            {
                vehicle.allocated && vehicle.allocated.customer ?
                    <div>

                        <Button size="small" onClick={() => { unblock(vehicle) }} type="secondary">Unblock</Button>

                        <Tag icon={<CheckCircleOutlined />} color="success">
                            Blocked
                        </Tag>
                    </div>
                    :
                    <div>

                        {
                            (booking.status === 'approved')
                                &&
                                (booking.bookingNo)
                                ?
                                <Button size="small" type="secondary" onClick={blockVehicle}>
                                    Block Vehicle
                                    {/* {user.role === 'tl' ? 'Send Request to SM' : 'Request TL to Block'} */}
                                </Button>
                                :
                                null
                        }



                        {/* <BlockVehicle vehicle={vehicle} requests={requests} city={city} callback={callback} /> */}
                    </div>
            }

        </div>
    </div>)
}





function BookingCard({ booking }) {

    const { user } = useContext(GlobalContext);

    return (<div className="booking-card allocation-request" >
        <div className="card">

            <div className="left">

                <Title level={4}>
                    {booking['Name of the Customer']}
                </Title>

                <h4 className="title">
                    {booking['Contact Number']}
                </h4>

                <h3 className="title ">
                    {booking['Variant']}
                </h3>

                <div>
                    <small> {booking['Color']}</small>
                </div>

                <h4>
                    {booking['Booking No']} - <Tag color="#2db7f5">
                        {/* {dealer[booking['Dealer Code']]} */}
                    </Tag>
                </h4>

                <h4>
                    KEC : {booking['Consultant Name']}
                </h4>


                <h4>
                    Team Leader : {booking['Team Leader']}
                </h4>
                <p className="">
                    Booked on {booking['Booking Date']}, <Text type="danger">{`${moment().diff(moment(booking['Booking Date'], "DD/MM/YYYY"), 'days')} days ago.`}</Text>

                    <Tag color="magenta">{booking.status || 'Initial'}</Tag>

                </p>

                Committed Delivery Date  {booking['Committed Delivery Date']} , Booked By {booking['Consultant Name']}

            </div>
            <div className="right card-buttons">

                <a className="card-action" href={`tel:${booking['Contact Number']}`} key="list-loadmore-edit">Call</a>

            </div>
        </div>

    </div>)
}