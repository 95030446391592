/**
 * Component to compare DMS RO billing report to accessory bills in surge
 */

import React, { useEffect, useState, useRef, useContext } from 'react';

import XLSX from 'xlsx';

import { Typography, Upload, Button, message, DatePicker, Table, Space } from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import { GlobalContext } from '../../../../Store';

import { Bills } from '../../../../models';

import moment from 'moment-timezone';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { ExportXLS } from './ExportXLS';

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

export default function CompareRO() {
    const { user } = useContext(GlobalContext);

    const { Title } = Typography;

    const [files, setFiles] = useState([]);

    // const [range, setRange] = useState(moment.tz('Asia/Calcutta').endOf('month'));

    const [range, setRange] = useState([]);

    const [found, setFound] = useState([]);

    const [missing, setMissing] = useState([]);

    const [loading, setLoading] = useState(false);

    const [roReport, setRoReport] = useState();

    const [loader, setLoader] = useState(false);

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    const uploadProps = {
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            setFiles([...files, file]);

            return false;
        },
        files,
    };

    function handleFile(f) {
        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {
            const bstr = e.target.result;

            // let data = csvJSON(bstr);

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            console.log(wb, wb.SheetNames);

            const dms = wb.Sheets[wsname];

            const ro = XLSX.utils.sheet_to_json(dms, {
                // header: 0
                // range:0

                header: 2,
                range: 0,
            });

            console.log(ro);

            setRoReport(ro);
        };

        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
    }

    function processData() {
        setLoader(true);

        let available = [];

        let notAvailable = [];

        // Find the keys of RO Report
        var roReportData = groupBy(roReport, 'VIN');

        let roReportKeys = Object.keys(roReportData);

        console.log('Finding Missing accessory bill in ro report');

        return Promise.all(
            roReportKeys.map((entry) => {
                var query = [
                    {
                        field: 'type',
                        value: 'Accessories',
                    },

                    {
                        field: 'attributes.vinNo',
                        value: entry,
                    },
                ];

                if (range.length) {
                    query.push(
                        {
                            field: 'invoice_date_millisecond',
                            operator: '>=',
                            value: moment(range[0]).startOf('day').valueOf(),
                        },
                        {
                            field: 'invoice_date_millisecond',
                            operator: '<=',
                            value: moment(range[1]).endOf('day').valueOf(),
                        }
                    );
                }

                return Bills.get(query).then((res) => {
                    if (res.bills.length) {
                        available.push(entry);
                    } else {
                        notAvailable.push(entry);
                    }
                });
            })
        ).then(() => {
            let notavailableBills = [];

            let availableBills = [];

            Promise.all(
                notAvailable.map((vinNo) => {
                    notavailableBills = [].concat(notavailableBills, roReportData[vinNo]);
                })
            ).then(() => {
                Promise.all(
                    available.map((vin) => {
                        availableBills = [].concat(availableBills, roReportData[vin]);
                    })
                );
                setFound(availableBills);
                setMissing(notavailableBills);
                setLoading(true);
                setLoader(false);
            });
        });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
            width: 100,
        },

        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '14px' }}>{record['Customer Name']}</div>
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'Bill Date',
            dataIndex: 'bill date',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '14px' }}>{record['Bill Date']}</div>
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'Bill No',
            dataIndex: 'bill no',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '14px' }}>{record['Bill No']}</div>
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'Vin Number',
            dataIndex: 'VIN',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '14px' }}>{record['VIN']}</div>
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'R/O No',
            dataIndex: 'ro',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '14px' }}>{record['R/O No']}</div>
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'Reg. No',
            dataIndex: 'regNo',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '14px' }}>{record['Reg. No']}</div>
                    </div>
                );
            },
            width: 200,
        },
    ];

    const SheetJSFT = [
        'xlsx',
        'xlsb',
        'xlsm',
        'xls',
        'xml',
        'csv',
        'txt',
        'ods',
        'fods',
        'uos',
        'sylk',
        'dif',
        'dbf',
        'prn',
        'qpw',
        '123',
        'wb*',
        'wq*',
        'html',
        'htm',
    ]
        .map(function (x) {
            return '.' + x;
        })
        .join(',');

    //on update of time
    function updateTime(dt) {
        setRange(dt);
    }

    return (
        <>
            <div className="page-header">
                <div className="left">
                    <Title level={4}>Compare RO Billing and Accessory Bill</Title>
                </div>

                <div className="table-hint right">
                    <div className="page-actions">
                        {/* {loading ? <Text type="secondary">Loading ..</Text> : null} */}
                        <RangePicker
                            inputReadOnly
                            format={dateFormat}
                            //value={range}
                            onChange={updateTime}
                            allowClear={false}
                        />
                    </div>
                </div>
            </div>

            <div>
                <Space direction="vertical">
                    <div className="button-container">
                        <>
                            <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                <Button size={'small'} icon={<UploadOutlined />}>
                                    Select File
                                </Button>
                            </Upload>
                        </>
                    </div>

                    <Button type="primary" htmlType="submit" className="submit-button" onClick={processData}>
                        Submit
                    </Button>
                </Space>
            </div>

            <div>
                <Space direction="vertical">
                    {loader ? (
                        <PlaceHolder type="listing" />
                    ) : found.length ? (
                        <>
                            <p style={{ margin: '10px' }}>
                                <b>{found.length}</b> records shown below are billed in Surge
                            </p>
                            {found.length ? <ExportXLS csvData={found} fileName="Billed Records" /> : null}
                            <Table scroll={{ x: true }} dataSource={found} columns={columns} pagination={false} />
                        </>
                    ) : null}
                </Space>
            </div>

            <div>
                <Space direction="vertical">
                    {loader ? (
                        <PlaceHolder type="listing" />
                    ) : missing.length ? (
                        <>
                            <p style={{ color: 'red', margin: '10px' }}>
                                <b>{missing.length}</b> records shown below are not billed in Surge
                            </p>

                            {missing.length ? <ExportXLS csvData={missing} fileName="Missing Records" /> : null}

                            <Table scroll={{ x: true }} dataSource={missing} columns={columns} pagination={false} />
                        </>
                    ) : null}
                </Space>
            </div>
        </>
    );
}

// function Table({ data, columns }) {
//     return (
//         <div className="request-card">
//             <div className="card">
//                 <h2>{data['Customer Name']}</h2>
//                 <h2 className="title" style={{ color: 'red' }}>
//                     {data['VIN']}
//                 </h2>
//                 <h3>Bill No: {data['Bill No']}</h3>
//                 <h3>Bill Date: {data['Bill Date']}</h3>
//                 <p>R/O No: {data['R/O No']}</p>
//                 <p>Reg. No: {data['Reg. No']}</p>
//             </div>
//         </div>
//     );
// }
