/**
 *
 *
 */

import React, { useEffect, useState } from 'react';

import { Row, Typography, Table, Space } from 'antd';

import { Bills, BillDetails } from '../../../../models';

import { useHistory, Link } from 'react-router-dom';

const { Title } = Typography;

export default function AllBills({ userRequest }) {
    const [data, setData] = useState([]);
    let history = useHistory();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        var query = [
            {
                field: 'bookingNo',
                value: userRequest.bookingNo,
            },
        ];
        Bills.get(query).then((result) => {
            let arr = result.bills.filter((r) => !r.cancellation_status);

            setData(arr);
        });
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Bill Type',
            dataIndex: 'type',
            key: 'type',
        },

        {
            title: 'Authorised By',
            dataIndex: 'authorised_by',
            key: 'authorised_by',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Actions',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/bill/${record.id}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    return (
        <div className="vehicle-card ">
            <div className="pricing">
                <div className="document-status">
                    <Title level={3}>Bills </Title>

                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Table scroll={{ x: true }} pagination={false} dataSource={data} columns={columns} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
