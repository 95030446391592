/**************************************************
 * Parse date to cart format
 *************************************************/
/**
 * Parses accessories to cart format
 * @param {*} type - booking or request
 * @param {*} data - Data to be parsed to array
 */
import moment from 'moment';

/**
 * Prepares the cart item with default props and
 *
 * @param {*} item
 * @param {*} booking
 * @returns
 */
export function prepareCartItem(item = { quantity: 1 }, booking, request) {
    let creation = {
        created_at: moment().format('DD/MM/YYYY HH:mm'),
        created_date: moment().startOf('day').valueOf(),
        created_time: moment().valueOf(),
        deleted_at:null,
        deleted_by:null
    };

    let defaultProps = {
        ...creation,
        dealerCode: booking['Dealer Code'],
        customerID: booking['Customer ID'],
        bookingNo: request.bookingNo
    };

    let content = {
        ...defaultProps,
        name: item.partName,
        description: item.description,
        price: item.price,
        type: item.category,

        no: item.partNo,
        quantity: item.quantity,
        total: item.price * item.quantity
    };

    if (['VAS', 'Accessories', 'After Market'].indexOf(item.category) !== -1) {
        content = {
            ...content,
            cgst: item.cgst,
            sgst: item.sgst,
            taxable_value: item.taxable_value
        };
    }

    return clean(content);
}

function clean(obj) {
    for (var propName in obj) {
        if ( obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
}

/**
 * Prepares the cart item with default props and
 *
 * @param {*} item
 * @param {*} booking
 * @returns
 */
export function prepareCartEntry(item = { quantity: 1 }, booking) {
    let creation = {
        created_at: moment().format('DD/MM/YYYY HH:mm'),
        created_date: moment().startOf('day').valueOf(),
        created_time: moment().valueOf()
    };

    let defaultProps = {
        ...creation,
        dealerCode: booking['Dealer Code'],
        customerID: booking['Customer ID'],
        bookingNo: booking['Booking No']
    };

    return {
        ...defaultProps,
        name: item.partName,
        description: item.partName,
        price: item.price,
        type: item.category
    };
}
