import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

import CustomerList from './components/customer-list/customer-list';
import InsuranceForm from './components/insurance-form/insurance-form';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import './insurance-landing.scss';

import { GlobalContext } from '../../Store';

import BookingDetail from './../common/components/booking-detail/booking-detail';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import { insuranceSteps, requestColumns } from './insurance-config';

import { requestSchema } from './../../modules/global-config';
import ChangePassword from './../common/components/change-password/change-password';
import GenericRequest from './../common/resources/generic-request/generic-request';

import TaskOverview from './../common/resources/task-overview/task-overview';

import RequestDetail from './../common/components/request-detail/request-detail';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';

export default function InsuranceLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    const queries = [];

    const updatedInsuranceSteps = insuranceSteps(user.locations[0]);

    const modules = [
        {
            caption: 'Requests',
            submenus: [
                {
                    path: `/${user.locations[0]}/all-requests`,
                    caption: 'All Requests',
                },
                {
                    path: `/${user.locations[0]}/pending-requests`,
                    caption: 'Pending Requests',
                },

                {
                    path: `/${user.locations[0]}/billing-requests`,
                    caption: 'Pending for Delivery',
                },

                {
                    path: `/${user.locations[0]}/delivered-requests`,
                    caption: 'Delivered Requests',
                },
            ],
        },

        {
            caption: 'Tasks',
            submenus: updatedInsuranceSteps.filter((step) => !step.isForm),
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => (
                            <>
                                <NoticeBoard />

                                <TaskOverview steps={updatedInsuranceSteps} />
                            </>
                        )}
                    ></Route>
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    <Route path="/:city/customers" component={() => <GenericRequest columns={requestColumns} queries={queries} />} />

                    <Route
                        exact
                        path={`/:city/all-requests`}
                        component={(...routeParams) => <GenericList schema={requestSchema} queries={[]} {...routeParams} />}
                    />

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/pending-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.pendingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/billing-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.billingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Delivered Requests */}
                    <Route
                        exact
                        path={`/:city/delivered-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.deliveryRequests} {...routeParams} />
                        )}
                    />
                    {/* Delivered Requests Ends */}

                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    {/* <Route path="/requests/:id" component={({ ...routeParams }) => <InsuranceForm step={{ fields: [] }} {...routeParams} columns={requestColumns} queries={queries} />} /> */}

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={updatedInsuranceSteps} list={CustomerList} form={InsuranceForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
