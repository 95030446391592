



import React, { useState, useEffect, useContext } from 'react';

import {
    Tag,
    Typography,
    Badge,
} from 'antd';

// import './reconcile-summary.scss';

import VoucherInfo from './../voucher-info/voucher-info';

const { Text } = Typography;

export default function ReceiptInfo({ receipt = { voucher: {} } }) {
    var created_by;
    var created_at;

    if (receipt.rejectRemarks) {
        if (receipt.smReject) {
            created_by = receipt.smReject.created_by;

            created_at = receipt.smReject.created_at;
        } else if (receipt.edpReject) {
            created_by = receipt.edpReject.created_by;

            created_at = receipt.edpReject.created_at;
        }
    }

    return (
        <>
            <h3 className="title">{receipt['description']}</h3>
            <h2 className={`title credit`}>{receipt['credit']}</h2>
            <p className="title ">{receipt['ref']}</p>
            <p className="">
                <Tag color="magenta">{receipt.location || receipt.bank}</Tag>

                {receipt.location || receipt.city ? <Badge color="#f50" text={receipt.city || receipt.location} /> : null}
            </p>
            Credited at {receipt['txn_date']} | <VoucherInfo receipt={receipt} />
            <div>
                {receipt.rejectRemarks ? (
                    <Text type="danger">
                        {receipt.rejectRemarks}, By {created_by} at {created_at}
                    </Text>
                ) : null}
            </div>
            {receipt.edp && receipt.edp.created_at ? (
                <>
                    Created at {receipt.edp.created_at}, By {receipt.edp.created_by}
                </>
            ) : null}
        </>
    );
}

