
import React, { useState, useEffect, useContext } from "react";

import { Row, Button, Typography } from "antd";

import { Link } from 'react-router-dom'


import { Tabs, Select } from 'antd';

import { Tag, Badge } from 'antd'

import PlaceHolder from "../../../../components/ui_elements/PlaceHolder";

import "./finance.scss";

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

const { Option } = Select;

const { TabPane } = Tabs;

const { Title } = Typography;


const documentOptions = [
    {
        label: 'Data Collected',
        value: 'Data Collected'
    },
    {
        label: 'Documents Collected',
        value: 'Documents Collected'
    },
    {
        label: 'Login',
        value: 'Login'
    },
    {
        label: 'Finance Approved',
        value: 'Finance Approved'
    },
    {
        label: 'Finance Rejected',
        value: 'Finance Rejected'
    },
    {
        label: 'Delivery Order',
        value: 'Delivery Order'
    },
    {
        label: 'Disbursement',
        value: 'Disbursement'
    }
]


export default function Finance({ step }) {

    const { user } = useContext(GlobalContext);

    useEffect(() => {

    }, [user])

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="finance">

            <Title level={3}>
                {step.caption}
            </Title>


            <Tabs defaultActiveKey="1" onChange={callback}>

                {
                    user.locations.map((location, index) => {

                        return (
                            <TabPane tab={`${location} Customers`} key={index}>

                                <FinanceList url={location} step={step} />

                            </TabPane>
                        )
                    })
                }

            </Tabs>

        </section>
    );
}


const FinanceList = ({ url, step }) => {

    const [allocations, setAllocations] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        getData();

    }, [])


    function getData() {

        setLoader(true);

        FirebaseUtils.getRequests(url, step.queries).then((vehicles) => {

            setLoader(false);

            setAllocations(vehicles.requests);

        });

    }


    return (<><div className="list-header">

        <p className="page-desc">
            {loader ? 'Loading data' : `Found ${Object.keys(allocations).length} results`}
        </p>

        <div className="list-actions">
            <Button onClick={() => { getData() }} type="secondary" size={'small'}>Refresh</Button>
        </div>

    </div>

        <Row className="report-listing-wrapper">
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                    <>
                        {
                            (allocations).map((report, index) => {
                                return <step.Card step={step} vehicle={report} index={report.id} key={report.id} />
                            })
                        }
                    </>
                )}
        </Row></>)
}
