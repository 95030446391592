import React from 'react';

import moment from 'moment';

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';

import { Link } from 'react-router-dom';

import { Tag, Badge } from 'antd';

import { Requests } from '../../models';

import { message } from 'antd';

function AllocationCard({ vehicle, index, step }) {
    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" to={`/${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">{vehicle.bookingNo}</h2>

                {/* {vehicle.finance && vehicle.finance.documentStatus ? <Tag color="orange">{vehicle.finance && vehicle.finance.documentStatus}</Tag> : null} */}

                {/* {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                } */}

                <h3 className="title">{vehicle.customer.customerName}</h3>

                <h2 className="title">{vehicle.customer.phone}</h2>

                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}
                </h3>

                {vehicle.allocation && vehicle.allocation.vinNo ? (
                    <p className="address">{vehicle.allocation.vinNo}</p>
                ) : (
                    <p className="address">Vehicle Allocation Pending</p>
                )}

                <p className="address">KEC on {vehicle.kec}</p>

                {waitingTime && waitingTime.toString().length > 0 ? (
                    <p className="date-time" style={{ display: 'flex' }}>
                        Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (
                        <div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                    </p>
                ) : null}

                {vehicle.isHighPriority ? <Tag color="magenta">High Priority</Tag> : null}

                {vehicle.finance && vehicle.finance.documentStatus ? <Badge status="processing" text={vehicle.finance.documentStatus} /> : null}
            </div>
        </Link>
    );
}

export const financeSteps = [
    {
        caption: 'Data Collection',
        path: '/data-collection',
        status: 'pending',
        redirect_to: 'finance',
        queries: [
            {
                field: 'finance.status',
                value: 'pending',
            },
            {
                field: 'finance.process',
                value: 'incomplete',
            },
            {
                field: 'allocation.status',
                operator: 'in',
                value: ['approved', 'Allocated'],
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'allocation.created_time',
        Card: AllocationCard,
    },

    {
        caption: 'Documents Collected',
        path: '/documents-collected',
        status: 'Data Collected',
        redirect_to: 'finance-documents',
        queries: [
            {
                field: 'finance.status',
                value: 'Data Collected',
            },
            {
                field: 'finance.process',
                value: 'incomplete',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'finance.data_collected_time',
        Card: AllocationCard,
    },

    {
        caption: 'Login',
        path: '/finance-login-list',
        status: 'Documents Collected',
        redirect_to: 'finance-login',
        queries: [
            {
                field: 'finance.status',
                value: 'Documents Collected',
            },
            {
                field: 'finance.process',
                value: 'incomplete',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'finance.documents_collected_time',
        Card: AllocationCard,
    },

    {
        caption: 'Finance Approved/Rejected',
        path: '/finance-status',
        status: 'Finance Approval Pending',
        redirect_to: 'finance-status-form',
        queries: [
            {
                field: 'finance.status',
                value: 'Finance Approval Pending',
            },
            {
                field: 'finance.process',
                value: 'incomplete',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'finance.logged_in_time',
        Card: AllocationCard,
    },

    {
        caption: 'Delivery Order',
        path: '/delivery-order',
        status: 'Delivery Order Pending',
        redirect_to: 'delivery-order-form',
        queries: [
            {
                field: 'finance.status',
                value: 'Delivery Order Pending',
            },
            {
                field: 'finance.process',
                value: 'incomplete',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'finance.finance_status_time',
        Card: AllocationCard,
    },

    {
        caption: 'Disbursement',
        path: '/disbursement',
        status: 'Disbursement Pending',
        redirect_to: 'disbursement-form',
        queries: [
            {
                field: 'finance.status',
                value: 'Disbursement Pending',
            },
            {
                field: 'finance.process',
                value: 'incomplete',
            },
        ],
        field_time: 'finance.do_time',
        Card: AllocationCard,
    },

    {
        caption: 'Finance Verification',
        path: '/finance-verification',
        redirect_to: 'finance-verification-form',
        queries: [
            {
                field: 'actual_billing.status',
                value: 'Finance Verification Pending',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: ' sm_initiated_at',
        Card: AllocationCard,
    },

    // Data Collection Form
    {
        isForm: true,
        caption: 'Data Collection',
        path: '/finance/:city/:id',
        post_status: 'Data Collected',
        fields: [
            {
                type: 'radio',
                options: ['In House', 'Direct', 'Own Fund'],
                caption: 'Finance Type',
                field: 'type',
            },

            {
                type: 'reference-selector',
                caption: 'Bank',
                field: 'bank_id',
                table: 'finance_banks',

                condition: (values) => {
                    return values.type && values.type !== 'Own Fund';
                },

                onUpdate: (field, record, selected) => {
                    return {
                        bank: selected.name,
                    };
                },
            },

            {
                type: 'reference-selector',
                caption: 'Branch',
                field: 'branch_id',
                queries: (values) => {
                    return [
                        {
                            field: 'bank_id',
                            value: values.bank_id,
                        },
                    ];
                },
                table: 'finance_branches',

                onUpdate: (field, record, selected) => {
                    return {
                        branch: selected.name,
                    };
                },

                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },
            },

            // {
            //     type: 'input',
            //     caption: 'Branch',
            //     field: 'branch',
            //     condition: (values) => {
            //         return values.type && values.type !== 'Own Fund';
            //     },
            // },

            {
                type: 'number',
                caption: 'Requested Amount',
                field: 'requestedAmount',
                condition: (values) => {
                    return values.type && values.type !== 'Own Fund';
                },
            },

            {
                type: 'date',
                transform: (value) => {
                    return moment(value);
                },
                caption: 'Expected Retail Date',
                field: 'expected_retail_date',
                condition: (values) => {
                    return values.type && values.type !== 'Own Fund';
                },
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                data_collected_at: moment().format('DD/MM/YYYY HH:mm'),
                data_collected_date: moment().startOf('day').valueOf(),
                data_collected_time: moment().valueOf(),
            };

            if (params.type === 'Own Fund') {
                return {
                    finance: {
                        ...creation,
                        ...params,
                        expected_retail_date: moment(params.expected_retail_date).valueOf(),
                        status: 'Disbursement Pending',
                    },
                };
            } else {
                return {
                    finance: {
                        ...creation,
                        ...params,
                        expected_retail_date: moment(params.expected_retail_date).valueOf(),
                    },
                };
            }
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Finance Data Collected',
                body: `Data has been collected for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
    },

    // Documents Collected
    {
        isForm: true,
        caption: 'Documents Pending',
        path: '/finance-documents/:city/:id',
        post_status: 'Documents Collected',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                documents_collected_at: moment().format('DD/MM/YYYY HH:mm'),
                documents_collected_date: moment().startOf('day').valueOf(),
                documents_collected_time: moment().valueOf(),
            };

            return {
                finance: {
                    ...creation,
                    ...params,
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Finance Documents Collected',
                body: `Documents has been collected for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
    },

    // Login Form
    {
        isForm: true,
        caption: 'Login',
        path: '/finance-login/:city/:id',
        post_status: 'Finance Approval Pending',
        fields: [
            {
                type: 'reference-selector',
                caption: 'Bank',
                field: 'bank_id',
                table: 'finance_banks',
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        bank: selected.name,
                    };
                },
                condition: (values) => {
                    return values.type && values.type !== 'Own Fund';
                },
            },

            {
                type: 'reference-selector',
                caption: 'Branch',
                field: 'branch_id',
                queries: (values) => {
                    return [
                        {
                            field: 'bank_id',
                            value: values.bank_id,
                        },
                    ];
                },
                table: 'finance_branches',
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        branch: selected.name,
                    };
                },
                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },
            },

            // {
            //     type: 'input',
            //     caption: 'Branch',
            //     field: 'branch',
            //     condition: (values) => {
            //         return values.type && values.type !== 'Own Fund';
            //     },
            // },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
            {
                type: 'date',
                caption: 'Expected Retail Date',
                field: 'expected_retail_date',
                transform: (value) => {
                    return moment(value);
                },
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                logged_in_at: moment().format('DD/MM/YYYY HH:mm'),
                logged_in_date: moment().startOf('day').valueOf(),
                logged_in_time: moment().valueOf(),
                // expected_retail_date: '',
            };

            return {
                finance: {
                    ...creation,
                    ...params,
                    expected_retail_date: moment(params.expected_retail_date).valueOf(),
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Finance Login Done',
                body: `Login has be done for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
    },

    // Finance Status
    {
        isForm: true,
        caption: 'Finance Status',
        path: '/finance-status-form/:city/:id',
        post_status: 'Delivery Order Pending',
        // todo conditional post_status
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                finance_status_at: moment().format('DD/MM/YYYY HH:mm'),
                finance_status_date: moment().startOf('day').valueOf(),
                finance_status_time: moment().valueOf(),
            };

            return {
                finance: {
                    ...creation,
                    ...params,
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Delivery order is pending.',
                body: `Delivery order is pending for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
    },

    // Delivery Order
    {
        isForm: true,
        caption: 'Delivery Order',
        path: '/delivery-order-form/:city/:id',
        post_status: 'Disbursement Pending',
        fields: [
            {
                type: 'number',
                caption: 'Sanctioned Amount',
                field: 'sanctioned_amount',
            },

            {
                type: 'reference-selector',
                caption: 'Bank',
                field: 'bank_id',
                table: 'finance_banks',
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        bank: selected.name,
                    };
                },
                condition: (values) => {
                    return values.type && values.type !== 'Own Fund';
                },
            },

            {
                type: 'reference-selector',
                caption: 'Branch',
                field: 'branch_id',
                queries: (values) => {
                    return [
                        {
                            field: 'bank_id',
                            value: values.bank_id,
                        },
                    ];
                },
                table: 'finance_branches',
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        branch: selected.name,
                    };
                },
                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },
            },

            // {
            //     type: 'input',
            //     caption: 'Branch',
            //     field: 'branch',
            //     condition: (values) => {
            //         return values.type && values.type !== 'Own Fund';
            //     },
            // },

            {
                type: 'input',
                caption: 'Interest',
                field: 'interest',
            },

            {
                type: 'input',
                caption: 'Payout',
                field: 'payout',
            },

            {
                type: 'upload',
                field: 'deliveryOrder',
                caption: 'Delivery Order ',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                do_at: moment().format('DD/MM/YYYY HH:mm'),
                do_date: moment().startOf('day').valueOf(),
                do_time: moment().valueOf(),
            };

            return {
                finance: {
                    ...creation,
                    ...params,
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Delivery Order Issued',
                body: `Delivery order has been issued for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
    },

    // Disbursement
    {
        isForm: true,
        caption: 'Disbursement',
        path: '/disbursement-form/:city/:id',
        post_status: 'Disbursed',
        fields: [
            {
                type: 'reference-selector',
                caption: 'Bank',
                field: 'bank_id',
                table: 'finance_banks',
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        bank: selected.name,
                    };
                },
                condition: (values) => {
                    return values.type && values.type !== 'Own Fund';
                },
            },

            {
                type: 'reference-selector',
                caption: 'Branch',
                field: 'branch_id',
                queries: (values) => {
                    return [
                        {
                            field: 'bank_id',
                            value: values.bank_id,
                        },
                    ];
                },
                table: 'finance_branches',

                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        branch: selected.name,
                    };
                },
                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },
            },

            // {
            //     type: 'input',
            //     caption: 'Branch',
            //     field: 'branch',
            //     condition: (values) => {
            //         return values.type && values.type !== 'Own Fund';
            //     },
            // },

            {
                type: 'input',
                caption: 'Credited Amount',
                field: 'credited_amount',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                disbursed_at: moment().format('DD/MM/YYYY HH:mm'),
                disbursed_date: moment().startOf('day').valueOf(),
                disbursed_time: moment().valueOf(),
            };

            return {
                finance: {
                    ...creation,
                    ...params,
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Finance Disbursed',
                body: `Finance has been disbursed for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
    },

    //Finance Verification Pending
    {
        isForm: true,
        caption: 'Finance Verification',
        path: '/finance-verification-form/:city/:id',
        fields: [
            {
                type: 'reference-selector',
                caption: 'Bank',
                field: 'bank_id',
                table: 'finance_banks',
                condition: (values) => {
                    return values.type && values.type !== 'Own Fund';
                },

                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        bank: selected.name,
                    };
                },
            },
            {
                type: 'reference-selector',
                caption: 'Branch',
                field: 'branch_id',
                queries: (values) => {
                    return [
                        {
                            field: 'bank_id',
                            value: values.bank_id,
                        },
                    ];
                },
                table: 'finance_branches',
                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },

                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        branch: selected.name,
                    };
                },
            },

            // {
            //     type: 'input',
            //     caption: 'Branch',
            //     field: 'branch',
            //     condition: (values) => {
            //         return values.type && values.type !== 'Own Fund';
            //     },
            // },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'finance_remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                finance_verification_at: moment().format('DD/MM/YYYY HH:mm'),
                finance_verification_date: moment().startOf('day').valueOf(),
                finance_verification_time: moment().valueOf(),
            };

            return {
                finance: {
                    ...params,
                    ...creation,
                },
            };
        },
        onSubmit: (request, values) => {
            let params;
            if (request.finance.type !== 'Own Fund') {
                params = {
                    actual_billing: {
                        ...request.actual_billing,
                        status: 'Document Verification Pending',
                        finance_remarks: values.finance_remarks,
                        type: request.finance.type || '',
                        bank: values.bank || '',
                        bank_id: values.bank_id || '',
                        branch: values.branch || '',
                        branch_id: values.branch_id || '',
                    },

                    finance: {
                        ...request.finance,
                        bank: values.bank || '',
                        bank_id: values.bank_id || '',
                        branch: values.branch || '',
                        branch_id: values.branch_id || '',
                    },
                };
            } else {
                params = {
                    actual_billing: {
                        ...request.actual_billing,
                        status: 'Document Verification Pending',
                        finance_remarks: values.finance_remarks,
                        type: request.finance.type || '',
                    },
                    finance: {
                        ...request.finance,
                    },
                };
            }

            return Requests.update(request.id, params).then(() => {
                message.success('Forward for document verification to team leader success');
            });
        },
    },
];

/**
 * Columns for Request Table
 */
export const requestColumns = [
    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName',
    },
    {
        type: 'input',
        caption: 'Part Name',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'finance.status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant',
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
];
