/**
 * Status-change Component
 *
 * @description Component to change status
 * @author Sneha T
 */

import React, { useState, useEffect } from 'react';

import { Form, Input, Timeline, Button, Modal, message, Select } from 'antd';

import { StatusMasters, StatusLogs } from '../../models';

import moment from 'moment';

import './status-change.scss';

import useDeviceDetect from '../../hooks/device-detect';

/**
 *
 */
export default function StatusChange({
    caption,
    description,
    referenceNumber,
    formValues,
    statusId,
    mode,
    callback,
    updateDisabled
    // stockdisable,
    // stockRecord,
    // loading,
}) {
    const { TextArea } = Input;

    const { Option } = Select;

    // state for status options
    const [status, setStatus] = useState([]);

    // state to manage modal visibility
    const [visible, setVisible] = useState(false);

    //state to store previous log of status
    const [logs, setLogs] = useState([]);

    //state to disable and enable button
    const [disabled, setDisabled] = useState('true');

    //state to manage button loading
    const [btnloading, setBtnLoading] = useState(false);

    // is mobile
    const { isMobile } = useDeviceDetect();

    useEffect(() => {
        // if (stockdisable) {
        //     getOptions();
        // }
    }, []);

    /**
     * Get all the status options
     */
    async function getOptions() {
        let queries = [
            {
                field: 'mode',
                value: mode,
            },
        ];

        const result = await StatusMasters.get(queries);
        setStatus(result.status_masters || []);
    }


    // /**
    //  * 
    //  * Stock transfer Reject button Functionality 
    //  */
    // async function rejecteStock() {
    //     let status;
    //     status = await updateStatus();
    //     // assign status rejected 
    //     status = {
    //         ...status,
    //         status: 'rejected',
    //     };
    //     // callback
    //     callback(status);

    // }

    /**
     * Update the status of the mode
     *
     * @param {*} values
     * @returns
     */

    async function updateStatus(values) {
        
        let formBody = {
            "status_id": values.status_id,
            "remarks": values.remarks ? values.remarks : null,
        }
        let matchingStatus = status.filter((record) => record.id === values.status_id)[0];

        // Find the matching status based on stocktransfer record 
        // if (stockdisable) {
        //     matchingStatus = status.filter((record) => record.name === statusId)[0];
        //     record = stockRecord;
        // } else {
        //     // Find the matching status based on vehicle record 
        //     record = values;
        // }


        setBtnLoading(true);
        // add status long 
        await StatusLogs.add(
            {
                ...formValues,
                ...formBody,
                status_name: matchingStatus.name,
            },
            {
                hideAlert: true,
            }
        );

        setBtnLoading(false);

        setVisible(false);

        callback(matchingStatus);

        return matchingStatus;
    }

    /**
     * Get status log
     */
    const getStatusLog = async () => {
        getOptions();

        setDisabled(true);

        setVisible(true);

        let queries = [
            {
                field: 'reference_number',
                value: referenceNumber,
            },
        ];

        const result = await StatusLogs.get(queries);

        setLogs(result.status_logs || []);
    };

    return (
        // <div>
        //     {stockdisable ? (
        //         <div>
        //             <Button onClick={updateStatus} type="primary" htmlType="submit" loading={loading}>
        //                 Approve
        //             </Button>

        //             <Button htmlType="submit" type="primary" onClick={rejecteStock}>
        //                 Reject
        //             </Button>
        //         </div>
        //     ) : (
        <div className="status-change">
            <div>
                <p>{description || <i>Pending</i>}</p>
            </div>

            <div className="actions">
                <Button className={'field-editor'} size="small" onClick={getStatusLog} disabled={updateDisabled}>
                    Update
                </Button>
            </div>

            <div className="status-section" style={{ display: 'flex' }}>
                <Modal
                    width={isMobile? '100%':'50%'}
                    header={null}
                    destroyOnClose
                    visible={visible}
                    title={'Status Update'}
                    footer={null}
                    onCancel={() => setVisible(false)}
                >
                    <div className="status-modal">
                        <div className="form-section">
                            <p>Update the {caption}</p>

                            <Form
                                onFieldsChange={(fields) => {
                                    // Enable submit button while changing the status
                                    if (fields[0].name[0] === 'status_id') {
                                        setDisabled(false);
                                    }
                                }}
                                onFinish={updateStatus}
                                // initialValues={formValues}
                                initialValues={{ status_id: statusId }}
                                name="status-change-form"
                                layout="vertical"
                                label="update status"
                            >
                                <Form.Item
                                    name="status_id"
                                    label="Status"
                                    rules={[
                                        {
                                            required: true,

                                            message: 'Please select status',
                                        },
                                    ]}
                                >
                                    <Select>
                                        {status.map((data, key) => (
                                            <Option value={data.id}>{data.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="remarks"
                                    label="Remarks"
                                    rules={[
                                        {
                                            required: false,
                                        },

                                        {
                                            max: 250,

                                            message: 'Length cannot exceed 250 characters !',
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} />
                                </Form.Item>

                                <Button disabled={disabled} loading={btnloading} htmlType="submit" type="primary" size="small">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                        <div className="history">
                            <Timeline>
                                {logs.map((ele) => {
                                    return (
                                        <Timeline.Item color="green">
                                            {ele.name}

                                            <p className="details">
                                                <small>
                                                    {ele.created_at}
                                                    {/* {moment.tz(ele.created_at, '').format('DD/MM/YYYY hh:mm a')} */}
                                                </small>
                                            </p>

                                            <p className="details">
                                                <small>
                                                    <strong>Remarks:</strong> {ele.remarks}
                                                </small>
                                            </p>
                                            <p className="details">
                                                <small>
                                                    <strong>Updated By:</strong> {ele.created_by_name}
                                                </small>
                                            </p>
                                        </Timeline.Item>
                                    );
                                })}
                            </Timeline>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
        // )}
        //     </div>
    );
}
