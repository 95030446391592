/**
 *
 * @author Hasna
 */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Dropdown, Menu, Typography, Button, Space, DatePicker, Select, Badge } from 'antd';

import FirebaseUtils from '../../../../utils/firebase.utils';
import ApiUtils from '../../../../utils/api.utils';

import Rupee from '../../../../components/rupee/rupee';

import { DownOutlined } from '@ant-design/icons';

import { Switch } from 'antd';

import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom';
import moment, { months } from 'moment-timezone';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { ExportXLS } from './ExportXLS';

import { useReactToPrint } from 'react-to-print';

import '../../../../models/bookings';

import './enquiry-report.scss';

import { Users, Enquirys } from '../../../../models/';

const { Title, Text } = Typography;

const dateFormat = 'DD/MM/YYYY';

const { RangePicker } = DatePicker;

function enquiryReport() {
    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
            width: 100,
        },
        {
            title: 'KEC',
            dataIndex: 'kec',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.name}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.name}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 150,
        },

        {
            title: 'Walk-in',
            dataIndex: 'walkin',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.walkin}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.walkin}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },

        {
            title: 'Tele-in',
            dataIndex: 'telein',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.telein}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.telein}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },

        {
            title: 'Field',
            dataIndex: 'field',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.field}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.field}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },

        {
            title: 'Referral',
            dataIndex: 'referral',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.referral}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.referral}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },

        {
            title: 'Online/CRM',
            dataIndex: 'crm',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.crm}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.crm}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },

        {
            title: 'Total Enquiry',
            dataIndex: 'total',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.total}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.total}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },
    ];

    var query = (range) => {
        return [
            {
                field: 'enquiry_date',
                operator: '>=',
                // value: 1580236200000,
                value: range[0].startOf('day').valueOf(),
            },

            {
                field: 'enquiry_date',
                operator: '<=',
                value: range[1].endOf('day').valueOf(),
                // value: 1590777000000,
            },
        ];
    };

    return (
        <div>
            <div className="report listing">
                <div className="page-header">
                    <div className="left">
                        <Title level={3}>Enquiry Report</Title>
                    </div>
                </div>
            </div>
            <Enquiry columns={columns} queries={query} />
        </div>
    );
}

function Enquiry({ columns, query }) {
    const [startdate, setStartdate] = useState(moment().startOf('month'));

    const [enddate, setEnddate] = useState(moment());

    const [range, setRange] = useState([startdate, enddate]);

    const [dates, setDates] = useState([startdate, enddate]);

    const [hackValue, setHackValue] = useState();

    const { user } = useContext(GlobalContext);

    const [location, setLocation] = useState(user.locations[0]);

    const [result, setResult] = useState({ enquirys: [] });

    const [loading, setLoading] = useState(true);

    // const print = useRef();

    // const handlePrint = useReactToPrint({
    //     content: () => print.current,

    //     bodyClass: 'hello',
    // });

    useEffect(() => {
        getEnquiry(location, range);
    }, []);

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function handleMenuClick(e) {
        setLocation(e.key);

        getEnquiry(e.key, range);
    }

    function updateTime(dt) {
        setRange(dt);
        getEnquiry(location, dt);
    }

    function getEnquiry(city, range) {
        var userArr = [];

        var response = [];

        var queries = [
            {
                field: 'role',
                value: 'kec',
            },

            {
                field: 'locations',
                operator: 'array-contains',
                value: city,
            },

            {
                field: 'active',
                value: 'true',
            },
        ];

        var enquiryQuery = [
            {
                field: 'enquiry_date',
                operator: '>=',
                value: range[0].valueOf(),
            },

            {
                field: 'enquiry_date',
                operator: '<=',
                value: range[1].valueOf(),
            },
        ];

        return new Promise((resolve, reject) => {
            return Users.get(queries)
                .then((result) => {
                    result.users.map((user) => {
                        userArr.push({
                            name: user.dms['Consultant Name'],
                            teamLeader: user.dms['teamLeader'],
                        });
                    });

                    console.log(userArr);
                    return true;
                })

                .then(() => {
                    return Enquirys.get(enquiryQuery).then((result) => {
                        var res = result.enquiries;

                        return res;
                    });
                })
                .then((res) => {
                    var finalOutput = [];

                    userArr.forEach((person, key) => {
                        let enq = res.filter((enquiry) => enquiry['Consultant Name'] === person.name);

                        let row = {
                            name: person.name,
                            teamLeader: person.teamLeader,
                            // enquiries: enq.length,
                            walkin: 0,
                            telein: 0,
                            field: 0,
                            referral: 0,
                            crm: 0,
                            total: 0,
                        };

                        enq.forEach((enquiry) => {
                            if (enquiry['Source'] === 'Walkin') {
                                row.walkin += 1;
                            } else if (enquiry['Source'] === 'Telephone') {
                                row.telein += 1;
                            } else if (enquiry['Source'] === 'Field') {
                                row.field += 1;
                            } else if (enquiry['Source'] === 'Referral') {
                                row.referral += 1;
                            } else if (enquiry['Source'] === 'Online/CRM') {
                                row.crm += 1;
                            }
                            row.total = row.walkin + row.telein + row.field + row.referral + row.crm;
                        });

                        finalOutput.push(row);
                    });

                    // Group the members by team to calculate each teams total
                    // var teams = groupBy((entry) => entry.teamLeader);
                    var teams = groupBy(finalOutput, 'teamLeader');

                    // itereate over teams to find the sum
                    Object.keys(teams).forEach((teamLeader) => {
                        var members = teams[teamLeader];

                        response = [].concat(response, members);

                        // Find team total
                        response.push({
                            name: teamLeader,
                            isteamLeader: true,
                            walkin: totalOf(members, 'walkin'),
                            telein: totalOf(members, 'telein'),
                            field: totalOf(members, 'field'),
                            referral: totalOf(members, 'referral'),
                            crm: totalOf(members, 'crm'),
                            total: totalOf(members, 'total'),
                        });
                    });

                    resolve(response);
                    return response;
                })
                .then((response) => {
                    setResult({ enquirys: response });
                    setLoading(false);
                });
        });
    }

    var groupBy = function (xs, key) {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    function totalOf(arr, field) {
        var tot = 0;

        arr.forEach((entry) => {
            tot += entry[field];
        });
        return tot;

        // return _.reduce(arr,(a, b) => a + parseInt(b[field]), 0)
    }
    var data = result.enquirys;

    return (
        <div className="finance-tracker listing">
            {/* <WrapperClass ref={print}> */}
            <div className="header">
                <div className="header-actions">
                    <div>{result && <ExportXLS csvData={data} />}</div>

                    {/* <Button size="small" type="primary" className="print-button" onClick={handlePrint}>
                            Download
                        </Button> */}

                    <Button
                        onClick={() => {
                            // refresh();
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>

                    <Dropdown overlay={menu}>
                        <Button size={'small'}>
                            {location} <DownOutlined />
                        </Button>
                    </Dropdown>

                    <RangePicker value={hackValue || range} inputReadOnly format={dateFormat} onChange={updateTime} allowClear={false} />
                </div>
            </div>
            <div>{loading ? <PlaceHolder type="listing" /> : <TeamView columns={columns} data={data} />}</div>
            {/* </WrapperClass> */}
        </div>
    );
}

// class WrapperClass extends React.Component {
//     render() {
//         return <div style={{ width: '100%' }}>{this.props.children}</div>;
//     }
// }

function TeamView({ columns, data }) {
    return (
        <div>
            <Table
                size="small"
                rowKey={(record) => record.index}
                dataSource={data}
                columns={columns}
                pagination={false}
                summary={(pageData) => {
                    let totalwalkin = 0;
                    let totaltelein = 0;
                    let totalfield = 0;
                    let totalreferral = 0;
                    let totalcrm = 0;

                    pageData.forEach((entry) => {
                        if (entry.teamLeader) {
                            totalwalkin += parseFloat(entry['walkin']);
                            totaltelein += parseFloat(entry['telein']);
                            totalfield += parseFloat(entry['field']);
                            totalreferral += parseFloat(entry['referral']);
                            totalcrm += parseFloat(entry['crm']);
                        }
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <Title level={5}>GrandTotal</Title>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <strong>{totalwalkin}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <strong>{totaltelein}</strong>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <strong>{totalfield}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <strong>{totalreferral}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <strong>{totalcrm}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <strong>{totalwalkin + totaltelein + totalfield + totalreferral + totalcrm}</strong>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            ></Table>
        </div>
    );
}

export default enquiryReport;
