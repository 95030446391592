import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Tabs, Divider } from 'antd';

import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

// Accounts Overview
import AccountsOverview from './../common/resources/accounts-overview/accounts-overview';
import BookingOverview from './../common/resources/booking-overview/booking-overview';
import DailyOverview from './../common/resources/daily-overview/daily-overview';

import StockDashboard from '../common/components/stock-dashboard/stock-dashboard';
import ManageRequests from '../common/components/manage-requests/manage-requests';
import ManageBookings from '../common/components/manage-bookings/manage-bookings';
import BookingDetail from '../common/components/booking-detail/booking-detail';

import BookingTracker from './../common/resources/booking-tracker/booking-tracker';

import PreferenceDetail from '../common/components/preference-detail/preference-detail';
import RequestDetail from '../common/components/request-detail/request-detail';
import ModuleRoutesLatest from '../common/resources/modules-routes-latest/modules-routes-latest';

import RequestOverview from './../common/resources/request-overview/request-overview';
import ChangePassword from './../common/components/change-password/change-password';
import VehicleOverview from './../common/resources/vehicle-overview/vehicle-overview';

import FinanceTracker from './../common/resources/finance-tracker/finance-tracker';

import VerifyPayment from './../common/components/verify-payment/verify-payment';

import ActualStock from '../common/components/actual-stock/actual-stock';

import Dashboard from './../common/components/dashboard/dashboard';

import ReconcileSummary from './../common/components/reconcile-summary/reconcile-summary';

import ManagePayments from './../common/components/manage-payments/manage-payments';

import MatchingDashboard from './../common/components/matching-dashboard/matching-dashboard';

import RequestDashboard from './../common/resources/request-dashboard/request-dashboard';

import CustomerReceipts from '../common/components/customer-receipts/customer-receipts';
import CustomerReceiptDetail from '../common/components/customer-receipts/customer-receipts-detail';
import CustomerReceiptAdd from '../common/components/customer-receipts/customer-receipts-add';
import PendingRequestsRM from './components/pending-requests-rm/pending-requests-rm';

import Cancellations from '../common/components/cancellations/cancellations';
import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import AccessoryOverview from './../common/resources/accessory-overview/accessory-overview';

import GenericList from '../common/resources/generic/generic-list/generic-list';
import ReconcileOverview from '../common/resources/reconcilation-overview/reconcilation-overview';

import HighPriority from './../common/components/high-priority/high-priority';
import HighPriorityDetail from './../common/components/high-priority-detail/high-priority-detail';

import { bookingColumns, rmSteps } from './rm-config';

import { requestSchema, bookingSchema, requestRouteConfig, ItemWiseSchema, itemActions, payoutSchema, partsSchema, partsColumns, receiptsSchema } from './../global-config';

import { Location } from '../../utils/location.utils';

import TaskOverview from '../common/resources/task-overview/task-overview';


import NoticeBoard from './../common/components/notice-board/notice-board';

import './rm-landing.scss';

import { GlobalContext } from '../../Store';

import Profile from './../common/resources/profile/profile';
import MonthWiseRetail from '../common/resources/monthwise-retail/monthwise-retail';
import enquiryReport from '../common/resources/enquiry-report/enquiry-report';
import BookingReport from '../common/resources/booking-report/booking-report';
import BulkKecChange from '../admin/components/bulk-kec-change/bulk-kec-change';
import SalesReport from '../common/resources/bill-list/sales-report';
import BillList from '../common/resources/bill-list/bill-list';
import ModuleRoutes from '../common/resources/modules-routes/module-routes';
import { adminSteps, stockColumns, stockSchema } from '../admin/admin-config';
import { Employees } from '../../models';
import IFStatus from '../common/components/if-status/if-status';
import { userSchema } from '../super/super-config';
import TaskRoutes from '../common/resources/task-routes/task-routes';
import ApprovalList from '../admin/components/approval-list/approval-list';
import ApprovalForm from '../admin/components/approval-form/approval-form';

const { TabPane } = Tabs;

export default function RMLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => { }, []);

    const currentURL = Location.search();

    const tagToSearch = Object.keys(currentURL)[0];

    const statusToSearch = currentURL[tagToSearch];

    const modules = [
        {
            caption: 'Daily Overview',
            path: '/daily-overview',
        },

        {
            caption: 'Reports',
            submenus: [
                {
                    caption: 'Monthwise Retail',
                    path: '/monthwise-retails',
                },

                // {
                //     caption: 'Enquiry Report',
                //     path: '/enquiry-report',
                // },
                // {
                //     caption: 'Booking Report',
                //     path: '/booking-report',
                // },
            ],
        },

        {
            caption: 'Reconcilation',
            submenus: [
                {
                    caption: 'Sales Accounts',
                    submenus: [
                        {
                            caption: 'Account Overview',
                            path: '/sales/accounts-overview',
                        },
                        {
                            path: '/sales/pending-summary',
                            caption: 'Pending to Reconcile',
                        },

                        {
                            path: '/verify-payment',
                            caption: 'Verify Receipts',
                        },
                        {
                            path: '/sales/bank-receipts',
                            caption: 'Bank Receipts',
                        },

                        {
                            path: '/sales/customer-receipts',
                            caption: 'Customer Receipts',
                        },
                    ],
                },

                {
                    caption: 'Service Accounts',
                    submenus: [
                        {
                            caption: 'Account Overview',
                            path: '/service/accounts-overview',
                        },
                        {
                            path: '/service/pending-summary',
                            caption: 'Pending to Reconcile',
                        },

                        {
                            path: '/service/bank-receipts',
                            caption: 'Bank Receipts',
                        },

                        {
                            path: '/service/customer-receipts',
                            caption: 'Customer Receipts',
                        },
                    ],
                },
            ],
        },

        // {
        //     caption: 'Account Overview',
        //     path: '/accounts-overview',
        // },

        {
            caption: 'Booking Overview',
            path: '/booking-overview',
        },

        {
            caption: 'Booking Tracker',
            path: '/booking-tracker',
        },

        {
            caption: 'Finance Tracker',
            path: '/finance-tracker',
        },
        {
            caption: 'Vehicle Overview',
            path: '/vehicle-overview',
        },

        {
            caption: 'Accessory Overview',
            path: '/accessory-overview',
        },

        {
            caption: 'Cancellations',
            path: '/cancellations',
        },
        {
            caption: 'Requests',
            submenus: [
                // {
                //   caption: 'All Requests',
                //   path: '/manage-requests/'
                // },

                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/request-overview`,
                                caption: 'Request Overview',
                            },

                            ...requestRouteConfig.map((config) => {
                                return {
                                    path: `/${location}/${config.path}`,
                                    caption: config.caption,
                                };
                            }),
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Bookings',
            submenus: [
                // {
                //   path: '/manage-bookings',
                //   caption: 'All Bookings'
                // },

                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/all-bookings`,
                                caption: 'All Bookings',
                            },

                            {
                                path: `/${location}/pending-bookings`,
                                caption: 'Pending Bookings',
                            },

                            {
                                path: `/${location}/billing-done`,
                                caption: 'Pending for Delivery',
                            },

                            {
                                path: `/${location}/delivery-done`,
                                caption: 'Delivered Bookings',
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Bulk KEC Change',
            path: '/bulk-kec-change',
        },

        {
            caption: 'Allocations',
            path: '/admin-stock',
        },

        {
            caption: 'Sales Report',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Vehicle Bills',
                                path: `/${location}/basic/bills`,
                            },
                            {
                                caption: 'Accessories Bills',
                                path: `/${location}/Accessories/bills`,
                            },
                            {
                                caption: 'VAS Bills',
                                path: `/${location}/VAS/bills`,
                            },
                            {
                                caption: 'After Market Bills',
                                path: `/${location}/After Market/bills`,
                            },
                        ],
                    };
                }),
            ],
        },


        {
            caption: 'Matching Summary',
            path: '/matching-summary',
        },

        {
            caption: 'Item-wise GST Report',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Accessories ',
                                path: `/${location}/Accessories/items`,
                            },
                            {
                                caption: 'VAS ',
                                path: `/${location}/VAS/items`,
                            },
                            {
                                caption: 'After Market ',
                                path: `/${location}/After Market/items`,
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Stock',
            path: '/actual-stock',
        },

        // {
        //     caption: 'Vehicles',
        //     submenus: user.locations.map((location) => {
        //         return {
        //             path: `/${location}/vehicles`,
        //             caption: `${location}`,
        //         };
        //     }),
        // },

        // {
        //     caption: 'Payouts',
        //     submenus: [
        //         {
        //             caption: 'From Finance',
        //             submenus: [
        //                 ...user.locations.map((location) => {
        //                     return {
        //                         caption: location,
        //                         path: `/${location}/finance-payout`,
        //                     };
        //                 }),
        //             ],
        //         },
        //     ],
        // },

        {
            caption: 'Accessories',
            submenus: user.locations.map((location) => {
                return {
                    path: `/${location}/accessories`,
                    caption: `${location}`,
                };
            }),
        },

        {
            caption: 'Employees',
            path: '/employees',
        },

        {
            caption: 'Summary',
            submenus: [
                {
                    path: '/if-status',
                    caption: 'If Status',
                },
            ],
        },

        // {
        //     caption: 'High Priority',
        //     submenus: [
        //         {
        //             path: '/high-priority',
        //             caption: 'High Priority Requests',
        //         },
        //     ],
        // },

        // {
        //   caption: "Requests",
        //   path: '/manage-requests',
        // },

        {
            caption: 'Allocations',
            path: '/allocation',
        },

        // {
        //     caption: 'Approvals',
        //     submenus: [
        //         // {
        //         //   path: '/allocation-list',
        //         //   caption: 'DMS Allocation Requests'
        //         // },

        //         ...adminSteps.filter((step) => !step.isForm),
        //     ],
        // },
        {
            caption: 'Settings',
            submenus: [
                {
                    path: '/common/users',
                    caption: 'Manage Users',
                },
            ],
        },

        // {
        //     caption: 'Manage',
        //     submenus: [
        //         {
        //             caption: 'Receipts',
        //             submenus: user.locations.map((location) => {
        //                 return {
        //                     path: `/${location}/receipts`,
        //                     caption: `${location}`,
        //                 };
        //             }),
        //         },
        //     ],
        // },

        // {
        //     caption: 'Import',
        //     submenus: [
        //         {
        //             path: '/manage-stock',
        //             caption: 'Manage Stock',
        //         },
        //         {
        //             path: '/manage-receipts',
        //             caption: 'Manage Receipts',
        //         },
        //         // {
        //         //     path: '/if-status',
        //         //     caption: 'If Status',
        //         // },
        //     ],
        // },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => {
                            return (
                                <>
                                    <NoticeBoard />

                                    {/* <ReconcileOverview /> */}

                                    {/* <PendingRequestsRM /> */}

                                    <Dashboard steps={rmSteps} />

                                    {/* <Tabs
                                        defaultActiveKey="0"
                                        type="card"
                                        size={'small'}
                                    >
                                        <TabPane tab="Todays Review" key="0">
                                            <DailyDashboard />
                                        </TabPane>
                                        <TabPane tab="Pending Cases" key="1">
                                            <RequestDashboard />
                                        </TabPane>
                                    </Tabs> */}

                                    <Divider />

                                    {/* <RequestDashboard /> */}

                                    {/* <TaskOverview steps={adminSteps} /> */}
                                </>
                            );
                        }}
                    ></Route>
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    <PrivateRoute exact path="/daily-overview" component={DailyOverview} userRole={'admin'} />

                    <PrivateRoute exact path="/actual-stock" component={ActualStock} userRole={'admin'} />

                    <Route path="/allocation" component={StockDashboard} userRole={'admin'} />

                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-bookings" component={ManageBookings} userRole={'admin'} />
                    <PrivateRoute exact path="/manage-requests" component={ManageRequests} userRole={'admin'} />

                    <Route exact path={`/booking-tracker`} component={BookingTracker} />

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    <PrivateRoute path="/matching-summary" component={MatchingDashboard} userRole={'admin'} />

                    <Route path={'/employees'} render={(routeParams) => <ModuleRoutesLatest {...routeParams} model={Employees} />} />


                    <Route
                        path="/:city/vehicles"
                        component={() => <ModuleRoutes model="vehicles" schema={stockSchema} columns={stockColumns} collection="vehicles" />}
                    />


                    {/* vehicle overview */}
                    <Route exact path={`/vehicle-overview`} component={(...routeParams) => <VehicleOverview {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    {/* accessory overview */}
                    <Route exact path={`/accessory-overview`} component={(...routeParams) => <AccessoryOverview {...routeParams} />} />
                    {/* accessory overview Ends */}

                    {/* finance tracker */}
                    <Route exact path={`/finance-tracker`} component={(...routeParams) => <FinanceTracker {...routeParams} />} />
                    {/* finance tracker Ends */}

                    <PrivateRoute exact path="/accounts-overview" component={AccountsOverview} userRole={'admin'} />

                    <Route exact path={'/monthwise-retails'} component={MonthWiseRetail}></Route>

                    <Route exact path={'/enquiry-report'} component={enquiryReport}></Route>

                    <Route exact path={'/booking-report'} component={BookingReport}></Route>

                    <Route exact path={`/:city/:category/bills`} component={(...routeParams) => <SalesReport {...routeParams} />} />

                    <Route path="/admin-stock" component={StockDashboard} userRole={'admin'} />

                    <Route
                        exact
                        path={`/:city/:category/items`}
                        component={(...routeParams) => (
                            <BillList schema={ItemWiseSchema} actions={itemActions} {...routeParams} dateField={'created_date'} />
                        )}
                    />

                    {/* Finance Payout */}

                    <Route
                        exact
                        path={`/:city/finance-payout`}
                        component={(...routeParams) => <GenericList schema={payoutSchema} queries={[]} {...routeParams} />}
                    />

                    {/* Finance Payout Ends */}

                    <PrivateRoute exact path="/booking-overview" component={BookingOverview} userRole={'admin'} />

                    <Route
                        exact
                        path="/:category/bank-receipts"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <ManagePayments {...routeParams} />;
                        }}
                    />

                    <PrivateRoute exact path="/pending-summary" component={ReconcileSummary} userRole={'admin'} />

                    {/* Manage Users */}
                    <Route
                        path="/common/users"
                        component={(routeParams) => (
                            <ModuleRoutes model="users" {...routeParams} schema={userSchema} columns={userSchema.columns} collection="users" />
                        )}
                    />
                    {/* Manage Users Ends */}

                    <PrivateRoute exact path="/if-status" component={IFStatus} userRole={'admin'} />

                    {/* Customer Receipts Module */}
                    <PrivateRoute exact path="/customer-receipts" component={CustomerReceipts} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'admin'} />
                    <Route
                        exact
                        path="/:category/customer-receipts"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <CustomerReceipts {...routeParams} />;
                        }}
                    />

                    <PrivateRoute exact path="/customer-receipts/add" component={CustomerReceiptAdd} userRole={'admin'} />
                    {/* Customer Receipts Module Ends */}

                    {/* Cancellations Module */}
                    <PrivateRoute exact path="/cancellations" component={Cancellations} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />
                    {/* Cancellations Module ends */}

                    {/* High Priority */}
                    <PrivateRoute exact path="/high-priority" component={HighPriority} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/high-priority/:id" component={HighPriorityDetail} userRole={'admin'} />
                    {/* High Priority Ends */}
                    {/* Receipts */}
                    <Route
                        path="/:city/receipts"
                        component={() => <ModuleRoutes model="receipts" schema={receiptsSchema} columns={receiptsSchema.columns} />}
                    />
                    {/* Receipts Ends */}

                    <Route
                        path="/:city/accessories"
                        component={() => <ModuleRoutes model="accessories" schema={partsSchema} columns={partsColumns} collection="parts" />}
                    />


                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />

                    {/* Bulk KEC Change */}
                    <Route exact path={`/bulk-kec-change`} component={BulkKecChange} />
                    {/* Bulk KEC Change Ends */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={bookingSchema}
                                queries={bookingSchema.pendingBookings}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingColumns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => (
                            <GenericList
                                queries={bookingSchema.billingQueries}
                                schema={bookingSchema}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingColumns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList
                                queries={bookingSchema.deliveryQueries}
                                schema={bookingSchema}
                                disableUpload={true}
                                {...routeParams}
                                model={'bookings'}
                                columns={bookingColumns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Delivery Bookings Ends */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/all-bookings`}
                        component={(...routeParams) => <GenericList dateField={'bookingDate'} schema={bookingSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Bookings Ends */}

                    <Route
                        exact
                        path={`/:city/all-requests`}
                        component={(...routeParams) => <GenericList schema={requestSchema} queries={[]} {...routeParams} />}
                    />

                    <Route
                        exact
                        path="/:category/accounts-overview"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <AccountsOverview {...routeParams} />;
                        }}
                    />

                    {/* Common Routes for Requests */}
                    {requestRouteConfig.map((config) => {
                        return (
                            <Route
                                exact
                                path={`/:city/${config.path}`}
                                component={(...routeParams) => <GenericList schema={requestSchema} queries={config.queries} {...routeParams} />}
                            />
                        );
                    })}
                    {/* Common Routes for Requests Ends */}

                    <Route
                        exact
                        path="/:category/pending-summary"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <ReconcileSummary {...routeParams} />;
                        }}
                    />

                    <PrivateRoute exact path="/verify-payment" component={VerifyPayment} userRole={'admin'} />


                    {/* Active Requests */}
                    <Route
                        exact
                        path={`/:city/requests`}
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                columns={requestSchema.columns}
                                queries={requestSchema.queries}
                                isDirect={true}
                                {...routeParams}
                                model={'requests'}
                                // columns={requestColumns}
                                collection={'list'}
                            />
                        )}
                    />
                    {/* Active Requests Ends */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* Status Requests */}

                    <Route
                        exact
                        path="/:city/request"
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                queries={[
                                    {
                                        field: tagToSearch,
                                        value: statusToSearch.replace(/-/g, ' '),
                                    },
                                ]}
                                {...routeParams}
                            />
                        )}
                    />

                    {/* Status Requests Ends */}

                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={rmSteps} list={ApprovalList} form={ApprovalForm} />

                </Switch>
            </GlobalHeader>
        </section>
    );
}
