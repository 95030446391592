/**
 * Booking Status Modal Component
 *
 * @description Component to update booking current status value when unblocking
 * @author Nihala Dilshi
 */

import React, { useEffect, useState, useContext } from 'react';

import { Typography, Button, Form, message, Alert, Select, Input } from 'antd';

import { Bookings, StatusMasters } from '../../../../models';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

const { Title, Text } = Typography;


const { TextArea } = Input;

const { Option } = Select;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};



export function BookingStatusModal({ id, callback }) {

    const { user } = useContext(GlobalContext);

    const [status, setStatus] = useState([])



    useEffect(() => {
        getBookingStatus();
    }, []);

    // Get booking status from status master
    async function getBookingStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'BOOKING',
            },
        ];

        const result = await StatusMasters.get(queries);
        setStatus(result.status_masters || []);
    }
    /**
     *
     *
     * @param {*} values
     */
    const onDataCollection = (values) => {
        let status_name = status.filter((record) => record.id === values.currentStatus);
        Bookings.getRecord(id).then((result) => {

            let params = {
                current: {
                    ...result.current,
                    currentStatus: status_name[0].name,
                    currentStatus_id: status_name[0].id,
                    remarks: values.remarks,
                },
            };

            FirebaseUtils.updateBookingGeneric(id, params, 'Updated booking status', values.remarks).then(() => {
                message.success('The booking status has been updated');
                callback();
            });
        })
    };

    return (
        <div className="vehicle-card card">
            <Title level={3}>Booking Status</Title>

            <Form
                {...layout}
                name="new-record"
                onFinish={onDataCollection}
                layout="vertical"
                initialValues={{}}            >
                <Form.Item name={'currentStatus'} label={'Current Status'} rules={[{ required: true, message: 'Please mention status' }]}>
                    <Select placeholder="Please update the current status">
                        {status.map((data, key) => (
                            <Option value={data.id}>{data.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name={'remarks'}
                    label="Remarks"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter remarks',
                        },
                    ]}
                >
                    <TextArea rows={4} />
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                >
                    SUBMIT
                </Button>
            </Form>
        </div>
    );
}
