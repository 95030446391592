import Base from './base';

class FinancialYear extends Base {
    
    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'financial_years';
    }

    get path() {
        return `financial_years`;
    }
}

export default FinancialYear;
