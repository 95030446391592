import React, { useState, useEffect } from 'react';

import { Row, message, Button, Typography, Tabs } from 'antd';

import PaymentCard from './payment-card';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import './payment-requests.scss';

import { GetData, PostData } from '../../../../utils/http.utils';

const { TabPane } = Tabs;

const { Title } = Typography;

export default function PaymentRequests({ schema, match, ...props }) {

    const { city } = match.params;

    return (
        <section className="landing">
            <Title level={4}>Payment Requests</Title>

            <Tabs defaultActiveKey={city} onChange={() => { }}>
                {[
                    { name: 'Kozhikode', location: 'clt' },
                    { name: 'Malappuram', location: 'mpm' },
                    { name: 'Kannur', location: 'knr' },
                    // { name: 'Kondotty', location: 'kty' },
                    // { name: 'Tirur', location: 'trr' },
                    // { name: 'Vadakara', location: 'vdk' },
                    // { name: 'Kasargod', location: 'ksd' },
                ].map((location, key) => {
                    return (
                        <TabPane tab={location.name} key={location.name.toLowerCase()}>
                            <PendingRequests {...props} schema={schema} location={location} />
                        </TabPane>
                    );
                })}
            </Tabs>
        </section>
    );
}

/**
 *
 *
 * @param {*} param0
 * @returns
 */
function PendingRequests({ location, schema, ...props }) {
    const [loader, setLoader] = useState(true);

    const [records, setRecords] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    /**
     * Callback action for Click of Button
     *
     * @param {*} record
     * @param {*} status
     * @returns
     */
    function callback(record, status) {
        var params = {
            approve: status === 'approved' ? true : false,
        };

        return PostData({ url: schema.form.url.replace(':id', record.ver_id).replace(':city', location.location), formBody: params }).then(
            (result) => {
                if (result.ok) {
                    message.success(`Operation Successfull`);

                    getData();
                } else {
                    if (result.statusCode === 400) {
                        message.error(`${result.message}`);
                    } else {
                        message.success(`Some issue`);
                    }
                }
            }
        );
    }

    /**
     * Get the data
     */
    function getData() {
        setLoader(true);

        GetData({ url: schema.endPoint.replace(':city', location.location) + schema.queryParams + '&page=1&limit=50' }).then((result) => {
            // Group By Voucher No
            var v = groupBy(result, 'ver_vno');

            var arr = [];

            // Iterate to form data
            Object.keys(v).forEach((v_no) => {
                var vouchers = v[v_no];

                // Find the bank record
                var bank_record = vouchers.filter((r) => r.ver_totrow === 'Y').pop();

                vouchers.forEach((rec) => {
                    if (rec.ver_totrow !== 'Y' && rec.ver_amt > 0) {
                        arr.push({
                            ...rec,
                            bank_record,
                        });
                    }
                });
            });

            // setRecords(result);
            setRecords(arr);

            setLoader(false);
        });
    }

    return (
        <>
            <div className="list-header">
                <p className="page-desc">{loader ? 'Loading data' : `Found ${records.length} results`}</p>

                <div>
                    <Button
                        onClick={() => {
                            getData();
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>
                </div>
            </div>

            <Row className="report-listing-wrapper">
                {loader ? (
                    <PlaceHolder type="listing" />
                ) : (
                    <>
                        {records.map((report, index) => {
                            return (
                                <PaymentCard {...props} location={location} record={report} index={report.index} key={index} callback={callback} />
                            );
                        })}
                    </>
                )}
            </Row>
        </>
    );
}
