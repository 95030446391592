/**
 * All the date related activities should happen here 
 */

import moment from 'moment-timezone';


import firebase from 'firebase/compat/app';

/**
 * 
 */

let dateFormat = 'DD/MM/YYYY';

let timezone = 'Asia/Kolkata';


//Get Firebase Timestamp of the moment date
const Timestamp= firebase.firestore.Timestamp


export default class DateUtils {

    static today = () => {
        return moment.tz(timezone).valueOf();
    };

    static getCreation = (field) => {

        let creation = {
            [field + '_at']: moment.tz(timezone).format('DD/MM/YYYY HH:mm'),
            [field + '_date']: moment.tz(timezone).startOf('day').valueOf(),
            [field + '_time']: moment.tz(timezone).valueOf(),
        };

        return {
            ...creation
        }
    }

    static formatDate = (value) => {
        return moment(value).tz(timezone).format(dateFormat)
    }


    static surgeTime = (time) => {

        return moment(time, "YYYY-MM-DDTHH:mm:ss.SSSSZ").utc().format('DD/MM/YYYY HH:mm a')
    }

    static getFirebaseTimestamp=(value)=>{

        var timeStamp

            if (moment.isMoment(value)) {

                let unix = moment(value).valueOf();
    
                timeStamp=new Timestamp(unix / 1000, 1000)
    
            } else {
    
                let unix = moment(value, 'DD/MM/YYYY HH:mm a').valueOf();
    
                timeStamp= new Timestamp(unix / 1000, 1000)
    
            }

            return timeStamp
    }

}