import React, { useState, useContext } from "react"

import { withRouter } from 'react-router-dom'

import { GlobalContext } from './../../Store';

import { Typography } from 'antd';

var logo = require('./../../assets/images/kia-logo.png');

const { Title } = Typography;

function AboutComponent({ history }) {

    const { user, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    return (
        <section className="about-component">
            <div className="about-section">

                <div className="brand-logo">
                    <img className="logo-welcome" src={logo} alt="Logo" />
                </div>

                <div className="content-section" style={{ margin: '50px' }}>

                    <Title level={3}>
                        About Us
                </Title>

                    <p>
                        The DKH Kia Application intends to support the day to day activity of all employees by maintaining a transparent unified solution that every member in the organization can refer to .
                        The application would strive to come up with advanced and innovative solutions to support each individual so as to streamline and reduce the workload that would otherwise take stressfull amount of man hours.
                        Please write to us at it@dkhkia.com with suggestions and feedbacks on the application or how we would could improve your workplace for a happy and contentful time at DKH Kia.
                </p>

                </div>




            </div>
        </section>
    );
}

export default withRouter(AboutComponent)