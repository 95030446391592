/**
 *
 * @description
 * This Component is used to view Gate pass
 *
 */

import React, { useState, useEffect, useRef } from 'react';

import { Button, Row, Form, Col, Card } from 'antd';

import { StockTransfers, Vehicles } from '../../models';

import QRCode from 'react-qr-code';

import './gate-pass.scss';

import { useReactToPrint } from 'react-to-print';

import PlaceHolder from '../ui_elements/PlaceHolder';
import FirebaseUtils from '../../utils/firebase.utils';

const formItemLayout = {
    layout: 'vertical',
};

export default function GatePassModal({ params, vehicle, stockTransferId }) {
    const [stocktransfers, setStockTransfers] = useState([]);

    const [loading, setloading] = useState(false);

    // Get vehicle details if it is not present
    const [vehicleDetails, setVehicleDetails] = useState({});

    useEffect(() => {
        // listing stock pointers
        getStockTransferDetails(params);
        if (vehicle) {
            setVehicleDetails(vehicle);
        } else {
            // Get vehicle details if it is not present
            getVehicle(params);
            // return () => {
            //     setVehicleDetails({}); // This worked for me
            // };
        }
    }, []);

    /**
     *
     * Print QR code
     */
    const print = useRef();

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'wrist-band-body',
    });

    /**
     * Get vehicle details
     * @param {*} id
     */
    async function getVehicle(id) {
        const vehicleRecord = await Vehicles.getRecord(id);

        setVehicleDetails(vehicleRecord);
    }

    /**
     * Get stocktransfer deatils using vino
     */
    async function getStockTransferDetails() {
        setloading(true);

        var result;
        // get stockpoint record. it get using from_yard id
        result = await StockTransfers.getRecordStock(stockTransferId);
        console.log(result);
        setStockTransfers(result);

        setloading(false);

        // var app = FirebaseUtils.getApp();

        // var batch = app.batch();

        // let value = null;

        // return StockTransfers.createStatusLog('STCKREQUESTED', result, batch, value).then((res) => {
        //     batch.commit().then(() => {
        //         // message.success('Request For Stock Transfer Created');
        //     });
        // });
        // });
    }

    return (
        <div>
            {loading ? (
                <PlaceHolder type="report" />
            ) : (
                <Card className="stock card" ref={print}>
                    <div className="stock-transfer-details">
                        <Form {...formItemLayout}>
                            <Row className="print-row" gutter={20}>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="VinNO">
                                        <strong>{stocktransfers.vinNo}</strong>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="Dealer Code">
                                        <strong>{vehicleDetails['dealerCode']}</strong>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    {/** Print Block start  */}
                                    <WrapperClass>
                                        <div style={{ height: 'auto', maxWidth: 64, width: '100%', marginRight: '200px' }}>
                                            <QRCode
                                                name="qr-code"
                                                size={256}
                                                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                                value={`${stocktransfers.id}`}
                                                viewBox={`0 0 256 256`}
                                                imageSettings={{ width: 50 }}
                                            />
                                        </div>
                                    </WrapperClass>
                                </Col>
                                {/** Print Block end   */}
                            </Row>
                            <Row className="print-row" gutter={20}>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="Model">
                                        <strong>{vehicleDetails['Model']}</strong>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="Variant">
                                        <strong>{vehicleDetails['Variant']}</strong>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="Color">
                                        <strong>{vehicleDetails['Exterior Color Name']}</strong>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="print-row" gutter={20}>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="From ">
                                        <strong>{stocktransfers.location_from_name}</strong>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="From Yard">
                                        <strong>{stocktransfers.from_yard_name}</strong>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="To">
                                        <strong>{stocktransfers.location_to_name}</strong>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="To Yard">
                                        <strong>{stocktransfers.to_yard_name}</strong>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="print-row" gutter={20}>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="Driver ">
                                        <strong>{stocktransfers.staff_name}</strong>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item label="Remarks">
                                        <strong>{stocktransfers.remarks}</strong>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button htmlType="submit" onClick={handlePrint} className='hide-on-print'>
                                    {' '}
                                    Print Gatepass
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Card>
            )}
        </div>
    );
}
/**
 *
 */
class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}
