import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';

import { Typography, Tabs, Button, Input, Alert, Tag, Modal } from 'antd';
import { StockPoints, StockTransfers } from '../../../../models';

const { Title, Text } = Typography;

/**
 *
 * Vehicles List
 */

export default function VehicleSelection({ vehicles = [], callback }) {
    const [selected, setSelected] = useState();

    const selectVehicle = async (vehicle, key) => {
        console.log(vehicle, key);

        // Check if vehicle is present at any stocklocation with same dealer code 
        if (vehicle.stock_point_id) {
            var result = await StockPoints.getRecord(vehicle.stock_point_id)
            console.log(result)

            if (vehicle.dealerCode != result.dealerCode) {
                let content = 'Vehicle should be present at a yard within the same dealer code.'
                showWarningModal(content)

            }
            else {
                setSelected(vehicle);
            }
        }

        // Check if any virtual stock transfer is initiated
        var query = [
            {
                field: "vinNo",
                value: vehicle.id
            },
            {
                field: "sub_mode",
                value: "virtual"
            },
            {
                field: "active",
                value: true
            }
        ]

        StockTransfers.get(query).then((result) => {

            if (result.stock_transfers.length) {
                // If the active virtual stock transfer status is not done, then billing not allowed

                const isDoneStatus = result.stock_transfers.some(obj => obj.status === 'done');

                if (isDoneStatus) {
                    setSelected(vehicle);
                } else {
                    let content = 'Virtual stock transfer request is not complete for this vehicle'
                    showWarningModal(content)

                }
            } else {
                setSelected(vehicle);

            }
        })
        // callback(selected);
    };

    function showWarningModal(content) {
        Modal.warning({
            title: 'Sorry! Can not raise invoice for this vehicle',
            content: content,
            okText: 'Ok',
            onOk() {
                // history.goBack();
            },
        });
    }
    console.log(selected);

    return (
        <div className="accessory-selection">
            <div className="split-list">
                <div
                    style={{
                        width: '100%',
                        border: '1px solid #f0f0f0',
                        padding: '16px',
                        height: 'fit-content',
                    }}
                >
                    <div
                        style={{
                            borderBottom: '1px solid #f0f0f0',
                            paddingBottom: '10px',
                        }}
                    >
                        <div style={{ fontWeight: 'bold', fontSize: '17px' }}>VEHICLE</div>
                    </div>

                    <div
                        style={{
                            borderTop: '1px solid #f0f0f0',
                            paddingTop: '10px',
                            marginTop: '10px',
                        }}
                    >
                        {selected ? (
                            <div>
                                <b>
                                    {' '}
                                    <Title level={5}>{selected['Variant']}</Title>
                                    <p>{selected['Exterior Color Name']}</p>
                                    <p style={{ color: 'red' }}>{selected['Vin Number']}</p>
                                </b>
                            </div>
                        ) : null}
                        <Button
                            type="dashed"
                            style={{ alignSelf: 'flex-end' }}
                            onClick={() => {
                                callback(selected);
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </div>

                <div>
                    {vehicles &&
                        vehicles.map((vehicle, key) => {
                            return (
                                <div key={key} className="item-card" style={{ padding: '16px', display: 'flex', justifyContent: 'space-between' }}>
                                    <div
                                        style={{
                                            paddingTop: '5px',
                                        }}
                                    >
                                        <Title level={5}>{vehicle['Variant']}</Title>
                                        <p>{vehicle['Exterior Color Name']}</p>
                                        <p style={{ color: 'red' }}>{vehicle['Vin Number']}</p>
                                    </div>

                                    <div>
                                        <Button
                                            type="dashed"
                                            style={{
                                                alignSelf: 'flex-end',
                                            }}
                                            onClick={() => {
                                                selectVehicle(vehicle, key);
                                            }}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
