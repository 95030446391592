import React, { useEffect, useState, useContext, useRef } from 'react';

import { Typography, Button, Skeleton, Input, Radio, Row, Col, Space, Alert, Form, Table, Popover, message, Badge, Modal, Popconfirm } from 'antd';

import FirebaseUtils from '../../utils/firebase.utils';

import './invoice-preview.scss';

import { useHistory } from 'react-router-dom';

import { Cart, InvoiceNumbers, Bills, BillDetails, Vehicles, Branches, Settings, Requests } from './../../models/';

import { DateDisplay } from './../../components/';

import { GlobalContext } from './../../Store';

import { useReactToPrint } from 'react-to-print';

import moment from 'moment';

import BillDetail from '../../modules/common/resources/finance-payout-tracker/bill-details';

// import TextArea from 'rc-textarea';

const { Title, Text } = Typography;

const { TextArea } = Input;

var companyLogo = require('../../assets/images/kia-logo.png');

export default function InvoicePreview ({ request, booking, printable = false }) {

    const [ loading, setLoading ] = useState(false);

    const { user } = useContext(GlobalContext);

    const [ bill, setBill ] = useState({});

    const [record, setRecord] = useState({ created_by: {}, attachments: [] });

    const [branch, setBranch] = useState();

    const [data, setData] = useState({ items: [] });

    const [vehicle, setVehicle] = useState({});

    const [lastNumber, setLastNumber] = useState({});

    const [matching, setMatching] = useState([]);

    const [cancelDay, setCancelDay] = useState();

    const [daydiff, setDayDiff] = useState();

    const [visible, setVisible] = useState(false);

    const print = useRef();

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    let history = useHistory();

    useEffect(() => {
        setLoading(true);

        loadCancelDay();

        if (request.actual_billing.status === 'Invoiced') {
            loadBillData();
        } else {
            getInvoiceNumber();

            prepareBill();
        }

        loadBranch();

        findingDayDifference();

        return () => {};
    }, []);

    //For brach details to add invoice
    const loadBranch = () => {
        var queries = [
            {
                field: 'dealer_code',
                value: request.dealerCode,
            },
        ];
        Branches.get(queries).then((result) => {
            setBranch(result.branches);
        });
    };

    function loadCancelDay() {
        let query = [
            {
                field: 'name',
                value: 'vas-billing-cancellation-days',
            },
        ];
        Settings.get(query).then((result) => {
            setCancelDay(result.settings[0].value);
        });
    }

    function loadBillData() {
        var queries = [
            {
                field: 'bookingNo',
                value: request.bookingNo,
            },
            {
                field: 'type',
                value: 'basic',
            },
            {
                field: 'cancellation_status',
                value: false,
            },
        ];

        Bills.get(queries).then((result) => {
            var vehicle_bill = result.bills[0];
            setBill(vehicle_bill || {});

            if (vehicle_bill && vehicle_bill.id) {
                var bill_id = vehicle_bill.id;
                console.log(bill_id);
                BillDetails.getBillItems({ bill_id }).then((result) => {
                    console.log(result);
                    setData(result);

                    getVehicle().then(() => {
                        setLoading(false);
                    });
                });
                Bills.getRecord(bill_id).then((result) => {
                    console.log(result);
                    // setMatching(result);
                    var query = [
                        {
                            field: 'bookingNo',
                            value: result.bookingNo,
                        },
                        {
                            field: 'type',
                            value: 'basic',
                        },
                    ];
                    Cart.get(query).then((result) => {
                        setMatching(result);
                    });
                });
            } else {
                setLoading(false);
            }
        });
    }

    function getInvoiceNumber() {
        // setInvoiceLoading(true);
        InvoiceNumbers.getNextNumber({ location: user.locations[0], category: 'basic', date: moment().valueOf() }).then((res) => {
            let id = res.prefix + (res.last_number + 1) + res.post_fix;

            setLastNumber({ id: res.id, lastNumber: id, numberOnly: res.last_number + 1 });
        });
    }

    function prepareBill() {
        return Cart.getVehicleInvoiceData(request).then((result) => {
            //  console.log(result[0]);
            var res = result;

            setData(res);

            return getVehicle().then(() => {
                setLoading(false);
            });
        });
    }

    const handleModal = () => {
        setVisible(true);
    };

    const onFinish = (values) => {
        cancelInvoice(values);
        setVisible(false);
    };

    const findingDayDifference = () => {
        var td = moment().format('DD/MM/YYYY');
        var id = moment(bill.created_date).format('DD/MM/YYYY');
        var invoice = moment(id, 'DD/MM/YYYY');
        var today = moment(td, 'DD/MM/YYYY');

        var result = today.diff(invoice, 'days');

        setDayDiff(result);
        console.log(result);
    };

    /**
     * Load the vehicle
     */
    function getVehicle() {
        // Load the vehicle
        let vinNo = request.allocation.vinNo;

        return Vehicles.getRecord(vinNo).then((result) => {
            setVehicle(result);

            console.log(result);

            return vehicle;
        });
    }
    const cancelInvoice = (value) => {
        let invoice = bill.invoice_number;

        let cancelValues = {
            ...value,
            user: user.name,
            branch_id: user.branch.id,
            bill_id: bill.id,
        };

        let values = {
            ...value,
            ...bill,
            cancellation_status: true,
        };

        return FirebaseUtils.setRecord('cancellations', cancelValues)

            .then((result) => {
                values.cancellation_id = result;
                return FirebaseUtils.updateRecord('bills', bill.id, values);
            })
            .then((result) => {
                let bill_id = values.id;

                var arr = [];

                var query = [
                    {
                        field: 'bill_id',
                        value: bill_id,
                    },
                ];
                BillDetails.get(query)
                    .then((detail) => {
                        let det = detail['bill-details'];

                        arr.push(det[0]);

                        return arr;
                    })
                    .then((arr) => {
                        arr.map((item) => {
                            let params = {
                                ...item,
                                cancellation_status: true,
                            };

                            return FirebaseUtils.updateRecord('bill-details', item.id, params);
                        });
                    });
            })
            .then(async (result) => {
                let config = {
                    hideAlert: true,
                };

                matching.cart[0].subItems.forEach((item) => {
                    if (['Ex Showroom Price'].indexOf(item.description) !== -1) {
                        item.status = 'active';

                        return item;
                    }
                });

                let cart = {
                    ...matching.cart[0],
                    status: 'active',
                };
                let actualBilling = {
                    ...request.actual_billing,
                    status: 'requested',
                };
                // Changing Cart status
                await Cart.update(matching.cart[0].id, cart, { hideAlert: true });
                // updating vehicle status
                await Vehicles.update(vehicle['Vin Number'], { update: 'status', status: 'completed' }, { hideAlert: true });
                // updating actual_billing status
                await Requests.update(request.id, { actual_billing: actualBilling }, { hideAlert: true });
            })
            .then(() => {
                message.success(`Invoice ${invoice} has cancelled successfully. `);
                history.goBack();
            });
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Cancel Invoice"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Form name="basic" onFinish={onFinish}>
                    <Form.Item
                        name="remarks"
                        PlaceHolder="Enter remarks"
                        label="Remarks"
                        rules={[{ required: true, message: 'Please enter remarks!' }]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <div>
                    {printable ? (
                        bill.cancellation_status !== true ? (
                            <Button type="dashed" className="print-button" onClick={handlePrint}>
                                Print Invoice
                            </Button>
                        ) : null
                    ) : null}
                </div>

                {daydiff <= cancelDay && (user.role === 'admin'||user.role==='management') && bill.cancellation_status !== true ? (
                    <div>
                        <Button type="dashed" className="print-button" onClick={handleModal}>
                            Cancel Invoice
                        </Button>
                    </div>
                ) : bill.cancellation_status === true ? (
                    <Text type="danger">Cancelled Invoice</Text>
                ) : null}
            </div>

            <div className={`vehicle-invoice ${printable ? 'scale' : ''}`} ref={print}>
                {loading ? (
                    <Skeleton />
                ) : (
                    <>
                        {bill.invoice_number || lastNumber.id ? (
                            <>
                                <>
                                    <div className="bill-header">
                                        <img className="logo-welcome" src={companyLogo} alt="Logo" />
                                        <div>
                                            <p>{branch && branch[0].address_line_1}</p>
                                            <p>{branch && branch[0].address_line_2}</p>
                                            <p>{branch && branch[0].state}</p>
                                            <p>{branch && branch[0].postal_code}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <Title className="headline">Vehicle Tax Invoice</Title>
                                    <div className="bill-details">
                                        <div className="left">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="detail-element">Dealer GST No </td>
                                                        <td>{branch && branch[0].gst_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Bill To Name</td>
                                                        <td style={{ textTransform: 'uppercase' }}>:{bill.customer_name ? bill.customer_name : booking['Bill Name']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Bill To Address</td>
                                                        <td style={{ textTransform: 'uppercase' }}>:{bill.customer_address ? bill.customer_address : booking['Bill To Add']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Ship To Name</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.ship_to_name ? bill.ship_to_name : booking['Bill Name']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Ship To Address</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.ship_to_address ? bill.ship_to_address : booking['Bill To Add']}</td>
                                                    </tr>
                                                    {booking['Ship To Pin No.'] ? (
                                                        <tr>
                                                            <td className="detail-element">Ship To Postal Code</td>
                                                            <td>: {bill.ship_to_postal_code}</td>
                                                        </tr>
                                                    ) : null}
                                                    <tr>
                                                        <td className="detail-element">Financed by/Hypo. by</td>
                                                        <td>: {request.finance && request.finance.bank}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="right">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="detail-element">Customer GST No </td>
                                                        <td>{bill.gst_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Customer Id</td>
                                                        <td>: {booking['Customer ID']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Invoice No</td>
                                                        <td>: {bill.invoice_number ? bill.invoice_number : lastNumber.lastNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Invoice Date </td>
                                                        <td>
                                                            : <DateDisplay value={bill.invoice_date_millisecond} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Phone No</td>
                                                        <td>: {booking['Contact Number']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">PAN No</td>
                                                        <td>: {booking['PAN No'] || bill.pan_number} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Place of Supply</td>
                                                        <td>: {bill.customer_state}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <table className="bill-content">
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                <th className="title text-left">PARTICULARS</th>
                                                <th className="title amount">AMOUNT(Rs)</th>
                                            </tr>

                                            {/* Basic Vehicle Price */}

                                            {data.items.map((record, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}.</td>

                                                        <td>{record.description}</td>

                                                        <td className="amount">{record.value}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <table className="bill-summary">
                                        <tbody>
                                            <tr>
                                                <td className="title text-left">TOTAL</td>
                                                <td className="title text-right">{data.net_amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="title text-left">TCS @1%</td>
                                                <td className="title text-right">{data.tcs_amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="title text-left">ROUND OFF</td>
                                                <td className="title text-right">{data.roundAmount}</td>
                                            </tr>
                                            <tr>
                                                <td className="title text-left grand-title">GRAND TOTAL</td>
                                                <td className="title text-right grand-content">{data.grand_total}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="vehicle-particulars">
                                        <p>Vehicle Particulars</p>
                                        <table>
                                            <tbody>
                                                <td>
                                                    <div className="details-box">
                                                        <span>Vin No.</span>
                                                        <span>{vehicle['Vin Number']}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="details-box">
                                                        <span>Engine No.</span>
                                                        <span>{vehicle['Engine No']}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="details-box">
                                                        <span>Key No.</span>
                                                        <span>{vehicle['Key Number']}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="details-box">
                                                        <span>HSN No</span>
                                                        <span>{vehicle['HSN Code']}</span>
                                                    </div>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="footer">
                                        <p>"Terms & condition apply"</p>
                                        <h3>Applied Promotion:</h3>
                                        <div className="signature">
                                            <div className="">
                                                <div className="sign-box"></div>
                                                <h4>Signature of Customer</h4>
                                            </div>
                                            <div className="">
                                                <p>For DKH Motors LLP</p>
                                                <div className="sign-box"></div>
                                                <h4>Authorised Signatory</h4>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </>
                        ) : (
                            <p>Invoice unavailable.</p>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
