import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import { Statistic, Tag, Typography, Row, Col } from 'antd';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import './pending-requests.scss';

const { Title, Text } = Typography;

export default function PendingRequest(props) {
    const { user } = useContext(GlobalContext);

    const [currentLocation, setCurrentLocation] = useState(user.locations[0]);

    const [pendingRequestCount, setPendingRequestCount] = useState(0);

    const [allocatedRequestCount, setAllocatedRequestCount] = useState(0);

    const [pendingDeliveryCount, setPendingDeliveryCount] = useState(0);

    const [DeliveredCount, setDeliveredCount] = useState(0);

    useEffect(() => {
        getPending();
    }, []);

    function getPending() {
        var queries = [
            [
                {
                    field: props.userRole,
                    value: user.employee_id,
                },
                {
                    field: 'actual_billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value: 'removed'
                }
            ],
            [
                {
                    field: props.userRole,
                    value: user.employee_id,
                },
                {
                    field: 'delivery.status',
                    operator: 'in',
                    value: ['pending', 'requested', 'approved', 'Delivery Note Taken'],
                },
                {
                    field: 'status',
                    operator: '!=',
                    value: 'removed'
                }
            ],
            [
                {
                    field: props.userRole,
                    value: user.employee_id,
                },
                {
                    field: 'delivery.status',
                    operator: 'in',
                    value: ['Delivered', 'Delivery Completed'],
                },
                {
                    field: 'status',
                    operator: '!=',
                    value: 'removed'
                }
            ],

            [
                {
                    field: props.userRole,
                    value: user.employee_id,
                },
                {
                    field: 'allocation.status',
                    operator: 'in',
                    value: ['approved', 'Allocated'],
                },

                {
                    field: 'actual_billing.status',
                    // operator: '=',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value: 'removed'
                }
            ],



        ];

        //Pending allocations
        FirebaseUtils.getRecords(currentLocation, queries[0], 'requests').then((result) => {
            setPendingRequestCount(result.requests.length);
        });

        //Pending for delivery
        FirebaseUtils.getRecords(currentLocation, queries[1], 'requests').then((result) => {
            setPendingDeliveryCount(result.requests.length);
        });

        //Delivered requests
        FirebaseUtils.getRecords(currentLocation, queries[2], 'requests').then((result) => {
            setDeliveredCount(result.requests.length);
        });


        //Delivered requests
        FirebaseUtils.getRecords(currentLocation, queries[3], 'requests').then((result) => {
            setAllocatedRequestCount(result.requests.length);
        });
    }

    return (
        <div className="summary-content">
            <div className="list-header">
                <div>
                    <Title level={4}>Dashboard</Title>
                    <div className="pending-requests-container">
                        <div className="card dashboard-item">
                            <Statistic title="Pending Requests" value={pendingRequestCount} className="dashboard-card" />
                            {/* {{ pathname: `${currentLocation}/pending-request`, search: `?allocation.status=pending` }} */}
                            <Link to={`/${currentLocation}/pending-requests`}>View</Link>
                        </div>


                        <div className="card dashboard-item">
                            <Statistic title="Allocated Requests" value={allocatedRequestCount} className="dashboard-card" />
                            {/* {{ pathname: `${currentLocation}/pending-request`, search: `?allocation.status=pending` }} */}
                            <Link to={`/${currentLocation}/allocated-requests`}>View</Link>
                        </div>



                        <div className="card dashboard-item">
                            <Statistic title="Pending for Delivery" value={pendingDeliveryCount} className="dashboard-card" />
                            <Link to={`/${currentLocation}/billing-requests`}>View</Link>
                        </div>

                        <div className="card dashboard-item">
                            <Statistic title="Delivered Requests" value={DeliveredCount} className="dashboard-card" />
                            <Link to={`/${currentLocation}/delivered-requests`}>View</Link>
                        </div>

                        <div className="card dashboard-item">
                            <Statistic title="View Allocations" className="dashboard-card" />
                            <Link to={`/allocations`}>View</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
