/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext, Fragment } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Form, Button, Descriptions, Input, Switch, Space, Tag, Select, InputNumber, Radio, Row, Col, message, Skeleton } from 'antd';

import { withRouter, useHistory } from 'react-router-dom';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { MinusCircleOutlined, PlusOutlined, MinusCircleTwoTone } from '@ant-design/icons';

import useDeviceDetect from '../../../../hooks/device-detect';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import Rupee from '../../../../components/rupee/rupee';

import FormCreator from '../form-creator/form-creator';

import { BillDetails, Items, PackageDetails } from '../../../../models';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { Title, Text } = Typography;

const { TextArea, Number } = Input;

const { Option } = Select;

/**
 * Generic List
 *
 * @param {*} param0
 */
function EditPackage({ match }) {
    const [record, setRecord] = useState([]);

    const history = useHistory();

    const { id } = match.params;

    const [master, setMaster] = useState();

    const [items, setItems] = useState();

    const { user } = useContext(GlobalContext);

    const [packages, setPackages] = useState();

    const [packageField, setPackageField] = useState();

    const [loading, setLoading] = useState(false);

    const [location, setLocation] = useState(user.locations[0]);

    const [selected, setSelected] = useState([]);

    const [sum, setSum] = useState();

    const [form] = Form.useForm();

    useEffect(() => {
        loadMaster();
        loadItems();
        loadPackages();
    }, []);

    useEffect(() => {
        getPackageField();
    }, [items]);

    const loadMaster = () => {
        return FirebaseUtils.getRecord('items', id).then((result) => {
            setMaster(result);
        });
    };

    const loadItems = () => {
        setLoading(true);
        return FirebaseUtils.getItemJoinStock({ dealerCode: 'KL305' }).then((result) => {
            result = result.items.map((res) => {
                if (res.price_on === 'stock' && res.item_id) {
                    return { ...res, id: res.item_id };
                } else {
                    return res;
                }
            });

            console.log(result);
            setItems(result);
            setLoading(false);
        });
    };

    let query = [
        {
            field: 'item_id',
            value: id,
        },
    ];

    const loadPackages = () => {
        return FirebaseUtils.getListing('central', query, 'package_details').then((result) => {
            result.package_details = result.package_details.filter((i) => i.status !== 'removed');

            setPackages(result.package_details);

            let fieldArray = result.package_details.map((pkg) => {
                return {
                    sub_item_id: pkg.sub_item_id,
                    type: pkg.type,
                    value: pkg.value,
                    active: pkg.active,
                };
            });
            setPackageField(fieldArray);
        });
    };

    console.log(packageField);

    /**
     * To display the items already added
     */
    const getPackageField = async () => {
        if (packageField) {
            packageField.map((pf) => {
                let item = items.filter((i) => i.id === pf.sub_item_id);

                selected.push(item[0])

            });

            await setSelected([...selected]);

            getTotal()
        }
    };

    /**
     *
     * @param {} item_id
     * @param {*} key
     *
     * show selected items
     */
    const selectItem = (item_id, key) => {
        console.log(item_id, key);

        let item = items.filter((i) => i.id === item_id);

        selected[key] = item[0];

        setSelected([...selected]);

        console.log(selected);

        getTotal();
    };

    /**
     * get total price of selected items
     */
    const getTotal = () => {
        
        return new Promise((resolve, reject) => {

            let prices = selected.map((i) => i.price);

            console.log(selected);

            let sum = 0;

            sum = prices.reduce((a, b) => a + b, 0);

            setSum(sum);

            resolve(sum)

        });
    };

    console.log(selected);

    /**
     * On submit after select items
     */
    const onFinish = ({ entries }) => {
        // if row deleted
        console.log(entries);
        let updated = [];
        packages.map((i) => {
            let a = entries.filter((e) => e.sub_item_id === i.sub_item_id);
            if (a.length === 0) {
                i.status = 'removed';
                i.active = false;
                return updated.push(i);
            } else {
                i = {
                    ...i,
                    ...a[0],
                };
                updated.push(i);
            }
        });

        //updating

        return Promise.all(
            updated.map((u) => {
                return FirebaseUtils.updateRecord('package_details', u.id, u).then((r) => {
                    return r;
                });
            })
        ).then(() => {
            // adding new items to package
            entries.map((entry, key) => {
                let a = packages.filter((p) => p.sub_item_id === entry.sub_item_id);

                if (a.length > 0) {
                    return;
                } else {
                    entry = {
                        ...entry,
                        item_id: id,
                        serial_number: key + 1,
                        deleted_at:null,
                        deleted_by:null
                    };
                    return FirebaseUtils.addListing('package_details', entry).then((result) => {});
                }
            });

            //update price of package in items table
            getTotal().then((s) => {
                Items.update(id, { price: s }, { hideAlert: true });
            });

            message.success('Package updated.');
            history.push('/manage-packages');
        });
    };

    /*
     *
     * delete record from selected and calculate total price
     */
    const deleteSelected = (key) => {
        selected.splice(key, 1);

        setSelected([...selected]);
        getTotal();
    };

    const handleChange = () => {
        form.setFieldsValue({ sights: [] });
    };

    return (
        <Fragment>
            {loading ? <Skeleton /> : (
            <Fragment>
            <Title level={2}>
                {master && master.part_name} <Tag color="magenta">Package</Tag>
            </Title>
            <Text>Add items to the package</Text>
            {!packageField ? null : (
                <Form
                    initialValues={{ entries: packageField }}
                    style={{ marginTop: '20px' }}
                    name="dynamic_form_nest_item"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.List name="entries">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Fragment>
                                        <Row span={24}>
                                            <Col span={6}>
                                                <Form.Item
                                                    label="Items"
                                                    name={[name, 'sub_item_id']}
                                                    fieldKey={[fieldKey, 'sub_item_id']}
                                                    rules={[{ required: true, message: 'Select an item' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Search Item to Select"
                                                        style={{ width: 200 }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                        onSelect={(value) => selectItem(value, key)}
                                                    >
                                                        {items &&
                                                            items
                                                                .filter((item) => !item.package_status)
                                                                .map((item, key) => {
                                                                    return (
                                                                        <Option key={key} value={item.id}>
                                                                            {item.part_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    label="Type"
                                                    name={[name, 'type']}
                                                    fieldKey={[fieldKey, 'type']}
                                                    rules={[{ required: true, message: 'Select type.' }]}
                                                >
                                                    <Select
                                                        style={{ width: 200 }}>
                                                        <Option value="quantity">Quantity</Option>
                                                        <Option value="amount">Amount</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    label="value"
                                                    name={[name, 'value']}
                                                    fieldKey={[fieldKey, 'value']}
                                                    rules={[{ required: true, message: 'Enter value.' }]}
                                                >
                                                    <InputNumber style={{ width: 100 }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    label="Active"
                                                    name={[name, 'active']}
                                                    fieldKey={[fieldKey, 'active']}
                                                    rules={[{ required: true, message: 'Please select.' }]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value={true}>true</Radio>
                                                        <Radio value={false}>false</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>

                                            <Col span={2}>
                                                <MinusCircleTwoTone
                                                    style={{ marginTop: '10px' }}
                                                    twoToneColor="#FF0000"
                                                    onClick={(key) => {
                                                        remove(name);
                                                        deleteSelected(name);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Items
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <Form.Item>
                        <Space direction="vertical">
                            {selected
                                ? selected.map((item) => {
                                      return (
                                          <div>
                                              <b>{item.part_name}</b>
                                              <br />
                                              <b>{item.part_number}</b>
                                              <br />
                                              <b>
                                                  {' '}
                                                  <Rupee value={item.price}></Rupee>
                                              </b>
                                          </div>
                                      );
                                  })
                                : // { selected.part_number}
                                  null}
                        </Space>
                    </Form.Item>
                    <Form.Item>
                        {sum ? (
                            <div style={{ color: 'red', font: 'large' }}>
                                <b>Total:{sum}</b>
                            </div>
                        ) : null}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                )}
            </Fragment>
            )}
        </Fragment>
    );
}

export default withRouter(EditPackage);
