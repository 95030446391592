/**
 *
 * This file should evolve to the Global Preferences
 * Which can be overriden by individual preferences
 *
 */

import React, { useState, useEffect, useContext } from 'react';

import FirebaseUtils from './../utils/firebase.utils';

import { message, Tag, Badge } from 'antd';

import { Link } from 'react-router-dom';

import moment from 'moment';

export const timezone = 'Asia/Calcutta';

/**
 *
 * Table Index
 *
 * Used to identify the key in the file uploaded
 * We might generate dynamic indexes, In this cases we make use of rowIndex
 *
 * Row Index
 *
 * Identifies each row in the table. Sometimes it might be similar to table Index
 *
 *
 */

const PaymentRequestForm = {
    node: 'nest',

    isForm: true,
    caption: 'Payment Requests',
    path: '/stock-transfer-form/:city/:id',

    url: '/voucher-entry-rqst/approve/:city/:id',

    endpoint: 'stock_transfers',
    model: 'stock_transfers',
    post_status: 'Allocated',
    extraParams: (values, content) => {
        return {
            approve: values.isApproved === 'No' ? false : true,
        };
    },
    fields: [
        {
            type: 'radio',
            options: ['Yes', 'No'],
            caption: 'Approve this payment ?',
            field: 'isApproved',
        },
        {
            type: 'textarea',
            caption: 'Remarks',
            field: 'remarks',
        },
    ],
    onSubmit: (transferRequest, values) => {
        // Once done, We have to update the allocation status on request so that
        // stock is taken from the same city
        let params = {};

        FirebaseUtils.updateRequestGeneric(transferRequest.requestId, params, 'Stock Transfer Request is Created', values.remarks, 'allocation').then(
            () => {
                message.success('Allocation status of request has been updated');
            }
        );
    },
};

/**
 * Schema for Payment Request
 */

export const paymentRequestSchema = {
    includeFirstRow: true,

    model: 'payment-requests',

    endPoint: '/voucher-entry-rqst/:city',
    queryParams: '?ver_status=E',

    Card: PaymentRequest,

    form: PaymentRequestForm,

    node: 'nest',

    isMain: true,

    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Receipt No.',
    rowIndex: 'Receipt No.',

    disableEdit: true,
    disableAdd: true,

    importAll: true,

    columns: [
        {
            field: 'Receipt No.',
            caption: 'Receipt No',
        },
        {
            field: 'Name of Customer',
            caption: 'Customer',
        },
        {
            field: 'KEC',
            caption: 'KEC',
        },
        {
            field: 'Invoice No',
            caption: 'Invoice No',
        },
    ],
};

function PaymentRequest(record) {
    return (
        <Link className="report-item" to={`/all/payment-requests/${record.ver_id}`}>
            <div className="card">
                <h2 className="title amount">
                    {record.ver_amt}
                    {/* {vehicle.bookingNo} */}
                </h2>

                <h3 className="title">
                    {/* {vehicle.customer.customerName} */}
                    {record.ver_narration}
                </h3>

                <h2 className="title">{record.ac_desc}</h2>

                <h3 className="title">{/* {vehicle['variant']} - {vehicle['color']} */}</h3>

                <p className="address">
                    Created by {record.ver_user} at {moment(record.ver_tm).format('DD/MM/YYYY HH:mm')}
                    {/* KEC on {vehicle.kec} , {vehicle.created_at} */}
                </p>

                {/* <Tag color="magenta">{vehicle.status}</Tag> */}

                {/* {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                } */}
            </div>
        </Link>
    );
}

/**
 *
 * Receipts Schema
 *
 */

export const receiptsSchema = {
    includeFirstRow: true,

    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Receipt No.',
    rowIndex: 'Receipt No.',

    disableEdit: true,
    disableAdd: true,

    importAll: true,

    cityKey: 'dealerCode',

    columns: [
        {
            field: 'Receipt No.',
            caption: 'Receipt No',
        },
        {
            field: 'Dealer Code',
            caption: 'Dealer Code',
        },
        {
            field: 'Name of Customer',
            caption: 'Customer',
        },
        {
            field: 'KEC',
            caption: 'KEC',
        },
        {
            field: 'Invoice No',
            caption: 'Invoice No',
        },
    ],
    extraParams: (latest, previous, dealerCode) => {
        var dateParams = {
            bookingId: latest['Appointment No'],
        };

        // if (latest['Appointment No']) {
        //     dateParams['Appointment No'] = dealerCode + latest['Appointment No']
        // }

        return dateParams;
    },
};

export const requestRouteConfig = [
    {
        caption: 'All Requests',
        queries: [],
        path: 'all-requests',
    },
    {
        path: 'pending-requests',
        caption: 'Pending Requests',
        queries: [
            {
                field: 'billing.status',
                value: 'pending',
            },
            {
                field: 'allocation.status',
                value: 'pending',
            },
        ],
    },
    {
        path: 'allocated-requests',
        caption: 'Allocated Requests',
        queries: [
            {
                field: 'actual_billing.status',
                value: 'pending',
                // operator: 'in',

                // value: ['pending', 'requested'],
            },
            {
                field: 'allocation.status',
                operator: 'in',
                value: ['requested', 'approved', 'Allocated'],
            },
        ],
    },
    {
        path: 'billed-requests',
        caption: 'Pending for Delivery',
        queries: [
            {
                field: 'delivery.status',
                operator: 'in',
                value: ['pending', 'requested', 'approved', 'Delivery Note Taken'],
            },
        ],
    },
    {
        path: 'delivered-requests',
        caption: 'Delivered Requests',
        queries: [
            {
                field: 'delivery.status',
                operator: 'in',
                value: ['Delivered', 'Delivery Completed'],
            },
        ],
    },
];

/**
 * Global Request Schema
 */
export const requestSchema = {
    model: 'requests',
    queries: [],

    pendingRequests: [
        {
            field: 'actual_billing.status',
            operator: 'in',
            value: ['pending', 'requested'],
        },
    ],

    allocatedRequests: [
        {
            field: 'allocation.status',
            operator: 'in',
            value: ['approved', 'Allocated'],
        },

        {
            field: 'actual_billing.status',
            value: 'pending',
            // operator: 'in',
            // value: ['approved', 'Allocated'],
        },
    ],

    billingRequests: [
        {
            field: 'actual_delivery.status',
            operator: 'in',
            value: ['pending', 'requested', 'approved', 'Delivery Note Taken', 'Settlement Pending', 'Settlement Ready', 'Delivery Pending'],
        },
    ],

    deliveryRequests: [
        {
            field: 'delivery.status',
            operator: 'in',
            value: ['Delivered', 'Delivery Completed'],
        },
    ],

    // billingRequests: [{
    //     field: 'billing.status',
    //     value: 'Invoiced'
    // },
    // {
    //     field: 'delivery.status',
    //     value: 'pending'
    // }],

    // deliveryRequests: [{
    //     field: 'delivery.status',
    //     value: 'Delivered'
    // }],

    deliveryNoteTakenRequests: [
        {
            field: 'rto.status',
            value: 'Road Tax Issued',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: true,
    columns: [
        {
            type: 'input',
            caption: 'Customer',
            field: 'customer.customerName',
            sort: (a, b) => {
                if (a && a['customer'] && a['customer']['customerName'] && b && b['customer'] && b['customer']['customerName']) {
                    return a['customer']['customerName'].localeCompare(b['customer']['customerName']);
                } else if (a && a['customer'] && a['customer']['customerName']) {
                    return a['customer']['customerName'].localeCompare('zz');
                } else if (b && b['customer'] && b['customer']['customerName']) {
                    return 'zz'.localeCompare(b['customer']['customerName']);
                } else {
                    return 'zz'.localeCompare('zz');
                }
            },
        },

        {
            field: 'variant',
            type: 'input',
            caption: 'Variant',
        },

        {
            type: 'input',
            caption: 'Phone',
            field: 'customer.phone',
        },

        {
            type: 'input',
            caption: 'Allocation',
            field: 'allocation.status',
        },

        {
            type: 'tag',
            caption: 'Finance',
            field: 'finance.status',
        },

        {
            type: 'input',
            caption: 'Insurance',
            field: 'insurance.status',
        },

        {
            type: 'input',
            caption: 'KEC',
            field: 'kec',
        },

        {
            caption: 'Created At',
            field: 'created_at',
            type: 'input',
        },
    ],
};

/**
 * Custom Actions for Editing Request
 */
export const billActions = [
    {
        caption: 'View',
        url: (record) => {
            return `/bill/${record.id}`;
        },
    },
];

export const itemActions = [
    {
        caption: 'View',
        url: (record) => {
            return `/bill/${record.bill_id}`;
        },
    },
];

export const billColumns = [
    {
        field: 'invoice_number',
        caption: 'Invoice No',
    },
    {
        caption: 'Invoice Date',
        render(record) {
            return moment(record.invoice_date_millisecond).format('DD/MM/YYYY');
        },
    },
    {
        field: 'bookingNo',
        caption: 'Booking No',
    },
    {
        field: 'customer_name',
        caption: 'Customer',
    },
    {
        field: 'customer_state',
        caption: 'State',
    },

    {
        field: 'gst_number',
        caption: 'GSTIN',
    },
    {
        field: 'attributes.model',
        caption: 'Model',
    },
    {
        field: 'attributes.variant',
        caption: 'Variant',
    },
    {
        field: 'attributes.vinNo',
        caption: 'VIN Number',
    },
    {
        field: 'taxable_amount',
        caption: 'Taxable Value',
    },
    {
        field: 'igst_amount',
        caption: 'IGST',
    },
    {
        field: 'cgst_amount',
        caption: 'CGST',
    },
    {
        field: 'sgst_amount',
        caption: 'SGST',
    },
    {
        field: 'cess_amount',
        caption: 'Cess',
    },
    {
        field: 'tcs_amount',
        caption: 'TCS',
    },
    // {
    //     field: 'total_tax',
    //     caption: 'Tax',
    // },
    {
        field: 'item_discount_amount',
        caption: 'Item (Dis)',
    },
    {
        field: 'net_amount',
        caption: 'Invoice Value',
    },
];

export const billSchema = {
    model: 'bills',
    queries: [
        {
            field: 'cancellation_status',
            value: false,
        },
        {
            field: 'transaction_type',
            operator: '==',
            value: 'sale',
        },
    ],
    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: true,
    dateField: 'created_date',
    // columns: [
    //     {
    //         type: 'input',
    //         caption: 'Customer ID',
    //         field: 'customerID',
    //     },

    //     {
    //         field: 'customer_name',
    //         type: 'input',
    //         caption: 'Customer',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Created At',
    //         field: 'created_at',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Authorised By',
    //         field: 'authorised_by',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Customer State',
    //         field: 'customet_state',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Tax',
    //         field: 'total_tax',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Discount',
    //         field: 'bill_discount',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Total',
    //         field: 'total',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Category',
    //         field: 'type',
    //     },
    // ],
};

export const ItemWiseSchema = {
    model: 'bill-details',
    queries: [
        {
            field: 'cancellation_status',
            value: false,
        },
        {
            field: 'transaction_type',
            value: 'sale',
        },
    ],
    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: true,
    dateField: 'created_date',
    // columns: [
    //     {
    //         type: 'input',
    //         caption: 'Customer ID',
    //         field: 'customerID',
    //     },

    //     {
    //         field: 'customer_name',
    //         type: 'input',
    //         caption: 'Customer',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Created At',
    //         field: 'created_at',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Authorised By',
    //         field: 'authorised_by',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Customer State',
    //         field: 'customet_state',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Tax',
    //         field: 'total_tax',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Discount',
    //         field: 'bill_discount',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Total',
    //         field: 'total',
    //     },

    //     {
    //         type: 'input',
    //         caption: 'Category',
    //         field: 'type',
    //     },
    // ],
};

export const billDetailSchema = {
    model: 'bills',
    queries: [],
    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: true,
    columns: [
        {
            type: 'input',
            caption: 'Part Number',
            field: 'part_number',
        },

        {
            type: 'input',
            caption: 'Part Name',
            field: 'part_number',
        },

        {
            type: 'input',
            caption: 'Rate',
            field: 'rate',
        },

        {
            type: 'input',
            caption: 'Quantity',
            field: 'quantity',
        },

        {
            type: 'input',
            caption: 'Taxable',
            field: 'taxable_amount',
        },

        {
            type: 'input',
            caption: 'SGST',
            field: 'sgst',
        },

        {
            type: 'input',
            caption: 'CGST',
            field: 'cgst',
        },

        {
            type: 'input',
            caption: 'Discount',
            field: 'discount_amount',
        },

        {
            type: 'input',
            caption: 'Total',
            field: 'total_amount',
        },

        {
            type: 'input',
            caption: 'Category',
            field: 'type',
        },
    ],
};

function BookingCard({ city, booking = {}, index, showPosition }) {
    return (
        <Link key={index} className="booking-card" to={`/bookings/${booking['Booking No']}`}>
            <div className="card">
                <h2 className="title amount ">{booking['Name of the Customer']}</h2>
                <h4 className="title">{booking['Contact Number']}</h4>
                <p className="">{booking['Variant']}</p>
                <p>
                    <small> {booking['Color']}</small>
                </p>
                {showPosition && booking.billing.status === 'pending' ? (
                    <>
                        <BookingPosition city={city} booking={booking} />
                    </>
                ) : null}
                <h4>{booking['Booking No']}</h4>
                <p className="address">Booked on {booking['Booking Date']}</p>
                Committed Delivery Date {booking['Committed Delivery Date']}
            </div>
        </Link>
    );
}

/**
 * Booking Position
 */
function BookingPosition({ booking, city }) {
    const [model, setModel] = useState({ position: 0 });

    useEffect(() => {
        loadMatchingBookings(city, booking).then((result) => {
            console.log(result);
        });

        return () => { };
    }, []);

    function loadMatchingBookings(city, booking) {
        var queries = [
            {
                field: 'billing.status',
                value: 'pending',
            },
            {
                field: 'Color',
                value: booking['Color'],
            },
            {
                field: 'Variant',
                value: booking['Variant'],
            },
        ];

        return FirebaseUtils.getListing(city, queries, 'bookings').then((result) => {
            const { bookings } = result;

            var arr = bookings.sort((a, b) => {
                return a.bookingDate > b.bookingDate ? 1 : -1;
            });

            var position;

            arr.forEach((item, index) => {
                if (item['Booking No'] === booking['Booking No']) {
                    position = index;
                }
            });

            setModel({ position, bookings });

            return { position, bookings };
        });
    }

    return (
        <p style={{ float: 'right' }}>
            <Badge status="processing" text={`Position : ${model.position + 1}`} />
        </p>
    );
}

/**
 * Schema used for Import
 */
export const bookingSchema = {
    model: 'bookings',

    // Card: BookingCard,

    tableHeader: 2,
    tableRange: 0,
    importAll: true,
    includeFirstRow: true,

    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    disableEdit: true,

    filterResults: (entry) => {
        return !entry.isCancelled;
    },

    pendingBookings: [
        {
            field: 'billing.status',
            value: 'pending',
        },
    ],

    billingQueries: [
        {
            field: 'billing.status',
            value: 'done',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryQueries: [
        {
            field: 'delivery.status',
            value: 'done',
        },
    ],

    extraParams: (element) => {
        return {
            billing: {
                status: 'pending',
            },
            delivery: {
                status: 'pending',
            },
            current: {
                status: 'Awaiting Confirmation'
            },
            document_status: 'pending',
            receipt_amount: 0,
            booking_amount: 0,
            bookingDate: moment(element['Booking Date'], 'DD/MM/YYYY').valueOf(),
            bookingId: element['Booking No'],
            ...element,
        };
    },

    tableIndex: 'Booking No',
    cityKey: 'dealerCode',

    dateField: 'Booking Date',

    rowIndex: 'Booking No',
    columns: [
        {
            type: 'input',
            caption: 'Booking No',
            field: 'Booking No',
            title: 'Booking No',
            dataIndex: 'Booking No',
        },
        {
            type: 'input',
            caption: 'Dealer Code',
            field: 'Dealer Code',
            title: 'Dealer Code',
            dataIndex: 'Dealer Code',
        },
        {
            field: 'Name of the Customer',
            type: 'input',
            caption: 'Customer Name',
            title: 'Customer Name',
            dataIndex: 'Name of the Customer',
            sort: (a, b) => {
                if (a && a['Name of the Customer'] && b && b['Name of the Customer']) {
                    return a['Name of the Customer'].localeCompare(b['Name of the Customer']);
                } else if (a && a['Name of the Customer']) {
                    return a['Name of the Customer'].localeCompare('zz');
                } else if (b && b['Name of the Customer']) {
                    return 'zz'.localeCompare(b['Name of the Customer']);
                } else {
                    return 'zz'.localeCompare('zz');
                }
            },
        },
        {
            field: 'Contact Number',
            type: 'input',
            caption: 'Contact Number',
            dataIndex: 'Contact Number',
            title: 'Contact Number',
        },
        {
            field: 'Booking Date',
            type: 'input',
            caption: 'Booking Date',
            dataIndex: 'Booking Date',
            title: 'Booking Date',
        },
        {
            caption: 'KEC',
            field: 'Consultant Name',
            type: 'input',
            dataIndex: 'Consultant Name',
            title: 'KEC',
        },

        {
            caption: 'Team Leader',
            field: 'Team Leader',
            type: 'input',
            dataIndex: 'Team Leader',
            title: 'KEC',
        },

        // {
        //     caption: 'Request',
        //     field: 'requestId',
        //     type: 'input',
        // },
    ],

    custom_actions: [
        {
            onClick: (selected, city, callback) => {
                console.log('jhell', selected);

                Promise.all(
                    selected.map((entry) => {
                        var count = 0;

                        return FirebaseUtils.removeRequest(entry).then(() => {
                            count++;
                            console.log(count);
                        });
                    })
                ).then(() => {
                    message.success('Action done');

                    callback();
                });
            },
            permission: (user) => user.role === 'config',
            caption: 'Reset',
            multiple: true,
        },
    ],
};

/**
 * Schema for Sale Report
 */
export const saleSchema = {
    importAll: true,

    includeFirstRow: true,
    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Invoice No',

    rowIndex: 'Invoice No',
    cityKey: 'dealerCode',

    // Override data with any extra params for each entry
    // We would get the element that is scanner and a previous value if its there
    extraParams: (latest, previous) => {
        console.log(timezone);
        var dateParams = {
            invoice_date: moment(latest['Invoice Date'], 'DD/MM/YYYY').tz(timezone).valueOf(),
            confirm_date: moment(latest['Confirm Date'], 'DD/MM/YYYY').tz(timezone).valueOf(),
        };

        if (latest['Delivery Date']) {
            dateParams.delivery_date = moment(latest['Delivery Date'], 'DD/MM/YYYY').tz(timezone).valueOf();
        }
        if (latest['Booking No']) {
            dateParams.bookingId = latest['Booking No'];
        }

        return dateParams;
    },

    columns: [
        {
            field: 'Invoice No',
            type: 'input',
            caption: 'Invoice No',
        },

        {
            field: 'Dealer Code',
            type: 'input',
            caption: 'Dealer Code',
        },

        {
            field: 'Invoice Date',
            type: 'input',
            caption: 'Invoice Date',
        },

        {
            field: 'Booking No',
            type: 'input',
            caption: 'Booking No',
        },

        {
            field: 'Registration Name',
            type: 'input',
            caption: 'Registration Name',
        },

        {
            field: 'Contact Num1',
            type: 'input',
            caption: 'Contact Num1',
        },

        {
            field: 'Vin Number',
            type: 'input',
            caption: 'Vin Number',
        },

        {
            field: 'Vin Number',
            type: 'input',
            caption: 'Vin Number',
        },

        {
            field: 'Consultant Name',
            type: 'input',
            caption: 'Consultant Name',
        },

        {
            field: 'Booking Date',
            type: 'input',
            caption: 'Booking Date',
        },
    ],
};

/**
 * Schema for Purchase Report
 */
export const purchaseSchema = {
    includeFirstRow: true,

    importAll: true,

    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Invoice No',
    uploadIndex: 'KIN Invoice No',

    rowIndex: 'Invoice No',

    cityKey: 'dealerCode',

    // Override data with any extra params for each entry
    // We would get the element that is scanner and a previous value if its there
    extraParams: (latest, previous) => {
        var dateParams = {
            invoice_date: moment(latest['KIN Invoice Date'], 'DD/MM/YYYY').valueOf(),
            VinNo: latest['Vin No.'],
        };

        if (latest['Delivery Date']) {
            dateParams.delivery_date = moment(latest['Delivery Date'], 'DD/MM/YYYY').valueOf();
        }
        if (latest['Booking No']) {
            dateParams.bookingId = latest['Booking No'];
        }

        return dateParams;
    },

    columns: [
        {
            field: 'KIN Invoice No',
            type: 'input',
            caption: 'Invoice No',
        },

        {
            field: 'Dealer Code',
            type: 'input',
            caption: 'Dealer Code',
        },

        {
            field: 'KIN Invoice Date',
            type: 'input',
            caption: 'Invoice Date',
        },

        {
            field: 'Booking No',
            type: 'input',
            caption: 'Booking No',
        },

        {
            field: 'Contact Num1',
            type: 'input',
            caption: 'Contact Num1',
        },

        {
            field: 'VinNo',
            type: 'input',
            caption: 'Vin Number',
        },

        {
            field: 'Consultant Name',
            type: 'input',
            caption: 'Consultant Name',
        },

        {
            field: 'Booking Date',
            type: 'input',
            caption: 'Booking Date',
        },
    ],
};

/**
 * Schema for Inter Stock Report
 */
export const InterStockSchema = {
    importAll: true,

    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Transfer No',

    rowIndex: 'Transfer No',
    cityKey: 'dealerCode',

    // Override data with any extra params for each entry
    // We would get the element that is scanner and a previous value if its there
    extraParams: (latest, previous) => {
        var dateParams = {
            transferred_date: moment(latest['Transferred Date'], 'DD/MM/YYYY').valueOf(),
        };

        // if (latest['Delivery Date']) {
        //     dateParams.delivery_date = moment(latest['Delivery Date'], "DD/MM/YYYY").valueOf()
        // }

        return dateParams;
    },

    columns: [
        {
            field: 'Transfer No',
            type: 'input',
            caption: 'Transfer No',
        },

        {
            field: 'Dealer Code',
            type: 'input',
            caption: 'Dealer Code',
        },

        {
            field: 'Transferred Date',
            type: 'input',
            caption: 'Transferred Date',
        },

        {
            field: 'Model',
            type: 'input',
            caption: 'Model',
        },

        {
            field: 'Variant',
            type: 'input',
            caption: 'Variant',
        },

        {
            field: 'VIN',
            type: 'input',
            caption: 'Vin Number',
        },

        {
            field: 'Color',
            type: 'input',
            caption: 'Color',
        },

        {
            field: 'Amount',
            type: 'input',
            caption: 'Amount',
        },

        {
            field: 'GST No',
            type: 'input',
            caption: 'GST No',
        },
    ],
};

/**
 * Global Billing Request Schema
 */
export const billingRequestSchema = {
    model: 'billing_snapshot',
    queries: [],

    actions: [
        {
            caption: 'View',
            url: (record) => {
                return `/requests/${record.requestId}`;
            },
        },
    ],

    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: true,
    columns: [
        {
            type: 'input',
            caption: 'Customer',
            field: 'customerName',
        },

        {
            field: 'variant',
            type: 'input',
            caption: 'Variant',
        },

        // {
        //     type: 'input',
        //     caption: 'Phone',
        //     field: 'customer.phone',
        // },

        {
            type: 'input',
            caption: 'Total Amount',
            field: 'total',
        },

        {
            type: 'tag',
            caption: 'Received Amount',
            field: 'received',
        },

        {
            type: 'input',
            caption: 'Balance',
            render: (record) => {
                return record.balance.toFixed(2);
            },
            // field: 'balance',
        },

        {
            type: 'input',
            caption: 'Booking',
            field: 'bookingNo',
        },
    ],
};

/**
 * Global Billing Request Schema
 */
export const deliveryRequestSchema = {
    model: 'delivery_snapshot',
    queries: [],

    billingQueries: [
        {
            field: 'status',
            operator: 'in',
            value: ['requested', 'approved'],
        },
    ],

    deliveryQueries: [
        {
            field: 'status',
            operator: 'in',
            value: ['requested', 'approved'],
        },
    ],

    pendingRequests: [
        {
            field: 'billing.status',
            value: 'pending',
            // operator: '!='
        },
    ],

    billingRequests: [
        {
            field: 'billing.status',
            value: 'Invoiced',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryRequests: [
        {
            field: 'delivery.status',
            value: 'Delivered',
        },
    ],

    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: true,
    columns: [
        {
            type: 'input',
            caption: 'Customer',
            field: 'customerName',
        },

        {
            field: 'variant',
            type: 'input',
            caption: 'Variant',
        },

        // {
        //     type: 'input',
        //     caption: 'Phone',
        //     field: 'customer.phone',
        // },

        {
            type: 'input',
            caption: 'Total Amount',
            render: (record) => {
                return record.total.toFixed(2);
            },

            // field: 'total',
        },

        {
            type: 'tag',
            caption: 'Received Amount',
            // field: 'received',
            render: (record) => {
                return record.received.toFixed(2);
            },
        },

        {
            type: 'input',
            caption: 'Balance',
            render: (record) => {
                return record.balance.toFixed(2);
            },
            // field: 'balance',
        },

        {
            type: 'input',
            caption: 'Booking',
            field: 'bookingNo',
        },
    ],
};

/**
 * Schema for Sale Report
 */
export const enquirySchema = {
    importAll: true,

    includeFirstRow: true,

    tableHeader: 2,
    tableRange: 0,

    disableAdd: true,
    disableEdit: true,
    disableSelect: true,

    tableIndex: 'Enquiry No.',

    rowIndex: 'Enquiry No.',
    cityKey: 'dealerCode',

    // Override data with any extra params for each entry
    // We would get the element that is scanner and a previous value if its there
    extraParams: (latest, previous) => {
        var dateParams = {
            enquiry_date: moment(latest['Enquiry Date'], 'DD/MM/YYYY').valueOf(),
        };

        if (latest['Booking Date']) {
            dateParams.booking_date = moment(latest['Booking Date'], 'DD/MM/YYYY').valueOf();
        }

        return dateParams;
    },

    columns: [
        {
            field: 'id',
            type: 'input',
            caption: 'Enquiry No',
        },

        {
            field: 'Name of the Customer',
            type: 'input',
            caption: 'Name of the Customer',
        },

        {
            field: 'Consultant Name',
            type: 'input',
            caption: 'Consultant Name',
        },

        {
            field: 'Enquiry Status',
            type: 'input',
            caption: 'Enquiry Status',
        },
    ],
};

const commonSteps = {
    insurance: {
        isForm: true,
        caption: 'Data Collection',
        path: '/insurance/:city/:id',
        model: 'insurance',
        post_status: 'Data Collected',
        fields: [
            {
                type: 'radio',
                options: ['inhouse', 'direct'],
                caption: 'Insurance Type',
                field: 'type',
            },

            {
                type: 'input',
                caption: 'Insurance Provider',
                field: 'provider',
                condition: (values) => {
                    return values.type === 'inhouse';
                },
            },
            {
                type: 'input',
                caption: 'Premium Amount',
                field: 'policyAmount',
                condition: (values) => {
                    return values.type === 'inhouse';
                },
            },
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },
};

/**
 * Schema used for Import
 */
export const partsSchema = {
    model: 'items',
    tableHeader: 2,
    tableRange: 0,
    uploadIndex: 'PART NO', // Do we required an extra column like this
    tableIndex: 'no',
    cityKey: 'dealerCode',

    custom_actions: [],
    rowIndex: 'partNo',
    columns: [
        {
            field: 'part_number',
            from: 'PART NO',
        },

        {
            field: 'part_name',
            from: 'PART NAME',
        },

        {
            field: 'hsn',
            from: 'HSN CD',
            // from: 'HSN CODE',
        },

        {
            field: 'supplier',
            from: 'SUPPLIER',
        },
    ],
};

/**
 * Structure for Parts Listing and Add
 */
export const partsColumns = [
    {
        type: 'input',
        caption: 'Part No',
        field: 'part_number',
    },
    {
        field: 'part_name',
        type: 'input',
        caption: 'Part Name',
    },
    {
        field: 'supplier',
        type: 'input',
        caption: 'Supplier',
    },
    {
        field: 'hsn',
        type: 'number',
        caption: 'HSN',
    },

    // {
    //     caption: 'Model',
    //     field: 'model',
    //     type: 'checkbox',
    //     options: [
    //         { label: 'Seltos', value: 'Seltos' },
    //         { label: 'Sonet', value: 'Sonet' },
    //         { label: 'Carnival', value: 'Carnival' },
    //     ], // This has to come from db
    // },
    {
        field: 'price',
        type: 'number',
        caption: 'Price',
    },
    {
        caption: 'Category',
        field: 'category',
        type: 'radio',
        options: ['VAS', 'After Market', 'Extended Warranty'],
    },

    {
        caption: 'Inclusive of Tax',
        field: 'tax_inclusive',
        type: 'radioText',
        options: [
            { value: true, text: 'true' },
            { value: false, text: 'false' },
        ],
    },
    {
        caption: 'Tax',
        field: 'tax_id',
        type: 'selectText',
        options: [
            { text: 'GST 12%', value: '' },
            { text: 'GST 14%', value: '' },
            { text: 'GST 18%', value: '' },
            { text: 'GST 28%', value: '' },
        ],
    },
];

export const itemsColumn = [
    {
        type: 'input',
        caption: 'Part No',
        field: 'part_number',
    },
    {
        field: 'part_name',
        type: 'input',
        caption: 'Part Name',
    },
    {
        field: 'supplier',
        type: 'input',
        caption: 'Supplier',
    },
    {
        field: 'hsn',
        type: 'number',
        caption: 'HSN',
    },

    // {
    //     caption: 'Model',
    //     field: 'model',
    //     type: 'checkbox',
    //     options: [
    //         { label: 'Seltos', value: 'Seltos' },
    //         { label: 'Sonet', value: 'Sonet' },
    //         { label: 'Carnival', value: 'Carnival' },
    //     ], // This has to come from db
    // },
    {
        field: 'price',
        type: 'number',
        caption: 'Price',
    },
    {
        caption: 'Category',
        field: 'category',
        type: 'radio',
        options: ['VAS', 'After Market', 'Extended Warranty'],
    },
];

export const packageSchema = {
    model: 'items',
    queries: [
        {
            field: 'package_status',
            value: true,
        },
    ],
    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    disableDownload: true,
    isDirect: true,
};

export const packageColumn = [
    {
        type: 'input',
        caption: 'Part No',
        field: 'part_number',
    },
    {
        field: 'part_name',
        type: 'input',
        caption: 'Part Name',
    },
    {
        field: 'price',
        type: 'number',
        caption: 'Price',
    },
];

export const itemsMandatory = ['part_name', 'part_number', 'tax_id', 'price', 'purchase_price_without_tax', 'tax_inclusive', 'category'];

/**
 * Schema used for Import
 */
export const partiesSchema = {
    tableHeader: 2,
    tableRange: 0,
    cityKey: 'dealerCode',

    custom_actions: [],
};

/**
 * Structure for parties add
 */

export const partiesColumns = [
    {
        type: 'input',
        caption: 'Name',
        field: 'name',
    },
    {
        type: 'input',
        caption: 'GST Number',
        field: 'gst_number',
    },
    {
        field: 'type',
        type: 'select',
        caption: 'Type',
        options: ['customer', 'vender'],
    },
    {
        field: 'sub-type',
        type: 'select',
        caption: 'Sub Type',
        options: ['accessory-supplier', 'vas-supplier', 'vehicle-supplier'],
    },
];

/**
 * Global Request Schema
 */
export const stockTransferSchema = {
    model: 'stock_transfers',
    queries: [],
    hideView: true,

    pendingRequests: [
        {
            field: 'billing.status',
            value: 'pending',
            // operator: '!='
        },
    ],

    billingRequests: [
        {
            field: 'billing.status',
            value: 'Invoiced',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryRequests: [
        {
            field: 'delivery.status',
            value: 'Delivered',
        },
    ],

    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: true,
    columns: [
        {
            type: 'input',
            caption: 'From',
            field: 'from',
        },

        {
            field: 'to',
            type: 'input',
            caption: 'To',
        },

        {
            type: 'input',
            caption: 'Vin Number',
            field: 'vinNo',
        },

        {
            type: 'input',
            caption: 'Booking',
            field: 'bookingNo',
            render: (record) => {
                return <Link to={`/bookings/${record.bookingNo}`}>{record.bookingNo}</Link>;
            },
        },

        {
            type: 'tag',
            caption: 'Status',
            field: 'status',
            render: (record) => {
                return <Tag color="magenta">{record.status}</Tag>;
            },
        },

        {
            type: 'input',
            caption: 'Remarks',
            field: 'remarks',
        },

        {
            caption: 'Created At',
            field: 'created_at',
            type: 'input',
        },

        {
            type: 'input',
            caption: 'Request',
            render: (record) => {
                return <Link to={`/requests/${record.requestId}`}>View</Link>;
            },
        },
    ],
};

/**
 * Global Request Schema
 */
export const stockRegisterSchema = {
    isDirect: true,
    dateField: 'created_time',

    model: 'stock-register',
    queries: [],
    hideView: true,

    actions: [
        {
            caption: 'View',
            url: (record) => {
                return `/${record.city}/stock-detail/${record.id}`;
            },
        },
    ],

    sort: (data) => {
        return data.sort((a, b) => {
            if (a.transaction_date > b.transaction_date) {
                return -1;
            } else {
            }
        });
    },

    disableUpload: true,
    disableSelect: true,
    disableAdd: true,

    // isDirect: true,
    columns: [
        {
            type: 'input',
            caption: 'Count',
            field: 'size',
        },

        {
            render: (record) => {
                if (!record.vehicleValue) {
                    return null
                } else {
                    return record.vehicleValue.toFixed(2);
                }

            },
            field: 'vehicleValue',
            type: 'input',
            caption: 'Stock Value',
        },

        {
            caption: 'Created At',
            field: 'created_at',
            type: 'input',
        },
    ],
};

/**
 * Global Request Schema
 */
export const payoutSchema = {
    model: 'payouts',
    queries: [],

    actions: [
        {
            caption: 'View',
            url: (record) => {
                return `/requests/${record.requestId}`;
            },
        },
    ],

    pendingRequests: [
        {
            field: 'billing.status',
            value: 'pending',
            // operator: '!='
        },
    ],

    billingRequests: [
        {
            field: 'delivery.status',
            operator: 'in',
            value: ['pending', 'requested', 'approved', 'Delivery Note Taken'],
        },
    ],

    deliveryRequests: [
        {
            field: 'delivery.status',
            operator: 'in',
            value: ['Delivered', 'Delivery Completed'],
        },
    ],

    deliveryNoteTakenRequests: [
        {
            field: 'rto.status',
            value: 'Road Tax Issued',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    disableUpload: true,
    disableSelect: true,
    disableAdd: true,
    isDirect: false,
    columns: [
        {
            type: 'input',
            caption: 'Customer',
            field: 'customerName',
        },

        {
            type: 'input',
            caption: 'Phone',
            field: 'phone',
        },

        {
            field: 'variant',
            type: 'input',
            caption: 'Variant',
        },

        {
            type: 'input',
            caption: 'Remarks',
            field: 'remarks',
        },

        {
            type: 'input',
            caption: 'Sanctioned Amount',
            field: 'sanctioned_amount',
        },

        {
            type: 'input',
            caption: 'Interest',
            field: 'interest',
        },

        {
            caption: 'Payout',
            field: 'payout',
            type: 'input',
        },

        {
            caption: 'Created At',
            field: 'created_at',
            type: 'input',
        },
    ],
};

/**
 * Dealer code keys
 */

export const dealerCodeKey = {

    KL305: 'kozhikode',
    KL306: 'kannur',
    KL307: 'malappuram',
    KL505: 'kondotty',
    KL506: 'tirur',
    KL507: 'vadakara',
    KL508: 'kasargod',
};


/**
 * To be used if location is saved as another name
 *
 */
export const dealerKeys = {

    KL305: 'Kozhikode',
    KL306: 'Kannur',
    KL307: 'Malappuram',
    KL508: 'Kasargod',
    KL506: 'Tirur',
    KL507: 'Vadakara',
    KL505: 'Kondotty'
};


/**
 * City keys
 */
export const cityKeys = {
    kozhikode: 'KL305',
    malappuram: 'KL307',
    kannur: 'KL306',
    kasargod: 'KL508',
    tirur: 'KL506',
    kondotty: 'KL505',
    vadakara: 'KL507'
}
