/**
 * Dms Stock Aging Dashboard Component
 *
 * @description Component to show Dms Stock Aging  
 * @author Nihala Dilshi
 */

import React, { useState, useEffect } from 'react';

import { Table, Modal, Card } from 'antd';
import { dealerCodeKey } from '../../../global-config';

import moment from 'moment';


import { Branches, StatusMasters, Vehicles } from '../../../../models';

// Helper function to group by dms stock ageing
const groupByNested = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        const keys = key.split('.');
        let value = x;
        for (let k of keys) {
            value = value[k];
            if (value === undefined) break;
        }

        // Determine the appropriate category based on the dms stock ageing
        let keyValue;
        if (value === undefined) {
            keyValue = 'NoStatus';
        } else if (value < 30) {
            keyValue = '<30 DAYS';
        } else if (value >= 30 && value <= 60) {
            keyValue = '30-60 DAYS';
        } else if (value >= 60 && value <= 90) {
            keyValue = '60-90 DAYS';
        } else if (value >= 90 && value <= 120) {
            keyValue = '90-120 DAYS';
        } else {
            keyValue = '120+ DAYS';
        }

        (rv[keyValue] = rv[keyValue] || []).push(x);
        return rv;
    }, {});
};


// Helper function to group by a dealerCode
const groupBy = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

/**
 *
 * Component for Dms Stock Ageing
 *
 * @param {*} param0
 */
export default function DmsStockAging({ }) {
    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ vehicles: [] });

    const [statusName, setStatusName] = useState([])

    const [dealerCode, setDealerCode] = useState([])


    useEffect(async () => {
        await getStockAgeingStatus();
        await getVehicle();
    }, []);

    // Get status from status master
    async function getStockAgeingStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'STOCK-AGE',
            }
        ];
        const result = await StatusMasters.get(queries);

        // fetching name field 
        result.status_masters.forEach((element) => {
            statusName.push(element.name);
        });
    }


    const generateColumns = (dealerCodeKey) => {
        // Initialize the columns array with the static 'Code' column
        const columns = [
            {
                title: 'AGEING',
                dataIndex: 'model',
            }
        ];

        // Iterate over the keys of the dealerKeys object to create dynamic columns
        for (const [key, value] of Object.entries(dealerCodeKey)) {
            columns.push({
                title: key.replace('KL', ''), // Extracting the number part of the key for the title
                className: value, // Converting the name  for the className
                render: (entry) => entry[key].mtd
            });
        }

        // Add the static 'Total' column at the end
        columns.push({
            className: 'total',
            title: 'TOTAL',
            render: (entry) => entry.all.mtd,
        });


        return columns;
    };

    // Generate the columns dynamically
    const columns = generateColumns(dealerCodeKey);

    /**
     *
     * Load all vehicles with the update: new
     *
     * @param {*} param0
     */

    async function getVehicle() {

        let query = [
            {
                field: 'update',
                value: 'new',
            },
        ];

        Vehicles.getAll(query, 'vehicles').then((result) => {
            let vehicles = result.all.filter((v) => v['KIN Invoice Date']);

            setContent({ vehicles: vehicles });

            Branches.getAllBranches().then(async (dealerCode) => {
                //  all dealerCode
                setDealerCode(dealerCode)

                await generateDmsStockAgeing(vehicles, dealerCode)
                    .then((arr) => {
                        setSummary(arr);
                    })
                    .catch((error) => {
                        console.error('Error generating booking dashboard:', error);
                    });
            })

        });
    }

    /**
     *
     * Component for generate DmsStockAgeing report
     *
     * @param {*} param0
     */

    async function generateDmsStockAgeing(vehicles, dealerCode) {
        var arr = [];
        const updatedData = vehicles.map(obj => {

            // Get stock age of vehicle
            let curdate = moment().valueOf();

            let difference = curdate - moment(obj['KIN Invoice Date'], 'DD/MM/YYYY').valueOf();

            const diffInDays = difference / 86400000;

            let age = (diffInDays.toFixed(0))

            obj.status = obj ? age : 'NoStatus';

            obj.allocated = obj.allocated ? 'allocated' : 'free';


            return obj;
        });

        const status = groupByNested(updatedData, 'status');

        statusName.forEach(async (model) => {
            var towns = groupBy(status[model], 'dealerCode');

            let town = {}


            await dealerCode.forEach((code) => {
                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { mtd: 0, allocated: 0, free: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;

            });

            dealerCode.forEach((code) => {
                // Group data by dealerCode and count allocated,free
                var customers = towns[code];
                if (customers && customers.length) {
                    let allocatedCount = customers.filter(c => c.allocated === 'allocated').length;
                    let freeCount = customers.length - allocatedCount;
                    town[code] = {
                        mtd: customers.length,
                        allocated: allocatedCount,
                        free: freeCount
                    };
                } else {
                    town[code] = { mtd: 0, allocated: 0, free: 0 };
                }
            })

            if (status[model]) {
                town.all = {
                    mtd: status[model].length,
                    allocated: status[model].filter(c => c.allocated === 'allocated').length,
                    free: status[model].filter(c => c.allocated === 'free').length
                };
            }
            arr.push(town)
        })

        return arr;

    }

    return (
        <>
            <div>
                <>
                    {content['vehicles'].length ? (
                        <Card title={`STOCK`} style={{ width: '100%', textAlign: 'center' }}>
                            <Table
                                size="small"
                                scroll={{ x: true }}
                                rowKey={(record) => record.id}
                                dataSource={summary}
                                columns={columns}
                                pagination={false}
                                summary={(pageData) => {
                                    let summary = {};
                                    // Loop through each dealer code in the dealerCode array
                                    dealerCode.forEach((code) => {
                                        // Initialize each dealer code property in the summary object with default  mtd,allocated and free values
                                        summary[code] = { mtd: 0, allocated: 0, free: 0 };

                                    });


                                    pageData.forEach((entry) => {
                                        dealerCode.forEach((code) => {
                                            if (entry[code]) {
                                                summary[code].mtd += entry[code].mtd;
                                                summary[code].allocated += entry[code].allocated;
                                                summary[code].free += entry[code].free;
                                            }
                                        });
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>TOTAL</strong>
                                                </Table.Summary.Cell>

                                                {dealerCode.map((code) => {
                                                    return (
                                                        <>
                                                            <Table.Summary.Cell>
                                                                <strong>{summary[code].mtd}</strong>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    );
                                                })}
                                            </Table.Summary.Row>
                                            {/* <Table.Summary.Row> 
                                                <Table.Summary.Cell>
                                                    <strong>AVG AGEING</strong>
                                                </Table.Summary.Cell>
                                                {/* {dealerCode.map((code) => {
                                                    return (
                                                        <>
                                                            <Table.Summary.Cell>
                                                                {/* total devided by number of vehicles */}
                                                                {/* <strong>{(((summary[code].mtd)/content.vehicles.length)*100).toFixed(0)}</strong> */}
                                                            {/* </Table.Summary.Cell> */}
                                                        {/* </> */}
                                                    {/* ); */}
                                                {/* })} */}
                                            {/* </Table.Summary.Row> */}
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>ALLOCATED</strong>
                                                </Table.Summary.Cell>
                                                {dealerCode.map((code) => {
                                                    return (
                                                        <>
                                                            <Table.Summary.Cell>
                                                                <strong>{summary[code].allocated}</strong>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    );
                                                })}
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>FREE</strong>
                                                </Table.Summary.Cell>
                                                {dealerCode.map((code) => {
                                                    return (
                                                        <>
                                                            <Table.Summary.Cell>
                                                                <strong>{summary[code].free}</strong>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    );
                                                })}
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            ></Table>

                        </Card>
                    ) : null}
                </>
            </div>
        </>
    );

}



