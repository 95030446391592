import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Tag } from 'antd'

import './request-card.scss';
import { GlobalContext } from '../../../../../../Store';

export default function RequestCard({ request, index }) {
    const { disableDisplayPhoneNumber } = useContext(GlobalContext);


    return (
        <Link className="request-card" to={`requests/${request.requestId}`}>
            <div className="card">

                <h2 className="title amount ">
                    {request.customer.customerName}

                </h2>

                {/*disable PhoneNumber for kec and tl */}
                {!disableDisplayPhoneNumber ?
                    <h4 className="title">{request.customer.phone}</h4>
                : null}



                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>

                <h4>
                    {request.color}
                </h4>

                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>


                Created at {request.created_at}


            </div>
        </Link>
    )
}