import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Row, Popconfirm, message, Typography, Image } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import RequestInfo from './../../../../components/request-info/request-info';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import './finance-form.scss';

import moment from 'moment';

import CommentBlock from './../../../common/components/comment-block/comment-block';
import Summary from './../../../../components/summary/summary';

import RequestForm from './../../../common/resources/request-form/request-form';
import { FinanceBanks, FinanceBranches, Payouts, Items, Departments } from '../../../../models';
import { cityKeys } from '../../../global-config';

// import ImageWrapper from './../../../../components/image-wrapper/image-wrapper';

const { Title } = Typography;

const dataCollectionStep = {
    isForm: true,
    caption: 'Data Collection',
    path: '/finance/:city/:id',
    // post_status: 'Data Collected',
    fields: [
        {
            type: 'radio',
            options: ['In House', 'Direct', 'Own Fund'],
            caption: 'Finance Type',
            field: 'type',
        },

        {
            type: 'input',
            caption: 'Bank',
            field: 'bank',
            condition: (values) => {
                return values.type && values.type !== 'Own Fund';
            },
        },

        // {
        //     type: 'input',
        //     caption: 'Branch',
        //     field: 'branch',
        //     condition: (values) => {
        //         return values.type && values.type !== 'Own Fund'
        //     }
        // },

        {
            type: 'input',
            caption: 'Requested Amount',
            field: 'requestedAmount',
            condition: (values) => {
                return values.type && values.type !== 'Own Fund';
            },
        },

        {
            type: 'textarea',
            caption: 'Remarks',
            field: 'remarks',
        },
    ],
    extraParams: (request, params) => {
        let creation = {
            data_updated_at: moment().format('DD/MM/YYYY HH:mm'),
            data_updated_date: moment().startOf('day').valueOf(),
            data_updated_time: moment().valueOf(),
        };

        if (params.type === 'Own Fund') {
            return {
                finance: {
                    ...creation,
                    ...params,
                    status: 'Disbursement Pending',
                },
            };
        } else {
            return {};
        }
    },
};

export default function FinanceForm({ match, step, history }) {
    const { user } = useContext(GlobalContext);

    const [booking, setBooking] = useState({});

    const [editFinanceStatus, seteditFinanceStatus] = useState(false);

    const [request, setRequest] = useState({ finance: {}, customer: {} });

    const { params } = match;

    const { city, id } = params;

    const [loader, setLoader] = useState(true);

    const [itemId, setItemId] = useState();

    const [deptId, setDeptId] = useState();

    var cityKey = cityKeys

    useEffect(() => {
        loadData();
        getDepartmentId();
        getItemId();
    }, []);

    function getItemId() {
        let qry = [
            {
                field: 'category',
                value: 'Finance Services',
            },
        ];

        return Items.get(qry).then((result) => {
            setItemId(result.items[0].id);
        });
    }

    function getDepartmentId() {
        let qry = [
            {
                field: 'description',
                value: 'Department Handling Finance',
            },

            {
                field: 'dealer_code',
                value: cityKey[match.params.city],
            },
        ];

        return Departments.get(qry).then((result) => {
            setDeptId(result.departments[0].id);
        });
    }

    function loadData() {
        setLoader(true);

        FirebaseUtils.getRequest(id).then((result) => {
            if (result) {
                let data = { ...{ finance: {} }, ...result };

                console.log(data);

                // if(data.finance.expected_retail_date){

                //     data.finance.expected_retail_date = moment(data.finance.expected_retail_date);
                // }

                setRequest(data);

                if (data.bookingNo) {
                    getBooking(data.bookingNo);
                }

                setLoader(false);
            } else {
                history.goBack();

                message.error('Error loading request');
            }
        });
    }

    function getBooking(id) {
        FirebaseUtils.getBooking(id).then((result) => {
            var booking = result.data();

            if (booking) {
                console.log(booking);

                setBooking(booking);
                // We need to get the variant Code
            } else {
            }
        });
    }

    /**
     * Start over the finance process
     *
     * @param {*} id
     */
    function startOver() {
        var params = {
            finance: {
                ...request.finance,
                status: 'pending',
            },
        };

        FirebaseUtils.updateRequestGeneric(request.id, params, 'pending', 'Finance Process has been started from beginning', 'finance').then(
            (result) => {
                history.goBack();
            }
        );
    }

    return (
        <div className="allocation-form request-detail">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="page-header">
                            <h1 className="page-heading">Finance Details</h1>

                            <Button
                                size="small"
                                onClick={() => {
                                    loadData();
                                }}
                            >
                                Refresh
                            </Button>
                        </div>

                        <div className="split-container">
                            <div className="left">
                                {/* Vehicle Card */}
                                <RequestInfo booking={booking} request={request} />
                            </div>

                            <div className="right">
                                <div className="vehicle-card">
                                    <Title level={3}>{step.caption} </Title>

                                    <p>Follow up with the financier and update the status of the documents collected</p>

                                    <RequestForm
                                        formContent={request}
                                        modelIndex="requestId"
                                        model={'finance'}
                                        step={step}
                                        callback={(params, values) => {
                                            loadData();
                                            console.log(values);
                                            // For Finance Approved , Log the payouts
                                            if (params.finance.status === 'Disbursement Pending' && params.finance.type !== 'Own Fund') {

                                                let bank_name;

                                                let branch_name;

                                                FinanceBranches.getRecord(values.branch_id)
                                                    .then((res) => {
                                                        console.log(res);

                                                        return { bank: res.bank, branch: res.name };
                                                    })
                                                    .then((br) => {
                                                        
                                                        let payoutEntry = {
                                                            ...values,
                                                            ...request.customer,
                                                            requestId: request.id,
                                                            bookingNo: request.bookingNo,
                                                            city: request.city,
                                                            variant: request.variant,
                                                            status: 'Invoice Pending',
                                                            bank: br.bank,
                                                            branch: br.branch,
                                                            item_id: itemId,
                                                            department_id: deptId,
                                                        };

                                                        Payouts.add(payoutEntry).then(() => { });
                                                    });
                                            }

                                            if (params.finance.status === 'Disbursed') {
                                                var params = {
                                                    finance: {
                                                        ...params.finance,

                                                        status: 'Disbursement',
                                                        process: 'complete',
                                                    },
                                                };

                                                FirebaseUtils.updateRequestGeneric(request.id, params, 'Finance Disbursed', null, 'finance').then(
                                                    () => {
                                                        message.success('Thank you. The changes have been captured');
                                                    }
                                                );

                                                // #todo Add Collection here
                                            }

                                            history.goBack();
                                        }}
                                    />
                                </div>

                                {/* Once the initial Data is collected , we show the below form to update status */}
                                {request.finance.status !== 'pending' && request.finance.type && request.finance.type !== 'Own Fund' ? (
                                    <div className="vehicle-card">
                                        {request.finance.type && request.finance.type !== 'Own Fund' ? (
                                            <>
                                                {editFinanceStatus ? (
                                                    <div className="document-status">
                                                        <RequestForm
                                                            formContent={request}
                                                            modelIndex="requestId"
                                                            model={'finance'}
                                                            step={dataCollectionStep}
                                                            callback={(params, values) => {
                                                                loadData();

                                                                seteditFinanceStatus(false);
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="">
                                                        <Title level={3}>Finance Status </Title>

                                                        <div gutter={0} className="detail-wrapper">
                                                            <div className="detail-element">
                                                                <Row span={12}>
                                                                    <span>Current Status</span>
                                                                </Row>
                                                                <Row span={12}>
                                                                    <h2 className="title">{request.finance.status}</h2>
                                                                </Row>
                                                            </div>

                                                            <div className="detail-element">
                                                                <Row span={12}>
                                                                    <span>Bank</span>
                                                                </Row>
                                                                <Row span={12}>
                                                                    <h2 className="title">{request.finance && request.finance.bank}</h2>
                                                                </Row>
                                                            </div>

                                                            {request.finance.requestedAmount ? (
                                                                <div className="detail-element">
                                                                    <Row span={12}>
                                                                        <span>Requested Amount</span>
                                                                    </Row>
                                                                    <Row span={12}>
                                                                        <h2 className="title">{request.finance.requestedAmount}</h2>
                                                                    </Row>
                                                                </div>
                                                            ) : null}

                                                            {request.finance.sanctioned_amount ? (
                                                                <div className="detail-element">
                                                                    <Row span={12}>
                                                                        <span>Sanctioned Amount</span>
                                                                    </Row>
                                                                    <Row span={12}>
                                                                        <h2 className="title">{request.finance.sanctioned_amount}</h2>
                                                                    </Row>
                                                                </div>
                                                            ) : null}

                                                            {request.finance.deliveryOrder ? (
                                                                <div className="detail-element">
                                                                    <Row span={12}>
                                                                        <span>Delivery Order</span>
                                                                    </Row>
                                                                    <Row span={12}>
                                                                        <h2 className="title">
                                                                            {request.finance.deliveryOrder.type === 'application/pdf' ? (
                                                                                <embed
                                                                                    src={request.finance.deliveryOrder.url}
                                                                                    width="500"
                                                                                    height="375"
                                                                                    type="application/pdf"
                                                                                />
                                                                            ) : (
                                                                                <Image width={200} src={request.finance.deliveryOrder.url} />
                                                                            )}
                                                                        </h2>
                                                                    </Row>
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {/* Edit Mode Toggle */}
                                                        {[
                                                            'Data Collected',
                                                            'Documents Collected',
                                                            'Login',
                                                            'Finance Approval Pending',
                                                            'Delivery Order Pending',
                                                            'Disbursement Pending',
                                                        ].indexOf(request.finance.status) !== -1 ? (
                                                            <>
                                                                <Button
                                                                    size="small"
                                                                    onClick={() => {
                                                                        seteditFinanceStatus(true);
                                                                    }}
                                                                    type="secondary"
                                                                    className="submit-button"
                                                                >
                                                                    Update Status
                                                                </Button>

                                                                <Popconfirm
                                                                    title="Are you sure you want to start over this case . All the progress so far will be lost ? "
                                                                    onConfirm={() => {
                                                                        startOver();
                                                                    }}
                                                                    onCancel={() => { }}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button size="small" type="primary" className="submit-button">
                                                                        Start Over
                                                                    </Button>
                                                                </Popconfirm>
                                                            </>
                                                        ) : null}
                                                        {/* Edit Mode Toggle */}
                                                    </div>
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                ) : null}

                                {booking['Booking No'] ? <CustomerProof booking={booking} /> : null}

                                {/* Summary */}
                                <Summary booking={booking} request={request} />
                                {/* Summary Ends */}

                                <CommentBlock id={request.id} />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}

function CustomerProof({ caption, booking }) {
    let proofs = Object.keys(booking.proof || {});

    return (
        <div className="vehicle-card card">
            <>
                <Title level={3}>{caption}</Title>

                {proofs && proofs.length ? <ImageWrapper content={booking.proof} images={proofs} type="array" /> : <p>No proofs attached</p>}
            </>
        </div>
    );
}

function ImageWrapper({ type, images, content }) {
    return (
        <div className="vehicle-card card">
            <div gutter={0} className="detail-wrapper">
                <div className="detail-element">
                    <div span={12}>
                        {type === 'array' ? (
                            <>
                                {images.map((file) => {
                                    return (
                                        <>
                                            <Title level={4}>{file}</Title>

                                            {content[file].type === 'application/pdf' ? (
                                                <embed src={content[file].url} width="500" height="375" type="application/pdf" />
                                            ) : (
                                                <Image width={200} src={content[file].url} />
                                            )}
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            <>
                                {images.type === 'application/pdf' ? (
                                    <embed src={images.url} width="500" height="375" type="application/pdf" />
                                ) : (
                                    <Image width={200} src={images.url} />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
