import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Tag, Button, Row, message, Alert, Image,Typography } from 'antd';
import { Form, Input, Radio, Select, InputNumber, DatePicker } from 'antd';

import { Link } from 'react-router-dom';

import Notify from '../../../../components/ui_elements/Notify';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import Header from '../../../../components/page-header/page-header';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import RequestForm from './../../../common/resources/request-form/request-form';

import BaseForm from './../../../common/resources/base-form/base-form';

import './ccm-approval-form.scss';
import RequestInfo from '../../../../components/request-info/request-info';

const { Title } = Typography;

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};

export default function ApprovalForm({ props, step, match, history }) {
    const [reports, setReports] = useState([]);

    const { user } = useContext(GlobalContext);

    const { params } = match;

    const { id, city } = params;

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (step.endpoint === 'cancellations') {
            FirebaseUtils.getBaseRecord(id, step.model).then((result) => {
                var request = result;

                setReports({ ...request });

                setLoader(false);
            });
        } else {
            FirebaseUtils.getRequest(id).then((result) => {
                var request = result;

                setReports({ ...request });

                setLoader(false);
            });
        }
    }, []);

    return (
        <div className="reports">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    {step.endpoint === 'cancellations' ? (
                        <>
                            <div className="vehicle-card">
                                <div className="about-us page-content">
                                    <h1 className="page-heading">Cancel {reports.type}</h1>
                                </div>
                                <p>Update any necessary information and see the status of your allocation below</p>
                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <Row span={12}>
                                                <h3>Customer Name </h3>
                                            </Row>
                                        </Row>
                                        <Row span={12}>
                                            <h2 className="title amount">{reports.customerName}</h2>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Email :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{reports.email}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Booking No </h3>
                                        </Row>
                                        <Row span={12}>
                                            <a href={`/${city}/bookings/${reports.bookingId}`}>{reports.bookingId}</a>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Dealer Code :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{reports.dealerCode}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Phone No :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{reports.phone}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Attachments :</h3>
                                        </Row>
                                        <>
                                            {reports.cancellation_request &&
                                                reports.cancellation_request.map((file) => {
                                                    return (
                                                        <>
                                                            {file.type === 'application/pdf' ? (
                                                                <embed src={file.url} width="500" height="375" type="application/pdf" />
                                                            ) : (
                                                                <Image width={200} src={file.url} />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                        </>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Reason :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{reports.reason}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Remarks :</h3>
                                        </Row>
                                        <Row span={12}>
                                            <span className="">{reports.remarks}</span>
                                        </Row>
                                    </div>
                                    {reports.sm_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from SM :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{reports.sm_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {reports.ccm_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from CCM :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{reports.ccm_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {reports.mgt_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from Management :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{reports.mgt_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    {reports.accounts_remarks ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks from Accounts :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{reports.accounts_remarks}</span>
                                            </Row>
                                        </div>
                                    ) : null}
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <h3>Status </h3>
                                        </Row>
                                        <Row span={12}>
                                            <span>{reports.status}</span>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <BaseForm
                                index={id}
                                formContent={reports}
                                modelIndex="id"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : step.endpoint == 'requests' ? (
                        <>
                            <div className="about-us page-content">
                                <h1 className="page-heading">Booked Vehicle</h1>
                                <p>Please inform the customer regarding funds and update remarks</p>
                            </div>
                            <div gutter={0} className="detail-wrapper">
                                <Tag color="purple">{reports.status}</Tag>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <Row span={12}>
                                            <h3>Customer </h3>
                                        </Row>
                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title amount">{reports.customer.customerName} </h2>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Phone </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{reports.customer.phone}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Remarks </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{reports.customer.remarks}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Variant </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{reports.variant}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Color</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{reports.color}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Consultant :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{reports.kec}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Team Leader :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{reports.teamLeader}</span>
                                    </Row>
                                </div>
                                <Link to={`/requests/${reports.id}`}>View Request</Link>
                            </div>

                            <RequestForm
                                formContent={reports}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : step.caption === 'Delivery Checklist' ? (
                        <>
                            <Fragment>

                                <div className='page-content'>

                                    <h1 className="page-heading">
                                        Complete Delivery
                                    </h1>


                                    <div className="split-container">

                                        <div className="left" style={{width:'50%'}}>

                                            {/* Vehicle Card */}
                                            <RequestInfo request={reports} />

                                        </div>


                                       
                                    </div>
                                </div>
                            </Fragment>
                        </>
                    ) : (
                        <>
                            <div className="about-us page-content">
                                <h1 className="page-heading">Customer Payment</h1>
                                <p>Please provide the customer id and receipt details after entering into dms</p>
                            </div>
                            <div gutter={0} className="detail-wrapper">
                                <Tag color="purple">{reports.status}</Tag>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <Row span={12}>
                                            <h3>Customer </h3>
                                        </Row>
                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title amount">{reports.customer.customerName} </h2>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Phone </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{reports.customer.phone}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Remarks </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{reports.customer.remarks}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Variant </h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{reports.variant}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Color</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span>{reports.color}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Consultant :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{reports.kec}</span>
                                    </Row>
                                </div>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <h3>Team Leader :</h3>
                                    </Row>
                                    <Row span={12}>
                                        <span className="">{reports.teamLeader}</span>
                                    </Row>
                                </div>
                                <Link to={`/requests/${reports.id}`}>View Request</Link>
                            </div>
                            <Alert message="Approval Request" description={`Please provide approval for the request`} type="info" />

                            <RequestForm
                                formContent={reports}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    )}
                </Fragment>
            )}
        </div>
    );
}
