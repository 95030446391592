/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from "react"

import { Typography, Button, message, Input, Upload, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom'

// import './generic-add.scss'

import XLSX from 'xlsx';

import FirebaseUtils from './../../../../utils/firebase.utils';

import { UploadOutlined } from '@ant-design/icons';


import useDeviceDetect from './../../../../hooks/device-detect';

import PlaceHolder from './../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from './../../../../Store';

const { Title } = Typography;

const { TextArea } = Input;

/**
 * Generic List 
 * 
 * @param {*} param0 
 */
function DataCorrection({ match, history, model, schema, collection, columns: cols }) {

    const [content, setContent] = useState({});

    const [records, setRecords] = useState([]);


    const [files, setFiles] = useState([])

    const [loading, setLoading] = useState(false);

    const step = {
        fields: cols
    }

    useEffect(() => {

        setLoading(true);

        var queries = [{
            field: 'billing.status',
            value: 'pending'
        }];

        FirebaseUtils.getListing('kannur', queries, 'bookings').then((result) => {

            setRecords(result);

            var r = {};

            console.log(result);

            // result[model].forEach((item) => {

            //     var key = findIndex(item);

            //     r[key] = item;
            // })

            // setContent(r);

            setLoading(false);
        });

    }, []);


    function handleFile(f) {

        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {

            const bstr = e.target.result;

            // let csvData = csvJSON(bstr);

            // console.log(csvData);

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws, {
                header: 2,
                range: 0
            });


            console.log('data in handle:', data);

            processData(data);

        };

        if (rABS) reader.readAsBinaryString(f); else reader.readAsArrayBuffer(f);
    }


    /**
     * 
     * Derive the index of the table from element
     * 
     * @param {]} element 
     */
    function findIndex(element) {

        var key;

        if (typeof schema.tableIndex === 'function') {

            key = schema.tableIndex(element);

        } else {
            // index = schema.tableIndex;

            key = element[schema.tableIndex];

        }

        return key;
    }


    function csvJSON(csv) {
        const lines = csv.split('\n')
        const result = []
        const headers = lines[0].split(',')

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i])
                continue
            const obj = {}
            const currentline = lines[i].split(',')

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j]
            }
            result.push(obj)
        }


        return result
    }

    /**
     * 
     * Prepare the data according to the configuration
     * 
     * @param {*} data 
     */
    function processData(data) {


        setContent(data);

    }

    function approveUpload() {

        console.log(content);


        // Promise.all(content.map((entry) => {

        //     var bookingNo = entry['Booking No'];

        //     var values = {
        //         current: {
        //             status: entry['STATUS']
        //         },

        //     }

        //     if (entry['FIRST COMMENT']) {
        //         values.current.firstComment = entry['FIRST COMMENT']
        //     }


        //     if (entry['SECOND COMMENT']) {
        //         values.current.secondComment = entry['SECOND COMMENT']
        //         // values.secondStatus
        //     }


        //     return FirebaseUtils.updateBookingStatus('kannur', bookingNo, values);

        // })).then((result) => {

        //     console.log(result);

        // })


    }


    const uploadProps = {
        onRemove: file => {

            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });

        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: file => {

            setFiles([...files, file]);

            return false;
        },
        files,
    };

    const SheetJSFT = [
        "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
    ].map(function (x) { return "." + x; }).join(",");


    return (
        <section className="generic-list">

            {/* Table Header */}
            <div className="table-header">

                <div className="table-title">
                    <Title level={4}>
                        Correct Accessories Pricing
                    </Title>
                </div>

                <div className="table-actions">

                    <div className="button-container">

                        {/* <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button> */}

                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <>

                        <div className="card">



                            <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                <Button size={'small'} icon={<UploadOutlined />}>Select File</Button>
                            </Upload>

                            <Button onClick={approveUpload} size={'small'} icon>Approve Upload</Button>



                        </div>

                    </>
            }

        </section>
    );
}

export default withRouter(DataCorrection);
