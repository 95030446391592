import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom';

import { Skeleton, Space, Switch, Tabs, Button, Badge, Tag, Table, DatePicker, Statistic, Typography, Drawer, Alert }
from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import moment from 'moment';

import { ExportXLS } from './ExportXLS';

import './cancellations.scss';


const { TabPane } = Tabs;

const { Title, Text } = Typography;

const dateFormat = 'DD/MM/YYYY';

const { RangePicker } = DatePicker;

export default function CustomerReceipts({ history }) {
    const { user } = useContext(GlobalContext);

    // const [today, setToday] = useState(moment());
    const [range, setRange] = useState([moment().subtract(1, 'months').startOf('day'), moment().endOf('day')]);


    function callback(key) {
        console.log(key);
    }

    function updateTime(dt) {
        setRange(dt);

        // loadData(dt);
    }


    const actions = (
        <RangePicker
            inputReadOnly
            format={'DD/MM/YYYY'}
            value={range}
            onChange={updateTime}
            ranges={{
                Today: [moment(), moment()],

                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                'This Week': [moment().startOf('week'), moment().endOf('week')],

                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                'This Month': [moment().startOf('month'), moment().endOf('month')],

                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            }}
        />
    );

    return (
        <section className="customer-receipts">
            <Title level={3}>Cancellations</Title>

            {/* {
                user.role !== 'finance' ?
                    (
                        <>
                            <Alert
                                message="Below are Customer Collections for which accounts verification is pending. Once verified Please use the Accounts Overview to make sure DMS receipts are shared for the customers without delays."
                                type="warning"
                                showIcon
                            />

                            <div className="score">
                                {user.locations.map((location, index) => {
                                    return (
                                        <div className={`dashboard-item`} key={index}>
                                            <PendingList url={location} />
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )
                    :
                    null
            } */}

            <Tabs onChange={callback} tabBarExtraContent={actions}>
                {user.locations.map((location, index) => {
                    return (
                        <TabPane tab={`${location} Cancellations`} key={index}>
                            <CollectionList date={range} history={history} url={location} />
                        </TabPane>
                    );
                })}
            </Tabs>
        </section>
    );
}

const CollectionList = ({ url, history, date }) => {
    const { user, isMobile } = useContext(GlobalContext);

    const [view, setView] = useState(isMobile);

    const [expRecord, setExptRecords] = useState();

    const [collections, setCollections] = useState([]);

    const [download, setDownload] = useState();



    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getData();
    }, [date]);



  /*  useEffect(() => {
        // Select required fields from customer json array. (for exporting to csv document)
        setDownload(
            collections &&
            collections
                .slice(0)
                .reverse()
                .map((item, index) => {
                    console.log(index)
                    console.log(item)
                    console.log(item.customerName)
                    return {

                        Index: index + 1,
                        Customer_Name: item.customerName,
                        Phone: item.phone,
                        Amount: item.amount,
                        Payment_Mode: item.payment_mode,
                        On_Account: item.type,
                        KEC: item.created_by,
                        Created_At: item.created_at,
                        Status: item.status,

                    };
                })
        );
    }, [collections]);
*/



    function getData() {
        setLoader(true);

        var queries = [
            {
                field: 'created_time',
                operator: '>=',
                // value: moment(today).startOf('day').valueOf(),
                value: moment(date[0]).valueOf(),
            },
            {
                field: 'created_time',
                operator: '<=',
                value: moment(date[1]).valueOf(),
            },
        ];

        // if (user.role === 'finance') {
        //     queries.push({
        //         field: 'source',
        //         operator: 'in',
        //         value: ['finance'],
        //     });
        // } else {
        //     queries.push({
        //         field: 'source',
        //         operator: 'in',
        //         value: ['kec', 'cashier', 'finance', 'sm', 'tl', 'KIA Website', 'Online'],
        //     });
        // }

        console.log(queries, url);

        FirebaseUtils.getRecords(url, queries, 'cancellations').then(
            (result) => {
                console.log(result);
                setLoader(false);
                // getMatching(result);
                setCollections(result['cancellations']);
                var exporData = getExportData(result['cancellations'], columns);

                setExptRecords(exporData);

            });

    }



    /**
     *
     * Get matching Bank Entries
     *
     * @param {*} customer_receipts
     */
    function getMatching(customer_receipts) {
        setLoader(true);

        var queries = [
            {
                field: 'source',
                operator: '==',
                value: 'bank',
            },
            {
                field: 'reconcile_status',
                value: 'pending',
            },
            {
                field: 'isCredit',
                value: true,
            },
        ];

        FirebaseUtils.getBankCredits(queries).then((result) => {
            customer_receipts
                .filter((entry) => !entry.verified)
                .forEach((entry) => {
                    entry.matching = [];

                    result.forEach((receipt) => {
                        var condition;

                        if ((entry.utr !== '' || entry.ref !== '') && parseInt(receipt['credit']) === entry.amount) {
                            condition =
                                receipt['description'].indexOf(entry.ref) !== -1 ||
                                receipt['description'].indexOf(entry.utr) !== -1 ||
                                receipt['ref'].indexOf(entry.utr) !== -1 ||
                                receipt['ref'].indexOf(entry.ref) !== -1;

                            if (condition) {
                                // Push the entries to matching
                                entry.matching.push(receipt);
                            }
                        }
                    });
                });

            console.log(customer_receipts);

            setCollections([...customer_receipts]);

            setLoader(false);
        });
    }

    function changeView(result) {
        setView(result);
    }

    function getExportData(data, columns) {
        console.log(data)
        return data.map((record, index) => {

            var content = {
                '#': index + 1,
            };
            columns.forEach((column) => {

                if (column.title === '#') {

                }
                else
                    content[column.title] = record[column.dataIndex]

            })
            console.log(content)
            return content
        })
    }



    return (
        <>
            <div className="list-header">
                <p className="page-desc">
                    {/* Listing collections from */}
                    {loader ? '. Loading data' : `. ${Object.keys(collections).length} results`}
                </p>
                <div className="right">
                    <div className="page-actions">
                        <Button
                            onClick={() => {
                                getData();
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            Refresh
                        </Button>


                        {/*table data export to csc component*/}
                        {expRecord && <ExportXLS fileName="VehicleCancellation" csvData={expRecord} />}

                        <Switch
                            defaultChecked
                            onChange={changeView}
                            checked={view}
                            checkedChildren={<OrderedListOutlined />}
                            unCheckedChildren={<PicCenterOutlined />}
                        />


                    </div>
                </div>
            </div>

            <div className="report-listing-wrapper">
                {loader ? (
                    <PlaceHolder type="listing" />
                ) : (
                    <>{!view ? <TableView data={collections} /> : <CardList url={url} data={collections} />}</>
                )}

            </div>
        </>

    );

};

/**
 *
 * Showing the pending list
 *
 * @param {*} param0
 */
export const PendingList = ({ url }) => {
    const [collections, setCollections] = useState([]);

    const [loader, setLoader] = useState(true);

    const { user } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        setLoader(true);

        var queries = [
            {
                field: 'source',
                operator: 'in',
                value: ['kec', 'cashier', 'finance', 'sm', 'tl', 'KIA Website', 'Online'],
            },
            {
                field: 'verified',
                value: false,
            },
        ];

        FirebaseUtils.getCollections(url, queries).then((result) => {
            setLoader(false);

            if (result.length) {
                getMatching(result);
            } else {
                setCollections([]);
            }

        });

    }

    /**
     *
     * Get matching Bank Entries
     *
     * @param {*} customer_receipts
     */
    function getMatching(customer_receipts) {
        setLoader(true);

        var queries = [
            {
                field: 'source',
                operator: '==',
                value: 'bank',
            },
            {
                field: 'reconcile_status',
                value: 'pending',
            },
            {
                field: 'isCredit',
                value: true,
            },
        ];

        FirebaseUtils.getBankCredits(queries).then((result) => {
            customer_receipts
                .filter((entry) => !entry.verified)
                .forEach((entry) => {
                    entry.matching = [];

                    result.forEach((receipt) => {
                        var condition;

                        if ((entry.utr !== '' || entry.ref !== '') && entry.amount === parseInt(receipt['credit'])) {
                            condition =
                                receipt['description'].indexOf(entry.ref) !== -1 ||
                                receipt['description'].indexOf(entry.utr) !== -1 ||
                                receipt['ref'].indexOf(entry.utr) !== -1 ||
                                receipt['ref'].indexOf(entry.ref) !== -1;

                            if (condition) {
                                // Push the entries to matching
                                entry.matching.push(receipt);
                            }
                        }
                    });
                });

            // console.log(customer_receipts);

            setCollections(customer_receipts);

            setLoader(false);
        });
    }


    return (
        <>
            <div className="report-listing-wrapper">
                {loader ? (
                    <div className="">
                        <Skeleton />
                    </div>
                ) : (
                    <>
                        {collections.length ? (
                            <div className={`dashboard-item`}>
                                <div className="card dashboard-item">
                                    <Statistic title={`${url}`} value={collections.length} />

                                    <a onClick={showDrawer}>View</a>
                                </div>
                            </div>
                        ) : null}
                    </>
                )}
            </div>

            <Drawer width={'80%'} placement="right" closable={false} onClose={onClose} visible={visible}>
                <Title level={3}>Pending List</Title>

                <Alert
                    message="Please make sure all the customer receipts are verified without delays so that it can proceed for DMS"
                    type="warning"
                    showIcon
                    closable
                />

                <TableView data={collections} />
            </Drawer>
        </>
    );
};
var columns

function TableView({ data }) {



    columns = [

        {

            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {


            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },

        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        //  console.log(dataIndex.phone)

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },



        {
            title: 'Payment Mode',
            key: 'payment_mode',
            onFilter: (value, record) => record.payment_mode.indexOf(value) !== -1,
            filters: [
                {
                    text: 'Cash',
                    value: 'Cash',
                },
                {
                    text: 'Net Banking',
                    value: 'Net Banking',
                },
                {
                    text: 'Transfer by Razorpay',
                    value: 'Transfer by Razorpay',
                },
                {
                    text: 'Payment Link by Razorpay',
                    value: 'Payment Link by Razorpay',
                },
                // {
                //     title: 'Type',
                //     key: 'type',
                //     onFilter: (value, record) => record.type.indexOf(value) !== -1,
                //     filters: [
                //         {
                //             text: 'Booking',
                //             value: 'booking',
                //         },
                //     ],
                //     render: (text, record) => {
                //         return (
                //             <Space size="middle">
                //                 <Tag color={'magenta'}>{text.type || 'Online Transfer'}</Tag>
                //             </Space>
                //         );
                //     },
                // },

                // {
                //     title: 'Source',
                //     key: 'source',
                //     onFilter: (value, record) => record.source.indexOf(value) !== -1,
                //     filters: [
                //         {
                //             text: 'Cashier',
                //             value: 'cashier',
                //         },
                //         {
                //             text: 'KEC',
                //             value: 'kec',
                //         },
                //         {
                //             text: 'Finance',
                //             value: 'finance',
                //         },
                //         {
                //             text: 'SM',
                //             value: 'sm',
                //         },
                //         {
                //             text: 'TL',
                //             value: 'tl',
                //         },
                //     ],
                //     render: (text, record) => {
                //         return (
                //             <Space size="middle">
                //                 <Tag color="blue">{text.source}</Tag>
                //             </Space>
                //         );
                //     },
                // },
            ],
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Tag color="blue">{text.payment_mode}</Tag>
                    </Space>
                );
            },
        },

        {
            title: 'On Account',
            dataIndex: 'type',
            key: 'type',
        },

        {
            title: 'KEC',

            dataIndex: 'created_by_name',
            key: 'created_by_name',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },

        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                let cls, caption;

                if (text === 'Cancellation Completed') {
                    cls = 'success';
                    caption = 'Cancellation Completed';
                } else {
                    caption = text;
                    cls = 'warning';
                }

                return (
                    <Space size="middle">
                        <Tag color={cls}>{caption}</Tag>
                        {/* {record.matching && record.matching.length ? <Badge status="success" text={`${record.matching.length} Matching`} /> : null} */}
                    </Space>
                );

            },
        },

        {
            title: '',
            key: 'action',
            render: (text, record) => {
                let city = text.dealerCode == 'KL305' ? 'kozhikode' : text.dealerCode == 'KL306' ? 'kannur' : text.dealerCode == 'KL307'?'malappuram':text.dealerCode == 'KL508'?'kasargod':text.dealerCode == 'KL505'?'kondotty':text.dealerCode == 'KL507'?'vadakara':'tirur';
                return (
                    <Space size="middle">
                        <Link to={`/${city}/cancellations/${text.id}`}>View</Link>
                    </Space>
                );
            },
        },

    ];



    return (

        <Table
            // loading={}
            width="100%"
            size="small"
            scroll={{ x: true }}
            // scroll={{ x: true, y: 750 }}
            rowKey={(record) => record.index}

            dataSource={data}
            columns={columns}
            pagination={false}
        >

        </Table>


    );


}




function CardList({ data, url }) {

    return data.map((report, index) => {
        return <CollectionCard city={url} request={report} index={index} key={index} />;
    });

}

function CollectionCard({ request = {} }) {
    let city = request.dealerCode == 'KL305' ? 'kozhikode' : request.dealerCode == 'KL306' ? 'kannur' : request.dealerCode == 'KL307'?'malappuram':request.dealerCode == 'KL508'?'kasargod':request.dealerCode == 'KL505'?'kondotty':request.dealerCode == 'KL507'?'vadakara':'tirur';

    return (
        <Link className="request-card" to={`/${city}/cancellations/${request.id}`}>
            <div className="card">
                <h2 className="title">{request.name}</h2>

                <h3 className="title amount">{request.amount}</h3>

                <h4 className="title ">{request.city}</h4>

                <h4 className="title ">{request.phone}</h4>

                <h4>{request.remarks}</h4>

                <p className="address">
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                    {/* 
                <Badge status="processing" text={`${request.matching} Vehicles Matching`} /> */}
                </p>

                {/* Created at {request.created_at} by {request.created_by.name} */}
            </div>
        </Link>
    );
}
