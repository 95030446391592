import Base from './base';

import { Settings, Vehicles, Pricing } from './';

class BillDetail extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'bill-details';
    }

    get path() {
        return `bill-details`;
    }

    get getName() {
        return `bill-details`;
    }

    get = (queries) => {
        return this.getMethod(queries).then((result) => {
            return result;
        });
    };

    getPayoutItems = ({ bill_id }) => {
        var queries = [
            {
                field: 'bill_id',
                value: bill_id,
            },
        ];

        let total = 0;

        return this.get(queries).then((result) => {
            let roundValue;

            let queries = [
                {
                    field: 'name',
                    value: 'net-amount-rounding',
                },
                {
                    field: 'category',
                    value: 'basic', //doubt
                },
            ];

            return Settings.get(queries)
                .then((result) => {
                    return (roundValue = result && result.settings.length && result.settings[0].round_value);
                })
                .then((roundValue) => {
                    return Settings.getValue('calculation-rounding').then((settings) => {
                        if (result['bill-details'].length) {
                            var data = result['bill-details'];

                            let final_payout;

                            return Promise.all(
                                data.map((item) => {
                                    if (item.inclusive_of_tax === true) {
                                        final_payout = item.actual_payout;
                                    } else if (item.inclusive_of_tax === false) {
                                        final_payout = calculatedAmount(parseFloat(item.actual_payout));
                                    }

                                    function calculatedAmount(amt) {
                                        let cgst = amt * 0.09;
                                        let sgst = amt * 0.09;
                                        let total = amt + parseFloat(cgst) + parseFloat(sgst);
                                        return total;
                                    }
                                    console.log(final_payout);
                                    return { ...item, final_payout };
                                })
                            ).then((res) => {
                                console.log(res);
                                var total = res.reduce((a, b) => a + parseInt(b['final_payout']), 0);

                                return { res, total };
                            });
                        }
                    });
                });
        });
    };

    /**
     * Get the bill details and modify it for vehicle invoice
     *
     * @param {*} param0
     * @returns
     */
    getBillItems = ({ bill_id }) => {
        var queries = [
            {
                field: 'bill_id',
                value: bill_id,
            },
        ];

        var items = [];

        var net_amount = 0;

        var tcs_amount = 0;

        let grand_total = 0;

        let ex_showroom = 0;

        return this.get(queries).then(async (result) => {
           
                let queries = [
                    {
                        field: 'name',
                        value: 'net-amount-rounding',
                    },
                    {
                        field: 'category',
                        value: 'basic',
                    },
                ];


                return Settings.get(queries)
                    .then((result) => {

                        let roundValue;

                        return (roundValue = result && result.settings.length && result.settings[0].round_value);
                    })
                    .then((roundValue) => {
                        return Settings.getValue('calculation-rounding').then(async (settings) => {
                            if (result['bill-details'].length) {
                                var data = result['bill-details'][0];

                                const vehicle = await Vehicles.getRecord(data.item_id);

                                console.log(vehicle);

                                // Add Basic Price
                                items.push({
                                    description: `Price of ${data.item_name} : ${data.item_id} : ${vehicle['Exterior Color Name']}`,
                                    value: data.rate,
                                });

                                // Add Basic Price
                                items.push({
                                    description: `Discount`,
                                    value: data.item_discount_amount,
                                });

                                // Net Selling Price
                                items.push({
                                    description: `Net Selling Price`,
                                    value: data.taxable_amount,
                                });

                                // net_amount -= data.item_discount_amount

                                // GST Below
                                if (data.sgst_amount !== 0) {
                                    items.push({
                                        description: `CGST @ ${data.cgst_percent} %`,
                                        value: data.cgst_amount,
                                    });

                                    items.push({
                                        description: `SGST @ ${data.sgst_percent} %`,
                                        value: data.cgst_amount,
                                    });
                                } else {
                                    items.push({
                                        description: `IGST @ ${data.igst_percent} %`,
                                        value: data.igst_amount,
                                    });
                                }

                                items.push({
                                    description: `Compensation Cess @ ${data.cess_percent}  %`,
                                    value: data.cess_amount,
                                });

                                if (!data.tcsExempted) {
                                    if (data.net_amount > 1000000) {
                                        tcs_amount = data.net_amount * 0.01;
                                    }
                                }

                                net_amount = this.getRoundedAmount(data.net_amount, settings.round);

                                tcs_amount = this.getRoundedAmount(tcs_amount, settings.round);

                                grand_total += net_amount + tcs_amount;

                                grand_total = this.getRoundedAmount(grand_total, settings.round);

                                ex_showroom = data.ex_showroom  

                                //rounding net amount

                                let adjArr = coinAdjustment(grand_total, roundValue);

                                grand_total = adjArr[0];

                                let roundAmount = adjArr[1];

                                // function for net amount rounding
                                function coinAdjustment(amount, roundValue) {
                                    let decArr = [];

                                    decArr[0] = amount;
                                    decArr[1] = 0;

                                    if (roundValue > 0 && (amount * 100) % roundValue !== 0) {
                                        decArr[0] = amount * 100;
                                        decArr[0] = Math.round(decArr[0] / roundValue) * (roundValue / 100);
                                        decArr[1] = parseFloat((decArr[0] - amount).toFixed(2));
                                    }

                                    return decArr;
                                }

                                return { items: items, net_amount, ex_showroom, tcs_amount, roundAmount, grand_total };
                            } else {
                                return { items: items, net_amount, ex_showroom, tcs_amount, grand_total };
                            }
                        });
                    });
            });
        
    };

    getActiveEntries = (queries) => {
        var arr = [];

        return this.get(queries).then((result) => {
            result['bill-details'].forEach((item) => {
                if (item.description !== 'Statutory Charges' && (!item.status || item.status !== 'rejected')) {
                    let body = {
                        subItems: [],
                        ...item,
                        table: 'bill-details',
                        order: 1,
                    };

                    arr.push(body);
                }
            });

            return arr;
        });
    };

    //Function for parseFloat and rounding decimal
    getRoundedAmount = (value, round) => {
        value = parseFloat(value.toFixed(round));

        return value;
    };

    getDetailedPricing = (record, round) => {
        // For each record we have to check if the price is including tax or notification

        // If its including tax , we have reverse calculate to identify the basic price , tax and its values

        record = {
            quantity: record.quantity || 1,
            ...record,
            cgst: record.cgst,
            sgst: record.sgst,
            igst: record.igst,
        };

        if (record.tax_inclusive === true) {
            // Find the non taxable value

            record.price = parseFloat(record.price);

            let total_tax = record.cgst + record.sgst;

            let rate = (100 * record.price) / (100 + total_tax);

            record.rate = this.getRoundedAmount(rate, round);

            let total = record.rate * record.quantity;

            record.total = this.getRoundedAmount(total, round);

            //Finding discount amount and

            if (record.discount_type && record.discount_type === 'percent') {
                let discountAmount = (record.discount_value / 100) * record.total;

                record.discount_amount = this.getRoundedAmount(discountAmount, round);

                record.discount_percent = record.discount_value;

                record.taxable_value = record.total - record.discount_amount;
            } else if (record.discount_type && record.discount_type === 'amount') {
                record.discount_amount = record.discount_value;

                record.discount_percent = (record.discount_amount / record.total) * 100;

                record.discount_percent = this.getRoundedAmount(record.discount_percent);

                record.taxable_value = record.total - record.discount_amount;
            } else {
                record.taxable_value = record.total;
            }

            record.taxable_value = this.getRoundedAmount(record.taxable_value, round);

            let cgstAmount = (record.taxable_value * record.cgst) / 100;
            record.cgst_amount = this.getRoundedAmount(cgstAmount, round);

            let sgstAmount = (record.taxable_value * record.sgst) / 100;
            record.sgst_amount = this.getRoundedAmount(sgstAmount, round);

            let igstAmount = (record.taxable_value * record.igst) / 100;
            record.igst_amount = this.getRoundedAmount(igstAmount, round);

            // Finding net-amount
            let netAmount = record.taxable_value + cgstAmount + sgstAmount;

            record.net_amount = this.getRoundedAmount(netAmount, round);
        } else {
            record.price = parseFloat(record.price);

            let rate = record.price;

            record.rate = this.getRoundedAmount(rate, round);

            let total = record.rate * record.quantity;

            record.total = this.getRoundedAmount(total, round);

            //Finding discount amount

            if (record.discount_type && record.discount_type === 'percent') {
                let discountAmount = (record.discount_value / 100) * record.total;

                record.discount_amount = this.getRoundedAmount(discountAmount, round);

                record.discount_percent = record.discount_value;

                record.taxable_value = record.total - record.discount_amount;
            } else if (record.discount_type && record.discount_type === 'amount') {
                record.discount_amount = record.discount_value;

                record.discount_percent = (record.discount_amount / record.total) * 100;

                record.discount_percent = this.getRoundedAmount(record.discount_percent);

                record.taxable_value = record.total - record.discount_amount;
            } else {
                record.taxable_value = record.total;
            }

            record.taxable_value = this.getRoundedAmount(record.taxable_value, round);

            let cgstAmount = (record.taxable_value * record.cgst) / 100;
            record.cgst_amount = this.getRoundedAmount(cgstAmount, round);

            let sgstAmount = (record.taxable_value * record.sgst) / 100;
            record.sgst_amount = this.getRoundedAmount(sgstAmount, round);

            let igstAmount = (record.taxable_value * record.igst) / 100;
            record.igst_amount = this.getRoundedAmount(igstAmount, round);

            // Finding net-amount
            let netAmount = record.taxable_value + cgstAmount + sgstAmount;

            record.net_amount = this.getRoundedAmount(netAmount, round);
        }

        return record;
    };

    salesReturnDetailedPricing = (record, bill, round) => {
        let netAmount = 0;

        let taxable = 0;
        let rate = 0;
        // Find the non taxable value

        if (!record.rate) {
            record.price = parseFloat(record.price);

            let total_tax = record.cgst_percent + record.sgst_percent;

            rate = (100 * record.price) / (100 + total_tax);
        }

        rate = record.rate;

        record.rate = this.getRoundedAmount(rate, round);

        let total = record.rate * record.return_quantity;

        record.return_total = this.getRoundedAmount(total, round);

        //Item discount calculation per item's quantity

        if (record.item_discount_amount && record.item_discount_amount !== 0) {
            let perQty = parseFloat(record.item_discount_amount / record.quantity);

            let iDsc = record.return_quantity * perQty;

            record.return_item_discount = this.getRoundedAmount(iDsc, round);
        } else {
            record.return_item_discount = 0;
        }

        taxable = record.return_total - record.return_item_discount;

        record.return_taxable_amount = this.getRoundedAmount(taxable, round);

        if (record.igst_amount === 0) {
            let cgstAmount = (record.return_taxable_amount * record.cgst_percent) / 100;
            record.return_cgst_amount = this.getRoundedAmount(cgstAmount, round);

            let sgstAmount = (record.return_taxable_amount * record.sgst_percent) / 100;
            record.return_sgst_amount = this.getRoundedAmount(sgstAmount, round);

            record.return_igst_amount = 0;
        } else {
            let igstAmount = (record.return_taxable_amount * record.igst_percent) / 100;
            record.return_igst_amount = this.getRoundedAmount(igstAmount, round);

            record.return_cgst_amount = 0;

            record.return_sgst_amount = 0;
        }

        // Finding net-amount
        netAmount = record.return_taxable_amount + record.return_cgst_amount + record.return_sgst_amount + record.return_igst_amount;

        //Bill discount calculation per item's quantity

        if (record.edit_discount) {
            record.return_bill_discount = record.edit_discount;
        } else {
            if (record.bill_discount_applied && record.bill_discount_applied !== 0) {
                let perQty = parseFloat(record.bill_discount_applied / record.quantity);

                let bDsc = record.return_quantity * perQty;

                record.return_bill_discount = this.getRoundedAmount(bDsc, round);
            } else {
                record.return_bill_discount = 0;
            }
        }

        let itemNet = netAmount;

        record.return_item_net_amount = this.getRoundedAmount(itemNet, round);

        netAmount -= record.return_bill_discount;

        record.return_net_amount = this.getRoundedAmount(netAmount, round);

        return record;
    };
}

export default BillDetail;
