import React from 'react'
import { Link } from 'react-router-dom'

import { Tag } from 'antd'

import './ReportCard.scss';

export default function ReportCard({ report, index }) {

    return (
        <Link className="report-item" to={`/${report.city}/report/${report.receiptId}`}>
            <div className="card">
                <h3 className="title">
                    {report['description']}
                </h3>

                <p>
                    {report['ref']}
                </p>

                <h2 className="title amount">
                    {report['credit'] ? 'Rs' : ''} {report['credit']}
                </h2>

                <p className="address">
                    Deposited on {report['txn_date']}
                    <Tag color="magenta">{report.bank}</Tag>
                </p>

            </div>
        </Link>
    )
}