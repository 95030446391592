

import React, { useState, useEffect } from 'react';

import FirebaseUtils from './../../../../utils/firebase.utils';

import moment from 'moment';

import { Skeleton, Drawer } from 'antd';

import { EventCalendar } from './../../resources/calendar/calendar';

import RequestDetail from '../request-detail/request-detail';



/**
 *
 * Delivery Calendar
 *
 * @returns
 */
export default function DeliveryCalendar({ url }) {
    const [visible, setVisible] = useState(false);

    const [selected, setSelected] = useState(null);

    const [requests, setRequests] = useState({ all: {}, requests: {} });

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        setLoader(true);

        var queries = [
            {
                field: 'delivery.schedule',
                value: 'Delivery Scheduled',
            },
        ];

        FirebaseUtils.getRequests(url, queries).then((result) => {
            setLoader(false);

            Promise.all(
                result.requests.map((request) => {
                    let start_time = moment(request.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A');

                    start_time = moment(start_time, 'DD/MM/YYYY, HH:mm A').toDate();

                    let end_time = moment(start_time, 'DD/MM/YYYY, HH:mm A').add(1, 'hours').toDate();

                    return (request = {
                        title: 'Delivery of ' + request.customer.customerName + ' : ' + request.variant,
                        start: start_time,
                        end: end_time,
                        id: request.id,
                        city: request.city,
                        // allDay?: boolean
                        // resource?: any,
                    });
                })
            ).then((res) => {
                setRequests(res);
            });
        });
    }

    function onClose() {
        setVisible(false);
    }

    return (
        <>
            {loader ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    {requests && requests[0] ? (
                        <EventCalendar
                            onSelectEvent={(event) => {
                                console.log(event);
                                setSelected(event);

                                setVisible(true);
                            }}
                            events={requests}
                        />
                    ) : null}

                    <Drawer visible={visible} width={'80%'} closable onClose={onClose}>
                        {selected && selected.id ? <RequestDetail hideInfo={true} city={selected.city} id={selected.id} /> : null}
                    </Drawer>
                </>
            )}
        </>
    );
}
