import React, { useState, useEffect, useContext } from 'react';

import { Typography, Tabs, Drawer, Skeleton } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import ReconcileOverview from './../../resources/reconcilation-overview/reconcilation-overview';

import DailyDashboard from './../../resources/daily-dashboard/daily-dashboard';

import TaskOverview from './../../resources/task-overview/task-overview';

import RequestDashboard from './../../resources/request-dashboard/request-dashboard';

import RequestDetail from './../../../common/components/request-detail/request-detail';

import NoticeBoard from './../../../common/components/notice-board/notice-board';

import './dashboard.scss';

import { GlobalContext } from './../../../../Store';

import FirebaseUtils from './../../../../utils/firebase.utils';

import { PendingList } from './../customer-receipts/customer-receipts';


import PaymentRequestsTile from './../payment-requests-tile/payment-requests-tile';

import DeliveryCalendar from './../delivery-calendar/delivery-calendar';

import moment from 'moment';

import { paymentRequestSchema } from './../../../../modules/global-config';

const { Title } = Typography;

const { TabPane } = Tabs;

export default function Dashboard({ steps = [] }) {
    const { user } = useContext(GlobalContext);

    return (
        <section className="dashboard-container">
            <Tabs defaultActiveKey="1">
                <TabPane tab="Overview" key="1">
                    <div className="dashboard-content">
                        <div className="left">

                            <TaskOverview steps={steps} />


                            <ReconcileOverview />

                            {/* {['management'].indexOf(user.role) !== -1 ? <PaymentRequestsTile schema={paymentRequestSchema} /> : null} */}

                            <div className="second-content dashboard dashboard-card">
                                <Title level={4}>Pending Collections</Title>

                                <div style={{ display: 'flex' }}>
                                    {user.role !== 'finance' ? (
                                        <>
                                            {/* <Alert message="Below are Customer Collections for which accounts verification is pending. Once verified Please use the Accounts Overview to make sure DMS receipts are shared for the customers without delays." type="warning" showIcon /> */}

                                            <div className="score-pending-collections">
                                                {user.locations.map((location, index) => {
                                                    return <PendingList key={index} url={location} />;
                                                })}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>

                <TabPane tab="Calendar" key="2">
                    <div className="right">
                        <div className="card vehicle-card calendar-card">
                            <Title level={4}>Delivery Plan</Title>

                            <p>Below is the scheduled delivery plan by KEC</p>

                            <Tabs defaultActiveKey="0" type="card" size={'small'}>
                                {user.locations.map((location, index) => {
                                    return (
                                        <TabPane tab={location} key={index}>
                                            <div className={`dashboard-item`} key={index}>
                                                <DeliveryCalendar url={location} />
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </Tabs>
                        </div>

                        <div className="daily-dashboard-wrapper">
                            <Tabs defaultActiveKey="0" type="card" size={'small'}>
                                <TabPane tab="Todays Review" key="0">
                                    <DailyDashboard />
                                </TabPane>
                                <TabPane tab="Pending Cases" key="1">
                                    <RequestDashboard />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </TabPane>

            </Tabs>

            <div className="list-header">
                {/* <Title level={4}>Pending Collections</Title> */}

                <div>{/* <Button onClick={getData} type="secondary" size={'small'}>Refresh</Button> */}</div>
            </div>

            {/* <NoticeBoard /> */}
        </section>
    );
}
