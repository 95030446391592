import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

// import CustomerList from './components/customer-list/customer-list';
// import InsuranceForm from './components/insurance-form/insurance-form';
// import StockDashboard from './components/stock-dashboard/stock-dashboard';

import ManageStocks from '../common/components/manage-stocks/manage-stocks';

import { requestColumns, accessoriesSteps, rowActions, billActions, packageActions } from './accessories-config';

import BookingDetail from './../common/components/booking-detail/booking-detail';

import {
    partsSchema,
    requestSchema,
    partsColumns,
    billSchema,
    billDetailSchema,
    partiesColumns,
    partiesSchema,
    itemsColumn,
    packageColumn,
    packageSchema,
} from './../global-config';

import BillList from './../common/resources/bill-list/bill-list';
import SalesReport from '../common/resources/bill-list/sales-report';

import RequestDetail from './../common/components/request-detail/request-detail';

import ChangePassword from './../common/components/change-password/change-password';

import RequestOverview from './../common/resources/request-overview/request-overview';

import AccessoryList from './components/accessory-customers/accessory-customers';

import AccessoryForm from './components/accessory-form/accessory-form';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import ModuleRoutes from './../common/resources/modules-routes/module-routes';

import GenericRequest from './../common/resources/generic-request/generic-request';

import { ServiceBill } from '../../components/service-bill/service-bill';

import './accessories-landing.scss';

// import { partsSchema, receiptsSchema, requestSchema, bookingSchema } from './../global-config';

import { GlobalContext } from '../../Store';

import TaskOverview from './../common/resources/task-overview/task-overview';

import OrderTracking from '../common/resources/order-tracking/order-tracking';

import AccessoryOverview from './../common/resources/accessory-overview/accessory-overview';

import BillDetails from './../common/resources/finance-payout-tracker/bill-details';

import DataCorrection from './../common/resources/data-correction/data-correction';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';

import GenericSalesReturn from '../common/resources/generic/generic-sales-return/generic-sales-return';

import GenericAdd from '../common/resources/generic/generic-add/generic-add';

import EditPackage from '../common/resources/edit-package/edit-package';

export default function AccessoriesLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    const queries = [
        {
            field: 'accessories.process',
            value: 'incomplete',
        },
    ];

    // Modules / Menus for this User
    const modules = [
        // {
        //   caption: "Customers",
        //   path: `/${user.locations[0]}/customers`,
        // },

        {
            caption: 'Requests',
            submenus: [
                // {
                //   caption: 'All Requests',
                //   path: '/manage-requests/'
                // },

                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/all-requests`,
                                caption: 'All Requests',
                            },

                            {
                                path: `/${location}/pending-requests`,
                                caption: 'Pending Requests',
                            },

                            {
                                path: `/${location}/billing-requests`,
                                caption: 'Pending for Delivery',
                            },

                            {
                                path: `/${location}/delivered-requests`,
                                caption: 'Delivered Requests',
                            },
                        ],
                    };
                }),
            ],
        },
        {
            caption: 'Order Tracking',
            path: '/order-tracking',
        },

        {
            caption: 'Accessory Overview',
            path: '/accessory-overview',
        },

        {
            caption: 'Tasks',
            submenus: accessoriesSteps.filter((step) => !step.isForm),
        },
        {
            caption: 'Bills',
            submenus: [
                {
                    caption: 'Accessories Bills',
                    path: `/${user.locations[0]}/Accessories/bills`,
                },
                {
                    caption: 'VAS Bills',
                    path: `/${user.locations[0]}/VAS/bills`,
                },
                {
                    caption: 'After Market Bills',
                    path: `/${user.locations[0]}/After Market/bills`,
                },
            ],
        },
        {
            caption: 'Manage',
            submenus: [
                {
                    path: `/items`,
                    caption: 'Manage Items',
                },
                {
                    path: `/${user.locations[0]}/stocks`,
                    caption: 'Manage Stocks',
                },
                {
                    path: `/manage-packages`,
                    caption: 'Manage Packages',
                },
            ],
        },
        {
            caption: 'Parties',
            submenus: [
                {
                    path: `/create-parties`,
                    caption: 'Create Parties',
                },
            ],
        },

        {
            caption: 'Sales Billing',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Vehicle Billing',
                                path: `/${location}/service/bill/Sales/Vehicle`,
                            },
                            {
                                caption: 'Accessory Billing',
                                path: `/${location}/service/bill/Sales/Accessories`,
                            },
                            {
                                caption: 'VAS Billing',
                                path: `/${location}/service/bill/Sales/VAS`,
                            },

                            {
                                caption: 'After Market Billing',
                                path: `/${location}/service/bill/Sales/After Market`,
                            },
                        ],
                    };
                }),
            ],
        },
        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => (
                            <>
                                <NoticeBoard /> <TaskOverview steps={accessoriesSteps} />
                            </>
                        )}
                    />

                    {/* Maintaining listing page for different status */}

                    {/* <Route exact path="/accessories/:city/:id" component={AccessoryForm} /> */}

                    {/* <PrivateRoute exact path="/manage-parts" component={ManageParts} /> */}

                    <Route path="/:city/customers" component={() => <GenericRequest columns={requestColumns} queries={queries} />} />

                    <Route
                        path="/accessories/:city/:id"
                        component={({ ...routeParams }) => (
                            <AccessoryForm step={{ fields: [] }} {...routeParams} columns={requestColumns} queries={queries} />
                        )}
                    />

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />

                    {/* All Cases */}
                    <Route
                        exact
                        path={`/:city/all-requests`}
                        component={(...routeParams) => <GenericList schema={requestSchema} actions={rowActions} queries={[]} {...routeParams} />}
                    />
                    {/* All Cases Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/pending-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} actions={rowActions} queries={requestSchema.pendingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/billing-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} actions={rowActions} queries={requestSchema.billingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Delivered Requests */}
                    <Route
                        exact
                        path={`/:city/delivered-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} actions={rowActions} queries={requestSchema.deliveryRequests} {...routeParams} />
                        )}
                    />
                    {/* Delivered Requests Ends */}

                    {/* order tracking */}
                    <Route exact path={`/order-tracking`} component={(...routeParams) => <OrderTracking {...routeParams} />} />
                    {/* order tracking Ends */}

                    {/* accessory overview */}
                    <Route exact path={`/accessory-overview`} component={(...routeParams) => <AccessoryOverview {...routeParams} />} />
                    {/* accessory overview Ends */}

                    {/* Requests according to Queries Done */}

                    {/* Items routes  */}
                    <Route
                        exact
                        path={`/items`}
                        component={(...routeParams) => <GenericList model="items" schema={partsSchema} columns={itemsColumn} {...routeParams} />}
                    />
                    {/* <Route exact path={`/:model/custom/upload/file`} component={(...routeParams) => <ItemsUpload
                        model = 'items'
                        schema={partsSchema}
                        columns={partsColumns}
                        {...routeParams}
                    />} /> */}

                    {/* <Route exact path={`/:city/${model}/custom/upload/file`} component={(...routeParams) => <ItemsUpload schema={schema} {...routeParams} model={model} columns={columns} collection={collection} />} /> */}
                    {/* Items route end  */}

                    {/* Bill listing */}

                    <Route exact path={`/:city/:category/bills`} component={(...routeParams) => <SalesReport {...routeParams} />} />

                    {/* Bill details */}

                    <Route exact path="/bill/:id" component={BillDetails} userRole={'admin'} />

                    <Route
                        exact
                        path="/:city/service/bill/:department/:category"
                        component={({ ...routeParams }) => <ServiceBill key={routeParams} {...routeParams} />}
                        userRole={'admin'}
                    />

                    {/* <Route
                        exact
                        path="/bill/vehicle/:id"
                        component={VehicleInvoice}
                        userRole={'admin'}
                    /> */}
                    {/* Bill details Ends */}

                    {/* Sales Return */}

                    <Route
                        exact
                        path={'/bill/return/:id/:category'}
                        component={({ ...routeParams }) => <GenericSalesReturn schema={billDetailSchema} actions={billActions} {...routeParams} />}
                    />

                    {/* Sales Return Ends */}

                    {/* <Route exact path={'/data-correction'} component={(...routeParams) => <DataCorrection {...routeParams} />} /> */}

                    <Route path="/steps" component={() => <TaskRoutes steps={accessoriesSteps} list={AccessoryList} form={AccessoryForm} />} />

                    {/* <ModuleRoutes model="accessories" columns={partsColumns} collection="parts" /> */}

                    <Route
                        path="/:city/items"
                        component={() => <ModuleRoutes model="items" schema={partsSchema} columns={partsColumns} collection="parts" />}
                    />

                    <Route
                        path="/create-parties"
                        component={(...routeParams) => (
                            <GenericAdd schema={partiesSchema} {...routeParams} model={'parties'} columns={partiesColumns} />
                        )}
                    />

                    {/* Package  */}
                    <Route
                        exact
                        path={`/manage-packages`}
                        component={(...routeParams) => (
                            <GenericList schema={packageSchema} columns={packageColumn} actions={packageActions} {...routeParams} />
                        )}
                    />

                    <Route
                        exact
                        path={`/package/:id`}
                        component={(...routeParams) => (
                            <EditPackage schema={packageSchema} columns={packageColumn} actions={packageActions} {...routeParams} />
                        )}
                    />

                    {/* Package end  */}

                    <PrivateRoute exact path="/:city/stocks" component={ManageStocks} />

                    {/* <ModuleRoutes model="accessory_stock" columns={partsColumns} collection="parts" /> */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    {/* <Route path="/accessory_stock" component={() => <ModuleRoutes model="accessory_stock" schema={partsSchema} columns={partsColumns} collection="parts" />} /> */}
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                </Switch>
            </GlobalHeader>
        </section>
    );
}
