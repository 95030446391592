import React, { useEffect, useRef, useState, Fragment, useContext } from 'react';

import { GlobalContext } from '../../../../Store';

import { Button, message, Typography } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

// import RequestInfo from './../../../../components/request-info/request-info';

import CustomerInfo from './../../../../components/customer-info/customer-info';

// import MatchingReceipts from './../../../../components/matching-receipts/matching-receipts';

// import FormCreator from './../../../common/resources/form-creator/form-creator';
import RequestForm from './../../../common/resources/request-form/request-form';

import AccessoryChoices from '../../../common/components/accessory-choices/accessory-choices';

import Bills from './../../../common/components/bills/bills';

import './accessory-form.scss';

import { Requests, Bookings } from '../../../../models';

import CommentBlock from '../../../common/components/comment-block/comment-block';

const { Title, Text } = Typography;

export default function AccessoryForm({ step, history, match }) {
    const { user } = useContext(GlobalContext);

    const [request, setRequest] = useState({});

    const [booking, setBooking] = useState({});

    const { params } = match;

    const { city, id } = params;

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        setLoader(true);

        Requests.getRecord(id).then((result) => {
            let data = { ...{ finance: {} }, ...result };

            setRequest(data);
            // setVehicle(data);

            if (data.bookingNo) {
                getBooking(data.bookingNo);
            } else {
                message.error('Could not load booking');
            }
        });
    }

    function getBooking(id) {
        Bookings.getRecord(id).then((booking) => {
            // var booking = result.data();

            if (booking) {
                setBooking({ ...booking, comments: [], isValid: true });
            } else {
                setBooking({ empty: true, isValid: false });
            }

            setLoader(false);

            // #todo the booking object have to be removed from
        });
    }

    return (
        <div className="accessory-form">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="page-header">
                            <h1 className="page-heading">Accessory Details</h1>

                            <div>
                                <Button onClick={loadData} type="secondary" size={'small'}>
                                    Refresh
                                </Button>
                            </div>
                        </div>

                        <div className="split-container">
                            <div className="left">
                                <CustomerInfo request={request} />
                            </div>

                            <div className="right">
                                <div className="vehicle-card">
                                    <Title level={4}>{step.caption}</Title>

                                    <p>Please check with the customer on the accessory proceedings and provide the details</p>

                                    <RequestForm
                                        formContent={request}
                                        modelIndex="requestId"
                                        model={'accessories'}
                                        step={step}
                                        callback={() => {
                                            history.goBack();
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Receipts */}
                        </div>

                        {/* Vehicle Card */}

                        <AccessoryChoices request={request} callback={loadData} isBooking={true} booking={booking} city={city} />
                        {/* <AccessoryChoices booking={booking} request={request} city={city} callback={loadData} /> */}

                        <Bills callback={loadData} isBooking={true} booking={booking} request={request} />

                        {/* {['kec', 'super', 'admin', 'sm', 'finance', 'accessories'].indexOf(user.role) !== -1 ? (
                           
                        ) : null} */}

                        {/* Receipts Ends */}

                        <CommentBlock id={request.id} />
                    </div>
                </Fragment>
            )}
        </div>
    );
}
