/**
 *@author Jinishakappil
 * 
 */

import React, { useState, useEffect, useContext } from 'react';

import { withRouter } from 'react-router-dom';

import { StockPoints } from '../../../../models';

import { GlobalContext } from '../../../../Store';

import { Select } from 'antd';

const { Option } = Select;

/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function StockPointSelector({ callback, onChange }) {

    const [stockPoints, setStockpoints] = useState([]);

    const { user } = useContext(GlobalContext);

    const [location, setLocation] = useState(user.locations[0]);

    useEffect(() => {
        getYardDetails(location);
    }, []);

    /**
     *
     *   Load the matching  Yard Details
     */

    function getYardDetails(location) {
        var queries = [
            {
                field: 'location',
                value: location,
            },
        ];

        StockPoints.get(queries).then((res) => {

            setStockpoints(res.stock_points);
        });
    }

    /**
     * On change of the select box ,
     * append object along with id
     */
    function handleChange(id) {
        onChange(id);

        callback(id);
    }

    return (
        <div className="stock-pointer">
            <Select style={{ width: 200 }} onChange={handleChange}>
                {stockPoints.map((option, key) => (
                    <Option key={key} value={option.id}>
                        {option.name}
                    </Option>
                ))}
            </Select>
        </div>
    );
}

export default withRouter(StockPointSelector);
