import React from 'react';

import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (JSON.parse(localStorage.getItem('userInfo')))
        ? <Component {...props} />
        :
        <Redirect to='/login-phone' />
      }
    />
  )
}
