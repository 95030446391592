import React, { useState, useEffect, useContext } from 'react';
import { Table, Dropdown, Menu, Typography, Button, Space, DatePicker, Select, Badge } from 'antd';

import FirebaseUtils from '../../../../utils/firebase.utils';
import ApiUtils from '../../../../utils/api.utils';

import Rupee from '../../../../components/rupee/rupee';

import { DownOutlined } from '@ant-design/icons';

import { Switch } from 'antd';

import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom';
import moment, { months } from 'moment-timezone';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { ExportXLS } from '../finance-tracker/ExportXLS';

import '../../../../models/bookings';

import './booking-report.scss';
import { Bookings, Requests, Receipts, BillDetails, Users, Sales, Departments } from '../../../../models/';
import { Cart } from '../../../../models/';
import { data } from 'jquery';

//import TeamView from './team-report';

const { Title, Text } = Typography;

const dateFormat = 'DD/MM/YYYY';

const { RangePicker } = DatePicker;

const BookingReport = () => {
    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <div>
                            <div>{record['Bill Name']}</div>
                        </div>
                    </Space>
                );
            },
            width: 200,
        },

        {
            title: 'Booking No',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.requestId ? (
                            <Link
                                style={{
                                    // fontSize: '12px',
                                    textTransform: 'capitalize',
                                }}
                                to={`/bookings/${record.id}`}
                            >
                                {record.id}
                            </Link>
                        ) : (
                            <div>
                                <div>{record['Booking No']}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 200,
        },

        {
            title: 'Booking Date',
            render: (text, record) => {
                return <div style={{ fontSize: '12px' }}>{record && record['Booking Date']}</div>;
            },

            width: 200,
        },

        {
            title: 'Payable',
            dataIndex: 'vehicleCost',
            render: (text, record) => {
                var vc = record.cartTotal.toFixed(2);
                // var vcr = vc.toFixed(2);
                return <div>{vc}</div>;
            },
            width: 150,
        },
        {
            title: 'Paid',
            dataIndex: 'DP',
            render: (text, record) => {
                let dp = record.receiptTotal.toFixed(2);

                return <Badge status={record && record.ac_code ? 'success' : 'error'} text={dp} />;
                // return <Badge status={record.accounts && record.accounts.ac_code && record.accounts.ac_code.value ? 'success' : 'error'} text={dp} />;
            },
            width: 150,
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            render: (text, record) => {
                let balance = record.cartTotal - record.receiptTotal;
                let balanceR = balance.toFixed(2);
                return <div>{balanceR}</div>;
            },
            width: 150,
        },

        {
            title: 'Finance DO',
            render: (text, record) => {
                if (record.request && record.request.finance) {
                    return <div>{record.request.finance.status}</div>;
                }
            },
        },

        {
            title: 'Docket',
            render: (text, record) => {
                return <div>{record['MOB Docket Email Count']}</div>;
            },
        },
    ];

    var query = (range) => {
        return [
            {
                field: 'bookingDate',
                operator: '>=',
                // value: 1580236200000,
                value: range[0].startOf('day').valueOf(),
            },

            {
                field: 'bookingDate',
                operator: '<=',
                value: range[1].endOf('day').valueOf(),
                // value: 1590777000000,
            },
        ];
    };

    return (
        <div>
            <div className="report listing">
                <div className="page-header">
                    <div className="left">
                        <Title level={3}>Booking Report</Title>
                    </div>
                </div>
            </div>

            <Collection columns={columns} queries={query} />
        </div>
    );
};

/**
 *
 * @param queries,columns
 * @returns
 *
 * Collection component to get collections
 *
 */
function Collection({ queries, columns }) {
    const [startdate, setStartdate] = useState(moment().startOf('month'));

    const [enddate, setEnddate] = useState(moment());

    const [range, setRange] = useState([startdate, enddate]);

    const [hackValue, setHackValue] = useState();

    const { user, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState({ bookings: [] });

    const [location, setLocation] = useState(user.locations[0]);

    const [view, setView] = useState(false);

    const [finResult, setFinResult] = useState({ bookings: [] });

    const [departments, setDepartments] = useState([]);

    const [bookingStatus, setBookingStatus] = useState('All Bookings');

    const [preBooking, setPreBooking] = useState(false);

    var status = ['All Bookings', 'Pending Bookings', 'Paid Bookings'];

    const bookingMenus = status.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const bookingMenu = <Menu onClick={handleClick}>{bookingMenus}</Menu>;

    function handleClick(e) {
        setBookingStatus(e.key);

        if (e.key === 'Pre Booking') {
            setPreBooking(true);
        } else {
            setPreBooking(false);
        }
    }

    useEffect(() => {
        getDepartments().then(async (departments) => {
            await getBookings(location, range, departments, preBooking);
        });
    }, []);

    useEffect(() => {
        getDepartments().then(async (departments) => {
            await getBookings(location, range, departments, preBooking);
        });
    }, [bookingStatus]);

    async function getDepartments() {
        var queries = [
            {
                field: 'description',
                value: 'Department Handling Sales',
            },
        ];

        const { departments = [] } = await Departments.get(queries);

        setDepartments(departments);

        return departments;
    }

    function updateTime(dt) {
        setRange(dt);

        if (!view) {
            getBookings(location, dt, departments, preBooking);
        } else {
            getBookingReport(location, dt);
        }
    }

    // useEffect(async () => {
    //     await getBookings(location, range, departments);
    // }, []);

    function refresh() {
        if (!view) {
            getBookings(location, range, departments, preBooking);
        } else {
            getBookingReport(location, range);
        }
    }

    /**
     *
     *
     * @param {*} location
     * @param {*} initialQueries
     * @returns
     */
    function getAllBookingsData(location, initialQueries) {
        return Bookings.getLocationData(location, initialQueries).then((result) => {
            return result;
        });
    }

    /**
     * Get stock Data
     */
    async function getBookings(location, range, departments, preBooking) {
        let department = departments.filter((department) => department.location === location)[0];

        setLoading(true);

        let initialQueries = [];

        initialQueries = queries(range);

        // if (preBooking) {
        //     initialQueries.push({ field: 'booking_status', value: 'prebooking' });
        // } else {
        //     initialQueries.push({ field: 'booking_status', value: 'booking' });
        // }

        // According to Booking Status we load the bookings
        if (bookingStatus !== 'All Bookings') {
            if (bookingStatus === 'Pending Bookings') {
                initialQueries.push({ field: 'booking_status', value: 'prebooking' });
            } else {
                initialQueries.push({ field: 'booking_status', value: 'booking' });
            }
        }

        return await getAllBookingsData(location, initialQueries).then((result) => {
            return Promise.all(
                result.bookings.map(async (booking) => {
                    //get requests for bookings that has requestId
                    if (booking.requestId) {
                        return await Requests.getRecord(booking.requestId).then((res) => {
                            return {
                                ...booking,
                                request: res,
                            };
                        });
                    } else {
                        return {
                            ...booking,
                        };
                    }
                })
            )
                .then(async (response) => {
                    return await getLedgerData(location, response, department).then((result) => {
                        return {
                            bookings: response,
                            ledger: result,
                        };
                    });
                })
                .then((result) => {
                    return Promise.all(
                        result.bookings.map((booking) => {
                            return getTotalValues(booking, result.ledger);
                        })
                    );
                })
                .then((result) => {
                    console.log(result);
                    setResults({ bookings: result });

                    setLoading(false);
                });
        });
    }

    /**112
     *
     *
     *
     * @param {*} location
     * @param {*} accounts
     * @returns
     */

    function getLedgerData(location, bookings, department) {
        var accounts = bookings.filter((booking) => booking && booking && booking.ac_code).map((booking) => booking.ac_code.value);

        //get ledger data

        if (accounts.length !== 0) {
            return ApiUtils.getBulkLedgerDetails(department, accounts);
        } else {
            return new Promise((resolve, reject) => {
                resolve();
            });
        }
    }

    /**
     *
     *
     * @param {*} booking
     * @param {*} response
     * @returns
     */
    function getTotalValues(booking, response) {
        var cartTotal = loadCartTotal(booking);

        var receiptTotal = loadReceiptTotal(booking, response);

        return Promise.all([cartTotal, receiptTotal]).then((result) => {
            return {
                ...booking,
                cartTotal: result[0],
                receiptTotal: result[1].paidtotal,
                pending: result[1].pendingcleartotal,
                balance: result[0] - result[1].paidtotal,
            };
        });
    }

    function loadCartTotal(booking) {
        // var tableData = {};
        var arr = [];

        // For each request we have to find the cart total and receipt total to be used
        // in the table

        return new Promise((resolve, reject) => {
            let request = {
                bookingNo: booking['Booking No'],
                dealerCode: booking['Dealer Code'],
            };

            if (request.bookingNo) {
                var queries = [
                    {
                        field: 'bookingNo',
                        value: request.bookingNo,
                    },
                    {
                        field: 'dealerCode',
                        value: request.dealerCode,
                    },
                ];

                return Cart.get(queries).then(
                    // We have the cart items as an array,
                    // We have to add them and assign it to array object

                    (result) => {
                        // let total = (cartItems).reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0)
                        // let cartTotal = (cartItems).reduce((a, b) => a + (parseInt(b['price']) * (b['quantity']) || 1), 0)
                        let cartTotal = 0;

                        result.cart
                            .filter((items) => {
                                return items.status !== 'removed' && items.status !== 'rejected';
                            })
                            .forEach((items) => {
                                arr.push(items);
                            });

                        return FirebaseUtils.getBillingDetailsStatement(request, 'basic').then((billItems) => {
                            if (billItems && billItems[0]) {
                                billItems
                                    .filter((items) => {
                                        return items.status !== 'removed' && items.status !== 'rejected';
                                    })
                                    .forEach((items) => {
                                        arr.push(items);
                                    });

                                arr.forEach((items) => {
                                    cartTotal = cartTotal + items['price'] * (items['quantity'] || 1);
                                });

                                resolve(cartTotal);

                                return cartTotal;
                            } else {
                                arr.forEach((items) => {
                                    cartTotal = cartTotal + items['price'] * (items['quantity'] || 1);
                                });

                                resolve(cartTotal);

                                return cartTotal;
                            }
                        });
                    }
                );
            } else {
                resolve(0);
            }
        });
    }

    // let cityKey = {
    //     KL305: 'kozhikode',
    //     KL306: 'kannur',
    //     KL307: 'malappuram',
    // };

    function loadReceiptTotal(booking, response) {
        var obj = {};

        var queries = [
            {
                field: 'Appointment No',
                value: booking['Booking No'],
            },
        ];

        if (
            // booking &&
            // booking.request &&
            booking &&
            booking.ac_code &&
            booking.ac_code.value &&
            response
        ) {
            return new Promise((resolve, reject) => {
                response.map((res) => {
                    if (res.acount_pointer == booking.ac_code.value) {
                        obj = res;
                    }
                });

                var ledgerReceipts = obj.ledgers.filter((entry) => entry.Trn === -1);

                var paidRecipt = ledgerReceipts;

                var recivedtotal = paidRecipt.reduce((a, b) => a + parseInt(b['CrAmt']), 0);

                var clearedRecipt = ledgerReceipts.filter((entry) => entry.OpAcPDCStatus === 'R' || entry.OpAcPDCStatus === 'N');

                var clearedTotal = clearedRecipt.reduce((a, b) => a + parseInt(b['CrAmt']), 0);

                var openingtotal = -obj.opening_balance;

                var totalBalance = recivedtotal + openingtotal;

                var total = {
                    paidtotal: totalBalance,
                    pendingcleartotal: totalBalance - (clearedTotal + openingtotal),
                };

                resolve(total);
            });
        } else {
            return new Promise((resolve, reject) => {
                if (booking['Booking No']) {
                    return Receipts.get(queries).then((content) => {
                        // We have the Receipt items as an array,
                        // We have to add them and assign it to array object
                        let receipts = content.receipts;

                        let totalBalance = receipts.reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0);

                        var total = {
                            paidtotal: totalBalance,
                            pendingcleartotal: 0,
                        };
                        resolve(total);
                    });
                } else {
                    resolve({
                        paidtotal: 0,
                        pendingcleartotal: 0,
                    });
                }
            });
        }
    }

    /**
     *
     * Teamwise report
     */

    function getBookingReport(city, range) {
        setLoading(true);

        var userArr = [];

        var requestDetails = [];

        var data = [];

        var response = [];

        var queries = [
            {
                field: 'role',
                value: 'kec',
            },
            {
                field: 'locations',
                operator: 'array-contains',
                value: city,
            },
        ];

        var bookingsQuery = [
            {
                field: 'bookingDate',
                operator: '>=',
                value: range[0].valueOf(),
            },

            {
                field: 'bookingDate',
                operator: '<=',
                value: range[1].valueOf(),
            },
        ];

        return new Promise((resolve, reject) => {
            //get the kecs and respective teamleaders

            return Users.get(queries)
                .then((result) => {
                    result.users.map((user) => {
                        userArr.push({
                            name: user.dms['Consultant Name'],
                            teamLeader: user.dms['teamLeader'],
                        });
                    });

                    return true;
                })
                .then(() => {
                    //get bookings data

                    return Bookings.get(bookingsQuery).then((result) => {
                        let filterData = result.bookings.filter((booking) => !booking.isCancelled);

                        return filterData;
                    });
                })
                .then((filterData) => {
                    return Promise.all(
                        filterData.map((item) => {
                            var query = [
                                {
                                    field: 'bookingNo',
                                    value: item['Booking No'],
                                },
                            ];

                            return Requests.get(query).then((result) => {
                                requestDetails.push(result.requests[0]);

                                return requestDetails;
                            });
                        })
                    ).then(() => {
                        return requestDetails;
                    });
                })
                .then((requestDetails) => {
                    requestDetails.forEach((item) => {
                        data.push(item);
                    });

                    return data;
                })
                .then(() => {
                    var finalOutput = [];

                    userArr.forEach((person, key) => {
                        let retails = data.filter((request) => request && request.kec === person.name);

                        let row = {
                            name: person.name,
                            teamLeader: person.teamLeader,
                            retails: retails.length,
                            cash: 0,
                            finance: 0,
                            direct: 0,
                            inhouse: 0,
                            ACH: 0,
                        };

                        retails.forEach((request) => {
                            // ['In House', 'Direct', 'Own Fund']
                            if (request.finance.type === 'In House') {
                                row.inhouse += 1;
                            } else if (request.finance.type === 'Direct') {
                                row.direct += 1;
                            } else if (request.finance.type === 'Own Fund') {
                                row.cash += 1;
                            }
                            row.finance = row.retails - row.cash;
                            row.ACH = parseFloat((row.inhouse / row.retails) * 100).toFixed(1);
                        });

                        finalOutput.push(row);
                    });

                    // Group the members by team to calculate each teams total

                    var teams = groupBy(finalOutput, 'teamLeader');

                    // itereate over teams to find the sum
                    Object.keys(teams).forEach((teamLeader) => {
                        var members = teams[teamLeader];

                        response = [].concat(response, members);

                        var ach = 0;

                        if (totalOf(members, 'retails') > 0) {
                            ach = parseFloat((totalOf(members, 'inhouse') / totalOf(members, 'retails')) * 100).toFixed(1);
                        }
                        // Find team total
                        response.push({
                            name: teamLeader,
                            isteamLeader: true,
                            retails: totalOf(members, 'retails'),
                            cash: totalOf(members, 'cash'),
                            finance: totalOf(members, 'finance'),
                            direct: totalOf(members, 'direct'),
                            inhouse: totalOf(members, 'inhouse'),
                            ACH: ach,
                        });
                    });

                    resolve(response);

                    console.log(response);

                    setLoading(false);
                    setFinResult({ bookings: response });

                    return response;
                });
        });
    }

    var groupBy = function (xs, key) {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    function totalOf(arr, field) {
        var tot = 0;

        arr.forEach((entry) => {
            tot += entry[field];
        });
        return tot;

        // return _.reduce(arr,(a, b) => a + parseInt(b[field]), 0)
    }

    //Handle change in location
    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function handleMenuClick(e) {
        setLocation(e.key);
        if (!view) {
            getBookings(e.key, range, departments);
        } else {
            getBookingReport(e.key, range);
        }
    }

    //datasource displayed for booking report
    let filtered = result.bookings;

    //data for team wise report
    let teamdata = finResult.bookings;

    //handle toggle

    function changeView(result) {
        console.log(result);
        setView(result);
        if (result === true) {
            getBookingReport(location, range);
        }
    }

    //const toggleView = (item) => <Switch defaultChecked={view} onChange={(value, e) => changeView(value, e, item)} checked={view[item]} />;

    return (
        <div className="finance-tracker listing">
            <div className="header">
                <div></div>
                <div className="header-actions">
                    {/* <Switch defaultChecked onChange={changeView} checked={view} /> */}

                    <Dropdown overlay={bookingMenu}>
                        <Button size={'small'}>
                            {bookingStatus} <DownOutlined />
                        </Button>
                    </Dropdown>

                    <Button
                        onClick={() => {
                            refresh();
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>
                    <Dropdown overlay={menu}>
                        <Button size={'small'}>
                            {location} <DownOutlined />
                        </Button>
                    </Dropdown>
                    <RangePicker value={hackValue || range} inputReadOnly format={dateFormat} onChange={updateTime} allowClear={false} />

                    {/* {finResult && <ExportXLS csvData={finResult} />} */}
                </div>
            </div>

            {loading ? (
                <PlaceHolder type="listing" />
            ) : !view ? (
                result && <TableView data={filtered} columns={columns} />
            ) : (
                <TeamView columns={teamReportColumns} data={teamdata} />
            )}
        </div>
    );
}

//columns passed in teamwise report
const teamReportColumns = [
    {
        title: '#',
        dataIndex: 'index',
        render: (value, item, index) => index + 1,
    },
    {
        title: 'KEC',
        dataIndex: 'kec',
        // dataIndex: 'Cust Name',
        render: (text, record) => {
            return (
                <Space size="middle">
                    {record.isteamLeader ? (
                        <span
                            style={{
                                fontSize: '16px',
                                textTransform: 'capitalize',
                            }}
                        >
                            <strong> {record.name}</strong>
                        </span>
                    ) : (
                        <div>
                            <div>{record.name}</div>
                        </div>
                    )}
                </Space>
            );
        },
        width: 350,
    },

    {
        title: 'RETAILS',
        dataIndex: 'retails',
        render: (text, record) => {
            return (
                <Space size="middle">
                    {record.isteamLeader ? (
                        <span
                            style={{
                                fontSize: '16px',
                                textTransform: 'capitalize',
                            }}
                        >
                            <strong> {record.retails}</strong>
                        </span>
                    ) : (
                        <div>
                            <div>{record.retails}</div>
                        </div>
                    )}
                </Space>
            );
        },
        width: 100,
    },
    {
        title: 'CASH',
        dataIndex: 'CASH',
        render: (text, record) => {
            return (
                <Space size="middle">
                    {record.isteamLeader ? (
                        <span
                            style={{
                                fontSize: '16px',
                                textTransform: 'capitalize',
                            }}
                        >
                            <strong> {record.cash}</strong>
                        </span>
                    ) : (
                        <div>
                            <div>{record.cash}</div>
                        </div>
                    )}
                </Space>
            );
        },

        width: 100,
    },

    {
        title: 'FINANCE',
        dataIndex: 'finance',
        render: (text, record) => {
            return (
                <Space size="middle">
                    {record.isteamLeader ? (
                        <span
                            style={{
                                fontSize: '16px',
                                textTransform: 'capitalize',
                            }}
                        >
                            <strong> {record.finance}</strong>
                        </span>
                    ) : (
                        <div>
                            <div>{record.finance}</div>
                        </div>
                    )}
                </Space>
            );
        },
        width: 100,
    },

    {
        title: 'DIRECT',
        dataIndex: 'direct',
        render: (text, record) => {
            return (
                <Space size="middle">
                    {record.isteamLeader ? (
                        <span
                            style={{
                                fontSize: '16px',
                                textTransform: 'capitalize',
                            }}
                        >
                            <strong> {record.direct}</strong>
                        </span>
                    ) : (
                        <div>
                            <div>{record.direct}</div>
                        </div>
                    )}
                </Space>
            );
        },
        width: 100,
    },
    {
        title: 'IN HOUSE',
        dataIndex: 'inhouse',
        render: (text, record) => {
            return (
                <Space size="middle">
                    {record.isteamLeader ? (
                        <span
                            style={{
                                fontSize: '16px',
                                textTransform: 'capitalize',
                            }}
                        >
                            <strong> {record.inhouse}</strong>
                        </span>
                    ) : (
                        <div>
                            <div>{record.inhouse}</div>
                        </div>
                    )}
                </Space>
            );
        },
        width: 100,
    },
    {
        title: '% OF ACH',
        dataIndex: 'ach',
        render: (text, record) => {
            var color = 'red';

            if (record.ACH >= 90) {
                color = '#52c41a';
            } else if (record.ACH >= 50) {
                color = '#ffb077';
            } else if (record.ACH >= 40) {
                color = '#faad14';
            } else if (record.ACH >= 25) {
                color = '#feec82';
            } else if (record.ACH > 0) {
                color = '#ff4d4f';
            } else if (record.ACH === 0) {
                color = 'white';
            } else {
            }

            return (
                <div style={{ backgroundColor: color, padding: '0px 8px' }}>
                    <Space size="middle">
                        <span>{record.ACH}</span>
                    </Space>
                </div>
            );
        },
        width: 100,
    },
];

//Table to display booking report
function TableView({ data, columns }) {
    return (
        <Table
            // width="100%"
            size="small"
            scroll={{ x: true }}
            rowKey={(record) => record.index}
            dataSource={data}
            columns={columns}
            pagination={false}
        ></Table>
    );
}

//Table to display teamwise report
function TeamView({ columns, data }) {
    return (
        <div>
            <Table
                size="small"
                rowKey={(record) => record.index}
                dataSource={data}
                columns={columns}
                pagination={false}
                summary={(pageData) => {
                    let cashTotal = 0,
                        directTotal = 0,
                        financeTotal = 0,
                        inhouseTotal = 0,
                        retailsTotal = 0;

                    pageData.forEach((entry) => {
                        if (entry.isteamLeader) {
                            cashTotal += entry.cash;
                            directTotal += entry.direct;
                            financeTotal += entry.finance;
                            inhouseTotal += entry.inhouse;
                            retailsTotal += entry.retails;
                        }
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <Text>
                                        <strong>{retailsTotal}</strong>
                                    </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>
                                        <strong>{cashTotal}</strong>
                                    </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>
                                        <strong>{financeTotal}</strong>
                                    </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>
                                        <strong>{directTotal}</strong>
                                    </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>
                                        <strong>{inhouseTotal}</strong>
                                    </Text>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
}

export default BookingReport;
