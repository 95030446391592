
import React, { useState, useContext } from "react";

import DateRangePicker from 'react-bootstrap-daterangepicker';

import moment from 'moment-timezone';

import './rangepicker.scss';

export default function RangePicker({ onChange, value }) {

    // const [value, setValue] = useState(initialValue);

    // console.log(value);

    // console.log();

    let startDate = value[0].format('DD/MM/YYYY');

    let endDate = value[1].format('DD/MM/YYYY');

    // console.log(startDate, endDate);

    /**
     * 
     * @param {*} event 
     * @param {*} picker 
     */
    function onCallback(event, picker) {

        onChange([picker.startDate, picker.endDate])
    }

    return (<div className="rangepicker">
        <>

            <DateRangePicker
                initialSettings={{
                    startDate,
                    endDate,
                    locale: {
                        format: 'DD/MM/YYYY'
                    }
                }} onApply={onCallback}

                range={{
                    Today: [moment(), moment()],

                    Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                    'This Week': [moment().startOf('week'), moment().endOf('week')],

                    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                    'This Month': [moment().startOf('month'), moment().endOf('month')],

                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                }}

            >
                {/* {{ value }} */}

                <input />

            </DateRangePicker>
        </>

    </div>)
}

