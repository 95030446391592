/**
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState, useContext } from "react"

import { Button, Form, Input, Radio, message } from 'antd';


import { withRouter } from 'react-router-dom'

// import './form-creator.scss'

import FirebaseUtils from './../../../../utils/firebase.utils';

import FileUpload from './../../../../components/file-upload/file-upload';

import GenericList from './../generic/generic-list/generic-list';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { TextArea } = Input;


function GenericRequest({ columns, queries }) {

    return (
        <section className="generic-request">

            <GenericList
                disableAdd={true}
                disableUpload={true}
                disableSelect={true}
                isDirect={true}
                queries={queries}
                model={'requests'}
                collection="requests"
                columns={columns}
            />

        </section>
    );
}

export default withRouter(GenericRequest);





