

import React, { useState, useContext, useEffect } from "react";

import { Typography, DatePicker, Space } from "antd";

import moment from 'moment-timezone';

// import moment from 'moment';

import Select from 'react-select';

import FirebaseUtils from './../../../../utils/firebase.utils';

import RequestDetail from './../../../common/components/request-detail/request-detail';


import { GlobalContext } from './../../../../Store';

import "./request-overview.scss";

import {
    useParams
} from "react-router-dom";


const { Title } = Typography;

const { RangePicker } = DatePicker;

const filterData = [
    {
        label: 'Allocation',
        options: [
            {
                label: 'Allocated Date',
                value: 'allocation.allocated_date'
            },
            {
                label: 'Created Date',
                value: 'allocation.created_date'
            },

        ]
    },
    {
        label: 'Retail',
        options: [
            {
                label: 'Invoiced Date',
                value: 'billing.invoiced_date'
            },
            {
                label: 'Requested Date',
                value: 'billing.requested_date'
            }
        ]
    },
    {
        label: 'Delivery',
        options: [
            {
                label: 'Delivered Note Date',
                value: 'delivery.delivered_note_date'
            },
            {
                label: 'Requested Date',
                value: 'delivery.requested_date'
            }
        ]
    },
]

export default function RequestOverview({ match, ...props }) {

    // const [range, setRange] = useState([moment().startOf('day'), moment().endOf('day')]);

    const [range, setRange] = useState([
        moment.tz('Asia/Calcutta').startOf('day'),
        moment.tz('Asia/Calcutta').endOf('day')
    ]);

    const [option, setOption] = useState('transaction_date');

    const [filteringValue, setFilteringValue] = useState(filterData[0].options[0].value);

    const [selectedDate, setSelectedDate] = useState();

    const [queries, setQueries] = useState([]);

    const { params } = match;

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white', width: 200 }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                width: 200
            };
        },
    };

    function loadData(range) {

        setRange(range);

    }

    useEffect(() => {

        console.log(selectedDate)

        if (selectedDate) {
            onDateChange(selectedDate);
        } else {
            onDateChange([
                moment.tz('Asia/Calcutta').startOf('day'),
                moment.tz('Asia/Calcutta').endOf('day')
            ]);
        }
    }, [filteringValue])

    const onFilterChange = (value) => {
        setFilteringValue(value.value);
    }

    const onDateChange = (value) => {

        let query = [];

        if (value && filteringValue) {

            setSelectedDate(value);

            // setSelectedDate(moment.tz(value,'Asia/Kolkata').startOf("day").valueOf());

            query = [
                {
                    field: filteringValue,
                    operator: '>=',
                    value: moment(value[0]).tz('Asia/Kolkata').startOf('day').valueOf()
                },
                {
                    field: filteringValue,
                    operator: '<=',
                    value: moment(value[1]).tz('Asia/Kolkata').endOf('day').valueOf()
                }

            ]
        }

        setRange(value);
        setQueries(query);
    }

    return (
        <section className="request-overview">

            <div className="page-header">

                <Title level={3}>
                    Request Overview
                </Title>

                <div className="page-actions">


                </div>
            </div>

            <div className="page-filter">

                <div className="filter">

                    <div className="category">
                        <Select
                            placeholder="Select a field"
                            defaultValue={filterData[0].options[0]}
                            onChange={(value) => { onFilterChange(value) }}
                            options={filterData}
                            styles={colourStyles}
                        />
                    </div>
                    <div className="date-picker-component">
                        <Space direction="vertical">
                            <RangePicker
                                defaultValue={moment(new Date()).tz('Asia/Kolkata').startOf('day')}
                                allowClear={false}
                                inputReadOnly
                                format={'DD/MM/YYYY'}
                                value={range}
                                onChange={(value) => { onDateChange(value) }}
                                ranges={{
                                    Today: [moment(), moment()],
                                    Yesterday: [
                                        moment().subtract(1, 'days').startOf('day'),
                                        moment().subtract(1, 'days').endOf('day')
                                    ],
                                    'This Week': [
                                        moment().startOf('week'),
                                        moment().endOf('week')
                                    ],
                                    'Last Week': [
                                        moment().subtract(1, 'week').startOf('week'),
                                        moment().subtract(1, 'week').endOf('week')
                                    ],
                                    'This Month': [
                                        moment().startOf('month'),
                                        moment().endOf('month')
                                    ],
                                    'Last Month': [
                                        moment().subtract(1, 'month').startOf('month'),
                                        moment().subtract(1, 'month').endOf('month')
                                    ]
                                }}
                            />
                        </Space>
                    </div>
                </div>
            </div>

            <RequestList option={option} range={range} url={params.city} query={queries} {...props} />

        </section>
    );
}



function RequestList({ url, option, query, ...props }) {

    const [loading, setLoading] = useState();

    const [list, setList] = useState([]);

    const [selected, setSelected] = useState({});

    useEffect(() => {

        console.log(query);

        if (query.length) {
            getRequests();
        }

        return () => {

        }
    }, [query])

    function getRequests() {

        setLoading(true);

        var billingPending = [{
            field: 'billing.status',
            value: 'pending',
        }, {
            field: 'delivery.status',
            value: 'pending',
        }]

        FirebaseUtils.getRequests(url, query).then((result) => {

            console.log(result);

            setLoading(false);

            setSelected(result.requests[0]);

            setList(result.requests);

        })
    }

    return (<>


        <div className="main-content">



            <div className="list">

                <p className="list-subheader">
                    {loading ? 'Loading Requests' : list.length + ' results'}

                </p>



                {
                    !loading && list.map((request) => {

                        return <div className={`list-card ${(selected && selected.id === request.id) ? 'active' : ''}`} onClick={() => setSelected(request)} >
                            {selected && selected.id ? <RequestCard selected={selected.id} request={request} /> : null}
                        </div>

                    })
                }

            </div>

            {!loading && <div className="list-detail">

                {selected && selected.id ? <RequestDetail hideInfo={true} city={url} {...props} id={selected.id} /> : null}

            </div>}
        </div>
    </>)
}




function RequestCard({ request, index, selected }) {

    return (
        <div className={`request-card ${(selected === request.id) ? 'active' : ''}`}>
            <div className="">

                <h3 className="title">
                    {request.customer.customerName}

                </h3>
                {/* <h4 className="title">
                    {request.customer.phone}
                </h4> */}


                <p className="title ">
                    {request.variant} , {request.city}
                </p>

                <small>
                    {request.color}
                </small>

                <p className="address">
                    {request.customer.remarks}
                </p>


                {/* Created at {request.created_at} */}


            </div>
        </div>
    )
}