import Base from './base';

import { Comments, Bookings, BankCredits, CustomerEntries } from './';

import { message } from 'antd';

import moment from 'moment';

import FirebaseUtils from '../utils/firebase.utils';

import ApiUtils from '../utils/api.utils';

class BankCredit extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'bank_credits';
    }

    get path() {
        return `bank_credits`;
    }

    get getName() {
        return `bank_credits`;
    }

    /**
     * To reconcile a bank credit with customer entry

     * 
     * @param {*} values 
     * @param {*} collection 
     * @param {*} bankReceipt 
     * @param {*} allDepartments 
     * @param {*} user 
     * @param {*} callback 
     * @param {*} othref 
     */
    reconcileBankCredit = async (values, collection, bankReceipt, user, callback, othref) => {
        const hide = message.loading('Updating accounts entry, Please wait', 0);

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        if (collection.account === 'BOOKING') {

            //Not required without cs
            // return ApiUtils.createHead(collection, collection.department)
            // .then(async (newHead) => {
            // Update the voucher record
            // values.ac_code = newHead.ac_code;


            // Not required without cs
            //To update the bank_credits with the params returned from updateBankCredit
            var bankCreditParams = await this.updateBankCredit(values, collection, bankReceipt, user, othref);

            var docRef = this.getRecordReference(bankReceipt.receiptId);

            await batch.update(docRef, bankCreditParams);
            ////

            //To update the customer_entries with the params returned from updateCollection
            var collectionParams = await this.updateCollection(values, collection, bankReceipt, user);

            var customerRef = CustomerEntries.getRecordReference(collection.collectionId);

            await batch.update(customerRef, collectionParams);

            //To update the bookings with the params returned from updateBookings
            // var bookingParams = await this.updateBookings(values, collection, othref);

            // var bookingRef = Bookings.getRecordReference(bookingParams.bookingId);

            // await batch.update(bookingRef, bookingParams.params);

            hide();

            await batch.commit();

            message.success('The updates have been captured');

            await callback();
            // })
            // .catch(() => {
            //     hide();
            //     message.error('Creating new head failed');
            // });
        } else {

            // Not required wuthout cs

            // // Get the value from the object
            // values.ac_code = values.ac_code.value;

            // //To update the bank_credits with the params returned from updateBankCredit
            var bankCreditParams = await this.updateBankCredit(values, collection, bankReceipt, user, othref);

            var docRef = this.getRecordReference(bankReceipt.receiptId);

            await batch.update(docRef, bankCreditParams);

            //To update the customer_entries with the params returned from updateCollection
            var collectionParams = await this.updateCollection(values, collection, bankReceipt, user);

            var customerRef = await CustomerEntries.getRecordReference(collection.collectionId);

            await batch.update(customerRef, collectionParams);

            //To update the bookings with the params returned from updateBookings
            // var bookingParams = await this.updateBookings(values, collection, othref);

            // var bookingRef = await Bookings.getRecordReference(bookingParams.bookingId);

            // await batch.update(bookingRef, bookingParams.params);

            hide();

            await batch.commit();

            message.success('The updates have been captured');

            await callback();

            // setLoading(false);
        }
    };

    /**
     * Update the Bank Credit
     */
    updateBankCredit = (values, collection, bankReceipt, user, othref) => {
        // On complete
        return new Promise((resolve, reject) => {
            let params2 = {
                // pending_to_reconcile: false,
                clearing_entry: true,
                reconcile_status: 'completed',
                pending_at: 'done',
                reconciled_for: collection.collectionId,
                reconciling_city: collection.city,
                closing: {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_by: user.name,
                },
            };

            if (bankReceipt) {
                // There is a chance that the update is made by another city credit

                // In that case we need to make additional entries

                // to do the interbranch transactions
                if (bankReceipt.city !== collection.city) {
                    /**
                     * Update the voucher record
                     */
                    // return ApiUtils.verifyOtherBranch(bankReceipt, values, collection, 'P').then(() => {
                    resolve(params2);
                    //     return params2;
                    // });
                } else {
                    // #noteenable
                    // values.ac_code = values.ac_code;

                    // Update reference of collection in accounts
                    // values.v_othref = othref + collection.collectionId;

                    /**
                     * Update the voucher record
                     */
                    // return ApiUtils.updateVoucherEntry(bankReceipt, values).then(() => {
                    resolve(params2);
                    //     return params2;
                    // });
                }
            } else {
                resolve({});

                // history.goBack();
            }
        });
    };

    /**
     * Update the Collection
     */
    updateCollection = async (values, collection, bankReceipt, user) => {
        let verificationTime = {
            verified_at: moment().format('DD/MM/YYYY HH:mm'),
            verified_by: { name: user.name, role: user.role },
            verified_date: moment().startOf('day').valueOf(),
            verified_time: moment().valueOf(), // this can be removed if not needed
        };

        let params = {
            pending_to_reconcile: false,
            bank: bankReceipt.bank,
            reconciled_by: bankReceipt.receiptId,
            clearing_city: bankReceipt.city,
            verificationRemarks: values.verificationRemarks, //only done in pending-receipt
            verified_from: 'reconcile', //not done in colelction-suggestion
            verified: 'approved',
            pending_at: 'edp',
            status: 'reviewed',

            // Not required without cs
            // voucher: {
            //     ...bankReceipt.voucher,
            // },
            ...verificationTime,
            ...values,
        };

        return params;
    };

    updateBookings = async (values, collection, othref) => {
        let params;

        if (values.ac_code) {
            params = {
                ac_code: {
                    value: values.ac_code,
                },
            };
        }

        var updateValues = await Bookings.updateBookingFields(collection);

        let bookingId;

        if (othref === 'RP:S' || othref === 'RD:S' || othref === 'CS:S') {
            bookingId = collection.dealerCode + collection.bookingNo;
        } else {
            bookingId = collection.bookingNo;
        }

        params = {
            ...updateValues,
            ...params,
        };

        var bookingParams = {
            params: params,

            bookingId: bookingId,
        };
        return await bookingParams;
    };
}

export default BankCredit;
