import React, { useEffect, useState, useContext } from 'react';

import { Tag, Button, message, Alert } from 'antd';

import { GlobalContext } from '../../../../../../Store';

import FirebaseUtils from '../../../../../../utils/firebase.utils';

import moment from 'moment';

import { Typography } from 'antd';
import { dealerCodeKey } from '../../../../../global-config';

const { Title, Text } = Typography;

var dealer = dealerCodeKey

// Maintaining each flag for each process
let defaultStats = {
    accounts: {
        status: 'pending',
    },

    allocation: {
        status: 'pending',
    },

    finance: {
        status: 'pending',
        process: 'incomplete',
    },
    // insurance: {
    //     status: 'pending',
    //     process: 'incomplete'

    // },
    accessories: {
        status: 'pending',
        process: 'incomplete',
    },
    warranty: {
        status: 'pending',
        process: 'incomplete',
    },
    billing: {
        status: 'pending',
    },

    actual_billing: {
        status: 'pending',
    }

};

export function BookingCard({ preference, booking, callback }) {
    const { user,disableDisplayPhoneNumber } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    /**
     *
     * Allocate already existing booking to
     * the team leader
     * Team leader has to verify after which the finance process woudl
     * start
     *
     * @param {*} param0
     */
    function createRequest({ booking }) {
        setLoading(true);


        let city = user.locations[0];

        // Find the city of the booking
        let bookingCity = dealer[booking['Dealer Code']];

        let customer = {
            customerName: booking['Name of the Customer'],
            phone: booking['Contact Number'],
            // place: booking["Ship To Add"] || booking["Bill To Add"],
            customerID: booking['Customer ID'],
        };

        if (booking['Bill To Add']) {
            customer = {
                ...customer,
                place: booking['Bill To Add'],
            };
        }



        if (booking['Ship To Add'] && !customer.place) {
            customer = {
                ...customer,
                place: booking['Ship To Add'],
            };
        }

        let newBooking = {
            ...defaultStats,
            created_at: new Date(),
            dealerCode: booking['Dealer Code'],
            deleted_at: null,
            deleted_by: null,
            verified: true,
            status: 'Verification Pending',
            kec: booking['Consultant Name'],
            teamLeader: booking['Team Leader'] || 'NA',

            ...preference, // preference is considered here , not the booking choice .
            city: bookingCity, // override the city in the preference with the one in the booking
            ...{
                bookingNo:(booking['Booking No'].substring(0, 4) !== 'KL30'&&booking['Booking No'].substring(0, 4) !== 'KL50') ? booking['Dealer Code'] + booking['Booking No'] : booking['Booking No'],
            },
            ...{
                customer: customer,
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                // expectedDate: booking['Committed Delivery Date']
            },
            created_by: user.id,
            created_by_name: user.name,
            created_by_role: user.role
        };

        if (booking['consultant_id']) {
            newBooking = {
                ...newBooking,
                consultant_id: booking['consultant_id']
            }
        }

        if (booking['teamleader_id']) {
            newBooking = {
                ...newBooking,
                teamleader_id: booking['teamleader_id']
            }
        }

        // If its for a different location , we have to add a field to identify that
        if (city !== preference.city) {
            newBooking.destination_city = preference.city;
        }

        if (booking['Committed Delivery Date']) {
            newBooking.expectedDate = booking['Committed Delivery Date'];
        }

        FirebaseUtils.addRequest(newBooking).then((result) => {
            FirebaseUtils.updateBooking(
                // bookingCity,
                booking['Booking No'],
                true,
                result.id
            ).then(() => {
                message.info('Request created');

                setLoading(false);

                callback();
            });
        });
    }

    return (
        <div className="booking-card allocation-request">
            <div className="card">
                <div className="left">
                    <Title level={4}>{booking['Name of the Customer']}</Title>

                    {!disableDisplayPhoneNumber ? <h4 className="title">{booking['Contact Number']}</h4> : null}

                    <div>
                        <h3 className="title variant">{booking['Variant']}</h3> <small> {booking['Color']}</small>
                    </div>

                    <h4>
                        {booking['Booking No']} - <Tag color="#2db7f5">{dealer[booking['Dealer Code']]}</Tag>
                    </h4>

                    <h4>
                        KEC : {booking['Consultant Name']} | Team Leader : {booking['Team Leader']}
                    </h4>

                    <p className="">
                        Booked on {booking['Booking Date']},{' '}
                        <Text type="danger">{`${moment().diff(moment(booking['Booking Date'], 'DD/MM/YYYY'), 'days')} days ago.`}</Text>
                        {booking.current && booking.current.currentStatus ? (
                            <>
                                <Tag color="magenta">{booking.current.currentStatus}</Tag>

                                <p>Recent Remarks : {booking.current.remarks}</p>
                            </>
                        ) : (
                            <></>
                        )}
                    </p>

                    {booking['Committed Delivery Date'] ? <>Committed Delivery Date {booking['Committed Delivery Date']}</> : null}
                </div>
                <div className="right card-buttons">
                    {/* Only Shown for SM */}
                    {['rm', 'sm', 'admin'].indexOf(user.role) !== -1 && booking.document_status === "completed" ? (
                        <Button loading={loading} onClick={() => createRequest({ booking })} type="secondary">
                            Create Request
                        </Button>
                    ) :
                        <Alert
                            message={`Please upload customer proof to create request`}
                            type="info"
                            showIcon
                            style={{ margin: '10px 0px' }}
                        />
                    }
                    {/* Only Shown for SM Ends */}

                    <a className="card-action" href={`tel:${booking['Contact Number']}`} key="list-loadmore-edit">
                        Call
                    </a>
                </div>
            </div>
        </div>
    );
}
