

import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
// const localizer = Calendar.momentLocalizer(moment) // or globalizeLocalizer

const localizer = momentLocalizer(moment)


export function EventCalendar({ events, ...props }) {

    return (
        <Calendar
            {...props}
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, width: '100%' }}
            onSelectEvent={props.onSelectEvent}
        />
    )
}
