import React, { useEffect, useState, Fragment, useContext } from "react";

import { Typography } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder'

import FirebaseUtils from '../../../../utils/firebase.utils';

import RequestInfo from '../../../../components/request-info/request-info';
import MatchingReceipts from '../../../../components/matching-receipts/matching-receipts';

import './delivery-form.scss';

import RequestForm from '../../../common/resources/request-form/request-form';

import CommentBlock from '../../../common/components/comment-block/comment-block';

const { Title } = Typography;


export default function DeliveryScheduleForm({ step, match, history }) {

    const [request, setRequest] = useState({ insurance: {}, customer: {} });

    const { params } = match;

    const { city, id } = params;

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        FirebaseUtils.getRequest(id).then((result) => {

            let data = { ...{ warranty: {} }, ...result };

            console.log(data);

            setRequest(data);

            setLoader(false);

        });

    }, [])


    return (
        <div className="insurance-form">

            {
                loader ?
                    <PlaceHolder type="report" />
                    :
                    <Fragment>

                        <div className='page-content'>

                            <h1 className="page-heading">
                                Complete Delivery
                             </h1>


                            <div className="split-container">

                                <div className="left">

                                    {/* Vehicle Card */}
                                    <RequestInfo request={request} />

                                </div>


                                <div className="right">


                                    <div className="document-status">

                                        <Title level={3}>Delivery Form </Title>

                                        <p>
                                            Please make sure all the items in the cheklist are ticked before delivery
                                        </p>

                                        <RequestForm
                                            formContent={request}
                                            modelIndex='requestId'
                                            model={step.model}
                                            preventMessage={'Delivery note is not ready yet'}
                                            preventCondition={() => {

                                                return request.delivery.note

                                            }}
                                            step={step}
                                            callback={() => {

                                                history.goBack();

                                            }}
                                        />

                                    </div>


                                    {/* Receipts
                                    <MatchingReceipts
                                        userRequest={request}
                                        callback={(total) => {

                                            // setReceived(total);

                                        }} />
                                    Receipts Ends */}

                                    {/* <CommentBlock id={request.id} /> */}

                                </div>
                            </div>


                        </div>


                    </Fragment>}
        </div>
    )
}





