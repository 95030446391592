
import React, { useState, useContext } from "react";

import { Button, Modal } from 'antd';

import CommentBlock from './../comment-block/comment-block';

import './view-comment.scss';


export default function ViewComment({ id, caption = 'View Comment', }) {

    const [visible, setVisible] = useState(false);

    /**
     * 
     */
    function closeModal() {
        setVisible(false);
    }


    return (<div className="block-vehicle">

        <Button size="small" type="secondary" onClick={(e) => {

            e.preventDefault();
            e.stopPropagation();

            setVisible(true);

        }}>
            {caption}
        </Button>


        <Modal
            title="View Comments"
            visible={visible}
            onOk={closeModal}
            onCancel={closeModal}>


            <CommentBlock id={id} />

        </Modal>
    </div>)
}