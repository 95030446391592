/**
 *  Dashboard Component
 *
 * @description Component to show Dashboard
 * @author Nihala Dilshi
 */

import React, { useState, useEffect } from 'react';

import BookingDashboard from '../booking-dashboard/booking-dashboard';
import DmsStockAging from '../dms-stock-aging/dms-stock-aging';
import EnquiryDashboard from '../enquiry-dashboard/enquiry-dashboard';


/**
 *
 * Component for  Dashboard
 *
 * @param {*} param0
 */
export default function Dashboards({ }) {

   
    return (
        <>
            <div>
                <>
                   <BookingDashboard/>
                   <DmsStockAging/>
                   <EnquiryDashboard/>
                </>
            </div>
        </>
    );

}



