import moment from 'moment';
import Base from './base';

class DocumentLogs extends Base {

    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'document_logs';
    }

    get path() {
        return `document_logs`;
    }

    get getName() {

        return `document_logs`;

    }

    /**
    * Record all the fields and logs the change in document_logs.
    */
    recordLog = async (model, record, values, user, batch, recordRef) => {
        const updatedFields = {};
        const fieldsChanged = [];
        const previousValues = {};
        const updatedValues = {};
        // Iterate over each key in values to compare with record
        Object.keys(values).forEach((field) => {
            if (values.hasOwnProperty(field) && values[field] !== record[field]) {
                console.log('0988', values[field], record[field])
                updatedFields[field] = values[field];
                fieldsChanged.push(field);
                previousValues[field] = record[field] !== undefined ? record[field] : null;
                updatedValues[field] = values[field] ? values[field] : null;
            }
        });
        if (fieldsChanged.length === 0) {
            return;
        }
        await batch.update(recordRef, updatedFields);
        const param = {
            fields_changed: fieldsChanged,
            previous_value: previousValues,
            model,
            record_id: record.id,
            updated_value: updatedValues,
            updated_at: moment().format('DD/MM/YYYY HH:mm'),
            updated_by:
            {
                name: user.name,
                id: user.id
            }
        };
        // Get a reference to the document logs collection and set the log with merge: true
        const documentRef = await this.getRecordReference();
        await batch.set(documentRef, param, { merge: true });
        await batch.commit();
    };
}

export default DocumentLogs;