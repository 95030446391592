import React, { useState, useContext } from "react"

import { withRouter } from 'react-router-dom'

import { GlobalContext } from './../../Store';

import { Typography } from 'antd';


var logo = require('./../../assets/images/kia-logo.png');

const { Title } = Typography;



function PrivacyComponent({ history }) {

    const { user, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    return (
        <section className="privacy-component">
            <div className="privacy-section">

                <div className="brand-logo">
                    <img className="logo-welcome" src={logo} alt="Logo" />
                </div>

                <div className="content-section" style={{ margin: '50px' }}>


                    <Title level={3}>
                        PRIVACY POLICY
                    </Title>
                    {/* need to write about kia */}
                    <p class="h5 privacy-para1">

                    </p>
                    <p class="h5 privacy-para1">
                        Please carefully review this Privacy Policy below before using our website. We reserve
                        the right to modify or amend the terms from time to time without notice. You are
                        requested to read and understand the Privacy Policy every time you use the website.
                        Your continued use of our website will mean you accept the changes to the Privacy
                        Policy as and when they are incorporated. When you access our website, you agree to
                        be bound by the terms and conditions of this Privacy Policy as updated from time to
                        time. If you do not agree to the terms of this Privacy Policy, you may immediately
                        discontinue using the website and may not accept or download the cookies into your
                        device(s).</p>
                    <p class="h5 privacy-para1">

                        <b>What information do we collect?</b><br></br>
                        We collect the following information; (1) personally identifiable information that you
                        provide voluntarily when you access certain features of the Services as noted below in
                        this Privacy Policy, and (2) generalized usage information that we collect automatically
                        as you access the Services.
                    </p>
                    <p class="h5 privacy-para1">

                        When you browse or register for services as may be available on our website, you may
                        be required to provide certain basic information when filling in the mandatory data
                        sheet on the website. During the process, you may be required to enter your name, e-
                        mail address, mailing address and/or phone number and such other information as may
                        be needed from time to time. If you are merely browsing the website, Cookies may be
                        downloaded onto your device(s) automatically or with permission depending on the
                        settings within the browser that you may be using (Google Chrome, Internet Explorer,
                        etc.) These settings may be changed or modified in your browser.</p>
                    <p class="h5 privacy-para1">

                        The information that is voluntarily provided are; (a) Information collected when signing
                        up: personal details including but not limited to name, user name, address, contact
                        information, date of birth, etc and such other information submitted when creating your
                        account or collected through the Cookies that are downloaded on to your web browser.
                        (b) Data we collect for customer service and support: information that you provide
                        including records of correspondences, feedbacks, customer surveys, competitions,
                        browsing patterns, etc. will be retained by us.</p>
                    <p class="h5 privacy-para1">

                        <b>Usage Information That Is Automatically Collected on the Websites.</b><br></br>
                        When you
                        access our Websites, we may collect certain generalized information without your
                        actively providing such information which may include, but not limited to your browser
                        type, your operating system, and your IP address using Cookies.
                    </p>
                    <p class="h5 privacy-para1">
                        If you enable location services on your device and grant permission to the website to
                        access this feature, we may collect your location via GPS in order to provide you with
                        such additional or relevant features. We automatically identify your location using the IP
                        address of your specific device, to track usage of our website, which is considered
                        Anonymous Data, collected using Cookies downloaded onto the device allowing us to
                        provide you with seamless service while browsing the website (for more information,
                        please refer – Our Cookie Policy).</p>
                    <p class="h5 privacy-para1">

                        The information collected will be maintained in our designated server for as long as is
                        necessary. You may contact us for removal/modification of your data if required and
                        such requests shall be considered by us at our sole discretion.
                    </p>
                    <p class="h5 privacy-para1">
                        <b>How we use the information?</b><br></br>

                        To personalize your experience, to improve the website, to improve customer service,
                        to process transactions, etc. The information, whether in the public or private domain,
                        will not be sold, exchanged, transferred, or transferred to any third-party, without your
                        consent, unless such information is requested by a court of law.
                    </p>
                    <p class="h5 privacy-para1">

                        <b>How do we protect your information?</b><br></br>
                        We implement all standard industry-accepted protocols as security measures to
                        maintain the safety of your personal information. We offer the use of a secure server.
                        Unfortunately, the transmission of information via. the internet is not completely secure.
                        We will do our best to protect your personal data, but we cannot guarantee the security
                        of your data transmitted to the website; any transmission is at your own risk. Once we
                        have received your information, we will use standard protocols and procedures to try to
                        prevent unauthorized access or dissemination. You are responsible for keeping the
                        password or user ID code confidential issued or created by you for the use of the
                        website. We insist that you do not share the password or user ID code with any third-
                        party. All supplied payment-related information is transmitted to our banking partners
                        which are then encrypted into our Payment gateway providers database only to be
                        accessible by those authorized with special access rights to such systems, and are
                        required to keep the information confidential. After a transaction, your private
                        information (credit cards, social security numbers, financials, etc.) will not be stored on
                        our servers.
                    </p>
                    <p class="h5 privacy-para1">
                        <b>Cookies</b><br></br>
                        We may place small data files, called “cookies,” on your device. It is standard web
                        technology allowing us to store and retrieve customer information such as login details,
                        passwords, etc., on the users' devices. Cookies automatically identify your browser to
                        our server whenever you access the website. Cookies also help us review usage and
                        traffic patterns on our website. You can change the cookie setting in the browser as
                        most automatically download the cookies, although this may prevent the seamless
                        experience of various features, suggestions, preferences, etc. while using the website.
                        None of the information collected is linked to any personally identifiable information of
                        the user.
                    </p>

                    <p class="h5 privacy-para1">

                        <b>Third-Party Links</b><br></br>
                        Occasionally, at our discretion, we may include or offer third-party products or services
                        on our website. These links are provided only for your convenience and, as such, you
                        may choose to access the links at your own risk. However, we do not assure or
                        guarantee the integrity of the website or the content therein. Each of these third-party
                        sites has separate and independent privacy policies and are not governed by this
                        Privacy Policy and we take no responsibility or liability for the content and activities of
                        these linked sites. We recommend reading and understanding the Privacy policy of the
                        third-party website before accessing and using them. Nevertheless, we seek to protect
                        the integrity of our website and welcome any feedback about these sites.
                    </p>
                    <p class="h5 privacy-para1">
                        <b>Cookie Policy</b><br></br>
                        As already mentioned above, we procedurally collect information about your device by
                        using Cookies that are downloaded as approved by you. They are widely used in order
                        to make websites work, or work more efficiently, as well as to provide information to
                        the owners of the site. We use cookies to enhance our security, help us gather
                        information during subscription and understand our web traffic trends. They help us to
                        improve the Services and to deliver a better and more personalized experience. They
                        enable us:
                        1. To estimate our audience and usage patterns;
                        2. To store information about your preferences and customize the services on the
                        website to your individual interests;
                        3. Faster searches;
                        4. To provide anonymised statistical information using non-personal data
                        You may set your browser to block cookies and local storage devices, but if you do so,
                        you may not be able to access all of the features of the Services. For further
                        functionalities and the uses about cookies visit: www.aboutcookies.org
                    </p>

                    <p class="h5 privacy-para1">

                        <b>Additional Privacy Protections For Children Using The Internet</b><br></br>
                        Our services are not targeted towards minor children. Our website does not permit
                        children under the age of 18 to sign up for services. We encourage minors to seek the
                        consent of their parents before providing any information about themselves or their
                        households to anyone on the Internet. We encourage parents to take an active role to
                        protect the privacy and security of their children and to prevent the inappropriate use of
                        information about their children. We support the development of technologies that help
                        parents to control the collection and use of personal information from children who use
                        online services in their households. However, should a minor share information with us
                        on the website the same level of protection will be extended to them as well. The
                        Parents may reach out to us for more information on how to keep safe or remove such
                        data that is already shared, any action taken in this regard shall be purely at our
                        discretion and will be final.
                    </p>
                    <p class="h5 privacy-para1">

                        <b>Handling incoming calls which disconnect an ongoing call</b><br></br>
                        Kia will use third party platform to receive and make calls to our registered and Interested (Leads) Customers only.
                    </p>
                    <p class="h5 privacy-para1">

                        <b>Queries</b><br></br>
                        If you have any questions about this Privacy Policy, then please contact us at
                        it@dkhkia.com
                    </p>
                    <p class="h5 privacy-para1">
                        <b>Changes to Privacy Policy</b><br></br>
                        We may change/modify this Privacy Policy from time to time. We encourage you to
                        check this page for any changes to the Privacy Policy and new versions thereof when
                        using the website. By continuing to access, browse, and/or use the Services, you agree
                        to accept any posted revised terms.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default withRouter(PrivacyComponent)