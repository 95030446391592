/**
 *
 * Final Entry for all the API calls
 *
 * All the common configurations should bypass httpUtils
 * Keep adding additional methods on requirement
 *
 */

import GLOBAL from './../global';
import FirebaseUtils from './firebase.utils';
import { message } from 'antd';

// import Notify from './Notify'
let authToken = localStorage.getItem('token');

let headers = {
    // 'Accept': 'application/json',
    'Content-Type': 'application/json',
    // 'Cache-Control': 'no-cache',
    // 'X-Apartment-Id': 1,
};

/**
 * Method for Get Call
 *
 * @param {*} param
 */
export async function GetData({ url, formBody }) {
    // console.log(url);
    return ApiCall({ url, formBody, method: 'GET' });
}

/**
 * Method posts data to mentioned url
 *
 * @param {*} param
 */
export async function PostData({ url, formBody }) {
    return ApiCall({ url, formBody, method: 'POST', returnResponse: true });
}

/**
 * Method for update the data
 */
export async function PutData({ url, formBody }) {
    return ApiCall({ url, formBody, method: 'PUT', returnResponse: true });
}

/**
 * Final Exit for API call
 *
 * @param {*} param
 */

export async function ApiCall({ url, formBody, method, ...props }) {
    // var token = JSON.parse(localStorage.userInfo).token;

    var token = await FirebaseUtils.getToken();

    headers = {
        ...headers,
        Authorization: 'Bearer ' + token,
        // Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjdiODcxMTIzNzU0MjdkNjU3ZjVlMjVjYTAxZDU2NWU1OTJhMjMxZGIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZ2J1c2luZXNzLTE2MDE3ODkzMzkyNDEiLCJhdWQiOiJnYnVzaW5lc3MtMTYwMTc4OTMzOTI0MSIsImF1dGhfdGltZSI6MTYzMjc1NzU1OCwidXNlcl9pZCI6ImtvVEsyN3ZjUTdPdFp6N3Q0Q0ZaWFZrbHplNDIiLCJzdWIiOiJrb1RLMjd2Y1E3T3Raejd0NENGWlhWa2x6ZTQyIiwiaWF0IjoxNjMyODA4NTQ1LCJleHAiOjE2MzI4MTIxNDUsImVtYWlsIjoicmVhY2guYXNoaXF1ZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsicmVhY2guYXNoaXF1ZUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.PgsgbSObxlqw9MW18EyBCsdg2ceB5dKApw91blv2Xdg2TYn2PYxYNw7i4XsZlLQJlxcV4qdoLK-_971WBn9U2KjdjlpQAGbWX0-lZCmM-G6eBraOfZeX2LfvXcN4E6AJo0gMTXSJg6qIpHbPbTC4Re3SS4dCkGxDbYZnhw3yx4TyIRIatc6GwLdPUI_oHz3OC8seMD8A9VqAaVPtiaAuLxJaTWbebAwdky3vPTMG4GHw6q90GtFRnOAX8u9r87ZoBzL5R_WZVXbWeGsKiSz0p4sHDpI8Pm6fgLIZF7tardYcms7ofgBj-pd_QijNn6B2uF-1t9O4UMpdXM4vnaLxCQ'
    };

    return new Promise((resolve, reject) => {
        let payload = {
            method: method,
            headers: headers,
            body: formBody ? JSON.stringify(formBody) : null,
        };

        // var baseUrl = 'http://localhost:3000/dev';

        // var baseUrl = 'https://api.dkhmotors.com/dev';

        // var baseUrl = 'https://api.dkhmotors.com/prod';
        // 
        var baseUrl = process.env.REACT_APP_endpoint;

        // For Kannur and Calicut update the base url
        // if (url.indexOf('clt') !== -1 || url.indexOf('knr') !== -1) {
        //     baseUrl = GLOBAL.API.API_PROD_URL;
        // }

        return fetch(baseUrl + url, payload)
            .then((res) => res.json())
            .then((res) => {
                // console.log('api response', res);
                // return res

                if (res.statusCode === 400 || res.statusCode === 500 || res.statusCode === 401) {
                    message.error(JSON.stringify(res.message));

                    reject(res);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                // Notify({ message: 'Hello', type: 'danger' });
                console.log('error api response', error);
                // return error

                reject(error);
            });
    });
}
