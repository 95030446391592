
import React, { useState, useEffect, useContext } from "react";

import { Row, Button, Tag, Badge } from "antd";

import { Link } from 'react-router-dom'

import { Tabs } from 'antd';

import PlaceHolder from "../../../../components/ui_elements/PlaceHolder";

import "./customer-list.scss";

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

const { TabPane } = Tabs;

export default function WarrantyCustomers({ step }) {

    const { user } = useContext(GlobalContext);

    const [allocations, setAllocations] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        getData();

    }, [user])

    function getData() {

        setLoader(true);

        const { locations } = user;

        FirebaseUtils.getRequests(locations[0], step.queries).then((vehicles) => {

            setLoader(false);

            console.log(vehicles);

            setAllocations(vehicles.requests);

        });

    }

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="landing">

            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Pending Customers" key="1">

                    <div className="list-header">

                        <p className="page-desc">
                            {loader ? 'Loading data' : `Found ${Object.keys(allocations).length} results`}
                        </p>

                        <div>
                            <Button onClick={getData} type="secondary" size={'small'}>Refresh</Button>

                        </div>

                    </div>

                    <Row className="report-listing-wrapper">
                        {loader ? (
                            <PlaceHolder type="listing" />
                        ) : (
                                <>
                                    {
                                        (allocations).map((report, index) => {
                                            return <WarrantyCard step={step} vehicle={report} index={report.id} key={index} />
                                        })
                                    }
                                </>
                            )}
                    </Row>
                </TabPane>

            </Tabs>

        </section>
    );
}



function WarrantyCard({ vehicle, index, step }) {

    return (
        <Link className="report-item" to={`${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">
                    {vehicle.bookingNo}
                </h2>

                {/* {vehicle.finance && vehicle.finance.documentStatus ? <Tag color="orange">{vehicle.finance && vehicle.finance.documentStatus}</Tag> : null} */}


                {/* {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                } */}


                <h3 className="title">
                    {vehicle.customer.customerName}
                </h3>

                <h2 className="title">
                    {vehicle.customer.phone}

                </h2>


                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}

                </h3>


                <p className="address">
                    KEC on {vehicle.kec} , {vehicle.created_at}
                </p>

                {/* <Tag color="magenta">{vehicle.status}</Tag> */}

                {vehicle.insurance && vehicle.insurance.documentStatus ?
                    <Badge status="processing" text={vehicle.insurance.documentStatus} />
                    :
                    null
                }



            </div>
        </Link>
    )
}