import Base from './base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import FirebaseUtils from '../utils/firebase.utils';

import { Comments, StatusMasters, Bookings } from './'

import moment from 'moment';

// import CollectionAdd from './../modules/accounts/components/collection-add/collection-add';

import { Settings, Users } from './index';

class Booking extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get filterDate() {
        return true;
    }

    get getEndpoint() {
        return 'bookings';
    }

    get path() {
        return `bookings`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `booking`;
    }

    get columns() {
        return [];
    }
    //Get Records with a limit
    getOrderRecord = (location, queries, config) => {
        return FirebaseUtils.getOrderRecord(location, queries, this, config);
    };

    //Get Records starting from middle

    getListingData = (location, queries, config) => {
        return FirebaseUtils.getListingData(location, queries, this, config);
    };

    //Update Bookingstatus and amounts

    updateBookingFields = async (collection) => {
        let receipt_amount, booking_amount, params;

        //To get booking amount of model from settings table
        var modelBookingAmount = await this.getModelBookingAmount(collection.model);

        if (collection.account === 'BOOKING') {
            booking_amount = await this.getAmount(collection, 'booking_amount');

            receipt_amount = await this.getAmount(collection, 'receipt_amount');

            params = {
                booking_amount: booking_amount,

                receipt_amount: receipt_amount,
            };

            //only if total booking amount is greater than booking amount of model
            //booking status will be chnaged to nooking
            if (params.booking_amount >= modelBookingAmount) {
                params = {
                    ...params,
                    booking_status: 'booking',
                };
            }
        } else {
            receipt_amount = await this.getAmount(collection, 'receipt_amount');

            params = {
                receipt_amount: receipt_amount,
            };
        }

        return params;
    };


    /**
    *
    *
    * @param {*} city
    * @param {*} id
    * @param {*} values
    * @param {*} statusUpdate
    * @param {*} remarks
    * @param {*} booking
    * @returns
    */
    updateBookingGeneric = (id, values, statusUpdate, remarks, booking) => {
        var user = this.getUser();

        if (remarks) {
            let content = {
                author: user.name,
                content: 'Status Update : ' + statusUpdate + ' - ' + remarks || values.remarks,
                datetime: moment().format('DD/MM/YYYY HH:mm'),
            };

            return Comments.add(id, content).then((result) => {
                return this.update(id, values);
            });
        } else {
            return this.update(id, values);
        }
    };

    /**
     * Get the receipt amount and booking amount of the booking
     *
     * @param {*} values
     * @returns
     */
    getAmount(collection, index) {
        return this.getRecord(collection.dealerCode + collection.bookingNo).then((result) => {
            let existing_amount = result[index] || 0;
            return existing_amount + collection.amount;
        });
    }


    /**
     *Update booking status 
     *
     */
    updateModelStatus(id, name) {
        Bookings.getRecord(id).then((result) => {
            // Get booking status from status master
            let queries = [
                {
                    field: 'name',
                    value: name,
                },
            ];

            StatusMasters.get(queries).then((res) => {

                // updating booking current status

                return Bookings.update(result['Booking No'], {
                    current: {
                        ...result.current,
                        currentStatus: res.status_masters[0].name,
                        currentStatus_id: res.status_masters[0].id,
                        status:'Status Updated'

                    },
                })
            });
        })
    }

    /**
     *Get booking amount of model from settings
     *
     */
    getModelBookingAmount(model) {
        var query = [
            {
                field: 'name',
                value: 'booking_amount',
            },
        ];
        return Settings.get(query).then((result) => {
            var amount = result.settings[0].models[model];

            return amount;
        });
    }
}

export default Booking;
