import React, { useEffect, useContext } from 'react';

import { Tabs, Divider } from 'antd';

import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './../../hocs/PrivateRoute';

import GlobalHeader from './../../components/global-header/global-header';

import ApprovalList from './components/approval-list/approval-list';
import ApprovalForm from './components/approval-form/approval-form';

import Dashboard from './../common/components/dashboard/dashboard';
import StockDashboard from './../common/components/stock-dashboard/stock-dashboard';

import BulkKecChange from './components/bulk-kec-change/bulk-kec-change';

// Accounts Overview
import AccountsOverview from './../common/resources/accounts-overview/accounts-overview';

import IncentiveOverview from './../common/resources/incentive-overview/incentive-overview';

import BookingOverview from './../common/resources/booking-overview/booking-overview';

import DailyOverview from './../common/resources/daily-overview/daily-overview';

import MonthWiseRetail from './../common/resources/monthwise-retail/monthwise-retail';

import TradingProfit from './../common/resources/trading-profit/trading-profit';

import VerifyPayment from './../common/components/verify-payment/verify-payment';

import MatchingDashboard from './../common/components/matching-dashboard/matching-dashboard';

import ReconcileSummary from './../common/components/reconcile-summary/reconcile-summary';
import ManagePayments from './../common/components/manage-payments/manage-payments';

import ManageBookings from './../common/components/manage-bookings/manage-bookings';
import ManageRequests from './../common/components/manage-requests/manage-requests';

import ManageUsers from './../common/components/manage-users/manage-users';

import StockDetail from './../common/components/stock-detail/stock-detail';

import PaymentRequests from './../common/components/payment-requests/payment-requests';

// import StockDashboard from './components/stock-dashboard/stock-dashboard';
// import GenericListing from './../../components/listing/listing';
import IFStatus from './../common/components/if-status/if-status';

import ActualStock from './../common/components/actual-stock/actual-stock';

import PreferenceDetail from './../common/components/preference-detail/preference-detail';
import RequestDetail from './../common/components/request-detail/request-detail';

// Existing Allocations
import ExistingAllocation from './../common/components/existing-allocations/existing-allocations';

import HighPriority from './../common/components/high-priority/high-priority';
import HighPriorityDetail from './../common/components/high-priority-detail/high-priority-detail';

import CustomerReceipts from '../common/components/customer-receipts/customer-receipts';
import CustomerReceiptDetail from '../common/components/customer-receipts/customer-receipts-detail';
import CustomerReceiptAdd from '../common/components/customer-receipts/customer-receipts-add';

import Cancellations from '../common/components/cancellations/cancellations';
import CancellationsDetail from '../common/components/cancellations/cancellations-detail';

import VehicleDetail from '../../components/vehicle-detail/vehicle-detail';

import PaymentRequestsTile from './../common/components/payment-requests-tile/payment-requests-tile';
import ChangePassword from './../common/components/change-password/change-password';

import BookingDetail from './../common/components/booking-detail/booking-detail';

import './admin-landing.scss';

import { GlobalContext } from './../../Store';

import TaskRoutes from './../common/resources/task-routes/task-routes';

import { billSchema, billActions, ItemWiseSchema, itemActions } from './../global-config';

import { stockSchema, stockColumns, adminSteps, userSchema } from './admin-config';

import BankUpload from '../common/components/manage-payments/bank-upload';

import StockTransferRecord from '../common/resources/stock-transfer/stock-transfer';

import StockTransactionRecord from './../common/resources/stock-transaction/stock-transactions';


import {
    paymentRequestSchema,
    partsSchema,
    partsColumns,
    receiptsSchema,
    requestSchema,
    bookingSchema,
    payoutSchema,
    billingRequestSchema,
    deliveryRequestSchema,
    requestRouteConfig,
} from './../global-config';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import RequestOverview from './../common/resources/request-overview/request-overview';

import ModuleRoutes from './../common/resources/modules-routes/module-routes';

import ModuleRoutesLatest from '../common/resources/modules-routes-latest/modules-routes-latest';

import DataCorrection from './../common/resources/data-correction/data-correction';

import BookingTracker from './../common/resources/booking-tracker/booking-tracker';
import { Location } from '../../utils/location.utils';
import ReconcileOverview from '../common/resources/reconcilation-overview/reconcilation-overview';

import PendingWarranty from './../common/resources/pending-warranty/pending-warranty';

import VehicleOverview from './../common/resources/vehicle-overview/vehicle-overview';

import AccessoryOverview from './../common/resources/accessory-overview/accessory-overview';

import RetailTracker from './../common/resources/retail-tracker/retail-tracker';

import DeliveryTracker from './../common/resources/delivery-tracker/delivery-tracker';

import FinanceTracker from './../common/resources/finance-tracker/finance-tracker';
import FinancePayoutTracker from './../common/resources/finance-payout-tracker/finance-payout-tracker';
import BillDetails from './../common/resources/finance-payout-tracker/bill-details';
import DailyDashboard from '../common/resources/daily-dashboard/daily-dashboard';

import InsuranceTracker from './../common/resources/insurance-tracker/insurance-tracker';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';
import BookingReport from '../common/resources/booking-report/booking-report';

import enquiryReport from '../common/resources/enquiry-report/enquiry-report';

import BillList from '../common/resources/bill-list/bill-list';

import SalesReport from '../common/resources/bill-list/sales-report';
import { Employees } from '../../models';

import ManageStock from './../common/components/manage-stock/manage-stock';


const { TabPane } = Tabs;

export default function AdminLanding(props) {
    const { user } = useContext(GlobalContext);

    const currentURL = Location.search();

    const tagToSearch = Object.keys(currentURL)[0];

    const statusToSearch = currentURL[tagToSearch];

    useEffect(() => {
        const tagToSearch = Object.keys(currentURL)[0];
    }, []);

    const modules = [
        {
            caption: 'Daily Overview',
            path: '/daily-overview',
        },

        {
            caption: 'Reports',
            submenus: [
                {
                    caption: 'Monthwise Retail',
                    path: '/monthwise-retails',
                },

                // {
                //     caption: 'Enquiry Report',
                //     path: '/enquiry-report',
                // },
                // {
                //     caption: 'Booking Report',
                //     path: '/booking-report',
                // },
            ],
        },

        {
            caption: 'Reconcilation',
            submenus: [
                {
                    caption: 'Sales Accounts',
                    submenus: [
                        {
                            caption: 'Account Overview',
                            path: '/sales/accounts-overview',
                        },
                        {
                            path: '/sales/pending-summary',
                            caption: 'Pending to Reconcile',
                        },

                        {
                            path: '/verify-payment',
                            caption: 'Verify Receipts',
                        },
                        {
                            path: '/sales/bank-receipts',
                            caption: 'Bank Receipts',
                        },

                        {
                            path: '/sales/customer-receipts',
                            caption: 'Customer Receipts',
                        },
                    ],
                },

                {
                    caption: 'Service Accounts',
                    submenus: [
                        {
                            caption: 'Account Overview',
                            path: '/service/accounts-overview',
                        },
                        {
                            path: '/service/pending-summary',
                            caption: 'Pending to Reconcile',
                        },

                        {
                            path: '/service/bank-receipts',
                            caption: 'Bank Receipts',
                        },

                        {
                            path: '/service/customer-receipts',
                            caption: 'Customer Receipts',
                        },
                    ],
                },
            ],
        },

        {
            caption: 'Tracker',
            submenus: [
                {
                    caption: 'Booking Tracker',
                    path: '/booking-tracker',
                },
                {
                    caption: 'Finance Tracker',
                    path: '/finance-tracker',
                },
            ]
        },

        {
            caption: 'Vehicle Stock',
            submenus: [
                {
                    caption: 'Stock Movement',
                    path: '/stock-transaction',
                },

                {
                    caption: 'Stock Transfer',
                    path: '/stock-transfers',
                },
        
            ],
        },

        // {
        //     caption: 'Incentive',
        //     path: '/incentive-overview',


        // {
        //     caption: 'Booking Overview',
        //     path: '/booking-overview',
        // },


        // {
        //     caption: 'Insurance Tracker',
        //     path: '/insurance-tracker',
        // },
        {
            caption: 'Vehicle Overview',
            path: '/vehicle-overview',
        },
        // {
        //     caption: 'Accessory Overview',
        //     path: '/accessory-overview',
        // },
        {
            caption: 'Cancellations',
            path: '/cancellations',
        },

        // {
        //     caption: 'Finance Payout',
        //     path: '/payouts',
        // },

        // {
        //     caption: 'Trading Overview',
        //     path: '/trading-profit'
        // },

        // {
        //     caption: 'Reports',
        //     submenus: [
        //         {
        //             caption: 'Pending Extended Warranty',
        //             path: '/pending-extended-warranty'
        //         }
        //     ]
        // },

        //     caption: "Delivery Requests",
        //     submenus: user.locations.map((location) => {

        //       return {
        //         path: `/${location}/delivery-review`,
        //         caption: `${location}`
        //       }

        //     })
        //   }]

        // },

        {
            caption: 'Requests',
            submenus: [
                // {
                //   caption: 'All Requests',
                //   path: '/manage-requests/'
                // },

                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/request-overview`,
                                caption: 'Request Overview',
                            },

                            ...requestRouteConfig.map((config) => {
                                return {
                                    path: `/${location}/${config.path}`,
                                    caption: config.caption,
                                };
                            }),
                            // {
                            //     path: `/${location}/all-requests`,
                            //     caption: 'All Requests'
                            // },

                            // {
                            //     path: `/${location}/pending-requests`,
                            //     caption: 'Pending Requests'
                            // },

                            // {
                            //     path: `/${location}/billing-requests`,
                            //     caption: 'Pending for Delivery'
                            // },

                            // {
                            //     path: `/${location}/delivered-requests`,
                            //     caption: 'Delivered Requests'
                            // }
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Bookings',
            submenus: [
                // {
                //   path: '/manage-bookings',
                //   caption: 'Bookings'
                // },

                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/all-bookings`,
                                caption: 'All Bookings',
                            },

                            {
                                path: `/${location}/pending-bookings`,
                                caption: 'Pending Bookings',
                            },

                            {
                                path: `/${location}/billing-done`,
                                caption: 'Pending for Delivery',
                            },

                            {
                                path: `/${location}/delivery-done`,
                                caption: 'Delivered Bookings',
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Bulk KEC Change',
            path: '/bulk-kec-change',
        },

        {
            caption: 'Allocations',
            path: '/admin-stock',
        },

        {
            caption: 'Sales Report',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Vehicle Bills',
                                path: `/${location}/basic/bills`,
                            },
                            {
                                caption: 'Accessories Bills',
                                path: `/${location}/Accessories/bills`,
                            },
                            {
                                caption: 'VAS Bills',
                                path: `/${location}/VAS/bills`,
                            },
                            {
                                caption: 'After Market Bills',
                                path: `/${location}/After Market/bills`,
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Matching Summary',
            path: '/matching-summary',
        },

        {
            caption: 'Item-wise GST Report',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                caption: 'Accessories ',
                                path: `/${location}/Accessories/items`,
                            },
                            {
                                caption: 'VAS ',
                                path: `/${location}/VAS/items`,
                            },
                            {
                                caption: 'After Market ',
                                path: `/${location}/After Market/items`,
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Stock',
            path: '/actual-stock',
        },

        // {
        //     caption: 'Vehicles',
        //     submenus: user.locations.map((location) => {
        //         return {
        //             path: `/${location}/vehicles`,
        //             caption: `${location}`,
        //         };
        //     }),
        // },

        // {
        //     caption: 'Payouts',
        //     submenus: [
        //         {
        //             caption: 'From Finance',
        //             submenus: [
        //                 ...user.locations.map((location) => {
        //                     return {
        //                         caption: location,
        //                         path: `/${location}/finance-payout`,
        //                     };
        //                 }),
        //             ],
        //         },
        //     ],
        // },

        // {
        //     caption: 'Accessories',
        //     submenus: user.locations.map((location) => {
        //         return {
        //             path: `/${location}/accessories`,
        //             caption: `${location}`,
        //         };
        //     }),
        // },

        {
            caption: 'Employees',
            path: '/employees',
        },

        {
            caption: 'Summary',
            submenus: [
                {
                    path: '/if-status',
                    caption: 'If Status',
                },
            ],
        },

        // {
        //     caption: 'High Priority',
        //     submenus: [
        //         {
        //             path: '/high-priority',
        //             caption: 'High Priority Requests',
        //         },
        //     ],
        // },

        {
            caption: 'Approvals',
            submenus: [
                // {
                //   path: '/allocation-list',
                //   caption: 'DMS Allocation Requests'
                // },

                ...adminSteps.filter((step) => !step.isForm),
            ],
        },

        // {
        //   path: '/allocation-list',
        //   caption: 'DMS Allocation Requests'
        // },
        {
            caption: 'Settings',
            submenus: [
                {
                    path: '/common/users',
                    caption: 'Manage Users',
                },
            ],
        },

        {
            caption: 'Manage',
            submenus: [
                {
                    caption: 'Receipts',
                    submenus: user.locations.map((location) => {
                        return {
                            path: `/${location}/receipts`,
                            caption: `${location}`,
                        };
                    }),
                },


                {
                    caption: 'Manage Stock',
                    path: '/manage-stock',
                },
            ],
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => {
                            return (
                                <>
                                    <NoticeBoard />

                                    {/* <PaymentRequestsTile schema={paymentRequestSchema} /> */}

                                    {/* <ReconcileOverview /> */}

                                    <Dashboard steps={adminSteps} />

                                    {/* <Tabs
                                        defaultActiveKey="0"
                                        type="card"
                                        size={'small'}
                                    >
                                        <TabPane tab="Todays Review" key="0">
                                            <DailyDashboard />
                                        </TabPane>
                                        <TabPane tab="Pending Cases" key="1">
                                            <RequestDashboard />
                                        </TabPane>
                                    </Tabs> */}

                                    <Divider />

                                    {/* <TaskOverview steps={adminSteps} /> */}
                                </>
                            );
                        }}
                    ></Route>

                    <Route excat path={'/change-password'} component={ChangePassword}></Route>

                    <Route excat path={'/profile'} component={Profile}></Route>

                    <Route exact path={`/booking-tracker`} component={BookingTracker} />

                    <Route exact path={'/monthwise-retails'} component={MonthWiseRetail}></Route>

                    <Route path={'/employees'} render={(routeParams) => <ModuleRoutesLatest {...routeParams} model={Employees} />} />

                    {/* <Route exact path={'/employee'} component={MenuAdd}></Route> */}

                    <Route exact path={'/enquiry-report'} component={enquiryReport}></Route>
                    <Route exact path={'/booking-report'} component={BookingReport}></Route>

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/retail-review`}
                        component={(...routeParams) => <GenericList schema={billingRequestSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Requests Ends */}

                    {/* Finance Payout */}
                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/finance-payout`}
                        component={(...routeParams) => <GenericList schema={payoutSchema} queries={[]} {...routeParams} />}
                    />

                    {/* Finance Payout Ends */}

                    <Route exact path={`/:city/:category/bills`} component={(...routeParams) => <SalesReport {...routeParams} />} />

                    <Route
                        exact
                        path={`/:city/:category/items`}
                        component={(...routeParams) => (
                            <BillList schema={ItemWiseSchema} actions={itemActions} {...routeParams} dateField={'created_date'} />
                        )}
                    />

                    {/* Status Requests */}

                    {/* Status Requests */}

                    {/* Common Routes for Requests */}
                    {requestRouteConfig.map((config) => {
                        return (
                            <Route
                                exact
                                path={`/:city/${config.path}`}
                                component={(...routeParams) => <GenericList schema={requestSchema} queries={config.queries} {...routeParams} />}
                            />
                        );
                    })}
                    {/* Common Routes for Requests Ends */}

                    <Route
                        exact
                        path="/:city/request"
                        component={(...routeParams) => (
                            <GenericList
                                schema={requestSchema}
                                queries={[
                                    {
                                        field: tagToSearch,
                                        value: statusToSearch.replace(/-/g, ' '),
                                    },
                                ]}
                                {...routeParams}
                            />
                        )}
                    />

                    {/* Status Requests Ends */}

                    {/* Status Requests Ends */}

                    <PrivateRoute exact path="/actual-stock" component={ActualStock} userRole={'admin'} />

                    <PrivateRoute path="/matching-summary" component={MatchingDashboard} userRole={'admin'} />

                    <Route path="/admin-stock" component={StockDashboard} userRole={'admin'} />

                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/:city/stock/:id" component={StockDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/verify-payment" component={VerifyPayment} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-bookings" component={ManageBookings} userRole={'admin'} />
                    <PrivateRoute exact path="/manage-requests" component={ManageRequests} userRole={'admin'} />

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'admin'} />

                    <PrivateRoute exact path="/if-status" component={IFStatus} userRole={'admin'} />

                    <PrivateRoute exact path="/manage-users" component={ManageUsers} userRole={'admin'} />

                    <PrivateRoute exact path="/existing-allocation" component={ExistingAllocation} userRole={'admin'} />

                    {/* High Priority */}
                    <PrivateRoute exact path="/high-priority" component={HighPriority} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/high-priority/:id" component={HighPriorityDetail} userRole={'admin'} />
                    {/* High Priority Ends */}

                    {/* Accounts Dashboard */}

                    <Route
                        exact
                        path="/:category/accounts-overview"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <AccountsOverview {...routeParams} />;
                        }}
                    />

                    <PrivateRoute exact path="/incentive-overview" component={IncentiveOverview} userRole={'admin'} />

                    <PrivateRoute exact path="/booking-overview" component={BookingOverview} userRole={'admin'} />

                    <Route
                        exact
                        path="/:category/bank-receipts"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <ManagePayments {...routeParams} />;
                        }}
                    />

                    {/* <PrivateRoute exact path="/collections" component={Collections} userRole={'admin'} /> */}
                    {/* <PrivateRoute exact path="/:city/collections/:id" component={CollectionDetail} userRole={'admin'} /> */}

                    <Route
                        exact
                        path="/:category/pending-summary"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <ReconcileSummary {...routeParams} />;
                        }}
                    />

                    {/* Customer Receipts Module */}
                    <Route
                        exact
                        path="/:category/customer-receipts"
                        userRole={'admin'}
                        component={(routeParams) => {
                            return <CustomerReceipts {...routeParams} />;
                        }}
                    />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'admin'} />
                    <PrivateRoute exact path="/customer-receipts/add" component={CustomerReceiptAdd} userRole={'admin'} />
                    {/* Customer Receipts Module Ends */}

                    {/* Cancellations Module */}
                    <PrivateRoute exact path="/cancellations" component={Cancellations} userRole={'admin'} />
                    <PrivateRoute exact path="/:city/cancellations/:id" component={CancellationsDetail} userRole={'admin'} />
                    {/* Cancellations Module ends */}

                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/all-bookings`}
                        component={(...routeParams) => <GenericList dateField={'bookingDate'} schema={bookingSchema} queries={[]} {...routeParams} />}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList schema={bookingSchema} queries={bookingSchema.pendingBookings} {...routeParams} />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => <GenericList queries={bookingSchema.billingQueries} schema={bookingSchema} {...routeParams} />}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList queries={bookingSchema.deliveryQueries} schema={bookingSchema} {...routeParams} />
                        )}
                    />
                    {/* Delivery Bookings Ends */}

                    {/* Requests according to Queries */}

                    {/* Requests according to Queries Done */}

                    {/* Bulk KEC Change */}
                    <Route exact path={`/bulk-kec-change`} component={BulkKecChange} />
                    {/* Bulk KEC Change Ends */}

                    {/* Pending Extended Warranty */}
                    <Route exact path={`/pending-extended-warranty`} component={(...routeParams) => <PendingWarranty {...routeParams} />} />
                    {/* Pending Extended Warranty Ends */}

                    {/* vehicle overview */}
                    <Route exact path={`/vehicle-overview`} component={(...routeParams) => <VehicleOverview {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    {/* accessory overview */}
                    <Route exact path={`/accessory-overview`} component={(...routeParams) => <AccessoryOverview {...routeParams} />} />
                    {/* accessory overview Ends */}

                    {/* Trading profit */}
                    <Route exact path={`/trading-profit`} component={(...routeParams) => <TradingProfit {...routeParams} />} />
                    {/* Trading profit Ends */}

                    {/* vehicle overview */}
                    <Route exact path={`/retail-tracker`} component={(...routeParams) => <RetailTracker {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    {/* vehicle overview */}
                    <Route exact path={`/delivery-tracker`} component={(...routeParams) => <DeliveryTracker {...routeParams} />} />
                    {/* vehicle overview Ends */}

                    {/* finance tracker */}
                    <Route exact path={`/finance-tracker`} component={(...routeParams) => <FinanceTracker {...routeParams} />} />
                    {/* finance tracker Ends */}

                    {/* finance payout tracker */}
                    <Route exact path={`/payouts`} component={({ ...routeParams }) => <FinancePayoutTracker {...routeParams} />} />
                    <Route exact path="/bill/:id" component={BillDetails} userRole={'admin'} />
                    {/* finance payout tracker Ends */}

                    {/* insurance tracker */}
                    <Route exact path={`/insurance-tracker`} component={(...routeParams) => <InsuranceTracker {...routeParams} />} />
                    {/* insurance tracker Ends */}

                    {/* Active Requests */}
                    {/* <Route exact path={`/:city/requests`} component={(...routeParams) => <GenericList
            schema={requestSchema}
            columns={requestSchema.columns}
            queries={requestSchema.queries}
            {...routeParams}
          />} /> */}
                    {/* Active Requests Ends */}

                    <PrivateRoute exact path="/daily-overview" component={DailyOverview} userRole={'admin'} />

                    <Route
                        path="/:city/payment-requests"
                        component={(routeParams) => <PaymentRequests {...routeParams} schema={paymentRequestSchema} />}
                    />

                    {/* <Route path="/:city/payment-requests" component={() => <ModuleRoutes isMain model="payment-requests" schema={paymentRequestSchema} columns={paymentRequestSchema.columns} />} /> */}

                    {/* Receipts */}
                    <Route
                        path="/:city/receipts"
                        component={() => <ModuleRoutes model="receipts" schema={receiptsSchema} columns={receiptsSchema.columns} />}
                    />
                    {/* Receipts Ends */}

                    <Route
                        path="/:city/vehicles"
                        component={() => <ModuleRoutes model="vehicles" schema={stockSchema} columns={stockColumns} collection="vehicles" />}
                    />

                    <Route
                        path="/:city/accessories"
                        component={() => <ModuleRoutes model="accessories" schema={partsSchema} columns={partsColumns} collection="parts" />}
                    />

                    {/* Manage Users */}
                    <Route
                        path="/common/users"
                        component={(routeParams) => (
                            <ModuleRoutes model="users" {...routeParams} schema={userSchema} columns={userSchema.columns} collection="users" />
                        )}
                    />
                    {/* Manage Users Ends */}

                    <PrivateRoute exact path="/manage-stock" component={ManageStock} userRole={'admin'} />

                    <PrivateRoute exact path="/stock-transaction" component={StockTransactionRecord} userRole={'admin'} />

                    <PrivateRoute exact path="/stock-transfers" component={StockTransferRecord} />

                    <PrivateRoute exact path="/vehicle/:id" component={VehicleDetail} userRole={'admin'} />


                    {/* Maintaining listing page for different status */}
                    <TaskRoutes steps={adminSteps} list={ApprovalList} form={ApprovalForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
