import React, { useEffect, useState, useContext, useRef } from 'react';

import { Typography, Button, Skeleton, Modal, InputNumber, Form, message, Radio, Input, Select, Checkbox } from 'antd';

import { Settings, Bills, BillDetails, Vehicles, Branches, Purchases } from '../../models';

import './vehicle-service-invoice.scss';

import { DateDisplay } from '../../components';

import { GlobalContext } from '../../Store';

import { useReactToPrint } from 'react-to-print';

import moment from 'moment';

import EditBill from '../../modules/common/resources/edit-bill/edit-bill';

const { Title, Text } = Typography;

const { Option } = Select;

const { TextArea } = Input;

var companyLogo = require('../../assets/images/kia-logo.png');

export default function VehicleServiceInvoice({ id, printable = false, matching }) {
    const [loading, setLoading] = useState(false);

    const { user } = useContext(GlobalContext);

    const [bill, setBill] = useState({});

    const [bill_details, setBill_details] = useState({});

    const [round, setRound] = useState();

    const [branch, setBranch] = useState();

    const [data, setData] = useState({ items: [] });

    const [vehicle, setVehicle] = useState({});

    const [hsncode, setHsncode] = useState();

    const [disable, setDisable] = useState(false);
    const [daydiff, setDayDiff] = useState();

    const [visible, setVisible] = useState(false);

    const [discountVisible, setDiscountVisible] = useState(false);

    const [editVisible, setEditVisible] = useState(false);

    const [btnloading, setBtnLoading] = useState(false);

    const [cancelDay, setCancelDay] = useState();

    const print = useRef();

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const handleClick = (params) => {
        //To show disocunt modal
        setDiscountVisible(true);
    };

    useEffect(() => {
        if (matching[0]) {
            loadPage();
        } else {
            setLoading(false);
        }
        loadCancelDay();

        findingDayDifference();

        return () => {};
    }, []);

    function loadPage() {
        getHsn();

        loadBranch();

        loadBillData();

        getRound();

        loadBillDetails();

        setLoading(true);
    }
    const findingDayDifference = () => {
        var td = moment().format('DD/MM/YYYY');
        var id = moment(bill.created_date).format('DD/MM/YYYY');
        var invoice = moment(id, 'DD/MM/YYYY');
        var today = moment(td, 'DD/MM/YYYY');

        var result = today.diff(invoice, 'days');

        setDayDiff(result);
        console.log(result);
    };

    function loadCancelDay() {
        let query = [
            {
                field: 'name',
                value: 'vas-billing-cancellation-days',
            },
        ];
        Settings.get(query).then((result) => {
            setCancelDay(result.settings[0].value);
        });
    }

    // For brach details to add invoice
    const loadBranch = () => {
        var queries = [
            {
                field: 'dealer_code',
                value: matching[0].dealerCode,
            },
        ];

        Branches.get(queries).then((result) => {
            setBranch(result.branches);
        });
    };

    function loadBillData() {
        Bills.getRecord(id).then((result) => {
            var vehicle_bill = result;

            setBill(vehicle_bill || {});

            if (vehicle_bill && vehicle_bill.id) {
                var bill_id = vehicle_bill.id;

                BillDetails.getBillItems({ bill_id }).then((result) => {
                    setData(result);
                    getVehicle().then(() => {
                        setLoading(false);
                    });
                });
            } else {
                setLoading(false);
            }
            if (vehicle_bill.latest_discount_amount) {
                setDisable(true);
            } else {
                setDisable(false);
            }
        });
    }
    /**to get bill_details
     * bill_id
     *
     */
    function loadBillDetails() {
        let query = [
            {
                field: 'bill_id',
                value: id,
                type: 'basic',
            },
        ];

        BillDetails.get(query).then((result) => {
            setBill_details(result['bill-details']);
        });
    }

    function getRound() {
        let query = [
            {
                field: 'name',
                value: 'calculation-rounding',
            },
        ];
        Settings.get(query).then((res) => {
            setRound(res.settings[0].round);
        });
    }

    /**
     * Load the vehicle
     */
    function getVehicle() {
        let vinNo = matching[0];

        let x = vinNo.item_id;

        return Vehicles.getRecord(x).then((result) => {
            setVehicle(result);

            return vehicle;
        });
    }

    function getHsn() {
        let vinNo = matching[0].id;

        let query = [
            {
                field: 'VinNo',
                value: vinNo,
            },
        ];

        Purchases.get(query).then((result) => {
            let hsn = result.purchase[0]['HSN Code'];

            setHsncode(hsn);
        });
    }

    const handleModal = () => {
        setVisible(true);
    };

    const onFinish = (values) => {
        cancelInvoice(values);
        setVisible(false);
    };

    const cancelInvoice = (value) => {
        let invoice = bill.invoice_number;

        let cancelValues = {
            ...value,
            user: user.name,
            branch_id: user.department_ids[0],
            bill_id: bill.id,
            deleted_at: null,
            deleted_by: null,
        };

        let values = {
            ...value,
            ...bill,
            cancellation_status: true,
        };

        return Vehicles.cancelInvoice(cancelValues, values, bill, matching, { cart: null }, { request: null }, vehicle).then(() => {
            // message.success(`Invoice ${invoice} has cancelled successfully. `);
            setBtnLoading(false);
            loadBillData();
            // history.goBack();
        });
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Cancel Invoice"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Form name="basic" onFinish={onFinish}>
                    <Form.Item
                        name="remarks"
                        PlaceHolder="Enter remarks"
                        label="Remarks"
                        rules={[{ required: true, message: 'Please enter remarks!' }]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <div className="buttons-set">
                {printable ? (
                    <div className="buttons">
                        <div>
                            <Button type="dashed" className="print-button" onClick={handlePrint}>
                                Print Invoice
                            </Button>
                        </div>

                        {user.role === 'admin' || user.role === 'management'|| user.isEditAllowed ? (
                            <div className="edit-discount-div">
                                <div className="lateDiscount">
                                    {' '}
                                    <Button type="dashed" onClick={handleClick}>
                                        Add Late Discount
                                    </Button>
                                </div>

                                <div className="editBill">
                                    <EditBill loadPage={loadPage} bill={bill} bill_details={bill_details} user={user} round={round} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {daydiff <= cancelDay && (user.role === 'admin' || user.role === 'management'|| user.isCancellationAllowed) && bill.cancellation_status !== true ? (
                    <div>
                        <Button loading={btnloading} type="dashed" onClick={handleModal}>
                            Cancel Invoice
                        </Button>
                    </div>
                ) : bill.cancellation_status === true ? (
                    <Text type="danger">Cancelled Invoice</Text>
                ) : null}
            </div>

            <div className={`vehicle-invoice ${printable ? 'scale' : ''}`} ref={print}>
                {loading ? (
                    <Skeleton />
                ) : (
                    <>
                        {bill.cancellation_status === true ? <Text type="danger">Cancelled Invoice</Text> : null}

                        {bill.invoice_number ? (
                            <>
                                <>
                                    <div className="bill-header">
                                        <img className="logo-welcome" src={companyLogo} alt="Logo" />
                                        <div>
                                            <p>{branch && branch[0].address_line_1}</p>
                                            <p>{branch && branch[0].address_line_2}</p>
                                            <p>{branch && branch[0].state}</p>
                                            <p>{branch && branch[0].postal_code}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <Title className="headline">Vehicle Tax Invoice</Title>
                                    <div className="bill-details">
                                        <div className="left">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="detail-element">Dealer GST No </td>
                                                        <td>: {branch && branch[0].gst_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Bill To Name</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.customer_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Bill To Address</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.customer_address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Ship To Name</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.ship_to_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Ship To Address</td>
                                                        <td style={{ textTransform: 'uppercase' }}>: {bill.ship_to_address}</td>
                                                    </tr>
                                                    {bill.ship_to_postal_code ? (
                                                        <tr>
                                                            <td className="detail-element">Ship To Postal Code</td>
                                                            <td>: {bill.ship_to_postal_code}</td>
                                                        </tr>
                                                    ) : null}
                                                    {/* <tr>
                                                        <td className="detail-element">Financed by/Hypo. by</td>
                                                        <td>: {request.finance && request.finance.bank}</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="right">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="detail-element">Customer GST No </td>
                                                        <td>: {bill.gst_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Customer Id</td>
                                                        <td>: {bill.customerID}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Invoice No</td>
                                                        <td>: {bill.invoice_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Invoice Date </td>
                                                        <td>
                                                            : <DateDisplay value={bill.invoice_date_millisecond} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Phone No</td>
                                                        <td>: {bill.customer_phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">PAN No</td>
                                                        <td>: {bill.pan_number} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="detail-element">Place of Supply</td>
                                                        <td>: {bill.customer_state}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <table className="bill-content">
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                <th className="title text-left">PARTICULARS</th>
                                                <th className="title amount">AMOUNT(Rs)</th>
                                            </tr>

                                            {/* Basic Vehicle Price */}

                                            {data.items.map((record, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}.</td>

                                                        <td>{record.description}</td>

                                                        <td className="amount">{record.value}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <table className="bill-summary">
                                        <tbody>
                                            <tr>
                                                <td className="title text-left">TOTAL</td>
                                                <td className="title text-right">{data.net_amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="title text-left">TCS @1%</td>
                                                <td className="title text-right">{data.tcs_amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="title text-left">ROUND OFF</td>
                                                <td className="title text-right">{data.roundAmount}</td>
                                            </tr>
                                            <tr>
                                                <td className="title text-left grand-title">GRAND TOTAL</td>
                                                <td className="title text-right grand-content">{data.grand_total}</td>
                                            </tr>
                                            {data.ex_showroom ? <tr>
                                                <td className="title text-left">EXSHOWROOM PRICE</td>
                                                <td className="title text-right">{data.ex_showroom}</td>
                                            </tr> : null}
                                        </tbody>
                                    </table>

                                    <div className="vehicle-particulars">
                                        <p>Vehicle Particulars</p>
                                        <table>
                                            <tbody>
                                                <td>
                                                    <div className="details-box">
                                                        <span>Vin No.</span>
                                                        <span>{vehicle['Vin Number']}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="details-box">
                                                        <span>Engine No.</span>
                                                        <span>{vehicle['Engine No']}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="details-box">
                                                        <span>Key No.</span>
                                                        <span>{vehicle['Key Number']}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="details-box">
                                                        <span>HSN No</span>
                                                        <span>{hsncode}</span>
                                                    </div>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="footer">
                                        <p>"Terms & condition apply"</p>
                                        <h3>Applied Promotion:</h3>
                                        <div className="signature">
                                            <div className="">
                                                <div className="sign-box"></div>
                                                <h4>Signature of Customer</h4>
                                            </div>
                                            <div className="">
                                                <p>For DKH Motors LLP</p>
                                                <div className="sign-box"></div>
                                                <h4>Authorised Signatory</h4>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </>
                        ) : (
                            <p>Invoice unavailable.</p>
                        )}
                    </>
                )}
            </div>
            {/* Modal for Adding Discount */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Add Discount"
                visible={discountVisible}
                okText="Okay"
                onOk={() => {
                    setDiscountVisible(false);
                }}
                onCancel={() => {
                    setDiscountVisible(false);
                }}
            >
                <DiscountModal
                    id={id}
                    bill={bill}
                    bill_details={bill_details}
                    round={round}
                    data={data}
                    user={user}
                    setDiscountVisible={setDiscountVisible}
                    setLoading={setLoading}
                    callBack={() => {
                        loadPage();

                        //To disable addlatedisocunt button once late discount is added
                        setDisable(true);
                    }}
                />
            </Modal>
            {/* Modal for Adding Discount Ends */}
        </>
    );
}
/**
 *
 *
 * @returns ]
 */

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { span: 16 },
};

function DiscountModal({ bill_details, id, data, bill, round, user, setDiscountVisible, callBack, setLoading }) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        setLoading(true);
        setDiscountVisible(false);

        let formBody = {
            latest_discount_type: values.discount_type,
            latest_discount_value: values.latest_discount_value,
            selectedRecords: bill_details,
            customer_state: bill.customer_state,
            bill_id: id,
            round,
            is_gst_customer: bill.is_gst_customer,
            user: user,
            vin_no: bill.attributes.vinNo,
            model: bill.attributes.model,
            variant: bill.attributes.variant,
            customer_name: bill.customer_name,
            gst_number: bill.gst_number,
            invoice_number: bill.invoice_number,
            branch_id: bill.branch_id,
            attached_with: bill.attached_with,
        };

        // This function is triggered where late disocunt type and value is passed
        Bills.loadFunction('addLateDiscount', formBody).then((result) => {
            if (result.status === 'success') {
                message.success('Successfully added latest discount');
            } else {
                message.error('Failed');
            }
            callBack();
        });
    };

    function onRadioButtonChange() {
        //To clear field when radio button is selected
        form.resetFields();
    }

    return (
        <>
            <p>Add the latest discount</p>

            <Form
                {...layout}
                name="basic"
                form={form}
                initialValues={{
                    remember: true,
                    discount_type: data.discount_type,
                    latest_bill_discount_value: data.latest_bill_discount_value,
                    latest_item_discount_value: data.latest_item_discount_value,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="discount_type"
                    label="Discount"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a type',
                        },
                    ]}
                >
                    <Radio.Group defaultValue={data.discount_type}>
                        <Radio value="percent" onChange={onRadioButtonChange}>
                            In %
                        </Radio>
                        <Radio value="amount" onChange={onRadioButtonChange}>
                            In Rs
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Latest Item Discount"
                    name="latest_discount_value"
                    dependencies={['discount_type']}
                    rules={[
                        { required: true, message: 'Please input your amount' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('discount_type') === 'amount') {
                                    if (value >= data.net_amount) {
                                        return Promise.reject(new Error('Discount should not exceed net amount.'));
                                    } else return Promise.resolve();
                                } else if (!value || getFieldValue('discount_type') === 'percent') {
                                    if (value >= 100) {
                                        return Promise.reject(new Error('Discount should not exceed 100%.'));
                                    } else return Promise.resolve();
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        }),
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
